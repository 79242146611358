import { Card, notification, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import RoutesConstants from '../../routes/RoutesConstant';
import trimbleApp from '../../assets/images/trimble_app.svg';
import trimble_logo from '../../assets/images/trimble_logo.svg';
import './trimbleApp.css';
import OAuth2Login from 'react-simple-oauth2-login';
import { getDigiitalAppAccessToken, getDigitalApps } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { DigiitalApp } from '../../constants/types';
import loading_logo from '../../assets/images/loading_logo.gif';

const DigitalApps: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const [applicatiions, setApplicatiions] = useState<any>([]);
  const [selectedApp, setSelectedApp] = useState<DigiitalApp>();
  const [loading, setLoading] = useState(false);
  const { push } = useHistory();

  const onSuccess = async (response: any) => {
    const { code } = response;

    try {
      setLoading(true);
      await getDigiitalAppAccessToken(
        userDetails.organization.api_url,
        userDetails.organization.id,
        selectedApp?.id as number,
        {
          auth_code: code,
          client_id: selectedApp?.tp_client_id,
          redirect_uri: location.origin,
          grant_type: 'authorization_code',
        },
      );

      push({
        pathname: RoutesConstants.Trimble,
        state: { app: selectedApp },
      });
    } catch (error) {
      notification.error({
        message: 'Login Failed',
      });
    } finally {
      setLoading(false);
    }
  };
  const onFailure = (response: any) =>
    notification.error({ message: 'Login Failed' });

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const { records } = await getDigitalApps(
          userDetails.organization.api_url,
          userDetails.organization.id,
        );
        setApplicatiions(records);
      } catch (error) {
        notification.error({
          message: 'Unable to get Apps',
        });
      } finally {
        setLoading(false);
      }
    };
    if (userDetails && userDetails.organization) {
      init();
    }
  }, [userDetails]);

  return (
    <Spin
      className="loader"
      spinning={loading}
      indicator={
        <img style={{ width: '40px', height: '40px' }} src={loading_logo} />
      }
    >
      <div className="trimbleAppContainer">
        <div className="appStoreTxt">App Store</div>
        {applicatiions &&
          applicatiions.map((app: DigiitalApp, index: number) => (
            <OAuth2Login
              authorizationUrl={app.tp_authorization_url}
              responseType="code"
              clientId={app.tp_client_id}
              redirectUri={location.origin}
              onSuccess={onSuccess}
              onFailure={onFailure}
              scope={app.tp_auth_scope}
              key={index}
            >
              <div onClick={() => setSelectedApp(app)}>
                <img alt="trimbleApp" src={trimbleApp} />
                <div className="trimbleLogTxt">
                  <div className="logoStyle">
                    <img alt="trimble_logo" src={trimble_logo} width="30px" />
                  </div>
                  <div className="trimbleTxtCon">
                    <div className="trimbleIdentity">Trimble Identity</div>
                    <div className="trimbleInc">Trimble Inc.</div>
                  </div>
                </div>
              </div>
            </OAuth2Login>
          ))}
      </div>
    </Spin>
  );
};

export default DigitalApps;
