/* eslint-disable react/jsx-no-undef */
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import React, { createRef, useContext, useEffect, useState } from 'react';
import FullCalendar, { EventSourceInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { getRecordedVideo } from '../../constants/Api';
import './style.css';
import { ApplicationContext } from '../../context/AppContext';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { notification } from 'antd';
import loadinggif from '../../assets/images/loading_logo.gif';
import { Image } from 'antd';
import { Pages } from '../library/Library';

type Event = {
  start: number;
  end: number;
  title: string;
  tractor_id: number;
  tractorName: string;
  video_time: number;
  backgroundColor: string;
  start_date_time: string;
  end_date_time: string;
};
interface Props {
  setCurrentPage: (page: string) => void;
  setStateData: (state: any) => void;
}
const RecordedVideo: React.FC<Props> = ({
  setCurrentPage,
  setStateData,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [event, setEvent] = useState<Event[]>([]);
  const calenderRef: React.RefObject<FullCalendar> = createRef();
  const [viewTitle, setViewTitle] = useState('');
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      const init = async () => {
        await getRecordedVideoList();
      };
      init();
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails && userDetails.organization && viewTitle) {
      const init = async () => {
        await getRecordedVideoList();
      };
      init();
    }
  }, [viewTitle]);

  const getRecordedVideoList = async () => {
    try {
      setLoader(true);
      const startTime = moment(
        calenderRef.current?.getApi().view.activeStart,
      ).format('YYYY-MM-DD');
      const endTime = moment(
        calenderRef.current?.getApi().view.activeEnd,
      ).format('YYYY-MM-DD');
      const data = await getRecordedVideo(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        startTime,
        endTime,
      );
      const events: Event[] = [];
      if (data) {
        data.map((record: any) => {
          events.push({
            start: record.start_date_time,
            end: record.end_date_time,
            title: record.tractor?.name,
            tractor_id: record.tractor_id,
            tractorName: record.tractor?.name,
            video_time: record.start_date_time,
            backgroundColor: 'green',
            start_date_time: record.start_date_time,
            end_date_time: record.end_date_time,
          });
        });

        setEvent(events);
      }
    } catch (error: any) {
      // eslint-disable-next-line no-console
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleClickEvent = async (e: any) => {
    // push({
    //   pathname: RoutesConstants.DayView,
    //   state: e.event._def.extendedProps,
    // });
    setCurrentPage(Pages.DayView);
    setStateData(e.event._def.extendedProps);
  };

  const handleDateEvent = (e: any) => {
    const video_time: any = e.dateStr;
    // push({
    //   pathname: RoutesConstants.DayView,
    //   state: { video_time },
    // });
    setStateData({ video_time });
    setCurrentPage(Pages.DayView);
  };

  return (
    <Layout className="taskmasterComp videosCon">
      <div className="mainContent">
        <div className="dbWidget tmTbl calTbl">
          {/* <Header className="tm-header ant-card-head">
            <div className="ant-card-head-title">Recorded Video</div>
          </Header> */}
          <Content className="tm-content ant-card-body calendar-body rmTbl2 recordedVideoTab">
            <FullCalendar
              ref={calenderRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              headerToolbar={{
                center: 'title',
                left: 'prev,next',
                right: '',
              }}
              slotDuration="00:05:00"
              slotLabelInterval="0:05"
              allDaySlot={false}
              eventTimeFormat={{
                hour: '2-digit', // 2-digit, numeric
                minute: '2-digit', // 2-digit, numeric
                hourCycle: 'h23',
              }}
              editable={false}
              selectMirror={true}
              displayEventTime={false}
              events={event as EventSourceInput}
              eventsSet={() => {
                setViewTitle(calenderRef.current?.getApi().view.title || '');
              }}
              eventClick={(e) => handleClickEvent(e)}
            />
          </Content>
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Image
              width={40}
              style={{ textAlign: 'center' }}
              alt="camImg"
              className="sImg"
              src={loadinggif}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default RecordedVideo;
