import React from 'react';
import Layout from 'antd/lib/layout/layout';

const VideoPop: React.FC = () => {
  return (
    <>
      <Layout>
        <div className="snapshotW">
          <h3>
            <div>Video</div>
          </h3>
          <video
            style={{ width: '100%', height: '930px' }}
            src={window.location.search.substring(1).split('=')[1]}
            controls
          ></video>
        </div>
      </Layout>
    </>
  );
};

export default VideoPop;
