import { Button, Result } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import RoutesConstants from '../../routes/RoutesConstant';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';

const Authentication: React.FC = () => {
  const { push } = useHistory();
  const { t } = useTranslation();

  const handleClick = () => {
    localStorage.clear();
    push(RoutesConstants.Login);
  };

  return (
    <Result
      status="403"
      title="401"
      subTitle="Sorry, your session expired."
      extra={
        <Button type="primary" onClick={() => handleClick()}>
          {t(translate.buttons.gotoLogin)}
        </Button>
      }
    />
  );
};

export default Authentication;
