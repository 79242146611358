/* eslint-disable no-console */
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCzwpezISuOvSkqUtCsGJaYmjIhZEfrg_k',
  authDomain: 'mt-atom.firebaseapp.com',
  databaseURL: 'https://mt-atom.firebaseio.com',
  projectId: 'mt-atom',
  storageBucket: 'mt-atom.appspot.com',
  messagingSenderId: '687185033768',
  appId: '1:687185033768:web:62e8f3bdbe21788709531d',
  measurementId: 'G-X6WFPFMV51',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const getFCMToken = async () => {
  const messaging = getMessaging(app);
  const token = await getToken(messaging, {
    vapidKey:
      'BOfh9aaHdCjlYqX-uqauCGv9dq5DNHSDpJgxpLQXr3nWfOggnHa7WpMVBTAFQ6qqzdEVUHFRwrH98j7ND7Lg1PU',
  });
  return token;
};

export const onMessageListener = () => {
  try {
    const messaging = getMessaging(app);
    return new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });
  } catch (error) {
    console.error(error);
    return new Promise((resolve) => {
      resolve(undefined);
    });
  }
};
