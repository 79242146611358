/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Avatar, notification, Upload } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import 'antd/dist/antd.css';
import './style.css';
import { ApplicationContext } from '../../context/AppContext';
import {
  getUserPermission,
  getUserProfile,
  updateUserProfileImageUpload,
} from '../../constants/Api';
import ChangePassword from './ChangePassword';
import EditIcon from '../../assets/images/EditIcon.svg';
import { checkForFileTypes, fileTypesAccepted } from '../../constants/Common';
import AppLoader from '../common/AppLoader';

const UserProfile: React.FC = () => {
  const { userDetails, updateUserDetails } = useContext(ApplicationContext);
  const [userPermission, setuserPermission] = useState<any>();
  const [passwordShown, setPasswordShown] = useState(false);
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<any>();
  const [file, setFile] = useState<any>();

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getProfile();
    }
  }, [userDetails]);

  const onChange = ({ file }: any) => {
    setTimeout(() => {
      setFile(file);
    }, 300);
  };

  useEffect(() => {
    if (file) {
      handleSave();
    }
  }, [file]);

  const getProfile = async () => {
    const data = await getUserProfile(
      userDetails.organization.api_url,
      userDetails.id,
    );
    setUserProfile(data);
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getPermissions();
    }
  }, [userDetails]);

  const getPermissions = async () => {
    try {
      setLoader(true);
      const response = await getUserPermission(
        userDetails.organization.api_url,
        userDetails.organization.id,
        userDetails.id,
      );
      setuserPermission(response);
    } catch (error: any) {
      notification.error({ message: 'Unable to get Permission data' });
    } finally {
      setLoader(false);
    }
  };

  const passCheck = (id: number) => userDetails && userDetails.id === id;

  const showPassWord = () => {
    setPasswordShown(!passwordShown);
  };

  const closeModelChange = () => {
    setShowModalChangePassword(false);
  };

  // const closeModel = () => {
  //   setShowModalVisible(false);
  // };

  const beforeUpload = (file: any) => {
    const imgSize = file.size / 1024 / 1024 < 10;
    if (!imgSize) {
      notification.error({
        message: 'Image must smaller than 10MB!',
        duration: 2,
      });
      return false;
    } else {
      const fileTypeAccepted = checkForFileTypes(file?.type);
      if (!fileTypeAccepted) {
        notification.error({
          message: 'This file type is not accepted',
          duration: 2,
        });
        return false;
      } else {
        return true;
      }
    }
  };

  const updateProfileImage = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('image', file);
      try {
        const response = await updateUserProfileImageUpload(
          userDetails.organization.api_url,
          userDetails.id,
          formData,
        );
        notification.success({
          message: response?.msg,
        });
      } catch (err: any) {
        notification.error({
          message: err?.message,
          duration: 2,
        });
      }
    }
  };
  const handleSave = async () => {
    try {
      setLoader(true);
      await updateProfileImage();
      updateUserDetails();
    } catch (err: any) {
      notification.error({
        message: err?.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Layout className="posRel">
      <Content>
        <div className="tblContainer my-profile-container userPf userProfileBlk">
          <AppLoader loader={loader} />
          <div
            className="profile-head headline1"
            data-testid="ProfileHeader-UserProfile"
          >
            My Profile
          </div>
          <div className="employee-details my-profile seeDtl">
            <div
              className="employee-profile posrel"
              // style={{ minHeight: '653px' }}
            >
              <div className="profileHeight">
                <div className="profile-details">
                  <div className="profile-avatar">
                    {userProfile?.profile_url !== '' &&
                    userProfile?.profile_url ? (
                      <Avatar src={userProfile?.profile_url}></Avatar>
                    ) : (
                      <Avatar>
                        {userProfile?.first_name?.charAt(0)}
                        {userProfile?.last_name?.charAt(0)}
                      </Avatar>
                    )}
                    <div className="profile-image-upload">
                      <ImgCrop rotate beforeCrop={beforeUpload}>
                        <Upload
                          accept={fileTypesAccepted}
                          listType="picture-card"
                          customRequest={onChange}
                          beforeUpload={beforeUpload}
                          className="avatar-uploader"
                        >
                          <img
                            src={EditIcon}
                            alt="edit"
                            className="profileEditImg"
                          />
                        </Upload>
                      </ImgCrop>
                    </div>
                  </div>
                  <h1 className="headline1 wordBreak">
                    {userProfile?.first_name} {userProfile?.last_name}
                  </h1>
                  <span className="headline2">
                    ID: {userProfile?.member_uuid}
                  </span>
                </div>

                <div className="profileDtlWrapper">
                  <div className="profileContacts">
                    <h1 className="headline2 fSbd">Contact</h1>
                    <h3
                      className="body1"
                      data-testid={`${userProfile?.email}-UseProfile`}
                    >
                      {userProfile?.email}
                    </h3>
                    <h3 className="body1">{userProfile?.contact_number}</h3>
                  </div>

                  {userProfile?.ops_code && (
                    <div className="profileContacts">
                      <h1 className="headline2 fSbd">Operator Code</h1>
                      <div className="proRoleValue flex-1">
                        <div
                          className="flex"
                          // style={{ justifyContent: 'flex-start' }}
                        >
                          <div className="flex-1 body1">
                            {passCheck(userProfile.id) && passwordShown
                              ? userProfile?.ops_code
                              : '* * * *'}
                          </div>
                          {passCheck(userProfile.id) && (
                            <div
                              className="flex-1 showPwdIcon"
                              onClick={showPassWord}
                            >
                              <>
                                {passwordShown ? (
                                  <EyeTwoTone />
                                ) : (
                                  <EyeInvisibleOutlined />
                                )}
                              </>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="profileContacts">
                    <h1 className="headline2 fSbd">Organization</h1>
                    <h3
                      className="body1"
                      data-testid={`${userProfile?.organization?.name}-UseProfile`}
                    >
                      {userProfile?.organization?.name}
                    </h3>
                  </div>
                </div>
              </div>
              <div
                onClick={() => setShowModalChangePassword(true)}
                className="change-ps"
              >
                <h3
                  className="Button changePass"
                  data-testid="changePasswordLink-UserProfile"
                >
                  Change Password
                </h3>
              </div>
            </div>
            <div className="employee-permissions teams-permissions">
              <div className="permissions-title">
                <h1 className="headline1">Teams & Permissions</h1>
              </div>
              <div className="employee-teams">
                <div className="employee-team-sub">
                  {userProfile?.teams.map((ele: any) => {
                    return (
                      <div className="empoyee-team" key={ele?.name}>
                        <div className="employee-team-title">
                          <h1 className="headline2 fSbd">{ele?.name}</h1>
                          <span className="body2">{ele?.description}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {userPermission && (
                <div className="employee-main-permissions">
                  <h1 className="headline3">Permissions</h1>
                  <div className="permission-sub">
                    {userPermission &&
                      userPermission.map((ele: any) => {
                        return (
                          <div className="operation-permissions">
                            <div className="operation-title">
                              <h2 className="body1">
                                {ele?.privilege_group_name}
                              </h2>
                              <Checkbox checked={true} />
                            </div>
                            {ele.privileges.map((eledata: any) => {
                              return (
                                <div className="operation-features">
                                  <div className="operation-feature">
                                    <h3 className="body3">
                                      {eledata?.privilege_name}
                                    </h3>
                                    <Checkbox checked={eledata.status} />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Content>
      {showModalChangePassword && (
        <ChangePassword
          showModal={showModalChangePassword}
          closeModel={() => closeModelChange()}
        />
      )}
    </Layout>
  );
};

export default UserProfile;
