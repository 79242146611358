/* eslint-disable no-dupe-else-if */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import flag_red from '../../assets/images/flag_red.svg';
import appIcon from '../../assets/images/ic_apps.svg';
import nfIcon from '../../assets/images/nfIcon.svg';
import nfTractor from '../../assets/images/nfTractor.svg';
import nf_flag2 from '../../assets/images/nf_flag2.svg';
import { checkAlphaFeature, getUserName } from '../../constants/Common';
import { UserDetails } from '../../constants/types';
import translate from '../../locale/en_translate.json';
import RoutesConstants from '../../routes/RoutesConstant';
import './style.css';
// import nfs from '../../assets/images/nfIcon_white.svg';
// import nfsp from '../../assets/images/nfBell_icon_plane.svg';
// import sSetting from '../../assets/images/gear.svg';
import { Avatar, Button, notification } from 'antd';
import { getTremsAndConditions } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { ApplicationThemeContext } from '../../context/ThemeContext';
import { onMessageListener } from '../../firebaseInit';
import TermsConditions from '../auth/TermsConditions';
import VideoWidget from '../common/VideoWidget';
import NotificationView from '../notifications/NotificationView';
import monarch_technology from '../../assets/images/monarch_technology.svg';
import { WINGSPANAI } from '../../constants/constant';
import { AUTO_DRIVE_LOGOUT_CONFIRM } from '../../context/actions';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import { AutoDriveLogoutConfirm } from '../map/AutoDriveLogoutConfirm';
import DeadManHandle from '../remote_drive_new/DeadManHandle';

interface Props {
  userProfile: UserDetails;
  collapsed: boolean;
  onLinkClick?: (link: string) => void;
}

const AppSideMenu: React.FC<Props> = ({
  userProfile,
  collapsed,
  onLinkClick,
}: Props) => {
  const { t } = useTranslation();
  const { profile_url, first_name, last_name } = userProfile || {};
  const isActive = (name: string) => {
    const loc = useLocation();
    return loc && name === loc.pathname ? 'iconBox mItemSel' : 'iconBox';
  };
  // const [visible, setVisible] = useState(false);
  // const [newNotification, setNewNotification] = useState<boolean>(false);
  // const mixpanel = useMixpanel();
  const { handleLogout, handlePauseResumeStopLiveCom } = useContext(
    AutoDriveApplicationContext,
  );
  const { userDetails, fullScreen, APPReducer } =
    useContext(ApplicationContext);
  const { styles } = useContext(ApplicationThemeContext);
  const [termsData, setTermsData] = useState<any>();
  const [showModal, setshowModal] = useState<boolean>(false);
  const [notificationData, setNotificationData] = useState<any>();
  const [state] = APPReducer;
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  // autoDriveLogoutConfirm key for display the popup
  const { autoDriveLogoutConfirm, drivingTractors } = autoDriveState;
  const { videoWidgetData } = state;

  const handleClick = (routeLnk: string) => {
    localStorage.removeItem('lastTabIndex');
    onLinkClick && onLinkClick(routeLnk);
  };

  const profileImg = (userDetails: any) => (
    <Avatar
      style={{
        width: '34px',
        height: '34px',
        backgroundColor: '#000',
        color: 'white',
        fontFamily: 'Montserrat-Bold',
      }}
      src={userDetails?.profile_url}
    >
      {userDetails?.first_name?.charAt(0)}
      {userDetails?.last_name?.charAt(0)}
    </Avatar>
  );

  const openNotification = async (data: any = {}) => {
    const {
      message = '',
      event_category = 'Indicator',
      event_level = 1,
      user_id,
      body = '',
      camera = '',
    } = data;
    let result;
    // try {
    //   result = await getUserProfile(userDetails.organization.api_url, user_id);
    // } catch (error) {
    //   // eslint-disable-next-line no-console
    //   console.error(error);
    // }
    // setNewNotification(true);
    // const { first_name = '', last_name = '' } = result || {};
    // const created_by_user = `${first_name} ${last_name}`;
    notification.open({
      message: '',
      duration: 5,
      description: (
        <div className="proRoleType subFlex flexBtn flexAlignStart mb0 notificationBlk">
          <div style={{ position: 'relative', width: '20%' }}>
            <div className="nfIcon posRel">
              <div className="nfTopIcon">
                <img
                  className=""
                  src={event_category !== 'Indicator' ? nfIcon : nfTractor}
                />
              </div>
              <div className="nfBottomIcon">
                {event_category === 'Indicator' ? (
                  <>
                    <img
                      className="flagIcon"
                      src={event_level === 3 ? flag_red : nf_flag2}
                    />
                    {event_level}
                  </>
                ) : (
                  profileImg(result)
                )}
              </div>
            </div>
          </div>
          <div className="notificationInrBlk ml20">
            <p className="notificationMsg body2">
              {message && message.trim() !== '' ? message : body}
              <br />
              {camera && camera.trim() !== '' ? `Camera : ${camera}` : ''}
              {/* {created_by_user.trim() !== '' && (
                  <span> {created_by_user} </span>
                )} */}
            </p>
            {/* <p className="mb0 mt32 notifiySeemoreBtn">See More</p> */}
          </div>
          {data?.event_category === 'guard_rails' && (
            <div className="notificationbtn">
              <Button
                key="submit"
                onClick={() => {
                  setNotificationData(data);
                }}
                className="btnView"
              >
                View
              </Button>
            </div>
          )}
        </div>
      ),
    });
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTermsAndConditionMethod();
    }
  }, [userDetails]);

  const getTermsAndConditionMethod = async () => {
    try {
      const data = await getTremsAndConditions(
        userDetails.organization.api_url,
        userDetails.organization_id,
        userDetails?.id,
      );
      if (data[0]) {
        setTermsData(data[0]);
        setshowModal(true);
      }
    } catch (error: any) {
      notification.error(error.message);
    }
  };

  const closeModel = () => {
    setshowModal(false);
  };

  onMessageListener()
    .then((payload: any) => {
      if (payload) {
        if (payload?.data) openNotification(payload?.data);
      }
    })
    // eslint-disable-next-line no-console
    .catch((err) => console.error('failed: ', err));

  return (
    <>
      <nav
        className={`main-menu ${
          checkAlphaFeature(WINGSPANAI) ? 'wingSpanAI' : ''
        }`}
        style={{
          zIndex: fullScreen ? 99 : 1051,
          background: styles.menuColor,
        }}
        data-testid="sideMenu-AppSideMenu"
      >
        <div className="login_logo">
          <div className="login_logo_box">
            <div className="company_logo"></div>
            {/* <img src={login_logo_small} alt="" className="sLogo" />
            <img src={login_logo_large} alt="" className="lLogo" /> */}
          </div>
        </div>
        <div className="login_user_sec">
          <div className="login_user_dtl">
            <ul className="">
              <li className="">
                <Link to={RoutesConstants.Profile}>
                  <div
                    className="iconBoxProfile"
                    data-testid="profileIcon-AppSideProfile"
                  >
                    {/* <img src={profile_url || profilePic} alt="profilePic" /> */}
                    <Avatar
                      style={{
                        backgroundColor: '#67AFB7',
                        color: 'white',
                        fontFamily: 'Montserrat-Bold',
                      }}
                      src={profile_url}
                    >
                      {first_name?.charAt(0)}
                      {last_name?.charAt(0)}
                    </Avatar>
                  </div>
                  <span
                    className="nav-text overline2"
                    data-testid="userName-AppSideMenu"
                  >
                    {userProfile && getUserName(userProfile)}
                  </span>
                </Link>
              </li>
              {checkAlphaFeature('wingspanai') && (
                <li className="monarch_tech_img">
                  <img src={monarch_technology} alt="" />
                </li>
              )}
              {/* <li className="monarch_tech_img">
                <img src={monarch_technology} alt="" />
              </li> */}
            </ul>
          </div>
        </div>
        <div className="side_menu_sec">
          <ul className="sMenuUL">
            <li>
              <Link to={RoutesConstants.LiveMap}>
                <div className={isActive('/')}>
                  {/* <img src={sectionFarm} alt="section_farm" className="wv25" /> */}
                  <div className="liveops_icon"></div>
                </div>
                <span
                  className="nav-text overline2"
                  data-testid="liveMapMenu-AppSideMenu"
                >
                  {t(translate.dashboard.liveMap)}
                </span>
              </Link>
            </li>
            <li className="has-subnav">
              <a
                id="org_profile"
                onClick={() => {
                  handleClick(RoutesConstants.Organization);
                }}
              >
                <div className={isActive('/organization')}>
                  {/* <img src={farmPro} alt="Farm Profile" className="wv22" /> */}
                  <div className="farmprofile_icon"></div>
                </div>
                <span
                  className="nav-text overline2"
                  data-testid="farmProfileMenu-AppSideMenu"
                >
                  {t(translate.sideMenu.farmProfile)}
                </span>
              </a>
            </li>

            {/* <li>
            <a
              onClick={() => {
                setVisible(!visible);
                setNewNotification(false);
              }}
            >
              <div className="iconBox">
                <img
                  src={newNotification ? nfs : nfsp}
                  alt="Notifications"
                  className="wv22"
                />
              </div>
              <span
                className="nav-text"
                data-testid="notificationsMenu-AppSideMenu"
              >
                {t(translate.sideMenu.notification)}
              </span>
            </a>
          </li> */}
            {process.env.REACT_APP_ALPHA_FEATURES &&
              process.env.REACT_APP_ALPHA_FEATURES.includes('TaskMaster') && (
                <li>
                  <a
                    onClick={() => {
                      handleClick(RoutesConstants.Taskmaster);
                    }}
                  >
                    <div
                      className={isActive('/tasks')}
                      // style={{ opacity: 0.4 }}
                    >
                      {/* <img src={taskIcon} alt="Tasks" className="wv21" /> */}
                      <div className="works_schedule_icon"></div>
                    </div>
                    <span
                      className="nav-text overline2"
                      data-testid="tasksMenu-AppSideMenu"
                    >
                      {t(translate.sideMenu.workSchedule)}
                    </span>
                  </a>
                </li>
              )}
            <li className="has-subnav">
              <a
                onClick={() => {
                  handleClick(RoutesConstants.FleetHealth);
                }}
              >
                <div className={isActive('/fleethealth')}>
                  {/* <img src={fleetHealth} alt="Fleet Health" className="wv22" /> */}
                  <div className="maintenance_icon"></div>
                </div>
                <span
                  className="nav-text overline2"
                  data-testid="fleetHealthMenu-AppSideMenu"
                >
                  {t(translate.sideMenu.fleetHealth)}
                </span>
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  handleClick(RoutesConstants.Analytic);
                }}
              >
                <div className={isActive('/analytic')}>
                  {/* <img src={analyticsIcon} alt="analytics" className="wv16" /> */}
                  <div className="analytics_icon"></div>
                </div>
                <span
                  className="nav-text overline2"
                  data-testid="analyticsMenu-AppSideMenu"
                >
                  {t(translate.sideMenu.fleetUsage)}
                </span>
              </a>
            </li>
            {process.env.REACT_APP_ALPHA_FEATURES &&
              process.env.REACT_APP_ALPHA_FEATURES.includes('DigitalApps') && (
                <li>
                  <a
                    onClick={() => {
                      handleClick(RoutesConstants.FleetUsage);
                    }}
                  >
                    <div className={isActive('/fleetusage')}>
                      {/* <img
                        src={fleetUsageIcon}
                        alt="fleetusage"
                        className="wv22"
                      /> */}
                      <div className="fleetusage_icon"></div>
                    </div>
                    <span
                      className="nav-text overline2"
                      data-testid="fleetusage-AppSideMenu"
                    >
                      {t(translate.sideMenu.fleetFeatures)}
                    </span>
                  </a>
                </li>
              )}
            {process.env.REACT_APP_ALPHA_FEATURES &&
              process.env.REACT_APP_ALPHA_FEATURES.includes('DigitalApps') && (
                <li>
                  <a
                    onClick={() => {
                      handleClick(RoutesConstants.Apps);
                    }}
                  >
                    <div className={isActive('/apps')}>
                      <img src={appIcon} alt="apps" className="wv16" />
                    </div>
                    <span
                      className="nav-text overline2"
                      data-testid="apps-AppSideMenu"
                    >
                      {t(translate.sideMenu.digitalAppStore)}
                    </span>
                  </a>
                </li>
              )}

            <div className="bottomLine"></div>
          </ul>

          <ul className="logout">
            <li className="logoutLink" onClick={() => handleLogout()}>
              <a>
                <div className="iconBox">
                  {/* <img src={logout} alt="logout" className="wv20" /> */}
                  <div className="logout_icon"></div>
                </div>
                <span
                  className="nav-text overline2"
                  data-testid="logoutButton-AppSideMenu"
                >
                  {t(translate.sideMenu.logout)}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <NotificationView data={notificationData} />
      {termsData && showModal && (
        <TermsConditions
          showModal={showModal}
          closeModel={closeModel}
          termsData={termsData}
        ></TermsConditions>
      )}
      {videoWidgetData?.toggle && <VideoWidget />}
      {/* Logout confirm dialog if auto_drive running */}
      {autoDriveLogoutConfirm && (
        <AutoDriveLogoutConfirm
          showModal={autoDriveLogoutConfirm}
          closeModel={(ok?: boolean) => {
            autoDriveDispatch({
              type: AUTO_DRIVE_LOGOUT_CONFIRM,
              payload: false,
            });
            if (ok) {
              handlePauseResumeStopLiveCom('stop');
            }
          }}
        />
      )}
      {drivingTractors.length > 0 && <DeadManHandle />}
    </>
  );
};

export default AppSideMenu;
