import React, { useContext, useEffect, useState } from 'react';
import {
  Input,
  Avatar,
  notification,
  Spin,
  Modal,
  Card,
  Space,
  Button,
  Select,
} from 'antd';
import applicationIds from '../../locale/applicationIds.json';
import EditIcon from '../../assets/images/EditIcon.svg';
import './ImpStyle.css';
import verM_logo from '../../assets/images/verM_logo.svg';
import {
  getCategoryList,
  getManufactureList,
  getManufactureModelsList,
  getMenfacturerList,
  imageUploadMethod,
  saveImm,
  SaveImplements,
  UpdateImplements,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import {
  onKeyDown,
  onlyImplmentsNumberAndDecimalsAllow,
} from '../../constants/Common';
import classNames from 'classnames';
import DropdownRender from '../common/DropdownRender';
import ProfileIcon from '../../assets/images/defaultProfileIcon.svg';
import ImageUpload from '../common/ImageUpload';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import ImplementSelect from '../common/ImplementSelect';
import { activeFilterData, floatingHitchData } from '../../constants/AppData';
import AppLoader from '../common/AppLoader';
interface Props {
  Close: () => void;
  setRefresh: () => void;
  implement: any;
  setEditImplement: any;
}

const EditImplement: React.FC<Props> = ({
  Close,
  setRefresh,
  implement,
  setEditImplement,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [modalFlag, setModalFlag] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [confirmLoader, setConfirmLoader] = useState<boolean>(false);
  const [manufactures, setManufactures] = useState<any[]>([]);
  const [models, setModels] = useState<any[]>([]);
  const [details, setDetails] = useState<any>({});
  const [imm, setImm] = useState<any>({});
  const [image, setImage] = useState<any>({});
  const [file, setFile] = useState<any>('');
  const [attachments, setAttachments] = useState<any[]>([]);
  const [powers, setPowerList] = useState<any[]>([]);
  const [implementType, setImpleteType] = useState<any>([]);
  const [validations, setValidations] = useState<any>({
    name: true,
    manufacture: true,
    model: true,
    enabled: false,
  });
  const [validations1, setValidations1] = useState<any>({
    manufacture: true,
    model: true,
    enabled: false,
  });
  const [newManufacture, setNewManufacture] = useState<string>('');

  const Cancel = () => {
    setModalFlag(false);
  };

  const getManufactureListData = async () => {
    try {
      const result: any = await getManufactureList(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
      );
      setManufactures(result);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  const getManufactureModelListData = async () => {
    try {
      if (
        details?.manufacture?.trim() !== '' ||
        implement?.config_imm.manufacturer?.trim() !== ''
      ) {
        const result: any = await getManufactureModelsList(
          userDetails.organization.api_url,
          userDetails.organization.fleet.id,
          details.manufacture || implement.config_imm.manufacturer,
        );
        setModels(result);
      }
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  const getAttachmeantList = async () => {
    try {
      const attachmeant = await getCategoryList(
        userDetails.organization.api_url,
        'attachment_type',
      );
      setAttachments(attachmeant);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  const getPowerList = async () => {
    try {
      const power = await getCategoryList(
        userDetails.organization.api_url,
        'power',
      );
      setPowerList(power);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };
  const getImplementType = async () => {
    try {
      setLoader(true);
      const cropsdata = await getCategoryList(
        userDetails.organization.api_url,
        'implement_type',
      );
      setImpleteType(cropsdata);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      notification.error({
        message: error?.response?.data?.error?.message || error?.message,
      });
    }
  };

  const handleOnChange = (e: any) => {
    if (e.target.files.length) {
      setImage(e.target.files[0]);
      setFile(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleDropdown = (manufacture: string) => {
    setImm({ ...imm, manufacture });
    setModalFlag(true);
  };

  const handleFormSubmit = () => {
    const { model, name, manufacture } = details;
    setValidations({
      ...validations,
      name: name && name.trim() !== '',
      model: Boolean(model),
      manufacture: Boolean(manufacture),
      enabled: true,
    });
  };

  const handleUpload = async (response: any) => {
    try {
      const formData = new FormData();
      formData.append('image', file?.originFileObj);
      formData.append('category_id', response?.id);
      formData.append('category_name', 'IMPLEMENTS');
      await imageUploadMethod(
        userDetails.organization.api_url,
        userDetails.organization.id,
        formData,
      );
      Close();
      setRefresh();
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const updateImplement = async () => {
    const data = { ...details, config_imm_uuid: details.model };
    try {
      setLoader(true);
      const response: any = await UpdateImplements(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        implement.config_implements_uuid,
        data,
      );
      if (file?.originFileObj) {
        handleUpload(response);
      } else {
        Close();
        setRefresh();
      }
      notification.success({ message: t(translate.label.implementUpdated) });
    } catch (err: any) {
      notification.error({
        message: err?.response?.data?.error?.message || err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleFormSubmit1 = () => {
    const { manufacture, model } = imm;
    setValidations1({
      ...validations,
      manufacture: manufacture?.trim() !== '',
      model: model?.trim() !== '',
      enabled: true,
    });
  };

  const saveManufacturer = async () => {
    const { manufacture, model } = imm;
    const data = {
      ...imm,
      manufacturer: manufacture,
      classification_id: Math.floor(100000 + Math.random() * 900000),
    };
    setConfirmLoader(true);
    try {
      const response = await saveImm(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        data,
      );
      notification.success({
        message: response.msg,
      });
      setModalFlag(false);
      getManufactureListData();
      setDetails({ ...details, manufacture, model: undefined });
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    } finally {
      setConfirmLoader(false);
    }
  };

  useEffect(() => {
    const isValid = Object.values(validations1).every((item) => item);
    if (isValid && Object.keys(validations1).length) saveManufacturer();
  }, [validations1]);

  useEffect(() => {
    const isValid = Object.values(validations).every((item) => item);
    if (isValid && Object.keys(validations).length) updateImplement();
  }, [validations]);

  useEffect(() => {
    const {
      name,
      config_imm,
      config_imm_uuid,
      power,
      attachment_type,
      serial_number,
      implement_type,
      floating_hitch,
      image_url,
      height,
      width,
      length,
      weight,
      is_active,
    } = implement;
    const { manufacturer, model } = config_imm;
    setDetails({
      name,
      manufacture: manufacturer,
      model: config_imm_uuid,
      power: power,
      attachment_type: attachment_type,
      serial_number,
      implement_type,
      floating_hitch,
      height,
      width,
      length,
      weight,
      is_active,
    });
    if (image_url) setFile(image_url);
  }, [implement]);

  useEffect(() => {
    getManufactureListData();
    getManufactureModelListData();
    getAttachmeantList();
    getPowerList();
    getImplementType();
  }, []);

  const changeManufacturer = (text: string) => {
    setNewManufacture(text);
  };

  useEffect(() => {
    if (details?.manufacture) {
      getManufactureModelListData();
    }
  }, [details?.manufacture]);

  return (
    <>
      <div className="profileView empView personeeltab rightWidgetView impWidget">
        <div className="uploadImg proRow mr0 rowGapBtm3">
          <div className="userIcon">
            <div className="proRow proPic">
              {file && file !== '' ? (
                <Avatar src={file?.thumbUrl ? file?.thumbUrl : file}></Avatar>
              ) : (
                <Avatar src={ProfileIcon} className="defaulticon"></Avatar>
              )}
            </div>
            <ImageUpload setFileList={setFile}></ImageUpload>
          </div>
        </div>

        <div className="proRow formStyles">
          <div className="width100 rowGapBtm3 posRel">
            {details?.name?.length > 0 && (
              <div className="inputTop implementLable">Name</div>
            )}
            <Input
              id={applicationIds.implementsScreen.editImpName}
              value={details.name}
              placeholder={!(details?.name?.length > 0) ? 'Name' : ''}
              onChange={(e) => setDetails({ ...details, name: e.target.value })}
              className={
                details?.name
                  ? `implmentInput `
                  : `${classNames({
                      errorClass: !validations.name,
                    })}`
              }
              data-testid="farmEditInputField-EditImplement"
            />
          </div>

          <div className="width100 rowGapBtm3 posRel manufactureTxt">
            <ImplementSelect
              dataTestkey="name"
              dataTestLabel="EditImplement"
              label="Manufacturer"
              list={manufactures}
              showSearch={true}
              optionKey="name"
              value={details.manufacture}
              className={classNames({
                errorClass: !validations.manufacture,
              })}
              onChange={() =>
                setDetails({ ...details, manufacture: '', model: undefined })
              }
              onSearch={(text: string) => changeManufacturer(text)}
              onSelect={(text) =>
                setDetails({
                  ...details,
                  manufacture: text,
                  model: undefined,
                })
              }
              placeholder="Manufacturer"
              testId="editManufacturer-EditImplement"
              filterOption={(input: any, option: any) => {
                return (
                  option.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                );
              }}
              dropDown={(menu: any) => (
                <DropdownRender
                  name={newManufacture}
                  addItem={handleDropdown}
                  menu={menu}
                />
              )}
              optionDisplay="name"
            />
          </div>
          <div className="width100 rowGapBtm3 posRel modelSelect">
            <div className="selectImplementContainer">
              {details?.model && (
                <div className="inputTop implementLable">Model</div>
              )}
              <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                // style={{ width: '284px' }}
                placeholder={!details?.model && 'Model'}
                data-testid="editModel-EditImplement"
                value={details.model}
                onSelect={(model) => {
                  setDetails({ ...details, model: model });
                }}
                className={classNames({
                  errorClass: !validations.model,
                })}
              >
                {models &&
                  models.map((data: any) => {
                    if (data.model) {
                      return (
                        <option
                          value={data.config_imm_uuid}
                          data-testid={`${data.model}-EditImplement`}
                          key={Math.random()}
                        >
                          {data.model}
                        </option>
                      );
                    }
                  })}
              </Select>
            </div>
          </div>
          <div className="width100 rowGapBtm3 posRel">
            <ImplementSelect
              dataTestkey="name"
              dataTestLabel="EditImplement"
              label="Implement Type"
              list={implementType}
              showSearch={false}
              value={details.implement_type}
              optionKey="name"
              onSelect={(implement_type) =>
                setDetails({ ...details, implement_type })
              }
              placeholder="Implement Type"
              testId="implettype-EditImplement"
              optionDisplay="name"
            />
          </div>
          <div className="width100 rowGapBtm3 posrel ipw">
            {details?.serial_number?.length > 0 && (
              <div className="inputTop implementLable">Serial Number</div>
            )}
            <Input
              className={details?.serial_number ? 'implmentInput' : ''}
              id={applicationIds.implementsScreen.editImpSerialNum}
              placeholder={
                !(details?.serial_number?.length > 0) ? 'Serial Number' : ''
              }
              data-testid="editSerialNumber-EditImplement"
              value={details.serial_number}
              onChange={(e) =>
                setDetails({ ...details, serial_number: e.target.value })
              }
            />
          </div>
          <div className="width100 rowGapBtm3 posRel">
            <ImplementSelect
              dataTestkey="name"
              dataTestLabel="EditImplement"
              label="Attachment"
              list={attachments}
              showSearch={false}
              value={details.attachment_type}
              optionKey="name"
              onSelect={(attachment_type) =>
                setDetails({ ...details, attachment_type })
              }
              placeholder="Attachment"
              testId="editAttachment-EditImplement"
              optionDisplay="name"
            />
          </div>

          <div className="width100 rowGapBtm3 posRel">
            <ImplementSelect
              dataTestkey="name"
              dataTestLabel="EditImplement"
              label="Power"
              list={powers}
              showSearch={false}
              value={details.power}
              optionKey="name"
              onSelect={(power) => setDetails({ ...details, power })}
              placeholder="Power"
              testId="editPower-EditImplement"
              optionDisplay="name"
            />
          </div>
          <div className="width100 rowGapBtm3 posRel">
            <ImplementSelect
              dataTestkey="name"
              dataTestLabel="EditImplement"
              label="Floating Hitch"
              list={floatingHitchData}
              showSearch={false}
              value={details?.floating_hitch}
              optionKey="id"
              onSelect={(floating_hitch) => {
                setDetails({
                  ...details,
                  floating_hitch: floating_hitch,
                });
              }}
              placeholder="Floating Hitch?"
              testId="editFloatingHitch-EditImplement"
              optionDisplay="name"
            />
          </div>
          <div className="width100 rowGapBtm3 posrel ipw fieldRange">
            <>
              <span className="posabs">IN</span>
            </>
            {details?.width || details?.width === 0 ? (
              <div className="inputTop implementLable">Max Width</div>
            ) : (
              <></>
            )}
            <Input
              type="number"
              className={
                details?.width || details?.width === 0 ? 'implmentInput' : ''
              }
              id={applicationIds.implementsScreen.editImpWidth}
              placeholder={!details?.width ? 'Max Width' : ''}
              data-testid="editWidth-EditImplement"
              onKeyDown={(e: any) => onKeyDown(e)}
              onChange={(e) => {
                setDetails({ ...details, width: Number(e.target.value) });
              }}
              onKeyPress={(e: any) => onlyImplmentsNumberAndDecimalsAllow(e)}
              value={details.width}
              onWheel={(e) => e.currentTarget.blur()}
            />
          </div>
          <div className="width100 rowGapBtm3 posrel ipw fieldRange">
            <>
              <span className="posabs">IN</span>
            </>
            {details?.length || details?.length === 0 ? (
              <div className="inputTop implementLable">Length</div>
            ) : (
              <></>
            )}
            <Input
              type="number"
              className={
                details?.length || details?.length === 0 ? 'implmentInput' : ''
              }
              id={applicationIds.implementsScreen.editImpLength}
              placeholder={!details?.length ? 'Length' : ''}
              data-testid="editLength-EditImplement"
              onKeyDown={(e: any) => onKeyDown(e)}
              onChange={(e) =>
                setDetails({ ...details, length: Number(e.target.value) })
              }
              onKeyPress={(e: any) => onlyImplmentsNumberAndDecimalsAllow(e)}
              value={details.length}
              onWheel={(e) => e.currentTarget.blur()}
            />
          </div>
          <div className="width100 rowGapBtm3 posrel ipw fieldRange">
            <>
              <span className="posabs">IN</span>
            </>
            {details?.height || details?.height === 0 ? (
              <div className="inputTop implementLable">Height</div>
            ) : (
              <></>
            )}
            <Input
              className={
                details?.height || details?.height === 0 ? 'implmentInput' : ''
              }
              type="number"
              id={applicationIds.implementsScreen.editImpHeight}
              placeholder={!details?.height ? 'Height' : ''}
              data-testid="editHeight-EditImplement"
              onKeyDown={(e: any) => onKeyDown(e)}
              onChange={(e) =>
                setDetails({ ...details, height: Number(e.target.value) })
              }
              onKeyPress={(e: any) => onlyImplmentsNumberAndDecimalsAllow(e)}
              value={details.height}
              onWheel={(e) => e.currentTarget.blur()}
            />
          </div>
          <div className="width100 rowGapBtm3 posrel ipw fieldRange">
            <>
              <span className="posabs">LB</span>
            </>
            {details?.weight || details?.weight === 0 ? (
              <div className="inputTop implementLable">Weight</div>
            ) : (
              <></>
            )}
            <Input
              type="number"
              className={
                details?.weight || details?.weight === 0 ? 'implmentInput' : ''
              }
              id={applicationIds.implementsScreen.editImpWeight}
              placeholder={!details?.weight ? 'Weight' : ''}
              data-testid="editWeight-EditImplement"
              onKeyDown={(e: any) => onKeyDown(e)}
              onChange={(e) =>
                setDetails({ ...details, weight: Number(e.target.value) })
              }
              onKeyPress={(e: any) => onlyImplmentsNumberAndDecimalsAllow(e)}
              value={details.weight}
              onWheel={(e) => e.currentTarget.blur()}
            />
          </div>
          {/* <h6 className="fSbd headline2 width100">Status</h6> */}
          <div className="width100 rowGapBtm3 posRel">
            <ImplementSelect
              dataTestkey="name"
              dataTestLabel="EditImplement"
              label="Status"
              list={activeFilterData}
              showSearch={false}
              value={details?.is_active}
              optionKey="id"
              onSelect={(status) =>
                setDetails({
                  ...details,
                  is_active: status,
                })
              }
              placeholder="Status"
              testId="status-AddImplement"
              optionDisplay="name"
            />
          </div>
          <AppLoader loader={loader} />
          <div className="proeditBtn tcktSaveBtn saveTxtBtn">
            <button
              id={applicationIds.ticketScreen.saveTicket}
              onClick={handleFormSubmit}
              data-testid="saveButton-EditImplement"
            >
              Save
            </button>
          </div>
        </div>
      </div>

      {modalFlag && (
        <Modal visible={modalFlag} footer={false} className="permissonPopup">
          <>
            <Card className="softwareCard">
              <div className="verHistorySec">
                <h3 className="verHistTxt posRel">
                  Add Manufacture
                  <img
                    src={verM_logo}
                    alt="mlogo"
                    className="mLogo"
                    height="33"
                  />
                </h3>

                <div>
                  <div className="width284 mt16">
                    <Input
                      placeholder="Manufacture"
                      value={imm.manufacture}
                      onChange={(e) =>
                        setImm({ ...imm, manufacture: e.target.value })
                      }
                      className={classNames({
                        errorClass: !validations1.manufacture,
                      })}
                    />
                    <br />
                    <br />
                    <Input
                      placeholder="Model"
                      value={imm.model}
                      onChange={(e) =>
                        setImm({ ...imm, model: e.target.value })
                      }
                      className={classNames({
                        errorClass: !validations1.model,
                      })}
                    />
                  </div>
                  <div className="permissonVerSec">
                    <Space>
                      <Button onClick={() => Cancel()} className="verCancelBtn">
                        CANCEL
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleFormSubmit1}
                        className="verResetBtn"
                      >
                        ADD
                      </Button>
                    </Space>
                  </div>
                </div>

                <div className="edit-loader">
                  {confirmLoader && (
                    <div className="loader">
                      <Spin size="large" />
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </>
        </Modal>
      )}
    </>
  );
};

export default EditImplement;
