/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Card, Input, notification, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { ApplicationContext } from '../../context/AppContext';
import { getDateTime } from '../../constants/Common';
import './style.css';
import {
  getFleetSoftwareHistory,
  getFleetSoftwarelogsHistory,
} from '../../constants/Api';
import { pagenation } from '../../constants/types';
import CustomTable from '../common/CustomTable';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import { RedoOutlined } from '@ant-design/icons';

interface Props {
  showModel: boolean;
  closeModel: () => void;
  logData: any;
}

const SoftwareHistoryLogs: React.FC<Props> = ({
  showModel,
  closeModel,
  logData,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const { t } = useTranslation();
  const [pageSize, setPagesize] = useState<any>(25);
  const pagination = {
    current: 1,
    defaultPageSize: 25,
  };
  const [pageNumberdata, setPageNumberdata] = useState<any>(pagination);
  const buildStatus = useRef();

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getfleetSoftwarelogs();
    }
  }, [userDetails, pageNumber, pageSize]);

  const getfleetSoftwarelogs = async () => {
    try {
      setLoader(true);
      const response = await getFleetSoftwareHistory(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        logData.id,
        pageNumber,
        pageSize,
      );
      const tdata: any =
        response && response.records && response.records.length > 0
          ? response.records
          : [];
      if (tdata && tdata.length > 0) {
        const { id, status } = tdata[0];
        buildStatus.current = status;
        const responseData = await getFleetSoftwarelogsHistory(
          userDetails.organization.api_url,
          userDetails.organization.fleet.id,
          logData.id,
          id,
          pageNumber,
          pageSize,
        );
        const data =
          responseData &&
          responseData.records &&
          responseData.records.length > 0
            ? responseData.records
            : [];
        const snapData: any[] = [];
        data.map((record: any) => {
          const obj = {
            date: record.created_date_time
              ? getDateTime(record.created_date_time)
              : '',
            Message: record.message,
          };
          snapData.push(obj);
        });
        setDataSource(snapData);
        const pagination = {
          current: responseData._metadata.page_number,
          pageSizeOptions: ['25'],
          total: responseData._metadata.total_records_count,
        };
        setPageNumberdata(pagination);
      }
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error);
      notification.error({
        message: error.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleChange = (page: pagenation) => {
    setPageNumber(page.current);
    setPagesize(page.pageSize);
  };

  const columns = [
    {
      title: `${t(translate.softwarehistorylogs.date)}`,
      dataIndex: 'date',
      key: 'date',
      width: 240,
    },
    {
      title: `${t(translate.softwarehistorylogs.message)}`,
      dataIndex: 'Message',
      key: 'Message',
    },
  ];

  const handleRefresh = () => {
    dataSource.length = 0;
    setPageNumber(1);
    getfleetSoftwarelogs();
  };
  return (
    <Modal
      title={t(translate.headers.softwarehistorylogs)}
      visible={showModel}
      className="tm-modal customModal"
      onCancel={closeModel}
      onOk={handleRefresh}
      okText="Refresh"
      cancelText="Close"
      okButtonProps={{
        className: 'okBtn',
      }}
      cancelButtonProps={{
        className: 'canelBtn',
      }}
      width={768}
    >
      <div className="formRow">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>Tractor Name</label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <Input defaultValue={logData.name} disabled />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>Software Version</label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <Input defaultValue={logData?.software_version} disabled />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Card bordered={true} className="dbWidget swLogs">
        <div style={{ cursor: 'pointer' }}>
          {buildStatus.current !== 'Pending' ? (
            <CustomTable
              columns={columns}
              dataSource={dataSource}
              pagination={pageNumberdata}
              handleChange={(page: pagenation) => {
                handleChange(page);
              }}
              loader={loader}
              fileName="Softwarehistorylogs"
            ></CustomTable>
          ) : (
            <div
              className="ant-modal-title center-text"
              style={{ padding: '32px 0px' }}
            >
              {' '}
              waiting for download logs
            </div>
          )}
        </div>
      </Card>
    </Modal>
  );
};

export default SoftwareHistoryLogs;
