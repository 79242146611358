/* eslint-disable no-constant-condition */
/* eslint-disable no-useless-escape */
/* eslint-disable no-console */
import moment from 'moment';
import 'moment-duration-format';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import arrowPoint from '../assets/images/arrowPoint.svg';
import tractorIcon from '../assets/images/circleTnb.svg';
import VinerowHeadIcon from '../assets/images/orgRowBdr.png';
import MarkIcon from './../assets/images/pinkRow.png';
import WaypointIcon from './../assets/images/waypoint_single.svg';
import {
  Localization,
  MapFeature,
  TractorHeartBeat,
  TractorsList,
  UserDetails,
} from './types';

import JumpnGoIcon from './../assets/images/m_jngo.svg';
import { mqttConstants } from '../util/MqttConstants';
import highCellular from './../assets/images/high.svg';
import lowCellular from './../assets/images/low.svg';
import mediumCellular from './../assets/images/medium.svg';
import VineIcon from './../assets/images/vine_green.svg';
import fullWifi from './../assets/images/wFull.svg';
import lowWifi from './../assets/images/wLow.svg';
import mediumWifi from './../assets/images/wMedium.svg';

import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';
import * as proj from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import CircleStyle from 'ol/style/Circle';
import badLoc2 from '../assets/images/badLoc2.svg';
import charging_hover from '../assets/images/charging_hover.svg';
import charging_normal from '../assets/images/charging_normal.svg';
import errorCodeCluster from '../assets/images/errorCodeCluster.svg';
import errorCodeClusterHover from '../assets/images/errorCodeClusterHover.svg';
import FleetIndicator from '../assets/images/FleetIndicator.svg';
import heading_flash from '../assets/images/heading_flash.svg';
import DirectionIcon from '../assets/images/inMotion.png';
import LowLocalizationNewTractorIcon from '../assets/images/noConnectivity.svg';
import redIcon from '../assets/images/redIcon.svg';
import selected_tractor_charging from '../assets/images/selected_tractor_charging.svg';
import selected_tractor_nouser from '../assets/images/selected_tractor_nouser.svg';
import selected_tractor_user from '../assets/images/selected_tractor_user.svg';
import NewTractorIcon from '../assets/images/standBy.png';
// import tooltip_icon from '../assets/images/tooltip_icon.svg';
import tooltip_icon_long from '../assets/images/tooltip_icon_long.svg';
import tooltip_icon_medium from '../assets/images/tooltip_icon_medium.svg';
import tooltip_icon_xl from '../assets/images/tooltip_icon_xl.svg';
import tractorsCluster from '../assets/images/tractorsCluster.svg';
import tractorsClusterHover from '../assets/images/tractorsClusterHover.svg';
import tractor_bg_shadow from '../assets/images/tractor_bg_shadow.svg';
import tractor_lock_hover from '../assets/images/tractor_lock_hover.svg';
import tractor_lock_normal from '../assets/images/tractor_lock_normal.svg';
import tractor_offline from '../assets/images/tractor_offline.svg';
import tractor_offline_hover from '../assets/images/tractor_offline_hover.svg';
import tractor_offline_selected from '../assets/images/tractor_offline_selected.svg';
import tractor__hover from '../assets/images/tractor__hover.svg';
import tractor__normal from '../assets/images/tractor__normal.svg';
import waypointsCluster from '../assets/images/waypointsCluster.svg';
import waypointsClusterHover from '../assets/images/waypointsClusterHover.svg';
import faultyTractorIcon from './../assets/images/faultyTractorIcon.svg';
import conv3 from './../assets/images/wFull_w.svg';
import conv1 from './../assets/images/wLow_w.svg';
import conv2 from './../assets/images/wMedium_w.svg';

import { notification } from 'antd';
import { Auth } from 'aws-amplify';
import { Coordinate } from 'ol/coordinate';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import { getArea } from 'ol/sphere';
import {
  getAbBlockVerticesData,
  getBrownPolygonData,
  getPolygonData,
} from './Api';
import constant, { BadLocalization, distanceForGoodPoints } from './constant';

export const getHeaders = async (): Promise<{
  Authorization: string;
  Api_Token?: string;
}> => {
  if (
    process.env.REACT_APP_ALPHA_FEATURES?.toLocaleLowerCase().includes(
      'cognito',
    )
  ) {
    await getAccessJwtToken();
    return {
      Authorization: localStorage.getItem('access_token') || '',
      Api_Token: localStorage.getItem('auth') || '',
    };
  }
  return {
    Authorization: localStorage.getItem('auth') || '',
  };
};

export const getUserName = (userDetails: UserDetails): string => {
  return `${userDetails.first_name} ${userDetails.last_name}`;
};

export const getLatitudeAndLongitude = (
  location: [number, number],
  base: [number, number],
  reverse = false,
): [number, number] => {
  const rEarth = 111319; // radius of earth is approximately 6378 kms (6378137 meters * pi/180)
  const deltaLat = location[0];
  const deltaLon = location[1];
  const newLatitude = base[0] + deltaLat / rEarth;
  const newLongitude =
    base[1] + deltaLon / rEarth / Math.cos(newLatitude * 0.01745);
  // default we send the lat after long
  return reverse ? [newLongitude, newLatitude] : [newLatitude, newLongitude];
};

export const convertRouteXYtoLatLang = (
  data: { y: number; x: number }[],
  groundLat: number,
  groundLang: number,
): { y: number; x: number }[] => {
  const rEarth = 111319; // radius of earth is approximately 6378 kms (6378137 meters * pi/180)
  data.forEach((element: { y: number; x: number }) => {
    const deltaLat = element.y;
    const deltaLon = element.x;
    element.y = groundLat + deltaLat / rEarth;
    element.x = groundLang + deltaLon / rEarth / Math.cos(element.y * 0.01745);
  });
  return data;
};

export const isAdmin = (userDetails: UserDetails): boolean => {
  if (userDetails && userDetails.roles) {
    const { roles } = userDetails;
    const admins = roles.filter((role) => {
      return role.role_key === 'ORG_ADMIN';
    });
    return admins.length > 0;
  }
  return false;
};

export const checkAdminprivalage = (
  userDetails: UserDetails,
  roleList: string[],
): boolean => {
  if (userDetails && userDetails.roles) {
    const { roles } = userDetails;
    const admins = roles.filter((role) => {
      return roleList.includes(role.role_key);
    });
    return admins.length > 0;
  }
  return false;
};

export const validatePassword = (password: string): string => {
  if (password.length < 8) {
    return 'Password should contains At least 8 characters';
  }
  const lowercaseRegex = new RegExp('(?=.*[a-z])'); // has at least one lower case letter
  if (!lowercaseRegex.test(password)) {
    return 'Password should contains At least 1 lowercase letter';
  }
  const uppercaseRegex = new RegExp('(?=.*[A-Z])'); // has at least one upper case letter
  if (!uppercaseRegex.test(password)) {
    return 'Password should contains At least 1 Upper case letter';
  }
  const numRegex = new RegExp('(?=.*\\d)'); // has at least one number
  if (!numRegex.test(password)) {
    return 'Password should contains At least 1 number from 0 to 9';
  }
  const specialcharRegex = new RegExp('[~!@#$%^&*()_+]');
  if (!specialcharRegex.test(password)) {
    return 'Password should contains At least 1 special characters from below ~!@#$%^&*()_+';
  }
  return '';
};

export const getDateTime = (timestamp: number): string => {
  return moment(timestamp).format('MMM D, YYYY h:mm A');
};

export const getDateTimes = (timestamp: number): string => {
  return moment(timestamp).format('MM/D/YY h:mm A');
};

export const getDateTimesByString = (timestamp: string): string => {
  return moment(timestamp).format('MM/D/YY h:mm A');
};
export const getMonthDay = (timestamp: string): string => {
  return moment(timestamp).format('MM/D h:mm A');
};

export const getDateAndTime = (timestamp: number): string => {
  return moment(timestamp).format('MM-D-YYYY h:mm A');
};

export const getDate = (timestamp: number): string => {
  return moment(timestamp).format('MMM D, YYYY');
};

export const getFullDate = (timestamp: number): string => {
  return moment(timestamp).format('MM/D/YYYY');
};

export const getTime = (timestamp: number): string => {
  return moment
    .duration(timestamp, 'seconds')
    .format('hh:mm:ss', { trim: false });
};
export const getHour = (timestamp: number): string => {
  return moment(timestamp).format('h:mm A');
};

export const getAnalyticFormat = (dates: any | undefined): string => {
  return dates
    ? `${moment(dates[0]).format('MMM')} ${moment(dates[0]).format(
        'D',
      )} - ${moment(dates[1]).format('D')}`
    : '-';
};

export const getHourFromString = (strDate: string): string => {
  const timestamp = Date.parse(strDate);
  return getHour(timestamp);
};
export const getDateFromString = (strDate: string): string => {
  const timestamp = Date.parse(strDate);
  return getDate(timestamp);
};

export const getSatelightRGBColor = (
  color: string,
  suggested = false,
): string => {
  switch (color) {
    case 'white':
      return 'rgba(255,255,255)';
    case 'green':
      return '#328D3B';
    case 'red':
      return '#FF0000';
    case 'yellow':
      return '#EBD36B';
    case 'blue':
      return suggested ? '#06348a' : '#7BA1E5';
    case 'brown':
      return '#7C5032';
    case 'abBlock':
      return '#032d57';
    default:
      return color;
  }
};

export const getSatelightRGBFillColor = (
  color: string,
  suggested = false,
): string => {
  switch (color) {
    case 'white':
      return 'rgba(255,255,255,0.1)';
    case 'green':
      // return 'rgba(148, 204, 161, 0.22)';
      // return suggested ? 'rgba(160, 32, 240, .6)' : 'rgba(148, 204, 161, 0.22)';
      return suggested
        ? 'rgba(148, 204, 161, 0.72)'
        : 'rgba(148, 204, 161, 0.22)';
    case 'red':
      return 'rgba(203, 52, 15, 0.15)';
    case 'yellow':
      return 'rgba(235, 211, 107, 0.15)';
    case 'blue':
      return suggested ? 'rgba(6,53,138, 0.90)' : 'rgba(123, 161, 229, 0.40)';
    case 'brown':
      return 'rgba(186, 156, 119, 0.45)';
    case 'abBlock':
      // return '#032d57';
      return 'rgba(3, 45, 87, 0.12)';

    default:
      return color;
  }
};

export const getRoadMapRGBColor = (
  color: string,
  suggested = false,
): string => {
  switch (color) {
    case 'white':
      return 'rgba(255,255,255)';
    case 'green':
      return '#2FB53B';
    case 'red':
      return '#FF0000';
    case 'yellow':
      return '#EBD36B';
    case 'blue':
      return suggested ? '#06348a' : '#7BA1E5';
    case 'brown':
      return '#808285';
    case 'abBlock':
      return '#032d57';
    default:
      return color;
  }
};

export const getRoadMapRGBFillColor = (
  color: string,
  suggested = false,
): string => {
  switch (color) {
    case 'white':
      return 'rgba(255,255,255,0.1)';
    case 'green':
      return suggested
        ? 'rgba(148, 204, 161, 0.72)'
        : 'rgba(216, 230, 217, 0.77)';
    // return suggested ? 'rgba(6,53,138, 0.90)' : 'rgba(123, 161, 229, 0.78)';
    case 'red':
      return 'rgba(203, 52, 15, 0.15)';
    case 'yellow':
      return 'rgba(235, 211, 107, 0.15)';
    case 'blue':
      return suggested ? 'rgba(6,53,138, 0.90)' : 'rgba(123, 161, 229, 0.78)';
    case 'brown':
      return 'rgba(234, 212, 189. 0.96)';
    case 'abBlock':
      return 'rgba(3, 45, 87, 0.12)';
    default:
      return color;
  }
};

export const isNumber = (value: string): boolean => {
  const re = /^[0-9\b]+$/;
  return re.test(value);
};

export const suggestedPolygon = 'suggested-green';

export const indicatorsOrder = [
  'Tractor Communication',
  'Tractor Drivability',
  'Tractor Automation',
  'Tractor Data',
  'Tractor Energy',
];

export const guardRailtypes = [
  '',
  'human_protection',
  'vehicle_protection',
  'pitch_protection',
  'roll_protection',
  'path_protection',
  'hitch_protection',
  'slip_protection',
  'pto_protection',
  'speed_protection',
];

export const isValidEmail = (value: string): boolean => {
  const regEmail =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regEmail.test(value);
};
export const betaCams = [
  { camera_name: 'front', title: 'Front' },
  { camera_name: 'front-right', title: 'Front Right' },
  { camera_name: 'front-left', title: 'Front Left' },
  { camera_name: 'rear', title: 'Rear' },
  { camera_name: 'rear-right', title: 'Rear Right' },
  { camera_name: 'rear-left', title: 'Rear Left' },
  { camera_name: 'pto', title: 'PTO' },
  { camera_name: 'driver-cam', title: 'Driver' },
];
export const pilotCams = [
  { camera_name: 'front', title: 'Front' },
  { camera_name: 'front-right', title: 'Front Right' },
  { camera_name: 'front-left', title: 'Front Left' },
  { camera_name: 'rear', title: 'Rear' },
  { camera_name: 'rear-right', title: 'Rear Right' },
  { camera_name: 'rear-left', title: 'Rear Left' },
  { camera_name: 'pto', title: 'PTO' },
  { camera_name: 'driver-cam', title: 'Driver' },
  { camera_name: 'chassis-left', title: 'Chassis Left' },
  { camera_name: 'chassis-right', title: 'Chassis Right' },
];

export const getXYCoordinates = (
  latlong: [number, number] | number[],
  base: [number, number],
): [number, number] => {
  const rEarth = 111319; // radius of earth is approximately 6378 kms (6378137 meters * pi/180)
  const newLatitude = latlong[1];
  const newLongitude = latlong[0];
  let y: any = rEarth * (newLatitude - base[0]);
  let x: any =
    (newLongitude - base[1]) * Math.cos(newLatitude * 0.01745) * rEarth;
  x = x * 100;
  y = y * 100;
  return [x, y];
};

export const getRAVXYCoordinates = (
  latlong: [number, number] | number[],
  base: [number, number],
): [number, number] => {
  const rEarth = 111319; // radius of earth is approximately 6378 kms (6378137 meters * pi/180)
  const newLatitude = latlong[1];
  const newLongitude = latlong[0];
  const y: any = rEarth * (newLatitude - base[0]);
  const x: any =
    (newLongitude - base[1]) * Math.cos(newLatitude * 0.01745) * rEarth;
  return [x, y];
};

export const getXYCoordinatesList = (
  list: any,
  base: [number, number],
): { x: number; y: number }[] => {
  const rEarth = 111319; // radius of earth is approximately 6378 kms (6378137 meters * pi/180)
  const prepare: any[] = [];
  list.forEach((item: any) => {
    const newLatitude = item[1];
    const newLongitude = item[0];
    let y = rEarth * (newLatitude - base[0]);
    let x = (newLongitude - base[1]) * Math.cos(newLatitude * 0.01745) * rEarth;
    x = x * 100;
    y = y * 100;
    prepare.push({ x, y });
  });
  return prepare;
};

export const convertBytesToGB = (bytes: number): number => {
  return Number((bytes / (1024 * 1024 * 1024)).toFixed(2));
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const degToCard = (deg: number): string => {
  if (deg > 11.25 && deg <= 33.75) {
    return 'NNE';
  }
  if (deg > 33.75 && deg <= 56.25) {
    return 'ENE';
  }
  if (deg > 56.25 && deg <= 78.75) {
    return 'E';
  }
  if (deg > 78.75 && deg <= 101.25) {
    return 'ESE';
  }
  if (deg > 101.25 && deg <= 123.75) {
    return 'ESE';
  }
  if (deg > 123.75 && deg <= 146.25) {
    return 'SE';
  }
  if (deg > 146.25 && deg <= 168.75) {
    return 'SSE';
  }
  if (deg > 168.75 && deg <= 191.25) {
    return 'S';
  }
  if (deg > 191.25 && deg <= 213.75) {
    return 'SSW';
  }
  if (deg > 213.75 && deg <= 236.25) {
    return 'SW';
  }
  if (deg > 236.25 && deg <= 258.75) {
    return 'WSW';
  }
  if (deg > 258.75 && deg <= 281.25) {
    return 'W';
  }
  if (deg > 281.25 && deg <= 303.75) {
    return 'WNW';
  }
  if (deg > 303.75 && deg <= 326.25) {
    return 'NW';
  }
  if (deg > 326.25 && deg <= 348.75) {
    return 'NNW';
  }
  return 'N';
};
export const priority: any = {
  TRACTOR: 12,
  BASESTATION: 11,
  ROUTINE: 10.1,
  PATH: 10,
  PREVIEWPATH: 9.5,
  SNAP: 10.5,
  INDICATOR: 10.5,
  WAYPOINT: 10.1,
  VINE: 8,
  VINEROW: 7,
  VINEBLOCK: 6,
  ABLANE: 7,
  ABBLOCK: 5.5,
  POLYGON: {
    blue: 5,
    green: 4,
    red: 3,
    yellow: 2,
    white: 1,
    brown: 1.5,
  },
};

export const vineBlockStyle = new Style({
  stroke: new Stroke({
    color: '#6a1287',
    width: 3,
  }),
});
export const vineRowStyle = [
  new Style({
    stroke: new Stroke({
      color: '#002B04',
      width: 9,
    }),
    zIndex: 0,
  }),
  new Style({
    stroke: new Stroke({
      color: '#3E7743',
      width: 7,
    }),
    zIndex: 1,
  }),
];
export const abLineStyle = [
  new Style({
    stroke: new Stroke({
      color: '#032d57',
      width: 9,
    }),
    zIndex: 0,
  }),
  new Style({
    stroke: new Stroke({
      color: '#0b6ea9',
      width: 7,
    }),
    zIndex: 1,
  }),
];

export const vineRowFromJAGStyle = [
  new Style({
    stroke: new Stroke({
      color: '#2596be',
      width: 9,
    }),
    zIndex: 0,
  }),
  new Style({
    stroke: new Stroke({
      color: '#2596be',
      width: 7,
    }),
    zIndex: 1,
  }),
];
export const getVineRowStyle = (setilight = true, zoom = false) => [
  new Style({
    stroke: new Stroke({
      color: setilight ? '#002B04' : '086E12',
      width: zoom ? 9 : 3,
    }),
    zIndex: 0,
  }),
  new Style({
    stroke: new Stroke({
      color: setilight ? '#3E7743' : '76A87A',
      width: zoom ? 7 : 1,
    }),
    zIndex: 1,
  }),
];

export const vineRowRoadMapStyle = [
  new Style({
    stroke: new Stroke({
      color: '#086E12',
      width: 9,
    }),
    zIndex: 0,
  }),
  new Style({
    stroke: new Stroke({
      color: '#76A87A',
      width: 7,
    }),
    zIndex: 1,
  }),
];

export const vineRowDisableStyle = new Style({
  stroke: new Stroke({
    color: '#0099ff',
    width: 2,
  }),
});

export const vineLaneStyle = new Style({
  stroke: new Stroke({
    // color: 'rgba(255, 255, 255, 0)',
    color: 'black',
    width: 0.1,
  }),
  fill: new Fill({
    color: 'rgba(180, 130, 130, 0.85)',
  }),
});

export const markStyle = new Style({
  image: new Icon({
    src: MarkIcon,
    size: [10, 10],
    // scale: 0.5,
    scale: 1,
  }),
});

export const vineRowHeadStyle = new Style({
  image: new Icon({
    src: VinerowHeadIcon,
    size: [10, 10],
    // scale: 0.5,
    scale: 1,
  }),
});

export const vineRowHoverStyle = (zoom: boolean) => [
  new Style({
    stroke: new Stroke({
      color: '#3E7743',
      width: zoom ? 9 : 3,
    }),
    zIndex: 0,
  }),
  new Style({
    stroke: new Stroke({
      color: '#51BA5A',
      width: zoom ? 7 : 1,
    }),
    zIndex: 1,
  }),
];

export const wayPointStyle = new Style({
  image: new Icon({
    src: WaypointIcon,
    size: [40, 40],
    scale: 0.5,
  }),
});

export const wayPointHoverStyle = (text: string, isSatilight: boolean) =>
  new Style({
    image: new Icon({
      src: WaypointIcon,
      size: [40, 40],
      scale: 0.5,
    }),
    text: new Text({
      text: text,
      scale: 0.7,
      fill: new Fill({
        color: isSatilight ? '#fff' : '#000',
      }),
      stroke: new Stroke({
        color: isSatilight ? '#000' : '#fff',
        width: 4,
      }),
      offsetX: 10,
      offsetY: 0,
      font: '20px Montserrat-ExtraBold',
      textAlign: 'left',
      rotation: 0,
    }),
  });

export const wayPointClusterHoverStyle = (size: number, hover = false) =>
  new Style({
    image: new Icon({
      src: hover ? waypointsClusterHover : waypointsCluster,
      scale: hover ? 0.6 : 0.5,
    }),
    text: new Text({
      text: size.toString(),
      scale: hover ? 1 : 0.8,
      fill: new Fill({
        color: '#fff',
      }),
      offsetX: 3,
      offsetY: hover ? -6 : -4,
    }),
  });

export const vineStyle = new Style({
  image: new Icon({
    src: VineIcon,
    size: [10, 10],
    scale: 0.5,
  }),
});

export const vineHoverStyle = new Style({
  image: new Icon({
    src: VineIcon,
    size: [10, 10],
  }),
});

export interface RecenterCommand {
  type: string;
  mapId: string;
}

export const secondsToHms = (d: any, shortForm = false) => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  let hDisplay = h > 0 ? h + (h == 1 ? ' hour ' : ' hours ') : '';
  let mDisplay = m > 0 ? m + (m == 1 ? ' minute ' : ' minutes ') : '';
  let sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
  if (shortForm) {
    hDisplay = h > 0 ? h + (h == 1 ? ' hr ' : ' hrs ') : '';
    mDisplay = m > 0 ? m + (m == 1 ? ' min ' : ' mins ') : '';
    sDisplay = s > 0 ? s + (s == 1 ? ' sec' : ' secs') : '';
  }

  if (h <= 0 && m <= 0) {
    return sDisplay;
  }
  if (h === 0 && m > 0) {
    return mDisplay;
  } else if (h > 0 && m > 0) {
    return `${hDisplay}, ${mDisplay}`;
  } else if (h == 0 && m > 0 && s > 0) {
    return `${mDisplay}, ${sDisplay}`;
  } else {
    return hDisplay;
  }
};

export const IsJsonString = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const tractorConstantsNew = {
  off: {
    key: 'off',
    value: 'Off',
    class: 't_off',
    image: 'ts_off',
    color: '#CECECF',
  },
  available: {
    key: 'available',
    value: 'Available',
    class: 't_available',
    image: 'ts_available',
    color: '#EC9322',
  },
  charging: {
    key: 'charging',
    value: 'Charging',
    class: 't_charging',
    image: 'ts_charging',
    color: '#738023',
  },
  standby: {
    key: 'standby',
    value: 'Manual',
    class: 't_manual',
    image: 'ts_manual',
    color: '#366376',
  },
  copycat: {
    key: 'copycat',
    value: 'Routines',
    class: 't_copycat',
    image: 'ts_routine',
    color: '#546CB2',
  },
  follow_me: {
    key: 'follow_me',
    value: 'Shadow',
    class: 't_follow_me',
    image: 'ts_shadow',
    color: '#366376',
  },
  manual: {
    key: 'manual',
    value: 'Manual',
    class: 't_manual',
    image: 'ts_manual',
    color: '#366376',
  },
  vinerow: {
    key: 'vinerow',
    value: 'Row Follow',
    class: 't_vinerow',
    image: 'ts_rowfollow',
    color: '#366376',
  },
  remote_av: {
    key: 'remote_av',
    value: 'Auto Drive',
    class: 't_remote_av',
    image: 'ts_autodrive',
    color: '#546CB2',
  },
  default: {
    key: 'default',
    value: 'Manual',
    class: 't_manual',
    image: 'ts_manual',
    color: '#366376',
  },
  faulty: {
    key: 'faulty',
    value: 'Error',
    class: 't_faulty',
    image: 'ts_faulty',
    color: '#D43D38',
  },
  service: {
    key: 'service',
    value: 'Service',
    class: 't_service',
    image: 'ts_service',
    color: '#CFCFCF',
  },
};
export const pathContantsNewbkp = {
  off: 'Off',
  available: 'Available',
  charging: 'Charging',
  standby: 'Standby',
  copycat: 'Routines',
  follow_me: 'Shadow Mode',
  manual: 'Manual',
  vinerow: 'Row Follow',
  remote_av: 'Live Command',
  default: 'Manual',
};
export const tractorStateNewConstants = {
  CHARGING: 'Charging',
  INUSE: 'In Use',
  AVAILABLE: 'Available',
  OFF: 'Off',
  // STANDBY: 'Standby',
  COPYCAT: 'Routines',
  FOLLOW_ME: 'Shadow Mode',
  MANUAL: 'Manual',
  VINEROW: 'Row Follow',
};

export const tractorStateConstants = {
  CHARGING: 'Charging',
  INUSE: 'In Use',
  AVAILABLE: 'Available',
  OFF: 'Off',
  FAULTY: 'Faulty',
};

export const getTractorStatusClass = (
  isAvailable: string,
  drive_action_created_at = null,
): string =>
  `fleetMsgTxt ${getStatusClass(isAvailable, drive_action_created_at)}`;

export const getOperationStatus = (heartBeat: TractorHeartBeat): string => {
  return getOperationNewStatus(heartBeat);
};

export const getOperationNewStatus = (heartBeat: TractorHeartBeat): string => {
  if (heartBeat) {
    const charge = getBatteryStatus(heartBeat);
    const battery = Math.trunc(
      Number(heartBeat?.tractor_model?.split(',')[mqttConstants.ENERGY]),
    );
    const n2Status = Boolean(
      Number(
        heartBeat?.system_model?.split(',')[mqttConstants.N2_POWER_STATUS],
      ),
    );
    const { lockout_details } = heartBeat;
    const status =
      lockout_details && lockout_details.is_tractor_lockout ? true : false;
    if (status) {
      return tractorConstantsNew.service.key;
    }
    if ((battery === -1 || !n2Status) && !heartBeat.is_simulator) {
      return tractorConstantsNew.faulty.key;
    }
    if (charge) {
      return tractorConstantsNew.charging.key;
    }
    return heartBeat.drive_action_details &&
      heartBeat.drive_action_details.current_drive_action
      ? heartBeat.planning_manager && heartBeat.planning_manager.planner_name
        ? heartBeat.planning_manager.planner_name
        : tractorConstantsNew.manual.key
      : tractorConstantsNew.available.key;
    // return heartBeat.drive_action_details &&
    // heartBeat.drive_action_details.current_drive_action
    // ? heartBeat.planning_manager && heartBeat.planning_manager.planner_name
    //   ? heartBeat.planning_manager.planner_name === 'standby'
    //     ? tractorContantsNew.manual.key
    //     : heartBeat.planning_manager.planner_name
    //   : tractorContantsNew.manual.key
    // : tractorContantsNew.available.key;
  }
  return tractorConstantsNew.off.key;
};

export const getBatteryPercentage = (tractor: any) =>
  (tractor &&
    tractor.heartbeat &&
    tractor.heartbeat.tractor_model &&
    tractor.heartbeat.tractor_model.length > 0 &&
    Math.trunc(
      Number(tractor.heartbeat.tractor_model.split(',')[mqttConstants.ENERGY]),
    )) ||
  '0';

export const getBatteryStatus = (heartbeat: any): number =>
  heartbeat &&
  heartbeat.tractor_model &&
  heartbeat.tractor_model.length > 0 &&
  Number(heartbeat?.tractor_model?.split(',')[mqttConstants.CHARGINGSTATUS]) ===
    1;

export const getInternetSpeed = (heartbeat: any): number =>
  heartbeat &&
  heartbeat.system_model &&
  heartbeat.system_model.length > 0 &&
  Math.round(
    Number(heartbeat?.system_model?.split(',')[mqttConstants.INTERNET_SPEED]),
  );

export const getInitials = (
  firstName: string | undefined,
  lastName: string | undefined,
): string => {
  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  }
  return '';
};

export const sortByKey = (arr: any[], key: string) =>
  arr.sort((a: any, b: any) => a[key].localeCompare(b[key]));

export const sortAOByKey = (data: any[], key: string) => {
  function compare(a: any, b: any) {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  }
  data.sort(compare);
  return data;
};

export const sortKeyWrapper = (url: string, sort: any): string => {
  if (sort && Object.keys(sort).length) {
    const sort_order = sort.order === 'descend' ? 'DESC' : 'ASC';
    const sort_column = sort.columnKey;
    url += `&sort_order=${sort_order}&sort_column=${sort_column}`;
  }
  return url;
};

export const fromToDateWrapper = (dates: any[]) => {
  let [from, to] = dates;
  from = moment(
    `${from.get('month') + 1}-${from.get('date')}-${from.get('year')}`,
    'MM-DD-YYYY',
  );
  to = moment(
    `${to.get('month') + 1}-${to.get('date')}-${to.get('year')}`,
    'MM-DD-YYYY',
  )
    .add(1, 'day')
    .subtract('1', 'second');
  return [from, to];
};

export const getConnectivityImg = (state: number, hover = false) => {
  let img =
    state === 0 || state === 1
      ? fullWifi
      : state === 2
      ? mediumWifi
      : state === 3
      ? lowWifi
      : lowWifi;
  if (hover)
    img =
      state === 0 || state === 1
        ? conv3
        : state === 2
        ? conv2
        : state === 3
        ? conv1
        : conv1;
  return img;
};

export const getConnectivityImg1 = (state: number, hover = false) => {
  // console.log(state, hover);
  let img =
    state === 0 || state === 1
      ? fullWifi
      : state === 2
      ? mediumWifi
      : state === 3
      ? lowWifi
      : lowWifi;
  if (hover)
    img =
      state === 0 || state === 1
        ? conv3
        : state === 2
        ? conv2
        : state === 3
        ? conv1
        : conv1;
  return img;
};

export const getNetworkTypeImg = (record: any, hover = false): string => {
  const { indicators: data } = record;
  const indicatorsData = wrapIndictorsData(data);
  const state = indicatorsData[constant.TRACTOR_COMMUNICATION];
  const content = record?.heartbeat?.system_model?.split(',');
  const type =
    content && content?.length >= 210
      ? content[mqttConstants.ACTIVE_NETWORk]
      : '';
  let img = '';
  if (type === 'cellular') {
    img =
      state === 0 || state === 1
        ? highCellular
        : state === 2
        ? mediumCellular
        : state === 3
        ? lowCellular
        : lowCellular;
  } else {
    img =
      state === 0 || state === 1
        ? fullWifi
        : state === 2
        ? mediumWifi
        : state === 3
        ? lowWifi
        : lowWifi;
  }

  if (hover)
    img =
      state === 0 || state === 1
        ? conv3
        : state === 2
        ? conv2
        : state === 3
        ? conv1
        : conv1;
  return img;
};

export const handleSelectedClass = (
  e: React.MouseEvent<HTMLElement, MouseEvent>,
) => {
  const items = document.getElementsByClassName('rowClassName1');
  const elements = Array.from(items);
  elements.map((item: any) => {
    item.className = item.className.replace(' row-selected', '');
  });
  e.currentTarget.className = `${e.currentTarget.className} row-selected`;
};

export const initClearSelection = () => {
  const elements = document.getElementsByClassName('row-selected');
  Array.from(elements).forEach(function (element) {
    element.className = element.className.replace(' row-selected', '');
  });
};
export const batteryValue = (tab: any): number => {
  return Math.trunc(
    Number(tab?.heartbeat?.tractor_model?.split(',')[mqttConstants.ENERGY]),
  ) > 0
    ? Math.trunc(
        Number(tab?.heartbeat?.tractor_model?.split(',')[mqttConstants.ENERGY]),
      )
    : 0;
};

export const batteryVal = (heartbeat: any): number => {
  return Math.trunc(
    Number(heartbeat?.tractor_model?.split(',')[mqttConstants.ENERGY]),
  );
};

export const secondsToHours = (timeInSeconds: number): string => {
  let hours: string | number = Math.floor(timeInSeconds / 3600);
  if (hours.toString().length === 1) {
    hours = `0${hours}`;
  }
  /**
   * Gets remaining seconds.
   * @function remainingSeconds
   * @returns the seconds.
   */
  const remainingSeconds = timeInSeconds % 3600;
  let minutes: string | number = Math.floor(remainingSeconds / 60);
  if (minutes.toString().length === 1) {
    minutes = `0${minutes}`;
  }
  if (hours || minutes) return `${hours}:${minutes}`;
  else return '-';
};

export const getTripMeterValue = (element: any): string => {
  let res = '0:00';
  const startTime =
    element?.heartbeat?.drive_action_details?.drive_action_start_time;
  const endTime = moment().valueOf();
  let min_difference;
  let hour_difference;
  if (startTime) {
    let difference = endTime - startTime;
    difference = Math.floor(difference / 1000);
    difference = Math.floor(difference / 60);
    min_difference = difference % 60;
    difference = Math.floor(difference / 60);
    hour_difference = difference % 24;
    res = `${
      hour_difference?.toString().length === 2
        ? hour_difference
        : hour_difference
    }`;
    res = `${res} : ${
      min_difference?.toString().length === 2
        ? min_difference
        : '0' + min_difference
    }`;
  }
  return res;
};

export const onlyNumbersAllow = (event: any) => {
  if (!/[0-9]/.test(event.key)) {
    return event.preventDefault();
  }
};

export const onlyNumberAndDecimalsAllow = (event: any) => {
  if (/[^0-9.{0,2}]/g.test(event.key)) {
    return event.preventDefault();
  }
};

export const onlyImplmentsNumberAndDecimalsAllow = (event: any) => {
  const regx = /^[+-]?\d+(\.\d+)?$/;
  if (regx.test(event)) {
    return event.preventDefault();
  }
};

export const isHaveAccess = (
  privilages: number[],
  privilage: number,
): boolean => {
  if (privilages && privilages.length > 0) {
    return privilages.includes(privilage);
  }
  return false;
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const getReleaseDate = (releaseDate: number): string =>
  releaseDate
    ? moment().diff(moment(releaseDate), 'hours') < 24
      ? 'today'
      : moment().diff(moment(releaseDate), 'hours') >= 24 &&
        moment().diff(moment(releaseDate), 'hours') <= 48
      ? 'yesterday'
      : moment(releaseDate).endOf('day').fromNow()
    : '';

export const wrapIndictorsData = (arr: any) => {
  const data = arr.reduce((a: any, c: any) => {
    a[c.indicator_key] = c.indicator_level;
    return a;
  }, {});
  return data;
};

export const polygonHoverLabel = (map: any): void => {
  let selected: any = null;
  map &&
    map.on('pointermove', function (e: any) {
      if (selected !== null && selected.get('type') === 'Polygon') {
        selected.getStyle()?.getText()?.setText('');
        selected.setStyle(selected.getStyle());
        selected = null;
      }
      map.forEachFeatureAtPixel(e.pixel, function (f: any) {
        selected = f;
        if (selected.get('type') === 'Polygon') {
          f.getStyle()?.getText()?.setText(f.get('label'));
          f.setStyle(f.getStyle());
        }
        return true;
      });
    });
};
export const abBlockHoverLabel = (map: any): void => {
  let selected: any = null;
  map &&
    map.on('pointermove', function (e: any) {
      if (selected !== null && selected.get('type') === 'ABBLOCK') {
        selected.getStyle()?.getText()?.setText('');
        selected.setStyle(selected.getStyle());
        selected = null;
      }
      map.forEachFeatureAtPixel(e.pixel, function (f: any) {
        selected = f;
        if (selected.get('type') === 'ABBLOCK') {
          f.getStyle()?.getText()?.setText(f.get('label'));
          f.setStyle(f.getStyle());
        }
        return true;
      });
    });
};

export const togglePolygonStyle = (
  polygonsLayerRef: any,
  toggleSatellite: boolean,
): void => {
  try {
    const features = polygonsLayerRef?.getSource()?.getFeatures();
    if (!toggleSatellite) {
      features?.map((feature: any) => {
        feature.setStyle(feature.get('roadMapPolyStyle'));
      });
    } else {
      features?.map((feature: any) => {
        feature.setStyle(feature.get('satelightPolyStyle'));
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const setPolygonStyle = (
  polygonsLayerRef: any,
  toggleSatellite: boolean,
): void => {
  const features = polygonsLayerRef?.current?.getSource().getFeatures();
  try {
    if (!toggleSatellite && features && features.length > 0) {
      features?.map((feature: any) => {
        feature.setStyle(feature.get('roadMapPolyStyle'));
      });
    } else {
      features?.map((feature: any) => {
        feature.setStyle(feature.get('satelightPolyStyle'));
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const toggleVineRowStyle = (
  vineRowsLayerRef: any,
  toggleSatellite: boolean,
  zoom: boolean,
): void => vineRowsLayerRef?.setStyle(getVineRowStyle(toggleSatellite, zoom));

export const displayRecenterViewPortSetUp = (
  feature: Feature<Geometry>,
  map: any,
  optional?: any,
): void => {
  if (feature && map) {
    const source = new VectorSource();
    if (optional) {
      source.addFeatures([feature, optional]);
    } else {
      source.addFeatures([feature]);
    }
    const view = map?.getView();
    view &&
      view?.fit(source.getExtent(), {
        constrainResolution: false,
        padding: [20, 20, 20, 20],
      });
  }
};

export const displayRecenterAllViewPortSetUp = (
  features: Feature<Geometry>[],
  map: any,
): void => {
  if (features && map) {
    const source = new VectorSource();
    source.addFeatures(features);
    const view = map?.getView();
    view &&
      view?.fit(source.getExtent(), {
        constrainResolution: false,
        padding: [20, 20, 20, 20],
      });
  }
};

export const getFullName = (record: any = {}): string => {
  const { first_name = '', last_name = '' } = record;
  let str = '';
  if (first_name) str += first_name;
  if (last_name) str += ` ${last_name}`;
  return str;
};

export const colors = ['#FBB313', '#44b3b7', '#EC9322'];
export const colors1 = ['#44b3b7', '#7adcda', '#EC9322'];

export const removeUnderScore = (data: string): string =>
  typeof data === 'string' ? data.replaceAll('_', ' ').toLowerCase() : '';

export const sliceIntoChunks = (arr: any, chunkSize: number) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const getRoadMapPolyStyle = (
  color: string,
  zIndex: number,
  suggested = false,
) => {
  const rgbColor = getRoadMapRGBColor(color, suggested);
  const rgbFillColor = getRoadMapRGBFillColor(color, suggested);
  return new Style({
    fill: new Fill({
      color: color === 'white' ? 'rgba(255,255,255,0.1)' : rgbFillColor,
    }),
    stroke: new Stroke({
      color: rgbColor,
      width: color === 'white' ? 4 : 3,
    }),
    text: new Text({
      font: '16px Montserrat-SemiBold',
      fill: new Fill({ color: '#000' }),
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
      text: '',
    }),
    zIndex,
  });
};

export const polyDrawStyle = [
  new Style({
    stroke: new Stroke({
      color: '#ffffff',
      width: 8,
    }),
    zIndex: 0,
  }),
  new Style({
    fill: new Fill({
      color: 'rgba(0,153,255,0.4)',
    }),
    stroke: new Stroke({
      color: '#0099ff',
      width: 6,
    }),
    zIndex: 1,
  }),
];

export const getSateLightPolyStyle = (
  color: string,
  zIndex: number,
  suggested = false,
) => {
  const rgbColor = getSatelightRGBColor(color, suggested);
  const rgbFillColor = getSatelightRGBFillColor(color, suggested);
  return new Style({
    fill: new Fill({
      color: color === 'white' ? 'rgba(255,255,255,0.1)' : rgbFillColor,
    }),
    stroke: new Stroke({
      color: rgbColor,
      width: color === 'white' ? 6 : 3,
    }),
    text: new Text({
      font: '16px Montserrat-SemiBold',
      fill: new Fill({ color: '#000' }),
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
      text: '',
    }),
    zIndex,
  });
};

export const pathConstants = {
  // Manual Driven
  MANUAL: 'manual',
  STANDBY: 'standby',
  ROW_FOLLOW: 'vine_row',

  // Autonomy Driven
  SHADOW_MODE: 'follow_me',
  ROUTINES: 'copycat',
  REMOTE_AV: 'remote_av',
  PREVIEW_PATH: 'preview_path',
  ROUTINE: 'routine',
};

export const pathColorConstants = {
  // MANUAL: '#00B7FF',
  // SHADOW_MODE: '#FFB76B',
  // ROW_FOLLOW: '#63F3FE',
  // ROUTINES: '#F36FC6',
  // STANDBY: '#C7C7C7',
  // DEFAULT: '#98DCE0',
  // REMOTE_AV: '#f19fd6',

  // MANUAL: '#11ADBC',
  // STANDBY: '#11ADBC',
  // ROW_FOLLOW: '#11ADBC',

  // SHADOW_MODE: '#713FB6',
  // ROUTINES: '#713FB6',
  // REMOTE_AV: '#713FB6',

  // DEFAULT: '#98DCE0',
  // Driven Path Fill #67AFB7 Stroke #366376
  // Driven Path Fill #67AFB7 Stroke #366376
  // Driven Path Fill #E9881F Stroke #7C5032
  // Path Ahead Fill #F1CE90 Stroke #E9881F
  MANUAL: '#67AFB7',
  STANDBY: '#67AFB7',
  DEFAULT: '#67AFB7',
  ROW_FOLLOW: '#67AFB7',
  SHADOW_MODE: '#67AFB7',

  ROUTINES: '#E9881F',
  REMOTE_AV: '#E9881F',
  PREVIEW_PATH: '#F1CE90',
  ROUTINE: '#EC9322',
};
export const pathBorderColorConstants = {
  // MANUAL: '#366376',
  // STANDBY: '#366376',
  // ROW_FOLLOW: '#366376',

  // SHADOW_MODE: '#414F79',
  // ROUTINES: '#414F79',
  // REMOTE_AV: '#414F79',

  // DEFAULT: '#366376',
  MANUAL: '#366376',
  STANDBY: '#366376',
  ROW_FOLLOW: '#366376',
  DEFAULT: '#366376',
  SHADOW_MODE: '#366376',

  ROUTINES: '#7C5032',
  REMOTE_AV: '#7C5032',
  PREVIEW_PATH: '#E9881F',
  ROUTINE: '#71462C',
};
export const getPathColor = (color: string): string => {
  switch (color) {
    case pathConstants.MANUAL:
      return pathColorConstants.MANUAL;
    case pathConstants.SHADOW_MODE:
      return pathColorConstants.SHADOW_MODE;
    case pathConstants.ROW_FOLLOW:
      return pathColorConstants.ROW_FOLLOW;
    case pathConstants.ROUTINES:
      return pathColorConstants.ROUTINES;
    case pathConstants.STANDBY:
      return pathColorConstants.STANDBY;
    case pathConstants.REMOTE_AV:
      return pathColorConstants.REMOTE_AV;
    case pathConstants.PREVIEW_PATH:
      return pathColorConstants.PREVIEW_PATH;
    case pathConstants.ROUTINE:
      return pathColorConstants.ROUTINE;

    default:
      return pathColorConstants.DEFAULT;
  }
};

export const getPathBorderColor = (color: string): string => {
  switch (color) {
    case pathConstants.MANUAL:
      return pathBorderColorConstants.MANUAL;
    case pathConstants.SHADOW_MODE:
      return pathBorderColorConstants.SHADOW_MODE;
    case pathConstants.ROW_FOLLOW:
      return pathBorderColorConstants.ROW_FOLLOW;
    case pathConstants.ROUTINES:
      return pathBorderColorConstants.ROUTINES;
    case pathConstants.STANDBY:
      return pathBorderColorConstants.STANDBY;
    case pathConstants.REMOTE_AV:
      return pathBorderColorConstants.REMOTE_AV;
    case pathConstants.PREVIEW_PATH:
      return pathBorderColorConstants.PREVIEW_PATH;
    case pathConstants.ROUTINE:
      return pathBorderColorConstants.ROUTINE;

    default:
      return pathBorderColorConstants.DEFAULT;
  }
};

export const convertXyToLatLang = (
  data: [number, number][],
  groundLat: number,
  groundLang: number,
): [number, number][] => {
  const rEarth = 111319; // radius of earth is approximately 6378 kms (6378137 meters * pi/180)
  const finalList: [number, number][] = [];
  data.forEach((element: [number, number]) => {
    const deltaLat = element[1];
    const deltaLon = element[0];
    element[0] = groundLat + deltaLat / rEarth;
    element[1] =
      groundLang + deltaLon / rEarth / Math.cos(element[0] * 0.01745);
    finalList.push([element[1], element[0]]);
  });
  return finalList;
};

export const sortCameraViews = (
  arr: any[],
  orderkey: string,
  type = 'images',
) => {
  const dataset: any = {
    images: [
      'front',
      'rear',
      'pto',
      'driver',
      'front-left',
      'rear-left',
      'front-right',
      'rear-right',
    ],
    videos: [
      'FrontCamera',
      'RearCamera',
      'PTOCamera',
      'driver',
      'front_left',
      'rear_left',
      'front_right',
      'rear_right',
    ],
  };
  const orderData = dataset[type].reduce((a: any, c: any, i: number) => {
    const index = arr.findIndex(
      (ar: any) => ar[orderkey].replaceAll(' ', '') == c,
    );
    if (index !== -1) {
      if (type === 'videos') {
        arr[index][orderkey] = dataset['images'][i];
      }
      a.push(arr[index]);
    }
    return a;
  }, []);
  return orderData;
};

export const styleFun = (feature: any, icon?: string) => {
  const size = feature.get('features').length;
  let style = new Style({});
  if (size === 1) {
    const feat = feature.get('features');
    style = feat[0].get('style');
    feature.set('name', feat[0].get('name'));
    feature.set('pointType', feat[0].get('pointType'));
    feature.set('content', feat[0].get('content'));
    feature.set('hoverStyle', feat[0].get('hoverStyle'));
  }
  if (size > 1) {
    const feat = feature.get('features');
    let names = '';
    feat.map((fe: any) => {
      names = names + fe.get('name') + ', ';
    });
    feature.set('name', names);
    let image: any = new CircleStyle({
      radius: 10,
      stroke: new Stroke({
        color: '#fff',
      }),
      fill: new Fill({
        color: '#3399CC',
      }),
    });
    let text = new Text({
      text: size.toString(),
      fill: new Fill({
        color: '#fff',
      }),
    });
    if (icon) {
      image = new Icon({
        src: icon,
        scale: 0.7,
      });

      text = new Text({
        text: size.toString(),
        fill: new Fill({
          color: '#fff',
        }),
        offsetX: 4,
        offsetY: -5,
      });
    }
    style = new Style({
      image,
      text,
    });
  }
  return style;
};

export const pathContants = {
  standby: { key: 'standby', value: 'Standby' },
  copycat: { key: 'copycat', value: 'Routines' },
  follow_me: { key: 'follow_me', value: 'Shadow Mode' },
  manual: { key: 'manual', value: 'Manual' },
  vinerow: { key: 'vinerow', value: 'Row Follow' },
  remoteav: { key: 'remote_av', value: 'Remote AV' },
};

export const formatCameraText = (text: string): string =>
  typeof text === 'string' ? text.trim().replace('_', '-').toUpperCase() : '';

export const getFleetUsagePointsInfo = (pointType: string, data: any) => {
  let dataStr = '';
  if (pointType == 'INDICATOR') {
    dataStr +=
      '<p>Indicator Name: <span>' + data.indicator_name + '</span></p>';
    dataStr += '<p> Level: <span>' + data.indicator_level + '</span></p>';
    dataStr += '<p> Error Code: <span>' + data.error_code + '</span></p>';
    dataStr += '<p> Description: <span>' + data.description + '</span></p>';
    // dataStr +=
    //   '<p> Acknowledge: <span>' +
    //   (data.is_acknowledged ? 'Yes' : 'No') +
    //   '</span></p>';
    dataStr +=
      '<p> Time: <span>' +
      (data.created_date_time ? getDateTime(data.created_date_time) : '') +
      '</span></p>';
    dataStr +=
      '<p> Resolved Time: <span>' +
      (data.resolved_date_time ? getDateTime(data.resolved_date_time) : '') +
      '</span></p>';
  } else if (pointType == 'Ticket') {
    dataStr += '<p>Ticket ID: <span>' + data.ticket_id + '</span></p>';
    dataStr += '<p> Status: <span>' + data.status + '</span></p>';
    dataStr += '<p> Level: <span>' + data.ticket_level + '</span></p>';
    dataStr +=
      '<p> Start Time: <span>' +
      (data.created_date_time ? getDateTime(data.created_date_time) : '') +
      '</span></p>';
    dataStr +=
      '<p> Resolved Time: <span>' +
      (data.resolved_date_time ? getDateTime(data.completed_date_time) : '') +
      '</span></p>';

    dataStr +=
      '<p> Created By: <span>' +
      (data.created_by_user
        ? data.created_by_user.first_name + ' ' + data.created_by_user.last_name
        : '') +
      '</span></p>';

    dataStr +=
      '<p> Completed By: <span>' +
      (data.completed_by_user
        ? data.completed_by_user.first_name +
          ' ' +
          data.completed_by_user.last_name
        : '') +
      '</span></p>';
  } else if (pointType == 'JAG_START_STOP') {
    dataStr +=
      '<p> JumpAndGo: <span>' +
      (data.jAGStatus === 'Start' ? 'Start' : 'End') +
      '</span></p>';
    dataStr += '<p>Tractor : <span>' + data.tractor?.name + '</span></p>';
    dataStr +=
      '<p> Operator: <span>' +
      (data.operator
        ? data.operator?.first_name + ' ' + data.operator?.last_name
        : '') +
      '</span></p>';
    dataStr +=
      '<p> Start Time: <span>' +
      (data.drive_action_finish_date_time
        ? getDateTime(data.drive_action_start_date_time)
        : '') +
      '</span></p>';
    dataStr +=
      '<p> End Time: <span>' +
      (data.drive_action_finish_date_time
        ? getDateTime(data.drive_action_finish_date_time)
        : '') +
      '</span></p>';
  } else if (pointType == 'SNAP') {
    const { created_at, created_by, guard_rail_type, created_date_time } = data;
    if (created_at === 'GUARD_RAILS') {
      dataStr += '<p>Tractor : <span>' + data.tractor_id?.name + '</span></p>';
      dataStr +=
        '<p> Creator: <span>' + getFullName(created_by) + '</span></p>';
      dataStr += '<p> Creation Type: <span>' + guard_rail_type + '</span></p>';
      dataStr +=
        '<p> Created at: <span>' +
        (created_date_time ? getDateTime(created_date_time) : '') +
        '</span></p>';
    }
  }
  return dataStr;
};

export const getFleetUsagePointsPathInfo = (data: any) => {
  const planner_name =
    data && data.planner_name && getStatusForTractor(data.planner_name)
      ? getStatusForTractor(data.planner_name)
      : '';
  let dataStr = '';
  dataStr += '<p>Tractor : <span>' + data.tractor_name + '</span></p>';
  dataStr += '<p>Operator : <span>' + data.operator_name + '</span></p>';
  dataStr += '<p>Mode : <span>' + planner_name + '</span></p>';
  // dataStr +=
  //   '<p>Low Localization: <span>' +
  //   String(data.accuracy >= 0.15) +
  //   ' ' +
  //   '</span></p>';
  dataStr +=
    '<p> Speed : <span>' +
    (data.avg_speed
      ? `${Number(Number(data.avg_speed) * 2.237).toFixed(1)} Mph`
      : '-') +
    ' </span></p>';
  dataStr +=
    '<p> Time : <span>' +
    (data.created_date_time ? getDateTime(data.created_date_time) : '') +
    '</span></p>';
  return dataStr;
};

export const IndicatorStyle = (count: number, hover = false) => {
  if (count === 1) {
    return new Style({
      image: new Icon({
        src: FleetIndicator,
        scale: hover ? 0.8 : 0.7,
        anchor: [0.5, 37],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
      }),
      zIndex: 100,
    });
  } else {
    return new Style({
      image: new Icon({
        src: hover ? errorCodeClusterHover : errorCodeCluster,
        scale: hover ? 0.9 : 0.8,
        anchor: [0.25, 57],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
      }),
      text: new Text({
        text: count.toString(),
        fill: new Fill({
          color: '#fff',
        }),
        offsetX: hover ? 18 : 17,
        offsetY: hover ? -32 : -29,
      }),
    });
  }
};

export const tractorClusterStyle = (
  count: number,
  hover = false,
  onZoom = false,
) => {
  return new Style({
    image: new Icon({
      src: hover ? tractorsClusterHover : tractorsCluster,
      scale: onZoom ? 1.5 * 0.4 : 0.4,
    }),
    text: new Text({
      text: count.toString(),
      scale: onZoom ? 1.5 * 1.4 : 1.4,
      fill: new Fill({
        color: '#fff',
      }),
    }),
  });
};

export const TractorIconContsant = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  LOWLOCALIZATION: 'LOWLOCALIZATION',
  CHARGING: 'CHARGING',
};
export const TractorIconImages: any = {
  ONLINE: NewTractorIcon,
  OFFLINE: tractor_offline,
  OFFLINE_HOVER: tractor_offline_hover,
  OFFLINE_SELECTED: tractor_offline_selected,
  LOWLOCALIZATION: LowLocalizationNewTractorIcon,
  CHARGING: charging_normal,
  CHARGING_HOVER: charging_hover,
  TRACTOR_LOCK_NORMAL: tractor_lock_normal,
  TRACTOR_LOCK_HOVER: tractor_lock_hover,
  TRACTOR__NORMAL: tractor__normal,
  TRACTOR__HOVER: tractor__hover,
  SELECTED_TRACTOR_NOUSER: selected_tractor_nouser,
  SELECTED_TRACTOR_USER: selected_tractor_user,
  SELECTED_TRACTOR_CHARGING: selected_tractor_charging,
};
export const getTractorIcon = (
  type: any,
  onHover = false,
  knownBearing = 0,
  onText = '',
  isSelected = false,
  moving = false,
  onZoom = false,
  betteryValue = 0,
  lowLocalization = false,
  tractorName = '',
  pointTypeIsSelectedForToolTip = false,
  tractorId = 1,
  isFaulty = false,
) => {
  const onZoomConst = 1.5;

  // image section
  let image: string = TractorIconImages[type]
    ? TractorIconImages[type]
    : TractorIconImages.OFFLINE;
  // charging on hover
  if (type === TractorIconContsant.CHARGING && onHover) {
    image = TractorIconImages.CHARGING_HOVER;
  }
  // tractor online and user not available available
  if (type === TractorIconContsant.ONLINE) {
    image = TractorIconImages.TRACTOR__NORMAL;
    if (onHover) {
      image = TractorIconImages.TRACTOR__HOVER;
    }
    if (isSelected) {
      image = TractorIconImages.SELECTED_TRACTOR_NOUSER;
    }
  }
  // tractor online and user available
  if (type === TractorIconContsant.ONLINE && onText && onText.length) {
    image = TractorIconImages.TRACTOR_LOCK_NORMAL;
    if (onHover) {
      image = TractorIconImages.TRACTOR_LOCK_HOVER;
    }
    if (isSelected) {
      image = TractorIconImages.SELECTED_TRACTOR_USER;
    }
  }
  if (type === TractorIconContsant.CHARGING && isSelected) {
    image = TractorIconImages.SELECTED_TRACTOR_CHARGING;
  }
  // tractor online and user not available available
  if (type === TractorIconContsant.OFFLINE) {
    image = TractorIconImages.OFFLINE;
    if (onHover) {
      image = TractorIconImages.OFFLINE_HOVER;
    }
    if (isSelected) {
      image = TractorIconImages.OFFLINE_SELECTED;
    }
  }

  // image scaling
  let imagesScale = type === TractorIconContsant.CHARGING ? 0.41 : 1;
  if (
    type === TractorIconContsant.ONLINE ||
    type === TractorIconContsant.OFFLINE
  ) {
    imagesScale = 0.43;
  }
  // text scaling
  const textScale = type === TractorIconContsant.CHARGING ? 0.5 : 0.7;
  let text = onText && onText.length > 0 ? onText : '';
  text = type === TractorIconContsant.CHARGING ? betteryValue + '%' : onText;

  // offset y
  let offsetY = type === TractorIconContsant.CHARGING ? 5 : 0;
  offsetY = type === TractorIconContsant.ONLINE ? 7 : offsetY;
  //on selected tractor on top with z index
  const addOnZIndex = isSelected ? tractorId + 100 : tractorId;

  const style = new Style({
    image: new Icon({
      src: image,
      scale: onZoom ? onZoomConst * imagesScale : imagesScale,
    }),
    text: new Text({
      text,
      scale: onZoom ? 1.3 * textScale : textScale,
      fill: new Fill({
        color: isSelected ? '#fff' : '#000',
      }),
      font: '14px Montserrat-SemiBold',
      offsetY,
    }),
    zIndex: addOnZIndex * 999,
  });

  let chargeStyle = null;
  if (betteryValue > 0 && (isSelected || onHover)) {
    let backGroundStroke = '#fff';
    // low soc less than 15
    backGroundStroke = betteryValue > 15 ? backGroundStroke : '#64451C';
    // critical soc less than 5
    backGroundStroke = betteryValue > 5 ? backGroundStroke : '#660E18';
    let frontStroke = '#58b847';
    // low soc less than 15
    frontStroke = betteryValue > 15 ? frontStroke : '#F7CB18';
    // critical soc less than 5
    frontStroke = betteryValue > 5 ? frontStroke : '#EC1E27';
    const chargeImage = `<svg
          width="74.4" height="74.4" viewBox="0 0 74.4 74.4"
          className="circular-chart orange"
          fill="transparent"
          version="1.1" xmlns="http://www.w3.org/2000/svg"
        >
        <path
        stroke-dasharray="100, 100"
        d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        stroke-width="3"
        stroke="${backGroundStroke}"
      />
          <path
          stroke-dasharray="${betteryValue.toString()}, 100"
          d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          stroke="${frontStroke}" 
          stroke-width="3"
        />
        </svg>`;
    chargeStyle = new Style({
      image: new Icon({
        src:
          'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(chargeImage),
        scale: onZoom ? onZoomConst * 0.915 : 0.915,
        anchor: [0.245, 0.243],
      }),
      zIndex: addOnZIndex * 999,
    });
  }

  let direction = null;
  // on hover show direction icon except charging state and offline
  if (
    type !== TractorIconContsant.OFFLINE &&
    type !== TractorIconContsant.CHARGING &&
    (onHover || isSelected || moving)
  ) {
    let directionIcon = DirectionIcon;
    let anchor = [0.5, 2.7];
    anchor = onHover ? [0.6, 2.9] : anchor;
    anchor = isSelected ? [0.6, 2.9] : anchor;
    if (type === TractorIconContsant.ONLINE && onText && onText.length) {
      directionIcon = heading_flash;
      anchor = [0.5, 1.5];
    }
    direction = new Style({
      image: new Icon({
        src: directionIcon,
        scale: onZoom ? onZoomConst * 0.4 : 0.4,
        anchor,
        rotation: ((90 - knownBearing) * Math.PI) / 180,
      }),
      zIndex: 5,
    });
  }

  let isFaultyStyle = null;
  if (isFaulty && type !== TractorIconContsant.OFFLINE) {
    const anchor = [-0.3, 1.5];
    isFaultyStyle = new Style({
      image: new Icon({
        src: faultyTractorIcon,
        scale: onZoom ? onZoomConst * 0.1 : 0.1,
        anchor,
      }),
      zIndex: addOnZIndex * 1000,
    });
  }

  let lowLocalizationStyle1 = null;
  let lowLocalizationStyle2 = null;
  // if not faulty show low localization
  if (lowLocalization && !isFaulty) {
    const anchor = [-0.3, 1.5];
    lowLocalizationStyle1 = new Style({
      image: new Icon({
        src: redIcon,
        scale: onZoom ? onZoomConst * 0.45 : 0.45,
        anchor,
      }),
      zIndex: addOnZIndex * 999,
    });
    lowLocalizationStyle2 = new Style({
      image: new Icon({
        src: tractor_bg_shadow,
        scale: onZoom ? onZoomConst * 0.5 : 0.5,
      }),
      zIndex: addOnZIndex * 998,
    });
  }
  // on hover,select and moving
  let tooltipStyle = null;
  if (
    // (onHover || isSelected || moving || pointTypeIsSelectedForToolTip) &&
    (onHover || isSelected || pointTypeIsSelectedForToolTip) &&
    tractorName &&
    tractorName.length > 0
  ) {
    tooltipStyle = new Style({
      image: new Icon({
        src:
          tractorName.length > 5
            ? tractorName.length > 15
              ? tooltip_icon_xl
              : tooltip_icon_long
            : tooltip_icon_medium,
        scale: onZoom ? onZoomConst * 0.5 : 0.5,
        anchor: [tractorName.length > 15 ? -0.1 : -0.15, 1.2],
      }),
      text: new Text({
        text:
          tractorName.length > 25
            ? `${tractorName.substring(0, 24)}...`
            : tractorName,
        scale: onZoom ? 1.3 * 1 : 1,
        fill: new Fill({
          color: '#000',
        }),
        font: '6px Montserrat-SemiBold',
        offsetX: onZoom
          ? tractorName.length > 5
            ? tractorName.length > 15
              ? 110
              : 74
            : 50
          : tractorName.length > 5
          ? tractorName.length > 15
            ? 74
            : 48
          : 35,
        offsetY: onZoom ? -28 : -18,
      }),
      zIndex: addOnZIndex * 999,
    });
  }

  const st = [style];
  if (lowLocalizationStyle2) {
    st.push(lowLocalizationStyle2);
  }

  if (chargeStyle) {
    st.push(chargeStyle);
  }
  if (direction) {
    st.push(direction);
  }
  if (lowLocalizationStyle1) {
    st.push(lowLocalizationStyle1);
  }
  if (isFaultyStyle) {
    st.push(isFaultyStyle);
  }
  if (tooltipStyle) {
    st.push(tooltipStyle);
  }

  return st;
};

export const sortAlphaNumber = (data: any, key = 'name') => {
  const newMap = data.reduce((a: any, c: any) => {
    if (a.get(c[key])) {
      a.set(c[key], [...a.get(c[key]), c]);
    } else {
      a.set(c[key], [c]);
    }
    return a;
  }, new Map());
  const res = Object.fromEntries(newMap);
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });
  const result = Object.keys(res)
    .sort(collator.compare)
    .reduce((a: any[], c: string) => {
      a.push(...newMap.get(c));
      return a;
    }, []);
  return [...result];
};

export const sortNumber = (data: any, key = 'id') => {
  const newMap = data.reduce((a: any, c: any) => {
    if (a.get(c[key])) {
      a.set(c[key], [...a.get(c[key]), c]);
    } else {
      a.set(c[key], [c]);
    }
    return a;
  }, new Map());
  const res = Object.fromEntries(newMap);
  const result = Object.keys(res)
    .sort()
    .reduce((a: any[], c: string) => {
      a.push(...res[c]);
      return a;
    }, []);
  return [...result];
};

export const tractorSort = (records: any, key = 'name') => {
  records = sortAlphaNumber(records, key);
  return tractorStatusSort(records);
};

export const tractorStatusSort = (list: any[]) => {
  const temp = list.reduce(
    (acc: any, item: any) => {
      acc[item.isAvailable].push(item);
      return acc;
    },
    {
      [tractorConstantsNew.standby.key]: [],
      [tractorConstantsNew.manual.key]: [],
      [tractorConstantsNew.vinerow.key]: [],
      [tractorConstantsNew.follow_me.key]: [],
      [tractorConstantsNew.copycat.key]: [],
      [tractorConstantsNew.remote_av.key]: [],
      [tractorConstantsNew.available.key]: [],
      [tractorConstantsNew.service.key]: [],
      [tractorConstantsNew.charging.key]: [],
      [tractorConstantsNew.faulty.key]: [],
      [tractorConstantsNew.off.key]: [],
    },
  );
  list = [
    ...temp[tractorConstantsNew.standby.key],
    ...temp[tractorConstantsNew.manual.key],
    ...temp[tractorConstantsNew.vinerow.key],
    ...temp[tractorConstantsNew.follow_me.key],
    ...temp[tractorConstantsNew.copycat.key],
    ...temp[tractorConstantsNew.remote_av.key],
    ...temp[tractorConstantsNew.available.key],
    ...temp[tractorConstantsNew.service.key],
    ...temp[tractorConstantsNew.charging.key],
    ...temp[tractorConstantsNew.faulty.key],
    ...temp[tractorConstantsNew.off.key],
  ];
  return list;
};

// export const tractorStatusSort = (list: any[]) => {
//   const temp = list.reduce(
//     (acc: any, item: any) => {
//       acc[item.isAvailable].push(item);
//       return acc;
//     },
//     {
//       Available: [],
//       Off: [],
//       'In Use': [],
//       Charging: [],
//       Faulty: [],
//       Offline: [],
//     },
//   );
//   list = [
//     ...temp['In Use'],
//     ...temp.Available,
//     ...temp.Charging,
//     ...temp.Faulty,
//     ...temp.Off,
//     ...temp.Offline,
//   ];
//   return list;
// };

export const getChargingText = (hour: number, minute: number): string => {
  if (hour === 0 && minute === 0) {
    return `Fully Charged`;
  }

  const CalenderTime = moment()
    .add(hour, 'hours')
    .add(minute, 'minutes')
    .calendar();
  return `Full charge by ${CalenderTime}`;
};

export const getTimeLeftToCharge = (
  charging: number,
): {
  hour: number;
  minute: number;
} => {
  let hour = 0;
  let minute = 0;
  if (charging) {
    const time = charging.toFixed(2).toString().split('.');
    if (time[0]) {
      hour = Number(time[0]);
    }

    if (time[1]) {
      minute = (Number(time[1]) / 100) * 60;
    }
  }
  return {
    hour,
    minute,
  };
};

export const durationData = [
  ...Array.from({ length: 12 }, (_, i) =>
    Object.assign({ label: `${5 * i} mins`, value: i * 5 * 60 }),
  ),
  ...Array.from({ length: 24 }, (_, i) =>
    Object.assign({}, { label: `${i} hours`, value: i * 60 * 60 }),
  ),
].filter((item) => item.value !== 0);

export const calculateDistance = (points: any[]): number => {
  let [lat1, lon1] = points[0];
  let [lat2, lon2] = points[1];
  // The math module contains a function
  // named toRadians which converts from
  // degrees to radians.
  lon1 = (lon1 * Math.PI) / 180;
  lon2 = (lon2 * Math.PI) / 180;
  lat1 = (lat1 * Math.PI) / 180;
  lat2 = (lat2 * Math.PI) / 180;

  // Haversine formula
  const dlon = lon2 - lon1;
  const dlat = lat2 - lat1;
  const a =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

  const c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth in kilometers. Use 3956
  // for miles
  const r = 6371;

  // calculate the result
  return c * r * 0.621371;
};

export const calPtsDistance = (paths: any): number => {
  let distance = 0;
  if (paths.length > 1) {
    const points = [paths[0], paths[paths.length - 1]].map((item) => [
      item.latitude,
      item.longitude,
    ]);
    distance = calculateDistance(points) * 5280;
  }
  return distance;
};

export const calPtsDistanceActualAllow = (paths: any): boolean => {
  let distance = 0;
  if (paths.length > 1) {
    const points = [paths[0], paths[paths.length - 1]].map((item) => [
      item.latitude,
      item.longitude,
    ]);
    distance = calculateDistance(points);
  }
  return distance * 5258 < distanceForGoodPoints ? true : false;
};

export const isSelectedTractor = (
  selectedTractors: any,
  tractorId: number,
): boolean => {
  let isSelected = false;
  if (selectedTractors && selectedTractors.length > 0) {
    isSelected = selectedTractors.some((selectedTractor: any) => {
      if (selectedTractor.id == tractorId) {
        return true;
      }
    });
  }
  return isSelected;
};

export const getStatusClass = (
  isAvailable: string,
  drive_action_created_at = null,
): string => {
  let text = '';
  if (drive_action_created_at && drive_action_created_at === 'ATOM') {
    isAvailable =
      isAvailable === tractorConstantsNew.standby.key ||
      isAvailable === tractorConstantsNew.manual.key
        ? tractorConstantsNew.remote_av.key
        : isAvailable;
  }

  switch (isAvailable) {
    case tractorConstantsNew.off.key:
      text = tractorConstantsNew.off.class;
      break;
    case tractorConstantsNew.charging.key:
      text = tractorConstantsNew.charging.class;
      break;
    case tractorConstantsNew.available.key:
      text = tractorConstantsNew.available.class;
      break;
    case tractorConstantsNew.manual.key:
      text = tractorConstantsNew.manual.class;
      break;
    case tractorConstantsNew.standby.key:
      text = tractorConstantsNew.standby.class;
      break;
    case tractorConstantsNew.copycat.key:
      text = tractorConstantsNew.copycat.class;
      break;
    case tractorConstantsNew.follow_me.key:
      text = tractorConstantsNew.follow_me.class;
      break;
    case tractorConstantsNew.vinerow.key:
      text = tractorConstantsNew.vinerow.class;
      break;
    case tractorConstantsNew.remote_av.key:
      text = tractorConstantsNew.remote_av.class;
      break;
    case tractorConstantsNew.faulty.key:
      text = tractorConstantsNew.faulty.class;
      break;
    case tractorConstantsNew.service.key:
      text = tractorConstantsNew.service.class;
      break;
    default:
      text = tractorConstantsNew.off.class;
      break;
  }
  return text;
};

export const getOperatorNameByHeartBeat = (heartbeat: any): string => {
  let text = '';
  if (heartbeat && heartbeat.drive_action_details) {
    const { operator_firstname, operator_lastname } =
      heartbeat.drive_action_details;
    if (operator_firstname) text += operator_firstname;
    if (operator_lastname) text += ' ' + operator_lastname;
  }
  return text;
};

export const togglePathStyle = (pathLayerRef: any, zoom: boolean): void => {
  try {
    const features = pathLayerRef?.getSource()?.getFeatures();
    features &&
      features.length > 0 &&
      features?.map((feature: any) => {
        if (
          feature.get('pointType') === 'PATH' ||
          feature.get('pointType') === 'LIVEPATH' ||
          feature.get('pointType') === pathConstants.PREVIEW_PATH
        ) {
          feature.setStyle(
            getPathStyle(
              feature.get('color'),
              feature.get('borderColor'),
              zoom,
            ),
          );
        } else if (feature.get('pointType') === 'startPoint') {
          feature.setStyle(
            getCenterStyle(
              feature.get('color'),
              feature.get('borderColor'),
              zoom,
            ),
          );
        }
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const getCenterStyle = (
  color: string,
  borderColor: string,
  zoom = false,
) =>
  new Style({
    image: new Circle({
      radius: zoom ? 12 : 6,
      fill: new Fill({
        color,
      }),
      stroke: new Stroke({
        color: borderColor,
      }),
    }),
    zIndex: 501,
  });

export const getPathFeatureStyle = (planner: string, zoom = false) => {
  const color = getPathColor(planner);
  const borderColor = getPathBorderColor(planner);
  return getPathStyle(color, borderColor, zoom);
};
export const getPathStyle = (
  color: string,
  borderColor: string,
  zoom = false,
) => [
  new Style({
    stroke: new Stroke({
      color: borderColor,
      width: zoom ? 10 : 4,
    }),
    zIndex: 500,
  }),
  new Style({
    stroke: new Stroke({
      color,
      width: zoom ? 9 : 3,
    }),
    zIndex: 501,
  }),
];

export const getChargingTextRemaining = (
  heartBeat: TractorHeartBeat,
): string => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');
    const time = data[mqttConstants.CHARGINGTIME];
    const timeToCharge = getTimeLeftToCharge(Number(time));
    if (timeToCharge.hour !== 0 || timeToCharge.minute !== 0)
      return `${getChargingText(timeToCharge.hour, timeToCharge.minute)}`;
    else return `${getChargingText(timeToCharge.hour, timeToCharge.minute)}`;
  }
  return '';
};

export const getRpm = (
  heartbeat: TractorHeartBeat | undefined,
  tractor_model = '',
): string => {
  if (heartbeat && heartbeat.tractor_model) {
    const data = heartbeat.tractor_model.split(',');
    if (data && data.length > 0) {
      let rpm = Number(data[mqttConstants.RPM]);
      const tractorModel =
        tractor_model && tractor_model.length > 0
          ? tractor_model.toLowerCase()
          : '';
      // if beta
      if (tractorModel === 'beta') {
        rpm = rpm / 8;
        return rpm.toFixed();
      } else if (tractorModel === 'pilot') {
        rpm = rpm / 10.36;
        return rpm.toFixed();
      } else {
        rpm = rpm / 8.46;
        return rpm.toFixed();
      }
    }
  }
  return '0';
};

export const getHeight = (heartBeat: TractorHeartBeat | undefined): number => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');
    if (data && data.length) {
      return Number(
        (
          (Number(data[mqttConstants.LEFTHITCH]) +
            Number(data[mqttConstants.RIGHTHITCH])) /
          2
        ).toFixed(),
      );
    }
  }
  return 0;
};

export const getFNR = (heartBeat: TractorHeartBeat | undefined): string => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');
    if (data && data.length > 0) {
      const fnr = Number(data[mqttConstants.FNR]);
      if (fnr === 1) {
        return 'F';
      } else if (fnr === 2) {
        return 'R';
      }
    }
  }
  return 'N';
};

export const getPTO = (heartBeat: TractorHeartBeat | undefined): string => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');
    if (data && data.length > 0) {
      const pto = Boolean(data[mqttConstants.PTOSTATE]);
      if (pto) {
        return 'ON';
      }
    }
  }
  return 'OFF';
};

export const getPTOState = (
  heartBeat: TractorHeartBeat | undefined,
): boolean => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');

    if (data && data.length > 0) {
      return Boolean(data[mqttConstants.PTOSTATE] === `1` ? true : false);
    }
  }
  return false;
};

export const getSpeed = (heartbeat: TractorHeartBeat | undefined): number =>
  heartbeat && heartbeat?.localization && heartbeat?.localization?.velocity
    ? Number(
        (Number(heartbeat?.localization?.velocity) * 2.237)
          .toFixed(1)
          .replaceAll('-', ''),
      )
    : 0;

export const getGear = (heartBeat: TractorHeartBeat | undefined): number => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');
    if (data && data.length > mqttConstants.GEAR) {
      return Number(data[mqttConstants.GEAR]);
    }
  }
  return 0;
};

export const getGearRange = (gear: number): string => {
  if ([1, 2, 3, 10].includes(gear)) {
    return 'L';
  }
  if ([4, 5, 6, 11].includes(gear)) {
    return 'M';
  }
  if ([7, 8, 9, 12].includes(gear)) {
    return 'H';
  }

  return '';
};

export const getGearRangeNumber = (gear: number) => {
  if ([1, 4, 7, 10].includes(gear)) {
    return 1;
  }
  if ([2, 5, 8, 11].includes(gear)) {
    return 2;
  }
  if ([3, 6, 9, 12].includes(gear)) {
    return 3;
  }
  return '-';
};
export const getFloatText = (
  heartBeat: TractorHeartBeat | undefined,
): string => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');
    if (data && data.length) {
      const hookUpMode = Number(data[mqttConstants.HOOKUPCONTROL]);
      if (hookUpMode === 0) {
        return 'POSITION';
      } else if (hookUpMode === 1) {
        return 'CLASSIC';
      } else if (hookUpMode === 2) {
        return 'FLOAT';
      } else if (hookUpMode === 3) {
        return 'MANUAL ADJUST';
      }
    }
  }
  return '';
};
export const getFloatImage = (
  heartBeat: TractorHeartBeat | undefined,
  planner_name: string,
): string => {
  if (heartBeat && heartBeat.tractor_model) {
    const data = heartBeat.tractor_model.split(',');
    if (data && data.length) {
      const hookUpMode = Number(data[mqttConstants.HOOKUPCONTROL]);
      if (hookUpMode === 0) {
        return positionModeIcon(planner_name);
      } else if (hookUpMode === 1) {
        return floatMode1Icon(planner_name);
      } else if (hookUpMode === 2) {
        return ClassicIconIcon(planner_name);
      } else if (hookUpMode === 3) {
        return manualIconIcon(planner_name);
      }
    }
  }
  return '';
};
export const getStatusForTractor = (
  planner_name: string,
  drive_action_created_at = null,
): string => {
  let text = '';
  planner_name =
    planner_name && planner_name.toLowerCase()
      ? planner_name.toLowerCase()
      : '';
  if (drive_action_created_at && drive_action_created_at === 'ATOM') {
    planner_name =
      planner_name === tractorConstantsNew.standby.key ||
      planner_name === tractorConstantsNew.manual.key
        ? tractorConstantsNew.remote_av.key
        : planner_name;
  }

  switch (planner_name) {
    case tractorConstantsNew.off.key:
      text = tractorConstantsNew.off.value;
      break;
    case tractorConstantsNew.available.key:
      text = tractorConstantsNew.available.value;
      break;
    case tractorConstantsNew.charging.key:
      text = tractorConstantsNew.charging.value;
      break;
    case tractorConstantsNew.manual.key:
      text = tractorConstantsNew.manual.value;
      break;
    case tractorConstantsNew.standby.key:
      text = tractorConstantsNew.standby.value;
      break;
    case tractorConstantsNew.copycat.key:
      text = tractorConstantsNew.copycat.value;
      break;
    case tractorConstantsNew.follow_me.key:
      text = tractorConstantsNew.follow_me.value;
      break;
    case tractorConstantsNew.vinerow.key:
      text = tractorConstantsNew.vinerow.value;
      break;
    case tractorConstantsNew.remote_av.key:
      text = tractorConstantsNew.remote_av.value;
      break;
    case tractorConstantsNew.faulty.key:
      text = tractorConstantsNew.faulty.value;
      break;
    case tractorConstantsNew.service.key:
      text = tractorConstantsNew.service.value;
      break;
    default:
      text = '';
      break;
  }
  return text;
};
export const geTractorStatusIcon = (planner_name: string): string => {
  let text = '';
  switch (planner_name) {
    case tractorConstantsNew.off.key:
      text = tractorConstantsNew.off.image;
      break;
    case tractorConstantsNew.available.key:
      text = tractorConstantsNew.available.image;
      break;
    case tractorConstantsNew.charging.key:
      text = tractorConstantsNew.charging.image;
      break;
    case tractorConstantsNew.manual.key:
      text = tractorConstantsNew.manual.image;
      break;
    case tractorConstantsNew.standby.key:
      text = tractorConstantsNew.standby.image;
      break;
    case tractorConstantsNew.copycat.key:
      text = tractorConstantsNew.copycat.image;
      break;
    case tractorConstantsNew.follow_me.key:
      text = tractorConstantsNew.follow_me.image;
      break;
    case tractorConstantsNew.vinerow.key:
      text = tractorConstantsNew.vinerow.image;
      break;
    case tractorConstantsNew.remote_av.key:
      text = tractorConstantsNew.remote_av.image;
      break;
    case tractorConstantsNew.faulty.key:
      text = tractorConstantsNew.faulty.image;
      break;
    case tractorConstantsNew.service.key:
      text = tractorConstantsNew.service.image;
      break;
    default:
      text = tractorConstantsNew.manual.image;
      break;
  }
  return text;
};
export const geTractorStatusColor = (planner_name: string): string => {
  let text = '';
  switch (planner_name) {
    case tractorConstantsNew.off.key:
      text = tractorConstantsNew.off.color;
      break;
    case tractorConstantsNew.available.key:
      text = tractorConstantsNew.available.color;
      break;
    case tractorConstantsNew.charging.key:
      text = tractorConstantsNew.charging.color;
      break;
    case tractorConstantsNew.manual.key:
      text = tractorConstantsNew.manual.color;
      break;
    case tractorConstantsNew.standby.key:
      text = tractorConstantsNew.standby.color;
      break;
    case tractorConstantsNew.copycat.key:
      text = tractorConstantsNew.copycat.color;
      break;
    case tractorConstantsNew.follow_me.key:
      text = tractorConstantsNew.follow_me.color;
      break;
    case tractorConstantsNew.vinerow.key:
      text = tractorConstantsNew.vinerow.color;
      break;
    case tractorConstantsNew.remote_av.key:
      text = tractorConstantsNew.remote_av.color;
      break;
    case tractorConstantsNew.faulty.key:
      text = tractorConstantsNew.faulty.color;
      break;
    case tractorConstantsNew.service.key:
      text = tractorConstantsNew.service.color;
      break;
    default:
      text = tractorConstantsNew.manual.color;
      break;
  }
  return text;
};

export const positionModeIcon = (planner_name: string): string => {
  // const color = geTractorStatusColor(planner_name);
  const color = '#8595ca';
  return `<svg xmlns="http://www.w3.org/2000/svg" width="41.82" height="38.545" viewBox="0 0 41.82 38.545">
  <g id="Group_179" data-name="Group 179" transform="translate(-508.035 -491.885)">
    <g id="Group_176" data-name="Group 176">
      <g id="Group_175" data-name="Group 175">
        <g id="Group_172" data-name="Group 172">
          <g id="Group_171" data-name="Group 171">
            <path id="Path_164" data-name="Path 164" d="M515.316,509.5a1.787,1.787,0,1,0-.047,2.526A1.778,1.778,0,0,0,515.316,509.5Zm0,0a1.787,1.787,0,1,0-.047,2.526A1.778,1.778,0,0,0,515.316,509.5Zm0,0a1.787,1.787,0,1,0-.047,2.526A1.778,1.778,0,0,0,515.316,509.5Zm0,0a1.787,1.787,0,1,0-.047,2.526A1.778,1.778,0,0,0,515.316,509.5Zm0,0a1.787,1.787,0,1,0-.047,2.526A1.778,1.778,0,0,0,515.316,509.5Z" fill="${color}" />
          </g>
        </g>
        <g id="Group_174" data-name="Group 174">
          <g id="Group_173" data-name="Group 173">
            <path id="Path_165" data-name="Path 165" d="M549.855,511.43a3.208,3.208,0,0,0-2.97-3.248L513.5,505.756a5.094,5.094,0,1,0-.164,10.171l8.038-.326,20.754-.843,4.653-.179a3.25,3.25,0,0,0,2.093-.9A3.2,3.2,0,0,0,549.855,511.43Zm-38.214,1.638a3.274,3.274,0,1,1,4.629.068A3.272,3.272,0,0,1,511.641,513.068Zm31.986-.409a1.966,1.966,0,1,1,2.78.052A1.962,1.962,0,0,1,543.627,512.659Z" fill="${color}" />
          </g>
        </g>
      </g>
    </g>
    <g id="Group_177" data-name="Group 177">
      <path id="Path_166" data-name="Path 166" d="M538.3,519.754l4.955,6.133h-2.676v4.543h-4.557v-4.543h-2.669Z" fill="${color}" />
    </g>
    <g id="Group_178" data-name="Group 178">
      <path id="Path_167" data-name="Path 167" d="M538.3,502.561l-4.955-6.133h2.676v-4.543h4.557v4.543h2.669Z" fill="${color}" />
    </g>
  </g>
</svg>`;
};
export const floatMode1Icon = (planner_name: string): string => {
  // const color = geTractorStatusColor(planner_name);
  const color = '#8595ca';
  return `<svg xmlns="http://www.w3.org/2000/svg" width="49.732" height="31.867" viewBox="0 0 49.732 31.867">
  <g id="Group_170" data-name="Group 170" transform="translate(-145.272 -496.096)">
    <g id="Group_165" data-name="Group 165">
      <g id="Group_164" data-name="Group 164">
        <g id="Group_161" data-name="Group 161">
          <g id="Group_160" data-name="Group 160">
            <path id="Path_160" data-name="Path 160" d="M152.683,509.636a1.819,1.819,0,1,0-.048,2.572A1.811,1.811,0,0,0,152.683,509.636Zm0,0a1.819,1.819,0,1,0-.048,2.572A1.811,1.811,0,0,0,152.683,509.636Zm0,0a1.819,1.819,0,1,0-.048,2.572A1.811,1.811,0,0,0,152.683,509.636Zm0,0a1.819,1.819,0,1,0-.048,2.572A1.811,1.811,0,0,0,152.683,509.636Zm0,0a1.819,1.819,0,1,0-.048,2.572A1.811,1.811,0,0,0,152.683,509.636Z" fill="${color}" />
          </g>
        </g>
        <g id="Group_163" data-name="Group 163">
          <g id="Group_162" data-name="Group 162">
            <path id="Path_161" data-name="Path 161" d="M187.836,511.6a3.264,3.264,0,0,0-3.023-3.3l-33.978-2.47a5.185,5.185,0,1,0-.166,10.352l8.181-.332,21.123-.858,4.735-.182a3.307,3.307,0,0,0,2.13-.92A3.261,3.261,0,0,0,187.836,511.6Zm-38.894,1.667a3.332,3.332,0,1,1,4.712.07A3.331,3.331,0,0,1,148.942,513.27Zm32.555-.416a2,2,0,1,1,2.83.053A2,2,0,0,1,181.5,512.854Z" fill="${color}" />
          </g>
        </g>
      </g>
    </g>
    <g id="Group_167" data-name="Group 167">
      <g id="Group_166" data-name="Group 166">
        <path id="Path_162" data-name="Path 162" d="M191.051,527.963l-6.125-.454,3.449-5.07.813,1.691c3.294-2.183,3.469-6.757,3.474-6.98l2.342.057c-.01.292-.207,6.272-4.788,9.041Z" fill="${color}" />
      </g>
    </g>
    <g id="Group_169" data-name="Group 169">
      <g id="Group_168" data-name="Group 168"> 
        <path id="Path_163" data-name="Path 163" d="M191.051,496.1l-6.125.454,3.449,5.07.813-1.691c3.294,2.184,3.469,6.757,3.474,6.98l2.342-.056c-.01-.293-.207-6.273-4.788-9.042Z" fill="${color}" />
      </g>
    </g>
  </g>
</svg>
`;
};

export const ClassicIconIcon = (planner_name: string): string => {
  // const color = geTractorStatusColor(planner_name);
  const color = '#8595ca';
  return `<svg xmlns="http://www.w3.org/2000/svg" width="33.143" height="13.388" viewBox="0 0 33.143 13.388">
  <g transform="translate(-287.284 -5364.381)">
  <path d="M292.222,5373.4a1.212,1.212,0,1,0-.032,1.714A1.206,1.206,0,0,0,292.222,5373.4Zm0,0a1.212,1.212,0,1,0-.032,1.714A1.206,1.206,0,0,0,292.222,5373.4Zm0,0a1.212,1.212,0,1,0-.032,1.714A1.206,1.206,0,0,0,292.222,5373.4Zm0,0a1.212,1.212,0,1,0-.032,1.714A1.206,1.206,0,0,0,292.222,5373.4Zm0,0a1.212,1.212,0,1,0-.032,1.714A1.206,1.206,0,0,0,292.222,5373.4Z" fill="${color}" />
  <path d="M315.65,5374.716a2.174,2.174,0,0,0-2.014-2.2l-22.645-1.646a3.456,3.456,0,1,0-.111,6.9l5.452-.221,14.078-.572,3.156-.121a2.2,2.2,0,0,0,1.419-.613A2.167,2.167,0,0,0,315.65,5374.716Zm-25.92,1.111a2.221,2.221,0,1,1,3.14.046A2.219,2.219,0,0,1,289.73,5375.827Zm21.7-.277a1.334,1.334,0,1,1,1.886.034A1.331,1.331,0,0,1,311.426,5375.55Z" fill="${color}" />
  <path d="M317.793,5364.381l-4.082.3,2.3,3.378.542-1.127c2.2,1.456,2.312,4.5,2.315,4.652l1.56-.037c-.006-.195-.138-4.181-3.19-6.026Z" fill="${color}" />
  </g></svg>`;
};
export const manualIconIcon = (planner_name: string): string => {
  // const color = geTractorStatusColor(planner_name);
  const color = '#8595ca';
  return `<svg xmlns="http://www.w3.org/2000/svg" width="30.052" height="20.492" viewBox="0 0 30.052 20.492">
  <g transform="translate(-210.177 -5489.908)" fill="${color}" >
  <path d="M234.006,5504.245a1.125,1.125,0,0,1,.41.618l.4,1.577a.452.452,0,0,1-.325.549l-1.315.338a.453.453,0,0,1-.55-.324l-.4-1.577a1.113,1.113,0,0,1,.061-.739,1.129,1.129,0,0,1,1.719-.442Z"/><path d="M219.654,5505.106l-.494-2.744-6.079.979a3.56,3.56,0,0,0-2.8,4.332l.005.02a3.556,3.556,0,0,0,4.636,2.5l7.283-2.638A3.878,3.878,0,0,1,219.654,5505.106Zm-4.428,2.5a1.68,1.68,0,1,1-.518-2.324A1.69,1.69,0,0,1,215.226,5507.6Z" fill="${color}" />
  <path d="M222.99,5507.524l2.4.261.441,1.713a.452.452,0,0,1-.325.55l-1.314.338a.452.452,0,0,1-.55-.325Z" fill="${color}" />
  <path d="M230.97,5504.2l.959,3.729a.452.452,0,0,1-.325.55l-1.316.338a.452.452,0,0,1-.549-.324l-.959-3.729a1.131,1.131,0,0,1,2.19-.564Z" fill="${color}" />
  <path d="M226.092,5507.158l-.294-.032h0l-.294-.031h0l-.293-.031-1.516-.165-.885-.1-.3-.031-.066-.008a2.307,2.307,0,0,1-.235-.038,2.454,2.454,0,0,1-1.876-1.79l-.956-3.72a.974.974,0,0,1,.2-.872l2.327-2.76-1.972-7.671,9.562,0,1.458,6.173a1.158,1.158,0,0,0,.949.856,1.122,1.122,0,0,1,.833.77l.352,1.389-5.465,1.052a5,5,0,0,0,.5-3.353l-.818.185.079.684a3.443,3.443,0,0,1-2.158,3.595l-2.492.984.134,1.538,1.018.5.449.218.469.229v0l.3.144c.011.006.023.011.035.018a1.014,1.014,0,0,1,.1.056,1.792,1.792,0,0,1,.151.1,2.059,2.059,0,0,1,.824,2.078l-.011.049Z"/><path d="M237.706,5498.826l-10.939,2.2a5.869,5.869,0,0,1-1.287.882l-2.006.794.056.626,1.874.916c.011,0,.018.012.029.014a1.66,1.66,0,0,1,.922-.615,1.693,1.693,0,0,1,1.834.711,1.691,1.691,0,0,1,1.407-1.535,1.747,1.747,0,0,1,1.937,1.331l.151.583a1.678,1.678,0,0,1,1.216-1.222,1.7,1.7,0,0,1,1.737.572l4.225-1.25a2.09,2.09,0,0,0-1.156-4.006Zm.845,2.586a.933.933,0,0,1-1.269.285.923.923,0,0,1,.987-1.56A.93.93,0,0,1,238.551,5501.412Z"/><path d="M227.872,5505l.958,3.729a.452.452,0,0,1-.324.55l-1.316.338a.453.453,0,0,1-.55-.325l-.362-1.409.482.052.125-.627.011-.048a2.753,2.753,0,0,0-.99-2.7,1.129,1.129,0,0,1,1.966.438Z" fill="${color}" />
  </g></svg>`;
};
// export const tractorListCamerasLsit = [],
export const getCameraViewAllow = (
  heartBeat: TractorHeartBeat | undefined,
  camera: string,
  status: string,
): boolean => {
  // Ignore Work Bench Tractors
  if (heartBeat?.tractor_serial_number.includes('MTBENCH')) {
    return true;
  }

  // if offline dont allow to click
  if (status === tractorConstantsNew.off.key) {
    return false;
  }
  if (heartBeat && heartBeat.system_model) {
    const data = heartBeat.system_model.split(',').map((item) => Number(item));
    if (data && data.length) {
      const XAVIER1_POWER_STATUS = data[mqttConstants.XAVIER1_POWER_STATUS]
        ? true
        : false; // front left, fronT right
      const XAVIER1_SYSTEM_NODE = data[mqttConstants.XAVIER1_SYSTEM_NODE]
        ? true
        : false; // front left, fronT right
      const XAVIER2_POWER_STATUS = data[mqttConstants.XAVIER2_POWER_STATUS]
        ? true
        : false; // front, rear left , rear right
      const XAVIER2_SYSTEM_NODE = data[mqttConstants.XAVIER2_SYSTEM_NODE]
        ? true
        : false; // front, rear left , rear right
      const XAVIER3_POWER_STATUS = data[mqttConstants.XAVIER3_POWER_STATUS]
        ? true
        : false; // front
      const XAVIER3_SYSTEM_NODE = data[mqttConstants.XAVIER3_SYSTEM_NODE]
        ? true
        : false; // front
      const XAVIER6_POWER_STATUS = data[mqttConstants.XAVIER6_POWER_STATUS]
        ? true
        : false; // rear and pto

      const XAVIER6_SYSTEM_NODE = data[mqttConstants.XAVIER6_SYSTEM_NODE]
        ? true
        : false; // rear and pto
      if (camera === tractorCameras.FRONT) {
        // return XAVIER2_POWER_STATUS && XAVIER2_SYSTEM_NODE ? true : false;
        return XAVIER3_POWER_STATUS && XAVIER3_SYSTEM_NODE ? true : false;
      }
      if (camera === tractorCameras.REAR || camera === tractorCameras.PTO) {
        return XAVIER6_POWER_STATUS && XAVIER6_SYSTEM_NODE ? true : false;
      }
      if (
        camera === tractorCameras.FRONT_LEFT ||
        camera === tractorCameras.FRONT_RIGHT
      ) {
        return XAVIER1_POWER_STATUS && XAVIER1_SYSTEM_NODE ? true : false;
      }
    }
  }
  return false;
};
export const tractorCameras = {
  FRONT: 'front',
  REAR: 'rear',
  PTO: 'pto',
  FRONT_LEFT: 'front_left',
  FRONT_RIGHT: 'front_right',
};
export const tractorCamerasLabelsFromLiveMap: any = {
  front: 'Front',
  rear: 'Rear',
  pto: 'Hitch',
  front_left: 'Left',
  front_right: 'Right',
  rear_right: 'Rear Right',
  rear_left: 'Rear Left',
};

export const tractorCamerasLabels: any = {
  front: 'Front',
  rear: 'Rear',
  pto: 'PTO',
  front_left: 'Front Left',
  front_right: 'Front Right',
  rear_right: 'Rear Right',
  rear_left: 'Rear Left',
};

export const setBadLocalization = (
  coordinates: Coordinate,
  showBadLocalizationIcon = true,
) => {
  const st = new Style({
    image: new Icon({
      src: badLoc2,
      scale: 0.7,
      anchor: [0.5, 37],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
    }),
    zIndex: Infinity,
  });
  const startingPathPoint = new Feature({
    geometry: new Point(coordinates),
    name: 'bad localization',
    pointType: BadLocalization,
    style: showBadLocalizationIcon ? st : new Style({}),
  });

  startingPathPoint.setStyle(showBadLocalizationIcon ? st : new Style({}));
  return startingPathPoint;
};

export const formatFleetAnalyticsData = (data: any) =>
  Object.keys(data).reduce((a: any, item: string) => {
    const tdata = { distance: 0, hours: 0 };
    if (data[item]) {
      if (!Object.keys(data[item]).includes('hours')) {
        const t = Object.keys(data[item]).reduce(
          (ac, it) => {
            ac.distance += isNaN(data[item][it].distance)
              ? data[item][it].distance
              : 0;
            ac.hours += isNaN(data[item][it].hours) ? data[item][it].hours : 0;
            return ac;
          },
          { distance: 0, hours: 0 },
        );
        a[item] = t;
      } else {
        tdata.distance += isNaN(data[item].distance) ? data[item].distance : 0;
        tdata.hours += isNaN(data[item].hours) ? data[item].hours : 0;
        a[item] = tdata;
      }
    }

    return a;
  }, {});

export function getRandomColor(): string {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const tractorOffInHours = (tractor: any) =>
  Math.round(
    moment
      .duration(moment().diff(moment(tractor?.last_offline_date_time)))
      .asHours(),
  );

export const commands = {
  GOTO_POINT: 'GOTO_POINT',
  ROW_FOLLOW: 'ROW_FOLLOW',
  ROW_FOLLOW_SECOND: 'ROW_FOLLOW_SECOND',
  ROUTINE: 'ROUTINE',
};

export const getNameFromList = (list: MapFeature[], id: number): string => {
  let name = '';
  list &&
    list.length &&
    list.forEach((feature: MapFeature) => {
      if (feature.id && id && feature.id === id) {
        name = feature.name;
      }
    });
  return name;
};

export const cameras = {
  FRONT: 'FRONT',
  REAR: 'REAR',
  HITCH: 'HITCH',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

export const GOTO_POINT_IMPLEMENT_CTRL = 'goto_point_implement_ctrl';
export const VINE_BLOCK_IMPLEMENT_CTRL = 'vine_block_implement_ctrl';
export const LIVECOMMAND = 'LiveCommand';

export const prepareCoordinates = (vertices: any, base: any): Coordinate[] => {
  const coordinates: Coordinate[] = [];
  if (vertices && vertices.length > 0) {
    vertices.map((point: any) => {
      if (point.latitude !== null && point.longitude !== null) {
        coordinates.push(proj.fromLonLat([point.longitude, point.latitude]));
      } else {
        point.xNew = point.x / 100;
        point.yNew = point.y / 100;
        const latAndLong: [number, number] = getLatitudeAndLongitude(
          [point.yNew, point.xNew],
          base,
          true,
        );
        coordinates.push(proj.fromLonLat(latAndLong));
      }
    });
  }
  return coordinates;
};

export const drawPoly = async (
  userDetails: UserDetails,
  base: any,
  layer: any,
  globalPolyUnique: number,
  localPolyUnique: number,
  poly: any,
  suggested = false,
  isApi = false,
) => {
  let polygonFeature = null;
  let polygonItem: MapFeature | null = null;
  let vertices = [];
  const id = `polygon_${poly.id}`;
  const { color }: { color: string } = poly;
  const { organization } = userDetails;
  try {
    let coordinates: any[] = [];
    if (!isApi) {
      if (!suggested) {
        coordinates = await getPolygonData(
          organization.api_url,
          organization.farm.id,
          poly.polygon_uuid,
        );
      } else {
        coordinates = await getBrownPolygonData(
          organization.api_url,
          organization.farm.id,
          poly.polygon_uuid,
          poly.color,
        );
      }
    } else {
      coordinates = poly.vertices;
    }
    vertices = coordinates;
    coordinates = prepareCoordinates(coordinates, base);
    let satelightPolyStyle = null;
    if (coordinates && coordinates.length > 0) {
      const zIndex = suggested
        ? priority.POLYGON['suggestedGreen']
        : priority.POLYGON[color];
      polygonFeature = new Feature({
        type: 'Polygon',
        pointType: 'Polygon',
        geometry: new Polygon([coordinates]),
        zIndex,
        polygon_uuid: poly.polygon_uuid,
        color: suggested ? suggestedPolygon : color,
        id: poly.id,
        remarks: poly.remarks,
        poly,
        label: poly.name,
        polyName: poly.name,
      });
      const area = getAreaOfPolygon(polygonFeature);
      if (area > 0) {
        // calcualted area in acre and final value rounded
        polygonFeature.set(
          'label',
          poly?.name + ' ' + Math.round(area) + ' ' + 'acre',
        );
      }
      polygonFeature.setId(id);
      satelightPolyStyle = getSateLightPolyStyle(color, zIndex, suggested);
      const roadMapPolyStyle = getRoadMapPolyStyle(color, zIndex, suggested);
      polygonFeature.setStyle(satelightPolyStyle);
      polygonFeature.set('satelightPolyStyle', satelightPolyStyle);
      polygonFeature.set('roadMapPolyStyle', roadMapPolyStyle);
      globalPolyUnique === localPolyUnique &&
        layer &&
        layer?.getSource().addFeature(polygonFeature);
    }
    polygonItem = {
      id: poly.id,
      name: poly.name,
      type: 'POLYGON',
      mapId: id,
      visible: true,
      style: satelightPolyStyle ? satelightPolyStyle : null,
      color: poly.color,
      polygon_uuid: poly.polygon_uuid,
      vertices,
      suggested: suggested ? true : false,
      remarks: poly?.remarks,
    };
    return { polygonFeature, polygonItem };
  } catch (error: any) {
    notification.error({
      message: error.message,
    });
    return { polygonFeature, polygonItem };
  }
};

export const prepareDrawPoly = (records: any, base: any, suggested = false) => {
  const polygonFeatures: any[] = [];
  const polygonsList: any[] = [];
  let fieldFeature: any[] = [];

  records.map((poly: any) => {
    const id = `polygon_${poly.id}`;
    const { color }: { color: string } = poly;
    let coordinates = prepareCoordinates(poly.vertices, base);
    coordinates = coordinates && coordinates.length > 0 ? coordinates : [];
    let satelightPolyStyle = null;
    if (coordinates && coordinates.length > 0) {
      const zIndex = suggested
        ? priority.POLYGON['suggestedGreen']
        : priority.POLYGON[color];
      // const zIndex = poly.id;
      const mapPolygon = new Feature({
        type: 'Polygon',
        pointType: 'Polygon',
        geometry: new Polygon([coordinates]),
        zIndex,
        polygon_uuid: poly.polygon_uuid,
        color: suggested ? suggestedPolygon : color,
        id: poly.id,
        // name: poly.name,
        remarks: poly.remarks,
        poly,
        label: poly.name,
      });
      mapPolygon.setId(id);
      satelightPolyStyle = getSateLightPolyStyle(color, zIndex, suggested);
      const roadMapPolyStyle = getRoadMapPolyStyle(color, zIndex, suggested);
      mapPolygon.setStyle(satelightPolyStyle);
      mapPolygon.set('satelightPolyStyle', satelightPolyStyle);
      mapPolygon.set('roadMapPolyStyle', roadMapPolyStyle);
      if (color === 'white') {
        fieldFeature = [mapPolygon];
      }
      polygonFeatures.push(mapPolygon);
    }

    polygonsList.push({
      id: poly.id,
      name: poly.name,
      type: 'POLYGON',
      mapId: id,
      visible: true,
      style: satelightPolyStyle ? satelightPolyStyle : null,
      color: poly.color,
      polygon_uuid: poly.polygon_uuid,
    });
  });
  return { polygonFeatures, polygonsList, fieldFeature };
};

export const getAreaOfPolygon = (polygonFeature: any): number => {
  if (polygonFeature) {
    const geo: any = polygonFeature?.getGeometry();
    const area = getArea(geo);
    let output;
    if (area > 0) {
      output = Math.round(area * 100) / 100;
      output = output / 4046.85642;
      return Number(output.toFixed(2));
    }
  }
  return 0;
};

export const mapMakerwWypointStyle = new Style({
  image: new Icon({
    src: WaypointIcon,
    size: [40, 40],
    // anchor: [0.17, 0.44],
    anchor: [0.42, 0.68],
    anchorOrigin: 'center',
    displacement: [0, 0],
    scale: 0.5,
  }),
});

export const mapmakerVineStyle = new Style({
  image: new Icon({
    src: VineIcon,
    size: [10, 10],
    scale: 0.7,
  }),
});

export const tractorStyle = new Style({
  image: new Icon({
    src: tractorIcon,
    scale: 0.5,
    size: [78, 78],
  }),
  text: new Text({
    text: '',
    scale: 1.0,
    fill: new Fill({
      color: '#2F3F8F',
    }),
    font: ' bold 8px Montserrat-Bold',
  }),
});

export const tractorDirectionStyle = new Style({
  image: new Icon({
    src: arrowPoint,
    scale: 0.6,
    anchor: [0.5, 2.7],
    rotation: ((1 - 1) * Math.PI) / 180,
  }),
  zIndex: 5,
});

export const isEmpty = (value: any) => {
  if (Array.isArray(value)) return !value.length;
  return !Object.keys(value).length;
};

export const calTotal = (data: any | undefined) => {
  const state: boolean = data && Object.keys(data).length;
  const total: number = state
    ? Object.values(data).reduce((a: number, c) => (a += Number(c)), 0)
    : 0;
  return { state, total };
};

export const calPercentage = (item: number, total: number) => {
  const num = (Number(item) / Number(total)) * 100;
  const d = ((num + Number.EPSILON) * 100) / 100;
  return Math.round(d);
};

export const diff_minutes = (dt2: number, dt1: number): number => {
  let diff =
    (moment(dt2).toDate().getTime() - moment(dt1).toDate().getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};

export const shuffle = (array: any[]) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

// =======
export const LS_RAV_COMMANDS_KEY = 'remoteAV_commands';
export const LS_ROUTINE = 'routineObj';
export const getLocalStorageData = (key: string, isJson: boolean) => {
  let lsItem: any = localStorage.getItem(key);
  if (isJson && lsItem) {
    lsItem = JSON.parse(lsItem);
  }
  return lsItem ? lsItem : null;
};

export const tractorConnected = (
  hearbeat: TractorHeartBeat,
  userId: number,
): boolean => {
  return hearbeat &&
    hearbeat.drive_action_details &&
    hearbeat.drive_action_details.current_operator_id &&
    hearbeat?.drive_action_details?.current_operator_id === userId &&
    hearbeat?.drive_action_details?.current_drive_action.length > 0
    ? true
    : false;
};
export const checkTractorInDrivingState = (
  id: number,
  userId: number,
  remoteAvTractors: TractorHeartBeat[],
): boolean => {
  const [hearbeat] =
    remoteAvTractors &&
    remoteAvTractors.filter(
      (tractor: TractorHeartBeat) => tractor.tractor_id == id,
    );
  return checkTractorDrivingState(hearbeat, userId);
};

export const checkTractorDrivingState = (
  hearbeat: TractorHeartBeat,
  userId: number,
): boolean => {
  const status = getOperationNewStatus(hearbeat);
  return hearbeat &&
    hearbeat.drive_action_details &&
    hearbeat.drive_action_details.current_operator_id &&
    hearbeat?.drive_action_details?.current_operator_id === userId &&
    (status === tractorConstantsNew.remote_av.key ||
      status === tractorConstantsNew.copycat.key)
    ? true
    : false;
};
export const checkTractorInConnectedState = (
  id: number,
  userId: number,
  remoteAvTractors: TractorHeartBeat[],
): boolean => {
  const [hearbeat] =
    remoteAvTractors &&
    remoteAvTractors.filter(
      (tractor: TractorHeartBeat) => tractor.tractor_id == id,
    );
  return checkTractorConnectedState(hearbeat, userId);
};
export const checkTractorConnectedState = (
  heartbeat: TractorHeartBeat,
  userId: number,
): boolean => {
  return heartbeat &&
    heartbeat?.drive_action_details &&
    heartbeat?.drive_action_details?.current_operator_id &&
    heartbeat?.drive_action_details?.current_operator_id === userId &&
    heartbeat?.drive_action_details.current_drive_action.length > 0 &&
    heartbeat?.drive_action_details?.drive_action_created_at === 'ATOM' &&
    getOperationNewStatus(heartbeat) !== tractorConstantsNew.charging.key &&
    getOperationNewStatus(heartbeat) !== tractorConstantsNew.remote_av.key &&
    getOperationNewStatus(heartbeat) !== tractorConstantsNew.copycat.key
    ? true
    : false;
};

export const getGearText = (heartbeat: any) => `
  ${getGearRange(getGear(heartbeat))}${getGearRangeNumber(getGear(heartbeat))}
              
`;

export const getDiffTime = (startTime: any, endTime: any): string => {
  return moment
    .utc(
      moment(endTime, 'MMM D, YYYY h:mm A').diff(
        moment(startTime, 'MMM D, YYYY h:mm A'),
      ),
    )
    .format('HH:mm:ss');
};

export const pointExistInPolygons = (
  coordinate: Coordinate,
  polygonsLayerRef: any,
): boolean => {
  let allow = true;
  const features = polygonsLayerRef?.getSource()?.getFeatures();
  coordinate &&
    features &&
    features?.map((feature: any) => {
      if (feature && feature.getGeometry().intersectsCoordinate(coordinate)) {
        allow = false;
      }
    });
  return allow;
};

export const setTitleAndDescription = (guardRail: string) => {
  switch (guardRail) {
    case 'human protection':
      return {
        description: 'Person detected close to moving tractor',
        title: 'Human Presence',
      };
    case 'vehicle presence':
      return {
        description: 'Vehicle detected near moving tractor',
        title: 'Vehicle Presence',
      };
    case 'pto protection':
      return {
        description: 'Person detected close to spinning PTO',
        title: 'PTO Danger',
      };
    case 'path protection':
      return {
        description: 'Obstruction detected in the tractors path',
        title: 'Path Detected',
      };
    case 'speed protection':
      return {
        description: 'Speed exceeding safe limits',
        title: 'Speed Guardrail',
      };
    case 'pitch roll protection':
      return {
        description: 'Danger of rolling tractor over',
        title: 'Pitch/Roll Alert',
      };

    default:
      return {
        description: '',
        title: '',
      };
  }
};

export const mapTractors: any = (tractors: TractorsList[]) =>
  tractors.reduce((a, c) => {
    a.set(c.id, c);
    return a;
  }, new Map());

export const drawAbBlockFun = async (
  userDetails: UserDetails,
  base: any,
  layer: any,
  globalPolyUnique: number,
  localPolyUnique: number,
  abBlock: any,
  isApi = false,
) => {
  let polygonFeature = null;
  let polygonItem: MapFeature | null = null;
  let vertices = [];
  const id = `ab_block_${abBlock.id}`;
  const { organization } = userDetails;
  try {
    let coordinates: any[] = [];
    if (!isApi) {
      coordinates = await getAbBlockVerticesData(
        organization.api_url,
        organization.farm.id,
        abBlock.generic_map_uuid,
      );
    } else {
      coordinates = abBlock.vertices;
    }
    vertices = coordinates;
    coordinates = prepareCoordinates(coordinates, base);
    let satelightPolyStyle = null;
    // const color = '#032d57';
    // const color = '#032d57';
    const color = 'abBlock';
    if (coordinates && coordinates.length > 0) {
      const zIndex = priority.ABBLOCK;
      polygonFeature = new Feature({
        type: 'ABBLOCK',
        pointType: 'ABBLOCK',
        geometry: new Polygon([coordinates]),
        zIndex,
        generic_map_uuid: abBlock.generic_map_uuid,
        color,
        id: abBlock.id,
        remarks: abBlock.remarks,
        poly: abBlock,
        label: abBlock.name,
      });
      polygonFeature.setId(id);
      satelightPolyStyle = getSateLightPolyStyle(color, zIndex);
      const roadMapPolyStyle = getRoadMapPolyStyle(color, zIndex);
      polygonFeature.setStyle(satelightPolyStyle);
      polygonFeature.set('satelightPolyStyle', satelightPolyStyle);
      polygonFeature.set('roadMapPolyStyle', roadMapPolyStyle);
      globalPolyUnique === localPolyUnique &&
        layer &&
        layer?.getSource().addFeature(polygonFeature);
    }
    polygonItem = {
      id: abBlock.id,
      name: abBlock.name,
      type: 'ABBLOCK',
      mapId: id,
      visible: true,
      style: satelightPolyStyle ? satelightPolyStyle : null,
      color,
      generic_map_uuid: abBlock.generic_map_uuid,
      vertices,
    };
    return { polygonFeature, polygonItem };
  } catch (error: any) {
    notification.error({
      message: error.response?.data.error.message,
    });
    return { polygonFeature, polygonItem };
  }
};

export const rangeList = Array.from({ length: 41 }, (_, i) =>
  Number((0.5 * i).toFixed(1)),
);

export const onKeyDown = (e: any) => {
  if (
    e.keyCode === 189 ||
    e.keyCode === 38 ||
    e.keyCode === 40 ||
    e.keyCode === 69
  ) {
    e.preventDefault();
  }
};

export const alphaFeature = (feature: string) =>
  process.env.REACT_APP_ALPHA_FEATURES &&
  process.env.REACT_APP_ALPHA_FEATURES.includes(feature);

export const checkDisconnectCheck = (
  userDetails: UserDetails,
  selectedTractor: TractorsList,
  autoDriveMonitorErrorCodes: any,
): boolean => {
  // checking tractor exist or not
  if (!selectedTractor || !selectedTractor.id) {
    return false;
  }
  // if tractor offline hide disconnect
  if (selectedTractor.isAvailable === tractorConstantsNew.off.key) {
    return false;
  }
  // if login user and tractor connected not same hide disconnect
  if (
    userDetails?.id !==
    selectedTractor?.heartbeat?.drive_action_details?.current_operator_id
  ) {
    return false;
  }
  // if login user not from ATOM hide disconnect
  if (
    selectedTractor?.heartbeat?.drive_action_details
      ?.drive_action_created_at !== 'ATOM'
  ) {
    return false;
  }
  // if they have monitoring errors show disconnect
  if (
    checkAutoDriveMonitorErrorCodes(
      autoDriveMonitorErrorCodes,
      selectedTractor?.id,
    )
  ) {
    return true;
  }
  // if the tractor in remote av or row follow then hide disconnect

  // if (
  //   selectedTractor.isAvailable === tractorContantsNew.remote_av.key ||
  //   selectedTractor.isAvailable === tractorContantsNew.copycat.key
  // ) {
  //   return false;
  // }
  return true;
};

export const isSameUserTractorConnectedOrNot = (
  userId: number,
  heartbeat: TractorHeartBeat,
): boolean =>
  heartbeat &&
  heartbeat.drive_action_details &&
  heartbeat.drive_action_details.current_operator_id &&
  userId &&
  heartbeat.drive_action_details.current_operator_id == userId &&
  heartbeat.drive_action_details.current_drive_action &&
  heartbeat.drive_action_details.current_drive_action.length > 0
    ? true
    : false;

export const checkAutoDriveMonitorErrorCodes = (
  errorCodes: any,
  id: number,
): boolean =>
  errorCodes &&
  errorCodes.errors &&
  errorCodes.errors.length > 0 &&
  errorCodes.tractorId == id
    ? true
    : false;

export const isHaveErrors = (errors: any): boolean =>
  errors && errors.errors && errors.errors.length > 0 ? true : false;

export const isHaveErrorsForRoutineFlow = (errors: any): boolean => {
  let resp = true;
  errors &&
    errors.errors &&
    errors.errors.length > 0 &&
    errors.errors.map((error: number) => {
      if (error == 7101 || error == 7102) {
        resp = false;
      }
    });
  return resp;
};

// added to calculate hours to days, hours and minutes
export const offTimeSetUp = (numberOfHours: number): string => {
  let resp = '';
  const Days = Math.floor(numberOfHours / 24);
  const Remainder = numberOfHours % 24;
  const Hours = Math.floor(Remainder);
  const Minutes = Math.floor(60 * (Remainder - Hours));
  if (Days && Days > 0) {
    resp = `${Days} Day${Days > 1 ? 's' : ''}`;
  }
  if (Hours && Hours > 0) {
    resp = `${resp} ${Hours} Hour${Hours > 1 ? 's' : ''}`;
  }
  if (Minutes && Minutes > 0) {
    resp = `${resp} ${Minutes} Minute${Minutes > 1 ? 's' : ''} `;
  }
  return resp;
};

export const isLowLocalization = (localization: Localization): boolean => {
  return localization?.gps_status === 'NO Solution';
};
// point in good occuracy and good localization
export const isGoodLocalizationInJumpAndGo = (point: any): boolean => {
  const { gps_status } = point;
  return gps_status !== 'NO Solution';
};
export const checkIsTractorConnectedNoATOMCond = (
  tractor: TractorsList,
): boolean => {
  const { heartbeat, isAvailable } = tractor;
  return heartbeat &&
    heartbeat?.drive_action_details &&
    heartbeat?.drive_action_details?.current_operator_id &&
    heartbeat?.drive_action_details.current_drive_action.length > 0 &&
    isAvailable &&
    isAvailable !== tractorConstantsNew.off.key &&
    isAvailable !== tractorConstantsNew.faulty.key &&
    isAvailable !== tractorConstantsNew.charging.key &&
    isAvailable !== tractorConstantsNew.service.key
    ? true
    : false;
};
export const checkIsTractorConnected = (
  tractor: TractorsList,
  userId: number,
): boolean => {
  const { heartbeat, isAvailable } = tractor;
  return heartbeat &&
    heartbeat?.drive_action_details &&
    heartbeat?.drive_action_details?.current_operator_id &&
    // heartbeat?.drive_action_details?.current_operator_id === userId &&
    heartbeat?.drive_action_details.current_drive_action.length > 0 &&
    heartbeat?.drive_action_details?.drive_action_created_at === 'ATOM' &&
    isAvailable &&
    isAvailable !== tractorConstantsNew.off.key &&
    isAvailable !== tractorConstantsNew.faulty.key &&
    isAvailable !== tractorConstantsNew.charging.key &&
    isAvailable !== tractorConstantsNew.service.key
    ? true
    : false;
};
export const isLoginUserAndDriveActionUserSame = (
  tractor: TractorsList,
  userId: number,
): boolean => {
  const { heartbeat } = tractor;
  return heartbeat &&
    heartbeat?.drive_action_details &&
    heartbeat?.drive_action_details?.current_operator_id &&
    heartbeat?.drive_action_details?.current_operator_id === userId &&
    heartbeat?.drive_action_details?.drive_action_created_at === 'ATOM'
    ? true
    : false;
};
export const noneAttached = 'None Attached';
export const getImplementName = (selectedTractor: TractorsList): string => {
  return selectedTractor?.heartbeat?.implement?.current_implement &&
    selectedTractor?.heartbeat?.implement?.current_implement.toLowerCase() ===
      'none'
    ? noneAttached
    : selectedTractor?.heartbeat?.implement?.current_implement || noneAttached;
};

export function delay(ms: number): Promise<number> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const checkAlphaFeature = (feature: string): boolean =>
  process.env.REACT_APP_ALPHA_FEATURES?.toLowerCase().includes(
    feature.toLowerCase(),
  )
    ? true
    : false;

export const checkPausedStatus = (
  userId: number,
  selectedTractor: TractorsList,
  bar = false,
): string => {
  return userId ===
    selectedTractor?.heartbeat?.drive_action_details?.current_operator_id &&
    selectedTractor?.heartbeat?.planning_manager?.planner_name?.length > 0 &&
    selectedTractor?.heartbeat?.planning_manager?.planner_status?.length > 0 &&
    (selectedTractor?.isAvailable === tractorConstantsNew.copycat.key ||
      selectedTractor?.isAvailable === tractorConstantsNew.remote_av.key) &&
    selectedTractor?.heartbeat?.planning_manager?.planner_status === 'paused'
    ? bar
      ? 'redBdr'
      : 'PAUSED'
    : '';
};

export const checkTractorHaveMonitorStatueError = (
  tractorId: number,
  remoteAvTractors: TractorHeartBeat[],
): string => {
  const [hearbeat] =
    remoteAvTractors &&
    remoteAvTractors.filter(
      (tractor: TractorHeartBeat) => tractor.tractor_id == tractorId,
    );
  return hearbeat && hearbeat.autodrive_monitor_status
    ? hearbeat.autodrive_monitor_status
    : '';
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTractorAvailableState = (
  connection: string,
  heartbeat: TractorHeartBeat | undefined,
) =>
  connection === 'offline'
    ? tractorConstantsNew.off.key
    : getOperationStatus(heartbeat as TractorHeartBeat);

export const initScroller = () => {
  document.getElementsByClassName('infinite-scroll-component').length &&
    (document.getElementsByClassName(
      'infinite-scroll-component',
    )[0].scrollTop = 0);
};

export const checkLockUserAndLoginUser = (
  heartbeat: TractorHeartBeat,
  userId: number,
): boolean => {
  const { lockout_details } = heartbeat;
  return lockout_details && lockout_details.lockout_by === userId
    ? true
    : false;
};

export const checkActivity = (activity: string) =>
  ['search', 'sort', 'filter'].includes(activity);

// Prototype Section

Object.defineProperty(String.prototype, 'regX', {
  get: function () {
    return this.replace(/[^a-zA-Z0-9!@$^*()_+\-=[\]{};':"\\|,.<>/?\s ]/g, '');
  },
});

// Prototype Section

export const ROWFOLLOW = 10001;
export const COPYCAT = 10002;
export const AUTODRIVE = 10003;
export const MAINTENANCE_LOCK = 10007;

export const SHADOW = 10004;

export const getAccessJwtToken = async () => {
  if (
    process.env.REACT_APP_ALPHA_FEATURES?.toLocaleLowerCase().includes(
      'cognito',
    )
  ) {
    // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
    const session = await Auth.currentSession();
    const access_token = await session.getIdToken().getJwtToken();
    localStorage.setItem('access_token', access_token);
    return access_token;
  }
};

export const checkSnapFeature = (orgId: number | null) => {
  if (!orgId) return false;
  return (
    checkAlphaFeature('SnapDownload') && ['3', '120'].includes(String(orgId))
  );
};

export const jAGStartNdStop = (points: any, name: string, zIndex = 1) => {
  const tooltipStyle = new Style({
    image: new Icon({
      src: tooltip_icon_medium,
      scale: 0.5,
      anchor: [-0.15, 2.2],
    }),
    text: new Text({
      text: name,
      scale: 1,
      fill: new Fill({
        color: '#000',
      }),
      font: '6px Montserrat-SemiBold',
      offsetX: 33,

      offsetY: -42,
    }),
    zIndex: 1111,
  });
  const style = new Style({
    image: new Icon({
      src: JumpnGoIcon,
      scale: 1.5,
      anchor: [0.6, 1],
    }),
    zIndex: 1110,
  });
  const feature = new Feature({
    geometry: new Point(points),
    name,
  });
  feature.setStyle([style, tooltipStyle]);
  return feature;
};
export const fileTypesAccepted = '.jpg, .jpeg, .png, .bmp, .pmg, .gif, .tiff';

export const checkForFileTypes = (type: string): boolean => {
  const fileTypeAllowed =
    type === 'image/jpeg' ||
    type === 'image/png' ||
    type === 'image/jpg' ||
    type === 'image/bmp' ||
    type === 'image/tiff' ||
    type === 'image/gif' ||
    type === 'image/pmg';
  return fileTypeAllowed;
};
