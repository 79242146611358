/* eslint-disable no-console */
import Layout, { Content } from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Tabs } from 'antd';
import { useLocation } from 'react-router-dom';
// import ReportsDistribution from './ReportsDistrbution';
import MapWrapper from './MapWrapper';
import Library from '../library/Library';
import { useMixpanel } from 'react-mixpanel-browser';
import AnalyticsContextProvider from './AnalyticsContext';
// import FileUploadView from '../FileUploadView/fileuploadview';
import ReportsDistribution from '../reports/ReportsDistrbution';
// import Charts from '../charts/Charts';
import { DriveAction } from '../../constants/types';
import FleetUsageCharts from '../fleetUsageBarCharts/FleetUsageCharts';
import { ApplicationContext } from '../../context/AppContext';
import { SET_LAST_TAB_INDEX } from '../../context/actions';

const { TabPane } = Tabs;
const AnalyticsContainer: React.FC = () => {
  const location = useLocation();
  const state: any = location && location.state ? location.state : {};
  const [tabid, setTabid] = useState<any>('');
  const [transactionId, setTransactionId] = useState('');
  const [driveAction, setDriveAction] = useState<DriveAction>({});
  const mixpanel = useMixpanel();
  const { APPReducer } = useContext(ApplicationContext);
  const [, dispatch] = APPReducer;

  useEffect(() => {
    if (state && state.action && state.action === 'Operations') {
      setTabid(state.action);
      if (state && state.drive_action) {
        setDriveAction(state.drive_action);
      }
    }
    if (state && state.action && state.action === 'Operations') {
      setTabid(state.action);
      if (state && state.transactionId) {
        setTransactionId(state.transactionId);
      }
    }
  }, [state]);

  useEffect(() => {
    if (state?.reset) {
      setTabid('Operations');
    }
  }, [state.reset]);

  useEffect(() => {
    const lastTabIndex: string | null = localStorage.getItem('lastTabIndex');
    const tlastTabIndex: any = lastTabIndex && JSON.parse(lastTabIndex);
    if (tlastTabIndex?.catlog === 'Analytics') {
      setTabid(tlastTabIndex?.tab);
    } else {
      setTabid('Operations');
    }
  }, [localStorage.getItem('lastTabIndex')]);

  return (
    <AnalyticsContextProvider>
      <Layout>
        <Content>
          <div className="mainContent">
            <div className="dbWidget attContainer padt0 analyticsTab bNone">
              <Card>
                <Tabs
                  activeKey={tabid}
                  className={
                    tabid && tabid === '1' ? 'elTabs elTabs1' : 'elTabs'
                  }
                  onChange={(key) => {
                    setTabid(key);
                    dispatch({
                      type: SET_LAST_TAB_INDEX,
                      payload: {
                        catlog: 'Analytics',
                        tab: key,
                      },
                    });
                    mixpanel.track(key, {
                      event: `${key} Tab`,
                    });
                  }}
                >
                  <TabPane
                    tab="Operations"
                    key="Operations"
                    className="attTableHeight mBottom0 elTabs1"
                    style={{ top: -50 }}
                  >
                    <MapWrapper
                      driveAction={driveAction}
                      setDriveAction={setDriveAction}
                    />
                  </TabPane>
                  {/* {process.env.REACT_APP_ALPHA_FEATURES &&
                    process.env.REACT_APP_ALPHA_FEATURES.includes(
                      'CostExplorer',
                    ) && ( */}
                  <TabPane
                    tab="Cost explorer"
                    key="Cost explorer"
                    className="attTableHeight mBottom0"
                  >
                    <FleetUsageCharts></FleetUsageCharts>
                  </TabPane>
                  {/* )} */}
                  {/* <TabPane
                    tab="Insights"
                    key="Insights"
                    className="attTableHeight mBottom0"
                    disabled
                  ></TabPane> */}
                  <TabPane
                    tab="Library"
                    key="Library"
                    className="attTableHeight mBottom0"
                  >
                    <Library transactionId={transactionId} />
                  </TabPane>
                  {/* <TabPane
                    tab="File Upload View"
                    key="FileUploadView"
                    className="attTableHeight mBottom0"
                  >
                    <FileUploadView />
                  </TabPane> */}
                  <TabPane
                    tab="Reports"
                    key="Reports"
                    className="attTableHeight mBottom0"
                  >
                    <ReportsDistribution />
                  </TabPane>
                </Tabs>
              </Card>
            </div>
          </div>
        </Content>
      </Layout>
    </AnalyticsContextProvider>
  );
};

export default AnalyticsContainer;
