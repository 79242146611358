/* eslint-disable no-console */
import { Button, Modal, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import atArrow from '../../assets/images/atArrow.svg';
import hoursOfOp from '../../assets/images/hours_of_op.svg';
import tCLock from '../../assets/images/tCLock.svg';

import './autodrivelist.css';

import {
  addRemoteAVCommand,
  createSnapshot,
  executeCommand,
  getImplementsList1,
  stopDriveTractor,
} from '../../constants/Api';
import {
  batteryValue,
  checkAlphaFeature,
  checkAutoDriveMonitorErrorCodes,
  checkIsTractorConnected,
  checkPausedStatus,
  checkTractorDrivingState,
  getFNR,
  getOperatorNameByHeartBeat,
  geTractorStatusIcon,
  getSpeed,
  getStatusClass,
  getStatusForTractor,
  getTripMeterValue,
  isHaveErrors,
  secondsToHours,
  tractorConstantsNew,
} from '../../constants/Common';
import { MapFeature, TractorHeartBeat } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { RemoteDriveAppCtx } from './RemoteDriveContext';

import endBtnIcon from '../../assets/images/endBtnIcon.svg';
import { SET_PINNED, SET_TRACTOR_IMPLEMENT_STATE } from '../../context/actions';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import { Cameras } from '../livecommand/CamerasPage1';
import {
  SELECTED_IMPLEMENT_NAME,
  SELECTED_TRACTORS,
  SET_AUTO_VALUE,
  SET_PINNED_TRACTORS,
  SET_SHOW_PATH_MODAL,
  SET_SHOW_PRESENT_COMMAND,
  SET_SHOW_REMOTE_DRIVE_DETAILS,
  SET_SHOW_SNAPSHOT,
  SNAP_SHOT_INITIATED,
} from './actions';
import AutoDriveRoutine from './AutoDriveRoutine';
import { Errorcodepopup } from './Errorcodepopup';
import { GuardrailWidget } from './GuardrailWidget';
import MaintenanceAndStartAutoDrive from './MaintenanceAndStartAutoDrive';
import PinnedTractors from './PinnedTractors';
import RemoteDriveActionsNewDesign from './RemoteDriveActionsNewDesign';

interface Props {
  clearPreviewPath: (id: number) => void;
  handleResetCommand: (state: string) => void;
  preCommandState: string;
  addRVCommand: () => void;
  addRowFollowRVCommand: () => void;
  recenterItem: (item: MapFeature) => void;
  recenterXY: (xy: any) => void;
}
const RemoteDriveDetailsNew: React.FC<Props> = ({
  clearPreviewPath,
  handleResetCommand,
  preCommandState,
  addRVCommand,
  addRowFollowRVCommand,
  recenterItem,
  recenterXY,
}: Props) => {
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const mixpanel = useMixpanel();
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  const [appState] = APPReducer;
  const { tractorsMap, tractorsList } = appState;
  const { tractorImplementState, isPinned } = autoDriveState;

  const {
    getImplement,
    RDReducer,
    handlePauseResumeStopLiveCom,
    setGoToPointCoordinates,
    getIndicatorIcons,
    mapPinnedTractors,
    clearAllLocalDBCommands,
  } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const {
    selectedTractor,
    showPathModal,
    autoDriveMonitorErrorCodes,
    autoDriveError,
    pinnedTractors,
    showAutoDrivePop,
    showAutoDriveConnect,
  } = state;
  const { heartbeat } = selectedTractor;
  const [loader, setLoader] = useState<boolean>(false);
  const [errorCodeFlag, setErrorCodeFlag] = useState<boolean>(false);
  const [implementsList, setImplements] = useState<any[]>([]);
  const [selectedImplement, setselectedImplement] = useState('');
  const { confirm } = Modal;
  const [showEndAndDisconnect, setShowEndAndDisconnect] = useState(false);

  useEffect(() => {
    setselectedImplement('');
    return () => {
      setselectedImplement('');
    };
  }, []);
  const showDeleteConfirm = () => {
    confirm({
      className: 'confirm-end-operation',
      title: 'End Operation?',
      icon: (
        <div className="endOptIcon">
          <img width="60" src={endBtnIcon} />
        </div>
      ),
      content:
        'Are you sure you want to end this operation before it has been completed?',
      okText: 'End Operation',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        stopAndDisconnectHandler();
      },
    });
  };
  const stopAndDisconnectHandler = () => {
    // stop start here
    clearAllLocalDBCommands(selectedTractor.id);
    dispatch({
      type: SELECTED_TRACTORS,
      payload: [],
    });
    handleResetCommand('');
    tractorImplementState[selectedTractor.id] = false;
    autoDriveDispatch({
      type: SET_TRACTOR_IMPLEMENT_STATE,
      payload: tractorImplementState,
    });
    clearPreviewPath(selectedTractor.id);
    handlePauseResumeStopLiveCom('stop');
    // stop end here
    // disconnect start here
    disConnectConnection(selectedTractor?.heartbeat);
    // disconnect ends here
  };
  useEffect(() => {
    setselectedImplement('');
    dispatch({
      type: SELECTED_IMPLEMENT_NAME,
      payload: '',
    });
    if (selectedTractor && selectedTractor.id && implementsList.length > 0) {
      implementsList.forEach((implement: any) => {
        if (
          selectedTractor &&
          selectedTractor?.heartbeat &&
          selectedTractor?.heartbeat?.implement &&
          selectedTractor?.heartbeat?.implement?.current_implement &&
          selectedTractor?.heartbeat?.implement?.current_implement.length > 0 &&
          implement.name ===
            selectedTractor?.heartbeat?.implement?.current_implement
        ) {
          dispatch({
            type: SELECTED_IMPLEMENT_NAME,
            payload: implement.name,
          });

          setselectedImplement(implement.config_implements_uuid);
        }
      });
    }
  }, [selectedTractor, implementsList]);

  useEffect(() => {
    if (selectedTractor && selectedTractor.id) getImplement(selectedTractor.id);
  }, [selectedTractor]);

  const disConnectConnection = async (hb?: TractorHeartBeat) => {
    if (hb && hb?.drive_action_details?.current_drive_action) {
      setLoader(true);
      try {
        const { organization } = userDetails;
        const { msg } = await stopDriveTractor(
          organization.api_url,
          hb?.drive_action_details?.current_drive_action,
          {
            operator_id: userDetails.id,
            action: 'FINISH',
          },
        );
        clearAllLocalDBCommands(hb.tractor_id);
        notification.success({
          message: msg,
          duration: 1,
        });
        setLoader(false);
        dispatch({
          type: SELECTED_TRACTORS,
          payload: [],
        });
        clearPreviewPath(hb.tractor_id);
        // to reset implement start here
        tractorImplementState[selectedTractor.id] = false;
        autoDriveDispatch({
          type: SET_TRACTOR_IMPLEMENT_STATE,
          payload: tractorImplementState,
        });
        return;
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      } finally {
        setLoader(false);
      }
    }
  };

  const clearAll1 = (hb: TractorHeartBeat) => {
    const { organization } = userDetails;
    if (organization && organization.api_url) {
      clearAllLocalDBCommands(hb.tractor_id);
      hb?.drive_action_details?.current_drive_action &&
        addRemoteAVCommand(
          organization.api_url,
          [
            {
              command: 'clear_command_queue',
            },
          ],
          hb?.drive_action_details?.current_drive_action as string,
        );
    }
    dispatch({
      type: SET_AUTO_VALUE,
      payload: '',
    });
    setGoToPointCoordinates([]);
    dispatch({
      type: SET_SHOW_PRESENT_COMMAND,
      payload: 'GOTO',
    });
    handleResetCommand('GOTO');
    clearPreviewPath(hb.tractor_id);
  };

  useEffect(() => {
    userDetails && userDetails.organization && getImplementsListData();
  }, [userDetails]);

  const getImplementsListData = async () => {
    try {
      setLoader(true);
      const records = await getImplementsList1(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
      );
      records && records.length > 0
        ? setImplements(records)
        : setImplements([]);
    } catch (error: any) {
      notification.error({
        message: error.response?.data?.error.message || error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    handleResetCommand(selectedImplement === '' ? '' : 'GOTO');
  }, [selectedImplement]);
  const handleIgnore = async (action: string) => {
    try {
      const { organization } = userDetails;
      const drive_action_uuid =
        heartbeat?.drive_action_details?.current_drive_action;
      const { msg } = await executeCommand(
        organization.api_url,
        drive_action_uuid,
        {
          action,
          operator_id: userDetails.id,
          planner: 'guardrails',
          tractor_id: selectedTractor.id,
          organization_id: userDetails.organization_id,
          parameters: [],
        },
      );
      notification.success({
        message: msg,
      });
      dispatch({ type: SET_SHOW_PATH_MODAL, payload: false });
    } catch (err: any) {
      notification.error({
        message: err.response?.data?.error.message || err.message,
      });
    }
  };
  const error_Codes_Details = () => {
    setErrorCodeFlag(true);
  };

  const closeModel = () => {
    setErrorCodeFlag(false);
  };

  useEffect(() => {
    if (
      checkAutoDriveMonitorErrorCodes(
        autoDriveMonitorErrorCodes,
        selectedTractor?.id,
      )
    ) {
      handleResetCommand('');
      clearAll1(selectedTractor?.heartbeat);
    }
  }, [autoDriveMonitorErrorCodes, selectedTractor]);

  useEffect(() => {
    if (isHaveErrors(autoDriveError)) {
      handleResetCommand('');
      clearAll1(selectedTractor?.heartbeat);
    }
  }, [autoDriveError, selectedTractor]);

  const createSnapshots = async () => {
    setLoader(true);
    try {
      const { organization } = userDetails;
      const response = await createSnapshot(
        organization.api_url,
        organization.farm.id,
        selectedTractor.id,
      );
      if (!response?.id) throw new Error('SnapShot Failed');
      const { msg, id } = mixpanel.track('Live Map', {
        event: `Fleet List SnapShot successful`,
      });
      response['tractorName'] = tractorsMap.get(response.tractor_id)?.name;
      response['name'] = `${userDetails.first_name} ${userDetails.last_name}`;
      dispatch({
        type: SNAP_SHOT_INITIATED,
        payload: response,
      });
      notification.open({
        message: '',
        key: response.id,
        // duration: 3,
        description: (
          <>
            <p>Snapshot Captured</p>
            <Button
              onClick={() => {
                notification.close(response.id);
                dispatch({
                  type: SET_SHOW_SNAPSHOT,
                  payload: true,
                });
              }}
              className="cursor"
            >
              View
            </Button>
          </>
        ),
      });
    } catch (error: any) {
      mixpanel.track('Live Map', {
        event: `Fleet List SnapShot Failed`,
      });
      notification.error({
        message: error.response?.data?.error?.message || error.message,
      });
    } finally {
      setLoader(false);
    }
  };
  const isPinnedFun = (id: number) => pinnedTractors.includes(id);
  const unPinnedTractors = (id: number) => {
    const ttractors = pinnedTractors.filter((item: any) => item !== id);
    dispatch({
      type: SET_PINNED_TRACTORS,
      payload: ttractors,
    });
  };

  const zindexStarts = 100;
  const tBox: any = document.querySelectorAll('.adtBottomBox');

  for (let i = 0; i < tBox.length; i++) {
    if (tBox[i]) {
      tBox[i].style['z-index'] = zindexStarts - i;
    }
  }

  return (
    <>
      <div className="rautoDriveRightSec grayBlock">
        <div className="autoDriveTractorHead">
          <div
            className="tractorPin"
            data-testid="tractPin-LiveMap"
            onClick={() => {
              mapPinnedTractors(selectedTractor.id);
              isPinned &&
                dispatch({
                  type: SET_PINNED_TRACTORS,
                  payload: [],
                });
              autoDriveDispatch({
                type: SET_PINNED,
                payload: !isPinned,
              });
            }}
          ></div>
          <div
            className="adtHeadSec"
            onClick={() => {
              dispatch({
                type: SET_SHOW_REMOTE_DRIVE_DETAILS,
                payload: false,
              });
            }}
          >
            <span className="adtHeadTextIcon">
              <img src={atArrow} alt="atArrow" />
            </span>
            <span className="adtHeadText Button">TRACTOR DETAILS</span>
          </div>
        </div>
        <div className={`whiteWrapper ${isPinned ? 'whiteLayer' : ''}`}></div>
        <div className="adtMainContainer">
          <div
            className={`adtTopSection ${geTractorStatusIcon(
              selectedTractor.isAvailable,
            )}_color ${pinnedTractors.length < 2 ? 'tdtl_nopin' : ''}`}
          >
            {/* <div
            className={`adtTopSection autoDrive_color ${
              pinnedTractors.length < 2 ? 'tdtl_nopin' : ''
            }`}
          > */}
            <div className={`adtTopBox ${isPinned ? 'adRoundBox' : ''}`}>
              <div
                className="adtClose"
                onClick={() => {
                  if (pinnedTractors.length == 1) {
                    if (isPinnedFun(selectedTractor.id)) {
                      dispatch({
                        type: SET_SHOW_REMOTE_DRIVE_DETAILS,
                        payload: false,
                      });
                      dispatch({
                        type: SELECTED_TRACTORS,
                        payload: [],
                      });
                    } else {
                      const [id] = pinnedTractors;
                      const [ftractor] = tractorsList.filter(
                        (tractor: any) => tractor.id === id,
                      );
                      dispatch({
                        type: SELECTED_TRACTORS,
                        payload: [ftractor],
                      });
                    }
                    unPinnedTractors(selectedTractor.id);
                    handleResetCommand('');
                  } else if (pinnedTractors.length > 1) {
                    const list = pinnedTractors.filter(
                      (item: any) => item !== selectedTractor.id,
                    );
                    const [ftractor] = tractorsList.filter(
                      (tractor: any) => tractor.id === list[0],
                    );
                    dispatch({
                      type: SELECTED_TRACTORS,
                      payload: [ftractor],
                    });
                    handleResetCommand('');
                    unPinnedTractors(selectedTractor.id);
                  } else {
                    dispatch({
                      type: SET_SHOW_REMOTE_DRIVE_DETAILS,
                      payload: false,
                    });
                    dispatch({
                      type: SELECTED_TRACTORS,
                      payload: [],
                    });
                    handleResetCommand('');
                  }
                }}
                data-testid="closeiconwin-LiveMap"
              ></div>
              <div className="adTabsHeadSec">
                <div
                  className="adtabsHeadText headline2"
                  data-testid="tractorHeadr-LiveMap"
                >
                  {selectedTractor?.name}
                </div>
                <div
                  className={`ttractor_state  ${getStatusClass(
                    selectedTractor.isAvailable,
                    selectedTractor?.heartbeat?.drive_action_details
                      ?.drive_action_created_at,
                  )}`}
                >
                  {getStatusForTractor(
                    selectedTractor.isAvailable,
                    selectedTractor?.heartbeat?.drive_action_details
                      ?.drive_action_created_at,
                  )}
                </div>
              </div>
              <div className="adTabsContentSec">
                <div
                  className="rautocloseIcon closeIconGrid"
                  data-testid="closeicon-LiveMap"
                >
                  {/* <div className="swithBtn">
                    {isSameUserTractorConnectedOrNot(
                      userDetails?.id,
                      heartbeat,
                    ) && (
                      <>
                        <Switch
                          checkedChildren="Unlock"
                          unCheckedChildren="Lockout"
                          checked={tractorLockUnLock}
                          onChange={() =>
                            tractorLockUnLock
                              ? updateReason()
                              : setShowTractorLockUnLock(true)
                          }
                          defaultChecked
                          style={{ color: '#546cb2' }}
                        />
                      </>
                    )}
                  </div> */}
                  {/* <div className="">
                    {checkDisconnectCheck(
                      userDetails,
                      selectedTractor,
                      autoDriveMonitorErrorCodes,
                    ) && (
                      <>
                        <Button
                          className="disconnectBtnSec"
                          onClick={() => {
                            disConnectConnection(selectedTractor?.heartbeat);
                            // to reset implement start here
                            tractorImplementState[selectedTractor.id] = false;
                            autoDriveDispatch({
                              type: SET_TRACTOR_IMPLEMENT_STATE,
                              payload: tractorImplementState,
                            });
                            // to reset implement ends here
                          }}
                        >
                          <img src={disCross} /> DISCONNECT
                        </Button>
                      </>
                    )}
                  </div> */}
                </div>
                <div
                  className="rautoDriveDetailsTitle"
                  data-testid="Tractornmefleet-LiveMap"
                ></div>
                <div className="rautoDriveStatus">
                  <div className="autoDrivetime">
                    <img src={hoursOfOp} alt="" className="hourIcon" />
                    <span className="body2">
                      {secondsToHours(
                        Number(selectedTractor?.heartbeat?.daily_hour_meter),
                      )}
                    </span>
                  </div>
                  {/* <div className="autoDriverName">
                    <img src={tCLock} alt="" width="11" />
                    <span className="aDname">Daron Hovanessian</span>
                  </div> */}
                  <div className="autoDriverName body2">
                    {selectedTractor.isAvailable !==
                      tractorConstantsNew.off.key && (
                      <>
                        {getOperatorNameByHeartBeat(
                          selectedTractor.heartbeat,
                        ).trim() !== '' && (
                          <img className="t_lock" src={tCLock} />
                        )}
                        <span className="aDname">
                          {getOperatorNameByHeartBeat(
                            selectedTractor.heartbeat,
                          )}
                        </span>
                      </>
                    )}

                    {/* <img src={hoursOfOp} alt="" />{' '}
                    <span>{getTripMeterValue(selectedTractor)}</span> */}
                  </div>
                  <div
                    // className={
                    //   selectedTractor.indicatorsData.filter(
                    //     (indicator: { indicator_level: number }) =>
                    //       indicator.indicator_level === 3,
                    //   ).length > 0
                    //     ? 'autodriveStatusIcons redBdr'
                    //     : 'autodriveStatusIcons whiteBdr'
                    // }
                    className="autodriveStatusIcons"
                  >
                    {selectedTractor &&
                      selectedTractor.id &&
                      getIndicatorIcons(selectedTractor)}
                  </div>
                </div>

                <div className="tractorSpeedometer">
                  <div className="t_info_progress_circle ">
                    <div
                      className="progressbar"
                      style={{
                        background: `radial-gradient(
                            closest-side,
                            white 75%,
                            transparent 0 99.9%,
                            white 0
                          ),
                          conic-gradient(#5BCD66 ${batteryValue({
                            heartbeat: heartbeat,
                          })}%, #F5F7F6 0)`,
                      }}
                    >
                      <div
                        className={`tractor_status_img ${checkPausedStatus(
                          userDetails?.id,
                          selectedTractor,
                          true,
                        )}`}
                      >
                        {/* <div className="mLockSec">
                          <img src={mtLock} alt="" height="40" />
                        </div> */}
                        <div
                          className={
                            geTractorStatusIcon(selectedTractor.isAvailable)
                              ? geTractorStatusIcon(selectedTractor.isAvailable)
                              : 't_off_img'
                            // 't_routine_img'
                          }
                        >
                          {/* {selectedTractor.isAvailable ===
                            tractorContantsNew.service.key && (
                            <img src={mtLock} alt="" height="40" />
                          )} */}
                        </div>
                        {/* display if not charging,offline and available */}
                        {selectedTractor.isAvailable !==
                          tractorConstantsNew.available.key &&
                          selectedTractor.isAvailable !==
                            tractorConstantsNew.faulty.key &&
                          selectedTractor.isAvailable !==
                            tractorConstantsNew.off.key &&
                          selectedTractor.isAvailable !==
                            tractorConstantsNew.charging.key &&
                          selectedTractor.isAvailable !==
                            tractorConstantsNew.service.key && (
                            <div className="t_speed_cont">
                              <span className="type">{getFNR(heartbeat)}</span>
                              <span className="speed">
                                {selectedTractor && selectedTractor.heartbeat
                                  ? getSpeed(selectedTractor.heartbeat)
                                  : '-'}{' '}
                              </span>
                              <span className="measure">mph</span>
                            </div>
                          )}
                        <div className="tractorPasued headline5">
                          {checkPausedStatus(userDetails?.id, selectedTractor)}
                        </div>
                      </div>
                      <div
                        className="rotate"
                        style={{
                          transform: `${`rotate(${
                            batteryValue({
                              heartbeat: heartbeat,
                            }) * 3.6
                          }deg)`}`,
                        }}
                      >
                        <div className="counterrotate">
                          <div
                            className="inner"
                            style={{
                              transform: `${`rotate(-${
                                batteryValue({
                                  heartbeat: heartbeat,
                                }) * 3.6
                              }deg)`}`,
                            }}
                          >
                            <div className="innerInner">
                              {batteryValue({
                                heartbeat: heartbeat,
                              })}
                              %
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <span className="trip-meter body3">
                    <span className="tripLbl">TRIP</span>
                    <span>{getTripMeterValue({ heartbeat: heartbeat })}</span>
                  </span>
                </div>

                {selectedTractor?.heartbeat?.drive_action_details
                  ?.drive_action_created_at === 'ATOM' &&
                  !showEndAndDisconnect && (
                    <RemoteDriveActionsNewDesign
                      showDeleteConfirm={() => setShowEndAndDisconnect(true)}
                      disConnectConnection={disConnectConnection}
                    />
                  )}
                <MaintenanceAndStartAutoDrive
                  setShowEndAndDisconnect={setShowEndAndDisconnect}
                  stopAndDisconnectHandler={stopAndDisconnectHandler}
                  showEndAndDisconnect={showEndAndDisconnect}
                  showAutoDriveConnect={showAutoDriveConnect}
                />
                <div className="autoDriveScoller">
                  {/* <div className="autoDriveBlk1">
                    {checkAutoDriveMonitorErrorCodes(
                      autoDriveMonitorErrorCodes,
                      selectedTractor?.id,
                    ) && (
                      <div className="tractormeterBlk error_code_block">
                        <div>
                          <img src={errorCode_icon} className="mb20" />
                        </div>
                        <div className="mb20 notAvail">
                          Auto drive not available
                        </div>
                        <div className="showDtl" onClick={error_Codes_Details}>
                          Show Details
                        </div>
                      </div>
                    )}
                  </div> */}

                  <div className="autoDriveCamViews">
                    {selectedTractor && selectedTractor.isAvailable && (
                      <Cameras
                        triggerSnapshot={createSnapshots}
                        selectedTractorId={selectedTractor.id}
                        tractorName={selectedTractor.name}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <PinnedTractors />
          </div>

          <div className="rautoDriveRightWrapper"></div>
        </div>
        {showPathModal && (
          <GuardrailWidget
            handleIgnore={() => handleIgnore('ignore_and_continue')}
          />
        )}
        {/* <div className="pathPopup">
          <img src={cone_icon} alt="" />
          <div className="path-inr-blk">
            <h6 className="pausedMsg">
              <img src={warning_icon} alt="" />
              <span>
                <strong>Paused:</strong> Obstructed path
              </span>
            </h6>
            <h4>Obstructed path</h4>
            <p>
              Tractor has come to a stop because of an obstruction in its path.
              Operation will resume when resolved.
            </p>
            <div className="continuebtn">ignore & continue</div>
          </div>
        </div> */}
        {/* <AppLoader loader={loader} /> */}
      </div>
      {checkAutoDriveMonitorErrorCodes(
        autoDriveMonitorErrorCodes,
        selectedTractor?.id,
      ) &&
        errorCodeFlag && (
          <Errorcodepopup showModal={errorCodeFlag} closeModel={closeModel} />
        )}
      {!checkAlphaFeature('autodrive') &&
        showAutoDrivePop &&
        selectedTractor &&
        selectedTractor.id &&
        checkIsTractorConnected(selectedTractor, userDetails.id) &&
        !checkTractorDrivingState(
          selectedTractor.heartbeat,
          selectedTractor.id,
        ) && (
          <div className="auto-drive-routine">
            <AutoDriveRoutine
              disConnectConnection={disConnectConnection}
              handleResetCommand={handleResetCommand}
              preCommandState={preCommandState}
              addRVCommand={addRVCommand}
              addRowFollowRVCommand={addRowFollowRVCommand}
              recenterItem={recenterItem}
              recenterXY={recenterXY}
              clearPreviewPath={clearPreviewPath}
            />
          </div>
        )}
    </>
  );
};

export default RemoteDriveDetailsNew;
