import { notification, Spin, Avatar, Tooltip } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ApplicationContext } from '../../context/AppContext';
import RoutesConstants from '../../routes/RoutesConstant';
import LiveVideo from '../livecommand/LiveVideo';
import { getClient } from '../../util/MqttConnection';
import loading_logo from '../../assets/images/loading_logo.gif';
import './style.css';
import {
  LiveStreamWatchers,
  TractorHeartBeat,
  Watcher,
} from '../../constants/types';
import { MqttClient } from 'mqtt';
import { getInternetSpeed, getRandomColor } from '../../constants/Common';
import ispeedLow from '../../assets/images/ispeedLow.svg';
import ispeedMedium from '../../assets/images/ispeedMedium.svg';
import ispeedFull from '../../assets/images/ispeedFull.svg';
import Timer from '../remotedrive/Timer';

const url = window.location.href;
const origin = window.location.origin;
const popUrl = url.replace(RoutesConstants.CameraView + '/', '');
const srcString = popUrl.replace(origin, '');
const viewData = srcString;
const tractorId = viewData.split('/')[0];
const tractorName = viewData.split('/')[2];
const cameraFace = viewData.split('/')[3];
const showStats = viewData.split('/')[4];
const heartBeatTopic = 'HeartBeat';

const CameraView: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const [src, setSrc] = useState('');
  const [showSpinner, setShowSpinner] = useState(true);
  const [spinnerDescription, setSpinnerDescription] = useState('');
  const mqttClientRef = useRef<MqttClient>();
  const [internetSpeed, setInternetSpeed] = useState(0);
  const [watchers, setWatchers] = useState<Watcher[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      setSrc(
        `${userDetails.organization.api_url}/live-camera/tractor/${viewData}`,
      );
    }
    document.title = `${tractorName} ${cameraFace
      .toLowerCase()
      .replaceAll('_', ' ')} camera`;
  }, [userDetails]);

  useEffect(() => {
    try {
      if (userDetails && userDetails.organization) {
        const { organization } = userDetails;
        const mqttClient = getClient(
          organization.mqtt_ip,
          organization.mqtt_port,
        );

        mqttClient.on('connect', () => {
          mqttClient.subscribe([heartBeatTopic, 'live_stream_watch_logs']);
        });
        mqttClient.on('message', (topic: string, message: any) => {
          if (topic === heartBeatTopic) {
            const heartBeat: TractorHeartBeat = JSON.parse(message.toString());
            if (
              heartBeat &&
              heartBeat.organization_id &&
              userDetails.organization_id &&
              heartBeat.organization_id === userDetails.organization_id &&
              heartBeat.tractor_id === Number(tractorId)
            ) {
              const speed = getInternetSpeed(heartBeat);
              setInternetSpeed(speed);
            }
          }

          if (topic === 'live_stream_watch_logs') {
            const watchers: LiveStreamWatchers = JSON.parse(message.toString());
            if (
              watchers.tractor_id.toString() === tractorId &&
              watchers.camera === cameraFace
            ) {
              const filteredUserd = watchers?.live_stream_watcher?.filter(
                (v, i, a) =>
                  a.findIndex((v2) => v2.user_id === v.user_id) === i,
              );

              setWatchers(filteredUserd);
            }
          }
        });
        mqttClientRef.current = mqttClient;
        return () => {
          mqttClient.unsubscribe([heartBeatTopic, 'live_stream_watch_logs']);
          mqttClient.end();
        };
      }
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  }, [userDetails]);

  return (
    <>
      <Spin
        spinning={showSpinner}
        tip={spinnerDescription}
        indicator={
          <img style={{ width: '2em', height: '1em' }} src={loading_logo} />
        }
      >
        {showStats === 'true' && (
          <>
            {/* {internetSpeed > 0 && (
              <div className="internetSpeed">
                <span>
                  <img
                    src={
                      internetSpeed <= 2.5
                        ? ispeedLow
                        : internetSpeed <= 5 && internetSpeed > 2.5
                        ? ispeedMedium
                        : ispeedFull
                    }
                    className="ispeedImg"
                  />{' '}
                  : {internetSpeed} <span className="iMBPS">Mbps</span>
                </span>
              </div>
            )} */}
            <div className="avatarGroup">
              <Avatar.Group
                maxCount={2}
                size="large"
                maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
              >
                {watchers &&
                  watchers.map((watcher: Watcher, index: number) => (
                    <>
                      <Tooltip title={watcher.user_name} placement="top">
                        <Avatar
                          style={{ backgroundColor: getRandomColor() }}
                          src={watcher.user_profile}
                        >
                          {watcher.user_name?.charAt(0)}
                        </Avatar>
                      </Tooltip>
                    </>
                  ))}
              </Avatar.Group>
            </div>
          </>
        )}

        {/* {internetSpeed > 0 && internetSpeed <= 2.5 && (
          <div className="overlay"> Poor network connection </div>
        )} */}
        {errorMessage && errorMessage.length > 0 && (
          <div className="overlay">{errorMessage}</div>
        )}
        <div style={{ height: '100vh', backgroundColor: 'grey' }}>
          {src.length > 0 && (
            <>
              <>
                {/* <video
                  className="rounded centered"
                  id={`remotevideov`}
                  width="100%"
                  style={{
                    display: 'flex',
                    height: '100%',
                    alignItems: 'center',
                  }}
                  playsInline={true}
                  onLoad={() => setShowSpinner(false)}
                  onLoadedData={() => setShowSpinner(false)}
                  onError={() =>
                    setSpinnerDescription('Camera offline, Please try later ')
                  }
                ></video> */}
                <div className="col-md-6" id="videos"></div>
                <LiveVideo
                  tractorName={tractorId}
                  cameraFace={cameraFace}
                  setErrorMessage={(msg) => setErrorMessage(msg)}
                  setShowSpinner={setShowSpinner}
                />
                {showStats === 'true' && (
                  <Timer promptTimeoutMinutes={1} timeoutMinutes={6} />
                )}
              </>
            </>
          )}
        </div>
      </Spin>
    </>
  );
};

export default CameraView;
