import {
  SET_OPERATION_FILTER,
  SET_SELECTED_JAG,
  SET_OPERATION_FILTER_LABEL,
  SET_OPERATIONS,
  SET_POLY_LAYER,
  SET_IS_LOADING,
  RESET,
} from './action';

interface InitialStateType {
  operationFilter: any;
  operationFilterLabel: any;
  operations: any[];
  JAG: any;
  polygonLayer: any;
}

interface Action {
  type: string;
  payload?: any;
}

export const initialState: InitialStateType = {
  operationFilter: null,
  operationFilterLabel: {
    durationLabel: '',
    operatorLabel: '',
    tractorLabel: '',
    dateLabel: '',
  },
  operations: [],
  JAG: null,
  polygonLayer: undefined,
};

export default (state = initialState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_OPERATION_FILTER:
      return {
        ...state,
        operationFilter: { ...state.operationFilter, ...payload },
      };
    case SET_OPERATION_FILTER_LABEL:
      return {
        ...state,
        operationFilterLabel: { ...state.operationFilterLabel, ...payload },
      };
    case SET_SELECTED_JAG:
      return { ...state, JAG: payload };
    case SET_OPERATIONS:
      return { ...state, operations: [...payload] };
    case SET_POLY_LAYER:
      return { ...state, polygonLayer: payload };
    case SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case RESET:
      return initialState;

    default:
      return state;
  }
};
