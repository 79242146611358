import React, { useEffect, useState } from 'react';
import './style.css';
import MapCenterIcon from '../../assets/images/c_loc.svg';

interface Props {
  frameHTML: any;
  mapSmallElement: any;
  mapElement: any;
  centreEnabledRef: any;
  handleZoom: any;
  openedView: any;
  layout: number;
}

const CameraGrid1: React.FC<Props> = ({
  frameHTML,
  mapSmallElement,
  mapElement,
  centreEnabledRef,
  handleZoom,
  openedView,
  layout,
}) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (layout == 2) setIndex(0);
    if (layout == 1) setIndex(6);
  }, [layout]);

  const manageClass = (item: number) => {
    let className = '';
    switch (layout) {
      case 1:
        className = index === item ? 'mainCont' : 'child';
        break;
      case 2:
        className = item !== 6 && index === item ? 'lay2Cam' : 'child';
        if (item === 6) className = 'lay2map';
        break;

      default:
        className = 'child';
        break;
    }
    return className;
  };
  return (
    <div className="camerasContainer">
      <div
        className={
          layout === 1
            ? 'layoutOne fr3'
            : layout === 2
            ? 'layoutTwo'
            : 'layoutThree frAuto'
        }
      >
        <div className="w100px">First</div>
        <div
          onClick={() => setIndex(0)}
          // className={index === 0 ? 'mainCont' : 'child'}
          className={manageClass(0)}
        >
          {frameHTML('front')}
        </div>
        <div
          onClick={() => setIndex(1)}
          // className={index === 1 ? 'mainCont' : 'child'}
          className={manageClass(1)}
        >
          {frameHTML('rear')}
        </div>
        <div
          onClick={() => setIndex(2)}
          // className={index === 2 ? 'mainCont' : 'child'}
          className={manageClass(2)}
        >
          {frameHTML('pto')}
        </div>
        <div
          onClick={() => setIndex(3)}
          // className={index === 3 ? 'mainCont' : 'child'}
          className={manageClass(3)}
        >
          {frameHTML('front_left')}
        </div>

        <div
          onClick={() => setIndex(4)}
          // className={index === 4 ? 'mainCont' : 'child'}
          className={manageClass(4)}
        >
          {frameHTML('front_right')}
        </div>
        <div className="w100px">five</div>
        <div
          style={{ display: openedView === 1 ? 'none' : 'block' }}
          onClick={() => setIndex(5)}
          // className={index === 5 ? 'mainCont' : 'child'}
          className={manageClass(5)}
        >
          <div ref={mapSmallElement} style={{ height: '100%' }} />
        </div>

        <div
          style={{ display: openedView === 1 ? 'block' : 'none' }}
          onClick={() => layout != 2 && setIndex(6)}
          // className={index === 6 ? 'mainCont' : 'child'}
          className={manageClass(6)}
        >
          <h6>MAP</h6>
          <div className="mapPathInr">
            <div ref={mapElement} style={{ height: '100%' }} />
            <div
              className="mapRecenter"
              onClick={() => (centreEnabledRef.current = true)}
            >
              <img
                style={{ width: '40px', height: '40px' }}
                src={MapCenterIcon}
              />
            </div>
            <div className="zoom_in_out">
              <button className="zoomIn" onClick={() => handleZoom('zoomIn')} />
              <button
                className="zoomOut"
                onClick={() => handleZoom('zoomOut')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CameraGrid1;
