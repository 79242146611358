/* eslint-disable no-console */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-key */
import React, { useState, useContext, useEffect } from 'react';
import { Input, Modal, notification, Select, Slider, Spin, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import './ImpStyle.css';
import {
  onKeyDown,
  onlyImplmentsNumberAndDecimalsAllow,
} from '../../constants/Common';
import { getCategoryList, UpdateImplements } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import classNames from 'classnames';

interface Props {
  showModal: boolean;
  handleCancel: () => void;
  implement: any;
  Close: () => void;
  setRefresh: () => void;
}

const AutonomySettings: React.FC<Props> = ({
  showModal,
  handleCancel,
  implement,
  Close,
  setRefresh,
}: Props) => {
  const { t } = useTranslation();
  const [details, setDetails] = useState<any>({});
  const { userDetails } = useContext(ApplicationContext);
  const [hitchMode, sethitchMode] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [validations, setValidations] = useState<any>({
    imp_ctrl_in_row_tractor_speed: true,
    imp_ctrl_in_row_3pt_hitch_mode: true,
    imp_ctrl_in_row_3pt_hitch_height: true,
    imp_ctrl_in_row_pto_rpm: true,
    imp_ctrl_in_row_toplink_pos: true,
    imp_ctrl_in_row_aux_hydraulics_1: true,
    imp_ctrl_in_row_aux_hydraulics_2: true,
    imp_ctrl_in_row_aux_hydraulics_6: true,
    imp_ctrl_in_row_generic_ctrl_5: true,
    imp_ctrl_in_row_generic_ctrl_6: true,
    imp_ctrl_out_of_row_tractor_speed: true,
    imp_ctrl_out_of_row_3pt_hitch_mode: true,
    imp_ctrl_out_of_row_3pt_hitch_height: true,
    imp_ctrl_out_of_row_pto_rpm: true,
    imp_ctrl_out_of_row_toplink_pos: true,
    imp_ctrl_out_of_row_aux_hydraulics_1: true,
    imp_ctrl_out_of_row_aux_hydraulics_2: true,
    imp_ctrl_out_of_row_aux_hydraulics_6: true,
    imp_ctrl_out_of_row_generic_ctrl_5: true,
    imp_ctrl_out_of_row_generic_ctrl_6: true,
    enabled: false,
  });

  useEffect(() => {
    getProfileLookps();
  }, []);
  const getProfileLookps = async () => {
    const cropsdata = await getCategoryList(
      userDetails.organization.api_url,
      '3pt_hitch_mode',
    );
    sethitchMode(cropsdata);
  };

  useEffect(() => {
    const {
      config_implements_uuid,
      imp_ctrl_in_row_3pt_hitch_height,
      imp_ctrl_in_row_3pt_hitch_mode,
      imp_ctrl_in_row_pto,
      imp_ctrl_in_row_pto_rpm,
      imp_ctrl_in_row_tractor_speed,
      imp_ctrl_out_of_row_3pt_hitch_height,
      imp_ctrl_out_of_row_3pt_hitch_mode,
      imp_ctrl_out_of_row_pto,
      imp_ctrl_out_of_row_tractor_speed,
      imp_ctrl_in_row_toplink_pos,
      imp_ctrl_in_row_aux_hydraulics_1,
      imp_ctrl_in_row_aux_hydraulics_2,
      imp_ctrl_in_row_aux_hydraulics_6,
      imp_ctrl_in_row_generic_ctrl_1,
      imp_ctrl_in_row_generic_ctrl_2,
      imp_ctrl_in_row_generic_ctrl_3,
      imp_ctrl_in_row_generic_ctrl_4,
      imp_ctrl_in_row_generic_ctrl_5,
      imp_ctrl_in_row_generic_ctrl_6,
      imp_ctrl_out_of_row_pto_rpm,
      imp_ctrl_out_of_row_toplink_pos,
      imp_ctrl_out_of_row_aux_hydraulics_1,
      imp_ctrl_out_of_row_aux_hydraulics_2,
      imp_ctrl_out_of_row_aux_hydraulics_6,
      imp_ctrl_out_of_row_generic_ctrl_1,
      imp_ctrl_out_of_row_generic_ctrl_2,
      imp_ctrl_out_of_row_generic_ctrl_3,
      imp_ctrl_out_of_row_generic_ctrl_4,
      imp_ctrl_out_of_row_generic_ctrl_5,
      imp_ctrl_out_of_row_generic_ctrl_6,
      name,
      config_imm,
      config_imm_uuid,
      power,
      attachment_type,
      implement_type,
      serial_number,
      floating_hitch,
      image_url,
      height,
      width,
      length,
      weight,
    } = implement;
    const { manufacturer, model } = config_imm;
    setDetails({
      config_imm_uuid,
      config_implements_uuid,
      imp_ctrl_in_row_3pt_hitch_height: imp_ctrl_in_row_3pt_hitch_height
        ? imp_ctrl_in_row_3pt_hitch_height
        : 0,
      imp_ctrl_in_row_3pt_hitch_mode,
      imp_ctrl_in_row_pto,
      imp_ctrl_in_row_pto_rpm: imp_ctrl_in_row_pto_rpm
        ? imp_ctrl_in_row_pto_rpm
        : 0,
      imp_ctrl_in_row_tractor_speed,
      imp_ctrl_out_of_row_3pt_hitch_height: imp_ctrl_out_of_row_3pt_hitch_height
        ? imp_ctrl_out_of_row_3pt_hitch_height
        : 0,
      imp_ctrl_out_of_row_3pt_hitch_mode,
      imp_ctrl_out_of_row_pto,
      imp_ctrl_out_of_row_tractor_speed,

      imp_ctrl_in_row_toplink_pos: imp_ctrl_in_row_toplink_pos
        ? imp_ctrl_in_row_toplink_pos
        : 0,
      imp_ctrl_in_row_aux_hydraulics_1: imp_ctrl_in_row_aux_hydraulics_1
        ? imp_ctrl_in_row_aux_hydraulics_1
        : 0,
      imp_ctrl_in_row_aux_hydraulics_2: imp_ctrl_in_row_aux_hydraulics_2
        ? imp_ctrl_in_row_aux_hydraulics_2
        : 0,
      imp_ctrl_in_row_aux_hydraulics_6: imp_ctrl_in_row_aux_hydraulics_6
        ? imp_ctrl_in_row_aux_hydraulics_6
        : 0,
      imp_ctrl_in_row_generic_ctrl_1,
      imp_ctrl_in_row_generic_ctrl_2,
      imp_ctrl_in_row_generic_ctrl_3,
      imp_ctrl_in_row_generic_ctrl_4,
      imp_ctrl_in_row_generic_ctrl_5: imp_ctrl_in_row_generic_ctrl_5
        ? imp_ctrl_in_row_generic_ctrl_5
        : 0,
      imp_ctrl_in_row_generic_ctrl_6: imp_ctrl_in_row_generic_ctrl_6
        ? imp_ctrl_in_row_generic_ctrl_6
        : 0,
      imp_ctrl_out_of_row_toplink_pos: imp_ctrl_out_of_row_toplink_pos
        ? imp_ctrl_out_of_row_toplink_pos
        : 0,
      imp_ctrl_out_of_row_pto_rpm: imp_ctrl_out_of_row_pto_rpm
        ? imp_ctrl_out_of_row_pto_rpm
        : 0,

      imp_ctrl_out_of_row_aux_hydraulics_1: imp_ctrl_out_of_row_aux_hydraulics_1
        ? imp_ctrl_out_of_row_aux_hydraulics_1
        : 0,
      imp_ctrl_out_of_row_aux_hydraulics_2: imp_ctrl_out_of_row_aux_hydraulics_2
        ? imp_ctrl_out_of_row_aux_hydraulics_2
        : 0,
      imp_ctrl_out_of_row_aux_hydraulics_6: imp_ctrl_out_of_row_aux_hydraulics_6
        ? imp_ctrl_out_of_row_aux_hydraulics_6
        : 0,
      imp_ctrl_out_of_row_generic_ctrl_1,
      imp_ctrl_out_of_row_generic_ctrl_2,
      imp_ctrl_out_of_row_generic_ctrl_3,
      imp_ctrl_out_of_row_generic_ctrl_4,
      imp_ctrl_out_of_row_generic_ctrl_5: imp_ctrl_out_of_row_generic_ctrl_5
        ? imp_ctrl_out_of_row_generic_ctrl_5
        : 0,
      imp_ctrl_out_of_row_generic_ctrl_6: imp_ctrl_out_of_row_generic_ctrl_6
        ? imp_ctrl_out_of_row_generic_ctrl_6
        : 0,
      name,
      manufacture: manufacturer,
      model: config_imm_uuid,
      power: power,
      attachment_type: attachment_type,
      implement_type,
      serial_number,
      floating_hitch,
      height,
      width,
      length,
      weight,
    });
  }, [implement]);

  useEffect(() => {
    const isValid = Object.values(validations).every(
      (item) => item || item === 0,
    );
    if (isValid && Object.keys(validations).length) updateImplement();
  }, [validations]);

  const saveAutonomy = () => {
    setValidations({
      ...validations,
      imp_ctrl_in_row_tractor_speed: details?.imp_ctrl_in_row_tractor_speed,
      imp_ctrl_in_row_3pt_hitch_mode: details?.imp_ctrl_in_row_3pt_hitch_mode,
      imp_ctrl_in_row_3pt_hitch_height:
        details?.imp_ctrl_in_row_3pt_hitch_height,
      imp_ctrl_in_row_pto_rpm: details?.imp_ctrl_in_row_pto_rpm,
      imp_ctrl_in_row_toplink_pos: details?.imp_ctrl_in_row_toplink_pos,
      imp_ctrl_in_row_aux_hydraulics_1:
        details?.imp_ctrl_in_row_aux_hydraulics_1,
      imp_ctrl_in_row_aux_hydraulics_2:
        details?.imp_ctrl_in_row_aux_hydraulics_2,
      imp_ctrl_in_row_aux_hydraulics_6:
        details?.imp_ctrl_in_row_aux_hydraulics_6,
      imp_ctrl_in_row_generic_ctrl_5: details?.imp_ctrl_in_row_generic_ctrl_5,
      imp_ctrl_in_row_generic_ctrl_6: details?.imp_ctrl_in_row_generic_ctrl_6,
      imp_ctrl_out_of_row_tractor_speed:
        details?.imp_ctrl_out_of_row_tractor_speed,
      imp_ctrl_out_of_row_3pt_hitch_mode:
        details?.imp_ctrl_out_of_row_3pt_hitch_mode,
      imp_ctrl_out_of_row_3pt_hitch_height:
        details?.imp_ctrl_out_of_row_3pt_hitch_height,
      imp_ctrl_out_of_row_pto_rpm: details?.imp_ctrl_out_of_row_pto_rpm,
      imp_ctrl_out_of_row_toplink_pos: details?.imp_ctrl_out_of_row_toplink_pos,
      imp_ctrl_out_of_row_aux_hydraulics_1:
        details?.imp_ctrl_out_of_row_aux_hydraulics_1,
      imp_ctrl_out_of_row_aux_hydraulics_2:
        details?.imp_ctrl_out_of_row_aux_hydraulics_2,
      imp_ctrl_out_of_row_aux_hydraulics_6:
        details?.imp_ctrl_out_of_row_aux_hydraulics_6,
      imp_ctrl_out_of_row_generic_ctrl_5:
        details?.imp_ctrl_out_of_row_generic_ctrl_5,
      imp_ctrl_out_of_row_generic_ctrl_6:
        details?.imp_ctrl_out_of_row_generic_ctrl_6,
      enabled: true,
    });
  };

  const updateImplement = async () => {
    const data = {
      ...details,
      config_imm_uuid: details.model,
      imp_ctrl_in_row_tractor_speed: Number(
        details?.imp_ctrl_in_row_tractor_speed,
      ),
      imp_ctrl_out_of_row_tractor_speed: Number(
        details?.imp_ctrl_out_of_row_tractor_speed,
      ),
    };
    try {
      setLoader(true);
      const response: any = await UpdateImplements(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        implement?.config_implements_uuid,
        data,
      );
      handleCancel();
      Close();
      setRefresh();
      notification.success({ message: t(translate.label.implementUpdated) });
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const CSlider = (field: string, placeholder: string, range: any) => {
    const [min, max] = range;
    return (
      <>
        <div className="fieldRange">
          <Input
            type="number"
            style={{ width: '284px' }}
            placeholder={placeholder}
            onKeyDown={(e: any) => onKeyDown(e)}
            className={classNames({
              errorClass: validations[field] === undefined,
            })}
            value={details[field]}
            onChange={(e) => {
              handleOnChangeNum(e, field, min, max);
            }}
          />
        </div>
      </>
    );
  };

  const handleOnChangeNum = (e: any, field: any, min: any, max: any) => {
    const str = e.target.value;
    const regx = /^[+-]?\d+(\.\d+)?$/;
    if (
      min <= Number(str) &&
      Number(str) <= max &&
      (str == 0 || regx.test(str))
    ) {
      setDetails({
        ...details,
        [field]: str.trim() === '' ? '' : Number(str),
      });
    } else if (str?.length == 1 && str?.trim() === '')
      setDetails({
        ...details,
        [field]: '',
      });
    else {
      setDetails({
        ...details,
        [field]: details[field] ? details[field] : '',
      });
    }
  };

  return (
    <Modal
      title=""
      visible={showModal}
      className="commonPopup impSetpopup autonomySettingModal"
      onCancel={handleCancel}
      onOk={saveAutonomy}
      okText={t(translate.buttons.save)}
      okButtonProps={{
        className: 'okBtn autonomySaveBtn',
      }}
      cancelButtonProps={{
        className: 'autocanelBtn',
      }}
    >
      <div style={{ marginBottom: 6 }}></div>

      <div className="autonomymodal autonomy_modal_sec">
        <div className="autoHeads mb20">
          <div>
            <h6 className="mt32 headline2 fSbd">Autonomy Controls</h6>
            <h6 className="headline2 fSbd">In Row</h6>
          </div>
          <div className="">
            <h6 className="mt32 headline2 fSbd">Autonomy Controls</h6>
            <h6 className="headline2 fSbd">Out of Row</h6>
          </div>
        </div>
        <div className="autoHeads ovfAuto">
          <div className="autonomywidth">
            <div className="rowGapBtm20">
              <label className="form-label overline1">Tractor Speed</label>
              <div className="width284 posrel ipw fieldRange">
                <>
                  <span className="posabs">MPH</span>
                </>
                <Input
                  type="number"
                  placeholder="Tractor Speed"
                  value={details?.imp_ctrl_in_row_tractor_speed}
                  onKeyDown={(e: any) => onKeyDown(e)}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      imp_ctrl_in_row_tractor_speed: e.target.value,
                    })
                  }
                  className={classNames({
                    errorClass: !validations.imp_ctrl_in_row_tractor_speed,
                  })}
                  onKeyPress={(e: any) =>
                    onlyImplmentsNumberAndDecimalsAllow(e)
                  }
                />
              </div>
            </div>
            <div className="rowGapBtm20">
              <label className="form-label overline1">3pt Hitch Mode</label>
              <div className="">
                <Select
                  style={{ width: '284px' }}
                  placeholder="3pt Hitch Mode"
                  value={details?.imp_ctrl_in_row_3pt_hitch_mode}
                  onSelect={(imp_ctrl_in_row_3pt_hitch_mode) =>
                    setDetails({ ...details, imp_ctrl_in_row_3pt_hitch_mode })
                  }
                  className={classNames({
                    errorClass: !validations.imp_ctrl_in_row_3pt_hitch_mode,
                  })}
                >
                  {hitchMode.map((ele: any) => {
                    return <option value={ele?.name}>{ele?.name}</option>;
                  })}
                </Select>
              </div>
            </div>
            <div className="rowGapBtm20">
              <label className="form-label overline1">3pt Hitch Height</label>
              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_in_row_3pt_hitch_height',
                  '3pt Hitch Height',
                  [0.0, 100.0],
                )}
              </div>
            </div>
            <div className="rowGapBtm20">
              <label className="form-label overline1">PTO</label>

              <div className="">
                <Select
                  style={{ width: '284px' }}
                  placeholder="PTO"
                  onSelect={(imp_ctrl_in_row_pto) =>
                    setDetails({
                      ...details,
                      imp_ctrl_in_row_pto:
                        imp_ctrl_in_row_pto === 'true' ? true : false,
                    })
                  }
                  value={details.imp_ctrl_in_row_pto ? 'true' : 'false'}
                >
                  <option value="" key="empty">
                    Select
                  </option>
                  <option value="true" key="true">
                    On
                  </option>
                  <option value="false" key="false">
                    Off
                  </option>
                </Select>
              </div>
            </div>
            <div className="rowGapBtm20">
              <label className="form-label overline1">PTO RPM</label>
              <div className="width284  posrel ipw">
                <>
                  <span className="posabs">RPM</span>
                </>
                {CSlider('imp_ctrl_in_row_pto_rpm', 'PTO RPM', [0.0, 540.0])}
              </div>
            </div>
            <div className="rowGapBtm20">
              <label className="form-label overline1">TOP Link</label>
              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_in_row_toplink_pos',
                  'Top Link',
                  [0.0, 100.0],
                )}
              </div>
            </div>
            <div className="rowGapBtm20">
              <label className="form-label overline1">Aux Hydraulics1</label>
              <div className="width284  posrel ipw">
                {CSlider(
                  'imp_ctrl_in_row_aux_hydraulics_1',
                  'Aux Hydraulics1',
                  [0.0, 8.0],
                )}
              </div>
            </div>
            <div className="rowGapBtm20">
              <label className="form-label overline1">Aux Hydraulics2</label>
              <div className="width284  posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_in_row_aux_hydraulics_2',
                  'Aux Hydraulics2',
                  [0.0, 100.0],
                )}
              </div>
            </div>
            <div className="rowGapBtm20">
              <label className="form-label overline1">Aux Hydraulics6</label>
              <div className="width284  posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_in_row_aux_hydraulics_6',
                  'Aux Hydraulics6',
                  [0.0, 100.0],
                )}
              </div>
            </div>

            <div className="generictoggle body2 rowGapBtm20">
              <span>Generic Ctrl 1</span>
              <Switch
                checked={details?.imp_ctrl_in_row_generic_ctrl_1}
                onChange={(imp_ctrl_in_row_generic_ctrl_1) =>
                  setDetails({
                    ...details,
                    imp_ctrl_in_row_generic_ctrl_1:
                      imp_ctrl_in_row_generic_ctrl_1 === false ? false : true,
                  })
                }
              />
            </div>

            <div className="generictoggle body2 rowGapBtm20">
              <span>Generic Ctrl 2</span>
              <Switch
                checked={details?.imp_ctrl_in_row_generic_ctrl_2}
                onChange={(imp_ctrl_in_row_generic_ctrl_2) =>
                  setDetails({
                    ...details,
                    imp_ctrl_in_row_generic_ctrl_2:
                      imp_ctrl_in_row_generic_ctrl_2 === false ? false : true,
                  })
                }
              />
            </div>
            <div className="generictoggle body2 rowGapBtm20">
              <span>Generic Ctrl 3</span>
              <Switch
                checked={details?.imp_ctrl_in_row_generic_ctrl_3}
                onChange={(imp_ctrl_in_row_generic_ctrl_3) =>
                  setDetails({
                    ...details,
                    imp_ctrl_in_row_generic_ctrl_3:
                      imp_ctrl_in_row_generic_ctrl_3 === false ? false : true,
                  })
                }
              />
            </div>
            <div className="generictoggle body2 rowGapBtm20">
              <span>Generic Ctrl 4</span>
              <Switch
                checked={details?.imp_ctrl_in_row_generic_ctrl_4}
                onChange={(imp_ctrl_in_row_generic_ctrl_4) =>
                  setDetails({
                    ...details,
                    imp_ctrl_in_row_generic_ctrl_4:
                      imp_ctrl_in_row_generic_ctrl_4 === false ? false : true,
                  })
                }
              />
            </div>

            <div className="rowGapBtm20">
              <label className="form-label overline1">Generic Ctrl5</label>

              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_in_row_generic_ctrl_5',
                  'Generic Ctrl5',
                  [0.0, 100.0],
                )}
              </div>
            </div>

            <div className="rowGapBtm20">
              <label className="form-label overline1">Generic Ctrl6</label>

              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_in_row_generic_ctrl_6',
                  'Generic Ctrl6',
                  [0.0, 100.0],
                )}
              </div>
            </div>
          </div>
          <div className="autonomywidth">
            <div className="rowGapBtm20">
              <label className="form-label overline1">Tractor Speed</label>
              <div className="width284 posrel ipw fieldRange">
                <>
                  <span className="posabs">MPH</span>
                </>
                <Input
                  type="number"
                  placeholder="Tractor Speed"
                  onKeyDown={(e: any) => onKeyDown(e)}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      imp_ctrl_out_of_row_tractor_speed: e.target.value,
                    })
                  }
                  className={classNames({
                    errorClass: !validations.imp_ctrl_out_of_row_tractor_speed,
                  })}
                  value={details?.imp_ctrl_out_of_row_tractor_speed}
                  onKeyPress={(e: any) =>
                    onlyImplmentsNumberAndDecimalsAllow(e)
                  }
                />
              </div>
            </div>
            <div className="rowGapBtm20">
              <label className="form-label overline1">3pt Hitch Mode</label>
              <div className="">
                <Select
                  style={{ width: '284px' }}
                  placeholder="3pt Hitch Mode"
                  value={details?.imp_ctrl_out_of_row_3pt_hitch_mode}
                  onSelect={(imp_ctrl_out_of_row_3pt_hitch_mode) =>
                    setDetails({
                      ...details,
                      imp_ctrl_out_of_row_3pt_hitch_mode,
                    })
                  }
                  className={classNames({
                    errorClass: !validations.imp_ctrl_out_of_row_3pt_hitch_mode,
                  })}
                >
                  {hitchMode.map((ele: any) => {
                    return <option value={ele?.name}>{ele?.name}</option>;
                  })}
                </Select>
              </div>
            </div>
            <div className="rowGapBtm20">
              <label className="form-label overline1">3pt Hitch Height</label>
              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_out_of_row_3pt_hitch_height',
                  '3pt Hitch Height',
                  [0.0, 100.0],
                )}
              </div>
            </div>
            <div className="rowGapBtm20">
              <label className="form-label overline1">PTO</label>
              <div className="">
                <Select
                  style={{ width: '284px' }}
                  placeholder="PTO"
                  onSelect={(imp_ctrl_out_of_row_pto) =>
                    setDetails({
                      ...details,
                      imp_ctrl_out_of_row_pto:
                        imp_ctrl_out_of_row_pto === 'true' ? true : false,
                    })
                  }
                  value={details.imp_ctrl_out_of_row_pto ? 'true' : 'false'}
                >
                  <option value="true" key="true">
                    On
                  </option>
                  <option value="false" key="false">
                    Off
                  </option>
                </Select>
              </div>
            </div>
            <div className="rowGapBtm20">
              <label className="form-label overline1">PTO RPM</label>
              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">RPM</span>
                </>
                {CSlider(
                  'imp_ctrl_out_of_row_pto_rpm',
                  'PTO RPM',
                  [0.0, 540.0],
                )}
              </div>
            </div>
            <div className="rowGapBtm20">
              <label className="form-label overline1">TOP Link</label>

              <div className="width284  posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_out_of_row_toplink_pos',
                  'Top Link',
                  [0.0, 100.0],
                )}
              </div>
            </div>
            <div className="rowGapBtm20">
              <label className="form-label overline1">Aux Hydraulics1</label>
              <div className="width284  posrel ipw">
                {CSlider(
                  'imp_ctrl_out_of_row_aux_hydraulics_1',
                  'Aux Hydraulics1',
                  [0.0, 8.0],
                )}
              </div>
            </div>
            <div className="rowGapBtm20">
              <label className="form-label overline1">Aux Hydraulics2</label>
              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_out_of_row_aux_hydraulics_2',
                  'Aux Hydraulics2',
                  [0.0, 100.0],
                )}
              </div>
            </div>
            <div className="rowGapBtm20">
              <label className="form-label overline1">Aux Hydraulics6</label>

              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_out_of_row_aux_hydraulics_6',
                  'Aux Hydraulics6',
                  [0.0, 100.0],
                )}
              </div>
            </div>
            <div className="generictoggle body2 rowGapBtm20">
              <span>Generic Ctrl 1</span>
              <Switch
                checked={details?.imp_ctrl_out_of_row_generic_ctrl_1}
                onChange={(imp_ctrl_out_of_row_generic_ctrl_1) =>
                  setDetails({
                    ...details,
                    imp_ctrl_out_of_row_generic_ctrl_1:
                      imp_ctrl_out_of_row_generic_ctrl_1 === false
                        ? false
                        : true,
                  })
                }
              />
            </div>

            <div className="generictoggle body2 rowGapBtm20">
              <span>Generic Ctrl 2</span>
              <Switch
                checked={details?.imp_ctrl_out_of_row_generic_ctrl_2}
                onChange={(imp_ctrl_out_of_row_generic_ctrl_2) =>
                  setDetails({
                    ...details,
                    imp_ctrl_out_of_row_generic_ctrl_2:
                      imp_ctrl_out_of_row_generic_ctrl_2 === false
                        ? false
                        : true,
                  })
                }
              />
            </div>

            <div className="generictoggle body2 rowGapBtm20">
              <span>Generic Ctrl 3</span>
              <Switch
                checked={details?.imp_ctrl_out_of_row_generic_ctrl_3}
                onChange={(imp_ctrl_out_of_row_generic_ctrl_3) =>
                  setDetails({
                    ...details,
                    imp_ctrl_out_of_row_generic_ctrl_3:
                      imp_ctrl_out_of_row_generic_ctrl_3 === false
                        ? false
                        : true,
                  })
                }
              />
            </div>

            <div className="generictoggle body2 rowGapBtm20">
              <span>Generic Ctrl 4</span>
              <Switch
                checked={details?.imp_ctrl_out_of_row_generic_ctrl_4}
                onChange={(imp_ctrl_out_of_row_generic_ctrl_4) =>
                  setDetails({
                    ...details,
                    imp_ctrl_out_of_row_generic_ctrl_4:
                      imp_ctrl_out_of_row_generic_ctrl_4 === false
                        ? false
                        : true,
                  })
                }
              />
            </div>

            <div className="rowGapBtm20">
              <label className="form-label overline1">Generic Ctrl5</label>

              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_out_of_row_generic_ctrl_5',
                  'Generic Ctrl5',
                  [0.0, 100.0],
                )}
              </div>
            </div>

            <div className="rowGapBtm20">
              <label className="form-label overline1">Generic Ctrl6</label>

              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_out_of_row_generic_ctrl_6',
                  'Generic Ctrl6',
                  [0.0, 100.0],
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AutonomySettings;
