/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { MenuItem, RowTicketDetails, UserDetails } from '../../constants/types';
import {
  getjumpandgoview,
  getTaskmasterHistory,
  getTicketbyId,
} from '../../constants/Api';
import tasksImg from '../../assets/images/tasks.svg';
import jumpGo from '../../assets/images/jump_go.svg';
import { getDateTime } from '../../constants/Common';

export const list: MenuItem[] = [
  {
    name: 'Tasks',
    image_url: tasksImg,
    type: 'TASK',
    layer: true,
  },
  {
    name: 'Jump & Go',
    image_url: jumpGo,
    type: 'JNG',
    layer: true,
  },
];

export const getTasks = async (
  userDetails: UserDetails,
  fromtime: any,
  totime: any,
  tractorId?: any,
) => {
  const taskhistory = await getTaskmasterHistory(
    userDetails.organization.api_url,
    userDetails.organization_id,
    'Closed',
    1,
    50,
    '',
    fromtime,
    totime,
    tractorId,
  );
  return taskhistory;
};

export const getJumpandGo = async (
  userDetails: UserDetails,
  fromtime: any,
  totime: any,
  tractorId?: any,
  userId?: any,
  duration?: any,
) => {
  const jumpgolist = await getjumpandgoview(
    userDetails.organization.api_url,
    1,
    50,
    (tractorId = tractorId ? tractorId : ''),
    fromtime,
    totime,
    (userId = userId ? userId : ''),
    (duration = duration ? duration : ''),
  );
  return jumpgolist;
};

export const getFleetTicketDetails = async (
  record: any,
  userDetails: UserDetails,
) => {
  const data: RowTicketDetails = await getTicketbyId(
    userDetails.organization.api_url,
    userDetails.organization.fleet.id,
    record,
  );
  const obj = {
    created_date_time: getDateTime(data.created_date_time),
    date_time: data.created_date_time,
    name: data.tractor?.name,
    id: data.id,
    status: data.status,
    fleet_id: data.fleet_id,
    comments: data.comments,
    ticket_id: data.ticket_id,
    tractor_id: data.tractor_id,
    ticket_description: data.ticket_description,
    first_name:
      data.created_by_user &&
      data.created_by_user.first_name + ' ' + data.created_by_user.last_name,
    ticket_level: data.ticket_level,
    completedfirst_name: data.completed_by_user
      ? data.completed_by_user.first_name +
        '' +
        data.completed_by_user.last_name
      : '',
    completed_date_time: data.completed_date_time
      ? getDateTime(data.completed_date_time)
      : '',
    // assignee: data.assignee,
    assignee:
      data.assignee && data.assignee.first_name + ' ' + data.assignee.last_name,
    createdbyid: data.created_by_user && data.created_by_user.id,
  };
  return obj;
};
