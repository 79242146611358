import React, { useState, useContext, useEffect } from 'react';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import { ApplicationContext } from '../../context/AppContext';
import { Layout, Row, Space, Card, Input, notification, Popover } from 'antd';
import applicationIds from '../../locale/applicationIds.json';
import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import '../fleethealth/style.css';
import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import { SoftwareModal } from './SoftwareModal';
import { VersionHistoryModal } from './VersionHistoryModal';
import verHisIcon from '../../assets/images/Group 1296.svg';
import dwlIcon from '../../assets/images/dwlIcon.svg';
import dwlingIcon from '../../assets/images/verDownload.svg';
import { getSoftwaresList, getTractorsData } from '../../constants/Api';
import { Software } from '../../constants/types';
import { useMixpanel } from 'react-mixpanel-browser';
import { privilagesConstants } from '../../constants/Privilages';
import {
  getStatusForTractor,
  getTractorAvailableState,
  getTractorStatusClass,
  tractorSort,
} from '../../constants/Common';
import SoftwareHistoryLogs from './SoftwareHistoryLogs';
import CSelect from '../common/CSelect';
import { softUpdateFilterData } from '../../constants/AppData';
import { useLocation } from 'react-router-dom';
export const SOFTWARE = 'software';
export const FIRMAWARE = 'firmware';
interface Props {
  onSubmit?: (content: string) => void;
}
export const SoftwareUpdate: React.FC<Props> = ({ onSubmit }: Props) => {
  const { privilegeChecker } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'name',
    order: 'ascend',
  });
  const [search, setSearch] = useState<string>('');
  const [softwaresList, setSoftwaresList] = useState<Software[]>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [totalcount, settotalcount] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [updateStatus, setUpdateStatus] = useState<string>('');
  const [searchString, setSearchString] = useState<string>('');
  const { userDetails } = useContext(ApplicationContext);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] = useState<Software>();
  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);
  const [tId, setTId] = useState<number>(0);
  const location: any = useLocation();

  const showModal = (record: any) => {
    setIsModalVisible(true);
    setSelectedItem(record);
  };
  const [sortedData, setSortedData] = useState<any>({
    name: 'ascend',
  });
  const [logData, setLogData] = useState();
  const mixpanel = useMixpanel();

  useEffect(() => {
    if (search != '') {
      setPageNumber(1);
    }
    if (userDetails && userDetails.organization) {
      getSoftwares();
    }
  }, [userDetails, pageNumber, search]);

  useEffect(() => {
    if (softwaresList.length && tId) {
      openSoftwareModal();
      setTId(0);
    }
  }, [tId, softwaresList]);
  useEffect(() => {
    if (location.state && location.state.action == 'SOFTWARE') {
      setTId(location?.state?.tractorId);
    }
    return () => {
      location.state = undefined;
    };
  }, [location]);

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  const replaceAt = (str: string | undefined) => {
    if (str && str.length) {
      const index = str.indexOf(':');
      let chars: any = str.split('');
      chars[index] = '\n';
      chars = chars
        .join('')
        .split('|')
        .join(`\n`)
        // .replace('Code changes are detected, they are', 'Build is modified')
        .replace('Code changes are detected, they are', '')
        .replace(/Repo name :/g, '')
        .replace(/, system is/g, ' : ');

      chars = chars.split('\n').map((item: any) => {
        item = item.split(':').reverse().join(' : ');
        return item;
      });
      chars = chars.join('\n');
      chars = `Build is modified ${chars}`;
      return chars;
    } else {
      return '';
    }
  };

  const showUpdaterIcon = (record: any) => {
    return (
      record &&
      record?.latest_software?.software_version &&
      record?.heartbeat?.software_version !==
        record?.latest_software?.software_version
    );
  };

  const showUpdaterIcon1 = (record: any) => {
    return (
      record &&
      record?.latest_firmware?.software_version &&
      record?.heartbeat?.firmware_version !==
        record?.latest_firmware?.software_version
    );
  };

  const columns: any = [
    {
      dataIndex: 'name',
      key: 'name',
      sorter: () => false,
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      width: '20%',
      ellipsis: true,
      showSorterTooltip: false,
      title: () => {
        return (
          <>
            {t(translate.baseStation.tractorName)}
            {sortedInfo.columnKey === 'name' && (
              <img
                className="sort-pad"
                src={sortedData['name'] === 'ascend' ? sortUp : sortDown}
              />
            )}
          </>
        );
      },
      render: (name: string, record: any) => {
        return (
          <>
            <div
              className="flex-space-between jfStart"
              data-testid={`${name}-Updates`}
            >
              <span className="upTname">{name}</span>
            </div>
          </>
        );
      },
    },
    {
      title: `${t(translate.fleet.status)}`,
      width: '150px',
      render: (record: any) => {
        return (
          <span
            className={getTractorStatusClass(
              record.isAvailable,
              record?.heartbeat?.drive_action_details?.drive_action_created_at,
            )}
          >
            {getStatusForTractor(
              record?.isAvailable,
              record?.heartbeat?.drive_action_details?.drive_action_created_at,
            )}
          </span>
        );
      },
    },
    {
      title: `${t(translate.baseStation.pin)}`,
      dataIndex: 'tractor_pin',
      key: 'tractor_pin',
      width: '15%',
    },
    {
      title: `${t(translate.baseStation.tractorModal)}`,
      dataIndex: 'tractor_model',
      key: 'tractor_model',
      width: '15%',
    },
    {
      dataIndex: 'software_version',
      render: (id: string, record: Software) => {
        return (
          <>
            <span>
              {record &&
              record?.data &&
              record?.data?.software &&
              record?.data?.software?.software_update_status &&
              record?.data?.software?.software_update_status ===
                'in-progress' ? (
                <span>
                  Updating
                  <img
                    src={dwlingIcon}
                    height="38px"
                    alt="download"
                    className="ml80"
                  />
                </span>
              ) : (
                <div className="versionList">
                  <span className="vSNo">
                    {id}{' '}
                    <span
                      style={{
                        color: 'red',
                        fontWeight: 'bold',
                        fontSize: 14,
                      }}
                    >
                      {record?.data?.software?.is_software_build_modified
                        ? '*'
                        : ''}
                    </span>
                  </span>

                  {privilegeChecker(privilagesConstants.Begin_an_update) &&
                    showUpdaterIcon(record) && (
                      <div>
                        <Popover
                          title={`Version ${record?.latest_software?.software_version} available now. Please Update.`}
                          placement="topRight"
                        >
                          <img src={verHisIcon} className="upMl" />
                        </Popover>

                        <img
                          src={dwlIcon}
                          height="38px"
                          alt="download"
                          className="upMl cPointer"
                          onClick={() => {
                            showModal({ ...record, type: SOFTWARE });
                            mixpanel.track('OpenSoftwareUpdated', {
                              event: 'Software Modal Open',
                            });
                          }}
                        />
                      </div>
                    )}
                </div>
              )}
            </span>
          </>
        );
      },
      key: 'software_version',
      sorter: (a: any, b: any) => {
        return a.software_version.localeCompare(b.software_version);
      },
      sortOrder:
        sortedInfo.columnKey === 'software_version' && sortedInfo.order,
      ellipsis: true,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend', 'descend'],
      title: () => {
        return (
          <>
            {t(translate.headers.softwareVersion)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'software_version' && (
                <img
                  className="sort-pad"
                  src={
                    sortedData['software_version'] === 'ascend'
                      ? sortUp
                      : sortDown
                  }
                />
              )}
            </span>
          </>
        );
      },
    },
    {
      dataIndex: 'firmware_version',
      render: (id: string, record: Software) => {
        return (
          <>
            <span>
              {record &&
              record?.data &&
              record?.data?.firmware &&
              record?.data?.firmware?.software_update_status &&
              record?.data?.firmware?.software_update_status ===
                'in-progress' ? (
                <span>
                  Updating
                  <img
                    src={dwlingIcon}
                    height="38px"
                    alt="download"
                    className="upMl"
                  />
                </span>
              ) : (
                <div className="versionList">
                  <span className="vSNo">
                    {id}{' '}
                    <span
                      style={{
                        color: 'red',
                        fontWeight: 'bold',
                        fontSize: 14,
                      }}
                    >
                      {record?.data?.firmware?.is_software_build_modified
                        ? '*'
                        : ''}
                    </span>{' '}
                  </span>
                  {privilegeChecker(privilagesConstants.Begin_an_update) &&
                    showUpdaterIcon1(record) && (
                      <div>
                        <Popover
                          placement="topRight"
                          title={`Version ${record?.latest_firmware?.software_version} available now. Please Update.`}
                        >
                          <img src={verHisIcon} className="upMl" />
                        </Popover>
                        <img
                          src={dwlIcon}
                          height="38px"
                          alt="download"
                          className="upMl cPointer"
                          onClick={() => {
                            showModal({ ...record, type: FIRMAWARE });
                            mixpanel.track('OpenFirmWareUpdated', {
                              event: 'FirmWare Modal Open',
                            });
                          }}
                        />
                      </div>
                    )}
                </div>
              )}
            </span>
          </>
        );
      },
      key: 'firmware_version',
      sorter: (a: any, b: any) => {
        return a.firmware_version.localeCompare(b.firmware_version);
      },
      sortOrder:
        sortedInfo.columnKey === 'firmware_version' && sortedInfo.order,
      ellipsis: true,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend', 'descend'],
      title: () => {
        return (
          <>
            {t(translate.headers.firmwareVersion)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'firmware_version' && (
                <img
                  className="sort-pad"
                  src={
                    sortedData['firmware_version'] === 'ascend'
                      ? sortUp
                      : sortDown
                  }
                />
              )}
            </span>
          </>
        );
      },
    },
  ];
  const { Search } = Input;

  const handleLoadMore = () => {
    // setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    if (softwaresList.length) {
      filterList();
    }
  }, [searchString, updateStatus]);

  const filterList = () => {
    let filteredList = [];
    if (updateStatus === 'uptodate') {
      filteredList = softwaresList?.filter((record: Software) => {
        if (
          record &&
          record?.software_version_id === record?.latest_software_version_id &&
          record &&
          record?.firmware_version_id === record?.latest_firmware_version_id &&
          record.name.toString() &&
          record.name
            .toString()
            .toLowerCase()
            .includes(searchString.toLowerCase())
        ) {
          return record;
        }
      });
    } else if (updateStatus === 'update_ready') {
      filteredList = softwaresList?.filter((record: Software) => {
        if (
          ((record &&
            record?.software_version_id !==
              record?.latest_software_version_id) ||
            (record &&
              record?.firmware_version_id !==
                record?.latest_firmware_version_id)) &&
          record.name.toString() &&
          record.name
            .toString()
            .toLowerCase()
            .includes(searchString.toLowerCase())
        ) {
          return record;
        }
      });
    } else {
      filteredList = softwaresList;
    }
    if (searchString) {
      filteredList = filteredList.filter((record: Software) => {
        if (
          record.name.toString() &&
          record.name
            .toString()
            .toLowerCase()
            .includes(searchString.toLowerCase())
        ) {
          return record;
        }
      });
    }
    if (!updateStatus && !searchString) {
      setFilterData(softwaresList);
      settotalcount(softwaresList.length);
    } else {
      settotalcount(filteredList.length);
      setFilterData(filteredList);
    }
  };

  const openSoftwareModal = async () => {
    const response = softwaresList?.filter(
      (software: Software) => software.id === tId,
    );
    if (response && response.length) {
      const list = response[0];
      showModal({ ...list, type: SOFTWARE });
    }
  };

  const getSoftwares = async () => {
    setLoading(true);
    try {
      const softwareReords = await getSoftwaresList(
        userDetails.organization.api_url,
        userDetails.organization.id,
      );
      const data =
        softwareReords && softwareReords.length > 0 ? softwareReords : [];

      const total_count =
        softwareReords?.length > 0 ? softwareReords.length : 0;
      let records: Software[] = [];
      const tractorsMap = await getTractorsList();
      data.map((record: Software) => {
        const heartbeatFirmware =
          tractorsMap[record.id]?.heartbeat?.tractor_model?.split(',');
        const heartbeatFirmwareVersion =
          heartbeatFirmware?.length >= 122 && heartbeatFirmware[121];
        const obj = {
          key: record.id,
          id: record.id,
          name: record.name,
          tractor_pin: tractorsMap[record.id].tractor_pin,
          heartbeat: tractorsMap[record.id].heartbeat,
          tractor_model: record.tractor_model,
          serial_number: record.serial_number,
          software_version: record?.software?.software_version ?? '-',
          firmware_version: heartbeatFirmwareVersion ?? '-',

          software_version_id: record?.software?.software_version_id
            ? record?.software?.software_version_id
            : 0,
          latest_software_version_id:
            record?.software?.latest_software?.software_version_id ?? 0,
          firmware_version_id: record?.firmware?.software_version_id
            ? record?.firmware?.software_version_id
            : 0,
          latest_firmware_version_id: record?.firmware?.latest_firmware
            ?.software_version_id
            ? record?.firmware?.latest_firmware?.software_version_id
            : 0,
          isAvailable: getTractorAvailableState(
            tractorsMap[record.id]?.connection,
            tractorsMap[record.id]?.heartbeat,
          ),
          latest_software: record?.software?.latest_software,
          latest_firmware: record?.firmware?.latest_firmware,
          data: record,
        };
        records.push(obj);
      });
      records = tractorSort(records);
      settotalcount(total_count);
      setSoftwaresList(records);
      setFilterData(records);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const getTractorsList = async () => {
    try {
      const data = await getTractorsData(
        userDetails.organization.api_url,
        userDetails.organization_id,
      );
      const list = data.records && data.records.length > 0 ? data.records : [];
      // list.forEach((element: any) => {
      //   element.isAvailable =
      //     element.connection === 'offline'
      //       ? tractorStateConstants.OFF
      //       : getOperationStatus(element?.heartbeat as TractorHeartBeat);
      // });
      const mapData = list.reduce((a: any, c: any) => {
        a[c.id] = c;
        return a;
      }, {});
      return mapData;
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (sortedInfo.columnKey === 'name') {
      const tdata = filterData.reverse();
      setFilterData([...tdata]);
    }
  }, [sortedInfo.order]);

  const handleSearch = (e: any) => {
    const value = e.target.value.trim().regX;
    onSubmit && onSubmit(value);
    setSearchString(value);
    mixpanel &&
      mixpanel.track('SoftwareUpdate', {
        event: `Search Updates ${e.target.value}`,
      });
  };

  return (
    <>
      <Layout>
        <div className="mainContent">
          <Content>
            <div className="tblContainer selectReport softwareUpdate viewportContainer">
              <Row>
                <Card className="tblTopOutSec tcktsBlk searchFilters filterGap40">
                  <Space
                    className="mt0"
                    style={{ marginBottom: 0, position: 'relative' }}
                  >
                    <div className="searchSec searchUnit">
                      <Search
                        id={
                          applicationIds.personnelScreen
                            .softwareUpdateSearcchText
                        }
                        placeholder="Search Tractors"
                        data-testid="Searchinput-SoftwareUpdate"
                        value={searchString}
                        onChange={(e) => handleSearch(e)}
                      />
                    </div>

                    <div
                      className="smallFilter"
                      data-testid="softwarestatus-SoftwareUpdate"
                    >
                      <CSelect
                        list={softUpdateFilterData}
                        selectTrigger={(selected) => {
                          if (selected) {
                            const { id } = selected;
                            setUpdateStatus(id);
                          } else {
                            setUpdateStatus('show_all');
                          }
                        }}
                        label="Status"
                        defaultId="show_all"
                        filterType={3}
                        dataTestkey="id"
                        dataTestLabel="SoftwareUpdate"
                      />
                    </div>
                  </Space>
                </Card>
              </Row>
              <Row>
                <div className="tblDft farmTabsTbl posRel">
                  <div className="vHistorySec">
                    <div
                      id={applicationIds.updateScreen.versionHistory}
                      onClick={() => {
                        setIsHistoryModalVisible(true);
                        mixpanel.track('OpenVersionHistory', {
                          event: 'Version History Modal Open',
                        });
                      }}
                      className="vHtxt Button"
                      data-testid="versionHistoryLink-SoftwareUpdate"
                    >
                      Version History
                    </div>
                  </div>
                  <InfiniteScrollTable
                    columns={columns}
                    loading={loading}
                    hasMore={false}
                    filterData={filterData}
                    totalcount={totalcount}
                    handleLoadMore={handleLoadMore}
                    filename="Tractors"
                    onChange={handleChange}
                  />
                </div>
              </Row>
            </div>
          </Content>
        </div>
        {isModalVisible && (
          <SoftwareModal
            isModalVisible={isModalVisible}
            setIsModalVisible={(state) => {
              setIsModalVisible(state);
              getSoftwares();
            }}
            data={selectedItem as Software}
          />
        )}

        <VersionHistoryModal
          isHistoryModalVisible={isHistoryModalVisible}
          setIsHistoryModalVisible={setIsHistoryModalVisible}
          data={selectedItem}
        />
        <div>
          {logData && (
            <SoftwareHistoryLogs
              showModel={Boolean(logData)}
              closeModel={() => setLogData(undefined)}
              logData={logData}
            />
          )}
        </div>
      </Layout>
    </>
  );
};

export default SoftwareUpdate;
