import React, { useCallback } from 'react';
import { Row, Col, Card } from 'antd';
import RoutesConstants from '../../routes/RoutesConstant';
import './style.css';
import CameraGrid from './CameraGrid';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';

const AllCameraView: React.FC = () => {
  const { t } = useTranslation();
  const url = window.location.href;
  const origin = window.location.origin;
  const popUrl = url.replace(RoutesConstants.AllCameraView + '/', '');
  const srcString = popUrl.replace(origin, '');
  const viewData = srcString;
  const tractorId = viewData.split('/')[0];
  const tractorName = viewData.split('/')[2];
  const cameraMap: any = {
    front: t(translate.cameras.front),
    rear: t(translate.cameras.rear),
    front_left: t(translate.cameras.left),
    front_right: t(translate.cameras.right),
    pto: t(translate.cameras.hitch),
  };

  const frameHTML: any = useCallback(
    (view: string) => (
      <>
        <h1 className="iframe-title"> {cameraMap[view]} </h1>
        <iframe
          frameBorder="0"
          className="responsive-iframe"
          src={`${origin}/CameraView/${tractorId}/stream/${tractorName}/${view}/true`}
        ></iframe>
      </>
    ),
    [],
  );

  return (
    <>
      <CameraGrid frameHTML={frameHTML} />
    </>
  );
};

export default AllCameraView;
