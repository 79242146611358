import { commands } from '../../constants/Common';
import { defaultSetUpKeys } from '../../constants/constant';
import { MosEventsResp, SetUpKeys, TractorsList } from '../../constants/types';
import {
  ADD_CONNECTED_TRACTORS,
  AUTO_DRIVE_ERROR,
  AUTO_DRIVE_LOGOUT_ERROR_CODES,
  AUTO_DRIVE_MONITOR_ERROR_CODES,
  EXECUTE,
  LOAD_AVAILABLE_TRACTORS,
  RESET_STATE,
  SELECTED_IMPLEMENT_NAME,
  SELECTED_ITEM_NAME,
  SELECTED_ROUTINE,
  SELECTED_TRACTOR,
  SELECTED_TRACTORS,
  SELECTED_VINE_BLOCK,
  SELECT_CONFIGURE_TRACTOR,
  SET_ADD_STEP,
  SET_AUTO_DRIVE,
  SET_AUTO_DRIVE_FEEDBACK,
  SET_AUTO_DRIVE_FEEDBACK_PAYLOAD,
  SET_AUTO_DRIVE_FEEDBACK_POP,
  SET_AUTO_DRIVE_INTERRUPT_MESSAGE,
  SET_AUTO_DRIVE_MONITOR_POP,
  SET_AUTO_DRIVE_POP,
  SET_AUTO_DRIVE_SETTINGS,
  SET_AUTO_DRIVE_SOFT_INTERRUPT_MESSAGE,
  SET_AUTO_VALUE,
  SET_COMMAND_TYPE,
  SET_CONNECTED,
  SET_CONNECTING,
  SET_IMPLEMENT,
  SET_PINNED_TRACTORS,
  SET_PLANNER_STATUS,
  SET_RIGHT_SIDE_TOGGLE,
  SET_SELECTED_TRACTOR_LOCATION,
  SET_SETUP_KEYS,
  SET_SHOW_AUTO_DRIVE,
  SET_SHOW_CONNECT_LOADER,
  SET_SHOW_MAINTENANCE_LOCK_DRIVE,
  SET_SHOW_PATH_MODAL,
  SET_SHOW_PRESENT_COMMAND,
  SET_SHOW_REMOTE_DRIVE_DETAILS,
  SET_SHOW_REMOTE_DRIVE_SETTINGS,
  SET_SHOW_ROUTINE,
  SET_SHOW_SNAPSHOT,
  SET_SHOW_TERM_AND_COND,
  SET_TRIGGERED_GUARDRAIL,
  SHOW_SAVE_COMMAND,
  SHOW_TRACTOR_START,
  SNAP_SHOT_INITIATED,
} from './actions';

interface InitialStateType {
  autoDrive: boolean;
  connecting: boolean;
  connected: boolean;
  showRemoteDriveDetails: boolean;
  showRemoteDriveSetting: boolean;
  showPresentCommand: string;
  implement: string;
  tractors: any[];
  availableTractors: any[];
  connectedTractors: any[];
  selectedConfigureTractor: any[];
  autoDriveSettings: boolean;
  setUpKeys: SetUpKeys;
  commandType: string;
  plannerStatus: string;
  title: string;
  triggeredGuardrail: string;
  showPathModal: boolean;
  showTractorStart: any;
  showSnapshot: boolean;
  snapShotInitiated: any;
  pinnedTractors: any[];
  selectedRoutine: any;
  showTermAndCond: boolean;
  showRoutine: boolean;
  autoDriveInterruptMsg: MosEventsResp | null;
  autoDriveSoftInterruptMsg: MosEventsResp | null;
  autoDriveMonitorPop: boolean;
  showAutoDrivePop: boolean;
  showAutoDriveFeedBackPop: boolean;
  showAutoDriveFeedBack: boolean;
  feedBackPayload: { tractor_id: number; drive_action_uuid: string };
  showAutoDriveConnect: boolean;
  autoDriveMonitorErrorCodes: any;
  showMaintenanceLock: boolean;
  showConnectLoader: boolean;
  selectedTractorLocation: any;
  selectedTractors: TractorsList[];
  selectedTractor: TractorsList | null;
}
interface Action {
  type: string;
  payload?: any;
}

export const initialState: InitialStateType = {
  autoDrive: false,
  connecting: false,
  connected: false,
  showRemoteDriveDetails: false,
  showRemoteDriveSetting: false,
  showPresentCommand: '', // '' | 'GOTO' | 'ROUTINE'
  autoDriveSettings: false,
  implement: '',
  tractors: [],
  availableTractors: [],
  connectedTractors: [],
  selectedConfigureTractor: [],
  setUpKeys: defaultSetUpKeys,
  commandType: commands.GOTO_POINT, // 'GOTO_POINT' | 'ROW_FOLLOW'
  plannerStatus: '',
  triggeredGuardrail: '',
  showPathModal: false,
  title: 'RemoteDriveContext',
  showTractorStart: {},
  showSnapshot: false,
  snapShotInitiated: null,
  pinnedTractors: [],
  selectedRoutine: null,
  showTermAndCond: false,
  showRoutine: false,
  autoDriveInterruptMsg: null,
  autoDriveSoftInterruptMsg: null,
  // autoDriveInterruptMsg: {
  //   category: 'autodrive_interrupt_msg',
  //   message: 'pause',
  //   organization_id: 2,
  //   tractor_id: 34,
  //   timestamp: 1669201914351,
  //   metadata: {
  //     details: interruptMessageTypes.lost_localization,
  //     category: 'system_pause',
  //     type: 'hard',
  //   },
  // },
  // autoDriveSoftInterruptMsg: {
  //   category: 'autodrive_interrupt_msg',
  //   message: 'pause',
  //   organization_id: 2,
  //   tractor_id: 34,
  //   timestamp: 1669201914351,
  //   metadata: {
  //     details: interruptMessageTypes.lost_localization,
  //     category: 'system_pause',
  //     type: 'soft',
  //   },
  // },
  autoDriveMonitorPop: false,
  showAutoDrivePop: false,
  showAutoDriveFeedBackPop: false,
  showAutoDriveFeedBack: false,
  feedBackPayload: { tractor_id: 0, drive_action_uuid: '' },
  showAutoDriveConnect: false,
  autoDriveMonitorErrorCodes: null,
  // autoDriveMonitorErrorCodes: {
  //   errors: [7001, 7002, 76100, 76200],
  //   message: '7001,7002,76100,76200',
  //   organization_id: 2,
  //   tractorId: 94,
  // },
  showMaintenanceLock: false,
  showConnectLoader: false,
  selectedTractorLocation: null,
  selectedTractors: [],
  selectedTractor: null,
};

export default function reducer(state: InitialStateType, action: Action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_CONNECTED_TRACTORS:
      return {
        ...state,
        connectedTractors: payload,
      };
    case SELECT_CONFIGURE_TRACTOR:
      return {
        ...state,
        selectedConfigureTractor: payload,
      };
    case SELECTED_TRACTOR:
      return {
        ...state,
        selectedTractor: payload,
      };

    case SELECTED_TRACTORS:
      return {
        ...state,
        selectedTractors: payload,
      };

    case LOAD_AVAILABLE_TRACTORS:
      return { ...state, availableTractors: payload };
    case SET_AUTO_DRIVE:
      return { ...state, autoDrive: payload };
    case SET_CONNECTING:
      return { ...state, connecting: payload };
    case SET_CONNECTED:
      return { ...state, connected: payload };
    case SET_IMPLEMENT:
      return { ...state, implement: payload };
    case SET_COMMAND_TYPE:
      return { ...state, commandType: payload };
    case SHOW_SAVE_COMMAND:
      return { ...state, showSaveCommand: payload };
    case SELECTED_IMPLEMENT_NAME:
      return { ...state, selectedImplementName: payload };
    case SET_SHOW_PRESENT_COMMAND:
      return { ...state, showPresentCommand: payload };
    case SET_SETUP_KEYS:
      return { ...state, setUpKeys: { ...state.setUpKeys, ...payload } };
    case SET_AUTO_DRIVE_SETTINGS:
      return { ...state, autoDriveSettings: payload };
    case SET_SHOW_REMOTE_DRIVE_SETTINGS:
      return { ...state, showRemoteDriveSetting: payload };
    case SET_SHOW_REMOTE_DRIVE_DETAILS:
      return { ...state, showRemoteDriveDetails: payload };
    case SET_PLANNER_STATUS:
      return { ...state, plannerStatus: payload };
    case EXECUTE:
      return { ...state, ...payload };
    case SELECTED_ITEM_NAME:
      return { ...state, selectedItemName: payload };
    case SELECTED_VINE_BLOCK:
      return { ...state, selectedVineBlockName: payload };
    case SET_RIGHT_SIDE_TOGGLE:
      return { ...state, rightSideToggle: payload };
    case SET_SHOW_PATH_MODAL:
      return { ...state, showPathModal: payload };
    case SET_TRIGGERED_GUARDRAIL:
      return { ...state, triggeredGuardrail: payload };
    case SET_ADD_STEP:
      return { ...state, addStep: payload };
    case SET_AUTO_VALUE:
      return { ...state, autoValue: payload };
    case SELECTED_ROUTINE:
      return { ...state, selectedRoutine: payload };
    case AUTO_DRIVE_MONITOR_ERROR_CODES:
      return { ...state, autoDriveMonitorErrorCodes: payload };
    case AUTO_DRIVE_ERROR:
      return { ...state, autoDriveError: payload };
    case SHOW_TRACTOR_START:
      return { ...state, showTractorStart: payload };
    case SNAP_SHOT_INITIATED:
      return { ...state, snapShotInitiated: payload };
    case SET_SHOW_SNAPSHOT:
      return { ...state, showSnapshot: payload };
    case AUTO_DRIVE_LOGOUT_ERROR_CODES:
      return { ...state, autoDriveLogoutErrorCodes: payload };
    case SET_PINNED_TRACTORS:
      return { ...state, pinnedTractors: payload };
    case SET_SHOW_TERM_AND_COND:
      return { ...state, showTermAndCond: payload };
    case SET_SHOW_ROUTINE:
      return { ...state, showRoutine: payload };
    case SET_AUTO_DRIVE_INTERRUPT_MESSAGE:
      return { ...state, autoDriveInterruptMsg: payload };
    case SET_AUTO_DRIVE_SOFT_INTERRUPT_MESSAGE:
      return { ...state, autoDriveSoftInterruptMsg: payload };
    case SET_AUTO_DRIVE_MONITOR_POP:
      return { ...state, autoDriveMonitorPop: payload };
    case SET_AUTO_DRIVE_POP:
      return { ...state, showAutoDrivePop: payload };
    case SET_AUTO_DRIVE_FEEDBACK_POP:
      return { ...state, showAutoDriveFeedBackPop: payload };
    case SET_AUTO_DRIVE_FEEDBACK:
      return { ...state, showAutoDriveFeedBack: payload };
    case SET_AUTO_DRIVE_FEEDBACK_PAYLOAD:
      return { ...state, feedBackPayload: payload };
    case SET_SHOW_AUTO_DRIVE:
      return { ...state, showAutoDriveConnect: payload };
    case SET_SHOW_MAINTENANCE_LOCK_DRIVE:
      return { ...state, showMaintenanceLock: payload };
    case SET_SHOW_CONNECT_LOADER:
      return { ...state, showConnectLoader: payload };
    case SET_SELECTED_TRACTOR_LOCATION:
      return { ...state, selectedTractorLocation: payload };

    case RESET_STATE:
      return initialState;
    case 'change_title':
      return { ...state, title: 'Changed RemoteDriveContext' };

    default:
      throw new Error();
  }
}
