/* eslint-disable no-console */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { RightOutlined } from '@ant-design/icons';
import { Checkbox, notification } from 'antd';
import {
  getAllTractorSnapShot,
  getDriveActionPath,
  getMapFleetIndicators,
  getMapFleetTickets,
  getPolygonsData,
} from '../../../constants/Api';
import { ApplicationContext } from '../../../context/AppContext';
import { AnalyticsContext } from '../AnalyticsContext';

import { Feature, Overlay, View } from 'ol';
import { ScaleLine } from 'ol/control';
import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import OLMap from 'ol/Map';
import * as proj from 'ol/proj';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';

import errorCodeCluster from '../../../assets/images/errorCodeCluster.svg';
import guardRailsCluster from '../../../assets/images/guardRailsCluster.svg';

import moment from 'moment';
import LineString from 'ol/geom/LineString';
import Cluster from 'ol/source/Cluster';
import CircleStyle from 'ol/style/Circle';
import { useMixpanel } from 'react-mixpanel-browser';
import BaseStationIcon from '../../../assets/images/basestation_green.png';
import bStationIcon from '../../../assets/images/bStationGreen.svg';
import datalayerIcon from '../../../assets/images/dataLayer.svg';
import FleetIndicator from '../../../assets/images/FleetIndicator.svg';
import guardRails from '../../../assets/images/guardRails.svg';
import human_pt from '../../../assets/images/human_pt.svg';
import implementsIcon from '../../../assets/images/implementsGreen.svg';
import mapNor from '../../../assets/images/mapNor.svg';
import mapSat from '../../../assets/images/mapSat.svg';
import MapCenterIcon from '../../../assets/images/map_recenter.svg';
import JumpnGoIcon from '../../../assets/images/m_jngo.svg';
import SnapIcon from '../../../assets/images/m_sshot.svg';
import TicketIcon from '../../../assets/images/m_ticket.svg';
import path_pt from '../../../assets/images/path_pt.svg';
import polygonIcon from '../../../assets/images/polygon.svg';
import pto_pt from '../../../assets/images/pto_pt.svg';
import RoundIcon from '../../../assets/images/selRound.svg';
import thumbnail_icon from '../../../assets/images/thumbnail_icon.svg';
import tractorIcon from '../../../assets/images/tractorGreen.svg';
import {
  calPtsDistance,
  displayRecenterAllViewPortSetUp,
  drawPoly,
  getDateTime,
  getDateTimes,
  getFleetUsagePointsInfo,
  getFleetUsagePointsPathInfo,
  getFullName,
  getPathBorderColor,
  getPathColor,
  polygonHoverLabel,
  priority,
  styleFun,
  toggleVineRowStyle,
  vineRowHoverStyle,
} from '../../../constants/Common';
import { rangeForAnalytics } from '../../../constants/constant';
import { SET_POLYGONS_LIST } from '../../../context/actions';
import applicationIds from '../../../locale/applicationIds.json';
import { getSeverity, getTags } from '../../fleethealth/Diagnostics';
import DiagnosticWidget from '../../fleethealth/DiagnosticWidget';
import { getFleetTicketDetails } from '../../fleetusage/util';
import { onGlobalZoomToShowZoomIcons } from '../../map/LiveMap';
import { getVineRows } from '../../map/VineRows';
import SnapView from '../../snapshot/SnapView';
import TicketsCreateEdit from '../../tickets/TicketsCreateEdit';
import TicketViewEdit from '../../tickets/TicketViewEdit';
import ThumbnailMenu from '../Database/ThumbnailMenu';

let selectedDriveActionUser = '';
let toggleForGuardRails = false;
let toggleForTicket = false;
let toggleForIndicator = false;
let globalGuardrailData: any = null;
let allIndicators: any = [];
let allTickets: any = [];
let globalZoom = 0;

interface Props {
  driveAction?: any;
}

const LiveMap: React.FC<Props> = ({ driveAction }) => {
  const { userDetails, updateFullScreen, APPReducer } =
    useContext(ApplicationContext);
  const { setVideoTime, analyticReducer, jumpAndGoStore } =
    useContext(AnalyticsContext);
  const [appState, appDispatch] = APPReducer;
  const { tractorsMap, groundZero, polygonsList } = appState;
  const [analyticState] = analyticReducer;
  const mixpanel = useMixpanel();
  const [mapInitialized, setMapInitialized] = useState(false);
  const [base, setBase] = useState<[number, number]>([0, 0]);
  const [toggleSatellite, setToggleSatellite] = useState(true);
  const [rightSideToggle, setRightSideToggle] = useState(false);
  const [selectedDriveAction, setSelectedDriveAction] = useState('');
  const [selectedJumpnGo, setselectedJumpnGo] = useState<any>();
  const selectedJumpnGoRef = useRef<any>(null);
  const [mapSnapshots, setMapSnapshots] = useState<any>([]);
  const [selectedTicket, setSelectedTicket] = useState<any>();
  const [showTicketPopup, setShowTicketPopup] = useState<boolean>(false);
  const [showThumbnails, setShowThumbnails] = useState<boolean>(false);
  const [showGuardrailModel, setShowGuardrailModel] = useState(false);
  const [guardrailData, setGuardrailData] = useState<any | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [errorDetails, setErrorDetails] = useState<any>();
  const [showIndicatorModel, setShowIndicatorModel] = useState(false);
  const mapElement: any = useRef();
  const container: any = useRef();
  const content: any = useRef();
  const centerRef: any = useRef();
  const mapRef = useRef<OLMap | null>(null);
  const vectorTileLayerRef = useRef<TileLayer<any>>();
  const satelliteTileLayerRef = useRef<TileLayer<any>>();
  const initialLayer = useRef<VectorLayer<any>>();
  const mapTicketsRefs = useRef<VectorLayer<any>>();
  const mapIndicatorRefs = useRef<VectorLayer<any>>();
  const tractorPathLayerRef = useRef<VectorLayer<any>>();
  const markerPointRef = useRef<VectorLayer<any>>();
  const guardRailsRefs = useRef<VectorLayer<any>>();
  const snapshotRefs = useRef<VectorLayer<any>>();
  const jumpAndGoRefs = useRef<VectorLayer<any>>();
  const jumpAndGoIconRefs = useRef<VectorLayer<any>>();
  const hoverPointLayerRef = useRef<VectorLayer<any>>();
  const polygonsLayerRef = useRef<VectorLayer<any>>();
  const vineRowsLayerRef = useRef<VectorLayer<any>>();
  const [toggleTicketWidget, setToggleTicketWidget] = useState<any>(false);
  const [ticketDetails, setTicketDetails] = useState(null);
  const [tractorId, setTractorId] = useState<any>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fullScreenFlag, setFullScreenFlag] = useState<boolean>(false);
  const [showGuardRailsLayer, setShowGuardRailsLayer] = useState(true);
  const [showSnapShotRailsLayer, setShowSnapShotRailsLayer] = useState(true);
  const [showPathlayer, setShowPathlayer] = useState(true);
  const [showhealdIndicatorsLayer, setShowhealdIndicatorsLayer] =
    useState(true);
  const [rightSideThumbnailsToggle, setRightSideThumbnailsToggle] =
    useState<boolean>(false);
  const [showTicketsLayer, setshowTicketsLayer] = useState(true);
  const [showPolygonsLayer, setshowPolygonsLayer] = useState(true);
  // const fieldFeatureItem = useRef<any>();
  const jAGSSIcons = useRef<any>(new Map());

  const { JAG } = analyticState;
  // cleanup component state
  useEffect(() => {
    return () => {
      setGuardrailData(null);
    };
  }, []);

  useEffect(() => {
    if (driveAction && JAG) {
      const content = driveAction;
      setGuardrailData({
        guard_rail_triggered_uuid: content.transaction_id,
        id: content.tractor_id.id,
        tractor_id: content.tractor_id.id,
        title: getDateTime(content.created_date_time),
        created_date_time: new Date(content.created_date_time).getTime(),
        guard_rail_type: content.guard_rail_type,
        created_at: content.created_at,
      });
      setShowGuardrailModel(true);
    }
  }, [driveAction, JAG]);

  useEffect(() => {
    if (JAG) {
      setselectedJumpnGo(JAG);
      const time = moment(JAG.drive_action_start_date_time).toDate().getTime();
      setVideoTime(time);
    }
  }, [JAG]);

  const onCreateTicket = () => {
    setTicketDetails(errorDetails);
    setTractorId(errorDetails?.tractor_id);
    setShowIndicatorModel(false);
    setToggleTicketWidget(true);
    mixpanel.track('CreateTicket', {
      event: 'Create Ticket',
    });
  };

  // const toggleViews = (view: string) => {
  //   setShowGuardrailModel(view === 'GUARD_RAILS');
  //   setShowIndicatorModel(view === 'INDICATOR');
  //   setShowTicketPopup(view === 'Ticket');
  // };

  const handleRecenter = () => {
    if (
      mapRef &&
      mapRef.current &&
      tractorPathLayerRef &&
      tractorPathLayerRef.current
    ) {
      const feats = tractorPathLayerRef.current?.getSource().getFeatures();
      feats &&
        feats.length > 0 &&
        displayRecenterAllViewPortSetUp(feats, mapRef.current);
    }
  };

  const handleCenterPoint = (point: number[]) => {
    if (mapRef.current && Array.isArray(point) && point?.length) {
      const view = mapRef.current?.getView();
      const zoom = view.getZoom();
      if (zoom) {
        // mapRef.current?.getView().setCenter(oarr[oarr.length - 1]);
        mapRef.current?.getView().setCenter(point);
        mapRef.current?.getView().setZoom(17);
        centerRef.current = point;
      }
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && groundZero) {
      initializeMap();
    }
  }, [userDetails, groundZero]);

  //  mapInitialization start here
  const initializeMap = async () => {
    try {
      // const { organization } = userDetails;
      // const { latitude, longitude } = await getGroundZero(
      //   organization.api_url,
      //   organization.farm.id,
      // );
      const { latitude, longitude } = groundZero;
      setBase([latitude, longitude]);

      const baseLocation = new Feature({
        geometry: new Point(proj.fromLonLat([longitude, latitude])),
      });
      baseLocation.set('name', 'Ground zero');
      baseLocation.setStyle(
        new Style({
          image: new Icon({
            src: BaseStationIcon,
            scale: 0.4,
          }),
          zIndex: 100,
        }),
      );

      const initialFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [baseLocation],
        }),
      });

      const vectorLayer = new TileLayer({
        source: new OSM(),
        visible: false,
      });

      const satelliteLayer = new TileLayer({
        source: new XYZ({
          url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga',
          cacheSize: 1000,
        }),
        visible: true,
      });

      // polygons list layar
      const polygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
      });
      const traveledPathLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.PATH,
      });

      // create map
      const initialMap = new OLMap({
        target: mapElement.current,
        layers: [
          satelliteLayer,
          vectorLayer,
          initialFeaturesLayer,
          polygonsFeaturesLayer,
          traveledPathLayer,
        ],
        controls: [scaleControl()],
        view: new View({
          projection: 'EPSG:3857',
          center: proj.transform(
            [longitude, latitude],
            'EPSG:4326',
            'EPSG:3857',
          ),
          zoom: 17,
          maxZoom: 25,
        }),
      });
      // set flag for map initialization
      setMapInitialized(true);
      mapRef.current = initialMap;
      initialLayer.current = initialFeaturesLayer;
      // initialize satelight layer
      satelliteTileLayerRef.current = satelliteLayer;
      // initialize vector layer
      vectorTileLayerRef.current = vectorLayer;
      // polygons list
      polygonsLayerRef.current = polygonsFeaturesLayer;

      // traveled path
      tractorPathLayerRef.current = traveledPathLayer;
    } catch (err: any) {
      notification.error({
        message: err.response?.data?.error?.message,
      });
    }
  };
  //  mapInitialization ends here

  const scaleControl = () => {
    const control = new ScaleLine({
      units: 'metric',
      className: 'positionMap',
    });
    return control;
  };
  // to change road map and satelight map
  useEffect(() => {
    vectorTileLayerRef.current?.setVisible(!toggleSatellite);
    satelliteTileLayerRef.current?.setVisible(toggleSatellite);
  }, [toggleSatellite]);
  /**
   * Zoom In Zoom Out and Recenter
   */

  const handleZoom = (zoomType: string) => {
    if (mapRef.current) {
      const view = mapRef.current.getView();
      const zoom = view.getZoom();
      if (zoom) {
        if (zoomType === 'zoomIn') {
          view.setZoom(zoom + 1);
          mixpanel.track('Analytics Map', {
            event: `Map Zoom In ${zoom + 1}`,
          });
        } else {
          view.setZoom(zoom - 1);
          mixpanel.track('Analytics Map', {
            event: `Map Zoom out ${zoom - 1}`,
          });
        }
      }
    }
  };

  const moveEndHandler = (map: any) => {
    let currZoom = map?.getView().getZoom();
    map &&
      map.on('moveend', function (e: any) {
        const newZoom = map?.getView().getZoom();
        if (currZoom != newZoom) {
          currZoom = newZoom;
          globalZoom = newZoom;
          handleLayerVisible(newZoom);
        }
      });
  };

  const handleLayerVisible = (zoom: number) => {
    vineRowsLayerRef?.current?.setVisible(zoom >= 17);
    toggleVineRowStyle(
      vineRowsLayerRef.current,
      toggleSatellite,
      globalZoom >= onGlobalZoomToShowZoomIcons,
    );
  };

  useEffect(() => {
    if (mapInitialized) {
      if (mapRef.current) {
        moveEndHandler(mapRef.current);
        polygonHoverLabel(mapRef.current);
      }
      const overlay = new Overlay({
        element: container.current,
        autoPan: false,
        autoPanAnimation: {
          duration: 10,
        },
      });
      mapRef.current?.on('pointermove', function (evt) {
        const hit = mapRef.current?.forEachFeatureAtPixel(
          evt.pixel,
          function (feature: any) {
            const coordinate = evt.coordinate;
            const name = feature.get('name');
            if (name) {
              content.current.innerHTML = '<p>' + name + '</p>';
              overlay.setPosition(coordinate);
              mapRef.current?.addOverlay(overlay);
              return true;
            }
            return false;
          },
        );
        if (hit) {
          evt.map.getTargetElement().style.cursor = 'pointer';
        } else {
          overlay.setPosition(undefined);
          mapRef.current?.addOverlay(overlay);
          evt.map.getTargetElement().style.cursor = '';
        }
      });
    }
  }, [userDetails, mapInitialized]);

  /**
   * Polygons initialization Starts Here
   */
  useEffect(() => {
    const init = async () => {
      polygonsLayerRef.current?.getSource().clear();
      try {
        const { organization } = userDetails;
        let recordsList = [];
        if (polygonsList && polygonsList.length > 0) {
          recordsList = polygonsList;
        } else {
          const response = await getPolygonsData(
            organization.api_url,
            organization.farm.id,
          );
          const { records } = response;
          recordsList =
            records && records.length && records.length > 0 ? records : [];
        }
        drawPolygon(
          recordsList,
          polygonsList && polygonsList.length > 0 ? false : true,
        );
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      }
    };
    if (userDetails && userDetails.organization && mapInitialized && base) {
      init();
    }
  }, [userDetails, mapInitialized, base, polygonsList]);

  const drawPolygon = async (polyList: any, save: boolean) => {
    try {
      const records = polyList && polyList.length > 0 ? polyList : [];
      records.map(async (record: any, index: number) => {
        const { polygonItem } = await drawPoly(
          userDetails,
          base,
          polygonsLayerRef.current,
          1,
          1,
          record,
          false, // suggested as false
          !save, // call api to get vertices -> false call api , -> true dont call api
        );
        // if (record.color === 'white') {
        //   fieldFeatureItem.current = polygonFeature;
        //   displayRecenterViewPortSetUp(
        //     polygonFeature as Feature<Geometry>,
        //     mapRef.current,
        //   );
        // }
        if (polygonItem && polygonItem.vertices) {
          record.vertices = polygonItem.vertices;
        }

        // polygons added to maintain app context start
        // listItems.push(polygonItem as MapFeature);
        if (records && records.length - 1 === index) {
          save &&
            appDispatch({
              type: SET_POLYGONS_LIST,
              payload: records,
            });
        }
        // polygons added to maintain app context end
      });

      polygonHoverLabel(mapRef.current);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };

  useEffect(() => {
    const init = async () => {
      const { layer }: { layer: any } = await getVineRows(userDetails, base);
      if (layer) {
        mapRef.current?.addLayer(layer);
        vineRowsLayerRef.current = layer;
      }
    };
    if (userDetails && userDetails.organization && mapInitialized && base) {
      init();
    }
  }, [userDetails, mapInitialized, base]);

  /**
   * Subscribing to Tractors and getting Information Starts Here
   */

  const setHoverName = (user: any) => {
    const n = `${user?.first_name}  ${user?.last_name}`;
    selectedDriveActionUser = n;
  };

  const getPoints = async () => {
    if (
      userDetails &&
      userDetails.organization &&
      selectedDriveAction &&
      selectedDriveAction.length > 0
    ) {
      const { organization } = userDetails;
      clearTractorPoints();
      setIsLoading(false);
      let response;
      if (jumpAndGoStore.current.get(selectedDriveAction)) {
        response = jumpAndGoStore.current.get(selectedDriveAction);
      } else {
        response = await getDriveActionPath(
          organization.api_url,
          selectedDriveAction,
        );
        jumpAndGoStore.current.set(selectedDriveAction, response);
      }
      if (selectedJumpnGo || selectedJumpnGoRef.current) {
        const { tractor, operator } =
          selectedJumpnGo || selectedJumpnGoRef.current;
        response['jng'] = selectedJumpnGo || selectedJumpnGoRef.current;
        response['tractor_name'] = tractor?.name;
        response['operator_name'] = getFullName(operator);
      }
      drawJAG(selectedDriveAction, response);
      setIsLoading(true);
    }
  };

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      selectedDriveAction &&
      mapInitialized
    )
      getPoints();
  }, [selectedDriveAction, mapInitialized, userDetails]);

  // Handle Jump and Go Start and Stop Icons
  useEffect(() => {
    if (jAGSSIcons.current.size && selectedDriveAction) {
      if (jumpAndGoIconRefs.current) {
        jumpAndGoIconRefs.current.getSource().clear();
        mapRef.current?.removeLayer(jumpAndGoIconRefs.current);
      }
      const items = jAGSSIcons.current.get(selectedDriveAction);
      if (items) {
        const [jng, point1, point2] = items;
        const pointFeature = jAGStartNdStop(point1, 'Start', jng);
        const pointFeature1 = jAGStartNdStop(point2, 'End', jng);

        const jumpAndGoSource = new VectorSource({
          features: [pointFeature, pointFeature1],
        });

        const jumpAndGolayer = new VectorLayer({
          source: jumpAndGoSource,
          zIndex: priority.SNAP,
        });

        mapRef.current?.addLayer(jumpAndGolayer);
        jumpAndGoIconRefs.current = jumpAndGolayer;
        // defaultLayer?.getSource().addFeatures([pointFeature, pointFeature1]);
      }
    }
  }, [selectedDriveAction, jAGSSIcons.current.size]);

  useEffect(() => {
    const getIndicators = async () => {
      if (selectedDriveAction && selectedDriveAction.trim().length > 0) {
        try {
          if (mapIndicatorRefs.current) {
            mapRef.current?.removeLayer(mapIndicatorRefs.current);
          }
          const indicators = await getMapFleetIndicators(
            userDetails.organization.api_url,
            userDetails.organization.id,
            selectedDriveAction,
          );
          if (indicators && indicators.length > 0)
            drawIndicators(indicators[0]?.indicators_data);
        } catch (error) {
          // error
        }
      }
    };

    const getTickets = async () => {
      if (selectedDriveAction && selectedDriveAction.trim().length > 0) {
        try {
          if (mapTicketsRefs.current) {
            mapRef.current?.removeLayer(mapTicketsRefs.current);
          }
          const tickets = await getMapFleetTickets(
            userDetails.organization.api_url,
            userDetails.organization.id,
            selectedDriveAction,
          );
          if (tickets && tickets.length) drawTickets(tickets[0]);
        } catch (error) {
          // error
        }
      }
    };
    getIndicators();
    getTickets();
  }, [selectedDriveAction, userDetails]);

  const clearTractorPoints = () => {
    allIndicators = [];
    allTickets = [];
    if (snapshotRefs.current) {
      snapshotRefs.current.getSource().clear();
      mapRef.current?.removeLayer(snapshotRefs.current);
    }
    if (jumpAndGoRefs.current) {
      jumpAndGoRefs.current.getSource().clear();
      mapRef.current?.removeLayer(jumpAndGoRefs.current);
    }
    if (jumpAndGoIconRefs.current) {
      jumpAndGoIconRefs.current.getSource().clear();
      mapRef.current?.removeLayer(jumpAndGoIconRefs.current);
    }
    if (guardRailsRefs.current) {
      guardRailsRefs.current.getSource().clear();
      mapRef.current?.removeLayer(guardRailsRefs.current);
    }
    if (mapTicketsRefs.current) {
      mapTicketsRefs.current.getSource().clear();
      mapRef.current?.removeLayer(mapTicketsRefs.current);
    }
    if (mapIndicatorRefs.current) {
      mapIndicatorRefs.current.getSource().clear();
      mapRef.current?.removeLayer(mapIndicatorRefs.current);
    }
    if (tractorPathLayerRef.current) {
      tractorPathLayerRef?.current?.getSource()?.clear();
    }
    if (markerPointRef.current) {
      markerPointRef?.current?.getSource()?.clear();
      mapRef.current?.removeLayer(markerPointRef.current);
    }
  };
  const drawTickets = (tickets: any) => {
    if (tickets) {
      const features: Feature<any>[] = [];
      tickets.fleet_tickets_data?.map((ticket: any) => {
        if (ticket && ticket.latitude && ticket.longitude) {
          const coordinates = [ticket.latitude, ticket.longitude];

          const style = new Style({
            image: new Icon({
              src: TicketIcon,
              scale: 1.5,
            }),
            zIndex: 100,
          });

          const feature = new Feature({
            geometry: new Point(
              proj.fromLonLat([coordinates[1], coordinates[0]]),
            ),
            name: 'Ticket',
            pointType: 'Ticket',
            content: ticket,
            style,
            point: [coordinates[1], coordinates[0]],
          });
          feature.setStyle(style);
          features.push(feature);
        }
      });

      const source = new VectorSource({
        features: features,
      });
      const clusterSource = new Cluster({
        distance: 10,
        source: source,
      });
      const layer = new VectorLayer({
        source: clusterSource,
        zIndex: priority.SNAP,
        style: function (feature: any) {
          const size = feature.get('features').length;
          let style = new Style({});
          if (size === 1) {
            const feat = feature.get('features');
            style = feat[0].get('style');
            feature.set('name', feat[0].get('name'));
            feature.set('pointType', feat[0].get('pointType'));
            feature.set('content', feat[0].get('content'));
          }
          if (size > 1) {
            const feat = feature.get('features');
            const count = feat.length;
            let names = '';
            feat.map((fe: any) => {
              names = names + fe.get('name') + ', ';
            });
            feature.set('name', names);
            style = new Style({
              image: new CircleStyle({
                radius: 10,
                stroke: new Stroke({
                  color: '#fff',
                }),
                fill: new Fill({
                  color: '#3399CC',
                }),
              }),
              text: new Text({
                text: count.toString(),
                fill: new Fill({
                  color: '#fff',
                }),
              }),
            });
          }
          return style;
        },
      });
      mapTicketsRefs.current = layer;
      mapRef.current?.addLayer(layer);
    }
  };

  const jAGStartNdStop = (points: any, name: string, content: any) => {
    const style = new Style({
      image: new Icon({
        src: JumpnGoIcon,
        scale: 1.5,
      }),
      zIndex: 100,
    });
    const feature = new Feature({
      geometry: new Point(points),
      name,
      pointType: 'JAG_START_STOP',
      content: { ...content, jAGStatus: name },
      style,
      point: points,
    });
    feature.setStyle(style);
    return feature;
  };

  const drawJAG = (id: string, jumpAndGo: any, all = false) => {
    const {
      tractor_id: tractorId,
      records: points,
      tractor_name = '',
      operator_name = '',
      jng,
    } = jumpAndGo;
    const defaultLayer = tractorPathLayerRef.current;
    let oarr: any = [];
    let lastPlannerLastPoint: any = null;
    let lastIndexPoint: any = null;

    points
      .filter((point: any) => point.planner !== 'standby') // filter standby
      .map((point: any, index: number, tpoints: any) => {
        // slice points
        if (lastIndexPoint) point.path = [lastIndexPoint, ...point.path];

        const result = point.path.reduce(
          (
            a: { data: any[][]; i: number | undefined; last_item: any },
            path_pt: {
              longitude: number;
              latitude: number;
              created_date_time: number;
              dTime: string;
              accuracy: number;
            },
            i: number,
          ) => {
            const ar = point.path;
            // if (i == 0 && Object.keys(a.last_item).length) ar.push(point.path);

            let distance = 0;

            if (i < ar.length) {
              distance = ar[i + 1] ? calPtsDistance([path_pt, ar[i + 1]]) : 1;

              if (distance > rangeForAnalytics) {
                a.data.push(ar.slice(a.i, i + 1));
                a.i = i + 1;
                if (ar[i + 1]) a.data.push([ar[i], ar[i + 1]]);
              }
            }

            if (ar.length - 1 == i && a.data.length == 0) {
              a.data.push(ar);
              // a.last_item = ar[ar.length - 1];
              lastIndexPoint = ar[ar.length - 1];
              return a.data;
            }
            if (ar.length - 1 == i && a.data.length > 0) {
              // a.last_item = ar[ar.length - 1];
              lastIndexPoint = ar[ar.length - 1];
              return a.data;
            }
            return a;
          },
          { i: 0, data: [], last_item: {} },
        );

        result
          .filter((item: any, key: number) => !(key % 2))
          .map((item: any, key: number) => {
            const arr: any[] = [];
            // if (lastPlannerLastPoint) arr.push(lastPlannerLastPoint);
            item.map(
              (
                path_pt: {
                  longitude: number;
                  latitude: number;
                  created_date_time: number;
                  dTime: string;
                  accuracy: number;
                },
                i: number,
              ) => {
                path_pt.dTime = getDateTime(path_pt.created_date_time);
                arr?.push(
                  proj.fromLonLat([path_pt.longitude, path_pt.latitude]),
                );
              },
            );
            let tractorLocation: Feature<any> = new Feature();
            if (defaultLayer) {
              tractorLocation = defaultLayer?.getSource().getFeatureById(id);
            }
            if (!tractorLocation) {
              tractorLocation = new Feature();
            }
            oarr = [...oarr, ...arr];

            tractorLocation.setGeometry(new LineString(arr));
            // let color = '#13B0FC';
            // const borderColor = '#FFFFFF';
            // if (point.planner === pathContants.standby.key) {
            //   color = '#EECDB4';
            // } else if (
            //   point.planner === pathContants.copycat.key ||
            //   point.planner === pathContants.remoteav.key
            // ) {
            //   color = '#755bb6';
            // } else if (
            //   point.planner === pathContants.follow_me.key ||
            //   point.planner === pathContants.vinerow.key
            // ) {
            //   color = '#7adcda';
            // } else if (point.planner === pathContants.manual.key) {
            //   color = '#42aebc';
            // }
            let color = getPathColor(point.planner);
            const borderColor = getPathBorderColor(point.planner);
            if (key % 2) color = 'grey';
            const style = [
              new Style({
                stroke: new Stroke({
                  color: borderColor,
                  width: 8,
                }),
                zIndex: 0,
              }),
              new Style({
                stroke: new Stroke({
                  color: color,
                  width: 6,
                }),
                zIndex: 1,
              }),
            ];
            const pathHover = [
              new Style({
                stroke: new Stroke({
                  color: borderColor,
                  width: 10,
                }),
                zIndex: 1,
              }),
              new Style({
                stroke: new Stroke({
                  color,
                  width: 6,
                }),
                zIndex: 1,
              }),
            ];

            tractorLocation.setStyle(style);
            tractorLocation.set('style', style);
            tractorLocation.set('hoverStyle', pathHover);
            tractorLocation.set('name', selectedDriveActionUser);
            tractorLocation.set('pointType', 'PATH');
            tractorLocation.set('drawPointsText', point);
            tractorLocation.set('tractorId', tractorId);
            tractorLocation.set('tractor_name', tractor_name);
            tractorLocation.set('operator_name', operator_name);
            tractorLocation.set('jng', jng);
            defaultLayer?.getSource().addFeature(tractorLocation);
            if (arr && arr.length) {
              lastPlannerLastPoint = arr[arr.length - 1];
            }
          });
      });
    if (oarr.length > 2) {
      jAGSSIcons.current?.set(id, [jng, oarr[0], oarr[oarr.length - 1]]);
    } else if (points?.length == 1 && points[0]?.planner === 'standby') {
      const arr = points[0].path.slice(0, 2).map(
        (
          path_pt: {
            longitude: number;
            latitude: number;
            created_date_time: number;
            dTime: string;
            accuracy: number;
          },
          i: number,
        ) => {
          return proj.fromLonLat([path_pt.longitude, path_pt.latitude]);
        },
      );
      if (arr.length) {
        jAGSSIcons.current?.set(id, [jng, arr[0], arr[arr.length - 1]]);
        handleCenterPoint(arr[0]);
      }
    }
    if (oarr.length) handleCenterPoint(oarr[0]);
  };

  const drawIndicators = (indicators: any) => {
    if (mapIndicatorRefs.current) {
      mapIndicatorRefs.current.getSource().clear();
      mapRef.current?.removeLayer(mapIndicatorRefs.current);
    }
    if (indicators && indicators.length > 0) {
      const features: Feature<any>[] = [];
      indicators?.map((indicator: any) => {
        if (indicator && indicator.latitude && indicator.longitude) {
          const coordinates = [indicator.latitude, indicator.longitude];

          const style = new Style({
            image: new Icon({
              src: FleetIndicator,
              scale: 0.7,
            }),
            zIndex: 100,
          });

          const feature = new Feature({
            geometry: new Point(
              proj.fromLonLat([coordinates[1], coordinates[0]]),
            ),
            name: indicator.indicator_name,
            pointType: 'INDICATOR',
            content: indicator,
            style,
            point: [coordinates[1], coordinates[0]],
          });
          feature.setStyle(style);
          features.push(feature);
        }
      });

      const source = new VectorSource({
        features: features,
      });
      const clusterSource = new Cluster({
        distance: 10,
        source: source,
      });
      const layer = new VectorLayer({
        source: clusterSource,
        zIndex: priority.INDICATOR,
        style: function (feature: any) {
          const size = feature.get('features').length;
          let style = new Style({});
          if (size === 1) {
            const feat = feature.get('features');
            style = feat[0].get('style');
            feature.set('name', feat[0].get('name'));
            feature.set('pointType', feat[0].get('pointType'));
            feature.set('content', feat[0].get('content'));
          }
          if (size > 1) {
            const feat = feature.get('features');
            let names = '';
            feat.map((fe: any) => {
              names = names + fe.get('name') + ', ';
            });
            feature.set('name', names);
            style = new Style({
              image: new Icon({
                src: errorCodeCluster,
                scale: 0.8,
              }),
              text: new Text({
                text: size.toString(),
                fill: new Fill({
                  color: '#fff',
                }),
                offsetX: 4,
                offsetY: -5,
              }),
            });
          }
          return style;
        },
      });
      mapIndicatorRefs.current = layer;
      mapRef.current?.addLayer(layer);
    }
  };

  useEffect(() => {
    if (guardRailsRefs.current) {
      guardRailsRefs.current.getSource().clear();
      mapRef.current?.removeLayer(guardRailsRefs.current);
    }
    const getSnapsGuardRails = async () => {
      try {
        const { organization } = userDetails;
        const snapshot = await getAllTractorSnapShot(
          organization.api_url,
          organization.farm.id,
          selectedJumpnGo.tractor.id,
          1,
          300,
          'completed',
          '',
          selectedJumpnGo.start_time
            ? selectedJumpnGo.start_time
            : selectedJumpnGo.drive_action_start_date_time,
          selectedJumpnGo.finish_time
            ? selectedJumpnGo.finish_time
            : selectedJumpnGo.drive_action_finish_date_time,
        );
        setMapSnapshots(snapshot);
      } catch (error) {
        console.error(error);
      }
    };

    if (selectedJumpnGo && userDetails && userDetails.organization) {
      getSnapsGuardRails();
      if (markerPointRef.current) {
        markerPointRef.current.setVisible(false);
      }
    }
  }, [selectedJumpnGo, userDetails]);

  useEffect(() => {
    if (mapSnapshots) {
      if (guardRailsRefs.current)
        mapRef.current?.removeLayer(guardRailsRefs.current);
      if (snapshotRefs.current)
        mapRef.current?.removeLayer(snapshotRefs.current);
      if (jumpAndGoRefs.current)
        mapRef.current?.removeLayer(jumpAndGoRefs.current);
      const guardRailsfeatures: Feature<any>[] = [];
      const snapShotfeatures: Feature<any>[] = [];
      // const jumpAndGofeatures: Feature<any>[] = [];
      mapSnapshots?.records?.map((snapshot: any) => {
        if (snapshot && snapshot.latitude && snapshot.longitude) {
          const coordinates = [snapshot.latitude, snapshot.longitude];
          // snapshot icons
          let icon = SnapIcon;
          let name = 'SNAPSHOT';
          let scale = 1.5;
          if (snapshot.created_at === 'GUARD_RAILS') {
            name = 'GUARD_RAILS';
            // icon = GuardRailIcon;
            icon = guardRails;
            name = 'Guard Rails';
            if (snapshot.guard_rail_type === 'human_protection') {
              icon = human_pt;
              name = 'Human Protection';
            } else if (snapshot.guard_rail_type === 'path_protection') {
              icon = path_pt;
              name = 'Path Protection';
            } else if (snapshot.guard_rail_type === 'pto_protection') {
              icon = pto_pt;
              name = 'Pto Protection';
            }
            scale = 0.7;
          } else if (snapshot.created_at === 'JUMP_AND_GO') {
            icon = JumpnGoIcon;
            name = 'JUMP_AND_GO';
          }
          const style = new Style({
            image: new Icon({
              src: icon,
              scale,
            }),
            zIndex: 100,
          });
          const hoverStyle = new Style({
            image: new Icon({
              src: icon,
              scale: scale * 1.2,
            }),
            zIndex: 100,
          });

          const feature = new Feature({
            geometry: new Point(
              proj.fromLonLat([coordinates[1], coordinates[0]]),
            ),
            name: name,
            pointType: 'SNAP',
            content: snapshot,
            style,
            point: [coordinates[1], coordinates[0]],
          });
          feature.setStyle(style);
          feature.set('style', style);
          feature.set('hoverStyle', hoverStyle);
          feature.set('content', snapshot);
          feature.set('type', 'SNAP');
          if (snapshot.created_at === 'GUARD_RAILS') {
            guardRailsfeatures.push(feature);
          } else if (snapshot.created_at === 'JUMP_AND_GO') {
            // jumpAndGofeatures.push(feature);
          } else {
            snapShotfeatures.push(feature);
          }
        }
      });
      // jump and go features
      // const jumpAndGoSource = new VectorSource({
      //   features: jumpAndGofeatures,
      // });
      // const jumpAndGoClusterSource = new Cluster({
      //   distance: 10,
      //   source: jumpAndGoSource,
      // });
      // const jumpAndGolayer = new VectorLayer({
      //   source: jumpAndGoClusterSource,
      //   zIndex: priority.INDICATOR,
      //   style: function (feature: any) {
      //     return styleFun(feature);
      //   },
      // });

      // snapshot features
      const snapshotSource = new VectorSource({
        features: snapShotfeatures,
      });
      const snapshotClusterSource = new Cluster({
        distance: 10,
        source: snapshotSource,
      });
      const snapshotlayer = new VectorLayer({
        source: snapshotClusterSource,
        zIndex: priority.SNAP,
        style: function (feature: any) {
          return styleFun(feature);
        },
      });
      // gaurd rails features
      const guardRailssource = new VectorSource({
        features: guardRailsfeatures,
      });
      const GuardRailsClusterSource = new Cluster({
        distance: 10,
        source: guardRailssource,
      });
      const guardRailslayer = new VectorLayer({
        source: GuardRailsClusterSource,
        zIndex: priority.SNAP,
        style: function (feature: any) {
          // return styleFun(feature);
          return styleFun(feature, guardRailsCluster);
        },
      });
      guardRailslayer.setVisible(showGuardRailsLayer);
      guardRailsRefs.current = guardRailslayer;
      snapshotRefs.current = snapshotlayer;
      // jumpAndGoRefs.current = jumpAndGolayer;
      mapRef.current?.addLayer(guardRailslayer);
      mapRef.current?.addLayer(snapshotlayer);
      // mapRef.current?.addLayer(jumpAndGolayer);
    }
  }, [mapSnapshots]);

  const onCloseTicketWidget = () => {
    setShowIndicatorModel(false);
    setToggleTicketWidget(false);
  };

  useEffect(() => {
    if (mapInitialized) {
      handleClick();
      handlePointerMove();
      const pointLocation: Feature<any> = new Feature();
      pointLocation.setId(999);
      const selectPointFeatureLayer = new VectorLayer({
        source: new VectorSource({
          features: [pointLocation],
        }),
        visible: true,
        style: new Style({}),
        zIndex: 111,
      });
      mapRef.current?.addLayer(selectPointFeatureLayer);
      hoverPointLayerRef.current = selectPointFeatureLayer;
    }
  }, [mapInitialized]);

  const handleClick = () => {
    mapRef.current?.on('click', (evt) => {
      if (markerPointRef.current) {
        mapRef.current?.removeLayer(markerPointRef.current);
      }
      mapRef.current?.forEachFeatureAtPixel(evt.pixel, function (feature: any) {
        const feat = feature.get('features');
        if (feat && feat.length > 1) {
          const view = mapRef.current?.getView();
          if (view) {
            const zoom = view.getZoom();
            if (zoom && zoom < 18) {
              view.setZoom(18);
            }
          }
        }
        if (feat && feat.length >= 1) {
          if (feat && feat.length > 1) {
            feat.map((f: any) => {
              const point = f.get('point');
              f.setGeometry(
                new Point(
                  proj.fromLonLat([
                    point[0] *
                      Number(`1.000000${Math.floor(Math.random() * 3)}`),
                    point[1] *
                      Number(`1.000000${Math.floor(Math.random() * 3)}`),
                  ]),
                ),
              );
            });
          }
          const pointType = feat[0].get('pointType');
          if (pointType == 'SNAP') {
            const content = feat[0].get('content');
            if (!toggleForGuardRails) {
              const point = feat[0].get('point');
              if (
                ['GUARD_RAILS', 'GILES', 'ATOM'].includes(content?.created_at)
              ) {
                setGuardrailData({
                  guard_rail_triggered_uuid: content.transaction_id,
                  id: content.tractor_id.id || content.tractor_id,
                  tractor_id: content.tractor_id.id || content.tractor_id,
                  title: getDateTime(content.created_date_time),
                  created_date_time: content.created_date_time,
                  guard_rail_type: content.guard_rail_type,
                  created_at: content.created_at,
                });
                setShowGuardrailModel(true);
                globalGuardrailData = content;
              } else {
                // setSelectedMapSnapshot(content);
              }
              toggleForGuardRails = true;
              const view = mapRef.current?.getView();
              if (view) {
                mapRef.current?.getView().setCenter(proj.fromLonLat(point));
              }
            } else {
              // setGuardrailData(null);
              if (content?.id === globalGuardrailData?.id)
                setShowGuardrailModel(false);
              toggleForGuardRails = false;
            }
          } else if (pointType && pointType === 'INDICATOR') {
            if (!toggleForIndicator) {
              const point = feat[0].get('point');
              const content = feat[0].get('content');
              const { subsystem = '', functionality_affected = '' } =
                content.error_code_data ? content.error_code_data : {};
              content.functionality_affected = functionality_affected;
              content.subsystem = subsystem;
              content.severity = content.indicator_level;
              content.start_time = content.created_date_time
                ? getDateTimes(content.created_date_time)
                : '';
              (content.counter_measure = content?.error_code_data
                ?.counter_measure
                ? content?.error_code_data?.counter_measure?.split(';')
                : ''),
                setErrorDetails(content);
              setShowIndicatorModel(true);
              toggleForIndicator = true;
              mapRef.current?.getView().setCenter(proj.fromLonLat(point));
            } else {
              setShowIndicatorModel(false);
              toggleForIndicator = false;
            }
          } else if (pointType && pointType === 'Ticket') {
            if (!toggleForTicket) {
              const content = feature.get('content');
              const ticketId = content.id;
              getFleetTicketDetails(ticketId, userDetails)
                .then((data) => {
                  setSelectedTicket(data);
                  setShowTicketPopup(true);
                  toggleForTicket = true;
                })
                .catch((error) => {
                  //do nothing
                });
            } else {
              setShowTicketPopup(false);
              toggleForTicket = false;
            }
          }
        }
        const pointType = feature.get('pointType');
        if (pointType && pointType === 'PATH') {
          const name = feature.get('name');
          const jng = feature.get('jng');
          if (name) {
            const geometry: any = feature.getGeometry();
            if (geometry) {
              const point = geometry.getClosestPoint(evt.coordinate);
              const coordinates = geometry.getCoordinates();
              for (
                let element = 0;
                element < coordinates.length - 1;
                element++
              ) {
                if (
                  new LineString([
                    coordinates[element],
                    coordinates[element + 1],
                  ]).intersectsCoordinate(point)
                ) {
                  const drawPointsText = feature.get('drawPointsText');
                  if (drawPointsText.path && drawPointsText.path[element]) {
                    const t = drawPointsText.path[element].dTime;
                    const time = moment(t).toDate().getTime();
                    const tractorId = feature.get('tractorId');
                    // getVideos(tractorId, time, userDetails);
                    setVideoTime(time);
                    if (markerPointRef.current) {
                      mapRef.current?.removeLayer(markerPointRef.current);
                    }
                    const lonLat = proj.transform(
                      evt.coordinate,
                      'EPSG:3857',
                      'EPSG:4326',
                    ); //, 'EPSG:4326'
                    const markerLocation = new Feature({
                      geometry: new Point(
                        proj.fromLonLat([lonLat[0], lonLat[1]]),
                      ),
                    });
                    markerLocation.setStyle(
                      new Style({
                        image: new Icon({
                          src: RoundIcon,
                          scale: 1,
                        }),
                        zIndex: 100,
                      }),
                    );
                    const markerLayer = new VectorLayer({
                      source: new VectorSource({
                        features: [markerLocation],
                      }),
                      zIndex: 12,
                    });
                    mapRef.current?.addLayer(markerLayer);
                    markerPointRef.current = markerLayer;
                  }
                }
              }
            }
          }
          // feature && displayRecenterAllViewPortSetUp([feature], mapRef.current);
          // if (!selectedJumpnGo && jng) setselectedJumpnGo(jng);
          // setLoadAll(false);
          // analyticDispatch({
          //   type: SET_SELECTED_JAG,
          //   payload: jng,
          // });
        }
      });
    });
  };

  useEffect(() => {
    if (!showTicketPopup) {
      toggleForTicket = false;
    } else {
      setShowIndicatorModel(false);
      setShowGuardrailModel(false);
    }
  }, [showTicketPopup]);

  useEffect(() => {
    if (!showIndicatorModel) {
      toggleForIndicator = false;
    } else {
      setShowTicketPopup(false);
      setShowGuardrailModel(false);
    }
  }, [showIndicatorModel]);

  useEffect(() => {
    if (!showGuardrailModel) {
      toggleForGuardRails = false;
    } else {
      setShowTicketPopup(false);
      setShowIndicatorModel(false);
    }
  }, [showGuardrailModel]);

  const handlePointerMove = () => {
    const overlay = new Overlay({
      element: container.current,
      autoPan: false,
      autoPanAnimation: {
        duration: 10,
      },
    });
    let selected: any = null;
    mapRef.current?.on('pointermove', function (evt) {
      const pointLayer = hoverPointLayerRef.current;
      if (selected !== null) {
        const feat = pointLayer?.getSource().getFeatureById(999);
        feat?.setStyle(new Style({}));
        selected?.setStyle(selected?.get('style'));
        selected = null;
      }
      const hit = mapRef.current?.forEachFeatureAtPixel(
        evt.pixel,
        function (feature: any) {
          const pointType = feature.get('pointType');
          if (pointType && pointType === 'vinerow') {
            selected = feature;
            feature.setStyle(
              vineRowHoverStyle(globalZoom > onGlobalZoomToShowZoomIcons),
            );
            return true;
          }
          if (pointType == 'SNAP') {
            const contentData = feature.get('content');
            if (contentData && contentData.created_at === 'GUARD_RAILS') {
              selected = feature;
              selected?.setStyle(feature.get('hoverStyle'));
            }
          }
          if (pointType === 'PATH') {
            const feat = pointLayer?.getSource().getFeatureById(999);
            if (feat) {
              feat.setGeometry(
                new Point(
                  proj.fromLonLat(
                    proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326'),
                  ),
                ),
              );
              feat.setStyle(
                new Style({
                  image: new CircleStyle({
                    radius: 5,
                    stroke: new Stroke({
                      color: 'blue',
                    }),
                    fill: new Fill({
                      color: '#3399CC',
                    }),
                  }),
                  zIndex: 10,
                }),
              );
              selected = feature;
              selected?.setStyle(feature.get('hoverStyle'));
              const geometry = feature.getGeometry();
              const point = geometry.getClosestPoint(evt.coordinate);
              const coordinates = geometry.getCoordinates();

              for (
                let element = 0;
                element < coordinates.length - 1;
                element++
              ) {
                if (
                  new LineString([
                    coordinates[element],
                    coordinates[element + 1],
                  ]).intersectsCoordinate(point)
                ) {
                  const drawPointsText = selected.get('drawPointsText');
                  if (drawPointsText.path && drawPointsText.path[element]) {
                    drawPointsText.path[element]['tractor_name'] =
                      selected.get('tractor_name');
                    drawPointsText.path[element]['operator_name'] =
                      selected.get('operator_name');
                    const innerHTML = getFleetUsagePointsPathInfo(
                      drawPointsText.path[element],
                    );
                    selected.set('name', innerHTML);
                    content.current.innerHTML = innerHTML;
                    if (
                      !overlay
                        .getElement()
                        ?.classList.contains('ol-details-popup')
                    ) {
                      overlay.getElement()?.classList.add('ol-details-popup');
                    }
                    overlay.setPosition(evt.coordinate);
                    mapRef.current?.addOverlay(overlay);
                    return true;
                  }
                }
              }
            }
          }
          const coordinate = evt.coordinate;
          const name = feature.get('name');
          const dataType = feature.get('pointType');
          const contentData = feature.get('content');
          if (name) {
            content.current.innerHTML = '<p>' + name + '</p>';
            if (
              dataType == 'JAG_START_STOP' ||
              dataType == 'INDICATOR' ||
              dataType == 'Ticket' ||
              name === 'GUARD_RAILS'
            ) {
              if (name === 'GUARD_RAILS') {
                const feat = feature.get('features')[0];
                const dataType = feat.get('pointType');
                const contentData = feat.get('content');
                content.current.innerHTML = getFleetUsagePointsInfo(
                  dataType,
                  contentData,
                );
              } else {
                content.current.innerHTML = getFleetUsagePointsInfo(
                  dataType,
                  contentData,
                );
              }
              if (
                !overlay.getElement()?.classList.contains('ol-details-popup')
              ) {
                overlay.getElement()?.classList.add('ol-details-popup');
              }
            } else {
              // stop removing path class removal
              if (dataType !== 'PATH') {
                overlay.getElement()?.classList.remove('ol-details-popup');
              }
            }

            overlay.setPosition(coordinate);
            mapRef.current?.addOverlay(overlay);
            return true;
          }

          return false;
        },
      );
      if (hit) {
        evt.map.getTargetElement().style.cursor = 'pointer';
      } else {
        overlay.setPosition(undefined);
        mapRef.current?.addOverlay(overlay);
        evt.map.getTargetElement().style.cursor = '';
      }
    });
  };

  const handleShowImagesVideos = (e: boolean) => {
    setShowThumbnails(e);
    snapshotRefs.current?.setVisible(e);
  };
  const handleShowGuardRails = (e: boolean) => {
    guardRailsRefs.current?.setVisible(e);
  };

  const handleShowTravelledPath = (e: boolean) => {
    tractorPathLayerRef.current?.setVisible(e);
    jumpAndGoRefs.current?.setVisible(e);
  };

  const handleShowIndicators = (e: boolean) => {
    mapIndicatorRefs.current?.setVisible(e);
  };

  const handleShowTickets = (e: boolean) => {
    mapTicketsRefs.current?.setVisible(e);
  };

  const handleShowPolygons = (e: boolean) => {
    polygonsLayerRef.current?.setVisible(e);
  };
  useEffect(() => {
    if (!showThumbnails) {
      // toggleForGuardRails = false;
      if (!driveAction) setShowGuardrailModel(false);
    }
  }, [showThumbnails]);

  const toggleMapView = () => {
    mixpanel.track('Analytics Map', {
      event: `${toggleSatellite ? 'Map View' : 'satellite View'}`,
    });
    setToggleSatellite(!toggleSatellite);
    if (vineRowsLayerRef.current)
      toggleVineRowStyle(
        vineRowsLayerRef.current,
        !toggleSatellite,
        globalZoom >= onGlobalZoomToShowZoomIcons,
      );
  };

  useEffect(() => {
    if (selectedJumpnGo) {
      setSelectedDriveAction(selectedJumpnGo?.drive_action_uuid);
      // setShowThumbnails(true);
      setHoverName(selectedJumpnGo?.operator);
      // setRightSideThumbnailsToggle(true);
    }
    selectedJumpnGoRef.current = selectedJumpnGo;
  }, [selectedJumpnGo]);

  const toggleFullScreen = (flagdata: any) => {
    updateFullScreen(flagdata);
    setFullScreenFlag(flagdata);
  };

  const iconSection = useMemo(
    () => (
      <div
        className={
          rightSideThumbnailsToggle || rightSideToggle
            ? 'mapIconSec tglexpand'
            : 'mapIconSec'
        }
        style={{ marginTop: '180px' }}
      >
        <div
          className={`mapIcons ${
            showThumbnails ? 'afterAnaMap' : 'beforeCollapseAnaMap'
          } `}
        >
          <div className="mapRecenter" onClick={handleRecenter}>
            <img src={MapCenterIcon} />
          </div>
          <div
            className={fullScreenFlag ? 'f_screenCol' : 'f_screen f_screen1'}
            onClick={() => {
              toggleFullScreen(!fullScreenFlag);
              setTimeout(() => {
                mapRef.current?.updateSize();
              }, 100);
            }}
          ></div>
          <div className="zoom_in_out">
            <button
              id={applicationIds.analyticsMapScreen.mapZoomIn}
              className="zoomIn"
              onClick={() => handleZoom('zoomIn')}
            />
            <button
              id={applicationIds.analyticsMapScreen.mapZoomOut}
              className="zoomOut"
              onClick={() => handleZoom('zoomOut')}
            />
          </div>
          <div className="mapView">
            <img
              id={applicationIds.analyticsMapScreen.toggleMap}
              src={toggleSatellite ? mapNor : mapSat}
              onClick={toggleMapView}
              alt=""
            />
          </div>
        </div>
      </div>
    ),
    [
      rightSideThumbnailsToggle,
      rightSideToggle,
      fullScreenFlag,
      toggleSatellite,
    ],
  );

  return (
    <>
      <div className={fullScreenFlag ? 'fullScreen' : 'normalCl'}>
        <div className="tblContainer lMapCont">
          <div
            ref={mapElement}
            style={{
              height: fullScreenFlag ? '100vh' : '100%',
              width: fullScreenFlag ? '100vw' : '100%',
            }}
            //to align right bottom of map distance
            className={`posHd ${showThumbnails ? 'afterAnaMapAlign' : ''} 
          ${
            rightSideToggle || rightSideThumbnailsToggle
              ? 'afterAnaMapRightAlign'
              : ''
          } `}
          >
            <div
              id={applicationIds.analyticsMapScreen.toggleRowBtn}
              // className="dataLayerBtn"
              className={
                rightSideThumbnailsToggle
                  ? 'dataLayerBtn tglexpand2'
                  : 'dataLayerBtn'
              }
              onClick={() => {
                setRightSideToggle(!rightSideToggle);
                setRightSideThumbnailsToggle(rightSideToggle);
              }}
            >
              <span className="dataArrow">{/* <LeftOutlined /> */}</span>
              <span className="dLIcon">
                <img src={datalayerIcon} alt="dataLayer" width="18" />
              </span>
              <span className="dLTxt">Map Layers</span>
            </div>
            {selectedJumpnGo && (
              <div
                id={applicationIds.analyticsMapScreen.toggleRowBtn}
                // className="dataLayerBtn"
                className={
                  rightSideToggle ? 'dataLayerBtn tglexpand2' : 'dataLayerBtn'
                }
                style={{ top: '150px' }}
                onClick={() => {
                  setRightSideThumbnailsToggle(!rightSideThumbnailsToggle);
                  setRightSideToggle(rightSideThumbnailsToggle);
                }}
              >
                <span className="dataArrow">{/* <LeftOutlined /> */}</span>
                <span className="dLIcon">
                  <img src={thumbnail_icon} alt="dataLayer" width="17" />
                </span>
                <span className="dLTxt">Thumbnails</span>
              </div>
            )}
            {iconSection}
            {selectedJumpnGo && (
              <>
                <div
                  className={
                    rightSideThumbnailsToggle
                      ? 'analyticsRightside expand'
                      : 'analyticsRightside'
                  }
                >
                  <ThumbnailMenu
                    // selectedDriveAction={selectedDriveAction}
                    showThumbnails={rightSideThumbnailsToggle}
                    setRightSideThumbnailsToggle={setRightSideThumbnailsToggle}
                    // spinner={spinning}
                  />
                </div>
                {/* <div className="jag-head">{jAGTitle}</div> */}
              </>
            )}
            <div
              className={
                rightSideToggle
                  ? 'analyticsRightside expand'
                  : 'analyticsRightside'
              }
            >
              <div className="dataLayerWrapper">
                <div className="dataLayerRowHead mb28">
                  <span
                    onClick={() => {
                      mixpanel.track('Analytics Map', {
                        event: `${
                          rightSideToggle
                            ? 'Data Layer Closed'
                            : 'Data Layer Opened'
                        }`,
                      });
                      setRightSideToggle(!rightSideToggle);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <RightOutlined />
                  </span>
                  <span className="dataIcon">
                    <img src={datalayerIcon} alt="dataLayer" height="18" />
                  </span>
                  <span className="dataHead">MAP LAYERS</span>
                </div>
                {/* datalayers block */}
                <div className="datalayerContent">
                  <h4>Map Data</h4>
                  <ul>
                    <li>
                      <span className="dlItem">
                        <img
                          src={tractorIcon}
                          alt="GuardRails"
                          width="24"
                          height="18"
                        />
                      </span>
                      <span className="dlItemTxt">Guard Rails</span>
                      <Checkbox
                        defaultChecked
                        checked={showGuardRailsLayer}
                        onChange={(e) => {
                          handleShowGuardRails(e.target.checked);
                          setShowGuardRailsLayer(e.target.checked);
                        }}
                      />
                    </li>
                    <li>
                      <span className="dlItem">
                        <img
                          src={bStationIcon}
                          alt="Snapshots"
                          width="13"
                          height="18"
                        />
                      </span>
                      <span className="dlItemTxt">Snapshots</span>
                      <Checkbox
                        defaultChecked
                        checked={showSnapShotRailsLayer}
                        onChange={(e) => {
                          handleShowImagesVideos(e.target.checked);
                          setShowSnapShotRailsLayer(e.target.checked);
                        }}
                      />
                    </li>
                    <li>
                      <span className="dlItem">
                        <img
                          src={implementsIcon}
                          alt="Implements"
                          width="24"
                          height="18"
                        />
                      </span>
                      <span className="dlItemTxt">Travelled Path</span>
                      <Checkbox
                        defaultChecked
                        checked={showPathlayer}
                        onChange={(e) => {
                          handleShowTravelledPath(e.target.checked);
                          setShowPathlayer(e.target.checked);
                        }}
                      />
                    </li>
                    <li>
                      <span className="dlItem">
                        <img
                          src={implementsIcon}
                          alt="Implements"
                          width="24"
                          height="18"
                        />
                      </span>
                      <span className="dlItemTxt">Health Indicators</span>
                      <Checkbox
                        defaultChecked
                        checked={showhealdIndicatorsLayer}
                        onChange={(e) => {
                          handleShowIndicators(e.target.checked);
                          setShowhealdIndicatorsLayer(e.target.checked);
                        }}
                      />
                    </li>
                    <li>
                      <span className="dlItem">
                        <img
                          src={implementsIcon}
                          alt="Implements"
                          width="24"
                          height="18"
                        />
                      </span>
                      <span className="dlItemTxt">Tickets</span>
                      <Checkbox
                        defaultChecked
                        checked={showTicketsLayer}
                        onChange={(e) => {
                          handleShowTickets(e.target.checked);
                          setshowTicketsLayer(e.target.checked);
                        }}
                      />
                    </li>
                    <li>
                      <span className="dlItem">
                        <img
                          src={polygonIcon}
                          alt="Implements"
                          width="24"
                          height="18"
                        />
                      </span>
                      <span className="dlItemTxt">Polygon</span>
                      <Checkbox
                        defaultChecked
                        checked={showPolygonsLayer}
                        onChange={(e) => {
                          handleShowPolygons(e.target.checked);
                          setshowPolygonsLayer(e.target.checked);
                        }}
                      />
                    </li>
                  </ul>
                  <h4>Path Data</h4>
                  <ul className="pathData">
                    <li>
                      <span className="pathBox manualBox"></span> Manual
                    </li>
                    <li>
                      <span className="pathBox operatorAssist"></span> Operator
                      Assist
                    </li>
                    <li>
                      <span className="pathBox autodrive"></span> Auto Drive
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {mapInitialized && (
            <div ref={container} className="ol-popup">
              <div ref={content}></div>
            </div>
          )}
          {selectedTicket && showTicketPopup && (
            <TicketViewEdit
              Close={() => setShowTicketPopup(false)}
              afterSave={() => setShowTicketPopup(false)}
              ticketObj={selectedTicket}
              toggleWidget={showTicketPopup}
            ></TicketViewEdit>
          )}
          {showIndicatorModel && (
            <DiagnosticWidget
              isEdit={isEdit}
              details={errorDetails}
              setIsEdit={setIsEdit}
              setToggleWidget={setShowIndicatorModel}
              getSeverity={getSeverity}
              getTags={getTags}
              onCreateTicket={onCreateTicket}
              toggleWidget={showIndicatorModel}
              tractor={tractorsMap.get(errorDetails?.tractor_id)}
            />
          )}

          {toggleTicketWidget && (
            <TicketsCreateEdit
              tractorId={tractorId}
              Close={onCloseTicketWidget}
              ticketObj={ticketDetails}
              afterSave={onCloseTicketWidget}
              toggleWidget={toggleTicketWidget}
            ></TicketsCreateEdit>
          )}

          {showGuardrailModel && (
            <SnapView
              snapshotFlag={showGuardrailModel}
              guardrailsList={guardrailData}
              Close={() => setShowGuardrailModel(false)}
              selectedJumpnGo={selectedJumpnGo}
            />
          )}
        </div>
        <div className={!isLoading ? 'wave-loader' : 'hide'}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </>
  );
};

export default LiveMap;
