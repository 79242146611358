/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useState } from 'react';

export const AnalyticsContext = createContext({
  JAG: null,
  setJAG: (item: any) => {},
  driveAction: null,
  setDriveAction: (item: any) => {},
  setVideoTime: (item: any) => {},
  videoTime: 0,
});

const AnalyticsContextProvider: React.FC = ({ children }) => {
  const [JAG, setJAG] = useState<any>(null);
  const [videoTime, setVideoTime] = useState<number>(0);
  const [driveAction, setDriveAction] = useState<any>(null);

  return (
    <AnalyticsContext.Provider
      value={{
        driveAction,
        setDriveAction,
        JAG,
        setJAG,
        videoTime,
        setVideoTime,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsContextProvider;
