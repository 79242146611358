/* eslint-disable no-console */
import React from 'react';
import { Button } from 'antd';
import logoHorizontalWhite from '../../assets/images/logo_horizontal_white_rgb.png';
import logoWingspanAI from '../../assets/images/WingspanAI_logo_temp.svg';
import update_complete from '../../assets/images/update_complete.png';
import applicationIds from '../../locale/applicationIds.json';
import { useHistory } from 'react-router-dom';
import RoutesConstants from '../../routes/RoutesConstant';
import { checkAlphaFeature } from '../../constants/Common';

const ResetPwSuccess: React.FC = () => {
  const { push } = useHistory();
  return (
    <div className="loginContainer">
      <div className="resetSuccessWrap">
        {checkAlphaFeature('wingspanai') ? (
          <img height="100px" className="mb60" src={logoWingspanAI} />
        ) : (
          <img height="50px" className="mb60" src={logoHorizontalWhite} />
        )}
        <div
          className="pwSuccess mb40"
          data-testid="resetPwdSuccessMsg-ResetPwdSuccess"
        >
          Reset Password Successful
        </div>
        <img height="95px" src={update_complete} className="mb50" />
        <div className="">
          <Button
            data-testid="goToLogInButton-ResetPwdSuccess"
            id={applicationIds.ResetPasswordScreen.resetSuccessGoTOLogIn}
            type="primary"
            className={
              checkAlphaFeature('wingspanai')
                ? 'btnTxt wspanAiBtn mtb0'
                : 'btnTxt mtb0'
            }
            onClick={() => push(RoutesConstants.Login)}
          >
            GO TO LOG IN
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResetPwSuccess;
