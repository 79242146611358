import React, { useContext } from 'react';
import { getStatusClass, getStatusForTractor } from '../../constants/Common';
import { ApplicationContext } from '../../context/AppContext';

import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import { SELECTED_TRACTORS, SET_PINNED_TRACTORS } from './actions';
import { RemoteDriveAppCtx } from './RemoteDriveContext';
const PinnedTractors: React.FC = () => {
  const { APPReducer } = useContext(ApplicationContext);
  const [appState] = APPReducer;
  const { tractorsList } = appState;
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState] = AutoDriveReducer;
  const { isPinned } = autoDriveState;
  const { RDReducer } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const { selectedTractor, pinnedTractors } = state;

  const unPinnedTractors = (id: number) => {
    const tTractors = pinnedTractors.filter((item: any) => item !== id);
    dispatch({
      type: SET_PINNED_TRACTORS,
      payload: tTractors,
    });
  };

  return (
    <>
      {isPinned && pinnedTractors.length && (
        <div className="adtBottomSection">
          {tractorsList
            .filter(
              (tractor: any) =>
                pinnedTractors.includes(tractor.id) &&
                selectedTractor.id !== tractor.id,
            )
            .map((tractor: any) => {
              return (
                <div
                  className="adtBottomBox"
                  key={tractor.id}
                  onClick={() => {
                    dispatch({
                      type: SELECTED_TRACTORS,
                      payload: [tractor],
                    });
                  }}
                >
                  <div
                    className="adtCloseBottom"
                    onClick={(e) => {
                      e.stopPropagation();
                      unPinnedTractors(tractor.id);
                    }}
                  ></div>
                  <div className="adTabsHeadSecBottom">
                    <div className="adtabsHeadTextBottom body3">
                      {tractor.name}
                    </div>
                    <div
                      className={`ttractor_state  ${getStatusClass(
                        tractor.isAvailable,
                        tractor?.heartbeat?.drive_action_details
                          ?.drive_action_created_at,
                      )}`}
                    >
                      {getStatusForTractor(
                        tractor.isAvailable,
                        tractor?.heartbeat?.drive_action_details
                          ?.drive_action_created_at,
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default PinnedTractors;
