/* eslint-disable react/jsx-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-empty */
import React, { useContext, useEffect, useState } from 'react';
import Layout from 'antd/lib/layout/layout';
import closeGreen from '../../assets/images/closeGreen.svg';
import applicationIds from '../../locale/applicationIds.json';
import { ApplicationContext } from '../../context/AppContext';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  RightOutlined,
  WarningFilled,
} from '@ant-design/icons';
import { Avatar, Spin } from 'antd';
import { initClearSelection } from '../../constants/Common';
import { privilagesConstants } from '../../constants/Privilages';
import EditEmployee from './EditEmployee';
import { SET_EMP_REFRESH } from '../../context/actions';
import { TeamsContext } from '../../context/TeamContext';
import { getUserProfile } from '../../constants/Api';
interface Props {
  userProfile: any;
  toggleWidget: boolean;
  isEdit: boolean;
  userDetails?: any;
  setToggleWidget: (key: boolean) => void;
  setEmpId?: any;
  seeMore: () => void;
  loaderview?: boolean;
}

const ViewEmployee: React.FC<Props> = ({
  userProfile,
  toggleWidget,
  setToggleWidget,
  isEdit,
  userDetails,
  setEmpId,
  seeMore,
  loaderview,
}: Props) => {
  const { privilegeChecker } = useContext(ApplicationContext);
  const { TEAMSReducer } = useContext(TeamsContext);
  const [teamsState, teamsDispatch] = TEAMSReducer;
  const [passwordShown, setPasswordShown] = useState(false);
  const [editFlag, setEditFlag] = useState<boolean>(false);
  const [appAcess, setAppAcess] = useState<boolean>(false);
  const [loggedUserDetails, setLoggedUserDetails] = useState<any>();

  const passCheck = (id: number) => {
    if ((userDetails && userDetails.id === id) || userDetails?.is_org_admin) {
      return true;
    } else return false;
  };

  const getProfile = async () => {
    const data = await getUserProfile(
      userDetails.organization.api_url,
      userDetails.id,
    );
    setLoggedUserDetails(data);
  };

  useEffect(() => {
    if (userProfile) {
      getProfile();
      const check = userProfile?.teams?.map((team: any) => team.app_access);
      check.length > 0 && setAppAcess(check.includes(true));
    }
    return () => {
      setPasswordShown(false);
    };
  }, [userProfile]);

  const closeBtn = () => {
    initClearSelection();
    setEmpId(0);
    setToggleWidget(false);
  };

  const showPassWord = () => {
    setPasswordShown(!passwordShown);
  };
  const editClose = () => {
    setEditFlag(false);
    setToggleWidget(false);
    teamsDispatch({ type: SET_EMP_REFRESH });
  };
  return (
    <>
      {userProfile && (
        <Layout>
          <div
            className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}
          >
            <div className="widgetCloseBtn">
              <img
                id={applicationIds.personnelScreen.personnelCloseBtn}
                src={closeGreen}
                alt="close icon"
                onClick={closeBtn}
              />
            </div>
            {!isEdit && (
              <>
                {loaderview ? (
                  <div className="edit-loader">
                    <div className="loader">
                      <Spin size="large" />
                    </div>
                  </div>
                ) : (
                  <div className="profileView personeeltab profileSideTab rightWidgetView">
                    <div className="proRow mb24 proPic profile rowGapTop4 rowGapBtm5">
                      {userProfile?.profile_url !== '' &&
                      userProfile?.profile_url ? (
                        <Avatar
                          src={userProfile?.profile_url}
                          className={
                            userProfile?.is_org_admin && 'profile-avatar'
                          }
                        ></Avatar>
                      ) : (
                        <Avatar
                          style={{
                            width: '145px',
                            height: '145px',
                            backgroundColor: '#67AFB7',
                            color: 'white',
                            fontFamily: 'Montserrat-Bold',
                          }}
                          className={
                            userProfile?.is_org_admin && 'profile-avatar'
                          }
                        >
                          {userProfile?.first_name?.charAt(0)}
                          {userProfile?.last_name?.charAt(0)}
                        </Avatar>
                      )}
                    </div>
                    <div className="proRow rowGapBtm1">
                      <h4
                        className="headline1"
                        data-testid={`${userProfile.first_name} ${userProfile.last_name}-EmployeeWidget`}
                      >{`${userProfile.first_name} ${userProfile.last_name}`}</h4>
                      <h3 className="headline2 member_uuid">
                        ID: {userProfile.member_uuid}
                      </h3>
                    </div>
                    {userProfile.teams ? (
                      <div className="rowGapBtm2">
                        <div className="flex alignCenter">
                          <div className="proRoleTypeHead flex-1">
                            <h5 className="headline2 fSbd rowGapBtm15">
                              Teams
                            </h5>
                          </div>
                          <p
                            className="proSideHeading seeteamBtn body3"
                            onClick={seeMore}
                          >
                            {' '}
                            see more
                            <RightOutlined />
                          </p>
                        </div>
                        {userProfile?.teams.map((ele: any) => {
                          return (
                            <div className="proRoleCards" key={ele?.name}>
                              <h6 className="proRoleTypeHead_details flex-1 overline1">
                                {ele?.name}
                              </h6>
                              <div className="proRoleType_details ">
                                {ele?.description}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="proRoleTypeHead rowGapBtm1">
                      <h5 className="headline2 fSbd mb11">Contact</h5>
                      <h6 className="proRoleValue userName body1">
                        {userProfile.email || userProfile.username}
                      </h6>
                      <h6 className="proRoleValue contactNumber mt16 body1">
                        {userProfile.contact_number}
                      </h6>
                      {appAcess && !userProfile?.username && (
                        <div style={{ display: 'flex' }}>
                          <WarningFilled
                            style={{
                              color: '#fbb313',
                              marginRight: '10px',
                              fontSize: '22px',
                            }}
                          />
                          <span className="warning-msg">Email Required</span>
                        </div>
                      )}
                    </div>
                    {userProfile?.ops_code && (
                      <div
                        className="flex"
                        style={{
                          display: userProfile?.ops_code ? 'flex' : 'none',
                        }}
                      >
                        <div className="proRoleTypeHead flex-1 headline2 fSbd">
                          Operator Code
                        </div>
                        <div className="proRoleValue flex-1">
                          <div
                            className="flex"
                            // style={{ justifyContent: 'flex-start' }}
                          >
                            <div className="flex-1">
                              {privilegeChecker(
                                privilagesConstants.View_Operator_Code,
                              ) && passwordShown
                                ? userProfile?.ops_code
                                : '* * * *'}
                            </div>
                            {privilegeChecker(
                              privilagesConstants.View_Operator_Code,
                            ) && (
                              <div
                                className="flex-1 showPwdIcon"
                                onClick={showPassWord}
                              >
                                <>
                                  {!passwordShown ? (
                                    <EyeTwoTone />
                                  ) : (
                                    <EyeInvisibleOutlined />
                                  )}
                                </>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {privilegeChecker(
                      privilagesConstants.Edit_personnel_information,
                    ) && (
                      <div
                        className="editRoles2 Button"
                        data-testid="editButton-ViewEmployee"
                        onClick={() => setEditFlag(true)}
                      >
                        Edit
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          {editFlag && (
            <EditEmployee
              Close={editClose}
              toggleWidget={editFlag}
              userProfile={userProfile}
            ></EditEmployee>
          )}
        </Layout>
      )}
    </>
  );
};

export default ViewEmployee;
