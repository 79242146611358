/* eslint-disable no-console */
/* eslint-disable react/display-name */
import {
  Select,
  notification,
  Row,
  DatePicker,
  Button,
  Modal,
  Card,
} from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import 'antd/dist/antd.css';
// import './style.css';
import {
  getReportsData,
  getFleetUsageReports,
  downloadReportPdf,
  generateFleetReport,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import moment from 'moment';
import { DailyUsageReports } from '../../constants/types';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import { getDateTimes, getDateTimesByString } from '../../constants/Common';
import dwlIcon from '../../assets/images/dwlIcon.svg';
import { DownOutlined } from '@ant-design/icons';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import { useMixpanel } from 'react-mixpanel-browser';
import { privilagesConstants } from '../../constants/Privilages';
import { saveAs } from 'file-saver';
import CSelect from '../common/CSelect';
import ReportRenderer from './ReportRenderer';

const ReportsDistribution: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails, privilegeChecker } = useContext(ApplicationContext);
  const [reports, setReports] = useState<DailyUsageReports[]>([]);
  const [reportsData, setReportsData] = useState<any[]>([]);
  const [pageSize, setPagesize] = useState<any>(25);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<any>();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [isGenerate, setIsGenerate] = useState<boolean>(false);
  const [reportId, setReportId] = useState<any>();
  const [DateTime, setDateTime] = useState<any>(null);
  const [reportDate, setReportDate] = useState<any>(null);
  const mixpanel = useMixpanel();
  const [sortedData, setSortedData] = useState<any>({
    created_date_time: 'descend',
  });
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'created_date_time',
    order: 'descend',
  });
  const [refresh, setRefresh] = useState(false);
  const [intialflag, setIntialflag] = useState(false);
  const [showPDFRenderer, setShowPDFRenderer] = useState(false);
  const [selectedReport, setSelectedReport] = useState('');

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getReportsDetails();
    }
  }, [userDetails, pageSize]);

  const getReportsDetails = async () => {
    try {
      setLoader(true);
      const reports: { records: DailyUsageReports[] } = await getReportsData(
        userDetails.organization.api_url,
        userDetails.organization_id,
      );
      let data =
        reports && reports.records && reports.records.length > 0
          ? reports.records
          : [];
      data = data.filter((item) => item.report_type === 'scheduled');
      setReports(data as []);
      if (data.length) handleSelect(data[0].reports_id);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (
      sortedInfo.order &&
      userDetails &&
      userDetails.organization &&
      intialflag
    ) {
      setPageNumber(1);
      setReportsData([]);
      reportsData.length = 0;
      handleTable(true);
    }
  }, [sortedInfo.order]);

  useEffect(() => {
    if (userDetails && userDetails.organization && reportId) {
      // setTotalCount([]);
      handleTable();
    }
  }, [reportId, DateTime, pageNumber, pageSize, sortedData, refresh]);

  const handleTable = async (sort = false) => {
    const page = sort ? 1 : pageNumber;
    try {
      setLoader(true);
      const report = await getFleetUsageReports(
        userDetails.organization.api_url,
        userDetails.organization_id,
        page,
        pageSize,
        sortedInfo,
        DateTime ? moment(DateTime).format('YYYY-MM-DD') : '',
        reportId,
      );
      setTotalCount(report?._metadata?.total_records_count);
      const data =
        report && report.records && report.records.length > 0
          ? report.records.map((record: DailyUsageReports) => {
              return {
                created_date_time: getDateTimes(record.created_date_time),
                name: record.report_title
                  ? record.report_title
                  : record.reports_name,
                id: record.id,
                reports_date: getDateTimesByString(record.report_date_str),
                reports_name: record.reports_name,
                download_report_url: record.download_report_url,
              };
            })
          : [];
      if (data.length === 0) {
        setHasMore(false);
      } else {
        if (data.length > 0 && !sort) {
          if (refresh || pageNumber === 1) {
            setReportsData([...data]);
            setRefresh(false);
          } else {
            setReportsData([...reportsData, ...data]);
          }
        } else {
          setReportsData(data);
        }
        if (data.length < 25) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      }
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleSelect = async (id: any) => {
    reportsData.length = 0;
    setPageNumber(1);
    setReportId(id);
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
    setIntialflag(false);
  };

  const columns = [
    {
      title: () => {
        return (
          <>
            {t(translate.headers.dateTime)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'created_date_time' && (
                <img
                  className="sort-pad"
                  src={
                    sortedData['created_date_time'] === 'ascend'
                      ? sortUp
                      : sortDown
                  }
                />
              )}
            </span>
          </>
        );
      },
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend', 'descend'],
      ellipsis: true,
      dataIndex: 'created_date_time',
      key: 'created_date_time',
      width: 240,
      sorter: (a: any, b: any) => a.reports_date - b.reports_date,
      visible: true,
      showSorterTooltip: false,
      render: (created_date_time: number, record: any) => {
        return (
          <div>
            {record.reports_name === 'Task Details'
              ? `${created_date_time}`
              : `${record.reports_date}`}
          </div>
        );
      },
      onCell: (record: any) => ({
        onClick: () => {
          setSelectedReport(record.download_report_url || ''),
            setShowPDFRenderer(true);
        },
      }),
    },
    {
      title: `${t(translate.reportsDistribution.reportType)}`,
      dataIndex: 'name',
      key: 'name',
      visible: true,
      onCell: (record: any) => ({
        onClick: () => {
          setSelectedReport(record.download_report_url || ''),
            setShowPDFRenderer(true);
        },
      }),
    },
    {
      dataIndex: 'id',
      key: 'id',
      // only displaying download icon which have access
      visible: privilegeChecker(privilagesConstants.Download_Reports),
      // eslint-disable-next-line react/display-name
      render: (id: number, record: any) => (
        <img
          style={{ cursor: 'pointer' }}
          src={dwlIcon}
          onClick={() => {
            if (privilegeChecker(privilagesConstants.Download_Reports)) {
              window.open(record.download_report_url);
            }
          }}
          height="38px"
          alt="download"
        />
      ),
    },
  ].filter((item: any) => item.visible);
  // above code  added to filter the show hide the down load button

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const handleSelectDate = async (date: any) => {
    setReportsData([]);
    reportsData.length = 0;
    setDateTime(date);
  };

  const handleGenerate = async () => {
    try {
      if (reportDate && userDetails) {
        const date = moment(reportDate).format('YYYY-MM-D');
        const response = await generateFleetReport(
          userDetails.organization.api_url,
          userDetails.organization.id,
          date,
        );
        notification.success({
          message: response?.msg,
        });
        pageNumber != 1 ? setPageNumber(1) : setRefresh(!refresh);
        setIsGenerate(!isGenerate);
      }
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };

  return (
    <Layout>
      <Content>
        <div className="mainContent">
          <div className="tblContainer selectReport">
            <Row>
              <div className="tblTopOutSec libraryBlk relative filterGap40">
                <div className="searchSec selectRow">
                  {/* <div className="mr20" data-testid="dailyfeetusage-Reports">
                    <CSelect
                      list={reports}
                      selectTrigger={(selected) => {
                        if (selected) {
                          const { reports_id } = selected;
                          handleSelect(reports_id);
                        } else {
                          handleSelect('');
                        }
                      }}
                      label="Report Type"
                      keyValue="reports_id"
                      defaultId={reportId}
                    />
                  </div> */}

                  <DatePicker
                    suffixIcon={<DownOutlined />}
                    format="YYYY-MM-DD"
                    value={DateTime}
                    placeholder="Date"
                    data-testid="datefeetusage-Reports"
                    onChange={(date: any) => handleSelectDate(date)}
                    className="dropdownStyle hwb singleDatePicker"
                    disabledDate={(d) => !d || d.isAfter(new Date())}
                  />
                  {reportId == 11 && (
                    <Button
                      className="dbWidget btn-rgt-align"
                      onClick={() => {
                        setIsGenerate(!isGenerate);
                      }}
                    >
                      {t(translate.buttons.generate)}
                    </Button>
                  )}
                </div>
              </div>
              <div className="tblDft farmTabsTbl reportsTbl hoverable cPointer">
                <InfiniteScrollTable
                  columns={columns}
                  hasMore={hasMore}
                  filterData={reportsData}
                  totalcount={totalCount}
                  loading={loader}
                  handleLoadMore={handleLoadMore}
                  filename="Reports"
                  onChange={handleChange}
                />
              </div>
            </Row>
          </div>
        </div>
        <Modal
          title={t(translate.headers.generateReport)}
          visible={isGenerate}
          onOk={() => handleGenerate()}
          okText={t(translate.buttons.generate)}
          onCancel={() => setIsGenerate(false)}
        >
          <p>
            <span style={{ fontSize: '18px' }}>Select Date : </span>{' '}
            <DatePicker
              suffixIcon={<DownOutlined />}
              format="YYYY-MM-DD"
              value={reportDate}
              placeholder="Date"
              onChange={(date: any) => setReportDate(date)}
              className="dropdownStyle"
              disabledDate={(d) => !d || d.isAfter(new Date())}
            />
          </p>
        </Modal>
      </Content>
      <ReportRenderer
        url={selectedReport}
        closeModel={() => setShowPDFRenderer(false)}
        showModel={showPDFRenderer}
      />
    </Layout>
  );
};
export default ReportsDistribution;
