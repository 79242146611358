/* eslint-disable no-console */
import { LeftOutlined } from '@ant-design/icons';
import { Button, Checkbox, Collapse, Modal, notification, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import './style.css';

import 'antd/dist/antd.css';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { useMixpanel } from 'react-mixpanel-browser';
import tractorTGreen from '../../assets/images/operation_tractor_icon.svg';
import {
  getAllAnalysticsTractors,
  getJgDetails,
  getJumpAndGoAllDay,
  getOperators,
} from '../../constants/Api';
import {
  durationData,
  getDateFromString,
  getDateTimesByString,
  isEmpty,
} from '../../constants/Common';
import {
  AllDayContent,
  DriveAction,
  JagDetails,
  TractorResponseType,
  TractorsList,
} from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import AllDayJumpAndGoMoreDetail from './AllDayJumpAndGoMoreDetail';
import JumpAndGoMoreDetail from './JumpAndGoMoreDetail';
import { OperationsDropDown } from './OperationsDropDown';
import { getJumpandGo } from './util';

const { Panel } = Collapse;
const { Option } = Select;
interface Props {
  clearTractorPoints: () => void;
  setHoverName: (user: any) => void;
  setSelectedDriveAction: (id: string) => void;
  // setSelectedAllDayDriveAction: (list: string[]) => void;
  setselectedJumpnGo: (record: any) => void;
  setShowThumbnails: (flag: boolean) => void;
  setRightSideThumbnailsToggle: (flag: boolean) => void;
  jAGD: any;
  setJumpAndGos: (record: any) => void;
  isLoading: boolean;
  setIsLoading: any;
  setLoadAll: any;
  loadAll: boolean;
  fullScreenFlag: boolean;
  driveAction: DriveAction;
}
const allDayContentKey = 'all-day-content';
const Operations: React.FC<Props> = ({
  clearTractorPoints,
  setHoverName,
  setSelectedDriveAction,
  setselectedJumpnGo,
  setShowThumbnails,
  setRightSideThumbnailsToggle,
  // setSelectedAllDayDriveAction,
  jAGD,
  setJumpAndGos,
  isLoading,
  setIsLoading,
  setLoadAll,
  loadAll,
  fullScreenFlag,
  driveAction,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const mixpanel = useMixpanel();
  const [showJNGModel, setShowJNGModel] = useState<boolean>(false);
  const [showAllDayJNGModel, setShowAllDayJNGModel] = useState<boolean>(false);
  const [tractorId, setTractorId] = useState<any>();
  const [operatorId, setOperatorId] = useState<any>();
  const [operations, setOperations] = useState<any>([]);
  const [duration, setDuration] = useState<string>();
  const [operators, setOperators] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([
    moment().subtract(1, 'day').toDate(),
    moment().toDate(),
  ]);
  const [openTab, setOpenTab] = useState<string | string[]>('1');
  const [tractorlist, settractorlist] = useState<TractorsList[]>([]);
  const [selectedJumpnGo, setSelectedJumpnGo] = useState<any>();
  const [jagDetails, setJagDetails] = useState<JagDetails>();
  const [allDay, setAllDay] = useState<boolean>(false);
  const [allDayOperation, setAllDayOperation] =
    useState<AllDayContent | null>();
  const [selectedTractorDetails, setSelectedTractorDetails] =
    useState<TractorsList>();
  const [tractorsData, setTractorsData] = useState<TractorResponseType[]>([
    { label: 'All', value: '', disabled: false, index: '' },
  ]);
  const [openedOperation, setOpenedOperation] =
    useState<string | string[]>(allDayContentKey);

  useEffect(() => {
    return () => {
      settractorlist([]);
      setOperations([]);
      setOperators([]);
    };
  }, []);

  const handleSearch = () => {
    const isValid = selectedDateRange.every((item) => item != null);
    if (!isValid) return;
    if (userDetails && userDetails.organization && isEmpty(driveAction))
      handleSelectFromDate(selectedDateRange);
    handleAllDay();
  };

  useEffect(() => {
    const isValid = selectedDateRange.every((item) => item != null);
    if (!isValid) return;
    if (userDetails && userDetails.organization) {
      loadOperators();
    }
  }, [userDetails, selectedDateRange, tractorId, operatorId, duration]);

  useEffect(() => {
    if (!isEmpty(driveAction) && userDetails && userDetails.organization) {
      const date1 = moment(driveAction?.created_date_time)
        .startOf('day')
        .toDate();
      const date2 = moment(driveAction?.created_date_time)
        .endOf('day')
        .toDate();
      setSelectedDateRange([date1, date2]);
      handleSelectFromDate([date1, date2]);
    }
  }, [driveAction]);

  const handleAllDay = (state = true) => {
    // if (allDay) handleAllDayValidation(allDay);
    if (!allDay) {
      setShowThumbnails(false);
    }
    if (userDetails && userDetails.organization && allDay) {
      setOpenedOperation(allDayContentKey);
      state && getAllDayContent();
      handleReset();
    }
    if (!allDay && selectedJumpnGo) {
      setSelectedDriveAction(selectedJumpnGo.drive_action_uuid);
      setShowThumbnails(true);
    }
  };

  useEffect(() => {
    const isValid = selectedDateRange.every((item) => item != null);
    if (!isValid) return;
    handleAllDay(false);
  }, [allDay]);

  const getAllDayContent = async () => {
    setAllDayOperation(null);
    try {
      const jngResponse = await getJumpAndGoAllDay(
        userDetails.organization.api_url,
        userDetails.organization_id,
        tractorId,
        moment(selectedDateRange[0]).startOf('day').toDate().getTime(),
        moment(selectedDateRange[1]).endOf('day').toDate().getTime(),
      );
      setAllDayOperation(jngResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectFromDate = async (date: any) => {
    try {
      setIsLoading(false);
      setOperations([]);
      setSelectedJumpnGo(null);
      clearTractorPoints();
      const jngResponse = await getJumpandGo(
        userDetails,
        moment(date[0]).startOf('day').toDate().getTime(),
        moment(date[1]).endOf('day').toDate().getTime(),
        tractorId,
        operatorId,
        duration,
      );
      if (!jngResponse.records.length) setIsLoading(true);
      setOperations(jngResponse.records);
    } catch (error) {
      console.error(error);
    }
  };

  const genExtra = () => (
    <img
      onClick={(event) => {
        event.stopPropagation();
      }}
      src={tractorTGreen}
      alt=""
      className="tabTractorImg"
      width="19"
      height="14"
    />
  );

  const getTractorsList = async () => {
    try {
      const tractor: TractorsList[] = await getAllAnalysticsTractors(
        userDetails.organization.api_url,
        userDetails.organization_id,
      );
      const data = tractor && tractor.length > 0 ? tractor : [];
      const tractors: TractorResponseType[] = data.map((d: TractorsList) => {
        return {
          label: d.name,
          value: d.id,
          disabled: false,
          index: d.id,
        };
      });
      settractorlist([...tractorlist, ...data]);
      setTractorsData([...tractorsData, ...tractors]);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error?.message,
      });
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
    }
  }, [userDetails]);

  useEffect(() => {
    if (showJNGModel && selectedJumpnGo) {
      jgDetails();
    }
  }, [showJNGModel, selectedJumpnGo]);

  const jgDetails = async () => {
    try {
      const data = await getJgDetails(
        userDetails.organization.api_url,
        selectedJumpnGo.drive_action_uuid,
      );
      setJagDetails(data);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };

  const selectTractor = () => {
    tractorlist.map((tractor) => {
      if (tractor && tractor.id && tractor.id === tractorId) {
        setSelectedTractorDetails(tractor);
      }
    });
  };

  useEffect(() => {
    if (tractorId) {
      selectTractor();
    } else {
      setSelectedTractorDetails(undefined);
    }
  }, [tractorId]);

  useEffect(() => {
    if (jAGD) {
      setSelectedJumpnGo(jAGD);
      setOpenedOperation(jAGD.id);
      // setShowJNGModel(true);
      setAllDay(false);
    } else {
      setAllDay(true);
    }
  }, [jAGD]);

  const handleReset = () => {
    setSelectedDriveAction('');
    setselectedJumpnGo(null);
    setSelectedJumpnGo(null);
    setShowThumbnails(false);
    setLoadAll(true);
  };

  const onOperationExpandHandler = (idKey: string | string[]) => {
    const index = operations.findIndex((jng: any) => jng.id == idKey);
    if (operations[index]) {
      const jng: any = operations[index];
      mixpanel.track('Analytics Map', {
        event: `Jump & Go Details Clicked`,
      });
      setLoadAll(false);
      setSelectedDriveAction(jng?.drive_action_uuid);
      setselectedJumpnGo(jng);
      setSelectedJumpnGo(jng);
      setHoverName(jng.operator);
      setShowThumbnails(true);
    } else if (idKey === '') {
      handleReset();
    }
  };

  // useEffect(() => {
  //   if (allDay && openedOperation && openedOperation == allDayContentKey) {
  //     if (allDayOperation && allDayOperation?.drive_action_uuids) {
  //       // setSelectedAllDayDriveAction(allDayOperation?.drive_action_uuids);
  //       setShowThumbnails(true);
  //     }
  //   }
  // }, [openedOperation, allDay, allDayOperation]);

  useEffect(() => {
    setJumpAndGos(operations);
  }, [operations]);

  const loadOperators = async () => {
    const result = await getOperators(
      userDetails.organization.api_url,
      moment(selectedDateRange[0]).startOf('day').toDate().getTime(),
      moment(selectedDateRange[1]).endOf('day').toDate().getTime(),
    );
    if (result && result[1] === 404) return;
    const members =
      result &&
      result.map((ele: any) =>
        Object.assign({
          id: ele.id,
          name: ele.first_name + ' ' + ele.last_name,
        }),
      );
    if (members.length) setOperators(members);
  };

  const handleCancel = () => {
    setShowJNGModel(false);
  };

  const handleAllDayValidation = (checked: boolean) => {
    setAllDay(checked);
  };

  const handleSelectDateRange = (dates: any[]) => {
    let [date1, date2] = dates;
    date1 = date1 && moment(date1).startOf('day').toDate();
    date2 = date2 && moment(date2).endOf('day').toDate();
    if (date1 && date2) {
      date1.getTime() < date2.getTime() && setSelectedDateRange([date1, date2]);
    } else {
      setSelectedDateRange([date1, date2]);
    }
  };

  return (
    <div
      className={
        fullScreenFlag
          ? 'searchOperationsBlk fullsearchOperationsBlk'
          : 'searchOperationsBlk'
      }
    >
      {!showJNGModel && !showAllDayJNGModel && (
        <div className="operationsBlk">
          <Collapse
            accordion
            expandIconPosition="right"
            className="parentAccordion parentAccordionHeight"
            expandIcon={({ isActive }) => (
              <LeftOutlined rotate={isActive ? 270 : 360} />
            )}
            defaultActiveKey={openTab}
            onChange={(key: string | string[]) => setOpenTab(key)}
          >
            <Panel
              header="OPERATIONS"
              className="operationsTitle"
              key="1"
              extra={genExtra()}
            >
              <div className="operationsBlock anaOvf">
                <div className="tsDatePic">
                  <ReactDatePicker
                    isClearable
                    maxDate={new Date()}
                    selected={selectedDateRange[0]}
                    placeholderText="Select Start Date"
                    dropdownMode="select"
                    dateFormat="MMMM dd, yyyy"
                    onChange={(date: any) => {
                      mixpanel.track('Analytics Map', {
                        event: `${date} selected`,
                      });
                      handleSelectDateRange([date, selectedDateRange[1]]);
                      // setSelectedDateRange((selectedDateRange) => [
                      //   date,
                      //   selectedDateRange[1],
                      // ]);
                    }}
                    className="dpicker dpickerWidth"
                    // readOnly={!isLoading}
                  />
                </div>
                <div className="tsDatePic">
                  <ReactDatePicker
                    isClearable
                    maxDate={new Date()}
                    selected={selectedDateRange[1]}
                    placeholderText="Select End Date"
                    dropdownMode="select"
                    dateFormat="MMMM dd, yyyy"
                    onChange={(date: any) => {
                      mixpanel.track('Analytics Map', {
                        event: `${date} selected`,
                      });
                      handleSelectDateRange([selectedDateRange[0], date]);
                      // setSelectedDateRange((selectedDateRange) => [
                      //   selectedDateRange[0],
                      //   date,
                      // ]);
                    }}
                    className="dpicker dpickerWidth"
                    // readOnly={!isLoading}
                  />
                </div>
                <div className="selectTractor sTcr">
                  <Select
                    style={{ width: 146, marginRight: 10 }}
                    placeholder="Select Tractor"
                    value={tractorId}
                    onSelect={(e: any) => {
                      mixpanel.track('Analytics Map', {
                        event: `tractor Id ${e} selected`,
                      });
                      setTractorId(e);
                    }}
                    disabled={!isLoading}
                  >
                    <Option value="">All</Option>
                    {tractorlist &&
                      tractorlist.length > 0 &&
                      tractorlist.map((data: TractorsList) => (
                        <Option key={data.id} value={data.id}>
                          {data.name}
                        </Option>
                      ))}
                  </Select>
                </div>
                {!allDay && (
                  <>
                    <div className="selectTractor sOps">
                      <Select
                        style={{ width: 146, marginRight: 10 }}
                        placeholder="Select Operator"
                        value={operatorId}
                        onSelect={(e: any) => {
                          mixpanel.track('Analytics Map', {
                            event: `Tractor Id ${e} selected`,
                          });
                          setOperatorId(e);
                        }}
                        disabled={!isLoading}
                      >
                        <Option value="">All</Option>
                        {operators &&
                          operators.length > 0 &&
                          operators.map((data: any) => (
                            <Option key={data.id} value={data.id}>
                              {data.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                    <div className="selectTractor sOps">
                      <Select
                        style={{ width: 146, marginRight: 10 }}
                        placeholder="Select Duration"
                        value={duration}
                        onSelect={(e: any) => {
                          mixpanel.track('Analytics Map', {
                            event: `Duration ${e} selected`,
                          });
                          setDuration(e);
                        }}
                        disabled={!isLoading}
                      >
                        <Option value="">All</Option>
                        {durationData &&
                          durationData.length > 0 &&
                          durationData.map((data: any) => (
                            <Option key={data.value} value={data.value}>
                              {data.label}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </>
                )}
                <div>
                  <Button
                    className="ant-btn"
                    block
                    style={{
                      backgroundColor: '#1e3a46',
                      color: '#FFF',
                      marginBottom: '10px',
                    }}
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </div>
                <div className="allDay">
                  <div className="checkBoxItem">
                    <span>
                      <Checkbox
                        disabled={!isLoading}
                        checked={allDay}
                        onChange={(e) => {
                          mixpanel.track('Analytics Map', {
                            event: `Checked ${e.target.checked}`,
                          });
                          handleAllDayValidation(e.target.checked);
                        }}
                      >
                        <span className="layerName">Cumulative</span>
                      </Checkbox>
                    </span>
                  </div>
                </div>
                {selectedDateRange[1] && (
                  <div
                    className="operationsTab"
                    style={{ display: isLoading ? 'block' : 'none' }}
                  >
                    {!allDay && (
                      <OperationsDropDown
                        jumpAndGos={operations}
                        handleOnSelect={(key: any) => {
                          mixpanel.track('Analytics Map', {
                            event: `Selected JumpandGo ${key} selected`,
                          });
                          if (key) {
                            setRightSideThumbnailsToggle(true);
                          } else {
                            setRightSideThumbnailsToggle(false);
                          }

                          setOpenedOperation(key);
                          onOperationExpandHandler(key);
                          setAllDay(!key);
                        }}
                        selectedJAG={selectedJumpnGo}
                        setShowJNGModel={(state: boolean) =>
                          setShowJNGModel(state)
                        }
                        loadAll={loadAll}
                        // openedOperation={openedOperation}
                      />
                    )}

                    {allDay && allDayOperation && (
                      <Collapse
                        // defaultActiveKey={openedOperation}
                        accordion
                        expandIconPosition="right"
                        className="childAccordion childAccordion2"
                        expandIcon={({ isActive }) => (
                          <LeftOutlined rotate={isActive ? 270 : 360} />
                        )}
                        onChange={() => {
                          setOpenedOperation(allDayContentKey);
                        }}
                        style={{ paddingTop: '0px' }}
                        activeKey={openedOperation}
                      >
                        <Panel
                          header={
                            allDayOperation &&
                            allDayOperation?.start_time &&
                            allDayOperation?.start_time != null
                              ? ' ' +
                                getDateFromString(allDayOperation?.start_time)
                              : selectedTractorDetails?.name
                          }
                          key={allDayContentKey}
                        >
                          <div
                            className="operation-record operationsInrAccordion"
                            // onClick={() => {
                            //   setSelectedAllDayDriveAction(
                            //     allDayOperation?.drive_action_uuids,
                            //   );
                            //   setShowThumbnails(true);
                            // }}
                          >
                            <p>
                              <span className="operationTitle">Operator: </span>

                              <span>
                                {allDayOperation &&
                                allDayOperation.operators &&
                                allDayOperation.operators.length > 0
                                  ? allDayOperation.operators.map(
                                      (operator, index) => (
                                        <span key={index}>
                                          {operator.first_name +
                                            ' ' +
                                            operator.last_name}
                                          {index <
                                            allDayOperation.operators.length -
                                              1 && ', '}
                                        </span>
                                      ),
                                    )
                                  : '-'}
                              </span>
                            </p>
                            <p>
                              <span className="operationTitle">
                                Start Time:{' '}
                              </span>
                              <span>
                                {allDayOperation?.start_time &&
                                allDayOperation?.start_time != null
                                  ? getDateTimesByString(
                                      allDayOperation?.start_time,
                                    )
                                  : '-'}
                              </span>
                            </p>
                            <p>
                              <span className="operationTitle">
                                Finish Time:{' '}
                              </span>
                              <span>
                                {allDayOperation?.finish_time &&
                                allDayOperation?.finish_time != null
                                  ? getDateTimesByString(
                                      allDayOperation?.finish_time,
                                    )
                                  : '-'}
                              </span>
                            </p>
                            {operations.length > 0 && (
                              <h5
                                className="moreBtn"
                                onClick={() => setShowAllDayJNGModel(true)}
                              >
                                MORE
                              </h5>
                            )}
                          </div>
                        </Panel>
                      </Collapse>
                    )}
                  </div>
                )}
              </div>
            </Panel>
          </Collapse>
        </div>
      )}

      {/* /* operational section */}
      {showJNGModel && (
        <Modal
          visible={true}
          onCancel={handleCancel}
          width={1200}
          bodyStyle={{ height: 'auto' }}
          footer={false}
          className="opPopup"
        >
          <JumpAndGoMoreDetail
            selectedJumpnGo={selectedJumpnGo}
            jagDetails={jagDetails as JagDetails}
            // setOpenTab={(openTab) => setOpenTab(openTab)}
            // setShowJNGModel={(showJNGModel) => setShowJNGModel(showJNGModel)}
          />
        </Modal>
      )}

      {/* /* operational section */}
      <Modal
        visible={showAllDayJNGModel}
        onCancel={() => setShowAllDayJNGModel(false)}
        width={1200}
        bodyStyle={{ height: 'auto' }}
        footer={false}
        className="opPopup"
      >
        {showAllDayJNGModel && (
          <AllDayJumpAndGoMoreDetail
            selectedTractorDetails={selectedTractorDetails}
            allDayOperation={allDayOperation as AllDayContent}
            selectedDateRange={selectedDateRange}
            tractorId={tractorId}
          />
        )}
      </Modal>
    </div>
  );
};

export default Operations;
