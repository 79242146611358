/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Layout,
  Space,
  Row,
  Col,
  Spin,
  notification,
} from 'antd';
import loading_logo from '../../assets/images/loading_logo.gif';
import reset_password from '../../assets/images/reset_password.svg';
import reject from '../../assets/images/reject.svg';
import success_check from '../../assets/images/success_check.svg';
import { Content } from 'antd/lib/layout/layout';
import applicationIds from '../../locale/applicationIds.json';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import logoHorizontalWhite from '../../assets/images/logo_horizontal_white_rgb.png';
import RoutesConstants from '../../routes/RoutesConstant';
import { useHistory, useLocation } from 'react-router';
import logoWingspanAI from '../../assets/images/WingspanAI_logo_temp.svg';
import './style.css';
import { addPassword, checkTokenData } from '../../constants/Api';
import { checkAlphaFeature } from '../../constants/Common';

const ResetPassword: React.FC = () => {
  const { push } = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [token, setToken] = useState('');
  const [spinning, setSpinning] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [same, setSame] = useState(false);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [isSpecialCharactorExist, setIsSpecialCharactorExist] = useState(false);
  const [isNumberExist, setIsNumberExist] = useState(false);
  const [allowToUpdate, setAllowToUpdate] = useState(false);
  const [resetFlag, setResetFlag] = useState<boolean>(false);
  const specialCharactorFormate = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  const numberFormate = /\d/g;
  const action = String(queryParams.get('action'));

  const handleResetPassword = async () => {
    try {
      setSpinning(true);
      const { msg } = await addPassword({
        token,
        password,
        action,
        app_type: 'web',
      });
      if (msg) {
        push(RoutesConstants.ResetPwSuccess);
        notification.success({
          message: 'Password updated succesfully',
        });
      } else {
        notification.error({
          message: msg,
        });
        push(RoutesConstants.Login);
      }
    } catch (err: any) {
      notification.error({
        message: err.response?.data?.error?.message || err.message,
      });
    } finally {
      setSpinning(false);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = String(query.get('token'));
    if (token) setToken(token);
  }, []);

  useEffect(() => {
    if (token) checkToken();
  }, [token]);

  const checkToken = async () => {
    try {
      setSpinning(true);
      await checkTokenData(token);
      setResetFlag(true);
    } catch (err: any) {
      notification.error({
        message: err.response?.data?.error?.message || err.message,
      });
    } finally {
      setSpinning(false);
    }
  };

  useEffect(() => {
    if (password.trim().length > 7 && password.trim().length < 15) {
      setIsLengthValid(true);
    } else {
      setIsLengthValid(false);
    }
    if (
      password &&
      confirmPassword &&
      password.trim() !== '' &&
      confirmPassword.trim() !== '' &&
      password.trim() === confirmPassword.trim()
    ) {
      setSame(true);
    } else {
      setSame(false);
    }
    if (specialCharactorFormate.test(password.trim())) {
      setIsSpecialCharactorExist(true);
    } else {
      setIsSpecialCharactorExist(false);
    }
    if (numberFormate.test(password.trim())) {
      setIsNumberExist(true);
    } else {
      setIsNumberExist(false);
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    if (same && isLengthValid && isSpecialCharactorExist && isNumberExist) {
      setAllowToUpdate(true);
    } else {
      setAllowToUpdate(false);
    }
  }, [same, isLengthValid, isSpecialCharactorExist, isNumberExist]);

  return (
    <>
      {/* {resetFlag && ( */}
      <Spin
        className="loader"
        spinning={spinning}
        indicator={
          <img style={{ width: '40px', height: '40px' }} src={loading_logo} />
        }
      >
        <div className="loginContainer">
          <div
            className={
              checkAlphaFeature('wingspanai')
                ? 'wai_forgot_wrapper'
                : 'resetWrap'
            }
          >
            <Row wrap={false}>
              <div className="loginLeft w48">
                <Col flex="436px">
                  <img
                    height="60px"
                    width="60px"
                    alt="monarch"
                    src={reset_password}
                  />
                  <p className="mtb30">
                    {action === 'change_password' ? 'Set' : 'Reset'} Your
                    Password
                  </p>
                  <div className="resetCont">
                    <div className="mb40">
                      In order to protect you account, make sure password:
                    </div>
                    <div className="resetContChar mb30">
                      <img src={isLengthValid ? success_check : reject} />
                      Is 8 to 14 characters long
                    </div>
                    <div className="resetContChar mb30">
                      <img src={same ? success_check : reject} />
                      Does not match your Password
                    </div>
                    <div className="resetContChar mb30">
                      <img
                        src={isSpecialCharactorExist ? success_check : reject}
                      />
                      Contains a special character
                    </div>
                    <div className="resetContChar mb30">
                      <img src={isNumberExist ? success_check : reject} />
                      Contains a number
                    </div>
                  </div>
                </Col>
              </div>
              <div className="loginRight w52">
                <Col>
                  <Layout className="layout">
                    <Content className="container">
                      <Space direction="vertical">
                        {checkAlphaFeature('wingspanai') ? (
                          <img
                            height="70px"
                            src={logoWingspanAI}
                            style={{ marginBottom: 70, cursor: 'pointer' }}
                          />
                        ) : (
                          <img
                            style={{ marginBottom: 70, cursor: 'pointer' }}
                            alt="monarch"
                            width="285px"
                            height="50px"
                            src={logoHorizontalWhite}
                            onClick={() => push(RoutesConstants.Login)}
                          />
                        )}
                        {/* <img
                          style={{ marginBottom: 70, cursor: 'pointer' }}
                          alt="monarch"
                          width="285px"
                          height="50px"
                          src={logoHorizontalWhite}
                          onClick={() => push(RoutesConstants.Login)}
                        /> */}
                        <div className="loginFRow">
                          <label className="labelTxt userId">
                            New Password
                          </label>
                          <Input.Password
                            data-testid="newPassword-ResetPassword"
                            id={applicationIds.ResetPasswordScreen.newPassword}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            // onPressEnter={(e) => handleLogin(e)}
                            placeholder=""
                            type="text"
                            iconRender={(visible) =>
                              visible ? (
                                <EyeTwoTone style={{ color: '#fff' }} />
                              ) : (
                                <EyeInvisibleOutlined
                                  style={{ color: '#fff' }}
                                />
                              )
                            }
                          />
                        </div>
                        <div className="loginFRow mb30">
                          <label className="labelTxt">
                            Confirm New Password
                          </label>
                          <Input.Password
                            data-testid="confirmNewPassword-ResetPassword"
                            id={
                              applicationIds.ResetPasswordScreen
                                .confirmNewPassword
                            }
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            // onPressEnter={(e) => handleLogin(e)}
                            placeholder=""
                            iconRender={(visible) =>
                              visible ? (
                                <EyeTwoTone style={{ color: '#fff' }} />
                              ) : (
                                <EyeInvisibleOutlined
                                  style={{ color: '#fff' }}
                                />
                              )
                            }
                          />
                        </div>
                        <Button
                          data-testid="updateMyPasswordButton-ResetPassword"
                          id={applicationIds.ResetPasswordScreen.updateBtn}
                          type="primary"
                          onClick={handleResetPassword}
                          className={
                            checkAlphaFeature('wingspanai')
                              ? 'btnTxt wspanAiBtn mt20'
                              : 'btnTxt mt20'
                          }
                          disabled={!allowToUpdate}
                        >
                          {action === 'change_password'
                            ? 'SET PASSWORD'
                            : 'UPDATE PASSWORD'}
                        </Button>
                      </Space>
                    </Content>
                  </Layout>
                </Col>
              </div>
            </Row>
          </div>
          {/* {resetSuccess && <ResetPwSuccess />} */}
        </div>
      </Spin>
      {/* )} */}
    </>
  );
};

export default ResetPassword;
