import React from 'react';

interface Props {
  analytics: any;
}

const AnalyticStats: React.FC<Props> = ({ analytics }) => {
  return (
    <div className="sprayoperhrs">
      <div className="operationalHours mb5">
        <div className="totalHours">Covered Acres</div>
        <div className="hrMinTxt">
          {analytics?.area_covered_in_acre
            ? `${Number(analytics?.area_covered_in_acre)
                .toFixed(2)
                .toLocaleString()} Acres`
            : '-'}
        </div>
      </div>

      <div className="operationalHours mb5">
        <div className="totalHours">Covered Vine Rows</div>
        <div className="hrMinTxt">
          {analytics?.num_of_vine_rows_covered
            ? `${Number(analytics?.num_of_vine_rows_covered).toFixed(0)}`
            : '-'}
        </div>
      </div>

      <div className="operationalHours mb5">
        <div className="totalHours">Acres per hour</div>
        <div className="hrMinTxt">
          {analytics?.acres_per_hours
            ? `${Number(analytics?.acres_per_hours).toFixed(2)}`
            : '-'}
        </div>
      </div>

      <div className="operationalHours mb5">
        <div className="totalHours">Coverage</div>
        <div className="hrMinTxt">
          {analytics?.distance
            ? `${Number(analytics?.distance).toFixed(2).toLocaleString()} Miles`
            : '-'}
        </div>
      </div>

      <div className="line" />

      <div className="operationalHours mb5">
        <div className="totalHours">Operational Savings</div>
        <div className="hrMinTxt">
          {analytics?.operational_savings
            ? `$${Number(analytics?.operational_savings).toFixed(2)}`
            : '-'}
        </div>
      </div>

      <div className="operationalHours mb5">
        <div className="totalHours">Avg Speed</div>
        <div className="hrMinTxt">
          {analytics?.avg_speed_in_miles
            ? `${Number(analytics?.avg_speed_in_miles).toFixed(1)} Mph`
            : '-'}
        </div>
      </div>

      <div className="operationalHours mb5">
        <div className="totalHours">
          CO<sub>2</sub> Savings
        </div>
        <div className="hrMinTxt">
          {analytics?.aggregate_emission_reduction
            ? `${Number(analytics?.aggregate_emission_reduction).toFixed(2)} kg`
            : '-'}
        </div>
      </div>

      <div className="operationalHours mb5">
        <div className="totalHours">Energy Used</div>
        <div className="hrMinTxt">
          {analytics?.jng_start_soc
            ? `${analytics?.jng_start_soc - analytics?.jng_finish_soc}%`
            : '-'}{' '}
          (
          <span>
            {analytics?.energy
              ? `${Number(analytics?.energy).toFixed(2).toLocaleString()} kwh`
              : '-'}
          </span>
          )
        </div>
      </div>

      <div className="line" />

      <div className="operationalHours mb5">
        <div className="totalHours">Electricity Rate</div>
        <div className="hrMinTxt">
          {analytics?.electricity_rate
            ? `$${Number(analytics?.electricity_rate).toFixed(2)}`
            : '-'}{' '}
          / Kwh
        </div>
      </div>
      <div className="operationalHours mb5">
        <div className="totalHours">Diesel Rate</div>
        <div className="hrMinTxt">
          {analytics?.electricity_rate
            ? `$${Number(analytics?.diesel_cost).toFixed(2)}`
            : '-'}{' '}
          / Gallon
        </div>
      </div>
    </div>
  );
};

export default AnalyticStats;
