import React, { useContext } from 'react';
import { Button, Modal, notification } from 'antd';
import { ApplicationContext } from '../../context/AppContext';
import logo from '../../assets/images/logo192.png';
import './style.css';
import { removeTeamMember } from '../../constants/Api';

interface Props {
  showModal?: boolean;
  closeModel: (success: boolean) => void;
  userProfile: any;
  teamDesc: any;
  setRefresh: (key: any) => void;
  setToggleWidget: (key: any) => void;
}

export const RemoveFromTeam: React.FC<Props> = ({
  showModal,
  closeModel,
  userProfile,
  teamDesc,
  setRefresh,
  setToggleWidget,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);

  const RemoveTeam = async () => {
    try {
      const data: any = await removeTeamMember(
        userDetails.organization.api_url,
        userDetails.organization.id,
        teamDesc.id,
        userProfile.id,
      );
      notification.success({ message: data.msg });
      setToggleWidget(false);
      closeModel(false);

      setRefresh(true);
    } catch (error: any) {
      notification.error({ message: 'Unable to Remove From Team' });
    }
  };

  return (
    <>
      <Modal
        className="profileUpdatePopup teammodal"
        visible={showModal}
        onCancel={() => closeModel(false)}
        width={590}
        closable={false}
        maskClosable={false}
        footer={[
          <>
            <Button
              key="back"
              className="btnteamCancel Button"
              onClick={() => closeModel(false)}
            >
              Cancel
            </Button>
            <Button className="btnteamSave Button" onClick={RemoveTeam}>
              REMOVE
            </Button>
          </>,
        ]}
      >
        <div className="createteamContainer">
          <div className="createtitle mb25">
            <div className="pull-left">
              <h1 className="title headline1">Remove From Team ?</h1>
            </div>
            <div className="img-div pull-right">
              <img src={logo} />
            </div>
          </div>
          <p className="teamdescription mb38 body1">
            Are you sure you want to remove {userProfile?.first_name}{' '}
            {userProfile?.last_name} from {teamDesc.name} Team?
          </p>
        </div>
      </Modal>
    </>
  );
};

export default RemoveFromTeam;
