/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';

interface Props {
  showModal: boolean;
  closeModal: () => void;
}

export const UserManualPdfModal: React.FC<Props> = ({
  showModal,
  closeModal,
}) => {
  const [numPages, setNumPages] = useState<number>(0);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Modal
      visible={showModal}
      title="How to Manual"
      className="userManualCont"
      onCancel={closeModal}
      footer={false}
      centered
    >
      <div>
        <Document
          file="../userManual.pdf"
          options={{ workerSrc: '/pdf.worker.js' }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
    </Modal>
  );
};
