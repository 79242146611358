/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import driveability_green from '../../assets/images/driveability_green.svg';
import connectivity_green from '../../assets/images/connectivity_green.svg';
import autonomy_green from '../../assets/images/autonomy_green.svg';
import battery_green from '../../assets/images/battery_green.svg';
import uploading_green from '../../assets/images/uploading_green.svg';

import driveability_red from '../../assets/images/driveability_red.svg';
import connectivity_red from '../../assets/images/connectivity_red.svg';
import autonomy_red from '../../assets/images/autonomy_red.svg';
import uploading_red from '../../assets/images/uploading_red.png';
import battery_red from '../../assets/images/battery_red.svg';

import driveability_yellow from '../../assets/images/driveability_yellow.svg';
import connectivity_yellow from '../../assets/images/connectivity_yellow.svg';
import autonomy_yellow from '../../assets/images/autonomy_yellow.svg';
import uploading_yellow from '../../assets/images/uploading_yellow.png';
import battery_yellow from '../../assets/images/battery_yellow.svg';

import AutonomyBlack from '../../assets/images/AutonomyBlack.svg';
import BatteryBlack from '../../assets/images/batteryBlack1.svg';
import HealthBlack from '../../assets/images/HealthBlack.svg';
import StorageBlack from '../../assets/images/StorageBlack.svg';

import autonomy_orange from '../../assets/images/autonomy_orange.svg';
import battery_orange from '../../assets/images/battery_orange.svg';
import connectivity_orange from '../../assets/images/connectivity_orange.svg';
import driveability_orange from '../../assets/images/driveability_orange.svg';
import upload_orange from '../../assets/images/upload_orange.svg';

let grayFlag = false;

export const getDriveabilityImage = (level: number, type?: boolean) => {
  if (type) {
    if (level === 0) return driveability_green;
    if (level === 1) return driveability_yellow;
    if (level === 2 || level === 3) return driveability_orange;
    if (level === 3) return driveability_red;
    return;
  }
  if (grayFlag) return HealthBlack;
  if (level === 0) return driveability_green;
  if (level === 1) return driveability_yellow;
  if (level === 2 || level === 3) return driveability_orange;
  if (level === 3) return driveability_red;
  return '';
};
export const getconnectivityImage = (level: number, type?: boolean) => {
  if (level === 0) {
    grayFlag = false;
    return connectivity_green;
  }
  if (level === 1) {
    grayFlag = false;
    return connectivity_yellow;
  }
  if (level === 2) {
    grayFlag = false;
    return connectivity_orange;
  }
  if (level === 3) {
    grayFlag = true;
    return connectivity_red;
  }
  return '';
};
export const getautonomyImage = (level: number, type?: boolean) => {
  if (type) {
    if (level === 0) return autonomy_green;
    if (level === 1) return autonomy_yellow;
    if (level === 2 || level === 3) return autonomy_orange;
    if (level === 3) return autonomy_red;
    return;
  }
  if (grayFlag) return AutonomyBlack;
  if (level === 0) return autonomy_green;
  if (level === 1) return autonomy_yellow;
  if (level === 2 || level === 3) return autonomy_orange;
  if (level === 3) return autonomy_red;
  return '';
};
export const getuploadingImage = (level: number, type?: boolean) => {
  if (type) {
    if (level === 0) return uploading_green;
    if (level === 1) return uploading_yellow;
    if (level === 2 || level === 3) return upload_orange;
    if (level === 3) return uploading_red;
    return;
  }
  if (grayFlag) return StorageBlack;
  if (level === 0) return uploading_green;
  if (level === 1) return uploading_yellow;
  if (level === 2 || level === 3) return upload_orange;
  if (level === 3) return uploading_red;
  return '';
};
export const getEnergyImage = (level: number, type?: boolean) => {
  if (type) {
    if (level === 0) return battery_green;
    if (level === 1) return battery_yellow;
    if (level === 2 || level === 3) return battery_orange;
    if (level === 3) return battery_red;
    return;
  }
  if (grayFlag) return BatteryBlack;
  if (level === 0) return battery_green;
  if (level === 1) return battery_yellow;
  if (level === 2 || level === 3) return battery_orange;
  if (level === 3) return battery_red;
  return '';
};

export const getIndicatorImages = (
  key: string,
  level: number,
  type?: boolean,
) => {
  switch (key) {
    case 'Tractor Communication':
      return getconnectivityImage(level, type);
    case 'Tractor Automation':
      return getautonomyImage(level, type);
    case 'Tractor Drivability':
      return getDriveabilityImage(level, type);
    case 'Tractor Data':
      return getuploadingImage(level, type);
    case 'Tractor Energy':
      return getEnergyImage(level, type);
    default:
      return '';
  }
};

export const getClickStatus: (key: string) => boolean | '' = (key: string) => {
  switch (key) {
    case 'Tractor Communication':
      return false;
    case 'Tractor Automation':
      return grayFlag;
    case 'Tractor Drivability':
      return grayFlag;
    case 'Tractor Data':
      return grayFlag;
    case 'Tractor Energy':
      return grayFlag;
    default:
      return '';
  }
};

export const getBaseStationIndicatorImages = (
  key: string,
  level: number,
  type?: boolean,
) => {
  switch (key) {
    case 'basestation_communication':
      return getconnectivityImage(level, type);
    case 'basestation_data':
      return getuploadingImage(level, type);
    default:
      return '';
  }
};
