/* eslint-disable no-console */
/* eslint-disable react/jsx-key */
import { Input, Modal, notification, Select, Switch } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getCategoryList,
  getimplementbyId,
  getImplementConfigById,
} from '../../constants/Api';
import {
  onKeyDown,
  onlyImplmentsNumberAndDecimalsAllow,
} from '../../constants/Common';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';
import '../farmImplements/ImpStyle.css';

interface Props {
  showModal: boolean;
  handleDetails: (details?: any) => void;
  id: string;
  tractorId: number;
  driveActionUuid: string;
  isDisabled: boolean;
}

const AutonomySettingsWidget: React.FC<Props> = ({
  showModal,
  handleDetails,
  id,
  tractorId,
  driveActionUuid,
  isDisabled,
}: Props) => {
  const { t } = useTranslation();
  const [details, setDetails] = useState<any>({});
  const [implement, setImplement] = useState<any>();
  const { userDetails } = useContext(ApplicationContext);
  const [hitchMode, setHitchMode] = useState<any[]>([]);
  const [savedImplementDetails, setSavedImplementDetails] = useState<any>();
  const [disableSave, setDisableSave] = useState(false);

  const getImplement = async (id: string) => {
    try {
      const resp = await getimplementbyId(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        id,
      );
      setImplement(resp);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };
  const getSavedImplement = async () => {
    try {
      const resp = await getImplementConfigById(
        userDetails.organization.api_url,
        tractorId,
        driveActionUuid,
        id,
      );
      setSavedImplementDetails(resp);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  useEffect(() => {
    userDetails && userDetails.organization && id && getImplement(id);
  }, [userDetails, id]);
  useEffect(() => {
    userDetails &&
      userDetails.organization &&
      tractorId &&
      driveActionUuid &&
      getSavedImplement();
  }, [userDetails, tractorId, id, driveActionUuid]);

  useEffect(() => {
    userDetails && userDetails.organization && getProfileLookps();
  }, [userDetails]);

  const getProfileLookps = async () => {
    const cropsData = await getCategoryList(
      userDetails.organization.api_url,
      '3pt_hitch_mode',
    );
    setHitchMode(cropsData);
  };

  useEffect(() => {
    const implementDetails =
      savedImplementDetails &&
      savedImplementDetails.config_implements_uuid &&
      savedImplementDetails.config_implements_uuid.length > 0
        ? savedImplementDetails
        : implement;
    if (!implementDetails) return;
    const {
      config_implements_uuid,
      imp_ctrl_in_row_3pt_hitch_height,
      imp_ctrl_in_row_3pt_hitch_mode,
      imp_ctrl_in_row_pto,
      imp_ctrl_in_row_pto_rpm,
      imp_ctrl_in_row_tractor_speed,
      imp_ctrl_out_of_row_3pt_hitch_height,
      imp_ctrl_out_of_row_3pt_hitch_mode,
      imp_ctrl_out_of_row_pto,
      imp_ctrl_out_of_row_pto_rpm,
      imp_ctrl_out_of_row_tractor_speed,
      imp_ctrl_in_row_toplink_pos,
      imp_ctrl_in_row_aux_hydraulics_1,
      imp_ctrl_in_row_aux_hydraulics_2,
      imp_ctrl_in_row_aux_hydraulics_6,
      imp_ctrl_in_row_generic_ctrl_1,
      imp_ctrl_in_row_generic_ctrl_2,
      imp_ctrl_in_row_generic_ctrl_3,
      imp_ctrl_in_row_generic_ctrl_4,
      imp_ctrl_in_row_generic_ctrl_5,
      imp_ctrl_in_row_generic_ctrl_6,
      imp_ctrl_out_of_row_toplink_pos,
      imp_ctrl_out_of_row_aux_hydraulics_1,
      imp_ctrl_out_of_row_aux_hydraulics_2,
      imp_ctrl_out_of_row_aux_hydraulics_6,
      imp_ctrl_out_of_row_generic_ctrl_1,
      imp_ctrl_out_of_row_generic_ctrl_2,
      imp_ctrl_out_of_row_generic_ctrl_3,
      imp_ctrl_out_of_row_generic_ctrl_4,
      imp_ctrl_out_of_row_generic_ctrl_5,
      imp_ctrl_out_of_row_generic_ctrl_6,
      name,
      config_imm,
      config_imm_uuid,
      power,
      attachment_type,
      implement_type,
      serial_number,
      floating_hitch,
      height,
      width,
      length,
      weight,
    } = implementDetails;
    const { manufacturer, model } =
      config_imm && config_imm.manufacturer
        ? config_imm
        : { manufacturer: '', model: '' };
    setDetails({
      config_imm_uuid: config_imm_uuid || '',
      config_implements_uuid: config_implements_uuid || '',
      imp_ctrl_in_row_3pt_hitch_height: imp_ctrl_in_row_3pt_hitch_height || 0,
      imp_ctrl_in_row_3pt_hitch_mode: imp_ctrl_in_row_3pt_hitch_mode || '',
      imp_ctrl_in_row_pto: imp_ctrl_in_row_pto ? true : false,
      imp_ctrl_in_row_pto_rpm: imp_ctrl_in_row_pto_rpm || 0,
      imp_ctrl_in_row_tractor_speed: imp_ctrl_in_row_tractor_speed || 0,
      imp_ctrl_out_of_row_3pt_hitch_height:
        imp_ctrl_out_of_row_3pt_hitch_height || 0,
      imp_ctrl_out_of_row_3pt_hitch_mode:
        imp_ctrl_out_of_row_3pt_hitch_mode || '',
      imp_ctrl_out_of_row_pto: imp_ctrl_out_of_row_pto ? true : false,
      imp_ctrl_out_of_row_pto_rpm: imp_ctrl_out_of_row_pto_rpm || 0,
      imp_ctrl_out_of_row_tractor_speed: imp_ctrl_out_of_row_tractor_speed || 0,
      imp_ctrl_in_row_toplink_pos: imp_ctrl_in_row_toplink_pos || 0,
      imp_ctrl_in_row_aux_hydraulics_1: imp_ctrl_in_row_aux_hydraulics_1 || 0,
      imp_ctrl_in_row_aux_hydraulics_2: imp_ctrl_in_row_aux_hydraulics_2 || 0,
      imp_ctrl_in_row_aux_hydraulics_6: imp_ctrl_in_row_aux_hydraulics_6 || 0,
      imp_ctrl_in_row_generic_ctrl_1: imp_ctrl_in_row_generic_ctrl_1
        ? true
        : false,
      imp_ctrl_in_row_generic_ctrl_2: imp_ctrl_in_row_generic_ctrl_2
        ? true
        : false,
      imp_ctrl_in_row_generic_ctrl_3: imp_ctrl_in_row_generic_ctrl_3
        ? true
        : false,
      imp_ctrl_in_row_generic_ctrl_4: imp_ctrl_in_row_generic_ctrl_4
        ? true
        : false,
      imp_ctrl_in_row_generic_ctrl_5: imp_ctrl_in_row_generic_ctrl_5 || 0,
      imp_ctrl_in_row_generic_ctrl_6: imp_ctrl_in_row_generic_ctrl_6 || 0,
      imp_ctrl_out_of_row_toplink_pos: imp_ctrl_out_of_row_toplink_pos || 0,
      imp_ctrl_out_of_row_aux_hydraulics_1:
        imp_ctrl_out_of_row_aux_hydraulics_1 || 0,
      imp_ctrl_out_of_row_aux_hydraulics_2:
        imp_ctrl_out_of_row_aux_hydraulics_2 || 0,
      imp_ctrl_out_of_row_aux_hydraulics_6:
        imp_ctrl_out_of_row_aux_hydraulics_6 || 0,
      imp_ctrl_out_of_row_generic_ctrl_1: imp_ctrl_out_of_row_generic_ctrl_1
        ? true
        : false,
      imp_ctrl_out_of_row_generic_ctrl_2: imp_ctrl_out_of_row_generic_ctrl_2
        ? true
        : false,
      imp_ctrl_out_of_row_generic_ctrl_3: imp_ctrl_out_of_row_generic_ctrl_3
        ? true
        : false,
      imp_ctrl_out_of_row_generic_ctrl_4: imp_ctrl_out_of_row_generic_ctrl_4
        ? true
        : false,
      imp_ctrl_out_of_row_generic_ctrl_5:
        imp_ctrl_out_of_row_generic_ctrl_5 || 0,
      imp_ctrl_out_of_row_generic_ctrl_6:
        imp_ctrl_out_of_row_generic_ctrl_6 || 0,
      name: name || '',
      manufacturer: manufacturer || '',
      model: config_imm_uuid || '',
      power: power || 0,
      attachment_type: attachment_type || '',
      implement_type: implement_type || '',
      serial_number: serial_number || 0,
      floating_hitch: floating_hitch ? true : false,
      height: height || 0,
      width: width || 0,
      length: length || 0,
      weight: weight || 0,
    });
  }, [savedImplementDetails, implement]);

  const CSlider = (field: string, placeholder: string, range: any) => {
    const [min, max] = range;
    return (
      <>
        <div className="fieldRange">
          <Input
            type="number"
            disabled={isDisabled}
            style={{ width: '284px' }}
            placeholder={placeholder}
            onKeyDown={(e: any) => onKeyDown(e)}
            value={details[field]}
            onChange={(e) => {
              handleOnChangeNum(e, field, min, max);
            }}
          />
        </div>
      </>
    );
  };

  const handleOnChangeNum = (e: any, field: any, min: any, max: any) => {
    const str = e.target.value;
    const regx = /^[+-]?\d+(\.\d+)?$/;
    if (min <= str && str <= max && (str == 0 || regx.test(str))) {
      setDetails({
        ...details,
        [field]: str.trim() === '' ? undefined : Number(str),
      });
    }
  };
  useEffect(() => {
    setDisableSave(false);
    details &&
      Object.keys(details).map((detail: any) => {
        // console.log(detail, details[detail]);
        if (
          detail !== 'name' &&
          detail !== 'manufacturer' &&
          detail !== 'model' &&
          detail !== 'power' &&
          detail !== 'attachment_type' &&
          detail !== 'implement_type' &&
          detail !== 'serial_number' &&
          detail !== 'floating_hitch' &&
          detail !== 'height' &&
          detail !== 'width' &&
          detail !== 'length' &&
          detail !== 'weight'
        ) {
          if (
            details[detail] !== '' &&
            details[detail] != null &&
            details[detail] != undefined
          ) {
            // console.log('pass');
          } else {
            setDisableSave(true);
            console.log('in disable case');
          }
        }
      });
  }, [details]);

  return (
    <Modal
      title=""
      visible={showModal}
      className="commonPopup impSetpopup autonomySettingPopup"
      onCancel={() => handleDetails()}
      onOk={() => {
        !disableSave && handleDetails(!isDisabled && details);
      }}
      okText={
        !isDisabled ? t(translate.buttons.save) : t(translate.buttons.close)
      }
      okButtonProps={{
        className: `okBtn autonomySaveBtn ${
          disableSave && !isDisabled ? 'disableSave' : ''
        } `,
      }}
      cancelButtonProps={{
        className: 'autocanelBtn',
      }}
    >
      <div style={{ marginBottom: 6 }}></div>
      <div className="autonomymodal">
        <div className="autoHeads mb20">
          <div>
            <h6 className="profHead mt32 width284">Autonomy Controls</h6>
            <h6 className="profHead width284">In Row</h6>
          </div>
          <div className="padr30">
            <h6 className="profHead mt32 width284">Autonomy Controls</h6>
            <h6 className="profHead width284">Out of Row</h6>
          </div>
        </div>
        <div className="autoHeads ovfAuto">
          <div className="autonomywidth">
            <>
              <label className="form-label">
                Tractor Speed <span style={{ color: 'red' }}> *</span>
              </label>
              <div className="width284 posrel ipw fieldRange">
                <>
                  <span className="posabs">MPH</span>
                </>
                <Input
                  type="number"
                  disabled={isDisabled}
                  placeholder="Tractor Speed"
                  onKeyDown={(e: any) => onKeyDown(e)}
                  value={details?.imp_ctrl_in_row_tractor_speed}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      imp_ctrl_in_row_tractor_speed: e.target.value,
                    })
                  }
                  onKeyPress={(e: any) =>
                    onlyImplmentsNumberAndDecimalsAllow(e)
                  }
                />
              </div>
            </>
            <>
              <label className="form-label">
                3pt Hitch Mode <span style={{ color: 'red' }}> *</span>
              </label>
              <div className="">
                <Select
                  disabled={isDisabled}
                  style={{ width: '284px' }}
                  placeholder="3pt Hitch Mode"
                  value={details?.imp_ctrl_in_row_3pt_hitch_mode}
                  onSelect={(imp_ctrl_in_row_3pt_hitch_mode) =>
                    setDetails({ ...details, imp_ctrl_in_row_3pt_hitch_mode })
                  }
                >
                  {hitchMode.map((ele: any) => {
                    return <option value={ele?.name}>{ele?.name}</option>;
                  })}
                </Select>
              </div>
            </>
            <>
              <label className="form-label">
                3pt Hitch Height <span style={{ color: 'red' }}> *</span>
              </label>
              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_in_row_3pt_hitch_height',
                  '3pt Hitch Height',
                  [0.0, 100.0],
                )}
              </div>
            </>
            <>
              <label className="form-label">
                PTO <span style={{ color: 'red' }}> *</span>
              </label>

              <div className="">
                <Select
                  disabled={isDisabled}
                  style={{ width: '284px' }}
                  placeholder="PTO"
                  onSelect={(imp_ctrl_in_row_pto) =>
                    setDetails({
                      ...details,
                      imp_ctrl_in_row_pto:
                        imp_ctrl_in_row_pto == 'true' ? true : false,
                    })
                  }
                  value={details.imp_ctrl_in_row_pto ? 'true' : 'false'}
                >
                  {/* <option value="" key="empty">
                    Select
                  </option> */}
                  <option value="true" key="true">
                    On
                  </option>
                  <option value="false" key="false">
                    Off
                  </option>
                </Select>
              </div>
            </>
            <>
              <label className="form-label">
                PTO RPM <span style={{ color: 'red' }}> *</span>
              </label>
              <div className="width284  posrel ipw">
                <>
                  <span className="posabs">RPM</span>
                </>
                {CSlider('imp_ctrl_in_row_pto_rpm', 'PTO RPM', [0.0, 540.0])}
              </div>
            </>
            <>
              <label className="form-label">
                TOP Link <span style={{ color: 'red' }}> *</span>
              </label>
              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_in_row_toplink_pos',
                  'Top Link',
                  [0.0, 100.0],
                )}
              </div>
            </>
            <>
              <label className="form-label">
                Aux Hydraulics1 <span style={{ color: 'red' }}> *</span>
              </label>
              <div className="width284  posrel ipw">
                {CSlider(
                  'imp_ctrl_in_row_aux_hydraulics_1',
                  'Aux Hydraulics1',
                  [0.0, 8.0],
                )}
              </div>
            </>
            <>
              <label className="form-label">
                Aux Hydraulics2 <span style={{ color: 'red' }}> *</span>
              </label>
              <div className="width284  posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_in_row_aux_hydraulics_2',
                  'Aux Hydraulics2',
                  [0.0, 100.0],
                )}
              </div>
            </>
            <>
              <label className="form-label">
                Aux Hydraulics6 <span style={{ color: 'red' }}> *</span>
              </label>
              <div className="width284  posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_in_row_aux_hydraulics_6',
                  'Aux Hydraulics6',
                  [0.0, 100.0],
                )}
              </div>
            </>

            <div className="mt24 generictoggle">
              <span>
                Generic Ctrl 1 <span style={{ color: 'red' }}> *</span>
              </span>
              <Switch
                disabled={isDisabled}
                checked={details?.imp_ctrl_in_row_generic_ctrl_1}
                onChange={(imp_ctrl_in_row_generic_ctrl_1) =>
                  setDetails({
                    ...details,
                    imp_ctrl_in_row_generic_ctrl_1:
                      imp_ctrl_in_row_generic_ctrl_1 === false ? false : true,
                  })
                }
              />
            </div>

            <div className="mt24 generictoggle">
              <span>
                Generic Ctrl 2 <span style={{ color: 'red' }}> *</span>
              </span>
              <Switch
                disabled={isDisabled}
                checked={details?.imp_ctrl_in_row_generic_ctrl_2}
                onChange={(imp_ctrl_in_row_generic_ctrl_2) =>
                  setDetails({
                    ...details,
                    imp_ctrl_in_row_generic_ctrl_2:
                      imp_ctrl_in_row_generic_ctrl_2 === false ? false : true,
                  })
                }
              />
            </div>
            <div className="mt24 generictoggle">
              <span>
                Generic Ctrl 3 <span style={{ color: 'red' }}> *</span>
              </span>
              <Switch
                disabled={isDisabled}
                checked={details?.imp_ctrl_in_row_generic_ctrl_3}
                onChange={(imp_ctrl_in_row_generic_ctrl_3) =>
                  setDetails({
                    ...details,
                    imp_ctrl_in_row_generic_ctrl_3:
                      imp_ctrl_in_row_generic_ctrl_3 === false ? false : true,
                  })
                }
              />
            </div>
            <div className="mt24 generictoggle">
              <span>
                Generic Ctrl 4 <span style={{ color: 'red' }}> *</span>
              </span>
              <Switch
                disabled={isDisabled}
                checked={details?.imp_ctrl_in_row_generic_ctrl_4}
                onChange={(imp_ctrl_in_row_generic_ctrl_4) =>
                  setDetails({
                    ...details,
                    imp_ctrl_in_row_generic_ctrl_4:
                      imp_ctrl_in_row_generic_ctrl_4 === false ? false : true,
                  })
                }
              />
            </div>

            <>
              <label className="form-label mtb_0">
                Generic Ctrl5 <span style={{ color: 'red' }}> *</span>
              </label>

              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_in_row_generic_ctrl_5',
                  'Generic Ctrl5',
                  [0.0, 100.0],
                )}
              </div>
            </>

            <>
              <label className="form-label mtb_0">
                Generic Ctrl6 <span style={{ color: 'red' }}> *</span>
              </label>

              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_in_row_generic_ctrl_6',
                  'Generic Ctrl6',
                  [0.0, 100.0],
                )}
              </div>
            </>
          </div>
          <div className="autonomywidth">
            <>
              <label className="form-label">
                Tractor Speed <span style={{ color: 'red' }}> *</span>
              </label>
              <div className="width284 posrel ipw fieldRange">
                <>
                  <span className="posabs">MPH</span>
                </>
                <Input
                  type="number"
                  disabled={isDisabled}
                  placeholder="Tractor Speed"
                  onKeyDown={(e: any) => onKeyDown(e)}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      imp_ctrl_out_of_row_tractor_speed: e.target.value,
                    })
                  }
                  value={details?.imp_ctrl_out_of_row_tractor_speed}
                  onKeyPress={(e: any) =>
                    onlyImplmentsNumberAndDecimalsAllow(e)
                  }
                />
              </div>
            </>
            <>
              <label className="form-label">
                3pt Hitch Mode <span style={{ color: 'red' }}> *</span>
              </label>
              <div className="">
                <Select
                  disabled={isDisabled}
                  style={{ width: '284px' }}
                  placeholder="3pt Hitch Mode"
                  value={details?.imp_ctrl_out_of_row_3pt_hitch_mode}
                  onSelect={(imp_ctrl_out_of_row_3pt_hitch_mode) =>
                    setDetails({
                      ...details,
                      imp_ctrl_out_of_row_3pt_hitch_mode,
                    })
                  }
                >
                  {hitchMode.map((ele: any) => {
                    return <option value={ele?.name}>{ele?.name}</option>;
                  })}
                </Select>
              </div>
            </>
            <>
              <label className="form-label">
                3pt Hitch Height <span style={{ color: 'red' }}> *</span>
              </label>
              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_out_of_row_3pt_hitch_height',
                  '3pt Hitch Height',
                  [0.0, 100.0],
                )}
              </div>
            </>
            <>
              <label className="form-label">
                PTO <span style={{ color: 'red' }}> *</span>
              </label>
              <div className="">
                <Select
                  disabled={isDisabled}
                  style={{ width: '284px' }}
                  placeholder="PTO"
                  onSelect={(imp_ctrl_out_of_row_pto) =>
                    setDetails({
                      ...details,
                      imp_ctrl_out_of_row_pto:
                        imp_ctrl_out_of_row_pto == 'true' ? true : false,
                    })
                  }
                  value={details.imp_ctrl_out_of_row_pto ? 'true' : 'false'}
                >
                  <option value="true" key="true">
                    On
                  </option>
                  <option value="false" key="false">
                    Off
                  </option>
                </Select>
              </div>
            </>
            <>
              <label className="form-label">
                PTO RPM <span style={{ color: 'red' }}> *</span>
              </label>
              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">RPM</span>
                </>
                {CSlider(
                  'imp_ctrl_out_of_row_pto_rpm',
                  'PTO RPM',
                  [0.0, 540.0],
                )}
              </div>
            </>
            <>
              <label className="form-label">
                TOP Link <span style={{ color: 'red' }}> *</span>
              </label>

              <div className="width284  posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_out_of_row_toplink_pos',
                  'Top Link',
                  [0.0, 100.0],
                )}
              </div>
            </>
            <>
              <label className="form-label">
                Aux Hydraulics1 <span style={{ color: 'red' }}> *</span>
              </label>
              <div className="width284  posrel ipw">
                {CSlider(
                  'imp_ctrl_out_of_row_aux_hydraulics_1',
                  'Aux Hydraulics1',
                  [0.0, 8.0],
                )}
              </div>
            </>
            <>
              <label className="form-label">
                Aux Hydraulics2 <span style={{ color: 'red' }}> *</span>
              </label>
              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_out_of_row_aux_hydraulics_2',
                  'Aux Hydraulics2',
                  [0.0, 100.0],
                )}
              </div>
            </>
            <>
              <label className="form-label">
                Aux Hydraulics6 <span style={{ color: 'red' }}> *</span>
              </label>

              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_out_of_row_aux_hydraulics_6',
                  'Aux Hydraulics6',
                  [0.0, 100.0],
                )}
              </div>
            </>

            <div className="mt24 generictoggle">
              <span>
                Generic Ctrl 1 <span style={{ color: 'red' }}> *</span>
              </span>
              <Switch
                disabled={isDisabled}
                checked={details?.imp_ctrl_out_of_row_generic_ctrl_1}
                onChange={(imp_ctrl_out_of_row_generic_ctrl_1) =>
                  setDetails({
                    ...details,
                    imp_ctrl_out_of_row_generic_ctrl_1:
                      imp_ctrl_out_of_row_generic_ctrl_1 === false
                        ? false
                        : true,
                  })
                }
              />
            </div>

            <div className="mt24 generictoggle">
              <span>
                Generic Ctrl 2 <span style={{ color: 'red' }}> *</span>
              </span>
              <Switch
                disabled={isDisabled}
                checked={details?.imp_ctrl_out_of_row_generic_ctrl_2}
                onChange={(imp_ctrl_out_of_row_generic_ctrl_2) =>
                  setDetails({
                    ...details,
                    imp_ctrl_out_of_row_generic_ctrl_2:
                      imp_ctrl_out_of_row_generic_ctrl_2 === false
                        ? false
                        : true,
                  })
                }
              />
            </div>

            <div className="mt24 generictoggle">
              <span>
                Generic Ctrl 3 <span style={{ color: 'red' }}> *</span>
              </span>
              <Switch
                disabled={isDisabled}
                checked={details?.imp_ctrl_out_of_row_generic_ctrl_3}
                onChange={(imp_ctrl_out_of_row_generic_ctrl_3) =>
                  setDetails({
                    ...details,
                    imp_ctrl_out_of_row_generic_ctrl_3:
                      imp_ctrl_out_of_row_generic_ctrl_3 === false
                        ? false
                        : true,
                  })
                }
              />
            </div>

            <div className="mt24 generictoggle">
              <span>
                Generic Ctrl 4 <span style={{ color: 'red' }}> *</span>
              </span>
              <Switch
                disabled={isDisabled}
                checked={details?.imp_ctrl_out_of_row_generic_ctrl_4}
                onChange={(imp_ctrl_out_of_row_generic_ctrl_4) =>
                  setDetails({
                    ...details,
                    imp_ctrl_out_of_row_generic_ctrl_4:
                      imp_ctrl_out_of_row_generic_ctrl_4 === false
                        ? false
                        : true,
                  })
                }
              />
            </div>

            <>
              <label className="form-label mtb_0">
                Generic Ctrl5 <span style={{ color: 'red' }}> *</span>
              </label>

              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_out_of_row_generic_ctrl_5',
                  'Generic Ctrl5',
                  [0.0, 100.0],
                )}
              </div>
            </>

            <>
              <label className="form-label mtb_0">
                Generic Ctrl6 <span style={{ color: 'red' }}> *</span>
              </label>

              <div className="width284 posrel ipw">
                <>
                  <span className="posabs">%</span>
                </>
                {CSlider(
                  'imp_ctrl_out_of_row_generic_ctrl_6',
                  'Generic Ctrl6',
                  [0.0, 100.0],
                )}
              </div>
            </>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AutonomySettingsWidget;
