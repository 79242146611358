/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useReducer, useRef, useState } from 'react';
import AnalyticReducer, { initialState } from './AnalyticReducer';

export const AnalyticsContext = createContext({
  JAG: null,
  setJAG: (item: any) => {},
  driveAction: null,
  setDriveAction: (item: string) => {},
  setVideoTime: (item: number) => {},
  videoTime: 0,
  analyticReducer: [],
  jumpAndGoStore: new Map(),
} as { analyticReducer: any; driveAction: any; setDriveAction: React.Dispatch<React.SetStateAction<string | null>>; JAG: any; setJAG: React.Dispatch<React.SetStateAction<any>>; videoTime: any; setVideoTime: React.Dispatch<React.SetStateAction<number>>; jumpAndGoStore: any });

const AnalyticsContextProvider: React.FC = ({ children }) => {
  const [JAG, setJAG] = useState<any>(null);
  const [videoTime, setVideoTime] = useState<number>(0);
  const [driveAction, setDriveAction] = useState<string | null>(null);
  const [state, dispatch] = useReducer(AnalyticReducer, initialState);
  const jumpAndGoStore = useRef(new Map());

  return (
    <AnalyticsContext.Provider
      value={{
        driveAction,
        setDriveAction,
        JAG,
        setJAG,
        videoTime,
        setVideoTime,
        analyticReducer: [state, dispatch],
        jumpAndGoStore,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsContextProvider;
