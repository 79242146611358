import React from 'react';
import { Button, notification } from 'antd';
import logoHorizontalWhite from '../../assets/images/logo_horizontal_white_rgb.png';
import logoWingspanAI from '../../assets/images/WingspanAI_logo_temp.svg';
import reset_password from '../../assets/images/reset_password.svg';
import applicationIds from '../../locale/applicationIds.json';
import { useLocation } from 'react-router-dom';
import { getForgetPassword } from '../../constants/Api';
import { checkAlphaFeature } from '../../constants/Common';

const ForgotPwSuccess: React.FC = () => {
  const { state } = useLocation();
  const emailId: any = state;

  const handleForgotPassword = async () => {
    const payload = {
      email: emailId.state,
    };
    try {
      const res = await getForgetPassword(payload);
      if (res.status === 200) {
        const data = await res.json();
        notification.success({ message: data?.msg });
      } else if (res.status === 404) {
        const data = await res.json();
        notification.error({ message: data?.error?.message });
      } else {
        const data = await res.json();
        notification.error({ message: data?.message });
      }
    } catch (err: any) {
      notification.error({
        message: err.res?.data?.error?.message || err.message,
      });
    }
  };
  return (
    <div className="loginContainer">
      <div className="resetSuccessWrap">
        {checkAlphaFeature('wingspanai') ? (
          <img height="100px" className="mb60" src={logoWingspanAI} />
        ) : (
          <img height="50px" className="mb60" src={logoHorizontalWhite} />
        )}
        <div className="mb60">
          <img height="60px" src={reset_password} />
        </div>
        <div className="forgotPwContent mb70">
          Check your email, or click button to resend
        </div>
        <div className="">
          <Button
            id={applicationIds.ForgotPasswordScreen.forgotPwResendBtn}
            type="primary"
            onClick={handleForgotPassword}
            className={
              checkAlphaFeature('wingspanai')
                ? 'btnTxt wspanAiBtn mtb0'
                : 'btnTxt mtb0'
            }
          >
            RESEND LINK
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPwSuccess;
