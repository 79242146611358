/* eslint-disable no-console */
/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react';
import { AnalyticsContext } from './AnalyticsContext';
import './style.css';
import { RightOutlined } from '@ant-design/icons';
import { MenuContext } from '../../context/MenuContext';
import { ApplicationContext } from '../../context/AppContext';
import {
  getDriveActionSnapShots,
  getDriveActionVideos,
} from '../../constants/Api';
import { Spin, Tabs, Image } from 'antd';
import {
  getDateAndTime,
  getFullName,
  sortCameraViews,
} from '../../constants/Common';
import { useMixpanel } from 'react-mixpanel-browser';
import loading_logo from '../../assets/images/loading_logo.gif';
import MediaWidget from '../common/MediaWidget';

const { TabPane } = Tabs;
export const JumpAndGo = 'JumpAndGo';
export const SNAPSHOT = 'SNAPSHOT';
export const JUMPANDGO = 'JUMPANDGO';

export const TabNames = {
  JumpAndGo: JumpAndGo,
  SNAPSHOT: SNAPSHOT,
  JUMPANDGO: JUMPANDGO,
};
interface Props {
  selectedDriveAction: string;
  // imagesData: any;
  // videosData: any;
  showThumbnails: boolean;
  selectedAllDayDriveAction?: string[];
  setRightSideThumbnailsToggle: (status: boolean) => void;
  spinner: boolean;
}

const ThumbnailMenu: React.FC<Props> = ({
  selectedDriveAction,
  // videosData,
  // imagesData,
  showThumbnails,
  selectedAllDayDriveAction,
  setRightSideThumbnailsToggle,
  spinner,
}: Props) => {
  const { collapsed } = useContext(MenuContext);
  const { JAG } = useContext(AnalyticsContext);
  const mixpanel = useMixpanel();
  const [, setMenuWidth] = useState(260);
  const { userDetails } = useContext(ApplicationContext);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  // const [fullVideosData, setFullVideosData] = useState<any>([]);
  const [spinning, setSpinning] = useState(false);
  const [ids, setids] = useState<string[]>([]);
  const [tabid, setTabid] = useState<string>('Videos');

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      selectedAllDayDriveAction &&
      selectedAllDayDriveAction.length > 0
    ) {
      setids(selectedAllDayDriveAction);
    }
  }, [selectedAllDayDriveAction]);

  useEffect(() => {
    // setTabid('Images');
    if (
      userDetails &&
      userDetails.organization &&
      selectedDriveAction &&
      selectedDriveAction.trim().length > 0
    ) {
      setids([selectedDriveAction]);
    }
  }, [selectedDriveAction]);

  useEffect(() => {
    setMenuWidth(collapsed ? 80 : 260);
  }, [collapsed]);

  // useEffect(() => {
  //   setVideos(videosData);
  //   return () => {
  //     setVideos([]);
  //   };
  // }, [videosData]);

  // useEffect(() => {
  //   setImages(imagesData);
  //   return () => {
  //     setImages([]);
  //   };
  // }, [imagesData]);

  useEffect(() => {
    if (userDetails && userDetails.organization && ids && ids.length > 0) {
      getSnapshots(1);
      getVideos(1);
    }
  }, [ids, userDetails]);

  useEffect(() => {
    setSpinning(spinner);
  }, [spinner]);

  const getSnapshots = async (pageNumber: number) => {
    try {
      setSpinning(true);
      const { organization } = userDetails;
      const { records, _metadata } = await getDriveActionSnapShots(
        organization.api_url,
        organization.id,
        ids,
        pageNumber,
      );
      const { snapshot_data } = records[0];
      const data = sortCameraViews(snapshot_data, 'camera_name');
      setImages(data);
    } catch (error: any) {
      // errors
    } finally {
      setSpinning(false);
    }
  };

  const getVideos = async (pageNumber: number) => {
    try {
      setSpinning(true);
      const { organization } = userDetails;
      let response = await getDriveActionVideos(
        organization.api_url,
        organization.id,
        ids,
        pageNumber,
      );
      const vediosdata: any[] = [];
      setVideos([]);
      response = response && response.length > 0 ? response : [];
      // const videoData = response.map((item: any) => item.video_data);
      // const videos = sortCameraViews(
      //   videoData[0].videos,
      //   'direction',
      //   'videos',
      // );

      response.forEach((element: any) => {
        if (element.video_data && element.video_data.length > 0) {
          element.video_data.forEach((ele: any) => {
            const videos = sortCameraViews(ele.videos, 'direction', 'videos');
            setVideos(videos);
            videos.forEach((ele1: any) => {
              vediosdata.push(ele1);
            });
          });
        }
      });
      // eslint-disable-next-line no-console
      // let dataofindex = 7;
      // const vedio: any[] = [];
      // const pushvedios: any[] = [];
      // vediosdata.forEach((ele: any, index: any) => {
      //   if (index < dataofindex) {
      //     vedio.push(ele);
      //   } else if (index <= dataofindex) {
      //     vedio.push(ele);
      //     const obj = {
      //       vedioData: [...vedio],
      //     };
      //     dataofindex = dataofindex + 10;
      //     pushvedios.push(obj);
      //     vedio.length = 0;
      //     return;
      //   }
      // });
      // setFullVideosData(pushvedios);
    } catch (error: any) {
      // errors
    } finally {
      setSpinning(false);
    }
  };

  useEffect(() => {
    if (!JAG) {
      setVideos([]);
      setImages([]);
    }
  }, [JAG]);

  return (
    <>
      {showThumbnails ? (
        <div
          className={
            showThumbnails ? 'analyticsRightside expand' : 'analyticsRightside'
          }
        >
          <div className="dataLayerWrapper">
            <div
              className="dataLayerRowHead2"
              style={{
                position: 'absolute',
                left: '-20px',
                top: '5px',
              }}
            >
              <span
                onClick={() => {
                  mixpanel.track('Analytics Map', {
                    event: `${
                      showThumbnails ? 'Data Layer Closed' : 'Data Layer Opened'
                    }`,
                  });
                  setRightSideThumbnailsToggle(!showThumbnails);
                }}
                style={{ cursor: 'pointer' }}
              >
                <RightOutlined />
              </span>
              {/* <span className="dataIcon">
                <img src={thumbnail_icon} alt="dataLayer" width="18" />
              </span>
              <span className="dataHead"> Details</span> */}
            </div>

            <div className="datalayerContent2">
              <div className="anaJngTabSec">
                {JAG && (
                  <div style={{ marginTop: '10px' }}>
                    <p>
                      <span className="operationTitle">Tractor : </span>
                      <span>{JAG['tractor']['name']}</span>
                    </p>
                    <p>
                      <span className="operationTitle">Operator: </span>
                      <span>{getFullName(JAG['operator'])}</span>
                    </p>
                    <p>
                      <span className="operationTitle">Start Time: </span>
                      <span>
                        {getDateAndTime(JAG['drive_action_start_date_time'])}
                      </span>
                    </p>
                    <p className="mb20">
                      <span className="operationTitle">Finish Time: </span>
                      <span>
                        {getDateAndTime(JAG['drive_action_finish_date_time'])}
                      </span>
                    </p>
                  </div>
                )}

                <Tabs
                  className="tbTabs anaJngTabs"
                  activeKey={tabid}
                  onChange={(key) => {
                    mixpanel.track('Analytics Map', {
                      event: `${key} Tab`,
                    });
                    setTabid(key);
                  }}
                >
                  <TabPane tab="Videos" key="Videos">
                    <div className="thumbWraper noVideos">
                      <Spin
                        className="loader"
                        spinning={spinning}
                        style={{ width: '100%', margin: '40%' }}
                        indicator={
                          <img
                            style={{ width: '60px', height: '36px' }}
                            src={loading_logo}
                          />
                        }
                      >
                        {/* {videos &&
                        videos.map(
                          (image: { video_url: string; direction: string }) => {
                            return (
                              <div className="thumbItem" key={image?.video_url}>
                                <video
                                  src={image?.video_url}
                                  className=""
                                  controls
                                />
                                <div className="imgNameCont">
                                  <label>
                                    {image?.direction?.toUpperCase()}
                                  </label>
                                </div>
                              </div>
                            );
                          },
                        )} */}
                        <MediaWidget videoData={videos} />
                      </Spin>
                    </div>
                  </TabPane>
                  <TabPane tab="Images" key="Images">
                    <div className="thumbWraper">
                      <Image.PreviewGroup>
                        {images &&
                          images.map(
                            (image: {
                              camera_image_url: string;
                              camera_name: string;
                            }) => {
                              return (
                                <div
                                  className="thumbItem"
                                  key={image?.camera_name}
                                >
                                  <Image src={image?.camera_image_url} alt="" />
                                  <div className="imgNameCont">
                                    <label>
                                      {/* FRONT-RIGHT */}
                                      {image?.camera_name?.toUpperCase()}
                                    </label>
                                  </div>
                                </div>
                              );
                            },
                          )}
                      </Image.PreviewGroup>
                      <Spin
                        className="loader"
                        spinning={spinning}
                        // style={{ width: '100%', margin: '30%' }}
                        indicator={
                          <img
                            style={{ width: '40px', height: '40px' }}
                            src={loading_logo}
                          />
                        }
                      ></Spin>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ThumbnailMenu;
