/* eslint-disable react/jsx-no-undef */
import { DatePicker, Input, notification, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useContext, useEffect, useState } from 'react';
import { editTask } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import moment from 'moment';
import { fromToDateWrapper } from '../../constants/Common';
import { useMixpanel } from 'react-mixpanel-browser';
const { RangePicker } = DatePicker;
interface Props {
  showModel: boolean;
  closeModel: () => void;
  taskData: any;
}

const EditTaskModel: React.FC<Props> = ({
  showModel,
  closeModel,
  taskData,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState<any>(
    new Date(taskData.planned_start_date_time),
  );
  const [toDate, setToDate] = useState<any>(
    new Date(taskData.planned_finish_date_time),
  );
  const [title, setTitle] = useState(taskData.title);
  const [description, setDescriptions] = useState(taskData.description);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const mixpanel = useMixpanel();

  useEffect(() => {
    if (fromDate && toDate && title.trim().length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [fromDate, toDate, title]);

  const edittask = async () => {
    try {
      setLoader(true);

      if (description && description.length > 19999) {
        notification.error({
          message: 'Description maximum length exceeded',
        });
        return;
      }
      const response: { msg: string } = await editTask(
        userDetails.organization.api_url,
        {
          title: title.trim(),
          description: description,
          planned_start_date_time: fromDate.getTime(),
          planned_finish_date_time: toDate.getTime(),
          supervisor_id: taskData.supervisor.id,
        },
        userDetails.organization.id,
        taskData.task_uuid,
      );
      mixpanel.track('UpdatedTask', {
        event: 'Task Updated Sucessfully',
      });
      notification.success({
        message: response.msg,
      });
      closeModel();
    } catch (err: any) {
      mixpanel.track('UpdatedTaskFailed', {
        event: 'Task Updated Failed',
      });
      notification.error({
        message: err.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };
  const onDateRangeChange = (dates: any) => {
    if (dates) {
      const [from, to] = fromToDateWrapper(dates);
      setFromDate(from.toDate());
      setToDate(to.toDate());
    } else {
      setFromDate(null);
      setFromDate(null);
    }
  };

  return (
    <Modal
      title={t(translate.headers.edittask)}
      visible={showModel}
      className="tm-modal betaModal"
      onCancel={closeModel}
      okText={t(translate.buttons.save)}
      onOk={edittask}
      okButtonProps={{
        className: 'tm-button btnSave',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow betaRow">
        <div className="formCol betaCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                Title <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div className="ant-form-item-control-input-content input-align">
                <Input
                  defaultValue={taskData.title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="formCol betaCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                Type <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div className="ant-form-item-control-input-content input-align">
                <Input value={taskData.task_type} disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="formRow betaRow">
        <div className="formCol betaCol"></div>
      </div>
      <div className="formRow betaRow">
        <div className="formColW">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>Description</label>
            </div>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div
                className="ant-form-item-control-input-content input-align"
                style={{
                  width: '100%',
                  overflowY: 'auto',
                }}
              >
                <div
                  style={{
                    minHeight: '200px',
                  }}
                >
                  <ReactQuill
                    style={{ height: 150 }}
                    value={description}
                    onChange={(e) => setDescriptions(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="formRow betaRow">
        <div className="formCol dPick betaCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                Date <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div className="ant-form-item-control-input-content input-align input-flex">
                <RangePicker
                  format="YYYY-MM-DD"
                  onChange={onDateRangeChange}
                  className="dropdownStyle dW250_date"
                  defaultValue={[
                    moment(fromDate, 'YYYY-MM-DD HH:mm'),
                    moment(toDate, 'YYYY-MM-DD HH:mm'),
                  ]}
                  style={{ width: 200, marginRight: '14px' }}
                  showTime={{ format: 'HH:mm' }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="formCol betaCol"></div>
      </div>

      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EditTaskModel;
