/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useRef, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Select } from 'antd';
import moment from 'moment';
import { ApplicationContext } from '../../context/AppContext';
import constants from '../../constants/constant';

const {
  TractorModalData,
  LocalisationData,
  DriveData,
  BAtteryData,
  HydraulicsData,
  ThermalsData,
  PowerData,
} = constants;

am4core.useTheme(am4themes_animated);

interface Props {
  data: any;
}

const HeartBeat1: React.FC<Props> = ({ data }: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [indexvalue, setIndexvalue] = useState<any>(2);
  const [indexModal, setIndexModal] = useState<any>([7, 8, 12]);
  const [indexData, setIndexData] = useState<any>(LocalisationData);
  const [chartData, setChartData] = useState<any>([]);
  const chartDataRef = useRef<any>([]);
  const chartRef = useRef<any>();

  const handleChange = (e: any) => {
    setIndexModal(e);
  };

  useEffect(() => {
    switch (indexvalue) {
      case 1:
        setIndexData(ThermalsData);
        break;
      case 2:
        setIndexData(LocalisationData);
        break;
      case 3:
        setIndexData(DriveData);
        break;
      case 4:
        setIndexData(BAtteryData);
        break;
      case 5:
        setIndexData(HydraulicsData);
        break;
      case 6:
        setIndexData(PowerData);
        break;
      default:
        break;
    }
    chartDataRef.current = [];
  }, [indexvalue]);

  useEffect(() => {
    if (chartRef.current) chartRef.current.dispose();

    if (indexModal.length) {
      const chart: any = am4core.create('chartdiv', am4charts.XYChart);
      // Increase contrast by taking evey second color
      chart.colors.step = 2;

      // Create chart instance

      // Add data
      chartDataRef.current = chartDataRef.current.length
        ? chartDataRef.current
        : generateChartData();
      chart.data = chartDataRef.current;

      // Create axes
      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 50;

      // Create series
      const createAxisAndSeries = (
        field: string,
        name: string,
        opposite: any,
      ) => {
        const valueAxis: any = chart.yAxes.push(new am4charts.ValueAxis());
        if (chart.yAxes.indexOf(valueAxis) !== 0)
          valueAxis.syncWithAxis = chart.yAxes.getIndex(0);

        const series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = field;
        series.dataFields.dateX = 'date';
        series.strokeWidth = 2;
        series.yAxis = valueAxis;
        series.name = name;
        series.tooltipText = '{name}: [bold]{valueY}[/]';
        series.tensionX = 0.8;
        series.showOnInit = true;

        // const interfaceColors = new am4core.InterfaceColorSet();
        // const bullet1:any = series.bullets.push(new am4charts.CircleBullet());
        // bullet1.circle.stroke = interfaceColors.getFor('background');
        // bullet1.circle.strokeWidth = 2;

        // chart.scrollbarX = new am4charts.XYChartScrollbar();
        // chart.scrollbarX?.series.push(series);

        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 2;
        valueAxis.renderer.line.stroke = series.stroke;
        valueAxis.renderer.labels.template.fill = series.stroke;
        valueAxis.renderer.opposite = opposite;
      };

      indexData
        .filter((it: any) => indexModal.includes(it.data_index))
        .forEach((item: any, i: number) => {
          createAxisAndSeries(item.data_name, item.data_name, Boolean(i));
        });
      // Add legend
      if (chart.logo) {
        chart.logo.disabled = true;
      }
      chart.legend = new am4charts.Legend();
      // Add cursor
      chart.cursor = new am4charts.XYCursor();
      chartRef.current = chart;
    }
    return () => chartRef.current && chartRef.current.dispose();
  }, [indexModal]);

  const generateChartData = () => {
    const tindexData = indexData.reduce((a: any, item: any) => {
      a[item.data_name] = item.data_index;
      return a;
    }, {});

    const tchartData = chartData.map((cdata: any) => {
      const { log_time, tractor_model, system_model, localization } = cdata;
      if (indexvalue !== 2) {
        const tractor_model_arr =
          indexvalue === 6 ? system_model.split(',') : tractor_model.split(',');
        const tdata = Object.keys(tindexData);
        const data = tdata.reduce((a: any, item) => {
          a[item] = tractor_model_arr[tindexData[item]];
          return a;
        }, {});
        return {
          date: moment(log_time).toDate().getTime(),
          ...data,
        };
      }

      const { good_localization, heading_accuracy, xy_accuracy } = localization;
      const obj: any = {
        date: moment(log_time).toDate().getTime(),
        'Good Localization': good_localization,
        'Heading Accuracy': Number(heading_accuracy),
        'XY Accuracy': Number(xy_accuracy),
      };
      return obj;
    });

    return tchartData;
  };
  useEffect(() => {
    if (data)
      setChartData([...chartData, { ...data, log_time: new Date().getTime() }]);
  }, [data]);

  useEffect(() => {
    if (chartData.length && chartRef.current) {
      chartRef.current.data = generateChartData();
    }
  }, [chartData]);
  return (
    <>
      <div>
        <div
          style={{ display: 'flex', position: 'absolute', top: 0, right: 0 }}
        >
          <Select
            style={{ margin: '0px 10px' }}
            placeholder="Select Index"
            value={indexvalue}
            onSelect={(e) => {
              setIndexvalue(e);
              setIndexModal([]);
            }}
          >
            {TractorModalData.map((ele: any) => (
              <option value={ele.id} key={ele.id}>
                {ele?.name}
              </option>
            ))}
          </Select>
          <Select
            style={{ width: '250px', marginRight: '0px', overflow: 'hidden' }}
            mode="multiple"
            placeholder="Select Modal"
            value={indexModal}
            onChange={handleChange}
            disabled={!chartData.length}
          >
            {indexData.length > 0 &&
              indexData.map((item: any) => (
                <Select.Option key={item.data_index} value={item.data_index}>
                  {item.data_name}
                </Select.Option>
              ))}
          </Select>
        </div>
        <br />
        <div className="appline">
          <div id="chartdiv" style={{ width: '100%', height: '700px' }} />
        </div>
      </div>
    </>
  );
};

export default HeartBeat1;
