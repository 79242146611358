import React, { useContext, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import './library.css';
import RecordedVideo from './../recordedVideo/RecordedVideo';
import DayView from './../recordedVideo/DayView';
import VideoSnaps from './../recordedVideo/VideoSnaps';
import { useLocation } from 'react-router-dom';

const { TabPane } = Tabs;
export const Pages = {
  RecordedVideo: 'RecordedVideo',
  DayView: 'DayView',
  VideoSnaps: 'VideoSnaps',
};
import Snapshot from '../snapshot/Sanpshot';
import { useMixpanel } from 'react-mixpanel-browser';
import { privilagesConstants } from '../../constants/Privilages';
import { ApplicationContext } from '../../context/AppContext';

interface Props {
  transactionId: string;
}

const Library: React.FC<Props> = ({ transactionId }) => {
  const location = useLocation();
  const state: any = location.state;
  const { privilegeChecker } = useContext(ApplicationContext);
  const [currentPage, setCurrentPage] = useState(Pages.RecordedVideo);
  const [stateData, setStateData] = useState();
  const [tabId, setTabId] = useState<any>('Snapshot');
  const mixpanel = useMixpanel();

  useEffect(() => {
    if (state && (state === 'Snapshot' || state === 'Recorded Videos')) {
      setTabId(state);
    }
  }, [state]);

  return (
    <div className="libraryContainer lbCont tblContainer rowGap30">
      <Tabs
        activeKey={tabId}
        onChange={(key) => {
          setTabId(key);
          mixpanel.track(key, {
            event: `${key} Tab`,
          });
        }}
      >
        <TabPane tab="Snapshot" key="Snapshot">
          <Snapshot transactionId={transactionId} />
        </TabPane>
        {privilegeChecker(privilagesConstants.Video_Library_Access) && (
          <TabPane tab="Recorded Videos" key="Recorded Videos">
            {Pages &&
              Pages.RecordedVideo &&
              currentPage === Pages.RecordedVideo && (
                <RecordedVideo
                  setCurrentPage={(page) => setCurrentPage(page)}
                  setStateData={(state) => setStateData(state)}
                />
              )}
            {Pages && Pages.DayView && currentPage === Pages.DayView && (
              <DayView
                setCurrentPage={(page) => setCurrentPage(page)}
                stateData={stateData}
                setStateData={(state) => setStateData(state)}
              />
            )}
            {Pages && Pages.VideoSnaps && currentPage === Pages.VideoSnaps && (
              <VideoSnaps
                setCurrentPage={(page) => setCurrentPage(page)}
                stateData={stateData}
                setStateData={(state) => setStateData(state)}
              />
            )}
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default Library;
