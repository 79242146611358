import { Button, Modal } from 'antd';
import React from 'react';
import logo from '../../assets/images/logo192.png';
import './style.css';

interface Props {
  showModal?: boolean;
  closeModel: (success: boolean) => void;
}

export const AutoDriveLogoutConfirm: React.FC<Props> = ({
  showModal,
  closeModel,
}: Props) => {
  return (
    <>
      <Modal
        className="profileUpdatePopup teammodal"
        visible={showModal}
        onCancel={() => closeModel(false)}
        width={590}
        closable={false}
        maskClosable={false}
        footer={[
          <>
            <Button
              key="back"
              className="btnteamCancel Button"
              onClick={() => closeModel(false)}
            >
              Cancel
            </Button>
            <Button
              className="btnteamSave Button"
              onClick={() => closeModel(true)}
            >
              Ok
            </Button>
          </>,
        ]}
      >
        <div className="createteamContainer">
          <div className="createtitle mb25">
            <div className="pull-left">
              <h1 className="title headline1">Logout</h1>
            </div>
            <div className="img-div pull-right">
              <img src={logo} />
            </div>
          </div>
          <p className="teamdescription mb38 body1">
            You have an autodrive running, logging out will cause you to end all
            autodrive operations - do you want to continue ?
          </p>
        </div>
      </Modal>
    </>
  );
};

export default AutoDriveLogoutConfirm;
