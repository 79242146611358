/* eslint-disable no-console */
import { Button, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import fleet_implement_new from '../../assets/images/hitch_implement_gray.svg';
import hitch_implement_S from '../../assets/images/hitch_implement_S.svg';
import maintenance_icon from '../../assets/images/maintenance_btn.svg';

import autodrive_icon from '../../assets/images/autodrive_btn.svg';
import { lockUnLockReason } from '../../constants/Api';
import {
  checkAlphaFeature,
  checkIsTractorConnectedNoATOMCond,
  checkLockUserAndLoginUser,
  checkTractorConnectedState,
  checkTractorDrivingState,
  getFloatText,
  getGearText,
  getHeight,
  getImplementName,
  getPTOState,
  getRpm,
  noneAttached,
  tractorConstantsNew,
} from '../../constants/Common';
import { LockedOutTractor, TractorHeartBeat } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import AppLoader from '../common/AppLoader';
import { SET_SHOW_TERM_AND_COND } from './actions';
import { AutoDriveInterruptMsg } from './AutoDriveInterruptMsg';
import { AutoDriveSoftInterruptMsg } from './AutoDriveSoftInterruptMsg';
import LockUnLockReason from './LockUnLockReason';
import { RemoteDriveAppCtx } from './RemoteDriveContext';

interface Props {
  stopAndDisconnectHandler: () => void;
  setShowEndAndDisconnect: (showDisConnect: boolean) => void;
  showEndAndDisconnect: boolean;
  showAutoDriveConnect: boolean;
}
const MaintenanceAndStartAutoDrive: React.FC<Props> = ({
  stopAndDisconnectHandler,
  setShowEndAndDisconnect,
  showEndAndDisconnect,
  showAutoDriveConnect,
}) => {
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const { RDReducer, setSelectedTractorsIds, connectTractorHandler } =
    useContext(RemoteDriveAppCtx);
  const [state] = RDReducer;
  const {
    selectedTractor,
    autoDriveInterruptMsg,
    showMaintenanceLock,
    autoDriveSoftInterruptMsg,
  } = state;
  const [appState] = APPReducer;
  const { tractorsList } = appState;
  const [showTractorLockUnLock, setShowTractorLockUnLock] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [tractorLockUnLock, setTractorLockUnLock] = useState<boolean>(false);

  useEffect(() => {
    if (
      selectedTractor &&
      selectedTractor.id &&
      tractorsList &&
      tractorsList.length > 0
    ) {
      const [tractor] = tractorsList.filter(
        (tractor: any) => tractor.id === selectedTractor.id,
      );
      const lockout_details = tractor?.heartbeat?.lockout_details;
      const status =
        lockout_details && lockout_details.is_tractor_lockout ? true : false;
      setTractorLockUnLock(status);
    }
  }, [tractorsList, selectedTractor]);
  const updateReason = async () => {
    try {
      setLoader(true);
      const payload: LockedOutTractor = {
        lockout_reason: '',
        is_tractor_lockout: false,
        drive_action_uuid:
          selectedTractor?.heartbeat?.drive_action_details
            ?.current_drive_action,
      };
      const resp = await lockUnLockReason(
        userDetails.organization.api_url,
        userDetails.organization.id,
        selectedTractor.id,
        payload,
      );
      if (resp && resp.msg) {
        notification.success({
          message: resp.msg,
        });
      }
      setLoader(false);
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error?.message || error?.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      {!showEndAndDisconnect && tractorLockUnLock && (
        <div className="mLockContainer">
          <img src={maintenance_icon} alt="" height="54" className="mb20" />
          <div className="mlockHelpTxt3">
            For your safety Maintenance Lock
            <br />
            will stay on until disconnecting
          </div>
          {checkLockUserAndLoginUser(
            selectedTractor?.heartbeat,
            userDetails?.id,
          ) && (
            <Button
              className="mlockBtn mlockBtnDisConnect"
              onClick={updateReason}
            >
              DISCONNECT
            </Button>
          )}
        </div>
      )}

      <div className="ppMsgBox">
        {checkTractorConnectedState(
          selectedTractor?.heartbeat,
          userDetails.id,
        ) && (
          <div className="maint_autodrive_btns maint_autodrive_btns_full">
            <Button
              className="comm_btn"
              onClick={() => {
                // dispatch({
                //   type: SET_SHOW_TERM_AND_COND,
                //   payload: true,
                // });
                setSelectedTractorsIds([selectedTractor.id]);
                connectTractorHandler(selectedTractor.id);
              }}
            >
              <img src={autodrive_icon} alt="" className="mb2" />
              &nbsp;&nbsp;
              <span className="maint_text Button2 mt2">START AUTODRIVE</span>
            </Button>
          </div>
        )}
        {!autoDriveInterruptMsg &&
          !autoDriveSoftInterruptMsg &&
          !showEndAndDisconnect && (
            <>
              {checkIsTractorConnectedNoATOMCond(selectedTractor) && (
                <div className="tOperationDtlSec">
                  <div className="tOpTypeSec">
                    <span className="a_icon a_pto_icon iconAutodrive"></span>
                    <span className="a_value headline2">
                      {getPTOState(selectedTractor?.heartbeat)
                        ? getRpm(
                            selectedTractor?.heartbeat as TractorHeartBeat,
                            selectedTractor?.tractor_model,
                          )
                        : '-'}
                    </span>
                    <span className="a_label headline4">
                      {getPTOState(selectedTractor.heartbeat)
                        ? ' PTO RPM'
                        : ' PTO OFF'}
                    </span>
                  </div>
                  <div className="tOpTypeSec">
                    <span className="a_icon a_gear_icon iconAutodrive"></span>
                    <span className="a_value headline2">
                      {selectedTractor &&
                        getGearText(selectedTractor?.heartbeat)}
                    </span>
                    <span className="a_label headline4">Gear</span>
                  </div>
                  <div className="tOpTypeSec">
                    <span className="a_icon a_height_icon iconAutodrive"></span>
                    <span className="a_value headline2">
                      {selectedTractor && getHeight(selectedTractor.heartbeat)}%
                    </span>
                    <span className="a_label headline4">HEIGHT</span>
                  </div>
                  <div className="tOpTypeSec">
                    <span className="a_icon a_potion_icon iconAutodrive"></span>
                    <span className="a_value2 headline2 ">
                      {getFloatText(selectedTractor?.heartbeat)}
                    </span>
                  </div>
                </div>
              )}
              {!autoDriveInterruptMsg &&
                !autoDriveSoftInterruptMsg &&
                !tractorLockUnLock &&
                selectedTractor.isAvailable ===
                  tractorConstantsNew.available.key && (
                  <>
                    {((checkAlphaFeature('MosFeatures') &&
                      (showAutoDriveConnect || showMaintenanceLock)) ||
                      !checkAlphaFeature('MosFeatures')) &&
                      userDetails?.is_active && (
                        <>
                          {/*  show both auto drive maintenance lock */}
                          {(!checkAlphaFeature('MosFeatures') ||
                            (showAutoDriveConnect && showMaintenanceLock)) && (
                            <div className="maint_autodrive_btns">
                              <Button
                                className="comm_btn"
                                onClick={() => {
                                  tractorLockUnLock
                                    ? updateReason()
                                    : setShowTractorLockUnLock(true);
                                }}
                              >
                                <img
                                  src={maintenance_icon}
                                  alt=""
                                  className="mb9"
                                />
                                <span className="maint_text Button2 ">
                                  MAINTENANCE <br />
                                  Lock
                                </span>
                              </Button>
                              <Button
                                className="comm_btn"
                                onClick={() => {
                                  // dispatch({
                                  //   type: SET_SHOW_TERM_AND_COND,
                                  //   payload: true,
                                  // });
                                  setSelectedTractorsIds([selectedTractor.id]);
                                  connectTractorHandler(selectedTractor.id);
                                }}
                              >
                                <img
                                  src={autodrive_icon}
                                  alt=""
                                  className="mb9"
                                />
                                <span className="maint_text Button2">
                                  START <br />
                                  AUTODRIVE
                                </span>
                              </Button>
                            </div>
                          )}
                          {/* only show  maintenance lock */}
                          {!showAutoDriveConnect && showMaintenanceLock && (
                            <div className="maint_autodrive_btns maint_autodrive_btns_full">
                              <Button
                                className="comm_btn"
                                onClick={() => {
                                  tractorLockUnLock
                                    ? updateReason()
                                    : setShowTractorLockUnLock(true);
                                }}
                              >
                                <img
                                  src={maintenance_icon}
                                  alt=""
                                  className="mb2"
                                />
                                &nbsp;&nbsp;
                                <span className="maint_text Button2 mt2">
                                  MAINTENANCE Lock
                                </span>
                              </Button>
                            </div>
                          )}
                          {/* only show  autodrive */}
                          {showAutoDriveConnect && !showMaintenanceLock && (
                            <div className="maint_autodrive_btns maint_autodrive_btns_full">
                              <Button
                                className="comm_btn"
                                onClick={() => {
                                  // dispatch({
                                  //   type: SET_SHOW_TERM_AND_COND,
                                  //   payload: true,
                                  // });
                                  setSelectedTractorsIds([selectedTractor.id]);
                                  connectTractorHandler(selectedTractor.id);
                                }}
                              >
                                <img
                                  src={autodrive_icon}
                                  alt=""
                                  className="mb2"
                                />
                                &nbsp;&nbsp;
                                <span className="maint_text Button2 mt2">
                                  START AUTODRIVE
                                </span>
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    <div
                      className={`tImplement ${
                        getImplementName(selectedTractor) === noneAttached
                          ? 'disableRoutine'
                          : ''
                      }`}
                    >
                      <span>
                        {getImplementName(selectedTractor) === noneAttached ? (
                          <img src={fleet_implement_new} alt="" />
                        ) : (
                          <img
                            src={hitch_implement_S}
                            alt="implement"
                            height="19"
                            className="mr10"
                          />
                        )}
                      </span>
                      <span className="Button2">
                        &nbsp; {` ${getImplementName(selectedTractor)}`}
                      </span>
                    </div>
                  </>
                )}
            </>
          )}
        {/* {autoDriveInterruptMsg && <AutoDriveInterruptMsg />}
        {autoDriveSoftInterruptMsg && <AutoDriveSoftInterruptMsg />} */}

        {autoDriveInterruptMsg &&
          checkTractorDrivingState(
            selectedTractor.heartbeat,
            userDetails.id,
          ) && <AutoDriveInterruptMsg />}
        {autoDriveSoftInterruptMsg &&
          checkTractorDrivingState(
            selectedTractor.heartbeat,
            userDetails.id,
          ) && <AutoDriveSoftInterruptMsg />}
        {/* End operation code starts here  */}
        {showEndAndDisconnect && (
          <>
            <div className="endingOperation tractorDetailsAlertBox">
              <div className="pathPRow">
                <div className="pText">
                  <h2 className="headline2 fSbd">Ending Operation</h2>
                  <div className="minH130">
                    <p className="body3">
                      Are you sure you wish to end this operation? This will
                      disconnect you from this tractor.
                    </p>
                    <p className="body3">
                      To pick up where you left off you can choose to Continue
                      Later.
                    </p>
                  </div>
                </div>
              </div>
              <div className="pathPRowBtn">
                <button
                  type="button"
                  className="pOpBtn Button2"
                  onClick={() => setShowEndAndDisconnect(false)}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="pOpBtn endOpSelBtn2 Button2"
                  onClick={stopAndDisconnectHandler}
                >
                  YES, END OPERATION
                </button>
              </div>
            </div>
          </>
        )}
        {/* End operation code ends here  */}

        <AppLoader loader={loader} />
      </div>
      {showTractorLockUnLock && (
        <LockUnLockReason
          showModal={showTractorLockUnLock}
          closeModel={() => {
            setShowTractorLockUnLock(false);
          }}
          tractorId={selectedTractor.id}
          status={tractorLockUnLock}
          driveActionUuid={
            selectedTractor?.heartbeat?.drive_action_details
              ?.current_drive_action
          }
        />
      )}
    </>
  );
};

export default MaintenanceAndStartAutoDrive;
