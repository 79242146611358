import React, { useRef } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import RoutesConstants from '../../../routes/RoutesConstant';
import fungiMap from '../../../assets/images/fungiMap.png';
import { Pie } from '@ant-design/charts';
import { Button } from 'antd';
import { colors, colors1 } from '../../../constants/Common';
import { VideoCameraOutlined } from '@ant-design/icons';
import FungicideSprayingMap from './FungicideSprayingMap';

const FungicideSpraying: React.FC = () => {
  const { push } = useHistory();
  const isNew = useRef<boolean>(false);

  const goBackDatabase = () => {
    push(RoutesConstants.Analytic);
  };

  const data = [
    {
      type: 'Herbicide Spraying',
      value: 50,
    },
    {
      type: 'Fungicide Spraying',
      value: 150,
    },
  ];
  const config: any = {
    legend: false,
    appendPadding: 10,
    data: data,
    angleField: 'value',
    colorField: 'type',
    color: isNew.current ? colors1 : colors,
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      style: {
        fontSize: 0,
        textAlign: 'center',
      },
    },
    options: {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    },
    interactions: [{ type: 'element-active' }],
  };

  const navigateToVideoFeed = () => {
    push(RoutesConstants.VideoFeed);
  };

  return (
    <>
      <div className="personnelSec fileUploadBack">
        <ArrowLeftOutlined onClick={() => goBackDatabase()} />
      </div>
      <div className="sprayContainer mb20">
        <div className="pieChartStyle" style={{ width: '25%' }}>
          <div className="sprayTxt">
            <div>Fungicide Spraying</div>
            <div className="dateStyle">Tue, 6/21 - Thu, 6/23</div>
          </div>

          <div className="dateStyle mt24">Total Progress</div>
          <div className="fungiFlex">
            <div className="progressBarColors progressRadius">
              <div className="progressWidth1" style={{ width: '20%' }} />
              <div className="progressWidth2" style={{ width: '30%' }} />
              <div className="progressWidth3" style={{ width: '50%' }} />
            </div>
            <span className="hrMinTxt">60%</span>
          </div>

          <div className="operationalHours mb5">
            <div className="totalHours">Total Operational Hours</div>
            <div className="hrMinTxt">5 HR 2 MIN</div>
          </div>

          <div style={{ display: 'flex' }}>
            <div className="pieTxtStyle">
              <Pie
                {...config}
                style={{ height: '215px', width: 170, marginLeft: '-16px' }}
              />
            </div>
            <div className="graphTbl" style={{ top: 72 }}>
              {!isNew.current && (
                <table>
                  <tr>
                    <td className="w115 fntSize">
                      <span className="manualdriveBox herbiColor"></span>
                      2022 MK-V PS3
                    </td>
                    <td className="w115 fntSize">
                      <span className="operatorassitsBox fungiColor"></span>
                      2022 MK-V PS4
                    </td>
                  </tr>
                </table>
              )}
            </div>
          </div>

          <div className="operationalHours mb10">
            <div className="totalHours">Total Downtime</div>
            <div className="hrMinTxt">23 Min</div>
          </div>

          <div className="operationalHours mb10">
            <div className="totalHours">Operational Efficiency</div>
            <div className="hrMinTxt">1.34 AC / HR</div>
          </div>

          <div className="operationalHours mb10">
            <div className="totalHours">Avg Speed</div>
            <div className="hrMinTxt">2.6 MPH</div>
          </div>

          <div className="operationalHours mb10">
            <div className="totalHours">Fuel Saving</div>
            <div className="hrMinTxt">$88.29</div>
          </div>

          <div className="operationalHours mb10">
            <div className="totalHours">
              CO<sub>2</sub> Saving
            </div>
            <div className="hrMinTxt">52.23 KG</div>
          </div>
          <div className="totalHours mb12">Operators</div>
          <div className="ml20">
            <div className="operationalHours mb10">
              <div className="totalHours opa">Curtis Mangadlore</div>
              <div className="hrMinTxt opa">1.8 AC / HR</div>
            </div>
            <div className="operationalHours mb10">
              <div className="totalHours">Michel Zapato</div>
              <div className="hrMinTxt">1.7 AC / HR</div>
            </div>
          </div>

          <div className="videoFeedBtn">
            <Button onClick={navigateToVideoFeed}>
              {' '}
              <VideoCameraOutlined /> See Video Feed
            </Button>
          </div>
        </div>

        <div style={{ width: '75%' }}>
          {/* <img width="100%" src={fungiMap} alt="" /> */}
          <FungicideSprayingMap />
        </div>
      </div>
    </>
  );
};

export default FungicideSpraying;
