/* eslint-disable no-console */
import { LeftOutlined } from '@ant-design/icons';
import { Collapse, Tabs } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import acrImg from '../../assets/images/acrIcon.svg';
import mphImg from '../../assets/images/mphIcon.svg';
import opaTractor from '../../assets/images/opaTractor.svg';
import socImg from '../../assets/images/socIcon.svg';
import { ApplicationContext } from '../../context/AppContext';

import { Pie } from '@ant-design/charts';
import moment from 'moment';
import { getJAGCummulativeFleetReport } from '../../constants/Api';
import {
  calPercentage,
  calTotal,
  colors,
  colors1,
  formatFleetAnalyticsData,
  getDateTimesByString,
  secondsToHms,
} from '../../constants/Common';
import { AllDayContent } from '../../constants/types';
const { Panel } = Collapse;
const { TabPane } = Tabs;
export interface DataItem {
  type: string;
  value: number;
}

interface Props {
  selectedTractorDetails: any;
  allDayOperation: AllDayContent;
  selectedDateRange: any;
  tractorId: number;
}

const AllDayJumpAndGoMoreDetail: React.FC<Props> = ({
  selectedTractorDetails,
  allDayOperation,
  selectedDateRange,
  tractorId,
}: Props) => {
  const [data, setData] = useState<DataItem[]>([]);
  const [fleetReportData, setFleetReportData] = useState<any>();
  const [fleetReportResponse, setFleetReportResponse] = useState<any>();
  const { userDetails } = useContext(ApplicationContext);
  const isNew = useRef<boolean>(false);

  const getJAGFleetReportData = async () => {
    try {
      const startTime = moment(selectedDateRange[0])
        .startOf('day')
        .toDate()
        .getTime();
      const endTime = moment(selectedDateRange[1])
        .endOf('day')
        .toDate()
        .getTime();

      const response = await getJAGCummulativeFleetReport(
        userDetails.organization.api_url,
        userDetails.organization_id,
        startTime,
        endTime,
        tractorId,
        // selectedJumpnGo.drive_action_uuid,
      );
      const data = response.map((item: any) => formatFleetAnalyticsData(item));
      setFleetReportResponse(response);
      setFleetReportData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      setFleetReportResponse([]);
      setFleetReportData([]);
      getJAGFleetReportData();
    }
  }, [userDetails, tractorId, selectedDateRange]);

  const newDataDisplay = (data: any) => {
    let total = 0;
    const { planning_manager_analytics } = data;
    const {
      operator_assist_hours = 0,
      manual_hours = 0,
      auto_drive_hours = 0,
    } = planning_manager_analytics;

    const result = calTotal({
      operator_assist_hours,
      manual_hours,
      auto_drive_hours,
    });
    if (result.state) {
      total = result.total;
    }
    if (!total) {
      isNew.current = false;
      oldDataDisplay(data);
      return;
    }
    const dataItem: DataItem[] = [
      {
        type: 'Manual',
        value: calPercentage(manual_hours, total),
      },
      {
        type: 'Operator Assist',
        value: calPercentage(operator_assist_hours, total),
      },
      {
        type: 'Auto Drive',
        value: calPercentage(auto_drive_hours, total),
      },
    ];
    setData(dataItem);
  };

  const oldDataDisplay = (data: any) => {
    let total = 0;
    const { idle_hours = 0, manual_hours = 0, autonomy_hours = 0 } = data;
    const result = calTotal({ idle_hours, manual_hours, autonomy_hours });
    if (result.state) {
      total = result.total;
    }
    const dataItem: DataItem[] = [
      {
        type: 'Idle',
        value: calPercentage(Number(idle_hours), total),
      },
      {
        type: 'Manual',
        value: calPercentage(manual_hours, total),
      },
      {
        type: 'Autonomous',
        value: calPercentage(autonomy_hours, total),
      },
    ];
    setData(dataItem);
  };

  useEffect(() => {
    const dataItem: DataItem[] = [];
    setData(dataItem);
    if (allDayOperation?.planning_manager_analytics) {
      isNew.current = true;
      newDataDisplay(allDayOperation);
    } else if (allDayOperation) {
      oldDataDisplay(allDayOperation);
    }
    // if (allDayOperation && allDayOperation?.planning_manager_analytics) {
    //   total =
    //     Number(allDayOperation.planning_manager_analytics?.auto_drive_hours) +
    //     Number(allDayOperation.planning_manager_analytics?.manual_hours) +
    //     Number(
    //       allDayOperation.planning_manager_analytics?.operator_assist_hours,
    //     );
    // }
    // isNew.current = Boolean(total);
    // if (total) {
    //   if (
    //     allDayOperation &&
    //     Number(allDayOperation.planning_manager_analytics?.auto_drive_hours) >=
    //       0
    //   ) {
    //     const value = Math.trunc(
    //       (Number(
    //         allDayOperation.planning_manager_analytics?.auto_drive_hours,
    //       ) /
    //         Number(total)) *
    //         100,
    //     );
    //     dataItem.push({
    //       type: 'Auto Drive',
    //       value,
    //     });
    //   } else {
    //     dataItem.push({
    //       type: 'Auto Drive',
    //       value: 0,
    //     });
    //   }
    //   if (
    //     allDayOperation &&
    //     allDayOperation.planning_manager_analytics &&
    //     Number(allDayOperation.planning_manager_analytics?.manual_hours) >= 0
    //   ) {
    //     const value = Math.trunc(
    //       (Number(allDayOperation.planning_manager_analytics?.manual_hours) /
    //         Number(total)) *
    //         100,
    //     );
    //     dataItem.push({
    //       type: 'Manual',
    //       value,
    //     });
    //   } else {
    //     dataItem.push({
    //       type: 'Manual',
    //       value: 0,
    //     });
    //   }
    //   if (
    //     allDayOperation &&
    //     Number(
    //       allDayOperation.planning_manager_analytics?.operator_assist_hours,
    //     ) >= 0
    //   ) {
    //     const value = Math.trunc(
    //       (Number(
    //         allDayOperation.planning_manager_analytics?.operator_assist_hours,
    //       ) /
    //         Number(total)) *
    //         100,
    //     );
    //     dataItem.push({
    //       type: 'Operator Assist',
    //       value,
    //     });
    //   } else {
    //     dataItem.push({
    //       type: 'Operator Assist',
    //       value: 0,
    //     });
    //   }
    // } else {
    //   if (allDayOperation && allDayOperation?.analytics) {
    //     total =
    //       Number(allDayOperation.analytics?.idle_hours) +
    //       Number(allDayOperation.analytics?.autonomy_hours) +
    //       Number(allDayOperation.analytics?.manual_hours);
    //   }
    //   if (
    //     allDayOperation &&
    //     Number(allDayOperation.analytics?.idle_hours) >= 0
    //   ) {
    //     const value = Math.trunc(
    //       (Number(allDayOperation.analytics?.idle_hours) / Number(total)) * 100,
    //     );
    //     dataItem.push({
    //       type: 'Idle',
    //       value,
    //     });
    //   } else {
    //     dataItem.push({
    //       type: 'Idle',
    //       value: 0,
    //     });
    //   }
    //   if (
    //     allDayOperation &&
    //     allDayOperation.analytics &&
    //     Number(allDayOperation.analytics?.manual_hours) >= 0
    //   ) {
    //     const value = Math.trunc(
    //       (Number(allDayOperation.analytics?.manual_hours) / Number(total)) *
    //         100,
    //     );
    //     dataItem.push({
    //       type: 'Manual',
    //       value,
    //     });
    //   } else {
    //     dataItem.push({
    //       type: 'Manual',
    //       value: 0,
    //     });
    //   }
    //   if (
    //     allDayOperation &&
    //     Number(allDayOperation.analytics?.autonomy_hours) >= 0
    //   ) {
    //     const value = Math.trunc(
    //       (Number(allDayOperation.analytics?.autonomy_hours) / Number(total)) *
    //         100,
    //     );
    //     dataItem.push({
    //       type: 'Autonomous',
    //       value,
    //     });
    //   } else {
    //     dataItem.push({
    //       type: 'Autonomous',
    //       value: 0,
    //     });
    //   }
    // }
    // setData(dataItem);
  }, [allDayOperation]);

  const config: any = {
    className: 'pieChartClass',
    // appendPadding: 10,
    data: data,
    angleField: 'value',
    colorField: 'type',
    color: isNew.current ? colors1 : colors,
    radius: 0.2,
    autofit: true,
    // legend: false,
    tooltips: {
      // display: false,
      type: 'inner',
      // offset: '-30%',
      content: function content(_ref: any) {
        return ''.concat(_ref.value, '%');
        // return '';
      },
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    legend: false,
    options: {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    },
    interactions: [{ type: 'element-active' }],
  };

  const tab1Content = (
    <div className="operationsBlk parentAccordion anaOpup">
      <div className="cardHeader">
        <h4>
          <img src={opaTractor} alt="" /> Cumulative Report
        </h4>
      </div>
      <div className="cardBody">
        <table>
          <tr>
            <td className="subTitle2">Operators:</td>
            <td>
              {allDayOperation &&
              allDayOperation.operators &&
              allDayOperation.operators.length > 0
                ? allDayOperation.operators.map((operator, index) => (
                    <>
                      <span>
                        {operator.first_name + ' ' + operator.last_name}
                        {index < allDayOperation.operators.length - 1 && ', '}
                      </span>
                    </>
                  ))
                : '-'}
            </td>
          </tr>
          <tr>
            <td className="subTitle2">Start Time:</td>
            <td>
              {allDayOperation?.start_time &&
              allDayOperation?.start_time != null
                ? getDateTimesByString(allDayOperation?.start_time)
                : '-'}
            </td>
          </tr>
          <tr>
            <td className="subTitle2">End Time:</td>
            <td>
              {allDayOperation?.finish_time &&
              allDayOperation?.finish_time != null
                ? getDateTimesByString(allDayOperation?.finish_time)
                : '-'}
            </td>
          </tr>
          <tr>
            <td className="subTitle2">Tractors:</td>
            {selectedTractorDetails ? (
              <td>{selectedTractorDetails.name}</td>
            ) : (
              <td>All</td>
            )}
          </tr>
          <tr>
            <td className="subTitle2">Operation Type:</td>
            <td>{allDayOperation?.analytics.operation_type || '-'}</td>
          </tr>
        </table>
        <Collapse
          defaultActiveKey={['1']}
          accordion
          expandIconPosition="right"
          className="childAccordion cardAccordion"
          expandIcon={({ isActive }) => (
            <LeftOutlined rotate={isActive ? 270 : 270} />
          )}
        >
          <Panel
            header="Operation Analytics"
            key="1"
            className="selectTractorOpt operationAnalyticsBlk"
          >
            <div className="operationAnalyticsInrBlk">
              <div className="anaGraphSec">
                <div className="analeftSec">
                  <h5>Time Distribution</h5>
                  <div className="pieGraphSec">
                    <div className="graphWrapper">
                      <Pie {...config} style={{ height: '400px' }} />
                    </div>
                    <div className="graphTbl" style={{ marginLeft: 0 }}>
                      {isNew.current ? (
                        <table>
                          <tr>
                            <td className="w115">
                              <span className="manualdriveBox"></span>Manual
                            </td>
                            <td className="w55">
                              {allDayOperation?.planning_manager_analytics
                                ?.manual_hours
                                ? `${secondsToHms(
                                    allDayOperation?.planning_manager_analytics
                                      ?.manual_hours,
                                    true,
                                  )}`
                                : '-'}
                            </td>
                          </tr>

                          <tr>
                            <td className="w115">
                              <span className="operatorassitsBox"></span>
                              Operator Assist
                            </td>
                            <td className="w55">
                              {allDayOperation?.planning_manager_analytics
                                ?.operator_assist_hours
                                ? `${secondsToHms(
                                    allDayOperation?.planning_manager_analytics
                                      ?.operator_assist_hours,
                                    true,
                                  )}`
                                : '-'}
                            </td>
                          </tr>
                          <tr>
                            <td className="w115">
                              <span className="autodriveBox"></span>Auto Drive
                            </td>
                            <td className="w55">
                              {allDayOperation?.planning_manager_analytics
                                ?.auto_drive_hours
                                ? `${secondsToHms(
                                    allDayOperation?.planning_manager_analytics
                                      ?.auto_drive_hours,
                                    true,
                                  )}`
                                : '-'}
                            </td>
                          </tr>
                        </table>
                      ) : (
                        <table>
                          <tr>
                            <td className="w115">
                              <span className="idleBox"></span>Idle
                            </td>
                            <td className="w55">
                              {allDayOperation?.planning_manager_analytics
                                ?.idle_hours
                                ? `${secondsToHms(
                                    allDayOperation?.planning_manager_analytics
                                      ?.idle_hours,
                                    true,
                                  )}`
                                : '-'}
                            </td>
                          </tr>
                          <tr>
                            <td className="w115">
                              <span className="manualBoxForPie"></span>Manual
                            </td>
                            <td className="w55">
                              {allDayOperation?.planning_manager_analytics
                                ?.manual_hours
                                ? `${secondsToHms(
                                    allDayOperation?.planning_manager_analytics
                                      ?.manual_hours,
                                    true,
                                  )}`
                                : '-'}
                            </td>
                          </tr>
                          <tr>
                            <td className="w115">
                              <span className="autonomousBox"></span>Autonomous
                            </td>
                            <td className="w55">
                              {allDayOperation?.analytics?.autonomy_hours
                                ? `${secondsToHms(
                                    allDayOperation?.analytics?.autonomy_hours,
                                    true,
                                  )}`
                                : '-'}
                            </td>
                          </tr>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
                <div className="anarightSec">
                  <div>
                    <h6>Avg Speed</h6>
                    <img src={mphImg} alt="" />
                    <h6>
                      {allDayOperation?.analytics?.avg_speed
                        ? `${Number(
                            Number(allDayOperation?.analytics?.avg_speed) *
                              2.236936,
                          ).toFixed(1)} Mph`
                        : '-'}
                    </h6>
                  </div>
                  <div>
                    <h6>Energy Used</h6>
                    <img src={socImg} alt="" />
                    <h6>
                      {allDayOperation?.analytics?.soc_used
                        ? `${allDayOperation?.analytics?.soc_used}%`
                        : '-'}{' '}
                      SOC
                      <br />
                      <span>
                        {allDayOperation?.analytics?.energy
                          ? `${Number(allDayOperation?.analytics?.energy)
                              .toFixed(2)
                              .toLocaleString()} kwh`
                          : '-'}
                      </span>
                    </h6>
                  </div>
                  <div>
                    <h6>Coverage</h6>
                    <img src={acrImg} alt="" />
                    <h6>
                      {allDayOperation?.analytics?.distance
                        ? `${Number(allDayOperation?.analytics?.distance)
                            .toFixed(2)
                            .toLocaleString()} Miles`
                        : '-'}
                    </h6>
                  </div>
                  <div>
                    <h6 className="acreshead">Covered Acres</h6>
                    <h6>
                      {allDayOperation?.analytics?.area_covered_in_acre
                        ? `${Number(
                            allDayOperation?.analytics?.area_covered_in_acre,
                          )
                            .toFixed(2)
                            .toLocaleString()} Acres`
                        : '-'}
                    </h6>
                  </div>
                  <div>
                    <h6 className="acreshead">Covered Vine Rows</h6>
                    <h6>
                      {allDayOperation?.analytics?.num_of_vine_rows_covered
                        ? `${Number(
                            allDayOperation?.analytics
                              ?.num_of_vine_rows_covered,
                          ).toFixed(0)}`
                        : '-'}
                    </h6>
                  </div>
                  <div>
                    <h6 className="acreshead">Acres per hour</h6>
                    <h6>
                      {allDayOperation?.analytics?.acres_per_hours
                        ? `${Number(
                            allDayOperation?.analytics?.acres_per_hours,
                          ).toFixed(2)}`
                        : '-'}
                    </h6>
                  </div>
                </div>
              </div>

              <div className="anaOefcSec">
                <div className="analeftSec">
                  <h5>Operational Efficiency</h5>
                  <span className="w5per">Electricity Rate</span>
                  <span className="w5per">
                    {allDayOperation?.analytics?.electricity_rate
                      ? `$${Number(
                          allDayOperation?.analytics?.electricity_rate,
                        ).toFixed(2)}`
                      : '-'}{' '}
                    / Kwh
                  </span>
                  <span className="w5per">Diesel Rate</span>
                  <span className="w5per">
                    {allDayOperation?.analytics?.electricity_rate
                      ? `$${Number(
                          allDayOperation?.analytics?.diesel_cost,
                        ).toFixed(2)}`
                      : '-'}{' '}
                    / Gallon
                  </span>
                </div>
                <div className="anaOefcRightSec">
                  <h5>Savings</h5>
                  <span className="w5per">Operational Savings</span>
                  <span className="w5per">
                    {allDayOperation?.analytics?.operational_savings
                      ? `$${Number(
                          allDayOperation?.analytics?.operational_savings,
                        ).toFixed(2)}`
                      : '-'}
                  </span>
                  <div>
                    <span className="w5per">
                      co<sub>2</sub> Reduction
                    </span>
                    <span className="w5per">
                      {allDayOperation?.analytics?.aggregate_emission_reduction
                        ? `${Number(
                            allDayOperation?.analytics
                              ?.aggregate_emission_reduction,
                          ).toFixed(2)} kg`
                        : '-'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    </div>
  );

  let tab2Content = <></>;

  if (fleetReportResponse) {
    const tab1body = fleetReportResponse.map((item: any, key: number) => {
      const {
        idle,
        manual,
        auto_drive: { follow, go_to },
        operator_assist: { row_drive, safe_drive, shadow_drive },
        un_available: { powered_off, system_error },
        tractor,
      } = item;
      return (
        <tr key={key}>
          <td colSpan={3}>{tractor?.name}</td>
          <td>{idle?.hours ? secondsToHms(idle?.hours, true) : 0}</td>
          <td>{idle?.distance}</td>
          <td>
            {powered_off?.hours ? secondsToHms(powered_off?.hours, true) : 0}
          </td>
          <td>-</td>
          <td>
            {system_error?.hours ? secondsToHms(system_error?.hours, true) : 0}
          </td>
          <td>-</td>
          <td>{manual?.hours ? secondsToHms(manual?.hours, true) : 0}</td>
          <td>{manual?.distance}</td>
          <td>
            {safe_drive?.hours ? secondsToHms(safe_drive?.hours, true) : 0}
          </td>
          <td>{safe_drive?.distance}</td>
          <td>
            {shadow_drive.hours ? secondsToHms(shadow_drive?.hours, true) : 0}
          </td>
          <td>{shadow_drive?.distance}</td>
          <td>{row_drive?.hours ? secondsToHms(row_drive?.hours, true) : 0}</td>
          <td>{row_drive?.distance}</td>
          <td>{go_to?.hours ? secondsToHms(go_to?.hours, true) : 0}</td>
          <td>{go_to?.distance}</td>
          <td>{follow?.hours ? secondsToHms(follow?.hours, true) : 0}</td>
          <td>{follow?.distance}</td>
        </tr>
      );
    });
    tab2Content = (
      <table className="table-center">
        <thead>
          <tr>
            <th rowSpan={3} colSpan={3}>
              Tractor
            </th>
            <th rowSpan={2} colSpan={2}>
              Idle
            </th>
            <th colSpan={4}>Un Available</th>
            <th rowSpan={2} colSpan={2}>
              Manual
            </th>
            <th colSpan={6}>Operator Assist</th>
            <th colSpan={4}>Auto Drive</th>
          </tr>
          <tr>
            <th colSpan={2}>POWER OFF</th>
            <th colSpan={2}>SYSTEM ERROR</th>
            <th colSpan={2}>SAFE DRIVE</th>
            <th colSpan={2}>SHADOW DRIVE</th>
            <th colSpan={2}>ROW DRIVE</th>
            <th colSpan={2}>GOTO</th>
            <th colSpan={2}>FOLLOW</th>
          </tr>
          <tr>
            <th>Hours</th>
            <th>Miles</th>
            <th>Hours</th>
            <th>Miles</th>
            <th>Hours</th>
            <th>Miles</th>
            <th>Hours</th>
            <th>Miles</th>
            <th>Hours</th>
            <th>Miles</th>
            <th>Hours</th>
            <th>Miles</th>
            <th>Hours</th>
            <th>Miles</th>
            <th>Hours</th>
            <th>Miles</th>
            <th>Hours</th>
            <th>Miles</th>
          </tr>
        </thead>
        <tbody>{tab1body}</tbody>
      </table>
    );
  }

  const onChange = (key: string) => {
    // console.log(key);
  };

  return (
    <Tabs
      defaultActiveKey="1"
      onChange={onChange}
      className="fleetAnalyticsTab"
    >
      <TabPane tab="Report" key="1">
        {tab1Content}
      </TabPane>
      <TabPane tab="Data" key="2">
        {tab2Content}
      </TabPane>
    </Tabs>
  );
};

export default AllDayJumpAndGoMoreDetail;
