/* eslint-disable no-console */
import React, { useContext } from 'react';
import pauseTask from '../../assets/images/pause.svg';
import {
  checkDisconnectCheck,
  tractorConstantsNew,
} from '../../constants/Common';
import { ApplicationContext } from '../../context/AppContext';
import { RemoteDriveAppCtx } from './RemoteDriveContext';

import endBtn from '../../assets/images/end_disconnect.svg';
import adrDicBtn from '../../assets/images/ex_circle.svg';
import playBtn from '../../assets/images/playBtn.svg';
import { TractorHeartBeat } from '../../constants/types';

interface Props {
  showDeleteConfirm: () => void;
  disConnectConnection: (heartbeat: TractorHeartBeat) => void;
}
const RemoteDriveActionsNewDesign: React.FC<Props> = ({
  showDeleteConfirm,
  disConnectConnection,
}) => {
  const { userDetails } = useContext(ApplicationContext);
  const { RDReducer, handlePauseResumeStopLiveCom } =
    useContext(RemoteDriveAppCtx);
  const [state] = RDReducer;
  const { selectedTractor, autoDriveMonitorErrorCodes } = state;

  return (
    <>
      {userDetails?.id ===
        selectedTractor?.heartbeat?.drive_action_details?.current_operator_id &&
      selectedTractor?.heartbeat?.planning_manager?.planner_name?.length > 0 &&
      selectedTractor?.heartbeat?.planning_manager?.planner_status?.length >
        0 ? (
        selectedTractor?.heartbeat?.planning_manager?.planner_name ===
          tractorConstantsNew.copycat.key ||
        selectedTractor?.heartbeat?.planning_manager?.planner_name ===
          tractorConstantsNew.remote_av.key ? (
          <>
            <div className="adConnectBtnSec">
              {selectedTractor?.heartbeat?.planning_manager?.planner_status ===
              'paused' ? (
                <button
                  className="btnBlock"
                  onClick={() =>
                    handlePauseResumeStopLiveCom(
                      'resume',
                      selectedTractor?.heartbeat,
                      'Click',
                    )
                  }
                >
                  <img src={playBtn} alt="Playbutton" />
                  <span>Resume</span>
                </button>
              ) : (
                <button
                  className="btnBlock"
                  onClick={() =>
                    handlePauseResumeStopLiveCom(
                      'pause',
                      selectedTractor?.heartbeat,
                      'Click',
                    )
                  }
                >
                  <img src={pauseTask} alt="Playbutton" />
                  <span>Pause</span>
                </button>
              )}

              <button className="btnBlock" onClick={showDeleteConfirm}>
                <img src={endBtn} alt="Resumebutton" />
                <span>End and Disconnect</span>
              </button>
            </div>
          </>
        ) : (
          <>
            {checkDisconnectCheck(
              userDetails,
              selectedTractor,
              autoDriveMonitorErrorCodes,
            ) && (
              <div className="adConnectBtnSec discBtnOne ">
                {/* <button
                  className="btnBlock"
                  onClick={() => {
                    disConnectConnection(selectedTractor?.heartbeat);
                  }}
                >
                  <img src={disconnectBtn} alt="Disconnect" />
                  <span>Disconnect</span>
                </button> */}
                <button
                  className="adrDisconnectBtn Button"
                  onClick={() => {
                    disConnectConnection(selectedTractor?.heartbeat);
                  }}
                >
                  <span>
                    <img
                      src={adrDicBtn}
                      alt=""
                      className="adrBtnIcon"
                      height="10"
                    />
                  </span>
                  <span>Disconnect</span>
                </button>
              </div>
            )}
          </>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default RemoteDriveActionsNewDesign;
