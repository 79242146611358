export const privilagesConstants = {
  Tractor_Access: 80001,
  Repair_Manager_Access: 80004,
  Access_Map_Maker: 20012,
  Remote_Tractor_and_Autodrive_access_allow_disallow_Based_on_Training: 80006,
  Customer_Portal_Access: 80002,
  Notifications: 70001,
  Edit_farm_profile_information: 20005,
  Edit_personnel_information: 20006,
  Create_Teams: 20015,
  Edit_Teams: 20010,
  Delete_a_Team: 20016,
  Add_Employees: 20017,
  Assign_Permissions: 20018,
  Edit_Fleet_Details: 20011,
  Edit_base_station_information: 20007,
  Add_Implements: 20009,
  Edit_Implement_Details: 20019,
  Create_a_fleet_ticket: 50001,
  Edit_a_fleet_ticket: 50006,
  Resolve_a_ticket_not_assigned_to_the_individual: 50007,
  Forward_a_ticket_to_Monarch: 50019,
  Create_a_ticket_from_diagnostic_code: 50020,
  Begin_an_update: 50017,
  Stop_an_Update: 50021,
  Rollback_an_Update: 50022,
  Start_Maintenance_Lockout: 50023,
  End_Maintenance_Lockout: 50024,
  Download_Reports: 60002,
  Video_Library_Access: 60003,
  Map_Analytics_Access: 60001,
  Daily_Fleet_usage_report_e_mailed_to_an_individual: 60004,
  View_Operator_Code: 80010,
};
