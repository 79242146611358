import React, { useContext, useEffect, useState } from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import { Card, Tabs } from 'antd';
import Jumpandgo from '../jumpandgo/Jumpandgo';
import Guardrails from '../guardrails/Guardrails';
import Shadow from '../shadow/Shadow';
import SafeDrive from '../safeDrive/SafeDrive';
import Copycat from '../Copycat/Copycat';
import RowFollow from '../RowFollow/RowFollow';
import FleetIndicatorHistory from '../fleetIndicatorHistory/FleetIndicatorHistory';
import './styles.css';
import { SET_LAST_TAB_INDEX } from '../../context/actions';
import { ApplicationContext } from '../../context/AppContext';
import Routines from '../routines/Routines';
const { TabPane } = Tabs;

const FleetUsageContainer: React.FC = () => {
  const [tabid, setTabid] = useState<any>('');
  const { APPReducer } = useContext(ApplicationContext);
  const [, dispatch] = APPReducer;

  useEffect(() => {
    const lastTabIndex: string | null = localStorage.getItem('lastTabIndex');
    const tlastTabIndex: any = lastTabIndex && JSON.parse(lastTabIndex);
    if (tlastTabIndex?.catlog === 'FleetUsage') {
      setTabid(tlastTabIndex?.tab);
    } else {
      setTabid('1');
    }
  }, [localStorage.getItem('lastTabIndex')]);

  return (
    <Layout>
      <Content>
        <div className="mainContent">
          <div className="dbWidget attContainer padt0 bNone">
            <Card>
              <Tabs
                activeKey={tabid}
                className="elTabs fleetHealth"
                onChange={(key) => {
                  setTabid(key);
                  dispatch({
                    type: SET_LAST_TAB_INDEX,
                    payload: {
                      catlog: 'FleetUsage',
                      tab: key,
                    },
                  });
                }}
              >
                <TabPane
                  tab="JUMP & GO"
                  key="1"
                  className="attTableHeight mBottom0"
                >
                  <Jumpandgo />
                </TabPane>
                <TabPane
                  tab="Guardrails"
                  key="2"
                  className="attTableHeight mBottom0"
                >
                  <Guardrails />
                </TabPane>
                <TabPane
                  tab="SHADOW"
                  key="3"
                  className="attTableHeight mBottom0"
                >
                  <Shadow />
                </TabPane>
                <TabPane
                  tab="Safe Drive"
                  key="4"
                  className="attTableHeight mBottom0"
                >
                  <SafeDrive />
                </TabPane>
                <TabPane
                  tab="Copy Cat"
                  key="5"
                  className="attTableHeight mBottom0"
                >
                  <Copycat />
                </TabPane>
                <TabPane
                  tab="Row Follow"
                  key="6"
                  className="attTableHeight mBottom0"
                >
                  <RowFollow />
                </TabPane>
                <TabPane
                  tab="Fleet Indicators"
                  key="7"
                  className="attTableHeight mBottom0"
                >
                  <FleetIndicatorHistory />
                </TabPane>
                <TabPane
                  tab="Routines"
                  key="8"
                  className="attTableHeight mBottom0"
                >
                  <Routines />
                </TabPane>
              </Tabs>
            </Card>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default FleetUsageContainer;
