/* eslint-disable no-console */
import React, { createContext, useState } from 'react';
export const FleetHealthCxt = createContext({
  ticketRefreshStatus: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  SetTicketRefreshStatus: (status) => {},
} as { ticketRefreshStatus: boolean; SetTicketRefreshStatus: (status: boolean) => void });

// eslint-disable-next-line react/prop-types
const FleetHealthContext: React.FC = ({ children }) => {
  const [ticketRefreshStatus, SetTicketRefreshStatus] =
    useState<boolean>(false);

  return (
    <FleetHealthCxt.Provider
      value={{ ticketRefreshStatus, SetTicketRefreshStatus }}
    >
      {children}
    </FleetHealthCxt.Provider>
  );
};

export default FleetHealthContext;
