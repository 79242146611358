/* eslint-disable no-console */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ApplicationContext } from '../../context/AppContext';
import { RemoteDriveAppCtx } from './RemoteDriveContext';
import { notification } from 'antd';
import noImplement from '../../assets/images/no_implement.png';
import {
  addRemoteAVCommand,
  createSnapshot,
  getImplementsData,
  getSnapshot,
  setImplement,
} from '../../constants/Api';
import { SET_TRACTOR_IMPLEMENT_STATE } from '../../context/actions';
import CSelect from '../common/CSelect';
import { SELECTED_IMPLEMENT_NAME } from './actions';
import AppLoader from '../common/AppLoader';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import AppLoaderV2 from '../common/AppLoaderV2';
import { ImplementDropDownType } from '../../constants/types';

interface Props {
  setShowImplement: (state: boolean) => void;
}

const INTERVAL = 3000;
const API_COUNT = 30;

const ImplementDetail: React.FC<Props> = ({ setShowImplement }) => {
  const [implementsList, setImplementsList] = useState<any>([]);
  const [implementListForDropdown, setImplementListForDropdown] = useState<
    ImplementDropDownType[]
  >([]);
  const [loader, setLoader] = useState<boolean>(false);
  const { userDetails } = useContext(ApplicationContext);
  const { RDReducer } = useContext(RemoteDriveAppCtx);
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  const { tractorImplementState } = autoDriveState;
  const [state, dispatch] = RDReducer;
  // default implement is none
  const [selectedImplement, setSelectedImplement] = useState('none');
  const [implementDetails, setImplementDetails] = useState<any>();
  const [hitchImage, setHitchImage] = useState('');
  const interval = useRef<any>(null);
  const counter = useRef<number>(0);
  const [transactionId, setTransactionId] = useState<string>('');
  const [imgLoader, setImgLoader] = useState<boolean>(true);

  const { selectedTractor, selectedImplementName, savedImleplentDetails } =
    state;

  useEffect(() => {
    userDetails && userDetails.organization && getImplementsListData();
    // userDetails && userDetails.organization && getRoutes();
  }, [userDetails]);

  const getImplementsListData = async () => {
    try {
      setLoader(true);
      const { records } = await getImplementsData(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
      );
      records && records.length > 0
        ? setImplementsList(records)
        : setImplementsList([]);

      const records1: any[] = records.map((d: any) => {
        return {
          label: d.name,
          value: d.config_implements_uuid,
          index: d.config_implements_uuid,
          id: d.config_implements_uuid,
        };
      });
      setImplementListForDropdown([
        {
          label: 'No Implement',
          value: 'none',
          index: 'none',
          id: 'none',
        },
        ...records1,
      ]);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message || error?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    dispatch({
      type: SELECTED_IMPLEMENT_NAME,
      payload: '',
    });
    if (selectedTractor && selectedTractor.id && implementsList.length > 0) {
      implementsList.forEach((implement: any) => {
        if (
          selectedTractor &&
          selectedTractor?.heartbeat &&
          selectedTractor?.heartbeat?.implement &&
          selectedTractor?.heartbeat?.implement?.current_implement &&
          selectedTractor?.heartbeat?.implement?.current_implement.length > 0 &&
          implement.name ===
            selectedTractor?.heartbeat?.implement?.current_implement
        ) {
          dispatch({
            type: SELECTED_IMPLEMENT_NAME,
            payload: implement.name,
          });
          setImplementDetails(implement);
          setSelectedImplement(implement.config_implements_uuid);
        }
      });
    }
  }, [selectedTractor, implementsList]);

  const approveImplementSettings = async () => {
    setLoader(true);
    try {
      const { organization } = userDetails;
      if (organization && organization.api_url) {
        await setImplement(
          organization.api_url,
          selectedTractor.id,
          selectedImplementName ? selectedImplementName : 'None',
        );
        if (tractorImplementState) {
          tractorImplementState[selectedTractor.id] = true;
          autoDriveDispatch({
            type: SET_TRACTOR_IMPLEMENT_STATE,
            payload: tractorImplementState,
          });
        }
        setShowImplement(false);
        notification.success({
          message: 'Success',
          duration: 1,
        });
      }
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message || err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const addImplementSettings = async () => {
    setLoader(true);
    try {
      const { organization } = userDetails;
      if (
        organization &&
        organization.api_url &&
        selectedTractor?.heartbeat?.drive_action_details?.current_drive_action
      ) {
        const commandData = {
          command: 'implement_ctrl_params',
          implement: selectedImplementName ? selectedImplementName : 'None',
          ...savedImleplentDetails,
        };
        await addRemoteAVCommand(
          organization.api_url,
          [commandData],
          selectedTractor?.heartbeat?.drive_action_details
            ?.current_drive_action as string,
        );
      }
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message || err?.message,
      });
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    const init = async () => {
      try {
        const { organization } = userDetails;
        const response = await createSnapshot(
          organization.api_url,
          organization.farm.id,
          selectedTractor.id,
        );
        if (response?.transaction_id)
          setTransactionId(response?.transaction_id);
      } catch (error: any) {
        notification.error({
          message: error.response?.data?.error?.message || error.message,
        });
      }
    };

    if (
      userDetails &&
      userDetails.organization &&
      selectedTractor &&
      selectedTractor.id
    ) {
      init();
    }
  }, [userDetails, selectedTractor]);

  useEffect(() => {
    const getHitchImage = async () => {
      try {
        setImgLoader(true);
        const { organization } = userDetails;
        const response2: any[] = await getSnapshot(
          organization.api_url,
          transactionId,
          selectedTractor.id,
        );
        const [snap] = response2.filter((resp) => resp.camera_name === 'pto');
        if (snap || counter.current === API_COUNT) {
          clearInterval(interval.current);
          setImgLoader(false);
        }
        snap && snap.camera_image_url && setHitchImage(snap.camera_image_url);
      } catch (error: any) {
        notification.error({
          message: error.response?.data?.error?.message || error.message,
        });
      }
    };
    const initialize = () => {
      interval.current = setInterval(() => {
        if (counter.current === API_COUNT) {
          clearInterval(interval.current);
          return;
        }
        counter.current += 1;
        getHitchImage();
      }, INTERVAL);
    };
    if (transactionId && transactionId?.trim() !== '') initialize();
    return () => clearInterval(interval.current);
  }, [transactionId]);

  return (
    <div className="rautoDriveRightSec">
      <div className="pd0 adrImpDtl profileView empView personeeltab implementsWidget">
        <div
          className="autoDriveCancel mt15 mb40"
          onClick={() => setShowImplement(false)}
        >
          Back
        </div>

        <div className="proRow mb28">
          <div className="implementImage">
            {imgLoader ? (
              <>
                <AppLoaderV2 loader={imgLoader} />
                <img src={noImplement} className="defaulticon impImgW" />
              </>
            ) : (
              <>
                {hitchImage && hitchImage !== '' ? (
                  <img src={hitchImage} className="defaulticon impImgW" />
                ) : (
                  <img src={noImplement} className="defaulticon impImgW" />
                )}
              </>
            )}
          </div>
          <div className="chooseTxt ">
            {/* Choose Another */}
            {userDetails &&
              userDetails?.id ===
                selectedTractor?.heartbeat?.drive_action_details
                  ?.current_operator_id && (
                <CSelect
                  list={implementListForDropdown}
                  isAll={false}
                  selectTrigger={(selected: any) => {
                    const { id } = selected;
                    const [implement] = implementsList.filter(
                      (implement: any) =>
                        id === implement.config_implements_uuid,
                    );
                    dispatch({
                      type: SELECTED_IMPLEMENT_NAME,
                      payload:
                        implement && implement.name ? implement.name : '',
                    });

                    setSelectedImplement(id);
                    setImplementDetails(implement);
                  }}
                  keyValue="value"
                  label="Implement"
                  keyLabel="label"
                  placeholder="Choose Another"
                  defaultId={selectedImplement}
                />
              )}
          </div>
        </div>
        {implementDetails && implementDetails.id && (
          <>
            <div className="flHead mb20">{implementDetails?.name}</div>
            <table className="fleetTbl">
              <tr>
                <td className="flHead">Model</td>
                <td id="modelName">{implementDetails?.config_imm?.model}</td>
              </tr>
              <tr>
                <td className="flHead">Serial Number</td>
                <td id="modelName">{implementDetails?.serial_number}</td>
              </tr>
              <tr>
                <td className="flHead">Attachment</td>
                <td id="modelName">{implementDetails?.attachment_type}</td>
              </tr>
              <tr>
                <td className="flHead">Power</td>
                <td id="modelName">{implementDetails?.power}</td>
              </tr>
              <tr>
                <td className="flHead">Dimensions</td>
                <td>
                  Max Width{' '}
                  <span className="dM">{implementDetails?.width}&rdquo;</span>
                  <br />
                  Length{' '}
                  <span className="dM">
                    {implementDetails?.length}&rdquo;
                  </span>{' '}
                  <br />
                  Height{' '}
                  <span className="dM">
                    {implementDetails?.height}&rdquo;
                  </span>{' '}
                  <br />
                  Weight{' '}
                  <span className="dS">{implementDetails?.weight} lb</span>{' '}
                  <br />
                </td>
              </tr>
              <tr>
                <td className="flHead">Hour Meter</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="flHead">Status</td>
                <td>
                  {implementDetails?.is_active === true ? 'Active' : 'InActive'}
                </td>
              </tr>
            </table>
          </>
        )}
      </div>
      <div className="verifyTxt">
        <button
          onClick={() => {
            addImplementSettings();
            approveImplementSettings();
          }}
        >
          Verify
        </button>
      </div>
      <AppLoader loader={loader} />
    </div>
  );
};

export default ImplementDetail;
