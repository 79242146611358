/* eslint-disable no-console */
import { Input, InputNumber, Modal, notification, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { editVinePointData, saveVinePoints } from '../../constants/Api';
import { VinesPayloadTypes } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean) => void;
  vinePayload: VinesPayloadTypes;
}
export const Vine: React.FC<Props> = ({
  showModal,
  handleCancel,
  vinePayload: vinePayload,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [name, setName] = useState<string>('');
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (vinePayload) {
      if (vinePayload.name) {
        setName(vinePayload.name);
      }
      if (vinePayload.x) {
        setX(vinePayload.x);
      }
      if (vinePayload.y) {
        setY(vinePayload.y);
      }
    }
  }, [vinePayload]);
  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      name &&
      name.trim().length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, name]);
  const saveVineRows = async () => {
    setLoader(true);
    const payload: VinesPayloadTypes = {
      name: name.trim(),
      y,
      x,
      vine_row1_uuid: '',
      vine_row2_uuid: '',
    };
    setName(name.trim());
    try {
      const { organization } = userDetails;
      if (vinePayload.action === 'ADD') {
        const response = await saveVinePoints(
          organization.api_url,
          organization.farm.id,
          payload,
        );
        if (response && response.msg) {
          handleCancel(true);
          notification.success({
            message: response.msg,
          });
        }
      }
      if (vinePayload.action === 'EDIT' && vinePayload.vineId) {
        const resp = await editVinePointData(
          organization.api_url,
          organization.farm.id,
          vinePayload.vineId,
          payload,
        );
        if (resp && resp.msg) {
          handleCancel(true);
          notification.success({
            message: resp.msg,
          });
        }
      }
    } catch (error: any) {
      notification.error({
        message: error?.message,
      });
    } finally {
      setLoader(false);
    }
  };
  return (
    <Modal
      className="commonPopup addPolygon"
      title="Vine"
      closable={false}
      centered
      width={'60vw'}
      visible={showModal}
      onCancel={() => handleCancel()}
      okText="Save"
      onOk={saveVineRows}
      okButtonProps={{
        className: 'btnSave ',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Name <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="formCol"></div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            X <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            className="commInput w100"
            value={x}
            onChange={(value) => setX(value)}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            Y <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            className="commInput w100"
            value={y}
            onChange={(value) => setY(value)}
          />
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default Vine;
