/* eslint-disable no-console */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Card, Row, Breadcrumb, Space, Button } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import './style.css';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { getDateTime } from '../../constants/Common';
import { getVideoURL } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import RoutesConstants from '../../routes/RoutesConstant';
import moment from 'moment';
import { ExpandOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Pages } from '../library/Library';
import { useMixpanel } from 'react-mixpanel-browser';
import CameraGrid2 from '../map/CameraGrid2';

interface Props {
  stateData: any;
  setCurrentPage: (page: string) => void;
  setStateData: (state: any) => void;
}
const VideoSnaps: React.FC<Props> = ({
  stateData,
  setCurrentPage,
  setStateData,
}: Props) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const mixpanel = useMixpanel();
  // const { state } = useLocation();
  const { userDetails } = useContext(ApplicationContext);
  // const { tractor_id, tractorName, video_time }: any = state;
  const [tractorName, setTractorName] = useState('');
  const [tractor_id, setTractorId] = useState<number>(0);
  const [updatedTime, setUpdatedTime] = useState<number>(0);
  const frontRef: any = useRef();
  const rearRef: any = useRef();
  const driverRef: any = useRef();
  const ptoRef: any = useRef();
  const frontLeft: any = useRef();
  const frontRight: any = useRef();
  const rearLeft: any = useRef();
  const rearRight: any = useRef();
  const chassisLeft: any = useRef();
  const chassisRight: any = useRef();
  const curTime: any = useRef();
  const fixTime: any = useRef();
  const [play, setPlay] = useState(true);
  const [state, setstate] = useState();
  const [videos, setVideos] = useState<any>([]);
  const [vedioInfo, setvedioInfo] = useState<any>({
    front: false,
    rear: false,
    pto: false,
    driver: false,
    frontleft: false,
    rearleft: false,
    frontright: false,
    rearrightRef: false,
  });

  useEffect(() => {
    setstate(stateData);
  }, [stateData]);
  useEffect(() => {
    if (state) {
      const { tractor_id, tractorName, video_time }: any = state;
      setTractorId(tractor_id);
      setTractorName(tractorName);
      setUpdatedTime(video_time);
    }
  }, [state]);

  useEffect(() => {
    if (userDetails && userDetails.organization && tractor_id && updatedTime) {
      getVideoData();
    }
  }, [userDetails, updatedTime, tractor_id, updatedTime]);

  const getVideoData = async () => {
    const response = await getVideoURL(
      userDetails.organization.api_url,
      userDetails.organization.farm.id,
      tractor_id,
      updatedTime,
    );
    setVideos([]);
    if (response) {
      response.map((data: any) => {
        switch (data.direction) {
          case 'Front Camera':
            vedioInfo.front = true;
            break;
          case 'Rear Camera':
            vedioInfo.rear = true;
            break;
          case 'PTO Camera':
            vedioInfo.pto = true;
            break;
          case 'driver':
            vedioInfo.driver = true;
            break;
          case 'front_left':
            vedioInfo.frontleft = true;
            break;
          case 'LeftTop Camera':
            vedioInfo.frontleft = true;
            break;
          case 'front_right':
            vedioInfo.frontright = true;
            break;
          case 'RightTop Camera':
            vedioInfo.frontright = true;
            break;
          default:
            break;
        }
      });
      setVideos(response);
    }
  };

  useEffect(() => {
    if (videos && videos.length > 0) {
      mapVideos(videos);
    }
  }, [videos]);

  const mapVideos = (videos: any) => {
    if (videos && videos.length > 0) {
      videos.map((data: any) => {
        switch (data.direction) {
          case 'Front Camera':
            if (frontRef.current) {
              frontRef.current.src = data.video_url;
            }
            break;
          case 'Rear Camera':
            if (rearRef.current) {
              rearRef.current.src = data.video_url;
            }
            break;
          // case 'driver':
          //   driverRef.current.src = data.video_url;
          //   break;
          // case 'Driver Camera':
          //   driverRef.current.src = data.video_url;
          //   break;
          case 'PTO Camera':
            if (ptoRef.current) {
              ptoRef.current.src = data.video_url;
            }
            break;
          case 'front_left':
            if (frontLeft.current) {
              frontLeft.current.src = data.video_url;
            }
            break;
          case 'LeftTop Camera':
            if (frontLeft.current) {
              frontLeft.current.src = data.video_url;
            }
            break;
          case 'front_right':
            if (frontRight.current) {
              frontRight.current.src = data.video_url;
            }
            break;
          case 'RightTop Camera':
            if (frontRight.current) {
              frontRight.current.src = data.video_url;
            }
            break;
          // case 'rear_left':
          //   rearLeft.current.src = data.video_url;
          //   break;
          // case 'LeftSide Camera':
          //   rearLeft.current.src = data.video_url;
          //   break;
          // case 'rear_right':
          //   rearRight.current.src = data.video_url;
          //   break;
          // case 'RightSide Camera':
          //   rearRight.current.src = data.video_url;
          //   break;
          // case 'chassis_left':
          //   chassisLeft.current.src = data.video_url;
          //   break;
          // case 'chassis_right':
          //   chassisRight.current.src = data.video_url;
          //   break;

          default:
            break;
        }
      });
    } else {
      frontRef.current.src = '';
      rearRef.current.src = '';
      driverRef.current.src = '';
      ptoRef.current.src = '';
      frontLeft.current.src = '';
      frontRight.current.src = '';
      rearLeft.current.src = '';
      rearRight.current.src = '';
      chassisLeft.current.src = '';
      chassisRight.current.src = '';
      curTime.current.innerHTML = 0;
      fixTime.current.innerHTML = 0;
    }
  };

  const playVideo = () => {
    if (play) {
      if (frontRef.current && frontRef.current.src) {
        frontRef.current.play();
      }
      if (rearRef.current && rearRef.current.src) {
        rearRef.current.play();
      }
      // if (driverRef.current.src) {
      //   driverRef.current.play();
      // }
      if (ptoRef.current && ptoRef.current.src) {
        ptoRef.current.play();
      }
      if (frontLeft.current && frontLeft.current.src) {
        frontLeft.current.play();
      }
      if (frontRight.current && frontRight.current.src) {
        frontRight.current.play();
      }
      // if (rearLeft.current.src) {
      //   rearLeft.current.play();
      // }
      // if (rearRight.current.src) {
      //   rearRight.current.play();
      // }
      // if (chassisLeft.current.src) {
      //   chassisLeft.current.play();
      // }
      // if (chassisRight.current.src) {
      //   chassisRight.current.play();
      // }
      setPlay(false);
      mixpanel.track('Recorded Video', {
        event: `All Videos Played`,
      });
    } else {
      if (frontRef.current && frontRef.current.src) {
        frontRef.current.pause();
      }
      if (rearRef.current && rearRef.current.src) {
        rearRef.current.pause();
      }
      // if (driverRef.current.src) {
      //   driverRef.current.pause();
      // }
      if (ptoRef.current && ptoRef.current.src) {
        ptoRef.current.pause();
      }
      if (frontLeft.current && frontLeft.current.src) {
        frontLeft.current.pause();
      }
      if (frontRight.current && frontRight.current.src) {
        frontRight.current.pause();
      }
      // if (rearLeft.current.src) {
      //   rearLeft.current.pause();
      // }
      // if (rearRight.current.src) {
      //   rearRight.current.pause();
      // }
      // if (chassisLeft.current.src) {
      //   chassisLeft.current.pause();
      // }
      // if (chassisRight.current.src) {
      //   chassisRight.current.pause();
      // }
      setPlay(true);
      mixpanel.track('Recorded Video', {
        event: `All Videos Paused`,
      });
    }
  };

  // const video: any = document.querySelector('video');
  const progress: any = document.querySelector('.recordVediosprogress');
  const progressBar: any = document.querySelector(
    '.recordVediosprogress__filled',
  );

  const currentTime = (video: any) => {
    const currentMinutes = Math.floor(video.currentTime / 60);
    const currentSeconds = Math.floor(video.currentTime - currentMinutes * 60);
    const durationMinutes = Math.floor(video.duration / 60);
    const durationSeconds = Math.floor(video.duration - durationMinutes * 60);
    curTime.current.innerHTML = `0${currentMinutes}: ${
      currentSeconds < 10 ? '0' + currentSeconds : currentSeconds
    }`;
    if (durationMinutes) {
      fixTime.current.innerHTML = `0${durationMinutes}: ${
        durationSeconds < 10 ? '0' + durationSeconds : durationSeconds
      }`;
    }
  };

  const handleProgress = () => {
    try {
      if (frontRef.current && frontRef.current.src) {
        const percent =
          (frontRef.current.currentTime / frontRef.current.duration) * 100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(frontRef.current);
      }

      if (rearRef.current && rearRef.current.src) {
        const percent =
          (rearRef.current.currentTime / rearRef.current.duration) * 100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(rearRef.current);
      }

      if (driverRef.current && driverRef.current.src) {
        const percent =
          (driverRef.current.currentTime / driverRef.current.duration) * 100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(driverRef.current);
      }

      if (ptoRef.current && ptoRef.current.src) {
        const percent =
          (ptoRef.current.currentTime / ptoRef.current.duration) * 100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(ptoRef.current);
      }

      if (frontLeft.current && frontLeft.current.src) {
        const percent =
          (frontLeft.current.currentTime / frontLeft.current.duration) * 100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(frontLeft.current);
      }

      if (frontRight.current && frontRight.current.src) {
        const percent =
          (frontRight.current.currentTime / frontRight.current.duration) * 100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(frontRight.current);
      }

      if (rearLeft.current && rearLeft.current.src) {
        const percent =
          (rearLeft.current.currentTime / rearLeft.current.duration) * 100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(rearLeft.current);
      }

      if (rearRight.current && rearRight.current.src) {
        const percent =
          (rearRight.current.currentTime / rearRight.current.duration) * 100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(rearRight.current);
      }

      if (chassisLeft.current && chassisLeft.current.src) {
        const percent =
          (chassisLeft.current.currentTime / chassisLeft.current.duration) *
          100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(chassisLeft.current);
      }

      if (chassisRight.current && chassisRight.current.src) {
        const percent =
          (chassisRight.current.currentTime / chassisRight.current.duration) *
          100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(chassisRight.current);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const barHandling = (e: any) => {
    try {
      if (frontRef.current && frontRef.current.src) {
        frontRef.current.currentTime =
          (e.offsetX / progress.offsetWidth) * frontRef.current.duration;
      }

      if (rearRef.current && rearRef.current.src) {
        rearRef.current.currentTime =
          (e.offsetX / progress.offsetWidth) * rearRef.current.duration;
      }

      if (driverRef.current && driverRef.current.src) {
        driverRef.current.currentTime =
          (e.offsetX / progress.offsetWidth) * driverRef.current.duration;
      }

      if (ptoRef.current && ptoRef.current.src) {
        ptoRef.current.currentTime =
          (e.offsetX / progress.offsetWidth) * ptoRef.current.duration;
      }

      if (frontLeft.current && frontLeft.current.src) {
        frontLeft.current.currentTime =
          (e.offsetX / progress.offsetWidth) * frontLeft.current.duration;
      }

      if (frontRight.current && frontRight.current.src) {
        frontRight.current.currentTime =
          (e.offsetX / progress.offsetWidth) * frontRight.current.duration;
      }

      if (rearLeft.current && rearLeft.current.src) {
        rearLeft.current.currentTime =
          (e.offsetX / progress.offsetWidth) * rearLeft.current.duration;
      }

      if (rearRight.current && rearRight.current.src) {
        rearRight.current.currentTime =
          (e.offsetX / progress.offsetWidth) * rearRight.current.duration;
      }

      if (chassisLeft.current && chassisLeft.current.src) {
        chassisLeft.current.currentTime =
          (e.offsetX / progress.offsetWidth) * chassisLeft.current.duration;
      }

      if (chassisRight.current && chassisRight.current.src) {
        chassisRight.current.currentTime =
          (e.offsetX / progress.offsetWidth) * chassisRight.current.duration;
      }

      // handleProgress();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  // video?.addEventListener('timeupdate', handleProgress);
  progress?.addEventListener('click', barHandling);

  const openFullscreen = (videoUrl: any) => {
    mixpanel.track('Recorded Video', {
      event: `${videoUrl} Video Expanded`,
    });
    videoUrl &&
      window.open(
        `${RoutesConstants.VideoPop}?videoUrl=${videoUrl}`,
        `Video URL`,
        'max-width: 100%',
      );
  };

  return (
    <Layout>
      <div className="mainContent" style={{ position: 'relative' }}>
        <Breadcrumb>
          <BreadcrumbItem
            onClick={() => {
              setCurrentPage(Pages.RecordedVideo);
              // push({
              //   pathname: RoutesConstants.RecordedVideo,
              // })
            }}
          >
            <a>{t(translate.recordedVideo.RecordedVideo)}</a>
          </BreadcrumbItem>
          <BreadcrumbItem
            onClick={() => {
              setCurrentPage(Pages.DayView);
              setStateData(state);
              // push({
              //   pathname: RoutesConstants.DayView,
              //   state: state,
              // })
            }}
          >
            <a>{tractorName}</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <span style={{ color: '#EB921F' }}>Videos</span>
          </BreadcrumbItem>
        </Breadcrumb>
        <Content>
          <Space
            className="mt0"
            style={{ marginBottom: 0, position: 'absolute', top: 0, right: 0 }}
          >
            <p style={{ fontFamily: 'Montserrat-Medium' }}>
              <a
                style={{ fontWeight: 'bold' }}
                onClick={() => {
                  setUpdatedTime((prev: any) => {
                    return moment(prev).subtract(5, 'minutes').valueOf();
                  });
                  setPlay(true);
                }}
              >
                <LeftOutlined />
              </a>{' '}
              <span>{updatedTime && getDateTime(updatedTime)}</span>
              <a
                style={{ fontWeight: 'bold' }}
                onClick={() => {
                  setUpdatedTime((prev: any) => {
                    return moment(prev).add(5, 'minutes').valueOf();
                  });
                  setPlay(true);
                }}
              >
                {' '}
                <RightOutlined />
              </a>
            </p>
          </Space>
          {/* <Row> */}
          <Card
            // title={tractorName}
            bordered={true}
            className="dbWidget videoDbCon"
            style={{ width: '100%' }}
            // extra={
            //   <Space
            //     className="mt0"
            //     style={{ marginBottom: 0, position: 'relative' }}
            //   >
            //     <p style={{ fontFamily: 'Montserrat-Medium' }}>
            //       <a
            //         style={{ fontWeight: 'bold' }}
            //         onClick={() =>
            //           setUpdatedTime((prev: any) => {
            //             return moment(prev).subtract(5, 'minutes').valueOf();
            //           })
            //         }
            //       >
            //         <LeftOutlined />
            //       </a>{' '}
            //       <span>{updatedTime && getDateTime(updatedTime)}</span>
            //       <a
            //         style={{ fontWeight: 'bold' }}
            //         onClick={() =>
            //           setUpdatedTime((prev: any) => {
            //             return moment(prev).add(5, 'minutes').valueOf();
            //           })
            //         }
            //       >
            //         {' '}
            //         <RightOutlined />
            //       </a>
            //     </p>
            //   </Space>
            // }
          >
            <div className="library-video-container">
              <CameraGrid2
                handleProgress={handleProgress}
                frontRef={frontRef}
                rearRef={rearRef}
                frontLeft={frontLeft}
                frontRight={frontRight}
                rearLeft={rearLeft}
                rearRight={rearRight}
                driverRef={driverRef}
                ptoRef={ptoRef}
                vedioInfo={vedioInfo}
                videos={videos}
              />
            </div>
            {videos.length > 0 && (
              <div className="barDisplay">
                <Button
                  style={{ display: 'block' }}
                  className="okBtn"
                  onClick={() => playVideo()}
                >
                  {play ? <CaretRightOutlined /> : <PauseOutlined />}
                </Button>

                <div className="recordVediosprogress">
                  <div className="recordVediosprogress__filled"></div>
                </div>
                <div
                  style={{
                    width: '140px',
                    fontFamily: 'Montserrat-SemiBold',
                  }}
                >
                  <span ref={curTime}>00:00</span> /{' '}
                  <span ref={fixTime}>00:00</span>
                </div>
              </div>
            )}
          </Card>
          {/* </Row> */}
        </Content>
      </div>
    </Layout>
  );
};

export default VideoSnaps;
