/* eslint-disable no-console */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import pause_icon from '../../assets/images/pause_icon.svg';
import play_icon from '../../assets/images/play_icon.svg';
import { TractorHeartBeat } from '../../constants/types';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import RoutesConstants from '../../routes/RoutesConstant';
import { SET_RESET_TIMER, SET_TOGGLE_TIMER } from './actions';
import './autodrivelist.css';
import { RemoteDriveAppCtx } from './RemoteDriveContext';
import Timer from './Timer';

const DeadManHandle: React.FC = () => {
  const locaction = useLocation();
  const [showDeadMan, setShowDeadMan] = useState(false);
  const init = useRef<boolean>(false);
  // const { handlePauseResumeStopLiveCom } = useContext(RemoteDriveAppCtx);
  const { AutoDriveReducer, handlePauseResumeStopLiveCom } = useContext(
    AutoDriveApplicationContext,
  );
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  const {
    drivingTractors,
    runningTractors,
    toggleTimer,
    resetTimer,
    timeLeft,
  } = autoDriveState;
  const { status: toggle } = toggleTimer;
  const handleToggle = (status: boolean) => {
    autoDriveDispatch({
      type: SET_TOGGLE_TIMER,
      payload: {
        status,
        from: !resetTimer && timeLeft == 0 ? 'Event' : 'Click',
      },
    });
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (showDeadMan && init.current) {
      handlePauseResumeStopLiveCom(!toggle ? 'pause' : 'resume');
      // drivingTractors.map((tractor: any) => {
      //   handlePauseResumeStopLiveCom(
      //     !toggle ? 'pause' : 'resume',
      //     tractor,
      //     toggleTimer.from,
      //   );
      // });
      autoDriveDispatch({ type: SET_RESET_TIMER, payload: toggle });
    }
  }, [toggle]);

  useEffect(() => {
    setShowDeadMan(false);
    let statusCheck = false;
    drivingTractors.map((drivingTractor: TractorHeartBeat) => {
      if (
        drivingTractor?.drive_action_details?.drive_action_created_at === 'ATOM'
      ) {
        setShowDeadMan(true);
        statusCheck = true;
      }
    });
    if (statusCheck) init.current = true;
  }, [drivingTractors]);

  useEffect(() => {
    if (runningTractors && runningTractors.length && toggle && resetTimer)
      autoDriveDispatch({ type: SET_RESET_TIMER, payload: false });
  }, [resetTimer, runningTractors, toggle]);

  return (
    <div
      className="remoteOperationsPopup"
      style={{
        display:
          locaction.pathname === RoutesConstants.LiveMap ? 'block' : 'none',
      }}
      // onMouseEnter={() => handleToggle(false)}
      // onMouseLeave={() => handleToggle(true)}
    >
      <h5>ALL REMOTE OPERATIONS</h5>
      <div className="adroutineTimer">
        <div className="adroutineTimerBtns">
          <div>
            {toggle ? (
              <img
                src={pause_icon}
                alt=""
                onClick={() => handleToggle(!toggle)}
              />
            ) : (
              <img
                src={play_icon}
                alt=""
                onClick={() => handleToggle(!toggle)}
              />
              // <PlayCircleOutlined
              //   style={{ fontSize: '400%', color: '#546cb2' }}
              //   onClick={() => handleToggle(!toggle)}
              // />
            )}
          </div>
        </div>
        <div className="adroutineTimerDtls">
          <div className="adrTime_rBtn">
            <div className="adrOpTime headline1">
              {!resetTimer ? <Timer seconds={60 * 10} /> : '00 : 00'}
            </div>
            {showDeadMan && (
              <p
                className="adrResetBtn"
                onClick={() =>
                  autoDriveDispatch({ type: SET_RESET_TIMER, payload: true })
                }
              >
                RESET
              </p>
            )}
          </div>
          <h6>Confirm active monitoring every 10 minutes</h6>
        </div>
      </div>

      {/* <div className="play_time_dur">
        <div className="operationsDuration"> */}
      {/* <h2> {runningTractors.length ? 'PAUSE' : 'RESUME'}</h2> */}
      {/* </div>
      </div> */}
    </div>
  );
};

export default DeadManHandle;
