import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Button, Input, Modal, notification, Select } from 'antd';
import { ApplicationContext } from '../../context/AppContext';
import logo from '../../assets/images/logo192.png';
import './style.css';
import { OrgUser } from '../../constants/types';
import { addTeam, getOrgUsers } from '../../constants/Api';
import loading_logo from '../../assets/images/loading_logo.gif';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import { duration } from 'moment-timezone';
import AppLoader from '../common/AppLoader';

interface Props {
  showModal?: boolean;
  closeModel: (success: boolean) => void;
  setTeamId: (id: any) => void;
}

const { TextArea } = Input;
const { Option } = Select;

export const CreateTeam: React.FC<Props> = ({
  showModal,
  closeModel,
  setTeamId,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails, initTeams } = useContext(ApplicationContext);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [teamName, setTeamName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [result, setResult] = useState<OrgUser[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getassigned();
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      setSaveDisabled(
        teamName.trim().length < 1 || description.trim().length < 1,
      );
    }
  }, [teamName, description, selectedEmployees]);

  const getassigned = async () => {
    let res: any[] = [];
    try {
      const users: OrgUser[] = await getOrgUsers(
        userDetails.organization.api_url,
        userDetails.organization.id,
        '',
      );
      users.map((ele: OrgUser) => {
        ele.fullname = ele.first_name + ' ' + ele.last_name;
      });
      res = users;
      setResult(res);
    } catch (error: any) {
      notification.error({ message: 'unable to get users', duration: 2 });
    }
  };

  const handleChange = async (e: any) => {
    setSelectedEmployees(e);
  };

  const saveTeam = async () => {
    try {
      setLoader(true);
      setSaveDisabled(true);
      const data = {
        name: teamName,
        description: description,
        users: selectedEmployees,
      };
      const response: any = await addTeam(
        userDetails.organization.api_url,
        userDetails.organization.id,
        data,
      );
      if (response) {
        notification.success({
          message: `${teamName} Created Successfully`,
          duration: 2,
        });
        setTeamId(response?.id);
        setLoader(false);
        closeModel(true);
        initTeams();
      }
    } catch (error: any) {
      if (error?.message) {
        notification.error({
          message: error?.message,
          duration: 2,
        });
      } else {
        notification.error({ message: 'Unable to create Team', duration: 2 });
      }
    } finally {
      setLoader(false);
      setSaveDisabled(false);
    }
  };

  const handleChangeTeam = (event: any) => {
    const regex = /^[a-zA-Z0-9 @#$&*)(+_-]*$/;
    if (regex.test(event.target.value)) {
      setTeamName(event.target.value);
    }
  };

  return (
    <>
      <Modal
        className="modalContainer teammodal teamEmpContainer"
        visible={showModal}
        onCancel={() => closeModel(false)}
        width={590}
        closable={false}
        maskClosable={false}
        centered
        footer={[
          <>
            <Button
              key="back"
              className="btnteamCancel Button"
              onClick={() => closeModel(false)}
              data-testid="cancelButton-CreateTeam"
            >
              Cancel
            </Button>
            <Button
              key="submit"
              className="btnteamSave-1 Button"
              disabled={saveDisabled}
              onClick={saveTeam}
              data-testid="saveButton-CreateTeam"
            >
              Save
            </Button>
          </>,
        ]}
      >
        <AppLoader loader={loader} />
        <div className="createteamContainer">
          <div className="createtitle">
            <div className="pull-left">
              <h1 className="title headline1">Create a New Team</h1>
            </div>
            <div className="img-div pull-right">
              <img src={logo} />
            </div>
          </div>
          <p className="teamdescription body1">
            Use teams to manage employees’ access and permissions to the Monarch
            Platform.
          </p>
          <Input
            data-testid="teamNameInputField-CreateTeam"
            placeholder="Enter Team Name"
            className="team-input input-text body1"
            value={teamName}
            onChange={handleChangeTeam}
          />
          <TextArea
            style={{ width: '100%' }}
            data-testid="teamDescriptionInputField-CreateTeam"
            placeholder="Create a description to further identify the specifics of the team"
            showCount
            autoSize={{ minRows: 3, maxRows: 8 }}
            className="team-text-area-2 input-text body1"
            value={description}
            maxLength={110}
            onChange={(e: any) => setDescription(e.target.value)}
          />
          <div className="team-multi-select mBtm36">
            <Select
              showSearch={true}
              mode="multiple"
              placeholder="Add Employees"
              data-testid="addEmployeesDropDownField-CreateTeam"
              style={{ width: '100%' }}
              onChange={handleChange}
              filterOption={(input, option) => {
                return (
                  option!.title
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
              maxTagCount="responsive"
            >
              {result.map((ele: OrgUser) => {
                return (
                  <Option
                    className="createTeamOption"
                    title={ele.fullname}
                    value={ele.id}
                    key={ele?.id}
                    data-testid={`${ele.fullname}-CreateTeam`}
                  >
                    <span className="emailAlign empName">
                      <Avatar
                        style={{
                          width: '34px',
                          height: '34px',
                          marginRight: '25px',
                          backgroundColor: '#67AFB7',
                          color: 'white',
                          fontFamily: 'Montserrat-Bold',
                        }}
                        src={ele?.profile_url}
                      >
                        {ele?.first_name?.charAt(0)}
                        {ele?.last_name?.charAt(0)}
                      </Avatar>
                      <span className="optionname">{ele.fullname}</span>
                    </span>
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateTeam;
