import { Button, DatePicker, Modal, notification, Select, Space } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateSnapshots, getDownloadedSnapshots } from '../../constants/Api';
import { initScroller } from '../../constants/Common';
import { TractorResponseType } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import translate from '../../locale/en_translate.json';
import { DownloadOutlined, ReloadOutlined } from '@ant-design/icons';

const { Option } = Select;

interface Props {
  callback: (state: boolean) => void;
  tractors: TractorResponseType[];
}

const SnapDownload: React.FC<Props> = ({ callback, tractors }) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [filterData, setFilter] = useState<any[]>([]);
  const [tractorId, setTractorId] = useState<string | undefined>();
  const [dateString, setDateString] = useState<string | undefined>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [validations, setValidations] = useState({
    date: false,
  });
  const [init, setInit] = useState<boolean>(false);
  const activity = useRef<string>('');

  const loadSnaps = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : fetchDownloads();
    } else if (activity.current === 'paginate' || activity.current === '') {
      fetchDownloads();
    }
  };

  const fetchDownloads = async () => {
    try {
      setLoader(true);
      const { organization } = userDetails;
      const response = await getDownloadedSnapshots(
        organization.api_url,
        organization.id,
        pageNumber,
        25,
      );
      setTotal(response._metadata?.total_records_count);
      const data =
        response.records && response.records.length ? response.records : [];

      setHasMore(data.length === 25);

      if (checkActivity()) setFilter(data);
      else if (activity.current === 'paginate')
        setFilter([...filterData, ...data]);
      else setFilter(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoader(false);
      // if (!initialize) setInitialize(true);
    }
  };

  const initGenerateSnap = async () => {
    try {
      const payload = {
        tractor_id: tractorId,
        snapshot_date: dateString,
      };
      const { organization } = userDetails;
      const { msg } = await generateSnapshots(organization?.id, payload);
      notification.success({ message: msg });
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  const handleGenerate = async () => {
    activity.current = 'filter';
    setInit(true);
    // eslint-disable-next-line no-console
    console.log('handleGenerate', tractorId, dateString);
    if (validations.date) {
      await initGenerateSnap();
      loadSnaps();
    }
  };
  const handleSelect = (e: string) => {
    setTractorId(e);
  };

  const checkActivity = () =>
    ['search', 'sort', 'filter'].includes(activity.current);

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const onChange = (date: any, dateString: string) => {
    setValidations({
      date: date ? true : false,
    });
    setDateString(date ? dateString : undefined);
  };

  const columns = [
    {
      title: `${t(translate.label.date)}`,
      dataIndex: 'date_str',
      key: 'date_str',
      // width: '25%',
    },
    {
      title: `${t(translate.label.createdBy)}`,
      dataIndex: 'created_by',
      key: 'created_by',
      // width: '15%',
      render: (created_by: any) => {
        return created_by && created_by.first_name + ' ' + created_by.last_name;
      },
    },
    {
      title: `${t(translate.label.tractor)}`,
      dataIndex: 'tractor',
      key: 'tractor',
      // width: '15%',
      render: (tractor: any) => {
        return tractor ? tractor.name : 'All';
      },
    },
    {
      title: `${t(translate.label.feedback)}`,
      dataIndex: 'feedback',
      key: 'feedback',
      // width: '15%',
    },
    {
      title: `${t(translate.label.status)}`,
      dataIndex: 'status',
      key: 'status',
      // width: '15%',
    },
    {
      title: `${t(translate.label.download)}`,
      dataIndex: 'file_path',
      key: 'file_path',
      width: '15%',
      render: (file_path: string, record: any) => {
        return (
          file_path && (
            <DownloadOutlined
              onClick={() => downloadURI(file_path, record.id)}
            />
          )
        );
      },
    },
  ];

  function downloadURI(uri: string, name: string) {
    const link = document.createElement('a');
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', name);
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  useEffect(() => {
    loadSnaps();
  }, [pageNumber]);

  // eslint-disable-next-line no-console
  console.log(init && !validations.date);

  return (
    <Modal
      // title="Download Snapshots"
      // centered
      visible={true}
      onOk={() => callback(true)}
      onCancel={() => callback(false)}
      width={1200}
      footer={false}
    >
      <Space style={{ margin: '10px 0' }}>
        <Select
          value={tractorId}
          onChange={(e) => {
            handleSelect(e);
          }}
          style={{ width: '300px' }}
          placeholder="Select Tractor"
        >
          <Option key={Math.random()} value="">
            All
          </Option>
          {tractors.map((tractor: any, key) => {
            return (
              <Option key={key} value={tractor.index}>
                {tractor.label}
              </Option>
            );
          })}
        </Select>
        <DatePicker
          className={init && !validations.date ? 'datepicker-error-class' : ''}
          // className="errorClass"
          onChange={onChange}
          style={{ width: '200px' }}
        />
        <Button
          onClick={handleGenerate}
          style={{ background: '#366376', color: '#FFF', borderRadius: '10px' }}
        >
          Generate
        </Button>
        <Button
          className="load-snap-refresh"
          onClick={loadSnaps}
          // style={{ background: '#366376', color: '#FFF', borderRadius: '10px' }}
        >
          <ReloadOutlined />
        </Button>
      </Space>{' '}
      <div className="tblDft lbTbls farmTabsTbl reportsTbl hoverable h42vh mx-ht cPointer">
        <InfiniteScrollTable
          columns={columns}
          hasMore={hasMore}
          loading={loader}
          filterData={filterData}
          totalcount={total}
          handleLoadMore={handleLoadMore}
          filename="Snapshots"
          // onRowClick={(record) => getRow(record)}
          // onChange={handleChange}
        />
      </div>
    </Modal>
  );
};

export default SnapDownload;
