/* eslint-disable react/jsx-no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Image } from 'antd';
import Layout from 'antd/lib/layout/layout';
import closeGreen from '../../assets/images/closeGreen.svg';
import './SnapshotStyle.css';
import { SnapShot } from '../../constants/types';
import applicationIds from '../../locale/applicationIds.json';
import usStandIcon from '../../assets/images/usStandIcon.svg';
import loading_logo from '../../assets/images/loading_logo.gif';
import {
  formatCameraText,
  removeUnderScore,
  sortCameraViews,
} from '../../constants/Common';
const GUARD_RAILS = 'GUARD_RAILS';
import { useCameraSnap, useSnap } from '../../constants/customHooks';
import { ApplicationContext } from '../../context/AppContext';
import { TOGGLE_VIDEO_WIDGET } from '../../context/actions';
import { getSnapshot } from '../../constants/Api';
import AppLoader from '../common/AppLoader';

interface Props {
  snapshotFlag: boolean;
  guardrailsList: any;
  Close: () => void;
  selectedJumpnGo: any;
}
const SnapView: React.FC<Props> = ({
  snapshotFlag,
  guardrailsList,
  Close,
  selectedJumpnGo,
}: Props) => {
  const [snapshot, setSnapShots] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const { userDetails } = useContext(ApplicationContext);

  const useSnap = (guardrails: any) => {
    const init = async () => {
      try {
        setLoader(true);
        const response = await getSnapshot(
          userDetails.organization.api_url,
          guardrails.guard_rail_triggered_uuid,
          guardrails.id,
        );
        const result = sortCameraViews(response, 'camera_name', 'images');
        setSnapShots(result);
      } catch (error) {
        // error
      } finally {
        setLoader(false);
      }
    };
    if (guardrails.length !== 0) init();

    //   return [snapshot];
  };

  useEffect(() => {
    useSnap(guardrailsList);
  }, []);
  // const [snapShots] = useSnap(guardrailsList);
  const [cameraData] = useCameraSnap(guardrailsList);
  const { APPReducer } = useContext(ApplicationContext);
  const [state, dispatch] = APPReducer;

  const handleVideos = () => {
    const {
      created_date_time,
      date_time,
      tractor_id: tractorId,
    } = guardrailsList;
    const videoTime = date_time || created_date_time;
    dispatch({
      type: TOGGLE_VIDEO_WIDGET,
      payload: {
        toggle: !state.videoWidgetData.toggle,
        videoTime: videoTime,
        tractorId: tractorId,
      },
    });
  };
  if (!guardrailsList) return <></>;
  return (
    <Layout>
      <div className={'rightSideWidegt ' + (snapshotFlag ? 'expand' : 'hide')}>
        <div className="closeButton mb40">
          <img
            id={applicationIds.snapshotScreen.snapshotCloseBtn}
            src={closeGreen}
            alt="close icon"
            onClick={Close}
          />
        </div>

        <div className="proRoleHead posRel">
          <span className="headline1">
            {guardrailsList.created_at === 'GUARD_RAILS'
              ? 'Guardrails'
              : guardrailsList.created_at === 'JUMP_AND_GO'
              ? 'Jump And Go'
              : 'Snapshot'}
          </span>
          {guardrailsList.created_at === 'GUARD_RAILS' && (
            <img
              src={usStandIcon}
              alt="dataLayer"
              height={18}
              style={{ padding: '0px 10px', height: '28px' }}
            />
          )}
          <h6 className="snapDate headline2">{guardrailsList?.title}</h6>
        </div>
        <div className="profileView">
          <div className="proRowType mb0 mt24">
            <h5 className="proRoleType subHead subFlex">
              <span className="subTitles">Tractor</span>
              <span className="subdesc">{selectedJumpnGo?.tractor?.name}</span>
            </h5>
          </div>

          <div className="proRowType mb0 mt24">
            <h5 className="proRoleType subHead subFlex">
              <span
                className="subTitles"
                data-testid="creatorName-SnapshortView"
              >
                Operator
              </span>
              <span className="subdesc">
                {' '}
                {selectedJumpnGo?.operator?.first_name}{' '}
                {selectedJumpnGo?.operator?.last_name}
              </span>
            </h5>
          </div>

          <div className="proRowType mb0 mt24">
            <h5 className="proRoleType subHead subFlex">
              <span className="subTitles">Creation Type</span>
              <span className="subdesc">
                {guardrailsList?.created_at === GUARD_RAILS
                  ? removeUnderScore(guardrailsList?.guard_rail_type)
                  : removeUnderScore(guardrailsList?.created_at)}
              </span>
            </h5>
          </div>

          {guardrailsList?.created_at === GUARD_RAILS && (
            <div className="proRowType mb0 mt24">
              <h5 className="proRoleType subHead subFlex">
                <span className="subTitles">Camera</span>
                <span className="subdesc">
                  {' '}
                  {formatCameraText(cameraData?.camera)}
                </span>
              </h5>
            </div>
          )}

          <div className="proRowType mb0 mt24">
            <h5 className="proRoleType subHead subFlex">
              <span className="subTitles">Images</span>
            </h5>
          </div>

          <div className="camWrapper snapsContainer">
            <AppLoader loader={loader} />
            {!loader && (
              <Image.PreviewGroup>
                {snapshot.map((item: SnapShot, key: number) => (
                  <div
                    // className={
                    //   betaPilot == '' || betaPilot == 'Beta'
                    //     ? 'camBox'
                    //     : 'camBox1'
                    // }
                    key={key}
                    className="camBox"
                  >
                    <div className="camBoxContent">
                      <Image
                        src={item.camera_image_url}
                        alt="camImg"
                        className="sImg"
                      />
                    </div>
                    <div className="camBoxHeads">
                      {' '}
                      {item?.camera_name?.toUpperCase()}
                    </div>
                  </div>
                ))}
              </Image.PreviewGroup>
            )}
          </div>
          <div className="proRowType mt32">
            <Button
              type="primary"
              className="employeeCreate w160"
              onClick={handleVideos}
            >
              View Videos
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SnapView;
