import Layout, { Content } from 'antd/lib/layout/layout';
import React from 'react';
import Profile from './Profile';

const ProfileContainer: React.FC = () => {
  return (
    <Layout>
      <Content>
        <div className="mainContent">
          <div className="dbWidget profileCont wBg">
            <div className="anaDashboardCont w100per">
              <Profile />
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default ProfileContainer;
