/* eslint-disable no-console */
import {
  DeleteOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Card,
  Checkbox,
  Col,
  Input,
  List,
  Menu,
  notification,
  Radio,
  Select,
  Space,
  Switch,
  TimePicker,
  Tooltip,
} from 'antd';
import Layout from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import React, { useContext, useEffect, useState } from 'react';
import locationIcon from '../../assets/images/c_loc.svg';
import dwl_icon from '../../assets/images/dwl_icon.png';
import loading_logo from '../../assets/images/loading_logo.gif';
import polygons from '../../assets/images/plg.svg';
import routes from '../../assets/images/rts.svg';
import vines from '../../assets/images/vin.svg';
import vineBlock from '../../assets/images/vnb.svg';
import vineRow from '../../assets/images/vnr.svg';
import wayPoint from '../../assets/images/wpt.svg';
import MapMakerMap from './MapMakerMap';
import './style.css';

import { BackwardOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { saveAs } from 'file-saver';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import MarkIcon from '../../assets/images/marks.png';
import TravalledPointIcon from '../../assets/images/t-points.png';
import {
  downloadMapMakerFeature,
  getAllMapTractors,
  getVineRowData,
  publishAbBlockWithId,
} from '../../constants/Api';
import {
  getDateTime,
  vineBlockStyle,
  vineRowStyle,
} from '../../constants/Common';
import { listVisibility } from '../../constants/constant';
import {
  MapFeature,
  MenuItem,
  TractorsList,
  VineBlock,
} from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import VBVRAccordion from './VBVRAccordion';
import AppLoader from '../common/AppLoader';
const { RangePicker } = TimePicker;
export interface ToggleLayer {
  type: string;
  value: boolean;
  position: number;
}
const MapMakerBeta: React.FC = () => {
  const { SubMenu } = Menu;
  const { Option } = Select;
  const [collapsed, setCollapsed] = useState(true);
  const [detailsPage, setDetailsPage] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const [menuItems, setMenuItems] = useState<MapFeature[]>([]);
  const [filteredMenuItems, setFilteredMenuItems] = useState<MapFeature[]>([]);
  const [routesData, setRoutes] = useState<MapFeature[]>([]);
  const [travelledRoutesData, setTravelledRoutesData] = useState<MapFeature[]>(
    [],
  );
  const [vinesData, setVines] = useState<MapFeature[]>([]);
  const [vineRowsData, setVineRows] = useState<MapFeature[]>([]);
  const [abLines, setAbLines] = useState<MapFeature[]>([]);
  const [abBlocks, setAbBlocks] = useState<MapFeature[]>([]);
  const [vineBlocksData, setVineBlocks] = useState<MapFeature[]>([]);
  const [polygonsData, setPolygons] = useState<MapFeature[]>([]);
  const [suggestedPolygonsData, setSuggestedPolygons] = useState<MapFeature[]>(
    [],
  );
  const [waypointsData, setWaypoints] = useState<MapFeature[]>([]);
  const [jumpAndGosData, setJumpAndGosData] = useState<MapFeature[]>([]);
  const [vineLaneData, setVineLaneData] = useState<MapFeature[]>([]);
  const [mapFeature, setMapFeature] = useState<MapFeature>();
  const [selectedMapFeature, setSelectedMapFeature] = useState<MapFeature>();
  const [selectedFeatureItem, setSelectedFeatureItem] =
    useState<MenuItem | null>();
  const [toggleLayer, setToggleLayer] = useState<ToggleLayer>();
  const [jumpAndGoTractorId, setJumpAndGoTractorId] = useState<any>();
  const [tractorsList, setTractorsList] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([
    moment().subtract(1, 'day').toDate(),
    moment().toDate(),
  ]);
  const [openKey, setOpenKey] = useState('');
  const [vineblockId, setvineblockId] = useState<string>();
  const [deleteRouteId, setDeleteRouteId] = useState<string>();
  const [vineblocksFromMap, setvineblocksFromMap] = useState<VineBlock[]>();
  const [loader, setLoader] = useState<boolean>(false);
  const [publishedTimeToReload, setPublishedTimeToReload] = useState<number>(0);
  const [reloadVineBlocks, setReloadVineBlocks] = useState(false);
  // layers toggles
  const onChange = (e: CheckboxChangeEvent) => {
    const item = e.target.value;
    item.visible = e.target.checked ? true : false;
    setMapFeature(item);
    setTrigger(!trigger);
  };
  // initialized for default selection of layer toggles
  const [checkedState, setCheckedState] = useState([
    listVisibility.WAYPOINT,
    listVisibility.POLYGON,
    listVisibility.VINE,
    listVisibility.VINEROW,
    listVisibility.VINEBLOCK,
    listVisibility.ROUTE,
    listVisibility.TRAVELLEDROUTE,
    listVisibility.MARKS,
    listVisibility.VINE_ROW_HEAD,
    listVisibility.VINELANE,
    listVisibility.JUMPANDGO,
    listVisibility.ABLINE,
    listVisibility.ABBLOCK,
    false,
  ]);
  const [range, setRange] = useState<any>(0);
  const [times, setTimes] = useState<any>([0, 0]);

  const handle = useFullScreenHandle();

  const toggleMapFeatureVisible = (featureType: string, visible: boolean) => {
    const feature: any = {
      type: featureType,
      visible: visible,
    };
    setSelectedMapFeature(feature);
  };
  useEffect(() => {
    abBlocks && handleSelectedMenu(selectedFeatureItem as MenuItem);
  }, [abBlocks]);
  useEffect(() => {
    routesData && handleSelectedMenu(selectedFeatureItem as MenuItem);
  }, [routesData]);
  // set the right side slider dynamic content
  const handleSelectedMenu = (menuItem: MenuItem) => {
    if (menuItem && menuItem.type) {
      setSelectedFeatureItem(menuItem);
      switch (menuItem.type) {
        case 'ROUTE':
          setMenuItems(routesData);
          setFilteredMenuItems(routesData);
          break;
        case 'TRAVELLEDROUTE':
          setMenuItems(travelledRoutesData);
          setFilteredMenuItems(travelledRoutesData);
          break;
        case 'VINE':
          setMenuItems(vinesData);
          setFilteredMenuItems(vinesData);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        case 'VINEROW':
          setMenuItems(vineRowsData);
          setFilteredMenuItems(vineRowsData);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        case 'VINEBLOCK':
          setMenuItems(vineBlocksData);
          setFilteredMenuItems(vineBlocksData);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        case 'POLYGON':
          setMenuItems([...polygonsData, ...suggestedPolygonsData]);
          setFilteredMenuItems([...polygonsData, ...suggestedPolygonsData]);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        case 'WAYPOINT':
          setMenuItems(waypointsData);
          setFilteredMenuItems(waypointsData);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        case 'JUMPANDGO':
          setMenuItems(jumpAndGosData);
          setFilteredMenuItems(jumpAndGosData);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        case 'REMOTEAV':
          setToggleLayer({ type: 'REMOTEAV', value: true, position: 10 });
          break;
        case 'VINELANE':
          setMenuItems(vineLaneData);
          setFilteredMenuItems(vineLaneData);
          toggleMapFeatureVisible(menuItem.type, true);
          break;

        case 'ABLINE':
          setMenuItems(abLines);
          setFilteredMenuItems(abLines);
          toggleMapFeatureVisible(menuItem.type, true);
          break;
        case 'ABBLOCK':
          setMenuItems(abBlocks);
          setFilteredMenuItems(abBlocks);
          toggleMapFeatureVisible(menuItem.type, true);
          break;

        default:
          break;
      }
    }
  };
  useEffect(() => {
    if (
      selectedFeatureItem &&
      selectedFeatureItem.type &&
      selectedFeatureItem.type === 'JUMPANDGO'
    ) {
      setMenuItems(jumpAndGosData);
      setFilteredMenuItems(jumpAndGosData);
      toggleMapFeatureVisible('JUMPANDGO', true);
    }
  }, [jumpAndGosData, selectedFeatureItem]);
  // full screen set up
  const toggleFullScreen = () => {
    if (handle.active) {
      handle.exit();
    } else {
      handle.enter();
    }
  };
  // handling layers visibility
  const handleToggleLayer = (e: boolean, position: number, type?: string) => {
    setToggleLayer({
      type: type || '',
      value: e,
      position,
    });
  };
  // handling layers checkbox visibility
  useEffect(() => {
    if (toggleLayer && toggleLayer.position >= 0) {
      const updatedCheckedState = checkedState.map((item, index) =>
        index === toggleLayer.position ? toggleLayer.value : item,
      );
      setCheckedState(updatedCheckedState);
    }
  }, [toggleLayer]);
  // filtering menu list dynamicly
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filtered = menuItems.filter((menuItem: MapFeature) => {
      return menuItem.name.toLowerCase().includes(e.target.value.toLowerCase());
    });

    setFilteredMenuItems(filtered);
  };
  // static menu list
  const list: MenuItem[] = [
    {
      name: 'Waypoints',
      image_url: wayPoint,
      type: 'WAYPOINT',
      layer: false,
    },
    {
      name: 'Polygons',
      image_url: polygons,
      type: 'POLYGON',
      layer: false,
    },
    { name: 'Vines', image_url: vines, type: 'VINE', layer: false },
    {
      name: 'VineRows',
      image_url: vineRow,
      type: 'VINEROW',
      layer: false,
    },
    {
      name: 'VineBlocks',
      image_url: vineBlock,
      type: 'VINEBLOCK',
      layer: false,
    },
    {
      name: 'Routines',
      image_url: routes,
      type: 'ROUTE',
      layer: false,
    },
    {
      name: 'Travelled Route',
      image_url: routes,
      type: 'TRAVELLEDROUTE',
      layer: false,
    },
    {
      name: 'Marks',
      image_url: MarkIcon,
      type: 'MARKS',
      layer: true,
    },
    {
      name: 'Vine row heads',
      image_url: MarkIcon,
      type: 'WINEROWHEAD',
      layer: true,
    },
    {
      name: 'Vine Lanes',
      image_url: TravalledPointIcon,
      type: 'VINELANE',
      layer: false,
    },
    {
      name: 'Jump & Go',
      image_url: TravalledPointIcon,
      type: 'JUMPANDGO',
      layer: true,
    },
    {
      name: 'Ab Line',
      image_url: vineRow,
      type: 'ABLINE',
      layer: false,
    },
    {
      name: 'Ab Block',
      image_url: polygons,
      type: 'ABBLOCK',
      layer: false,
    },
  ];

  // polygon colors
  const polyColorHeaders = [
    {
      heading: 'GeoFence',
      color: 'white',
    },
    {
      heading: 'Field',
      color: 'green',
    },
    {
      heading: 'Path',
      color: 'yellow',
    },
    {
      heading: 'Junction',
      color: 'red',
    },
    {
      heading: 'Blocked',
      color: 'blue',
    },
    {
      heading: 'Brown',
      color: 'brown',
    },
    {
      heading: 'Blue',
      color: 'blue',
    },
    {
      heading: 'Suggested Green',
      color: 'green',
    },
    {
      heading: 'Suggested Blue',
      color: 'blue',
    },
  ];

  const recenterSelctedFeature = (mapFeature: MapFeature) => {
    setMapFeature(mapFeature);
    setTrigger(!trigger);
  };
  const [chapters, setChapters] = useState<MapFeature[]>([]);
  const { userDetails } = useContext(ApplicationContext);

  useEffect(() => {
    const vB = vineBlocksData;
    const vR = vineRowsData;
    vR &&
      vR.length > 0 &&
      vB &&
      vB.map((vb: any, index: number) => {
        const { vine_blocks_uuid: vine_blocks_uuidVb } = vb;
        vb.children = [];
        vR.map((vr: any) => {
          const { vine_blocks_uuid } = vr;
          vine_blocks_uuidVb === vine_blocks_uuid && vb.children.push(vr);
        });
        index + 1 == vB.length && setChapters(vB);
      });
  }, [vineBlocksData, vineRowsData]);

  useEffect(() => {
    if (userDetails && userDetails.organization && vineblocksFromMap) {
      // getVineBlocks(vineblocksFromMap);
    }
  }, [userDetails, vineblocksFromMap]);

  // get vineblocks list
  const getVineBlocks = async (records: VineBlock[]) => {
    if (records && records.length > 0) {
      records.forEach((element) => {
        element.mapId = `vine_block_${element.id}`;
        element.type = 'VINEBLOCK';
        element.visible = true;
        element.style = vineBlockStyle;
        element.is_published = element.is_published ? true : false;
      });
      getVineRows(records);
    }
  };
  // get vine rows for expand in right side slider
  const getVineRows = async (vineblocks: VineBlock[]) => {
    for (const topic of vineblocks) {
      if (topic.vine_blocks_uuid) {
        try {
          const records: VineBlock[] = await getVineRowData(
            userDetails.organization.api_url,
            userDetails.organization.farm.id,
            topic.vine_blocks_uuid,
          );
          if (records && records.length > 0) {
            records.forEach((element: any) => {
              element.mapId = `vine_row_${element.id}`;
              element.type = 'VINEROW';
              element.visible = true;
              element.style = vineRowStyle;
            });
            topic.children = records;
          }
        } catch (err: any) {
          notification.error({
            message: err.response?.data.error.message,
          });
        }
      }
    }
    // setChapters(vineblocks);
  };

  const checkDownloadable = () => {
    const data = ['Polygons', 'Vines', 'VineRows', 'VineBlocks', 'Waypoints'];
    return data.includes(selectedFeatureItem?.name || '');
  };

  // down load csv file from backend
  const CsvFileDownload = async (category: string) => {
    try {
      const records: any = await downloadMapMakerFeature(
        userDetails.organization.api_url,
        userDetails.organization.id,
        userDetails.organization.farm.id,
        category,
      );

      const fileName = selectedFeatureItem?.name || 'catrography';
      await saveAs(records.data, `${fileName}.csv`);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  const convertCategoryTests = (category: string) => {
    switch (category) {
      case 'Polygons':
        return 'polygons';

      case 'Vines':
        return 'vines';

      case 'VineRows':
        return 'vine_rows';

      case 'VineBlocks':
        return 'vine_blocks';

      case 'Waypoints':
        return 'waypoints';

      default:
        return '';
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        const { organization } = userDetails;
        const { records } = await getAllMapTractors(
          organization.api_url,
          organization.id,
        );
        setTractorsList(records);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    if (userDetails && userDetails.organization) {
      init();
    }
  }, [userDetails]);

  const handleSelectDateRange = (dates: any[]) => {
    const [date1, date2] = dates;
    if (date1 && date2) {
      date1.getTime() < date2.getTime() && setSelectedDateRange([date1, date2]);
    } else {
      setSelectedDateRange([date1, date2]);
    }
  };
  const handleSelectDateRange1 = (jng: any, dates: any[]) => {
    let [date1Old, date2Old] = jng.times;
    let [date1, date2] = dates;
    date1Old = moment(date1Old).toDate().getTime();
    date2Old = moment(date2Old).toDate().getTime();
    date1 = moment(date1).toDate().getTime();
    date2 = moment(date2).toDate().getTime();

    if (date1Old > date1 || date2 > date2Old) {
      return;
    }
    if (date1 && date2) {
      if (date1 < date2) {
        jng.showTimes = dates;
        setMapFeature(jng);
        setTrigger(!trigger);
        setTimes(dates);
      }
    } else {
      jng.showTimes = dates;

      setMapFeature(jng);
      setTrigger(!trigger);
      setTimes(dates);
    }
  };

  const publishAbBlock = async (abBlockId: number) => {
    const payload = {
      is_published: true,
    };
    try {
      const { organization } = userDetails;
      const {
        msg,
        published_date_time,
      }: { msg: string; published_date_time: number } =
        await publishAbBlockWithId(
          userDetails.organization.api_url,
          organization.farm.id,
          abBlockId,
          payload,
        );
      published_date_time && setPublishedTimeToReload(published_date_time);
      // reloadVineBlock && reloadVineBlock();
      notification.success({ message: msg });
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };

  return (
    <FullScreen handle={handle}>
      <Layout style={{}}>
        <Sider
          collapsible
          collapsed={collapsed}
          collapsedWidth={0}
          onCollapse={setCollapsed}
          theme="light"
          width="380"
          className={`livemapSidebar mapmakerSidebar mmBeta ${
            selectedFeatureItem &&
            selectedFeatureItem.type &&
            selectedFeatureItem.type === 'JUMPANDGO'
              ? 'jump-and-go-expand'
              : ''
          }`}
          trigger={null}
        >
          {collapsed && (
            <div
              className="tIconOpen"
              data-testid="openLayersIcon-MapMakerBeta"
              onClick={() => setCollapsed(false)}
            ></div>
          )}
          {!collapsed && (
            <div
              className="tIconClose"
              data-testid="closeLayersIcon-MapMakerBeta"
              onClick={() => setCollapsed(true)}
            ></div>
          )}
          {!detailsPage && (
            <List
              header="Layers"
              className="demo-loadmore-list"
              itemLayout="horizontal"
              dataSource={list}
              renderItem={(item, index) => (
                <List.Item className="switchC">
                  <List.Item.Meta
                    avatar={<Avatar src={item.image_url} />}
                    title={
                      <Space direction="horizontal">
                        <div
                          onClick={() => {
                            item &&
                              item.type !== 'WINEROWHEAD' &&
                              item.type !== 'MARKS' &&
                              setDetailsPage(true);
                            handleSelectedMenu(item);
                          }}
                        >
                          {item.name}
                        </div>
                        <div className="w30">
                          {item.type !== 'REMOTEAV' && (
                            <Switch
                              defaultChecked={checkedState[index]}
                              checked={checkedState[index]}
                              data-testid={`${item.type}-MapMakerBetaLayers`}
                              size="small"
                              onClick={(e) =>
                                handleToggleLayer(e, index, item?.type)
                              }
                            />
                          )}
                        </div>
                      </Space>
                    }
                  />
                </List.Item>
              )}
            />
          )}
          {detailsPage && (
            <Layout className="site-layout lmSide">
              <div
                className="lmapBack"
                onClick={() => {
                  setDetailsPage(false);
                  setSelectedFeatureItem(null);
                }}
              >
                <BackwardOutlined />
              </div>
              <Card
                title={selectedFeatureItem?.name}
                bordered={true}
                className="abcc"
              >
                {checkDownloadable() && (
                  <img
                    className="btnSavefile"
                    src={dwl_icon}
                    onClick={() =>
                      CsvFileDownload(
                        convertCategoryTests(selectedFeatureItem?.name || ''),
                      )
                    }
                  />
                )}
                {selectedFeatureItem?.type != 'REMOTEAV' &&
                  selectedFeatureItem?.type !== 'JUMPANDGO' && (
                    <Input
                      className=""
                      placeholder="Search"
                      onChange={(e) => handleSearch(e)}
                      suffix={<SearchOutlined className="searchIcon" />}
                    />
                  )}
                {selectedFeatureItem?.type != 'REMOTEAV' &&
                selectedFeatureItem?.type != 'POLYGON' &&
                selectedFeatureItem?.type != 'VINEBLOCK' &&
                selectedFeatureItem?.type != 'JUMPANDGO' &&
                filteredMenuItems &&
                filteredMenuItems.length > 0
                  ? filteredMenuItems.map((listItem, index) => {
                      return (
                        <>
                          <Col span={8} style={{ padding: '5px 2px' }}>
                            <Checkbox
                              key={index}
                              defaultChecked={listItem.visible}
                              value={listItem}
                              onChange={onChange}
                              className="mapCheckbox"
                            >
                              <div style={{ display: 'flex' }}>
                                <Tooltip title={listItem.name}>
                                  <div className="mapNames">
                                    {listItem.name}
                                  </div>{' '}
                                </Tooltip>
                                {selectedFeatureItem?.type === 'ABBLOCK' &&
                                  !listItem.isPublished && (
                                    <Tooltip title="Publish" placement="top">
                                      <ReloadOutlined
                                        onClick={(e) => {
                                          e.preventDefault();
                                          publishAbBlock(listItem?.id);
                                        }}
                                        style={{
                                          zIndex: 99,
                                          marginLeft: -22,
                                          left: 192,
                                          top: 7,
                                          position: 'absolute',
                                        }}
                                      />
                                    </Tooltip>
                                  )}

                                {(selectedFeatureItem?.type === 'TRACTOR' ||
                                  selectedFeatureItem?.type === 'IMPLEMENT' ||
                                  selectedFeatureItem?.type === 'VINE' ||
                                  selectedFeatureItem?.type === 'VINEROW' ||
                                  selectedFeatureItem?.type === 'VINEBLOCK' ||
                                  selectedFeatureItem?.type === 'VINELANE' ||
                                  selectedFeatureItem?.type === 'ROUTE' ||
                                  selectedFeatureItem?.type === 'WAYPOINT' ||
                                  selectedFeatureItem?.type === 'ABLINE' ||
                                  selectedFeatureItem?.type === 'ABBLOCK') && (
                                  <>
                                    <img
                                      src={locationIcon}
                                      width="28"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        recenterSelctedFeature(listItem);
                                      }}
                                    />
                                  </>
                                )}
                                {selectedFeatureItem &&
                                  selectedFeatureItem?.type === 'ROUTE' && (
                                    <Tooltip title="Delete" placement="top">
                                      <DeleteOutlined
                                        onClick={(e) => {
                                          console.log('tested');
                                          e.preventDefault();
                                          setDeleteRouteId('');
                                          setTimeout(() => {
                                            setDeleteRouteId(listItem?.mapId);
                                          }, 100);
                                        }}
                                        style={{
                                          zIndex: 99,
                                          marginLeft: -22,
                                          left: 245,
                                          top: 7,
                                          position: 'absolute',
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                              </div>
                            </Checkbox>
                          </Col>
                        </>
                      );
                    })
                  : null}
                {selectedFeatureItem?.type === 'POLYGON' &&
                polyColorHeaders &&
                filteredMenuItems &&
                filteredMenuItems.length > 0 ? (
                  <Menu mode="inline" defaultSelectedKeys={['1']}>
                    {polyColorHeaders.map((polyColor) => (
                      <>
                        <SubMenu
                          key={polyColor.heading}
                          title={polyColor.heading}
                          style={{ fontSize: '18px' }}
                          className="poly-menu-class"
                        >
                          {polyColor &&
                            filteredMenuItems &&
                            filteredMenuItems
                              .filter((listItem) => {
                                if (polyColor.heading === 'Suggested Green') {
                                  return (
                                    listItem.suggested &&
                                    listItem.color == polyColor.color
                                  );
                                }
                                if (polyColor.heading === 'Suggested Blue') {
                                  return (
                                    listItem.suggested &&
                                    listItem.color == polyColor.color
                                  );
                                } else {
                                  return (
                                    !listItem.suggested &&
                                    listItem.color == polyColor.color
                                  );
                                }
                              })
                              .map((listItem, index) => {
                                return (
                                  <div key={index} className="poly-class">
                                    <Menu.Item
                                      style={{ padding: '5px 2px' }}
                                      className=""
                                    >
                                      <Checkbox
                                        key={index}
                                        defaultChecked={listItem.visible}
                                        value={listItem}
                                        onChange={onChange}
                                        className={`mapCheckbox ${listItem.color}-box`}
                                      >
                                        <div style={{ display: 'flex' }}>
                                          <Tooltip title={listItem.name}>
                                            <div className="mapNames">
                                              {listItem.name}
                                            </div>{' '}
                                          </Tooltip>
                                          {selectedFeatureItem?.type ===
                                            'POLYGON' && (
                                            <>
                                              <img
                                                src={locationIcon}
                                                width="28"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  recenterSelctedFeature(
                                                    listItem,
                                                  );
                                                }}
                                              />
                                            </>
                                          )}
                                        </div>
                                      </Checkbox>
                                    </Menu.Item>
                                  </div>
                                );
                              })}
                        </SubMenu>
                      </>
                    ))}
                  </Menu>
                ) : null}
                {selectedFeatureItem?.type === 'VINEBLOCK' &&
                  chapters &&
                  chapters.length && (
                    <div className="repairLayout leftMenuAlign vinBlockContainer mmVineBlock ">
                      {chapters.map((chapter: MapFeature) => {
                        return (
                          <VBVRAccordion
                            topic={chapter}
                            key={chapter.name}
                            toggleMapItem={() => setTrigger(!trigger)}
                            setMapFeature={(data: MapFeature) =>
                              setMapFeature(data)
                            }
                            recenterSelctedFeature={(data: MapFeature) => {
                              recenterSelctedFeature(data);
                            }}
                            reloadVineBlock={() =>
                              setReloadVineBlocks(!reloadVineBlocks)
                            }
                            deletVineBlock={(id: string) => {
                              setvineblockId('');
                              setTimeout(() => {
                                setvineblockId(id);
                              }, 100);
                            }}
                          />
                        );
                      })}
                    </div>
                  )}
                {selectedFeatureItem?.type === 'JUMPANDGO' ? (
                  <>
                    <div className="jumpAndGoList tsDatePic">
                      <ReactDatePicker
                        isClearable
                        maxDate={new Date()}
                        selected={selectedDateRange[0]}
                        placeholderText="Select Start Date"
                        dropdownMode="select"
                        dateFormat="MM-dd-yyyy HH:mm"
                        showTimeSelect
                        onChange={(date: any) => {
                          handleSelectDateRange([date, selectedDateRange[1]]);
                          setOpenKey('');
                        }}
                        className="dpicker dpickerWidth"
                        timeIntervals={5}
                      />
                    </div>
                    <div className="jumpAndGoList tsDatePic">
                      <ReactDatePicker
                        isClearable
                        maxDate={new Date()}
                        minDate={selectedDateRange[0]}
                        // minTime={selectedDateRange[0]}
                        // maxTime={new Date()}
                        selected={selectedDateRange[1]}
                        placeholderText="Select End Date"
                        dropdownMode="select"
                        dateFormat="MM-dd-yyyy HH:mm"
                        showTimeSelect
                        onChange={(date: any) => {
                          handleSelectDateRange([selectedDateRange[0], date]);
                          setOpenKey('');
                        }}
                        className="dpicker dpickerWidth"
                        timeIntervals={5}
                      />
                    </div>
                    <div className="selectTractor sTcr">
                      <Select
                        style={{ width: 146, marginRight: 10 }}
                        placeholder="Select Tractor"
                        value={jumpAndGoTractorId}
                        onSelect={(e: any) => {
                          setJumpAndGoTractorId(e);
                        }}
                      >
                        <>
                          <Option value="">All</Option>
                          {tractorsList &&
                            tractorsList.length > 0 &&
                            tractorsList.map((data: TractorsList) => (
                              <Option key={data.id} value={data.id}>
                                {data.name}
                              </Option>
                            ))}
                        </>
                      </Select>
                    </div>
                    <Col className="tContWp">
                      <Radio.Group>
                        <Menu mode={'inline'} openKeys={[openKey]}>
                          {filteredMenuItems &&
                            filteredMenuItems.map((jng: any) => {
                              return (
                                <div
                                  className="tBoxDtlCont"
                                  key={jng?.created_date_time}
                                  style={{
                                    border:
                                      jng && jng?.visible
                                        ? '1px solid #fc7e09'
                                        : '',
                                  }}
                                  onClick={() => {
                                    jng.visible = !jng.visible;
                                    if (jng.visible) {
                                      setRange(jng.range);
                                      setTimes(jng.times);
                                    } else {
                                      setRange(0);
                                      setTimes([0, 0]);
                                    }
                                    jng.showTimes = jng.times;

                                    setMapFeature(jng);
                                    setTrigger(!trigger);
                                  }}
                                >
                                  <p className="topter">
                                    <b className="tOTxt">Operator:</b>
                                    <span className="clrOrg">
                                      {jng?.first_name} {jng?.last_name}
                                    </span>
                                  </p>
                                  <p className="jngStime">
                                    <b className="tOTxt">Start Time:</b>
                                    <span className="clrOrg">
                                      {jng?.drive_action_start_date_time
                                        ? getDateTime(
                                            jng?.drive_action_start_date_time,
                                          )
                                        : '-'}
                                    </span>
                                  </p>
                                  <p className="jngStime">
                                    <b className="tOTxt">Finish Time:</b>
                                    <span className="clrOrg">
                                      {jng?.drive_action_finish_date_time
                                        ? getDateTime(
                                            jng?.drive_action_finish_date_time,
                                          )
                                        : '-'}
                                    </span>
                                  </p>
                                  <p className="jngStime mb20">
                                    <b className="tOTxt">Tractor Name:</b>
                                    <span className="clrOrg">
                                      {jng?.tractorName}
                                    </span>
                                  </p>
                                  {jng?.visible &&
                                    range > 0 &&
                                    mapFeature?.id === jng.id && (
                                      <div
                                        style={{ width: '100%' }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        <RangePicker
                                          format="hh:mm A"
                                          defaultValue={jng.times}
                                          value={times}
                                          onChange={(date: any) => {
                                            handleSelectDateRange1(jng, date);
                                          }}
                                        />
                                      </div>
                                    )}
                                </div>
                              );
                            })}
                        </Menu>
                      </Radio.Group>
                    </Col>
                    <AppLoader loader={loader} />
                  </>
                ) : null}
              </Card>
            </Layout>
          )}
        </Sider>
        <Layout className="site-layout mapMaker">
          <MapMakerMap
            mapFeature={mapFeature as MapFeature}
            setRoutesFeature={(data) => setRoutes(data)}
            setTravelledRoutesDataFeature={(data) =>
              setTravelledRoutesData(data)
            }
            setVineFeature={(data) => setVines(data)}
            setVineRowFeature={(data) => setVineRows(data)}
            setAbLineFeature={(data) => setAbLines(data)}
            setAbBlockFeature={(data) => setAbBlocks(data)}
            setVineBlockFeature={(data) => setVineBlocks(data)}
            setPolygonFeature={(data) => setPolygons(data)}
            setSuggestedPolygonFeature={(data) => setSuggestedPolygons(data)}
            setWaypointFeature={(data) => setWaypoints(data)}
            setJumpAndGoFeature={(data) => setJumpAndGosData(data)}
            setVineLaneFeature={(data) => setVineLaneData(data)}
            trigger={trigger}
            toggleMapFeature={selectedMapFeature}
            toggleFullScreen={toggleFullScreen}
            toggleLayer={toggleLayer as ToggleLayer}
            handleToggleLayer={(e, type, index) =>
              handleToggleLayer(e, index, type)
            }
            rightSideMenuCollapsed={collapsed}
            vineblockId={vineblockId as string}
            setSliderVineBlocks={(vBlocks) => setvineblocksFromMap(vBlocks)}
            selectedDateRange={selectedDateRange as Date[]}
            jumpAndGoTractorId={jumpAndGoTractorId}
            setLoader={setLoader}
            publishedTimeToReload={publishedTimeToReload}
            reloadVineBlocks={reloadVineBlocks}
            deleteRouteId={deleteRouteId as string}
          />
        </Layout>
      </Layout>
    </FullScreen>
  );
};

export default MapMakerBeta;
