import { SET_EMP_REFRESH, SET_TITLE } from './actions';

interface InitialStateType {
  title: string;
  empRefresh: boolean;
}
interface Action {
  type: string;
  payload: any;
}

export const initialState: InitialStateType = {
  title: 'AppStore',
  empRefresh: false,
};

export default function reducer(state: InitialStateType, action: Action) {
  const { type, payload } = action;
  switch (type) {
    case SET_TITLE:
      return { ...state, title: 'Change AppStore State' };
    case SET_EMP_REFRESH:
      return { ...state, empRefresh: !state.empRefresh };
    default:
      throw new Error();
  }
}
