import React from 'react';
import Layout from 'antd/lib/layout/layout';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import LiveMap from './LiveMap';
import './map-style.css';
import './style.css';
import { DriveAction } from '../../constants/types';
interface Props {
  driveAction: DriveAction;
  setDriveAction: any;
}
const MapWrapper: React.FC<Props> = ({ driveAction, setDriveAction }) => {
  // const handle = useFullScreenHandle();
  // const toggleFullScreen = () => {
  //   if (handle.active) {
  //     handle.exit();
  //   } else {
  //     handle.enter();
  //   }
  // };
  return (
    //<FullScreen handle={handle}>
    <Layout>
      <Layout className="site-layout posRel">
        <LiveMap
          driveAction={driveAction}
          setDriveAction={setDriveAction}
          //toggleFullScreen={toggleFullScreen}
        />
      </Layout>
    </Layout>
    //</FullScreen>
  );
};

export default MapWrapper;
