import { Select } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { getDateAndTime, getHour } from '../../constants/Common';
const { Option } = Select;

type Props = {
  jumpAndGos: any[];
  handleOnSelect: (value: any) => void;
  selectedJAG: any;
  setShowJNGModel: (state: boolean) => void;
  loadAll: boolean;
};

export const OperationsDropDown: React.FC<Props> = ({
  jumpAndGos,
  handleOnSelect,
  selectedJAG,
  setShowJNGModel,
  loadAll,
}) => {
  const mixpanel = useMixpanel();
  const validHour = (timestamp: any) => {
    const hour = getHour(timestamp);
    return hour === 'Invalid date' ? '  ' : hour;
  };
  const jAGHMTLView = (jng: any) =>
    jng.report_status === 'COMPLETED' ? (
      <div
        className="operation-record operationsInrAccordion"
        key={jng?.created_date_time}
        style={{ background: '#FFF' }}
      >
        <p>
          <span className="operationTitle">Operator: </span>
          <span>
            {jng?.operator.first_name} {jng?.operator.last_name}
          </span>
        </p>
        <p>
          <span className="operationTitle">Start Time: </span>
          <span>{getDateAndTime(jng?.drive_action_start_date_time)}</span>
        </p>
        <p>
          <span className="operationTitle">Finish Time: </span>
          <span>{getDateAndTime(jng?.drive_action_finish_date_time)}</span>
        </p>
        <p className="mb20">
          <span className="operationTitle">Tractor Name: </span>
          <span>{jng?.tractor?.name}</span>
        </p>
        <h5
          className="moreBtn"
          onClick={() => {
            mixpanel.track('Analytics Map', {
              event: `Jump & Go More Details Clicked`,
            });
            setShowJNGModel(true);
          }}
        >
          MORE
        </h5>
      </div>
    ) : (
      <div className="emptyPanelBlock" key={jng.id}>
        {jng?.tractor?.name +
          ' ' +
          validHour(jng?.drive_action_start_date_time) +
          ' - ' +
          validHour(jng?.drive_action_finish_date_time)}
      </div>
    );

  return (
    <>
      {jumpAndGos && jumpAndGos.length > 0 && (
        <div className="selectTractor sOps">
          <Select
            style={{ width: 146, marginRight: 10 }}
            placeholder="Select JumpAndGo"
            value={loadAll ? '' : selectedJAG?.id}
            onSelect={handleOnSelect}
          >
            <Option value="">All</Option>
            {jumpAndGos
              .filter((data: any) => data.drive_action_finish_date_time)
              .map((jng: any) => (
                <Option
                  key={jng.id}
                  value={jng.id}
                  //disabled={jng.report_status !== 'COMPLETED'}
                >
                  {jng?.tractor?.name +
                    ' ' +
                    validHour(jng?.drive_action_start_date_time) +
                    ' - ' +
                    validHour(jng?.drive_action_finish_date_time) +
                    ' | ' +
                    moment(jng?.drive_action_start_date_time).format('MMM Do')}
                </Option>
              ))}
          </Select>
          {selectedJAG && jAGHMTLView(selectedJAG)}
        </div>
      )}
    </>
  );
};
