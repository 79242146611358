import React from 'react';

interface Props {
  allDayOperation: any;
}

const AnalyticStats: React.FC<Props> = ({ allDayOperation }) => {
  return (
    <div className="sprayoperhrs">
      <div className="operationalHours mb5">
        <div className="totalHours">Covered Acres</div>
        <div className="hrMinTxt">
          {allDayOperation?.analytics?.area_covered_in_acre
            ? `${Number(allDayOperation?.analytics?.area_covered_in_acre)
                .toFixed(2)
                .toLocaleString()} Acres`
            : '-'}
        </div>
      </div>

      <div className="operationalHours mb5">
        <div className="totalHours">Covered Vine Rows</div>
        <div className="hrMinTxt">
          {allDayOperation?.analytics?.num_of_vine_rows_covered
            ? `${Number(
                allDayOperation?.analytics?.num_of_vine_rows_covered,
              ).toFixed(0)}`
            : '-'}
        </div>
      </div>

      <div className="operationalHours mb5">
        <div className="totalHours">Acres per hour</div>
        <div className="hrMinTxt">
          {allDayOperation?.analytics?.acres_per_hours
            ? `${Number(allDayOperation?.analytics?.acres_per_hours).toFixed(
                2,
              )}`
            : '-'}
        </div>
      </div>

      <div className="operationalHours mb5">
        <div className="totalHours">Coverage</div>
        <div className="hrMinTxt">
          {allDayOperation?.analytics?.distance
            ? `${Number(allDayOperation?.analytics?.distance)
                .toFixed(2)
                .toLocaleString()} Miles`
            : '-'}
        </div>
      </div>
      <div className="operationalHours mb5">
        <div className="totalHours">Avg Speed</div>
        <div className="hrMinTxt">
          {allDayOperation?.analytics?.avg_speed_in_miles
            ? `${Number(
                Number(allDayOperation?.analytics?.avg_speed_in_miles),
              ).toFixed(1)} Mph`
            : '-'}
        </div>
      </div>

      <div className="line" />

      <div className="operationalHours mb5">
        <div className="totalHours">Operational Savings</div>
        <div className="hrMinTxt">
          {allDayOperation?.analytics?.operational_savings
            ? `$${Number(
                allDayOperation?.analytics?.operational_savings,
              ).toFixed(2)}`
            : '-'}
        </div>
      </div>

      <div className="operationalHours mb5">
        <div className="totalHours">
          CO<sub>2</sub> Savings
        </div>
        <div className="hrMinTxt">
          {allDayOperation?.analytics?.aggregate_emission_reduction
            ? `${Number(
                allDayOperation?.analytics?.aggregate_emission_reduction,
              ).toFixed(2)} kg`
            : '-'}
        </div>
      </div>

      <div className="operationalHours mb5">
        <div className="totalHours">Energy Used</div>
        <div className="hrMinTxt">
          {allDayOperation?.analytics?.soc_used
            ? `${allDayOperation?.analytics?.soc_used}%`
            : '-'}{' '}
          SOC
          {/* <br /> */} (
          <span>
            {allDayOperation?.analytics?.energy
              ? `${Number(allDayOperation?.analytics?.energy)
                  .toFixed(2)
                  .toLocaleString()} kwh`
              : '-'}
          </span>
          )
        </div>
      </div>
      <div className="analyticsHover">
        <div className="line" />

        <div className="operationalHours mb5">
          <div className="totalHours">Electricity Rate</div>
          <div className="hrMinTxt">
            {allDayOperation?.analytics?.electricity_rate
              ? `$${Number(
                  allDayOperation?.analytics?.electricity_rate,
                ).toFixed(2)}`
              : '-'}{' '}
            / Kwh
          </div>
        </div>
        <div className="operationalHours mb5">
          <div className="totalHours">Diesel Rate</div>
          <div className="hrMinTxt">
            {allDayOperation?.analytics?.electricity_rate
              ? `$${Number(allDayOperation?.analytics?.diesel_cost).toFixed(2)}`
              : '-'}{' '}
            / Gallon
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticStats;
