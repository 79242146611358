/* eslint-disable no-console */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { memo, useContext, useEffect, useRef, useState } from 'react';

import {
  addRemoteAVCommand,
  getPolygonsData,
  getPreviewPath,
  getRoutePoints,
  getTractorTraveledPointsBasedOnPlanner,
  getTractorTraveledPointsGuardRails,
  getTractorTraveledPointsIndicators,
  getVineBlockName,
  getVineLanesData,
} from '../../constants/Api';
import {
  batteryValue,
  calPtsDistanceActualAllow,
  checkAutoDriveMonitorErrorCodes,
  checkTractorConnectedState,
  commands,
  convertXyToLatLang,
  displayRecenterViewPortSetUp,
  drawPoly,
  getCenterStyle,
  getDateTime,
  getDateTimes,
  getFleetUsagePointsInfo,
  getInitials,
  getLatitudeAndLongitude,
  getNameFromList,
  getOperationNewStatus,
  getPathBorderColor,
  getPathColor,
  getPathFeatureStyle,
  getRAVXYCoordinates,
  getStatusForTractor,
  getTractorIcon,
  getVineRowStyle,
  IndicatorStyle,
  isGoodLocalizationInJumpAndGo,
  isHaveErrors,
  isLowLocalization,
  isSelectedTractor,
  pathColorConstants,
  pathConstants,
  pointExistInPolygons,
  polygonHoverLabel,
  priority,
  setBadLocalization,
  sliceIntoChunks,
  styleFun,
  togglePathStyle,
  togglePolygonStyle,
  toggleVineRowStyle,
  tractorClusterStyle,
  tractorConnected,
  tractorConstantsNew,
  TractorIconContsant,
  vineRowHoverStyle,
  wayPointClusterHoverStyle,
  wayPointHoverStyle,
} from '../../constants/Common';
import {
  GuardRailType,
  MapFeature,
  MosEventsResp,
  SetUpKeys,
  TractorHeartBeat,
} from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';

import { Button, notification, Radio } from 'antd';

import { Collection, Feature, Overlay, View } from 'ol';
import { ScaleLine } from 'ol/control';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import OLMap from 'ol/Map';
import * as proj from 'ol/proj';
import Cluster from 'ol/source/Cluster';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

import { Coordinate } from 'ol/coordinate';
import BaseStationIcon from '../../assets/images/basestation_green.png';

import goToPoint from '../../assets/images/go_to_pointG.png';
import guardRails from '../../assets/images/guardRails.svg';
import guardRailsCluster from '../../assets/images/guardRailsCluster.svg';
import human_pt from '../../assets/images/human_pt.svg';
import SnapIcon from '../../assets/images/m_sshot.svg';
import path_pt from '../../assets/images/path_pt.svg';
import pto_pt from '../../assets/images/pto_pt.svg';
import dataLayerIcon from '../../assets/images/tdtl_icon.svg';

import { bearing as bearingMethod, distance, lineArc, point } from '@turf/turf';
import { PubSub } from 'aws-amplify';
import { MqttClient } from 'mqtt';
import GeoJSON from 'ol/format/GeoJSON';
import LineString from 'ol/geom/LineString';
import MultiPoint from 'ol/geom/MultiPoint';
import Polygon from 'ol/geom/Polygon';
import Modify from 'ol/interaction/Modify';
import { useMixpanel } from 'react-mixpanel-browser';
import { useLocation } from 'react-router-dom';
import {
  AUTO_DRIVE_ERROR_TOPIC,
  AUTO_DRIVE_INTERRUPT_MESSAGE_CATEGORY,
  AUTO_DRIVE_MONITOR_STATUS,
  AUTO_LOGOUT_CODE,
  BadLocalization,
  CLEAR_COMMAND,
  defaultSetUpKeys,
  getDriveActionUuid,
  heartBeatTopic,
  INTERNAL_MESSAGE,
  MOS_EVENTS_TOPIC,
  PLANNING_MANAGER_REMOTE_AV_EXECUTION_FBK,
  SystemError2,
  TRACTOR_STATUS,
} from '../../constants/constant';
import {
  LOAD_REMOTE_DRIVE_TRACTORS,
  SET_POLYGONS_LIST,
  SET_TRACTOR_IMPLEMENT_STATE,
} from '../../context/actions';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import applicationIds from '../../locale/applicationIds.json';
import { getClient } from '../../util/MqttConnection';
import AppLoader from '../common/AppLoader';
import { getSeverity, getTags } from '../fleethealth/Diagnostics';
import DiagnosticWidget from '../fleethealth/DiagnosticWidget';
import TicketWidget from '../fleethealth/TicketWidget';
import { RoutineNewDesign } from '../livecommand/RoutineNewDesign';
import {
  AUTO_DRIVE_ERROR,
  AUTO_DRIVE_LOGOUT_ERROR_CODES,
  AUTO_DRIVE_MONITOR_ERROR_CODES,
  SELECTED_ITEM_NAME,
  SELECTED_ROUTINE,
  SELECTED_TRACTORS,
  SELECTED_VINE_BLOCK,
  SET_ADD_STEP,
  SET_AUTO_DRIVE_FEEDBACK,
  SET_AUTO_DRIVE_INTERRUPT_MESSAGE,
  SET_AUTO_DRIVE_MONITOR_POP,
  SET_AUTO_DRIVE_SETTINGS,
  SET_AUTO_DRIVE_SOFT_INTERRUPT_MESSAGE,
  SET_AUTO_VALUE,
  SET_COMMAND_TYPE,
  SET_RIGHT_SIDE_TOGGLE,
  SET_SELECTED_TRACTOR_LOCATION,
  SET_SETUP_KEYS,
  SET_SHOW_CONNECT_LOADER,
  SET_SHOW_PRESENT_COMMAND,
  SET_SHOW_REMOTE_DRIVE_DETAILS,
  SET_SHOW_ROUTINE,
  SET_SHOW_SNAPSHOT,
  SHOW_SAVE_COMMAND,
  SHOW_TRACTOR_START,
} from '../remote_drive_new/actions';
import AutoDriveLogoutErrorPopup from '../remote_drive_new/AutoDriveLogoutErrorPopup';
import DeadManHandle from '../remote_drive_new/DeadManHandle';
import Errorcodepopup from '../remote_drive_new/Errorcodepopup';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import SnapshotView from '../snapshot/SnapshotView';
import DiagnosticsModal from './DiagnosticsModal';
import LiveMapFleetList from './LiveMapFleetList';
import MapIcons from './MapIcons';
import MapLayers from './MapLayers';
import { getVineRows } from './VineRows';
import { getWaypoints } from './Waypoint';

// for global variables set default value on page pop
// global variables start here
let toggleForIndicator = false; // to show hide indictor pop
let allTractorsList: MapFeature[] = [];
let globalBase: [number, number] = [0, 0];
let allowLiveStream = false;
let globalSelectedTractorId = 0;
let globalShowPath = true;
let globalShowDataLayers = true;
let globalToggleSatellite = true;
let globalZoom = 0;
let globalSelectedTractors: any = null;
export const onGlobalZoomToShowZoomIcons = 19;
let preCommandGlobal = '';
let commandForSecondTime = '';
const types = { POLYGON: 'Polygon', VINELANEHEAD: 'VINELANEHEAD' };

let setUpKeysGlobal: SetUpKeys = JSON.parse(JSON.stringify(defaultSetUpKeys));
let pointCoordinateGlobalForApiCall: Coordinate = [];
let pointCoordinateGlobalForDrawPoint: Coordinate = [];

let apiCall = false;
let angleDegrees = 0;
let goToPointMove = false;
let goToPointFeature: any = null;
const tractorsMap: Map<number, any> = new Map();
let globalShowPresentCommand = '';
let vineBlocksGlobal: any[] = [];
let selectedItemNameGlobal = '';
let globalAddStep = false;
let globalShowBadLocalization = true;
let globalAutoDriveMonitorErrorCodes: any = null;
let globalShowTractorStart: any = {};
let globalSelectedDriveActionUuid = '';
let toggleForSideWindow = false;
let globalTractorList: any[] = [];
let globalTractorClickWait = false; // added this for wait two tractors click at a time
let globalShowRoutine = false;

const LiveMap: React.FC = memo(() => {
  const mixpanel = useMixpanel();
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const {
    setwaypointsForRemoteAv,
    goToPointCoordinates,
    setGreenPolygons,
    goToRowFollowPolygon,
    setGoToPointCoordinates,
    setGoToRowFollowPolygon,
    RDReducer,
    setPointCordinate,
    pointCordinate,
    guardRailsCheck,
    commandsList,
    activeIds,
    iconsFlag,
    clearAllLocalDBCommands,
    saveCommandTOLocalDB,
    addRVRoutineCommand,
    startAutoDriveIteration,
    selectedTractorsIds,
  } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const [appState, appDispatch] = APPReducer;
  const { tractors, tractorsList, groundZero, polygonsList } = appState;
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  const { tractorImplementState, remoteAvTractors } = autoDriveState;
  const {
    autoDrive,
    autoDriveSettings,
    setUpKeys,
    selectedTractor,
    selectedImplementName,
    selectedItemName,
    selectedVineBlockName,
    rightSideToggle,
    showRemoteDriveDetails,
    showSaveCommand,
    commandType,
    showPresentCommand,
    addStep,
    selectedRoutine,
    autoDriveMonitorErrorCodes,
    showTractorStart,
    snapShotInitiated,
    showSnapshot,
    autoDriveLogoutErrorCodes,
    showRoutine,
    autoDriveMonitorPop,
    selectedTractorLocation,
    selectedTractors,
  } = state;
  const [mapInitialized, setMapInitialized] = useState(false);
  const [base, setBase] = useState<[number, number]>([0, 0]);
  const [toggleSatellite, setToggleSatellite] = useState(true);
  const [fleet, setFleet] = useState(true);
  const [showTractors, setShowTractors] = useState(true);
  const [showBasestations, setShowBasestations] = useState(true);
  const [polygons, setPolygons] = useState<MapFeature[]>([]);
  const [tractorsInitialized, setTractorsInitialized] = useState(false);
  const [heartBeat, setHeartBeat] = useState<TractorHeartBeat>();
  const [selectedTractorId, setSelectedTractorId] = useState<number>(0);
  const [selectedTractorFromFleetId, setSelectedTractorFromFleetId] =
    useState<number>(0);
  const [redirectToggle, setRedirectToggle] = useState(false);
  const [errorDetails, setErrorDetails] = useState<any>();
  const [showIndicatorModel, setShowIndicatorModel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [ticketDetails, setTicketDetails] = useState(null);
  const [toggleTicketWidget, setToggleTicketWidget] = useState<any>(false);
  const [tractorId, setTractorId] = useState<any>(0);
  const [showWaypoints, setShowWaypoints] = useState(true);
  const [showGreenPolygons, setShowGreenPolygons] = useState(true);
  const [showWhitePolygons, setShowWhitePolygons] = useState(true);
  const [showGrayPolygons, setShowGrayPolygons] = useState(true);
  const [showBluePolygons, setShowBluePolygons] = useState(true);
  const [showBrownPolygons, setShowBrownPolygons] = useState(true);
  const [showDataLayers, setShowDataLayers] = useState<boolean>(true);
  const [showPath, setShowPath] = useState<boolean>(true);
  const [farm, setFarm] = useState(true);
  const [mapSnapshots, setMapSnapshots] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [speed] = useState<number>(3.5);
  const [leftVineRowName, setLeftVineRowName] = useState('');
  const [rightVineRowName, setRightVineRowName] = useState('');
  const [preCommandState, setPreCommandState] = useState('');
  const [showBadLocalization, setShowBadLocalization] = useState(true);
  const mapElement: any = useRef();
  const container: any = useRef();
  const content: any = useRef();
  const tractorClusterSource: any = useRef();
  const mapRef = useRef<OLMap | null>(null);
  const vectorTileLayerRef = useRef<TileLayer<any>>();
  const satelliteTileLayerRef = useRef<TileLayer<any>>();
  const initialLayer = useRef<VectorLayer<any>>();
  const tractorPathLayerRef = useRef<VectorLayer<any>>();
  const tractorLayerRef = useRef<VectorLayer<any>>();
  const waypointsLayerRef = useRef<VectorLayer<any>>();
  const implementsLayerRef = useRef<VectorLayer<any>>();
  const vineRowsLayerRef = useRef<VectorLayer<any>>();
  const vineBlocksLayerRef = useRef<VectorLayer<any>>();
  const pathLayerRef = useRef<VectorLayer<any>>();
  const tractorPaths = useRef<Map<string, [number, number][]>>();
  const tractorTraveledPathLayerRef = useRef<VectorLayer<any>>();
  const planerTractorPathLayerRef = useRef<VectorLayer<any>>();
  const plannerTractorPaths = useRef<Map<string, any>>();
  const mqttClientRef = useRef<MqttClient>();
  const fieldFeatureItem = useRef<any>();
  const mapIndicatorRefs = useRef<VectorLayer<any>>();
  const mapGuardRailsRefs = useRef<VectorLayer<any>>();
  const tractorsIndicatorMap = useRef<Map<string, GuardRailType[]>>();
  const tractorsGuardrailMap = useRef<Map<string, GuardRailType[]>>();
  const mapGuardRailsFromStreamRefs = useRef<VectorLayer<any>>();
  const mapIndicatorFromStreamRefs = useRef<VectorLayer<any>>();
  const [recenterTabId, setRecenterTabId] = useState<number>(0);
  const [showSnapShotView, setShowSnapShotView] = useState(false);
  const [snapshotContent, setSnapshotContent] = useState();
  const hoverPointLayerRef = useRef<VectorLayer<any>>();
  const greenPolygonsLayerRef = useRef<VectorLayer<any>>();
  const whitePolygonsLayerRef = useRef<VectorLayer<any>>();
  const grayPolygonsLayerRef = useRef<VectorLayer<any>>();
  const brownPolygonsLayerRef = useRef<VectorLayer<any>>();
  const bluePolygonsLayerRef = useRef<VectorLayer<any>>();
  const pointLayerRef = useRef<VectorLayer<any>>();
  const commandLayerRef = useRef<VectorLayer<any>>();
  const vineLaneLayerRef = useRef<VectorLayer<any>>();
  const [mapLayersToggle, setMapLayersToggle] = useState(false);

  const { state: lState } = useLocation();
  const tractorObject = lState as {
    tractorId: any | undefined;
  };

  useEffect(() => {
    return () => {
      toggleForIndicator = false; // to show hide indictor pop
      allTractorsList = [];
      globalBase = [0, 0];
      allowLiveStream = false;
      globalSelectedTractorId = 0;
      globalShowPath = true;
      globalShowDataLayers = true;
      globalToggleSatellite = true;
      globalZoom = 0;
      globalSelectedTractors = null;
      preCommandGlobal = '';
      commandForSecondTime = '';
      setUpKeysGlobal = JSON.parse(JSON.stringify(defaultSetUpKeys));
      pointCoordinateGlobalForApiCall = [];
      apiCall = false;
      angleDegrees = 0;
      goToPointMove = false;
      goToPointFeature = null;
      globalShowPresentCommand = '';
      vineBlocksGlobal = [];
      selectedItemNameGlobal = '';
      globalAddStep = false;
      globalAutoDriveMonitorErrorCodes = null;
      globalShowTractorStart = {};
      globalSelectedDriveActionUuid = '';
      toggleForSideWindow = false;
      globalTractorList = [];
      globalTractorClickWait = false;
      globalShowRoutine = false;
    };
  }, []);
  // to show live tractor position routine preview path popup
  useEffect(() => {
    globalShowRoutine = showRoutine;
  }, [showRoutine]);

  useEffect(() => {
    globalTractorList = tractorsList;
  }, [tractorsList]);

  useEffect(() => {
    snapShotInitiated &&
      globalSelectedTractorId &&
      tractorConnected(selectedTractor?.heartbeat, userDetails.id) &&
      getGuardRails(Number(globalSelectedTractorId));
  }, [snapShotInitiated]);

  useEffect(() => {
    toggleForSideWindow = showSnapShotView;
  }, [showSnapShotView]);

  useEffect(() => {
    globalSelectedDriveActionUuid = selectedTractor?.heartbeat
      ?.drive_action_details?.current_drive_action
      ? selectedTractor?.heartbeat?.drive_action_details.current_drive_action
      : '';
  }, [selectedTractor]);

  useEffect(() => {
    globalAddStep = addStep;
    // when go point / block enable we hiding vine row
    addStep && handleToggleLayer('VINEROW', false);
  }, [addStep]);

  useEffect(() => {
    globalShowTractorStart = showTractorStart;
  }, [showTractorStart]);

  useEffect(() => {
    globalAutoDriveMonitorErrorCodes = autoDriveMonitorErrorCodes;
  }, [autoDriveMonitorErrorCodes]);

  useEffect(() => {
    if (mapInitialized && tractorObject && tractorObject.tractorId > 0) {
      setSelectedTractorId(Number(tractorObject.tractorId));
      setSelectedTractorFromFleetId(Number(tractorObject.tractorId));
      setRedirectToggle(true);
    }
  }, [tractorObject, mapInitialized]);
  useEffect(() => {
    if (userDetails && userDetails.organization && groundZero) {
      initializeMap();
    }
  }, [userDetails, groundZero]);
  //  mapInitialization start here
  const initializeMap = async () => {
    try {
      // const { organization } = userDetails;
      // const { latitude, longitude } = await getGroundZero(
      //   organization.api_url,
      //   organization.farm.id,
      // );
      const { latitude, longitude } = groundZero;
      globalBase = [latitude, longitude];
      setBase([latitude, longitude]);

      const baseLocation = new Feature({
        geometry: new Point(proj.fromLonLat([longitude, latitude])),
      });
      baseLocation.set('name', '');
      //  set id 1 for base station recenter
      baseLocation.setId(1);
      const style = new Style({
        image: new Icon({
          src: BaseStationIcon,
          scale: 0.4,
        }),
        zIndex: 100,
      });
      baseLocation.setStyle(style);
      const initialFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [baseLocation],
        }),
        zIndex: priority.BASESTATION,
      });

      const vectorLayer = new TileLayer({
        source: new OSM(),
        visible: false,
      });

      const satelliteLayer = new TileLayer({
        source: new XYZ({
          url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga',
          cacheSize: 1000,
        }),
        visible: true,
      });

      const initialPathLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: false,
        zIndex: priority.PREVIEWPATH,
      });
      const pointLocation: Feature<any> = new Feature();
      pointLocation.setId(999);
      const selectPointFeatureLayer = new VectorLayer({
        source: new VectorSource({
          features: [pointLocation],
        }),
        visible: true,
        style: new Style({}),
        zIndex: 111,
      });

      const greenPolygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.POLYGON.green,
      });

      const whitePolygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.POLYGON.white,
      });

      const grayPolygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.POLYGON.gray,
      });
      const brownPolygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.POLYGON.brown,
      });
      const bluePolygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.POLYGON.blue,
      });

      // create and add vector source layer
      const initialPointLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.ROUTINE,
      });
      const initialCommandPointLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: Infinity,
      });

      const rowFollowFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        style: getVineRowStyle(),
        // style: new Style({
        //   stroke: new Stroke({
        //     color: '#92B799',
        //     width: 5,
        //   }),
        // }),
      });

      const guardRailsSource = new VectorSource({
        features: [],
      });
      const GuardRailsClusterSource = new Cluster({
        distance: 10,
        source: guardRailsSource,
      });
      const guardRailsLayer = new VectorLayer({
        source: GuardRailsClusterSource,
        visible: globalShowDataLayers,
        zIndex: priority.SNAP,
        style: function (feature: any) {
          return styleFun(feature, guardRailsCluster);
        },
      });

      // create map
      const initialMap = new OLMap({
        target: mapElement.current,
        layers: [
          satelliteLayer,
          vectorLayer,
          initialFeaturesLayer,
          initialPathLayer,
          selectPointFeatureLayer,
          greenPolygonsFeaturesLayer,
          whitePolygonsFeaturesLayer,
          grayPolygonsFeaturesLayer,
          bluePolygonsFeaturesLayer,
          initialPointLayer,
          rowFollowFeaturesLayer,
          initialCommandPointLayer,
          guardRailsLayer,
          brownPolygonsFeaturesLayer,
        ],
        controls: [scaleControl()],
        view: new View({
          projection: 'EPSG:3857',
          center: proj.transform(
            [longitude, latitude],
            'EPSG:4326',
            'EPSG:3857',
          ),
          zoom: 17,
          maxZoom: 23,
        }),
      });
      // set flag for map initialization
      setMapInitialized(true);
      mapRef.current = initialMap;
      initialLayer.current = initialFeaturesLayer;
      pointLayerRef.current = initialPointLayer;
      commandLayerRef.current = initialCommandPointLayer;

      // initialize satellite layer
      satelliteTileLayerRef.current = satelliteLayer;
      // initialize vector layer
      vectorTileLayerRef.current = vectorLayer;

      pathLayerRef.current = initialPathLayer;
      // initialize point selection layer
      hoverPointLayerRef.current = selectPointFeatureLayer;
      // polygon layers
      greenPolygonsLayerRef.current = greenPolygonsFeaturesLayer;
      whitePolygonsLayerRef.current = whitePolygonsFeaturesLayer;
      grayPolygonsLayerRef.current = grayPolygonsFeaturesLayer;
      brownPolygonsLayerRef.current = brownPolygonsFeaturesLayer;
      bluePolygonsLayerRef.current = bluePolygonsFeaturesLayer;
      // vine lane layer
      vineLaneLayerRef.current = rowFollowFeaturesLayer;
      mapGuardRailsRefs.current = guardRailsLayer;
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };
  //  mapInitialization ends here

  const scaleControl = () => {
    const control = new ScaleLine({
      units: 'metric',
      className: 'positionMap',
    });
    return control;
  };
  // to change road map and satellite map
  useEffect(() => {
    vectorTileLayerRef.current?.setVisible(!toggleSatellite);
    satelliteTileLayerRef.current?.setVisible(toggleSatellite);
    globalToggleSatellite = toggleSatellite;

    if (whitePolygonsLayerRef.current)
      togglePolygonStyle(whitePolygonsLayerRef.current, toggleSatellite);

    if (greenPolygonsLayerRef.current)
      togglePolygonStyle(greenPolygonsLayerRef.current, toggleSatellite);

    if (bluePolygonsLayerRef.current)
      togglePolygonStyle(bluePolygonsLayerRef.current, toggleSatellite);

    if (grayPolygonsLayerRef.current)
      togglePolygonStyle(grayPolygonsLayerRef.current, toggleSatellite);

    if (vineRowsLayerRef.current)
      toggleVineRowStyle(
        vineRowsLayerRef.current,
        toggleSatellite,
        globalZoom >= onGlobalZoomToShowZoomIcons,
      );
  }, [toggleSatellite]);
  /**
   * Zoom In Zoom Out and Recenter
   */
  const handleZoom = (zoomType: string) => {
    if (mapRef.current) {
      const view = mapRef.current.getView();
      const zoom = view.getZoom();

      if (zoom) {
        if (zoomType === 'zoomIn') {
          view.setZoom(zoom + 1);
          mixpanel.track('Live Map', {
            event: `Map Zoom In ${zoom + 1}`,
          });
        } else {
          view.setZoom(zoom - 1);
          mixpanel.track('Live Map', {
            event: `Map Zoom Out ${zoom - 1}`,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (mapInitialized && tractors && tractors.length > 0) {
      getTractors();
      if (mapRef.current) moveEndHandler(mapRef.current);
    }
  }, [userDetails, mapInitialized, tractors]);

  const moveEndHandler = (map: any) => {
    let currZoom = map?.getView().getZoom();
    map &&
      map.on('moveend', function (e: any) {
        const newZoom = map?.getView().getZoom();
        if (newZoom > 15) {
          tractorClusterSource.current.setDistance(0);
        } else {
          tractorClusterSource.current.setDistance(10);
        }
        if (currZoom != newZoom) {
          currZoom = newZoom;
          globalZoom = newZoom;
          handleLayerVisible(newZoom);
          selectedOnTractor();
        }
      });
  };

  const getFleetUsagePointsPathInfo = (data: any) => {
    const planner_name =
      data && data.planner_name && getStatusForTractor(data.planner_name)
        ? getStatusForTractor(data.planner_name)
        : '';
    let dataStr = '';
    dataStr += '<p>Tractor : <span>' + data?.tractorName + '</span></p>';
    dataStr += '<p>Operator : <span>' + data?.operatorName + '</span></p>';
    dataStr += '<p>Mode : <span>' + planner_name + '</span></p>';
    dataStr +=
      '<p>  Speed : <span>' +
      (data.avg_speed
        ? Number(Number(data.avg_speed) * 2.237).toFixed(1) // 0.621371
        : '0') +
      ' Mph</span></p>';
    dataStr +=
      '<p> Time : <span>' +
      (data.created_date_time ? getDateTime(data.created_date_time) : '') +
      '</span></p>';
    return dataStr;
  };

  const getTractors = async () => {
    try {
      // const { organization } = userDetails;
      // const { records } = await getAllMapTractors(
      // const { records } = await getTractorsListData(
      //   organization.api_url,
      //   organization.id,
      //   1,
      //   true,
      // );
      const records = tractors && tractors.length > 0 ? tractors : [];
      const tractorFeatures: Feature<any>[] = [];
      const tractorList: MapFeature[] = [];
      records.map((tractor: any) => {
        const {
          known_latitude,
          known_longitude,
          connection,
          known_bearing,
          heartbeat: hb,
        } = tractor;
        const id = `PoseModel_streaming_${tractor.id}`;

        const klong =
          known_longitude *
          Number(`1.000000${Math.floor(Math.random() * 9 + 1)}`);

        const kLat =
          known_latitude *
          Number(`1.000000${Math.floor(Math.random() * 9 + 1)}`);

        const tractorLocation = new Feature({
          geometry: new Point(proj.fromLonLat([klong, kLat])),
        });
        tractorLocation.setId(id);
        // remoteTopics.push(`remoteav_preview_${tractor.id}`);
        tractorLocation.set('name', tractor.name);
        tractorLocation.set('latLang', [klong, kLat]);
        const icon =
          connection === 'online'
            ? TractorIconContsant.ONLINE
            : TractorIconContsant.OFFLINE;
        const batValue = batteryValue(tractor);
        // only online tractor have faulty icon
        const isFaulty =
          connection === 'online' &&
          getOperationNewStatus(hb) === tractorConstantsNew.faulty.key
            ? true
            : false;
        const style = getTractorIcon(
          icon,
          false,
          known_bearing,
          '',
          false,
          false,
          false,
          batValue,
          false,
          tractor.name,
          false,
          tractor.id,
          isFaulty,
        );
        const hoverStyle = getTractorIcon(
          icon,
          true,
          known_bearing,
          '',
          false,
          false,
          false,
          batValue,
          false,
          tractor.name,
          false,
          tractor.id,
          isFaulty,
        );
        const onZoomStyle = getTractorIcon(
          icon,
          false,
          known_bearing,
          '',
          false,
          false,
          true,
          batValue,
          false,
          tractor.name,
          false,
          tractor.id,
          isFaulty,
        );
        const onZoomHoverStyle = getTractorIcon(
          icon,
          true,
          known_bearing,
          '',
          false,
          false,
          true,
          batValue,
          false,
          tractor.name,
          false,
          tractor.id,
          isFaulty,
        );
        tractorLocation.set('pointType', 'TRACTOR');
        tractorLocation.set('style', style);
        tractorLocation.set('hoverStyle', hoverStyle);
        tractorLocation.set('onZoomStyle', onZoomStyle);
        tractorLocation.set('onZoomHoverStyle', onZoomHoverStyle);
        tractorLocation.set('pointTypeSelected', icon);
        tractorLocation.set('pointTypeBearing', known_bearing);
        tractorLocation.set('pointTypeIni', '');
        tractorLocation.set('pointTypeBattery', batValue);
        tractorLocation.set('isFaulty', isFaulty);
        tractorLocation.setStyle(style);
        tractorFeatures.push(tractorLocation);
        tractorList.push({
          id: tractor.id,
          name: tractor.name,
          baseStationName: tractor?.basestation?.name,
          type: 'TRACTOR',
          mapId: id,
          visible: true,
          style,
        });
      });
      const source = new VectorSource({
        features: tractorFeatures,
      });
      tractorClusterSource.current = new Cluster({
        distance: 10,
        source: source,
      });
      allTractorsList = tractorList;

      const tractorFeaturesLayer = new VectorLayer({
        source: tractorClusterSource.current,
        zIndex: priority.TRACTOR,
        style: function (feature: any) {
          const size = feature.get('features').length;
          let style = new Style({});
          if (size === 1) {
            const feat = feature.get('features');
            style =
              globalZoom > onGlobalZoomToShowZoomIcons
                ? feat[0].get('onZoomStyle')
                : feat[0].get('style');
            feature.set('name', feat[0].get('name'));
            feature.set('pointType', feat[0].get('pointType'));
          }
          if (size > 1) {
            const feat = feature.get('features');
            let names = '';
            feat.map((fe: any, index: number) => {
              names = `${names} ${fe.get('name')} ${
                index < feat.length - 1 ? ', ' : ''
              }`;
            });
            feature.set('pointType', feat[0].get('pointType'));
            feature.set('name', names);
            style = tractorClusterStyle(
              size,
              false,
              globalZoom > onGlobalZoomToShowZoomIcons,
            );
          }
          return style;
        },
      });

      const tractorPathFeaturesLayer = new VectorLayer({
        source: new VectorSource(),
        zIndex: priority.PATH,
      });

      const plannerTractorPathFeaturesLayer = new VectorLayer({
        source: new VectorSource(),
        zIndex: priority.PATH,
      });

      const tractorTraveledPath = new VectorLayer({
        source: new VectorSource(),
        style: new Style({
          stroke: new Stroke({ color: pathColorConstants.DEFAULT, width: 9 }),
        }),
        zIndex: priority.PATH,
      });

      mapRef.current?.addLayer(tractorFeaturesLayer);
      mapRef.current?.addLayer(tractorPathFeaturesLayer);
      mapRef.current?.addLayer(plannerTractorPathFeaturesLayer);
      mapRef.current?.addLayer(tractorTraveledPath);

      setTractorsInitialized(true);

      tractorLayerRef.current = tractorFeaturesLayer;
      tractorPathLayerRef.current = tractorPathFeaturesLayer;
      planerTractorPathLayerRef.current = plannerTractorPathFeaturesLayer;
      // tractor traveled path
      tractorTraveledPathLayerRef.current = tractorTraveledPath;
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };
  /**
   * Live Tractors initialization Ends Here
   */

  useEffect(() => {
    if (mapInitialized && !tractorsInitialized) {
      const overlay = new Overlay({
        element: container.current,
        autoPan: false,
        autoPanAnimation: {
          duration: 10,
        },
      });
      let type1 = '';
      mapRef.current?.on('pointermove', function (evt) {
        const hit = mapRef.current?.forEachFeatureAtPixel(
          evt.pixel,
          function (feature: any) {
            const coordinate = evt.coordinate;
            const name = feature.get('name');
            const pointType = feature.get('pointType');
            type1 = pointType;
            if (name && pointType !== 'TRACTOR') {
              content.current.innerHTML =
                '<p>' + name && name != undefined ? name : '' + '</p>';
              overlay.setPosition(coordinate);
              mapRef.current?.addOverlay(overlay);
              return true;
            }

            return false;
          },
        );
        if (hit) {
          if (type1 !== 'vinerow') {
            evt.map.getTargetElement().style.cursor = 'pointer';
          }
        } else {
          overlay.setPosition(undefined);
          mapRef.current?.addOverlay(overlay);
          evt.map.getTargetElement().style.cursor = '';
        }
      });
      let selected: any = null;
      mapRef.current?.on('pointermove', function (evt) {
        if (preCommandGlobal !== '') {
          return;
        }
        if (selected !== null) {
          selected.setStyle(undefined);
          selected = null;
        }
        mapRef.current?.forEachFeatureAtPixel(
          evt.pixel,
          function (feature: any) {
            const pointType = feature.get('pointType');
            if (pointType && pointType === 'vinerow') {
              selected = feature;
              feature.setStyle(
                vineRowHoverStyle(globalZoom > onGlobalZoomToShowZoomIcons),
              );
              return true;
            }
            if (pointType == 'SNAP') {
              const contentData = feature.get('content');
              if (
                (contentData && contentData.created_at === 'GUARD_RAILS') ||
                (contentData && contentData.title === 'guard_rails')
              ) {
                selected = feature;
                selected?.setStyle(feature.get('hoverStyle'));
              }
            }
            const feat = feature.get('features');
            if (feat && feat.length > 0) {
              if (
                feat[0].get('pointType') &&
                feat[0].get('pointType') === 'INDICATOR'
              ) {
                if (feat.length === 1) {
                  selected = feature;
                  feature.setStyle(IndicatorStyle(1, true));
                  return true;
                } else {
                  selected = feature;
                  feature.setStyle(IndicatorStyle(feat.length, true));
                  return true;
                }
              }
              if (
                feat[0].get('pointType') &&
                feat[0].get('pointType') === 'TRACTOR'
              ) {
                if (feat.length === 1) {
                  selected = feature;
                  const style =
                    globalZoom > onGlobalZoomToShowZoomIcons
                      ? feat[0].get('onZoomHoverStyle')
                      : feat[0].get('hoverStyle');
                  feature.setStyle(style);
                  return true;
                } else {
                  selected = feature;
                  feature.setStyle(
                    tractorClusterStyle(
                      feat.length,
                      true,
                      globalZoom > onGlobalZoomToShowZoomIcons,
                    ),
                  );
                  return true;
                }
              }
              if (
                feat &&
                feat[0] &&
                feat[0].get('pointType') &&
                feat[0].get('pointType') === 'waypoint'
              ) {
                selected = feature;
                if (feat.length === 1) {
                  const style = wayPointHoverStyle(
                    feat[0].get('name'),
                    globalToggleSatellite,
                  );
                  selected.setStyle(style);
                } else {
                  const style = wayPointClusterHoverStyle(feat.length, true);
                  selected.setStyle(style);
                }
              }
            }
          },
        );
      });
      let selected1: any = null;
      let type2 = '';
      mapRef.current?.on('pointermove', function (evt) {
        const pointLayer = hoverPointLayerRef.current;
        if (selected1 !== null) {
          const feat = pointLayer?.getSource().getFeatureById(999);
          feat?.setStyle(new Style({}));
          selected1?.setStyle(selected1?.get('style'));
          selected1 = null;
        }
        const hit = mapRef.current?.forEachFeatureAtPixel(
          evt.pixel,
          function (feature: any) {
            const pointType = feature.get('pointType');
            type2 = pointType;
            if (pointType === 'PATH' || pointType === 'LIVEPATH') {
              const feat = pointLayer?.getSource().getFeatureById(999);
              if (feat) {
                feat.setGeometry(
                  new Point(
                    proj.fromLonLat(
                      proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326'),
                    ),
                  ),
                );
                feat.setStyle(
                  new Style({
                    image: new CircleStyle({
                      radius: 5,
                      stroke: new Stroke({
                        color: 'blue',
                      }),
                      fill: new Fill({
                        color: '#3399CC',
                      }),
                    }),
                    zIndex: 10,
                  }),
                );
                selected1 = feature;
                selected1?.setStyle(feature.get('hoverStyle'));
                const geometry = feature.getGeometry();
                const point = geometry.getClosestPoint(evt.coordinate);
                const coordinates = geometry.getCoordinates();

                for (
                  let element = 0;
                  element < coordinates.length - 1;
                  element++
                ) {
                  if (
                    new LineString([
                      coordinates[element],
                      coordinates[element + 1],
                    ]).intersectsCoordinate(point)
                  ) {
                    const drawPointsText = selected1.get('drawPointsText');
                    const points =
                      pointType === 'PATH'
                        ? drawPointsText.path
                        : drawPointsText.points;
                    if (points && points.length && points[element]) {
                      let innerHTML = '';
                      if (pointType === 'PATH') {
                        innerHTML = getFleetUsagePointsPathInfo(
                          points[element],
                        );
                      }
                      if (pointType === 'LIVEPATH') {
                        innerHTML = getFleetUsagePointsPathInfo(drawPointsText);
                      }

                      selected1.set('name', innerHTML);
                      content.current.innerHTML = innerHTML;
                      if (
                        !overlay
                          .getElement()
                          ?.classList.contains('ol-details-popup')
                      ) {
                        overlay.getElement()?.classList.add('ol-details-popup');
                      }
                      overlay.setPosition(evt.coordinate);
                      mapRef.current?.addOverlay(overlay);
                      return true;
                    }
                  }
                }
              }
            }
            const coordinate = evt.coordinate;
            const name = feature.get('name');
            const dataType = feature.get('pointType');
            if (name && dataType !== 'TRACTOR') {
              content.current.innerHTML = '<p>' + name + '</p>';
              if (dataType == 'INDICATOR') {
                const contentData = feature.get('content');
                content.current.innerHTML = getFleetUsagePointsInfo(
                  dataType,
                  contentData,
                );
                if (
                  !overlay.getElement()?.classList.contains('ol-details-popup')
                ) {
                  overlay.getElement()?.classList.add('ol-details-popup');
                }
              } else {
                // stop removing path class removal
                if (!['PATH', 'LIVEPATH'].includes(dataType)) {
                  overlay.getElement()?.classList.remove('ol-details-popup');
                }
              }

              overlay.setPosition(coordinate);
              mapRef.current?.addOverlay(overlay);
              return true;
            }
            const feat = feature.get('features');
            if (pointType === 'TRACTOR' && feat.length > 0) {
              if (feat.length > 1) {
                content.current.innerHTML =
                  '<p>' + name && name != undefined ? name : '' + '</p>';
                overlay.setPosition(coordinate);
                mapRef.current?.addOverlay(overlay);
              }
              return true;
            }
            return false;
          },
        );
        if (hit) {
          if (type2 !== 'vinerow') {
            evt.map.getTargetElement().style.cursor = 'pointer';
          }
        } else {
          overlay.setPosition(undefined);
          mapRef.current?.addOverlay(overlay);
          evt.map.getTargetElement().style.cursor = '';
        }
      });
    }
    if (mapInitialized && tractorsInitialized) {
      mapRef.current?.on('click', function (evt) {
        mapRef.current?.forEachFeatureAtPixel(evt.pixel, function (feature) {
          const feat = feature.get('features');
          if (feat && feat.length === 1) {
            globalTractorList &&
              globalTractorList.forEach(
                (element: { mapId: string; id: number }) => {
                  if (
                    !globalTractorClickWait &&
                    feat[0].getId() &&
                    feat[0].getId().replace('PoseModel_streaming_', '') ==
                      element.id
                  ) {
                    globalTractorClickWait = true;
                    setTimeout(() => {
                      globalTractorClickWait = false;
                    }, 1000);
                    dispatch({
                      type: SELECTED_TRACTORS,
                      payload: [element],
                    });
                    allowLiveStream = true;
                  }
                },
              );
          }
          if (
            feat &&
            feat.length > 1 &&
            feat[0] &&
            feat[0].get('pointType') &&
            (feat[0].get('pointType') === 'TRACTOR' ||
              feat[0].get('pointType') === 'INDICATOR')
          ) {
            tractorClusterSource.current.setDistance(0);
            feat.map((f: any) => {
              const geometry: any = feature.getGeometry();
              const co = geometry.getCoordinates();
              f.setGeometry(
                new Point([
                  co[0] * Number(`1.00000${Math.floor(Math.random() * 3)}`),
                  co[1] * Number(`1.00000${Math.floor(Math.random() * 3)}`),
                ]),
              );
            });
          }
          if (feat && feat.length > 1) {
            const geometry = feat[0].getGeometry();
            const coordinates = geometry.getCoordinates();
            const view = mapRef.current?.getView();
            if (view) {
              // view.setZoom(18);
              const zoom = view.getZoom();
              zoom && view.setZoom(zoom + 1);
              if (coordinates) {
                mapRef.current?.getView().setCenter(coordinates);
              }
            }
          }
          const pointType = feature.get('pointType');
          if (pointType && pointType === 'INDICATOR') {
            if (!toggleForIndicator) {
              const content = feature.get('content');
              const { subsystem = '', functionality_affected = '' } =
                content.error_code_data ? content.error_code_data : {};
              content.functionality_affected = functionality_affected;
              content.subsystem = subsystem;
              content.indicator_type = content.indicator_name;
              content.severity = content.indicator_level;
              content.start_time = content.created_date_time
                ? getDateTimes(content.created_date_time)
                : '';
              (content.counter_measure = content?.error_code_data
                ?.counter_measure
                ? content?.error_code_data?.counter_measure?.split(';')
                : ''),
                setErrorDetails(content);
              setShowIndicatorModel(true);
              toggleForIndicator = true;
            } else {
              setShowIndicatorModel(false);
              toggleForIndicator = false;
            }
          }
          if (pointType == 'SNAP') {
            if (!toggleForSideWindow) {
              const content = feat[0].get('content');
              // eslint-disable-next-line no-empty
              if (content.created_at === 'GUARD_RAILS') {
              } else {
                const cont: any = {
                  id: content.id,
                  tractorName: content.tractor_id.name,
                  date_time: content.created_date_time,
                  created_date_time: content.created_date_time
                    ? getDateTimes(content.created_date_time)
                    : '',
                  name: content.created_by
                    ? content.created_by?.first_name
                    : '-',
                  status: content.status,
                  remarks: content.remarks,
                  tractor_id: content.tractor_id,
                  tractorId: content.tractor_id?.id,
                  transaction_id: content.transaction_id,
                  created_at: content.created_at,
                  guard_rail_type: content.guard_rail_type,
                  drive_action_uuid: content.drive_action_uuid,
                  createdAt: content.created_at.replaceAll('_', ' '),
                };
                setSnapshotContent(cont);
                setShowSnapShotView(true);
              }
            }
          }
        });
      });
      mapRef.current?.on('pointerdrag', function () {
        allowLiveStream = false;
      });

      mapRef.current?.on('click', async (event: any) => {
        if (globalAddStep && globalShowPresentCommand === 'GOTO') {
          const featureData: any = await mapRef.current?.forEachFeatureAtPixel(
            event.pixel,
            function (feature) {
              return feature;
            },
          );
          goToRowFollowCode(featureData);
          goToPointCode(event.coordinate);
        }
      });
    }
  }, [userDetails, mapInitialized, tractorsInitialized]);

  useEffect(() => {
    const greenPolyLayer = getFeatureLayer('GREENPOLYGON');
    if (greenPolyLayer && goToRowFollowPolygon && goToRowFollowPolygon.mapId) {
      const greenFeature = greenPolyLayer
        ?.getSource()
        ?.getFeatureById(goToRowFollowPolygon.mapId);
      greenFeature && goToRowFollowCode(greenFeature);
    }
  }, [goToRowFollowPolygon]);
  const goToRowFollowCode = async (featureData: any) => {
    if (globalShowPresentCommand === 'GOTO') {
      if (featureData) {
        const type = featureData.get('pointType');
        const polygon = featureData.get('poly');

        if (preCommandGlobal === '') {
          if (type === types.POLYGON && polygon.color !== 'white') {
            setPreCommandState(commands.ROW_FOLLOW);
            commandForSecondTime = commands.ROW_FOLLOW;
            if (polygon && polygon.color === 'green') {
              const layer = greenPolygonsLayerRef.current;
              if (layer?.getVisible) {
                const { organization } = userDetails;
                setLoader(true);

                try {
                  const laneFilterContent: any = await getVineLanesData(
                    organization.api_url,
                    organization.farm.id,
                    polygon.polygon_uuid,
                  );
                  if (laneFilterContent && laneFilterContent.length > 0) {
                    dispatch({
                      type: SELECTED_ITEM_NAME,
                      payload: polygon.name,
                    });
                    dispatch({
                      type: SET_SETUP_KEYS,
                      payload: {
                        ...setUpKeysGlobal,
                        polygonUuid: polygon.polygon_uuid,
                        laneFilterContent,
                      },
                    });
                    handleToggleLayer('VINEROW', false);
                    setFarm(false);
                    selectFarmItems(false);
                    setLoader(true);
                    try {
                      const { organization } = userDetails;
                      vineBlocksGlobal = await getVineBlockName(
                        organization.api_url,
                        organization.farm.id,
                        setUpKeysGlobal.polygonUuid,
                      );
                    } catch (error) {
                      resetForMapClicks('');
                      setTimeout(() => {
                        resetForMapClicks('GOTO');
                      }, 100);
                      notification.error({
                        message: 'Failed to get the Vineblocks',
                      });
                    } finally {
                      setLoader(false);
                    }
                  } else {
                    resetForMapClicks('');
                    setTimeout(() => {
                      resetForMapClicks('GOTO');
                    }, 100);
                    notification.error({
                      message: 'No Vine Rows found',
                    });
                  }
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error(error);
                  resetForMapClicks('');
                  setTimeout(() => {
                    resetForMapClicks('GOTO');
                  }, 100);
                  notification.error({
                    message: 'Failed to get VineLanes',
                  });
                } finally {
                  setLoader(false);
                }
              }
            }
          }
        } else if (
          preCommandGlobal === commands.ROW_FOLLOW &&
          commandForSecondTime !== commands.ROW_FOLLOW_SECOND
        ) {
          if (type === types.VINELANEHEAD) {
            commandForSecondTime = commands.ROW_FOLLOW_SECOND;
            const startIndexValue = featureData.get('startIndex');
            const startIndexUuid = featureData.get('startIndexUuid');
            const startWithValue = featureData.get('head_name');
            // set vine block name
            const data = featureData.get('data');
            const { vine_blocks_uuid } = data;
            const [vineBlock] = vineBlocksGlobal.filter(
              (item: any) => item.vine_blocks_uuid === vine_blocks_uuid,
            );
            if (vineBlock && vineBlock.name) {
              const { name } = vineBlock;
              dispatch({
                type: SELECTED_VINE_BLOCK,
                payload: name,
              });

              const laneFilterContent =
                setUpKeysGlobal.laneFilterContent.filter(
                  (item: any) => item.vine_blocks_uuid === vine_blocks_uuid,
                );
              dispatch({
                type: SET_SETUP_KEYS,
                payload: {
                  ...setUpKeysGlobal,
                  startIndex: startIndexValue,
                  startWith: startWithValue,
                  isFirstTime: true,
                  laneFilterContent,
                  startIndexUuid,
                },
              });
              dispatch({
                type: SET_COMMAND_TYPE,
                payload: commands.ROW_FOLLOW,
              });
              // dispatch({
              //   type: SET_SHOW_CONFIGURE_TRACTOR,
              //   payload: true,
              // });
              dispatch({
                type: SET_AUTO_DRIVE_SETTINGS,
                payload: true,
              });
            } else {
              resetForMapClicks('');
              setTimeout(() => {
                resetForMapClicks('GOTO');
              }, 100);
              notification.error({
                message: 'VineBlock Not found',
              });
            }
          }
        }
      }
    }
  };
  const goToPointCode = (coordinate: any) => {
    if (globalShowPresentCommand === 'GOTO') {
      if (preCommandGlobal === '' && preCommandGlobal !== commands.ROW_FOLLOW) {
        if (
          !(
            fieldFeatureItem &&
            fieldFeatureItem.current &&
            fieldFeatureItem.current
              .getGeometry()
              .intersectsCoordinate(coordinate)
          )
        ) {
          setPreCommandState('');
          commandForSecondTime = '';
          notification.error({
            message: 'Point should be inside Geofence',
          });
          return;
        }
        if (!pointExistInPolygons(coordinate, greenPolygonsLayerRef.current)) {
          setPreCommandState('');
          commandForSecondTime = '';
          notification.error({
            message: 'Point should not be inside Green Field',
          });
          return;
        }

        // if (pointLayerRef.current) {
        pointLayerRef?.current?.getSource().clear();
        // }
        const coordinates = proj.transform(
          coordinate,
          'EPSG:3857',
          'EPSG:4326',
        );
        setPointCordinate(coordinates);
        // drawPoint(coordinates);
        // const [x, y] = getRAVXYCoordinates(
        //   [coordinates[0], coordinates[1]],
        //   base,
        // );
        // pointCordinateGlobal = [x, y];
        // setPointCordinate([x, y]);
        angleDegrees = 0;
        apiCall = false;
        // addRVCommand(x, y);
        dispatch({
          type: SET_COMMAND_TYPE,
          payload: commands.GOTO_POINT,
        });
        dispatch({
          type: SHOW_SAVE_COMMAND,
          payload: true,
        });
        setPreCommandState(commands.GOTO_POINT);
        commandForSecondTime = commands.GOTO_POINT;
        // setShowNextButton(true);
        // dispatch({
        //   type: SET_AUTODRIVE_SETTINGS,
        //   payload: true,
        // });
      }
    }
  };

  /**
   * Subscribing to IOT
   */

  useEffect(() => {
    if (process.env.REACT_APP_ALPHA_FEATURES?.toLowerCase().includes('iot')) {
      try {
        const tractorLayer = tractorLayerRef.current;
        if (tractorLayer?.getVisible()) {
          const sub1 = PubSub.subscribe('HeartBeat').subscribe({
            next: (data: any) => {
              heartBeatHandler(JSON.stringify(data.value));
            },
            error: (error: any) => console.error(error),
          });

          const sub2 = PubSub.subscribe('tractor_status').subscribe({
            next: (data: any) => {
              tractorOfflineStatusHandler(JSON.stringify(data.value));
            },
            error: (error: any) => console.error(error),
          });

          const sub3 = PubSub.subscribe(
            `${MOS_EVENTS_TOPIC}${userDetails.organization_id}`,
          ).subscribe({
            next: (data: any) => {
              mosEventsHandler(JSON.stringify(data.value));
            },
            error: (error: any) => console.error(error),
          });

          const sub4 = PubSub.subscribe(AUTO_DRIVE_ERROR_TOPIC).subscribe({
            next: (data: any) => {
              autoDriveMonitorErrorHandler(JSON.stringify(data.value));
            },
            error: (error: any) => console.error(error),
          });

          const sub5 = PubSub.subscribe(
            `remoteav_preview_${userDetails.organization_id}`,
          ).subscribe({
            next: (data: any) => {
              remoteAvPreviewForOrgHandler(JSON.stringify(data.value));
            },
            error: (error: any) => console.error(error),
          });

          const sub6 = PubSub.subscribe(
            `live_event_notifications_${userDetails.organization_id}`,
          ).subscribe({
            next: (data: any) => {
              liveEventNotificationsForOrdHandler(JSON.stringify(data.value));
            },
            error: (error: any) => console.error(error),
          });

          const sub7 = PubSub.subscribe(
            `guardrail_triggered_${globalSelectedTractorId}`,
          ).subscribe({
            next: (data: any) => {
              guardrailTriggeredForTractorHandler(JSON.stringify(data.value));
            },
            error: (error: any) => console.error(error),
          });

          return () => {
            sub1.unsubscribe();
            sub2.unsubscribe();
            sub3.unsubscribe();
            sub4.unsubscribe();
            sub5.unsubscribe();
            sub6.unsubscribe();
            sub7.unsubscribe();
          };
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [tractorsInitialized, userDetails, heartBeatTopic]);

  /**
   * Subscribing to Tractors and getting Information Starts Here
   */

  useEffect(() => {
    if (!process.env.REACT_APP_ALPHA_FEATURES?.toLowerCase().includes('iot')) {
      try {
        if (
          tractorsInitialized &&
          userDetails &&
          userDetails.organization &&
          heartBeatTopic
        ) {
          const { organization } = userDetails;
          const mqttClient = getClient(
            organization.mqtt_ip,
            organization.mqtt_port,
          );

          if (tractorsInitialized) {
            mqttClient.on('connect', () => {
              mqttClient.subscribe([
                heartBeatTopic,
                `live_event_notifications_${organization.id}`,
                TRACTOR_STATUS,
                `guardrails_ui_notification_${globalSelectedTractorId}`,
                `guardrail_triggered_${globalSelectedTractorId}`,
                `remoteav_preview_${userDetails.organization_id}`,
                // AUTO_DRIVE_MONITOR_STATUS,
                AUTO_DRIVE_ERROR_TOPIC,
                `${MOS_EVENTS_TOPIC}${userDetails.organization_id}`,
              ]);
            });
            mqttClient.on('message', (topic: string, message: any) => {
              const tractorLayer = tractorLayerRef.current;
              if (tractorLayer?.getVisible()) {
                if (topic === TRACTOR_STATUS) {
                  tractorOfflineStatusHandler(message);
                } else if (
                  topic === `${MOS_EVENTS_TOPIC}${userDetails.organization_id}`
                ) {
                  mosEventsHandler(message);
                } else if (topic === AUTO_DRIVE_ERROR_TOPIC) {
                  autoDriveMonitorErrorHandler(message);
                } else if (topic === heartBeatTopic) {
                  heartBeatHandler(message);
                  // } else if (topic.includes('remoteav_preview_')) {
                } else if (
                  topic === `remoteav_preview_${userDetails.organization_id}`
                ) {
                  remoteAvPreviewForOrgHandler(message);
                } else if (
                  topic === `live_event_notifications_${organization.id}`
                ) {
                  liveEventNotificationsForOrdHandler(message);
                } else if (
                  topic === `guardrail_triggered_${globalSelectedTractorId}`
                ) {
                  guardrailTriggeredForTractorHandler(message);
                }
              }

              //  else if (topic.includes('localization_streaming_')) {
              //   const tractorId = topic.replace('localization_streaming_', '');
              //   const feat: any = tractorLayerRef.current?.getSource();
              //   const feature: any = feat
              //     .getSource()
              //     .getFeatureById('PoseModel_streaming_' + tractorId);
              //   const tractor = new TextDecoder('utf-8').decode(message);
              //   const data = tractor.split(',').map((item) => {
              //     return parseFloat(item);
              //   });
              //   const latAndLong: [number, number] = getLatitudeAndLongitude(
              //     [data[1], data[0]],
              //     base,
              //   );
              //   feature &&
              //     feature?.setGeometry(
              //       new Point(proj.fromLonLat([latAndLong[1], latAndLong[0]])),
              //     );
              //   feature.set('pointTypeBearing', data[5]);
              // }
            });
            mqttClientRef.current = mqttClient;
          }

          return () => {
            mqttClient.unsubscribe([
              heartBeatTopic,
              `live_event_notifications_${organization.id}`,
              TRACTOR_STATUS,
              `guardrails_ui_notification_${globalSelectedTractorId}`,
              `guardrail_triggered_${globalSelectedTractorId}`,
              `remoteav_preview_${userDetails.organization_id}`,
              // AUTO_DRIVE_MONITOR_STATUS,
              AUTO_DRIVE_ERROR_TOPIC,
              `${MOS_EVENTS_TOPIC}${userDetails.organization_id}`,
            ]);
            mqttClient.end();
          };
        }
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    }
  }, [tractorsInitialized, userDetails, heartBeatTopic]);
  /**
   * Draw Tractor Path Ends Here
   */
  const mosEventsHandler = (message: any) => {
    const msg = JSON.parse(message.toString());
    const { id, organization_id } = userDetails;
    msg &&
      msg.category &&
      msg.category === AUTO_DRIVE_MONITOR_STATUS &&
      autoDriveMonitorStatusHandle(msg.tractor_id, msg.message);
    if (
      msg &&
      msg.category &&
      msg.category === PLANNING_MANAGER_REMOTE_AV_EXECUTION_FBK &&
      msg.message &&
      msg.organization_id == organization_id &&
      msg.message.toLowerCase() === 'finished'
    ) {
      if (globalSelectedTractorId == msg.tractor_id) {
        dispatch({
          type: SET_AUTO_DRIVE_FEEDBACK,
          payload: true,
        });
      }

      dispatch({
        type: SET_SHOW_PRESENT_COMMAND,
        payload: '',
      });
      // resetForMapClicks('');
      clearAll();
      // to show hide the start button added this code
      globalShowTractorStart[globalSelectedTractorId] = false;
      dispatch({
        type: SHOW_TRACTOR_START,
        payload: {
          ...globalShowTractorStart,
        },
      });
    }
    msg &&
      msg.category &&
      msg.category === INTERNAL_MESSAGE &&
      msg.metadata &&
      msg.metadata.user_id &&
      id &&
      msg.metadata.user_id === id &&
      msg.message == AUTO_LOGOUT_CODE &&
      autoDriveTractorLogoutHandle(msg.user_id, msg);
    interruptHandler(msg);
  };

  const interruptHandler = (msg: MosEventsResp) => {
    const { organization_id } = userDetails;
    if (
      msg &&
      msg.category &&
      msg.category === AUTO_DRIVE_INTERRUPT_MESSAGE_CATEGORY &&
      msg.organization_id &&
      msg.organization_id === organization_id &&
      msg.tractor_id &&
      msg.tractor_id == globalSelectedTractorId &&
      msg.metadata.category &&
      msg.metadata.category === 'system_pause'
    ) {
      // hard interrupt message
      if (
        msg &&
        msg.metadata &&
        msg.metadata.type &&
        msg.metadata.type === 'hard'
      ) {
        msg.message &&
          msg.message === 'pause' &&
          dispatch({
            type: SET_AUTO_DRIVE_INTERRUPT_MESSAGE,
            payload: msg,
          });
      } else if (
        msg &&
        msg.metadata &&
        msg.metadata.type &&
        msg.metadata.type === 'soft'
      ) {
        // soft interrupt message
        msg.message &&
          msg.message === 'pause' &&
          dispatch({
            type: SET_AUTO_DRIVE_SOFT_INTERRUPT_MESSAGE,
            payload: msg,
          });
        msg.message &&
          msg.message === 'resume' &&
          dispatch({
            type: SET_AUTO_DRIVE_SOFT_INTERRUPT_MESSAGE,
            payload: null,
          });
      }
    }
    // msg &&
    //   msg.category &&
    //   msg.category === AUTO_DRIVE_INTERRUPT_MESSAGE_CATEGORY &&
    //   msg.organization_id &&
    //   msg.organization_id === organization_id &&
    //   msg.metadata &&
    //   msg.metadata.type &&
    //   msg.metadata.type === 'hard' &&
    //   msg.tractor_id &&
    //   msg.tractor_id == globalSelectedTractorId &&
    //   msg.message &&
    //   msg.message === 'pause' &&
    //   msg.metadata.category &&
    //   msg.metadata.category === 'system_pause' &&
    //   dispatch({
    //     type: SET_AUTO_DRIVE_INTERRUPT_MESSAGE,
    //     payload: msg,
    //   });
  };
  // to handle the offline tractor status
  const tractorOfflineStatusHandler = (message: any) => {
    const feat: any = tractorLayerRef.current?.getSource();
    const status = JSON.parse(message.toString());
    const { id } = status;
    const feature = feat
      ?.getSource()
      .getFeatureById(`PoseModel_streaming_${id}`);
    const pointTypeBattery =
      feature?.get('pointTypeBattery') > 0
        ? feature?.get('pointTypeBattery')
        : 0;
    const tractorName = feature?.get('name') ? feature?.get('name') : '';
    const isFaulty = false; // offline tractor not have faulty option
    const isSelected = id == globalSelectedTractorId;
    const pointTypeIsSelectedForToolTip = isSelectedTractor(
      globalSelectedTractors,
      id,
    );
    const style = getTractorIcon(
      TractorIconContsant.OFFLINE,
      false,
      0,
      '',
      isSelected,
      false,
      false,
      pointTypeBattery,
      false,
      tractorName,
      pointTypeIsSelectedForToolTip,
      id,
      isFaulty,
    );
    const hoverStyle = getTractorIcon(
      TractorIconContsant.OFFLINE,
      true,
      0,
      '',
      isSelected,
      false,
      false,
      pointTypeBattery,
      false,
      tractorName,
      pointTypeIsSelectedForToolTip,
      id,
      isFaulty,
    );

    const onZoomStyle = getTractorIcon(
      TractorIconContsant.OFFLINE,
      false,
      0,
      '',
      isSelected,
      false,
      true,
      0,
      false,
      tractorName,
      pointTypeIsSelectedForToolTip,
      id,
      isFaulty,
    );
    const onZoomHoverStyle = getTractorIcon(
      TractorIconContsant.OFFLINE,
      true,
      0,
      '',
      isSelected,
      false,
      true,
      0,
      false,
      tractorName,
      pointTypeIsSelectedForToolTip,
      id,
      isFaulty,
    );

    feature?.setStyle(style);
    feature?.set('style', style);
    feature?.set('hoverStyle', hoverStyle);
    feature?.set('onZoomStyle', onZoomStyle);
    feature?.set('onZoomHoverStyle', onZoomHoverStyle);
    // on click change icon
    feature?.set('pointTypeSelected', TractorIconContsant.OFFLINE);
    feature?.set('pointTypeIni', '');
    feature?.set('pointTypeBearing', 0);
    feature?.set('pointTypeBattery', 0);
  };
  // useEffect(() => {
  //   setTimeout(() => {
  //     autoDriveMonitorErrorHandler('{"tractor_id":94,"message":7102}');
  //   }, 30000);
  // }, []);
  // to handle the monitor status error
  const autoDriveMonitorErrorHandler = (message: any) => {
    const msg = JSON.parse(message.toString());
    if (globalSelectedTractorId == msg.tractor_id && msg && msg.message) {
      let clearErrors = false;
      if (msg.message == CLEAR_COMMAND) {
        clearErrors = true;
      }
      dispatch({
        type: AUTO_DRIVE_ERROR,
        payload: {
          ...msg,
          errors: clearErrors ? [] : [msg.message],
        },
      });
    }
  };
  const guardrailTriggeredForTractorHandler = (message: any) => {
    const pathData = message.toString().split(',');
    let guardRail = pathData[0];
    guardRail = guardRail.replace(/_/g, ' ');
    const triggerState = pathData.length === 7 ? pathData[6] : '';
    guardRailsCheck(guardRail, triggerState.trim());
  };
  const liveEventNotificationsForOrdHandler = (message: any) => {
    const messageData = message.toString().trim();
    try {
      const jsonData = JSON.parse(messageData);
      if (jsonData && jsonData.title && jsonData.title === 'guard_rails') {
        setTractorGuardRailsPoints(jsonData);
      }
      if (jsonData && jsonData.title && jsonData.title === 'Indicator') {
        setTractorIndicatorsPoints(jsonData);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  const remoteAvPreviewForOrgHandler = (message: any) => {
    const preview = JSON.parse(message.toString());
    if (
      preview &&
      preview.tractor_id &&
      preview.message &&
      preview.message.trim().length > 0
    ) {
      const previewPath =
        preview && preview.message ? preview.message.toString() : '';
      if (preview.tractor_id == globalSelectedTractorId) {
        globalShowTractorStart[preview.tractor_id] = true;
        dispatch({
          type: SHOW_TRACTOR_START,
          payload: { ...globalShowTractorStart },
        });
      }
      handlePreview(previewPath, Number(preview.tractor_id));
    }
  };
  // const fun = () => {
  //   autoDriveMonitorStatusHandle(94, '7001,7002');
  //   setTimeout(() => {
  //     fun();
  //   }, 30000);
  // };
  // useEffect(() => {
  //   setTimeout(() => {
  //     fun();
  //   }, 30000);
  // }, []);
  const heartBeatHandler = (message: any) => {
    const heartBeat: TractorHeartBeat = JSON.parse(message.toString());
    if (
      heartBeat &&
      heartBeat.organization_id &&
      userDetails.organization_id &&
      heartBeat.organization_id === userDetails.organization_id
    ) {
      if (!heartBeat?.localization) {
        return;
      }
      // added this code for displaying loading icon in auto drive pop
      if (
        heartBeat.tractor_id === globalSelectedTractorId &&
        getOperationNewStatus(heartBeat) !== tractorConstantsNew.available.key
      ) {
        dispatch({
          type: SET_SHOW_CONNECT_LOADER,
          payload: false,
        });
      }

      autoDriveMonitorStatusHandle(
        heartBeat.tractor_id,
        heartBeat.autodrive_monitor_status,
      );

      // subscribeTopic(heartBeat);
      setHeartBeat(heartBeat);

      // clear preview path with heartbeat
      // if no jump and go clear preview path
      if (
        !(
          heartBeat.drive_action_details &&
          heartBeat.drive_action_details.current_drive_action &&
          heartBeat.drive_action_details.current_drive_action.length > 0
        )
      ) {
        clearPreviewPath(heartBeat.tractor_id);
      }
      // drawing preview path using planner starts here
      // if (
      //   heartBeat &&
      //   heartBeat.tractor_id == globalSelectedTractorId
      // ) {
      //   if (
      //     heartBeat.planning_manager &&
      //     heartBeat.planning_manager.planner_name &&
      //     heartBeat?.drive_action_details &&
      //     heartBeat?.drive_action_details.current_drive_action &&
      //     heartBeat.planning_manager.planner_name ===
      //       pathConstants.REMOTE_AV
      //   ) {
      //     if (!isPreviewPathDrawn) {
      //       isPreviewPathDrawn = true;
      //       onAutoDriveStart(
      //         heartBeat.drive_action_details.current_drive_action,
      //       );
      //     }
      //   } else {
      //     // clearPreviewPath();
      //   }
      // }
      // drawing preview path using planner ends here
      const id = `PoseModel_streaming_${heartBeat.tractor_id}`;
      tractorsMap.set(heartBeat.tractor_id, heartBeat);
      const list: any = [];
      const entries = Array.from(tractorsMap.entries());
      if (entries && entries.length > 0) {
        entries.map((item: any) => {
          list.push(item[1]);
        });
      }
      // maintain the all tractors heartbeat in app context
      autoDriveDispatch({
        type: LOAD_REMOTE_DRIVE_TRACTORS,
        payload: list,
      });
      const tractorLayer = tractorLayerRef.current;
      if (tractorLayer?.getVisible()) {
        const feat: any = tractorLayerRef.current?.getSource();
        const feature: any = feat.getSource().getFeatureById(id);
        const pointTypeLowLocalization = isLowLocalization(
          heartBeat.localization,
        );
        const isFaulty =
          getOperationNewStatus(heartBeat) === tractorConstantsNew.faulty.key
            ? true
            : false;
        let latLang: [number, number] = [
          heartBeat.localization.longitude,
          heartBeat.localization.latitude,
        ];
        // don't draw tractor path if tractor in low localization or faulty
        if (!pointTypeLowLocalization && !isFaulty) {
          // in path key we have the tractor moved path
          if (heartBeat.path && heartBeat.path.length > 0) {
            let listForPath: any = heartBeat.path.split('|');
            listForPath = listForPath.map((listItem: any) =>
              listItem.split(','),
            );
            listForPath = listForPath.map((listItem: any[]) =>
              listItem.map(Number),
            );
            let latLangs;
            if (
              listForPath &&
              listForPath.length > 0 &&
              listForPath[0].length > 4
            ) {
              // taking lat langs instead of x and y
              latLangs = listForPath.map((item: any) => [item[4], item[3]]);
            } else {
              // if not lat lang take x and y
              latLangs = convertXyToLatLang(
                listForPath,
                globalBase[0],
                globalBase[1],
              );
            }
            if (latLangs) {
              let operatorName = '-';
              if (heartBeat?.drive_action_details) {
                const { operator_firstname, operator_lastname } =
                  heartBeat.drive_action_details;
                if (operator_firstname || operator_lastname)
                  operatorName = operator_firstname + ' ' + operator_lastname;
              }
              const avg_speed =
                heartBeat &&
                heartBeat?.localization &&
                heartBeat?.localization?.velocity;
              setPlannerTractorPaths(
                id,
                heartBeat?.planning_manager?.planner_name,
                latLangs,
                heartBeat.tractor_name,
                heartBeat.date_time,
                operatorName,
                avg_speed,
              );
              latLang = latLangs[latLangs.length - 1];
            }
          } else {
            // to draw all tractors path maintain points
            setTractorPoints(id, latLang);
          }
        }
        if (feature) {
          const pointTypeBearing = heartBeat.localization.yaw;
          let pointTypeIni = '';
          let pointTypeSelected = TractorIconContsant.ONLINE;
          const isSelected = heartBeat.tractor_id == globalSelectedTractorId;
          const pointTypeIsSelectedForToolTip = isSelectedTractor(
            globalSelectedTractors,
            heartBeat.tractor_id,
          );
          let pointTypeIsMoving = false;

          const pointTypeBattery = batteryValue({
            heartbeat: heartBeat,
          });
          const tractorName = feature.get('name') ? feature.get('name') : '';
          if (
            heartBeat.drive_action_details &&
            heartBeat?.drive_action_details.operator_firstname &&
            heartBeat?.drive_action_details.operator_lastname
          ) {
            pointTypeIni = getInitials(
              heartBeat?.drive_action_details.operator_firstname,
              heartBeat?.drive_action_details.operator_lastname,
            );
          }
          // checking the low localization status
          // faulty and low localization tractor not update tractor location
          if (!pointTypeLowLocalization && !isFaulty) {
            // only update when not low localization
            pointTypeIsMoving = true;
            feature.setGeometry(new Point(proj.fromLonLat(latLang)));
            feature.set('latLang', latLang);
            if (
              allowLiveStream &&
              latLang &&
              latLang.length > 0 &&
              latLang[0] != undefined &&
              heartBeat.tractor_id == globalSelectedTractorId
            ) {
              mapRef?.current?.getView().setCenter(proj.fromLonLat(latLang));
            }
          }

          // tractor in charging state
          if (
            getOperationNewStatus(heartBeat as TractorHeartBeat) ===
            tractorConstantsNew.charging.key
          ) {
            pointTypeSelected = TractorIconContsant.CHARGING;
            clearTractorPaths(
              id,
              heartBeat?.planning_manager?.planner_name,
              heartBeat.tractor_name,
              heartBeat.date_time,
            );
          }
          // normal tractor style
          const style = getTractorIcon(
            pointTypeSelected,
            false,
            pointTypeBearing,
            pointTypeIni,
            isSelected,
            pointTypeIsMoving,
            globalZoom > onGlobalZoomToShowZoomIcons,
            pointTypeBattery,
            pointTypeLowLocalization,
            tractorName,
            pointTypeIsSelectedForToolTip,
            heartBeat.tractor_id,
            isFaulty,
          );
          // tractor on hover
          const hoverStyle = getTractorIcon(
            pointTypeSelected,
            true,
            pointTypeBearing,
            pointTypeIni,
            isSelected,
            pointTypeIsMoving,
            globalZoom > onGlobalZoomToShowZoomIcons,
            pointTypeBattery,
            pointTypeLowLocalization,
            tractorName,
            pointTypeIsSelectedForToolTip,
            heartBeat.tractor_id,
            isFaulty,
          );
          // on zoom the tractor icon
          const onZoomStyle = getTractorIcon(
            pointTypeSelected,
            false,
            pointTypeBearing,
            pointTypeIni,
            isSelected,
            pointTypeIsMoving,
            true,
            pointTypeBattery,
            pointTypeLowLocalization,
            tractorName,
            pointTypeIsSelectedForToolTip,
            heartBeat.tractor_id,
            isFaulty,
          );
          // on zoom hover the tractor icon
          const onZoomHoverStyle = getTractorIcon(
            pointTypeSelected,
            true,
            pointTypeBearing,
            pointTypeIni,
            isSelected,
            pointTypeIsMoving,
            true,
            pointTypeBattery,
            pointTypeLowLocalization,
            tractorName,
            pointTypeIsSelectedForToolTip,
            heartBeat.tractor_id,
            isFaulty,
          );
          feature.setStyle(style);
          feature.set('style', style);
          feature.set('hoverStyle', hoverStyle);
          feature.set('onZoomStyle', onZoomStyle);
          feature.set('onZoomHoverStyle', onZoomHoverStyle);

          feature.set('pointTypeIni', pointTypeIni);
          feature.set('pointTypeSelected', pointTypeSelected);
          feature.set('pointTypeBearing', pointTypeBearing);
          feature.set('pointTypeBattery', pointTypeBattery);
          feature.set('pointTypeIsMoving', pointTypeIsMoving);
          feature.set('pointTypeLowLocalization', pointTypeLowLocalization);
          feature.set('isFaulty', isFaulty);
          setSelectedTractorLoc(heartBeat.tractor_id, feature);
        }
      }
    }
  };
  const setSelectedTractorLoc = (id: number, feature: any) => {
    if (globalSelectedTractorId === id && globalShowRoutine) {
      dispatch({
        type: SET_SELECTED_TRACTOR_LOCATION,
        payload: null,
      });
      dispatch({
        type: SET_SELECTED_TRACTOR_LOCATION,
        payload: feature,
      });
    }
  };
  const autoDriveMonitorStatusHandle = (tractorId: number, message: string) => {
    if (globalSelectedTractorId == tractorId) {
      if (message && message.length > 0) {
        const errors = message.split(',').map((element: string) => {
          return Number(element);
        });
        if (tractorsMap.get(tractorId)) {
          const rvTractor = tractorsMap.get(tractorId);
          if (
            rvTractor &&
            rvTractor.planning_manager &&
            rvTractor.planning_manager.planner_name &&
            rvTractor.planning_manager.planner_name ===
              tractorConstantsNew.remote_av.key
          ) {
            errors.map(
              (error, index: number) =>
                error == SystemError2 && errors.splice(index, 1),
            );
          }
          if (
            globalAutoDriveMonitorErrorCodes &&
            globalAutoDriveMonitorErrorCodes.tractorId == tractorId
          ) {
            globalAutoDriveMonitorErrorCodes.message !== message &&
              dispatch({
                type: AUTO_DRIVE_MONITOR_ERROR_CODES,
                payload: {
                  message,
                  errors,
                  tractorId,
                },
              });
          } else {
            dispatch({
              type: AUTO_DRIVE_MONITOR_ERROR_CODES,
              payload: { message, errors, tractorId },
            });
          }
        }
      } else {
        // clear the error codes if not get message
        dispatch({
          type: AUTO_DRIVE_MONITOR_ERROR_CODES,
          payload: {
            message: '',
            errors: [],
            tractorId,
          },
        });
      }
    }
  };
  const autoDriveTractorLogoutHandle = (userId: number, message: any) => {
    dispatch({
      type: AUTO_DRIVE_LOGOUT_ERROR_CODES,
      payload: {
        message,
        errors: [AUTO_LOGOUT_CODE],
        userId,
      },
    });
  };
  const subscribeTopic = (heartBeat: TractorHeartBeat) => {
    if (
      heartBeat &&
      heartBeat.planning_manager &&
      heartBeat.planning_manager.planner_name &&
      heartBeat.planning_manager.planner_name === 'remote_av' &&
      heartBeat.tractor_id
    ) {
      mqttClientRef.current &&
        mqttClientRef.current &&
        mqttClientRef.current.subscribe(
          'localization_streaming_' + heartBeat.tractor_id,
        );
    } else {
      mqttClientRef.current &&
        mqttClientRef.current.unsubscribe(
          'localization_streaming_' + heartBeat.tractor_id,
        );
    }
  };

  const clearTractorPaths = async (
    id: string,
    planner: string,
    tractorName: string,
    created_date_time: number,
  ) => {
    const existingPath = plannerTractorPaths.current;
    if (existingPath) {
      existingPath.set(id, [
        {
          planner,
          planner_name: planner,
          tractorName,
          created_date_time,
          points: [],
        },
      ]);
      plannerTractorPaths.current = existingPath;
    } else {
      const existingPath = new Map<string, any>();
      const arr = [
        {
          planner,
          planner_name: planner,
          tractorName,
          created_date_time,
          points: [],
        },
      ];
      existingPath.set(id, arr);
      plannerTractorPaths.current = existingPath;
    }
    drawPlannerTractorPath();
  };
  const setTractorGuardRailsPoints = (points: GuardRailType) => {
    const existingGurdRails = tractorsGuardrailMap?.current;
    const id = `${points.tractor_id}`;
    if (existingGurdRails) {
      const path = existingGurdRails.get(id);
      if (path && path.length > 0) {
        path?.push(points);
        existingGurdRails.set(id, path);
        tractorsGuardrailMap.current = existingGurdRails;
      } else {
        existingGurdRails.set(id, [points]);
        tractorsGuardrailMap.current = existingGurdRails;
      }
    } else {
      const existingPath = new Map<string, any>();
      existingPath.set(id, [points]);
      tractorsGuardrailMap.current = existingPath;
    }
    drawGuardRailsFromStream();
  };

  const setTractorIndicatorsPoints = (points: GuardRailType) => {
    const existingIndicators = tractorsIndicatorMap?.current;
    const id = `${points.tractor_id}`;
    if (existingIndicators) {
      const path = existingIndicators.get(id);
      if (path && path.length > 0) {
        path?.push(points);
        existingIndicators.set(id, path);
        tractorsIndicatorMap.current = existingIndicators;
      } else {
        existingIndicators.set(id, [points]);
        tractorsIndicatorMap.current = existingIndicators;
      }
    } else {
      const existingPath = new Map<string, any>();
      existingPath.set(id, [points]);
      tractorsIndicatorMap.current = existingPath;
    }
    drawIndicatorsFromStreams();
  };

  /**
   * Draw  planner Tractor Path starts Here
   */
  const drawPlannerTractorPath = () => {
    const defaultLayer = planerTractorPathLayerRef.current;
    defaultLayer?.setVisible(globalShowPath);
    defaultLayer?.getSource().clear();
    const existingPath = plannerTractorPaths.current;
    if (existingPath) {
      // let iteration = 0;
      existingPath.forEach((value, key) => {
        let lastElement: any;
        value.forEach((element: any, index: number) => {
          const color = getPathColor(element.planner);
          const borderColor = getPathBorderColor(element.planner);
          const style = getPathFeatureStyle(
            element.planner,
            globalZoom > onGlobalZoomToShowZoomIcons,
          );
          // const style = getPathStyle(
          //   color,
          //   borderColor,
          //   globalZoom > onGlobalZoomToShowZoomIcons,
          // );
          const coordinates: Coordinate[] = [];
          // if previous and present planner same add last planner point to latest planner
          if (
            lastElement &&
            lastElement.points &&
            lastElement.points.length > 0 &&
            element &&
            element.points &&
            element.points.length > 0
          ) {
            const allowToCombinePath = calPtsDistanceActualAllow([
              {
                latitude: lastElement.points[lastElement.points.length - 1][1],
                longitude: lastElement.points[lastElement.points.length - 1][0],
              },
              {
                latitude: element.points[0][1],
                longitude: element.points[0][0],
              },
            ]);
            const point = lastElement.points[lastElement.points.length - 1];
            if (allowToCombinePath) {
              coordinates.push(proj.fromLonLat([point[0], point[1]]));
            } else {
              const coordinates = proj.fromLonLat([point[0], point[1]]);
              const startingPathPoint = setBadLocalization(coordinates);
              defaultLayer
                ?.getSource()
                .addFeature(startingPathPoint, globalShowBadLocalization);
            }
          }
          element &&
            element.points &&
            element.points.forEach((item: any) => {
              coordinates.push(proj.fromLonLat([item[0], item[1]]));
            });
          let tractorLocation: Feature<Geometry> = new Feature();
          if (defaultLayer) {
            tractorLocation = defaultLayer
              ?.getSource()
              .getFeatureById(key + index);
          }
          if (!tractorLocation) {
            tractorLocation = new Feature();
          }
          tractorLocation.setGeometry(new LineString(coordinates));
          tractorLocation.setId(key + index);
          tractorLocation.set('color', color);
          tractorLocation.set('pointType', 'LIVEPATH');
          tractorLocation.set('borderColor', borderColor);
          tractorLocation.set('drawPointsText', element);
          allTractorsList.forEach((feature: { mapId: string; name: any }) => {
            if (feature.mapId && key && feature.mapId === key) {
              tractorLocation.set('name', feature.name);
            }
          });
          tractorLocation.setStyle(style);
          tractorLocation.set('style', style);
          tractorLocation.set('hoverStyle', style);
          defaultLayer?.getSource().addFeature(tractorLocation);
          // added last planner data
          lastElement = element;
        });
      });
    }
  };

  const setPlannerTractorPaths = async (
    id: string,
    planner: string,
    passedPoints: [number, number][],
    tractorName: string,
    created_date_time: number,
    operatorName?: string,
    avg_speed?: number,
  ) => {
    const existingPath = plannerTractorPaths.current;
    let arrData: any = [];
    const mainaArrData: any = [];
    let pushIndex = 0;

    if (passedPoints && passedPoints.length > 0) {
      passedPoints.slice(1).filter((v: [number, number], i) => {
        const allowToCombinePath = calPtsDistanceActualAllow([
          { latitude: passedPoints[i][1], longitude: passedPoints[i][0] },
          {
            latitude: v[1],
            longitude: v[0],
          },
        ]);
        if (allowToCombinePath) {
          arrData.push(v);
          mainaArrData[pushIndex] = arrData;
          return v;
        } else {
          if (arrData && arrData.length > 0) {
            arrData[arrData.length - 1] = {
              ...arrData[arrData.length - 1],
            };
            mainaArrData[pushIndex] = arrData;
            pushIndex = pushIndex + 1;
          }
          arrData = [];
        }
      });
    }
    mainaArrData.map((points: any) => {
      if (existingPath) {
        if (existingPath.get(id)) {
          const arr = existingPath.get(id);
          arr.push({
            planner,
            planner_name: planner,
            tractorName,
            operatorName,
            avg_speed,
            created_date_time,
            points,
          });
          existingPath.set(id, arr);
          plannerTractorPaths.current = existingPath;
        } else {
          existingPath.set(id, [
            {
              planner,
              planner_name: planner,
              tractorName,
              operatorName,
              avg_speed,
              created_date_time,
              points,
            },
          ]);
          plannerTractorPaths.current = existingPath;
        }
      } else {
        const existingPath = new Map<string, any>();
        const arr = [
          {
            planner,
            planner_name: planner,
            tractorName,
            operatorName,
            avg_speed,
            created_date_time,
            points,
          },
        ];
        existingPath.set(id, arr);
        plannerTractorPaths.current = existingPath;
      }
    });
    drawPlannerTractorPath();
  };
  /**
   * Draw  planner Tractor Path ends Here
   */
  const setTractorPoints = (id: string, points: [number, number]) => {
    const existingPath = tractorPaths.current;
    if (existingPath) {
      let path = existingPath.get(id);
      if (path) {
        if (path.length > 5000) {
          path = path.splice(2500);
        }
        path?.push([points[0], points[1]]);
        existingPath.set(id, path);
        tractorPaths.current = existingPath;
      } else {
        existingPath.set(id, [points]);
        tractorPaths.current = existingPath;
      }
    } else {
      const existingPath = new Map<string, [number, number][]>();
      existingPath.set(id, [points]);
      tractorPaths.current = existingPath;
    }
    drawTractorPath();
  };

  const drawTractorPath = () => {
    const tractorStyle = new Style({
      image: new CircleStyle({
        radius: 3,
        fill: new Fill({
          color: pathColorConstants.DEFAULT,
        }),
      }),
      stroke: new Stroke({
        color: pathColorConstants.DEFAULT,
        width: globalZoom > onGlobalZoomToShowZoomIcons ? 9 : 3,
      }),
      fill: new Fill({
        color: pathColorConstants.DEFAULT,
      }),
      zIndex: 400,
    });

    const existingPath = tractorPaths.current;
    const defaultLayer = tractorPathLayerRef.current;
    defaultLayer?.setVisible(globalShowPath);
    defaultLayer?.getSource().clear();
    if (existingPath) {
      existingPath.forEach((value, key) => {
        let coordinates: Coordinate[] = [];
        value.forEach((item) => {
          coordinates.push(proj.fromLonLat([item[0], item[1]]));
        });
        if (coordinates.length > 2500) {
          coordinates = coordinates.splice(1000);
        }
        let tractorLocation: Feature<Geometry> = new Feature();
        if (defaultLayer) {
          tractorLocation = defaultLayer?.getSource().getFeatureById(key);
        }
        if (!tractorLocation) {
          tractorLocation = new Feature();
        }
        tractorLocation.setGeometry(new MultiPoint(coordinates));
        tractorLocation.setId(key);
        allTractorsList.forEach((feature: { mapId: string; name: any }) => {
          if (feature.mapId && key && feature.mapId === key) {
            tractorLocation.set('name', feature.name);
          }
        });
        tractorLocation.setStyle(tractorStyle);
        defaultLayer?.setZIndex(0);
        defaultLayer?.getSource().addFeature(tractorLocation);
      });
    }
  };

  const initTravelledPath = async (tractorId: number, tractors?: any[]) => {
    setRecenterTabId(Number(tractorId));
    setSelectedTractorId(Number(tractorId));
    //clearing jump and go path
    if (tractorTraveledPathLayerRef.current) {
      const defaultLayer = tractorTraveledPathLayerRef.current;
      defaultLayer?.getSource().clear();
    }
    //clearing jump and go Indicators
    if (mapIndicatorRefs.current) {
      const defaultLayer1 = mapIndicatorRefs.current;
      defaultLayer1?.getSource().clear();
      mapRef.current?.removeLayer(mapIndicatorRefs.current);
    }
    //clearing jump and go gurd rails
    if (mapGuardRailsRefs.current) {
      const defaultLayer = mapGuardRailsRefs.current;
      defaultLayer?.getSource()?.getSource()?.clear();
      // mapRef.current?.removeLayer(mapGuardRailsRefs.current);
    }
    // clearing guard rails from stream
    if (mapGuardRailsFromStreamRefs.current) {
      const defaultLayer = mapGuardRailsFromStreamRefs.current;
      defaultLayer?.getSource().clear();
      mapRef.current?.removeLayer(mapGuardRailsFromStreamRefs.current);
    }
    const tractorsGuardrail = tractorsGuardrailMap?.current;
    tractorsGuardrail && tractorsGuardrail.set(`${tractorId}`, []);
    if (tractorsGuardrail) tractorsGuardrailMap.current = tractorsGuardrail;
    // clearing indicators from stream
    if (mapIndicatorFromStreamRefs.current) {
      const defaultLayer = mapIndicatorFromStreamRefs.current;
      defaultLayer?.getSource().clear();
      mapRef.current?.removeLayer(mapIndicatorFromStreamRefs.current);
    }
    const tractorsIndicator = tractorsIndicatorMap?.current;
    tractorsIndicator && tractorsIndicator.set(`${tractorId}`, []);
    if (tractorsIndicator) tractorsIndicatorMap.current = tractorsIndicator;
    // clearing preview path
    // clearPreviewPath();
    // if no tractor dont get the data about tractor
    if (!tractorId) {
      return;
    }
    const [tractorDetails] = selectedTractors;
    if (tractorDetails && tractorDetails.id && tractorDetails.id == tractorId) {
      if (
        tractorDetails?.heartbeat &&
        tractorDetails?.heartbeat?.planning_manager &&
        tractorDetails?.heartbeat?.planning_manager.planner_name &&
        tractorDetails?.heartbeat?.drive_action_details &&
        tractorDetails?.heartbeat?.drive_action_details.current_drive_action
      ) {
        onAutoDriveStart(
          tractorDetails?.heartbeat?.drive_action_details?.current_drive_action,
          tractorDetails.id,
        );
      }
    }
    let allow = false;
    if (tractors && tractors.length) {
      for (const tractor of tractors) {
        const hB = tractor.heartbeat;
        if (
          hB &&
          hB.tractor_id == tractorId &&
          hB.drive_action_details &&
          hB.drive_action_details.current_drive_action &&
          hB.drive_action_details.current_drive_action.length > 0
        ) {
          allow = true;
        }
      }
    }
    if (!allow) return;
    try {
      if (userDetails && userDetails.organization) {
        const { organization } = userDetails;
        getIndicators(tractorId);
        getGuardRails(Number(tractorId));
        const response = await getTractorTraveledPointsBasedOnPlanner(
          organization.api_url,
          Number(tractorId),
        );
        // const response = await getDriveActionPath(
        //   organization.api_url,
        //   '16527400543380',
        // );
        if (response && response.records && response.records.length > 0) {
          let operatorName = '-';
          if (response?.operator) {
            const { first_name, last_name } = response?.operator;
            operatorName = first_name + ' ' + last_name;
          }
          drawJAG(response.records, response.tractor.name, operatorName);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  const getGuardRails = async (tractorId: number) => {
    if (tractorId) {
      try {
        const guardRails = await getTractorTraveledPointsGuardRails(
          userDetails.organization.api_url,
          tractorId,
        );
        setMapSnapshots(guardRails);
      } catch (error) {
        // error
      }
    }
  };
  useEffect(() => {
    if (mapSnapshots) {
      const defaultLayer = mapGuardRailsRefs.current;
      defaultLayer?.getSource()?.getSource()?.clear();
      mapSnapshots?.records?.map((snapshot: any) => {
        if (snapshot && snapshot.latitude && snapshot.longitude) {
          const coordinates = [snapshot.latitude, snapshot.longitude];
          let icon = SnapIcon;
          let name = 'SNAPSHOT';
          let scale = 1.5;
          if (snapshot.created_at === 'GUARD_RAILS') {
            icon = guardRails;
            name = 'Guard Rails';
            if (snapshot.guard_rail_type === 'human_protection') {
              icon = human_pt;
              name = 'Human Protection';
            } else if (snapshot.guard_rail_type === 'path_protection') {
              icon = path_pt;
              name = 'Path Protection';
            } else if (snapshot.guard_rail_type === 'pto_protection') {
              icon = pto_pt;
              name = 'Pto Protection';
            }
            scale = 0.7;
          }
          const style = new Style({
            image: new Icon({
              src: icon,
              scale,
            }),
            zIndex: 100,
          });
          const hoverStyle = new Style({
            image: new Icon({
              src: icon,
              scale: scale * 1.2,
            }),
            zIndex: 100,
          });
          const feature = new Feature({
            geometry: new Point(
              proj.fromLonLat([coordinates[1], coordinates[0]]),
            ),
            name: name,
            pointType: 'SNAP',
            content: snapshot,
            style,
            point: [coordinates[1], coordinates[0]],
          });
          feature.setStyle(style);
          feature.set('style', style);
          feature.set('hoverStyle', hoverStyle);
          defaultLayer?.getSource()?.getSource()?.addFeature(feature);
        }
      });
    }
  }, [mapSnapshots]);

  const drawJAG = (paths: any, tractorName: string, operatorName = '') => {
    const defaultLayer = tractorTraveledPathLayerRef.current;
    let lastPlannerLastPoint: any = null;
    let start = false;
    if (paths && paths.length > 0) {
      paths.map((point: any) => {
        let arrData: any = [];
        let pushIndex = 0;
        point.paths = [];
        point.path.slice(1).map((v: any, i: number) => {
          if (
            calPtsDistanceActualAllow([point.path[i], v]) &&
            ((i === 0 &&
              isGoodLocalizationInJumpAndGo(point.path[i]) &&
              isGoodLocalizationInJumpAndGo(v)) ||
              (i !== 0 && isGoodLocalizationInJumpAndGo(v)))
          ) {
            arrData.push(v);
            point.paths[pushIndex] = arrData;
          } else {
            if (arrData && arrData.length > 0) {
              pushIndex = pushIndex + 1;
            }
            arrData = [];
          }
        });
      });
    }
    paths.map((path1: any) => {
      path1.paths.map((path: any) => {
        const color = getPathColor(path1.planner);
        const borderColor = getPathBorderColor(path1.planner);
        const arr: any[] = [];
        if (lastPlannerLastPoint && path.length > 0) {
          if (calPtsDistanceActualAllow([lastPlannerLastPoint, path[0]])) {
            arr.push(
              proj.fromLonLat([
                lastPlannerLastPoint.longitude,
                lastPlannerLastPoint.latitude,
              ]),
            );
          } else {
            const coordinates = proj.fromLonLat([
              lastPlannerLastPoint.longitude,
              lastPlannerLastPoint.latitude,
            ]);
            const startingPathPoint = setBadLocalization(
              coordinates,
              globalShowBadLocalization,
            );
            defaultLayer?.getSource().addFeature(startingPathPoint);
          }
        }
        path.map(
          (pathPoint: {
            longitude: number;
            latitude: number;
            created_date_time: number;
            dTime: string;
            tractorName: string;
            operatorName: string;
          }) => {
            pathPoint.tractorName = tractorName;
            pathPoint.operatorName = operatorName || '-';
            pathPoint.dTime = getDateTime(pathPoint.created_date_time);
            arr?.push(
              proj.fromLonLat([pathPoint.longitude, pathPoint.latitude]),
            );
          },
        );
        let tractorLocation: Feature<any> = new Feature();
        if (!tractorLocation) {
          tractorLocation = new Feature();
        }
        tractorLocation.setGeometry(new LineString(arr));
        // path style
        const style = getPathFeatureStyle(
          path1.planner,
          globalZoom > onGlobalZoomToShowZoomIcons,
        );
        // const style1 = getPathStyle(
        //   color,
        //   borderColor,
        //   globalZoom > onGlobalZoomToShowZoomIcons,
        // );
        if (!start && arr.length > 0) {
          start = true;
          // set starting point
          const startingPathPoint = new Feature({
            geometry: new Point(arr[0]),
            color,
            borderColor,
            pointType: 'startPoint',
          });
          // set circle style
          const circleStyle = getCenterStyle(
            color,
            borderColor,
            globalZoom > onGlobalZoomToShowZoomIcons,
          );
          startingPathPoint.setStyle(circleStyle);
          defaultLayer?.getSource().addFeature(startingPathPoint);
        }
        tractorLocation.setStyle(style);
        tractorLocation.set('style', style);
        tractorLocation.set('hoverStyle', style);
        // color for in zoom and zoom out style
        tractorLocation.set('color', color);
        tractorLocation.set('borderColor', borderColor);
        tractorLocation.set('name', tractorName);
        tractorLocation.set('drawPointsText', { path });
        tractorLocation.set('pointType', 'PATH');
        tractorLocation.set('tractorId', '');
        defaultLayer?.getSource().addFeature(tractorLocation);
        // displayRecenterViewPortSetUp(tractorLocation, mapRef.current);
        if (arr && arr.length) {
          // lastPlannerLastPoint = arr[arr.length - 1];
          // good_localization = path[path.length - 1].good_localization;
          lastPlannerLastPoint = path[path.length - 1];
        }
      });
    });

    defaultLayer?.setVisible(globalShowPath);
  };
  const getIndicators = async (tractorId: number) => {
    if (tractorId) {
      try {
        if (mapIndicatorRefs.current) {
          mapRef.current?.removeLayer(mapIndicatorRefs.current);
        }
        const indicators = await getTractorTraveledPointsIndicators(
          userDetails.organization.api_url,
          tractorId,
        );
        drawIndicators(indicators);
      } catch (error) {
        // error
      }
    }
  };

  const drawIndicators = (indicators: any) => {
    const indicators_data = indicators[0]?.indicators_data;
    if (indicators_data && indicators_data.length > 0) {
      const features: Feature<any>[] = [];
      indicators_data.map((indicator: any) => {
        if (indicator && indicator.latitude && indicator.longitude) {
          const coordinates = [indicator.latitude, indicator.longitude];
          const feature = new Feature({
            geometry: new Point(
              proj.fromLonLat([coordinates[1], coordinates[0]]),
            ),
            name: indicator.indicator_name,
            pointType: 'INDICATOR',
            content: indicator,
            point: [coordinates[1], coordinates[0]],
          });
          features.push(feature);
        }
      });

      const source = new VectorSource({
        features: features,
      });
      const clusterSource = new Cluster({
        distance: 10,
        source: source,
      });
      const layer = new VectorLayer({
        source: clusterSource,
        zIndex: priority.INDICATOR,
        visible: globalShowDataLayers,
        style: function (feature: any) {
          const size = feature.get('features').length;
          let style = new Style({});
          if (size === 1) {
            const feat = feature.get('features');
            style = IndicatorStyle(1);
            feature.set('name', feat[0].get('name'));
            feature.set('pointType', feat[0].get('pointType'));
            feature.set('content', feat[0].get('content'));
          }
          if (size > 1) {
            const feat = feature.get('features');
            let names = '';
            feat.map((fe: any) => {
              names = names + fe.get('name') + ', ';
            });
            feature.set('name', names);
            style = IndicatorStyle(size);
          }
          return style;
        },
      });
      mapIndicatorRefs.current = layer;
      mapRef.current?.addLayer(layer);
    }
  };
  const onCreateTicket = () => {
    setTicketDetails(errorDetails);
    setTractorId(errorDetails?.tractor_id);
    setShowIndicatorModel(false);
    setToggleTicketWidget(true);
    mixpanel.track('CreateTicket', {
      event: 'Create Ticket',
    });
  };

  /**
   * Polygons initialization Starts Here
   */
  useEffect(() => {
    const init = async () => {
      try {
        const { organization } = userDetails;
        let recordsList = [];
        if (polygonsList && polygonsList.length > 0) {
          recordsList = polygonsList;
        } else {
          const response = await getPolygonsData(
            organization.api_url,
            organization.farm.id,
          );
          const { records } = response;
          recordsList =
            records && records.length && records.length > 0 ? records : [];
        }
        drawPolyAndPrepareLayers(
          recordsList,
          polygonsList.length > 0 ? false : true,
        );
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    if (userDetails && userDetails.organization && mapInitialized && base) {
      init();
    }
  }, [userDetails, mapInitialized, base]);

  const drawPolyAndPrepareLayers = async (polyList: any, save = true) => {
    const listItems: MapFeature[] = [];
    const greenPolyItems: MapFeature[] = [];
    polyList.map(async (record: any, index: number) => {
      let layer = null;
      if (record.color === 'white') {
        layer = whitePolygonsLayerRef.current;
      }
      if (record.color === 'green') {
        layer = greenPolygonsLayerRef.current;
      }
      if (record.color === 'blue') {
        layer = bluePolygonsLayerRef.current;
      }
      if (record.color === 'gray') {
        layer = grayPolygonsLayerRef.current;
      }
      if (record.color === 'brown') {
        layer = brownPolygonsLayerRef.current;
      }
      const { polygonFeature, polygonItem } = await drawPoly(
        userDetails,
        base,
        layer,
        1,
        1,
        record,
        false, // suggested as false
        !save, // call api to get vertices -> false call api , -> true don't call api
      );
      listItems.push(polygonItem as MapFeature);
      // assign for recenter the white field
      if (record.color === 'white') {
        fieldFeatureItem.current = polygonFeature;
        if (polygonFeature && !tractorObject) {
          // displayRecenterViewPortSetUp(
          //   polygonFeature as Feature<Geometry>,
          //   mapRef.current,
          // );
        }
      }
      if (record.color === 'green') {
        greenPolyItems.push(polygonItem as MapFeature);
      }
      if (polygonItem && polygonItem.vertices) {
        record.vertices = polygonItem.vertices;
      }
      if (polyList && polyList.length - 1 === index) {
        setPolygons(listItems);
        // polygons added to maintain app context start
        save &&
          appDispatch({
            type: SET_POLYGONS_LIST,
            payload: polyList,
          });
        // polygons added to maintain app context end

        setGreenPolygons(greenPolyItems);
      }
    });
    polygonHoverLabel(mapRef.current);
  };

  useEffect(() => {
    const init = async () => {
      try {
        const { layer, list }: { layer: any; list: MapFeature[] } =
          await getWaypoints(userDetails, base);
        list && list.length > 0 && setwaypointsForRemoteAv(list);

        if (layer) {
          mapRef.current?.addLayer(layer);
          waypointsLayerRef.current = layer;
        }
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
      try {
        const { layer }: { layer: any } = await getVineRows(userDetails, base);
        if (layer) {
          mapRef.current?.addLayer(layer);
          vineRowsLayerRef.current = layer;
        }
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    if (userDetails && userDetails.organization && mapInitialized && base) {
      init();
    }
  }, [userDetails, mapInitialized, base]);
  /**
   * Polygons initialization Ends Here
   */

  const getFeatureLayer = (type: string) => {
    let layer: VectorLayer<any> | any;
    if (type === 'TRACTOR') {
      layer = tractorLayerRef.current;
    } else if (type === 'WAYPOINT') {
      layer = waypointsLayerRef.current;
    } else if (type === 'IMPLEMENT') {
      layer = implementsLayerRef.current;
    } else if (type === 'VINEROW') {
      layer = vineRowsLayerRef.current;
    } else if (type === 'VINEBLOCK') {
      layer = vineBlocksLayerRef.current;
    } else if (type === 'BASESTATION') {
      layer = initialLayer.current;
    } else if (type === 'GREENPOLYGON') {
      layer = greenPolygonsLayerRef.current;
    } else if (type === 'WHITEPOLYGON') {
      layer = whitePolygonsLayerRef.current;
    } else if (type === 'GRAYPOLYGON') {
      layer = grayPolygonsLayerRef.current;
    } else if (type === 'BROWNPOLYGON') {
      layer = brownPolygonsLayerRef.current;
    } else if (type === 'BLUEPOLYGON') {
      layer = bluePolygonsLayerRef.current;
    } else if (type === 'INDICATOR') {
      layer = mapIndicatorRefs.current;
    } else if (type === 'PATH') {
      layer = planerTractorPathLayerRef.current;
    } else if (type === 'SNAP') {
      layer = mapGuardRailsRefs.current;
    } else if (type === 'SNAP_STREAM') {
      layer = mapGuardRailsFromStreamRefs.current;
    } else if (type === 'INDICATOR_STREAM') {
      layer = mapIndicatorFromStreamRefs.current;
    }

    return layer;
  };
  /** To toggle map layers from remoteAV popup */
  const handleToggleLayer = (featureLayerType: string, isVisible: boolean) => {
    const layer: VectorLayer<any> | any = getFeatureLayer(featureLayerType);
    if (layer) {
      layer.setVisible(isVisible);
      if (featureLayerType === 'TRACTOR') {
        tractorPathLayerRef?.current?.setVisible(isVisible);
        if (!isVisible) {
          tractorPathLayerRef.current
            ?.getSource()
            .getFeatures()
            .forEach((feature: any) => {
              tractorPathLayerRef.current?.getSource().removeFeature(feature);
            });
          const clear = tractorPaths.current;
          tractorPaths.current?.clear();
          tractorPaths.current = clear;

          // clear the planner layers points
          const clear1 = plannerTractorPaths.current;
          plannerTractorPaths.current?.clear();
          plannerTractorPaths.current = clear1;
        }
      }
    }
    if (featureLayerType === 'PATH') {
      planerTractorPathLayerRef?.current?.setVisible(isVisible);
      tractorPathLayerRef?.current?.setVisible(isVisible);
      tractorTraveledPathLayerRef?.current?.setVisible(isVisible);
      pathLayerRef?.current?.setVisible(isVisible);
    }
  };

  const handleLayerVisible = (zoom: number) => {
    handleToggleLayer('VINEROW', false);
    // displaying vine row when not goto Point / Block
    if (zoom >= 17 && !globalAddStep && setUpKeysGlobal.polygonUuid === '') {
      handleToggleLayer('VINEROW', true);
    }
    toggleVineRowStyle(
      vineRowsLayerRef.current,
      toggleSatellite,
      globalZoom >= onGlobalZoomToShowZoomIcons,
    );
    if (planerTractorPathLayerRef && planerTractorPathLayerRef.current) {
      togglePathStyle(
        planerTractorPathLayerRef.current,
        zoom >= onGlobalZoomToShowZoomIcons,
      );
    }
    if (tractorTraveledPathLayerRef && tractorTraveledPathLayerRef.current) {
      togglePathStyle(
        tractorTraveledPathLayerRef.current,
        zoom >= onGlobalZoomToShowZoomIcons,
      );
    }
    if (pointLayerRef && pointLayerRef.current) {
      togglePathStyle(
        pointLayerRef.current,
        zoom >= onGlobalZoomToShowZoomIcons,
      );
    }
  };

  const selectItems = (fleet: boolean) => {
    mixpanel.track('Live Map', {
      event: `Data Layers Fleet Checked ${fleet}`,
    });
    setShowBasestations(fleet);
    setShowTractors(fleet);
  };
  useEffect(() => {
    if (showTractors && showBasestations) {
      setFleet(true);
    } else {
      if (!showTractors || !showBasestations) {
        setFleet(false);
      }
    }
  }, [showTractors, showBasestations]);

  const recenterTractor = (tractorId: number) => {
    const layer: VectorLayer<any> | any = getFeatureLayer('TRACTOR');
    if (layer) {
      layer.setVisible(true);
      setShowTractors(true);
      const feat = layer.getSource();
      const feature: any = feat
        .getSource()
        .getFeatureById(`PoseModel_streaming_${tractorId}`);
      if (feature) {
        const latLang = feature.get('latLang');
        const view = mapRef.current?.getView();
        if (latLang) {
          mapRef.current?.getView().setCenter(proj.fromLonLat(latLang));
        }
        if (view) {
          // view.setZoom(22); // 2 Meters
          // view.setZoom(19); // 10 Meters
          // view.setZoom(18);
          view.setZoom(autoDrive ? 20 : 19);
        }
      }
    }
  };
  useEffect(() => {
    if (recenterTabId && mapInitialized && tractorsInitialized) {
      recenterTractor(recenterTabId);
    }
  }, [recenterTabId, mapInitialized, tractorsInitialized]);
  const recenterXY = (coordinates: any) => {
    coordinates = proj.transform(coordinates, 'EPSG:3857', 'EPSG:4326');
    coordinates &&
      coordinates.length > 0 &&
      mapRef?.current?.getView().setCenter(proj.fromLonLat(coordinates));
  };
  const recenterItem = (mapFeature: MapFeature) => {
    if (mapRef.current) {
      if (mapFeature.type === 'TRACTOR') {
        const layer: VectorLayer<any> | any = getFeatureLayer(mapFeature.type);
        layer?.setVisible(true);
        setShowTractors(true);
        const feat: any = layer?.getSource();
        const tractorFeature: any = feat
          .getSource()
          .getFeatureById(mapFeature.mapId);
        const point = tractorFeature.getGeometry().getCoordinates();
        const coordinates = proj.transform(point, 'EPSG:3857', 'EPSG:4326');
        mapRef.current.getView().setCenter(proj.fromLonLat(coordinates));
      } else if (
        mapFeature.type === 'WAYPOINT' ||
        mapFeature.type === 'BASESTATION'
      ) {
        const layer: VectorLayer<any> | any = getFeatureLayer(mapFeature.type);
        layer.setVisible(true);
        let feature;
        if (mapFeature.type === 'BASESTATION') {
          setShowBasestations(true);
          feature = layer.getSource().getFeatureById(mapFeature.mapId);
        }
        if (mapFeature.type === 'WAYPOINT') {
          const source: any = layer.getSource();
          feature = source.getSource().getFeatureById(mapFeature.mapId);
        }

        if (feature) {
          const point = feature.getGeometry().getCoordinates();
          if (point) {
            const coordinates = proj.transform(point, 'EPSG:3857', 'EPSG:4326');
            coordinates &&
              coordinates.length > 0 &&
              mapRef.current.getView().setCenter(proj.fromLonLat(coordinates));
          }
        }
      } else if (mapFeature.type === 'POLYGON') {
        const greenPolyLayer = getFeatureLayer('GREENPOLYGON');
        const whitePolyLayer = getFeatureLayer('WHITEPOLYGON');
        const grayPolyLayer = getFeatureLayer('GRAYPOLYGON');
        const brownPolyLayer = getFeatureLayer('BROWNPOLYGON');
        if (greenPolyLayer) {
          const greenFeature = greenPolyLayer
            ?.getSource()
            ?.getFeatureById(mapFeature.mapId);
          const whiteFeature = whitePolyLayer
            ?.getSource()
            ?.getFeatureById(mapFeature.mapId);
          const grayFeature = grayPolyLayer
            ?.getSource()
            ?.getFeatureById(mapFeature.mapId);
          const brownFeature = brownPolyLayer
            ?.getSource()
            ?.getFeatureById(mapFeature.mapId);
          if (greenFeature) {
            setShowGreenPolygons(true);
            greenPolyLayer?.setVisible(true);
          }
          if (whiteFeature) {
            setShowWhitePolygons(true);
            whitePolyLayer?.setVisible(true);
          }
          if (grayFeature) {
            setShowGreenPolygons(true);
            grayPolyLayer?.setVisible(true);
          }
          if (brownFeature) {
            setShowBrownPolygons(true);
            brownPolyLayer?.setVisible(true);
          }
          if (greenFeature || whiteFeature || grayFeature || brownFeature) {
            const f = greenFeature
              ? greenFeature
              : whiteFeature
              ? whiteFeature
              : grayFeature
              ? grayFeature
              : brownFeature;
            displayRecenterViewPortSetUp(
              f as Feature<Geometry>,
              mapRef.current,
            );
          }
        }
      }
    }
  };
  // };

  useEffect(() => {
    handleToggleLayer('BASESTATION', showBasestations);
  }, [showBasestations]);

  useEffect(() => {
    handleToggleLayer('TRACTOR', showTractors);
  }, [showTractors]);

  useEffect(() => {
    handleToggleLayer('WAYPOINT', showWaypoints);
  }, [showWaypoints]);

  useEffect(() => {
    handleToggleLayer('GREENPOLYGON', showGreenPolygons);
  }, [showGreenPolygons]);

  useEffect(() => {
    handleToggleLayer('BROWNPOLYGON', showBrownPolygons);
  }, [showBrownPolygons]);

  useEffect(() => {
    handleToggleLayer('WHITEPOLYGON', showWhitePolygons);
  }, [showWhitePolygons]);

  useEffect(() => {
    handleToggleLayer('GRAYPOLYGON', showGrayPolygons);
  }, [showGrayPolygons]);

  useEffect(() => {
    handleToggleLayer('BLUEPOLYGON', showBluePolygons);
  }, [showBluePolygons]);

  const onAutoDriveStart = (uuid: string, tractorId: number) => {
    const { organization } = userDetails;
    getPreviewPath(organization.api_url, uuid)
      .then((response: { data: any }) => {
        handlePreview(response.data, tractorId);
      })
      .catch((err) => {
        notification.error({
          message: err.response?.data.error.message,
        });
      });
  };

  const clearPreviewPath = (id: number) => {
    const feature = pathLayerRef?.current?.getSource().getFeatureById(id);
    feature &&
      pathLayerRef &&
      pathLayerRef?.current?.getSource()?.removeFeature(feature);
  };
  const handlePreview = (data: string, tractorId: number) => {
    let tractorName = '';
    allTractorsList.forEach((feature: { id: number; name: any }) => {
      if (feature.id && tractorId && feature.id == tractorId) {
        tractorName = feature.name;
      }
    });
    const latlongTextList = data.split('|');
    const coordinates: Coordinate[] = [];
    latlongTextList.forEach((record: any) => {
      const latlong = record?.split(',');
      coordinates.push(proj.fromLonLat([latlong[1], latlong[0]]));
    });

    const tractorLocationFeature: Feature<Geometry> = new Feature();
    tractorLocationFeature.setGeometry(new LineString(coordinates));

    const style = [
      new Style({
        stroke: new Stroke({
          color: getPathBorderColor(pathConstants.PREVIEW_PATH),
          width: 10,
        }),
        zIndex: 100,
      }),
      new Style({
        stroke: new Stroke({
          color: getPathColor(pathConstants.PREVIEW_PATH),
          width: 8,
        }),
        zIndex: 101,
      }),
    ];
    // const style = getPathFeatureStyle(
    //   pathConstants.PREVIEW_PATH,
    //   globalZoom > onGlobalZoomToShowZoomIcons,
    // );
    // Path Ahead Fill #F1CE90 Stroke #E9881F
    tractorLocationFeature.setStyle(style);
    tractorLocationFeature.setId(tractorId);
    tractorLocationFeature.set('name', `${tractorName} Preview path`);
    tractorLocationFeature.set('tractorId', tractorId);
    pathLayerRef.current?.getSource().addFeature(tractorLocationFeature);
    pathLayerRef.current?.setVisible(globalShowPath);
  };

  useEffect(() => {
    if (!showIndicatorModel) {
      toggleForIndicator = false;
    }
  }, [showIndicatorModel]);

  const onCloseTicketWidget = () => {
    setShowIndicatorModel(false);
    setToggleTicketWidget(false);
  };

  const selectFarmItems = (farm: boolean) => {
    setShowWaypoints(farm);
    setShowGreenPolygons(farm);
    setShowWhitePolygons(farm);
    setShowGrayPolygons(farm);
    setShowBluePolygons(farm);
    setShowBrownPolygons(farm);
  };
  useEffect(() => {
    handleToggleLayer('INDICATOR', showDataLayers);
    handleToggleLayer('SNAP', showDataLayers);
    handleToggleLayer('INDICATOR_STREAM', showDataLayers);
    handleToggleLayer('SNAP_STREAM', showDataLayers);
    globalShowDataLayers = showDataLayers;
  }, [showDataLayers]);

  useEffect(() => {
    handleToggleLayer('PATH', showPath);
    globalShowPath = showPath;
  }, [showPath]);

  useEffect(() => {
    globalShowBadLocalization = showBadLocalization;
    if (tractorTraveledPathLayerRef.current) {
      const defaultLayer = tractorTraveledPathLayerRef.current;
      const features = defaultLayer.getSource().getFeatures();
      if (features && features.length > 0) {
        features?.map((feature: any) => {
          if (feature.get('pointType') == BadLocalization) {
            if (showBadLocalization) {
              feature.setStyle(feature.get('style'));
            } else {
              feature.setStyle(new Style({})); // to hide the item
            }
          }
        });
      }
    }
  }, [showBadLocalization]);

  useEffect(() => {
    if (
      showWaypoints &&
      showGreenPolygons &&
      showWhitePolygons &&
      showGrayPolygons &&
      showBrownPolygons &&
      showBluePolygons
    ) {
      setFarm(true);
    } else {
      if (
        !showWaypoints ||
        !showGreenPolygons ||
        !showWhitePolygons ||
        !showGrayPolygons ||
        !showBrownPolygons ||
        !showBluePolygons
      ) {
        setFarm(false);
      }
    }
  }, [
    showWaypoints,
    showGreenPolygons,
    showWhitePolygons,
    showGrayPolygons,
    showBrownPolygons,
    showBluePolygons,
  ]);

  // added to maintain the selected tarctorid
  useEffect(() => {
    globalSelectedTractorId = selectedTractorId;
    selectedOnTractor();
  }, [selectedTractorId]);

  const selectedOnTractor = () => {
    const features = tractorLayerRef?.current
      ?.getSource()
      ?.getSource()
      ?.getFeatures();
    if (features) {
      features.map((feature: any) => {
        if (feature) {
          const tractorId = Number(
            feature.getId().replace('PoseModel_streaming_', ''),
          );
          const isSelected = tractorId == globalSelectedTractorId;
          const pointTypeSelected = feature.get('pointTypeSelected');
          const pointTypeBearing: any = feature.get('pointTypeBearing');
          const pointTypeBattery: any = feature.get('pointTypeBattery');
          const pointTypeIni: any = feature.get('pointTypeIni');
          const tractorName: any = feature.get('name');
          const isFaulty = feature.get('isFaulty') ? true : false;
          const pointTypeIsSelectedForToolTip = isSelectedTractor(
            globalSelectedTractors,
            tractorId,
          );
          const pointTypeIsMoving = feature.get('pointTypeIsMoving')
            ? true
            : false;
          const pointTypeLowLocalization = feature.get(
            'pointTypeLowLocalization',
          )
            ? true
            : false;
          let style = null;
          let hoverStyle = null;
          let onZoomStyle = null;
          let onZoomHoverStyle = null;
          if (pointTypeSelected) {
            const onZoom = globalZoom > onGlobalZoomToShowZoomIcons;
            style = getTractorIcon(
              pointTypeSelected,
              false, // with out hover
              pointTypeBearing,
              pointTypeIni,
              isSelected,
              pointTypeIsMoving,
              onZoom,
              pointTypeBattery,
              pointTypeLowLocalization,
              tractorName,
              pointTypeIsSelectedForToolTip,
              tractorId,
              isFaulty,
            );
            hoverStyle = getTractorIcon(
              pointTypeSelected,
              true, // with hover
              pointTypeBearing,
              pointTypeIni,
              isSelected,
              pointTypeIsMoving,
              onZoom,
              pointTypeBattery,
              pointTypeLowLocalization,
              tractorName,
              pointTypeIsSelectedForToolTip,
              tractorId,
              isFaulty,
            );
            // on zoom the online icon
            onZoomStyle = getTractorIcon(
              pointTypeSelected,
              false, // with out hover
              pointTypeBearing,
              pointTypeIni,
              isSelected,
              pointTypeIsMoving,
              onZoom,
              pointTypeBattery,
              pointTypeLowLocalization,
              tractorName,
              pointTypeIsSelectedForToolTip,
              tractorId,
              isFaulty,
            );
            // on zoom hover the online icon
            onZoomHoverStyle = getTractorIcon(
              pointTypeSelected,
              true, // with hover
              pointTypeBearing,
              pointTypeIni,
              isSelected,
              pointTypeIsMoving,
              onZoom,
              pointTypeBattery,
              pointTypeLowLocalization,
              tractorName,
              pointTypeIsSelectedForToolTip,
              tractorId,
              isFaulty,
            );
          }
          feature.setStyle(style);
          feature.set('style', style);
          feature.set('hoverStyle', hoverStyle);
          feature.set('onZoomStyle', onZoomStyle);
          feature.set('onZoomHoverStyle', onZoomHoverStyle);
          setSelectedTractorLoc(tractorId, feature);
        }
      });
    }
  };

  const drawGuardRailsFromStream = () => {
    if (mapGuardRailsFromStreamRefs.current) {
      const defaultLayer = mapGuardRailsFromStreamRefs.current;
      defaultLayer?.getSource().clear();
      mapRef.current?.removeLayer(mapGuardRailsFromStreamRefs.current);
    }
    const existingItems = tractorsGuardrailMap.current;
    const records = existingItems?.get(`${globalSelectedTractorId}`);
    if (records && records.length > 0) {
      const guardRailsfeatures: Feature<any>[] = [];
      records?.map((snapshot: any) => {
        snapshot = { ...snapshot, ...snapshot.data };
        if (
          snapshot &&
          snapshot.latitude &&
          snapshot.longitude &&
          snapshot.title === 'guard_rails'
        ) {
          const coordinates = [
            Number(snapshot.latitude),
            Number(snapshot.longitude),
          ];
          let icon = guardRails;
          let name = 'Guard Rails';
          if (
            snapshot &&
            snapshot.guard_rail_type &&
            snapshot.guard_rail_type === 'human_protection'
          ) {
            icon = human_pt;
            name = 'Human Protection';
          } else if (
            snapshot &&
            snapshot.guard_rail_type &&
            snapshot.guard_rail_type === 'path_protection'
          ) {
            icon = path_pt;
            name = 'Path Protection';
          } else if (
            snapshot &&
            snapshot.guard_rail_type &&
            snapshot.guard_rail_type === 'pto_protection'
          ) {
            icon = pto_pt;
            name = 'Pto Protection';
          }
          const scale = 0.7;
          const style = new Style({
            image: new Icon({
              src: icon,
              scale,
            }),
            zIndex: 100,
          });
          const hoverStyle = new Style({
            image: new Icon({
              src: icon,
              scale: scale * 1.2,
            }),
            zIndex: 100,
          });
          const feature = new Feature({
            geometry: new Point(
              proj.fromLonLat([coordinates[1], coordinates[0]]),
            ),
            name: name,
            pointType: 'SNAP',
            content: snapshot,
            style,
            point: [coordinates[1], coordinates[0]],
          });
          feature.setStyle(style);
          feature.set('style', style);
          feature.set('hoverStyle', hoverStyle);
          guardRailsfeatures.push(feature);
        }
      });
      // gaurd rails features
      const guardRailssource = new VectorSource({
        features: guardRailsfeatures,
      });
      const GuardRailsClusterSource = new Cluster({
        distance: 10,
        source: guardRailssource,
      });
      const guardRailslayer = new VectorLayer({
        source: GuardRailsClusterSource,
        visible: globalShowDataLayers,
        zIndex: priority.SNAP,
        style: function (feature: any) {
          return styleFun(feature, guardRailsCluster);
        },
      });
      mapGuardRailsFromStreamRefs.current = guardRailslayer;
      mapRef.current?.addLayer(guardRailslayer);
    }
  };

  const drawIndicatorsFromStreams = () => {
    if (mapIndicatorFromStreamRefs.current) {
      const defaultLayer = mapIndicatorFromStreamRefs.current;
      defaultLayer?.getSource().clear();
      mapRef.current?.removeLayer(mapIndicatorFromStreamRefs.current);
    }
    const existingItems = tractorsIndicatorMap.current;
    const records = existingItems?.get(`${globalSelectedTractorId}`);
    // const indicators_data = indicators[0]?.indicators_data;
    if (records && records.length > 0) {
      const features: Feature<any>[] = [];
      records.map((indicator: any) => {
        indicator = { ...indicator, ...indicator.data };
        if (indicator && indicator.latitude && indicator.longitude) {
          const coordinates = [
            Number(indicator.latitude),
            Number(indicator.longitude),
          ];
          const feature = new Feature({
            geometry: new Point(
              proj.fromLonLat([coordinates[1], coordinates[0]]),
            ),
            name: indicator.indicator_name,
            pointType: 'INDICATOR',
            content: indicator,
            point: [coordinates[1], coordinates[0]],
          });
          features.push(feature);
        }
      });

      const source = new VectorSource({
        features: features,
      });
      const clusterSource = new Cluster({
        distance: 10,
        source: source,
      });
      const layer = new VectorLayer({
        source: clusterSource,
        zIndex: priority.INDICATOR,
        visible: globalShowDataLayers,
        style: function (feature: any) {
          const size = feature.get('features').length;
          let style = new Style({});
          if (size === 1) {
            const feat = feature.get('features');
            style = IndicatorStyle(1);
            feature.set('name', feat[0].get('name'));
            feature.set('pointType', feat[0].get('pointType'));
            feature.set('content', feat[0].get('content'));
          }
          if (size > 1) {
            const feat = feature.get('features');
            let names = '';
            feat.map((fe: any) => {
              names = names + fe.get('name') + ', ';
            });
            feature.set('name', names);
            style = IndicatorStyle(size);
          }
          return style;
        },
      });
      mapIndicatorFromStreamRefs.current = layer;
      mapRef.current?.addLayer(layer);
    }
  };

  useEffect(() => {
    setUpKeysGlobal = setUpKeys;
    if (setUpKeys && setUpKeys.polygonUuid === '') {
      setPreCommandState('');
      // dispatch({
      //   type: SELECTED_VINEBLOCK,
      //   payload: '',
      // });
    } else {
      // getVineBlockNameHandler();
    }
  }, [setUpKeys]);

  // const getVineBlockNameHandler = async () => {
  //   setLoader(true);
  //   try {
  //     const { organization } = userDetails;
  //     const data = await getVineBlockName(
  //       organization.api_url,
  //       organization.farm.id,
  //       setUpkeysGlobal.polygonUuid,
  //     );
  //     // vineBlocks = data;
  //     setVineBlocks(data);
  //   } catch (error: any) {
  //     notification.error({
  //       message: error.response?.data.error.message,
  //     });
  //   } finally {
  //     setLoader(false);
  //   }
  // };

  const drawPoint = (coordinates: any) => {
    const pointLocation = new Feature({
      geometry: new Point(proj.fromLonLat(coordinates)),
    });
    const goToPointStyleChange = new Style({
      image: new Icon({
        src: goToPoint,
        scale: 0.2,
        rotation: ((90 - angleDegrees) * Math.PI) / 180,
      }),
    });
    pointLocation.setStyle(goToPointStyleChange);
    pointLocation.set('coordinates', coordinates);
    pointLayerRef.current?.setSource(
      new VectorSource({
        features: [pointLocation],
      }),
    );
    pointLayerRef.current?.setVisible(true);
    const wayDrag = new Modify({
      features: new Collection([pointLocation]),
      style: new Style({}),
    });
    coordinates &&
      coordinates.length > 0 &&
      mapRef.current?.getView().setCenter(proj.fromLonLat(coordinates));
    // Add the event to the drag and drop feature
    wayDrag.on('modifystart', function (evt: any) {
      goToPointMove = true;
      const arr: any = evt.features.getArray();
      if (arr && arr.length && arr[arr.length - 1]) {
        goToPointFeature = arr[arr.length - 1];
      }
    });
    wayDrag.on('modifyend', function () {
      goToPointMove = false;
      goToPointFeature = null;
      // if (!apiCall) {
      // addRVCommand(pointCordinateGlobal[0], pointCordinateGlobal[1]);
      // }
    });
    mapRef.current?.addInteraction(wayDrag);
  };

  useEffect(() => {
    mapRef.current?.on('pointermove', (event: any) => {
      if (goToPointMove) {
        if (goToPointFeature) {
          const latLong2 = goToPointFeature.get('coordinates');
          goToPointFeature.setGeometry(new Point(proj.fromLonLat(latLong2)));
          const lonLat1 = proj.transform(
            event.coordinate,
            'EPSG:3857',
            'EPSG:4326',
          ); // , 'EPSG:4326'
          const xyCoordinates = getRAVXYCoordinates(
            [lonLat1[0], lonLat1[1]],
            globalBase,
          );
          const originalPointPosition = [];
          originalPointPosition.push(parseFloat(xyCoordinates[0].toFixed(2)));
          originalPointPosition.push(parseFloat(xyCoordinates[1].toFixed(2)));
          const xyCoordinates2 = getRAVXYCoordinates(
            [latLong2[0], latLong2[1]],
            globalBase,
          );
          const originalPointPosition2 = [];
          originalPointPosition2.push(parseFloat(xyCoordinates2[0].toFixed(2)));
          originalPointPosition2.push(parseFloat(xyCoordinates2[1].toFixed(2)));
          const dx = originalPointPosition[0] - originalPointPosition2[0];
          const dy = originalPointPosition[1] - originalPointPosition2[1];
          const rotation = Math.atan2(dy, dx);
          angleDegrees = rotation * (180 / Math.PI);
          angleDegrees = Math.trunc(angleDegrees);
          const goToPointStyleChange = new Style({
            image: new Icon({
              src: goToPoint,
              scale: 0.2,
              rotation: ((90 - angleDegrees) * Math.PI) / 180,
            }),
          });
          if (!apiCall) {
            goToPointFeature.setStyle(goToPointStyleChange);
          }
        }
      }
    });
  }, [base, mapInitialized]);

  const addRVCommand = async () => {
    setLoader(true);
    const x = pointCoordinateGlobalForApiCall[0];
    const y = pointCoordinateGlobalForApiCall[1];
    apiCall = true;
    try {
      const { organization } = userDetails;
      if (organization && organization.api_url) {
        const commandData: any = {
          command: 'goto_point',
          x,
          y,
          heading: angleDegrees,
          orientation: 'center',
          speed,
          implement: selectedImplementName ? selectedImplementName : 'None',
        };
        await addRemoteAVCommand(
          organization.api_url,
          [commandData],
          selectedTractor?.heartbeat?.drive_action_details
            ?.current_drive_action as string,
        );
        commandData.name = selectedItemNameGlobal;
        commandData.pointCordinate = pointCoordinateGlobalForDrawPoint;
        selectedTractor &&
          selectedTractor.id &&
          saveCommandTOLocalDB(selectedTractor.id, commandData);
        resetForMapClicks('');
      }
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };
  // const addRVRoutineCommand = async () => {
  //   setLoader(true);
  //   try {
  //     const { organization } = userDetails;
  //     console.log('selectedImplementName', selectedImplementName);
  //     console.log('selectedItemNameGlobal', selectedItemNameGlobal);
  //     if (organization && organization.api_url) {
  //       const commandData: any = {
  //         command: 'routine',
  //         routine: selectedItemNameGlobal,
  //         implement: selectedImplementName ? selectedImplementName : 'None',
  //       };
  //       await addRemoteAVCommand(
  //         organization.api_url,
  //         [commandData],
  //         selectedTractor?.heartbeat?.drive_action_details
  //           ?.current_drive_action as string,
  //       );
  //       commandData.name = selectedItemNameGlobal;
  //       selectedTractor &&
  //         selectedTractor.id &&
  //         saveCommandTOLocalDB(selectedTractor.id, commandData);
  //       resetForMapClicks('');
  //     }
  //   } catch (err: any) {
  //     notification.error({
  //       message: err.response?.data.error.message,
  //     });
  //   } finally {
  //     setLoader(false);
  //   }
  // };
  /**
   * Vine lanes initialization Starts Here
   */

  const getVineLanes = async (uuid: string) => {
    // if (vineLaneLayerRef.current) {
    //   mapRef.current?.removeLayer(vineLaneLayerRef.current);
    // }
    vineLaneLayerRef.current?.getSource().clear();
    try {
      const vineLaneFeatures: Feature<Geometry>[] = [];
      const pointAFeatures: Feature<Geometry>[] = [];
      const pointBFeatures: Feature<Geometry>[] = [];
      const vineRowFeatures: Feature<Geometry>[] = [];
      const arcFeatures: Feature<Geometry>[] = [];
      let zoomLatLong = [0, 0];

      let coordinates: any = [];
      const style = new Style({
        stroke: new Stroke({
          // color: 'rgba(0, 128, 0, 0.5)',
          color: '#92B799',
          width: 5,
        }),
        // fill: new Fill({
        //   // color: 'rgba(0, 128, 0, 0.5)',
        //   color: '#92B799DB',
        // }),
      });
      // const blueDotStyleB = new Style({
      //   image: new Icon({
      //     src: playIcon,
      //     rotation: -123,
      //   }),
      // });
      // const blueDotStyleA = new Style({
      //   image: new Icon({
      //     src: playIcon,
      //     rotation: 123,
      //   }),
      // });
      const blueDotStyle = new Style({
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: '#8595CA',
          }),
          fill: new Fill({
            color: '#8595CA',
          }),
        }),
        zIndex: 10,
      });
      const dottedList: { A: [number, number]; B: [number, number] }[] = [];
      const laneFilterContent = setUpKeys.laneFilterContent
        ? setUpKeys.laneFilterContent
        : [];
      laneFilterContent.map((vineLane: any, index: number) => {
        const id = `vineLane_${vineLane.id}`;
        coordinates = [];
        vineLane.vertices.map((pointData: any) => {
          pointData.xNew = pointData.x / 100;
          pointData.yNew = pointData.y / 100;
          const latAndLong: [number, number] = getLatitudeAndLongitude(
            [pointData.yNew, pointData.xNew],
            base,
          );
          if (index === 0) {
            zoomLatLong = latAndLong;
          }
          coordinates.push(proj.fromLonLat([latAndLong[1], latAndLong[0]]));
        });
        if (coordinates && coordinates.length > 0) {
          const vinelanePoly = new Feature({
            type: 'Polygon',
            geometry: new Polygon([coordinates]),
            pointId: vineLane.id,
            name: vineLane.name,
            isVineLaneRow: true,
          });

          vinelanePoly.setId(id);
          vinelanePoly.set('id', vineLane.id);
          vinelanePoly.set('data', vineLane);
          vineLaneFeatures.push(vinelanePoly);
        }
      });
      const temporaryArray: any = [];
      let indexTemp = 0;
      const everyFilter = setUpKeys.everyFilter > 0 ? setUpKeys.everyFilter : 1;
      let startIndex = setUpKeys.startIndex;
      if (setUpKeys.startIndexUuid !== '') {
        laneFilterContent.map((content: any, index: number) => {
          if (content.vine_lane_uuid === setUpKeys.startIndexUuid)
            startIndex = index;
        });
        if (startIndex > laneFilterContent.length / 2) {
          laneFilterContent.reverse();
          laneFilterContent.map((content: any, index: number) => {
            if (content.vine_lane_uuid === setUpKeys.startIndexUuid)
              startIndex = index;
          });
        }
      }
      for (let i = startIndex; i < laneFilterContent.length; i += everyFilter) {
        // Add two to i every iteration
        if (
          setUpKeys.startWith &&
          (setUpKeys.startWith === 'A' || setUpKeys.startWith === 'B') &&
          setUpKeys.isFirstTime
        ) {
          if (indexTemp === 0) {
            setLeftVineRowName(laneFilterContent[i].left_row_name);
            setRightVineRowName(laneFilterContent[i].right_row_name);
            temporaryArray.push(laneFilterContent[i]); // Add the element at index i to a temporary array
          }
        } else {
          temporaryArray.push(laneFilterContent[i]); // Add the element at index i to a temporary array
        }
        indexTemp += 1;
      }
      // laneFilterContent.forEach((vineLane: any, index: number) => {
      //   const vineRow = [];
      //   if (vineLane && vineLane.vertices.length > 0) {
      //     const latAndLong1: [number, number] = getLatitudeAndLongitude(
      //       [vineLane.head_a_y / 100, vineLane.head_a_x / 100],
      //       base,
      //     );
      //     const latAndLong2: [number, number] = getLatitudeAndLongitude(
      //       [vineLane.head_b_y / 100, vineLane.head_b_x / 100],
      //       base,
      //     );

      //     vineRow.push(proj.fromLonLat([latAndLong1[1], latAndLong1[0]]));
      //     vineRow.push(proj.fromLonLat([latAndLong2[1], latAndLong2[0]]));
      //     const vineLocation = new Feature({
      //       geometry: new LineString(vineRow),
      //       pointType: 'vinerow',
      //       name: vineLane.name,
      //     });
      //     const id3 = `vineLane_vineRow_1_${vineLane.id}`;
      //     const rowStyle = new Style({
      //       stroke: new Stroke({
      //         // color: 'blue',
      //         color: '#4C7548',
      //         width: 10,
      //       }),
      //       fill: new Fill({
      //         color: 'rgba(180, 130, 130, 0.85)',
      //       }),
      //     });
      //     vineLocation.setStyle(rowStyle);
      //     vineLocation.setId(id3);

      //     vineRowFeatures.push(vineLocation);
      //   }
      // });
      temporaryArray.forEach((vineLane: any, index: number) => {
        const vineRow = [];
        if (vineLane && vineLane.vertices.length > 0) {
          const id1 = `vineLane_Head_A_${vineLane.id}`;

          const latAndLong1: [number, number] = getLatitudeAndLongitude(
            [vineLane.head_a_y / 100, vineLane.head_a_x / 100],
            base,
          );
          const pointLocation1 = new Feature({
            geometry: new Point(
              proj.fromLonLat([latAndLong1[1], latAndLong1[0]]),
            ),
            name: 'Click here to select Head A',
            head_name: 'A',
            headLatLong: latAndLong1,
            data: vineLane,
            pointType: types.VINELANEHEAD,
            startIndex: index,
            startIndexUuid: vineLane.vine_lane_uuid,
          });
          pointLocation1.setId(id1);
          pointLocation1.setStyle(blueDotStyle);
          const id2 = `vineLane_Head_B_${vineLane.id}`;
          const latAndLong2: [number, number] = getLatitudeAndLongitude(
            [vineLane.head_b_y / 100, vineLane.head_b_x / 100],
            base,
          );
          const pointLocation2 = new Feature({
            geometry: new Point(
              proj.fromLonLat([latAndLong2[1], latAndLong2[0]]),
            ),
            name: 'Click here to select Head B',
            head_name: 'B',
            headLatLong: latAndLong2,
            data: vineLane,
            pointType: types.VINELANEHEAD,
            startIndex: index,
            startIndexUuid: vineLane.vine_lane_uuid,
          });
          pointLocation2.setStyle(blueDotStyle);
          pointLocation2.setId(id2);

          vineRow.push(proj.fromLonLat([latAndLong1[1], latAndLong1[0]]));
          vineRow.push(proj.fromLonLat([latAndLong2[1], latAndLong2[0]]));
          const vineLocation = new Feature({
            geometry: new LineString(vineRow),
            pointType: 'vinerow',
            name: vineLane.name,
          });
          const id3 = `vineLane_vineRow_${vineLane.id}`;
          const rowStyle = new Style({
            stroke: new Stroke({
              // color: 'blue',
              color: '#546CB2',
              width: 10,
            }),
            fill: new Fill({
              color: 'rgba(180, 130, 130, 0.85)',
            }),
          });
          vineLocation.setStyle(rowStyle);
          vineLocation.setId(id3);

          dottedList.push({ A: latAndLong1, B: latAndLong2 });
          if (
            setUpKeys.startWith &&
            (setUpKeys.startWith === 'A' || setUpKeys.startWith === 'B')
          ) {
            vineRowFeatures.push(vineLocation);
          }
          // if (setUpKeys.startWith !== 'A' && setUpKeys.startWith !== 'B') {
          //   pointAFeatures.push(pointLocation1);
          //   pointBFeatures.push(pointLocation2);
          // }
          // if (index === 0) {
          //   if (setUpKeys.startWith) {
          //     if (setUpKeys.startWith === 'A') {
          //       pointAFeatures.push(pointLocation1);
          //     }
          //     if (setUpKeys.startWith === 'B') {
          //       pointBFeatures.push(pointLocation2);
          //     }
          //   }
          // }
          pointAFeatures.push(pointLocation1);
          pointBFeatures.push(pointLocation2);
        }
      });

      const jointDotts: any = [];
      if (setUpKeys.startWith) {
        if (setUpKeys.startWith === 'A') {
          dottedList.forEach(
            (
              dot: { A: [number, number]; B: [number, number] },
              index: number,
            ) => {
              if (index === 0) {
                jointDotts.push(dot.B);
              } else if (index % 2 === 0) {
                jointDotts.push(dot.A);
                jointDotts.push(dot.B);
              } else {
                jointDotts.push(dot.B);
                jointDotts.push(dot.A);
              }
            },
          );
        } else if (setUpKeys.startWith === 'B') {
          dottedList.forEach(
            (
              dot: { A: [number, number]; B: [number, number] },
              index: number,
            ) => {
              if (index === 0) {
                jointDotts.push(dot.A);
              } else if (index % 2 === 0) {
                jointDotts.push(dot.B);
                jointDotts.push(dot.A);
              } else {
                jointDotts.push(dot.A);
                jointDotts.push(dot.B);
              }
            },
          );
        }
        const multiPointContent = sliceIntoChunks(jointDotts, 2);
        multiPointContent.forEach((ct, index: number) => {
          if (ct && ct.length === 2) {
            const p1LonLat = [ct[0][1], ct[0][0]];
            const p2LonLat = [ct[1][1], ct[1][0]];

            const p1 = proj.fromLonLat(p1LonLat);
            const p2 = proj.fromLonLat(p2LonLat);

            const x1 = p1[0];
            const x2 = p2[0];
            const y1 = p1[1];
            const y2 = p2[1];

            const y3 = (y1 + y2) / 2;
            const x3 = (x1 + x2) / 2;

            const b = proj.transform(base, 'EPSG:3857', 'EPSG:4326');
            const cx1 = x3 + b[0];
            const cy1 = y3 + b[1];

            const pcLonLat = proj.transform(
              [cx1, cy1],
              'EPSG:3857',
              'EPSG:4326',
            );
            const r1 = distance(point(pcLonLat), point(p1LonLat));

            let bear1 = bearingMethod(point(pcLonLat), point(p1LonLat));
            let bear2 = bearingMethod(point(pcLonLat), point(p2LonLat));
            if (setUpKeys.startWith) {
              if (setUpKeys.startWith === 'B') {
                if (index !== 0 && index % 2 !== 0) {
                  bear1 += 180;
                  bear2 += 180;
                }
              } else if (setUpKeys.startWith === 'A') {
                if (index === 0 || index % 2 === 0) {
                  bear1 += 180;
                  bear2 += 180;
                }
              }
            }

            const arc = lineArc(point(pcLonLat), r1, bear2, bear1, {
              steps: 256,
            });

            const arcFeature = new GeoJSON().readFeatures(arc, {
              featureProjection: 'EPSG:3857',
              dataProjection: 'EPSG:4326',
            });
            const rowStyle = new Style({
              stroke: new Stroke({
                color: 'blue',
                width: 5,
              }),
              fill: new Fill({
                color: 'rgba(180, 130, 130, 0.85)',
              }),
            });
            arcFeature[0].setStyle(rowStyle);

            arcFeatures.push(arcFeature[0]);
            /// ///////
          }
        });
      }
      const feats = [
        ...vineLaneFeatures,
        ...pointAFeatures,
        ...pointBFeatures,
        ...vineRowFeatures,
        // ...arcFeatures,
      ];
      // if (vineLaneLayerRef.current) {
      vineLaneLayerRef.current?.getSource().clear();
      vineLaneLayerRef.current?.getSource().addFeatures(feats);

      if (setUpKeys && setUpKeys.startWith === '') {
        mapRef.current
          ?.getView()
          .setCenter(proj.fromLonLat([zoomLatLong[1], zoomLatLong[0]]));
        mapRef.current?.getView().setZoom(20);
      }
    } catch (error: any) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (mapInitialized && setUpKeys.polygonUuid !== '') {
      getVineLanes(setUpKeys.polygonUuid);
    }
  }, [mapInitialized, setUpKeys]);

  /**
   * VineLanes initialization Ends Here
   */
  const resetForMapClicks = (item: string) => {
    // setShowPresentCommand(item);
    dispatch({
      type: SHOW_SAVE_COMMAND,
      payload: false,
    });
    dispatch({ type: SET_SHOW_PRESENT_COMMAND, payload: item });
    globalShowPresentCommand = item;
    setPreCommandState('');

    commandForSecondTime = '';
    apiCall = false;
    pointLayerRef?.current?.getSource().clear();
    vineLaneLayerRef.current?.getSource().clear();
    setFarm(true);
    selectFarmItems(true);
    dispatch({
      type: SET_SETUP_KEYS,
      payload: defaultSetUpKeys,
    });
    setGoToPointCoordinates([]);
    setGoToRowFollowPolygon(null);
    // for selected polygon name
    dispatch({
      type: SELECTED_ITEM_NAME,
      payload: '',
    });
    dispatch({
      type: SELECTED_VINE_BLOCK,
      payload: '',
    });
    dispatch({
      type: SET_AUTO_DRIVE_SETTINGS,
      payload: false,
    });
    // to clear the routine selection
    dispatch({
      type: SELECTED_ROUTINE,
      payload: null,
    });
    dispatch({ type: SET_SHOW_ROUTINE, payload: false });
  };
  // const startAutoDriveIteration = async (sct: TractorHeartBeat) => {
  //   try {
  //     const { organization } = userDetails;
  //     const { msg } = await executeRemoteAVCommand(organization.api_url, {
  //       organization_id: userDetails.organization_id,
  //       action: 'start',
  //       tractor_id: sct?.tractor_id,
  //       drive_action_uuid: sct?.drive_action_details
  //         ?.current_drive_action as string,
  //     });
  //     notification.success({
  //       message: msg,
  //     });
  //     dispatch({
  //       type: SET_SHOW_CONFIGURE_TRACTOR,
  //       payload: false,
  //     });
  //   } catch (err: any) {
  //     notification.error({
  //       message: err.response?.data.error.message,
  //     });
  //   }
  // };
  const startAutoDrive = async () => {
    resetForMapClicks('');
    remoteAvTractors &&
      remoteAvTractors.length > 0 &&
      remoteAvTractors.map(
        (remoteAvTractor: any) =>
          remoteAvTractor &&
          remoteAvTractor.drive_action_details &&
          remoteAvTractor.drive_action_details.current_operator_id &&
          remoteAvTractor?.drive_action_details?.current_operator_id ===
            userDetails?.id &&
          remoteAvTractor.drive_action_details.current_drive_action.length >
            0 &&
          getOperationNewStatus(remoteAvTractor) !==
            tractorConstantsNew.charging.key &&
          remoteAvTractor.drive_action_details.current_operator_id &&
          remoteAvTractor?.drive_action_details?.current_operator_id ===
            userDetails?.id &&
          getOperationNewStatus(remoteAvTractor) !==
            tractorConstantsNew.remote_av.key &&
          getOperationNewStatus(remoteAvTractor) !==
            tractorConstantsNew.copycat.key &&
          startAutoDriveIteration(remoteAvTractor.tractor_id),
      );
  };

  const addRowFollowRVCommand = async () => {
    try {
      const { organization } = userDetails;
      if (organization && organization.api_url) {
        const commandData: any = {
          command: 'vine_block',
          block_name: selectedVineBlockName,
          vinerow_name_1: leftVineRowName,
          vinerow_name_2: rightVineRowName,
          head_name: setUpKeys.startWith,
          row_seq: setUpKeys.everyFilter,
          implement: selectedImplementName ? selectedImplementName : 'None',
        };
        await addRemoteAVCommand(
          organization.api_url,
          [commandData],
          selectedTractor?.heartbeat?.drive_action_details
            ?.current_drive_action as string,
        );

        commandData.name = selectedItemNameGlobal;
        selectedTractor &&
          selectedTractor.id &&
          saveCommandTOLocalDB(selectedTractor.id, commandData);
        resetForMapClicks('');
      }
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };
  useEffect(() => {
    preCommandGlobal = preCommandState;
  }, [preCommandState]);

  useEffect(() => {
    selectedItemNameGlobal = selectedItemName;
  }, [selectedItemName]);
  useEffect(() => {
    if (pointCordinate && pointCordinate.length > 0) {
      const [x, y] = getRAVXYCoordinates(pointCordinate, base);
      pointCoordinateGlobalForApiCall = [x, y];
      pointCoordinateGlobalForDrawPoint = pointCordinate;
      // goToPointMove = false;
      // goToPointfeature = null;
      drawPoint(pointCordinate);
    }
  }, [pointCordinate]);

  // added for goto point for waypoint
  useEffect(() => {
    goToPointCoordinates &&
      goToPointCoordinates.length > 0 &&
      goToPointCode(goToPointCoordinates);
  }, [goToPointCoordinates]);
  const setRightSideToggle = (state: boolean) =>
    dispatch({
      type: SET_RIGHT_SIDE_TOGGLE,
      payload: state,
    });

  useEffect(() => {
    commandLayerRef?.current?.getSource().clear();
    if (
      activeIds &&
      commandsList &&
      commandsList[activeIds] &&
      commandsList[activeIds].length > 0
    ) {
      commandsList[activeIds].map(
        (command: any, index: number) =>
          command.command === 'goto_point' &&
          drawCommandPoint(
            command.pointCordinate,
            'C' + (index + 1),
            command.heading,
          ),
      );
    }
  }, [activeIds, commandsList, showRemoteDriveDetails]);

  const drawCommandPoint = (coordinates: any, name: string, angle: number) => {
    const pointLocation = new Feature({
      geometry: new Point(proj.fromLonLat(coordinates)),
      name,
    });
    const goToPointStyleChange = new Style({
      image: new Icon({
        src: goToPoint,
        scale: 0.2,
        rotation: ((90 - angle) * Math.PI) / 180,
      }),
    });
    pointLocation.setStyle(goToPointStyleChange);
    pointLocation.set('coordinates', coordinates);

    commandLayerRef.current?.getSource().addFeature(pointLocation);
    commandLayerRef.current?.setVisible(true);
    coordinates &&
      coordinates.length > 0 &&
      mapRef.current?.getView().setCenter(proj.fromLonLat(coordinates));
  };
  useEffect(() => {
    const defaultLayer = pointLayerRef.current;
    const features = defaultLayer?.getSource().getFeatures();
    const feats: any[] = [];
    features &&
      features.map((feature: any) => {
        const pointType = feature.get('pointType');
        if (pointType && pointType !== pathConstants.ROUTINE) {
          feats.push(feature);
        }
      });
    defaultLayer?.getSource().clear();
    defaultLayer?.getSource().addFeatures(feats);
    userDetails &&
      userDetails.organization &&
      selectedRoutine &&
      selectedRoutine.id &&
      getRoutinePoints();
  }, [selectedRoutine, userDetails, showRoutine]);
  const getRoutinePoints = async () => {
    setLoader(true);
    try {
      const routinePoints = await getRoutePoints(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        selectedRoutine.id,
      );

      if (routinePoints && routinePoints.length > 0) {
        const coordinates: any = [];
        routinePoints.forEach((element: any) => {
          // const latAndLong = getLatitudeAndLongitude(
          //   [element.y, element.x],
          //   base,
          // );

          // coordinates.push(proj.fromLonLat([latAndLong[1], latAndLong[0]]));
          coordinates.push(
            proj.fromLonLat([element.longitude, element.latitude]),
          );
        });

        // // head start here
        // const headPointData = routinePoints[0];
        // const blueDotStyleA = new Style({
        //   image: new Icon({
        //     src: playIcon,
        //     scale: 0.5,
        //     rotation: ((90 - headPointData.bearing) * Math.PI) / 180,
        //   }),
        //   zIndex: 501,
        // });
        // const pointLocation = new Feature({
        //   geometry: new Point(coordinates[0]),
        // });
        // pointLocation.setStyle(blueDotStyleA);
        // // head ends here

        const layerLines = new Feature({
          geometry: new LineString(coordinates),
          name: `Routine: ${selectedRoutine.name}`,
          pointType: pathConstants.ROUTINE,
          color: getPathColor(pathConstants.ROUTINE),
          borderColor: getPathBorderColor(pathConstants.ROUTINE),
        });
        layerLines.setStyle(getPathFeatureStyle(pathConstants.ROUTINE));
        // layerLines.setStyle(
        //   new Style({
        //     stroke: new Stroke({ color: '#98DCE0', width: 9 }),
        //   }),
        // );
        pointLayerRef?.current?.getSource().addFeature(layerLines);
        // pointLayerRef?.current?.getSource().addFeature(pointLocation);

        if (mapRef.current) {
          displayRecenterViewPortSetUp(
            layerLines as Feature<Geometry>,
            mapRef.current,
          );
        }
      }
    } catch (error: any) {
      notification.error({
        message: 'No Data Found',
      });
    } finally {
      setLoader(false);
    }
  };

  // const clearAll = (selectedTractor: TractorHeartBeat) => {
  const clearAll = () => {
    const { organization } = userDetails;
    if (organization && organization.api_url) {
      clearAllLocalDBCommands(globalSelectedTractorId);
      const driveActionUuid = getDriveActionUuid(
        globalTractorList,
        globalSelectedTractorId,
      );
      driveActionUuid &&
        addRemoteAVCommand(
          organization.api_url,
          [
            {
              command: 'clear_command_queue',
            },
          ],
          driveActionUuid as string,
        );
    }
    dispatch({
      type: SET_AUTO_VALUE,
      payload: '',
    });
    setGoToPointCoordinates([]);
    dispatch({
      type: SET_SHOW_PRESENT_COMMAND,
      payload: 'GOTO',
    });
    resetForMapClicks('GOTO');
    clearPreviewPath(globalSelectedTractorId);
    // to reset implement start here
    // after clea command from mqtt clear implement selection
    tractorImplementState[globalSelectedTractorId] = false;
    autoDriveDispatch({
      type: SET_TRACTOR_IMPLEMENT_STATE,
      payload: tractorImplementState,
    });
    // to reset implement ends here
  };

  useEffect(() => {
    if (showSnapshot) {
      setSnapshotContent(snapShotInitiated);
      setShowSnapShotView(true);
    }
    return () => {
      dispatch({
        type: SET_SHOW_SNAPSHOT,
        payload: false,
      });
    };
  }, [showSnapshot]);

  return (
    <>
      <div
        className="mapLayerIcon"
        data-testid="mapLayer-LiveMap"
        onClick={() => setMapLayersToggle(!mapLayersToggle)}
      ></div>
      {addStep &&
        checkTractorConnectedState(
          selectedTractor?.heartbeat,
          userDetails?.id,
        ) &&
        selectedTractor &&
        selectedTractor.id &&
        showRemoteDriveDetails && (
          <div className="livemapTopBar livemapTopBarTwo">
            <div className="topMenuTypeTxt">
              <div className="drawBtnsSec">
                {preCommandState === commands.GOTO_POINT && (
                  <div>
                    <div className="pBtnsRow">
                      {showSaveCommand && (
                        <>
                          <Button
                            key="back"
                            className="pcancelBtn"
                            onClick={() => {
                              resetForMapClicks('GOTO');
                              dispatch({
                                type: SET_AUTO_VALUE,
                                payload: '',
                              });
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            key="submit"
                            className="psaveBtn"
                            onClick={() => {
                              addRVCommand();
                              dispatch({
                                type: SET_ADD_STEP,
                                payload: false,
                              });
                              dispatch({
                                type: SET_AUTO_VALUE,
                                payload: '',
                              });
                              globalShowTractorStart[selectedTractor.id] =
                                false;
                              dispatch({
                                type: SHOW_TRACTOR_START,
                                payload: {
                                  ...globalShowTractorStart,
                                },
                              });
                            }}
                          >
                            Save
                          </Button>
                        </>
                      )}
                    </div>
                    <span className="pHelpTxt">
                      Rotate the marker to set the heading or click save
                    </span>
                  </div>
                )}
                {preCommandState === commands.ROW_FOLLOW && (
                  <>
                    {commandType === commands.ROW_FOLLOW && autoDriveSettings && (
                      <div className="radioOptions">
                        <Radio.Group
                          value={setUpKeys.everyFilter}
                          onChange={(e) =>
                            dispatch({
                              type: SET_SETUP_KEYS,
                              payload: {
                                everyFilter: e.target.value,
                                isFirstTime:
                                  e.target.value === 0 ? true : false,
                              },
                            })
                          }
                        >
                          <Radio value={0}>Single Row</Radio>
                          <Radio value={1}>Every Row</Radio>
                          <Radio value={2}>Every Other</Radio>
                          <Radio value={3}>Every Third</Radio>
                          <Radio value={4}>Every Fourth</Radio>
                        </Radio.Group>
                      </div>
                    )}

                    <div>
                      <div className="pBtnsRow">
                        {commandType === commands.ROW_FOLLOW &&
                          autoDriveSettings && (
                            <>
                              <Button
                                key="back"
                                className="pcancelBtn"
                                onClick={() => {
                                  resetForMapClicks('GOTO');
                                  dispatch({
                                    type: SET_AUTO_VALUE,
                                    payload: '',
                                  });
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                key="submit"
                                className="psaveBtn"
                                onClick={() => {
                                  addRowFollowRVCommand();
                                  dispatch({
                                    type: SET_ADD_STEP,
                                    payload: false,
                                  });
                                  dispatch({
                                    type: SET_AUTO_VALUE,
                                    payload: '',
                                  });
                                  globalShowTractorStart[selectedTractor.id] =
                                    false;
                                  dispatch({
                                    type: SHOW_TRACTOR_START,
                                    payload: {
                                      ...globalShowTractorStart,
                                    },
                                  });
                                }}
                              >
                                Save
                              </Button>
                            </>
                          )}
                      </div>
                      {!autoDriveSettings && (
                        <span className="pHelpTxt">Select A vinerow head</span>
                      )}
                      {commandType === commands.ROW_FOLLOW &&
                        autoDriveSettings && (
                          <span className="pHelpTxt">
                            select a sequence or click save
                          </span>
                        )}
                    </div>
                  </>
                )}
                {preCommandState === commands.ROUTINE && (
                  <div>
                    <div className="pBtnsRow">
                      {showSaveCommand && (
                        <>
                          <Button
                            key="back"
                            className="pcancelBtn"
                            onClick={() => {
                              resetForMapClicks('GOTO');
                              dispatch({
                                type: SET_AUTO_VALUE,
                                payload: '',
                              });
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            key="submit"
                            className="psaveBtn"
                            onClick={() => {
                              addRVRoutineCommand();
                              dispatch({
                                type: SET_ADD_STEP,
                                payload: false,
                              });
                              dispatch({
                                type: SET_AUTO_VALUE,
                                payload: '',
                              });
                              globalShowTractorStart[selectedTractor.id] =
                                false;
                              dispatch({
                                type: SHOW_TRACTOR_START,
                                payload: {
                                  ...globalShowTractorStart,
                                },
                              });
                            }}
                          >
                            Save
                          </Button>
                        </>
                      )}
                    </div>
                    <span className="pHelpTxt">
                      click on save to save command
                    </span>
                  </div>
                )}
                {preCommandState === '' && showPresentCommand === 'GOTO' && (
                  <>
                    <span className="pHelpTxt">
                      Select a point or waypoint or Green polygon inside the
                      farm
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      <LiveMapFleetList
        setRecenterTabId={setRecenterTabId}
        initTravelledPath={initTravelledPath}
        heartBeat={heartBeat as TractorHeartBeat}
        selectedTractorFromFleetId={selectedTractorFromFleetId as number}
        polygons={polygons}
        recenterItem={(item) => recenterItem(item)}
        recenterXY={recenterXY}
        clearPreviewPath={clearPreviewPath}
        setSelectedTractorsForMaps={(items) => (globalSelectedTractors = items)}
        handleResetCommand={(item: string) => {
          resetForMapClicks(item);
        }}
        pointCordinate={pointCordinate}
        startAutoDrive={startAutoDrive}
        addRowFollowRVCommand={addRowFollowRVCommand}
        preCommandState={preCommandState}
        addRVCommand={addRVCommand}
        base={base}
        setPreCommandState={setPreCommandState}
        setRedirectToggle={setRedirectToggle}
        redirectToggle={redirectToggle}
        clearAll={clearAll}
      ></LiveMapFleetList>
      <MapIcons
        autoDriveSettings={autoDriveSettings}
        rightSideToggle={rightSideToggle}
        selectedTractorId={selectedTractorId}
        recenterTractor={recenterTractor}
        map={mapRef.current}
        fieldFeatureItem={fieldFeatureItem.current as Feature<Geometry>}
        displayRecenterViewPortSetUp={displayRecenterViewPortSetUp}
        toggleSatellite={toggleSatellite}
        setToggleSatellite={setToggleSatellite}
      />
      <MapLayers
        rightSideToggle={rightSideToggle}
        setRightSideToggle={setRightSideToggle}
        mapLayersToggle={mapLayersToggle}
        setMapLayersToggle={setMapLayersToggle}
        fleet={fleet}
        setFleet={setFleet}
        showTractors={showTractors}
        setShowTractors={setShowTractors}
        selectItems={selectItems}
        showBasestations={showBasestations}
        setShowBasestations={setShowBasestations}
        farm={farm}
        setFarm={setFarm}
        showWaypoints={showWaypoints}
        setShowWaypoints={setShowWaypoints}
        showPath={showPath}
        setShowPath={setShowPath}
        showDataLayers={showDataLayers}
        setShowDataLayers={setShowDataLayers}
        showWhitePolygons={showWhitePolygons}
        setShowWhitePolygons={setShowWhitePolygons}
        showGrayPolygons={showGrayPolygons}
        setShowGrayPolygons={setShowGrayPolygons}
        selectFarmItems={selectFarmItems}
        showGreenPolygons={showGreenPolygons}
        setShowGreenPolygons={setShowGreenPolygons}
        showBrownPolygons={showBrownPolygons}
        setShowBrownPolygons={setShowBrownPolygons}
        showBluePolygons={showBluePolygons}
        setShowBluePolygons={setShowBluePolygons}
        showBadLocalization={showBadLocalization}
        setshowBadLocalization={setShowBadLocalization}
      />
      <div
        ref={mapElement}
        style={{ height: '100vh' }}
        className={`posHd ${
          rightSideToggle ||
          (selectedTractor && selectedTractor.id && showRemoteDriveDetails)
            ? 'afterMapRightAlign'
            : ''
        }`}
      >
        <AppLoader loader={loader} />
        {selectedTractor && selectedTractor.id && (
          <div
            id={applicationIds.liveMap.rowBtn}
            className="dataLayerBtn"
            onClick={() =>
              dispatch({
                type: SET_SHOW_REMOTE_DRIVE_DETAILS,
                payload: !showRemoteDriveDetails,
              })
            }
          >
            <span className="dataArrow">
              {/* <LeftOutlined style={{ fontSize: 17 }} /> */}
            </span>
            <span className="dLIcon">
              <img src={dataLayerIcon} alt="dataLayer" height="18" />
            </span>
            <span className="dLTxt">TRACTOR DETAILS</span>
          </div>
        )}
      </div>
      <div ref={container} className="ol-popup" style={{ display: 'block' }}>
        <div ref={content}></div>
      </div>
      {showIndicatorModel && (
        <DiagnosticWidget
          isEdit={isEdit}
          details={errorDetails}
          setIsEdit={setIsEdit}
          setToggleWidget={setShowIndicatorModel}
          getSeverity={getSeverity}
          getTags={getTags}
          onCreateTicket={onCreateTicket}
          toggleWidget={showIndicatorModel}
          hideVideos={true}
        />
      )}
      {toggleTicketWidget && (
        <TicketWidget
          details={ticketDetails}
          onCloseTicketWidget={onCloseTicketWidget}
          getSeverity={getSeverity}
          toggleTicketWidget={toggleTicketWidget}
          tractorId={tractorId}
        />
      )}
      {/* {showPresentCommand === 'GOTO' && (
        <div className={showPresentCommand === 'GOTO' ? 'mapShow' : 'mapHide'}>
          <AutoDriveNewDesign
            handleCancel={() => setShowPresentCommand('')}
            tractorId={selectedTractorId}
            heartBeat={currentTractorHeartBeat as TractorHeartBeat}
            previewPath={previewPath}
            polygonListForPop={polygonListForPop}
            selectedTractorId={selectedTractorId}
            tractorName={getNameFromList(allTractorsList, selectedTractorId)}
            commandName="GoTo"
          />
        </div>
      )} */}
      {showRoutine && (
        <RoutineNewDesign
          showModal={showRoutine}
          tractorName={getNameFromList(allTractorsList, selectedTractorId)}
          handleCancel={(routine?: any) => {
            // setShowLiveRoutineCommand(false);
            dispatch({ type: SET_SHOW_ROUTINE, payload: false });
            if (routine && routine.id) {
              // setShowPresentCommand('ROUTINE');
              dispatch({
                type: SET_SHOW_PRESENT_COMMAND,
                payload: 'ROUTINE',
              });
            }
          }}
        />
      )}
      {/* {showPresentCommand === 'ROUTINE' && (
        <div
          className={showPresentCommand === 'ROUTINE' ? 'mapShow' : 'mapHide'}
        >
          <RoutineNewDesignMap
            handleCancel={() => setShowPresentCommand('')}
            tractorId={selectedTractorId}
            heartBeat={currentTractorHeartBeat as TractorHeartBeat}
            previewPath={previewPath}
            polygonListForPop={polygonListForPop}
            selectedTractorId={selectedTractorId}
            tractorName={getNameFromList(allTractorsList, selectedTractorId)}
            commandName="Routine Replay"

          />
        </div>
      )}  */}
      {iconsFlag && <DiagnosticsModal iconsFlag={iconsFlag}></DiagnosticsModal>}
      {showSnapShotView && (
        <SnapshotView
          Close={() => setShowSnapShotView(false)}
          snapshotFlag={showSnapShotView}
          snapshotList={snapshotContent}
          hideAnalytics={true}
          showLoader={true}
        />
      )}
      {isHaveErrors(autoDriveLogoutErrorCodes) && (
        <AutoDriveLogoutErrorPopup
          autoDriveLogoutErrorCodes={autoDriveLogoutErrorCodes}
          showModal={isHaveErrors(autoDriveLogoutErrorCodes)}
        />
      )}
      {selectedTractorsIds &&
        selectedTractorsIds.length > 0 &&
        checkAutoDriveMonitorErrorCodes(
          autoDriveMonitorErrorCodes,
          selectedTractorsIds[0],
        ) &&
        autoDriveMonitorPop && (
          <Errorcodepopup
            showModal={autoDriveMonitorPop}
            closeModel={() =>
              dispatch({ type: SET_AUTO_DRIVE_MONITOR_POP, payload: false })
            }
          />
        )}
    </>
  );
});

export default LiveMap;
