/* eslint-disable no-console */
import React, { useContext } from 'react';
import humanprotection_icon from '../../assets/images/humanprotection_icon.svg';
import low_localization from '../../assets/images/low_localization.svg';
import pprotection_icon from '../../assets/images/pprotection_icon.svg';
import { checkAutoDriveMonitorErrorCodes } from '../../constants/Common';
import {
  getInterruptTitle,
  interruptMessageTypes,
} from '../../constants/constant';
import { SET_AUTO_DRIVE_INTERRUPT_MESSAGE } from './actions';
import { RemoteDriveAppCtx } from './RemoteDriveContext';

export const AutoDriveInterruptMsg: React.FC = () => {
  const { RDReducer, startAutoDriveIteration } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const { autoDriveInterruptMsg, selectedTractor, autoDriveMonitorErrorCodes } =
    state;

  const getDescription = (details: string) => {
    switch (details) {
      case interruptMessageTypes.guardrails_human:
        return (
          <p className="body3">
            The operation has been paused due to a person detected near the
            tractor. Check camera views before proceeding.
          </p>
        );
      case interruptMessageTypes.guardrails_path_presence:
        return (
          <p className="body3">
            The operation has been paused due to an obstruction in the tractor’s
            path. Check camera views before proceeding.
          </p>
        );
      case interruptMessageTypes.lost_localization:
        return (
          <p className="body3">
            Poor localization, waiting for a better connection.
          </p>
        );

      default:
        return (
          <>
            <p className="body3">
              Before resuming your operation please check the tractor’s
              surroundings to make sure it is safe to proceed.
            </p>
            <p className="body3">
              By choosing to resume you are taking responsibility of any and all
              personal injuries and damages that may occur.
            </p>
          </>
        );
    }
  };
  const continueOperation = () => {
    const details = autoDriveInterruptMsg?.metadata?.details;
    if (getPayloadForContinueOperation(details)) {
      const payload = getPayloadForContinueOperation(details);
      startAutoDriveIteration(selectedTractor.id, payload);
      dispatch({
        type: SET_AUTO_DRIVE_INTERRUPT_MESSAGE,
        payload: null,
      });
    }
  };
  const endOperation = () => {
    const details = autoDriveInterruptMsg?.metadata?.details;
    if (getPayloadForEndOperation(details)) {
      const payload = getPayloadForEndOperation(details);
      startAutoDriveIteration(selectedTractor.id, payload);
      dispatch({
        type: SET_AUTO_DRIVE_INTERRUPT_MESSAGE,
        payload: null,
      });
    }
  };
  const getPayloadForEndOperation = (details: string) => {
    switch (details) {
      case interruptMessageTypes.guardrails_human:
        return {
          action: 'stop',
          details: '',
          category: 'user_initiated',
          type: '',
        };
      case interruptMessageTypes.guardrails_path_presence:
        return {
          action: 'stop',
          details: '',
          category: 'user_initiated',
          type: '',
        };
      case interruptMessageTypes.guardrails_slip:
        return {
          action: 'stop',
          details: '',
          category: 'user_initiated',
          type: '',
        };
      case interruptMessageTypes.firmware_fault:
        return {
          action: 'resume',
          details: 'firmware_fault',
          category: 'system_pause',
          type: 'hard',
        };
      case interruptMessageTypes.lost_localization:
        return {
          action: 'stop',
          details: '',
          category: 'user_initiated',
          type: '',
        };
      case interruptMessageTypes.tractor_moved_off_path:
        return {
          action: 'resume',
          details: 'tractor_moved_off_path',
          category: 'system_pause',
          type: 'hard',
        };
      case interruptMessageTypes.tractor_lost_connection:
        return {
          action: 'stop',
          details: '',
          category: 'user_initiated',
          type: '',
        };
      default:
        return null;
    }
  };
  const getPayloadForContinueOperation = (details: string) => {
    switch (details) {
      case interruptMessageTypes.guardrails_human:
        return {
          action: 'resume',
          details: 'guardrails_human',
          category: 'system_pause',
          type: 'hard',
        };
      case interruptMessageTypes.guardrails_path_presence:
        return {
          action: 'resume',
          details: 'guardrails_path_presence',
          category: 'system_pause',
          type: 'hard',
        };
      case interruptMessageTypes.lost_localization:
        return {
          action: 'resume',
          details: 'lost_localization',
          category: 'system_pause',
          type: 'hard',
        };
      case interruptMessageTypes.tractor_lost_connection:
        return {
          action: 'resume',
          details: 'tractor_lost_connection',
          category: 'system',
          type: 'hard',
        };
      default:
        return null;
    }
  };
  const isPathProtectionDisabled = (type: string): boolean => {
    let resp = false;
    if (
      checkAutoDriveMonitorErrorCodes(
        autoDriveMonitorErrorCodes,
        selectedTractor?.id,
      )
    ) {
      autoDriveMonitorErrorCodes.errors.map((error: number) => {
        if (
          (type === interruptMessageTypes.guardrails_path_presence &&
            error == 76200) ||
          (type === interruptMessageTypes.guardrails_human && error == 76100) ||
          (type === interruptMessageTypes.lost_localization && error == 7003)
        ) {
          resp = true;
        }
      });
    }
    return resp;
  };

  return getInterruptTitle(autoDriveInterruptMsg?.metadata?.details) !== '' ? (
    <>
      {/* Path Protection code starts  */}
      {autoDriveInterruptMsg?.metadata?.details ===
        interruptMessageTypes.guardrails_path_presence && (
        <div className="pathProtection tractorDetailsAlertBox">
          <div className="pathPRow">
            <span className="terrImgBlock">
              <img src={pprotection_icon} alt="ppIcon" />
            </span>
            <div className="pText">
              <h2 className="headline2 fSbd">Path Protection</h2>
              <div className="minH70">
                <p className="body3">
                  The operation has been paused due to a person detected near
                  the tractor. Check camera views before proceeding.
                </p>
              </div>
            </div>
          </div>
          <div className="pathPRowBtn">
            <button
              type="button"
              className="pOpBtn Button2"
              onClick={endOperation}
            >
              End Operation
            </button>
            <button
              type="button"
              className="pOpBtn ppSelBtn Button2"
              onClick={continueOperation}
              disabled={isPathProtectionDisabled(
                autoDriveInterruptMsg?.metadata?.details,
              )}
            >
              Ignore and Continue
            </button>
          </div>
        </div>
      )}

      {/* Human Protection code starts  */}

      {autoDriveInterruptMsg?.metadata?.details ===
        interruptMessageTypes.guardrails_human && (
        <div className="humanProtection tractorDetailsAlertBox">
          <div className="pathPRow">
            <span className="terrImgBlock">
              <img src={humanprotection_icon} alt="ppIcon" />
            </span>
            <div className="pText">
              <h2 className="headline2 fSbd">Human Detection</h2>
              <div className="minH70">
                <p className="body3">
                  The operation has been paused due to a person detected near
                  the tractor. Check camera views before proceeding.
                </p>
              </div>
            </div>
          </div>
          <div className="pathPRowBtn">
            <button
              type="button"
              className="pOpBtn Button2"
              onClick={endOperation}
            >
              End Operation
            </button>
            <button
              type="button"
              className="pOpBtn ppSelBtn Button2"
              onClick={continueOperation}
              disabled={isPathProtectionDisabled(
                autoDriveInterruptMsg?.metadata?.details,
              )}
            >
              Ignore and Continue
            </button>
          </div>
        </div>
      )}
      {autoDriveInterruptMsg?.metadata?.details ===
        interruptMessageTypes.lost_localization && (
        <div className="localizationDown tractorDetailsAlertBox">
          <div className="pathPRow">
            <span className="terrImgBlock">
              <img src={low_localization} alt="ppIcon" />
            </span>
            <div className="pText">
              <h2 className="headline2 fSbd">Localization Down</h2>
              <div className="minH70">
                <p className="body3">
                  Poor localization, waiting for a better connection.
                </p>
              </div>
            </div>
          </div>
          <div className="pathPRowBtn df_jc">
            <button
              type="button"
              className="pOpBtn Button2"
              onClick={endOperation}
            >
              End Operation
            </button>
            <button
              type="button"
              className="pOpBtn ppSelBtn Button2"
              onClick={continueOperation}
              disabled={isPathProtectionDisabled(
                autoDriveInterruptMsg?.metadata?.details,
              )}
            >
              Ignore and Continue
            </button>
          </div>
        </div>
      )}

      {autoDriveInterruptMsg?.metadata?.details !==
        interruptMessageTypes.guardrails_path_presence &&
        autoDriveInterruptMsg?.metadata?.details !==
          interruptMessageTypes.guardrails_human &&
        autoDriveInterruptMsg?.metadata?.details !==
          interruptMessageTypes.lost_localization && (
          <div className="pwCaution tractorDetailsAlertBox">
            <div className="pathPRow">
              <div className="pText">
                <h2 className="headline2 fSbd">Proceed With Caution</h2>
                <h2 className="headline2 fSbd">
                  {getInterruptTitle(autoDriveInterruptMsg?.metadata?.details)}
                </h2>
                <div className="minH130 body3">
                  {getDescription(autoDriveInterruptMsg?.metadata?.details)}
                </div>
              </div>
            </div>
            <div className="pathPRowBtn">
              {getPayloadForEndOperation(
                autoDriveInterruptMsg?.metadata?.details,
              ) && (
                <button
                  type="button"
                  className="pOpBtn Button2"
                  onClick={endOperation}
                >
                  End Operation
                </button>
              )}
              {getPayloadForContinueOperation(
                autoDriveInterruptMsg?.metadata?.details,
              ) && (
                <button
                  type="button"
                  className="pOpBtn ppSelBtn Button2"
                  onClick={continueOperation}
                >
                  Ignore and Continue
                </button>
              )}
            </div>
          </div>
        )}
    </>
  ) : (
    <></>
  );
};
