import {
  AutoComplete,
  Input,
  notification,
  Spin,
  Select,
  DatePicker,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useContext, useEffect, useState } from 'react';
import {
  createTask,
  getTasksearch,
  getTractorsListData,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { Taskview, Taskviewdetails, TractorsList } from '../../constants/types';
import { useMixpanel } from 'react-mixpanel-browser';
import './style.css';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import { EditorState, convertToRaw } from 'draft-js';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { fromToDateWrapper } from '../../constants/Common';
import moment from 'moment';
const { RangePicker } = DatePicker;
interface Props {
  readonly visible: boolean;
  close: () => void;
  success: () => void;
  selectedDate: Date;
  selectedfromDate: Date;
}

const CreateTaskModel: React.FC<Props> = ({
  visible,
  close,
  success,
  selectedDate,
  selectedfromDate,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState<any>(selectedDate);
  const [toDate, setToDate] = useState<any>(selectedfromDate);
  const [result, setResult] = useState<Taskviewdetails[]>([]);
  const [title, setTitle] = useState('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const fullname = userDetails.first_name + ' ' + userDetails.last_name;
  const [autoValue, setAutoValue] = useState(fullname);
  const [autoid, setautoid] = useState(userDetails.id);
  const [loader, setLoader] = useState<boolean>(false);
  const { Option } = AutoComplete;
  const [type, setType] = useState();
  const mixpanel = useMixpanel();
  const [tractors, setTractorsData] = useState<TractorsList[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (type && fromDate && toDate && title.trim().length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [type, fromDate, toDate, title]);

  const onDateRangeChange = (dates: any) => {
    if (dates) {
      const [from, to] = fromToDateWrapper(dates);
      setFromDate(from.toDate());
      setToDate(to.toDate());
    } else {
      setFromDate(null);
      setFromDate(null);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoader(true);
      let raw: any = convertToRaw(editorState.getCurrentContent());
      raw = JSON.stringify(raw);
      if (raw && raw.length > 19999) {
        notification.error({
          message: 'Description maximum length exceeded',
        });
        return;
      }
      const response: { msg: string } = await createTask(
        userDetails.organization.api_url,
        {
          title: title.trim(),
          description: description,
          planned_start_date_time: fromDate.getTime(),
          planned_finish_date_time: toDate.getTime(),
          supervisor_id: autoid ? autoid : null,
        },
        userDetails.organization.id,
      );
      mixpanel.track('AddedTask', {
        event: 'Task Added Sucessfully',
      });
      notification.success({
        message: response.msg,
      });
      success();
    } catch (err: any) {
      mixpanel.track('AddedTaskFailed', {
        event: 'Task Added Failed',
      });
      notification.error({
        message: err.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleSearch = async (value: any) => {
    let res: any[] = [];
    if (value.length >= 3) {
      const taskviewData: Taskviewdetails = await getTasksearch(
        userDetails.organization.api_url,
        value,
      );
      taskviewData.records.map((ele: Taskview) => {
        ele.fullname = ele.first_name + ' ' + ele.last_name;
      });
      res = taskviewData.records;
      setResult(res);
    } else if (value.length <= 3) {
      res = [];
      setResult([]);
    }
  };
  const onSelect = (e: any) => {
    result.map((ele: any) => {
      if (ele.fullname === e) {
        setAutoValue(ele.fullname);
        setautoid(ele.id);
        setResult([]);
      }
    });
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
    }
  }, [userDetails]);

  const getTractorsList = async () => {
    try {
      const tractor = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractorData = data.map((d: TractorsList) => {
        return {
          label: d.name,
          value: d.id,
          disabled: false,
          index: d.id,
        };
      });
      setTractorsData(tractorData);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };

  const getDisabledHours = () => {
    const hours = [];
    for (let i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const getDisabledMinutes = () => {
    const minute = [];
    for (let i = 0; i < moment().minutes(); i++) {
      minute.push(i);
    }
    return minute;
  };

  return (
    <Modal
      title={t(translate.headers.newtask)}
      visible={visible}
      className="tm-modal betaModal"
      onCancel={close}
      okText={t(translate.buttons.create)}
      onOk={handleSubmit}
      okButtonProps={{
        className: 'tm-button btnSave',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow betaRow">
        <div className="formCol betaCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                Title <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div
                className="ant-form-item-control-input-content input-align"
                data-testid="inputtitle-AddTask"
              >
                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="formCol betaCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>Supervisor</label>
            </div>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div className="ant-form-item-control-input-content input-align">
                <AutoComplete
                  className=""
                  onSearch={handleSearch}
                  onSelect={onSelect}
                  defaultValue={autoValue}
                  placeholder="Supervisor"
                  data-testid="inputSupervisor-AddTask"
                >
                  {result.map((ele: any) => (
                    <Option key={ele.id} value={ele.fullname}>
                      {ele.fullname}
                    </Option>
                  ))}
                </AutoComplete>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="formRow betaRow">
        <div className="formCol betaCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                Type <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div className="ant-form-item-control-input-content input-align">
                <Select
                  style={{ width: '100%' }}
                  defaultValue="Select Type"
                  data-testid="selecType-AddTask"
                  onChange={(e: any) => setType(e)}
                >
                  <option value="Manual" data-testid="manual-AddTask">
                    Manual
                  </option>
                  <option value="Remote-AV" data-testid="remote-AddTask">
                    Remote-AV
                  </option>
                </Select>
              </div>
            </div>
          </div>
        </div>

        <div className="formCol betaCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>&nbsp;</label>
            </div>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div className="ant-form-item-control-input-content input-align">
                <Select
                  defaultValue="Tractor"
                  data-testid="tractorSelect-AddTask"
                >
                  {tractors.map((data: any) => {
                    if (data.label !== '') {
                      return (
                        <option
                          key={data.index}
                          value={data.index}
                          data-testid={`${data.index}-AddTask`}
                        >
                          {data.label}
                        </option>
                      );
                    }
                  })}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="formRow betaRow">
        <div className="formColW">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>Description</label>
            </div>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div
                className="ant-form-item-control-input-content input-align"
                data-testid="inputDescription-AddTask"
                style={{
                  width: '100%',
                  overflowY: 'auto',
                  minHeight: 200,
                }}
              >
                <ReactQuill
                  style={{ height: 150 }}
                  value={description}
                  onChange={(e) => setDescription(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="formRow betaRow">
        <div className="formCol dPick betaCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>
                Date <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
          </div>
          <div className="ant-col ant-form-item-control">
            <div className="ant-form-item-control-input">
              <div className="ant-form-item-control-input-content input-align input-flex">
                <RangePicker
                  format="YYYY-MM-DD"
                  onChange={onDateRangeChange}
                  className="dropdownStyle dW250_date"
                  data-testid="dateSelect-AddTask"
                  defaultValue={[
                    moment(fromDate, 'YYYY-MM-DD HH:mm'),
                    moment(toDate, 'YYYY-MM-DD HH:mm'),
                  ]}
                  disabledDate={(d) => !d || d.isBefore(new Date())}
                  style={{ width: 200, marginRight: '14px' }}
                  showTime={{ format: 'HH:mm' }}
                  disabledMinutes={getDisabledMinutes}
                  disabledHours={getDisabledHours}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="formCol betaCol"></div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CreateTaskModel;
