import React, {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { Dropdown, Select } from 'antd';

interface Props {
  placeholder?: string;
  label?: string;
  list: any[];
  // selectTrigger: (data: any) => void;
  className?: string;
  showSearch?: boolean;
  value: any;
  onSearch?: (text: string) => void;
  onSelect: (select: any) => void;
  onChange?: () => void;
  filterOption?: (input: any, option: any) => any;
  dropDown?: (
    menu: any,
  ) => ReactElement<any, string | JSXElementConstructor<any>>;
  testId: string;
  dataTestkey: string;
  dataTestLabel: string;
  optionKey: string;
  optionDisplay: string;
}
const ImplementSelect: React.FC<Props> = ({
  placeholder,
  label,
  list,
  className,
  showSearch,
  value,
  onSearch,
  onSelect,
  filterOption,
  testId,
  dropDown,
  dataTestkey,
  dataTestLabel,
  onChange,
  optionKey,
  optionDisplay,
}) => {
  const [options, setOptions] = useState<any[]>([]);
  useEffect(() => {
    if (list?.length > 0) {
      setOptions(list);
    }
  }, [list]);
  return (
    <>
      <div className="selectImplementContainer">
        {((typeof value === 'boolean' && value === false) || value) && (
          <div className="inputTop implementLable">{label}</div>
        )}
        <Select
          getPopupContainer={(trigger) => trigger.parentNode}
          showSearch={showSearch}
          value={value}
          className={value ? `selectImplement` : className}
          placeholder={!value && placeholder}
          onSearch={onSearch}
          onSelect={onSelect}
          onChange={onChange}
          data-testid={testId}
          filterOption={filterOption}
          notFoundContent={<em>No Matches Found</em>}
          dropdownRender={dropDown}
        >
          {options &&
            options?.length > 0 &&
            options?.map((data: any) => {
              return (
                <option
                  value={data[optionKey]}
                  data-testid={`${data[dataTestkey]}-${dataTestLabel}`}
                  key={Math.random()}
                >
                  {data[optionDisplay]}
                </option>
              );
            })}
        </Select>
      </div>
    </>
  );
};

export default ImplementSelect;
