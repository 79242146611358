import React from 'react';
import loading_logo from '../../assets/images/loading_logo.gif';
interface Props {
  loader: boolean;
}

const AppLoader: React.FC<Props> = ({ loader }) => {
  return (
    <div className="edit-loader">
      {loader && (
        <div className="loader">
          <img style={{ width: '40px', height: '40px' }} src={loading_logo} />
        </div>
      )}
    </div>
  );
};

export default AppLoader;
