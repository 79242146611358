/* eslint-disable react/jsx-no-undef */
import { Input, notification, Popover, Row } from 'antd';
import 'antd/dist/antd.css';
import Layout, { Content } from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMixpanel } from 'react-mixpanel-browser';
import { useHistory } from 'react-router-dom';
import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import Alerticon from '../../assets/images/Group 1296.svg';
import { getTractorsList } from '../../constants/Api';
import { product } from '../../constants/AppData';
import {
  checkActivity,
  getStatusClass,
  getStatusForTractor,
  getTractorAvailableState,
  getTractorStatusClass,
  indicatorsOrder,
  initClearSelection,
  initScroller,
  tractorStatusSort,
} from '../../constants/Common';
import { privilagesConstants } from '../../constants/Privilages';
import { IndicatorsData, TractorsList } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';
import RoutesConstants from '../../routes/RoutesConstant';
import BaseStation from '../basestation/BaseStation';
import CSelect from '../common/CSelect';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import Battery from './Battery';
import Editfleet from './Editfleet';
import './style.css';
import { getIndicatorIcons } from '../remote_drive_new/common';

const { Search } = Input;

interface Props {
  onSearchText?: (text: string) => void;
}
const Fleet: React.FC<Props> = ({ onSearchText }: Props) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const [pageSize] = useState<any>(25);
  const { userDetails, privilegeChecker } = useContext(ApplicationContext);
  const [filterData, setFilter] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [fleetflage, setbyfleet] = useState<boolean>(false);
  const [fleetobj, setfleetobj] = useState<any>('');
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const [totalCount, setTotalCount] = useState<any>();
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'name',
    order: 'ascend',
  });
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortedData, setSortedData] = useState<any>({
    name: 'ascend',
  });
  const mixpanel = useMixpanel();
  const [intialize, setIntialize] = useState<boolean>(false);
  const [productType, setProductType] = useState<string>('Tractor');
  const [inputValue, setInputValue] = useState('');

  const activity = useRef<string>('');
  const searchString = useRef<string>('');

  const content = (record: any) => {
    return (
      <div>
        <p>
          software update available.
          <br />
          Please{' '}
          <a
            onClick={() => {
              push({
                pathname: RoutesConstants.FleetHealth,
                state: {
                  action: 'SOFTWARE',
                  tractorId: record?.id,
                },
              });
            }}
            style={{ textDecoration: 'underline' }}
          >
            Update Now.{' '}
          </a>
        </p>
      </div>
    );
  };

  const showUpdaterIcon = (record: any) => {
    if (
      record &&
      record?.heartbeat?.software_version !==
        record?.latest_software?.software_version
    ) {
      return true;
    }

    return false;
  };

  const columns = [
    {
      render: (name: string) => {
        return (
          <>
            <div
              className="flex-space-between jfStart"
              data-testid={`${name}-Fleet`}
            >
              <span className="upTname">{name}</span>
            </div>
          </>
        );
      },
      title: () => {
        return (
          <>
            {t(translate.fleet.name)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'name' && (
                <img
                  className="sort-pad"
                  src={sortedData['name'] === 'ascend' ? sortUp : sortDown}
                />
              )}
            </span>
          </>
        );
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      sorter: () => false,
      dataIndex: 'name',
      key: 'name',
      width: '200px',
      showSorterTooltip: false,
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.fleet.status)}`,
      width: '150px',
      render: (record: any) => {
        return (
          <span
            className={getTractorStatusClass(
              record.isAvailable,
              record?.heartbeat?.drive_action_details?.drive_action_created_at,
            )}
          >
            {getStatusForTractor(
              record?.isAvailable,
              record?.heartbeat?.drive_action_details?.drive_action_created_at,
            )}
          </span>
        );
      },
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.fleet.health)}`,
      dataIndex: 'indicatorsData',
      key: 'connectivity_level',
      width: '150px',
      render: (indicatorsData: IndicatorsData[], record: any) => (
        <span className={`indicatorimges`}>{getIndicatorIcons(record)}</span>
      ),
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
    {
      title: () => {
        return (
          <>
            {t(translate.baseStation.houseMeter)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'hour_meter' && (
                <img
                  src={
                    sortedData['hour_meter'] === 'ascend' ? sortUp : sortDown
                  }
                />
              )}
            </span>
          </>
        );
      },
      sortOrder: sortedInfo.columnKey === 'hour_meter' && sortedInfo.order,
      sortDirections: ['descend', 'ascend', 'descend'],
      sorter: (a: { hour_meter: number }, b: { hour_meter: number }) =>
        a.hour_meter - b.hour_meter,
      dataIndex: 'housemeter',
      key: 'hour_meter',
      width: '150px',
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
      showSorterTooltip: false,
    },
    {
      title: `${t(translate.fleet.softwareVersion)}`,
      dataIndex: 'softwareversion',
      key: 'softwareversion',
      width: '150px',
      render: (version: string, record: any) => (
        <div className="versionList">
          <span className="vSNo vs">{version}</span>
          {privilegeChecker(privilagesConstants.Begin_an_update) &&
            version &&
            showUpdaterIcon(record) && (
              <div>
                <Popover content={content(record)} title="">
                  <img src={Alerticon} height="24px" />
                </Popover>
              </div>
            )}
        </div>
      ),
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.fleet.pin)}`,
      dataIndex: 'tractor_pin',
      key: 'tractor_pin',
      width: '200px',
      render: (tractor_pin: string) => {
        return <span>{tractor_pin}</span>;
      },
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
  ];

  const wrapResult = async (
    apiUrl: string,
    ogId: number,
    pageNumber: number,
    pageSize: number,
    baseStationId: number,
    sortedInfo: string,
    search: string,
  ) => {
    const result: any = await getTractorsList(
      apiUrl,
      ogId,
      pageNumber,
      pageSize,
      baseStationId,
      sortedInfo,
      search ? search : '',
    );
    return {
      result,
      searchKey: search,
    };
  };

  const getTractors = async () => {
    try {
      if (checkActivity(activity.current)) initScroller();
      setLoading(true);
      const { result, searchKey } = await wrapResult(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
        pageSize,
        0,
        sortedInfo,
        search ? search : '',
      );

      if (searchString.current !== searchKey) return;

      let data = result && result?.records?.length > 0 ? result.records : [];

      data.forEach((element: any) => {
        let hourMeter = (
          Number(element.heartbeat?.hour_meter) / 3600
        ).toFixed();
        hourMeter = !isNaN(Number(hourMeter)) ? `${hourMeter} hours` : '-';
        (element.housemeter = element.heartbeat
          ? element.heartbeat.hour_meter && hourMeter
          : '-'),
          (element.softwareversion = element.heartbeat
            ? element.heartbeat?.software_version
            : ''),
          (element.firmware = element.heartbeat
            ? element.heartbeat?.firmware_version
            : ''),
          (element.hour_meter = element.heartbeat
            ? element.heartbeat.hour_meter &&
              (Number(element.heartbeat?.hour_meter) / 3600).toFixed()
            : 0),
          (element.indicatorsData = []);
        element.isAvailable = getTractorAvailableState(
          element.connection,
          element?.heartbeat,
        );
        let connectivity_level = 0;
        for (const arrangeIndicator of indicatorsOrder) {
          for (const indicator of element.indicators) {
            if (arrangeIndicator === indicator.indicator_name) {
              element.indicatorsData.push(indicator);
              if (indicator.indicator_key === 'Tractor_Communication')
                connectivity_level = indicator.indicator_level;
            }
          }
        }
        element.connectivity_level = connectivity_level;
        element.active_network = '';
        let network_type = [];
        network_type = element?.heartbeat?.system_model?.split(',');

        if (network_type && network_type?.length >= 210) {
          element.active_network = network_type[209];
        }
        let TractorPin = '';
        if (element?.tractor_pin) {
          TractorPin =
            element?.tractor_pin === '-1' || element?.tractor_pin === '0'
              ? '-'
              : element?.tractor_pin;
        } else {
          TractorPin = '-';
        }

        element.basestation_name = element.basestation?.name || '-';
        element.tractor_pin = TractorPin;
        element.battery_name = element?.battery?.name || '-';
        element.basestation_pin = element.basestation?.bsin || '-';
        element.battery_pin = element?.battery?.bin || '-';
        element.isTractorError =
          element?.indicators &&
          element?.indicators?.filter(
            (indicator: any) => indicator.indicator_level === 3,
          ).length > 0;
      });
      setTotalCount(result._metadata?.total_records_count);
      setHasMore(data?.length === pageSize);

      if (checkActivity(activity.current)) {
        data = tractorStatusSort(data);
        setFilter(data);
      } else if (activity.current === 'paginate') {
        data = tractorStatusSort([...filterData, ...data]);
        setFilter(data);
      } else {
        data = tractorStatusSort(data);
        setFilter(data);
      }
    } catch (error: any) {
      notification.error({
        message: error.response?.data?.error?.message || error.message,
      });
    } finally {
      setLoading(false);
      if (!intialize) setIntialize(true);
    }
  };
  const getRow = (record: TractorsList) => {
    setfleetobj(record);
    setbyfleet(true);
    mixpanel.track('Fleet', {
      event: `${record.name} Clicked`,
    });
  };
  const Close = () => {
    setbyfleet(false);
    initClearSelection();
    mixpanel.track('Edit Fleet', {
      event: 'Edit Fleet Closed',
    });
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity(activity.current) && document)
      initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    activity.current = 'sort';
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  const handleSearch = (value: any) => {
    const searchValue = value.trim().regX;
    activity.current = 'search';
    searchString.current = searchValue;
    setInputValue(value);
    onSearchText && onSearchText(searchValue);
    if (searchValue.length <= 2) {
      searchString.current = '';
      if (search != '') {
        if (productType === 'Tractor') {
          setFilter([]);
        }
      }
      setSearch('');
    } else {
      searchString.current = searchValue;
      setSearch(searchValue);
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      loadTractors();
    }
  }, [userDetails]);

  useEffect(() => {
    if (sortedInfo.order && intialize && productType == 'Tractor') {
      loadTractors();
    }
  }, [sortedInfo.order, pageNumber, search, productType]);

  const loadTractors = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity(activity.current)) {
      pageNumber !== 1 ? setPageNumber(1) : getTractors();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getTractors();
    }
  };

  const handleProduct = (value: string) => {
    setProductType(value);
    searchString.current = '';
    setInputValue('');
    setSearch('');
    // setFilter([]);
  };

  return (
    <Layout>
      {fleetflage && (
        <Editfleet
          fleetobj={fleetobj}
          Close={Close}
          setRefresh={() => setRefresh(!refresh)}
          fleetflage={fleetflage}
        ></Editfleet>
      )}

      <div className="mainContent">
        <Content>
          <div className="tblContainer viewportContainer">
            <Row>
              <div className="tblTopOutSec fleetTop searchFilters filterGap40">
                <div className="searchSec">
                  <Search
                    id="search-field"
                    placeholder="Search Directory"
                    data-testid="fleetSearchInputField-Fleet"
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: 200 }}
                    value={inputValue}
                    autoComplete="off"
                    // onKeyDown={(e) => handleKey(e)}
                  />
                </div>
                <div className="smallFilter">
                  <CSelect
                    list={product}
                    isAll={false}
                    selectTrigger={(selected: any) => {
                      if (selected) {
                        const { value } = selected;
                        handleProduct(value);
                        setbyfleet(false);
                      } else {
                        handleProduct('Tractor');
                      }
                    }}
                    label="PRODUCTS"
                    keyValue="value"
                    defaultId="Tractor"
                    filterType={3}
                  />
                </div>
              </div>
              <div className="tblDft farmTabsTbl hoverable fleetsTbl">
                {productType === 'Tractor' && (
                  <div className="cPointer">
                    <InfiniteScrollTable
                      xScroll={400}
                      columns={columns}
                      hasMore={hasMore}
                      loading={loading}
                      filterData={filterData}
                      totalcount={totalCount}
                      handleLoadMore={handleLoadMore}
                      filename="Tractors"
                      onChange={handleChange}
                    />
                  </div>
                )}
                {productType === 'Base Station' && (
                  <BaseStation search={search} />
                )}
                {productType === 'Battery' && <Battery search={search} />}
              </div>
            </Row>
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default Fleet;
