import React from 'react';
import logoHorizontalWhite from '../assets/images/logo_horizontal_white_rgb.png';
import appleStore from '../assets/images/app_store.svg';
import googlePlay from '../assets/images/play_store.svg';

export default function MobileRoot() {
  return (
    <>
      <div className="loginContainer">
        <div className="loginWrapper mobileLoginWrapper">
          <div className="monarchImg">
            <img alt="monarch" src={logoHorizontalWhite} className="compLogo" />
          </div>
          <div className="loginContWrap">
            <a href={`monarchatom://forgot_password?${window.location.href}`}>
              {' '}
              <h3>Open in App</h3>
            </a>
            <div className="appIcons">
              <a href="https://play.google.com/store/apps/details?id=com.monarchtractor.atomapp&hl=en_IN&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  // height={100}
                  alt="Get it on Google Play"
                  src={googlePlay}
                />
              </a>
              <a href="https://apps.apple.com/in/app/monarch-tractor/id1522934747">
                {' '}
                <img
                  // height={77}
                  src={appleStore}
                  alt="Download on the App Store"
                />
              </a>
            </div>
          </div>
        </div>{' '}
      </div>
    </>
  );
}
