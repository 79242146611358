import { Button, Layout, notification, Tooltip } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getTrimbleOrganizations, syncLandMarks } from '../../../constants/Api';
import { ApplicationContext } from '../../../context/AppContext';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import trimbleApp from '../../../assets/images/trimbleLogoHead.svg';
import sync from '../../../assets/images/sync.svg';
import trView from '../../../assets/images/trView.svg';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getDateTimesByString } from '../../../constants/Common';
import { SyncedWaypointsModal } from './SyncedWaypointsModal';

const Trimble: React.FC = () => {
  const { state } = useLocation();
  const { goBack } = useHistory();
  const { userDetails } = useContext(ApplicationContext);
  const [organizations, setorganizations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [waypointsModal, setWaypointsModal] = useState<boolean>(false);
  const [selectedOrg, setSelectedOrg] = useState();

  useEffect(() => {
    const init = async () => {
      if (state && userDetails && userDetails.organization) {
        handleTrimbleOrgs();
      }
    };
    init();
  }, [state, userDetails]);

  const handleTrimbleOrgs = async () => {
    const data: any = state;
    const { app } = data;
    try {
      setLoading(true);
      const { records } = await getTrimbleOrganizations(
        userDetails.organization.api_url,
        userDetails.organization.id,
        app.id,
      );
      setorganizations(records);
    } catch (error) {
      notification.error({
        message: 'Failed to get organizations',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSyncData = async (data: any) => {
    const stateData: any = state;
    const { app } = stateData;
    try {
      setLoading(true);
      await syncLandMarks(
        userDetails.organization.api_url,
        userDetails.organization.id,
        data.id,
        userDetails.organization.farm.id,
        {
          tp_app_id: app.id,
          tp_org_name: data.name,
        },
      );

      notification.success({
        message: 'Waypoints data sync success',
      });
      handleTrimbleOrgs();
    } catch (error) {
      notification.error({
        message: 'Failed to get organizations',
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: `Org Name`,
      dataIndex: 'name',
    },
    {
      title: `Last Sync Time`,
      dataIndex: 'tp_last_sync_time',
      render: (data: string) => getDateTimesByString(data),
    },
    {
      title: `Action`,
      render: (data: string, record: any) => {
        return (
          <>
            <Tooltip title="Sync Trimble Landmarks / Waypoints">
              <Button
                className="syncBtn"
                type="primary"
                onClick={() => handleSyncData(record)}
              >
                Sync{' '}
                <img alt="sync" src={sync} className="mL10" height="14px" />
              </Button>
            </Tooltip>
            <Button
              className="syncBtn bgColor"
              type="primary"
              onClick={() => {
                setSelectedOrg(record);
                setWaypointsModal(true);
              }}
            >
              View{' '}
              <img alt="sync" src={trView} className="mL10" height="14px" />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Layout>
      <div className="mainContent">
        <Content>
          <div className="tblContainer viewportContainer">
            <div className="subPermissionTitle mtb32">
              <div className="teamarrow" onClick={() => goBack()}>
                <ArrowLeftOutlined />
              </div>
              <img alt="trimbleApp" src={trimbleApp} />
            </div>

            <div className="tblDft farmTabsTbl hoverable">
              <InfiniteScrollTable
                columns={columns}
                hasMore={false}
                loading={loading}
                filterData={organizations}
                totalcount={organizations.length}
                handleLoadMore={() => []}
                filename="Trimble Orgs"
              />
            </div>
          </div>
        </Content>
      </div>
      {waypointsModal && (
        <SyncedWaypointsModal
          selectedOrg={selectedOrg}
          showModal={waypointsModal}
          closeModal={() => setWaypointsModal(false)}
          userDetails={userDetails}
        />
      )}
    </Layout>
  );
};

export default Trimble;
