/* eslint-disable no-tabs */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Props {
  readonly description: string;
  setDescription?: (e: any) => void;
  readOnly?: boolean;
  height?: number;
  hideToolbar?: boolean;
}

const TextArea: React.FC<Props> = ({
  description,
  setDescription,
  readOnly,
  height,
  hideToolbar,
}: Props) => {
  const quillRef: any = useRef();

  const ImageUpload = (delay: any) =>
    new Promise((resolve) =>
      setTimeout(
        () =>
          resolve({
            data: {
              url: 'https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png',
            },
          }),
        delay,
      ),
    );

  const imageHandler = (e: any) => {
    const editor = quillRef.current.getEditor();
    // eslint-disable-next-line no-console
    console.log(editor);
    const input: any = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        // eslint-disable-next-line no-console
        // console.log(file);
        const formData: any = new FormData();
        formData.append('image', file);
        const res: any = await ImageUpload(1000); // upload data into server or aws or cloudinary
        // const res:any = await ImageUpload(formData); // upload data into server or aws or cloudinary
        const url = res?.data?.url;
        editor.insertEmbed(editor.getSelection().index, 'image', url);
      } else {
        // eslint-disable-next-line no-console
        console.error('You could only upload images.');
        // ErrorToast('You could only upload images.');
      }
    };
  };

  const toolbarOptions = {
    toolbar: hideToolbar
      ? null
      : {
          container: [
            [
              { header: [1, 2, 3, 4, 5, 6, false] },
              { font: [] },
              { size: ['small', false, 'large', 'huge'] },
            ],
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            ['clean'], // remove formatting button
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [
              { align: '' },
              { align: 'center' },
              { align: 'right' },
              { align: 'justify' },
            ],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

            ['blockquote', 'code-block'],
            // ['image', 'link'],
            // ['table'],

            // [{ direction: 'rtl' }], // text direction
          ],
          handlers: {
            image: imageHandler,
          },
        },
  };

  // eslint-disable-next-line no-undef
  const modules = useMemo(() => toolbarOptions, []);

  const download = (url: string) =>
    fetch(url)
      .then((resp) => resp.blob())
      .then((blobobject) => {
        const blob = window.URL.createObjectURL(blobobject);
        const anchor: any = document.createElement('a');
        anchor.style.display = 'none';
        anchor.href = blob;
        const filename = url.split('/').pop();
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(blob);
      })
      // eslint-disable-next-line no-console
      .catch(() => console.log('An error in downloadin gthe file sorry'));

  useEffect(() => {
    const url =
      'https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png';
    // download(url);
  }, []);

  return (
    <div
      className="ant-form-item-control-input-content input-align"
      style={{
        width: '100%',
        overflowY: 'auto',
      }}
    >
      <ReactQuill
        ref={quillRef}
        style={{ height }}
        value={description}
        readOnly={readOnly}
        modules={modules}
        onChange={(e) => setDescription && setDescription(e)}
      />
    </div>
  );
};

export default TextArea;
