/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-undef */
import { Button, Image, notification, Spin } from 'antd';
import Layout from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import closeGreen from '../../assets/images/closeGreen.svg';
import './SnapshotStyle.css';
import { SnapShot, TractorsList } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { getSnapshot } from '../../constants/Api';
import {
  betaCams,
  formatCameraText,
  getDateTime,
  pilotCams,
  removeUnderScore,
  sortCameraViews,
} from '../../constants/Common';
import msgIcon from '../../assets/images/msgIcon.svg';
import applicationIds from '../../locale/applicationIds.json';
import { useHistory } from 'react-router-dom';
import loading_logo from '../../assets/images/loading_logo.gif';
import loading from '../../assets/images/refresh_icon.svg';
import { useCameraSnap } from '../../constants/customHooks';
import {
  EXECUTE,
  SET_LAST_TAB_INDEX,
  TOGGLE_VIDEO_WIDGET,
} from '../../context/actions';
import { privilagesConstants } from '../../constants/Privilages';
import TicketsCreateEdit from '../tickets/TicketsCreateEdit';
import AppLoader from '../common/AppLoader';

const GUARD_RAILS = 'GUARD_RAILS';

interface Props {
  Close: () => void;
  snapshotFlag: boolean;
  snapshotList: any;
  hideAnalytics?: boolean; // optional
  showLoader?: boolean;
}
const SnapshotView: React.FC<Props> = ({
  Close,
  snapshotFlag,
  snapshotList,
  hideAnalytics,
  showLoader,
}: Props) => {
  const { push } = useHistory();
  const { userDetails, APPReducer, privilegeChecker } =
    useContext(ApplicationContext);
  const [images, setImages] = useState<any[]>([]);
  const [betaPilot, setBetaPilot] = useState<string>('');
  // const [loader, setLoading] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);
  // show hide analytic
  const [showAnalytics, setShowAnalytics] = useState(true);
  const [cameraData] = useCameraSnap({
    guard_rail_triggered_uuid: snapshotList?.transaction_id,
    created_at: snapshotList?.created_at,
  });
  const [state, dispatch] = APPReducer;
  const [toggleTicketWidget, setToggleTicketWidget] = useState<any>(false);
  const [tractorVisable, settractorVisable] = useState<any>(false);

  useEffect(() => {
    hideAnalytics && setShowAnalytics(false);
  }, [hideAnalytics]);

  const handleVideos = () => {
    const { date_time, created_date_time, tractorId, tractor_id } =
      snapshotList;
    const videoTime = date_time || created_date_time;
    dispatch({
      type: TOGGLE_VIDEO_WIDGET,
      payload: {
        toggle: !state.videoWidgetData.toggle,
        videoTime: videoTime,
        tractorId: tractorId || tractor_id,
      },
    });
  };

  const loadImages = () => {
    const data: {
      tractorName: string;
      tractor_id: { id: number };
      tractors: TractorsList[];
      tractorId: number;
      date: string;
      drive_action_uuid: string;
      created_date_time: string;
    } = snapshotList as {
      tractorName: string;
      tractors: TractorsList[];
      tractor_id: { id: number };
      tractorId: number;
      date: string;
      drive_action_uuid: string;
      created_date_time: string;
    };
    let model = 'Beta';
    data.tractors =
      data && data.tractors && data.tractors.length > 0 ? data.tractors : [];
    for (const iterator of data.tractors) {
      if (iterator.id && data.tractorId && iterator.id == data.tractorId) {
        model = iterator.tractor_model;
        setBetaPilot(iterator.tractor_model);
      }
    }
    getImages(model);
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && snapshotList) loadImages();
  }, [userDetails, snapshotList, refresh]);

  const getImages = async (model: string) => {
    try {
      // setLoading(true);
      setImageLoader(true);

      const { organization } = userDetails;
      const response: any[] = await getSnapshot(
        organization.api_url,
        snapshotList.transaction_id,
        snapshotList.tractor_id.id || snapshotList.tractor_id,
      );
      const cams = model === 'Pilot' ? [...pilotCams] : [...betaCams];
      let img: SnapShot[] = cams.map((cam) => {
        let url = '';
        if (response && response.length > 0) {
          const item = response.filter(
            (resp) => resp.camera_name === cam.camera_name,
          );
          if (item && item.length && item[0].camera_image_url) {
            url = item[0].camera_image_url;
          }
        }
        // if (url === '') {
        //   url = nvImg;
        // }
        return {
          camera_image_url: url,
          title: cam.title,
          camera_name: cam.camera_name,
        };
      });
      img = sortCameraViews(img, 'camera_name', 'images');
      setImages(img);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    } finally {
      // setLoading(false);
      setImageLoader(false);
    }
  };

  const filteredImages = images.filter(
    (imageItem: SnapShot) =>
      imageItem.title === 'PTO' ||
      imageItem.title === 'Rear' ||
      imageItem.title === 'Front Left' ||
      imageItem.title === 'Front Right' ||
      imageItem.title === 'Front',
  );

  const onCloseTicketWidget = () => {
    setToggleTicketWidget(false);
    settractorVisable(false);
  };

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (snapshotFlag ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn" data-testid="snapclose-SnapshortView">
          <img
            id={applicationIds.snapshotScreen.snapshotCloseBtn}
            src={closeGreen}
            alt="close icon"
            onClick={Close}
          />
        </div>

        <div className="txtCenter tcktInfo posRel errorTcktTitle">
          <span className="headline1">
            {snapshotList.created_at === 'GUARD_RAILS'
              ? 'Guardrails'
              : snapshotList.created_at === 'JUMP_AND_GO'
              ? 'Jump And Go'
              : 'Snapshot'}
          </span>
          <h6 className="snapDate headline2">
            {typeof snapshotList?.created_date_time === 'number'
              ? getDateTime(snapshotList?.created_date_time)
              : snapshotList?.created_date_time}
          </h6>
        </div>
        <div className="profileView rightWidgetView">
          <div className="proRowType rowGapBtm3">
            <h5 className="subFlex">
              <span className="subTitles overline1 fSbd">Tractor</span>
              <span className="subdesc body1">{snapshotList?.tractorName}</span>
            </h5>
          </div>

          <div className="proRowType rowGapBtm3">
            <h5 className="subFlex">
              <span
                className="subTitles overline1 fSbd"
                data-testid="creatorName-SnapshortView"
              >
                Operator
              </span>
              <span className="subdesc body1">{snapshotList?.name}</span>
            </h5>
          </div>

          <div className="proRowType rowGapBtm3">
            <h5 className="subFlex">
              <span className="subTitles overline1 fSbd">
                {snapshotList?.created_at === 'ATOM'
                  ? 'Captured On'
                  : 'Creation Type'}
              </span>
              <span
                className="subdesc body1"
                style={{ textTransform: 'capitalize' }}
              >
                {snapshotList?.created_at === GUARD_RAILS
                  ? removeUnderScore(snapshotList?.guard_rail_type)
                  : removeUnderScore(snapshotList?.created_at)}

                {/* <img
                  src={ptoPG}
                  alt="ptoPG"
                  height="22px"
                  style={{ marginLeft: 6 }}
                /> */}
              </span>
            </h5>
          </div>

          {snapshotList?.created_at === GUARD_RAILS && (
            <div className="proRowType rowGapBtm3">
              <h5 className="subFlex">
                <span className="subTitles overline1 fSbd">Camera</span>
                <span className="subdesc body1">
                  {' '}
                  {formatCameraText(cameraData?.camera)}
                </span>
              </h5>
            </div>
          )}

          <div className="proRowType">
            <h5 className="subFlex">
              <span className="subTitles overline1 fSbd">Images</span>
              {showLoader && (
                <span
                  className="cursor"
                  onClick={() => {
                    setRefresh(!refresh);
                  }}
                >
                  {/* <ReloadOutlined /> */}
                  <img src={loading} alt="loading" width="24" height="20" />
                </span>
              )}
            </h5>
          </div>

          <div className="camWrapper snapsContainer">
            <AppLoader loader={imageLoader} />
            <Image.PreviewGroup>
              {!imageLoader &&
                filteredImages.map((item: SnapShot, index) => (
                  <div key={index}>
                    {item.camera_image_url !== '' ? (
                      <div
                        className={
                          betaPilot == '' || betaPilot == 'Beta'
                            ? 'camBox'
                            : 'camBox1'
                        }
                      >
                        <div className="camBoxContent">
                          <Image
                            src={item.camera_image_url}
                            alt="camImg"
                            className="sImg"
                          />
                        </div>
                        <div className="camBoxHeads">{item.title}</div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ))}
            </Image.PreviewGroup>
          </div>
          <div className="proRowType rowGapTop36">
            <h5 className="overline1 fSbd">Tickets and Tasks</h5>
            <h6 className="proRoleValue createTcktBtn">
              {privilegeChecker(privilagesConstants.Create_a_fleet_ticket) && (
                <div
                  id={applicationIds.snapshotScreen.addSnapshotTicket}
                  onClick={() => {
                    setToggleTicketWidget(true);
                    settractorVisable(true);
                  }}
                  className="commentBtnBlk"
                >
                  <span className="addCBtn addTcktCBtn">
                    <img src={msgIcon} />
                  </span>
                  <span
                    className="addCommentBtn ticketComments"
                    data-testid="createTicketHeader-SnapshotView"
                  >
                    CREATE A TICKET
                  </span>
                </div>
              )}
            </h6>
          </div>
          <div className="proRowType mt32">
            <Button
              type="primary"
              className="employeeCreate w160 Button"
              onClick={handleVideos}
            >
              View Videos
            </Button>
          </div>
          {showAnalytics &&
            // snapshotList?.status === 'Completed' &&
            !['None', 'null'].includes(snapshotList.drive_action_uuid) && (
              <div
                className="analyticsTxt"
                onClick={() => {
                  dispatch({
                    type: SET_LAST_TAB_INDEX,
                    payload: {
                      catlog: 'Analytics',
                      tab: 'Operations',
                    },
                  });
                  // push(RoutesConstants.Analytic, {
                  //   action: 'Operations',
                  //   drive_action: snapshotList,
                  // });
                  dispatch({
                    type: EXECUTE,
                    payload: {
                      routeRefresh: !state.routeRefresh,
                      jAGDAction: snapshotList,
                    },
                  });
                  Close();
                }}
              >
                SEE ANALYTICS
              </div>
            )}
        </div>

        {toggleTicketWidget && (
          <TicketsCreateEdit
            tractorId={snapshotList?.tractor_id?.id || snapshotList?.tractor_id}
            Close={onCloseTicketWidget}
            afterSave={onCloseTicketWidget}
            toggleWidget={toggleTicketWidget}
            tractorVisable={tractorVisable}
          ></TicketsCreateEdit>
        )}
      </div>
    </Layout>
  );
};

export default SnapshotView;
