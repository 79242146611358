/* eslint-disable no-console */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Card, Row, Space, Button, Modal, Dropdown, Menu, message } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import './style.css';
import {
  CaretRightOutlined,
  PauseOutlined,
  MoreOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { getDateTime, sortCameraViews } from '../../constants/Common';
import { getVideoURL } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import RoutesConstants from '../../routes/RoutesConstant';
import moment from 'moment';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useMixpanel } from 'react-mixpanel-browser';
import { AnalyticsContext } from '../fleetusage/AnalyticsContext';
import CameraGrid2 from '../map/CameraGrid2';
import loading_logo from '../../assets/images/loading_logo.gif';

interface Props {
  showModal: boolean;
  setVediosModal: (data: any) => void;
  UpdatedTime: any;
}
const VedioThumbnailModal: React.FC<Props> = ({
  showModal,
  setVediosModal,
  UpdatedTime,
}: Props) => {
  const mixpanel = useMixpanel();
  const { userDetails } = useContext(ApplicationContext);
  const { JAG } = useContext(AnalyticsContext);
  const [videos, setVideos] = useState<any>([]);
  const [tractor_id, setTractorId] = useState<number>(
    JAG ? JAG['tractor']['id'] : 0,
  );
  const [tractorName, setTractorName] = useState<any>('');
  const [updatedTime, setUpdatedTime] = useState<number>(
    UpdatedTime ? UpdatedTime : 0,
  );
  const frontRef: any = useRef();
  const rearRef: any = useRef();
  const driverRef: any = useRef();
  const ptoRef: any = useRef();
  const frontLeft: any = useRef();
  const frontRight: any = useRef();
  const rearLeft: any = useRef();
  const rearRight: any = useRef();
  const curTime: any = useRef();
  const fixTime: any = useRef();
  const [play, setPlay] = useState(true);
  const [duration, setDuration] = useState<any>();
  const faces = [frontRef, rearRef, ptoRef, frontLeft, frontRight];
  const [openMenu, setOpenMenu] = useState(false);
  const [speedMenu, setSpeedMenu] = useState(false);
  const [current, setCurrent] = useState('1');
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [vedioInfo, setvedioInfo] = useState<any>({
    front: false,
    rear: false,
    pto: false,
    driver: false,
    frontleft: false,
    rearleft: false,
    frontright: false,
    rearrightRef: false,
  });

  const mapVideoTag = (face: any, data: any) => {
    if (face.current) face.current.src = data.video_url;
  };

  const mapAction = (action: string) => {
    if (frontRef.current && frontRef.current.src) {
      frontRef.current[action]();
    }
    if (rearRef.current && rearRef.current.src) {
      rearRef.current[action]();
    }
    if (driverRef.current && driverRef.current.src) {
      driverRef.current[action]();
    }
    if (ptoRef.current && ptoRef.current.src) {
      ptoRef.current[action]();
    }
    if (frontLeft.current && frontLeft.current.src) {
      frontLeft.current[action]();
    }
    if (frontRight.current && frontRight.current.src) {
      frontRight.current[action]();
    }
    if (rearLeft.current && rearLeft.current.src) {
      rearLeft.current[action]();
    }
    if (rearRight.current && rearRight.current.src) {
      rearRight.current[action]();
    }
  };

  const resetTags = () => {
    faces.map((face) => face.current && face.current.src);
    if (curTime.current && fixTime.current) {
      curTime.current.innerHTML = 0;
      fixTime.current.innerHTML = 0;
    }
  };
  const mapVideos = (videos: any) => {
    if (videos.length) {
      videos.map((data: any) => {
        switch (data.direction) {
          case 'front':
            mapVideoTag(frontRef, data);
            break;
          case 'rear':
            mapVideoTag(rearRef, data);
            break;
          case 'pto':
            mapVideoTag(ptoRef, data);
            break;
          case 'front-left':
            mapVideoTag(frontLeft, data);
            break;
          case 'front-right':
            mapVideoTag(frontRight, data);
            break;
          default:
            break;
        }
      });
    } else {
      resetTags();
    }
  };

  useEffect(() => {
    if (videos) mapVideos(videos);
  }, [videos]);

  const processDates = (dates: any[]) =>
    dates.map((date: any) => {
      date = new Date(date);
      date.setSeconds(0);
      date.setMilliseconds(0);
      // let minutes = moment(date).minutes();
      // minutes =
      //   moment(date).toDate().getMinutes() % 5 >= 2.5
      //     ? Math.ceil(moment(date).toDate().getMinutes() / 5) * 5
      //     : Math.floor(moment(date).toDate().getMinutes() / 5) * 5;
      // date.setMinutes(minutes);
      return date.getTime();
    });

  useEffect(() => {
    if (JAG) {
      setTractorId(JAG['tractor']['id']);
      setTractorName(JAG['tractor']['name']);
      const startDate: any = JAG['drive_action_start_date_time'];
      const endDate: any = JAG['drive_action_finish_date_time'];
      const dates = processDates([startDate, endDate]);
      setDuration(dates);
    } else {
      setVideos([]);
    }
  }, [JAG]);

  useEffect(() => {
    if (userDetails && userDetails.organization && updatedTime && tractor_id) {
      getVideoData();
    }
  }, [userDetails, updatedTime]);

  const getVideoData = async () => {
    try {
      setShowLoader(true);
      let response = await getVideoURL(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        tractor_id,
        updatedTime,
      );
      response = sortCameraViews(response, 'direction', 'videos');
      response.map((data: any) => {
        switch (data.direction) {
          case 'front':
            vedioInfo.front = true;
            break;
          case 'rear':
            vedioInfo.rear = true;
            break;
          case 'pto':
            vedioInfo.pto = true;
            break;
          case 'driver':
            vedioInfo.driver = true;
            break;
          case 'front-left':
            vedioInfo.frontleft = true;
            break;
          case 'rear-left':
            vedioInfo.rearleft = true;
            break;
          case 'front-right':
            vedioInfo.frontright = true;
            break;
          case 'rear-right':
            vedioInfo.rearrightRef = true;
            break;
          default:
            break;
        }
      });
      setVideos(response);
    } finally {
      setShowLoader(false);
    }
  };

  const playVideo = () => {
    if (play) {
      mapAction('play');
      setPlay(false);
      mixpanel.track('Recorded Video', {
        event: `All Videos Played`,
      });
    } else {
      mapAction('pause');
      setPlay(true);
      mixpanel.track('Recorded Video', {
        event: `All Videos Paused`,
      });
    }
  };

  // const video: any = document.querySelector('video');
  const progress: any = document.querySelector('.vedioprogress');
  const progressBar: any = document.querySelector('.vedioprogress__filled');

  const currentTime = (video: any) => {
    const currentMinutes = Math.floor(video.currentTime / 60);
    const currentSeconds = Math.floor(video.currentTime - currentMinutes * 60);
    const durationMinutes = Math.floor(video.duration / 60);
    const durationSeconds = Math.floor(video.duration - durationMinutes * 60);
    curTime.current.innerHTML = `0${currentMinutes}: ${
      currentSeconds < 10 ? '0' + currentSeconds : currentSeconds
    }`;
    if (durationMinutes) {
      fixTime.current.innerHTML = `0${durationMinutes}: ${
        durationSeconds < 10 ? '0' + durationSeconds : durationSeconds
      }`;
    }
  };

  const handleProgress = () => {
    try {
      if (frontRef.current?.src) {
        const percent =
          (frontRef.current.currentTime / frontRef.current.duration) * 100;
        if (isNaN(percent)) {
          progressBar.style.flexBasis = `0%`;
        } else {
          progressBar.style.flexBasis = `${percent}%`;
        }
        currentTime(frontRef.current);
      }

      if (rearRef.current?.src) {
        const percent =
          (rearRef.current.currentTime / rearRef.current.duration) * 100;
        if (isNaN(percent)) {
          progressBar.style.flexBasis = `0%`;
        } else {
          progressBar.style.flexBasis = `${percent}%`;
        }
        currentTime(rearRef.current);
      }

      // if (driverRef.current.src) {
      //   const percent =
      //     (driverRef.current.currentTime / driverRef.current.duration) * 100;
      //   progressBar.style.flexBasis = `${percent}%`;
      //   currentTime(driverRef.current);
      // }

      if (frontLeft.current?.src) {
        const percent =
          (frontLeft.current.currentTime / frontLeft.current.duration) * 100;
        if (isNaN(percent)) {
          progressBar.style.flexBasis = `0%`;
        } else {
          progressBar.style.flexBasis = `${percent}%`;
        }
        currentTime(frontLeft.current);
      }

      if (frontRight.current?.src) {
        const percent =
          (frontRight.current.currentTime / frontRight.current.duration) * 100;
        if (isNaN(percent)) {
          progressBar.style.flexBasis = `0%`;
        } else {
          progressBar.style.flexBasis = `${percent}%`;
        }
        currentTime(frontRight.current);
      }

      if (ptoRef.current?.src) {
        const percent =
          (ptoRef.current.currentTime / ptoRef.current.duration) * 100;
        if (isNaN(percent)) {
          progressBar.style.flexBasis = `0%`;
        } else {
          progressBar.style.flexBasis = `${percent}%`;
        }
        currentTime(ptoRef.current);
      }

      // if (rearLeft.current.src) {
      //   const percent =
      //     (rearLeft.current.currentTime / rearLeft.current.duration) * 100;
      //   progressBar.style.flexBasis = `${percent}%`;
      //   currentTime(rearLeft.current);
      // }

      // if (rearRight.current.src) {
      //   const percent =
      //     (rearRight.current.currentTime / rearRight.current.duration) * 100;
      //   progressBar.style.flexBasis = `${percent}%`;
      //   currentTime(rearRight.current);
      // }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const barHandling = (e: any) => {
    try {
      if (frontRef.current?.src) {
        frontRef.current.currentTime =
          (e.offsetX / progress.offsetWidth) * frontRef.current.duration;
      }

      if (rearRef.current?.src) {
        rearRef.current.currentTime =
          (e.offsetX / progress.offsetWidth) * rearRef.current.duration;
      }

      if (driverRef.current?.src) {
        driverRef.current.currentTime =
          (e.offsetX / progress.offsetWidth) * driverRef.current.duration;
      }

      if (ptoRef.current?.src) {
        ptoRef.current.currentTime =
          (e.offsetX / progress.offsetWidth) * ptoRef.current.duration;
      }

      if (frontLeft.current?.src) {
        frontLeft.current.currentTime =
          (e.offsetX / progress.offsetWidth) * frontLeft.current.duration;
      }

      if (frontRight.current?.src) {
        frontRight.current.currentTime =
          (e.offsetX / progress.offsetWidth) * frontRight.current.duration;
      }

      if (rearLeft.current?.src) {
        rearLeft.current.currentTime =
          (e.offsetX / progress.offsetWidth) * rearLeft.current.duration;
      }

      if (rearRight.current?.src) {
        rearRight.current.currentTime =
          (e.offsetX / progress.offsetWidth) * rearRight.current.duration;
      }
      // handleProgress();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  // video?.addEventListener('timeupdate', handleProgress);
  progress?.addEventListener('click', barHandling);

  const openFullscreen = (videoUrl: any) => {
    mixpanel.track('Recorded Video', {
      event: `${videoUrl} Video Expanded`,
    });
    videoUrl &&
      window.open(
        `${RoutesConstants.VideoPop}?videoUrl=${videoUrl}`,
        `Video URL`,
        'max-width: 100%',
      );
  };
  const Cancel = () => {
    setVediosModal(false);
  };
  const rightArrowClick = () => {
    setPlay(true);
    if (duration[1] >= moment(updatedTime).add(5, 'minutes').valueOf()) {
      setUpdatedTime((prev: any) => {
        return moment(prev).add(5, 'minutes').valueOf();
      });
    }
  };

  const leftArrowClick = () => {
    setPlay(true);
    if (duration[0] <= moment(updatedTime).subtract(5, 'minutes').valueOf()) {
      setUpdatedTime((prev: any) => {
        return moment(prev).subtract(5, 'minutes').valueOf();
      });
    }
  };

  const moreOptions = () => {
    setOpenMenu(true);
  };
  const onClickSpeedMenu = () => {
    setOpenMenu(false);
    setSpeedMenu(true);
  };

  const selectSpeed = (speed: number, e: any) => {
    setCurrent(e.key);
    const mainVideo: HTMLVideoElement | null = document.getElementById(
      'presentvideo',
    ) as HTMLVideoElement;
    if (mainVideo) {
      mainVideo.playbackRate = speed;
    }
    const elms: NodeListOf<HTMLVideoElement> =
      document.querySelectorAll("[id='subvideo']");
    setSpeedMenu(false);
    for (let i = 0; i < elms.length; i++) {
      elms[i].playbackRate = speed;
    }
  };

  return (
    <>
      <Modal
        title={''}
        visible={showModal}
        className="thubnailVideosFull"
        onCancel={Cancel}
        footer={null}
        width="95vw"
      >
        <Content>
          <Row>
            <Card
              title={tractorName}
              bordered={true}
              className="dbWidget videoDbCon"
              extra={
                <Space
                  className="mt0"
                  style={{ marginBottom: 0, position: 'relative' }}
                >
                  <p style={{ fontFamily: 'Montserrat-Medium' }}>
                    <a style={{ fontWeight: 'bold' }} onClick={leftArrowClick}>
                      <LeftOutlined />
                    </a>{' '}
                    <span>{updatedTime && getDateTime(updatedTime)}</span>
                    <a style={{ fontWeight: 'bold' }} onClick={rightArrowClick}>
                      {' '}
                      <RightOutlined />
                    </a>
                  </p>
                </Space>
              }
            >
              <CameraGrid2
                handleProgress={handleProgress}
                frontRef={frontRef}
                rearRef={rearRef}
                frontLeft={frontLeft}
                frontRight={frontRight}
                rearLeft={rearLeft}
                rearRight={rearRight}
                driverRef={driverRef}
                ptoRef={ptoRef}
                vedioInfo={vedioInfo}
                showLoader={showLoader}
              />
              {videos.length > 0 && (
                <div className="barDisplay">
                  <Button
                    style={{ display: 'block' }}
                    className="okBtn"
                    onClick={() => playVideo()}
                  >
                    {play ? <CaretRightOutlined /> : <PauseOutlined />}
                  </Button>
                  <div className="vedioprogress">
                    <div className="vedioprogress__filled"></div>
                  </div>
                  <div
                    style={{
                      width: '140px',
                      fontFamily: 'Montserrat-SemiBold',
                    }}
                  >
                    <span ref={curTime}>00:00</span> /{' '}
                    <span ref={fixTime}>00:00</span>
                  </div>
                  <div className="moreDiv">
                    <div onClick={moreOptions}>
                      <MoreOutlined />
                    </div>
                    {openMenu && (
                      <Menu>
                        <Menu.Item
                          key="playback speed"
                          onClick={onClickSpeedMenu}
                        >
                          Playback Speed
                        </Menu.Item>
                      </Menu>
                    )}
                    {speedMenu && (
                      <Menu className="">
                        <Menu.Item
                          key="0.25"
                          onClick={(e) => selectSpeed(0.25, e)}
                        >
                          0.25
                          {current === '0.25' && (
                            <CheckOutlined className="checkmark" />
                          )}
                        </Menu.Item>
                        <Menu.Item
                          key="0.5"
                          onClick={(e) => selectSpeed(0.5, e)}
                        >
                          0.5
                          {current === '0.5' && (
                            <CheckOutlined className="checkmark" />
                          )}
                        </Menu.Item>
                        <Menu.Item
                          key="0.75"
                          onClick={(e) => selectSpeed(0.75, e)}
                        >
                          0.75
                          {current === '0.75' && (
                            <CheckOutlined className="checkmark" />
                          )}
                        </Menu.Item>
                        <Menu.Item key="1" onClick={(e) => selectSpeed(1, e)}>
                          Normal
                          {current === '1' && (
                            <CheckOutlined className="checkmark" />
                          )}
                        </Menu.Item>
                        <Menu.Item
                          key="1.25"
                          onClick={(e) => selectSpeed(1.25, e)}
                        >
                          1.25
                          {current === '1.25' && (
                            <CheckOutlined className="checkmark" />
                          )}
                        </Menu.Item>
                        <Menu.Item
                          key="1.5"
                          onClick={(e) => selectSpeed(1.5, e)}
                        >
                          1.5
                          {current === '1.5' && (
                            <CheckOutlined className="checkmark" />
                          )}
                        </Menu.Item>
                        <Menu.Item
                          key="1.75"
                          onClick={(e) => selectSpeed(1.75, e)}
                        >
                          1.75
                          {current === '1.75' && (
                            <CheckOutlined className="checkmark" />
                          )}
                        </Menu.Item>
                        <Menu.Item key="2" onClick={(e) => selectSpeed(2, e)}>
                          2
                          {current === '2' && (
                            <CheckOutlined className="checkmark" />
                          )}
                        </Menu.Item>
                      </Menu>
                    )}
                  </div>
                </div>
              )}
              <div className="edit-loader">
                {showLoader && (
                  <div className="loader">
                    <img
                      style={{ width: '40px', height: '40px' }}
                      src={loading_logo}
                    />
                  </div>
                )}
              </div>
            </Card>
          </Row>
        </Content>
      </Modal>
    </>
  );
};

export default VedioThumbnailModal;
