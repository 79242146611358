/* eslint-disable no-console */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import equalFront from '../../assets/images/equalFront.svg';
import equalRear from '../../assets/images/equalRear.svg';
import rowWayPointer from '../../assets/images/rowWayPointer.svg';
import tractorAlertIcon from '../../assets/images/tractorAlert.svg';
import tractorspic from '../../assets/images/tractor_health.svg';
import './RemoteDriveStyle.css';

import {
  executeCommand,
  executeRemoteAVCommand,
  getGroundZero,
  getImplementAttached,
  getPreviewPath,
  getRoutePoints,
  getTractorIndicatorSummaryData,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';

import { View } from 'ol';
import Feature from 'ol/Feature';

import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import OLMap from 'ol/Map';
import * as proj from 'ol/proj';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import './../map/style.css';

import { Modal, notification } from 'antd';
import { MqttClient } from 'mqtt';
import { Coordinate } from 'ol/coordinate';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import { useMixpanel } from 'react-mixpanel-browser';
import alertIcon from '../../assets/images/alertIcon.svg';
import BaseStationIcon from '../../assets/images/basestation_green.png';
import endBtnIcon from '../../assets/images/endBtnIcon.svg';
import frontCam from '../../assets/images/front.svg';
import playIcon from '../../assets/images/heading_icon.svg';
import hitchCam from '../../assets/images/hitch.svg';
import leftCam from '../../assets/images/left.svg';
import pinchIcon from '../../assets/images/pinchIcon.svg';
import playerIcon from '../../assets/images/playerIcon.svg';
import playPause from '../../assets/images/playPause.svg';
import playResume from '../../assets/images/playResume.svg';
import rearCam from '../../assets/images/rear.svg';
import remoteLockIcon from '../../assets/images/remoteLockIcon.svg';
import tripOne from '../../assets/images/trip1.svg';
import tripTwo from '../../assets/images/trip2.svg';
import tripThree from '../../assets/images/trip3.svg';
import {
  displayRecenterViewPortSetUp,
  getConnectivityImg,
  getFloatImage,
  getFloatText,
  getGear,
  getGearRange,
  getGearRangeNumber,
  getHeight,
  getLatitudeAndLongitude,
  getPTOState,
  getRpm,
  getStatusForTractor,
  getTripMeterValue,
  pathColorConstants,
  polygonHoverLabel,
  priority,
  wrapIndictorsData,
} from '../../constants/Common';
import constant from '../../constants/constant';
import { TractorHeartBeat } from '../../constants/types';
import RoutesConstants from '../../routes/RoutesConstant';
import { getClient } from '../../util/MqttConnection';
import CameraGrid1 from '../map/CameraGrid1';
import { getPolygons, PolygonReturns } from '../map/Polygon';
import { GuardRailDetection } from './GuardRailDetection';
import Timer from './Timer';

const { TRACTOR_COMMUNICATION } = constant;
const url = window.location.href;
const origin = window.location.origin;
const popUrl = url.replace(RoutesConstants.RemoteDrive + '/', '');
const srcString = popUrl.replace(origin, '');
const viewData = srcString;
const tractorId = viewData.split('/')[0];
const tractorName = viewData.split('/')[2];
const type = viewData.split('/')[3];
const param = viewData.split('/')[4];
const heartBeatTopic = 'HeartBeat';
let updateLocationFromStream = false;
let globalMapInitialized = false;
const Remotedrive: React.FC = () => {
  const mixpanel = useMixpanel();
  const mapElement: any = useRef();
  const mapSmallElement: any = useRef();
  const mqttClientRef = useRef<MqttClient>();
  const movTractRef = useRef<Feature<any> | null>(null);
  const movTractPathRef = useRef<Feature<any> | null>(null);
  const mapRef = useRef<OLMap | null>(null);
  const pathLayerRef = useRef<VectorLayer<any>>();

  const { userDetails } = useContext(ApplicationContext);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [showPathModal, setShowPathModal] = useState(false);
  const [base, setBase] = useState<[number, number]>([0, 0]);
  const [velocity, setVelocity] = useState(0);
  const [implement, setImplement] = useState<string>('');
  const [triggeredGuardRail, setTriggeredGuardRail] = useState('');
  const [heartBeat, setheartBeat] = useState<TractorHeartBeat>();
  const [indicators, setIndicators] = useState<any>({});
  const [openedView, setOpenedView] = useState(1);
  const [previewPath, setPreviewPath] = useState<string>('');
  const [planEvent, setPlanEvent] = useState<{
    planner_name: string;
    planner_status: string;
  }>({ planner_name: 'StandBy', planner_status: 'Running' });
  const greenPolygonsLayerRef = useRef<VectorLayer<any>>();
  const whitePolygonsLayerRef = useRef<VectorLayer<any>>();
  const grayPolygonsLayerRef = useRef<VectorLayer<any>>();
  const fieldFeatureItem = useRef<any>();
  const centreEnabledRef = useRef(true);
  const tractorPaths = useRef<Coordinate[]>([]);
  const [layout, setLayout] = useState<number>(1);
  const [minimise, setMinimise] = useState<boolean>(false);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      document.title = `${tractorName} Remote Drive`;
      initializeMap();
    }
  }, [userDetails]);

  //  mapInitialization start here
  const initializeMap = async () => {
    try {
      const { organization } = userDetails;
      const { latitude, longitude } = await getGroundZero(
        organization.api_url,
        organization.farm.id,
      );
      setBase([latitude, longitude]);

      const baseLocation = new Feature({
        geometry: new Point(proj.fromLonLat([longitude, latitude])),
      });
      baseLocation.set('name', 'Ground zero');
      //  set id 1 for base station recenter
      baseLocation.setId(1);
      const style = new Style({
        image: new Icon({
          src: BaseStationIcon,
          scale: 0.1,
        }),
        zIndex: 100,
      });
      baseLocation.setStyle(style);

      const tracLocation = new Feature({
        geometry: new Point(proj.fromLonLat([longitude, latitude])),
      });

      tracLocation.setStyle(
        new Style({
          image: new Icon({
            src: rowWayPointer,
          }),
          zIndex: priority.TRACTOR,
        }),
      );

      const tractorPathStyle = new Style({
        // image: new CircleStyle({
        //   radius: 3,
        //   fill: new Fill({
        //     color: pathColorConstants.DEFAULT,
        //   }),
        // }),
        stroke: new Stroke({
          color: pathColorConstants.REMOTE_AV,
          width: 9,
        }),
        fill: new Fill({
          color: pathColorConstants.REMOTE_AV,
        }),
        zIndex: priority.PATH,
      });
      const tracPathLocation = new Feature({
        geometry: new LineString([proj.fromLonLat([longitude, latitude])]),
      });
      tracPathLocation.setStyle(tractorPathStyle);

      const initialFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [baseLocation, tracLocation, tracPathLocation],
        }),
        zIndex: Infinity,
      });

      const vectorLayer = new TileLayer({
        source: new OSM(),
        visible: false,
      });

      const satelliteLayer = new TileLayer({
        source: new XYZ({
          url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga',
          cacheSize: 1000,
        }),
        visible: true,
      });
      // live command path layer
      const initalPathLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: false,
        zIndex: priority.PREVIEWPATH,
      });

      // create map
      const initialMap = new OLMap({
        target: mapElement.current,
        layers: [
          satelliteLayer,
          vectorLayer,
          initialFeaturesLayer,
          initalPathLayer,
        ],
        controls: [],
        view: new View({
          projection: 'EPSG:3857',
          center: proj.transform(
            [longitude, latitude],
            'EPSG:4326',
            'EPSG:3857',
          ),
          zoom: 17,
          maxZoom: 25,
        }),
      });
      mapRef.current = initialMap;
      movTractRef.current = tracLocation;
      movTractPathRef.current = tracPathLocation;
      pathLayerRef.current = initalPathLayer;
      setMapInitialized(true);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  const handleZoom = (zoomType: string) => {
    if (mapRef.current) {
      const view = mapRef.current.getView();
      const zoom = view.getZoom();

      if (zoom) {
        if (zoomType === 'zoomIn') {
          view.setZoom(zoom + 1);
          mixpanel.track('Live Map', {
            event: `Map Zoom In ${zoom + 1}`,
          });
        } else {
          view.setZoom(zoom - 1);
          mixpanel.track('Live Map', {
            event: `Map Zoom Out ${zoom - 1}`,
          });
        }
      }
    }
  };
  useEffect(() => {
    globalMapInitialized = mapInitialized;
  }, [mapInitialized]);

  useEffect(() => {
    if (userDetails && userDetails.organization && mapInitialized) {
      const { organization } = userDetails;
      const mqttClient = getClient(
        organization.mqtt_ip,
        organization.mqtt_port,
      );
      mqttClient.on('connect', () => {
        mqttClientRef.current = mqttClient;
        mqttClient.subscribe([
          `localization_streaming_${tractorId}`,
          `planning_manager_streaming_${tractorId}`,
          `guardrails_ui_notification_${tractorId}`,
          `guardrail_triggered_${tractorId}`,
          `remoteav_preview_${tractorId}`,
          heartBeatTopic,
        ]);
      });

      mqttClient.on('message', (topic: string, message: any) => {
        if (!globalMapInitialized) return;
        if (topic === `localization_streaming_${tractorId}`) {
          updateLocationFromStream = true;
          const tractor = new TextDecoder('utf-8').decode(message);
          const data = tractor.split(',').map((item) => {
            return parseFloat(item);
          });

          const latAndLong: [number, number] = getLatitudeAndLongitude(
            [data[1], data[0]],
            base,
          );
          movTractRef &&
            movTractRef.current &&
            movTractRef.current?.setGeometry(
              new Point(proj.fromLonLat([latAndLong[1], latAndLong[0]])),
            );

          const latlan: Coordinate = proj.fromLonLat([
            latAndLong[1],
            latAndLong[0],
          ]);
          setTractorPoints(latlan);

          mapRef.current
            ?.getView()
            .setRotation(((data[5] - 90) * Math.PI) / 180);

          if (centreEnabledRef.current) {
            reCenter([data[1], data[0]]);
          }
          setVelocity(Number((data[6] * 2.237).toFixed(1).replaceAll('-', '')));
        }

        if (topic === `planning_manager_streaming_${tractorId}`) {
          const messageData = message.toString().trim();
          setPlanEvent({
            planner_name: messageData.split(',')[0],
            planner_status: messageData.split(',')[1],
          });
        }

        if (topic === `guardrail_triggered_${tractorId}`) {
          const pathData = message.toString().split(',');
          let guardRail = pathData[0];
          guardRail = guardRail.replace(/_/g, ' ');
          const triggerState = pathData.length === 7 ? pathData[6] : '';
          guardRailsCheck(guardRail, triggerState.trim());
        }

        if (topic === `guardrails_ui_notification_${tractorId}`) {
          const status = message.toString();
          if (status === 'False') {
            setShowPathModal(false);
          }
        }

        if (topic === heartBeatTopic) {
          const heartBeat: TractorHeartBeat = JSON.parse(message.toString());
          if (
            heartBeat &&
            heartBeat.organization_id &&
            userDetails.organization_id &&
            heartBeat.organization_id === userDetails.organization_id &&
            Number(tractorId) === heartBeat.tractor_id
          ) {
            setheartBeat(heartBeat);

            !updateLocationFromStream &&
              movTractRef &&
              movTractRef.current &&
              movTractRef.current?.setGeometry(
                new Point(
                  proj.fromLonLat([
                    heartBeat.localization.longitude,
                    heartBeat.localization.latitude,
                  ]),
                ),
              );
            !updateLocationFromStream && mapRef && mapRef.current;
            mapRef.current
              ?.getView()
              .setRotation(((heartBeat.localization.yaw - 90) * Math.PI) / 180);
          }
        }
        if (topic === `remoteav_preview_${tractorId}`) {
          setPreviewPath('');
          setPreviewPath(message.toString());
        }
      });
    }

    return () => {
      mqttClientRef.current?.unsubscribe([
        `localization_streaming_${tractorId}`,
        `planning_manager_streaming_${tractorId}`,
        `guardrails_ui_notification_${tractorId}`,
        `guardrail_triggered_${tractorId}`,
        `remoteav_preview_${tractorId}`,
        heartBeatTopic,
      ]);
      mqttClientRef.current?.end();
    };
  }, [userDetails, mapInitialized]);

  useEffect(() => {
    if (previewPath && previewPath.trim().length > 0) {
      handlePreview(previewPath);
    }
  }, [previewPath]);

  /**
   * Polygons initialization Starts Here
   */
  useEffect(() => {
    if (mapInitialized) {
      setTimeout(async () => {
        const {
          fieldFeature,
          greenLayer,
          whiteLayer,
          grayLayer,
        }: PolygonReturns = await getPolygons(userDetails, base);
        // setPolygons(list);
        fieldFeatureItem.current = fieldFeature;
        if (whiteLayer) {
          mapRef.current?.addLayer(whiteLayer);
          whitePolygonsLayerRef.current = whiteLayer;
        }
        if (greenLayer) {
          mapRef.current?.addLayer(greenLayer);
          greenPolygonsLayerRef.current = greenLayer;
        }
        if (grayLayer) {
          mapRef.current?.addLayer(grayLayer);
          grayPolygonsLayerRef.current = grayLayer;
        }
        polygonHoverLabel(mapRef.current);
      }, 10);
    }
  }, [userDetails, mapInitialized, base]);

  const setTitleAndDescription = (guardRail: string) => {
    switch (guardRail) {
      case 'human protection':
        return {
          description: 'Person detected close to moving tractor',
          title: 'Human Presence',
        };
      case 'vehicle presence':
        return {
          description: 'Vehicle detected near moving tractor',
          title: 'Vehicle Presence',
        };
      case 'pto protection':
        return {
          description: 'Person detected close to spinning PTO',
          title: 'PTO Danger',
        };
      case 'path protection':
        return {
          description: 'Obstruction detected in the tractors path',
          title: 'Path Detected',
        };
      case 'speed protection':
        return {
          description: 'Speed exceeding safe limits',
          title: 'Speed Guardrail',
        };
      case 'pitch roll protection':
        return {
          description: 'Danger of rolling tractor over',
          title: 'Pitch/Roll Alert',
        };

      default:
        return {
          description: '',
          title: '',
        };
    }
  };

  const guardRailsCheck = async (guardRail: string, triggerState: string) => {
    try {
      setTriggeredGuardRail(guardRail);
      if (triggerState === 'passive') {
        const titleAndDescription = setTitleAndDescription(guardRail);
        notification.warning({
          message: titleAndDescription.title,
          description: titleAndDescription.description,
        });
      } else if (triggerState === 'active') {
        if (
          guardRail === 'path protection' ||
          guardRail === 'human protection' ||
          guardRail === 'pto protection'
        ) {
          setShowPathModal(true);
        } else {
          const titleAndDescription = setTitleAndDescription(guardRail);
          notification.warning({
            message: titleAndDescription.title,
            description: titleAndDescription.description,
          });
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('error');
    }
  };

  const reCenter = (location: [number, number]) => {
    if (mapRef.current) {
      const latAndLong: [number, number] = getLatitudeAndLongitude(
        location,
        base,
      );

      mapRef.current
        .getView()
        .setCenter(proj.fromLonLat([latAndLong[1], latAndLong[0]]));
    }
  };

  useEffect(() => {
    if (mapInitialized) {
      if (type === 'routine') {
        getRoutinePoints();
      } else {
        showPreviewPath();
      }
    }
  }, [mapInitialized]);

  const showPreviewPath = () => {
    const { organization } = userDetails;
    getPreviewPath(organization.api_url, param)
      .then((response: { data: any }) => {
        handlePreview(response.data);
      })
      .catch((err) => {
        notification.error({
          message: err.response?.data.error.message,
        });
      });
  };
  const handlePreview = (data: string) => {
    pathLayerRef.current?.getSource().clear();
    const latlongTextList = data.split('|');
    const coordinates: Coordinate[] = [];
    latlongTextList.forEach((record: any) => {
      const latlong = record?.split(',');
      coordinates.push(proj.fromLonLat([latlong[1], latlong[0]]));
    });

    const tractorLocationFeature: Feature<Geometry> = new Feature();
    tractorLocationFeature.setGeometry(new LineString(coordinates));

    const style = [
      new Style({
        stroke: new Stroke({
          color: 'rgba(194, 199, 216, 1)',
          width: 7,
        }),
        zIndex: 100,
      }),
      new Style({
        stroke: new Stroke({
          color: 'rgba(194, 199, 216, 1)',
          width: 7,
        }),
      }),
    ];

    tractorLocationFeature.setStyle(style);
    pathLayerRef.current?.setSource(
      new VectorSource({
        features: [tractorLocationFeature],
      }),
    );
    pathLayerRef.current?.setVisible(true);
  };

  const getRoutinePoints = async () => {
    try {
      const routinePoints = await getRoutePoints(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        param.split(':')[0] as any,
      );

      if (routinePoints && routinePoints.length > 0) {
        const coordinates: any = [];
        routinePoints.forEach((element: any) => {
          const latAndLong = getLatitudeAndLongitude(
            [element.y, element.x],
            base,
          );

          coordinates.push(proj.fromLonLat([latAndLong[1], latAndLong[0]]));
        });

        // head start here
        const headPointData = routinePoints[0];
        const blueDotStyleA = new Style({
          image: new Icon({
            src: playIcon,
            scale: 0.5,
            rotation: ((90 - headPointData.bearing) * Math.PI) / 180,
          }),
        });
        const pointLocation = new Feature({
          geometry: new Point(coordinates[0]),
        });
        pointLocation.setStyle(blueDotStyleA);
        // head ends here

        const layerLines = new Feature({
          geometry: new LineString(coordinates),
          name: 'Line',
        });

        const lineLayers = new VectorLayer({
          source: new VectorSource({
            features: [layerLines, pointLocation],
          }),
          style: new Style({
            stroke: new Stroke({ color: '#98DCE0', width: 9 }),
          }),
        });

        mapRef.current?.addLayer(lineLayers);
        if (mapRef.current) {
          displayRecenterViewPortSetUp(
            layerLines as Feature<Geometry>,
            mapRef.current,
            movTractRef.current as Feature<Geometry>,
          );
        }
      }
    } catch (error: any) {
      notification.error({
        message: 'No Data Found',
      });
    }
  };

  useEffect(() => {
    const getImplement = async () => {
      try {
        const { organization } = userDetails;
        const { attached_implement } = await getImplementAttached(
          organization.api_url,
          tractorId as any,
        );
        setImplement(attached_implement);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };

    if (userDetails && userDetails.organization) {
      getImplement();
    }
  }, [userDetails]);

  const handleStart = async () => {
    if (type === 'routine') {
      startRoutine();
    } else {
      startAutoDrive();
    }
  };

  const startRoutine = async () => {
    try {
      const obj = {
        operator_id: userDetails.id,
        planner: 'copycat',
        action: 'start',
        tractor_id: tractorId,
        organization_id: userDetails.organization_id,
        reason: 'string',
        parameters: [
          {
            name: 'route_name',
            value: param.split(':')[1],
          },
        ],
      };

      const report = await executeCommand(
        userDetails.organization.api_url,
        param.split(':')[2],
        obj,
      );
      notification.success({
        message: report.msg,
      });
    } catch (error) {
      notification.error({
        message: 'No Data Found',
      });
    }
  };

  const startAutoDrive = async () => {
    try {
      const { organization } = userDetails;
      const { msg } = await executeRemoteAVCommand(organization.api_url, {
        organization_id: userDetails.organization_id,
        action: 'start',
        tractor_id: tractorId,
        drive_action_uuid: param,
      });
      notification.success({
        message: msg,
      });
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  const handlePauseResumeStopLiveCom = async (command: string) => {
    try {
      const { organization } = userDetails;
      if (type === 'autodrive') {
        const { msg } = await executeRemoteAVCommand(organization.api_url, {
          organization_id: organization.id,
          operator_id: userDetails.id,
          action: command,
          planner: 'remote_av',
          tractor_id: tractorId,
          drive_action_uuid: param,
        });
        notification.success({
          message: msg,
        });
      } else {
        const obj = {
          operator_id: userDetails.id,
          planner: 'copycat',
          action: command,
          tractor_id: tractorId,
          organization_id: userDetails.organization_id,
          parameters: [
            {
              name: 'reason',
              value:
                command === 'pause'
                  ? 'operator initiated'
                  : command === 'pause'
                  ? 'operator initiated_soft pause'
                  : '',
            },
          ],
        };

        const { msg } = await executeCommand(
          userDetails.organization.api_url,
          param.split(':')[2],
          obj,
        );
        notification.success({
          message: msg,
        });
      }

      if (command === 'stop') {
        window.close();
      }
      if (command === 'resume' && showPathModal) {
        setShowPathModal(false);
      }
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  mapRef?.current?.on('pointerdrag', () => {
    centreEnabledRef.current = false;
  });

  const handleIgnore = async (action: string) => {
    try {
      const { organization } = userDetails;
      const drive_action_uuid =
        type === 'autodrive' ? param : param.split(':')[2];

      const { msg } = await executeCommand(
        organization.api_url,
        drive_action_uuid,
        {
          action,
          operator_id: userDetails.id,
          planner: 'guardrails',
          tractor_id: tractorId,
          organization_id: userDetails.organization_id,
          parameters: [],
        },
      );

      notification.success({
        message: msg,
      });
      setShowPathModal(false);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  const setTractorPoints = (points: Coordinate) => {
    const existingPath = tractorPaths.current;
    if (existingPath) {
      let path = existingPath;
      if (path) {
        if (path.length > 5000) {
          path = path.splice(2500);
        }
        path?.push(points);
        tractorPaths.current = path;
      } else {
        tractorPaths.current = [points];
      }
    } else {
      tractorPaths.current = [points];
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && mapInitialized) {
      const interval = setInterval(() => {
        drawTractorPath();
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [userDetails, mapInitialized]);

  const drawTractorPath = () => {
    const existingPath = tractorPaths.current;
    movTractPathRef?.current?.setGeometry(new LineString(existingPath));
  };

  useEffect(() => {
    if (userDetails && userDetails.organization && tractorId) {
      getTractorIndicatorSummary();
    }
  }, [userDetails, tractorId]);

  const getTractorIndicatorSummary = async () => {
    try {
      const data = await getTractorIndicatorSummaryData(
        userDetails.organization.api_url,
        Number(tractorId),
      );
      const indicatorsData = wrapIndictorsData(data);
      setIndicators(indicatorsData);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    if (mapInitialized) {
      if (openedView === 1) {
        mapRef?.current?.setTarget(mapElement.current);
      } else {
        mapRef?.current?.setTarget(mapSmallElement.current);
      }
      mapRef.current?.updateSize();
    }
  }, [openedView, mapInitialized]);

  const cameraMap: any = {
    front: 'Front',
    rear: 'Rear',
    front_left: 'Front Left',
    front_right: 'Front Right',
    pto: 'Hitch',
  };

  const frameHTML: any = useCallback(
    (view: string) => (
      <>
        <h1 className="iframe-title"> {cameraMap[view]} </h1>
        <iframe
          frameBorder="0"
          className="responsive-iframe"
          src={`${origin}/CameraView/${tractorId}/stream/${tractorName}/${view}/false`}
        ></iframe>
      </>
    ),
    [],
  );

  const { confirm } = Modal;

  const showDeleteConfirm = () => {
    confirm({
      className: 'confirm-end-operation',
      title: 'End Operation?',
      icon: (
        <div className="endOptIcon">
          <img width="60" src={endBtnIcon} />
        </div>
      ),
      content:
        'Are you sure you want to end this operation before it has been completed?',
      okText: 'End Operation',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        handlePauseResumeStopLiveCom('stop');
      },
      // onCancel() {},
    });
  };

  return (
    <>
      <div className={minimise ? 'remoteDrive mini' : 'remoteDrive'}>
        <div className="remoteLivemapBlk">
          <div className="alertBlk flexBtn">
            <img src={alertIcon} alt="" />
            <img
              src={pinchIcon}
              alt=""
              onClick={() => setMinimise(!minimise)}
            />
          </div>
          <div className="playStatus">{planEvent.planner_status}</div>
          <div className="statusTxt">
            {getStatusForTractor(planEvent.planner_name)}
          </div>
          <h2 className="remotetTractorName">{tractorName}</h2>
          <div className="remoteUsername">
            <img src={remoteLockIcon} alt="" /> {userDetails?.first_name}{' '}
            {userDetails?.last_name}
          </div>
          <div className="remoteIndicatorIcons">
            <img
              className="mr4 imgwhite"
              width="18px"
              height="18px"
              src={
                indicators &&
                Object.keys(indicators).filter(
                  (indicator: string) => indicators[indicator] === 3,
                ).length > 0
                  ? tractorAlertIcon
                  : tractorspic
              }
            />
            <img
              className="mr4 imgwhite"
              src={getConnectivityImg(indicators[TRACTOR_COMMUNICATION])}
            />
          </div>
          <div className="tractorSpeedometer">
            <span className="trip-meter">
              TRIP {getTripMeterValue({ heartbeat: heartBeat })}
            </span>
          </div>
          <div className="tripDetails">
            <div className="flexStart">
              <span className="opImage">
                <img src={tripOne} alt="" />
              </span>
              <span className="opCount">
                {getPTOState(heartBeat)
                  ? getRpm(heartBeat as TractorHeartBeat, '')
                  : '-'}
              </span>
              <span className="opMesure">
                {getPTOState(heartBeat) ? ' PTO RPM' : ' PTO OFF'}
              </span>
            </div>
            <div className="flexStart">
              <span className="opImage">
                <img src={tripTwo} alt="" />
              </span>
              <span className="opCount">
                {getGearRange(getGear(heartBeat))}
                {getGearRangeNumber(getGear(heartBeat))}
              </span>
              <span className="opMesure">GEAR</span>
            </div>
            <div className="flexStart">
              <span className="opImage">
                <img src={tripThree} alt="" />
              </span>
              <span className="opCount">{getHeight(heartBeat)}%</span>
              <span className="opMesure">HEIGHT</span>
            </div>
            <div className="flexStart aLeft">
              <span className="opImage">
                {/* <img src={tripFour} alt="" /> */}
                {getFloatImage(heartBeat, planEvent.planner_name) && (
                  <div
                    className="t_float_custom isize"
                    dangerouslySetInnerHTML={{
                      __html: getFloatImage(heartBeat, planEvent.planner_name),
                    }}
                  />
                )}
              </span>{' '}
              {!getFloatImage(heartBeat, planEvent.planner_name) && (
                <span className="opCount">-</span>
              )}
              <span className="opCount">{getFloatText(heartBeat)}</span>
            </div>
          </div>
          <>
            {planEvent.planner_name &&
              planEvent.planner_name.length > 0 &&
              planEvent.planner_status &&
              planEvent.planner_status.length > 0 && (
                <>
                  {planEvent.planner_name === 'copycat' ||
                  planEvent.planner_name === 'remote_av' ? (
                    <>
                      {planEvent.planner_status === 'paused' ? (
                        <>
                          <div className="playButtonsSec">
                            <div
                              className="playButtonBg"
                              onClick={() =>
                                handlePauseResumeStopLiveCom('resume')
                              }
                            >
                              <img src={playResume} className="ml5" />
                            </div>
                            <div className="palyActionTxt">
                              Resume Operation
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="playButtonsSec">
                            <div
                              className="playButtonBg"
                              onClick={() =>
                                handlePauseResumeStopLiveCom('pause')
                              }
                            >
                              <img src={playPause} className="" />
                            </div>
                            <div className="palyActionTxt">Pause Operation</div>
                          </div>
                        </>
                      )}
                      <div className="endOperation" onClick={showDeleteConfirm}>
                        <img src={endBtnIcon} className="mr10" />
                        End Operation
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="playButtonsSec">
                        <div
                          className="playButtonBg"
                          onClick={() => handleStart()}
                        >
                          <img src={playResume} className="ml5" />
                        </div>
                        <div className="palyActionTxt">Start Operation</div>
                      </div>
                    </>
                  )}
                </>
              )}
            {/* <h6>PAUSE OPERATION</h6> */}
          </>
        </div>
        <div
          className="cameraContainer"
          style={{ display: minimise ? 'none' : 'block' }}
        >
          <div className="cameraWrapper">
            <CameraGrid1
              frameHTML={frameHTML}
              mapSmallElement={mapSmallElement}
              openedView={openedView}
              mapElement={mapElement}
              centreEnabledRef={centreEnabledRef}
              handleZoom={handleZoom}
              layout={layout}
            />
          </div>
          <div className="cameraControls">
            <img src={playerIcon} alt="" />
            <div className="layoutButtonSec">
              <button
                className={
                  layout === 1
                    ? 'layoutButton layout1 lsel'
                    : 'layoutButton layout1'
                }
                onClick={() => setLayout(1)}
              ></button>

              <button
                className={
                  layout === 3
                    ? 'layoutButton layout2 lsel'
                    : 'layoutButton layout2'
                }
                onClick={() => setLayout(3)}
              ></button>
              <button
                className={
                  layout === 2
                    ? 'layoutButton layout3 lsel'
                    : 'layoutButton layout3'
                }
                onClick={() => setLayout(2)}
              ></button>
            </div>
          </div>
        </div>
        <div className="remotecamerasBlk">
          <div className="remotcameraBlk">
            {/* <CameraGrid1
              frameHTML={frameHTML}
              mapSmallElement={mapSmallElement}
              openedView={openedView}
              mapElement={mapElement}
              centreEnabledRef={centreEnabledRef}
              handleZoom={handleZoom}
            /> */}
          </div>
          {/* Layout 2 start here */}
          <div className="remotecameraBlk2" style={{ display: 'none' }}>
            <div className="equalGrid">
              <img src={equalFront} alt="" />
              <h6>FRONT</h6>
            </div>
            <div className="equalGrid">
              <img src={equalRear} alt="" />
              <h6>HITCH</h6>
            </div>
            <div className="equalGrid">
              <img src={equalFront} alt="" />
              <h6>RIGHT</h6>
            </div>
            <div className="equalGrid">
              <img src={equalRear} alt="" />
              <h6>REAR</h6>
            </div>
            <div className="equalGrid">
              <img src={equalFront} alt="" />
              <h6>LEFT</h6>
            </div>
            <div className="equalGrid">
              <img src={equalRear} alt="" />
              <h6>MAP</h6>
            </div>
          </div>
          {/* Layout 3 start here */}
          <div className="remotecameraBlk3" style={{ display: 'none' }}>
            <div className="subCams2">
              <div className="subCam2">
                <img src={rearCam} alt="" />
                <h6>FRONT</h6>
              </div>
              <div className="subCam2">
                <img src={rearCam} alt="" />
                <h6>REAR</h6>
              </div>
              <div className="subCam2">
                <img src={hitchCam} alt="" />
                <h6>HITCH</h6>
              </div>
              <div className="subCam2">
                <img src={leftCam} alt="" />
                <h6>LEFT</h6>
              </div>
            </div>
            <div className="camandmap">
              <div className="camandmapGrid">
                <img src={frontCam} alt="" />
                <h4>RIGHT</h4>
              </div>
              <div className="camandmapGrid">
                <img src={frontCam} alt="" />
                <h4>MAP</h4>
              </div>
            </div>
          </div>
          {/* <div className="playerBlk" style={{ position: 'relative' }}>
            <img src={playerIcon} alt="" /> */}
          {/* <div className="layoutButtonSec">
              <button className="layoutButton layout1 lsel"></button>
              <button className="layoutButton layout2"></button>
              <button className="layoutButton layout3"></button>
            </div> */}
          {/* </div> */}
        </div>
        {showPathModal && (
          <GuardRailDetection
            resize={minimise}
            triggeredGuardRail={triggeredGuardRail}
            handleIgnore={() => handleIgnore('ignore_and_continue')}
          />
        )}
        <Timer
          promptTimeoutMinutes={1}
          timeoutMinutes={5}
          handleAction={() => handlePauseResumeStopLiveCom('stop')}
        />
      </div>
    </>
  );
};

export default Remotedrive;
