import Layout, { Content } from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Tabs } from 'antd';
import { useLocation } from 'react-router-dom';
import { useMixpanel } from 'react-mixpanel-browser';
import AnalyticsDatabase from './Database/AnalyticsDatabase';
import AnalyticsContextProvider from './AnalyticsContext';
import Library from '../library/Library';
import ReportsDistribution from '../reports/ReportsDistrbution';
import FleetUsageCharts from '../fleetUsageBarCharts/FleetUsageCharts';
import { DriveAction } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { SET_JAG_DACTION, SET_LAST_TAB_INDEX } from '../../context/actions';
import AnalyticsWrapper from './Database/AnalyticsWrapper';
import AnalyticsDetails from './Database/AnalyticsDetails';
import { privilagesConstants } from '../../constants/Privilages';

const { TabPane } = Tabs;
const AnalyticContainer: React.FC = () => {
  const location = useLocation();
  const state: any = location && location.state ? location.state : {};
  const [tabid, setTabid] = useState<any>('Operations');
  const [transactionId, setTransactionId] = useState('');
  const [showLiveMap, setShowLiveMap] = useState<boolean>(false);
  const [driveAction, setDriveAction] = useState<DriveAction | null>(null);
  const { APPReducer, privilegeChecker } = useContext(ApplicationContext);
  const [tstate, dispatch] = APPReducer;
  const { routeRefresh, jAGDAction } = tstate;

  const mixpanel = useMixpanel();

  useEffect(() => {
    if (jAGDAction) {
      setTabid('Operations');
      if (jAGDAction) {
        setDriveAction(jAGDAction);
        setShowLiveMap(true);
      }
    }
    return () => {
      window.history.replaceState({}, document.title);
      setDriveAction(null);
      dispatch({
        type: SET_JAG_DACTION,
        payload: null,
      });
    };
  }, [routeRefresh]);

  useEffect(() => {
    if (state && state.action && state.action === 'Operations') {
      setTabid(state.action);
      if (state.transactionId && state.transactionId.trim() !== '') {
        setTransactionId(state.transactionId);
      }
    }
    return () => {
      window.history.replaceState({}, document.title);
      setTransactionId('');
    };
  }, [state.action, state.drive_action]);

  useEffect(() => {
    const lastTabIndex: string | null = localStorage.getItem('lastTabIndex');
    const tlastTabIndex: any = lastTabIndex && JSON.parse(lastTabIndex);
    if (tlastTabIndex?.catlog === 'Analytics') {
      setTabid(tlastTabIndex?.tab);
    } else if (privilegeChecker(privilagesConstants.Map_Analytics_Access)) {
      setTabid('Operations');
    } else {
      setTabid('Library');
    }
  }, [localStorage.getItem('lastTabIndex')]);

  return (
    <AnalyticsContextProvider>
      <Layout>
        <Content>
          <div className="mainContent">
            <div className="dbWidget attContainer padt0 analyticsTab bNone sphVH antBdrNone">
              <div style={{ display: showLiveMap ? 'none' : 'block' }}>
                <Card>
                  <Tabs
                    activeKey={tabid}
                    className={
                      tabid && tabid === '1' ? 'elTabs elTabs1' : 'elTabs'
                    }
                    onChange={(key) => {
                      setTabid(key);
                      dispatch({
                        type: SET_LAST_TAB_INDEX,
                        payload: {
                          catlog: 'Analytics',
                          tab: key,
                        },
                      });
                      mixpanel.track(key, {
                        event: `${key} Tab`,
                      });
                    }}
                  >
                    {privilegeChecker(
                      privilagesConstants.Map_Analytics_Access,
                    ) && (
                      <TabPane
                        tab="Operations"
                        key="Operations"
                        className="attTableHeight mBottom0 elTabs1"
                        data-testid="operationsmenu-Analytics"
                        style={{ top: -50 }}
                      >
                        <AnalyticsDatabase
                          toggleLiveMap={() => setShowLiveMap(!showLiveMap)}
                        />
                      </TabPane>
                    )}
                    {process.env.REACT_APP_ALPHA_FEATURES &&
                      process.env.REACT_APP_ALPHA_FEATURES.includes(
                        'CostExplorer',
                      ) && (
                        <TabPane
                          tab="Cost explorer"
                          key="Cost explorer"
                          className="attTableHeight mBottom0"
                          data-testid="costexplorersmenu-Analytics"
                        >
                          <FleetUsageCharts></FleetUsageCharts>
                        </TabPane>
                      )}
                    <TabPane
                      tab="Library"
                      key="Library"
                      className="attTableHeight mBottom0"
                      data-testid="librarysmenu-Analytics"
                    >
                      <Library transactionId={transactionId} />
                    </TabPane>
                    <TabPane
                      tab="Reports"
                      key="Reports"
                      className="attTableHeight mBottom0"
                      data-testid="reportsmenu-Analytics"
                    >
                      <ReportsDistribution />
                    </TabPane>
                  </Tabs>
                </Card>
              </div>
              {!driveAction && (
                <AnalyticsWrapper
                  setShowLiveMap={setShowLiveMap}
                  showLiveMap={showLiveMap}
                />
              )}
              {driveAction && (
                <AnalyticsDetails
                  driveAction={driveAction}
                  setShowLiveMap={setShowLiveMap}
                  setDriveAction={setDriveAction}
                />
              )}
            </div>
          </div>
        </Content>
      </Layout>
    </AnalyticsContextProvider>
  );
};

export default AnalyticContainer;
