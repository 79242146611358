import { useEffect, useState, useContext } from 'react';
import { getGuraudrailsCamera, getSnapshot } from './Api';
import { sortCameraViews } from './Common';
import { ApplicationContext } from '../context/AppContext';
const GUARD_RAILS = 'GUARD_RAILS';

export const useCameraSnap = (guardrails: any) => {
  const [data, setData] = useState<any>(null);
  const { userDetails } = useContext(ApplicationContext);
  useEffect(() => {
    const initGuardrailsCamera = async () => {
      try {
        const data = await getGuraudrailsCamera(
          userDetails.organization.api_url,
          userDetails.organization.id,
          guardrails.guard_rail_triggered_uuid,
        );
        setData(data);
      } catch (error) {
        // error
      }
    };
    if (guardrails?.created_at === GUARD_RAILS) initGuardrailsCamera();
  }, [guardrails?.guard_rail_triggered_uuid]);
  return [data];
};

export const useSnap = (guardrails: any) => {
  const [snapshot, setSnapShots] = useState<any>([]);
  const { userDetails } = useContext(ApplicationContext);
  useEffect(() => {
    const init = async () => {
      try {
        const response = await getSnapshot(
          userDetails.organization.api_url,
          guardrails.guard_rail_triggered_uuid,
          guardrails.id,
        );
        const result = sortCameraViews(response, 'camera_name', 'images');
        setSnapShots(result);
      } catch (error) {
        // error
      }
    };
    if (guardrails?.id) init();
  }, [guardrails?.guard_rail_triggered_uuid]);

  return [snapshot];
};
