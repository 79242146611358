import { ImportOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import React from 'react';

const Details: React.FC = () => {
  // need
  return (
    <div className="digiAppContainer">
      <Result
        icon={<ImportOutlined style={{ color: '#39515c' }} />}
        title={<div className="importTitle">Import Locations From Trimble</div>}
        extra={
          <Button className="importBtn" type="primary">
            {' '}
            Import
          </Button>
        }
      />
    </div>
  );
};

export default Details;
