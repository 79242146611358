/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-key */

import {
  Input,
  notification,
  Spin,
  Select,
  Upload,
  Avatar,
  Image,
  Modal,
  Table,
  Button,
} from 'antd';

import Layout from 'antd/lib/layout/layout';
import { useMixpanel } from 'react-mixpanel-browser';
import React, { useContext, useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import './TicketsStyle.css';
import closeGreen from '../../assets/images/closeGreen.svg';
import FlagGrayIcon from '../../assets/images/flag_gray.svg';
import FlagOrangeIcon from '../../assets/images/flag_orange.svg';
import FlagRedIcon from '../../assets/images/flag_red.svg';
import addCBtn from '../../assets/images/addCBtn.svg';
import loading_logo from '../../assets/images/loading_logo.gif';
import {
  getOrgTags,
  getOrgEquipments,
  updateTicket,
  addComment,
  getTasksearch,
  getTicketById,
  getCommentsTickets,
  ticketaddattachment,
  UpdateStatus,
  DeleteAttachmentFile,
  forwaredTicketDetails,
  getVideoURL,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import errorCodeIcon from '../../assets/images/errorCodeIcon.svg';

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Taskview, Taskviewdetails } from '../../constants/types';
import { Tag } from 'antd';
import { getDateTimes } from '../../constants/Common';
import applicationIds from '../../locale/applicationIds.json';
import Interweave from 'interweave';
import { privilagesConstants } from '../../constants/Privilages';
import { TOGGLE_VIDEO_WIDGET } from '../../context/actions';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import ticketFwd from '../../assets/images/ticketFwd.gif';
import ticketSucess from '../../assets/images/ticket_success.svg';
import verM_logo from '../../assets/images/verM_logo.svg';
import Assigned_to from '../../assets/images/assigned_to.png';
import { RcFile } from 'antd/lib/upload';
import { speCharRegX } from '../../constants/constant';
import AppLoader from '../common/AppLoader';

const { CheckableTag } = Tag;
const { Option } = Select;

interface Props {
  ticketObj?: any;
  Close: () => void;
  afterSave: () => void;
  toggleWidget: boolean;
}
const TicketViewEdit: React.FC<Props> = ({
  ticketObj,
  Close,
  afterSave,
  toggleWidget,
}: Props) => {
  const { privilegeChecker, APPReducer } = useContext(ApplicationContext);
  const { TextArea } = Input;
  const { t } = useTranslation();
  const [editflag, seteditflag] = useState<boolean>(false);
  const [editflagBody, seteditflagBody] = useState<boolean>(false);
  const [description, setDescription] = useState<any>();
  const { userDetails } = useContext(ApplicationContext);
  const { errorCodes } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [tagsList, setTagsList] = useState<any[]>([]);
  const [equipmentsList, setEquipmentsList] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [selectedEquipment, setSelectedEquipment] = useState<any>();
  const [commentflag, setComment] = useState<boolean>(false);
  const [commentData, setCommentData] = useState<any>();
  const [result, setResult] = useState<Taskviewdetails[]>([]);
  const [assignid, setAssignid] = useState<any>(userDetails.id);
  const [TickObj, setticketObj] = useState<any>();
  const [CommentsData, setCommentsData] = useState<any>([]);
  const [FleetData, setFleetData] = useState<any>([]);
  const [stateVarible, setStateVarible] = useState<any>(1);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isresolvedDisabled, setIsresolvedDisabled] = useState<boolean>(false);
  const [forwardTicketLoader, setForwardTicketLoader] =
    useState<boolean>(false);
  const [ticketLoader, setTicketLoader] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [ticketObjectLoaing, setTicketObjectLoading] = useState<boolean>(false);
  const [errCode, setErrCodes] = useState<any>();
  const mixpanel = useMixpanel();
  const [state, dispatch] = APPReducer;
  const [forwardticketmodal, setforwardTicketModel] = useState(false);
  const [statusmodal, setstatusmodal] = useState(false);
  const [errorcodeFlag, setErrorcodeFlag] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [currentIndex, setCurrentIndex] = useState<any>();
  const [showDescriptionCount, setShowDescriptionCount] =
    useState<boolean>(false);
  const [descriptionCount, setDescriptionCount] = useState<number>(0);
  const [vedioinfo, setVedioinfo] = useState<any>();
  const [uuidnum, setuuid] = useState<any>();
  const onChange = (info: any) => {
    const { file, fileList } = info;
    if (fileList.length && file.status === 'uploading' && file.percent === 0) {
      fileList[fileList.length - 1].status = 'done';
      SaveAttach(file);
      mixpanel.track('TicketViewEdit', {
        event: 'Added Ticket Attachment',
      });
    } else if (file?.id) {
      DeleteAttachment(file);
    }
  };

  const [stauslable, SetStatusLable] = useState<any>();
  const [descriptionResolve, setDescriptionResolve] = useState<any>();

  const getVideoData = async () => {
    const { date_time: videoTime, tractorId, tractor_id } = ticketObj;
    const tractorDetails = tractorId || tractor_id;
    const response = await getVideoURL(
      userDetails.organization.api_url,
      userDetails.organization.farm.id,
      tractorDetails,
      videoTime,
    );

    if (response.length === 0) {
      setShowVideo(true);
      setVedioinfo('');
    } else {
      setVedioinfo(response[0].video_url);
      setShowVideo(false);
    }
  };

  useEffect(() => {
    if (
      stateVarible > 0 &&
      description &&
      description.trim().length > 2 &&
      selectedEquipment
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [stateVarible, description, selectedEquipment]);

  useEffect(() => {
    if (descriptionResolve && descriptionResolve.trim().length > 2) {
      setIsresolvedDisabled(false);
    } else {
      setIsresolvedDisabled(true);
    }
  }, [descriptionResolve]);

  useEffect(() => {
    if (description !== undefined) setDescriptionCount(description.length);
    if (
      description !== undefined &&
      description.length >= 0 &&
      description.length <= 500
    ) {
      setShowDescriptionCount(true);
    } else {
      setShowDescriptionCount(false);
    }
  }, [description]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      loadTags();
      loadEquipments();
      getassigned();
    }
  }, [userDetails]);

  useEffect(() => {
    if (toggleWidget) {
      seteditflag(false);
    }
  }, [toggleWidget]);

  useEffect(() => {
    if (ticketObj?.id) {
      setComment(false);
      setticketObj(ticketObj);
      getVideoData();
      getbyticketbyid();
      getComments();
      setAssignid(ticketObj?.assignee_id);
    }
  }, [ticketObj]);

  const DeleteAttachment = async (file: any) => {
    try {
      const payload = {
        data_key: 'ATTACHMENTS',
      };
      const result: any = await DeleteAttachmentFile(
        userDetails.organization.api_url,
        file?.fleet_id,
        file?.id,
        file?.fleet_maintenance_log_id,
        payload,
      );
      notification.success({
        message: t(translate.label.ticketAttachmentDeleted),
        duration: 2,
      });
      getbyticketbyid();
    } catch (err: any) {
      notification.error({
        message: err.result?.data.error.message,
        duration: 2,
      });
    }
  };

  const getComments = async () => {
    setCommentsData([]);
    const datakey: any = 'COMMENTS';
    const result: any = await getCommentsTickets(
      userDetails.organization.api_url,
      ticketObj?.fleet_id,
      ticketObj?.id,
      datakey,
    );
    setCommentsData(result);
  };

  const getbyticketbyid = async () => {
    try {
      setTicketObjectLoading(true);
      const result: any = await getTicketById(
        userDetails.organization.api_url,
        ticketObj?.fleet_id,
        ticketObj?.id,
      );
      if (result.error_codes) {
        const commaData = result.error_codes.includes(',');
        const pipeData = result.error_codes.includes('|');
        if (commaData) {
          const error_codes = result.error_codes.split(',');
          const errordata = errorCodes.filter((x: any) =>
            error_codes.includes(x.indicator_error_code.toString()),
          );
          setErrCodes(errordata);
        } else if (pipeData) {
          const error_codes = result.error_codes.split('|');
          const errordata = errorCodes.filter((x: any) =>
            error_codes.includes(x.indicator_error_code.toString()),
          );
          setErrCodes(errordata);
        }
      }

      const dataarray: any[] = [];
      result?.Attachments?.map((ele: any, index: any) => {
        const obj = {
          uid: index.toString(),
          name: '',
          status: 'done',
          url: ele.image_url ? ele.image_url : '',
          id: ele.id,
          fleet_maintenance_log_id: ele?.fleet_maintenance_log_id,
          fleet_id: result?.fleet_id,
          index: index,
        };
        dataarray.push(obj);
      });
      setFleetData(dataarray);
      const lable = result?.status !== 'open' ? 'Resolved' : 'Unresolved';
      SetStatusLable(lable);
      if (result.assignee) {
        const { first_name, last_name } = result.assignee;
        result.assignee = `${first_name} ${last_name}`;
      }
      if (result.created_by_user) {
        const { first_name, last_name } = result.created_by_user;
        result.created_by_user = `${first_name} ${last_name}`;
      }
      const fleetTags: any = [];
      result?.Tags?.map((tag: any) => {
        fleetTags.push(tag?.data_value);
      });
      result.tags = fleetTags;
      setticketObj(result);
      setTicketObjectLoading(false);
      seteditflag(editflag ?? false);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error?.message || error.error?.message,
      });
    }
  };

  const getassigned = async () => {
    let res: any[] = [];
    const taskviewData: Taskviewdetails = await getTasksearch(
      userDetails.organization.api_url,
      '',
    );
    taskviewData.records.map((ele: Taskview) => {
      ele.fullname = ele.first_name + ' ' + ele.last_name;
    });
    res = taskviewData.records;
    setResult(res);
  };

  const editTicketDetails = () => {
    if (!TickObj?.equipment_id) {
      setSelectedEquipment('Other');
    } else if (TickObj?.equipment) {
      setSelectedEquipment(TickObj?.equipment?.name);
    }
    if (tagsList && TickObj?.Tags) {
      for (const iterator of tagsList) {
        for (const iterator2 of TickObj?.Tags) {
          if (iterator.name === iterator2.data_value) {
            iterator.selected = true;
          }
        }
      }
    }

    setSelectedTags(tagsList);
    setDescription(TickObj?.ticket_description);
    setStateVarible(TickObj?.ticket_level);
    seteditflag(true);
    mixpanel.track('EditTicket', {
      event: 'Ticket Edit',
    });
  };

  const loadTags = async () => {
    const resultTags: any = await getOrgTags(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );
    resultTags.map((tag: any) => {
      tag.selected = false;
    });
    setTagsList(resultTags);
  };

  const loadEquipments = async () => {
    let result: any = await getOrgEquipments(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );

    result = result.filter(
      (equipment: any) => equipment.equipment_type !== 'None',
    );
    setSelectedEquipment(result[0].name);
    setuuid(1);
    result = result.map((equip: any, i: any) => {
      if (ticketObj?.equipment?.name === equip.name) {
        setuuid(i + 1);
      }
      const { name } = equip;
      equip.uuid = i + 1;
      equip.name = !speCharRegX?.test(name) ? encodeURIComponent(name) : name;
      return equip;
    });

    setEquipmentsList(result);
  };

  const UpdateTicketStatus = async (status: any) => {
    const obj: any = {
      status: status,
    };
    if (descriptionResolve) {
      obj.reason_message = descriptionResolve.trim();
    }

    try {
      setLoader(true);
      const response: { msg: string } = await UpdateStatus(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        ticketObj.id,
        obj,
      );
      setstatusmodal(false);
      if (status === 'completed') {
        SetStatusLable('Resolved');
      } else {
        SetStatusLable('Unresolved');
      }
      setDescriptionResolve('');
      notification.success({
        message: t(translate.label.ticketStatus),
        duration: 2,
      });
      afterSave();
      if (editflag) {
        Close();
      } else {
        getbyticketbyid();
      }
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
    }
  };

  const updateTicketDetails = async () => {
    if (description === '') {
      notification.error({
        message: `Ticket description is required and can't be blank.`,
        duration: 2,
      });
      return;
    }

    const obj: any = {};
    const selTags: any[] = [];
    let equipmentType = '';
    let equipment_id: any = 0;

    tagsList.map((tag: any) => {
      if (tag.selected) {
        selTags.push(tag.name);
      }
    });
    equipmentsList.map((equip: any) => {
      if (equip.uuid == uuidnum) {
        equipmentType = equip.equipment_type?.trim();
        equipment_id = equip.equipment_id;
      }
    });
    (obj.ticket_description = description), (obj.ticket_level = stateVarible);
    if (equipment_id !== 'NULL') {
      obj.tractor_id = equipment_id;
    }
    (obj.assignee_id = assignid),
      (obj.tags = selTags),
      (obj.equipment_id = equipment_id),
      (obj.equipment_type = equipmentType),
      (obj.id = TickObj?.id),
      (obj.ticket_id = TickObj?.ticket_id),
      (obj.status = stauslable === 'Resolved' ? 'completed' : 'open');

    try {
      setLoader(true);
      const response: { msg: string } = await updateTicket(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        ticketObj.id,
        obj,
      );
      mixpanel.track('UpdateTicket', {
        event: 'Ticket Updated Sucessfully',
      });
      notification.success({
        message: t(translate.label.ticketUpdated),
        duration: 2,
      });
      if (editflag) {
        Close(), afterSave();
      }
    } catch (err: any) {
      mixpanel.track('UpdateTicketFailed', {
        event: 'Ticket Update Failed',
      });
      notification.error({
        message: err.response?.data.error.message || err.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
    }
  };
  const equipmentSelect = (e: any) => {
    const raw = JSON.parse(e);
    equipmentsList.map((ele: any) => {
      if (ele.uuid === raw.uuid) {
        setuuid(ele.uuid);
        setSelectedEquipment(ele.name);
      }
    });
    mixpanel.track('TicketViewEdit', {
      event: `Select Equipment ${e}`,
    });
  };

  const getSeverityIcon = (level: number, orderNumber: number) => {
    if (level >= 3) {
      return FlagRedIcon;
    }
    if (orderNumber <= level) {
      return FlagOrangeIcon;
    }
    return FlagGrayIcon;
  };

  const handleChange = (tag: any, checked: any) => {
    tag.selected = checked;
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t: any) => t !== tag);
    setSelectedTags(nextSelectedTags);
    if (checked) {
      mixpanel.track('TicketViewEdit', {
        event: 'Select Tags',
      });
    } else {
      mixpanel.track('TicketViewEdit', {
        event: 'Remove Tags',
      });
    }
  };

  const addcomment = () => {
    setCommentData('');
    setComment(true);
    mixpanel.track('AddedTicketComment', {
      event: 'Ticket Added Comment',
    });
  };

  const SaveComment = async () => {
    if (commentData && commentData.trim()) {
      const obj = {
        data_key: 'COMMENTS',
        data_text: commentData.trim(),
        data_values: commentData.trim(),
      };
      try {
        setLoader(true);
        const response: { msg: string } = await addComment(
          userDetails.organization.api_url,
          TickObj.fleet_id,
          TickObj.id,
          obj,
        );
        notification.success({
          message: t(translate.label.ticketCommentAdded),
          duration: 2,
        });
        setComment(false);
        getComments();
        mixpanel.track('TicketComment', {
          event: 'Ticket Comment Sucessfully',
        });
      } catch (err: any) {
        mixpanel.track('TicketCommentFailed', {
          event: 'Ticket Comment Failed',
        });
        notification.error({
          message: err.response?.data.error.message,
          duration: 2,
        });
      } finally {
        setLoader(false);
      }
    } else {
      notification.error({
        message: 'Added Comments',
        duration: 2,
      });
    }
  };

  const onSelect = (e: any) => {
    setAssignid(e);
    mixpanel.track('TicketViewEdit', {
      event: `Select Assign ${e}`,
    });
  };

  const SaveAttach = async (file: any) => {
    const formData = new FormData();
    formData.append('image', file.originFileObj);
    try {
      const res = await ticketaddattachment(
        userDetails.organization.api_url,
        TickObj?.fleet_id,
        TickObj?.id,
        formData,
      );
      notification.success({ message: res.msg, duration: 2 });
      mixpanel.track('TicketViewEdit', {
        event: 'Ticket Attachment Added Sucessfully',
      });
      getbyticketbyid();
    } catch (e: any) {
      mixpanel.track('TicketViewEdit', {
        event: 'Ticket Attachment Failed',
      });
    }
  };

  const StatusonChange = () => {
    const status = stauslable === 'Unresolved' ? true : false;
    if (status) {
      UpdateTicketStatus('completed');
    } else {
      UpdateTicketStatus('open');
    }
  };

  const Closebtn = () => {
    Close();

    mixpanel.track('CloseTicket', {
      event: 'Ticket Close',
    });
  };
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const onPreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
    );
    setCurrentIndex(file.index);
  };

  useEffect(() => {
    seteditflagBody(editflag);
  }, [editflag]);

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const forwaredTicket = async () => {
    seteditflagBody(true);
    setForwardTicketLoader(true);
    setforwardTicketModel(false);
    try {
      await delay(2000);
      setForwardTicketLoader(false);
      setTicketLoader(true);
      await forwaredTicketDetails(
        userDetails.organization.api_url,
        TickObj?.fleet_id,
        TickObj?.id,
      );

      delay(2000).then(function () {
        setTicketLoader(false);
        seteditflagBody(false);
      });
      getbyticketbyid();
    } catch (e: any) {
      notification.error({
        message: e.response?.data.error.message,
        duration: 2,
      });
    }
  };

  const handleVideos = () => {
    const { date_time: videoTime, tractorId, tractor_id } = ticketObj;
    dispatch({
      type: TOGGLE_VIDEO_WIDGET,
      payload: {
        toggle: !state.videoWidgetData.toggle,
        videoTime: videoTime,
        tractorId: tractorId || tractor_id,
      },
    });
  };

  const beforeUpload = (file: any) => {
    const imgSize = file.size / 1024 / 1024 < 8;
    const fileTypeAccepted =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg';
    if (!fileTypeAccepted) {
      notification.error({
        message: `${file.type} is not accepted`,
      });
      return Upload.LIST_IGNORE;
    }
    if (!imgSize) {
      notification.error({
        message: 'file size must be smaller than 8MB!',
      });
    }
    return imgSize || Upload.LIST_IGNORE;
  };

  const forwaredTicketModal = () => {
    setforwardTicketModel(true);
  };
  const StatusonChangeModal = () => {
    setstatusmodal(true);
  };

  const forwardCancel = () => {
    setforwardTicketModel(false);
  };
  const resolveCancel = () => {
    setDescriptionResolve('');
    setstatusmodal(false);
  };

  const errorCodesLink = () => {
    setErrorcodeFlag(true);
  };
  const errorcodeCancel = () => {
    setErrorcodeFlag(false);
  };
  const columns = [
    {
      title: 'Severity',
      dataIndex: 'level',
      key: 'level',
      width: '110px',
      render: (level: any) => {
        return (
          <div className="description">
            <img className="mr11" src={getSeverityIcon(level, 1)} />
            <img className="mr11" src={getSeverityIcon(level, 2)} />
            <img className="mr11" src={getSeverityIcon(level, 3)} />
          </div>
        );
      },
    },
    {
      title: 'Error Code',
      dataIndex: 'indicator_error_code',
      key: 'indicator_error_code',
      width: '120px',
      render: (indicator_error_code: any) => <>{indicator_error_code}</>,
    },
    {
      title: 'Indicator Types',
      width: '200px',
      dataIndex: 'indicator',
      key: 'indicator',
    },
    {
      title: 'Issues',
      width: '160px',
      dataIndex: 'subsystem',
      key: 'subsystem',
      render: (subsystem: string) => {
        return <span className="description">{subsystem}</span>;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '200px',
      render: (description: string) => {
        return (
          <div className="description">
            <div className="diagnosticsdesc">{description}</div>
          </div>
        );
      },
    },
  ];

  const ticketCancel = () => {
    seteditflag(false);
  };

  const leftarrowClick = async () => {
    const leftindex = currentIndex + 1;

    setCurrentIndex(leftindex);
    setPreviewTitle(
      FleetData[leftindex].name ||
        FleetData[leftindex].url!.substring(
          FleetData[leftindex].url!.lastIndexOf('/') + 1,
        ),
    );
    setPreviewImage(FleetData[leftindex].url);
  };

  const rightarrowClick = async () => {
    const rightindex = currentIndex - 1;

    setCurrentIndex(rightindex);
    setPreviewTitle(
      FleetData[rightindex].name ||
        FleetData[rightindex].url!.substring(
          FleetData[rightindex].url!.lastIndexOf('/') + 1,
        ),
    );
    setPreviewImage(FleetData[rightindex].url);
  };

  const handleCancel = () => setPreviewOpen(false);

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn flexBtn">
          {!editflag && (
            <img
              id={applicationIds.ticketScreen.editClose}
              src={closeGreen}
              alt="close icon"
              onClick={Closebtn}
              data-testid="modelCloseIcon-TicketViewEdit"
            />
          )}

          {privilegeChecker(privilagesConstants.Edit_a_fleet_ticket) &&
            !TickObj?.is_service_ticket_raised &&
            ((!editflag && ticketObj?.assignee_id === userDetails?.id) ||
              (!editflag && ticketObj?.created_by === userDetails?.id)) && (
              <span
                className="editTxtBtn"
                onClick={() => {
                  editTicketDetails();
                }}
              >
                Edit
              </span>
            )}
        </div>
        <div className="profileView rightWidgetView">
          <div className="proRow tcktInfo">
            <h4 className="posRel errorTcktTitle headline1">
              {editflag && <span>Edit</span>}
              <span style={{ fontFamily: 'Montserrat-SemiBold' }}>
                Ticket #{TickObj?.ticket_id}
              </span>
            </h4>
            <h3 className="headline2">
              {getDateTimes(TickObj?.created_date_time)}
            </h3>
          </div>
          {!editflag && (
            <>
              {!editflagBody && (
                <>
                  <div className="proRowType rowGapBtm3">
                    <h5 className="subFlex alignCenter">
                      <span className="fSbd overline1 subTitles">Status</span>
                      <span className="width50">
                        {TickObj?.status !== 'open' ? (
                          <span className="status_value status_value_resolved body3">
                            RESOLVED
                          </span>
                        ) : (
                          <span className="status_value body3">UNRESOLVED</span>
                        )}
                      </span>
                    </h5>
                  </div>
                  <div className="proRowType rowGapBtm3">
                    <h5 className="subFlex alignCenter">
                      <>
                        <span className="fSbd overline1 subTitles">
                          Severity Level
                        </span>
                        <span className="width50 body1">
                          <img
                            className="mr11"
                            src={getSeverityIcon(TickObj?.ticket_level, 1)}
                          />
                          <img
                            className="mr11"
                            src={getSeverityIcon(TickObj?.ticket_level, 2)}
                          />
                          <img
                            className="mr11"
                            src={getSeverityIcon(TickObj?.ticket_level, 3)}
                          />
                          {TickObj?.ticket_level === 1 && <>Low</>}
                          {TickObj?.ticket_level === 2 && <>Medium</>}
                          {TickObj?.ticket_level === 3 && <>High</>}
                        </span>
                      </>
                    </h5>
                  </div>
                  {TickObj?.equipment ? (
                    <div className="proRowType rowGapBtm3">
                      <h5 className="subFlex alignCenter">
                        <span className="fSbd overline1 subTitles">
                          Equipment
                        </span>
                        <span className="width50 body1">
                          <label>{TickObj?.equipment?.name}</label>
                        </span>
                      </h5>
                    </div>
                  ) : (
                    <div className="proRowType rowGapBtm3">
                      <h5 className="subFlex alignCenter">
                        <span className="fSbd overline1 subTitles">
                          Equipment
                        </span>
                        <span className="width50 body1">
                          <label>Other</label>
                        </span>
                      </h5>
                    </div>
                  )}
                  {TickObj?.created_by_user && (
                    <div className="proRowType rowGapBtm3">
                      <h5 className="subFlex alignCenter">
                        <span className="fSbd overline1 subTitles">
                          Creator
                        </span>
                        <span className="width50 body1 subdesc">
                          {TickObj?.created_by_user ? (
                            <label>{TickObj?.created_by_user}</label>
                          ) : (
                            <label></label>
                          )}
                        </span>
                      </h5>
                    </div>
                  )}
                  {TickObj?.assignee && (
                    <div className="proRowType rowGapBtm3">
                      <h5 className="subFlex alignCenter">
                        <span className="fSbd overline1 subTitles">
                          Assigned To
                        </span>
                        <span className="width50 body1 subdesc">
                          {TickObj?.is_service_ticket_raised ? (
                            <label className="status_value status_value_forward">
                              <img src={Assigned_to}></img> Monarch
                            </label>
                          ) : TickObj?.assignee ? (
                            <label>{TickObj?.assignee}</label>
                          ) : (
                            <label></label>
                          )}
                        </span>
                      </h5>
                    </div>
                  )}
                  {TickObj?.ticket_description && (
                    <div className="proRowType rowGapBtm3">
                      <h5 className="fSbd overline1">Description</h5>
                      <div className="txtLftGap w100 body1">
                        <Interweave content={TickObj?.ticket_description} />
                      </div>
                    </div>
                  )}
                  {TickObj?.tags && TickObj?.tags.length > 0 && (
                    <div className="rowGapBtm3 tagsSec subFlex alignBaseline">
                      <h4 className="mr11 fSbd overline1">Tags</h4>
                      <div className="tagsBlk">
                        <div className="tags-container">
                          {TickObj?.tags?.map((data: any) => {
                            return (
                              <div
                                className="tag-chip-selected body3"
                                key={data}
                              >
                                <span>{data}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="attachemtBlk rowGapBtm1">
                    <h4 className="fSbd overline1 mb16">
                      Attachments({TickObj?.Attachments?.length})
                    </h4>
                    <div className="videoImgStyle">
                      {ticketObj?.equipment_id !== 0 &&
                        vedioinfo !== '' &&
                        vedioinfo && (
                          <video
                            src={vedioinfo}
                            className="vedioinfo"
                            onClick={handleVideos}
                          />
                        )}
                      {TickObj &&
                        TickObj?.Attachments &&
                        TickObj?.Attachments?.map((ele: any) => {
                          return (
                            <Image
                              src={ele?.image_url}
                              style={{ marginRight: 10, width: 66, height: 70 }}
                            />
                          );
                        })}
                    </div>
                  </div>
                  {errCode && errCode.length > 0 && (
                    <div className="errorCodesBlk mb32 errorCodesList">
                      <h4 className="fSbd overline1">
                        Error Codes
                        <img
                          src={errorCodeIcon}
                          className="errorCodeIcon"
                          onClick={errorCodesLink}
                        />
                      </h4>

                      <table className="table proRoleValue">
                        {errCode &&
                          errCode.map((x: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td>-</td>
                                <td>Error: {x?.indicator_error_code}</td>
                                <td>
                                  <img
                                    className="mr8"
                                    src={getSeverityIcon(x?.level, 1)}
                                  />
                                  <img
                                    className="mr8"
                                    src={getSeverityIcon(x?.level, 2)}
                                  />
                                  <img src={getSeverityIcon(x?.level, 3)} />
                                </td>
                              </tr>
                            );
                          })}
                      </table>
                    </div>
                  )}
                  <div className="proRowType mb32">
                    <h5 className="fSbd overline1">Comments</h5>
                    <h6 className="createTcktBtn mt16 ticketComments">
                      <div>
                        {commentflag && (
                          <>
                            <div className="tcktCommentBox">
                              <div
                                className="txtareaAvatar"
                                onClick={() => setComment(false)}
                              >
                                <Avatar
                                  style={{
                                    width: '43px',
                                    height: '43px',
                                    backgroundColor: '#67AFB7',
                                    color: 'white',
                                    fontFamily: 'Montserrat-Bold',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                  src={userDetails?.profile_url}
                                >
                                  {userDetails?.first_name?.charAt(0)}
                                  {userDetails?.last_name?.charAt(0)}
                                </Avatar>
                              </div>
                              <TextArea
                                data-testid="CommentInputField-TicketViewEdit"
                                style={{ width: '100%' }}
                                placeholder="Enter Comments here"
                                className="tckComt"
                                maxLength={500}
                                onChange={(e: any) =>
                                  setCommentData(e.target.value)
                                }
                              />
                              <CloseOutlined
                                onClick={() => {
                                  setComment(false);
                                  setCommentData('');
                                }}
                              />
                              <div className="commentsCounts">
                                <span
                                  className="descCount"
                                  style={{
                                    display:
                                      commentData !== undefined &&
                                      commentData.length <= 500
                                        ? ''
                                        : 'none',
                                    float: 'right',
                                  }}
                                >
                                  {commentData !== undefined &&
                                    commentData.length}{' '}
                                  <> / 500</>
                                </span>
                                {commentData && commentData.trim() && (
                                  <span
                                    id={applicationIds.ticketScreen.saveComment}
                                    onClick={SaveComment}
                                    className="commentSaveBtn"
                                    data-testid="saveButton-TicketViewEdit"
                                  >
                                    Save
                                  </span>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      {!commentflag && (
                        <div
                          id={applicationIds.ticketScreen.addComment}
                          onClick={addcomment}
                          className="commentBtnBlk"
                        >
                          <span className="addCBtn addTcktCBtn">
                            <img
                              src={addCBtn}
                              data-testid="addCommentIcon-TicketViewEdit"
                            />
                          </span>
                          <span className="addCommentBtn">ADD A COMMENT</span>
                        </div>
                      )}
                      {!ticketObjectLoaing && (
                        <div>
                          {CommentsData &&
                            CommentsData.map((ele: any) => {
                              return (
                                <div className="commentsBlk">
                                  {ele?.data_text && (
                                    <>
                                      <div className="commentsAvatar">
                                        <div className="commentsIcon">
                                          <Avatar
                                            style={
                                              ele?.is_service_ticket_raised ===
                                              true
                                                ? {
                                                    width: '42px',
                                                    height: '24px',
                                                    color: 'white',
                                                    fontFamily:
                                                      'Montserrat-Bold',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                  }
                                                : {
                                                    width: '43px',
                                                    height: '43px',
                                                    color: 'white',
                                                    fontFamily:
                                                      'Montserrat-Bold',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: '#67afb7',
                                                    border: '1px solid #67afb7',
                                                  }
                                            }
                                            src={
                                              ele?.is_service_ticket_raised ===
                                              true
                                                ? Assigned_to
                                                : ele.created_by_user
                                                    ?.profile_url
                                            }
                                          >
                                            {ele.created_by_user?.first_name?.charAt(
                                              0,
                                            )}
                                            {ele.created_by_user?.last_name?.charAt(
                                              0,
                                            )}
                                          </Avatar>
                                        </div>
                                      </div>
                                      <div className="commentsContent">
                                        <h5
                                          data-testid={`${ele.data_text}-TicketViewEdit`}
                                        >
                                          {ele.data_text}
                                        </h5>
                                        <h6>
                                          {getDateTimes(ele?.created_date_time)}
                                        </h6>
                                      </div>
                                    </>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      )}
                      <AppLoader loader={ticketObjectLoaing} />
                    </h6>
                    <div className="proeditBtn tcktSaveBtn widgetBtns">
                      {!ticketObjectLoaing && (
                        <div className="fwdBtnsSec">
                          {privilegeChecker(
                            privilagesConstants.Forward_a_ticket_to_Monarch,
                          ) &&
                            stauslable === 'Unresolved' &&
                            TickObj &&
                            !TickObj.is_service_ticket_raised && (
                              <button
                                type="button"
                                className="TktFwdBtn Button"
                                id={applicationIds.ticketScreen.forwardticket}
                                onClick={forwaredTicketModal}
                                data-testid="forwardButton-TicketViewEdit"
                              >
                                FORWARD
                              </button>
                            )}
                          {TickObj &&
                            stauslable === 'Unresolved' &&
                            privilegeChecker(
                              privilagesConstants.Resolve_a_ticket_not_assigned_to_the_individual,
                            ) && (
                              <div>
                                <button
                                  type="button"
                                  className="TktRslBtn Button"
                                  onClick={StatusonChangeModal}
                                  data-testid="resolveTicketSubmitButton-TicketViewEdit"
                                >
                                  RESOLVE TICKET
                                </button>
                              </div>
                            )}
                        </div>
                      )}
                      {!ticketObjectLoaing && stauslable === 'Resolved' && (
                        <div className="fwdBtnsSecResolve">
                          {TickObj &&
                            privilegeChecker(
                              privilagesConstants.Resolve_a_ticket_not_assigned_to_the_individual,
                            ) && (
                              <div>
                                <button
                                  type="button"
                                  className="TktRslBtn Button"
                                  onClick={StatusonChangeModal}
                                  data-testid="unResolveTicketButton-TicketViewEdit"
                                >
                                  UNRESOLVE TICKET
                                </button>
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              <>
                {forwardTicketLoader && (
                  <div className="fwdTicketProgressSec">
                    <div className="fwdTicketMKV">
                      Forwarding Ticket to Monarch
                    </div>
                    <div className="proRoleValue mt14 subdesc w100 frwdTcktProgrsssDesc">
                      A Monarch support representative will get back to you
                      shortly
                    </div>
                    <img src={ticketFwd} className="frwdTcktLoadingImg" />
                  </div>
                )}
                {ticketLoader && (
                  <div className="fwdTicketProgressSec">
                    <div
                      className="fwdTicketMKV"
                      data-testid="ticketForwardedSuccessMsg-TicketViewEdit"
                    >
                      Ticket Forwarded Succesfully!
                    </div>
                    <div className="proRoleValue mt14 subdesc w100 frwdTcktProgrsssDesc">
                      A Monarch support representative will get back to you
                      shortly
                    </div>
                    <img src={ticketSucess} />
                  </div>
                )}
              </>
            </>
          )}

          {editflag && (
            <>
              <div className="proRowType rowGapBtm3">
                <h5 className="subFlex">
                  <span className="fSbd overline1 subTitles">Status</span>
                  <span className="width50">
                    {TickObj?.status !== 'open' ? (
                      <span className="status_value status_value_resolved body3">
                        Resolved
                      </span>
                    ) : (
                      <span className="status_value body3">Unresolved</span>
                    )}
                  </span>
                </h5>
              </div>
              <div className="proRowType rowGapBtm3">
                <h5 className="subFlex alignCenter">
                  <span className="fSbd overline1 subTitles">
                    Severity Level
                  </span>
                  <span className="width50 body1 createTcktFlag">
                    <div className="subFlex">
                      {Array.from({ length: 3 }).map((item, key) => {
                        return (
                          <div
                            key={key}
                            onClick={() => {
                              stateVarible === 1 && key === 0
                                ? setStateVarible(1)
                                : setStateVarible(key + 1);
                              mixpanel.track('TicketViewEdit', {
                                event: `Select Severity ${key + 1}`,
                              });
                            }}
                            data-testid="severityLevelUpdate-TicketViewEdit"
                          >
                            {stateVarible === 0 && (
                              <img
                                className={key !== 0 ? 'ml24' : ''}
                                src={FlagGrayIcon}
                              />
                            )}
                            {stateVarible === 1 && (
                              <img
                                className={key !== 0 ? 'ml24' : ''}
                                src={key === 0 ? FlagOrangeIcon : FlagGrayIcon}
                              />
                            )}
                            {stateVarible === 2 && (
                              <img
                                className={key !== 0 ? 'ml24' : ''}
                                src={key !== 2 ? FlagOrangeIcon : FlagGrayIcon}
                              />
                            )}
                            {stateVarible === 3 && (
                              <img
                                className={key !== 0 ? 'ml24' : ''}
                                src={FlagRedIcon}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </span>
                </h5>
              </div>
              <div className="rowGapBtm3">
                <Select
                  style={{ width: '100%' }}
                  onSelect={equipmentSelect}
                  placeholder="Select Equipment"
                  data-testid="tractorName-TicketViewEdit"
                  value={selectedEquipment}
                >
                  {equipmentsList?.map((data: any, i: number) => {
                    return (
                      <Option value={JSON.stringify(data)} key={i}>
                        {data.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>

              {result.length > 0 && (
                <div className="rowGapBtm3">
                  <Select
                    showSearch={true}
                    style={{ width: '100%' }}
                    defaultValue={assignid}
                    onChange={onSelect}
                    filterOption={(input, option) => {
                      return (
                        option!.title
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {result.map((ele: any) => (
                      <Option
                        title={ele.fullname}
                        key={ele.id}
                        value={ele.id}
                        data-testid={`${ele.fullname}-TicketViewEdit`}
                      >
                        {ele.fullname}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
              <>
                <div className="rowGapBtm3">
                  <h4 className="fSbd overline1">Description</h4>

                  <TextArea
                    className={`ticketArea ${
                      descriptionCount >= 1 && descriptionCount < 3
                        ? 'newTicket'
                        : ''
                    }`}
                    data-testid="updateDescriptionInputField-TicketViewEdit"
                    style={{ width: '100%' }}
                    placeholder="Enter description here"
                    autoSize={{ minRows: 5, maxRows: 8 }}
                    defaultValue={description}
                    maxLength={500}
                    onChange={(e: any) => setDescription(e.target.value)}
                  />
                  <span
                    style={{
                      display: showDescriptionCount ? 'block' : 'none',
                      color:
                        descriptionCount >= 1 && descriptionCount < 3
                          ? '#ff0000d1'
                          : '',
                      float: 'right',
                    }}
                    className="descCount"
                  >
                    {descriptionCount} <> / 500</>
                  </span>
                </div>
                <div className="rowGapBtm3 tagsSec">
                  <h4 className="fSbd overline1 mr11">Tags</h4>
                  <div className="tagsBlk mt14">
                    {tagsList?.map((data: any) => {
                      return (
                        <CheckableTag
                          data-testid={`${data.name}-TicketViewEdit`}
                          className="checktag body3"
                          key={data.id}
                          checked={data.selected}
                          onChange={(checked: any) =>
                            handleChange(data, checked)
                          }
                        >
                          {data.name}
                        </CheckableTag>
                      );
                    })}
                  </div>
                </div>

                <div className="attachemtBlk editAttachementBlk rowGapBtm3 attachIcons">
                  <h4 className="fSbd overline1">
                    Attachments({FleetData.length}){' '}
                  </h4>
                  <Upload
                    disabled={FleetData.length >= 5}
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    fileList={FleetData}
                    onChange={onChange}
                    onPreview={onPreview}
                    beforeUpload={beforeUpload}
                    accept=".jpg,.png"
                    maxCount={5}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  >
                    <PlusOutlined />
                  </Upload>
                </div>
              </>

              <div className="edit-loader">
                {loader && (
                  <div className="loader">
                    <Spin size="large" />
                  </div>
                )}
              </div>
              <div className="proeditBtn tcktSaveBtn editTcktBtns">
                <div className="fwdBtnsSec" onClick={ticketCancel}>
                  <button className="TktFwdBtn Button"> Cancel</button>
                </div>
                <div
                  className="fwdBtnsSec"
                  data-testid="TicketUpdateSaveButton-TicketViewEdit"
                  id={applicationIds.ticketScreen.editSave}
                  onClick={updateTicketDetails}
                >
                  <button disabled={isDisabled} className="TktRslBtn Button">
                    Save
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {forwardticketmodal && (
        <Modal
          visible={true}
          onCancel={forwardCancel}
          width={1200}
          bodyStyle={{ height: 'auto' }}
          footer={false}
          className="opPopup forwardTicketModal"
        >
          <img src={verM_logo} alt="" className="modal-logo" />
          <h3 data-testid="forwardPopUpModel-TicketViewEdit">
            Forward to Monarch?
          </h3>
          <p>Are you sure you want to forward this ticket to Monarch?</p>
          <p>This process cannot be undone.</p>
          <div className="forwardTicketButtons">
            <div
              className="fwdTcktCancelBtn"
              data-testid="fwdTcktCancelButton-TicketViewEdit"
              onClick={forwardCancel}
            >
              Cancel
            </div>
            <div
              className="fwdTcktForwardBtn"
              data-testid="fwdTcktForwardButton-TicketViewEdit"
              onClick={forwaredTicket}
            >
              Forward
            </div>
          </div>
        </Modal>
      )}

      {statusmodal && (
        <Modal
          visible={true}
          onCancel={resolveCancel}
          width={1200}
          bodyStyle={{ height: 'auto' }}
          footer={false}
          className="opPopup statusModal"
        >
          <h3>Are you sure?</h3>
          <p>
            Are you sure you want to{' '}
            {stauslable === 'Resolved' ? 'unresolve' : 'resolve'} this ticket?
          </p>
          <p>This process cannot be undone.</p>
          <div className="mt24">
            <p className="descLabel">
              Enter a description for{' '}
              {stauslable === 'Resolved' ? 'unresolve' : 'resolve'} the ticket
            </p>
            <TextArea
              data-testid="resolveTcktDescInputField-TicketViewEdit"
              autoSize={{ minRows: 5, maxRows: 8 }}
              placeholder="Enter description here"
              maxLength={500}
              onChange={(e) => setDescriptionResolve(e.target.value)}
            />
            <span
              style={{
                color:
                  descriptionResolve >= 1 && descriptionResolve < 3
                    ? '#ff0000d1'
                    : '',
                float: 'right',
              }}
              className="descCount"
            >
              {descriptionResolve?.length === undefined ||
              descriptionResolve?.length === null ||
              descriptionResolve?.length === ''
                ? 0
                : descriptionResolve?.length}
              / 500
            </span>
          </div>
          <div className="forwardTicketButtons">
            <div
              className="fwdTcktCancelBtn"
              data-testid="resolveTcktCancelButton-TicketViewEdit"
              onClick={resolveCancel}
            >
              Cancel
            </div>
            <div
              className="fwdTcktForwardBtn"
              data-testid="resolveTcktResolvedButton-TicketViewEdit"
              onClick={StatusonChange}
            >
              <Button disabled={isresolvedDisabled}>
                {stauslable === 'Resolved' ? 'UnResolve' : 'Resolve'}
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {errorcodeFlag && (
        <Modal
          title="Error Codes"
          visible={true}
          onCancel={errorcodeCancel}
          width={1200}
          maskClosable={false}
          bodyStyle={{ height: 'auto' }}
          footer={false}
          className="opPopup errorCodesModal"
        >
          <div className="tblDft farmTabsTbl hoverable dsTbl errorCodesTbl">
            <Table
              sticky={true}
              dataSource={errCode}
              columns={columns}
              pagination={false}
            />
          </div>
        </Modal>
      )}

      <Modal
        visible={previewOpen}
        title={previewTitle}
        footer={null}
        maskClosable={false}
        onCancel={handleCancel}
        className="previewModal noTitle"
      >
        <div className="previewmodalBody">
          {FleetData.length - 1 !== currentIndex && (
            <ArrowLeftOutlined onClick={leftarrowClick} />
          )}
          {previewImage && (
            <img alt="example" className="img-responsive" src={previewImage} />
          )}
          {currentIndex !== 0 && (
            <ArrowRightOutlined onClick={rightarrowClick} />
          )}
        </div>
      </Modal>
    </Layout>
  );
};

export default TicketViewEdit;
