/* eslint-disable no-console */
import { Collapse, Input, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import hitch_implement_S from '../../assets/images/hitch_implement_S.svg';
import { getRoute } from '../../constants/Api';
import {
  getFullDate,
  getImplementName,
  LS_ROUTINE,
  secondsToHms,
} from '../../constants/Common';
import { ApplicationContext } from '../../context/AppContext';

import translate from '../../locale/en_translate.json';
import AppLoader from '../common/AppLoader';
import {
  SELECTED_ITEM_NAME,
  SELECTED_ROUTINE,
} from '../remote_drive_new/actions';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import { SimpleMap } from './SimpleMap';

import refresh from './../../assets/images/refresh.svg';
import refreshD from './../../assets/images/refreshD.svg';
import refreshUp from './../../assets/images/refUp.svg';
import sortA from './../../assets/images/sortA.svg';
import sortAD from './../../assets/images/sortAD.svg';
import sortUP from './../../assets/images/sortUP.svg';

const { Panel } = Collapse;
const { Search } = Input;
interface Props {
  showModal: boolean;
  handleCancel: (routine?: any) => void;
  tractorName: string;
}
export const RoutineNewDesign: React.FC<Props> = ({
  showModal,
  handleCancel,
  tractorName,
}) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [routineList, setRoutineList] = useState<any>([]);
  const [search, setSearch] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | string[]>([]);
  const [sortColumn, setSortColumn] = useState<string>('created_date_time');
  const [sortBy, setSortBy] = useState<string>('desc');

  const { RDReducer, addRVRoutineCommand } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const { selectedTractor } = state;

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getRoutines();
    }
  }, [userDetails, search, sortColumn, sortBy]);

  const getRoutines = async () => {
    try {
      setLoader(true);
      const report = await getRoute(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        search ? search : '',
        sortColumn,
        sortBy,
      );
      setRoutineList(report);
    } finally {
      setLoader(false);
    }
  };
  const handleRoutine = (routine: any) => {
    dispatch({ type: SELECTED_ROUTINE, payload: routine });
    handleCancel(routine);
    dispatch({
      type: SELECTED_ITEM_NAME,
      payload: routine.name,
    });
    localStorage.removeItem(LS_ROUTINE);
    localStorage.setItem(LS_ROUTINE, JSON.stringify(routine));
    addRVRoutineCommand();
  };

  const handleSearch = (e: any) => {
    const value = e.target.value.trim();
    if (value.length <= 2) {
      if (search != '') {
        setRoutineList(['']);
      }
      setSearch('');
    } else {
      setSearch(value);
    }
  };
  const handleSorting = (name: string) => {
    if (sortColumn !== name) {
      setSortColumn(name);
      setSortBy('asc');
    } else {
      setSortBy((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    }
  };

  return (
    <Modal
      visible={showModal}
      className="rPopup"
      onCancel={handleCancel}
      maskClosable={false}
    >
      <div className="RoutineContainer">
        <div className="rHeaderSec dblock">
          <div className="adrHeaderRow">
            <div className="adrHeaderTxt">Select Autodrive Routine</div>
            {/* <div className="adrHeaderCancel">CANCEL</div> */}
          </div>
          <div className="adrTractorSec">
            <div className="rTractorName headline1">{tractorName}</div>
          </div>
          <div className="adrTractorSec">
            <div className="rImageSec">
              <img src={hitch_implement_S} height="12" />
              <div className="adrImplementName">
                {getImplementName(selectedTractor)}
              </div>
            </div>
            <div className="rSearchSec">
              <div style={{ marginRight: 15 }}>
                <img
                  alt="sortAD"
                  src={
                    sortColumn !== 'name'
                      ? sortAD
                      : sortBy === 'asc'
                      ? sortUP
                      : sortA
                  }
                  height="25"
                  style={{
                    marginRight: 8,
                    backgroundColor: '#fff',
                    borderRadius: 6,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleSorting('name')}
                />
                <img
                  alt="dateTime"
                  src={
                    sortColumn !== 'created_date_time'
                      ? refreshD
                      : sortBy === 'asc'
                      ? refreshUp
                      : refresh
                  }
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 6,
                    cursor: 'pointer',
                  }}
                  height="25"
                  onClick={() => handleSorting('created_date_time')}
                />
              </div>
              <Search
                placeholder="Enter Seach"
                style={{ width: 200 }}
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>

          {/* <div className="rUserDtl">
            <div className="rUserLock">
              <img src={rLock} height="24" />
            </div>
            <div className="rTractorName">{tractorName}</div>
            {/* <div className="rUserLockSec">
             
            </div> 
            <div className="rUser">
              {userDetails?.first_name?.charAt(0) +
                userDetails?.last_name?.charAt(0)}
            </div>
            <div className="rUserLockSec">
              <div className="rUser">Basestation 1</div>
            </div>
          </div> */}
          {/* <div>
            <div className="rNames">ROUTINES</div>
          </div> */}
          {/* <div className="rSearchSec">
            <Search
              placeholder="Enter Seach"
              style={{ width: 200 }}
              onChange={(e) => handleSearch(e)}
            />
          </div> */}
        </div>

        <div className="rBodySec">
          <div className="rBodyWrapper">
            {routineList && routineList.length > 0 ? (
              routineList.map((routine: any, i: number) => (
                <>
                  <div className="adRoutinesBlock">
                    <div className="rRowItem" key={routine.id}>
                      <div className="rDateUser">
                        <div className="rrBy">
                          <span className="rduTxt">
                            {getFullDate(routine.created_date_time)}
                          </span>
                          <span className="rduTxt">
                            RECORDED BY {routine?.created_by?.first_name}{' '}
                            {routine?.created_by?.last_name}
                          </span>
                        </div>
                        <div className="adrouteName">
                          <div className="rNamesSec">{routine.name}</div>
                        </div>
                        <div className="adrImpleName">
                          <div className="adrImpName">
                            {routine?.implement_name}
                          </div>
                        </div>
                      </div>
                      <div className="adrDuration">
                        <div className="adrDvalue">
                          {secondsToHms(routine?.duration) || '-'}
                        </div>
                        <div className="adrDLabel">Duration</div>
                      </div>
                      {/* <div className="adrDuration">
                        <div className="adrDvalue">
                          {routine?.distance
                            ? routine?.distance + 'miles'
                            : '-'}
                        </div>
                        <div className="adrDLabel">Distance</div>
                      </div> */}
                      <div className="adrSelBtn">
                        <button
                          onClick={() => handleRoutine(routine)}
                          type="button"
                          className="adrSelectBtn"
                        >
                          SELECT
                        </button>
                      </div>
                      {/* <div className="rImpSec">
                          <span className="rImpName">
                            {routine.implement &&
                              routine.implement.length > 0 &&
                              routine.implement}
                          </span>
                        </div> */}
                      {/* <div className="rImageSec"><img src={rImage} /></div> */}
                    </div>
                    <div className="seePathSec">
                      <Collapse
                        onChange={(e) =>
                          setSelectedId(
                            selectedId === routine.id ? '' : routine.id,
                          )
                        }
                        activeKey={selectedId}
                      >
                        <Panel header="SEE PATH" key={routine.id}>
                          <p>
                            {/* <img src={seePath} alt="" height="275" /> */}
                            <SimpleMap selectedRoutine={routine} />
                          </p>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>
                </>
              ))
            ) : (
              <>
                {!loader && (
                  <div className="routines-empty-block">
                    <div>{t(translate.label.noRoutines)}</div>
                  </div>
                )}
              </>
            )}
            <AppLoader loader={loader} />
          </div>
        </div>
      </div>
    </Modal>
  );
};
