import { Checkbox } from 'antd';
import React from 'react';

import { useMixpanel } from 'react-mixpanel-browser';

import datalayerIcon from '../../../assets/images/dataLayer.svg';
import dataLayer_te from '../../../assets/images/dataLayer_te.svg';
import dataLayer_tg from '../../../assets/images/dataLayer_tg.svg';
import mlArrow from '../../../assets/images/mlArrow.svg';
import path_ad from '../../../assets/images/path_ad.svg';
import path_md from '../../../assets/images/path_md.svg';
import path_preview from '../../../assets/images/preview_path.svg';

interface Props {
  rightSideToggle: boolean;
  setRightSideToggle: (rightSideToggle: boolean) => void;
  fleet: boolean;
  setFleet: (fleet: boolean) => void;
  showTractors: boolean;
  setShowTractors: (showTractors: boolean) => void;
  selectItems: (items: boolean) => void;
  showBasestations: boolean;
  setShowBasestations: (showBasestations: boolean) => void;
  farm: boolean;
  setFarm: (farm: boolean) => void;
  showWaypoints: boolean;
  setShowWaypoints: (showWaypoints: boolean) => void;
  showPath: boolean;
  setShowPath: (showPath: boolean) => void;
  showDataLayers: boolean;
  setShowDataLayers: (showDataLayers: boolean) => void;
  showWhitePolygons: boolean;
  setShowWhitePolygons: (showWhitePolygons: boolean) => void;
  showGrayPolygons: boolean;
  setShowGrayPolygons: (showGrayPolygons: boolean) => void;
  selectFarmItems: (items: boolean) => void;
  showGreenPolygons: boolean;
  setShowGreenPolygons: (showGreenPolygons: boolean) => void;
  showBluePolygons: boolean;
  setShowBluePolygons: (showBluePolygons: boolean) => void;
}
const FungicideSprayingMapLayers: React.FC<Props> = ({
  rightSideToggle,
  setRightSideToggle,
  fleet,
  setFleet,
  showTractors,
  setShowTractors,
  selectItems,
  showBasestations,
  setShowBasestations,
  farm,
  setFarm,
  showWaypoints,
  setShowWaypoints,
  showPath,
  setShowPath,
  showDataLayers,
  setShowDataLayers,
  showWhitePolygons,
  setShowWhitePolygons,
  showGrayPolygons,
  setShowGrayPolygons,
  selectFarmItems,
  showGreenPolygons,
  setShowGreenPolygons,
  showBluePolygons,
  setShowBluePolygons,
}) => {
  const mixpanel = useMixpanel();

  return (
    <div
      className={
        rightSideToggle ? 'rightSideDataLayers expand' : 'rightSideDataLayers'
      }
    >
      <div className="dataLayerWrapper">
        <div className="dataLayerRowHead mb28">
          <span
            onClick={() => {
              mixpanel.track('Live Map', {
                event: `Data Layers ${rightSideToggle ? 'Closed' : 'Opened'}`,
              });
              setRightSideToggle(!rightSideToggle);
            }}
            style={{ cursor: 'pointer' }}
          >
            <img src={mlArrow} alt="dataLayer" height="17" />
          </span>
          <span className="dataIcon">
            <img src={datalayerIcon} alt="dataLayer" height="18" />
          </span>
          <span className="dataHead">MAP LAYERS</span>
        </div>
        <div className="dataLayerSec">
          <div className="dataLayerContainer mb20">
            <div className="dataLayerRow">
              <div className="checkBoxItem">
                <span className="layerName">Data Layers</span>
                <span>
                  <Checkbox
                    defaultChecked={showDataLayers}
                    checked={showDataLayers}
                    // onChange={(e) => {
                    //   setShowDataLayers(e.target.checked);
                    // }}
                  />
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img
                    src={dataLayer_tg}
                    alt="tractor"
                    width="24"
                    height="18"
                  />
                </span>
                <span className="layerName">Triggered Guardrails</span>
                <span>
                  <Checkbox
                    defaultChecked={showDataLayers}
                    checked={showDataLayers}
                    // onChange={(e) => {
                    //   setShowDataLayers(e.target.checked);
                    // }}
                  />
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img
                    src={dataLayer_te}
                    alt="tractor"
                    width="24"
                    height="18"
                  />
                </span>
                <span className="layerName">Tractor Errors</span>
                <span>
                  <Checkbox
                    defaultChecked={showDataLayers}
                    checked={showDataLayers}
                    // onChange={(e) => {
                    //   setShowDataLayers(e.target.checked);
                    // }}
                  />
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img
                    src={dataLayer_te}
                    alt="tractor"
                    width="24"
                    height="18"
                  />
                </span>
                <span className="layerName">Fleet Tickets</span>
                <span>
                  <Checkbox
                    defaultChecked={showDataLayers}
                    checked={showDataLayers}
                    // onChange={(e) => {
                    //   setShowDataLayers(e.target.checked);
                    // }}
                  />
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img
                    src={dataLayer_te}
                    alt="tractor"
                    width="24"
                    height="18"
                  />
                </span>
                <span className="layerName">Emergency Stops</span>
                <span>
                  <Checkbox
                    defaultChecked={showDataLayers}
                    checked={showDataLayers}
                    // onChange={(e) => {
                    //   setShowDataLayers(e.target.checked);
                    // }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="dataLayerContainer mb20">
            <div className="dataLayerRow">
              <div className="checkBoxItem">
                <span className="layerName">Path Overlay</span>
              </div>
            </div>
            <div className="dataLayerInnerRow">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={path_md} alt="tractor" width="24" height="18" />
                </span>
                <span className="layerName">Manual</span>
              </div>
            </div>
            <div className="dataLayerInnerRow">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={path_ad} alt="tractor" width="24" height="18" />
                </span>
                <span className="layerName">Auto Drive</span>
              </div>
            </div>
            <div className="dataLayerInnerRow">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img
                    src={path_preview}
                    alt="tractor"
                    width="24"
                    height="18"
                  />
                </span>
                <span className="layerName">Planned Path</span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FungicideSprayingMapLayers;
