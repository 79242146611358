/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-console */

import React, { useContext, useEffect, useRef, useState } from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import { Avatar, Badge, Input, notification, Row, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  getEmployeesList,
  getEmployeePermissionDetails,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { EmployeesResponse } from '../../constants/types';
import translate from '../../locale/en_translate.json';
import 'antd/dist/antd.css';
import './style.css';
import copyIcon from '../../assets/images/copy_icon.png';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import { useMixpanel } from 'react-mixpanel-browser';
import AddButtonIcon from '../../assets/images/add_button_icon.svg';
import applicationIds from '../../locale/applicationIds.json';
import TeamsDashBoard from '../teamsAndPrivileges/TeamsDashBoard';
import AddEmployee from '../common/AddEmployee';
import ViewEmployee from '../common/ViewEmployee';
import EmployeeDetails from '../teamsAndPrivileges/EmployeeDetails';
import { TeamsContext } from '../../context/TeamContext';
import PermissionsDashBoard from '../teamsAndPrivileges/PermissionsDashBoard';
import { WarningOutlined } from '@ant-design/icons';
import { privilagesConstants } from '../../constants/Privilages';
import CSelect from '../common/CSelect';
import { activeFilterData } from '../../constants/AppData';
import { initScroller } from '../../constants/Common';

const { Search } = Input;

const Employees: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails, privilegeChecker } = useContext(ApplicationContext);
  const { TEAMSReducer } = useContext(TeamsContext);
  const [teamsState] = TEAMSReducer;
  const [filterData, setFilter] = useState<EmployeesResponse[]>([]);
  const [pageSize] = useState<any>(25);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [active, setActive] = useState<boolean>(true);
  const [toggleWidget, setToggleWidget] = useState(false);
  const [empId, setEmpId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [userProfile, setUserProfile] = useState<any>();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [editroleFlag, setEditRolesFlag] = useState<boolean>();
  const [enableEmployeeDetails, setEnableEmployeeDetails] =
    useState<boolean>(false);
  const [refresh, setRefresh] = useState(false);
  const [orgFlag, setOrgFlag] = useState(false);
  const [totalcount, settotalcount] = useState<any>();
  const [teamids, setTeamids] = useState<any>();
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'first_name',
    order: 'ascend',
  });
  const [sortedData, setSortedData] = useState<any>({
    first_name: 'ascend',
  });
  const [AddEmployeeFlag, setAddEmployeeFlag] = useState<boolean>(false);
  const [permissionFlag, setPermission] = useState<boolean>(false);
  const mixpanel = useMixpanel();
  const [initialize, setInitialize] = useState<boolean>(false);
  const [keyCode, setKeyCode] = useState<boolean>(false);
  const activity = useRef<string>('');
  const searchString = useRef<string>('');
  const [loaderview, setLoaderview] = useState<boolean>(false);

  const checkActivity = () =>
    ['search', 'sort', 'filter'].includes(activity.current);

  const trackMsg = () => {
    mixpanel.track('Email copy', {
      event: 'Email copied',
    });
  };
  const columnValues: any = [
    {
      title: () => {
        return (
          <>
            {t(translate.headers.employee)}
            <span className="sort-pad">
              <img
                className="sort-pad"
                src={sortedData['first_name'] === 'ascend' ? sortUp : sortDown}
              />
            </span>
          </>
        );
      },
      dataIndex: 'first_name',
      key: 'first_name',
      width: '20%',
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: false,
      sorter: (a: { first_name: string }, b: { first_name: string }) =>
        a.first_name.localeCompare(b.first_name),
      sortOrder: sortedInfo.columnKey === 'first_name' && sortedInfo.order,
      ellipsis: true,
      render: (username: string, row: any) => {
        return (
          <>
            <span className="emailAlign empName">
              {row.appAcess && !row.username ? (
                <Tooltip
                  title="Need Email to grant some permissions."
                  placement="right"
                  zIndex={10000}
                >
                  <Badge
                    count={<WarningOutlined style={{ color: '#EC9322' }} />}
                  >
                    {row?.profile_url ? (
                      <Avatar
                        style={{
                          width: '34px',
                          height: '34px',
                          backgroundColor: '#67AFB7',
                          color: 'white',
                          fontFamily: 'Montserrat-Bold',
                          border: row.is_org_admin
                            ? '1px solid #EC9322'
                            : 'none',
                          transition: '0.4s all ease-in-out',
                        }}
                        src={row?.profile_url}
                        className={row.is_org_admin && 'employee-avatar'}
                      />
                    ) : (
                      <Avatar
                        style={{
                          width: '34px',
                          height: '34px',
                          backgroundColor: '#67AFB7',
                          color: 'white',
                          fontFamily: 'Montserrat-Bold',
                          border: row.is_org_admin
                            ? '1px solid #EC9322'
                            : 'none',
                          transition: '0.4s all ease-in-out',
                        }}
                        className={row.is_org_admin && 'employee-avatar'}
                      >
                        {row?.first_name?.charAt(0)}
                        {row?.last_name?.charAt(0)}
                      </Avatar>
                    )}
                  </Badge>
                </Tooltip>
              ) : (
                <>
                  {row?.profile_url !== '' && row?.profile_url ? (
                    <Avatar
                      src={row?.profile_url}
                      className={row?.is_org_admin && 'profile-avatar'}
                    ></Avatar>
                  ) : (
                    <Avatar
                      style={{
                        width: '34px',
                        height: '34px',
                        backgroundColor: '#67AFB7',
                        color: 'white',
                        fontFamily: 'Montserrat-Bold',
                        border: row.is_org_admin ? '1px solid #EC9322' : 'none',
                        transition: '0.4s all ease-in-out',
                      }}
                      src={row?.profile_url}
                      className={row.is_org_admin && 'employee-avatar'}
                    >
                      {row?.first_name?.charAt(0)}
                      {row?.last_name?.charAt(0)}
                    </Avatar>
                  )}
                </>
              )}
              <Tooltip title={`${row.first_name} ${row.last_name}`}>
                <span
                  className="wwrapBreakAll"
                  data-testid={`${row.first_name} ${row.last_name}-Employees`}
                >
                  {row.first_name} {row.last_name}
                </span>
              </Tooltip>
            </span>
          </>
        );
      },
    },
    {
      title: `${t(translate.headers.email)}`,
      dataIndex: 'email',
      key: 'email',
      width: '25%',
      // eslint-disable-next-line react/display-name
      render: (email: string, record: any) => {
        return (
          <span
            className="emailAlign hoverWrapper"
            data-testid={email || record?.username}
          >
            <span>{email || record?.username}</span>
            <img
              src={copyIcon}
              alt="Edit"
              height="32"
              width="32"
              className="marginLeftIcon hoverShow1 on-hover-hand-change"
              onClick={() => {
                navigator.clipboard.writeText(email || record?.username);
                notification.info({ message: 'Copied' });
                trackMsg();
              }}
            />
          </span>
        );
      },
    },
    {
      title: `${t(translate.Employees.phoneNumber)}`,
      dataIndex: 'contact_number',
      key: 'contact_number',
      width: '20%',
    },
    {
      title: `${t(translate.Employees.team)}`,
      dataIndex: 'teamName',
      key: 'teamName',
      width: '35%',
      render: (teamName: any) => {
        return (
          <Tooltip title={teamName}>
            <div className="description-text">
              {teamName.split(',').length > 3
                ? teamName.split(',', 3).join(',') + ' ...'
                : teamName}
            </div>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    userDetails &&
      userDetails.roles &&
      userDetails.roles.map((ele: any) => {
        if (ele?.role_key === 'ORG_ADMIN') {
          setOrgFlag(true);
        }
      });
  }, [userDetails]);

  useEffect(() => {
    if (orgFlag) {
      const obj = {
        title: `${t(translate.Employees.opsCode)}`,
        dataIndex: 'ops_code',
        key: 'ops_code',
      };
      columnValues.push(obj);
    }
  }, [orgFlag]);

  useEffect(() => {
    if (teamids) {
      setPermission(true);
    }
  }, [teamids]);

  const wrapResult = async (
    apiUrl: string,
    orgId: number,
    pageNumber: number,
    pageSize: number,
    search: string,
    active: boolean,
    sortedInfo: string,
  ) => {
    const result = await getEmployeesList(
      apiUrl,
      orgId,
      pageNumber,
      pageSize,
      search,
      active,
      sortedInfo,
    );
    return {
      result,
      searchKey: search,
    };
  };

  const getEmployees = async () => {
    try {
      if (checkActivity()) initScroller();
      setLoading(true);
      const { result: emp, searchKey } = await wrapResult(
        userDetails.organization.api_url,
        userDetails.organization.id,
        pageNumber,
        pageSize,
        search,
        active,
        sortedInfo,
      );
      if (searchString.current !== searchKey) return;
      settotalcount(emp._metadata?.total_records_count);
      const data =
        emp && emp.records && emp.records.length > 0 ? emp.records : [];

      data.map((ele: any) => {
        let data1 = '';
        ele &&
          ele.teams &&
          ele.teams.length > 0 &&
          ele.teams.map((ele2: any) => {
            if (ele2 && ele2.name) {
              data1 = data1.concat(', ' + ele2.name);
            }
          });
        const appcheck = ele?.teams?.some((team: any) => team?.app_access);
        ele.appAcess = appcheck;
        ele.teamName = data1.replace(',', '');
      });

      setHasMore(data.length === pageSize);

      if (checkActivity()) setFilter(data);
      else if (activity.current === 'paginate')
        setFilter([...filterData, ...data]);
      else setFilter(data);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    } finally {
      setLoading(false);
      if (!initialize) setInitialize(true);
    }
  };

  const getProfile = async (empId: number) => {
    try {
      setLoaderview(true);
      const data = await getEmployeePermissionDetails(
        userDetails.organization.api_url,
        userDetails.organization.id,
        empId,
      );
      const { name: organization_name } = data.organization || {};
      data.organization_name = organization_name;
      setUserProfile(data);
      if (refresh === false) {
        setToggleWidget(true);
      } else {
        setToggleWidget(false);
      }
    } finally {
      setLoaderview(false);
    }
  };

  const getRow = (record: any) => {
    setEmpId(record.id);
    setAddEmployeeFlag(false);
    setIsEdit(false);
    mixpanel.track('Employee', {
      event: `${record.first_name} ${record.last_name} Clicked`,
    });
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    activity.current = 'sort';

    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim().regX;
    if (value.length <= 1) {
      searchString.current = '';
      setSearch('');
    } else {
      searchString.current = value;
      setSearch(value);
    }
  };

  const loadEmployees = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getEmployees();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getEmployees();
    }
  };

  useEffect(() => {
    if (sortedInfo && sortedInfo.order && initialize) {
      loadEmployees();
    }
  }, [sortedInfo.order, active, teamsState.empRefresh]);

  useEffect(() => {
    loadEmployees();
  }, [userDetails, pageNumber, search]);

  useEffect(() => {
    if (refresh && pageNumber === 1) {
      loadEmployees();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (empId && userDetails && userDetails.organization) {
      getProfile(empId);
    }
  }, [empId]);

  useEffect(() => {
    if (!toggleWidget) setEmpId(0);
  }, [toggleWidget]);

  useEffect(() => {
    if (editroleFlag === false) {
      setActive(true);
    }
  }, [editroleFlag]);

  useEffect(() => {
    if (refresh === true) {
      getProfile(userProfile?.id);
    }
  }, [refresh, userProfile]);

  const addEmployees = () => {
    setAddEmployeeFlag(true);
    mixpanel.track('Add Employee', {
      event: 'Add Employee Clicked',
    });
  };

  const Close = () => {
    setAddEmployeeFlag(false);
    mixpanel.track('Add Employee', {
      event: 'Add Employee Closed',
    });
  };
  const editRoles = () => {
    searchString.current = '';
    setSearch('');
    setEditRolesFlag(true);
  };

  const seeMore = () => {
    setToggleWidget(false);
    setEnableEmployeeDetails(true);
    setActive(true);
  };

  const closeDetails = () => {
    searchString.current = '';
    setSearch('');
    setEnableEmployeeDetails(false);
    setToggleWidget(false);
  };
  const closePermission = () => {
    setPermission(false);
    setTeamids(0);
  };

  const handleKey = (e: any) => {
    setKeyCode(false);
    if (e.keyCode === 8) {
      setKeyCode(true);
    }
  };

  return (
    <Layout className="posRel">
      {toggleWidget && (
        <ViewEmployee
          isEdit={isEdit}
          userProfile={userProfile}
          toggleWidget={toggleWidget}
          setToggleWidget={setToggleWidget}
          userDetails={userDetails}
          setEmpId={setEmpId}
          seeMore={seeMore}
          loaderview={loaderview}
        />
      )}
      {enableEmployeeDetails && (
        <EmployeeDetails
          userProfile={userProfile}
          setEnableEmployeeDetails={setEnableEmployeeDetails}
          setDetails={closeDetails}
          setTeamids={setTeamids}
          setRefresh={setRefresh}
          setToggleWidget={setToggleWidget}
        />
      )}
      {editroleFlag && (
        <TeamsDashBoard
          setEditRolesFlag={setEditRolesFlag}
          setSearch={setSearch}
        />
      )}
      {!editroleFlag && !enableEmployeeDetails && !permissionFlag && (
        <>
          <Content>
            <div className="tblContainer viewportContainer">
              <Row>
                <div className="tblTopOutSec searchFilters filterGap40">
                  <div className="flex csvrow">
                    <div className="searchSec">
                      <Search
                        placeholder="Search Directory"
                        defaultValue={search}
                        data-testid="personnelSearchInputField-Employees"
                        style={{ width: '200px !important' }}
                        onChange={(e) => handleSearch(e)}
                        onKeyDown={(e) => handleKey(e)}
                      />
                    </div>
                    <div className="smallFilter">
                      <CSelect
                        dataTestkey="name"
                        label="Status"
                        dataTestLabel="Employees"
                        list={activeFilterData}
                        isAll={false}
                        selectTrigger={(selected) => {
                          activity.current = 'filter';
                          const { id } = selected;
                          setActive(id);
                        }}
                        defaultId="true"
                      />
                    </div>
                  </div>
                </div>

                <div className="tblDft farmTabsTbl hoverable empTable emp-hov">
                  <div className="vHistorySec veditroles">
                    <div
                      onClick={() => {
                        editRoles();
                        mixpanel.track('OpenVersionHistory', {
                          event: 'Version History Modal Open',
                        });
                      }}
                      className="vHtxt Button"
                      data-testid="editRolesLink-Employees"
                    >
                      {t(translate.headers.viewTeams)}
                    </div>
                  </div>
                  <div style={{ cursor: 'pointer' }}>
                    <InfiniteScrollTable
                      // xScroll={1600}
                      columns={columnValues}
                      loading={loading}
                      hasMore={hasMore}
                      filterData={filterData}
                      totalcount={totalcount}
                      handleLoadMore={handleLoadMore}
                      filename="Employees"
                      onRowClick={(record) => getRow(record)}
                      onChange={handleChange}
                    />
                  </div>
                  {privilegeChecker(privilagesConstants.Add_Employees) && (
                    <div>
                      <img
                        id={applicationIds.personnelScreen.addEmployee}
                        className={'addButton '}
                        data-testid="addButton-Employees"
                        src={AddButtonIcon}
                        onClick={addEmployees}
                      />
                    </div>
                  )}
                </div>
              </Row>
            </div>
          </Content>
        </>
      )}
      {AddEmployeeFlag && (
        <AddEmployee
          Close={Close}
          toggleWidget={AddEmployeeFlag}
          setRefresh={() => setRefresh(true)}
        ></AddEmployee>
      )}
      {permissionFlag && (
        <PermissionsDashBoard
          setPermission={closePermission}
          teamId={teamids}
          setTeamId={setTeamids}
        ></PermissionsDashBoard>
      )}
    </Layout>
  );
};

export default Employees;
