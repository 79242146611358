/* eslint-disable no-console */
import { Input, Modal, notification, Select, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { getPolyColorData, setPolygon } from '../../constants/Api';
import { PolyColor, PolyPayload } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean, brownPoly?: any) => void;
  polyPayload: PolyPayload;
}
export const AddPoly: React.FC<Props> = ({
  showModal,
  handleCancel,
  polyPayload,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [name, setName] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [polyColors, setPolyColors] = useState<PolyColor[]>([]);
  const [color, setSelectedColor] = useState('');

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getPolyColors();
    }
  }, [userDetails]);
  const getPolyColors = async () => {
    try {
      setLoader(true);
      const { organization } = userDetails;
      const response = await getPolyColorData(
        organization.api_url,
        organization.farm.id,
      );
      if (response && response.length > 0) {
        setSelectedColor(response[0].value);
      }
      setPolyColors(response);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      name &&
      name.trim().length > 0 &&
      color &&
      color.trim().length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, name, color]);

  const AddPolygon = async () => {
    try {
      setLoader(true);
      const payload = {
        name: name.trim(),
        color,
        lat_long_vertices: polyPayload.lat_long_vertices,
        area: polyPayload.area,
      };
      setName(name.trim());

      const { organization } = userDetails;
      const resp = await setPolygon(
        organization.api_url,
        organization.farm.id,
        payload,
      );
      if (resp && resp.msg) {
        if (color === 'brown') {
          resp.vertices.map((item: any) => {
            item.x = item.x * 100;
            item.y = item.y * 100;
          });
          resp.polygone.vertices = resp.vertices;
          handleCancel(true, resp.polygone);
        } else {
          handleCancel(true);
        }
        notification.success({
          message: resp.msg,
        });
      }
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
    }
  };
  const handleSelect = (e: any) => {
    setSelectedColor(e);
  };
  return (
    <Modal
      className="commonPopup addPolygon"
      title="Add polygon"
      centered
      closable={false}
      width={'60vw'}
      visible={showModal}
      onCancel={() => handleCancel()}
      okText="Save"
      onOk={AddPolygon}
      okButtonProps={{
        className: 'btnSave ',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Name <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            onChange={(e) => setName(e.target.value)}
          />

          <div className="edit-loader">
            {loader && (
              <div className="loader">
                <Spin size="large" />
              </div>
            )}
          </div>
        </div>
        <div className="formCol">
          <label className="formLabel">
            Color <span style={{ color: 'red' }}> *</span>
          </label>
          <Select
            className="customSelect"
            onSelect={handleSelect}
            value={color}
          >
            {polyColors.map((data: any) => {
              if (data.name !== '') {
                return (
                  <option value={data.value}>
                    <span style={{ textTransform: 'capitalize' }}>
                      {data.name}
                    </span>{' '}
                    {data.name.toLowerCase() === 'brown'
                      ? 'Polygon'
                      : data.description}
                  </option>
                );
              }
            })}
          </Select>
        </div>
      </div>
      <div className="formRow">
        <div className="formColFull">
          <label className="formLabel">
            Vertices <span style={{ color: 'red' }}> *</span>
          </label>
          <div className="verticsSec">
            {polyPayload?.lat_long_vertices.map((data: any) => {
              return (
                <>
                  <div>
                    ({data?.latitude}, {data?.longitude})
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default AddPoly;
