/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-unescaped-entities */

import React, { useContext, useEffect, useRef, useState } from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import 'antd/dist/antd.css';
import {
  ArrowLeftOutlined,
  ExclamationOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Input, notification, Tooltip } from 'antd';
import CreateTeam from './CreateTeam';
import './style.css';
import PermissionsDashBoard from './PermissionsDashBoard';
import { getTeamsList } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import loading_logo from '../../assets/images/loading_logo.gif';
import InfiniteScroll from 'react-infinite-scroll-component';
import { privilagesConstants } from '../../constants/Privilages';

const { Search } = Input;
interface Props {
  setEditRolesFlag: (key: boolean) => void;
  setSearch: (e: any) => void;
}
const TeamsDashBoard: React.FC<Props> = ({ setEditRolesFlag, setSearch }) => {
  const [createFlag, setCreateFlag] = useState<boolean>(false);
  const [permissionFlag, setPermission] = useState<boolean>(false);
  const { userDetails, privilegeChecker } = useContext(ApplicationContext);
  const [teamsList, setTeamsList] = useState<any>([]);
  const [teamId, setTeamId] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [dataLaoding, setDataLoding] = useState<boolean>(false);
  const searchString = useRef<string>('');
  useEffect(() => {
    if (searchValue != '') {
      setPageNumber(1);
    }
    if (userDetails && userDetails.organization) {
      getTeams();
    }
    if (permissionFlag) {
      setSearchValue('');
      setPageNumber(1);
    }
  }, [userDetails, teamId, permissionFlag, pageNumber, searchValue]);

  const wrapResult = async (
    apiUrl: string,
    orgId: number,
    pageNumber: number,
    search: string,
  ) => {
    const response = await getTeamsList(apiUrl, orgId, pageNumber, search);
    return {
      response,
      searchKey: search,
    };
  };

  const getTeams = async () => {
    try {
      setDataLoding(true);
      const { response, searchKey } = await wrapResult(
        userDetails.organization.api_url,
        userDetails.organization.id,
        pageNumber,
        searchValue,
      );
      if (searchString.current !== searchKey) return;
      const data =
        response && response.records && response.records.length > 0
          ? response.records
          : [];
      if (data.length === 0 && searchValue === '') {
        setHasMore(false);
        setTeamsList(data);
      } else {
        if (data.length > 0 && searchValue === '') {
          if (pageNumber === 1) {
            setTeamsList(data);
          } else {
            setTeamsList([...teamsList, ...data]);
          }
        } else {
          setTeamsList(data);
        }
      }
      if (data.length < 25) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    } finally {
      setDataLoding(false);
    }
  };

  const closeModel = (status: boolean) => {
    if (status) {
      setPermission(true);
      getTeams();
    }
    setCreateFlag(false);
  };

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: any) => {
    const value: string = e.target.value.trim().regX;
    searchString.current = value;
    if (value.length <= 2) {
      searchString.current = '';
      setSearchValue('');
    } else {
      searchString.current = value;
      setSearchValue(value);
    }
  };

  return (
    <Layout className="posRel teamsRel">
      <Content>
        {!permissionFlag && (
          <div className="tblContainer teamsContainer">
            <div className="teamdashboard teamsBlk filterGap3 posrel">
              <div className="teamsTitle">
                <div
                  className="teamarrow pull-left permissionBackBtn perTeamsBack"
                  onClick={() => {
                    setEditRolesFlag(false);
                  }}
                  data-testid="backArrowIcon-EditRoles"
                >
                  {/* <ArrowLeftOutlined data-testid="backArrowIcon-EditRoles" /> */}
                </div>
                <div className="teamTitle pull-left">
                  <h4 className="headline1">Teams</h4>
                  <p className="teamDesc body1">
                    Use teams to manage employees' access and permissions to the
                    Monarch Platform
                  </p>
                </div>
              </div>
              <div className="teambtn pull-right">
                <div className="searchSec">
                  <Search
                    placeholder="Search Team"
                    data-testid="baseStationSerchInputField-BaseStations"
                    style={{ width: '200px !important' }}
                    onChange={handleSearch}
                  />
                </div>
                {privilegeChecker(privilagesConstants.Create_Teams) && (
                  <Button
                    className="Button"
                    data-testid="createTeam-TeamsDashBoard"
                    key="submit"
                    onClick={() => {
                      setCreateFlag(true);
                    }}
                  >
                    Create Team
                  </Button>
                )}
              </div>
            </div>

            <div className={'teamlist'}>
              {teamsList.length === 0 && !dataLaoding && (
                <div className="notFound">
                  <h4 className="tableEmptyTxt overline1">No Data Found</h4>
                </div>
              )}
              <div style={{ width: '100%', textAlign: 'center' }}></div>
              <InfiniteScroll
                scrollThreshold={0.95}
                height={window.innerHeight - 240}
                dataLength={teamsList.length}
                next={handleLoadMore}
                hasMore={hasMore}
                loader={
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <img
                      style={{ width: '40px', height: '40px' }}
                      src={loading_logo}
                    />
                  </div>
                }
              >
                <div className="teamsgrid container_full_2">
                  {teamsList.length > 0 &&
                    teamsList?.map((team: any) => (
                      <div
                        key={team.id}
                        className="teamsgridbox"
                        onClick={() => {
                          setPermission(true);
                          setTeamId(team.id);
                        }}
                      >
                        <h4
                          className="headline1"
                          data-testid={`${team.name}-TeamsDashBoard`}
                        >
                          {team.name}
                        </h4>
                        <span className="pull-right teamCntContainer">
                          <span className="teamCnt headline2 fSbd">
                            {team.members_count}
                          </span>{' '}
                          <UserOutlined />
                        </span>
                        <p className="body2">{team.description}</p>
                        {!team.is_privileges_configured && (
                          <Tooltip
                            title="Finish setting up these permissions"
                            placement="bottom"
                          >
                            <ExclamationOutlined className="exclamationicon" />
                          </Tooltip>
                        )}
                      </div>
                    ))}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        )}

        {createFlag && (
          <CreateTeam
            showModal={createFlag}
            closeModel={closeModel}
            setTeamId={setTeamId}
          ></CreateTeam>
        )}
        {permissionFlag && teamId && (
          <PermissionsDashBoard
            setPermission={setPermission}
            teamId={teamId}
            setTeamId={setTeamId}
          ></PermissionsDashBoard>
        )}
      </Content>
    </Layout>
  );
};

export default TeamsDashBoard;
