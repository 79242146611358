/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react';
import fuv_upload from '../../assets/images/fuv_upload.svg';
import './fuvStyles.css';
// import fuv_new from '../../assets/images/fuv_new.svg';
// import fuv_fullscreen from '../../assets/images/fuv_fullscreen.svg';
import fuv_root from '../../assets/images/fuv_root.svg';
// import fuv_download from '../../assets/images/fuv_download.svg';
// import fuv_delete from '../../assets/images/fuv_delete.svg';
import { getClient } from '../../util/MqttConnection';

import {
  ArrowLeftOutlined,
  CaretRightOutlined,
  PauseCircleOutlined,
} from '@ant-design/icons';
import { Button, Collapse, notification, Spin } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useHistory, useLocation } from 'react-router-dom';
import { getUploadFiles, uploadFilesControl } from '../../constants/Api';
import { FileUploadList } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import RoutesConstants from '../../routes/RoutesConstant';

const { Panel } = Collapse;
interface DataType {
  key: React.Key;
  name: string;
  size: string;
  modified: string;
}
const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    // console.log(
    //   `selectedRowKeys: ${selectedRowKeys}`,
    //   'selectedRows: ',
    //   selectedRows,
    // );
  },
  getCheckboxProps: (record: DataType) => ({
    disabled: record.name === 'Disabled User', // Column configuration not to be checked
    name: record.name,
  }),
};

const columns: ColumnsType<DataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text: string) => <a>{text}</a>,
  },
  {
    title: 'Size',
    dataIndex: 'size',
  },
  {
    title: 'Modified',
    dataIndex: 'modified',
  },
];

const data: DataType[] = [
  {
    key: '1',
    name: 'John Brown',
    size: '102.5 KB',
    modified: '02/04/2022',
  },
  {
    key: '2',
    name: 'Jim Green',
    size: '15.2 KB',
    modified: '04/04/2022',
  },
  {
    key: '3',
    name: 'Joe Black',
    size: '34.3 KB',
    modified: '01/04/2022',
  },
  {
    key: '4',
    name: 'Joe Black',
    size: '21.3 KB',
    modified: '02/04/2022',
  },
];
const text = `
  Lorem ipsum is a placeholder text commonly
`;
const FileUploadView: React.FC = () => {
  const { push } = useHistory();
  const { state } = useLocation();
  const tractorId: any = state;
  const { userDetails } = useContext(ApplicationContext);
  const [fileUploading, setFileUploading] = useState<FileUploadList[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [status, setStatus] = useState();

  const [selectionType, setSelectionType] =
    useState<'checkbox' | 'radio'>('checkbox');

  const goBackFleet = () => {
    push(RoutesConstants.Organization, { state: 'Fleet' });
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getUploadFilesList();
    }
  }, [userDetails]);

  const getUploadFilesList = async () => {
    try {
      setLoader(true);
      const data = await getUploadFiles(
        userDetails.organization.api_url,
        tractorId,
      );

      setFileUploading(data);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const uploadFilesControlling = async (payload: any) => {
    try {
      const response = await uploadFilesControl(
        userDetails.organization.api_url,
        tractorId,
        payload,
      );
      notification.success({ message: response.msg });

      getUploadFilesList();
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  useEffect(() => {
    try {
      if (userDetails && userDetails.organization) {
        const { organization } = userDetails;
        const mqttClient = getClient(
          organization.mqtt_ip,
          organization.mqtt_port,
        );

        if (mqttClient) {
          mqttClient.on('connect', () => {
            mqttClient.subscribe([
              `file_upload_to_cloud_status_${organization.id}`,
            ]);
          });
          mqttClient.on('message', (topic, message) => {
            if (topic === `file_upload_to_cloud_status_${organization.id}`) {
              const data = JSON.parse(message.toString());
              if (data.tractor_id === tractorId) {
                setStatus(data.message);
              }
            }
          });
        }

        return () => {
          mqttClient.unsubscribe([
            `file_upload_to_cloud_status_${organization.id}`,
          ]);
          mqttClient.end();
        };
      }
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  }, []);

  return (
    <>
      <div className="personnelSec fileUploadBack">
        <ArrowLeftOutlined onClick={() => goBackFleet()} />
      </div>
      <div className="fileUploadContainer tblContainer">
        <div className="fuvWrapper">
          <div className="fuvRow fullBdr">
            <div className="fuvHead">
              <div className="fuvButtons">
                <Button
                  className="resumeAllTxt"
                  onClick={() =>
                    uploadFilesControlling({
                      upload_all: true,
                    })
                  }
                >
                  <img src={fuv_upload} /> &nbsp; Upload All{' '}
                </Button>

                <Button
                  className="fuvBtn"
                  onClick={() =>
                    uploadFilesControlling({
                      pause_all: true,
                    })
                  }
                >
                  {' '}
                  <PauseCircleOutlined style={{ fontSize: 15 }} /> Pause All{' '}
                </Button>

                {/* <Button className="fuvBtn" disabled>
                  {' '}
                  <img src={fuv_new} /> New
                </Button> */}
              </div>
              {/* <div className="fuvFscreenBtn">
                <img src={fuv_fullscreen} />
              </div> */}
            </div>
          </div>
          <div className="fuvRow mb10 fullBdr">
            <div className="fuvRoot">
              <div className="fuvHead fuvbdr">
                <img src={fuv_root} /> <span className="rootTxt">Root</span>
              </div>
              <div className="rootFolders">
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 1 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  {fileUploading.length > 0 &&
                    fileUploading.map((item: FileUploadList, i: any) => {
                      return (
                        <Panel
                          header={
                            <span className="rootItemsTxt">
                              {item.attribute_name}
                            </span>
                          }
                          key={i}
                          extra={
                            <Button
                              className={
                                item.upload === true ? 'pauseTxt' : 'resumeTxt'
                              }
                              onClick={() =>
                                uploadFilesControlling({
                                  attribute_name: item.attribute_name,
                                  upload: !item.upload,
                                  network: 'all',
                                })
                              }
                            >
                              {item.upload === true ? 'Pause' : 'Upload'}
                            </Button>
                          }
                        >
                          <p>{text}</p>
                        </Panel>
                      );
                    })}
                </Collapse>
              </div>
            </div>
            {/* <div className="fuvFilesList">
              <div className="fuvFileHead">
                <button className="fuvFileBtn">
                  <img src={fuv_download} />
                  Download
                </button>
                <button className="fuvFileBtn">
                  <img src={fuv_delete} />
                  Delete
                </button>
              </div>
              <div className="fuvTable">
                <div>
                  <Table
                    rowSelection={{
                      type: selectionType,
                      ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={data}
                  />
                </div>
              </div>
            </div> */}
          </div>
          {status && (
            <div className="fuvRow fullBdr">
              <div className="fuvConsole">
                <p>{status}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </>
  );
};

export default FileUploadView;
