/* eslint-disable no-console */
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Timeline,
  Tooltip,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import atArrow from '../../assets/images/atArrow.svg';
import disCross from '../../assets/images/disCross.svg';
import errorCode_icon from '../../assets/images/errorCode_icon.svg';
import fleet_implement_new from '../../assets/images/fleet_implement_new.svg';
import hitch1 from '../../assets/images/hi_gray.svg';
import hoursOfOp from '../../assets/images/hours_of_op.svg';
import opRpmIcon from '../../assets/images/opRpmIcon.svg';
import opSpeedIcon from '../../assets/images/opSpeedIcon.svg';
import t_lock from '../../assets/images/t_lock.svg';
import t_loc_rou from '../../assets/images/t_loc_rou.svg';
import waypoint_command from '../../assets/images/waypoint_command.svg';
import './autodrivelist.css';

import {
  addRemoteAVCommand,
  createSnapshot,
  executeCommand,
  executeRemoteAVCommand,
  getImplementsList1,
  getRoute,
  getTractorLockById,
  lockUnLockReason,
  setImplement,
  stopDriveTractor,
} from '../../constants/Api';
import {
  checkAutoDriveMonitorErrorCodes,
  checkDisconnectCheck,
  checkTractorConnectedState,
  commands,
  getFloatImage,
  getFloatText,
  getGearText,
  getHeight,
  getImplementName,
  getOperatorNameByHeartBeat,
  getPTOState,
  getRpm,
  getSpeed,
  getStatusClass,
  getStatusForTractor,
  getTripMeterValue,
  isHaveErrors,
  LS_ROUTINE,
  tractorConstantsNew,
} from '../../constants/Common';
import {
  LockedOutTractor,
  MapFeature,
  TractorHeartBeat,
} from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { RemoteDriveAppCtx } from './RemoteDriveContext';

import endBtnIcon from '../../assets/images/endBtnIcon.svg';
import { SET_PINNED, SET_TRACTOR_IMPLEMENT_STATE } from '../../context/actions';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import AppLoader from '../common/AppLoader';
import AutonomySettingsWidget from '../common/AutonomySettingsWidget';
import { Cameras } from '../livecommand/CamerasPage1';
import {
  SELECTED_IMPLEMENT_NAME,
  SELECTED_ITEM_NAME,
  SELECTED_ROUTINE,
  SELECTED_TRACTORS,
  SET_ADD_STEP,
  SET_AUTO_VALUE,
  SET_PINNED_TRACTORS,
  SET_SETUP_KEYS,
  SET_SHOW_PATH_MODAL,
  SET_SHOW_PRESENT_COMMAND,
  SET_SHOW_REMOTE_DRIVE_DETAILS,
  SET_SHOW_SNAPSHOT,
  SHOW_SAVE_COMMAND,
  SHOW_TRACTOR_START,
  SNAP_SHOT_INITIATED,
} from './actions';
import AutoDriveErrorCodePopup from './AutoDriveErrorCodePopup';
import { AutoDriveInterruptMsg } from './AutoDriveInterruptMsg';
import { Errorcodepopup } from './Errorcodepopup';
import { GuardrailWidget } from './GuardrailWidget';
import LockUnLockReason from './LockUnLockReason';
import RemoteAvActions from './RemoteAvActions';
import TimeLineItem from './TimeLineItem';

const { Option } = Select;

interface Props {
  clearPreviewPath: (id: number) => void;
  handleResetCommand: (state: string) => void;
  recenterItem: (item: MapFeature) => void;
  recenterXY: (xy: any) => void;
  addRVCommand: () => void;
  preCommandState: string;
  base: [number, number];
  addRowFollowRVCommand: () => void;
  setPreCommandState: (command: string) => void;
  clearAll: () => void;
}
const RemoteDriveDetails: React.FC<Props> = ({
  clearPreviewPath,
  handleResetCommand,
  recenterItem,
  recenterXY,
  addRVCommand,
  preCommandState,
  base,
  addRowFollowRVCommand,
  setPreCommandState,
  clearAll,
}: Props) => {
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const mixpanel = useMixpanel();
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  const [appState] = APPReducer;
  const { tractorsMap, tractorsList } = appState;
  const { tractorImplementState, isPinned } = autoDriveState;
  const {
    getImplement,
    RDReducer,
    handlePauseResumeStopLiveCom,
    waypointsForRemoteAv,
    setGoToPointCoordinates,
    greenPolygons,
    setGoToRowFollowPolygon,
    commandsList,
    getIndicatorIcons,
    mapPinnedTractors,
    clearAllLocalDBCommands,
    addRVRoutineCommand,
  } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const {
    selectedTractor,
    showSaveCommand,
    setUpKeys,
    autoDriveSettings,
    commandType,
    selectedItemName,
    selectedVineBlockName,
    selectedImplementName,
    showPathModal,
    addStep,
    autoValue,
    autoDriveMonitorErrorCodes,
    autoDriveError,
    showTractorStart,
    pinnedTractors,
    autoDriveInterruptMsg,
  } = state;
  const { heartbeat } = selectedTractor;
  const [loader, setLoader] = useState<boolean>(false);
  const [autonomyFlag, setAutonomyFlag] = useState<boolean>(false);
  const [autonomyFlag1, setAutonomyFlag1] = useState<boolean>(false);
  const [errorCodeFlag, setErrorCodeFlag] = useState<boolean>(false);

  const [options, setOptions] = useState<any>();
  const [implementsList, setImplements] = useState<any[]>([]);
  const [selectedImplement, setselectedImplement] = useState('');
  const [savedImleplentDetails, setsavedImplentDetails] = useState<any>();
  const [showTractorLockUnLock, setShowTractorLockUnLock] = useState(false);
  const [tractorLockUnLock, setTractorLockUnLock] = useState<boolean>(false);
  // const [isPinned, setTogglePinned] = useState<boolean>(false);
  const [routines, setRoutines] = useState([]);
  const { confirm } = Modal;

  useEffect(() => {
    if (selectedTractor && selectedTractor.id) {
      getTractorDetails(selectedTractor.id);
    }
  }, [selectedTractor]);

  useEffect(() => {
    setselectedImplement('');
    return () => {
      setselectedImplement('');
    };
  }, []);
  const showDeleteConfirm = () => {
    confirm({
      className: 'confirm-end-operation',
      title: 'End Operation?',
      icon: (
        <div className="endOptIcon">
          <img width="60" src={endBtnIcon} />
        </div>
      ),
      content:
        'Are you sure you want to end this operation before it has been completed?',
      okText: 'End Operation',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        clearAllLocalDBCommands(selectedTractor.id);
        dispatch({
          type: SELECTED_TRACTORS,
          payload: [],
        });
        handleResetCommand('');
        tractorImplementState[selectedTractor.id] = false;
        autoDriveDispatch({
          type: SET_TRACTOR_IMPLEMENT_STATE,
          payload: tractorImplementState,
        });
        clearPreviewPath(selectedTractor.id);
        handlePauseResumeStopLiveCom('stop');
      },
    });
  };
  useEffect(() => {
    setselectedImplement('');
    dispatch({
      type: SELECTED_IMPLEMENT_NAME,
      payload: '',
    });
    if (selectedTractor && selectedTractor.id && implementsList.length > 0) {
      implementsList.forEach((implement: any) => {
        if (
          selectedTractor &&
          selectedTractor?.heartbeat &&
          selectedTractor?.heartbeat?.implement &&
          selectedTractor?.heartbeat?.implement?.current_implement &&
          selectedTractor?.heartbeat?.implement?.current_implement.length > 0 &&
          implement.name ===
            selectedTractor?.heartbeat?.implement?.current_implement
        ) {
          dispatch({
            type: SELECTED_IMPLEMENT_NAME,
            payload: implement.name,
          });

          setselectedImplement(implement.config_implements_uuid);
        }
      });
    }
  }, [selectedTractor, implementsList]);

  useEffect(() => {
    if (selectedTractor && selectedTractor.id) getImplement(selectedTractor.id);
  }, [selectedTractor]);

  const startAutoDrive = async () => {
    try {
      const { organization } = userDetails;
      const { msg } = await executeRemoteAVCommand(organization.api_url, {
        organization_id: userDetails.organization_id,
        action: 'start',
        tractor_id: selectedTractor.id,
        drive_action_uuid: selectedTractor?.heartbeat?.drive_action_details
          ?.current_drive_action as string,
      });
      notification.success({
        message: msg,
        duration: 1,
      });
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message || err?.message,
      });
    }
  };

  const disConnectConnection = async (hb?: TractorHeartBeat) => {
    if (hb && hb?.drive_action_details?.current_drive_action) {
      setLoader(true);
      try {
        const { organization } = userDetails;
        const { msg } = await stopDriveTractor(
          organization.api_url,
          hb?.drive_action_details?.current_drive_action,
          {
            operator_id: userDetails.id,
            action: 'FINISH',
          },
        );
        clearAllLocalDBCommands(hb.tractor_id);
        notification.success({
          message: msg,
          duration: 1,
        });
        setLoader(false);

        clearPreviewPath(hb.tractor_id);
        return;
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      } finally {
        setLoader(false);
      }
    }
  };

  const clearAll1 = (selectedTractor: TractorHeartBeat) => {
    const { organization } = userDetails;
    if (organization && organization.api_url) {
      clearAllLocalDBCommands(selectedTractor.tractor_id);
      selectedTractor?.drive_action_details?.current_drive_action &&
        addRemoteAVCommand(
          organization.api_url,
          [
            {
              command: 'clear_command_queue',
            },
          ],
          selectedTractor?.drive_action_details?.current_drive_action as string,
        );
    }
    dispatch({
      type: SET_AUTO_VALUE,
      payload: '',
    });
    setGoToPointCoordinates([]);
    dispatch({
      type: SET_SHOW_PRESENT_COMMAND,
      payload: 'GOTO',
    });
    handleResetCommand('GOTO');
    clearPreviewPath(selectedTractor.tractor_id);
  };

  const onSelect = (data: string) => {
    dispatch({
      type: SET_AUTO_VALUE,
      payload: data,
    });
    if (data) {
      if (waypointsForRemoteAv && waypointsForRemoteAv.length > 0) {
        waypointsForRemoteAv.map((tractor: MapFeature) => {
          if (tractor && tractor.name && tractor.name === data) {
            recenterItem(tractor);
            recenterXY(tractor.coordinates);
            setGoToPointCoordinates([]);
            setGoToPointCoordinates(tractor.coordinates);
            dispatch({
              type: SELECTED_ITEM_NAME,
              payload: tractor.name,
            });
          }
        });
      }
      if (greenPolygons && greenPolygons.length > 0) {
        greenPolygons.map((greenPolygon: MapFeature) => {
          if (greenPolygon && greenPolygon.name && greenPolygon.name === data) {
            recenterItem(greenPolygon);
            setGoToRowFollowPolygon(null);
            setGoToRowFollowPolygon(greenPolygon);
          }
        });
      }
      if (routines && routines.length > 0) {
        routines.map((routine: MapFeature) => {
          if (routine && routine.name && routine.name === data) {
            localStorage.removeItem(LS_ROUTINE);
            localStorage.setItem(LS_ROUTINE, JSON.stringify(routine));
            dispatch({
              type: SELECTED_ITEM_NAME,
              payload: routine.name,
            });
            dispatch({
              type: SELECTED_ROUTINE,
              payload: routine,
            });
            dispatch({
              type: SHOW_SAVE_COMMAND,
              payload: true,
            });
            setPreCommandState(commands.ROUTINE);
          }
        });
      }
    }
  };

  const onSearch = (searchText: string) => {
    if (searchText) {
      const objects: { value: string }[] = [];
      waypointsForRemoteAv.forEach((value: MapFeature) => {
        if (
          value &&
          value.name &&
          value.name.toLowerCase().includes(searchText.toLowerCase())
        ) {
          objects.push({
            value: value.name,
          });
        }
      });
      greenPolygons.forEach((value: MapFeature) => {
        if (
          value &&
          value.name &&
          value.name.toLowerCase().includes(searchText.toLowerCase())
        ) {
          objects.push({
            value: value.name,
          });
        }
      });
      routines.forEach((value: MapFeature) => {
        if (
          value &&
          value.name &&
          value.name.toLowerCase().includes(searchText.toLowerCase())
        ) {
          objects.push({
            value: value.name,
          });
        }
      });
      setOptions(objects);
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    userDetails && userDetails.organization && getImplementsListData();
    userDetails && userDetails.organization && getRoutes();
  }, [userDetails]);

  const getImplementsListData = async () => {
    try {
      setLoader(true);
      const records = await getImplementsList1(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
      );
      records && records.length > 0
        ? setImplements(records)
        : setImplements([]);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message || error?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    handleResetCommand(selectedImplement === '' ? '' : 'GOTO');
  }, [selectedImplement]);

  const approveImplementSettings = async () => {
    setLoader(true);
    try {
      const { organization } = userDetails;
      if (organization && organization.api_url) {
        await setImplement(
          organization.api_url,
          selectedTractor?.heartbeat?.tractor_id,
          selectedImplementName ? selectedImplementName : 'None',
        );
        tractorImplementState[selectedTractor.id] = true;
        autoDriveDispatch({
          type: SET_TRACTOR_IMPLEMENT_STATE,
          payload: tractorImplementState,
        });
        notification.success({
          message: 'Success',
          duration: 1,
        });
      }
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message || err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const addImplementSettings = async () => {
    setLoader(true);
    try {
      const { organization } = userDetails;
      if (
        organization &&
        organization.api_url &&
        selectedTractor?.heartbeat?.drive_action_details?.current_drive_action
      ) {
        const commandData = {
          command: 'implement_ctrl_params',
          implement: selectedImplementName ? selectedImplementName : 'None',
          ...savedImleplentDetails,
        };
        await addRemoteAVCommand(
          organization.api_url,
          [commandData],
          selectedTractor?.heartbeat?.drive_action_details
            ?.current_drive_action as string,
        );
      }
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message || err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleIgnore = async (action: string) => {
    try {
      const { organization } = userDetails;
      const drive_action_uuid =
        heartbeat?.drive_action_details?.current_drive_action;
      const { msg } = await executeCommand(
        organization.api_url,
        drive_action_uuid,
        {
          action,
          operator_id: userDetails.id,
          planner: 'guardrails',
          tractor_id: selectedTractor.id,
          organization_id: userDetails.organization_id,
          parameters: [],
        },
      );
      notification.success({
        message: msg,
      });
      dispatch({ type: SET_SHOW_PATH_MODAL, payload: false });
    } catch (err: any) {
      notification.error({
        message: err.response?.data?.error.message || err.message,
      });
    }
  };

  // get routes routes
  const getRoutes = async () => {
    try {
      const { organization } = userDetails;
      let records = await getRoute(organization.api_url, organization.farm.id);
      records = records && records.length > 0 ? records : [];
      setRoutines(records);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message || error?.message,
      });
    }
  };

  const error_Codes_Details = () => {
    setErrorCodeFlag(true);
  };

  const closeModel = () => {
    setErrorCodeFlag(false);
  };

  useEffect(() => {
    if (
      checkAutoDriveMonitorErrorCodes(
        autoDriveMonitorErrorCodes,
        selectedTractor?.id,
      )
    ) {
      handleResetCommand('');
      clearAll1(selectedTractor?.heartbeat);
    }
  }, [autoDriveMonitorErrorCodes, selectedTractor]);

  useEffect(() => {
    if (isHaveErrors(autoDriveError)) {
      handleResetCommand('');
      clearAll1(selectedTractor?.heartbeat);
    }
  }, [autoDriveError, selectedTractor]);

  const updateReason = async () => {
    try {
      setLoader(true);
      const payload: LockedOutTractor = {
        lockout_reason: '',
        is_tractor_lockout: false,
        drive_action_uuid:
          selectedTractor?.heartbeat?.drive_action_details
            ?.current_drive_action,
      };
      const resp = await lockUnLockReason(
        userDetails.organization.api_url,
        userDetails.organization.id,
        selectedTractor.id,
        payload,
      );
      if (resp && resp.msg) {
        notification.success({
          message: resp.msg,
        });
        getTractorDetails(selectedTractor.id);
      }
      setLoader(false);
    } catch (error: any) {
      notification.error({
        message: error.response.data.error.message || error?.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
    }
  };

  const getTractorDetails = async (tractorId: number) => {
    const resp = await getTractorLockById(
      userDetails.organization.api_url,
      userDetails.organization_id,
      tractorId,
    );

    const status =
      resp &&
      resp.value &&
      resp.value.is_tractor_lockout &&
      resp.value.is_tractor_lockout.length > 0 &&
      resp.value.is_tractor_lockout.toLowerCase() == 'true'
        ? true
        : false;
    setTractorLockUnLock(status);
  };

  const createSnapshots = async () => {
    setLoader(true);
    try {
      const { organization } = userDetails;
      const response = await createSnapshot(
        organization.api_url,
        organization.farm.id,
        selectedTractor.id,
      );
      if (!response?.id) throw new Error('SnapShot Failed');
      const { msg, id } = mixpanel.track('Live Map', {
        event: `Fleet List SnapShot successful`,
      });
      response['tractorName'] = tractorsMap.get(response.tractor_id)?.name;
      response['name'] = `${userDetails.first_name} ${userDetails.last_name}`;
      dispatch({
        type: SNAP_SHOT_INITIATED,
        payload: response,
      });
      notification.open({
        message: '',
        key: response.id,
        // duration: 3,
        description: (
          <>
            <p>Snapshot Captured</p>
            <Button
              onClick={() => {
                notification.close(response.id);
                dispatch({
                  type: SET_SHOW_SNAPSHOT,
                  payload: true,
                });
              }}
              className="cursor"
            >
              View
            </Button>
          </>
        ),
      });
    } catch (error: any) {
      mixpanel.track('Live Map', {
        event: `Fleet List SnapShot Failed`,
      });
      notification.error({
        message: error.response?.data?.error?.message || error.message,
      });
    } finally {
      setLoader(false);
    }
  };
  const isPinnedFun = (id: number) => pinnedTractors.includes(id);
  const unPinnedTractors = (id: number) => {
    const ttractors = pinnedTractors.filter((item: any) => item !== id);
    dispatch({
      type: SET_PINNED_TRACTORS,
      payload: ttractors,
    });
  };

  const zindexStarts = 100;
  const tBox: any = document.querySelectorAll('.adtBottomBox');

  for (let i = 0; i < tBox.length; i++) {
    if (tBox[i]) {
      tBox[i].style['z-index'] = zindexStarts - i;
    }
  }

  return (
    <>
      <div className="rautoDriveRightSec grayBlock">
        <div className="autoDriveTractorHead">
          <div
            className="tractorPin"
            data-testid="tractorpin-LiveMap"
            onClick={() => {
              mapPinnedTractors(selectedTractor.id);
              isPinned &&
                dispatch({
                  type: SET_PINNED_TRACTORS,
                  payload: [],
                });
              autoDriveDispatch({
                type: SET_PINNED,
                payload: !isPinned,
              });
            }}
          ></div>
          <div
            className="adtHeadSec"
            onClick={() => {
              dispatch({
                type: SET_SHOW_REMOTE_DRIVE_DETAILS,
                payload: false,
              });
            }}
          >
            <span className="adtHeadTextIcon">
              <img src={atArrow} alt="atArrow" />
            </span>
            <span className="adtHeadText">TRACTOR DETAILS</span>
          </div>
        </div>
        <div className={`whiteWrapper ${isPinned ? 'whiteLayer' : ''}`}></div>
        <div className="adtMainContainer">
          <div
            className={`adtTopSection ${
              pinnedTractors.length < 2 ? 'tdtl_nopin' : ''
            }`}
          >
            <div className={`adtTopBox ${isPinned ? 'adRoundBox' : ''}`}>
              <div
                className="adtClose"
                onClick={() => {
                  if (pinnedTractors.length == 1) {
                    if (isPinnedFun(selectedTractor.id)) {
                      dispatch({
                        type: SET_SHOW_REMOTE_DRIVE_DETAILS,
                        payload: false,
                      });
                      dispatch({
                        type: SELECTED_TRACTORS,
                        payload: [],
                      });
                    } else {
                      const [id] = pinnedTractors;
                      const [ftractor] = tractorsList.filter(
                        (tractor: any) => tractor.id === id,
                      );
                      dispatch({
                        type: SELECTED_TRACTORS,
                        payload: [ftractor],
                      });
                    }
                    unPinnedTractors(selectedTractor.id);
                    handleResetCommand('');
                  } else if (pinnedTractors.length > 1) {
                    const list = pinnedTractors.filter(
                      (item: any) => item !== selectedTractor.id,
                    );
                    const [ftractor] = tractorsList.filter(
                      (tractor: any) => tractor.id === list[0],
                    );
                    dispatch({
                      type: SELECTED_TRACTORS,
                      payload: [ftractor],
                    });
                    handleResetCommand('');
                    unPinnedTractors(selectedTractor.id);
                  } else {
                    dispatch({
                      type: SET_SHOW_REMOTE_DRIVE_DETAILS,
                      payload: false,
                    });
                    dispatch({
                      type: SELECTED_TRACTORS,
                      payload: [],
                    });
                    handleResetCommand('');
                  }
                }}
                data-testid="closeiconwin-LiveMap"
              ></div>
              <div className="adTabsHeadSec">
                <div
                  className="adtabsHeadText"
                  data-testid="Tractornmefleet-LiveMap"
                >
                  {selectedTractor?.name}
                </div>
                <div
                  className={`ttractor_state  ${getStatusClass(
                    selectedTractor.isAvailable,
                    selectedTractor?.heartbeat?.drive_action_details
                      ?.drive_action_created_at,
                  )}`}
                >
                  {getStatusForTractor(
                    selectedTractor.isAvailable,
                    selectedTractor?.heartbeat?.drive_action_details
                      ?.drive_action_created_at,
                  )}
                </div>
              </div>
              <div className="adTabsContentSec">
                <div
                  className="rautocloseIcon closeIconGrid"
                  data-testid="closeicon-LiveMap"
                >
                  <div className="swithBtn">
                    {/* {isSameUserTractorConnectedOrNot(
                      userDetails?.id,
                      heartbeat,
                    ) && (
                      <>
                        <Switch
                          checkedChildren="Unlock"
                          unCheckedChildren="Lockout"
                          checked={tractorLockUnLock}
                          onChange={() =>
                            tractorLockUnLock
                              ? updateReason()
                              : setShowTractorLockUnLock(true)
                          }
                          defaultChecked
                          style={{ color: '#546cb2' }}
                        />
                      </>
                    )} */}
                  </div>
                  <div className="">
                    {checkDisconnectCheck(
                      userDetails,
                      selectedTractor,
                      autoDriveMonitorErrorCodes,
                    ) && (
                      <>
                        <Button
                          className="disconnectBtnSec"
                          onClick={() => {
                            disConnectConnection(selectedTractor?.heartbeat);
                            // to reset implement start here
                            tractorImplementState[selectedTractor.id] = false;
                            autoDriveDispatch({
                              type: SET_TRACTOR_IMPLEMENT_STATE,
                              payload: tractorImplementState,
                            });
                            // to reset implement ends here
                          }}
                        >
                          <img src={disCross} /> DISCONNECT
                        </Button>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="rautoDriveDetailsTitle"
                  data-testid="Tractornmefleet-LiveMap"
                ></div>
                <div className="rautoDriveStatus">
                  {/* <div className="t_battery">
                    <span style={{ marginLeft: '8px' }}>
                      {batteryValue(selectedTractor)}%
                    </span>
                    <div className="battery-blk">
                      <Battery
                        battery={getBatteryPercentage(selectedTractor)}
                        charging={getStatusForTractor(
                          getOperationStatus(selectedTractor.heartbeat),
                        )}
                      ></Battery>
                    </div>
                  </div> */}
                  <div className="autoDrivetime">
                    <img src={hoursOfOp} alt="" />{' '}
                    <span>{getTripMeterValue(selectedTractor)}</span>
                  </div>
                  <div>
                    {selectedTractor.isAvailable !==
                      tractorConstantsNew.off.key && (
                      <>
                        {getOperatorNameByHeartBeat(
                          selectedTractor.heartbeat,
                        ).trim() !== '' && (
                          <img className="t_lock" src={t_lock} />
                        )}
                        {getOperatorNameByHeartBeat(selectedTractor.heartbeat)}
                      </>
                    )}
                  </div>
                  <div
                    // className={
                    //   selectedTractor.indicatorsData.filter(
                    //     (indicator: { indicator_level: number }) =>
                    //       indicator.indicator_level === 3,
                    //   ).length > 0
                    //     ? 'autodriveStatusIcons redBdr'
                    //     : 'autodriveStatusIcons whiteBdr'
                    // }
                    className="autodriveStatusIcons"
                  >
                    {selectedTractor &&
                      selectedTractor.id &&
                      getIndicatorIcons(selectedTractor)}
                  </div>
                </div>
                {/* <div className="autodriveDisconnectBlk">
                  <div className="autoDrivetime">
                    <img src={hoursOfOp} alt="" />{' '}
                    <span>{getTripMeterValue(selectedTractor)}</span>
                  </div>
                </div> */}
                <div
                  className="autoDriveInrBlk tractormeterBlk"
                  style={{ position: 'relative' }}
                >
                  <Space>
                    <h6 className="implement-txt">
                      <img src={fleet_implement_new} alt="" />
                      {getImplementName(selectedTractor)}
                    </h6>

                    {userDetails &&
                      // userDetails?.id ===
                      //   selectedTractor?.heartbeat?.drive_action_details
                      //     ?.current_operator_id &&
                      selectedImplement !== '' && (
                        <div className="settings-o">
                          <SettingOutlined
                            onClick={() => setAutonomyFlag1(true)}
                          />
                        </div>
                      )}
                  </Space>
                  <div className="autoDriveCodeGrid">
                    <div className="flexBtn">
                      <div className="autoDriveMph">
                        <img src={opSpeedIcon} />{' '}
                        {selectedTractor && selectedTractor.heartbeat
                          ? getSpeed(selectedTractor.heartbeat)
                          : '-'}{' '}
                        MPH
                      </div>
                    </div>

                    <div className="autoDriveRPM">
                      <img src={opRpmIcon} />
                      <div
                        className="t_pos_col"
                        style={{
                          borderBottom: 0,
                          marginRight: 0,
                          paddingLeft: 0,
                        }}
                      >
                        <span className="t_pto_speed">
                          {getPTOState(selectedTractor.heartbeat)
                            ? getRpm(
                                selectedTractor.heartbeat as TractorHeartBeat,
                                selectedTractor.tractor_model,
                              )
                            : '-'}
                        </span>
                        <span className="t_pto_lbl">
                          {getPTOState(selectedTractor.heartbeat)
                            ? ' PTO RPM'
                            : ' PTO OFF'}
                        </span>
                      </div>
                    </div>
                    <div className="autoDrivePer">
                      <img src={t_loc_rou} width="15" /> Gear{' '}
                      {selectedTractor && getGearText(heartbeat)}
                    </div>
                    <div className="autoDrivePer dflexspace">
                      {/* <img src={opHitchIcon} />{' '}
                {selectedTractor && getHeight(selectedTractor.heartbeat)}% */}
                      {getFloatImage(
                        selectedTractor?.heartbeat,
                        selectedTractor.isAvailable,
                      ) && (
                        <span
                          className={
                            ['CLASSIC', 'POSITION'].includes(
                              getFloatText(selectedTractor?.heartbeat),
                            )
                              ? 'hitch-icon-min mr2 autoDrivePerIcon'
                              : 'mr2'
                          }
                          // className="t_float_custom mr5"
                          dangerouslySetInnerHTML={{
                            __html: getFloatImage(
                              selectedTractor?.heartbeat,
                              selectedTractor.isAvailable,
                            ),
                          }}
                        />
                      )}
                      <span className="">
                        {getFloatText(selectedTractor?.heartbeat)}
                      </span>
                    </div>

                    <div className="autoDrivePer dflexspace">
                      {/* <img src={hitch_purple} width="12" />{' '} */}
                      {getFloatImage(
                        selectedTractor?.heartbeat,
                        selectedTractor.isAvailable,
                      ) && (
                        <span
                          className={
                            ['CLASSIC', 'POSITION'].includes(
                              getFloatText(selectedTractor?.heartbeat),
                            )
                              ? 'hitch-icon-min mr2 autoDrivePerIcon'
                              : 'mr2'
                          }
                          // className="t_float_custom mr5"
                          dangerouslySetInnerHTML={{
                            __html: getFloatImage(
                              selectedTractor?.heartbeat,
                              selectedTractor.isAvailable,
                            ),
                          }}
                        />
                      )}
                      {selectedTractor && getHeight(selectedTractor.heartbeat)}%
                      Height
                    </div>
                  </div>
                </div>
                <div className="autoDriveScoller">
                  <div className="autoDriveBlk1">
                    {userDetails &&
                      userDetails?.id ===
                        selectedTractor?.heartbeat?.drive_action_details
                          ?.current_operator_id && (
                        <div className="implementsMainBlk">
                          <div className="implementsBlk">
                            <img src={hitch1} alt="" />
                            <Select
                              defaultValue={selectedImplement}
                              placeholder="No Implement"
                              onChange={(id) => {
                                const [implement] = implementsList.filter(
                                  (implement: any) =>
                                    id === implement.config_implements_uuid,
                                );
                                dispatch({
                                  type: SELECTED_IMPLEMENT_NAME,
                                  payload:
                                    implement && implement.name
                                      ? implement.name
                                      : '',
                                });

                                setselectedImplement(id);
                              }}
                              value={selectedImplement}
                            >
                              <Option value={''} key={'empty'}>
                                No Implement
                              </Option>

                              {implementsList &&
                                implementsList.length > 0 &&
                                implementsList.map((implement: any) => (
                                  <>
                                    <Option
                                      value={implement.config_implements_uuid}
                                      key={implement.config_implements_uuid}
                                    >
                                      {implement.name}
                                    </Option>
                                  </>
                                ))}
                            </Select>
                          </div>
                          <CheckCircleOutlined
                            onClick={() => {
                              approveImplementSettings();
                              addImplementSettings();
                            }}
                          />
                          {selectedImplement !== '' && (
                            <SettingOutlined
                              onClick={() => setAutonomyFlag(true)}
                            />
                          )}
                        </div>
                      )}
                    {checkAutoDriveMonitorErrorCodes(
                      autoDriveMonitorErrorCodes,
                      selectedTractor?.id,
                    ) ? (
                      <div className="tractormeterBlk error_code_block">
                        <div>
                          <img src={errorCode_icon} className="mb20" />
                        </div>
                        <div className="mb20 notAvail">
                          Auto drive not available
                        </div>
                        <div className="showDtl" onClick={error_Codes_Details}>
                          Show Details
                        </div>
                      </div>
                    ) : (
                      <>
                        {tractorImplementState &&
                          tractorImplementState[selectedTractor.id] && (
                            <>
                              {checkTractorConnectedState(
                                selectedTractor?.heartbeat,
                                userDetails?.id,
                              ) && (
                                <div className="autoDriveInrBlk2 waypointTimeline">
                                  <div className="waypointBlk">
                                    <div className="waypointInrBlk">
                                      <h5 className="wayPointTitle">
                                        <img src={waypoint_command} alt="" />{' '}
                                        Add AutoDrive Step
                                      </h5>
                                      <div className="commandSteps">
                                        <Timeline>
                                          {commandsList &&
                                            commandsList[selectedTractor.id] &&
                                            commandsList[selectedTractor.id]
                                              .length > 0 &&
                                            commandsList[
                                              selectedTractor.id
                                            ].map(
                                              (command: any, index: number) => (
                                                <>
                                                  <TimeLineItem
                                                    command={command}
                                                    commantId={index + 1}
                                                    recenterXY={recenterXY}
                                                  />
                                                </>
                                              ),
                                            )}

                                          {preCommandState ===
                                            commands.GOTO_POINT && (
                                            <Timeline.Item>
                                              <div className="commandStepsFlex">
                                                <div className="searchMapBlk savedCommand">
                                                  <Input
                                                    data-testid="searchMapInputField-LiveMapFleetList"
                                                    placeholder="Search Waypoint or Select on Map"
                                                    value={` GOTO ${
                                                      selectedItemName
                                                        ? '( ' +
                                                          selectedItemName +
                                                          ' )'
                                                        : 'Point'
                                                    }`}
                                                    readOnly
                                                  />
                                                </div>
                                                {showSaveCommand && (
                                                  <>
                                                    <Tooltip title="Delete Command">
                                                      <CloseOutlined
                                                        onClick={() => {
                                                          handleResetCommand(
                                                            'GOTO',
                                                          );
                                                          dispatch({
                                                            type: SET_AUTO_VALUE,
                                                            payload: '',
                                                          });
                                                        }}
                                                      />
                                                    </Tooltip>
                                                    <CheckOutlined
                                                      onClick={() => {
                                                        addRVCommand();
                                                        dispatch({
                                                          type: SET_AUTO_VALUE,
                                                          payload: '',
                                                        });
                                                        dispatch({
                                                          type: SET_ADD_STEP,
                                                          payload: false,
                                                        });
                                                        // to show hide the start button added this code
                                                        showTractorStart[
                                                          selectedTractor.id
                                                        ] = false;
                                                        dispatch({
                                                          type: SHOW_TRACTOR_START,
                                                          payload: {
                                                            ...showTractorStart,
                                                          },
                                                        });
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              </div>
                                            </Timeline.Item>
                                          )}
                                          {preCommandState ===
                                            commands.ROW_FOLLOW && (
                                            <Timeline.Item>
                                              <div className="commandStepsFlex">
                                                <div className="searchMapBlk savedCommand">
                                                  <Input
                                                    data-testid="searchMapInputField-LiveMapFleetList"
                                                    placeholder="Search Waypoint or Select on Map"
                                                    value={
                                                      ' ' +
                                                      selectedItemName +
                                                      ' ' +
                                                      selectedVineBlockName
                                                    }
                                                    readOnly
                                                  />
                                                </div>

                                                {commandType ===
                                                  commands.ROW_FOLLOW &&
                                                  autoDriveSettings && (
                                                    <>
                                                      <Select
                                                        style={{
                                                          width: '284px',
                                                        }}
                                                        placeholder="Select sequence"
                                                        value={
                                                          setUpKeys.everyFilter
                                                        }
                                                        onChange={(value) =>
                                                          dispatch({
                                                            type: SET_SETUP_KEYS,
                                                            payload: {
                                                              everyFilter:
                                                                value,
                                                              isFirstTime:
                                                                false,
                                                            },
                                                          })
                                                        }
                                                      >
                                                        <Option value={0}>
                                                          Single Row
                                                        </Option>
                                                        <Option value={1}>
                                                          Every Row
                                                        </Option>
                                                        <Option value={2}>
                                                          Every Other{' '}
                                                        </Option>
                                                        <Option value={3}>
                                                          Every Third
                                                        </Option>
                                                        <Option value={4}>
                                                          Every Fourth
                                                        </Option>
                                                      </Select>
                                                    </>
                                                  )}
                                                <Tooltip title="Delete Command">
                                                  <CloseOutlined
                                                    onClick={() => {
                                                      handleResetCommand(
                                                        'GOTO',
                                                      );
                                                      dispatch({
                                                        type: SET_AUTO_VALUE,
                                                        payload: '',
                                                      });
                                                    }}
                                                  />
                                                </Tooltip>
                                                {commandType ===
                                                  commands.ROW_FOLLOW &&
                                                  autoDriveSettings && (
                                                    <>
                                                      <CheckOutlined
                                                        onClick={() => {
                                                          addRowFollowRVCommand();
                                                          dispatch({
                                                            type: SET_AUTO_VALUE,
                                                            payload: '',
                                                          });
                                                          dispatch({
                                                            type: SET_ADD_STEP,
                                                            payload: false,
                                                          });
                                                        }}
                                                      />
                                                    </>
                                                  )}
                                              </div>
                                            </Timeline.Item>
                                          )}
                                          {preCommandState ===
                                            commands.ROUTINE && (
                                            <Timeline.Item>
                                              <div className="commandStepsFlex">
                                                <div className="searchMapBlk savedCommand">
                                                  <Input
                                                    data-testid="searchMapInputField-LiveMapFleetList"
                                                    placeholder="Search Waypoint or Select on Map"
                                                    value={` Routine ${
                                                      selectedItemName
                                                        ? '( ' +
                                                          selectedItemName +
                                                          ' )'
                                                        : ''
                                                    }`}
                                                    readOnly
                                                  />
                                                </div>
                                                {showSaveCommand && (
                                                  <>
                                                    <Tooltip title="Delete Command">
                                                      <CloseOutlined
                                                        onClick={() => {
                                                          handleResetCommand(
                                                            'GOTO',
                                                          );
                                                          dispatch({
                                                            type: SET_AUTO_VALUE,
                                                            payload: '',
                                                          });
                                                        }}
                                                      />
                                                    </Tooltip>
                                                    <CheckOutlined
                                                      onClick={() => {
                                                        addRVRoutineCommand();
                                                        dispatch({
                                                          type: SET_AUTO_VALUE,
                                                          payload: '',
                                                        });
                                                        dispatch({
                                                          type: SET_ADD_STEP,
                                                          payload: false,
                                                        });
                                                        // to show hide the start button added this code
                                                        showTractorStart[
                                                          selectedTractor.id
                                                        ] = false;
                                                        dispatch({
                                                          type: SHOW_TRACTOR_START,
                                                          payload: {
                                                            ...showTractorStart,
                                                          },
                                                        });
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              </div>
                                            </Timeline.Item>
                                          )}
                                          {preCommandState === '' && addStep && (
                                            <Timeline.Item>
                                              <div className="commandStepsFlex">
                                                <div className="searchMapBlk">
                                                  <SearchOutlined />
                                                  <AutoComplete
                                                    options={options}
                                                    onSelect={onSelect}
                                                    onSearch={onSearch}
                                                    value={autoValue}
                                                  >
                                                    <Input
                                                      data-testid="searchMapInputField-LiveMapFleetList"
                                                      placeholder="Search Waypoint or Select on Map"
                                                      onChange={(e) =>
                                                        dispatch({
                                                          type: SET_AUTO_VALUE,
                                                          payload:
                                                            e.target.value,
                                                        })
                                                      }
                                                    />
                                                  </AutoComplete>
                                                </div>
                                                <Tooltip title="Delete Command">
                                                  <CloseOutlined
                                                    onClick={() => {
                                                      handleResetCommand(
                                                        'GOTO',
                                                      );
                                                      dispatch({
                                                        type: SET_AUTO_VALUE,
                                                        payload: '',
                                                      });
                                                    }}
                                                  />
                                                </Tooltip>
                                                {showSaveCommand && (
                                                  <>
                                                    <CheckOutlined
                                                      onClick={() => {
                                                        addRVCommand();
                                                        dispatch({
                                                          type: SET_AUTO_VALUE,
                                                          payload: '',
                                                        });
                                                        dispatch({
                                                          type: SET_ADD_STEP,
                                                          payload: false,
                                                        });
                                                        // to show hide the start button added this code
                                                        showTractorStart[
                                                          selectedTractor.id
                                                        ] = false;
                                                        dispatch({
                                                          type: SHOW_TRACTOR_START,
                                                          payload: {
                                                            ...showTractorStart,
                                                          },
                                                        });
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              </div>
                                            </Timeline.Item>
                                          )}
                                        </Timeline>
                                      </div>
                                      {!addStep && (
                                        <div
                                          className="addStepIcon"
                                          onClick={() => {
                                            dispatch({
                                              type: SET_ADD_STEP,
                                              payload: true,
                                            });
                                            handleResetCommand('GOTO');
                                          }}
                                        >
                                          <PlusCircleOutlined />
                                          <span className="addStepTxt">
                                            Add Step
                                          </span>
                                        </div>
                                      )}

                                      <div className="addStep startClearBtns">
                                        {commandsList &&
                                          commandsList[selectedTractor.id] &&
                                          commandsList[selectedTractor.id]
                                            .length > 0 && (
                                            <>
                                              {showTractorStart &&
                                              showTractorStart[
                                                selectedTractor.id
                                              ] ? (
                                                <Button
                                                  className="driveStartBtn"
                                                  onClick={startAutoDrive}
                                                >
                                                  Start
                                                </Button>
                                              ) : (
                                                <Button
                                                  className="driveStartBtn"
                                                  style={{ color: '#767676' }}
                                                  // onClick={startAutoDrive}
                                                  disabled
                                                >
                                                  Waiting for Preview
                                                </Button>
                                              )}
                                            </>
                                          )}
                                        <Button
                                          className="driveStartBtn"
                                          onClick={() => {
                                            dispatch({
                                              type: SET_SHOW_PRESENT_COMMAND,
                                              payload: '',
                                            });
                                            // to show hide the start button added this code
                                            showTractorStart[
                                              selectedTractor.id
                                            ] = false;
                                            dispatch({
                                              type: SHOW_TRACTOR_START,
                                              payload: {
                                                ...showTractorStart,
                                              },
                                            });
                                            clearAll1(
                                              selectedTractor?.heartbeat,
                                            );
                                          }}
                                        >
                                          Clear All
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <RemoteAvActions
                                showDeleteConfirm={showDeleteConfirm}
                                // handlePauseResumeStopLiveCom={
                                //   handlePauseResumeStopLiveCom
                                // }
                              />
                            </>
                          )}
                      </>
                    )}
                  </div>
                  {autoDriveInterruptMsg && <AutoDriveInterruptMsg />}
                  <div className="autoDriveCamViews">
                    {selectedTractor && selectedTractor.isAvailable && (
                      // <CameraWidget1 tractor={selectedTractor} />
                      <Cameras
                        triggerSnapshot={createSnapshots}
                        selectedTractorId={selectedTractor.id}
                        tractorName={selectedTractor.name}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {isPinned && pinnedTractors.length && (
              <div
                className="adtBottomSection"
                // style={{ display: togglePinned ? 'block' : 'none' }}
              >
                {tractorsList
                  .filter(
                    (tractor: any) =>
                      pinnedTractors.includes(tractor.id) &&
                      selectedTractor.id !== tractor.id,
                  )
                  .map((tractor: any) => {
                    return (
                      <div
                        className="adtBottomBox"
                        key={tractor.id}
                        onClick={() => {
                          dispatch({
                            type: SELECTED_TRACTORS,
                            payload: [tractor],
                          });
                        }}
                      >
                        <div
                          className="adtCloseBottom"
                          onClick={(e) => {
                            e.stopPropagation();
                            unPinnedTractors(tractor.id);
                          }}
                        ></div>
                        <div className="adTabsHeadSecBottom">
                          <div className="adtabsHeadTextBottom">
                            {tractor.name}
                          </div>
                          {/* <div className="adtStatusBottom">ROUTINES1</div> */}
                          <div
                            className={`ttractor_state  ${getStatusClass(
                              tractor.isAvailable,
                              tractor?.heartbeat?.drive_action_details
                                ?.drive_action_created_at,
                            )}`}
                          >
                            {getStatusForTractor(
                              tractor.isAvailable,
                              tractor?.heartbeat?.drive_action_details
                                ?.drive_action_created_at,
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {/* <div className="adtBottomBox">
                <div className="adtCloseBottom"></div>
                <div className="adTabsHeadSecBottom">
                  <div className="adtabsHeadTextBottom">Tractor MK4-450</div>
                  <div className="adtStatusBottom">ROUTINES2</div>
                </div>
              </div>
              <div className="adtBottomBox">
                <div className="adtCloseBottom"></div>
                <div className="adTabsHeadSecBottom">
                  <div className="adtabsHeadTextBottom">Tractor MK4-450</div>
                  <div className="adtStatusBottom">ROUTINES3</div>
                </div>
              </div>
              <div className="adtBottomBox">
                <div className="adtCloseBottom"></div>
                <div className="adTabsHeadSecBottom">
                  <div className="adtabsHeadTextBottom">Tractor MK4-450</div>
                  <div className="adtStatusBottom">ROUTINES4</div>
                </div>
              </div>
              <div className="adtBottomBox">
                <div className="adtCloseBottom"></div>
                <div className="adTabsHeadSecBottom">
                  <div className="adtabsHeadTextBottom">Tractor MK4-450</div>
                  <div className="adtStatusBottom">ROUTINES5</div>
                </div>
              </div> */}
              </div>
            )}
          </div>

          <div className="rautoDriveRightWrapper"></div>
        </div>
        {showPathModal && (
          <GuardrailWidget
            handleIgnore={() => handleIgnore('ignore_and_continue')}
          />
        )}
        {/* <div className="pathPopup">
          <img src={cone_icon} alt="" />
          <div className="path-inr-blk">
            <h6 className="pausedMsg">
              <img src={warning_icon} alt="" />
              <span>
                <strong>Paused:</strong> Obstructed path
              </span>
            </h6>
            <h4>Obstructed path</h4>
            <p>
              Tractor has come to a stop because of an obstruction in its path.
              Operation will resume when resolved.
            </p>
            <div className="continuebtn">ignore & continue</div>
          </div>
        </div> */}
        <AppLoader loader={loader} />
      </div>
      {autonomyFlag && (
        <AutonomySettingsWidget
          showModal={autonomyFlag}
          handleDetails={(details?: any) => {
            setsavedImplentDetails(details);
            setAutonomyFlag(false);
          }}
          id={selectedImplement}
          tractorId={selectedTractor.id}
          driveActionUuid={
            selectedTractor?.heartbeat.drive_action_details
              ?.current_drive_action as string
          }
          isDisabled={false}
        />
      )}
      {autonomyFlag1 && (
        // <AutonomySettingsWidget1
        //   showModal={autonomyFlag1}
        //   handleDetails={(details?: any) => {
        //     // setsavedImplentDetails(details);
        //     setAutonomyFlag1(false);
        //   }}
        //   id={selectedImplement}
        // />
        <AutonomySettingsWidget
          showModal={autonomyFlag1}
          handleDetails={(details?: any) => {
            // setsavedImplentDetails(details);
            setAutonomyFlag1(false);
          }}
          id={selectedImplement}
          tractorId={selectedTractor.id}
          driveActionUuid={
            selectedTractor?.heartbeat.drive_action_details
              ?.current_drive_action as string
          }
          isDisabled={true}
        />
      )}
      {checkAutoDriveMonitorErrorCodes(
        autoDriveMonitorErrorCodes,
        selectedTractor?.id,
      ) &&
        errorCodeFlag && (
          <Errorcodepopup showModal={errorCodeFlag} closeModel={closeModel} />
        )}
      {isHaveErrors(autoDriveError) && (
        <AutoDriveErrorCodePopup
          showModal={autoDriveError?.errors?.length > 0 ? true : false}
        />
      )}
      {showTractorLockUnLock && (
        <LockUnLockReason
          showModal={showTractorLockUnLock}
          closeModel={(status?: boolean) => {
            setShowTractorLockUnLock(false);
            status && getTractorDetails(selectedTractor.id);
          }}
          tractorId={selectedTractor.id}
          status={tractorLockUnLock}
          driveActionUuid={
            selectedTractor?.heartbeat?.drive_action_details
              ?.current_drive_action
          }
        />
      )}
    </>
  );
};

export default RemoteDriveDetails;
