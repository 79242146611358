import React, { useContext, useEffect, useState } from 'react';
import { Modal, notification, Radio, Space, Spin } from 'antd';
import { downloadMapMakerFeature } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';

interface Props {
  showModal: boolean;
  handleCancel: () => void;
}

export const FileDownload: React.FC<Props> = ({
  showModal,
  handleCancel,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [selectedFeatureItem, setSelectedFeatureItem] = useState<any>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (selectedFeatureItem) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, selectedFeatureItem]);

  const CsvFileDownload = async () => {
    try {
      setLoader(true);
      const records: any = await downloadMapMakerFeature(
        userDetails.organization.api_url,
        userDetails.organization.id,
        userDetails.organization.farm.id,
        selectedFeatureItem,
      );

      const fileName = selectedFeatureItem || 'catrography';
      await saveAs(records.data, `${fileName}.csv`);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
    handleCancel();
  };

  return (
    <Modal
      className="commonPopup addPolygon popupStyles"
      title="Download File"
      centered
      width={'50vw'}
      visible={showModal}
      onCancel={handleCancel}
      okText="Download"
      onOk={CsvFileDownload}
      okButtonProps={{
        className: 'btnSave Button',
        disabled: isDisabled,
      }}
      data-testid="downloadButton-FileDownload"
      cancelButtonProps={{
        className: 'btnCancel Button',
      }}
    >
      <div className="cfqRadioBtn">
        <Radio.Group onChange={(e) => setSelectedFeatureItem(e.target.value)}>
          <Space direction="vertical">
            <Radio
              value="Waypoints"
              data-testid="wayPointDownload-FileDownload"
            >
              {' '}
              Waypoints
            </Radio>
            <Radio value="Polygons" data-testid="polygonDownload-FileDownload">
              Polygons
            </Radio>
            <Radio value="Vines" data-testid="vinesDownload-FileDownload">
              Vines
            </Radio>
            <Radio
              value="vine_rows"
              data-testid="vinerowsDownload-FileDownload"
            >
              {' '}
              Vine Rows
            </Radio>
            <Radio
              value="vine_blocks"
              data-testid="vineblockDownload-FileDownload"
            >
              {' '}
              Vine Blocks
            </Radio>
            <Radio
              value="vine_lane"
              data-testid="vineLaneDownload-FileDownload"
            >
              {' '}
              Vine Lanes
            </Radio>
          </Space>
        </Radio.Group>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default FileDownload;
