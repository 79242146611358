/* eslint-disable no-console */
import { Button, Modal } from 'antd';
import React, { useContext } from 'react';
import errorCode_icon from '../../assets/images/errorCode_icon.svg';
import { error_message } from '../../constants/constant';
import './autodrivelist.css';
import { RemoteDriveAppCtx } from './RemoteDriveContext';

interface Props {
  showModal?: boolean;
  closeModel?: any;
}
export const Errorcodepopup: React.FC<Props> = ({
  showModal,
  closeModel,
}: Props) => {
  const { RDReducer } = useContext(RemoteDriveAppCtx);
  const [state] = RDReducer;
  const { autoDriveMonitorErrorCodes } = state;
  return (
    <>
      <Modal
        className="commonPopup errorCodePopup"
        title=""
        visible={showModal}
        onCancel={closeModel}
        width={650}
        closable={false}
        maskClosable={false}
        footer={[
          <>
            <Button key="back" className="errorPopupBtn" onClick={closeModel}>
              OK
            </Button>
          </>,
        ]}
      >
        <div className="errorCodepopupBlock">
          <div className="error_code_block mb40">
            <div>
              <img src={errorCode_icon} className="mb20" height="48" />
            </div>
            <div className="mb20 notAvail">Auto drive not available</div>
          </div>
          <div className="errorCodeList">
            <ul>
              {autoDriveMonitorErrorCodes &&
                autoDriveMonitorErrorCodes.errors &&
                autoDriveMonitorErrorCodes.errors.map((error: any) => (
                  <>
                    <li className="errorListItem">
                      <span className="errorCodeNo">{error}:</span>
                      <span className="errorCodeName">
                        {error_message[error] && error_message[error]}
                      </span>
                    </li>
                  </>
                ))}
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Errorcodepopup;
