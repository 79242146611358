/* eslint-disable no-console */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import { SET_TIME_LEFT, SET_TOGGLE_TIMER } from './actions';

interface Props {
  seconds: number;
  // reset: () => void;
}
const Timer: React.FC<Props> = ({ seconds }) => {
  // initialize timeLeft with the seconds prop
  const [timeLeft, setTimeLeft] = useState(seconds);
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  const intervalId = useRef<any>();
  const { toggleTimer } = autoDriveState;
  useEffect(() => {
    autoDriveDispatch({
      type: SET_TIME_LEFT,
      payload: timeLeft,
    });
  }, [timeLeft]);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) {
      autoDriveDispatch({
        type: SET_TOGGLE_TIMER,
        payload: { status: !toggleTimer.status, from: 'Event' },
      });
      return;
    }
    // save intervalId to clear the interval when the
    // component re-renders
    intervalId.current = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId.current);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const min = Math.floor(timeLeft / 60);
  const f = Number((timeLeft / 60).toFixed(2)) % 1;
  const sec = Math.round(f * 60);

  return (
    <>{String(sec).length == 1 ? `0${min} : 0${sec}` : `0${min} : ${sec}`}</>
  );
};

export default Timer;
