/* eslint-disable no-console */
import { Input, InputNumber, Modal, notification, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { editWayPointData, saveWayPointData } from '../../constants/Api';
import { WaypointPayloadTypes } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean) => void;
  wayPointPayload: WaypointPayloadTypes;
}
export const Waypoint: React.FC<Props> = ({
  showModal,
  handleCancel,
  wayPointPayload,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [name, setName] = useState<string>('');
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (wayPointPayload) {
      if (wayPointPayload.name) {
        setName(wayPointPayload.name);
      }
      if (wayPointPayload.x) {
        setX(wayPointPayload.x);
      }
      if (wayPointPayload.y) {
        setY(wayPointPayload.y);
      }
    }
  }, [wayPointPayload]);

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      name &&
      name.trim().length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, name]);

  const saveVineRows = async () => {
    setLoader(true);
    const payload = {
      name: name.trim(),
      y,
      x,
    };
    setName(name.trim());
    try {
      const { organization } = userDetails;
      if (wayPointPayload.action === 'ADD') {
        const response = await saveWayPointData(
          organization.api_url,
          organization.farm.id,
          payload,
        );
        if (response && response.msg) {
          handleCancel(true);
          notification.success({
            message: response.msg,
          });
        }
      }
      if (wayPointPayload.action === 'EDIT' && wayPointPayload.waypointId) {
        const resp = await editWayPointData(
          organization.api_url,
          organization.farm.id,
          wayPointPayload.waypointId,
          payload,
        );
        if (resp && resp.msg) {
          handleCancel(true);
          notification.success({
            message: resp.msg,
          });
        }
      }
    } catch (error: any) {
      notification.error({
        message: error?.message,
      });
    } finally {
      setLoader(false);
    }
  };
  return (
    <Modal
      className="commonPopup addPolygon"
      title="Waypoint"
      closable={false}
      centered
      width={'60vw'}
      visible={showModal}
      onCancel={() => handleCancel()}
      okText="SAVE"
      onOk={saveVineRows}
      okButtonProps={{
        className: 'btnSave ',
        disabled: isDisabled,
      }}
      data-testid="savebutton-Waypoint"
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Name <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            data-testid="wayPointInput-Waypoint"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="formCol"></div>
      </div>
      <div className="formRow ">
        <div className="formCol">
          <label className="formLabel">
            X <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            className="commInput w100"
            value={x}
            onChange={(value) => setX(value)}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            Y <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            className="commInput w100"
            value={y}
            onChange={(value) => setY(value)}
          />
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default Waypoint;
