import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getFullName } from '../../../constants/Common';
import { AnalyticsContext } from '../AnalyticsContext';
import { getJgDetails, getJumpAndGoDetails } from '../../../constants/Api';
import { AllDayContent } from '../../../constants/types';
import { ApplicationContext } from '../../../context/AppContext';
import AnalyticChart from '../common/AnalyticChart';
import AnalyticStats from '../common/AnalyticStats';
import { SET_SELECTED_JAG } from '../action';
import AnalyticMap from '../Map/AnalyticMap';
import loading_logo from '../../../assets/images/loading_logo.gif';
import { SET_JAG_DACTION, SET_LAST_TAB_INDEX } from '../../../context/actions';
import '../Database/database.css';
import AppLoader from '../../common/AppLoader';

interface Props {
  driveAction?: any;
  setDriveAction?: (state: any) => void;
  setShowLiveMap?: (state: boolean) => void;
}

const AnalyticsDetails: React.FC<Props> = ({
  driveAction,
  setShowLiveMap,
  setDriveAction,
}) => {
  const { analyticReducer } = useContext(AnalyticsContext);
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [, AppDispatch] = APPReducer;
  const [state, dispatch] = analyticReducer;
  const [allDayOperation, setAllDayOperation] =
    useState<AllDayContent | null>();
  const { JAG } = state;
  const [showLoader, setShowLoader] = useState<boolean>(true);

  const getAllDayContent = async (driveAction: any) => {
    setAllDayOperation(null);
    setShowLoader(true);
    try {
      const data = await getJgDetails(
        userDetails.organization.api_url,
        driveAction,
      );
      setAllDayOperation(data);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: SET_SELECTED_JAG,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    if (userDetails && userDetails.organization && JAG) {
      const { drive_action_uuid } = JAG;
      getAllDayContent(drive_action_uuid);
    }
  }, [JAG]);

  useEffect(() => {
    const init = async () => {
      const data = await getJumpAndGoDetails(
        userDetails.organization.api_url,
        driveAction.drive_action_uuid,
      );
      dispatch({
        type: SET_SELECTED_JAG,
        payload: data,
      });
    };
    if (userDetails && userDetails.organization && driveAction) init();
  }, [driveAction]);

  if (!JAG)
    return (
      <div className="jag-loader">
        <div className="loader">
          <img style={{ width: '40px', height: '40px' }} src={loading_logo} />
        </div>
      </div>
    );

  return (
    <>
      <div className="personnelSec fileUploadBack sprayOper">
        <ArrowLeftOutlined
          onClick={() => {
            setShowLiveMap && setShowLiveMap(false);
            setDriveAction && setDriveAction(null);
            dispatch({
              type: SET_SELECTED_JAG,
              payload: null,
            });
            if (driveAction) {
              AppDispatch({
                type: SET_LAST_TAB_INDEX,
                payload: {
                  catlog: 'Analytics',
                  tab: 'Library',
                },
              });
              AppDispatch({
                type: SET_JAG_DACTION,
                payload: null,
              });
            }
          }}
        />
      </div>
      <div className="sprayContainer spchvh tractorOperDtls">
        <div className="flexColumn tractorOperDtls">
          <div className="pieChartStyle mb10 piechartBlk1">
            <div className="sprayTxt">Operation Analytics</div>

            <AnalyticChart allDayOperation={allDayOperation} />

            <div className="sprayoperhrs">
              <div style={{ marginTop: '10px' }}>
                <div className="operationalHours mb5">
                  <div className="totalHours">Tractor</div>
                  <div className="hrMinTxt">{JAG['tractor']['name']}</div>
                </div>

                <div className="operationalHours mb5">
                  <div className="totalHours">Operator</div>
                  <div className="hrMinTxt">{getFullName(JAG['operator'])}</div>
                </div>
                <div className="operationalHours mb5">
                  <div className="totalHours">Operation Type</div>
                  <div className="hrMinTxt">
                    {allDayOperation?.operation_type}
                  </div>
                </div>
                <div className="operationalHours mb5">
                  <div className="totalHours">Start Time</div>
                  <div className="hrMinTxt">
                    {moment(JAG['drive_action_start_date_time']).format(
                      'Do MMM, h:mm A',
                    )}
                  </div>
                </div>
                <div className="operationalHours mb5">
                  <div className="totalHours">Finish Time</div>
                  <div className="hrMinTxt">
                    {moment(JAG['drive_action_finish_date_time']).format(
                      'Do MMM, h:mm A',
                    )}
                  </div>
                </div>
              </div>
              <div className="line" />
              <AnalyticStats analytics={allDayOperation} />
            </div>

            <AppLoader loader={showLoader} />
          </div>
        </div>

        <div className="sparyMapContainer">
          <AnalyticMap driveAction={driveAction} />
        </div>
      </div>
    </>
  );
};

export default AnalyticsDetails;
