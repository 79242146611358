import React from 'react';
import { Route, Switch } from 'react-router';
import Login from '../components/auth/Login';
import Authentication from '../components/errors/Authentication';
import Authorization from '../components/errors/Authorization';
import InternalServer from '../components/errors/InternalServer';
import AppLayout from '../components/layout/AppLayout';
import RoutesConstants from './RoutesConstant';
import CameraView from '../components/map/CameraView';
import AllCameraView from '../components/map/AllCameraView';
import VideoPop from '../components/recordedVideo/VideoPop';
import SomethingWentWrong from '../components/errors/SomethingWentWrong';
import ResetPassword from '../components/auth/ResetPassword';
import ResetPwSuccess from '../components/auth/ResetPwSuccess';
import ForgotPassword from '../components/auth/ForgotPassword';
import ForgotPwSuccess from '../components/auth/ForgotPwSuccess';
import Remotedrive from '../components/remotedrive/Remotedrive';
import ChangeAdminEmail from '../components/auth/ChangeAdminEmail';
import AdminChangeSuccess from '../components/auth/AdminChangeSuccess';
import LoginWing from '../components/auth/LoginWing';
import { checkAlphaFeature } from '../constants/Common';

const Root: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path={RoutesConstants.Login}
        component={checkAlphaFeature('wingspanai') ? LoginWing : Login}
      ></Route>
      <Route
        exact
        path={RoutesConstants.Exception[401]}
        component={Authentication}
      />
      <Route
        exact
        path={RoutesConstants.Exception[403]}
        component={Authorization}
      />
      <Route
        exact
        path={RoutesConstants.Exception[500]}
        component={InternalServer}
      />
      <Route
        exact
        path={`${RoutesConstants.CameraView}/:tractorid/stream/:tractorName/:view/:showStats`}
        component={CameraView}
      />
      <Route
        exact
        path={`${RoutesConstants.AllCameraView}/:tractorid/stream/:tractorName/:view/:showStats`}
        component={AllCameraView}
      />
      <Route
        exact
        path={`${RoutesConstants.RemoteDrive}/:tractorid/command/:tractorName/:type/:param`}
        component={Remotedrive}
      />
      <Route
        exact
        path={`${RoutesConstants.ForgotPassword}`}
        component={ResetPassword}
      />

      <Route
        exact
        path={`${RoutesConstants.ResetPwSuccess}`}
        component={ResetPwSuccess}
      />

      <Route
        exact
        path={`${RoutesConstants.EmailConfirm}`}
        component={ForgotPassword}
      />

      <Route
        exact
        path={`${RoutesConstants.ForgotPwSuccess}`}
        component={ForgotPwSuccess}
      />

      <Route exact path={`${RoutesConstants.VideoPop}`} component={VideoPop} />
      <Route
        exact
        path={RoutesConstants.SomethingWentWrong}
        component={SomethingWentWrong}
      />
      <Route
        exact
        path={`${RoutesConstants.ChangeAdminEmail}`}
        component={ChangeAdminEmail}
      />

      <Route
        exact
        path={`${RoutesConstants.AdminChangeSuccess}`}
        component={AdminChangeSuccess}
      />

      <Route component={AppLayout}></Route>
    </Switch>
  );
};

export default Root;
