import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import './style.css';
import rowWayPointer from '../../../assets/images/rowWayPointer.svg';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ApplicationContext } from '../../../context/AppContext';
import { getGroundZero } from '../../../constants/Api';

import { View } from 'ol';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import * as proj from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import OLMap from 'ol/Map';
import '../../map/style.css';
import BaseStationIcon from '../../../assets/images/basestation_green.png';
import { notification } from 'antd';
import RoutesConstants from '../../../routes/RoutesConstant';
import {
  pathColorConstants,
  polygonHoverLabel,
  priority,
} from '../../../constants/Common';
import LineString from 'ol/geom/LineString';
import { Coordinate } from 'ol/coordinate';
import { getPolygons, PolygonReturns } from '../../map/Polygon';
import { TractorHeartBeat } from '../../../constants/types';
import CameraGrid1 from '../../map/CameraGrid1';
import { useHistory } from 'react-router';

const tractorId = 14;
const tractorName = 'PS9';

const VideoFeed: React.FC = () => {
  //   const mixpanel = useMixpanel();
  const mapElement: any = useRef();
  const mapSmallElement: any = useRef();
  const movTractRef = useRef<Feature<any> | null>(null);
  const movTractPathRef = useRef<Feature<any> | null>(null);
  const mapRef = useRef<OLMap | null>(null);
  const pathLayerRef = useRef<VectorLayer<any>>();
  const { userDetails } = useContext(ApplicationContext);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [base, setBase] = useState<[number, number]>([0, 0]);
  // const [heartBeat, setheartBeat] = useState<TractorHeartBeat>();
  const [openedView, setOpenedView] = useState(1);
  // const [previewPath, setPreviewPath] = useState<string>('');
  const greenPolygonsLayerRef = useRef<VectorLayer<any>>();
  const whitePolygonsLayerRef = useRef<VectorLayer<any>>();
  const grayPolygonsLayerRef = useRef<VectorLayer<any>>();
  const fieldFeatureItem = useRef<any>();
  const centreEnabledRef = useRef(true);
  // const tractorPaths = useRef<Coordinate[]>([]);
  // const [layout, setLayout] = useState<number>(1);
  const { push } = useHistory();

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      document.title = `${tractorName} Remote Drive`;
      initializeMap();
    }
  }, [userDetails]);

  /**
   * Polygons initialization Starts Here
   */
  useEffect(() => {
    if (mapInitialized) {
      setTimeout(async () => {
        const {
          fieldFeature,
          greenLayer,
          whiteLayer,
          grayLayer,
        }: PolygonReturns = await getPolygons(userDetails, base);
        // setPolygons(list);
        fieldFeatureItem.current = fieldFeature;
        if (whiteLayer) {
          mapRef.current?.addLayer(whiteLayer);
          whitePolygonsLayerRef.current = whiteLayer;
        }
        if (greenLayer) {
          mapRef.current?.addLayer(greenLayer);
          greenPolygonsLayerRef.current = greenLayer;
        }
        if (grayLayer) {
          mapRef.current?.addLayer(grayLayer);
          grayPolygonsLayerRef.current = grayLayer;
        }
        polygonHoverLabel(mapRef.current);
      }, 10);
    }
  }, [userDetails, mapInitialized, base]);

  //  mapInitialization start here
  const initializeMap = async () => {
    try {
      const { organization } = userDetails;
      const { latitude, longitude } = await getGroundZero(
        organization.api_url,
        organization.farm.id,
      );
      setBase([latitude, longitude]);

      const baseLocation = new Feature({
        geometry: new Point(proj.fromLonLat([longitude, latitude])),
      });
      baseLocation.set('name', 'Ground zero');
      //  set id 1 for base station recenter
      baseLocation.setId(1);
      const style = new Style({
        image: new Icon({
          src: BaseStationIcon,
          scale: 0.1,
        }),
        zIndex: 100,
      });
      baseLocation.setStyle(style);

      const tracLocation = new Feature({
        geometry: new Point(proj.fromLonLat([longitude, latitude])),
      });

      tracLocation.setStyle(
        new Style({
          image: new Icon({
            src: rowWayPointer,
          }),
          zIndex: priority.TRACTOR,
        }),
      );

      const tractorStyle = new Style({
        // image: new CircleStyle({
        //   radius: 3,
        //   fill: new Fill({
        //     color: pathColorConstants.DEFAULT,
        //   }),
        // }),
        stroke: new Stroke({
          color: pathColorConstants.REMOTE_AV,
          width: 9,
        }),
        fill: new Fill({
          color: pathColorConstants.REMOTE_AV,
        }),
        zIndex: priority.PATH,
      });
      const tracPathLocation = new Feature({
        geometry: new LineString([proj.fromLonLat([longitude, latitude])]),
      });
      tracPathLocation.setStyle(tractorStyle);

      const initialFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [baseLocation, tracLocation, tracPathLocation],
        }),
        zIndex: Infinity,
      });

      const vectorLayer = new TileLayer({
        source: new OSM(),
        visible: false,
      });

      const satelliteLayer = new TileLayer({
        source: new XYZ({
          url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga',
          cacheSize: 1000,
        }),
        visible: true,
      });
      // live command path layer
      const initalPathLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: false,
        zIndex: priority.PREVIEWPATH,
      });

      // create map
      const initialMap = new OLMap({
        target: mapElement.current,
        layers: [
          satelliteLayer,
          vectorLayer,
          initialFeaturesLayer,
          initalPathLayer,
        ],
        controls: [],
        view: new View({
          projection: 'EPSG:3857',
          center: proj.transform(
            [longitude, latitude],
            'EPSG:4326',
            'EPSG:3857',
          ),
          zoom: 17,
          maxZoom: 25,
        }),
      });
      mapRef.current = initialMap;
      movTractRef.current = tracLocation;
      movTractPathRef.current = tracPathLocation;
      pathLayerRef.current = initalPathLayer;
      setMapInitialized(true);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  const handleZoom = (zoomType: string) => {
    if (mapRef.current) {
      const view = mapRef.current.getView();
      const zoom = view.getZoom();

      if (zoom) {
        if (zoomType === 'zoomIn') {
          view.setZoom(zoom + 1);
          //   mixpanel.track('Live Map', {
          //     event: `Map Zoom In ${zoom + 1}`,
          //   });
        } else {
          view.setZoom(zoom - 1);
          //   mixpanel.track('Live Map', {
          //     event: `Map Zoom Out ${zoom - 1}`,
          //   });
        }
      }
    }
  };

  // useEffect(() => {
  //   if (userDetails && userDetails.organization && mapInitialized) {
  //     const { organization } = userDetails;
  //     const mqttClient = getClient(
  //       organization.mqtt_ip,
  //       organization.mqtt_port,
  //     );
  //     mqttClient.on('connect', () => {
  //       mqttClientRef.current = mqttClient;
  //       mqttClient.subscribe([
  //         `localization_streaming_${tractorId}`,
  //         `planning_manager_streaming_${tractorId}`,
  //         `guardrails_ui_notification_${tractorId}`,
  //         `guardrail_triggered_${tractorId}`,
  //         `remoteav_preview_${tractorId}`,
  //         heartBeatTopic,
  //       ]);
  //     });

  //     mqttClient.on('message', (topic: string, message: any) => {
  //       if (topic === `localization_streaming_${tractorId}`) {
  //         const tractor = new TextDecoder('utf-8').decode(message);
  //         const data = tractor.split(',').map((item) => {
  //           return parseFloat(item);
  //         });

  //         const latAndLong: [number, number] = getLatitudeAndLongitude(
  //           [data[1], data[0]],
  //           base,
  //         );

  //         movTractRef.current?.setGeometry(
  //           new Point(proj.fromLonLat([latAndLong[1], latAndLong[0]])),
  //         );

  //         const latlan: Coordinate = proj.fromLonLat([
  //           latAndLong[1],
  //           latAndLong[0],
  //         ]);
  //         setTractorPoints(latlan);

  //         mapRef.current
  //           ?.getView()
  //           .setRotation(((data[5] - 90) * Math.PI) / 180);

  //         if (centreEnabledRef.current) {
  //           reCenter([data[1], data[0]]);
  //         }
  //         //   setVelocity(Number((data[6] * 2.237).toFixed(1).replaceAll('-', '')));
  //       }

  //       if (topic === `planning_manager_streaming_${tractorId}`) {
  //         const messageData = message.toString().trim();
  //         //   setPlanEvent({
  //         //     planner_name: messageData.split(',')[0],
  //         //     planner_status: messageData.split(',')[1],
  //         //   });
  //       }

  //       // if (topic === `guardrail_triggered_${tractorId}`) {
  //       //   const pathData = message.toString().split(',');
  //       //   let guardRail = pathData[0];
  //       //   guardRail = guardRail.replace(/_/g, ' ');
  //       //   const triggerState = pathData.length === 7 ? pathData[6] : '';
  //       //   guardRailsCheck(guardRail, triggerState.trim());
  //       // }

  //       if (topic === `guardrails_ui_notification_${tractorId}`) {
  //         const status = message.toString();
  //         if (status === 'False') {
  //           setShowPathModal(false);
  //         }
  //       }

  //       if (topic === heartBeatTopic) {
  //         const heartBeat: TractorHeartBeat = JSON.parse(message.toString());
  //         if (
  //           heartBeat &&
  //           heartBeat.organization_id &&
  //           userDetails.organization_id &&
  //           heartBeat.organization_id === userDetails.organization_id &&
  //           Number(tractorId) === heartBeat.tractor_id
  //         ) {
  //           setheartBeat(heartBeat);
  //         }
  //       }
  //       if (topic === `remoteav_preview_${tractorId}`) {
  //         setPreviewPath('');
  //         setPreviewPath(message.toString());
  //       }
  //     });
  //   }

  //   return () => {
  //     mqttClientRef.current?.unsubscribe([
  //       `localization_streaming_${tractorId}`,
  //       `planning_manager_streaming_${tractorId}`,
  //       `guardrails_ui_notification_${tractorId}`,
  //       `guardrail_triggered_${tractorId}`,
  //       `remoteav_preview_${tractorId}`,
  //       heartBeatTopic,
  //     ]);
  //     mqttClientRef.current?.end();
  //   };
  // }, [userDetails, mapInitialized]);

  // useEffect(() => {
  //   if (previewPath && previewPath.trim().length > 0) {
  //     handlePreview(previewPath);
  //   }
  // }, [previewPath]);

  // const reCenter = (location: [number, number]) => {
  //   if (mapRef.current) {
  //     const latAndLong: [number, number] = getLatitudeAndLongitude(
  //       location,
  //       base,
  //     );

  //     mapRef.current
  //       .getView()
  //       .setCenter(proj.fromLonLat([latAndLong[1], latAndLong[0]]));
  //   }
  // };

  // useEffect(() => {
  //   if (mapInitialized) {
  //     if (type === 'routine') {
  //       getRoutinePoints();
  //     } else {
  //       showPreviewPath();
  //     }
  //   }
  // }, [mapInitialized]);

  // const showPreviewPath = () => {
  //   const { organization } = userDetails;
  //   getPreviewPath(organization.api_url, param)
  //     .then((response: { data: any }) => {
  //       handlePreview(response.data);
  //     })
  //     .catch((err) => {
  //       notification.error({
  //         message: err.response?.data.error.message,
  //       });
  //     });
  // };
  // const handlePreview = (data: string) => {
  //   pathLayerRef.current?.getSource().clear();
  //   const latlongTextList = data.split('|');
  //   const coordinates: Coordinate[] = [];
  //   latlongTextList.forEach((record: any) => {
  //     const latlong = record?.split(',');
  //     coordinates.push(proj.fromLonLat([latlong[1], latlong[0]]));
  //   });

  //   const tractorLocationFeature: Feature<Geometry> = new Feature();
  //   tractorLocationFeature.setGeometry(new LineString(coordinates));

  //   const style = [
  //     new Style({
  //       stroke: new Stroke({
  //         color: 'rgba(194, 199, 216, 1)',
  //         width: 7,
  //       }),
  //       zIndex: 100,
  //     }),
  //     new Style({
  //       stroke: new Stroke({
  //         color: 'rgba(194, 199, 216, 1)',
  //         width: 7,
  //       }),
  //     }),
  //   ];

  //   tractorLocationFeature.setStyle(style);
  //   pathLayerRef.current?.setSource(
  //     new VectorSource({
  //       features: [tractorLocationFeature],
  //     }),
  //   );
  //   pathLayerRef.current?.setVisible(true);
  // };

  // const getRoutinePoints = async () => {
  //   try {
  //     const routinePoints = await getRoutePoints(
  //       userDetails.organization.api_url,
  //       userDetails.organization.farm.id,
  //       param.split(':')[0] as any,
  //     );

  //     if (routinePoints && routinePoints.length > 0) {
  //       const coordinates: any = [];
  //       routinePoints.forEach((element: any) => {
  //         const latAndLong = getLatitudeAndLongitude(
  //           [element.y, element.x],
  //           base,
  //         );

  //         coordinates.push(proj.fromLonLat([latAndLong[1], latAndLong[0]]));
  //       });

  //       // head start here
  //       const headPointData = routinePoints[0];
  //       const blueDotStyleA = new Style({
  //         image: new Icon({
  //           src: playIcon,
  //           scale: 0.5,
  //           rotation: ((90 - headPointData.bearing) * Math.PI) / 180,
  //         }),
  //       });
  //       const pointLocation = new Feature({
  //         geometry: new Point(coordinates[0]),
  //       });
  //       pointLocation.setStyle(blueDotStyleA);
  //       // head ends here

  //       const layerLines = new Feature({
  //         geometry: new LineString(coordinates),
  //         name: 'Line',
  //       });

  //       const lineLayers = new VectorLayer({
  //         source: new VectorSource({
  //           features: [layerLines, pointLocation],
  //         }),
  //         style: new Style({
  //           stroke: new Stroke({ color: '#98DCE0', width: 9 }),
  //         }),
  //       });

  //       mapRef.current?.addLayer(lineLayers);
  //       if (mapRef.current) {
  //         displayRecenterViewPortSetUp(
  //           layerLines as Feature<Geometry>,
  //           mapRef.current,
  //           movTractRef.current as Feature<Geometry>,
  //         );
  //       }
  //     }
  //   } catch (error: any) {
  //     notification.error({
  //       message: 'No Data Found',
  //     });
  //   }
  // };

  // const setTractorPoints = (points: Coordinate) => {
  //   const existingPath = tractorPaths.current;
  //   if (existingPath) {
  //     let path = existingPath;
  //     if (path) {
  //       if (path.length > 5000) {
  //         path = path.splice(2500);
  //       }
  //       path?.push(points);
  //       tractorPaths.current = path;
  //     } else {
  //       tractorPaths.current = [points];
  //     }
  //   } else {
  //     tractorPaths.current = [points];
  //   }
  // };

  // useEffect(() => {
  //   if (userDetails && userDetails.organization && mapInitialized) {
  //     const interval = setInterval(() => {
  //       drawTractorPath();
  //     }, 1000);
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // }, [userDetails, mapInitialized]);

  // const drawTractorPath = () => {
  //   const existingPath = tractorPaths.current;
  //   movTractPathRef?.current?.setGeometry(new LineString(existingPath));
  // };

  useEffect(() => {
    if (mapInitialized) {
      if (openedView === 1) {
        mapRef?.current?.setTarget(mapElement.current);
      } else {
        mapRef?.current?.setTarget(mapSmallElement.current);
      }
      mapRef.current?.updateSize();
    }
  }, [openedView, mapInitialized]);

  const cameraMap: any = {
    front: 'Front',
    rear: 'Rear',
    front_left: 'Front Left',
    front_right: 'Front Right',
    pto: 'Hitch',
  };

  const frameHTML: any = useCallback(
    (view: string) => (
      <>
        <h1 className="iframe-title"> {cameraMap[view]} </h1>
        <iframe
          frameBorder="0"
          className="responsive-iframe"
          src={`${origin}/CameraView/${tractorId}/stream/${tractorName}/${view}/false`}
        ></iframe>
      </>
    ),
    [],
  );

  const goToFungicideSpraying = () => {
    push(RoutesConstants.fungicideSpraying);
  };

  return (
    <>
      <div className="videoFeed">
        <div className="header">
          <div className="personnelSec">
            <ArrowLeftOutlined onClick={goToFungicideSpraying} />
          </div>
          <div>
            <h2>Fungicide Spraying Video Feed</h2>
            <h4>
              Control video playback by selecting a point on the map or sliding
              along the seek bar
            </h4>
          </div>
        </div>
        <div className="cameraContainer" style={{ display: 'block' }}>
          <div className="cameraWrapper cameraBackground">
            <CameraGrid1
              frameHTML={frameHTML}
              mapSmallElement={mapSmallElement}
              openedView={openedView}
              mapElement={mapElement}
              centreEnabledRef={centreEnabledRef}
              handleZoom={handleZoom}
              layout={1}
            />
          </div>
          {/* <div className="cameraControls">
            <img src={playerIcon} alt="" />
            <div className="layoutButtonSec">
              <button
                className={
                  layout === 1
                    ? 'layoutButton layout1 lsel'
                    : 'layoutButton layout1'
                }
                onClick={() => setLayout(1)}
              ></button>

              <button
                className={
                  layout === 3
                    ? 'layoutButton layout2 lsel'
                    : 'layoutButton layout2'
                }
                onClick={() => setLayout(3)}
              ></button>
              <button
                className={
                  layout === 2
                    ? 'layoutButton layout3 lsel'
                    : 'layoutButton layout3'
                }
                onClick={() => setLayout(2)}
              ></button>
            </div>
          </div> */}
        </div>
        <div className="remotecamerasBlk"></div>
      </div>
    </>
  );
};

export default VideoFeed;
