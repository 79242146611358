import { message, Upload, notification } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useState } from 'react';
import './style.css';
import EditIcon from '../../assets/images/EditIcon.svg';
import { fileTypesAccepted, checkForFileTypes } from '../../constants/Common';

interface Props {
  setFileList: (page: any) => void;
}
const ImageUpload: React.FC<Props> = ({ setFileList }: Props) => {
  const [fileAcceptable, setFileAcceptable] = useState<boolean>(false);
  const onChange = ({ fileList: newFileList }: any) => {
    if (fileAcceptable) {
      setTimeout(function () {
        setFileList(newFileList[newFileList.length - 1]);
      }, 300);
    }
  };
  const beforeUpload = (file: any) => {
    const fileTypeAccepted = checkForFileTypes(file?.type);
    setFileAcceptable(fileTypeAccepted);
    if (!fileTypeAccepted) {
      notification.error({
        message: 'This file type is not accepted',
      });
      return false;
    } else {
      return true;
    }
  };
  return (
    <ImgCrop beforeCrop={beforeUpload} rotate>
      <Upload
        accept={fileTypesAccepted}
        beforeUpload={beforeUpload}
        listType="picture-card"
        data-testid="imageUpload-ImageUpload"
        onChange={onChange}
      >
        <label htmlFor="upload-button" className="editImgs">
          <img src={EditIcon} alt="edit" />
        </label>
      </Upload>
    </ImgCrop>
  );
};

export default ImageUpload;
