import React, { useEffect, useState, useContext } from 'react';
import { notification } from 'antd';
import jwt_decode from 'jwt-decode';
import { ApplicationContext } from '../../context/AppContext';
import { APP_TYPE, REMOTE_ACCESS_GRANT } from '../../constants/constant';
import {
  getTractorsData,
  grantOrgRemoteAccess,
  grantTractorRemoteAccess,
} from '../../constants/Api';
import logoHorizontalWhite from '../../assets/images/logo_horizontal_white_rgb.png';
import ragImg from '../../assets/images/ragImg.svg';
import './style.css';
import { useHistory, useLocation } from 'react-router-dom';
import RoutesConstants from '../../routes/RoutesConstant';
import { delay } from '../../constants/Common';

const RemoteAccess: React.FC = () => {
  const [status, setStatus] = useState(0);
  const [tractorName, setTractorName] = useState('');
  const { userDetails } = useContext(ApplicationContext);
  const location = useLocation();
  const history = useHistory();
  const { search } = location;

  const handleRemoteAccessToken = async () => {
    const token1 = localStorage.getItem(REMOTE_ACCESS_GRANT);
    const appType1 = localStorage.getItem(APP_TYPE);
    const token = new URLSearchParams(search).get('remote_access_token');
    const appType = new URLSearchParams(search).get('app_type');
    const remote_access_token = token1 || token;

    const type = appType ?? appType1;
    if (remote_access_token) {
      setStatus(0);
      try {
        const tokenData: any = jwt_decode(remote_access_token);
        const response = await getTractorsData(
          userDetails.organization.api_url,
          userDetails.organization_id,
        );
        const tractors =
          response.records && response.records.length > 0
            ? response.records
            : [];

        const [tractor] = tractors.filter(
          (item: any) => item.id == tokenData.tractor_id,
        );
        tractor && setTractorName(tractor.name);

        const { organization_id: orgId = 0 } = tokenData;

        const data =
          type === 'ORGANIZATION'
            ? await grantOrgRemoteAccess(
                userDetails.organization.api_url,
                orgId,
                {
                  remote_access_token,
                },
              )
            : await grantTractorRemoteAccess(userDetails.organization.api_url, {
                remote_access_token,
              });
        setStatus(1);
        notification.success({ message: data.msg });
      } catch (error: any) {
        if (error.response)
          notification.error({ message: error.response?.data.error.message });
        else notification.error({ message: error.message });
        setStatus(-1);
      } finally {
        delay(3000).then(() => {
          localStorage.removeItem(REMOTE_ACCESS_GRANT);
          localStorage.removeItem(APP_TYPE);
        });
      }
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) handleRemoteAccessToken();
  }, [userDetails]);

  return (
    <div className="loginContainer">
      <div className="resetSuccessWrap">
        <img
          style={{ cursor: 'pointer' }}
          height="50px"
          src={logoHorizontalWhite}
          className="mbrag"
          onClick={() => history.push(RoutesConstants.Attention)}
        />
        <div
          className="ragHead"
          // style={{ display: status == 0 ? 'none' : 'block' }}
        >
          {status === 0
            ? 'Processing Access Request ...'
            : status === 1
            ? 'Remote Access Granted'
            : 'Request Remote Access Failed'}
        </div>
        <div className="ragImage">
          <img src={ragImg} />
        </div>
        <div className="ragHelpTxt">
          Please make sure{' '}
          <b style={{ fontSize: '16px' }}>{tractorName ? tractorName : ''}</b>{' '}
          is powered on and refrain from operating the tractor during this
          period.
        </div>
      </div>
    </div>
  );
};

export default RemoteAccess;
