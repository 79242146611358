/* eslint-disable no-console */
import React from 'react';
import './style.css';
import BatteryBlack from '../../assets/images/batteryBlack1.svg';

interface Props {
  battery: any;
  hover?: boolean;
  charging?: string;
}
const Battery: React.FC<Props> = ({
  battery,
  hover = false,
  charging,
}: Props) => {
  const getcolor = (energy: any) => {
    if (energy > 0) {
      const width = energy + '' + '%';
      let backgroundColor =
        energy > 25
          ? '#000'
          : energy <= 25 && energy > 10
          ? '#EC9322'
          : '#D33C39';
      if (hover) backgroundColor = '#67afb7';
      return {
        backgroundColor,
        width,
      };
    }
  };
  let style;
  if (hover) style = { borderColor: '#67afb7' };
  return (
    <>
      {charging === 'Charging' && battery !== 100 ? (
        <img
          className="indicatorImg mr5"
          width="22px"
          height="14px"
          src={BatteryBlack}
        />
      ) : (
        <div className="battery-blk">
          <div className="charging-container" style={style}>
            <div className="battery-percentage" style={getcolor(battery)}></div>
          </div>
        </div>
      )}
    </>
  );
};

export default Battery;
