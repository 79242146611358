import React, { useContext, useEffect, useState } from 'react';
import Layout from 'antd/lib/layout/layout';
import { Card, DatePicker, Space, notification, Select } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { ApplicationContext } from '../../context/AppContext';
import { getjumpandgoview, getTractorsListData } from '../../constants/Api';
import {
  Actions,
  JumpGoList,
  MyJumpGoList,
  TractorsList,
} from '../../constants/types';
import {
  getDateTimes,
  getTime,
  fromToDateWrapper,
  tractorSort,
  getTractorAvailableState,
} from '../../constants/Common';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import Jumpandgoctions from './Jumpandgoctions';
import CSelect from '../common/CSelect';

const { RangePicker } = DatePicker;

interface TractorResponseType {
  label: string;
  value: number | string | boolean;
  disabled: boolean;
  index: number | string;
  isAvailable?: string;
}

const Jumpandgo: React.FC = () => {
  const { t } = useTranslation();
  const [jumpandgoData, setjumpandgoData] = useState<JumpGoList[]>([]);
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPagesize] = useState<any>(25);
  const [tractors, setTractorsData] = useState<TractorResponseType[]>([]);
  const [fromDate, setFromDate] = useState<any>(
    moment().subtract(1, 'day').toDate(),
  );
  const [toDate, setToDate] = useState<any>(moment().toDate());
  const [tractorId, setTaractorId] = useState<string>();
  const [actionmodel, setactionmodel] = useState(false);
  const [actiondata, setactiondata] = useState<Actions>();
  const [loader, setLoader] = useState<boolean>(false);
  const [filterData, setFilter] = useState<JumpGoList[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalcount, settotalcount] = useState<any>();

  const columns = [
    {
      title: `${t(translate.baseStation.tractorName)}`,
      dataIndex: 'name',
      sorter: (a: { name: string }, b: { name: string }) =>
        a.name.localeCompare(b.name),
      // width: '12%',
      showSorterTooltip: false,
    },
    {
      title: `${t(translate.Jumpandgo.operator)}`,
      dataIndex: 'fullname',
      // width: '20%',
    },
    {
      title: `${t(translate.MyJumpAndGo.createdAt)}`,
      dataIndex: 'createdAt',
      // width: '15%',
    },
    {
      title: `${t(translate.taskmaster.starttime)}`,
      dataIndex: 'created_date_time',
      // width: '240px',
    },
    {
      title: `${t(translate.Jumpandgo.finishTime)}`,
      dataIndex: 'modified_date_time',
      // width: '240px',
    },
    {
      title: `${t(translate.MyJumpAndGo.duration)}`,
      dataIndex: 'duration',
    },
  ];

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      const init = async () => {
        await getjumpandgolist();
      };
      init();
    }
  }, [userDetails, pageNumber, pageSize, tractorId, fromDate, toDate]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
    }
  }, [userDetails]);

  const getjumpandgolist = async () => {
    try {
      setLoader(true);
      const jumpgolist: any = await getjumpandgoview(
        userDetails.organization.api_url,
        pageNumber,
        pageSize,
        tractorId ? tractorId : '',
        fromDate ? fromDate.getTime() : '',
        toDate ? toDate.getTime() : '',
      );
      settotalcount(jumpgolist._metadata.total_records_count);
      const data =
        jumpgolist && jumpgolist.records && jumpgolist.records.length > 0
          ? jumpgolist.records
          : [];
      const jumpandgolist: any = [];
      data.map((record: MyJumpGoList) => {
        const obj = {
          id: Math.random(),
          createdAt:
            record.drive_action_created_at === 'GILES'
              ? 'SMARTSCREEN'
              : record.drive_action_created_at,
          operatorid: record.operator ? record.operator.id : '',
          name: record.tractor.name ? record.tractor.name : '-',
          fullname: record.operator
            ? record.operator.first_name + ' ' + record.operator.last_name
            : '-',
          created_date_time: record.created_date_time
            ? getDateTimes(record.created_date_time)
            : '-',
          modified_date_time: record.modified_date_time
            ? getDateTimes(record.modified_date_time)
            : '-',
          duration: record.duration_in_seconds
            ? getTime(record.duration_in_seconds)
            : '-',
          data: record,
        };
        jumpandgolist.push(obj);
      });
      if (data.length > 0) {
        if (pageNumber === 1) {
          setFilter(jumpandgolist);
        } else {
          setFilter([...filterData, ...jumpandgolist]);
        }
      } else {
        setFilter(jumpandgolist);
        setjumpandgoData(jumpandgolist);
      }
      if (jumpandgolist.length < 25) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setLoader(false);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const getTractorsList = async () => {
    try {
      const tractor: { records: TractorsList[] } = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractorData: TractorResponseType[] = data.map((d: TractorsList) => {
        return {
          label: d.name,
          value: d.id,
          disabled: false,
          index: d.id,
          id: d.id,
          connection: d.connection,
          isOnline: d.connection === 'online' ? true : false,
          isAvailable: getTractorAvailableState(d.connection, d?.heartbeat),
        };
      });
      const arr = tractorSort(tractorData, 'label');
      setTractorsData(arr);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };

  const handleSelect = async (e: any) => {
    jumpandgoData.length = 0;
    filterData.length = 0;
    setPageNumber(1);
    setTaractorId(e);
  };

  const onDateRangeChange = (dates: any) => {
    jumpandgoData.length = 0;
    filterData.length = 0;
    if (dates) {
      setPageNumber(1);
      setFilter([]);
      setjumpandgoData([]);
      const [from, to] = fromToDateWrapper(dates);
      setFromDate(from.toDate());
      setToDate(to.toDate());
    } else {
      setFromDate(null);
      setFromDate(null);
    }
  };

  function disabledDate(current: any) {
    return current && current > moment();
  }

  return (
    <Layout>
      <div className="mainContent">
        <Content>
          <div className="tblContainer softwareUpdate">
            <Card
              bordered={true}
              className="tcktsBlk searchFilters filterGap40"
            >
              <Space
                className="mt0"
                style={{ marginBottom: 0, position: 'relative' }}
              >
                <CSelect
                  list={tractors.map((item) =>
                    Object.assign({
                      id: item.index,
                      name: item.label,
                      isAvailable: item.isAvailable,
                    }),
                  )}
                  selectTrigger={(selected) => {
                    handleSelect(selected ? selected?.id : 0);
                  }}
                  placeholder="All Tractors"
                  label="Tractor"
                  isAll={true}
                  tractorStatus={true}
                  className="dropdownStyle slkttractor tractorField"
                />
                <RangePicker
                  format="YYYY-MM-DD"
                  onChange={onDateRangeChange}
                  className="dropdownStyle dW250_date dateFilter"
                  disabledDate={disabledDate}
                  defaultValue={[moment(fromDate), moment(toDate)]}
                />
              </Space>
            </Card>
            <div className="tblDft farmTabsTbl posRel">
              <InfiniteScrollTable
                columns={columns}
                loading={loader}
                hasMore={hasMore}
                filterData={filterData}
                totalcount={totalcount}
                handleLoadMore={handleLoadMore}
                filename="Jump&Go"
                onRowClick={(record: any) => {
                  setactiondata(record.data);
                  setactionmodel(true);
                }}
              />
            </div>
          </div>
          <div>
            {actionmodel && (
              <Jumpandgoctions
                showModel={actionmodel}
                closeModel={() => setactionmodel(false)}
                actiondata={actiondata as Actions}
              />
            )}
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default Jumpandgo;
