/* eslint-disable react/jsx-no-undef */
import {
  Avatar,
  Button,
  Input,
  notification,
  Popover,
  Spin,
  Tooltip,
} from 'antd';
import Layout from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import './style.css';
import closeGreen from '../../assets/images/closeGreen.svg';
import AlertIcon from '../../assets/images/Group 1296.svg';
import tractorImg from '../../assets/images/tractorImg.png';
import pvTractor from '../../assets/images/pvTractor.png';
import tractorAlertIcon from '../../assets/images/tractorAlert.svg';
import tractorspic from '../../assets/images/tractor_health.svg';
import applicationIds from '../../locale/applicationIds.json';
import { useHistory } from 'react-router-dom';
import RoutesConstants from '../../routes/RoutesConstant';
import {
  getBatteryDetailsForTractor,
  Updatetractor,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { BatteryList, TractorsList } from '../../constants/types';
import {
  getNetworkTypeImg,
  getTractorStatusClass,
  tractorConstantsNew,
  getStatusForTractor,
} from '../../constants/Common';
import { useMixpanel } from 'react-mixpanel-browser';
import DataCollectionModal from './DataCollectionModal';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import { privilagesConstants } from '../../constants/Privilages';
import AppLoader from '../common/AppLoader';

interface Props {
  fleetobj: TractorsList;
  Close: () => void;
  setRefresh: () => void;
  fleetflage: boolean;
}
const Editfleet: React.FC<Props> = ({
  fleetobj,
  Close,
  setRefresh,
  fleetflage,
}: Props) => {
  const { goBack, push } = useHistory();
  const { t } = useTranslation();
  const [editFlag, setEditFlag] = useState<boolean>(true);
  const [Name, setName] = useState(fleetobj?.name);
  const { userDetails, privilegeChecker } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const mixpanel = useMixpanel();
  const [showDataCollection, setShowDataCollection] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [batteryDetails, setBatteryDetails] = useState<BatteryList>();

  useEffect(() => {
    if (Name && Name.trim().length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [Name]);

  const getBatteryDetails = async (tractorId: number) => {
    try {
      setLoader(true);
      const response = await getBatteryDetailsForTractor(
        userDetails.organization.api_url,
        userDetails.organization_id,
        tractorId,
      );
      if (response && response.length) {
        setBatteryDetails(response[0]);
      }
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message || err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (fleetobj && userDetails && userDetails.organization) {
      setName(fleetobj?.name);
      setEditFlag(true);
      getBatteryDetails(fleetobj?.id);
    }
  }, [fleetobj, userDetails]);

  const content = (
    <div>
      <p>
        software update available.
        <br />
        Please{' '}
        <a
          onClick={() => {
            push({
              pathname: RoutesConstants.FleetHealth,
              state: {
                action: 'SOFTWARE',
                tractorId: fleetobj?.id,
              },
            });
          }}
          style={{ textDecoration: 'underline' }}
        >
          Update Now.{' '}
        </a>
      </p>
    </div>
  );

  const Editdetails = () => {
    setEditFlag(false);
    mixpanel.track('Edit Fleet', {
      event: 'Edit Fleet Details Clicked',
    });
  };
  const SaveTractor = async () => {
    const data = {
      name: Name,
    };
    try {
      setLoader(true);
      const response = await Updatetractor(
        userDetails.organization.api_url,
        fleetobj.id,
        data,
      );
      fleetobj.name = Name;
      notification.success({ message: t(translate.label.tractorUpdated) });
      setEditFlag(true);
      setRefresh();
      mixpanel.track('Edit Fleet', {
        event: 'Edit fleet name Successfully',
      });
    } catch (err: any) {
      mixpanel.track('Edit Fleet', {
        event: 'Edit fleet name Failed',
      });
      notification.error({
        message: err.response?.data.error.message || err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const MapOpen = () => {
    push({
      pathname: RoutesConstants.LiveMap,
      state: {
        tractorId: fleetobj.id,
      },
    });
    mixpanel.track('LiveMap', {
      event: 'Moved to LiveMap',
    });
  };

  const showUpdaterIcon = (record: any) => {
    if (
      record?.softwareversion &&
      record?.heartbeat?.software_version !==
        record?.latest_software?.software_version
    ) {
      return true;
    }

    return false;
  };

  const tractorIcon =
    fleetobj?.indicatorsData &&
    fleetobj?.indicatorsData?.filter(
      (indicator: any) => indicator?.indicator_level === 3,
    ).length > 0
      ? tractorAlertIcon
      : tractorspic;

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (fleetflage ? 'expand' : 'hide')}>
        {editFlag && (
          <>
            <div className="widgetCloseBtn">
              <img
                id={applicationIds.fleetScreen.fleetCloseBtn}
                data-testid="closeButton-Fleet"
                src={closeGreen}
                alt="close icon"
                onClick={Close}
              />
            </div>
            <div>
              <div className="profileView rightWidgetView">
                <AppLoader loader={loader} />
                <div className="proRow tractorPic">
                  <Avatar
                    style={{ height: 162, width: 224 }}
                    src={
                      fleetobj?.tractor_model === 'PV' ||
                      fleetobj?.tractor_model === 'DV'
                        ? pvTractor
                        : tractorImg
                    }
                    alt=""
                  ></Avatar>
                </div>
                <div className="proRow rowGapBtm4 tractorType ">
                  <h4
                    className="headline1 rowGapBtm6 txtCenter"
                    data-testid="Header-Fleet"
                  >
                    {fleetobj?.name}
                  </h4>
                  <h5 className="headline2 fSbd rowGapBtm6">
                    {fleetobj?.tractor_model === 'PV' ||
                    fleetobj?.tractor_model === 'DV'
                      ? 'MK-V'
                      : fleetobj?.tractor_model}
                  </h5>
                  <Tooltip title="View in Live Map">
                    <span
                      className={getTractorStatusClass(fleetobj.isAvailable)}
                      onClick={MapOpen}
                    >
                      {getStatusForTractor(fleetobj?.isAvailable)}
                    </span>
                  </Tooltip>
                </div>
                <div>
                  <div className="tractorDetailsContainer rowGapBtm30">
                    <div
                      className="trDetailsTxt headline3 rowGapBtm4"
                      data-testid="Tractrdetails-Fleet"
                    >
                      Tractor Details
                    </div>
                    <div className="tractorList rowGapBtm30">
                      {/* <div className="trIconsTxt tractorLeft"> */}
                      <Tooltip
                        overlayInnerStyle={{
                          color: 'rgb(61, 60, 60)',
                          backgroundColor: '#fff',
                          fontSize: 10,
                          border: '1px solid #ccc',
                          borderRadius: 4,
                        }}
                        title="View in Tractor Diagnostics"
                      >
                        <div
                          className={`tractorPics ${
                            fleetobj?.isTractorError && 't_error'
                          }`}
                          data-testid="tractpicHover-Fleet"
                          onClick={() =>
                            push({
                              pathname: RoutesConstants.FleetHealth,
                              state: {
                                tractorId: fleetobj.id,
                                action: 'INDICATOR',
                              },
                            })
                          }
                          style={{
                            opacity:
                              fleetobj.isAvailable ===
                              tractorConstantsNew.off.key
                                ? 0.3
                                : 1,
                          }}
                        >
                          <div className="t-pic-block">
                            <img
                              src={tractorIcon ?? tractorspic}
                              width="22px"
                            />
                          </div>
                          <div className="t-pic-block">
                            {fleetobj.active_network === 'cellular' ? (
                              <img
                                src={getNetworkTypeImg(fleetobj)}
                                width="18px"
                                height="18px"
                              />
                            ) : (
                              <img
                                src={getNetworkTypeImg(fleetobj)}
                                width="24px"
                                height="24px"
                              />
                            )}
                          </div>
                        </div>
                      </Tooltip>
                      <div>
                        <div>
                          <div className="fleetSwVer headline5">Hour Meter</div>
                          <div className="fleetSwVerNo body1">
                            {fleetobj?.housemeter ?? '-'}
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                      {/* <div className="trIconsTxt tractorRight"> */}

                      <div>
                        <div
                          className="fleetSwVer headline5"
                          data-testid="softwareVersion-Fleet"
                        >
                          Software Version
                        </div>
                        {
                          <div
                            className="fleetSwVerNo body1"
                            data-testid="tractversion-Fleet"
                          >
                            {fleetobj?.softwareversion ?? '-'}
                            {showUpdaterIcon(fleetobj) && (
                              <Popover content={content} title="">
                                <img
                                  src={AlertIcon}
                                  height="24px"
                                  data-testid="updateicon-Fleet"
                                />
                              </Popover>
                            )}
                          </div>
                        }
                      </div>
                      <div>
                        <div className="fleetSwVer headline5">PIN</div>
                        <div className="fleetSwVerNo pinBody">
                          {fleetobj?.tractor_pin ?? '-'}
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>

                  <div className="tractorDetailsContainer rowGapBtm30">
                    <div
                      className="trDetailsTxt headline3 rowGapBtm4"
                      data-testid="batterydetails-Fleet"
                    >
                      Battery Details
                    </div>
                    <div className="tractorList rowGapBtm30">
                      {/* <div className="trIconsTxt tractorLeft"> */}
                      <div>
                        <div
                          className="fleetSwVer headline5"
                          data-testid="batteryname-Fleet"
                        >
                          Name
                        </div>
                        <div className="fleetSwVerNo body1">
                          {batteryDetails?.battery_name ?? '-'}
                        </div>
                      </div>
                      <div>
                        <div className="fleetSwVer headline5">Hour Meter</div>
                        <div className="fleetSwVerNo body1">
                          {batteryDetails &&
                          batteryDetails?.current_hour_meter_hours > 0
                            ? `${batteryDetails?.current_hour_meter_hours} hours`
                            : '-'}
                        </div>
                      </div>
                      <div>
                        <div className="fleetSwVer headline5">PIN</div>
                        <div className="fleetSwVerNo body1">
                          {batteryDetails?.bin === '0'
                            ? '-'
                            : batteryDetails?.bin}
                        </div>
                      </div>
                      {/* </div> */}
                      {/* <div className="trIconsTxt tractorRight"> */}

                      <div />
                      {/* </div> */}
                    </div>
                  </div>

                  <div className="mt20">
                    <div
                      className="trDetailsTxt headline3 rowGapBtm4"
                      data-testid="basestationdetails-Fleet"
                    >
                      Base Station Details
                    </div>
                    <div className="tractorList rowGapBtm30">
                      <div className="tractorLeft">
                        <div className="fleetSwVer headline5">Station</div>
                        <div className="fleetSwVerNo body1">
                          {fleetobj?.basestation?.name ?? '-'}
                        </div>
                      </div>
                      <div className="tractorRight">
                        <div className="fleetSwVer headline5">PIN</div>
                        <div className="fleetSwVerNo body1">
                          {fleetobj?.basestation?.bsin ?? '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {privilegeChecker(privilagesConstants.Edit_Fleet_Details) && (
                <div className="proeditBtn saveTxtBtn">
                  <button
                    id={applicationIds.fleetScreen.editFleetDetails}
                    onClick={Editdetails}
                    data-testid="fleetEditDetailsLink-EditFleet"
                  >
                    EDIT DETAILS
                  </button>
                </div>
              )}
            </div>
          </>
        )}
        {!editFlag && (
          <div className="profileEdit rightWidgetView editFleetView">
            <AppLoader loader={loader} />
            <div className="proRow mt30">
              <div className="proRow mb24 tractorPic">
                <Avatar
                  style={{ height: 162, width: 224 }}
                  src={
                    fleetobj?.tractor_model === 'PV' ||
                    fleetobj?.tractor_model === 'DV'
                      ? pvTractor
                      : tractorImg
                  }
                  alt=""
                ></Avatar>
              </div>
            </div>
            <div className="proInputMain">
              <h4 className="proInput">
                <div className="fleetSwVer mlb tractorLable">Tractor Name</div>
                <Input
                  id={applicationIds.fleetScreen.fleetName}
                  placeholder="Name"
                  defaultValue={Name}
                  onChange={(e) => setName(e.target.value)}
                  data-testid="tractorNameEditInputField-EditFleet"
                />
              </h4>
            </div>
            <div className="editTractorDetCon">
              <div className="tractorDetailsContainer">
                <div className="trDetailsTxt headline3 rowGapBtm4">
                  Tractor Details
                </div>
                <div className="tractorList rowGapBtm30">
                  {/* <div className="trIconsTxt tractorLeft"> */}
                  <div
                    className="tractorPics1"
                    style={{
                      opacity:
                        fleetobj.isAvailable === tractorConstantsNew.off.key
                          ? 0.3
                          : 1,
                    }}
                  >
                    <img src={tractorIcon ?? tractorspic} width="23px" />
                    <img src={getNetworkTypeImg(fleetobj)} height="22px" />
                  </div>
                  <div>
                    <div className="fleetSwVer headline5">Hour Meter</div>
                    <div className="fleetSwVerNo body1">
                      {fleetobj?.housemeter ?? '-'}
                    </div>
                  </div>
                  <div>
                    <div className="fleetSwVer headline5">Software Version</div>
                    <div className="fleetSwVerNo body1">
                      {fleetobj?.softwareversion ?? '-'}{' '}
                      <img src={AlertIcon} height="24px" />
                    </div>
                  </div>
                  {/* </div> */}
                  {/* <div className="trIconsTxt tractorRight"> */}

                  <div>
                    <div className="fleetSwVer headline5">PIN</div>
                    <div className="fleetSwVerNo body1">
                      {fleetobj?.tractor_pin ?? '-'}
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>

              <div className="tractorDetailsContainer mt14">
                <div className="trDetailsTxt headline3 rowGapBtm4">
                  Battery Details
                </div>
                <div className="tractorList rowGapBtm30">
                  {/* <div className="trIconsTxt tractorLeft"> */}
                  <div>
                    <div className="fleetSwVer headline5">Name</div>
                    <div className="fleetSwVerNo body1">
                      {batteryDetails?.battery_name ?? '-'}
                    </div>
                  </div>
                  <div>
                    <div className="fleetSwVer headline5">Hour Meter</div>
                    <div className="fleetSwVerNo body1">
                      {/* {batteryDetails?.current_hour_meter_hours === 0
                        ? '-'
                        : `${batteryDetails?.current_hour_meter_hours} hours`} */}
                      {batteryDetails &&
                      batteryDetails?.current_hour_meter_hours > 0
                        ? `${batteryDetails?.current_hour_meter_hours} hours`
                        : '-'}
                    </div>
                  </div>
                  <div>
                    <div className="fleetSwVer headline5">PIN</div>
                    <div className="fleetSwVerNo body1">
                      {batteryDetails?.bin === '0' ? '-' : batteryDetails?.bin}
                    </div>
                  </div>
                  {/* </div> */}
                  {/* <div className="trIconsTxt tractorRight"> */}

                  <div />
                  {/* </div> */}
                </div>
              </div>

              <div className="mt20">
                <div className="trDetailsTxt headline3 rowGapBtm4 ">
                  Base Station Details
                </div>
                <div className="tractorList rowGapBtm30">
                  <div className="tractorLeft">
                    <div className="fleetSwVer headline5">Station</div>
                    <div className="fleetSwVerNo body1">
                      {fleetobj?.basestation?.name ?? '-'}
                    </div>
                  </div>
                  <div className="tractorRight">
                    <div className="fleetSwVer headline5">PIN</div>
                    <div className="fleetSwVerNo body1">
                      {fleetobj?.basestation?.bsin ?? '-'}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="newFleetBtns editFleetButtons">
              <Button
                className="fleetCancel Button"
                onClick={() => setEditFlag(true)}
              >
                Cancel
              </Button>
              <Button
                className="fleetSave Button"
                id={applicationIds.fleetScreen.saveTractor}
                onClick={SaveTractor}
                disabled={disabled}
                data-testid="fleetSaveButton-EditFleet"
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </div>
      {showDataCollection && (
        <DataCollectionModal
          showModal={showDataCollection}
          closeModal={() => setShowDataCollection(false)}
          tractorIdentity={fleetobj}
        />
      )}
    </Layout>
  );
};

export default Editfleet;
