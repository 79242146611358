/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react';
import Layout from 'antd/lib/layout/layout';
import {
  Input,
  Avatar,
  notification,
  Spin,
  Modal,
  Card,
  Space,
  Button,
  Select,
  Switch,
} from 'antd';
import closeGreen from '../../assets/images/closeGreen.svg';
import applicationIds from '../../locale/applicationIds.json';
import './ImpStyle.css';
import verM_logo from '../../assets/images/verM_logo.svg';
import ProfileIcon from '../../assets/images/defaultProfileIcon.svg';
import {
  getCategoryList,
  getManufactureList,
  getManufactureModelsList,
  imageUploadMethod,
  saveImm,
  SaveImplements,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import {
  onKeyDown,
  onlyImplmentsNumberAndDecimalsAllow,
} from '../../constants/Common';
import classNames from 'classnames';
import DropdownRender from '../common/DropdownRender';
import ImageUpload from '../common/ImageUpload';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import ImplementSelect from '../common/ImplementSelect';
import { floatingHitchData, ptoOptions } from '../../constants/AppData';
import AppLoader from '../common/AppLoader';

interface Props {
  Close: () => void;
  toggleWidget: boolean;
  setRefresh: () => void;
  onSubmit?: (content: any) => void;
}

const AddImplement: React.FC<Props> = ({
  Close,
  toggleWidget,
  setRefresh,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [modalFlag, setModalFlag] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [confirmLoader, setConfirmLoader] = useState<boolean>(false);
  const [manufactures, setManufactures] = useState<any[]>([]);
  const [models, setModels] = useState<any[]>([]);
  const [hitchMode, sethitchMode] = useState<any[]>([]);
  const [implementType, setImpleteType] = useState<any>([]);
  const [details, setDetails] = useState<any>({});
  const [imm, setImm] = useState<any>({});
  const [, setImage] = useState<any>({});
  const [file, setFile] = useState<any>('');
  const [attachments, setAttachments] = useState<any[]>([]);
  const [powers, setPowerList] = useState<any[]>([]);
  // const [manufacturer, setManufacturer] = useState<string>();
  const [tempInput, setTempInput] = useState<any>({});
  const [isDisable, setIsDisable] = useState<boolean>(true);

  const [validations, setValidations] = useState<any>({
    name: true,
    manufacture: true,
    model: true,
    imp_ctrl_in_row_tractor_speed: true,
    imp_ctrl_in_row_3pt_hitch_mode: true,
    imp_ctrl_in_row_3pt_hitch_height: true,
    imp_ctrl_in_row_pto_rpm: true,
    imp_ctrl_in_row_toplink_pos: true,
    imp_ctrl_in_row_aux_hydraulics_1: true,
    imp_ctrl_in_row_aux_hydraulics_2: true,
    imp_ctrl_in_row_aux_hydraulics_6: true,
    imp_ctrl_in_row_generic_ctrl_5: true,
    imp_ctrl_in_row_generic_ctrl_6: true,
    imp_ctrl_out_of_row_tractor_speed: true,
    imp_ctrl_out_of_row_3pt_hitch_mode: true,
    imp_ctrl_out_of_row_3pt_hitch_height: true,
    imp_ctrl_out_of_row_pto_rpm: true,
    imp_ctrl_out_of_row_toplink_pos: true,
    imp_ctrl_out_of_row_aux_hydraulics_1: true,
    imp_ctrl_out_of_row_aux_hydraulics_2: true,
    imp_ctrl_out_of_row_aux_hydraulics_6: true,
    imp_ctrl_out_of_row_generic_ctrl_5: true,
    imp_ctrl_out_of_row_generic_ctrl_6: true,
    enabled: false,
  });
  const [validations1, setValidations1] = useState<any>({
    manufacture: true,
    model: true,
    enabled: false,
  });

  const Cancel = () => {
    setModalFlag(false);
  };

  useEffect(() => {
    if (imm && imm.manufacture && imm.model) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [imm.manufacture, imm.model]);

  const getProfileLookps = async () => {
    try {
      const cropsdata = await getCategoryList(
        userDetails.organization.api_url,
        '3pt_hitch_mode',
      );
      sethitchMode(cropsdata);
    } catch (err: any) {
      notification.error({
        message: err?.response?.data?.error?.message || err?.message,
      });
    }
  };

  const getManufactureListData = async () => {
    try {
      const result: any = await getManufactureList(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
      );
      setManufactures(result);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  const getImplementType = async () => {
    try {
      setLoader(true);
      const cropsdata = await getCategoryList(
        userDetails.organization.api_url,
        'implement_type',
      );
      setImpleteType(cropsdata);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      notification.error({
        message: error?.response?.data?.error?.message || error?.message,
      });
    }
  };

  const getManufactureModelListData = async () => {
    try {
      if (details?.manufacture?.trim() !== '') {
        const result: any = await getManufactureModelsList(
          userDetails.organization.api_url,
          userDetails.organization.fleet.id,
          details.manufacture,
        );
        setModels(result);
      }
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  const getAttachmeantList = async () => {
    try {
      const attachmeant = await getCategoryList(
        userDetails.organization.api_url,
        'attachment_type',
      );
      setAttachments(attachmeant);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  const getPowerList = async () => {
    try {
      const power = await getCategoryList(
        userDetails.organization.api_url,
        'power',
      );
      setPowerList(power);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  const handleOnChange = (e: any) => {
    if (e.target.files.length) {
      setImage(e.target.files[0]);
      setFile(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleDropdown = (manufacture: string) => {
    setImm({ ...imm, manufacture, model: undefined });
    setModalFlag(true);
  };

  const handleFormSubmit = () => {
    const { model, name, manufacture } = details;
    name && onSubmit && onSubmit(name.trim());
    setValidations({
      ...validations,
      name: name && name.trim() !== '',
      model: model,
      manufacture: manufacture,
      imp_ctrl_in_row_tractor_speed: details?.imp_ctrl_in_row_tractor_speed,
      imp_ctrl_in_row_3pt_hitch_mode: details?.imp_ctrl_in_row_3pt_hitch_mode,
      imp_ctrl_in_row_3pt_hitch_height:
        details?.imp_ctrl_in_row_3pt_hitch_height,
      imp_ctrl_in_row_pto_rpm: details?.imp_ctrl_in_row_pto_rpm,
      imp_ctrl_in_row_toplink_pos: details?.imp_ctrl_in_row_toplink_pos,
      imp_ctrl_in_row_aux_hydraulics_1:
        details?.imp_ctrl_in_row_aux_hydraulics_1,
      imp_ctrl_in_row_aux_hydraulics_2:
        details?.imp_ctrl_in_row_aux_hydraulics_2,
      imp_ctrl_in_row_aux_hydraulics_6:
        details?.imp_ctrl_in_row_aux_hydraulics_6,
      imp_ctrl_in_row_generic_ctrl_5: details?.imp_ctrl_in_row_generic_ctrl_5,
      imp_ctrl_in_row_generic_ctrl_6: details?.imp_ctrl_in_row_generic_ctrl_6,
      imp_ctrl_out_of_row_tractor_speed:
        details?.imp_ctrl_out_of_row_tractor_speed,
      imp_ctrl_out_of_row_3pt_hitch_mode:
        details?.imp_ctrl_out_of_row_3pt_hitch_mode,
      imp_ctrl_out_of_row_3pt_hitch_height:
        details?.imp_ctrl_out_of_row_3pt_hitch_height,
      imp_ctrl_out_of_row_pto_rpm: details?.imp_ctrl_out_of_row_pto_rpm,
      imp_ctrl_out_of_row_toplink_pos: details?.imp_ctrl_out_of_row_toplink_pos,
      imp_ctrl_out_of_row_aux_hydraulics_1:
        details?.imp_ctrl_out_of_row_aux_hydraulics_1,
      imp_ctrl_out_of_row_aux_hydraulics_2:
        details?.imp_ctrl_out_of_row_aux_hydraulics_2,
      imp_ctrl_out_of_row_aux_hydraulics_6:
        details?.imp_ctrl_out_of_row_aux_hydraulics_6,
      imp_ctrl_out_of_row_generic_ctrl_5:
        details?.imp_ctrl_out_of_row_generic_ctrl_5,
      imp_ctrl_out_of_row_generic_ctrl_6:
        details?.imp_ctrl_out_of_row_generic_ctrl_6,
      enabled: true,
    });
  };

  const handleUpload = async (response: any) => {
    try {
      const formData = new FormData();
      formData.append('image', file?.originFileObj);
      formData.append('category_id', response?.id);
      formData.append('category_name', 'IMPLEMENTS');
      await imageUploadMethod(
        userDetails.organization.api_url,
        userDetails.organization.id,
        formData,
      );
      Close();
      setRefresh();
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const saveImplement = async () => {
    const data = {
      ...details,
      config_imm_uuid: details.model,
      is_active: details.status,
      imp_ctrl_in_row_tractor_speed: Number(
        details?.imp_ctrl_in_row_tractor_speed,
      ),
      imp_ctrl_out_of_row_tractor_speed: Number(
        details?.imp_ctrl_out_of_row_tractor_speed,
      ),
    };
    try {
      setLoader(true);
      const response: any = await SaveImplements(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        data,
      );
      if (file) {
        handleUpload(response);
      } else {
        Close();
        setRefresh();
      }
      notification.success({ message: t(translate.label.implementCreated) });
    } catch (err: any) {
      notification.error({
        message: err?.response?.data?.error?.message || err?.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleFormSubmit1 = () => {
    const { manufacture, model } = imm;
    setValidations1({
      ...validations,
      manufacture: manufacture?.trim() !== '',
      model: model?.trim() !== '',
      enabled: true,
    });
  };

  const saveManufacturer = async () => {
    const { manufacture, model } = imm;
    const data = {
      ...imm,
      manufacturer: manufacture,
      classification_id: Math.floor(100000 + Math.random() * 900000),
    };
    setConfirmLoader(true);
    try {
      const response = await saveImm(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        data,
      );
      notification.success({
        message: response.msg,
      });
      setModalFlag(false);
      getManufactureListData();
      setDetails({ ...details, manufacture });
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    } finally {
      setConfirmLoader(false);
    }
  };

  useEffect(() => {
    const isValid = Object.values(validations1).every((item) => item);
    if (isValid && Object.keys(validations1).length) saveManufacturer();
  }, [validations1]);

  useEffect(() => {
    const isValid = Object.values(validations).every(
      (item) => item || item === 0,
    );
    if (isValid && Object.keys(validations).length) saveImplement();
  }, [validations]);

  useEffect(() => {
    getManufactureListData();
    // getManufactureModelListData();
    getAttachmeantList();
    getPowerList();
    getProfileLookps();
    getImplementType();
  }, []);

  useEffect(() => {
    if (details.manufacture && details?.manufacture?.trim() !== '')
      getManufactureModelListData();
  }, [details.manufacture]);

  const checkForPlaceholder = (value: number): boolean => {
    if (value) {
      return false;
    } else {
      return true;
    }
  };

  const CSlider = (
    field: string,
    placeholder: string,
    range: number[],
    param: string,
    temp: any = {},
  ) => {
    const [min, max] = range;
    return (
      <>
        <div className="fieldRange">
          {!checkForPlaceholder(temp[param]) || temp[param] === 0 ? (
            <div className="inputTop implementLable">{placeholder}</div>
          ) : (
            <></>
          )}
          <Input
            type="number"
            style={{ width: '284px' }}
            placeholder={checkForPlaceholder(details[field]) ? placeholder : ''}
            onKeyDown={(e: any) => onKeyDown(e)}
            className={
              !checkForPlaceholder(temp[param]) || temp[param] === 0
                ? 'implmentInput'
                : classNames({
                    errorClass: validations[field] === undefined,
                  })
            }
            value={details[field]}
            onChange={(e) => {
              handleOnChangeNum(e, field, min, max, param, temp);
            }}
            onWheel={(e) => e.currentTarget.blur()}
          />
        </div>
      </>
    );
  };

  const handleOnChangeNum = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
    min: number,
    max: number,
    param: string,
    temp: any = {},
  ) => {
    const str = e?.target?.value;
    const regx = /^[+-]?\d+(\.\d+)?$/;
    if (
      min <= Number(str) &&
      Number(str) <= max &&
      (Number(str) === 0 || regx.test(str))
    ) {
      setDetails({
        ...details,
        [field]: str.trim() === '' ? '' : Number(str),
      });
      setTempInput({ ...temp, [param]: e?.target?.value });
    } else if (str?.length == 1 && str?.trim() === '')
      setDetails({
        ...details,
        [field]: '',
      });
    else {
      setDetails({
        ...details,
        [field]: details[field] ? details[field] : '',
      });
    }
  };

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn">
          <img
            id={applicationIds.implementsScreen.AddImpClose}
            src={closeGreen}
            alt="close icon"
            onClick={Close}
          />
        </div>
        <div className="profileView empView rightWidgetView impWidget">
          <div className="uploadImg proRow mr0 rowGapBtm4">
            <div className="userIcon">
              <div className="proRow proPic">
                {file !== '' ? (
                  <Avatar src={file?.thumbUrl}></Avatar>
                ) : (
                  <Avatar src={ProfileIcon} className="defaulticon"></Avatar>
                )}
              </div>
              <ImageUpload setFileList={setFile}></ImageUpload>
            </div>
          </div>
          <div className="proRow formStyles">
            <div className="width100 rowGapBtm3 posRel">
              {details?.name?.length > 0 && (
                <div className="inputTop implementLable">Name</div>
              )}
              <Input
                id={applicationIds.implementsScreen.addImpName}
                data-testid="addImplementName-AddImplement"
                placeholder={!(details?.name?.length > 0) ? 'Name' : ''}
                onChange={(e) =>
                  setDetails({ ...details, name: e.target.value })
                }
                className={
                  details?.name
                    ? `implmentInput `
                    : `${classNames({
                        errorClass: !validations.name,
                      })}`
                }
              />
            </div>

            <div className="width100 rowGapBtm3 posRel manufactureTxt">
              <ImplementSelect
                dataTestkey="name"
                dataTestLabel="AddImplement"
                label="Manufacturer"
                list={manufactures}
                showSearch={true}
                value={details.manufacture}
                optionKey="name"
                className={classNames({
                  errorClass: !validations.manufacture,
                })}
                onSearch={(text: string) =>
                  setDetails({
                    ...details,
                    manufacture: text === '' ? undefined : text,
                    model: undefined,
                  })
                }
                onSelect={(text: any) =>
                  setDetails({
                    ...details,
                    manufacture: text,
                    model: undefined,
                  })
                }
                placeholder="Manufacturer"
                testId="selectManufacturer-AddImplement"
                filterOption={(input: any, option: any) => {
                  return (
                    option.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                dropDown={(menu: any) => (
                  <DropdownRender
                    name={details.manufacture}
                    addItem={handleDropdown}
                    menu={menu}
                  />
                )}
                optionDisplay="name"
              />
            </div>
            <div className="width100 rowGapBtm3 posRel modelSelect">
              <div className="selectImplementContainer">
                {details?.model && (
                  <div className="inputTop implementLable">Model</div>
                )}
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  // style={{ width: '284px' }}
                  placeholder={!details.model && 'Model'}
                  data-testid="selectModel-AddImplement"
                  value={details.model}
                  onSelect={(model) => {
                    setDetails({ ...details, model: model });
                  }}
                  className={classNames({
                    errorClass: !validations.model,
                  })}
                >
                  {models &&
                    models.map((data: any) => {
                      if (data.model) {
                        return (
                          <option
                            value={data.config_imm_uuid}
                            data-testid={`${data.model}-AddImplement`}
                            key={Math.random()}
                          >
                            {data.model}
                          </option>
                        );
                      }
                    })}
                </Select>
              </div>
            </div>
            <div className="width100 rowGapBtm3 posRel">
              <ImplementSelect
                dataTestkey="name"
                dataTestLabel="AddImplement"
                label="Implement Type"
                list={implementType}
                showSearch={false}
                value={details.implement_type}
                optionKey="name"
                onSelect={(implement_type) =>
                  setDetails({ ...details, implement_type })
                }
                placeholder="Implement Type"
                testId="impletype-AddImplement"
                optionDisplay="name"
              />
            </div>
            <div className="width100 rowGapBtm3 posrel ipw">
              {details?.serial_number?.length > 0 && (
                <div className="inputTop implementLable">Serial Number</div>
              )}
              <Input
                className={details?.serial_number ? 'implmentInput' : ''}
                id={applicationIds.implementsScreen.addImpSerialNum}
                placeholder={
                  !(details?.serial_number?.length > 0) ? 'Serial Number' : ''
                }
                data-testid="addSerialNumber-AddImplement"
                onChange={(e) =>
                  setDetails({ ...details, serial_number: e.target.value })
                }
              />
            </div>

            <div className="width100 rowGapBtm3 posRel">
              <ImplementSelect
                dataTestkey="name"
                dataTestLabel="AddImplement"
                label="Attachment"
                list={attachments}
                showSearch={false}
                value={details.attachment_type}
                optionKey="name"
                onSelect={(attachment_type) =>
                  setDetails({ ...details, attachment_type })
                }
                placeholder="Attachment"
                testId="selectAttachment-AddImplement"
                optionDisplay="name"
              />
            </div>

            <div className="width100 rowGapBtm3 posRel">
              <ImplementSelect
                dataTestkey="name"
                dataTestLabel="AddImplement"
                label="Power"
                list={powers}
                showSearch={false}
                value={details.power}
                optionKey="name"
                onSelect={(power) => setDetails({ ...details, power })}
                placeholder="Power"
                testId="addPower-AddImplement"
                optionDisplay="name"
              />
            </div>
            <div className="width100 rowGapBtm3 posRel">
              <ImplementSelect
                dataTestkey="name"
                dataTestLabel="AddImplement"
                label="Floating Hitch"
                list={floatingHitchData}
                showSearch={false}
                value={details?.floating_hitch}
                optionKey="id"
                onSelect={(floating_hitch) => {
                  setDetails({
                    ...details,
                    floating_hitch: floating_hitch,
                  });
                }}
                placeholder="Floating Hitch?"
                testId="addFloatingHitch-AddImplement"
                optionDisplay="name"
              />
            </div>
            <div className="width100 rowGapBtm3 posrel ipw fieldRange">
              <>
                <span className="posabs">IN</span>
              </>
              {tempInput?.width || tempInput?.width === 0 ? (
                <div className="inputTop implementLable">Max Width</div>
              ) : (
                <></>
              )}
              <Input
                type="number"
                className={
                  tempInput?.width || tempInput?.width === 0
                    ? 'implmentInput'
                    : ''
                }
                id={applicationIds.implementsScreen.addImpWidth}
                placeholder={!details?.width ? 'Max Width' : ''}
                data-testid="addWidth-AddImplement"
                onKeyDown={(e: any) => onKeyDown(e)}
                onChange={(e) => {
                  setDetails({
                    ...details,
                    width: Number(e.target.value),
                  });
                  setTempInput({ ...tempInput, width: e?.target?.value });
                }}
                onKeyPress={(e: any) => onlyImplmentsNumberAndDecimalsAllow(e)}
                onWheel={(e) => e.currentTarget.blur()}
              />
            </div>
            <div className="width100 rowGapBtm3 posrel ipw fieldRange">
              <>
                <span className="posabs">IN</span>
              </>
              {tempInput?.length || tempInput?.length === 0 ? (
                <div className="inputTop implementLable">Length</div>
              ) : (
                <></>
              )}
              <Input
                type="number"
                className={
                  tempInput?.length || tempInput?.length === 0
                    ? 'implmentInput'
                    : ''
                }
                id={applicationIds.implementsScreen.addImpLength}
                placeholder={!details?.length ? 'Length' : ''}
                data-testid="addLength-AddImplement"
                onKeyDown={(e: any) => onKeyDown(e)}
                onChange={(e) => {
                  setDetails({ ...details, length: Number(e.target.value) });
                  setTempInput({ ...tempInput, length: e?.target?.value });
                }}
                onKeyPress={(e: any) => onlyImplmentsNumberAndDecimalsAllow(e)}
                onWheel={(e) => e.currentTarget.blur()}
              />
            </div>
            <div className="width100 rowGapBtm3 posrel ipw fieldRange">
              <>
                <span className="posabs">IN</span>
              </>
              {tempInput?.height || tempInput?.height === 0 ? (
                <div className="inputTop implementLable">Height</div>
              ) : (
                <></>
              )}
              <Input
                type="number"
                className={
                  tempInput?.height || tempInput?.height === 0
                    ? 'implmentInput'
                    : ''
                }
                id={applicationIds.implementsScreen.addImpHeight}
                placeholder={!details?.height ? 'Height' : ''}
                data-testid="addHeight-AddImplement"
                onKeyDown={(e: any) => onKeyDown(e)}
                onChange={(e) => {
                  setDetails({ ...details, height: Number(e.target.value) });
                  setTempInput({ ...tempInput, height: e?.target?.value });
                }}
                onKeyPress={(e: any) => onlyImplmentsNumberAndDecimalsAllow(e)}
                onWheel={(e) => e.currentTarget.blur()}
              />
            </div>
            <div className="width100 rowGapBtm3 posrel ipw fieldRange">
              <>
                <span className="posabs">LB</span>
              </>
              {tempInput?.weight || tempInput?.weight === 0 ? (
                <div className="inputTop implementLable">Weight</div>
              ) : (
                <></>
              )}
              <Input
                type="number"
                className={
                  tempInput?.weight || tempInput?.weight === 0
                    ? 'implmentInput'
                    : ''
                }
                id={applicationIds.implementsScreen.addImpWeight}
                placeholder={!details?.weight ? 'Weight' : ''}
                data-testid="addWeight-AddImplement"
                onKeyDown={(e: any) => onKeyDown(e)}
                onChange={(e) => {
                  setDetails({ ...details, weight: Number(e.target.value) });
                  setTempInput({ ...tempInput, weight: e?.target?.value });
                }}
                onKeyPress={(e: any) => onlyImplmentsNumberAndDecimalsAllow(e)}
                onWheel={(e) => e.currentTarget.blur()}
              />
            </div>
            <h6 className="fSbd headline2 width100">Autonomy Controls</h6>
            <h6 className="fSbd headline2 width100">In Row</h6>
            <div className="width100 rowGapBtm3 posrel ipw fieldRange">
              <>
                <span className="posabs">MPH</span>
              </>
              {details?.imp_ctrl_in_row_tractor_speed ? (
                <div className="inputTop implementLable">Tractor Speed</div>
              ) : (
                <></>
              )}
              <Input
                type="number"
                placeholder={
                  !details?.imp_ctrl_in_row_tractor_speed ? 'Tractor Speed' : ''
                }
                data-testid="speed-AddImplement"
                onKeyDown={(e: any) => onKeyDown(e)}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    imp_ctrl_in_row_tractor_speed: e.target.value,
                  })
                }
                className={
                  details?.imp_ctrl_in_row_tractor_speed
                    ? 'implmentInput'
                    : classNames({
                        errorClass: !validations.imp_ctrl_in_row_tractor_speed,
                      })
                }
                onKeyPress={(e: any) => onlyImplmentsNumberAndDecimalsAllow(e)}
                onWheel={(e) => e.currentTarget.blur()}
              />
            </div>
            <div className="width100 rowGapBtm3 posRel">
              <ImplementSelect
                dataTestkey="name"
                dataTestLabel="AddImplement"
                label="3pt Hitch Mode"
                list={hitchMode}
                showSearch={false}
                value={details?.imp_ctrl_in_row_3pt_hitch_mode}
                optionKey="name"
                className={classNames({
                  errorClass: !validations.imp_ctrl_in_row_3pt_hitch_mode,
                })}
                onSelect={(imp_ctrl_in_row_3pt_hitch_mode) =>
                  setDetails({ ...details, imp_ctrl_in_row_3pt_hitch_mode })
                }
                placeholder="3pt Hitch Mode"
                testId="hitchmode-AddImplement"
                optionDisplay="name"
              />
            </div>
            <div
              className="width100 rowGapBtm3 posrel ipw"
              data-testid="3pthitchheight-AddImplement"
            >
              <>
                <span className="posabs">%</span>
              </>
              {CSlider(
                'imp_ctrl_in_row_3pt_hitch_height',
                '3pt Hitch Height',
                [0.0, 100.0],
                'hitchHeight',
                tempInput,
              )}
            </div>
            <div className="width100 rowGapBtm3 posRel">
              <ImplementSelect
                dataTestkey="name"
                dataTestLabel="AddImplement"
                label="PTO"
                list={ptoOptions}
                showSearch={false}
                value={details?.imp_ctrl_in_row_pto}
                optionKey="id"
                onSelect={(imp_ctrl_in_row_pto) =>
                  setDetails({
                    ...details,
                    imp_ctrl_in_row_pto: imp_ctrl_in_row_pto,
                  })
                }
                placeholder="PTO"
                testId="pto-AddImplement"
                optionDisplay="name"
              />
            </div>
            <div
              className="width100 rowGapBtm3 posrel ipw"
              data-testid="ptoRPM-AddImplement"
            >
              <>
                <span className="posabs">RPM</span>
              </>
              {CSlider(
                'imp_ctrl_in_row_pto_rpm',
                'PTO RPM',
                [0.0, 540.0],
                'ptoRpm',
                tempInput,
              )}
            </div>
            <div
              className="width100 rowGapBtm3 posrel ipw"
              data-testid="toplink-AddImplement"
            >
              <>
                <span className="posabs">%</span>
              </>
              {CSlider(
                'imp_ctrl_in_row_toplink_pos',
                'Top Link',
                [0.0, 100.0],
                'link',
                tempInput,
              )}
            </div>
            <div
              className="width100 rowGapBtm3 posrel ipw"
              data-testid="auxHydra1-AddImplement"
            >
              {CSlider(
                'imp_ctrl_in_row_aux_hydraulics_1',
                'Aux Hydraulics1',
                [0.0, 8.0],
                'hydra1',
                tempInput,
              )}
            </div>
            <div
              className="width100 rowGapBtm3 posrel ipw"
              data-testid="auxHydra2-AddImplement"
            >
              <>
                <span className="posabs">%</span>
              </>
              {CSlider(
                'imp_ctrl_in_row_aux_hydraulics_2',
                'Aux Hydraulics2',
                [0.0, 100.0],
                'hydra2',
                tempInput,
              )}
            </div>
            <div
              className="width100 rowGapBtm3 posrel ipw"
              data-testid="auxHydra6-AddImplement"
            >
              <>
                <span className="posabs">%</span>
              </>
              {CSlider(
                'imp_ctrl_in_row_aux_hydraulics_6',
                'Aux Hydraulics6',
                [0.0, 100.0],
                'hydra6',
                tempInput,
              )}
            </div>
            <div
              className="width100 rowGapBtm3 generictoggle genric_toggle_sec"
              data-testid="genCtrl1-AddImplement"
            >
              <span>Generic Ctrl 1</span>
              <Switch
                checked={details?.imp_ctrl_in_row_generic_ctrl_1}
                onChange={(imp_ctrl_in_row_generic_ctrl_1) =>
                  setDetails({
                    ...details,
                    imp_ctrl_in_row_generic_ctrl_1:
                      imp_ctrl_in_row_generic_ctrl_1 === false ? false : true,
                  })
                }
              />
            </div>

            <div
              className="width100 rowGapBtm3 generictoggle genric_toggle_sec"
              data-testid="genCtrl2-AddImplement"
            >
              <span>Generic Ctrl 2</span>
              <Switch
                checked={details?.imp_ctrl_in_row_generic_ctrl_2}
                onChange={(imp_ctrl_in_row_generic_ctrl_2) =>
                  setDetails({
                    ...details,
                    imp_ctrl_in_row_generic_ctrl_2:
                      imp_ctrl_in_row_generic_ctrl_2 === false ? false : true,
                  })
                }
              />
            </div>
            <div
              className="width100 rowGapBtm3 generictoggle genric_toggle_sec"
              data-testid="genCtrl3-AddImplement"
            >
              <span>Generic Ctrl 3</span>
              <Switch
                checked={details?.imp_ctrl_in_row_generic_ctrl_3}
                onChange={(imp_ctrl_in_row_generic_ctrl_3) =>
                  setDetails({
                    ...details,
                    imp_ctrl_in_row_generic_ctrl_3:
                      imp_ctrl_in_row_generic_ctrl_3 === false ? false : true,
                  })
                }
              />
            </div>
            <div
              className="width100 rowGapBtm3 generictoggle genric_toggle_sec"
              data-testid="genCtrl4-AddImplement"
            >
              <span>Generic Ctrl 4</span>
              <Switch
                checked={details?.imp_ctrl_in_row_generic_ctrl_4}
                onChange={(imp_ctrl_in_row_generic_ctrl_4) =>
                  setDetails({
                    ...details,
                    imp_ctrl_in_row_generic_ctrl_4:
                      imp_ctrl_in_row_generic_ctrl_4 === false ? false : true,
                  })
                }
              />
            </div>

            <div
              className="width100 rowGapBtm3 posrel ipw"
              data-testid="genCtrl5-AddImplement"
            >
              <>
                <span className="posabs">%</span>
              </>
              {CSlider(
                'imp_ctrl_in_row_generic_ctrl_5',
                'Generic Ctrl5',
                [0.0, 100.0],
                'ctrl5',
                tempInput,
              )}
            </div>
            <div
              className="width100 rowGapBtm3 posrel ipw"
              data-testid="genCtrl6-AddImplement"
            >
              <>
                <span className="posabs">%</span>
              </>
              {CSlider(
                'imp_ctrl_in_row_generic_ctrl_6',
                'Generic Ctrl6',
                [0.0, 100.0],
                'ctrl6',
                tempInput,
              )}
            </div>
            <h6 className="fSbd headline2 width100">Autonomy Controls</h6>
            <h6 className="fSbd headline2 width100">Out of Row</h6>
            <div className="width100 rowGapBtm3 posrel ipw fieldRange">
              <>
                <span className="posabs">MPH</span>
              </>
              {details?.imp_ctrl_out_of_row_tractor_speed ? (
                <div className="inputTop implementLable">Tractor Speed</div>
              ) : (
                <></>
              )}
              <Input
                type="number"
                placeholder={
                  !details?.imp_ctrl_out_of_row_tractor_speed
                    ? 'Tractor Speed'
                    : ''
                }
                data-testid="speedOut-AddImplement"
                onKeyDown={(e: any) => onKeyDown(e)}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    imp_ctrl_out_of_row_tractor_speed: e.target.value,
                  })
                }
                className={
                  details?.imp_ctrl_out_of_row_tractor_speed
                    ? 'implmentInput'
                    : classNames({
                        errorClass:
                          !validations.imp_ctrl_out_of_row_tractor_speed,
                      })
                }
                onKeyPress={(e: any) => onlyImplmentsNumberAndDecimalsAllow(e)}
                onWheel={(e) => e.currentTarget.blur()}
              />
            </div>
            <div className="width100 rowGapBtm3 posRel">
              <ImplementSelect
                dataTestkey="name"
                dataTestLabel="AddImplement"
                label="3pt Hitch Mode"
                list={hitchMode}
                showSearch={false}
                value={details?.imp_ctrl_out_of_row_3pt_hitch_mode}
                optionKey="name"
                className={classNames({
                  errorClass: !validations.imp_ctrl_out_of_row_3pt_hitch_mode,
                })}
                onSelect={(imp_ctrl_out_of_row_3pt_hitch_mode) =>
                  setDetails({ ...details, imp_ctrl_out_of_row_3pt_hitch_mode })
                }
                placeholder="3pt Hitch Mode"
                testId="hitchmodeOut-AddImplement"
                optionDisplay="name"
              />
            </div>
            <div
              className="width100 rowGapBtm3 posrel ipw"
              data-testid="hitchheightOut-AddImplement"
            >
              <>
                <span className="posabs">%</span>
              </>
              {CSlider(
                'imp_ctrl_out_of_row_3pt_hitch_height',
                '3pt Hitch Height',
                [0.0, 100.0],
                'outHitchHeight',
                tempInput,
              )}
            </div>
            <div className="width100 rowGapBtm3 posRel">
              <ImplementSelect
                dataTestkey="name"
                dataTestLabel="AddImplement"
                label="PTO"
                list={ptoOptions}
                showSearch={false}
                value={details?.imp_ctrl_out_of_row_pto}
                optionKey="id"
                onSelect={(imp_ctrl_out_of_row_pto) =>
                  setDetails({
                    ...details,
                    imp_ctrl_out_of_row_pto: imp_ctrl_out_of_row_pto,
                  })
                }
                placeholder="PTO"
                testId="ptoOut-AddImplement"
                optionDisplay="name"
              />
            </div>
            <div
              className="width100 rowGapBtm3 posrel ipw"
              data-testid="ptoRPMOut-AddImplement"
            >
              <>
                <span className="posabs">RPM</span>
              </>
              {CSlider(
                'imp_ctrl_out_of_row_pto_rpm',
                'PTO RPM',
                [0.0, 540.0],
                'outPtoRpm',
                tempInput,
              )}
            </div>
            <div
              className="width100 rowGapBtm3 posrel ipw"
              data-testid="toplinkOut-AddImplement"
            >
              <>
                <span className="posabs">%</span>
              </>
              {CSlider(
                'imp_ctrl_out_of_row_toplink_pos',
                'Top Link',
                [0.0, 100.0],
                'outPos',
                tempInput,
              )}
            </div>
            <div
              className="width100 rowGapBtm3 posrel ipw"
              data-testid="auxHydra1Out-AddImplement"
            >
              {CSlider(
                'imp_ctrl_out_of_row_aux_hydraulics_1',
                'Aux Hydraulics1',
                [0.0, 8.0],
                'outHydra1',
                tempInput,
              )}
            </div>
            <div
              className="width100 rowGapBtm3 posrel ipw"
              data-testid="auxHydra2Out-AddImplement"
            >
              <>
                <span className="posabs">%</span>
              </>
              {CSlider(
                'imp_ctrl_out_of_row_aux_hydraulics_2',
                'Aux Hydraulics2',
                [0.0, 100.0],
                'outHydra2',
                tempInput,
              )}
            </div>
            <div
              className="width100 rowGapBtm3 posrel ipw"
              data-testid="auxHydra6Out-AddImplement"
            >
              <>
                <span className="posabs">%</span>
              </>
              {CSlider(
                'imp_ctrl_out_of_row_aux_hydraulics_6',
                'Aux Hydraulics6',
                [0.0, 100.0],
                'outHydra6',
                tempInput,
              )}
            </div>
            <div
              className="width100 rowGapBtm3 generictoggle genric_toggle_sec"
              data-testid="genCtrl1Out-AddImplement"
            >
              <span>Generic Ctrl 1</span>
              <Switch
                checked={details?.imp_ctrl_out_of_row_generic_ctrl_1}
                onChange={(imp_ctrl_out_of_row_generic_ctrl_1) =>
                  setDetails({
                    ...details,
                    imp_ctrl_out_of_row_generic_ctrl_1:
                      imp_ctrl_out_of_row_generic_ctrl_1 === false
                        ? false
                        : true,
                  })
                }
              />
            </div>

            <div
              className="width100 rowGapBtm3 generictoggle genric_toggle_sec"
              data-testid="genCtrl2Out-AddImplement"
            >
              <span>Generic Ctrl 2</span>
              <Switch
                checked={details?.imp_ctrl_out_of_row_generic_ctrl_2}
                onChange={(imp_ctrl_out_of_row_generic_ctrl_2) =>
                  setDetails({
                    ...details,
                    imp_ctrl_out_of_row_generic_ctrl_2:
                      imp_ctrl_out_of_row_generic_ctrl_2 === false
                        ? false
                        : true,
                  })
                }
              />
            </div>

            <div
              className="width100 rowGapBtm3 generictoggle genric_toggle_sec"
              data-testid="genCtrl3Out-AddImplement"
            >
              <span>Generic Ctrl 3</span>
              <Switch
                checked={details?.imp_ctrl_out_of_row_generic_ctrl_3}
                onChange={(imp_ctrl_out_of_row_generic_ctrl_3) =>
                  setDetails({
                    ...details,
                    imp_ctrl_out_of_row_generic_ctrl_3:
                      imp_ctrl_out_of_row_generic_ctrl_3 === false
                        ? false
                        : true,
                  })
                }
              />
            </div>

            <div
              className="width100 rowGapBtm3 generictoggle genric_toggle_sec"
              data-testid="genCtrl4Out-AddImplement"
            >
              <span>Generic Ctrl 4</span>
              <Switch
                checked={details?.imp_ctrl_out_of_row_generic_ctrl_4}
                onChange={(imp_ctrl_out_of_row_generic_ctrl_4) =>
                  setDetails({
                    ...details,
                    imp_ctrl_out_of_row_generic_ctrl_4:
                      imp_ctrl_out_of_row_generic_ctrl_4 === false
                        ? false
                        : true,
                  })
                }
              />
            </div>

            <div
              className="width100 rowGapBtm3 posrel ipw"
              data-testid="genCtrl5Out-AddImplement"
            >
              <>
                <span className="posabs">%</span>
              </>
              {CSlider(
                'imp_ctrl_out_of_row_generic_ctrl_5',
                'Generic Ctrl5',
                [0.0, 100.0],
                'outCtrl5',
                tempInput,
              )}
            </div>
            <div
              className="width100 rowGapBtm3 posrel ipw"
              data-testid="genCtrl6Out-AddImplement"
            >
              <>
                <span className="posabs">%</span>
              </>
              {CSlider(
                'imp_ctrl_out_of_row_generic_ctrl_6',
                'Generic Ctrl6',
                [0.0, 100.0],
                'outCtrl6',
                tempInput,
              )}
            </div>
            <AppLoader loader={loader} />

            <div className="proeditBtn tcktSaveBtn saveTxtBtn">
              <button
                id={applicationIds.ticketScreen.saveTicket}
                onClick={handleFormSubmit}
                data-testid="saveButton-AddImplement"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalFlag && (
        <Modal visible={modalFlag} footer={false} className="permissonPopup">
          <>
            <Card className="softwareCard">
              <div className="verHistorySec">
                <h3 className="verHistTxt posRel">
                  Add Manufacturer
                  <img
                    src={verM_logo}
                    alt="mlogo"
                    className="mLogo"
                    height="33"
                  />
                </h3>

                <div>
                  <div className="width284 mt16 addImp">
                    <Input
                      placeholder="Manufacturer"
                      value={imm.manufacture}
                      onChange={(e) =>
                        setImm({ ...imm, manufacture: e.target.value })
                      }
                      className={classNames({
                        errorClass: !validations1.manufacture,
                      })}
                    />
                    <br />
                    <br />
                    <Input
                      placeholder="Model"
                      value={imm.model}
                      onChange={(e) =>
                        setImm({ ...imm, model: e.target.value })
                      }
                      className={classNames({
                        errorClass: !validations1.model,
                      })}
                    />
                  </div>
                  <div className="permissonVerSec">
                    <Space>
                      <Button onClick={() => Cancel()} className="verCancelBtn">
                        CANCEL
                      </Button>
                      <Button
                        onClick={handleFormSubmit1}
                        className="verResetBtn"
                        disabled={isDisable}
                      >
                        ADD
                      </Button>
                    </Space>
                  </div>
                </div>

                <div className="edit-loader">
                  {confirmLoader && (
                    <div className="loader">
                      <Spin size="large" />
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </>
        </Modal>
      )}
    </Layout>
  );
};

export default AddImplement;
