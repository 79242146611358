import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { getClient } from '../../util/MqttConnection';
import { ApplicationContext } from '../../context/AppContext';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  tractorIdentity: any;
}
export const DataCollectionModal: React.FC<Props> = ({
  showModal,
  closeModal,
  tractorIdentity,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [mqttMessage, setMqttMessage] = useState<any>({});

  useEffect(() => {
    const { organization } = userDetails;
    const mqttClient = getClient(organization.mqtt_ip, organization.mqtt_port);

    mqttClient.on('connect', () => {
      mqttClient.subscribe(
        `debug_notifications_${userDetails.organization_id}`,
      );
    });
    mqttClient.on('message', (topics: string, message: string) => {
      if (topics === `debug_notifications_${userDetails.organization_id}`) {
        const reloadData = JSON.parse(message.toString());
        setMqttMessage(reloadData);
      }
    });

    return () => {
      mqttClient.unsubscribe(
        `debug_notifications_${userDetails.organization_id}`,
      );
    };
  }, []);
  return (
    <Modal
      className="baseModalContainer"
      title="Data Collection"
      centered
      visible={showModal}
      onCancel={closeModal}
      footer={false}
      width={800}
      style={{
        width: '800px',
        position: 'relative',
        top: '150px',
      }}
    >
      {tractorIdentity?.id === mqttMessage.tractor_id
        ? mqttMessage.message
        : 'Data Not found'}
    </Modal>
  );
};
export default DataCollectionModal;
