import React, { useContext, useEffect, useState } from 'react';
import { Card, DatePicker, notification, Select, Space } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import { getAllControls, getTractorsListData } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { ShadowView, TractorsList } from '../../constants/types';
import {
  fromToDateWrapper,
  getDateTimes,
  getTime,
  getTractorAvailableState,
  tractorSort,
} from '../../constants/Common';
import 'react-datepicker/dist/react-datepicker.css';
import ShadowHistory from '../shadow/ShadowHistory';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import moment from 'moment';
import CSelect from '../common/CSelect';

const { RangePicker } = DatePicker;

interface TractorResponseType {
  label: string;
  value: number | string | boolean;
  disabled: boolean;
  index: number | string;
  isAvailable?: string;
}

const RowFollow: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<ShadowView[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [pageSize, setPagesize] = useState<any>(25);
  const [tractors, setTractorsData] = useState<TractorResponseType[]>([]);
  const [tractorId, setTaractorId] = useState<string>();
  const [ControlName, setControlName] = useState<string>('vinerow');
  const [fromtime, setfromTime] = useState<any>(
    moment().subtract(1, 'day').toDate(),
  );
  const [totime, settoTime] = useState<any>(moment().toDate());
  const [rowFollowModel, setRowFollowModel] = useState<boolean>(false);
  const [driveControl, setDriveControl] = useState();
  const [totalcount, settotalcount] = useState<any>();
  const [filterData, setFilter] = useState<ShadowView[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      fromtime === null &&
      totime === null
    ) {
      const init = async () => {
        await getShadowview();
      };
      init();
    }
  }, [userDetails, pageNumber, pageSize, tractorId, fromtime, totime]);

  useEffect(() => {
    if (fromtime && totime) {
      const init = async () => {
        await getShadowview();
      };
      if (userDetails && userDetails.organization) init();
    }
  }, [tractorId, fromtime, totime, pageNumber]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
    }
  }, [userDetails]);
  const getShadowview = async () => {
    const { organization } = userDetails;
    try {
      setLoader(true);
      const shadowview = await getAllControls(
        organization.api_url,
        tractorId ? tractorId : '',
        ControlName,
        fromtime ? fromtime.getTime() : '',
        totime ? totime.getTime() : '',
        pageNumber,
        pageSize,
      );
      settotalcount(shadowview._metadata.total_records_count);
      const data =
        shadowview && shadowview.records && shadowview.records.length > 0
          ? shadowview.records
          : [];
      const shadowData: ShadowView[] = [];
      data.map((record: any) => {
        const obj = {
          id: record.id,
          name: record.tractor_id.name,
          first_name:
            record.operator_id?.first_name +
            ' ' +
            record.operator_id?.last_name,
          drive_action_uuid: record.drive_action_uuid,
          duration_in_seconds: record.duration_in_seconds
            ? getTime(record.duration_in_seconds)
            : '',
          acr_start_date_time: record.acr_start_date_time
            ? getDateTimes(record.acr_start_date_time)
            : '',
          acr_finish_date_time: record.acr_finish_date_time
            ? getDateTimes(record.acr_finish_date_time)
            : '',
          tractorId: record.tractor_id.id,
          acr_uuid: record.acr_uuid,
        };
        shadowData.push(obj);
      });
      if (data.length > 0) {
        if (pageNumber === 1) {
          setFilter([...shadowData]);
        } else {
          setFilter([...filterData, ...shadowData]);
        }
      } else {
        setFilter(shadowData);
        setDataSource(shadowData);
      }
      if (shadowData.length < 25) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setLoader(false);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };
  const getTractorsList = async () => {
    try {
      const tractor: { records: TractorsList[] } = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractorData: TractorResponseType[] = data.map((d: TractorsList) => {
        return {
          label: d.name,
          value: d.id,
          disabled: false,
          index: d.id,
          id: d.id,
          connection: d.connection,
          isOnline: d.connection === 'online' ? true : false,
          isAvailable: getTractorAvailableState(d.connection, d?.heartbeat),
        };
      });
      const arr = tractorSort(tractorData, 'label');
      setTractorsData(arr);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };

  const handleSelect = async (e: any) => {
    setTaractorId(e);
    dataSource.length = 0;
    filterData.length = 0;
    setPageNumber(1);
  };

  const columns = [
    {
      title: `${t(translate.copycat.tractorName)}`,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: `${t(translate.copycat.operator)}`,
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: `${t(translate.copycat.starttime)}`,
      dataIndex: 'acr_start_date_time',
      key: 'acr_start_date_time',
      // width: '240px',
    },
    {
      title: `${t(translate.copycat.endtime)}`,
      dataIndex: 'acr_finish_date_time',
      key: 'acr_finish_date_time',
      // width: '240px',
    },
    {
      title: `${t(translate.copycat.duration)}`,
      dataIndex: 'duration_in_seconds',
      key: 'duration_in_seconds',
    },
  ];

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const onDateRangeChange = (dates: any) => {
    dataSource.length = 0;
    filterData.length = 0;
    setPageNumber(1);
    setDataSource([]);
    setFilter([]);
    if (dates) {
      const [from, to] = fromToDateWrapper(dates);
      setfromTime(from.toDate());
      settoTime(to.toDate());
    } else {
      setfromTime(null);
      settoTime(null);
    }
  };

  function disabledDate(current: any) {
    return current && current > moment();
  }

  return (
    <Layout>
      <div className="mainContent">
        <Content>
          <div className="tblContainer softwareUpdate">
            <Card
              bordered={true}
              className="tcktsBlk searchFilters filterGap40"
            >
              <Space
                className="mt0"
                style={{ marginBottom: 0, position: 'relative' }}
              >
                <CSelect
                  list={tractors.map((item) =>
                    Object.assign({
                      id: item.index,
                      name: item.label,
                      isAvailable: item.isAvailable,
                    }),
                  )}
                  selectTrigger={(selected) => {
                    handleSelect(selected ? selected?.id : 0);
                  }}
                  placeholder="All Tractors"
                  label="Tractor"
                  isAll={true}
                  tractorStatus={true}
                  className="dropdownStyle slkttractor tractorField"
                />
                <RangePicker
                  format="YYYY-MM-DD"
                  onChange={onDateRangeChange}
                  className="dropdownStyle dW250_date dateFilter"
                  disabledDate={disabledDate}
                  defaultValue={[moment(fromtime), moment(totime)]}
                />
              </Space>
            </Card>
            <div className="tblDft farmTabsTbl posRel">
              <InfiniteScrollTable
                columns={columns}
                loading={loader}
                hasMore={hasMore}
                filterData={filterData}
                totalcount={totalcount}
                handleLoadMore={handleLoadMore}
                filename="RowFollow"
                onRowClick={(record: any) => {
                  setDriveControl(record);
                  setRowFollowModel(true);
                }}
              />
            </div>
            <div>
              {rowFollowModel && (
                <ShadowHistory
                  title={t(translate.headers.rowfollowdetails)}
                  showModel={rowFollowModel}
                  closeModel={() => setRowFollowModel(false)}
                  reportId={driveControl}
                />
              )}
            </div>
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default RowFollow;
