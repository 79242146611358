import React, { useEffect, useState } from 'react';
import './style.css';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import downloadIcon from '../../assets/images/videoDownload.png';
import loading_logo from '../../assets/images/loading_logo.gif';

interface Props {
  handleProgress: any;
  frontRef: any;
  rearRef: any;
  frontLeft: any;
  frontRight: any;
  rearLeft: any;
  rearRight: any;
  driverRef: any;
  ptoRef: any;
  vedioInfo: any;
  showLoader?: any;
  videos?: any;
}

const CameraGrid2: React.FC<Props> = ({
  handleProgress,
  frontRef,
  rearRef,
  frontLeft,
  frontRight,
  rearLeft,
  rearRight,
  driverRef,
  ptoRef,
  vedioInfo,
  showLoader,
  videos = [],
}) => {
  const [index, setIndex] = useState(1);
  const { t } = useTranslation();
  const [rearflag, setrearflag] = useState<any>(true);
  const [frontflag, setfrontflag] = useState<any>(true);
  const [frontleftflag, setfrontleftflag] = useState<any>(true);
  const [frontrightflag, setfrontrightflag] = useState<any>(true);
  const [ptoflag, setptoflag] = useState<any>(true);
  const [rearloader, setrearloader] = useState<any>(false);
  const [frontloader, setfrontloader] = useState<any>(false);
  const [frontleftloader, setfrontleftloader] = useState<any>(false);
  const [frontrightloader, setfrontrightloader] = useState<any>(false);
  const [ptoloader, setptoloader] = useState<any>(false);

  const NOVIDEO = () => {
    return (
      <>{!showLoader && <div className="noVideo">No Video Available</div>}</>
    );
  };
  const videoTag = (
    i: number,
    ref: React.LegacyRef<HTMLVideoElement> | undefined,
  ) =>
    index == i ? (
      <video
        id="presentvideo"
        className="video"
        width="100%"
        height="100%"
        ref={ref}
        onTimeUpdate={handleProgress}
        onLoadStart={(e: any) => {
          MethodloaderTrue(e, i);
        }}
        onLoadedData={(e: any) => {
          myMethodDataLoaderFalse(e, i);
        }}
        onError={(e: any) => myMethod(e, i)}
      ></video>
    ) : (
      <video
        id="subvideo"
        className="video"
        width="100%"
        height="100%"
        ref={ref}
        onTimeUpdate={handleProgress}
        onError={(e: any) => myMethod(e, i)}
        onLoadStart={(e: any) => {
          MethodloaderTrue(e, i);
        }}
        onLoadedData={(e: any) => {
          myMethodDataLoaderFalse(e, i);
        }}
      ></video>
    );

  const myMethod = (e: any, i: any) => {
    switch (i) {
      case 1:
        setfrontflag(false);
        break;
      case 2:
        setrearflag(false);
        break;
      case 4:
        setptoflag(false);
        break;
      case 5:
        setfrontleftflag(false);
        break;
      case 6:
        setfrontrightflag(false);
        break;
    }
    myMethodDataLoaderFalse(e, i);
  };

  const MethodloaderTrue = (e: any, i: any) => {
    switch (i) {
      case 1:
        setfrontloader(true);
        break;
      case 2:
        setrearloader(true);
        break;
      case 4:
        setptoloader(true);
        break;
      case 5:
        setfrontleftloader(true);
        break;
      case 6:
        setfrontrightloader(true);
        break;
    }
  };

  const myMethodDataLoaderFalse = (e: any, i: any) => {
    switch (i) {
      case 1:
        setfrontloader(false);
        break;
      case 2:
        setrearloader(false);
        break;
      case 4:
        setptoloader(false);
        break;
      case 5:
        setfrontleftloader(false);
        break;
      case 6:
        setfrontrightloader(false);
        break;
    }
  };

  const handleDownload = (ref: any) =>
    ref && window.open(ref?.current?.currentSrc, '_self');

  return (
    <div className="camerasContainer videoGrid">
      <div className="containerMain posrel">
        <div
          onClick={() => setIndex(1)}
          className={index === 1 ? 'mainCont' : 'child '}
          id={frontloader ? 'loaderborder' : ''}
        >
          <p className="snapText"> {t(translate.cameras.front)}</p>
          {vedioInfo?.front && frontflag ? videoTag(1, frontRef) : NOVIDEO()}
          <div className="edit-loader ">
            {frontloader && (
              <div className="loader">
                <img
                  style={{ width: '40px', height: '40px' }}
                  src={loading_logo}
                />
              </div>
            )}
          </div>
          {index === 1 && (
            <a
              className="video-download-btn"
              onClick={() => handleDownload(frontRef)}
            >
              <img src={downloadIcon} />
            </a>
          )}
        </div>
        <div
          onClick={() => setIndex(5)}
          className={index === 5 ? 'mainCont' : 'child'}
          id={frontleftloader ? 'loaderborder' : ''}
        >
          <p className="snapText"> Left </p>
          {vedioInfo?.frontleft && frontleftflag
            ? videoTag(5, frontLeft)
            : NOVIDEO()}
          <div className="edit-loader">
            {frontleftloader && (
              <div className="loader">
                <img
                  style={{ width: '40px', height: '40px' }}
                  src={loading_logo}
                />
              </div>
            )}
          </div>
          {index === 5 && (
            <a
              className="video-download-btn"
              onClick={() => handleDownload(frontLeft)}
            >
              <img src={downloadIcon} />
            </a>
          )}
        </div>
        <div
          onClick={() => setIndex(6)}
          className={index === 6 ? 'mainCont' : 'child'}
          id={frontrightloader ? 'loaderborder' : ''}
        >
          <p className="snapText"> {t(translate.cameras.right)} </p>
          {vedioInfo?.frontright && frontrightflag
            ? videoTag(6, frontRight)
            : NOVIDEO()}
          <div className="edit-loader">
            {frontrightloader && (
              <div className="loader">
                <img
                  style={{ width: '40px', height: '40px' }}
                  src={loading_logo}
                />
              </div>
            )}
          </div>
          {index === 6 && (
            <a
              className="video-download-btn"
              onClick={() => handleDownload(frontRight)}
            >
              <img src={downloadIcon} />
            </a>
          )}
        </div>

        <div
          onClick={() => setIndex(2)}
          className={index === 2 ? 'mainCont' : 'child'}
          id={rearloader ? 'loaderborder' : ''}
        >
          <p className="snapText">{t(translate.cameras.rear)}</p>
          {vedioInfo?.rear && rearflag ? videoTag(2, rearRef) : NOVIDEO()}
          <div className="edit-loader">
            {rearloader && (
              <div className="loader">
                <img
                  style={{ width: '40px', height: '40px' }}
                  src={loading_logo}
                />
              </div>
            )}
          </div>
          {index === 2 && (
            <a
              className="video-download-btn"
              onClick={() => handleDownload(rearRef)}
            >
              <img src={downloadIcon} />
            </a>
          )}
        </div>

        <div
          onClick={() => setIndex(4)}
          className={index === 4 ? 'mainCont' : 'child'}
          id={ptoloader ? 'loaderborder' : ''}
        >
          <p className="snapText">{t(translate.cameras.hitch)}</p>
          {vedioInfo?.pto && ptoflag ? videoTag(4, ptoRef) : NOVIDEO()}
          <div className="edit-loader">
            {ptoloader && (
              <div className="loader">
                <img
                  style={{ width: '40px', height: '40px' }}
                  src={loading_logo}
                />
              </div>
            )}
          </div>
          {index === 4 && (
            <a
              className="video-download-btn"
              onClick={() => handleDownload(ptoRef)}
            >
              <img src={downloadIcon} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default CameraGrid2;
