/* eslint-disable no-console */
/* eslint-disable react/jsx-no-undef */
import React, { useContext, useEffect, useState } from 'react';
import autodrive_new from '../../assets/images/autodrive_Iconv6.svg';
import { TractorsList } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import {
  ADD_CONNECTED_TRACTORS,
  LOAD_AVAILABLE_TRACTORS,
  SET_AUTO_DRIVE,
  SET_CONNECTING,
} from './actions';
import { RemoteDriveAppCtx } from './RemoteDriveContext';
import { getAvailableTractors } from './RemoteDriveServce';
import TractorCard1 from './TractorCard1';
interface Props {
  setRecenterTabId: (id: number) => void;
  setTractorTabes: (tractor: any) => void;
}
const AutoDrive: React.FC<Props> = ({
  setRecenterTabId,
  setTractorTabes,
}: Props) => {
  const [loader] = useState<boolean>(false);
  const { APPReducer } = useContext(ApplicationContext);
  const [appState] = APPReducer;
  const { tractorsList } = appState;
  const {
    RDReducer,
    setSelectedTractorsIds,
    selectedTractorsIds,
    connectTractorHandler,
  } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const { availableTractors, connecting, showConnectLoader, selectedTractors } =
    state;

  useEffect(() => {
    return () => {
      dispatch({ type: SET_CONNECTING, payload: false });
    };
  }, []);

  const handleSelectTractor = (id: number) => {
    if (!connecting)
      setSelectedTractorsIds(
        !selectedTractorsIds.includes(id)
          ? [id]
          : selectedTractorsIds.filter((tractorId: number) => tractorId !== id),
      );
    else console.log('Connected Tractor Selected');
  };

  useEffect(() => {
    const tConnectedTractor = availableTractors.filter((tractor: any) =>
      selectedTractorsIds.includes(tractor.id),
    );
    dispatch({ type: ADD_CONNECTED_TRACTORS, payload: tConnectedTractor });
  }, [selectedTractorsIds]);

  useEffect(() => {
    const result = getAvailableTractors(tractorsList);
    dispatch({ type: LOAD_AVAILABLE_TRACTORS, payload: result });
  }, [tractorsList]);

  return (
    <>
      <div className="auto-drive-block">
        <div
          className={`operationsBlk ${
            tractorsList && tractorsList.length > 0 ? 'listExpand' : ''
          }`}
          data-testid="fleetListSelector-LiveMapFleetList"
        >
          <div
            className="autodriveClose"
            onClick={() =>
              dispatch({
                type: !connecting ? SET_AUTO_DRIVE : SET_CONNECTING,
                payload: false,
              })
            }
          >
            <span
              className="autodrive-cancel"
              data-testid="autodriveCancel-LiveMapFleetList"
            >
              CANCEL
            </span>
          </div>
          <div
            className={`parentAccordion noBorderRadius posRel ${
              !connecting &&
              selectedTractorsIds &&
              selectedTractorsIds.length > 0
                ? 'startaConBtn'
                : ''
            }`}
          >
            <div className="autoDempty">
              <div className="alignCenter mb8">
                <img
                  src={autodrive_new}
                  alt="route"
                  className=""
                  height="18"
                  width="21"
                />
              </div>
              <h2 className="alignCenter Button mb12">AUTODRIVE</h2>
              <div className="alignCenter body3 selClr mb36">
                {connecting
                  ? 'Connecting to Tractor'
                  : availableTractors.length > 0
                  ? 'Select a Tractor for an Autodrive Routine'
                  : 'No Tractors Available'}
              </div>
            </div>
            <div className="list fleetOverallBlk">
              {availableTractors &&
                availableTractors.length > 0 &&
                availableTractors.map((tractor: any) => {
                  return (
                    <TractorCard1
                      key={Math.random()}
                      tractor={tractor}
                      connecting={
                        tractor.id === selectedTractorsIds[0] ? loader : false
                      }
                      selectedTractorsIds={selectedTractorsIds}
                      showConnectLoader={showConnectLoader}
                      handleSelectTractor={(tractorDetails: TractorsList) => {
                        if (tractorDetails && tractorDetails.id) {
                          handleSelectTractor(tractorDetails.id);
                          setRecenterTabId(0);
                          setTimeout(() => {
                            setRecenterTabId(tractorDetails.id);
                          }, 10);
                          const [tract] = selectedTractors;
                          const [tract2] = !selectedTractorsIds.includes(
                            tractorDetails.id,
                          )
                            ? [tractorDetails.id]
                            : [];
                          // selecting tractor from autodrive pop
                          tract?.id != tract2 &&
                            setTractorTabes(tractorDetails);
                        }
                      }}
                    />
                  );
                })}
            </div>
          </div>
          <div className="bottomButton">
            {!connecting &&
              selectedTractorsIds &&
              selectedTractorsIds.length > 0 && (
                <span
                  className="connectButton fleetConnectBtn"
                  onClick={() => connectTractorHandler()}
                >
                  Connect
                </span>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoDrive;
