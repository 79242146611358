/* eslint-disable no-console */
/* eslint-disable react/jsx-no-undef */
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import React, { createRef, useContext, useEffect, useState } from 'react';
import FullCalendar, { EventSourceInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { getDayview } from '../../constants/Api';
import './style.css';
import { ApplicationContext } from '../../context/AppContext';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Breadcrumb, notification } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import RoutesConstants from '../../routes/RoutesConstant';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import loadinggif from '../../assets/images/loading_logo.gif';
import { Image } from 'antd';
import { Pages } from '../library/Library';
import { useMixpanel } from 'react-mixpanel-browser';
import { getDateTime } from '../../constants/Common';

type Event = {
  start: number;
  end: number;
  title: string;
  tractor_id: number;
  tractorName: string;
  video_time: number;
  backgroundColor: string;
  start_date_time: string;
};
interface Props {
  stateData: any;
  setCurrentPage: (page: string) => void;
  setStateData: (state: any) => void;
}
let dayviewobj: any;

const DayView: React.FC<Props> = ({
  stateData,
  setCurrentPage,
  setStateData,
}: Props) => {
  const mixpanel = useMixpanel();
  const { userDetails } = useContext(ApplicationContext);
  const [event, setEvent] = useState<Event[]>([]);
  const { t } = useTranslation();
  const calenderRef: React.RefObject<FullCalendar> = createRef();
  const [viewTitle, setViewTitle] = useState('');
  // const { state } = useLocation();
  const [state, setstate] = useState();

  const [loader, setLoader] = useState<boolean>(false);
  // let dayviewobj: any;
  useEffect(() => {
    dayviewobj = stateData;
    setstate(stateData);
  }, [stateData]);
  useEffect(() => {
    if (userDetails && userDetails.organization && state) {
      const init = async () => {
        mixpanel.track('Recorded Video', {
          event: `${stateData?.tractorName} Clicked`,
        });
        const { video_time }: any = state;
        calenderRef.current?.getApi().changeView('timeGridDay', video_time);
        await getRecordedVideoList();
      };
      init();
    }
  }, [userDetails, state]);

  useEffect(() => {
    if (viewTitle) {
      const init = async () => {
        await getRecordedVideoList();
      };
      init();
    }
  }, [viewTitle]);

  const getRecordedVideoList = async () => {
    try {
      setLoader(true);
      const startTime = moment(
        calenderRef.current?.getApi().view.activeStart,
      ).format('YYYY-MM-DD');
      const endTime = moment(
        calenderRef.current?.getApi().view.activeEnd,
      ).format('YYYY-MM-DD');
      const data = await getDayview(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        startTime,
        endTime,
        dayviewobj?.tractor_id,
      );
      const events: Event[] = [];
      if (data) {
        data.map((record: any) => {
          events.push({
            start: record.start_date_time,
            end: record.end_date_time,
            title: record.tractor.name,
            tractor_id: record.tractor_id,
            tractorName: record.tractor.name,
            video_time: record.start_date_time,
            backgroundColor: 'green',
            start_date_time: record.start_date_time,
          });
        });

        setEvent(events);
      }
    } catch (error) {
      notification.error({
        message: 'Error',
      });
    } finally {
      setLoader(false);
    }
  };

  const handleClickEvent = async (e: any) => {
    const { tractor_id, tractorName, video_time } = e.event._def.extendedProps;
    setCurrentPage(Pages.VideoSnaps);
    setStateData({
      tractor_id,
      tractorName,
      video_time,
    });
    mixpanel.track('Recorded Video', {
      event: `${stateData?.tractorName} ${getDateTime(video_time)} Clicked`,
    });
    // push({
    //   pathname: RoutesConstants.VideoSnaps,
    //   state: {
    //     tractor_id,
    //     tractorName,
    //     video_time,
    //   },
    // });
  };

  return (
    <Layout className="taskmasterComp">
      <div className="mainContent">
        <Breadcrumb>
          <BreadcrumbItem
            onClick={() => {
              // push({
              //   pathname: RoutesConstants.RecordedVideo,
              // })
              setCurrentPage(Pages.RecordedVideo);
            }}
          >
            <a>{t(translate.recordedVideo.RecordedVideo)}</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <span style={{ color: '#EB921F' }}>{dayviewobj?.tractorName}</span>
          </BreadcrumbItem>
        </Breadcrumb>
        <div className="dbWidget tmTbl calTbl day-view-height">
          {/* <Header className="tm-header ant-card-head">
            <div className="ant-card-head-title">Recorded Video</div>
          </Header> */}
          <Content className="tm-content ant-card-body calendar-body rmTbl2">
            {' '}
            <FullCalendar
              ref={calenderRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="timeGridDay"
              headerToolbar={{
                center: 'title',
                left: 'prev,next',
                right: '',
              }}
              slotDuration="00:05:00"
              slotLabelInterval="0:05"
              allDaySlot={false}
              eventTimeFormat={{
                hour: '2-digit', // 2-digit, numeric
                minute: '2-digit', // 2-digit, numeric
                hourCycle: 'h23',
              }}
              editable={false}
              selectMirror={true}
              displayEventTime={false}
              events={event as EventSourceInput}
              eventsSet={() => {
                setViewTitle(calenderRef.current?.getApi().view.title || '');
              }}
              eventClick={(e) => handleClickEvent(e)}
            />
          </Content>
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Image
              width={300}
              style={{ textAlign: 'center' }}
              alt="camImg"
              className="sImg"
              src={loadinggif}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default DayView;
