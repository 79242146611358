import { Button, Result } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import RoutesConstants from '../../routes/RoutesConstant';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';

const NotFound: React.FC = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={() => push(RoutesConstants.Dashboard)}>
          {t(translate.buttons.back)}
        </Button>
      }
    />
  );
};

export default NotFound;
