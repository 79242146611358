import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';
import React from 'react';
import applicationIds from '../../../locale/applicationIds.json';

import { useMixpanel } from 'react-mixpanel-browser';
import mapNor from '../../../assets/images/mapNor.svg';
import mapSat from '../../../assets/images/mapSat.svg';
import MapCenterIcon from '../../../assets/images/map_recenter.svg';

interface Props {
  rightSideToggle: boolean;
  selectedTractorId: number;
  recenterTractor: (selectedTractorId: number) => void;
  map: any;
  fieldFeatureItem: Feature<Geometry>;
  displayRecenterViewPortSetUp: (
    fieldFeatureItem: Feature<Geometry>,
    map: any,
  ) => void;
  toggleSatellite: boolean;
  setToggleSatellite: (toggleSatellite: boolean) => void;
}
const FungicideSpraying: React.FC<Props> = ({
  rightSideToggle,
  selectedTractorId,
  recenterTractor,
  map,
  fieldFeatureItem,
  displayRecenterViewPortSetUp,
  toggleSatellite,
  setToggleSatellite,
}) => {
  const mixpanel = useMixpanel();
  const handleZoom = (zoomType: string) => {
    if (map) {
      const view = map.getView();
      const zoom = view.getZoom();

      if (zoom) {
        if (zoomType === 'zoomIn') {
          view.setZoom(zoom + 1);
          mixpanel.track('Live Map', {
            event: `Map Zoom In ${zoom + 1}`,
          });
        } else {
          view.setZoom(zoom - 1);
          mixpanel.track('Live Map', {
            event: `Map Zoom Out ${zoom - 1}`,
          });
        }
      }
    }
  };

  return (
    <div className={rightSideToggle ? 'mapIconSec tglexpand' : 'mapIconSec'}>
      <div className="mapIcons">
        <div
          className="mapRecenter"
          onClick={() => {
            if (Number(selectedTractorId)) {
              recenterTractor(Number(selectedTractorId));
            } else {
              map &&
                displayRecenterViewPortSetUp(
                  fieldFeatureItem as Feature<Geometry>,
                  map,
                );
            }
          }}
        >
          <img style={{ width: '40px', height: '40px' }} src={MapCenterIcon} />
        </div>

        <div className="zoom_in_out">
          <button
            id={applicationIds.liveMap.zoomInBtn}
            className="liveZoomIn"
            onClick={() => handleZoom('zoomIn')}
          />
          <button
            id={applicationIds.liveMap.zoomOutBtn}
            className="liveZoomOut"
            onClick={() => handleZoom('zoomOut')}
          />
        </div>
        <div className="mapView">
          <img
            id={applicationIds.liveMap.mapViewBtn}
            src={toggleSatellite ? mapNor : mapSat}
            onClick={() => {
              mixpanel.track('Live Map', {
                event: `${toggleSatellite ? 'Map View' : 'satellite View'}`,
              });
              setToggleSatellite(!toggleSatellite);
            }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default FungicideSpraying;
