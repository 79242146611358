/* eslint-disable no-console */
import { Image, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { getSnapshot } from '../../constants/Api';
import { UserDetails } from '../../constants/types';

interface Props {
  showModal: boolean;
  handleCancel: () => void;
  guardrailsList: any;
  userDetails: UserDetails;
}

const GuardRailPopup: React.FC<Props> = ({
  showModal,
  handleCancel,
  guardrailsList,
  userDetails,
}: Props) => {
  const [snapShots, setSnapShots] = useState([]);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (guardrailsList && userDetails && userDetails.organization) {
      const init = async () => {
        try {
          const response = await getSnapshot(
            userDetails.organization.api_url,
            guardrailsList.guard_rail_triggered_uuid,
            guardrailsList.tractor_id,
          );
          const dataResponse: any =
            response && response.length > 0 ? response : [];
          setSnapShots(dataResponse);
          if (dataResponse.length === 0) {
            setLoader(true);
          } else {
            setLoader(false);
          }
        } catch (error) {
          // error
        }
      };
      init();
    }
  }, [guardrailsList]);

  return (
    <Modal
      width="1000px"
      className="EmployeeModalContainer customModal grPopup"
      title={
        guardrailsList && guardrailsList.title
          ? guardrailsList.title
          : 'Guardrails'
      }
      visible={showModal}
      centered
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <Content>
        <div className="camWrapper guardrailsModal">
          {snapShots &&
            snapShots.length > 0 &&
            snapShots.map(
              (snapshot: { camera_image_url: string; camera_name: string }) => {
                return (
                  <div className="camBox1" key={snapshot.camera_image_url}>
                    <div className="camBoxHead">
                      {snapshot?.camera_name?.toUpperCase().replace('-', ' ')}
                    </div>
                    <div className="camBoxContent">
                      <Image src={snapshot?.camera_image_url}></Image>
                    </div>
                  </div>
                );
              },
            )}
          <div className="edit-loader">
            {loader && (
              <div className="loader nodata">
                <span className="nodatafound">No Data Found</span>
              </div>
            )}
          </div>
        </div>
      </Content>
    </Modal>
  );
};

export default GuardRailPopup;
