import { SecondPieChartInput, PieDatum } from '../../types/linked_charts';

import { OperatorCostReferenceDict } from '../../types/operator';

export const firstBarToPieChart = (
  op_cost_ref_dict: OperatorCostReferenceDict,
  first_date_str: string,
  total_cost: string,
): Omit<SecondPieChartInput, 'title'> => {
  const op_cost_dict = op_cost_ref_dict[first_date_str];

  const pie_data_list: PieDatum[] = OpCostToPieDatumList(op_cost_dict);

  const total_cost_str = 'USD ' + total_cost;
  return {
    line_id: 'total',
    info: {
      x: first_date_str,
      y: total_cost_str,
    },
    data: pie_data_list,
  };
};

export const OpCostToPieDatumList = (op_cost_dict: {
  [op_name: string]: number;
}): PieDatum[] => {
  const pie_data_list: PieDatum[] = [];
  for (const [op_name, op_cost] of Object.entries(op_cost_dict)) {
    pie_data_list.push({
      id: op_name,
      label: op_name,
      value: Math.floor(op_cost * 100) / 100,
    });
  }
  return pie_data_list;
};
