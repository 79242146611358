import {
  LOAD_DRIVING_TRACTORS,
  LOAD_RUNNING_TRACTORS,
  SET_RESET_TIMER,
  SET_TIME_LEFT,
  SET_TOGGLE_TIMER,
} from '../components/remote_drive_new/actions';
import { TractorHeartBeat } from '../constants/types';
import {
  AUTO_DRIVE_LOGOUT_CONFIRM,
  EXECUTE,
  LOAD_REMOTE_DRIVE_TRACTORS,
  SET_PINNED,
  SET_TRACTOR_IMPLEMENT_STATE,
} from './actions';

interface InitialStateType {
  title: string;
  tractorImplementState: any;
  isPinned: boolean;
  remoteAvTractors: any[];
  autoDriveLogoutConfirm: boolean;
  drivingTractors: TractorHeartBeat[];
  runningTractors: TractorHeartBeat[];
  toggleTimer: { status: boolean; from: 'Click' | 'Event' };
  resetTimer: boolean;
  timeLeft: number;
}
interface Action {
  type: string;
  payload: any;
}
export const initialState: InitialStateType = {
  title: 'AutoDriveStore',
  tractorImplementState: {},
  isPinned: false,
  remoteAvTractors: [],
  autoDriveLogoutConfirm: false,
  drivingTractors: [],
  runningTractors: [],
  toggleTimer: { status: true, from: 'Event' },
  resetTimer: false,
  timeLeft: 60 * 10,
};

export default function reducer(state: InitialStateType, action: Action) {
  const { type, payload } = action;
  switch (type) {
    case SET_TRACTOR_IMPLEMENT_STATE:
      return {
        ...state,
        tractorImplementState: { ...payload },
      };
    case SET_PINNED:
      return { ...state, isPinned: payload };
    case EXECUTE:
      return { ...state, ...payload };
    case LOAD_REMOTE_DRIVE_TRACTORS:
      return { ...state, remoteAvTractors: payload };
    case AUTO_DRIVE_LOGOUT_CONFIRM:
      return { ...state, autoDriveLogoutConfirm: payload };
    case LOAD_DRIVING_TRACTORS:
      return { ...state, drivingTractors: payload };
    case LOAD_RUNNING_TRACTORS:
      return { ...state, runningTractors: payload };
    case SET_TOGGLE_TIMER:
      return { ...state, toggleTimer: payload };
    case SET_RESET_TIMER:
      return { ...state, resetTimer: payload };
    case SET_TIME_LEFT:
      return { ...state, timeLeft: payload };
    default:
      // eslint-disable-next-line no-console
      console.log(action);
      throw new Error();
  }
}
