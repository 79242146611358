export const EXECUTE = 'EXECUTE';
export const SET_CONNECTED = 'SET_CONNECTED';
export const SET_CONNECTING = 'SET_CONNECTING';
export const SET_AUTO_DRIVE = 'SET_AUTO_DRIVE';
export const ADD_CONNECTED_TRACTORS = 'ADD_CONNECTED_TRACTORS';
export const SELECT_CONFIGURE_TRACTOR = 'SELECT_CONFIGURE_TRACTOR';
export const SELECTED_TRACTOR = 'SELECTED_TRACTOR';
export const SELECTED_TRACTORS = 'SELECTED_TRACTORS';
export const LOAD_AVAILABLE_TRACTORS = 'LOAD_AVAILABLE_TRACTORS';
export const SET_SHOW_REMOTE_DRIVE_SETTINGS = 'SET_SHOW_REMOTE_DRIVE_SETTINGS';
export const SET_SHOW_REMOTE_DRIVE_DETAILS = 'SET_SHOW_REMOTE_DRIVE_DETAILS';
export const SET_IMPLEMENT = 'SET_IMPLEMENT';
export const SET_AUTO_DRIVE_SETTINGS = 'SET_AUTO_DRIVE_SETTINGS';
export const SET_SHOW_PRESENT_COMMAND = 'SET_SHOW_PRESENT_COMMAND';
export const SET_SETUP_KEYS = 'SET_SETUP_KEYS';
export const SET_COMMAND_TYPE = 'SET_COMMAND_TYPE';
export const SET_TOGGLE_TIMER = 'SET_TOGGLE_TIMER';
export const LOAD_DRIVING_TRACTORS = 'LOAD_DRIVING_TRACTORS';
export const SHOW_SAVE_COMMAND = 'SHOW_SAVE_COMMAND';
export const SELECTED_IMPLEMENT_NAME = 'SELECTED_IMPLEMENT_NAME';
export const SELECTED_ITEM_NAME = 'SELECTED_ITEM_NAME';
export const SELECTED_VINE_BLOCK = 'SELECTED_VINE_BLOCK';
export const LOAD_RUNNING_TRACTORS = 'LOAD_RUNNING_TRACTORS';
export const SET_PLANNER_STATUS = 'SET_PLANNER_STATUS';
export const SELECTED_WAYPOINT = 'SELECTED_WAYPOINTS';
export const SET_RIGHT_SIDE_TOGGLE = 'SET_RIGHT_SIDE_TOGGLE';
export const SET_RESET_TIMER = 'SET_RESET_TIMER';
export const SET_TRIGGERED_GUARDRAIL = 'SET_TRIGGERED_GUARDRAIL';
export const SET_SHOW_PATH_MODAL = 'SET_SHOW_PATH_MODAL';
export const SET_ADD_STEP = 'SET_ADD_STEP';
export const SET_AUTO_VALUE = 'SET_AUTO_VALUE';
export const RESET_STATE = 'RESET_STATE';
export const SELECTED_ROUTINE = 'SELECTED_ROUTINE';
export const AUTO_DRIVE_MONITOR_ERROR_CODES = 'AUTO_DRIVE_MONITOR_ERROR_CODES';
export const AUTO_DRIVE_ERROR = 'AUTO_DRIVE_ERROR';
export const SHOW_TRACTOR_START = 'SHOW_TRACTOR_START';
export const SNAP_SHOT_INITIATED = 'SNAP_SHOT_INITIATED';
export const SET_SHOW_SNAPSHOT = 'SET_SHOW_SNAPSHOT';
export const AUTO_DRIVE_LOGOUT_ERROR_CODES = 'AUTO_DRIVE_LOGOUT_ERROR_CODES';
export const SET_TIME_LEFT = 'SET_TIME_LEFT';
export const SET_PINNED_TRACTORS = 'SET_PINNED_TRACTORS';
export const SET_SHOW_TERM_AND_COND = 'SET_SHOW_TERM_AND_COND';
export const SET_SHOW_ROUTINE = 'SET_SHOW_ROUTINE';
export const SET_AUTO_DRIVE_INTERRUPT_MESSAGE =
  'SET_AUTO_DRIVE_INTERRUPT_MESSAGE';
export const SET_AUTO_DRIVE_SOFT_INTERRUPT_MESSAGE =
  'SET_AUTO_DRIVE_SOFT_INTERRUPT_MESSAGE';

export const SET_AUTO_DRIVE_MONITOR_POP = 'SET_AUTO_DRIVE_MONITOR_POP';
export const SET_AUTO_DRIVE_POP = 'SET_AUTO_DRIVE_POP';
export const SET_AUTO_DRIVE_FEEDBACK_POP = 'SET_AUTO_DRIVE_FEEDBACK_POP';
export const SET_AUTO_DRIVE_FEEDBACK = 'SET_AUTO_DRIVE_FEEDBACK';
export const SET_AUTO_DRIVE_FEEDBACK_PAYLOAD =
  'SET_AUTO_DRIVE_FEEDBACK_PAYLOAD';
export const SET_SHOW_AUTO_DRIVE = 'SET_SHOW_AUTO_DRIVE';
export const SET_SHOW_MAINTENANCE_LOCK_DRIVE =
  'SET_SHOW_MAINTENANCE_LOCK_DRIVE';
export const SET_SHOW_CONNECT_LOADER = 'SET_SHOW_CONNECT_LOADER';
export const SET_SELECTED_TRACTOR_LOCATION = 'SET_SELECTED_TRACTOR_LOCATION';
