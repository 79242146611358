/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useState } from 'react';
import { Button, Modal, notification } from 'antd';
import { ApplicationContext } from '../../context/AppContext';
import logo from '../../assets/images/logo192.png';
import './style.css';
import { removeTeam } from '../../constants/Api';
import loading_logo from '../../assets/images/loading_logo.gif';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import AppLoader from '../common/AppLoader';

interface Props {
  showModal?: boolean;
  closeModel: (status: boolean) => void;
  teamName: string;
  teamId: number;
}

export const DeleteTeam: React.FC<Props> = ({
  showModal,
  closeModel,
  teamName,
  teamId,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails, initTeams } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);

  const deleteTeam = async () => {
    try {
      setLoader(true);
      const response = await removeTeam(
        userDetails.organization.api_url,
        userDetails.organization.id,
        teamId,
      );
      notification.success({
        message: (
          <span data-testid="teamDeletedSuccessMessage-DeleteTeam">{`${teamName} Deleted Successfully`}</span>
        ),
      });
      initTeams();
      setLoader(false);
      closeModel(true);
    } catch (error: any) {
      if (error.response.data.error.message) {
        notification.error({
          message: error.response.data.error.message,
          duration: 2,
        });
      } else {
        notification.error({ message: 'Unable to delete Team' });
      }
      setLoader(false);
      closeModel(false);
    }
  };

  return (
    <>
      <Modal
        className="modalContainer teammodal discardmodal"
        visible={showModal}
        onCancel={() => closeModel(false)}
        width={590}
        closable={false}
        maskClosable={false}
        footer={[
          <>
            <Button
              data-testid="cancelButton-DeleteTeam"
              key="back"
              className="btnteamCancel captalize Button"
              onClick={() => closeModel(false)}
            >
              Cancel
            </Button>
            <Button
              data-testid="deleteButton-DeleteTem"
              key="submit"
              className="btnteamSave captalize Button"
              onClick={deleteTeam}
            >
              Delete
            </Button>
          </>,
        ]}
      >
        <AppLoader loader={loader} />
        <div className="createteamContainer">
          <div className="createtitle">
            <div className="pull-left">
              <h1
                className="title headline1"
                data-testid="deleteTeamTitle-DeleteTeam"
              >
                Delete Team?
              </h1>
            </div>
            <div className="img-div pull-right">
              <img src={logo} />
            </div>
          </div>
          <p
            className="teamdescription body1"
            data-testid="deleteTeamConfirmPopUp-DeleteTeam"
          >
            Are you sure you want to delete{' '}
            <span className="teambold">{teamName}</span> Team?
          </p>
        </div>
      </Modal>
    </>
  );
};

export default DeleteTeam;
