import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { getJumpAndGoAllDay } from '../../constants/Api';
import { AllDayContent } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import loading_logo from '../../assets/images/loading_logo.gif';
import acrImg from '../../assets/images/acrIcon.svg';
import mphImg from '../../assets/images/mphIcon.svg';
import socImg from '../../assets/images/socIcon.svg';
import { notification } from 'antd';
import AppLoader from '../common/AppLoader';

interface Props {
  datetime: number;
  tractor?: any;
}

const ChartDetails: React.FC<Props> = ({ datetime, tractor }) => {
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [allDayOperation, setAllDayOperation] =
    useState<AllDayContent | null>();

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getAllDayContent();
    }
  }, [datetime, tractor]);

  const getAllDayContent = async () => {
    setAllDayOperation(null);
    try {
      // eslint-disable-next-line no-console
      setLoader(true);
      const jngResponse = await getJumpAndGoAllDay(
        userDetails.organization.api_url,
        userDetails.organization_id,
        tractor ? tractor?.tractorId : 0,
        moment(datetime).startOf('day').toDate().getTime(),
        moment(datetime).endOf('day').toDate().getTime(),
      );
      setAllDayOperation(jngResponse);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      notification.error({
        message: error.message,
      });
    }
  };
  return (
    <div className="operationAnalyticsBlk">
      <div className="text-3xl font-bold">
        Analytics {tractor && `(${tractor?.label})`}
      </div>

      <div className="anarightSec mb10">
        <div>
          <img src={mphImg} alt="" height="30" />
          <h6 className="aHead">Avg Speed</h6>
          <h6>
            <span className="aVal">
              {allDayOperation?.analytics?.avg_speed
                ? `${Number(
                    Number(allDayOperation?.analytics?.avg_speed) * 2.236936,
                  ).toFixed(1)} `
                : '-'}
            </span>
            <span className="aValTxt">Mph</span>
          </h6>
        </div>
        <div>
          <img src={socImg} alt="" height="30" />
          <h6 className="aHead">Energy Used</h6>
          <h6>
            <span className="aVal">
              {allDayOperation?.analytics?.soc_used
                ? `${allDayOperation?.analytics?.soc_used}%`
                : '-'}{' '}
            </span>
            <span className="aValTxt">SOC</span>
            <br />
            <span>
              <span className="aVal">
                {allDayOperation?.analytics?.energy
                  ? `${Number(allDayOperation?.analytics?.energy)
                      .toFixed(2)
                      .toLocaleString()}`
                  : '-'}{' '}
              </span>
              <span className="aValTxt">kwh</span>
            </span>
          </h6>
        </div>
        <div>
          <img src={acrImg} alt="" height="30" />
          <h6 className="aHead">Coverage</h6>
          <h6>
            <span className="aVal">
              {allDayOperation?.analytics?.distance
                ? `${Number(allDayOperation?.analytics?.distance)
                    .toFixed(2)
                    .toLocaleString()} `
                : '-'}
            </span>
            <span className="aValTxt">Miles</span>
          </h6>
        </div>
      </div>

      <div className="anarightSec mb10">
        <div>
          <h6 className="acreshead aHead">Covered Acres</h6>
          <h6>
            <span className="aVal">
              {allDayOperation?.analytics?.area_covered_in_acre
                ? `${Number(allDayOperation?.analytics?.area_covered_in_acre)
                    .toFixed(2)
                    .toLocaleString()} `
                : '-'}
            </span>
            <span className="aValTxt">Acres</span>
          </h6>
        </div>
        <div>
          <h6 className="acreshead aHead">Covered Vine Rows</h6>
          <h6>
            <span className="aVal">
              {allDayOperation?.analytics?.num_of_vine_rows_covered
                ? `${Number(
                    allDayOperation?.analytics?.num_of_vine_rows_covered,
                  ).toFixed(0)}`
                : '-'}
            </span>
          </h6>
        </div>
        <div>
          <h6 className="acreshead aHead">Acres per hour</h6>
          <h6>
            <span className="aVal">
              {allDayOperation?.analytics?.acres_per_hours
                ? `${Number(
                    allDayOperation?.analytics?.acres_per_hours,
                  ).toFixed(2)}`
                : '-'}
            </span>
          </h6>
        </div>
      </div>

      <div className="">
        <div className="opCostSec">
          <h5 className="aHead mb5">Operational Efficiency</h5>
          <div className="dflex aHead2">
            <div className="w52">Electricity Rate</div>
            <div className="w52">
              {allDayOperation?.analytics?.electricity_rate
                ? `$${Number(
                    allDayOperation?.analytics?.electricity_rate,
                  ).toFixed(2)}`
                : '-'}{' '}
              / Kwh
            </div>
          </div>
          <div className="dflex aHead2">
            <div className="w52">Diesel Rate</div>
            <div className="w52">
              {allDayOperation?.analytics?.electricity_rate
                ? `$${Number(allDayOperation?.analytics?.diesel_cost).toFixed(
                    2,
                  )}`
                : '-'}{' '}
              / Gallon
            </div>
          </div>
        </div>

        <div className="opCostSec bbNone">
          <h5 className="aHead mb5">Savings</h5>
          <div className="dflex aHead2">
            <div className="w52">Operational Savings</div>
            <div className="w52">
              {allDayOperation?.analytics?.operational_savings
                ? `$${Number(
                    allDayOperation?.analytics?.operational_savings,
                  ).toFixed(2)}`
                : '-'}
            </div>
          </div>
          <div className="dflex aHead2">
            <div className="w52">
              co<sub>2</sub> Reduction
            </div>
            <div className="w52">
              {allDayOperation?.analytics?.aggregate_emission_reduction
                ? `${Number(
                    allDayOperation?.analytics?.aggregate_emission_reduction,
                  ).toFixed(2)} kg`
                : '-'}
            </div>
          </div>
        </div>
      </div>

      <AppLoader loader={loader} />
    </div>
  );
};

export default ChartDetails;
