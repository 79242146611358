import { Button, Col, Input, notification, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import loading_logo from '../../assets/images/loading_logo.gif';
import { ChangeOrgAdmin, createOrgAdmin } from '../../constants/Api';
import { useHistory, useLocation } from 'react-router';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import RoutesConstants from '../../routes/RoutesConstant';
import reset_password from '../../assets/images/reset_password.svg';
import reject from '../../assets/images/reject.svg';
import success_check from '../../assets/images/success_check.svg';
import { isValidEmail } from '../../constants/Common';

const ChangeAdminEmail: React.FC = () => {
  const { push } = useHistory();
  const location = useLocation();
  const [data, setData] = useState<any>(null);
  const [spinning, setSpinning] = useState(false);
  const [token, setToken] = useState('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [contactNumber, setContactNumber] = useState<string>('');

  const [validMail, setValidMail] = useState(false);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [isSpecialCharactorExist, setIsSpecialCharactorExist] = useState(false);
  const [isNumberExist, setIsNumberExist] = useState(false);
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const specialCharactorFormate = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  const numberFormate = /\d/g;

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = String(query.get('token'));
    if (token) setToken(token);
  }, []);

  useEffect(() => {
    if (token) checkToken();
  }, [token]);

  const checkToken = async () => {
    try {
      setSpinning(true);
      const data: any = await ChangeOrgAdmin(token);
      const { email = '', msg } = data;
      if (data?.page_code && data?.page_code === 10001) {
        const { org_obj } = data;
        const { id } = org_obj;
        setData({
          orgId: id,
          email,
        });
        notification.info({
          message: msg,
        });
      } else {
        push(RoutesConstants.AdminChangeSuccess);
        notification.success({
          message: msg,
        });
      }
    } catch (err: any) {
      notification.error({
        message: err.response?.data?.error?.message || err.message,
      });
    } finally {
      setSpinning(false);
    }
  };

  const handleChangeAdmin = async () => {
    const { orgId, email } = data;
    const payload = {
      email: email,
      organization_id: orgId,
      token: token,
      password: password,
      first_name: firstName,
      last_name: lastName,
      contact_number: contactNumber,
    };
    try {
      setSpinning(true);
      const { msg = 'user created and assigned as org admin successfully' } =
        await createOrgAdmin(payload);
      if (msg) {
        push(RoutesConstants.AdminChangeSuccess);
        notification.success({
          message: msg,
        });
      } else {
        notification.error({
          message: msg,
        });
        push(RoutesConstants.Login);
      }
    } catch (err: any) {
      notification.error({
        message: err.response?.data?.error?.message || err.message,
      });
    } finally {
      setSpinning(false);
    }
  };

  useEffect(() => {
    if (isValidEmail(data?.email)) {
      setValidMail(true);
    } else {
      setValidMail(false);
    }
    if (password.trim().length > 7 && password.trim().length < 15) {
      setIsLengthValid(true);
    } else {
      setIsLengthValid(false);
    }
    if (specialCharactorFormate.test(password.trim())) {
      setIsSpecialCharactorExist(true);
    } else {
      setIsSpecialCharactorExist(false);
    }
    if (numberFormate.test(password.trim())) {
      setIsNumberExist(true);
    } else {
      setIsNumberExist(false);
    }
  }, [data, password]);

  useEffect(() => {
    if (
      firstName &&
      firstName.trim().length > 2 &&
      lastName &&
      lastName.trim().length > 0 &&
      validMail &&
      isLengthValid &&
      isSpecialCharactorExist &&
      isNumberExist
    ) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [
    firstName,
    lastName,
    validMail,
    isLengthValid,
    isSpecialCharactorExist,
    isNumberExist,
  ]);

  return (
    <>
      <Spin
        className="loader"
        spinning={spinning}
        indicator={
          <img style={{ width: '40px', height: '40px' }} src={loading_logo} />
        }
      >
        <div className="loginContainer">
          <div className="resetWrap">
            <Row wrap={false}>
              <div className="loginLeft w48">
                <Col flex="436px">
                  <img
                    height="60px"
                    width="60px"
                    alt="monarch"
                    src={reset_password}
                  />
                  <p className="mtb30">Set Your Details</p>
                  <div className="resetCont">
                    <div className="mb40">
                      In order to protect you account, make sure email &
                      password:
                    </div>
                    <div className="resetContChar mb30">
                      <img src={validMail ? success_check : reject} />
                      Is Valid Email
                    </div>
                    <div className="resetContChar mb30">
                      <img src={isLengthValid ? success_check : reject} />
                      Is 8 to 14 characters long
                    </div>
                    <div className="resetContChar mb30">
                      <img
                        src={isSpecialCharactorExist ? success_check : reject}
                      />
                      Contains a special character
                    </div>
                    <div className="resetContChar mb30">
                      <img src={isNumberExist ? success_check : reject} />
                      Contains a number
                    </div>
                  </div>
                </Col>
              </div>

              <div className="loginRight w52">
                <Col>
                  <div className="loginFRow">
                    {/* <label className="labelTxt userId">First Name</label> */}
                    <Input
                      placeholder="First Name"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>

                  <div className="loginFRow">
                    {/* <label className="labelTxt userId">Last Name</label> */}
                    <Input
                      placeholder="Last Name"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>

                  <div className="loginFRow">
                    {/* <label className="labelTxt userId">Email</label> */}
                    <Input
                      placeholder={data?.email}
                      // value={data?.email}
                      disabled
                      // onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="loginFRow">
                    {/* <label className="labelTxt userId">Password</label> */}
                    <Input.Password
                      autoComplete="new-password"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      type="text"
                      iconRender={(visible) =>
                        visible ? (
                          <EyeTwoTone style={{ color: '#fff' }} />
                        ) : (
                          <EyeInvisibleOutlined style={{ color: '#fff' }} />
                        )
                      }
                    />
                  </div>

                  <div className="loginFRow">
                    {/* <label className="labelTxt userId">Contact Number</label> */}
                    <Input
                      placeholder="Contact Number (optional)"
                      type="number"
                      onChange={(e) => setContactNumber(e.target.value)}
                    />
                  </div>
                  <Button
                    type="primary"
                    onClick={handleChangeAdmin}
                    className="btnTxt mt20"
                    disabled={isDisable}
                  >
                    Change Admin Mail
                  </Button>
                </Col>
              </div>
            </Row>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default ChangeAdminEmail;
