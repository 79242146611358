import React from 'react';
import { useState, useEffect, memo } from 'react';
import FlagGrayIcon from '../../assets/images/flag_gray.svg';
import FlagOrangeIcon from '../../assets/images/flag_orange.svg';
import FlagRedIcon from '../../assets/images/flag_red.svg';
import './style.css';

const levels = [
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
];
interface Props {
  placeholder?: string;
  label?: string;
  // list?: any[];
  isAll?: boolean;
  defaultId?: string;
  keyValue?: string;
  keyLabel?: string;
  selectTrigger: (data: any) => void;
  filterType?: number;
}

const LevelFilter: React.FC<Props> = ({
  label = 'Severity Level',
  placeholder = 'All Severity Levels',
  isAll = true,
  defaultId = 1,
  keyValue = 'id',
  keyLabel = 'name',
  selectTrigger,
  filterType,
}) => {
  const [sel1, setSel1] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>(null);
  const [farr, setFarr] = useState<any>([]);
  const [arr, setArr] = useState<any>([]);

  const getSeverityIcon = (level: number, orderNumber: number) => {
    if (level >= 3) {
      return FlagRedIcon;
    }
    if (orderNumber <= level) {
      return FlagOrangeIcon;
    }
    return FlagGrayIcon;
  };

  useEffect(() => {
    if (levels.length > 0) {
      const list = levels.map((item: any) =>
        Object.assign({
          [keyValue]: item[keyValue],
          [keyLabel]: item[keyLabel],
        }),
      );
      setArr(list);
    }
  }, [levels]);

  const handleSelect = (id: string) => {
    setSel1(false);
    const [selected] = arr.filter(
      (item: any) => String(item[keyValue]) == String(id),
    );
    setSelected(selected);
    const [selected1] = levels.filter(
      (item: any) => String(item[keyValue]) == String(id),
    );
    selectTrigger(selected1);
  };

  useEffect(() => {
    if (arr.length > 0 && defaultId) handleSelect(String(defaultId));
  }, [defaultId, arr]);

  useEffect(() => {
    if (selected && isAll && filterType !== 3) {
      const tarr = [{ [keyValue]: '', [keyLabel]: placeholder }, ...arr];
      setFarr(tarr);
    } else if (selected && !isAll) {
      const tarr = arr.filter(
        (item: any) => item[keyValue] != selected[keyValue],
      );
      setFarr(tarr);
    } else {
      setFarr(arr);
    }
  }, [sel1, selected]);

  const getIcon = (item: any) => {
    return (
      <>
        <img className="mr11" src={getSeverityIcon(item[keyValue], 1)} />
        <img className="mr11" src={getSeverityIcon(item[keyValue], 2)} />
        <img className="mr11" src={getSeverityIcon(item[keyValue], 3)} />
        {item[keyLabel] === '1'
          ? 'Low'
          : item[keyLabel] === '2'
          ? 'Medium'
          : 'High'}
      </>
    );
  };

  const buttonGroupPressed = (e: any) => {
    e.stopPropagation();
    const isButton = e.target.closest('.flbBlock');
    if (!isButton) {
      setSel1(false);
      return;
    }
  };

  useEffect(() => {
    const buttonGroup: any = document.querySelector('body');
    buttonGroup.addEventListener('click', buttonGroupPressed);
    return () => {
      buttonGroup.removeEventListener('click', buttonGroupPressed);
    };
  }, []);

  const handleCommonClick = (e: any) => {
    const isButton = e.target?.closest('.flbBlock');
    const ID = isButton?.getAttribute('id');
    const elements: any = document.querySelectorAll('.flbBlock.selectOpen');
    for (const element of elements) {
      const id = element.getAttribute('id');
      if (id !== ID) element?.click();
    }
  };

  return (
    <div>
      <div
        className={
          selected && isAll && filterType !== 3
            ? 'flblWrapper all-select sel180'
            : 'flblWrapper sel180'
        }
      >
        <div
          className={sel1 ? 'flbBlock selectOpen' : 'flbBlock'}
          id={`CSELECT-${label}`}
          onClick={(e: any) => {
            e.stopPropagation();
            handleCommonClick(e);
            setSel1(!sel1);
          }}
        >
          {/* <input type="checkbox" id="chck1" /> */}
          <label
            className={selected ? 'tab-label' : 'tab-label selNor'}
            htmlFor="chck1"
          >
            <div className={selected ? 'flbSelCont' : 'flbSelCont selNor'}>
              {selected && selected?.[keyValue] !== '' && (
                <div className="flbTopFixed">{label}</div>
              )}

              {selected && selected?.[keyValue] !== '' ? (
                <div className="flbSelected">{getIcon(selected)}</div>
              ) : (
                <div className="flbSelected selGray">{label}</div>
              )}
            </div>
          </label>
          {sel1 && (
            <div className="tab-content">
              <div className="flbContLine"></div>
              <ul className="flbUL">
                {farr.map((item: any, i: number) => {
                  // eslint-disable-next-line react/jsx-key
                  return (
                    <li
                      className={
                        selected?.[keyValue] === item[keyValue] &&
                        item?.[keyValue] != '' &&
                        isAll
                          ? 'item sel'
                          : 'item'
                      }
                      data-testid={`${item.name}-LevelSeverity`}
                      key={i}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelect(item[keyValue]);
                      }}
                    >
                      {item && item?.[keyValue] !== ''
                        ? getIcon(item)
                        : item?.[keyLabel]}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(LevelFilter);
