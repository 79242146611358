/* eslint-disable react/jsx-no-undef */
import { Avatar, Button, Input, notification, Spin } from 'antd';
import Layout from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import './style.css';
import closeGreen from '../../assets/images/closeGreen.svg';
import baseStationImg from '../../assets/images/base_station.svg';
import { UpdateBasestation } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import applicationIds from '../../locale/applicationIds.json';
import { useMixpanel } from 'react-mixpanel-browser';
import { privilagesConstants } from '../../constants/Privilages';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';

interface Props {
  baseobj: any;
  Close: () => void;
  setRefresh: () => void;
  baseflag: boolean;
  baseStationNames: string[];
}
const Editbasestation: React.FC<Props> = ({
  baseobj,
  Close,
  setRefresh,
  baseflag,
  baseStationNames,
}: Props) => {
  const [editflag, seteditflag] = useState<boolean>();
  const { t } = useTranslation();
  const [Name, setName] = useState('');
  const { userDetails, privilegeChecker } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (baseobj) setName(baseobj.name);
    seteditflag(true);
  }, [baseobj]);
  const mixpanel = useMixpanel();

  const Editdetails = () => {
    seteditflag(false);
    mixpanel.track('Edit BaseStation', {
      event: 'Edit BaseStation Details Clicked',
    });
  };

  const checkDuplicate = () =>
    baseStationNames.filter((item) => item !== baseobj.name).includes(Name);

  const saveBasestation = async () => {
    const data = {
      name: Name,
    };
    if (checkDuplicate())
      return notification.error({
        message: 'basestaion name alredy exits!',
      });
    try {
      setLoader(true);
      await UpdateBasestation(
        userDetails.organization.api_url,
        userDetails.organization_id,
        baseobj?.basestation_id,
        data,
      );
      baseobj.name = Name;
      notification.success({ message: t(translate.label.baseStationUpdated) });
      seteditflag(true);
      setRefresh();
      mixpanel.track('Edit BaseStation', {
        event: 'BaseStation Edited Successfully',
      });
    } catch (err: any) {
      mixpanel.track('Edit BaseStation', {
        event: 'Edit BaseStation failed',
      });
      notification.error({
        message: err.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (baseflag ? 'expand' : 'hide')}>
        {editflag && (
          <div className="closeButton mb55">
            <img
              id={applicationIds.baseStationScreen.baseStationCloseBtn}
              src={closeGreen}
              alt="close icon"
              onClick={Close}
              data-testid="baseStationModelCloseIcon-Editbasestation"
            />
          </div>
        )}
        {editflag && (
          <div className="basestationWidget">
            <div className="profileView">
              <div className="proRow tractorPic mb48">
                <Avatar
                  style={{ height: '117px', width: '77px' }}
                  src={baseStationImg}
                  alt=""
                ></Avatar>
              </div>
              <div className="proRow mb73">
                <h4 className="proRoleHead baseStationName basename">
                  {baseobj?.name}
                </h4>
              </div>
              <div>
                <table className="fleetTbl basestationTbl">
                  <tr>
                    <td className="flHead">PIN</td>
                    <td>{baseobj?.bsin}</td>
                  </tr>
                </table>
              </div>
            </div>

            {privilegeChecker(
              privilagesConstants.Edit_base_station_information,
            ) && (
              <div className="proeditBtn">
                <button
                  id={applicationIds.baseStationScreen.editDetails}
                  onClick={Editdetails}
                  data-testid="baseStationEditDetailsLink-EditBaseStation"
                >
                  EDIT DETAILS
                </button>
              </div>
            )}
          </div>
        )}
        {!editflag && (
          <div className="profileEdit height100 editBasestationWidget">
            {/* <div className="proRow mb24">
              <h4 className="proRoleHead">Edit Base Station</h4>
            </div> */}
            <div className="proRow">
              <div className="proRow tractorPic mb64">
                <Avatar
                  style={{ height: '117px', width: '77px' }}
                  src={baseStationImg}
                  alt=""
                ></Avatar>
              </div>
            </div>
            <div className="proRow">
              <h4 className="proInput">
                <div className="fleetSwVer mlb baseStationTitle">
                  Base Station Name
                </div>
                <Input
                  id={applicationIds.baseStationScreen.editBaseStation}
                  placeholder="Name"
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                  data-testid="basestationEditInputField-EditBasestation"
                />
              </h4>
            </div>
            <div>
              <table className="fleetTbl basestationTbl">
                <tr>
                  <td className="flHead basePin">PIN</td>
                  <td>{baseobj?.bsin}</td>
                </tr>
              </table>
            </div>
            <div className="edit-loader">
              {loader && (
                <div className="loader">
                  <Spin size="large" />
                </div>
              )}
            </div>
            <div className="newFleetBtns basebtns">
              <Button className="fleetCancel" onClick={Close}>
                Cancel
              </Button>
              <Button
                className="fleetSave"
                id={applicationIds.baseStationScreen.saveBaseStation}
                onClick={saveBasestation}
                disabled={baseobj.name === Name || Name.length == 0}
                data-testid="basestationSaveButton-EditBasestation"
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Editbasestation;
