/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/display-name */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Row, notification, Space, Input, DatePicker, Tag } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import {
  getFleetDiagnostics,
  getOrgErrorCodesData,
  getOrgSubsystemsData,
  getTractorDataById,
  getTractorIndicatorSummaryData,
  getTractorsListData,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { fleetObject, TractorsList } from '../../constants/types';
import {
  batteryValue,
  fromToDateWrapper,
  getBatteryPercentage,
  getConnectivityImg,
  getDateTimes,
  getTractorAvailableState,
  initScroller,
  tractorSort,
} from '../../constants/Common';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import 'react-datepicker/dist/react-datepicker.css';
import autonomyFull from '../../assets/images/autonomy_n_full.svg';
import battery_charger from '../../assets/images/battery_charger.svg';
import driveability from '../../assets/images/driveability.svg';
import hitch from '../../assets/images/hitch1.svg';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import './style.css';
import DiagnosticWidget from './DiagnosticWidget';
import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import successCheck from '../../assets/images/success_check.svg';
import alertIcon from '../../assets/images/alert_icon.svg';
import failCross from '../../assets/images/fail_cross.svg';
import FlagGrayIcon from '../../assets/images/flag_gray.svg';
import FlagOrangeIcon from '../../assets/images/flag_orange.svg';
import FlagRedIcon from '../../assets/images/flag_red.svg';
import autonomyNewW from '../../assets/images/autonomyNewW.svg';
import battery_chargerW from '../../assets/images/battery_chargerW.svg';
import hitchImpW from '../../assets/images/hitchImpW.svg';
import hDriveabilityW from '../../assets/images/hDriveabilityW.svg';

import autonomyNewG from '../../assets/images/au_gray.svg';
import battery_chargerG from '../../assets/images/bc_gray.svg';
import hitchImpG from '../../assets/images/hi_gray.svg';
import hDriveabilityG from '../../assets/images/db_gray.svg';
import tractorImg from '../../assets/images/tractorImg.png';
import moment from 'moment';
import Battery from '../common/Battery';
import { useMixpanel } from 'react-mixpanel-browser';
import constant, { STATUS_LIST } from '../../constants/constant';
import { FleetHealthCxt } from './FleetHealthContext';
import './style.css';
import { RESET_NAVIGATE } from '../../context/actions';
import CSelect from '../common/CSelect';
import { resolveFilterData1 } from '../../constants/AppData';
import LevelFilter from '../common/LevelFilter';
import { delay } from '../../constants/Common';
import TicketsCreateEdit from '../tickets/TicketsCreateEdit';

const { RangePicker } = DatePicker;
const { Search } = Input;

const {
  TRACTOR_COMMUNICATION,
  TRACTOR_AUTOMATION,
  TRACTOR_DRIVABILITY,
  HITCH_AND_IMPLEMENT,
  TRACTOR_ENERGY,
} = constant;

interface TractorResponseType {
  label: string;
  value: number | string | boolean;
  disabled: boolean;
  index: number | string;
  isAvailable?: string;
}

interface Props {
  tractorID?: number;
}
export const getTags = (details: any) => {
  const { functionality_affected } = details;
  let tags = <></>;
  if (functionality_affected && functionality_affected.trim() !== '') {
    tags = functionality_affected
      .split(functionality_affected.includes(';') ? ';' : ',')
      .map((element: string, key: number) => (
        <Tag key={key} className="tagsName">
          {element}
        </Tag>
      ));
  }
  return tags;
};
export const getSeverity = (level: number) => {
  let images;
  switch (level) {
    case 1:
      images = (
        <>
          <img src={FlagOrangeIcon} className="mr11" />
          <img src={FlagGrayIcon} className="mr11" />
          <img src={FlagGrayIcon} className="mr11" />
        </>
      );
      break;
    case 2:
      images = (
        <>
          <img src={FlagOrangeIcon} className="mr11" />
          <img src={FlagOrangeIcon} className="mr11" />
          <img src={FlagGrayIcon} className="mr11" />
        </>
      );
      break;
    case 3:
      images = (
        <>
          <img src={FlagRedIcon} className="mr11" />
          <img src={FlagRedIcon} className="mr11" />
          <img src={FlagRedIcon} className="mr11" />
        </>
      );
      break;
    default:
      images = (
        <>
          <img src={FlagGrayIcon} className="mr11" />
          <img src={FlagGrayIcon} className="mr11" />
          <img src={FlagGrayIcon} className="mr11" />
        </>
      );
      break;
  }
  return <Space>{images}</Space>;
};
const Diagnostics: React.FC<Props> = ({ tractorID }: Props) => {
  const { t } = useTranslation();
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [state, dispatch] = APPReducer;
  const { navigate } = state;
  let routeData: any;
  if (navigate) {
    routeData = navigate.data;
  }
  const { ticketRefreshStatus, SetTicketRefreshStatus } =
    useContext(FleetHealthCxt);
  const [filterData, setFilter] = useState<fleetObject[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPagesize] = useState<any>(25);
  const [tractors, setTractorsData] = useState<TractorResponseType[]>([]);
  const [tractorsHeartbeatData, setTractorsHeartbeatData] = useState<
    TractorResponseType[]
  >([]);
  const [tractorId, setTractorId] = useState<number>(tractorID || 0);
  const [toDateTime, setToDateTime] = useState<any>(null);
  const [totalCount, setTotalCount] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [toggleWidget, setToggleWidget] = useState(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isEdit, setIsEdit] = useState(false);
  const [errorDetails, setErrorDetails] = useState<any>();
  const [subSystem, setSubSystem] = useState<any>();
  const [indicatorType, setIndicatorType] = useState<any>();
  const [level, setLevel] = useState<string>('');
  const mixpanel = useMixpanel();
  const [status, setStatus] = useState<string>('unresolved');
  const [indicators, setIndicators] = useState<any>({});
  const [tractor, setTractor] = useState<any>(null);
  const [toggleTicketWidget, setToggleTicketWidget] = useState<any>(false);
  const [ticketDetails, setTicketDetails] = useState(null);
  const [tractorDetails, setTractorDetails] = useState<TractorsList>();
  const [errorCodes, setErrorCodes] = useState<any>([]);
  const [subSystems, setSubSystems] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'indicator_level',
    order: 'descend',
  });
  const [sortedData, setSortedData] = useState<any>({
    modified_date_time: 'descend',
  });
  const [dateFilter, setDateFilter] = useState<any>([]);
  const [keyCode, setKeyCode] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);
  const activity = useRef<string>('');

  const checkActivity = () =>
    ['search', 'sort', 'filter'].includes(activity.current);

  const columns = [
    {
      title: () => {
        return (
          <>
            {t(translate.headers.severity)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'indicator_level' && (
                <img
                  src={
                    sortedData['indicator_level'] === 'ascend'
                      ? sortUp
                      : sortDown
                  }
                />
              )}
            </span>
          </>
        );
      },

      sortDirections: ['descend', 'ascend', 'descend'],
      width: '13%',
      dataIndex: 'severity',
      key: 'indicator_level',
      render: (severity: number) => {
        return getSeverity(severity);
      },
      sorter: (a: any, b: any) => {
        return a.severity - b.severity;
      },
      sortOrder: sortedInfo.columnKey === 'indicator_level' && sortedInfo.order,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: () => {
        return (
          <>
            {t(translate.headers.dateTime)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'modified_date_time' && (
                <img
                  className="sort-pad"
                  src={
                    sortedData['modified_date_time'] === 'ascend'
                      ? sortUp
                      : sortDown
                  }
                />
              )}
            </span>
          </>
        );
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      dataIndex: 'modified_date_time',
      key: 'modified_date_time',
      width: '18%',
      sorter: (a: any, b: any) => {
        return a.modified_date_time - b.modified_date_time;
      },
      sortOrder:
        sortedInfo.columnKey === 'modified_date_time' && sortedInfo.order,
      ellipsis: true,
      showSorterTooltip: false,
    },

    {
      title: `${t(translate.tractorDetails.errorcode)}`,
      dataIndex: 'error_code',
      key: 'error_code',
      width: '14%',
      render: (error_code: any) => <>{error_code}</>,
    },
    {
      title: `${t(translate.headers.indicatorType)}`,
      width: '18%',
      dataIndex: 'indicator_type',
      key: 'indicator_type',
    },
    {
      title: `${t(translate.headers.issue)}`,
      width: '18%',
      dataIndex: 'subsystem',
      key: 'subsystem',
      render: (subsystem: string) => {
        return <span className="description">{subsystem}</span>;
      },
    },
    {
      title: `${t(translate.myTask.description)}`,
      dataIndex: 'description',
      key: 'description',
      // width: '200px',
      ellipsis: true,
      render: (description: string) => {
        return (
          <div className="description">
            <div className="description-text">{description}</div>
          </div>
        );
      },
    },
  ];

  const getTractorDetails = async (tractorId: number) => {
    const tractor: { records: TractorsList[] } = await getTractorDataById(
      userDetails.organization.api_url,
      userDetails.organization_id,
      tractorId,
    );
    const [tractorData] =
      tractor && tractor.records && tractor.records.length > 0
        ? tractor.records
        : [];
    tractorData['isOnline'] =
      tractorData.connection === 'online' ? true : false;
    const { connection } = tractorData;
    setTractorDetails(tractorData);
    setTractor({ ...tractor, isOnline: tractorData['isOnline'], connection });
  };

  const getOrgErrorCodes = async () => {
    const result: any = await getOrgErrorCodesData(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );
    const errorCodesData = result && result.length > 0 ? result : [];

    const data = errorCodesData.map((item: any) =>
      Object.assign({
        label: item.indicator_error_code,
        value: item.indicator_error_code,
      }),
    );
    setErrorCodes(data);
  };

  const getOrgSubsystems = async () => {
    const result: any = await getOrgSubsystemsData(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );
    const subSystemsData = result && result.length > 0 ? result : [];
    const data = subSystemsData.map((item: any) =>
      Object.assign({
        label: item.subsystem,
        value: item.id,
      }),
    );
    setSubSystems(data);
  };

  const initDefaults = async () => {
    if (userDetails && userDetails.organization) {
      getOrgErrorCodes();
      getOrgSubsystems();
      getTractorsList();
    }
  };

  const fleetIndicatorHistoryList = async (sort = false) => {
    if (checkActivity()) initScroller();
    if (keyCode) {
      await delay(1000);
    }
    const tstatus = handleRouteValidation();
    if (!tstatus) return;
    const { organization } = userDetails;
    try {
      const fromDateTime = dateFilter
        ? dateFilter[0] && moment(dateFilter[0]).startOf('day').toDate()
        : '';
      const toDateTime = dateFilter
        ? dateFilter[1] && moment(dateFilter[1]).endOf('day').toDate()
        : '';
      setLoading(true);
      const fleetView = await getFleetDiagnostics(
        organization.api_url,
        tractorId ? tractorId : 0,
        status ? status : '',
        indicatorType ? indicatorType : '',
        level ? level : '',
        subSystem ? subSystem : '',
        fromDateTime ? fromDateTime.getTime() : '',
        toDateTime ? toDateTime.getTime() : '',
        pageNumber,
        pageSize,
        search ? search : '',
        sortedInfo,
      );
      const { _metadata } = fleetView;
      setTotalCount(_metadata?.total_records_count);
      setTotalPages(_metadata.total_pages);
      let data =
        fleetView && fleetView.records && fleetView.records.length > 0
          ? fleetView.records
          : [];
      data = data.map((record: any) => {
        const {
          description = '',
          subsystem = '',
          functionality_affected = '',
        } = record.error_code_data ? record.error_code_data : {};
        const {
          error_code,
          created_date_time,
          indicator_level,
          modified_date_time,
        } = record;
        const obj = {
          id: record.id,
          start_time: created_date_time ? getDateTimes(created_date_time) : '-',
          error_code,
          severity: indicator_level,
          subsystem: subsystem || '-',
          description: description || '-',
          indicator_type: record.error_code_data?.indicator || '-',
          created_date_time,
          modified_date_time: modified_date_time
            ? getDateTimes(modified_date_time)
            : '-',
          functionality_affected: functionality_affected
            ? functionality_affected
            : '',
          counter_measure: record?.error_code_data?.counter_measure
            ? record?.error_code_data?.counter_measure?.split(';')
            : '',
          tractor_id: record.tractor_id,
        };
        return obj;
      });
      setHasMore(data.length === pageSize);

      if (checkActivity()) setFilter(data);
      else if (activity.current === 'paginate')
        setFilter([...filterData, ...data]);
      else setFilter(data);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const wrapIndictorsData = (arr: any) => {
    const data = arr.reduce((a: any, c: any) => {
      a[c.indicator_key] = c.indicator_level;
      return a;
    }, {});
    return data;
  };

  const getTractorIndicatorSummary = async () => {
    try {
      const data = await getTractorIndicatorSummaryData(
        userDetails.organization.api_url,
        tractorId,
      );
      const indicatorsData = wrapIndictorsData(data);
      setIndicators(indicatorsData);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getTractorsList = async () => {
    try {
      const tractor: { records: TractorsList[] } = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractorData: TractorResponseType[] = data.map((d: TractorsList) => {
        return {
          label: d.name,
          value: d.id,
          disabled: false,
          index: d.id,
          id: d.id,
          connection: d.connection,
          isOnline: d.connection === 'online' ? true : false,
          isAvailable: getTractorAvailableState(d.connection, d?.heartbeat),
        };
      });
      const arr = tractorSort(tractorData, 'label');
      setTractorsHeartbeatData(arr);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };

  const handleSelect = async (e: any) => {
    setTractorId(e);
    mixpanel.track('Diagnostics', {
      event: `Select Tractor  ${e}`,
    });
  };

  const levelSelect = (e: string) => {
    setLevel(e);
    mixpanel.track('Diagnostics', {
      event: `Select Level  ${e}`,
    });
  };
  const selectIndicatorType = (e: string) => {
    setIndicatorType(e);
    mixpanel.track('Diagnostics', {
      event: `Select Indicator  ${e}`,
    });
  };
  const subSystemSelect = (e: string) => {
    setSubSystem(e);
    mixpanel.track('Diagnostics', {
      event: `Select SubSystem  ${e}`,
    });
  };
  const statusSelect = (e: string) => {
    setStatus(e);
    mixpanel.track('Diagnostics', {
      event: `Select Status  ${e}`,
    });
  };

  const onDateRangeChange = (dates: any) => {
    if (dates) {
      const [from, to] = fromToDateWrapper(dates);
      setToDateTime(to.toDate());
      mixpanel.track('Diagnostics', {
        event: `Select From Date ${from.toDate()} & To Date ${to.toDate()}`,
      });
    } else {
      setToDateTime(null);
      mixpanel.track('Diagnostics', {
        event: 'Select From Date & To Date Empty',
      });
    }
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    activity.current = 'sort';
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim().regX;
    if (value.length <= 2) {
      if (search != '') {
        setFilter([]);
      }
      setSearch('');
    } else {
      setSearch(value);
      mixpanel.track('Diagnostics', {
        event: `Search Ticket Num : ${value}`,
      });
    }
  };

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current > moment();
  }

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const getRow = (record: any) => {
    setErrorDetails(record);
    setToggleWidget(true);
    mixpanel.track('Diagnostics details', {
      event: 'Diagnostics Details',
    });
  };

  const getCrossCheck = (state: number) => {
    if (state == 0 || state == 1) {
      return <img src={successCheck} height="21px" width="21px" />;
    } else if (state == 2) {
      return <img src={alertIcon} height="21px" width="21px" />;
    } else if (state == 3) {
      return <img src={failCross} height="21px" width="21px" />;
    }
  };

  // ticket creation

  const onCreateTicket = () => {
    setTicketDetails(errorDetails);
    setToggleWidget(false);
    setToggleTicketWidget(true);
    mixpanel.track('CreateTicket', {
      event: 'Create Ticket',
    });
  };

  const onCloseTicketWidget = () => {
    setToggleWidget(true);
    setToggleTicketWidget(false);
    SetTicketRefreshStatus(!ticketRefreshStatus);
  };

  const handleIndicatorTypes = (indType: string) => {
    if (indType === indicatorType) {
      selectIndicatorType('');
    } else {
      selectIndicatorType(indType);
    }
  };

  const handleRouteValidation = () => {
    if (routeData && dateFilter) {
      const { event_date_time } = routeData;
      return (
        dateFilter[0]?.startOf('day').toDate().getTime() ===
        moment(event_date_time).startOf('day').toDate().getTime()
      );
    }
    return true;
  };

  useEffect(() => {
    if (dateFilter && dateFilter.length > 0) {
      onDateRangeChange(dateFilter);
    }
    if (dateFilter === null) {
      setToDateTime('');
    }
  }, [dateFilter]);

  useEffect(() => {
    if (tractorsHeartbeatData.length > 0) {
      setTractorsData(tractorsHeartbeatData);
    }
  }, [tractorsHeartbeatData]);

  useEffect(() => {
    if (routeData) {
      const { event_date_time, event_level } = routeData;
      const dates = [
        moment(event_date_time).startOf('day'),
        moment(event_date_time).endOf('day'),
      ];
      setDateFilter(dates);
      levelSelect(event_level);
    }
  }, [routeData]);

  useEffect(() => {
    if (filterData.length && routeData) {
      const [temp] = filterData.filter(
        (ticket) => ticket.id == routeData.ref_uuid,
      );
      if (temp) getRow(temp);
    }
    if (routeData)
      dispatch({
        type: RESET_NAVIGATE,
      });
  }, [filterData]);

  useEffect(() => {
    initDefaults();
  }, [userDetails]);

  useEffect(() => {
    if (tractors.length) {
      setTractorId(
        routeData
          ? routeData.tractor.id
          : tractorID
          ? tractorID
          : Number(tractors[0]['index']),
      );
      setInit(true);
    }
    return () => {
      setTractorId(0);
    };
  }, [tractors]);

  useEffect(() => {
    if (userDetails && userDetails.organization && tractorId) {
      activity.current == 'filter';
      getTractorIndicatorSummary();
      getTractorDetails(tractorId);
      handleTractorStatus(tractorId);
    }
  }, [tractorId]);

  useEffect(() => {
    if (init) {
      activity.current = 'filter';
      loadAlerts();
    }
  }, [level, subSystem, indicatorType, status, toDateTime]);

  const loadAlerts = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : fleetIndicatorHistoryList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      fleetIndicatorHistoryList();
    }
  };

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      sortedInfo.order &&
      tractorId
    ) {
      loadAlerts();
    }
  }, [sortedInfo]);

  useEffect(() => {
    if (userDetails && userDetails.organization && tractorId && init) {
      loadAlerts();
    }
  }, [userDetails, pageNumber, tractorId, search]);

  const handleTractorStatus = (tractorId: string | number) => {
    if (tractorId) {
      const tractor: any = tractors.filter(
        (item: any) => item.id == tractorId,
      )[0];
      setTractor(tractor);
    }
  };

  const handleKey = (e: any) => {
    setKeyCode(false);
    if (e.keyCode === 8) {
      setKeyCode(true);
    }
  };

  return (
    <Layout>
      <DiagnosticWidget
        isEdit={isEdit}
        details={errorDetails}
        setIsEdit={setIsEdit}
        setToggleWidget={setToggleWidget}
        getSeverity={getSeverity}
        getTags={getTags}
        onCreateTicket={onCreateTicket}
        toggleWidget={toggleWidget}
        tractor={tractorDetails}
      />

      {ticketDetails && toggleTicketWidget && (
        <TicketsCreateEdit
          tractorId={tractorId}
          Close={onCloseTicketWidget}
          ticketObj={ticketDetails}
          afterSave={onCloseTicketWidget}
          toggleWidget={toggleTicketWidget}
        ></TicketsCreateEdit>
      )}

      <Content>
        <div className="tblContainer">
          <Row className="w100per">
            <div className="flex rowGap40 w100per">
              <div className="item mr20">
                <div className="mb16 selectTractorDiag1">
                  <CSelect
                    list={tractors.map((item) =>
                      Object.assign({
                        id: item.index,
                        name: item.label,
                        isAvailable: item.isAvailable,
                      }),
                    )}
                    selectTrigger={(selected) => {
                      handleSelect(selected ? selected?.id : '');
                    }}
                    placeholder=""
                    label="Tractors"
                    defaultId={String(tractorId)}
                    tractorStatus={true}
                    className="tractor-status"
                  />
                </div>
                <div className="flex-bg battConnBlk">
                  <div>
                    <div
                      className="center-text battConnTxt"
                      data-testid="batteryText-Diagnostics"
                    >
                      Battery
                    </div>
                    <div className="btSecTxt diagbattery">
                      <Battery
                        battery={getBatteryPercentage(tractorDetails)}
                      ></Battery>
                      <span className="center-text battConnPer">
                        {batteryValue(tractorDetails)}%
                      </span>
                    </div>
                  </div>
                  <div
                    className="pointer"
                    onClick={() =>
                      handleIndicatorTypes('Tractor_Communication')
                    }
                  >
                    <div
                      className="center-text battConnTxt"
                      data-testid="connectivityText-Diagnostics"
                    >
                      Connectivity
                    </div>
                    <div
                      className={
                        indicatorType === 'Tractor_Communication'
                          ? 'pointerEvents'
                          : 'pointerEventsDefault'
                      }
                    >
                      <img
                        width="35"
                        className="indicatorImg"
                        src={getConnectivityImg(
                          indicators[TRACTOR_COMMUNICATION],
                          indicatorType === 'Tractor_Communication',
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="flex-1 flex-bg tractorHealthBlk"
                style={{ marginTop: '0' }}
              >
                <b
                  className="tractorHealthTitle overline2"
                  data-testid="tractorHealthHeader-Diagnostics"
                >
                  Tractor Health <span>({tractor && tractor.connection})</span>
                </b>
                <div className="flex mt32 tractorHealthGrid">
                  <div
                    className="pointer"
                    onClick={() => handleIndicatorTypes('Tractor_Drivability')}
                  >
                    <div
                      className={
                        indicatorType === 'Tractor_Drivability'
                          ? 'pointerEvents'
                          : 'pointerEventsDefault'
                      }
                    >
                      <img
                        className="indicatorImg"
                        src={
                          indicatorType === 'Tractor_Drivability'
                            ? hDriveabilityW
                            : tractor?.isOnline
                            ? driveability
                            : hDriveabilityG
                        }
                      />
                    </div>

                    <div
                      className="center tractorHealthTxt overline1"
                      data-testid="drivabilityHeader-Diagnostics"
                    >
                      {getCrossCheck(indicators[TRACTOR_DRIVABILITY])}
                      Driveability
                    </div>
                  </div>
                  <div
                    className="pointer"
                    onClick={() => handleIndicatorTypes('Tractor_Automation')}
                  >
                    <div
                      className={
                        indicatorType === 'Tractor_Automation'
                          ? 'pointerEvents'
                          : 'pointerEventsDefault'
                      }
                    >
                      <img
                        className="indicatorImg"
                        src={
                          indicatorType === 'Tractor_Automation'
                            ? autonomyNewW
                            : tractor?.isOnline
                            ? autonomyFull
                            : autonomyNewG
                        }
                      />
                    </div>

                    <div
                      className="center tractorHealthTxt overline1"
                      data-testid="autonomyheader-Diagnostics"
                    >
                      {getCrossCheck(indicators[TRACTOR_AUTOMATION])}
                      Autonomy
                    </div>
                  </div>
                  <div
                    className="pointer"
                    onClick={() => handleIndicatorTypes('Tractor_Energy')}
                  >
                    <div
                      className={
                        indicatorType === 'Tractor_Energy'
                          ? 'pointerEvents'
                          : 'pointerEventsDefault'
                      }
                    >
                      <img
                        className="indicatorImg"
                        src={
                          indicatorType === 'Tractor_Energy'
                            ? battery_chargerW
                            : tractor?.isOnline
                            ? battery_charger
                            : battery_chargerG
                        }
                      />
                    </div>

                    <div
                      className="center tractorHealthTxt overline1"
                      data-testid="batteryAndChargerText-Diagnostics"
                    >
                      {getCrossCheck(indicators[TRACTOR_ENERGY])}
                      Battery & Charger
                    </div>
                  </div>
                  <div
                    className="pointer"
                    onClick={() => handleIndicatorTypes('Hitch_and_Implement')}
                  >
                    <div
                      className={
                        indicatorType === 'Hitch_and_Implement'
                          ? 'pointerEvents'
                          : 'pointerEventsDefault'
                      }
                    >
                      <img
                        className="indicatorImg"
                        src={
                          indicatorType === 'Hitch_and_Implement'
                            ? hitchImpW
                            : tractor?.isOnline
                            ? hitch
                            : hitchImpG
                        }
                      />
                    </div>

                    <div
                      className="center tractorHealthTxt overline1"
                      data-testid="hitchAndImplementText-Diagnostics"
                    >
                      {getCrossCheck(indicators[HITCH_AND_IMPLEMENT])}
                      Hitch & Implement
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tcktsBlk">
              <Space className="mtb20 mr0 searchFilters">
                <div className="searchSec searchUnit">
                  <Search
                    placeholder="Search Alerts"
                    data-testid="searchinput-Diagnostics"
                    style={{ width: 180 }}
                    onChange={(e) => handleSearch(e)}
                    onKeyDown={(e) => handleKey(e)}
                  />
                </div>
                <div
                  className="smallFilter"
                  data-testid="statusselect-Diagnostics"
                >
                  <CSelect
                    list={resolveFilterData1}
                    isAll={false}
                    selectTrigger={(selected) => {
                      if (selected) {
                        const { value } = selected;
                        statusSelect(value);
                      }
                    }}
                    label="Status"
                    keyValue="value"
                    defaultId="unresolved"
                  />
                </div>
                <RangePicker
                  format="YYYY-MM-DD"
                  onChange={(dates) => setDateFilter(dates)}
                  value={dateFilter}
                  className="dropdownStyle dW250_date dateFilter "
                  data-testid="dateselect-Diagnostics"
                  // style={{ width: 125 }}
                  disabledDate={disabledDate}
                />

                <div
                  className="medFilter"
                  data-testid="severityselect-Diagnostics"
                >
                  <LevelFilter
                    selectTrigger={(selected) => {
                      levelSelect(selected ? selected.id : '');
                    }}
                    defaultId={level}
                  />
                </div>
                <div
                  className="medFilter"
                  data-testid="indicatorselet-Diagnostics"
                >
                  <CSelect
                    list={STATUS_LIST.map((item) =>
                      Object.assign({
                        id: item.value,
                        name: item.name.replace('_', ' '),
                      }),
                    )}
                    selectTrigger={(selected) => {
                      selectIndicatorType(selected ? selected?.id : '');
                    }}
                    placeholder="All Indicators"
                    label="Indicator"
                    defaultId=""
                  />
                </div>
                <div
                  className="medFilter creatorFilter"
                  data-testid="subsystemselet-Diagnostics"
                >
                  <CSelect
                    list={subSystems.map((item: any) =>
                      Object.assign({
                        id: item.label,
                        name: item.label,
                      }),
                    )}
                    selectTrigger={(selected) => {
                      subSystemSelect(selected ? selected?.id : '');
                    }}
                    placeholder="All SubSystems"
                    label="SubSystem"
                    defaultId=""
                  />
                </div>
              </Space>
            </div>
            <Row className="w100per">
              <div className="tblDft farmTabsTbl hoverable dsTbl empTable cPointer">
                <InfiniteScrollTable
                  columns={columns}
                  hasMore={hasMore}
                  loading={loading}
                  filterData={filterData}
                  totalcount={totalCount}
                  handleLoadMore={handleLoadMore}
                  onRowClick={(record) => getRow(record)}
                  filename="Alerts"
                  onChange={handleChange}
                />
              </div>
            </Row>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default Diagnostics;
