import { TractorsList } from './types';

export default Object.freeze({
  TRACTOR_COMMUNICATION: 'Tractor_Communication',
  TRACTOR_AUTOMATION: 'Tractor_Automation',
  TRACTOR_DRIVABILITY: 'Tractor_Drivability',
  TRACTOR_DATA: 'Tractor_Data',
  HITCH_AND_IMPLEMENT: 'Hitch_and_Implement',
  TRACTOR_ENERGY: `Tractor_Energy`,
  RAMDATA: {
    4: 'nuc1_cpu_ram',
    9: 'nuc2_cpu_ram',
    14: 'xavier1_ram',
    21: 'xavier2_ram',
    28: 'xavier3_ram',
    35: 'xavier4_ram',
  },
  TEMPDATA: {
    2: 'nuc1_cpu_temp',
    7: 'nuc2_cpu_temp',
    12: 'xavier1_cpu_temp',
    19: 'xavier2_cpu_temp',
    26: 'xavier3_cpu_temp',
    33: 'xavier4_cpu_temp',
  },

  USAGEDATA: {
    1: 'nuc1_cpu_usage',
    6: 'nuc2_cpu_usage',
    11: 'xavier1_cpu_usage',
    18: 'xavier2_cpu_usage',
    25: 'xavier3_cpu_usage',
    32: 'xavier4_cpu_usage',
  },

  POWERSTATUSDATA: {
    0: 'nuc1_power_status',
    5: 'nuc2_power_status',
    10: 'xavier1_power_status',
    17: 'xavier2_power_status',
    24: 'xavier3_power_status',
    31: 'xavier4_power_status',
  },
  DISKUSAGEDATA: {
    3: 'nuc1_disk_usage',
    8: 'nuc2_disk_usage',
    13: 'xavier1_disk_usage',
    20: 'xavier2_disk_usage',
    27: 'xavier3_disk_usage',
    34: 'xavier4_disk_usage',
  },
  SYSTEM_MODEL: {
    1: 'CPU Usage',
    2: 'Temperature',
    3: 'RAM',
    4: 'Power Status',
    5: 'Disk Usage',
  },

  TRACTOR_MODEL: {
    0: 'llc_state',
  },

  TractorModalData: [
    {
      id: 1,
      name: 'Thermals',
    },
    {
      id: 2,
      name: 'Localization',
    },
    {
      id: 3,
      name: 'Drive',
    },
    {
      id: 4,
      name: 'Battery',
    },
    {
      id: 5,
      name: 'Hydraulics',
    },
    {
      id: 6,
      name: 'System Status',
    },
  ],
  LocalisationData: [
    {
      data_index: 7,
      data_name: 'XY Accuracy',
    },
    {
      data_index: 12,
      data_name: 'Good Localization',
    },
    {
      data_index: 8,
      data_name: 'Heading Accuracy',
    },
  ],
  DriveData: [
    {
      data_index: 43,
      data_name: 'Gear',
    },
    {
      data_index: 42,
      data_name: 'Motor Torque Newton Meters',
    },
    {
      data_index: 41,
      data_name: 'Steering Tire Angle Degrees',
    },
    {
      data_index: 45,
      data_name: 'Three Point Position Lh Percent',
    },
    {
      data_index: 47,
      data_name: 'Three Point Position Rh Percent',
    },
    {
      data_index: 40,
      data_name: 'Traction Motor Speed Rpm',
    },
  ],
  BAtteryData: [
    {
      data_index: 84,
      data_name: 'Hv Batt Current Amps',
    },
    {
      data_index: 73,
      data_name: 'Hv Batt Voltage Volts',
    },
    {
      data_index: 100,
      data_name: 'Power From Battery',
    },
  ],
  HydraulicsData: [
    {
      data_index: 50,
      data_name: 'Accumulator Pressure Bars',
    },
    {
      data_index: 48,
      data_name: 'Brake Pressure Lh Bars',
    },
    {
      data_index: 49,
      data_name: 'Brake Pressure Rh Bars',
    },
    {
      data_index: 51,
      data_name: 'Transmission Pressure Bars',
    },
  ],

  PowerData: [
    {
      data_index: 10,
      data_name: 'Xavier1 Power Status',
    },
    {
      data_index: 17,
      data_name: 'Xavier2 Power Status',
    },
    {
      data_index: 167,
      data_name: 'Xavier6 Power Status',
    },
    {
      data_index: 150,
      data_name: 'Xavier1 System Node',
    },
    {
      data_index: 151,
      data_name: 'Xavier2 System Node',
    },
    {
      data_index: 155,
      data_name: 'Xavier6 System Node',
    },
  ],

  ThermalsData: [
    {
      data_index: 82,
      data_name: 'Hv Batt Cell Temp Avg Degc',
    },
    {
      data_index: 64,
      data_name: 'Temp Coolant Charger Degc',
    },
    {
      data_index: 65,
      data_name: 'Temp Coolant Dcdc Degc',
    },
    {
      data_index: 68,
      data_name: 'Temp Coolant Motor Controller Degc',
    },
    {
      data_index: 69,
      data_name: 'Temp Coolant Motor Degc',
    },
    {
      data_index: 67,
      data_name: 'Temp Coolant Radiator Out Degc',
    },
    {
      data_index: 71,
      data_name: 'Temp Hydraulics Sump Degc',
    },
  ],
});

export const distanceForGoodPoints = 200;
export const rangeForAnalytics = 750;
export const REMOTE_ACCESS_GRANT = 'remote_access_grant';
export const APP_TYPE = 'app_type';
export const driver_hourly_wage_usd = 17.44;

export const defaultSetUpKeys = {
  isFirstTime: true,
  polygonUuid: '',
  startWith: '',
  startIndex: 0,
  everyFilter: 0,
  laneFilterContent: [],
  startIndexUuid: '',
};

export const BadLocalization = 'BAD_LOCALIZATION';
export const SystemError2 = 7001;
export const error_message: any = {
  7001: 'System error 2',
  7002: 'Map Could not be generated',
  7003: 'System error 1',
  7004: 'System error 3',
  7101: 'Path could not be generated for current request',
  7102: 'Tractor not in correct position to start routine. Please move the tractor and try again',
  7120: 'Internal Messaging Error',
  7121: 'Implement Data mismatch',
  7301: 'System error 5',
  400001: 'You have been logged out from tractor duo to inactivity',
  76001: 'System Error 6001',
  76002: 'System Error 6002',
  76003: 'System Error 6003',
  76004: 'System Error 6004',
  76005: 'System Error 6005',
  76006: 'System Error 6006',
  76007: 'System Error 6007',
  76008: 'System Error 6008',
  76009: 'Clean Front Cameras',
  76010: 'Clean Front Right Cameras',
  76011: 'Clean Rear Right Cameras',
  76012: 'Clean Rear Cameras',
  76013: 'Clean Rear Left Cameras',
  76014: 'Clean Front Left Cameras',
  76015: 'Clean Hitch Cameras',
  76016: 'Clean Driver Cameras',
  76017: 'System Error 6017',
  76018: 'System Error 6018',
  76019: 'System Error 6019',
  76020: 'System Error 6020',
  76021: 'System Error 6021',
  76022: 'System Error 6022',
  76023: 'System Error 6023',
  76024: 'System Error 6024',
  76025: 'System Error 6025',
  76026: 'System Error 6026',
  76027: 'System Error 6027',
  76028: 'System Error 6028',
  76029: 'System Error 6029',
  76030: 'System Error 6030',
  76031: 'System Error 6031',
  76032: 'System Error 6032',
  76100: 'Human Detected nearby',
  76200: 'Obstruction Detected nearby',
};
export const heartBeatTopic = 'HeartBeat';
export const AUTO_DRIVE_ERROR_TOPIC = 'autodrive_error';
export const AUTO_DRIVE_MONITOR_STATUS = 'autodrive_monitor_status';
export const INTERNAL_MESSAGE = 'internal_message';
export const AUTO_DRIVE_INTERRUPT_MESSAGE_CATEGORY = 'autodrive_interrupt_msg';
export const AUTO_LOGOUT_CODE = 400001;

export const CLEAR_COMMAND = '7000';
export const TRACTOR_STATUS = 'tractor_status';
export const MOS_EVENTS_TOPIC = 'mos_events_';
export const PLANNING_MANAGER_REMOTE_AV_EXECUTION_FBK =
  'planning_manager_remote_av_execution_fbk';

// eslint-disable-next-line no-useless-escape
export const speCharRegX = /^[a-zA-Z0-9!@#$%\^\s&*)(+=._-]*$/;
// on page load show the layers in mapmaker
export const listVisibility = {
  WAYPOINT: false,
  POLYGON: true,
  VINE: false,
  VINEROW: false,
  VINEBLOCK: false,
  ROUTE: false,
  TRAVELLEDROUTE: false,
  MARKS: false,
  WINEROWHEAD: false,
  VINELANE: false,
  JUMPANDGO: false,
  VINE_ROW_HEAD: false,
  ABLINE: false,
  ABBLOCK: false,
};

export const STATUS_LIST: any[] = [
  { name: 'Connectivity', value: 'Tractor_Communication' },
  { name: 'Driveability', value: 'Tractor_Drivability' },
  { name: 'Autonomy', value: 'Tractor_Automation' },
  { name: 'Battery & Charger', value: 'Tractor_Energy' },
  { name: 'Hitch & Implement', value: 'Hitch_and_Implement' },
];

export const getDriveActionUuid = (
  globalTractorList: TractorsList[],
  tractor_id: number,
): string => {
  const [tractor] = globalTractorList.filter(
    (tractor: TractorsList) => tractor.id == tractor_id,
  );
  return tractor?.heartbeat?.drive_action_details?.current_drive_action
    ? tractor?.heartbeat?.drive_action_details?.current_drive_action
    : '';
};
export const interruptMessageTypes = {
  guardrails_human: 'guardrails_human',
  guardrails_path_presence: 'guardrails_path_presence',
  guardrails_slip: 'guardrails_slip',
  firmware_fault: 'firmware_fault',
  lost_localization: 'lost_localization',
  tractor_moved_off_path: 'tractor_moved_off_path',
  tractor_lost_connection: 'tractor_lost_connection',
};

export const getInterruptTitle = (details: string) => {
  switch (details) {
    case interruptMessageTypes.guardrails_human:
      return 'Human Detection';
    case interruptMessageTypes.guardrails_path_presence:
      return 'Path Protection';

    case interruptMessageTypes.lost_localization:
      return 'Localization Down';

    case interruptMessageTypes.guardrails_slip:
      return 'Tractor is stuck and slipping';
    case interruptMessageTypes.firmware_fault:
      return 'Firmware Fault';
    case interruptMessageTypes.tractor_moved_off_path:
      return 'Tractor Deviated from Planned Path';
    case interruptMessageTypes.tractor_lost_connection:
      return 'Tractor Lost Connection';

    default:
      return '';
  }
};

export const WINGSPANAI = 'wingspanai';
