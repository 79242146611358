/* eslint-disable no-console */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { memo, useContext, useEffect, useRef, useState } from 'react';

import {
  getDriveActionSinglePath,
  getGroundZero,
  getPolygonsData,
} from '../../../constants/Api';
import {
  displayRecenterViewPortSetUp,
  drawPoly,
  getDateTime,
  getFleetUsagePointsInfo,
  getFleetUsagePointsPathInfo,
  polygonHoverLabel,
  priority,
  togglePolygonStyle,
  toggleVineRowStyle,
  vineRowHoverStyle,
} from '../../../constants/Common';
import { MapFeature } from '../../../constants/types';
import { ApplicationContext } from '../../../context/AppContext';

import { notification } from 'antd';

import { Feature, Overlay, View } from 'ol';
import { ScaleLine } from 'ol/control';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import OLMap from 'ol/Map';
import * as proj from 'ol/proj';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

import BaseStationIcon from '../../../assets/images/basestation_green.png';
import datalayerIcon from '../../../assets/images/dataLayer.svg';

import { LeftOutlined } from '@ant-design/icons';
import LineString from 'ol/geom/LineString';
import { useLocation } from 'react-router-dom';
import applicationIds from '../../../locale/applicationIds.json';
import { getWaypoints } from '../../map/Waypoint';
import FungicideSprayingMapLayers from './FungicideSprayingMapLayers';
import FungicideSprayingMapIcons from './FungicideSprayingMapIcons';
import { Coordinate } from 'ol/coordinate';

// let globalToggleSatellite = true;
const globalZoom = 0;
export const onGlobalZoomToShowZoomIcons = 19;

const FungicideSprayingMap: React.FC = memo(() => {
  const { userDetails } = useContext(ApplicationContext);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [base, setBase] = useState<[number, number]>([0, 0]);
  const [toggleSatellite, setToggleSatellite] = useState(true);
  const [showTractors, setShowTractors] = useState(true);
  const [showBasestations, setShowBasestations] = useState(true);
  const [rightSideToggle, setRightSideToggle] = useState(false);
  const [polygons, setPolygons] = useState<MapFeature[]>([]);

  const mapElement: any = useRef();
  const container: any = useRef();
  const content: any = useRef();
  const mapRef = useRef<OLMap | null>(null);
  const vectorTileLayerRef = useRef<TileLayer<any>>();
  const satelliteTileLayerRef = useRef<TileLayer<any>>();
  const initialLayer = useRef<VectorLayer<any>>();
  const tractorPathLayerRef = useRef<VectorLayer<any>>();
  const tractorLayerRef = useRef<VectorLayer<any>>();
  const waypointsLayerRef = useRef<VectorLayer<any>>();
  const implementsLayerRef = useRef<VectorLayer<any>>();
  const vineRowsLayerRef = useRef<VectorLayer<any>>();
  const vineBlocksLayerRef = useRef<VectorLayer<any>>();
  const pathLayerRef = useRef<VectorLayer<any>>();
  const tractorPaths = useRef<Map<string, [number, number][]>>();
  const tractorTravelledPathLayerRef = useRef<VectorLayer<any>>();
  const planerTractorPathLayerRef = useRef<VectorLayer<any>>();
  const plannerTractorPaths = useRef<Map<string, any>>();
  const fieldFeatureItem = useRef<any>();
  const mapIndicatorRefs = useRef<VectorLayer<any>>();
  const mapGuardRailsRefs = useRef<VectorLayer<any>>();
  const mapGuardRailsFromStreamRefs = useRef<VectorLayer<any>>();
  const mapIndicatorFromStreamRefs = useRef<VectorLayer<any>>();

  const hoverPointLayerRef = useRef<VectorLayer<any>>();
  const greenPolygonsLayerRef = useRef<VectorLayer<any>>();
  const whitePolygonsLayerRef = useRef<VectorLayer<any>>();
  const grayPolygonsLayerRef = useRef<VectorLayer<any>>();
  const bluePolygonsLayerRef = useRef<VectorLayer<any>>();
  const jumpAndGoLayerRef = useRef<VectorLayer<any>>();

  const { state } = useLocation();
  const tractorObject = state as {
    tractorId: any | undefined;
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      initializeMap();
    }
  }, [userDetails]);
  //  mapInitialization start here
  const initializeMap = async () => {
    try {
      const { organization } = userDetails;
      const { latitude, longitude } = await getGroundZero(
        organization.api_url,
        organization.farm.id,
      );
      setBase([latitude, longitude]);

      const baseLocation = new Feature({
        geometry: new Point(proj.fromLonLat([longitude, latitude])),
      });
      baseLocation.set('name', 'Ground zero');
      //  set id 1 for base station recenter
      baseLocation.setId(1);
      const style = new Style({
        image: new Icon({
          src: BaseStationIcon,
          scale: 0.4,
        }),
        zIndex: 100,
      });
      baseLocation.setStyle(style);
      const initialFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [baseLocation],
        }),
      });

      const vectorLayer = new TileLayer({
        source: new OSM(),
        visible: false,
      });

      const satelliteLayer = new TileLayer({
        source: new XYZ({
          url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga',
          cacheSize: 1000,
        }),
        visible: true,
      });

      const initalPathLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: false,
        zIndex: priority.PREVIEWPATH,
      });
      const pointLocation: Feature<any> = new Feature();
      pointLocation.setId(999);
      const selectPointFeatureLayer = new VectorLayer({
        source: new VectorSource({
          features: [pointLocation],
        }),
        visible: true,
        style: new Style({}),
        zIndex: 111,
      });

      const greenPolygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.POLYGON.green,
      });

      const whitePolygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.POLYGON.white,
      });

      const grayPolygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.POLYGON.gray,
      });
      const bluePolygonsFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        visible: true,
        zIndex: priority.POLYGON.blue,
      });

      const jumpAndGo = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
        zIndex: Infinity,
        style,
      });
      // create map
      const initialMap = new OLMap({
        target: mapElement.current,
        layers: [
          satelliteLayer,
          vectorLayer,
          initialFeaturesLayer,
          initalPathLayer,
          selectPointFeatureLayer,
          greenPolygonsFeaturesLayer,
          whitePolygonsFeaturesLayer,
          grayPolygonsFeaturesLayer,
          bluePolygonsFeaturesLayer,
          jumpAndGo,
        ],
        controls: [scaleControl()],
        view: new View({
          projection: 'EPSG:3857',
          center: proj.transform(
            [longitude, latitude],
            'EPSG:4326',
            'EPSG:3857',
          ),
          zoom: 17,
          maxZoom: 25,
        }),
      });
      // set flag for map initialization
      setMapInitialized(true);
      mapRef.current = initialMap;
      initialLayer.current = initialFeaturesLayer;
      // initialize satelight layer
      satelliteTileLayerRef.current = satelliteLayer;
      // initialize vector layer
      vectorTileLayerRef.current = vectorLayer;

      pathLayerRef.current = initalPathLayer;
      // initialize point selection layer
      hoverPointLayerRef.current = selectPointFeatureLayer;

      greenPolygonsLayerRef.current = greenPolygonsFeaturesLayer;
      whitePolygonsLayerRef.current = whitePolygonsFeaturesLayer;
      grayPolygonsLayerRef.current = grayPolygonsFeaturesLayer;
      bluePolygonsLayerRef.current = bluePolygonsFeaturesLayer;
      jumpAndGoLayerRef.current = jumpAndGo;
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };
  //  mapInitialization ends here

  const scaleControl = () => {
    const control = new ScaleLine({
      units: 'metric',
      className: 'positionMap',
    });
    return control;
  };
  // to change road map and satelight map
  useEffect(() => {
    vectorTileLayerRef.current?.setVisible(!toggleSatellite);
    satelliteTileLayerRef.current?.setVisible(toggleSatellite);
    // globalToggleSatellite = toggleSatellite;

    if (whitePolygonsLayerRef.current)
      togglePolygonStyle(whitePolygonsLayerRef.current, toggleSatellite);

    if (greenPolygonsLayerRef.current)
      togglePolygonStyle(greenPolygonsLayerRef.current, toggleSatellite);

    if (bluePolygonsLayerRef.current)
      togglePolygonStyle(bluePolygonsLayerRef.current, toggleSatellite);

    if (grayPolygonsLayerRef.current)
      togglePolygonStyle(grayPolygonsLayerRef.current, toggleSatellite);

    if (vineRowsLayerRef.current)
      toggleVineRowStyle(
        vineRowsLayerRef.current,
        toggleSatellite,
        globalZoom >= onGlobalZoomToShowZoomIcons,
      );
  }, [toggleSatellite]);
  /**
   * Live Tractors initialization Ends Here
   */

  useEffect(() => {
    if (mapInitialized) {
      const overlay = new Overlay({
        element: container.current,
        autoPan: false,
        autoPanAnimation: {
          duration: 10,
        },
      });
      mapRef.current?.on('pointermove', function (evt) {
        const hit = mapRef.current?.forEachFeatureAtPixel(
          evt.pixel,
          function (feature: any) {
            const coordinate = evt.coordinate;
            const name = feature.get('name');
            const pointType = feature.get('pointType');
            if (name && pointType !== 'TRACTOR') {
              content.current.innerHTML =
                '<p>' + name && name != undefined ? name : '' + '</p>';
              overlay.setPosition(coordinate);
              mapRef.current?.addOverlay(overlay);
              return true;
            }

            return false;
          },
        );
        if (hit) {
          evt.map.getTargetElement().style.cursor = 'pointer';
        } else {
          overlay.setPosition(undefined);
          mapRef.current?.addOverlay(overlay);
          evt.map.getTargetElement().style.cursor = '';
        }
      });
      let selected: any = null;
      mapRef.current?.on('pointermove', function (evt) {
        if (selected !== null) {
          selected.setStyle(undefined);
          selected = null;
        }
        mapRef.current?.forEachFeatureAtPixel(
          evt.pixel,
          function (feature: any) {
            const pointType = feature.get('pointType');
            if (pointType && pointType === 'vinerow') {
              selected = feature;
              feature.setStyle(
                vineRowHoverStyle(globalZoom > onGlobalZoomToShowZoomIcons),
              );
              return true;
            }
          },
        );
      });
      let selected1: any = null;
      mapRef.current?.on('pointermove', function (evt) {
        const pointLayer = hoverPointLayerRef.current;
        if (selected1 !== null) {
          const feat = pointLayer?.getSource().getFeatureById(999);
          feat?.setStyle(new Style({}));
          selected1?.setStyle(selected1?.get('style'));
          selected1 = null;
        }
        const hit = mapRef.current?.forEachFeatureAtPixel(
          evt.pixel,
          function (feature: any) {
            const pointType = feature.get('pointType');
            if (pointType === 'PATH' || pointType === 'LIVEPATH') {
              const feat = pointLayer?.getSource().getFeatureById(999);
              if (feat) {
                feat.setGeometry(
                  new Point(
                    proj.fromLonLat(
                      proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326'),
                    ),
                  ),
                );
                feat.setStyle(
                  new Style({
                    image: new CircleStyle({
                      radius: 5,
                      stroke: new Stroke({
                        color: 'blue',
                      }),
                      fill: new Fill({
                        color: '#3399CC',
                      }),
                    }),
                    zIndex: 10,
                  }),
                );
                selected1 = feature;
                selected1?.setStyle(feature.get('hoverStyle'));
                const geometry = feature.getGeometry();
                const point = geometry.getClosestPoint(evt.coordinate);
                const coordinates = geometry.getCoordinates();

                for (
                  let element = 0;
                  element < coordinates.length - 1;
                  element++
                ) {
                  if (
                    new LineString([
                      coordinates[element],
                      coordinates[element + 1],
                    ]).intersectsCoordinate(point)
                  ) {
                    const drawPointsText = selected1.get('drawPointsText');
                    const points =
                      pointType === 'PATH'
                        ? drawPointsText.path
                        : drawPointsText.points;
                    if (points && points.length && points[element]) {
                      let innerHTML = '';
                      if (pointType === 'PATH') {
                        innerHTML = getFleetUsagePointsPathInfo(
                          points[element],
                        );
                      }
                      if (pointType === 'LIVEPATH') {
                        innerHTML = getFleetUsagePointsPathInfo(drawPointsText);
                      }

                      selected1.set('name', innerHTML);
                      content.current.innerHTML = innerHTML;
                      if (
                        !overlay
                          .getElement()
                          ?.classList.contains('ol-details-popup')
                      ) {
                        overlay.getElement()?.classList.add('ol-details-popup');
                      }
                      overlay.setPosition(evt.coordinate);
                      mapRef.current?.addOverlay(overlay);
                      return true;
                    }
                  }
                }
              }
            }
            const coordinate = evt.coordinate;
            const name = feature.get('name');
            const dataType = feature.get('pointType');
            if (name && dataType !== 'TRACTOR') {
              content.current.innerHTML = '<p>' + name + '</p>';
              if (dataType == 'INDICATOR') {
                const contentData = feature.get('content');
                content.current.innerHTML = getFleetUsagePointsInfo(
                  dataType,
                  contentData,
                );
                if (
                  !overlay.getElement()?.classList.contains('ol-details-popup')
                ) {
                  overlay.getElement()?.classList.add('ol-details-popup');
                }
              } else {
                // stop removing path class removal
                if (dataType !== 'PATH') {
                  overlay.getElement()?.classList.remove('ol-details-popup');
                }
              }

              overlay.setPosition(coordinate);
              mapRef.current?.addOverlay(overlay);
              return true;
            }

            return false;
          },
        );
        if (hit) {
          evt.map.getTargetElement().style.cursor = 'pointer';
        } else {
          overlay.setPosition(undefined);
          mapRef.current?.addOverlay(overlay);
          evt.map.getTargetElement().style.cursor = '';
        }
      });
    }
    if (mapInitialized) {
      mapRef.current?.on('click', function (evt) {
        mapRef.current?.forEachFeatureAtPixel(evt.pixel, function (feature) {
          const feat = feature.get('features');
          if (feat && feat.length > 1) {
            const geometry = feat[0].getGeometry();
            const coordinates = geometry.getCoordinates();
            const view = mapRef.current?.getView();
            if (view) {
              view.setZoom(22);
              if (coordinates) {
                mapRef.current?.getView().setCenter(coordinates);
              }
            }
          }
        });
      });
    }
  }, [userDetails, mapInitialized]);

  /**
   * Subscribing to Tractors and getting Information Starts Here
   */
  /**
   * Draw Tractor Path Ends Here
   */

  /**
   * Polygons initialization Starts Here
   */
  useEffect(() => {
    const init = async () => {
      try {
        const { organization } = userDetails;
        const response = await getPolygonsData(
          organization.api_url,
          organization.farm.id,
        );
        const { records } = response;
        const list =
          records && records.length && records.length > 0 ? records : [];
        drawPolyAndPrepareLayers(list);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    if (userDetails && userDetails.organization && mapInitialized && base) {
      init();
    }
  }, [userDetails, mapInitialized, base]);

  const drawPolyAndPrepareLayers = async (polyList: any) => {
    const listItems: MapFeature[] = [];
    polyList.map(async (record: any, index: number) => {
      let layer = null;
      if (record.color === 'white') {
        layer = whitePolygonsLayerRef.current;
      }
      if (record.color === 'green') {
        layer = greenPolygonsLayerRef.current;
      }
      if (record.color === 'blue') {
        layer = bluePolygonsLayerRef.current;
      }
      if (record.color === 'gray') {
        layer = grayPolygonsLayerRef.current;
      }
      const { polygonFeature, polygonItem } = await drawPoly(
        userDetails,
        base,
        layer,
        1,
        1,
        record,
        false, // suggested as false
        false, // call api to get vertices -> false call api , -> true dont call api
      );
      listItems.push(polygonItem as MapFeature);
      // assigne for recenter the white field
      if (record.color === 'white') {
        fieldFeatureItem.current = polygonFeature;
        if (polygonFeature && !tractorObject) {
          displayRecenterViewPortSetUp(
            polygonFeature as Feature<Geometry>,
            mapRef.current,
          );
        }
      }
      if (polyList && polyList.length - 1 === index) {
        setPolygons(listItems);
      }
    });
    polygonHoverLabel(mapRef.current);
  };

  useEffect(() => {
    const init = async () => {
      try {
        const { layer }: { layer: any } = await getWaypoints(userDetails, base);
        if (layer) {
          mapRef.current?.addLayer(layer);
          waypointsLayerRef.current = layer;
        }
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    if (userDetails && userDetails.organization && mapInitialized && base) {
      init();
    }
  }, [userDetails, mapInitialized, base]);
  /**
   * Polygons initialization Ends Here
   */

  const getFeatureLayer = (type: string) => {
    let layer: VectorLayer<any> | any;
    if (type === 'TRACTOR') {
      layer = tractorLayerRef.current;
    } else if (type === 'WAYPOINT') {
      layer = waypointsLayerRef.current;
    } else if (type === 'IMPLEMENT') {
      layer = implementsLayerRef.current;
    } else if (type === 'VINEROW') {
      layer = vineRowsLayerRef.current;
    } else if (type === 'VINEBLOCK') {
      layer = vineBlocksLayerRef.current;
    } else if (type === 'BASESTATION') {
      layer = initialLayer.current;
    } else if (type === 'GREENPOLYGON') {
      layer = greenPolygonsLayerRef.current;
    } else if (type === 'WHITEPOLYGON') {
      layer = whitePolygonsLayerRef.current;
    } else if (type === 'GRAYPOLYGON') {
      layer = grayPolygonsLayerRef.current;
    } else if (type === 'BLUEPOLYGON') {
      layer = bluePolygonsLayerRef.current;
    } else if (type === 'INDICATOR') {
      layer = mapIndicatorRefs.current;
    } else if (type === 'PATH') {
      layer = planerTractorPathLayerRef.current;
    } else if (type === 'SNAP') {
      layer = mapGuardRailsRefs.current;
    } else if (type === 'SNAP_STREAM') {
      layer = mapGuardRailsFromStreamRefs.current;
    } else if (type === 'INDICATOR_STREAM') {
      layer = mapIndicatorFromStreamRefs.current;
    }

    return layer;
  };
  /** To toggle map layers from remoteAV popup */
  const handleToggleLayer = (featureLayerType: string, isVisible: boolean) => {
    const layer: VectorLayer<any> | any = getFeatureLayer(featureLayerType);
    if (layer) {
      layer.setVisible(isVisible);
      if (featureLayerType === 'TRACTOR') {
        tractorPathLayerRef?.current?.setVisible(isVisible);
        if (!isVisible) {
          tractorPathLayerRef.current
            ?.getSource()
            .getFeatures()
            .forEach((feature: any) => {
              tractorPathLayerRef.current?.getSource().removeFeature(feature);
            });
          const clear = tractorPaths.current;
          tractorPaths.current?.clear();
          tractorPaths.current = clear;

          // clear the planner layers points
          const clear1 = plannerTractorPaths.current;
          plannerTractorPaths.current?.clear();
          plannerTractorPaths.current = clear1;
        }
      }
    }
    if (featureLayerType === 'PATH') {
      planerTractorPathLayerRef?.current?.setVisible(isVisible);
      tractorPathLayerRef?.current?.setVisible(isVisible);
      tractorTravelledPathLayerRef?.current?.setVisible(isVisible);
    }
  };

  const recenterTractor = (tractorId: number) => {
    const layer: VectorLayer<any> | any = getFeatureLayer('TRACTOR');
    if (layer) {
      layer.setVisible(true);
      setShowTractors(true);
      const feat = layer.getSource();
      const feature: any = feat
        .getSource()
        .getFeatureById(`PoseModel_streaming_${tractorId}`);
      if (feature) {
        const latLang = feature.get('latLang');
        const view = mapRef.current?.getView();
        if (latLang) {
          mapRef.current?.getView().setCenter(proj.fromLonLat(latLang));
        }
        if (view) {
          view.setZoom(22);
        }
      }
    }
  };

  useEffect(() => {
    handleToggleLayer('BASESTATION', showBasestations);
  }, [showBasestations]);

  useEffect(() => {
    userDetails &&
      userDetails.organization &&
      drawJAG('16582610267330', 'jump_and_go_13439', 'Alfonso  Morales');
  }, [userDetails]);
  const drawJAG = async (actionUuid: string, id: string, name?: string) => {
    jumpAndGoLayerRef.current?.getSource().clear();
    const { organization } = userDetails;
    const { tractor_id, records } = await getDriveActionSinglePath(
      organization.api_url,
      actionUuid,
    );
    const defaultLayer = jumpAndGoLayerRef.current;
    const arr: Coordinate[] = [];
    if (records && records.length > 0) {
      records.map(
        (path: {
          longitude: number;
          latitude: number;
          created_date_time: number;
          dTime: string;
        }) => {
          path.dTime = getDateTime(path.created_date_time);
          arr?.push(proj.fromLonLat([path.longitude, path.latitude]));
        },
      );
      let tractorLocation: Feature<Geometry> = new Feature();
      if (defaultLayer) {
        tractorLocation = defaultLayer?.getSource().getFeatureById(id);
      }
      if (!tractorLocation) {
        tractorLocation = new Feature();
      }
      tractorLocation.setGeometry(new LineString(arr));
      const color = 'rgba(0,183,255,255)';
      const borderColor = '#FFFFFF';
      const style = [
        new Style({
          stroke: new Stroke({
            color: borderColor,
            width: 8,
          }),
          zIndex: 0,
        }),
        new Style({
          stroke: new Stroke({
            color: color,
            width: 6,
          }),
          zIndex: 1,
        }),
      ];
      const pathHover = [
        new Style({
          stroke: new Stroke({
            color: borderColor,
            width: 10,
          }),
          zIndex: 0,
        }),
        new Style({
          stroke: new Stroke({
            color,
            width: 8,
          }),
          zIndex: 1,
        }),
      ];
      tractorLocation.setStyle(style);
      tractorLocation.set('style', style);
      tractorLocation.set('hoverStyle', pathHover);
      tractorLocation.set('name', name);
      tractorLocation.set('pointType', 'JUMPANDGO');
      tractorLocation.set('drawPointsText', records);
      tractorLocation.set('tractorId', tractor_id);
      defaultLayer?.getSource().addFeature(tractorLocation);
      const view = mapRef.current?.getView();
      if (view && arr && arr.length > 0) {
        const zoom = view.getZoom();
        if (zoom) {
          mapRef.current?.getView().setCenter(arr[0]);
          mapRef.current?.getView().setZoom(17);
        }
      }
    }
  };

  return (
    <>
      <FungicideSprayingMapIcons
        rightSideToggle={rightSideToggle}
        selectedTractorId={0}
        recenterTractor={recenterTractor}
        map={mapRef.current}
        fieldFeatureItem={fieldFeatureItem.current as Feature<Geometry>}
        displayRecenterViewPortSetUp={displayRecenterViewPortSetUp}
        toggleSatellite={toggleSatellite}
        setToggleSatellite={setToggleSatellite}
      />
      <FungicideSprayingMapLayers
        rightSideToggle={rightSideToggle}
        setRightSideToggle={setRightSideToggle}
        fleet={false}
        setFleet={function (fleet: boolean): void {
          throw new Error('Function not implemented.');
        }}
        showTractors={false}
        setShowTractors={function (showTractors: boolean): void {
          throw new Error('Function not implemented.');
        }}
        selectItems={function (items: boolean): void {
          throw new Error('Function not implemented.');
        }}
        showBasestations={false}
        setShowBasestations={function (showBasestations: boolean): void {
          throw new Error('Function not implemented.');
        }}
        farm={false}
        setFarm={function (farm: boolean): void {
          throw new Error('Function not implemented.');
        }}
        showWaypoints={false}
        setShowWaypoints={function (showWaypoints: boolean): void {
          throw new Error('Function not implemented.');
        }}
        showPath={false}
        setShowPath={function (showPath: boolean): void {
          throw new Error('Function not implemented.');
        }}
        showDataLayers={false}
        setShowDataLayers={function (showDataLayers: boolean): void {
          throw new Error('Function not implemented.');
        }}
        showWhitePolygons={false}
        setShowWhitePolygons={function (showWhitePolygons: boolean): void {
          throw new Error('Function not implemented.');
        }}
        showGrayPolygons={false}
        setShowGrayPolygons={function (showGrayPolygons: boolean): void {
          throw new Error('Function not implemented.');
        }}
        selectFarmItems={function (items: boolean): void {
          throw new Error('Function not implemented.');
        }}
        showGreenPolygons={false}
        setShowGreenPolygons={function (showGreenPolygons: boolean): void {
          throw new Error('Function not implemented.');
        }}
        showBluePolygons={false}
        setShowBluePolygons={function (showBluePolygons: boolean): void {
          throw new Error('Function not implemented.');
        }}
      />
      <div
        ref={mapElement}
        style={{ height: '100vh', borderRadius: 10 }}
        className={`posHd ${rightSideToggle ? 'afterMapRightAlign' : ''}`}
      >
        <div
          id={applicationIds.liveMap.rowBtn}
          className="dataLayerBtn"
          onClick={() => setRightSideToggle(!rightSideToggle)}
        >
          <span className="dataArrow">
            <LeftOutlined style={{ fontSize: 17 }} />
          </span>
          <span className="dLIcon">
            <img src={datalayerIcon} alt="dataLayer" height="18" />
          </span>
          <span className="dLTxt">Map Layers</span>
        </div>
      </div>
      <div ref={container} className="ol-popup">
        <div ref={content}></div>
      </div>
    </>
  );
});

export default FungicideSprayingMap;
