/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { RightOutlined } from '@ant-design/icons';
import { MenuContext } from '../../../context/MenuContext';
// import { Spin } from 'antd';
import { useMixpanel } from 'react-mixpanel-browser';
// import loading_logo from '../../../assets/images/loading_logo.gif';
import MediaWidget from './MediaWidget';

export const JumpAndGo = 'JumpAndGo';
export const SNAPSHOT = 'SNAPSHOT';
export const JUMPANDGO = 'JUMPANDGO';

interface Props {
  showThumbnails: boolean;
  setRightSideThumbnailsToggle: (status: boolean) => void;
  // spinner: boolean;
}

const ThumbnailMenu: React.FC<Props> = ({
  showThumbnails,
  setRightSideThumbnailsToggle,
}: // spinner,
Props) => {
  const { collapsed } = useContext(MenuContext);
  const mixpanel = useMixpanel();
  const [, setMenuWidth] = useState(260);

  useEffect(() => {
    setMenuWidth(collapsed ? 80 : 260);
  }, [collapsed]);

  return (
    <>
      {showThumbnails ? (
        <div
          className={
            showThumbnails
              ? 'analyticsRightside newAnalytics expand'
              : 'analyticsRightside newAnalytics'
          }
        >
          <div className="dataLayerWrapper">
            <div
              className="dataLayerRowHead2"
              style={{
                position: 'absolute',
                left: '-20px',
                top: '5px',
              }}
            >
              <span
                onClick={() => {
                  mixpanel.track('Analytics Map', {
                    event: `${
                      showThumbnails ? 'Data Layer Closed' : 'Data Layer Opened'
                    }`,
                  });
                  setRightSideThumbnailsToggle(!showThumbnails);
                }}
                style={{ cursor: 'pointer' }}
              >
                <RightOutlined />
              </span>
            </div>

            <div className="datalayerContent2">
              <div className="anaJngTabSec">
                <div className="tbTabs anaJngTabs dbThumb">
                  <div className="thumbWraper noVideos">
                    {/* <Spin
                      className="loader"
                      style={{ width: '100%', margin: '40%' }}
                      indicator={
                        <img
                          style={{ width: '60px', height: '36px' }}
                          src={loading_logo}
                        />
                      }
                    > */}
                    <MediaWidget />
                    {/* </Spin> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ThumbnailMenu;
