/* eslint-disable no-console */
import { Collection, Feature, Overlay } from 'ol';
import Point from 'ol/geom/Point';
import { Draw, Modify, Snap } from 'ol/interaction';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import OLMap from 'ol/Map';
import * as proj from 'ol/proj';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import View from 'ol/View';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import BaseStationIcon from '../../assets/images/basestation_green.png';
import polygon_icon from '../../assets/images/polygon_icon.svg';
import vine from '../../assets/images/Vine.svg';
import Vine_Block from '../../assets/images/Vine_Block.svg';
import Vine_Lane from '../../assets/images/Vine_Lane.svg';
import waypoint from '../../assets/images/Waypoint.svg';
import {
  approvePolygon,
  deleteAbBlockFun,
  deleteAbLineFun,
  deleteMarkFun,
  deletePolygonFun,
  deleteRouteFun,
  deleteVineBlockFun,
  deleteVineFun,
  deleteVineLaneFun,
  deleteVineRowFun,
  deleteWaypointFun,
  getAbBlocksData,
  getAbLineData,
  getActualRoute,
  getAllVinePoints,
  getBrwonPolygonsSuggestedPolygonsData,
  getDesiredRoute,
  getDriveActionSinglePath,
  getJumpAndGoVineRowsData,
  getMarksData,
  getPolygonsData,
  getRoute,
  getRoutePoints,
  getTractorsListData,
  getTravelledRoute,
  getVineBlocksData,
  getVineLaneData,
  getVineRowsData,
  getWaypointsData,
} from '../../constants/Api';
import {
  abBlockHoverLabel,
  abLineStyle,
  diff_minutes,
  displayRecenterAllViewPortSetUp,
  displayRecenterViewPortSetUp,
  drawAbBlockFun,
  drawPoly,
  getAreaOfPolygon,
  getDateTime,
  getLatitudeAndLongitude,
  getNameFromList,
  getRoadMapPolyStyle,
  getSateLightPolyStyle,
  getXYCoordinates,
  getXYCoordinatesList,
  mapmakerVineStyle,
  mapMakerwWypointStyle,
  markStyle,
  polyDrawStyle,
  polygonHoverLabel,
  priority,
  setPolygonStyle,
  vineBlockStyle,
  vineLaneStyle,
  vineRowDisableStyle,
  vineRowFromJAGStyle,
  vineRowHeadStyle,
  vineRowStyle,
} from '../../constants/Common';
import { ApplicationContext } from '../../context/AppContext';

import ground_zero from '../../assets/images/groundZero.svg';
import map_apps from '../../assets/images/ic_apps.svg';
import map_file_upload from '../../assets/images/ic_file_upload.svg';
import map_image from '../../assets/images/ic_image.svg';
import map_map from '../../assets/images/ic_map.svg';
import map_mode from '../../assets/images/ic_mode.svg';
import map_my_location from '../../assets/images/ic_my_location.svg';
import map_file_download from '../../assets/images/map_file_download.svg';
import map_zoomin from '../../assets/images/map_zoomin.svg';
import map_zoomout from '../../assets/images/map_zoomout.svg';

import { Button, Dropdown, Menu, Modal, notification, Tooltip } from 'antd';
import { Coordinate } from 'ol/coordinate';
import { FeatureLike } from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import { convertRouteXYtoLatLang } from '../../constants/Common';
import {
  AbBlockPayload,
  AbLinePayloadSingle,
  DRAWITEM,
  LatLang,
  MapFeature,
  MarkPointPayloadTypes,
  PolygonDropDown,
  PolyPayload,
  TractorsList,
  VineBlockPayloadType,
  VineLanePayloadType,
  VineRowPayload,
  VineRowPayloadSingle,
  VinesPayloadTypes,
  WaypointPayloadTypes,
} from '../../constants/types';
import { ToggleLayer } from './MapMakerBeta';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import ContextMenu from 'ol-contextmenu';
import { ScaleLine } from 'ol/control';
import MousePosition from 'ol/control/MousePosition';
import { createStringXY } from 'ol/coordinate';
import GeometryType from 'ol/geom/GeometryType';
import Graticule from 'ol/layer/Graticule';
import { useHistory } from 'react-router';
import { listVisibility } from '../../constants/constant';
import { SET_POLYGONS_LIST } from '../../context/actions';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import { MenuContext } from '../../context/MenuContext';
import RoutesConstants from '../../routes/RoutesConstant';
import { getJumpandGo } from '../fleetusage/util';
import AbBlock from './AbBlock';
import AbLine from './AbLine';
import AbLine1 from './AbLine1';
import AddPoly from './AddPoly';
import DiscoverModal2 from './DiscoverModal2';
import EditPoly from './EditPoly';
import FileDownload from './FileDownload';
import FileUpload from './FileUpload';
import GroundZeroEditModal from './GroundZeroEditModal';
import ImageUpload from './ImageUpload';
import Mark from './Mark';
import Vine from './Vine';
import VineBlock from './VineBlock';
import VineLaneModal1 from './VineLaneModal1';
import VineLaneModal2 from './VineLaneModal2';
import VineRow from './VineRow';
import VineRowJumpAndGoModel from './VineRowJumpAndGoModel';
import VineRowModal2 from './VineRowModal2';
import VineRowModelWithJumpAndGo from './VineRowModelWithJumpAndGo';
import Waypoint from './Waypoint';

// modify declations
let polygonModify: Modify;
let abBlockModify: Modify;
let vineRowModify: Modify;
let abLineModify: Modify;
let JumpAndGoModify: Modify;

// draw declations
let polygonDraw: Draw;
let abBlockDraw: Draw;
let vineRowDraw: Draw;
let abLineDraw: Draw;
let vineDraw: Draw;
let waypointDraw: Draw;

// soyurces for features
let polygonSource: VectorSource<Geometry>;
let abBlockSource: VectorSource<Geometry>;
let wayPointSource: VectorSource<Geometry>;
let vineSource: VectorSource<Geometry>;
let vineRowSource: VectorSource<Geometry>;
let abLineSource: VectorSource<Geometry>;
let jumpAndGoSource: VectorSource<Geometry>;

let activeDrawActionGlobal: DRAWITEM['item'] = 'NONE';
let showSaveGlobal = false;
let selectedVineRowsForVineBlock: any[] = [];
let mouseClickEvent = false;
let vineBlockId = 0;
let vineBlockName = '';
let polygonUuid = '';
let globalVineRowModal: 1 | 2 | 3 | 4 = 1;
let globalTriggerCancelOperation = false;

const gridArray = [
  0.000342, 0.000171, 0.0000849, 0.0000439, 0.0000229, 0.00001099, 0.00000545,
  0.00000289, 0.00000139, 0.000000669, 0.000000359, 0.000000189, 0.0000000839,
  0.0000000469,
];
let graticuleToggle = false;
const suggestedPolygon = 'suggested-green';

let globalPolyUnique = Math.random();
let globalAbLineUnique = Math.random();
interface Props {
  setRoutesFeature: (data: MapFeature[]) => void;
  setTravelledRoutesDataFeature: (data: MapFeature[]) => void;
  setVineFeature: (data: MapFeature[]) => void;
  setVineRowFeature: (data: MapFeature[]) => void;
  setAbLineFeature: (data: MapFeature[]) => void;
  setAbBlockFeature: (data: MapFeature[]) => void;
  setVineBlockFeature: (data: MapFeature[]) => void;
  setPolygonFeature: (data: MapFeature[]) => void;
  setVineLaneFeature: (data: MapFeature[]) => void;
  setWaypointFeature: (data: MapFeature[]) => void;
  setJumpAndGoFeature: (data: MapFeature[]) => void;
  handleToggleLayer: (e: boolean, type: string, index: number) => void;
  mapFeature: MapFeature;
  trigger: boolean;
  toggleMapFeature: any;
  toggleFullScreen: () => void;
  toggleLayer: ToggleLayer;
  rightSideMenuCollapsed: boolean;
  vineblockId: string;
  setSliderVineBlocks: (vineBlocks: any) => void;
  selectedDateRange: Date[];
  jumpAndGoTractorId: any;
  setLoader: (loader: boolean) => void;
  setSuggestedPolygonFeature: (data: MapFeature[]) => void;
  publishedTimeToReload: number;
  reloadVineBlocks: boolean;
  deleteRouteId: string;
}
const MapMakerMap: React.FC<Props> = memo(
  ({
    setRoutesFeature,
    setTravelledRoutesDataFeature,
    setVineFeature,
    setVineRowFeature,
    setAbLineFeature,
    setAbBlockFeature,
    setVineBlockFeature,
    setPolygonFeature,
    setVineLaneFeature,
    setWaypointFeature,
    setJumpAndGoFeature,
    handleToggleLayer,
    mapFeature,
    trigger,
    toggleFullScreen,
    toggleLayer: toggleLayer,
    rightSideMenuCollapsed,
    vineblockId,
    setSliderVineBlocks,
    selectedDateRange,
    jumpAndGoTractorId,
    setLoader,
    setSuggestedPolygonFeature,
    publishedTimeToReload,
    reloadVineBlocks,
    deleteRouteId,
  }: Props) => {
    const { push } = useHistory();
    const [mapInitialized, setMapInitialized] = useState(false);
    const { userDetails, APPReducer } = useContext(ApplicationContext);
    const [appState, appDispatch] = APPReducer;
    const { groundZero, polygonsList } = appState;

    const { collapsed } = useContext(MenuContext);
    const [base, setBase] = useState<[number, number]>([0, 0]);
    const [baseLat, setGroundLat] = useState(0);
    const [baseLang, setGroundLang] = useState(0);
    const [updateBaseLat, setUpdateBaseLat] = useState(0);
    const [updateBaseLang, setUpdateBasedLang] = useState(0);
    const [toggleSatellite, setToggleSatellite] = useState(true);
    const [fileupload, setFileUpload] = useState(false);
    const [fileDownload, setFileDownload] = useState(false);
    const [imgUpload, setImgUpload] = useState(false);
    const [layerVisibleUpdated, setLayerVisibleUpdated] = useState<any>({});
    const [showPoly, setShowPoly] = useState<boolean>(false);
    const [showAbBlock, setShowAbBlock] = useState<boolean>(false);
    const [latLong, setLatLong] = useState<boolean>(false);
    const [showVineRow, setShowVineRow] = useState<boolean>(false);
    const [showAbLine, setShowAbLine] = useState<boolean>(false);
    const [showEditPoly, setShowEditPoly] = useState<boolean>(false);
    const [showWaypoint, setShowWaypoint] = useState<boolean>(false);
    const [showMarkPoint, setShowMarkPoint] = useState<boolean>(false);
    const [showVineBlock, setShowVineBlock] = useState<boolean>(false);
    const [showVineLane, setShowVineLane] = useState<boolean>(false);
    const [vineBlockPayload, setVineBlockPayload] =
      useState<VineBlockPayloadType>();
    const [vineLanePayload, setVineLanePayload] =
      useState<VineLanePayloadType>();
    const [polyPayload, setPolyPayload] = useState<PolyPayload>();
    const [abBlockPayload, setAbBlockPayload] =
      useState<AbBlockPayload | null>();
    const [vineRowPayload, setVineRowPayload] =
      useState<VineRowPayloadSingle | null>(null);
    const [abLinePayload, setAbLinePayload] =
      useState<AbLinePayloadSingle | null>();
    const [abLinePayload1, setAbLinePayload1] =
      useState<AbLinePayloadSingle | null>();
    const [vineRowModel2FirstPayload, setVineRowModel2FirstPayload] =
      useState<VineRowPayload | null>();
    const [vineRowModel2LastPayload, setVineRowModel2LastPayload] =
      useState<VineRowPayload | null>();
    const [wayPointPayload, setWayPointPayload] =
      useState<WaypointPayloadTypes>();
    const [markPointPayload, setMarkPointPayload] =
      useState<MarkPointPayloadTypes>();
    const [vinePayload, setVinePayload] = useState<VinesPayloadTypes>();
    const [showVine, setShowVine] = useState<boolean>(false);
    const [polygonsListForPop, setPolygonsListForPop] = useState<any[]>([]);
    const [brownPolygons, setBrownPolygons] = useState<any>([]);
    const [showDiscover, setShowDiscover] = useState(false);
    const [waypoints, setWaypoints] = useState<any>([]);
    const [vines, setVines] = useState<any>([]);
    const [polygons, setPolygons] = useState<any>([]);
    const [abBlocks, setAbBlocks] = useState<any>([]);
    const [vineRows, setVineRows] = useState<any>([]);
    const [abLines, setAbLines] = useState<any>([]);
    const [vineBlocks, setvineBlocks] = useState<any>([]);
    const [vineLanes, setvineLanes] = useState<any>([]);
    const [marks, setMarks] = useState<any>([]);
    const [vineRowHeads, setVineRowHeads] = useState<any>([]);
    const [brownPoly, setBrownPoly] = useState('');
    const [activeDrawAction, setActiveDrawAction] =
      useState<DRAWITEM['item']>('NONE');
    const [showSave, setShowSave] = useState(false);
    const [vineLaneModeal, setvineLaneModeal] = useState<1 | 2>(1);
    const [vineRowModal, setvineRowModal] = useState<1 | 2 | 3 | 4>(1);
    const [reloadSuggestedPolygons, setReloadSuggestedPolygons] =
      useState(false);
    const [selectedJumpAndGoForVineRow, setSelectedJumpAndGoForVineRow] =
      useState<any[]>([]);
    const [
      selectedJumpAndGoListForVineRows,
      setSelectedJumpAndGoListForVineRows,
    ] = useState<any[]>([]);
    const [tractors, setTractorsData] = useState<TractorsList[]>([]);
    const [selectedTractor, setSelectedtractor] = useState<string>('');
    const [selctedVinerowsFromJumpandGo, setSelctedVinerowsFromJumpandGo] =
      useState<any[]>([]);
    const [triggerCancelOperation, setTriggerCancelOperation] =
      useState<boolean>(false);
    const { confirm } = Modal;

    const mapRef = useRef<OLMap | null>(null);
    const mapElement: any = useRef();
    const tractorLayerRef = useRef<VectorLayer<any>>();
    const initialLayer = useRef<VectorLayer<any>>();
    const vinesLayerRef = useRef<VectorLayer<any>>();
    const routeLayerRef = useRef<VectorLayer<any>>();
    const jumpAndGoLayerRef = useRef<VectorLayer<any>>();
    const travelledRouteLayerRef = useRef<VectorLayer<any>>();
    const desiredRouteLayerRef = useRef<VectorLayer<any>>();
    const vineRowsLayerRef = useRef<VectorLayer<any>>();
    const abLinesLayerRef = useRef<VectorLayer<any>>();
    const abBlockLayerRef = useRef<VectorLayer<any>>();
    const vineRowsForJumpAndGoLayerRef = useRef<VectorLayer<any>>();
    const vineBlocksLayerRef = useRef<VectorLayer<any>>();
    const polygonsLayerRef = useRef<VectorLayer<any>>();
    const vineLaneLayerRef = useRef<VectorLayer<any>>();
    const waypointsLayerRef = useRef<VectorLayer<any>>();
    const marksLayerRef = useRef<VectorLayer<any>>();
    const drawFeaturesLayer = useRef<VectorLayer<any>>();
    const vineRowHeadLayerRef = useRef<VectorLayer<any>>();
    const implementsLayerRef = useRef<VectorLayer<any>>();
    const vectorTileLayerRef = useRef<TileLayer<any>>();
    const graticuleLayerRef = useRef<Graticule>();
    const satelliteTileLayerRef = useRef<TileLayer<any>>();
    const fieldFeatureItem = useRef<any>();
    const baseFeatureItem = useRef<any>();
    const container: any = useRef();
    const content: any = useRef();
    // default right click menu
    const contextMenuItems = [
      {
        text: 'Recentre',
        classname: 'some-style-class', // add some CSS rules
        callback: () => reCenter(), // `center` is your callback function
      },
    ];
    // default right click menu setup
    const contextmenu = new ContextMenu({
      width: 180,
      items: contextMenuItems,
    });
    let mapMakeToggle = false;

    useEffect(() => {
      userDetails &&
        userDetails.organization &&
        mapInitialized &&
        base &&
        getVineBlocksListData();
    }, [reloadVineBlocks]);

    // to reload the ab-line and ab-block
    useEffect(() => {
      publishedTimeToReload && getAbLineListData();
      publishedTimeToReload && getAbBlocksListData();
    }, [publishedTimeToReload]);

    useEffect(() => {
      if (userDetails && userDetails.organization && groundZero) {
        initializeMap();
      }
    }, [userDetails, groundZero]);

    const mousePositionControl = new MousePosition({
      coordinateFormat: createStringXY(4),
      projection: 'EPSG:3857',
    });

    let graticule = new Graticule({
      strokeStyle: new Stroke({
        color: 'rgba(1, 1, 1, 1)',
        width: 1,
      }),
      intervals: [gridArray[0]],
      showLabels: false,
      targetSize: 5,
      visible: false,
    });
    // Map Initialized Start here
    const initializeMap = async () => {
      try {
        // const { organization } = userDetails;
        // const { latitude, longitude } = await getGroundZero(
        //   organization.api_url,
        //   organization.farm.id,
        // );
        const { latitude, longitude } = groundZero;
        setGroundLat(latitude);
        setGroundLang(longitude);
        setUpdateBaseLat(latitude);
        setUpdateBasedLang(longitude);

        setBase([latitude, longitude]);

        const baseLocation = new Feature({
          geometry: new Point(proj.fromLonLat([longitude, latitude])),
          name: 'Ground zero',
        });
        baseFeatureItem.current = baseLocation;
        // base location feature layer
        const initialFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [baseLocation],
          }),
          style: new Style({
            image: new Icon({
              src: BaseStationIcon,
              scale: 0.4,
            }),
          }),
        });
        // draw feature layer
        const drawFeatureslayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
        });
        // road map layer
        const vectorLayer = new TileLayer({
          source: new OSM(),
          visible: false,
        });
        // satelight map layer
        const satelliteLayer = new TileLayer({
          source: new XYZ({
            url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga',
            cacheSize: 1000,
          }),
          visible: true,
        });
        // vine rows list layer
        const vineRowsFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          zIndex: priority.VINEROW,
          visible: listVisibility.VINEROW,
          style: vineRowStyle,
        });
        const vineRowsFromJumpAndGoFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          zIndex: priority.VINEROW,
          visible: true,
          style: vineRowFromJAGStyle,
        });

        // polygons list layer
        const polygonsFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          visible: listVisibility.POLYGON,
        });
        // vines list layer
        const vinesFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          zIndex: priority.VINE,
          visible: listVisibility.VINE,
          style: mapmakerVineStyle,
        });
        // vine lanes list layer
        const vineLaneFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          visible: listVisibility.VINELANE,
        });
        // vine blocks list layer
        const vineBlocksFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          zIndex: priority.VINEBLOCK,
          visible: listVisibility.VINEBLOCK,
          style: vineBlockStyle,
        });
        // waypoints list layer
        const waypointsFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          visible: listVisibility.WAYPOINT,
          zIndex: priority.WAYPOINT,
          style: mapMakerwWypointStyle,
        });
        // marks list layer
        const marksFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          visible: listVisibility.MARKS,
          style: markStyle,
        });
        // vine rows heads list layer
        const vinerowHeadFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          visible: listVisibility.VINE_ROW_HEAD,
          style: vineRowHeadStyle,
        });
        // ab lines list layer
        const abLinesFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          zIndex: priority.ABLANE,
          visible: listVisibility.ABLINE,
          style: abLineStyle,
        });

        // polygons list layer
        const abBlockFeaturesLayer = new VectorLayer({
          source: new VectorSource({
            features: [],
          }),
          visible: listVisibility.ABBLOCK,
        });
        // create map
        const initialMap = new OLMap({
          target: mapElement.current,
          layers: [
            satelliteLayer,
            vectorLayer,
            initialFeaturesLayer,
            graticule,
            drawFeatureslayer,
            vineRowsFeaturesLayer,
            polygonsFeaturesLayer,
            vinesFeaturesLayer,
            vineLaneFeaturesLayer,
            vineBlocksFeaturesLayer,
            waypointsFeaturesLayer,
            marksFeaturesLayer,
            vinerowHeadFeaturesLayer,
            vineRowsFromJumpAndGoFeaturesLayer,
            abLinesFeaturesLayer,
            abBlockFeaturesLayer,
          ],
          controls: [scaleControl(), mousePositionControl],
          view: new View({
            projection: 'EPSG:3857',
            center: proj.transform(
              [longitude, latitude],
              'EPSG:4326',
              'EPSG:3857',
            ),
            zoom: 15,
            // minZoom: 15,
            maxZoom: 28,
          }),
        });
        setMapInitialized(true);
        mapRef.current = initialMap;
        initialLayer.current = initialFeaturesLayer;
        satelliteTileLayerRef.current = satelliteLayer;
        vectorTileLayerRef.current = vectorLayer;
        graticuleLayerRef.current = graticule;
        drawFeaturesLayer.current = drawFeatureslayer;
        waypointsLayerRef.current = waypointsFeaturesLayer;
        vineRowsLayerRef.current = vineRowsFeaturesLayer;
        vineRowsForJumpAndGoLayerRef.current =
          vineRowsFromJumpAndGoFeaturesLayer;

        polygonsLayerRef.current = polygonsFeaturesLayer;
        vinesLayerRef.current = vinesFeaturesLayer;
        vineLaneLayerRef.current = vineLaneFeaturesLayer;
        vineBlocksLayerRef.current = vineBlocksFeaturesLayer;
        marksLayerRef.current = marksFeaturesLayer;
        vineRowHeadLayerRef.current = vinerowHeadFeaturesLayer;
        abLinesLayerRef.current = abLinesFeaturesLayer;
        abBlockLayerRef.current = abBlockFeaturesLayer;
        let currZoom = mapRef?.current?.getView().getZoom();
        // set the grids based on zoom
        mapRef.current?.on('moveend', function (e) {
          const newZoom = mapRef?.current?.getView().getZoom();
          if (graticuleToggle && currZoom != newZoom) {
            if (newZoom) {
              if (currZoom && newZoom > currZoom) {
                zoomInGrid();
              } else {
                zoomOutGrid();
              }
            }
            currZoom = newZoom;
          }
        });
      } catch (err: any) {
        notification.error({
          message: err.response?.data.error.message,
        });
      }
    };
    // Map Inititilesed Start here
    const scaleControl = () => {
      const control = new ScaleLine({
        units: 'metric',
        className: 'positionMap',
      });
      return control;
    };
    useEffect(() => {
      if (
        base &&
        mapInitialized &&
        baseFeatureItem &&
        baseFeatureItem.current
      ) {
        const baseDrag = new Modify({
          features: new Collection([baseFeatureItem.current]),
          style: new Style({}),
        });
        // Add the event to the drag and drop feature
        baseDrag.on('modifyend', function (evt: any) {
          const arr: any = evt.features.getArray();
          if (arr && arr.length && arr[arr.length - 1]) {
            const point = arr[arr.length - 1].getGeometry().getCoordinates();
            const latLong: number[] = proj.transform(
              point,
              'EPSG:3857',
              'EPSG:4326',
            );
            setShowSave(true);
            setActiveDrawAction('BASESTATION');
            setUpdateBaseLat(latLong[1]);
            setUpdateBasedLang(latLong[0]);
          }
        });
        mapRef.current?.addInteraction(baseDrag);
      }
    }, [mapInitialized, base]);

    useEffect(() => {
      if (mapInitialized) {
        // added right click menu
        mapRef.current?.addControl(contextmenu);
        // get the travvled routes
        getTravelledRoutes();
        // get the Routes
        getRoutes();

        // added code on hover namings
        const overlay = new Overlay({
          element: container.current,
          autoPan: false,
          autoPanAnimation: {
            duration: 10,
          },
        });
        mapRef.current?.on('pointermove', function (evt) {
          const hit = mapRef.current?.forEachFeatureAtPixel(
            evt.pixel,
            function (feature: FeatureLike) {
              const coordinate = evt.coordinate;
              let name = feature.get('name');
              const pointType = feature.get('pointType');
              const color = feature.get('color');
              // added below code displaying remarks field
              if (pointType === 'Polygon' && color === 'brown') {
                const remarks = feature.get('remarks');
                if (remarks) {
                  name = feature.get('label');
                  const innerHTML = getBrownPolyRemarks(name, remarks);
                  content.current.innerHTML = innerHTML;
                  if (
                    !overlay
                      .getElement()
                      ?.classList.contains('ol-details-popup')
                  ) {
                    overlay.getElement()?.classList.add('ol-details-popup');
                  }
                  overlay.setPosition(evt.coordinate);
                  mapRef.current?.addOverlay(overlay);
                  return true;
                }
              }
              if (name) {
                content.current.innerHTML =
                  '<p class="nameOverlay">' + name + '</p>';
                overlay.setPosition(coordinate);
                mapRef.current?.addOverlay(overlay);
                return true;
              }

              return false;
            },
          );
          if (hit) {
            evt.map.getTargetElement().style.cursor = 'pointer';
            if (
              (activeDrawActionGlobal === 'WAYPOINT' ||
                activeDrawActionGlobal === 'VINE') &&
              !showSaveGlobal
            ) {
              evt.map.getTargetElement().style.cursor = 'none';
            }
          } else {
            mapRef.current?.removeOverlay(overlay);
            evt.map.getTargetElement().style.cursor = '';
            if (
              (activeDrawActionGlobal === 'WAYPOINT' ||
                activeDrawActionGlobal === 'VINE') &&
              !showSaveGlobal
            ) {
              evt.map.getTargetElement().style.cursor = 'none';
            }
            return;
          }
        });
        mapRef.current?.on('click', function (evt) {
          mapRef.current?.forEachFeatureAtPixel(
            evt.pixel,
            function (feature: any) {
              evt.preventDefault();
              if (!mouseClickEvent) {
                setShowSave(true);
                mouseClickEvent = true;
                setTimeout(function () {
                  mouseClickEvent = false;
                  if (
                    feature &&
                    feature.get('pointType') === 'VINEROWFROMJUMPANDGO' &&
                    activeDrawActionGlobal === 'VINEROWFROMJUMPANDGO'
                  ) {
                    let exist = false;
                    selectedVineRowsForVineBlock.forEach(
                      (element: { rowUuid: string }) => {
                        if (
                          !exist &&
                          element.rowUuid === feature.get('rowUuid')
                        ) {
                          exist = true;
                        }
                      },
                    );
                    if (!exist) {
                      selectedVineRowsForVineBlock.push({
                        name: feature.get('name'),
                        rowUuid: feature.get('rowUuid'),
                        data: feature.get('rowData'),
                      });
                      feature.setStyle(vineRowDisableStyle);
                      if (
                        activeDrawActionGlobal === 'VINEROWFROMJUMPANDGO' &&
                        selectedVineRowsForVineBlock.length === 2
                      ) {
                        saveVineRowFromJumpandGo();
                      }
                    } else {
                      const li: any[] = [];
                      selectedVineRowsForVineBlock.forEach(
                        (element: { rowUuid: string }) => {
                          if (element.rowUuid === feature.get('rowUuid')) {
                            feature.setStyle(vineRowStyle);
                          } else {
                            li.push(element);
                          }
                        },
                      );
                      selectedVineRowsForVineBlock = li;
                    }
                  } else if (
                    feature &&
                    feature.get('pointType') === 'vinerow' &&
                    (activeDrawActionGlobal === 'VINEBLOCK' ||
                      activeDrawActionGlobal === 'VINEBLOCKEDIT' ||
                      activeDrawActionGlobal === 'VINELANE')
                  ) {
                    let exist = false;
                    selectedVineRowsForVineBlock.forEach(
                      (element: { rowUuid: string }) => {
                        if (
                          !exist &&
                          element.rowUuid === feature.get('rowUuid')
                        ) {
                          exist = true;
                        }
                      },
                    );
                    if (!exist) {
                      selectedVineRowsForVineBlock.push({
                        name: feature.get('name'),
                        rowUuid: feature.get('rowUuid'),
                      });
                      feature.setStyle(vineRowDisableStyle);
                      if (
                        activeDrawActionGlobal === 'VINELANE' &&
                        selectedVineRowsForVineBlock.length === 2
                      ) {
                        saveVineLane();
                      }
                    } else {
                      const li: any[] = [];
                      selectedVineRowsForVineBlock.forEach(
                        (element: { rowUuid: string }) => {
                          if (element.rowUuid === feature.get('rowUuid')) {
                            feature.setStyle(vineRowStyle);
                          } else {
                            li.push(element);
                          }
                        },
                      );
                      selectedVineRowsForVineBlock = li;
                    }
                  }
                }, 1000);
              }
            },
          );
        });
      }
    }, [userDetails, mapInitialized]);
    /**
     * Subscribing to Tractors and getting Information Starts Here
     */

    /**
     * Vines initialization starts Here
     */
    const getVines = async () => {
      vinesLayerRef.current?.getSource().clear();
      try {
        const records = vines && vines.length > 0 ? vines : [];
        const vinesFeatures: Feature<Geometry>[] = [];
        const vineList: MapFeature[] = [];
        records.map((vine: any) => {
          const id = `vine_${vine.id}`;
          let latAndLong: number[] = [];
          if (vine.latitude !== null && vine.longitude !== null) {
            latAndLong = [vine.longitude, vine.latitude];
          } else {
            latAndLong = getLatitudeAndLongitude(
              [vine.y / 100, vine.x / 100],
              base,
              true,
            );
          }
          const vineLocation = new Feature({
            geometry: new Point(proj.fromLonLat(latAndLong)),
            pointType: 'vine',
            name: vine.name,
            pointId: vine.id,
          });
          vineLocation.setId(id);
          vinesFeatures.push(vineLocation);
          vineList.push({
            id: vine.id,
            name: vine.name,
            type: 'VINE',
            mapId: id,
            visible: true,
            style: mapmakerVineStyle,
          });
        });

        vinesLayerRef.current?.getSource().addFeatures(vinesFeatures);
        vinesLayerRef.current?.setVisible(listVisibility.VINE);
        setVineFeature(vineList);

        const vineDrag = new Modify({
          features: new Collection(vinesFeatures),
          style: new Style({}),
          // pixelTolerance: 20,
        });
        // Add the event to the drag and drop feature
        vineDrag.on('modifyend', function (evt: any) {
          dragVinePoint(evt);
        });
        // Add the vector layer and the refering drag and drop interaction
        mapRef.current?.addInteraction(vineDrag);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    /**
     * Vines initialization Ends Here
     */

    /**
     * Start Travelled Points
     */
    const getTravelledRoutes = async () => {
      const routeList: MapFeature[] = [];
      const travelledRoutes = await getTravelledRoute(
        userDetails.organization.api_url,
        userDetails.organization.id,
        userDetails.organization.farm.id,
      );

      const routeFeature: Feature<Geometry>[] = [];
      const desiredFeature: Feature<Geometry>[] = [];
      const style = new Style({
        stroke: new Stroke({
          color: '#64F4FF',
          width: 3,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 255, 0.1)',
        }),
      });
      const desiredStyle = new Style({
        stroke: new Stroke({
          color: 'blue',
          width: 3,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 255, 0.1)',
        }),
      });
      travelledRoutes.map(
        async (route: {
          id: number;
          copycat_name: string;
          copycat_travelled_route_uuid: string;
        }) => {
          const routeLocation = new Feature({
            geometry: new LineString([]),
            name: route.copycat_name,
          });
          routeLocation.setId(route.copycat_travelled_route_uuid);

          const desiredLocation = new Feature({
            geometry: new LineString([]),
            name: route.copycat_name,
          });
          desiredLocation.setId(route.copycat_travelled_route_uuid);

          routeFeature.push(routeLocation);
          desiredFeature.push(desiredLocation);
          routeList.push({
            id: route.id,
            name: route.copycat_name,
            type: 'TRAVELLEDROUTE',
            mapId: route.copycat_travelled_route_uuid,
            visible: false,
            style,
          });
        },
      );
      const routeFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: routeFeature,
        }),
        zIndex: Infinity,
        style,
      });
      routeFeaturesLayer.setZIndex(0);
      mapRef.current?.addLayer(routeFeaturesLayer);

      const desiredFeatureLayer = new VectorLayer({
        source: new VectorSource({
          features: desiredFeature,
        }),
        zIndex: Infinity,
        style: desiredStyle,
      });
      desiredFeatureLayer.setZIndex(0);
      mapRef.current?.addLayer(desiredFeatureLayer);

      travelledRouteLayerRef.current = routeFeaturesLayer;
      travelledRouteLayerRef.current?.setVisible(listVisibility.TRAVELLEDROUTE);
      desiredRouteLayerRef.current = desiredFeatureLayer;
      setTravelledRoutesDataFeature(routeList);
    };

    const travelledRouteUpdate = async (route: MapFeature) => {
      const { organization } = userDetails;
      const defaultLayer = travelledRouteLayerRef.current;
      let tractorLocation: Feature<Geometry> = new Feature();
      if (defaultLayer) {
        tractorLocation = defaultLayer?.getSource().getFeatureById(route.mapId);
      }
      if (!tractorLocation) {
        tractorLocation = new Feature();
      }

      const { actual_path }: any = await getActualRoute(
        organization.api_url,
        organization.id,
        organization.farm.id,
        route.mapId,
      );

      if (actual_path.length > 0) {
        const array = actual_path.map(
          (item: { latitude: number; longitude: number }) =>
            proj.fromLonLat([item.longitude, item.latitude]),
        );

        tractorLocation.setGeometry(new LineString(array));
        tractorLocation.setId(route.mapId);
        tractorLocation.set('name', `${route.name}_actual`);
        tractorLocation.setStyle(route.style);

        if (mapRef.current) {
          if (actual_path && actual_path.length > 0) {
            const cordinates: { latitude: number; longitude: number } =
              actual_path[0];

            mapRef.current
              .getView()
              .setCenter(
                proj.fromLonLat([cordinates.longitude, cordinates.latitude]),
              );
          }
        }
      }
    };

    const desiredRouteUpdate = async (route: MapFeature) => {
      const { organization } = userDetails;
      const defaultLayer = desiredRouteLayerRef.current;
      defaultLayer?.setVisible(true);
      let tractorLocation: Feature<Geometry> = new Feature();
      if (defaultLayer) {
        tractorLocation = defaultLayer?.getSource().getFeatureById(route.mapId);
      }
      if (!tractorLocation) {
        tractorLocation = new Feature();
      }

      const desired_path: any = await getDesiredRoute(
        organization.api_url,
        organization.id,
        organization.farm.id,
        route.mapId,
      );

      if (desired_path.length > 0) {
        const array = desired_path.map(
          (item: { latitude: number; longitude: number }) =>
            proj.fromLonLat([item.longitude, item.latitude]),
        );

        tractorLocation.setGeometry(new LineString(array));
        tractorLocation.setId(route.mapId);
        tractorLocation.set('name', `${route.name}_desired`);
        const desiredStyle = new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 3,
          }),
          fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
          }),
        });
        tractorLocation.setStyle(desiredStyle);
      }
    };

    /**
     * Stop Travelled Points
     */

    /**
     * Draw route start Here
     */
    useEffect(() => {
      if (deleteRouteId && deleteRouteId !== '') {
        const feature = routeLayerRef.current
          ?.getSource()
          .getFeatureById(deleteRouteId);
        const id = deleteRouteId.replace('route_', '');
        const name = feature ? feature?.get('name') : '';
        confirmDelete(id, 'ROUTE', name);
      }
    }, [deleteRouteId]);
    const deleteRoute = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteRouteFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        if (resp && resp.msg) {
          const feature = routeLayerRef.current
            ?.getSource()
            .getFeatureById('route_' + pointId);
          feature && routeLayerRef.current?.getSource().removeFeature(feature);
          notification.success({
            message: resp.msg,
          });
          getRoutes();
        }
      } catch (err: any) {
        notification.error({
          message: err.resp?.data.error.message,
        });
      }
    };

    // get routes routes
    const getRoutes = async () => {
      try {
        const routeList: MapFeature[] = [];
        const { organization } = userDetails;
        const records: { id: number; name: string }[] = await getRoute(
          organization.api_url,
          organization.farm.id,
        );
        const routeFeature: Feature<Geometry>[] = [];
        const style = new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 3,
          }),
          fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
          }),
        });
        records.map(
          async (route: { id: number; name: string }, index: number) => {
            const record = [{ x: 0, y: 0 }];
            if (record.length > 0) {
              const id = 'route_' + route.id;
              const convertedRecord = convertRouteXYtoLatLang(
                record,
                baseLat,
                baseLang,
              );
              const array = convertedRecord.map(
                (item: { x: number; y: number }) =>
                  proj.fromLonLat([item.x, item.y]),
              );
              const routeLocation = new Feature({
                geometry: new LineString(array),
                name: route.name,
              });
              routeLocation.setId(id);
              routeFeature.push(routeLocation);
              routeList.push({
                id: route.id,
                name: route.name,
                type: 'ROUTE',
                mapId: id,
                visible: false,
                style,
              });
            }
            if (index + 1 === records.length) {
              const routeFeaturesLayer = new VectorLayer({
                source: new VectorSource({
                  features: routeFeature,
                }),
                zIndex: Infinity,
                style,
              });
              routeFeaturesLayer.setZIndex(0);
              mapRef.current?.addLayer(routeFeaturesLayer);
              routeLayerRef.current = routeFeaturesLayer;
              setRoutesFeature(routeList);
            }
          },
        );
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };

    const routeUpdate = async (route: MapFeature) => {
      const { organization } = userDetails;
      const defaultLayer = routeLayerRef.current;
      let tractorLocation: Feature<Geometry> = new Feature();
      if (defaultLayer) {
        tractorLocation = defaultLayer?.getSource().getFeatureById(route.mapId);
      }
      if (!tractorLocation) {
        tractorLocation = new Feature();
      }
      const records: LatLang[] = await getRoutePoints(
        organization.api_url,
        organization.farm.id,
        route.id,
      );
      if (records.length > 0) {
        let latLanOrXY = false;
        const { latitude, longitude } = records[0];
        if (latitude && longitude) {
          latLanOrXY = true;
        } else {
          latLanOrXY = false;
        }
        const coordinates: any[] = [];
        if (latLanOrXY) {
          records.forEach((element: any) => {
            coordinates.push(
              proj.fromLonLat([element.longitude, element.latitude]),
            );
          });
        } else {
          records.forEach((element) => {
            const latAndLong = getLatitudeAndLongitude(
              [element.y, element.x],
              base,
              true,
            );
            coordinates.push(proj.fromLonLat(latAndLong));
          });
        }
        tractorLocation.setGeometry(new LineString(coordinates));
        tractorLocation.setId(route.mapId);
        tractorLocation.set('name', route.name);
        tractorLocation.setStyle(route.style);
      }
    };

    // convert points to latitude and longitude

    const getFeatureLayer = (type: string) => {
      let layer: VectorLayer<any> | any;
      if (type === 'TRACTOR') {
        layer = tractorLayerRef.current;
      } else if (type === 'ROUTE') {
        layer = routeLayerRef.current;
      } else if (type === 'VINE') {
        layer = vinesLayerRef.current;
      } else if (type === 'VINEROW') {
        layer = vineRowsLayerRef.current;
      } else if (type === 'VINEBLOCK') {
        layer = vineBlocksLayerRef.current;
      } else if (type === 'POLYGON') {
        layer = polygonsLayerRef.current;
      } else if (type === 'WAYPOINT') {
        layer = waypointsLayerRef.current;
      } else if (type === 'IMPLEMENT') {
        layer = implementsLayerRef.current;
      } else if (type === 'TRAVELLEDROUTE') {
        layer = travelledRouteLayerRef.current;
      } else if (type === 'MARKS') {
        layer = marksLayerRef.current;
      } else if (type === 'VINELANE') {
        layer = vineLaneLayerRef.current;
      } else if (type === 'JUMPANDGO') {
        layer = jumpAndGoLayerRef.current;
      } else if (type === 'WINEROWHEAD') {
        layer = vineRowHeadLayerRef.current;
      } else if (type === 'ABLINE') {
        layer = abLinesLayerRef.current;
      } else if (type === 'ABBLOCK') {
        layer = abBlockLayerRef.current;
      }

      return layer;
    };

    const handleVisiabilityMenu = (menuItem: any) => {
      switch (menuItem.type) {
        case 'ROUTE':
          listVisibility.ROUTE = menuItem.value;
          break;
        case 'TRAVELLEDROUTE':
          listVisibility.ROUTE = menuItem.value;
          break;
        case 'VINE':
          listVisibility.VINE = menuItem.value;
          break;
        case 'VINELANE':
          listVisibility.VINELANE = menuItem.value;
          break;
        case 'MARKS':
          listVisibility.MARKS = menuItem.value;
          break;
        case 'WINEROWHEAD':
          listVisibility.VINE_ROW_HEAD = menuItem.value;
          break;
        case 'VINEROW':
          listVisibility.VINEROW = menuItem.value;
          break;
        case 'VINEBLOCK':
          listVisibility.VINEBLOCK = menuItem.value;
          break;
        case 'POLYGON':
          listVisibility.POLYGON = menuItem.value;
          break;
        case 'WAYPOINT':
          listVisibility.WAYPOINT = menuItem.value;
          break;
        case 'JUMPANDGO':
          listVisibility.ROUTE = menuItem.value;
          break;
        case 'ABLINE':
          listVisibility.ABLINE = menuItem.value;
          break;
        case 'ABBLOCK':
          listVisibility.ABBLOCK = menuItem.value;
          break;
        default:
          break;
      }
    };
    const hideShowFeature = (mapFeature: MapFeature) => {
      // handleVisiabilityMenu(mapFeature);
      const layer: VectorLayer<any> | any = getFeatureLayer(mapFeature.type);
      const feature = layer.getSource().getFeatureById(mapFeature.mapId);
      handleVisiabilityMenu(mapFeature);
      if (!layer.visible) {
        layer.setVisible(true);
      }
      if (feature) {
        if (mapFeature.visible) {
          if (mapFeature.type === 'ROUTE') {
            routeUpdate(mapFeature);
            return;
          } else if (mapFeature.type === 'TRAVELLEDROUTE') {
            travelledRouteUpdate(mapFeature);
            desiredRouteUpdate(mapFeature);
            return;
          } else if (mapFeature.type === 'JUMPANDGO') {
            if (mapFeature && mapFeature.actionUuid) {
              drawJAG(mapFeature, mapFeature.mapId, mapFeature.name);
            }
            return;
          }
          feature.setStyle(mapFeature.style);
        } else {
          if (mapFeature.type === 'TRAVELLEDROUTE') {
            const desiredLayer = desiredRouteLayerRef.current;
            const feature = desiredLayer
              ?.getSource()
              .getFeatureById(mapFeature.mapId);
            feature?.setStyle(new Style({}));
          }
          feature.setStyle(new Style({})); // to hide the item
        }
        // to show item
      }
      const layersUpdated = layerVisibleUpdated;
      layersUpdated[mapFeature.type] = layer.visible;
      setLayerVisibleUpdated(layersUpdated);
    };
    // on page load getting map items list Api calls
    useEffect(() => {
      if (userDetails && userDetails.organization && mapInitialized && base) {
        getWayPointsListData();
        // getVineRowsListData();
        getVineBlocksListData();
        // on page load once get from context
        getPolygonsListData(
          polygonsList && polygonsList.length > 0 ? true : false,
        );
        // getVineLanesListData();
        getVinesListData();

        getMarksListData();
        getVineRowHeadsListData();
        getAbLineListData();
        getAbBlocksListData();
      }
    }, [userDetails, mapInitialized, base]);

    useEffect(() => {
      drawWaypoints();
    }, [waypoints]);

    useEffect(() => {
      drawVineRows();
    }, [vineRows, selectedJumpAndGoListForVineRows]);

    useEffect(() => {
      drawVineBlocks();
    }, [vineBlocks, vineRows]);

    useEffect(() => {
      getVineLanes();
    }, [vineLanes]);

    useEffect(() => {
      getVines();
    }, [vines]);

    useEffect(() => {
      drawWinerowHead();
    }, [vineRowHeads]);

    useEffect(() => {
      drawMarks();
    }, [marks]);
    // useEffect(() => {
    //   drawAbLines();
    // }, [abLines]);

    useEffect(() => {
      drawAbBlocks();
    }, [abBlocks]);
    // get the waypoints data
    const getWayPointsListData = async () => {
      try {
        const { organization } = userDetails;
        const response = await getWaypointsData(
          organization.api_url,
          organization.farm.id,
        );

        const { records } = response;
        setWaypoints(records);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    // get the vine rows data
    const getVineRowsListData = async () => {
      try {
        const { organization } = userDetails;
        const response = await getVineRowsData(
          organization.api_url,
          organization.farm.id,
        );
        const { records } = response;
        setVineRows(records);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    // get the vine block data
    const getVineBlocksListData = async () => {
      getVineRowsListData();
      getVineLanesListData();
      try {
        const { organization } = userDetails;
        const response = await getVineBlocksData(
          organization.api_url,
          organization.farm.id,
        );
        const { records } = response;
        setvineBlocks(records);
        // setSliderVineBlocks(records);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    // get the polygons data
    const getPolygonsListData = async (loadFromContext = false) => {
      polygonsLayerRef.current?.getSource().clear();
      globalPolyUnique = Math.random();
      try {
        const { organization } = userDetails;
        let list = [];
        if (loadFromContext) {
          list = polygonsList;
        } else {
          const response = await getPolygonsData(
            organization.api_url,
            organization.farm.id,
          );
          const { records } = response;
          list = records && records.length && records.length > 0 ? records : [];
        }
        const brownList: any[] = [];
        list.map(async (poly: any) => {
          const { color }: { color: string } = poly;
          if (color === 'brown') {
            brownList.push(poly);
          }
        });
        getPolygons(globalPolyUnique, list, loadFromContext, loadFromContext);
        getBrownPolyons(globalPolyUnique, brownList, false);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    // get the vine lanes data
    const getVineLanesListData = async () => {
      try {
        const { organization } = userDetails;
        const response = await getVineLaneData(
          organization.api_url,
          organization.farm.id,
        );
        const { records } = response;
        setvineLanes(records);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    // get the vines data
    const getVinesListData = async () => {
      try {
        const { organization } = userDetails;
        const response = await getAllVinePoints(
          organization.api_url,
          organization.farm.id,
        );
        const { records } = response;
        setVines(records);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    // get the marks data
    const getMarksListData = async () => {
      try {
        const { organization } = userDetails;
        const response = await getMarksData(
          organization.api_url,
          organization.farm.id,
          'marks',
        );
        const { records } = response;
        setMarks(records);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    // get the vine row heads data
    const getVineRowHeadsListData = async () => {
      try {
        const { organization } = userDetails;
        const response = await getMarksData(
          organization.api_url,
          organization.farm.id,
          'vine_row_head',
        );
        const { records } = response;
        setVineRowHeads(records);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    // get the vine rows data
    const getJumpAndGoVineRowsListData = async () => {
      let temp: any[] = [];
      setSelectedJumpAndGoListForVineRows([]);
      const { organization } = userDetails;
      setLoader(true);
      const res = await Promise.all(
        selectedJumpAndGoForVineRow.map((item: any) =>
          getJumpAndGoVineRowsData(
            organization.api_url,
            organization.farm.id,
            item.index,
          ).then((res) => res),
        ),
      ).finally(() => setLoader(false));
      res.map(async (item: any, index: number) => {
        const records =
          item && item.vine_rows_list && item.vine_rows_list.length > 0
            ? item.vine_rows_list
            : [];
        if (records && records.length > 0) temp = [...temp, ...records];
        if (temp && temp.length > 0 && res.length === index + 1) {
          setSelectedJumpAndGoListForVineRows(temp);
        }
      });
    };

    // get the vine rows data
    const getAbLineListData = async () => {
      try {
        const { organization } = userDetails;
        const response = await getAbLineData(
          organization.api_url,
          organization.farm.id,
          1,
          100,
        );
        const { _metadata } = response;
        let list: any = [];
        const total_pages = _metadata.total_pages;
        abLinesLayerRef.current?.getSource().clear();
        if (total_pages > 0) {
          for (let i = 1; i <= total_pages; i++) {
            try {
              const resp = await getAbLineData(
                organization.api_url,
                organization.farm.id,
                i,
                100,
              );
              const { records } = resp;
              if (records && records.length > 0) list = [...list, ...records];
              globalAbLineUnique = Math.random();
              drawAbLines(records, globalAbLineUnique);
            } catch (error: any) {
              notification.error({
                message: error.response?.data.error.message,
              });
            }
          }
        }
        const vineRowsList: MapFeature[] = [];
        list.map((vine: any) => {
          const id = `ab_line_${vine.id}`;
          vineRowsList.push({
            id: vine.id,
            name: vine.name,
            type: 'ABLINE',
            mapId: id,
            visible: true,
            style: abLineStyle,
          });
        });

        setAbLineFeature(vineRowsList);
        setAbLines(list);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    /** VineRows initialization Starts Here
     *
     */
    // get the vine rows data
    const drawVineRows = async () => {
      vineRowsLayerRef.current?.getSource().clear();
      vineRowsForJumpAndGoLayerRef.current?.getSource().clear();
      try {
        const records = vineRows && vineRows.length > 0 ? vineRows : [];
        const vineRowsFeatures: Feature<Geometry>[] = [];
        const vineRowsList: MapFeature[] = [];
        records.map((vine: any) => {
          const coordinates: any = [];
          const id = `vine_row_${vine.id}`;
          let latAndLongA: number[] = [];
          let latAndLongB: number[] = [];
          if (
            vine.head_a_latitude !== null &&
            vine.head_a_longitude !== null &&
            vine.head_b_latitude !== null &&
            vine.head_b_longitude !== null
          ) {
            latAndLongA = [vine.head_a_longitude, vine.head_a_latitude];
            latAndLongB = [vine.head_b_longitude, vine.head_b_latitude];
          } else {
            const vineA = {
              id: vine.id,
              x: vine.head_a_x / 100,
              y: vine.head_a_y / 100,
            };
            const vineB = {
              id: vine.id,
              x: vine.head_b_x / 100,
              y: vine.head_b_y / 100,
            };
            latAndLongA = getLatitudeAndLongitude(
              [vineA.y, vineA.x],
              base,
              true,
            );
            latAndLongB = getLatitudeAndLongitude(
              [vineB.y, vineB.x],
              base,
              true,
            );
          }

          coordinates.push(proj.fromLonLat(latAndLongA));
          coordinates.push(proj.fromLonLat(latAndLongB));
          const vineLocation = new Feature({
            geometry: new LineString(coordinates),
            pointType: 'vinerow',
            rowUuid: vine.vine_row_uuid,
            pointName: vine.name,
            pointId: vine.id,
            rowData: vine,
            name: vine.name,
            head_a_bearing: vine.head_a_bearing,
            head_b_bearing: vine.head_b_bearing,
          });

          vineLocation.setId(id);
          vineLocation.setStyle(vineRowStyle);

          vineRowsFeatures.push(vineLocation);

          vineRowsList.push({
            id: vine.id,
            name: vine.name,
            type: 'VINEROW',
            mapId: id,
            visible: true,
            style: vineRowStyle,
            vine_blocks_uuid: vine.vine_blocks_uuid,
            vine_row_uuid: vine.vine_row_uuid,
          });
        });
        vineRowsLayerRef.current?.getSource().addFeatures(vineRowsFeatures);
        vineRowsLayerRef.current?.setVisible(listVisibility.VINEROW);

        setVineRowFeature(vineRowsList);

        const records1 =
          selectedJumpAndGoListForVineRows &&
          selectedJumpAndGoListForVineRows.length > 0
            ? selectedJumpAndGoListForVineRows
            : [];
        const vineRowsFeatures1: Feature<Geometry>[] = [];
        records1.map((vine: any) => {
          const coordinates: any = [];
          const id = `vine_row_${vine[0].row_name}`;
          vine.map((vineItem: any) => {
            if (vineItem.latitude !== null && vineItem.longitude !== null) {
              coordinates.push(
                proj.fromLonLat([vineItem.longitude, vineItem.latitude]),
              );
            } else {
              const vineA = {
                x: vine[0].x / 100,
                y: vine[0].y / 100,
              };
              const latAndLong = getLatitudeAndLongitude(
                [vineA.y, vineA.x],
                base,
                true,
              );
              coordinates.push(proj.fromLonLat(latAndLong));
            }
          });
          const vineLocation = new Feature({
            geometry: new LineString(coordinates),
            pointType: 'VINEROWFROMJUMPANDGO',
            rowUuid: vine[0].row_name,
            pointName: vine[0].row_name,
            pointId: vine[0].row_name,
            rowData: vine,
            name: vine[0].row_name,
          });
          vineLocation.setId(id);
          vineRowsFeatures1.push(vineLocation);
        });
        vineRowsForJumpAndGoLayerRef?.current
          ?.getSource()
          .addFeatures(vineRowsFeatures1);
        vineRowsFeatures1 &&
          vineRowsFeatures1.length > 0 &&
          displayRecenterAllViewPortSetUp(vineRowsFeatures1, mapRef.current);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };

    /**
     * VineRows initialization Ends Here
     */
    const drawAbLines = async (records: any, localAbLineUnique: number) => {
      try {
        records = records && records.length > 0 ? records : [];
        const vineRowsList: MapFeature[] = [];
        records.map(async (vine: any, index: number) => {
          const coordinates: any = [];
          const id = `ab_line_${vine.id}`;
          // const { organization } = userDetails;
          // const coordinates1 = await getAbLineVerticesData(
          //   organization.api_url,
          //   organization.farm.id,
          //   vine.ab_line_uuid,
          //   // vine.generic_map_uuid,
          // );
          const coordinates1 = [
            {
              latitude: vine.head_a_latitude,
              longitude: vine.head_a_longitude,
              x: vine.head_a_x,
              y: vine.head_a_y,
            },
            {
              latitude: vine.head_b_latitude,
              longitude: vine.head_b_longitude,
              x: vine.head_b_x,
              y: vine.head_b_y,
            },
          ];

          coordinates1 &&
            coordinates1.length > 0 &&
            coordinates1.map((coordinate: any) => {
              if (
                coordinate.latitude !== null &&
                coordinate.longitude !== null
              ) {
                coordinates.push(
                  proj.fromLonLat([coordinate.longitude, coordinate.latitude]),
                );
              } else {
                coordinates.push(
                  proj.fromLonLat(
                    getLatitudeAndLongitude(
                      [coordinate.y / 100, coordinate.x / 100],
                      base,
                      true,
                    ),
                  ),
                );
              }
            });

          const vineLocation = new Feature({
            geometry: new LineString(coordinates),
            pointType: 'ABLINE',
            rowUuid: vine.vine_row_uuid,
            pointName: vine.name,
            pointId: vine.id,
            rowData: vine,
            name: vine.name,
            head_a_bearing: vine.head_a_bearing,
            head_b_bearing: vine.head_b_bearing,
          });

          vineLocation.setId(id);
          vineLocation.setStyle(abLineStyle);
          localAbLineUnique === globalAbLineUnique &&
            abLinesLayerRef.current?.getSource().addFeature(vineLocation);
        });
        abLinesLayerRef.current?.setVisible(listVisibility.ABLINE);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };

    /**
     * VineBlocks initialization Starts Here
     */

    const drawVineBlocks = async () => {
      vineBlocksLayerRef.current?.getSource().clear();
      try {
        if (
          !(
            vineRows &&
            vineRows.length > 0 &&
            vineBlocks &&
            vineBlocks.length > 0
          )
        ) {
          return;
        }
        const records = vineBlocks && vineBlocks.length > 0 ? vineBlocks : [];
        const vineBlocksFeatures: Feature<Geometry>[] = [];
        const vineBlocksList: MapFeature[] = [];
        records.map((block: any) => {
          const id = `vine_block_${block.id}`;

          const coordinates: any = [];

          const vinesHeadA: any = [];
          const vinesHeadB: any = [];
          block.vine_rows_uuidsContent = [];
          block.vine_rows_uuids &&
            block.vine_rows_uuids.length > 0 &&
            block.vine_rows_uuids.map((id: string) => {
              const [item] = vineRows.filter(
                (vineRow: any) => vineRow.vine_row_uuid === id,
              );
              item && block.vine_rows_uuidsContent.push(item);
            });

          let xyOrLatLong = true; // true means x y and false -> lat and long
          const { vine_rows_uuidsContent: vine_rows_uuids } = block;
          const content =
            vine_rows_uuids && vine_rows_uuids && vine_rows_uuids.length > 0
              ? vine_rows_uuids[0]
              : null;
          if (
            content &&
            content.head_a_latitude !== null &&
            content.head_a_longitude !== null &&
            content.head_b_latitude !== null &&
            content.head_b_longitude !== null
          ) {
            xyOrLatLong = false;
          } else {
            xyOrLatLong = true;
          }
          !xyOrLatLong &&
            block &&
            vine_rows_uuids &&
            vine_rows_uuids.map((vineBlock: any) => {
              const vineA = [
                vineBlock.head_a_longitude,
                vineBlock.head_a_latitude,
              ];
              const vineB = [
                vineBlock.head_b_longitude,
                vineBlock.head_b_latitude,
              ];
              vinesHeadA.push(vineA);
              vinesHeadB.push(vineB);
            });
          xyOrLatLong &&
            vine_rows_uuids &&
            vine_rows_uuids.map((vineBlock: any) => {
              const vineA = { x: vineBlock.head_a_x, y: vineBlock.head_a_y };
              const vineB = { x: vineBlock.head_b_x, y: vineBlock.head_b_y };
              vinesHeadA.push(vineA);
              vinesHeadB.push(vineB);
              xyOrLatLong = true;
            });
          let vineBlockCoordinates = vinesHeadA;
          vinesHeadB.reverse();
          vineBlockCoordinates = vineBlockCoordinates.concat(vinesHeadB);
          if (vinesHeadA[0]) {
            vineBlockCoordinates.push(vinesHeadA[0]);
          }
          if (!xyOrLatLong) {
            vineBlockCoordinates.map((item: any) => {
              coordinates.push(proj.fromLonLat(item));
            });
          } else {
            vineBlockCoordinates.map((item: any) => {
              item.xNew = item.x / 100;
              item.yNew = item.y / 100;
              const latAndLong: [number, number] = getLatitudeAndLongitude(
                [item.yNew, item.xNew],
                base,
                true,
              );
              coordinates.push(proj.fromLonLat(latAndLong));
            });
          }

          if (coordinates && coordinates.length > 0) {
            const vineBlockPolygon = new Feature({
              type: 'Polygon',
              geometry: new Polygon([coordinates]),
              pointType: 'VineBlock',
              vineRows: vine_rows_uuids,
              vineBlockUuid: block.vine_blocks_uuid,
              pointId: block.id,
              name: block.name,
              polygonUuid: block.polygon_uuid,
            });
            vineBlockPolygon.setId(id);

            vineBlocksFeatures.push(vineBlockPolygon);
          }
          vineBlocksList.push({
            id: block.id,
            name: block.name,
            type: 'VINEBLOCK',
            mapId: id,
            visible: true,
            style: vineBlockStyle,
            vine_blocks_uuid: block.vine_blocks_uuid,
            is_published: block.is_published ? true : false,
          });
        });
        vineBlocksLayerRef.current?.setVisible(listVisibility.VINEBLOCK);
        vineBlocksLayerRef.current?.getSource().addFeatures(vineBlocksFeatures);
        setVineBlockFeature(vineBlocksList);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };

    /**
     * VineBlocks initialization Ends Here
     */

    /**
     * Polygons initialization Starts Here
     */

    const getPolygons = async (
      localPolyUnique: number,
      polyList: any,
      isApi = false,
      loadFromContext = false,
    ) => {
      try {
        // checking  polygon list from app conect
        const records = loadFromContext
          ? polygonsList
          : polyList && polyList.length > 0
          ? polyList
          : [];
        const preparedList: MapFeature[] = [];
        records.map(async (record: any, index: number) => {
          const { polygonFeature, polygonItem } = await drawPoly(
            userDetails,
            base,
            polygonsLayerRef.current,
            globalPolyUnique,
            localPolyUnique,
            record,
            false, // suggested as false
            isApi, // call api to get vertices -> false call api , -> true dont call api
          );
          if (polygonItem && polygonItem.vertices) {
            record.vertices = polygonItem.vertices;
          }
          // assigne for recenter the white field
          if (record.color === 'white') {
            fieldFeatureItem.current = [polygonFeature];
          }
          preparedList.push(polygonItem as MapFeature);
          if (records && records.length - 1 === index) {
            setPolygonFeature(preparedList);
            setPolygons(records);
            // polygons added to maintain app context start
            !isApi &&
              appDispatch({
                type: SET_POLYGONS_LIST,
                payload: records,
              });
            // polygons added to maintain app context end
          }
        });
        // set show hide the polygon layer
        polygonsLayerRef.current?.setVisible(listVisibility.POLYGON);
        const list = records.map(
          (item: { name: string; color: string; polygon_uuid: string }) => {
            return {
              name: item.name,
              color: item.color,
              polygon_uuid: item.polygon_uuid,
            };
          },
        );
        setPolygonsListForPop(list);
        polygonHoverLabel(mapRef.current);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };

    /**
     * Polygons initialization Ends Here
     */

    /** Waypoints initialization Starts Here
     *
     */
    const drawWaypoints = async () => {
      waypointsLayerRef.current?.getSource().clear();
      try {
        const records = waypoints && waypoints.length > 0 ? waypoints : [];
        const waypointsFeatures: Feature<Geometry>[] = [];
        const waypointList: MapFeature[] = [];
        const style = mapMakerwWypointStyle;
        records.map((point: any) => {
          const id = `waypoint_${point.id}`;
          let latAndLong: number[] = [];
          if (point.latitude !== null && point.longitude !== null) {
            latAndLong = [point.longitude, point.latitude];
          } else {
            latAndLong = getLatitudeAndLongitude(
              [point.y / 100, point.x / 100],
              base,
              true,
            );
          }
          if (latAndLong && latAndLong.length === 2) {
            const pointLocation = new Feature({
              geometry: new Point(proj.fromLonLat(latAndLong)),
              name: point.name,
              pointType: 'waypoint',
              pointId: point.id,
              positionX: point.x,
              positionY: point.y,
            });
            pointLocation.setId(id);
            waypointsFeatures.push(pointLocation);
            waypointList.push({
              id: point.id,
              name: point.name,
              type: 'WAYPOINT',
              mapId: id,
              visible: true,
              style,
            });
          }
        });
        waypointsLayerRef.current?.setVisible(listVisibility.WAYPOINT);
        waypointsLayerRef.current?.getSource().addFeatures(waypointsFeatures);
        // const layer = new VectorLayer({
        //   source: new VectorSource({
        //     features: waypointsFeatures,
        //   }),
        //   visible: true,
        //   zIndex: 10,
        //   // style: mapMakerwWypointStyle,
        // });
        // mapRef.current?.addLayer(layer);
        setWaypointFeature(waypointList);
        const wayDrag = new Modify({
          features: new Collection(waypointsFeatures),
          style: new Style({}),
        });
        // Add the event to the drag and drop feature
        wayDrag.on('modifyend', function (evt: any) {
          dragWayPoint(evt);
        });

        // Add the vector layer and the refferring drag and drop interaction
        mapRef.current?.addInteraction(wayDrag);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    /**
     * Waypoints initialization Ends Here
     */

    /** Marks initialization Starts Here
     *
     */

    const drawMarks = async () => {
      marksLayerRef.current?.getSource().clear();
      try {
        const records = marks && marks.length > 0 ? marks : [];
        const marksFeatures: Feature<Geometry>[] = [];
        records.map((point: any) => {
          const { x, y, id } = point;
          // const { latitude, longitude, x, y, id } = point;
          // if (latitude && longitude) {
          //   const pointLocation = new Feature({
          //     geometry: new Point(proj.fromLonLat([longitude, latitude])),
          //     name: `Mark :- (x: ${x * 100}, y: ${y * 100})`,
          //     pointType: 'MARK',
          //     pointId: id,
          //     point,
          //   });
          //   marksFeatures.push(pointLocation);
          // } else
          if (x && y) {
            const latAndLong = getLatitudeAndLongitude([y, x], base, true);
            const pointLocation = new Feature({
              geometry: new Point(proj.fromLonLat(latAndLong)),
              name: `Mark :- (x: ${x * 100}, y: ${y * 100})`,
              pointType: 'MARK',
              pointId: id,
              point,
            });
            pointLocation.setId(id);
            marksFeatures.push(pointLocation);
          }
        });

        marksLayerRef.current?.setVisible(listVisibility.MARKS);
        marksLayerRef.current?.getSource().addFeatures(marksFeatures);
        // const layer = new VectorLayer({
        //   source: new VectorSource({
        //     features: marksFeatures,
        //   }),
        //   visible: true,
        //   zIndex: 10,
        //   // style: mapMakerwWypointStyle,
        // });
        // mapRef.current?.addLayer(layer);
        const marksDrag = new Modify({
          features: new Collection(marksFeatures),
          style: new Style({}),
        });
        // Add the event to the drag and drop feature
        marksDrag.on('modifyend', function (evt: any) {
          dragMarkPoint(evt);
        });
        // Add the vector layer and the refering drag and drop interaction
        mapRef.current?.addInteraction(marksDrag);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };

    const drawWinerowHead = async () => {
      vineRowHeadLayerRef.current?.getSource().clear();
      try {
        const records =
          vineRowHeads && vineRowHeads.length > 0 ? vineRowHeads : [];
        const waypointsFeatures: Feature<Geometry>[] = [];
        records.map((point: any) => {
          const { x, y, id } = point;
          if (x && y) {
            const latAndLong = getLatitudeAndLongitude([y, x], base, true);
            const pointLocation = new Feature({
              geometry: new Point(proj.fromLonLat(latAndLong)),
              // name: `vine_row_head :- (x: ${point.cm_x}, y: ${point.cm_y}) ${id}`,
              name: `Vine Row Head :- (x: ${x * 100}, y: ${y * 100})`,
              pointType: 'VINE_ROW_HEAD',
              pointId: id,
              point,
            });
            pointLocation.setId(id);
            waypointsFeatures.push(pointLocation);
          }
        });
        vineRowHeadLayerRef.current?.setVisible(listVisibility.VINE_ROW_HEAD);
        vineRowHeadLayerRef.current?.getSource().addFeatures(waypointsFeatures);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    /**
     * Marks initialization Ends Here
     */
    // updating feature start here
    useEffect(() => {
      if (mapFeature) {
        hideShowFeature(mapFeature);
        recenterTractor();
      }
    }, [mapFeature, trigger]);
    const recenterTractor = () => {
      if (mapRef.current) {
        if (mapFeature.type === 'TRACTOR') {
          const feat: any = tractorLayerRef.current?.getSource();
          const tractorFeature: any = feat
            .getSource()
            .getFeatureById(mapFeature.mapId);
          const point = tractorFeature.getGeometry().getCoordinates();
          const coordinates = proj.transform(point, 'EPSG:3857', 'EPSG:4326');
          mapRef.current.getView().setCenter(proj.fromLonLat(coordinates));
        } else if (
          mapFeature.type === 'IMPLEMENT' ||
          mapFeature.type === 'VINE' ||
          mapFeature.type === 'WAYPOINT'
        ) {
          const layer: VectorLayer<any> | any = getFeatureLayer(
            mapFeature.type,
          );
          const feature = layer.getSource().getFeatureById(mapFeature.mapId);
          const point = feature.getGeometry().getCoordinates();
          const coordinates = proj.transform(point, 'EPSG:3857', 'EPSG:4326');
          mapRef.current.getView().setCenter(proj.fromLonLat(coordinates));
          mapRef.current?.getView().setZoom(19);
        } else if (
          mapFeature.type === 'ABBLOCK' ||
          mapFeature.type === 'POLYGON' ||
          mapFeature.type === 'VINELANE' ||
          mapFeature.type === 'VINEBLOCK'
        ) {
          const layer = getFeatureLayer(mapFeature.type);
          const feature = layer.getSource().getFeatureById(mapFeature.mapId);
          if (feature) {
            const point = feature.getGeometry().getCoordinates();
            point &&
              point.length > 0 &&
              displayRecenterViewPortSetUp(feature, mapRef.current);
          }
        } else if (
          mapFeature.type === 'ABLINE' ||
          mapFeature.type === 'VINEROW' ||
          mapFeature.type === 'ROUTE'
        ) {
          const layer = getFeatureLayer(mapFeature.type);
          const feature = layer.getSource().getFeatureById(mapFeature.mapId);
          if (feature) {
            const point = feature.getGeometry().getCoordinates();
            point &&
              point.length > 1 &&
              feature &&
              displayRecenterViewPortSetUp(feature, mapRef.current);
          }
        }
      }
    };

    /**
     * Zoom In Zoom Out and Recenter
     */

    const handleZoom = (zoomType: string) => {
      if (mapRef.current) {
        const view = mapRef.current.getView();
        const zoom = view.getZoom();
        if (zoom) {
          if (zoomType === 'zoomIn') {
            view.setZoom(zoom + 1);
          } else {
            view.setZoom(zoom - 1);
          }
        }
      }
    };

    const zoomInGrid = () => {
      const layer = graticuleLayerRef.current;
      if (layer) {
        const visible = layer.getVisible();
        mapRef.current?.removeLayer(layer);
        graticule = new Graticule({
          strokeStyle: new Stroke({
            color: 'rgba(1, 1, 1, 1)',
            width: 1,
          }),
          intervals: [gridArray[getGridIndex()]],
          showLabels: false,
          targetSize: 5,
          visible,
        });
        mapRef.current?.addLayer(graticule);
      }
      graticuleLayerRef.current = graticule;
    };

    const zoomOutGrid = () => {
      const layer = graticuleLayerRef.current;
      if (layer) {
        const visible = layer.getVisible();
        mapRef.current?.removeLayer(layer);

        graticule = new Graticule({
          strokeStyle: new Stroke({
            color: 'rgba(1, 1, 1, 1)',
            width: 1,
          }),
          intervals: [gridArray[getGridIndex()]],
          showLabels: false,
          targetSize: 5,
          visible,
        });
        mapRef.current?.addLayer(graticule);
      }
      graticuleLayerRef.current = graticule;
    };

    const getGridIndex = () => {
      const zoom = mapRef?.current?.getView().getZoom();
      if (zoom) {
        const actualZoom = Math.floor(zoom);
        mapRef?.current?.getView().setZoom(actualZoom);
        if (actualZoom === 15 || actualZoom < 16) {
          return 0;
        } else if (actualZoom === 16 || actualZoom < 17) {
          return 1;
        } else if (actualZoom === 17 || actualZoom < 18) {
          return 2;
        } else if (actualZoom === 18 || actualZoom < 19) {
          return 3;
        } else if (actualZoom === 19 || actualZoom < 20) {
          return 4;
        } else if (actualZoom === 20 || actualZoom < 21) {
          return 5;
        } else if (actualZoom === 21 || actualZoom < 22) {
          return 6;
        } else if (actualZoom === 22 || actualZoom < 23) {
          return 7;
        } else if (actualZoom === 23 || actualZoom < 24) {
          return 8;
        } else if (actualZoom === 24 || actualZoom < 25) {
          return 9;
        } else if (actualZoom === 25 || actualZoom < 26) {
          return 10;
        } else if (actualZoom === 26 || actualZoom < 27) {
          return 11;
        } else if (actualZoom === 27 || actualZoom < 28) {
          return 12;
        } else if (actualZoom === 28 || actualZoom < 29) {
          return 13;
        }
      }
      return 0;
    };

    const reCenter = () => {
      if (
        mapRef.current &&
        fieldFeatureItem &&
        fieldFeatureItem.current &&
        fieldFeatureItem.current.length > 0
      ) {
        displayRecenterAllViewPortSetUp(
          fieldFeatureItem.current,
          mapRef.current,
        );
      }
    };

    /**
     * Zoom In Zoom Out and Recenter
     */
    /**
     * Toggle Layer
     */

    useEffect(() => {
      setToggleLayer(toggleLayer);
    }, [toggleLayer]);

    const setToggleLayer = (toggleLayerObject: ToggleLayer) => {
      if (toggleLayerObject) {
        if (toggleLayerObject.type !== 'REMOTEAV') {
          const layer = getFeatureLayer(toggleLayerObject.type);
          handleVisiabilityMenu(toggleLayerObject);
          layer?.setVisible(toggleLayerObject.value);
        }
      }
    };
    // toggle satelight / road map view
    useEffect(() => {
      vectorTileLayerRef.current?.setVisible(!toggleSatellite);
      satelliteTileLayerRef.current?.setVisible(toggleSatellite);
    }, [toggleSatellite]);

    /**
     * Toogle layer ends
     */

    /**
     * Resize Map on collapsed and exand
     */

    useEffect(() => {
      if (mapInitialized) {
        setTimeout(() => {
          mapRef.current?.updateSize();
        }, 1000);
      }
    }, [collapsed, mapInitialized]);
    /**
     * RemoteAV layer ends
     */
    /**
     * Right Click context menu open event listener
     */
    contextmenu.on('open', (evt: any) => {
      contextmenu.clear();
      let menu: any[] = [];
      // draw menu start here
      if (activeDrawActionGlobal === 'NONE') {
        menu.push({
          text: 'Draw',
          classname: 'remoteav',
        });
        menu.push({
          text: 'Waypoint',
          classname: 'rvactionItem', // add some CSS rules
          callback: () => {
            listVisibility.WAYPOINT = true;
            drawWayPoint();
          },
        });
        menu.push({
          text: 'Polygon',
          classname: 'rvactionItem', // add some CSS rules
          callback: () => {
            listVisibility.POLYGON = true;
            drawPolygon();
          },
        });
        menu.push({
          text: 'Vine',
          classname: 'rvactionItem', // add some CSS rules
          callback: () => {
            listVisibility.VINE = true;
            drawVine();
          },
        });
        menu.push({
          text: 'Vine row',
          classname: 'rvactionItem', // add some CSS rules
          callback: () => {
            listVisibility.VINEROW = true;
            drawVineRow();
          },
        });
        menu.push({
          text: 'VineBlock',
          classname: 'rvactionItem', // add some CSS rules
          callback: () => {
            listVisibility.VINEBLOCK = true;
            drawVineBlockFun();
          },
        });
        menu.push({
          text: 'Vine Lane',
          classname: 'rvactionItem', // add some CSS rules
          callback: () => {
            listVisibility.VINELANE = true;
            drawVineLaneFun();
          },
        });

        // Update Menu start here
        let subMenu: any[] = [];
        const feature = mapRef.current?.forEachFeatureAtPixel(
          evt.pixel,
          function (feature: any) {
            return feature;
          },
        );
        if (activeDrawActionGlobal === 'NONE' && feature) {
          const pointType = feature.get('pointType');
          if (pointType === 'Polygon') {
            subMenu = [];
            subMenu.push({
              text: 'Polygon',
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Update',
              classname: 'rvactionItem',
              callback: () => {
                updatePolygon(feature);
              },
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                if (feature.get('color') === suggestedPolygon) {
                  confirmDelete(
                    feature.get('id'),
                    'POLYGON',
                    feature.get('label'),
                    true,
                  );
                } else {
                  confirmDelete(
                    feature.get('id'),
                    'POLYGON',
                    feature.get('label'),
                  );
                }
              },
            });
            if (feature.get('color') === 'brown') {
              subMenu.push({
                text: 'Discover',
                classname: 'rvactionItem',
                callback: () => {
                  // discoverPolygonHandler(feature);
                  const poly = feature.get('poly');
                  setShowDiscover(true);
                  setBrownPoly(poly);
                },
              });
            }
            if (feature.get('color') === suggestedPolygon) {
              subMenu.push({
                text: 'Approve',
                classname: 'rvactionItem',
                callback: () => {
                  approvePolygonHandler(feature);
                },
              });
            }
          } else if (pointType === 'waypoint') {
            subMenu = [];
            subMenu.push({
              text: 'Waypoint',
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('pointId'),
                  'WAYPOINT',
                  feature.get('name'),
                );
              },
            });
          } else if (pointType === 'MARK') {
            subMenu = [];
            subMenu.push({
              text: 'Mark',
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('pointId'),
                  'MARK',
                  feature.get('name'),
                );
              },
            });
            subMenu.push({
              text: 'Copy Coordinates ',
              classname: 'rvactionItem',
              callback: () => {
                const point = feature.get('point');
                navigator.clipboard.writeText(
                  `{latitude: ${point.latitude}, longitude: ${point.longitude}}, {x: ${point.x}, y: ${point.y}}`,
                );
                notification.info({ message: 'Copied' });
              },
            });
          } else if (pointType === 'VINE_ROW_HEAD') {
            subMenu = [];
            subMenu.push({
              text: 'Vine Row Head',
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('pointId'),
                  'VINE_ROW_HEAD',
                  feature.get('name'),
                );
              },
            });
            subMenu.push({
              text: 'Copy Coordinates ',
              classname: 'rvactionItem',
              callback: () => {
                const point = feature.get('point');
                navigator.clipboard.writeText(
                  `{latitude: ${point.latitude}, longitude: ${point.longitude}}, {x: ${point.x}, y: ${point.y}}`,
                );
                notification.info({ message: 'Copied' });
              },
            });
          } else if (pointType === 'vine') {
            subMenu = [];
            subMenu.push({
              text: 'Vine',
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('pointId'),
                  'VINE',
                  feature.get('name'),
                );
              },
            });
          } else if (pointType === 'vinerow') {
            subMenu = [];
            subMenu.push({
              text: 'Vine row',
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Update',
              classname: 'rvactionItem',
              callback: () => {
                updateVineRow(feature);
              },
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('pointId'),
                  'VINEROW',
                  feature.get('name'),
                );
              },
            });
          } else if (pointType === 'VineBlock') {
            subMenu = [];
            subMenu.push({
              text: 'VineBlock',
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Update',
              classname: 'rvactionItem',
              callback: () => {
                vineBlockId = feature.get('pointId');
                vineBlockName = feature.get('name');
                polygonUuid = feature.get('polygonUuid');
                setActiveDrawAction('VINEBLOCKEDIT');
                disableVineRows(feature.get('vineRows'));
              },
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('pointId'),
                  'VINEBLOCK',
                  feature.get('name'),
                );
              },
            });
          } else if (pointType === 'VineLane') {
            subMenu = [];
            subMenu.push({
              text: 'VineLane',
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('pointId'),
                  'VINELANE',
                  feature.get('name'),
                );
              },
            });
          } else if (pointType === 'JUMPANDGO') {
            subMenu = [];
            subMenu.push({
              text: 'Jump & Go',
              classname: 'remoteav',
            });
            subMenu.push({
              text: 'Update',
              classname: 'rvactionItem',
              callback: () => {
                updateJumpAndGo(feature);
              },
            });
            // } else if (pointType === 'ABLINE') {
            //   subMenu = [];
            //   subMenu.push({
            //     text: 'Ab Line',
            //     classname: 'remoteav',
            //   });
            //   subMenu.push({
            //     text: 'Update',
            //     classname: 'rvactionItem',
            //     callback: () => {
            //       updateAbline(feature);
            //     },
            //   });
            // subMenu.push({
            //   text: 'Delete',
            //   classname: 'rvactionItem',
            //   callback: () => {
            //     confirmDelete(
            //       feature.get('pointId'),
            //       'ABLINE',
            //       feature.get('name'),
            //     );
            //   },
            // });
          } else if (pointType === 'ABBLOCK') {
            subMenu = [];
            subMenu.push({
              text: 'Ab Block',
              classname: 'remoteav',
            });
            // subMenu.push({
            //   text: 'Update',
            //   classname: 'rvactionItem',
            //   callback: () => {
            //     updateAbBlock(feature);
            //   },
            // });
            subMenu.push({
              text: 'Delete',
              classname: 'rvactionItem',
              callback: () => {
                confirmDelete(
                  feature.get('id'),
                  'ABBLOCK',
                  feature.get('label'),
                );
              },
            });
          }
        }
        menu = [...menu, ...subMenu];
        // Update Menu start here
      }

      // draw menu ends here

      // Update start here
      if (activeDrawActionGlobal !== 'NONE') {
        if (activeDrawActionGlobal === 'WAYPOINT') {
          menu.push({
            text: 'Waypoint',
            classname: 'remoteav',
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // clearWaypoint();
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === 'POLYGON') {
          menu.push({
            text: 'Polygon',
            classname: 'remoteav',
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // clearPolygonDrawModifyHandler();
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === 'POLYGONEDIT') {
          menu.push({
            text: 'Polygon',
            classname: 'remoteav',
          });
          menu.push({
            text: 'Save',
            classname: 'rvactionItem',
            callback: () => {
              updatePolygonFun();
              setShowEditPoly(true);
            },
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem',
            callback: () => {
              // clearPolygonDrawModifyHandler();
              // cancelOperations();
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === 'VINE') {
          menu.push({
            text: 'Vine',
            classname: 'remoteav',
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // clearVine()
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === 'VINEROW') {
          menu.push({
            text: 'VineRow',
            classname: 'remoteav',
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // clearVineRow()
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === 'VINEROWEDIT') {
          menu.push({
            text: 'Vine row',
            classname: 'remoteav',
          });
          menu.push({
            text: 'Save',
            classname: 'rvactionItem',
            callback: () => {
              updateVineRowFunc();
            },
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem',
            callback: () => {
              // clearModifiedVineRow()
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === 'VINEBLOCK') {
          menu.push({
            text: 'VineBlock',
            classname: 'remoteav',
          });
          menu.push({
            text: 'Save',
            classname: 'rvactionItem',
            callback: () => {
              saveVineBlock();
            },
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // setActiveDrawAction('NONE');
              // selectedVineRowsForVineBlock = [];
              // drawVineRows();
              // drawVineBlocks();
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === 'VINELANE') {
          menu.push({
            text: 'Vine Lane',
            classname: 'remoteav',
          });
          menu.push({
            text: 'Save',
            classname: 'rvactionItem',
            callback: () => {
              saveVineLane();
            },
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // clearVinblockSelection();
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === 'VINEBLOCKEDIT') {
          menu.push({
            text: 'VineBlock',
            classname: 'remoteav',
          });
          menu.push({
            text: 'Update',
            classname: 'rvactionItem',
            callback: () => {
              editVineBlock();
            },
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // handleToggleLayer(true, 'VINEBLOCK', 4);
              // handleToggleLayer(true, 'VINEROW', 3);
              // drawVineRows();
              // drawVineBlocks();
              // setActiveDrawAction('NONE');
              // selectedVineRowsForVineBlock = [];
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === 'JUMPANDGO') {
          menu.push({
            text: 'Jump &Go',
            classname: 'remoteav',
          });
          menu.push({
            text: 'Update',
            classname: 'rvactionItem',
            callback: () => {
              updateJumpAndGoFunc();
            },
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // clearModifiedJumpAndGo();
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === 'ABLINE') {
          menu.push({
            text: 'Ab Line',
            classname: 'remoteav',
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem', // add some CSS rules
            callback: () => {
              // clearAbLine()
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        } else if (activeDrawActionGlobal === 'ABBLOCKEDIT') {
          menu.push({
            text: 'Ab Block',
            classname: 'remoteav',
          });
          menu.push({
            text: 'Save',
            classname: 'rvactionItem',
            callback: () => {
              updateAbBlockFun();
              setShowAbBlock(true);
            },
          });
          menu.push({
            text: 'Cancel',
            classname: 'rvactionItem',
            callback: () => {
              // clearAbBlock()
              setTriggerCancelOperation(!globalTriggerCancelOperation);
            },
          });
        }
      }
      // Update ends here
      contextmenu.extend(menu);
    });

    useEffect(() => {
      globalTriggerCancelOperation = triggerCancelOperation;
      mapInitialized && cancelOperations();
    }, [mapInitialized, triggerCancelOperation]);

    const clearVinblockSelection = () => {
      setActiveDrawAction('NONE');
      selectedVineRowsForVineBlock = [];
      drawVineRows();
      getVineLanes();
    };

    const cancelOperations = () => {
      // clear new waypoint
      clearWaypoint();
      // clear  new vine
      clearVine();
      // to clear new vinerow
      clearVineRow();
      // clear update vinerow
      clearModifiedVineRow();

      clearPolygonDrawModifyHandler();

      clearModifiedJumpAndGo();

      // vine lane create cancel
      selectedVineRowsForVineBlock = [];

      // clear vine block selection
      clearVinblockSelection();

      // clear the abline
      clearAbLine();

      // clear ablock
      clearAbBlock();

      setShowSave(false);

      vineRowsForJumpAndGoLayerRef.current?.getSource().clear();

      // to clear drawn features on map
      drawFeaturesLayer.current?.getSource().clear();
      setActiveDrawAction('NONE');
      // baseFeatureItem.current.setGe();
      baseFeatureItem?.current.setGeometry(
        new Point(proj.fromLonLat([baseLang, baseLat])),
      );
      setSelectedJumpAndGoListForVineRows([]);
      setSelctedVinerowsFromJumpandGo([]);
      setSelectedJumpAndGoForVineRow([]);
      setVineRowPayload(null);
      setAbLinePayload(null);
      setAbLinePayload1(null);
      setVineRowModel2LastPayload(null);
      setVineRowModel2FirstPayload(null);

      drawWaypoints();
      drawVineRows();
      drawVineBlocks();
      polygons &&
        polygons.length > 0 &&
        polygonsLayerRef.current?.getSource().clear();
      globalPolyUnique = Math.random();
      polygons &&
        polygons.length > 0 &&
        getPolygons(globalPolyUnique, polygons, true);
      getBrownPolyons(globalPolyUnique, brownPolygons, true);
      getVineLanes();
      getVines();
      drawWinerowHead();
      drawMarks();
      globalAbLineUnique = Math.random();
      abLines &&
        abLines.length > 0 &&
        abLinesLayerRef.current?.getSource().clear();
      abLines && abLines.length > 0 && drawAbLines(abLines, globalAbLineUnique);
      drawAbBlocks();
      // getAbBlocks(globalPolyUnique, abBlocks, true);
    };

    const drawVineBlockFun = () => {
      selectedVineRowsForVineBlock = [];
      setActiveDrawAction('VINEBLOCK');
    };
    const drawVineLaneFun = () => {
      selectedVineRowsForVineBlock = [];
      setActiveDrawAction('VINELANE');
    };

    const disableVineRows = (rows: any) => {
      const features = vineRowsLayerRef?.current?.getSource().getFeatures();
      if (features && features.length > 0) {
        selectedVineRowsForVineBlock = [];
        rows.forEach((element: { name: string }) => {
          features.map((fe: any) => {
            if (fe.get('name') === element.name) {
              setShowSave(true);
              editVineBlock();
              fe.setStyle(vineRowDisableStyle);
              selectedVineRowsForVineBlock.push({
                name: fe.get('name'),
                rowUuid: fe.get('rowUuid'),
              });
            }
          });
        });
      }
    };
    const toggleMapMaker = () => {
      toggleSatellite &&
        satelliteTileLayerRef.current?.setVisible(mapMakeToggle);
      !toggleSatellite && vectorTileLayerRef.current?.setVisible(mapMakeToggle);
      mapMakeToggle = !mapMakeToggle;
    };
    const toggleGraticule = () => {
      graticuleToggle = !graticuleToggle;
      // added for getting grids zoom level on toggle
      if (graticuleToggle) {
        mapRef.current?.getView().setMinZoom(15);
      } else {
        mapRef.current?.getView().setMinZoom(1);
      }
      graticuleLayerRef.current?.setVisible(graticuleToggle);
    };
    useEffect(() => {
      setPolygonStyle(polygonsLayerRef, toggleSatellite);
    }, [toggleSatellite]);

    const clearPolygonDrawModifyHandler = () => {
      detatchPolygonModifyHandler();
      detatchPolygonDrawHandler();
    };

    // DRAW VINES START **************************************************
    const clearVine = () => {
      if (activeDrawActionGlobal === 'VINE') {
        mapRef.current?.removeInteraction(vineDraw);
        setActiveDrawAction('NONE');
        return;
      }
    };
    const drawVine = () => {
      clearVine();
      setActiveDrawAction('VINE');
      vineSource = new VectorSource();
      vineDraw = new Draw({
        source: vineSource,
        type: GeometryType.POINT,
        style: mapmakerVineStyle,
      });
      mapRef.current?.addInteraction(vineDraw);
      vineDraw.on('drawend', vineDrawEnd);
    };
    const vineDrawEnd = (e: any) => {
      e.feature.setStyle(mapmakerVineStyle);
      vineDraw?.un('drawend', vineDrawEnd);
      vineDraw.abortDrawing();
      mapRef.current?.removeInteraction(vineDraw);
      drawFeaturesLayer.current?.getSource().clear();
      drawFeaturesLayer.current?.getSource().addFeature(e.feature);
      const points = e.feature.getGeometry().getCoordinates();
      const latLong: number[] = proj.transform(
        points,
        'EPSG:3857',
        'EPSG:4326',
      );
      // clearVine();
      saveVine(getXYCoordinates(latLong, base));

      const wayDrag = new Modify({
        features: new Collection([e.feature]),
        style: new Style({}),
      });
      // Add the event to the drag and drop feature
      wayDrag.on('modifyend', function (evt: any) {
        const arr: any = evt.features.getArray();
        if (arr && arr.length && arr[arr.length - 1]) {
          const point = arr[arr.length - 1].getGeometry().getCoordinates();
          let latLong: number[] = proj.transform(
            point,
            'EPSG:3857',
            'EPSG:4326',
          );
          latLong = getXYCoordinates(latLong, base);
          saveVine(latLong);
        }
      });
      // Add the vector layer and the refferring drag and drop interaction
      mapRef.current?.addInteraction(wayDrag);
    };
    const saveVine = async (latLong: number[]) => {
      const payload: VinesPayloadTypes = {
        name: '',
        y: latLong[1],
        x: latLong[0],
        vine_row1_uuid: '',
        vine_row2_uuid: '',
        action: 'ADD',
      };
      setVinePayload(payload);
      setShowSave(true);
    };
    const deleteVine = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteVineFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        if (resp && resp.msg) {
          const mapId = `vine_${pointId}`;
          const feature = vinesLayerRef?.current
            ?.getSource()
            .getFeatureById(mapId);
          feature && vinesLayerRef.current?.getSource().removeFeature(feature);
          notification.success({
            message: resp.msg,
          });
          getVinesListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.response?.data.error.message,
        });
      }
    };
    const dragVinePoint = async (e: any) => {
      const arr: any = e.features.getArray();
      if (arr && arr.length && arr[arr.length - 1]) {
        const point = arr[arr.length - 1].getGeometry().getCoordinates();
        const feature = arr[arr.length - 1];
        const vineId = feature.get('pointId');
        const name = feature.get('name');
        let latLong: number[] = proj.transform(point, 'EPSG:3857', 'EPSG:4326');
        latLong = getXYCoordinates(latLong, base);
        const payload: VinesPayloadTypes = {
          name,
          y: latLong[1],
          x: latLong[0],
          vine_row1_uuid: '',
          vine_row2_uuid: '',
          action: 'EDIT',
          vineId,
        };
        setVinePayload(payload);
        setShowSave(true);
        setActiveDrawAction('VINE');
        // setShowVine(true);
      }
    };
    // DRAW VINES END *******************************************************

    // DRAW VINE ROWS START *************************************************
    const clearVineRow = () => {
      if (vineRowDraw) {
        vineRowDraw?.un('drawend', vineRowDrawEnd);
        vineRowDraw.abortDrawing();
        mapRef.current?.removeInteraction(vineRowDraw);
        setActiveDrawAction('NONE');
        return;
      }
    };
    const drawVineRow = () => {
      clearVineRow();
      setActiveDrawAction('VINEROW');
      vineRowSource = new VectorSource();
      vineRowDraw = new Draw({
        source: vineRowSource,
        type: GeometryType.LINE_STRING,
        maxPoints: 2,
      });
      mapRef.current?.addInteraction(vineRowDraw);
      const snap = new Snap({
        source: vineRowHeadLayerRef?.current?.getSource(),
      });
      mapRef?.current?.addInteraction(snap);
      vineRowDraw.on('drawend', vineRowDrawEnd);
    };
    const vineRowDrawEnd = (e: any) => {
      e.feature.setStyle(vineRowStyle);
      drawFeaturesLayer.current?.getSource().clear();
      drawFeaturesLayer.current?.getSource().addFeature(e.feature);
      mapRef.current?.removeInteraction(vineRowDraw);
      const points = e.feature.getGeometry().getCoordinates();
      const xy: any[] = [];
      const vertices: any[] = [];
      points.forEach((element: Coordinate) => {
        const latLong = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
        xy.push(latLong);
        vertices.push({ longitude: latLong[0], latitude: latLong[1] });
      });
      const payload: VineRowPayload = {
        name: '',
        vineRowXY: getXYCoordinatesList(xy, base),
        action: 'ADD',
        vineRowId: 0,
        head_a_bearing: 0,
        head_b_bearing: 0,
        polygon_uuid: '',
      };
      const payload1: VineRowPayloadSingle = {
        name: '',
        vineRowXY: vertices,
        action: 'ADD',
        vineRowId: 0,
        head_a_bearing: 0,
        head_b_bearing: 0,
        polygon_uuid: '',
      };
      setVineRowPayload(payload1);
      setVineRowModel2FirstPayload(payload);
      if (globalVineRowModal === 1) {
        setShowSave(true);
      }
      if (globalVineRowModal === 2) {
        vineRowSource = new VectorSource();
        vineRowDraw = new Draw({
          source: vineRowSource,
          type: GeometryType.LINE_STRING,
          maxPoints: 2,
        });
        mapRef.current?.addInteraction(vineRowDraw);
        const snap = new Snap({
          source: vineRowHeadLayerRef?.current?.getSource(),
        });
        mapRef?.current?.addInteraction(snap);
        vineRowDraw.on('drawend', vineRowDrawEndSecond);
      }
    };
    const vineRowDrawEndSecond = (e: any) => {
      e.feature.setStyle(vineRowStyle);
      drawFeaturesLayer.current?.getSource().addFeature(e.feature);
      mapRef.current?.removeInteraction(vineRowDraw);
      const points = e.feature.getGeometry().getCoordinates();
      const xy: any[] = [];
      points.forEach((element: Coordinate) => {
        const latLong = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
        xy.push(latLong);
      });
      const payload: VineRowPayload = {
        name: '',
        vineRowXY: getXYCoordinatesList(xy, base),
        action: 'ADD',
        vineRowId: 0,
        head_a_bearing: 0,
        head_b_bearing: 0,
        polygon_uuid: '',
      };
      setShowSave(true);
      setVineRowModel2LastPayload(payload);
    };
    const clearModifiedVineRow = () => {
      if (activeDrawActionGlobal === 'VINEROWEDIT') {
        vineRowModify?.un('modifyend', vineRowDrawEnd);
        mapRef.current?.removeInteraction(vineRowModify);
        setActiveDrawAction('NONE');
        return;
      }
    };
    const updateVineRow = (feature: any) => {
      clearModifiedVineRow();
      setShowSave(true);
      setActiveDrawAction('VINEROWEDIT');
      vineRowSource = new VectorSource();
      vineRowSource.addFeature(feature);
      vineRowModify = new Modify({ source: vineRowSource });
      mapRef.current?.addInteraction(vineRowModify);
      updateVineRowFunc();
      vineRowModify.on('modifyend', updateVineRowFunc);
    };
    const updateVineRowFunc = () => {
      const feature: any = vineRowSource.getFeatures();
      if (feature && feature[0]) {
        const points: any = feature[0].getGeometry().getCoordinates();
        const pointName = feature[0].get('pointName');
        const vineRowId = feature[0].get('pointId');
        const head_a_bearing = feature[0].get('head_a_bearing');
        const head_b_bearing = feature[0].get('head_b_bearing');
        const polygon_uuid = feature[0].get('polygon_uuid');
        const vineRowXY: any[] = [];
        const vertices: any[] = [];
        points.forEach((element: Coordinate) => {
          const latLong = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
          vineRowXY.push(latLong);
          vertices.push({ longitude: latLong[0], latitude: latLong[1] });
        });
        const payload: VineRowPayloadSingle = {
          name: pointName,
          // vineRowXY: getXYCoordinatesList(vineRowXY, base),
          vineRowXY: vertices,
          action: 'EDIT',
          vineRowId,
          head_a_bearing,
          head_b_bearing,
          polygon_uuid,
        };
        setVineRowPayload(payload);
        // clearModifiedVineRow();
        // setShowVineRow(true);
      }
    };

    const deleteVineRow = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteVineRowFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        if (resp && resp.msg) {
          const feature = vineRowsLayerRef.current
            ?.getSource()
            .getFeatureById('vine_row_' + pointId);
          feature &&
            vineRowsLayerRef.current?.getSource().removeFeature(feature);
          notification.success({
            message: resp.msg,
          });
          getVineRowsListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.resp?.data.error.message,
        });
      }
    };
    // DRAW VINE ROWS END *****************************************************

    // draw polygon start here
    const drawPolygon = () => {
      clearPolygonDrawModifyHandler();
      setActiveDrawAction('POLYGON');
      polygonSource = new VectorSource({ wrapX: false });
      polygonDraw = new Draw({
        source: polygonSource,
        type: GeometryType.POLYGON,
      });
      mapRef.current?.addInteraction(polygonDraw);
      polygonDraw.on('drawend', polyDrawEnd);
    };
    const polyDrawEnd = async (e: any) => {
      drawFeaturesLayer.current?.getSource().clear();
      drawFeaturesLayer.current?.getSource().addFeature(e.feature);
      e.feature.setStyle(polyDrawStyle);
      let points = e.feature.getGeometry().getCoordinates();
      points = points && points.length > 0 ? points[0] : [];
      const vertices: any[] = [];
      points.forEach((element: Coordinate) => {
        const xy = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
        vertices.push({ longitude: xy[0], latitude: xy[1] });
      });
      const area = getAreaOfPolygon(e.feature);
      const payload = {
        name: '',
        color: '',
        // vertices: getXYCoordinatesList(vertices, base),
        lat_long_vertices: vertices,
        polygonId: 0,
        area: area ? area : 0,
      };
      setPolyPayload(payload);

      // show polygon save button
      setShowSave(true);
      // detach polygon draw
      polygonDraw.abortDrawing();
      polygonDraw?.un('drawend', polyDrawEnd);
      mapRef.current?.removeInteraction(polygonDraw);
      const featuresList = drawFeaturesLayer.current?.getSource().getFeatures();
      if (featuresList && featuresList.length > 0) {
        const feature = featuresList[0];
        polygonSource = new VectorSource();
        polygonSource.addFeature(feature);
        polygonModify = new Modify({
          source: polygonSource,
        });
        mapRef.current?.addInteraction(polygonModify);
        polygonModify.on('modifyend', createdPolyUpdatePolygonFun);
      }
    };
    const createdPolyUpdatePolygonFun = async () => {
      if (polygonSource) {
        const feature: any = polygonSource.getFeatures();
        if (feature && feature[0]) {
          let points = feature[0].getGeometry().getCoordinates();
          points = points && points.length > 0 ? points[0] : [];
          const vertices: any[] = [];
          points.forEach((element: Coordinate) => {
            const xy = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
            // vertices.push(xy);
            vertices.push({ longitude: xy[0], latitude: xy[1] });
          });
          const area = getAreaOfPolygon(feature);
          const payload = {
            name: '',
            color: '',
            // vertices: getXYCoordinatesList(vertices, base),
            lat_long_vertices: vertices,
            polygonId: 0,
            area: area ? area : 0,
          };
          setPolyPayload(payload);
        }
      }
    };
    const editDrawPolygon = (e: any) => {
      setShowSave(true);
    };
    // clear the drawn polygon
    const detatchPolygonDrawHandler = () => {
      if (polygonDraw) {
        polygonDraw.abortDrawing();
        polygonDraw?.un('drawend', polyDrawEnd);
        mapRef.current?.removeInteraction(polygonDraw);
        setActiveDrawAction('NONE');
      }
    };
    // clear the modified polygon
    const detatchPolygonModifyHandler = () => {
      if (polygonModify) {
        polygonModify?.un('modifyend', editDrawPolygon);
        mapRef.current?.removeInteraction(polygonModify);
        setActiveDrawAction('NONE');
      }
    };

    // update polygon start here
    const updatePolygon = (feat: any) => {
      clearPolygonDrawModifyHandler();
      setActiveDrawAction('POLYGONEDIT');
      feat.setStyle(null);
      polygonSource = new VectorSource();
      feat.set('type', 'none');
      polygonSource.addFeature(feat);
      feat.setStyle(polyDrawStyle);
      polygonModify = new Modify({
        source: polygonSource,
      });
      mapRef.current?.addInteraction(polygonModify);
      polygonModify.on('modifyend', editDrawPolygon);
    };
    const updatePolygonFun = async () => {
      if (polygonSource) {
        const feature: any = polygonSource.getFeatures();
        if (feature && feature[0]) {
          let points = feature[0].getGeometry().getCoordinates();
          points = points && points.length > 0 ? points[0] : [];
          const vertices: any[] = [];
          points.forEach((element: Coordinate) => {
            const xy = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
            // vertices.push(xy);
            vertices.push({ longitude: xy[0], latitude: xy[1] });
          });
          // const name = feature[0].get('name');
          const polyName = feature[0].get('polyName');
          const label = feature[0].get('label');
          const color = feature[0].get('color');
          const polygonId = feature[0].get('id');
          // clearPolygonDrawModifyHandler();
          const area = getAreaOfPolygon(feature[0]);
          const payload = {
            name: polyName,
            color,
            // vertices: getXYCoordinatesList(vertices, base),
            lat_long_vertices: vertices,
            polygonId,
            area: area ? area : 0,
          };
          setPolyPayload(payload);
          polygonModify?.un('modifyend', editDrawPolygon);
          mapRef.current?.removeInteraction(polygonModify);
        }
      }
    };
    // update polygon ends here

    // delete Polygon starts here
    const deletePolygon = async (polygonId: number, isSuggested = false) => {
      try {
        const { organization } = userDetails;
        let url = `${organization.api_url}farm/${organization.farm.id}/cartography/polygons/${polygonId}`;
        if (isSuggested) {
          url = `${organization.api_url}farm/${organization.farm.id}/cartography/suggested-polygon/${polygonId}`;
        }
        const resp = await deletePolygonFun(url);
        if (resp && resp.msg) {
          const mapId = `polygon_${polygonId}`;
          const feature = polygonsLayerRef?.current
            ?.getSource()
            .getFeatureById(mapId);
          feature &&
            polygonsLayerRef.current?.getSource().removeFeature(feature);
          isSuggested && setReloadSuggestedPolygons(!reloadSuggestedPolygons);
          notification.success({
            message: resp.msg,
          });
          getPolygonsListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.response?.data.error.message,
        });
      }
    };
    // delete polygon end here

    // DRAW WAYPOINT START **************************************************
    const clearWaypoint = () => {
      if (activeDrawActionGlobal === 'WAYPOINT') {
        mapRef.current?.removeInteraction(waypointDraw);
        // setWaypointDrawOn(false);
        setActiveDrawAction('NONE');
        return;
      }
    };
    const drawWayPoint = () => {
      clearWaypoint();
      setActiveDrawAction('WAYPOINT');
      wayPointSource = new VectorSource();
      waypointDraw = new Draw({
        source: wayPointSource,
        type: GeometryType.POINT,
        style: mapMakerwWypointStyle,
      });
      mapRef.current?.addInteraction(waypointDraw);
      waypointDraw.on('drawend', wayPointDrawEnd);
    };
    const wayPointDrawEnd = (e: any) => {
      e.feature.setStyle(mapMakerwWypointStyle);
      drawFeaturesLayer.current?.getSource().clear();
      drawFeaturesLayer.current?.getSource().addFeature(e.feature);

      const points = e.feature.getGeometry().getCoordinates();
      let latLong: number[] = proj.transform(points, 'EPSG:3857', 'EPSG:4326');
      latLong = getXYCoordinates(latLong, base);
      saveWaypoint(latLong);
      setShowSave(true);
      // stop waypoint drawing
      waypointDraw?.un('drawend', wayPointDrawEnd);
      waypointDraw.abortDrawing();
      mapRef.current?.removeInteraction(waypointDraw);

      const wayDrag = new Modify({
        features: new Collection([e.feature]),
        style: new Style({}),
      });
      // Add the event to the drag and drop feature
      wayDrag.on('modifyend', function (evt: any) {
        const arr: any = evt.features.getArray();
        if (arr && arr.length && arr[arr.length - 1]) {
          const point = arr[arr.length - 1].getGeometry().getCoordinates();
          let latLong: number[] = proj.transform(
            point,
            'EPSG:3857',
            'EPSG:4326',
          );
          latLong = getXYCoordinates(latLong, base);
          saveWaypoint(latLong);
        }
      });
      // Add the vector layer and the refferring drag and drop interaction
      mapRef.current?.addInteraction(wayDrag);
    };
    const saveWaypoint = async (latLong: number[]) => {
      const payload: WaypointPayloadTypes = {
        name: '',
        y: latLong[1],
        x: latLong[0],
        waypointId: 0,
        action: 'ADD',
      };
      // setShowWaypoint(true);
      setWayPointPayload(payload);
    };
    const confirmDelete = (
      pointeId: any,
      type:
        | 'WAYPOINT'
        | 'POLYGON'
        | 'VINE'
        | 'VINEROW'
        | 'VINEBLOCK'
        | 'VINELANE'
        | 'MARK'
        | 'VINE_ROW_HEAD'
        | 'ABLINE'
        | 'ABBLOCK'
        | 'ROUTE',
      name = '',
      isSuggested = false,
    ) => {
      let title = '';
      if (type === 'WAYPOINT') {
        title = 'Waypoint';
      } else if (type === 'POLYGON') {
        title = 'Polygon';
      } else if (type === 'VINE') {
        title = 'Vine';
      } else if (type === 'VINEROW') {
        title = 'Vinerow';
      } else if (type === 'VINEBLOCK') {
        title = 'VineBlock';
      } else if (type === 'VINELANE') {
        title = 'Vine Lane';
      } else if (type === 'MARK') {
        title = 'Mark';
      } else if (type === 'VINE_ROW_HEAD') {
        title = 'Vine Row Head';
      } else if (type === 'ABLINE') {
        title = 'Ab Line';
      } else if (type === 'ABBLOCK') {
        title = 'Ab Block';
      } else if (type === 'ROUTE') {
        title = 'Route';
      }

      confirm({
        title: `Deleting ${title} ${name} ?`,
        icon: <ExclamationCircleOutlined />,
        content: '',
        okButtonProps: {
          className: 'okBtn',
        },
        cancelButtonProps: {
          className: 'canelBtn',
        },
        onOk() {
          if (type === 'WAYPOINT') {
            deleteWaypoint(pointeId);
          } else if (type === 'POLYGON') {
            deletePolygon(pointeId, isSuggested);
          } else if (type === 'VINE') {
            deleteVine(pointeId);
          } else if (type === 'VINEROW') {
            deleteVineRow(pointeId);
          } else if (type === 'VINEBLOCK') {
            deleteVineBlock(pointeId);
          } else if (type === 'VINELANE') {
            deleteVineLane(pointeId);
          } else if (type === 'MARK') {
            deleteMark(pointeId, type);
          } else if (type === 'VINE_ROW_HEAD') {
            deleteMark(pointeId, type);
          } else if (type === 'ABLINE') {
            deleteAbLine(pointeId);
          } else if (type === 'ABBLOCK') {
            deleteAbBlock(pointeId);
          } else if (type === 'ROUTE') {
            deleteRoute(pointeId);
          }
        },
      });
    };
    const deleteWaypoint = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteWaypointFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        if (resp && resp.msg) {
          const feat = waypointsLayerRef.current
            ?.getSource()
            .getFeatureById('waypoint_' + pointId);
          feat && waypointsLayerRef.current?.getSource().removeFeature(feat);
          notification.success({
            message: resp.msg,
          });
          getWayPointsListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.response?.data.error.message,
        });
      }
    };
    const dragWayPoint = async (e: any) => {
      const arr: any = e.features.getArray();
      if (arr && arr.length && arr[arr.length - 1]) {
        const point = arr[arr.length - 1].getGeometry().getCoordinates();
        const feature = arr[arr.length - 1];
        const waypointId = feature.get('pointId');
        // feature.set('name', '');
        const name = feature.get('name');
        let latLong: number[] = proj.transform(point, 'EPSG:3857', 'EPSG:4326');
        latLong = getXYCoordinates(latLong, base);
        const payload: WaypointPayloadTypes = {
          name,
          y: latLong[1],
          x: latLong[0],
          waypointId,
          action: 'EDIT',
        };
        setShowSave(true);
        setActiveDrawAction('WAYPOINT');
        setWayPointPayload(payload);
      }
    };
    // DRAW WAYPOINT END *******************************************************
    const saveVineBlock = () => {
      const payload: VineBlockPayloadType = {
        name: '',
        vineBlockId: 0,
        action: 'ADD',
        vineRows: selectedVineRowsForVineBlock,
        polygonUuid: '',
      };
      setVineBlockPayload(payload);
    };
    const editVineBlock = () => {
      const payload: VineBlockPayloadType = {
        name: vineBlockName,
        vineBlockId,
        action: 'EDIT',
        vineRows: selectedVineRowsForVineBlock,
        polygonUuid,
      };
      // setShowVineBlock(true);
      setVineBlockPayload(payload);
    };
    const deleteVineBlock = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteVineBlockFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        if (resp && resp.msg) {
          const feature = vineBlocksLayerRef.current
            ?.getSource()
            .getFeatureById('vine_block_' + pointId);
          feature &&
            vineBlocksLayerRef.current?.getSource().removeFeature(feature);
          notification.success({
            message: resp.msg,
          });
          getVineBlocksListData();
          // getVineRowsListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.response?.data.error.message,
        });
      }
    };

    const closefileModel = () => {
      setFileUpload(false);
    };

    const closeImageModel = () => {
      setImgUpload(false);
    };

    /*
    start vine lane here 
    */
    const saveVineLane = () => {
      const payload: VineLanePayloadType = {
        name: '',
        vineLaneId: 0,
        action: 'ADD',
        vineRows: selectedVineRowsForVineBlock,
      };
      setShowVineLane(true);
      setVineLanePayload(payload);
    };
    const saveVineRowFromJumpandGo = () => {
      setvineRowModal(4);
      setShowVineRow(true);
      setSelctedVinerowsFromJumpandGo(selectedVineRowsForVineBlock);
    };
    const deleteVineLane = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteVineLaneFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        if (resp && resp.msg) {
          const feature = vineLaneLayerRef.current
            ?.getSource()
            .getFeatureById('vineLane_' + pointId);
          feature &&
            vineLaneLayerRef.current?.getSource().removeFeature(feature);
          notification.success({
            message: resp.msg,
          });
          getVineLanesListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.response?.data.error.message,
        });
      }
    };

    /**
     * Vine lanes initialization Starts Here
     */

    const getVineLanes = async () => {
      vineLaneLayerRef.current?.getSource().clear();
      try {
        const records = vineLanes && vineLanes.length ? vineLanes : [];
        const vineLaneFeatures: Feature<Geometry>[] = [];
        const vineLanesList: MapFeature[] = [];
        let coordinates: any = [];
        const style = vineLaneStyle;
        records.map((poly: any) => {
          const id = `vineLane_${poly.id}`;
          coordinates = [];
          poly.vertices.map((point: any) => {
            point.xNew = point.x / 100;
            point.yNew = point.y / 100;
            const latAndLong: [number, number] = getLatitudeAndLongitude(
              [point.yNew, point.xNew],
              base,
              true,
            );
            coordinates.push(proj.fromLonLat(latAndLong));
          });
          if (coordinates && coordinates.length > 0) {
            const vineLane = new Feature({
              type: 'Polygon',
              pointType: 'VineLane',
              geometry: new Polygon([coordinates]),
              pointId: poly.id,
              name: poly.name,
              style,
            });

            vineLane.setId(id);
            vineLane.set('id', poly.id);
            vineLane.setStyle(style);
            vineLaneFeatures.push(vineLane);
            vineLanesList.push({
              id: poly.id,
              name: poly.name,
              type: 'VINELANE',
              mapId: id,
              visible: true,
              style,
            });
          }
        });
        vineLaneLayerRef.current?.getSource().addFeatures(vineLaneFeatures);
        vineLaneLayerRef.current?.setVisible(listVisibility.VINELANE);
        setVineLaneFeature(vineLanesList);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };

    /**
     * VineLanes initialization Ends Here
     */
    /**
     * Draw route start Here
     */
    useEffect(() => {
      if (mapInitialized && selectedDateRange) {
        /** Lets Do it After Optimization */
        getJumpandGos();
      }
    }, [userDetails, mapInitialized, selectedDateRange, jumpAndGoTractorId]);
    // get routes routes
    const getJumpandGos = async () => {
      if (jumpAndGoLayerRef.current) {
        mapRef.current?.removeLayer(jumpAndGoLayerRef.current);
      }
      setJumpAndGoFeature([]);
      setLoader(true);
      try {
        const routeList: MapFeature[] = [];
        const [date1, date2] = selectedDateRange;
        const { records } = await getJumpandGo(
          userDetails,
          // moment(date1).startOf('day').toDate().getTime(),
          // moment(date2).endOf('day').toDate().getTime(),
          moment(date1).toDate().getTime(),
          moment(date2).toDate().getTime(),
          jumpAndGoTractorId,
        );
        const jumpAndGoFeature: Feature<Geometry>[] = [];
        const style = new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 3,
          }),
          fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
          }),
        });
        records.map(async (route: any, index: number) => {
          route.range = 0;
          route.times = [];
          route.showTimes = [];
          if (
            route &&
            route.drive_action_start_date_time &&
            route.drive_action_finish_date_time
          ) {
            route.times = [
              moment(route?.drive_action_start_date_time),
              moment(route?.drive_action_finish_date_time),
            ];
            route.showTimes = route.times;
            route.range = diff_minutes(
              route.drive_action_finish_date_time,
              route.drive_action_start_date_time,
            );
          }
          const record = [{ x: 0, y: 0 }];
          if (record.length > 0) {
            const id = 'jump_and_go_' + route.id;
            const convertedRecord = convertRouteXYtoLatLang(
              record,
              baseLat,
              baseLang,
            );
            const array = convertedRecord.map(
              (item: { x: number; y: number }) =>
                proj.fromLonLat([item.x, item.y]),
            );
            const name = `${route?.operator?.first_name}  ${route?.operator?.last_name}`;
            const jumpAndGo = new Feature({
              geometry: new LineString(array),
              name,
            });
            jumpAndGo.setId(id);
            jumpAndGoFeature.push(jumpAndGo);
            routeList.push({
              id: route.id,
              name,
              type: 'JUMPANDGO',
              mapId: id,
              visible: false,
              style,
              actionUuid: route.drive_action_uuid,
              tractorName: route?.tractor?.name,
              drive_action_finish_date_time:
                route?.drive_action_finish_date_time,
              drive_action_start_date_time: route?.drive_action_start_date_time,
              first_name: route?.operator?.first_name,
              last_name: route?.operator?.last_name,
              created_date_time: route.created_date_time,
              times: route.times,
              showTimes: route.showTimes,
              range: route.range,
            });
          }
          if (index + 1 === records.length) {
            const jumpAndGo = new VectorLayer({
              source: new VectorSource({
                features: jumpAndGoFeature,
              }),
              zIndex: Infinity,
              style,
            });
            // jumpAndGo.setZIndex(0);
            mapRef.current?.addLayer(jumpAndGo);
            jumpAndGoLayerRef.current = jumpAndGo;
            setJumpAndGoFeature(routeList);
          }
        });
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      } finally {
        setLoader(false);
      }
    };
    const drawJAG = async (mapFeature: any, id: string, name?: string) => {
      const { organization } = userDetails;
      const { tractor_id, records } = await getDriveActionSinglePath(
        organization.api_url,
        mapFeature.actionUuid,
      );
      const defaultLayer = jumpAndGoLayerRef.current;
      const arr: Coordinate[] = [];
      if (records && records.length > 0) {
        records.map(
          (path: {
            longitude: number;
            latitude: number;
            created_date_time: number;
            dTime: string;
          }) => {
            if (
              mapFeature.showTimes &&
              mapFeature.showTimes.length > 0 &&
              mapFeature.showTimes[0] < path.created_date_time &&
              mapFeature.showTimes[1] > path.created_date_time
            ) {
              path.dTime = getDateTime(path.created_date_time);
              arr?.push(proj.fromLonLat([path.longitude, path.latitude]));
            }
          },
        );
        let tractorLocation: Feature<Geometry> = new Feature();
        if (defaultLayer) {
          tractorLocation = defaultLayer?.getSource().getFeatureById(id);
        }
        if (!tractorLocation) {
          tractorLocation = new Feature();
        }
        tractorLocation.setGeometry(new LineString(arr));
        const color = 'rgba(0,183,255,255)';
        const borderColor = '#FFFFFF';
        const style = [
          new Style({
            stroke: new Stroke({
              color: borderColor,
              width: 8,
            }),
            zIndex: 0,
          }),
          new Style({
            stroke: new Stroke({
              color: color,
              width: 6,
            }),
            zIndex: 1,
          }),
        ];
        const pathHover = [
          new Style({
            stroke: new Stroke({
              color: borderColor,
              width: 10,
            }),
            zIndex: 0,
          }),
          new Style({
            stroke: new Stroke({
              color,
              width: 8,
            }),
            zIndex: 1,
          }),
        ];
        tractorLocation.setStyle(style);
        tractorLocation.set('style', style);
        tractorLocation.set('hoverStyle', pathHover);
        tractorLocation.set('name', name);
        tractorLocation.set('pointType', 'JUMPANDGO');
        tractorLocation.set('drawPointsText', records);
        tractorLocation.set('tractorId', tractor_id);
        defaultLayer?.getSource().addFeature(tractorLocation);
        const view = mapRef.current?.getView();
        if (view && arr && arr.length > 0) {
          const zoom = view.getZoom();
          if (zoom) {
            mapRef.current?.getView().setCenter(arr[0]);
            mapRef.current?.getView().setZoom(17);
          }
        }
      }
    };
    const getBrownPolyRemarks = (name: string, remarks: string) => {
      const dataStr = `<p> Name: <span>  ${name} </span></p>
                       <p> Remarks: <span> ${remarks}</span></p>`;
      return dataStr;
    };
    const clearModifiedJumpAndGo = () => {
      if (activeDrawActionGlobal === 'JUMPANDGO') {
        JumpAndGoModify?.un('modifyend', updateJumpAndGoFunc);
        mapRef.current?.removeInteraction(JumpAndGoModify);
        setActiveDrawAction('NONE');
        return;
      }
    };
    const updateJumpAndGo = (feature: any) => {
      clearModifiedJumpAndGo();
      setActiveDrawAction('JUMPANDGO');
      jumpAndGoSource = new VectorSource();
      jumpAndGoSource.addFeature(feature);
      JumpAndGoModify = new Modify({ source: jumpAndGoSource });
      mapRef.current?.addInteraction(JumpAndGoModify);
      // JumpAndGoModify.on('modifyend', updateJumpAndGoFunc);
      JumpAndGoModify.on('modifyend', (e) => console.log(e));
    };
    const updateJumpAndGoFunc = () => {
      const feature: any = jumpAndGoSource.getFeatures();
      if (feature && feature[0]) {
        const points: any = feature[0].getGeometry().getCoordinates();
        const pointName = feature[0].get('pointName');
        const vineRowId = feature[0].get('pointId');
        const head_a_bearing = feature[0].get('head_a_bearing');
        const head_b_bearing = feature[0].get('head_b_bearing');
        const vineRowXY: any[] = [];
        points.forEach((element: Coordinate) => {
          const latLong = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
          vineRowXY.push(latLong);
        });
        const payload: VineRowPayload = {
          name: pointName,
          vineRowXY: getXYCoordinatesList(vineRowXY, base),
          action: 'EDIT',
          vineRowId,
          head_a_bearing,
          head_b_bearing,
        };
        // const r = getXYCoordinatesList(vineRowXY, base);
        localStorage.setItem('updatedJumpAndGo', JSON.stringify(vineRowXY));
        // setVineRowPayload(payload);
        clearModifiedJumpAndGo();
        // setAddVineRow(true);
      }
    };
    const approvePolygonHandler = async (feature: any) => {
      try {
        const polygonUuid = feature.get('polygon_uuid');
        const payload = {
          polygon_uuids: [polygonUuid],
        };
        const { organization } = userDetails;
        const resp = await approvePolygon(
          organization.api_url,
          organization.farm.id,
          payload,
        );
        if (resp && resp.msg) {
          getPolygonsListData();
          notification.success({
            message: resp.msg,
          });
        }
      } catch (err: any) {
        notification.error({
          message: err.response?.data.error.message,
        });
      }
    };

    // draw suggested green polygons starts here
    const getBrownPolyons = (
      localPolyUnique: number,
      brownPolygonsList: any,
      isApi = false,
    ) => {
      if (brownPolygonsList && brownPolygonsList.length > 0) {
        brownPolygonsList.map(async (brownPolygon: any, index: number) => {
          try {
            const { organization } = userDetails;
            let records = [];
            if (!isApi) {
              const res1 = await getBrwonPolygonsSuggestedPolygonsData(
                organization.api_url,
                organization.farm.id,
                brownPolygon.polygon_uuid,
              );
              const res2 = await getBrwonPolygonsSuggestedPolygonsData(
                organization.api_url,
                organization.farm.id,
                brownPolygon.polygon_uuid,
                'blue',
              );
              records = [...res1.records, ...res2.records];
            } else {
              records = brownPolygon.records;
            }

            brownPolygon.records = records;
            if (index === brownPolygonsList.length - 1) {
              drawBrownPolyons(localPolyUnique, brownPolygonsList, isApi);
            }
          } catch (error: any) {
            notification.error({
              message: error.response?.data.error.message,
            });
          }
        });
      }
    };

    // draw suggested green polygons starts here
    const drawBrownPolyons = (
      localPolyUnique: number,
      brownPolygonsList: any,
      isApi = false,
    ) => {
      const suggestedPolys: MapFeature[] = [];
      if (brownPolygonsList && brownPolygonsList.length > 0) {
        brownPolygonsList.map(async (brownPolygon: any, index: number) => {
          brownPolygon.records =
            brownPolygon.records && brownPolygon.records.length > 0
              ? brownPolygon.records
              : [];
          brownPolygon.records.map(async (record: any) => {
            const { polygonItem } = await drawPoly(
              userDetails,
              base,
              polygonsLayerRef.current,
              globalPolyUnique,
              localPolyUnique,
              record,
              true, // suggested as false
              isApi, // call api to get vertices -> false call api , -> true dont call api
            );
            if (polygonItem && polygonItem.vertices) {
              suggestedPolys.push(polygonItem);
              record.vertices = polygonItem.vertices;
            }
          });
          if (brownPolygonsList && brownPolygonsList.length - 1 === index) {
            setSuggestedPolygonFeature(suggestedPolys as MapFeature[]);
            setBrownPolygons(brownPolygonsList);
          }
        });
      }
    };

    // draw brown polygons ends here

    // mark delete starts here
    const deleteMark = async (pointId: number, type: string) => {
      try {
        const { organization } = userDetails;
        const payload = {
          mark_ids: [pointId],
        };
        const resp = await deleteMarkFun(
          organization.api_url,
          organization.farm.id,
          payload,
        );
        if (resp && resp.msg) {
          if (type === 'MARK') {
            const feature = marksLayerRef.current
              ?.getSource()
              .getFeatureById(pointId);
            feature &&
              marksLayerRef.current?.getSource().removeFeature(feature);
            getMarksListData();
          }
          if (type === 'VINE_ROW_HEAD') {
            const feature = vineRowHeadLayerRef.current
              ?.getSource()
              .getFeatureById(pointId);
            feature &&
              vineRowHeadLayerRef.current?.getSource().removeFeature(feature);
            getVineRowHeadsListData();
          }
          notification.success({
            message: resp.msg,
          });
        }
      } catch (err: any) {
        notification.error({
          message: err.response?.data.error.message,
        });
      }
    };
    // mark delete ends here

    // mark drag  starts here
    const dragMarkPoint = async (e: any) => {
      const arr: any = e.features.getArray();
      if (arr && arr.length && arr[arr.length - 1]) {
        const point = arr[arr.length - 1].getGeometry().getCoordinates();
        const feature = arr[arr.length - 1];
        const markId = feature.get('pointId');
        let latLong: number[] = proj.transform(point, 'EPSG:3857', 'EPSG:4326');
        latLong = getXYCoordinates(latLong, base);
        const pointData = feature.get('point');
        const { z }: { z: number } = pointData;
        const payload: MarkPointPayloadTypes = {
          y: latLong[1],
          x: latLong[0],
          markId,
          action: 'EDIT',
          z,
        };
        setActiveDrawAction('MARK');
        setShowSave(true);
        setMarkPointPayload(payload);
      }
    };
    // mark drag  ends here

    // on save button click differnt operations
    const onSaveClick = () => {
      // open Mark save popup
      if (activeDrawAction === 'MARK') {
        setShowMarkPoint(true);
      }
      // open waypoint save popup
      if (activeDrawAction === 'WAYPOINT') {
        setShowWaypoint(true);
      }
      // open Vine save popup
      if (activeDrawAction === 'VINE') {
        setShowVine(true);
      }
      // open polygon save popup

      if (activeDrawAction === 'POLYGON') {
        setShowPoly(true);
      }
      // open waypoint edit popup
      if (activeDrawAction === 'POLYGONEDIT') {
        updatePolygonFun();
        setShowEditPoly(true);
      }
      // open waypoint save / update popup
      if (
        activeDrawAction === 'VINEROW' ||
        activeDrawAction === 'VINEROWEDIT'
      ) {
        setShowVineRow(true);
      }
      // open vineblock save popup
      if (activeDrawAction === 'VINEBLOCK') {
        saveVineBlock();
        setShowVineBlock(true);
      }
      if (activeDrawAction === 'VINEBLOCKEDIT') {
        editVineBlock();
        setShowVineBlock(true);
      }
      if (activeDrawAction === 'BASESTATION') {
        setLatLong(true);
      }
      if (activeDrawAction === 'ABLINE' || activeDrawAction === 'ABLINEEDIT') {
        setShowAbLine(true);
      }
      if (
        activeDrawAction === 'ABBLOCK' ||
        activeDrawAction === 'ABBLOCKEDIT'
      ) {
        if (activeDrawAction === 'ABBLOCKEDIT') {
          updateAbBlockFun();
        }
        setShowAbBlock(true);
      }
    };
    // stting global vairible for drawitem
    useEffect(() => {
      activeDrawActionGlobal = activeDrawAction;
    }, [activeDrawAction]);

    useEffect(() => {
      showSaveGlobal = showSave;
    }, [showSave]);

    const closeLatLongModal = () => {
      setLatLong(false);
    };

    // vinelane menu
    const vineLaneMenu = () => (
      <Menu>
        <Menu.Item
          className="pMenu"
          onClick={() => {
            setvineLaneModeal(1);
            listVisibility.VINEROW = true;
            listVisibility.VINELANE = true;
            handleToggleLayer(true, 'VINEROW', 3);
            setTimeout(() => {
              handleToggleLayer(true, 'VINELANE', 9);
            }, 1000);
            cancelOperations();
            drawVineLaneFun();
          }}
        >
          Single Vinelane
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setvineLaneModeal(2);
            listVisibility.VINEROW = true;
            listVisibility.VINELANE = true;
            handleToggleLayer(true, 'VINEROW', 3);
            setTimeout(() => {
              handleToggleLayer(true, 'VINELANE', 9);
            }, 1000);
            cancelOperations();
            drawVineLaneFun();
          }}
        >
          Auto Generate Vinelane
        </Menu.Item>
      </Menu>
    );
    // vine rows menu
    const vineRowMenu = () => (
      <Menu>
        <Menu.Item
          className="pMenu"
          onClick={() => {
            setvineRowModal(1);
            globalVineRowModal = 1;
            handleToggleLayer(true, 'VINEROW', 3);
            listVisibility.VINEROW = true;
            cancelOperations();
            drawVineRow();
          }}
        >
          Single Vine Row
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setvineRowModal(2);
            globalVineRowModal = 2;
            handleToggleLayer(true, 'VINEROW', 3);
            listVisibility.VINEROW = true;
            cancelOperations();
            drawVineRow();
          }}
        >
          Auto Generate Vine Row
        </Menu.Item>
        <Menu.Item
          className="pMenu"
          onClick={() => {
            setvineRowModal(3);
            globalVineRowModal = 3;
            handleToggleLayer(true, 'VINEROW', 3);
            listVisibility.VINEROW = true;
            cancelOperations();
            // drawVineRow();
            setShowVineRow(true);
          }}
        >
          Generate using Jump and Go
        </Menu.Item>
      </Menu>
    );
    // ab line menu
    const abLineMenu = () => (
      <Menu>
        {/* <Menu.Item
          className="pMenu"
          onClick={() => {
            handleToggleLayer(true, 'ABLINE', 11);
            listVisibility.ABLINE = true;
            cancelOperations();
            drawAbLine();
          }}
        >
          Single Ab Line
        </Menu.Item> */}
        <Menu.Item
          onClick={() => {
            handleToggleLayer(true, 'ABLINE', 11);
            listVisibility.ABLINE = true;
            cancelOperations();
            drawAbLine1();
          }}
        >
          Auto Generate Ab Line
        </Menu.Item>
      </Menu>
    );
    // deleting vine blocks from right slider
    useEffect(() => {
      if (vineblockId && vineblockId !== '') {
        const id = vineblockId.replace('vine_block_', '');
        const name = getNameFromList(vineBlocks, Number(id));
        confirmDelete(id, 'VINEBLOCK', name);
      }
    }, [vineblockId]);
    useEffect(() => {
      userDetails && userDetails.organization && getTractorsList();
    }, [userDetails]);
    const getTractorsList = async () => {
      setSelectedJumpAndGoForVineRow([]);
      try {
        const tractor = await getTractorsListData(
          userDetails.organization.api_url,
          userDetails.organization_id,
          1,
        );
        const data =
          tractor && tractor.records && tractor.records.length > 0
            ? tractor.records
            : [];
        const tractorData = data.map((d: TractorsList, index: number) => {
          index === 0 && setSelectedtractor(`${d.id}`);
          // index === 0 && setSelectedtractor(`34`);
          return {
            label: d.name,
            value: `${d.id}`,
            disabled: false,
            index: `${d.id}`,
          };
        });

        setTractorsData(tractorData);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };

    const clearAbLine = () => {
      if (abLineDraw) {
        abLineDraw?.un('drawend', abLineDrawEnd);
        abLineDraw.abortDrawing();
        mapRef.current?.removeInteraction(abLineDraw);
        setActiveDrawAction('NONE');
        return;
      }
      clearModifiedAbLine();
    };
    const drawAbLine = () => {
      clearAbLine();
      setActiveDrawAction('ABLINE');
      abLineSource = new VectorSource();
      abLineDraw = new Draw({
        source: abLineSource,
        type: GeometryType.LINE_STRING,
        maxPoints: 2,
      });
      mapRef.current?.addInteraction(abLineDraw);
      const snap = new Snap({
        source: vineRowHeadLayerRef?.current?.getSource(),
      });
      mapRef?.current?.addInteraction(snap);
      abLineDraw.on('drawend', abLineDrawEnd);
    };
    const abLineDrawEnd = (e: any) => {
      e.feature.setStyle(abLineStyle);
      drawFeaturesLayer.current?.getSource().clear();
      drawFeaturesLayer.current?.getSource().addFeature(e.feature);
      mapRef.current?.removeInteraction(abLineDraw);
      const points = e.feature.getGeometry().getCoordinates();
      const xy: any[] = [];
      const vertices: any[] = [];
      points.forEach((element: Coordinate) => {
        const latLong = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
        xy.push(latLong);
        vertices.push({
          longitude: latLong[0],
          latitude: latLong[1],
          altitude: 0,
        });
      });
      const payload1: AbLinePayloadSingle = {
        name: '',
        abLineXY: vertices,
        head_a_bearing: 0,
        head_b_bearing: 0,
        action: 'Add',
        vineRowId: 0,
        polygon_uuid: '',
      };
      if (vertices && vertices.length > 1) {
        setAbLinePayload(payload1);
        setShowSave(true);
      }
    };

    const drawAbLine1 = () => {
      clearAbLine();
      setActiveDrawAction('ABLINE');
      abLineSource = new VectorSource();
      abLineDraw = new Draw({
        source: abLineSource,
        type: GeometryType.LINE_STRING,
        maxPoints: 2,
      });
      mapRef.current?.addInteraction(abLineDraw);
      const snap = new Snap({
        source: vineRowHeadLayerRef?.current?.getSource(),
      });
      mapRef?.current?.addInteraction(snap);
      abLineDraw.on('drawend', abLineDrawEnd1);
    };
    const abLineDrawEnd1 = (e: any) => {
      e.feature.setStyle(abLineStyle);
      drawFeaturesLayer.current?.getSource().clear();
      drawFeaturesLayer.current?.getSource().addFeature(e.feature);
      mapRef.current?.removeInteraction(abLineDraw);
      const points = e.feature.getGeometry().getCoordinates();
      const xy: any[] = [];
      const vertices: any[] = [];
      points.forEach((element: Coordinate) => {
        const latLong = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
        xy.push(latLong);
        vertices.push({
          longitude: latLong[0],
          latitude: latLong[1],
          altitude: 0,
        });
      });
      const payload1: AbLinePayloadSingle = {
        name: '',
        abLineXY: vertices,
        head_a_bearing: 0,
        head_b_bearing: 0,
        action: 'Add',
        vineRowId: 0,
        polygon_uuid: '',
      };
      if (vertices && vertices.length > 1) {
        setAbLinePayload1(payload1);
        setShowSave(true);
      }
    };

    const deleteAbLine = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteAbLineFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        if (resp && resp.msg) {
          const feature = abLinesLayerRef.current
            ?.getSource()
            .getFeatureById('ab_line_' + pointId);
          feature &&
            abLinesLayerRef.current?.getSource().removeFeature(feature);
          notification.success({
            message: resp.msg,
          });
          getAbLineListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.resp?.data.error.message,
        });
      }
    };
    const clearModifiedAbLine = () => {
      if (abLineModify) {
        abLineModify?.un('modifyend', updateAbLineFunc);
        mapRef.current?.removeInteraction(abLineModify);
        setActiveDrawAction('NONE');
        return;
      }
    };
    const updateAbline = (feature: any) => {
      clearModifiedAbLine();
      setShowSave(true);
      setActiveDrawAction('ABLINEEDIT');
      abLineSource = new VectorSource();
      abLineSource.addFeature(feature);
      abLineModify = new Modify({ source: abLineSource });
      mapRef.current?.addInteraction(abLineModify);
      updateAbLineFunc();
      abLineModify.on('modifyend', updateAbLineFunc);
    };
    const updateAbLineFunc = () => {
      const feature: any = abLineSource.getFeatures();
      if (feature && feature[0]) {
        const points: any = feature[0].getGeometry().getCoordinates();
        const pointName = feature[0].get('pointName');
        const vineRowId = feature[0].get('pointId');
        const head_a_bearing = feature[0].get('head_a_bearing');
        const head_b_bearing = feature[0].get('head_b_bearing');
        const polygon_uuid = feature[0].get('polygon_uuid');
        const vineRowXY: any[] = [];
        const vertices: any[] = [];
        points.forEach((element: Coordinate) => {
          const latLong = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
          vineRowXY.push(latLong);
          vertices.push({ longitude: latLong[0], latitude: latLong[1] });
        });
        const payload: AbLinePayloadSingle = {
          name: pointName,
          abLineXY: vertices,
          action: 'Edit',
          vineRowId,
          head_a_bearing,
          head_b_bearing,
          polygon_uuid,
        };
        setAbLinePayload(payload);
        // clearModifiedAbLine();
      }
    };

    const getAbBlocksListData = async () => {
      abBlockLayerRef.current?.getSource().clear();
      globalPolyUnique = Math.random();
      try {
        const { organization } = userDetails;
        const response = await getAbBlocksData(
          organization.api_url,
          organization.farm.id,
        );
        const { records } = response;
        const list =
          records && records.length && records.length > 0 ? records : [];
        // getAbBlocks(globalPolyUnique, list, false);
        setAbBlocks(list);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };

    const getAbBlocks = async (
      localPolyUnique: number,
      polyList: any,
      isApi = false,
    ) => {
      try {
        abBlockLayerRef.current?.getSource().clear();
        const records = polyList && polyList.length > 0 ? polyList : [];
        const preparedList: MapFeature[] = [];
        records.map(async (record: any, index: number) => {
          const { polygonItem } = await drawAbBlockFun(
            userDetails,
            base,
            abBlockLayerRef.current,
            globalPolyUnique,
            localPolyUnique,
            record,
            isApi, // call api to get vertices -> false call api , -> true dont call api
          );
          if (polygonItem && polygonItem.vertices) {
            record.vertices = polygonItem.vertices;
          }
          preparedList.push(polygonItem as MapFeature);
          if (records && records.length - 1 === index) {
            setAbBlockFeature(preparedList);
            setAbBlocks(records);
          }
        });
        // set show hide the polygon layer
        abBlockLayerRef.current?.setVisible(listVisibility.ABBLOCK);
        abBlockHoverLabel(mapRef.current);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };
    const detatchAbBlockDrawHandler = () => {
      if (abBlockDraw) {
        abBlockDraw.abortDrawing();
        abBlockDraw?.un('drawend', drawAbBlock);
        mapRef.current?.removeInteraction(abBlockDraw);
        setActiveDrawAction('NONE');
      }
    };
    const detatchAbBlockModifyHandler = () => {
      if (abBlockModify) {
        abBlockModify?.un('modifyend', editDrawAbBlock);
        mapRef.current?.removeInteraction(abBlockModify);
        setActiveDrawAction('NONE');
      }
    };
    const editDrawAbBlock = (e: any) => {
      setShowSave(true);
    };
    const clearAbBlock = () => {
      detatchAbBlockDrawHandler();
      detatchAbBlockModifyHandler();
    };
    const drawAbBlock = () => {
      clearAbBlock();
      setActiveDrawAction('ABBLOCK');
      abBlockSource = new VectorSource({ wrapX: false });
      abBlockDraw = new Draw({
        source: abBlockSource,
        type: GeometryType.POLYGON,
      });
      mapRef.current?.addInteraction(abBlockDraw);
      // abBlockDrawEnd();
      abBlockDraw.on('drawend', abBlockDrawEnd);
    };
    const abBlockDrawEnd = async (e: any) => {
      drawFeaturesLayer.current?.getSource().clear();
      drawFeaturesLayer.current?.getSource().addFeature(e.feature);
      e.feature.setStyle(polyDrawStyle);
      let points = e.feature.getGeometry().getCoordinates();
      points = points && points.length > 0 ? points[0] : [];
      const vertices: any[] = [];
      points.forEach((element: Coordinate) => {
        const xy = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
        vertices.push({ longitude: xy[0], latitude: xy[1] });
      });
      const payload: AbBlockPayload = {
        name: '',
        color: '',
        type: 'Add',
        lat_long_vertices: vertices,
        polygonId: 0,
      };
      setAbBlockPayload(payload);

      // show polygon save button
      setShowSave(true);
      // detach polygon draw
      abBlockDraw.abortDrawing();
      abBlockDraw?.un('drawend', abBlockDrawEnd);
      mapRef.current?.removeInteraction(abBlockDraw);
      const featuresList = drawFeaturesLayer.current?.getSource().getFeatures();
      if (featuresList && featuresList.length > 0) {
        const feature = featuresList[0];
        abBlockSource = new VectorSource();
        abBlockSource.addFeature(feature);
        abBlockModify = new Modify({
          source: abBlockSource,
        });
        mapRef.current?.addInteraction(abBlockModify);
        abBlockModify.on('modifyend', createdAbBlockUpdateAbBlockFun);
      }
    };
    const createdAbBlockUpdateAbBlockFun = async () => {
      if (abBlockSource) {
        const feature: any = abBlockSource.getFeatures();
        if (feature && feature[0]) {
          let points = feature[0].getGeometry().getCoordinates();
          points = points && points.length > 0 ? points[0] : [];
          const vertices: any[] = [];
          points.forEach((element: Coordinate) => {
            const xy = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
            vertices.push({ longitude: xy[0], latitude: xy[1] });
          });
          const payload: AbBlockPayload = {
            name: '',
            color: '',
            type: 'Add',
            lat_long_vertices: vertices,
            polygonId: 0,
          };
          setAbBlockPayload(payload);
        }
      }
    };
    const deleteAbBlock = async (pointId: number) => {
      try {
        const { organization } = userDetails;
        const resp = await deleteAbBlockFun(
          organization.api_url,
          organization.farm.id,
          pointId,
        );
        if (resp && resp.msg) {
          const feature = abBlockLayerRef.current
            ?.getSource()
            .getFeatureById('ab_block_' + pointId);
          feature &&
            abBlockLayerRef.current?.getSource().removeFeature(feature);
          notification.success({
            message: resp.msg,
          });
          getAbBlocksListData();
          getAbLineListData();
        }
      } catch (err: any) {
        notification.error({
          message: err.resp?.data.error.message,
        });
      }
    };

    // update polygon start here
    const updateAbBlock = (feat: any) => {
      clearPolygonDrawModifyHandler();
      setActiveDrawAction('ABBLOCKEDIT');
      feat.setStyle(null);
      abBlockSource = new VectorSource();
      feat.set('type', 'none');
      abBlockSource.addFeature(feat);
      feat.setStyle(polyDrawStyle);
      abBlockModify = new Modify({
        source: abBlockSource,
      });
      mapRef.current?.addInteraction(abBlockModify);
      abBlockModify.on('modifyend', editDrawAbBlock);
    };
    const updateAbBlockFun = async () => {
      if (abBlockSource) {
        const feature: any = abBlockSource.getFeatures();
        if (feature && feature[0]) {
          let points = feature[0].getGeometry().getCoordinates();
          points = points && points.length > 0 ? points[0] : [];
          const vertices: any[] = [];
          points.forEach((element: Coordinate) => {
            const xy = proj.transform(element, 'EPSG:3857', 'EPSG:4326');
            vertices.push({ longitude: xy[0], latitude: xy[1] });
          });
          const label = feature[0].get('label');
          const color = feature[0].get('color');
          const polygonId = feature[0].get('id');
          const payload: AbBlockPayload = {
            name: label,
            type: 'Edit',
            color,
            lat_long_vertices: vertices,
            polygonId,
          };
          setAbBlockPayload(payload);
          abBlockModify?.un('modifyend', editDrawAbBlock);
          mapRef.current?.removeInteraction(abBlockModify);
        }
      }
    };
    const drawAbBlocks = async () => {
      abBlockLayerRef.current?.getSource().clear();
      try {
        if (
          !(abLines && abLines.length > 0 && abBlocks && abBlocks.length > 0)
        ) {
          return;
        }
        const records = abBlocks && abBlocks.length > 0 ? abBlocks : [];
        const abBlocksFeatures: Feature<Geometry>[] = [];
        const abBlocksList: MapFeature[] = [];
        const color = 'abBlock';
        records.map((abBlock1: any) => {
          const id = `ab_block_${abBlock1.id}`;
          const vertices: any = [];

          const vinesHeadA: any[] = [];
          const vinesHeadB: any[] = [];
          let xyOrLatLong = false;

          abBlock1.ab_line_uuidsContent = [];
          abBlock1.ab_line_uuids &&
            abBlock1.ab_line_uuids.length > 0 &&
            abBlock1.ab_line_uuids.map((id: string) => {
              const [item] = abLines.filter(
                (vineRow: any) => vineRow.ab_line_uuid === id,
              );
              item && abBlock1.ab_line_uuidsContent.push(item);
            });
          const { ab_line_uuidsContent: ab_line_uuids } = abBlock1;
          const content =
            ab_line_uuids && ab_line_uuids && ab_line_uuids.length > 0
              ? ab_line_uuids[0]
              : null;

          if (
            content &&
            content.head_a_latitude !== null &&
            content.head_a_longitude !== null &&
            content.head_b_latitude !== null &&
            content.head_b_longitude !== null
          ) {
            xyOrLatLong = false;
          } else {
            xyOrLatLong = true;
          }
          !xyOrLatLong &&
            ab_line_uuids.map((abLine: any) => {
              const vineA = [abLine.head_a_longitude, abLine.head_a_latitude];
              const vineB = [abLine.head_b_longitude, abLine.head_b_latitude];
              vinesHeadA.push(vineA);
              vinesHeadB.push(vineB);
            });
          xyOrLatLong &&
            ab_line_uuids.map((abLine: any) => {
              const vineA = { x: abLine.head_a_x, y: abLine.head_a_y };
              const vineB = { x: abLine.head_b_x, y: abLine.head_b_y };
              vinesHeadA.push(vineA);
              vinesHeadB.push(vineB);
            });
          let vineBlockCoordinates = vinesHeadA;
          vinesHeadB.reverse();
          vineBlockCoordinates = vineBlockCoordinates.concat(vinesHeadB);
          if (vinesHeadA[0]) {
            vineBlockCoordinates.push(vinesHeadA[0]);
          }

          if (!xyOrLatLong) {
            vineBlockCoordinates.map((item: any) => {
              vertices.push(proj.fromLonLat(item));
            });
          } else {
            vineBlockCoordinates.map((item: any) => {
              item.xNew = item.x / 100;
              item.yNew = item.y / 100;
              const latAndLong: [number, number] = getLatitudeAndLongitude(
                [item.yNew, item.xNew],
                base,
                true,
              );
              vertices.push(proj.fromLonLat(latAndLong));
            });
          }

          let satelightPolyStyle = null;
          if (vertices && vertices.length > 0) {
            const zIndex = priority.ABBLOCK;
            const abBlockFeature = new Feature({
              type: 'ABBLOCK',
              pointType: 'ABBLOCK',
              geometry: new Polygon([vertices]),
              zIndex,
              color,
              id: abBlock1.id,
              remarks: abBlock1.remarks,
              poly: abBlock1,
              label: abBlock1.name,
            });
            abBlockFeature.setId(id);
            satelightPolyStyle = getSateLightPolyStyle(color, zIndex);
            const roadMapPolyStyle = getRoadMapPolyStyle(color, zIndex);
            abBlockFeature.setStyle(satelightPolyStyle);
            abBlockFeature.set('satelightPolyStyle', satelightPolyStyle);
            abBlockFeature.set('roadMapPolyStyle', roadMapPolyStyle);
            // abBlockLayerRef.current?.getSource().addFeatures(abBlockFeature);
            abBlocksFeatures.push(abBlockFeature);
          }
          abBlocksList.push({
            id: abBlock1.id,
            name: abBlock1.name,
            type: 'ABBLOCK',
            mapId: id,
            visible: true,
            style: satelightPolyStyle ? satelightPolyStyle : null,
            color,
            vertices,
            isPublished: abBlock1.is_published ? true : false,
          });
        });
        abBlockLayerRef.current?.setVisible(listVisibility.ABBLOCK);
        abBlockLayerRef.current?.getSource().addFeatures(abBlocksFeatures);
        setAbBlockFeature(abBlocksList);
        abBlockHoverLabel(mapRef.current);
      } catch (error: any) {
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    };

    // update polygon ends here
    return (
      <>
        <div
          className="livemapSideMenu"
          style={{
            right: rightSideMenuCollapsed ? 0 : 380,
            transition: 'all 0.2s',
          }}
        >
          <div className="sideMenuOne">
            <ul>
              <Tooltip title="Zoom In" placement="left">
                <li onClick={() => handleZoom('zoomIn')}>
                  <img
                    src={map_zoomin}
                    data-testid="zoominLiveMap-MapMakerMap"
                  />
                </li>
              </Tooltip>
              <Tooltip title="Zoom Out" placement="left">
                <li onClick={() => handleZoom('zoomOut')}>
                  <img
                    src={map_zoomout}
                    data-testid="zoomoutLiveMap-MapMakerMap"
                  />
                </li>
              </Tooltip>
              {/* <Tooltip title="Full Screen" placement="left">
                <li onClick={toggleFullScreen}>
                  <img src={map_fullscreen} />
                </li>
              </Tooltip> */}
              <Tooltip title="Recenter" placement="left">
                <li onClick={reCenter}>
                  <img
                    src={map_my_location}
                    data-testid="recenterLiveMap-MapMakerMap"
                  />
                </li>
              </Tooltip>
            </ul>
          </div>
          <div className="sideMenuTwo">
            <ul>
              <Tooltip title="Toggle Satellite" placement="left">
                <li
                  onClick={() => {
                    setToggleSatellite(!toggleSatellite);
                  }}
                >
                  <img
                    src={map_map}
                    data-testid="toggleSatelliteLiveMap-MapMakerMap"
                  />
                </li>
              </Tooltip>
              <Tooltip title="Toggle Grid" placement="left">
                <li onClick={toggleGraticule}>
                  <img
                    src={map_apps}
                    data-testid="toggleGridLiveMap-MapMakerMap"
                  />
                </li>
              </Tooltip>
              <Tooltip title="Toggle Map" placement="left">
                <li onClick={toggleMapMaker}>
                  <img
                    src={map_mode}
                    data-testid="toggleMapLiveMap-MapMakerMap"
                  />
                </li>
              </Tooltip>
              <Tooltip title="Upload Farm Profile" placement="left">
                <li onClick={() => setImgUpload(true)}>
                  <img
                    src={map_image}
                    data-testid="uploadFarmLiveMap-MapMakerMap"
                  />
                </li>
              </Tooltip>
              <Tooltip title="Upload Map Elements" placement="left">
                <li onClick={() => setFileUpload(true)}>
                  <img
                    src={map_file_upload}
                    data-testid="uploadElementsLiveMap-MapMakerMap"
                  />
                </li>
              </Tooltip>
              <Tooltip title="Download Map Elements" placement="left">
                <li onClick={() => setFileDownload(true)}>
                  <img
                    src={map_file_download}
                    data-testid="downloadLiveMap-MapMakerMap"
                  />
                </li>
              </Tooltip>
              <Tooltip title="Edit Ground Zero" placement="left">
                <li onClick={() => setLatLong(true)}>
                  <img
                    src={ground_zero}
                    data-testid="editGroundZeroLiveMap-MapMakerMap"
                  />
                </li>
              </Tooltip>
            </ul>
          </div>
        </div>
        <div className="livemapTopBar">
          <Tooltip title="Back" placement="right">
            <div
              className="leftTIconClose"
              data-testid="backArrowIcon-MapMakerMap"
              onClick={() => push(RoutesConstants.Organization)}
            ></div>
          </Tooltip>
          <div className="topMenuTypeTxt">
            {activeDrawAction !== 'NONE' && (
              <>
                <div className="drawBtnsSec">
                  <div className="pBtnsRow">
                    <Button
                      key="back"
                      className="pcancelBtn"
                      data-testid="cancelButton-MapMakerMap"
                      onClick={() => cancelOperations()}
                    >
                      Cancel
                    </Button>
                    {(activeDrawAction === 'BASESTATION' ||
                      activeDrawAction === 'MARK' ||
                      activeDrawAction === 'WAYPOINT' ||
                      activeDrawAction === 'VINE' ||
                      activeDrawAction === 'POLYGON' ||
                      activeDrawAction === 'POLYGONEDIT' ||
                      (activeDrawAction === 'VINEROW' &&
                        ((vineRowModal === 1 &&
                          vineRowPayload &&
                          vineRowPayload.vineRowXY &&
                          vineRowPayload?.vineRowXY.length > 0) ||
                          (vineRowModal === 2 &&
                            vineRowModel2LastPayload &&
                            vineRowModel2LastPayload.vineRowXY &&
                            vineRowModel2LastPayload.vineRowXY.length > 0))) ||
                      activeDrawAction === 'VINEROWEDIT' ||
                      activeDrawAction === 'VINEBLOCK' ||
                      activeDrawAction === 'VINEBLOCKEDIT' ||
                      activeDrawAction === 'ABLINE' ||
                      activeDrawAction === 'ABLINEEDIT' ||
                      activeDrawAction === 'ABBLOCK' ||
                      activeDrawAction === 'ABBLOCKEDIT') &&
                      showSave && (
                        <Button
                          key="submit"
                          className="psaveBtn"
                          data-testid="nextButtonWaypoint-MapMakerMap"
                          onClick={() => onSaveClick()}
                        >
                          Next
                        </Button>
                      )}
                  </div>
                  {!showSave && (
                    <>
                      {activeDrawAction === 'WAYPOINT' && (
                        <span className="pHelpTxt">
                          Select point on the map to create waypoint
                        </span>
                      )}
                      {activeDrawAction === 'VINE' && (
                        <span className="pHelpTxt">
                          Select point on the map to create vine
                        </span>
                      )}
                      {activeDrawAction === 'POLYGON' && (
                        <span className="pHelpTxt">
                          Select points on the map to draw polygon
                        </span>
                      )}
                      {activeDrawAction === 'VINEROW' && (
                        <span className="pHelpTxt">
                          Select two points on map to draw Vinerow
                        </span>
                      )}
                      {activeDrawAction === 'VINEBLOCK' && (
                        <span className="pHelpTxt">
                          Select Vine Rows to create VineBlock
                        </span>
                      )}
                      {activeDrawAction === 'VINELANE' && (
                        <span className="pHelpTxt">
                          Select any two vine rows to draw vinelane
                        </span>
                      )}
                      {activeDrawAction === 'ABLINE' && (
                        <span className="pHelpTxt">
                          Select two points on map to draw Ab line
                        </span>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
            {activeDrawAction === 'NONE' && (
              <div>
                <ul>
                  <li
                    onClick={() => {
                      handleToggleLayer(true, 'WAYPOINT', 0);
                      listVisibility.WAYPOINT = true;
                      cancelOperations();
                      drawWayPoint();
                    }}
                  >
                    <img
                      src={waypoint}
                      data-testid="waypointMaker-MapMakerMap"
                    />
                    Waypoint
                  </li>
                  <li
                    onClick={() => {
                      handleToggleLayer(true, 'POLYGON', 1);
                      listVisibility.POLYGON = true;
                      cancelOperations();
                      drawPolygon();
                    }}
                  >
                    <img
                      src={polygon_icon}
                      data-testid="polygonMaker-MapMakerMap"
                    />
                    Polygon
                  </li>
                  <li
                    onClick={() => {
                      handleToggleLayer(true, 'VINE', 2);
                      listVisibility.VINE = true;
                      cancelOperations();
                      drawVine();
                    }}
                  >
                    <img src={vine} data-testid="vineMaker-MapMakerMap" />
                    Vine
                  </li>
                  <li>
                    <Dropdown overlay={vineRowMenu}>
                      <a onClick={(e) => e.preventDefault()}>
                        <img
                          src={Vine_Lane}
                          data-testid="vineRowMaker-MapMakerMap"
                        />
                        Vine Row
                      </a>
                    </Dropdown>
                  </li>
                  <li
                    onClick={() => {
                      listVisibility.VINEBLOCK = true;
                      listVisibility.VINEROW = true;
                      handleToggleLayer(true, 'VINEBLOCK', 4);
                      setTimeout(() => {
                        handleToggleLayer(true, 'VINEROW', 3);
                      }, 1000);
                      cancelOperations();
                      drawVineBlockFun();
                    }}
                  >
                    <img
                      src={Vine_Block}
                      data-testid="vineBlockMaker-MapMakerMap"
                    />
                    Vine Block
                  </li>
                  <li>
                    <Dropdown overlay={vineLaneMenu}>
                      <a onClick={(e) => e.preventDefault()}>
                        <img
                          src={Vine_Lane}
                          data-testid="vineLaneMaker-MapMakerMap"
                        />
                        VineLane
                      </a>
                    </Dropdown>
                  </li>
                  {/* <li
                    onClick={() => {
                      handleToggleLayer(true, 'ABLINE', 11);
                      listVisibility.ABLINE = true;
                      cancelOperations();
                      drawAbLine();
                    }}
                  >
                    <img src={Vine_Lane} />
                    Ab line
                  </li> */}
                  <li>
                    <Dropdown overlay={abLineMenu}>
                      <a onClick={(e) => e.preventDefault()}>
                        <img
                          src={Vine_Lane}
                          data-testid="abLaneMaker-MapMakerMap"
                        />
                        Ab Line
                      </a>
                    </Dropdown>
                  </li>
                  {/* <li
                    onClick={() => {
                      handleToggleLayer(true, 'ABBLOCK', 12);
                      listVisibility.ABBLOCK = true;
                      cancelOperations();
                      drawAbBlock();
                    }}
                  >
                    <img src={polygon_icon} />
                    Ab Block
                  </li> */}
                </ul>
                <h5>Add Map Element</h5>
              </div>
            )}
          </div>
        </div>
        <div
          ref={mapElement}
          style={{ height: '100vh', backgroundColor: '#1c1c1c' }}
        ></div>
        <div ref={container} className="ol-popup">
          <div ref={content}></div>
        </div>
        <div>
          {showVineRow &&
            vineRowModal == 1 &&
            vineRowPayload &&
            vineRowPayload.vineRowXY && (
              <VineRow
                showModal={showVineRow}
                handleCancel={(reload?: boolean) => {
                  cancelOperations();
                  setShowVineRow(false);
                  reload && getVineRowsListData();
                }}
                vineRowPayload={vineRowPayload as VineRowPayloadSingle}
              />
            )}
        </div>
        <div>
          {showVineRow &&
            vineRowModal == 2 &&
            vineRowModel2FirstPayload &&
            vineRowModel2LastPayload && (
              <VineRowModal2
                showModal={showVineRow}
                handleCancel={(reload?: boolean) => {
                  if (reload) {
                    setTimeout(() => {
                      location.reload();
                    }, 2000);
                    return;
                  }
                  cancelOperations();
                  setShowVineRow(false);
                  reload && getVineRowsListData();
                }}
                vineRowModel2FirstPayload={
                  vineRowModel2FirstPayload as VineRowPayload
                }
                vineRowModel2LastPayload={
                  vineRowModel2LastPayload as VineRowPayload
                }
                polygonsListForPop={polygonsListForPop}
              />
            )}
        </div>
        <div>
          {showVineRow && vineRowModal == 3 && (
            <VineRowJumpAndGoModel
              showModal={showVineRow}
              setselectedJumpAndGoForVineRow={setSelectedJumpAndGoForVineRow}
              selectedJumpAndGoForVineRow={selectedJumpAndGoForVineRow}
              handleCancel={(showVineRows?: boolean) => {
                setvineRowModal(2);
                globalVineRowModal = 2;

                setShowVineRow(false);

                if (showVineRows) {
                  listVisibility.VINEROW = false;
                  handleToggleLayer(false, 'VINEROW', 3);
                  setActiveDrawAction('VINEROWFROMJUMPANDGO');
                  getJumpAndGoVineRowsListData();
                }
              }}
              selectedTractor={selectedTractor}
              setSelectedtractor={setSelectedtractor}
              tractors={tractors}
            />
          )}
        </div>
        <div>
          {showVineRow && vineRowModal == 4 && (
            <VineRowModelWithJumpAndGo
              showModal={showVineRow}
              handleCancel={(reload?: boolean) => {
                if (reload) {
                  setTimeout(() => {
                    location.reload();
                  }, 2000);
                  return;
                }
                cancelOperations();
                setShowVineRow(false);
                reload && getVineRowsListData();
              }}
              selctedVinerowsFromJumpandGo={selctedVinerowsFromJumpandGo}
              polygonsListForPop={polygonsListForPop}
            />
          )}
        </div>
        <div>
          {showPoly && (
            <AddPoly
              showModal={showPoly}
              polyPayload={polyPayload as PolyPayload}
              handleCancel={(reload?: boolean, brownPoly?: any) => {
                cancelOperations();
                setShowPoly(false);
                reload && getPolygonsListData();
                if (brownPoly && brownPoly.id) {
                  setShowDiscover(true);
                  setBrownPoly(brownPoly);
                }
              }}
            />
          )}
        </div>
        <div>
          {fileupload && (
            <FileUpload
              showModal={fileupload}
              handleCancel={() => {
                closefileModel();
              }}
            />
          )}
        </div>
        <div>
          {fileDownload && (
            <FileDownload
              showModal={fileDownload}
              handleCancel={() => setFileDownload(false)}
            />
          )}
        </div>
        <div>
          {imgUpload && (
            <ImageUpload
              showModal={imgUpload}
              handleCancel={() => {
                closeImageModel();
              }}
            />
          )}
        </div>
        <div>
          {showEditPoly && (
            <EditPoly
              showModal={showEditPoly}
              polyPayload={polyPayload as PolyPayload}
              handleCancel={(reload?: boolean) => {
                setShowEditPoly(false);
                cancelOperations();
                reload && getPolygonsListData();
              }}
            />
          )}
        </div>
        <div>
          {showWaypoint && (
            <Waypoint
              showModal={showWaypoint}
              wayPointPayload={wayPointPayload as WaypointPayloadTypes}
              handleCancel={(reload?: boolean) => {
                cancelOperations();
                setShowWaypoint(false);
                // get the update data
                reload && getWayPointsListData();
              }}
            />
          )}
        </div>
        <div>
          {showVine && (
            <Vine
              showModal={showVine}
              vinePayload={vinePayload as VinesPayloadTypes}
              handleCancel={(reload?: boolean) => {
                cancelOperations();
                setShowVine(false);
                reload && getVinesListData();
              }}
            />
          )}
        </div>
        <div>
          {showVineBlock && (
            <VineBlock
              showModal={showVineBlock}
              vineBlockPayload={vineBlockPayload as VineBlockPayloadType}
              handleCancel={(reload?: boolean) => {
                cancelOperations();
                setShowVineBlock(false);
                reload && getVineBlocksListData();
              }}
              polygonsListForPop={polygonsListForPop as PolygonDropDown[]}
            />
          )}
        </div>
        <div>
          {showVineLane && vineLaneModeal === 1 && (
            <VineLaneModal1
              showModal={showVineLane}
              vineLanePayload={vineLanePayload as VineLanePayloadType}
              polygonsListForPop={polygonsListForPop as PolygonDropDown[]}
              handleCancel={(reload?: boolean) => {
                cancelOperations();
                setShowVineLane(false);
                reload && getVineLanesListData();
              }}
            />
          )}
        </div>
        <div>
          {showVineLane && vineLaneModeal === 2 && (
            <VineLaneModal2
              showModal={showVineLane}
              vineLanePayload={vineLanePayload as VineLanePayloadType}
              polygonsListForPop={polygonsListForPop as PolygonDropDown[]}
              handleCancel={(reload?: boolean) => {
                cancelOperations();
                setShowVineLane(false);
                reload && getVineLanesListData();
              }}
            />
          )}
        </div>
        <div>
          {showMarkPoint && (
            <Mark
              showModal={showMarkPoint}
              markPointPayload={markPointPayload as MarkPointPayloadTypes}
              handleCancel={(reload?: boolean) => {
                cancelOperations();
                setShowMarkPoint(false);
                reload && getMarksListData();
              }}
            />
          )}
        </div>
        <div>
          {showDiscover && (
            // <Discover
            //   showDiscover={showDiscover}
            //   handleCancel={() => setShowDiscover(false)}
            //   brownPoly={brownPoly}
            //   confirmDelete={(id: number, name: string) =>
            //     confirmDelete(id, 'POLYGON', name, true)
            //   }
            //   reloadSuggestedPolygons={reloadSuggestedPolygons}
            // />
            <DiscoverModal2
              showDiscover={showDiscover}
              handleCancel={(reloadPoly?: boolean) => {
                setShowDiscover(false);
                reloadPoly && getPolygonsListData();
              }}
              brownPoly={brownPoly}
            />
          )}
        </div>
        <div>
          {latLong && (
            <GroundZeroEditModal
              showModal={latLong}
              closeModal={() => {
                closeLatLongModal();
                setUpdateBaseLat(baseLat);
                setUpdateBasedLang(baseLang);
                cancelOperations();
              }}
              groundLat={updateBaseLat}
              groundLang={updateBaseLang}
            />
          )}
        </div>
        {showAbLine && abLinePayload && abLinePayload.abLineXY && (
          <AbLine
            showModal={showAbLine}
            handleCancel={(reload?: boolean) => {
              cancelOperations();
              setShowAbLine(false);
              reload && getAbLineListData();
            }}
            abLinePayload={abLinePayload as AbLinePayloadSingle}
          />
        )}
        {showAbLine && abLinePayload1 && abLinePayload1.abLineXY && (
          <AbLine1
            showModal={showAbLine}
            handleCancel={(reload?: boolean) => {
              cancelOperations();
              setShowAbLine(false);
              reload && getAbBlocksListData();
              reload && getAbLineListData();
            }}
            abLinePayload={abLinePayload1 as AbLinePayloadSingle}
            polygonsListForPop={polygonsListForPop}
          />
        )}
        {showAbBlock && (
          <AbBlock
            showModal={showAbBlock}
            polyPayload={abBlockPayload as AbBlockPayload}
            handleCancel={(reload?: boolean) => {
              cancelOperations();
              setShowAbBlock(false);
              reload && getAbBlocksListData();
            }}
          />
        )}
        {/* <div className="edit-loader">
          {loader && (
            <div className="loader">
              <img
                style={{ width: '60px', height: '36px' }}
                src={loading_logo}
              />
            </div>
          )}
        </div> */}
      </>
    );
  },
);

export default MapMakerMap;
MapMakerMap.displayName = 'LiveMap';
