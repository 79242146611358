/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import type { PieDatum, SecondPieChartInput } from '../types/linked_charts';
import type { PieCustomLayerProps } from '@nivo/pie';

import { ResponsivePie } from '@nivo/pie';
import { BasicTooltip } from '@nivo/tooltip';

const SecondPieChart = ({
  title,
  line_id,
  info,
  data,
  color_scheme,
  use_data_color = false,
  onClick,
}: // disable_info
SecondPieChartInput) => {
  const CenterView = MakeCenterView({ line_id, info });
  return (
    <div>
      <div className="text-3xl font-bold">{title}</div>
      {/* {!disable_info &&
    // <div className='text-lg'>
    //     {line_id} <br/>
    //     {info.x} <br/>
    //     {info.y}
    // </div>
    } */}
      <div style={{ height: '440px', width: '100%' }}>
        <ResponsivePie
          data={data}
          margin={{ top: 100, right: 0, bottom: 80, left: 0 }}
          innerRadius={0.7}
          // colors={{ datum: 'data.color' }}
          colors={
            use_data_color
              ? { datum: 'data.color' }
              : color_scheme
              ? color_scheme
              : undefined
          }
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
          arcLinkLabelsSkipAngle={2}
          arcLabelsSkipAngle={2}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [['darker', 2]],
          }}
          fit={true}
          onClick={onClick}
          tooltip={(info: any) => {
            return (
              <>
                <BasicTooltip
                  id={info?.datum?.data?.id}
                  value={'$ ' + info?.datum?.data?.value}
                  color={info?.datum?.color}
                  enableChip
                />
              </>
            );
          }}
          // legends={[
          //     {
          //         anchor: 'top',
          //         direction: 'row',
          //         justify: false,
          //         translateX: 0,
          //         translateY: 56,
          //         itemsSpacing: 0,
          //         itemWidth: 100,
          //         itemHeight: 18,
          //         itemTextColor: '#999',
          //         itemDirection: 'left-to-right',
          //         itemOpacity: 1,
          //         symbolSize: 18,
          //         symbolShape: 'circle',
          //         effects: [
          //             {
          //                 on: 'hover',
          //                 style: {
          //                     itemTextColor: '#000'
          //                 }
          //             }
          //         ]
          //     }
          // ]}
          layers={['arcLinkLabels', 'arcs', 'arcLabels', 'legends', CenterView]}
        />
      </div>
    </div>
  );
};

const MakeCenterView =
  ({
    line_id,
    info,
  }: {
    line_id: string | number;
    info: {
      x: string;
      y: string;
    };
  }) =>
  ({ dataWithArc, centerX, centerY }: PieCustomLayerProps<PieDatum>) => {
    const line_id_head = (line_id as string).split('.')[0];
    return (
      <text
        x={centerX}
        y={centerY - 25}
        textAnchor="middle"
        dominantBaseline="top"
        style={{
          fontSize: '20px',
          fontWeight: 400,
        }}
      >
        <tspan x={centerX} dy=".6em">
          {line_id_head === 'total' ? 'Total' : line_id_head}
        </tspan>
        <tspan x={centerX} dy="1.2em">
          {info.x}
        </tspan>
        <tspan x={centerX} dy="1.2em">
          {info.y}
        </tspan>
      </text>
    );
  };

export default SecondPieChart;
