/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */
import { notification } from 'antd';
import { Coordinate } from 'ol/coordinate';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import nworkOff from '../../assets/images/low.svg';
import tractorAlertIcon from '../../assets/images/tractorAlert.svg';
import tractorspic from '../../assets/images/tractor_health.svg';
import tOff from '../../assets/images/tractor_off.svg';
import wifiOff from '../../assets/images/wOff.svg';
import {
  addRemoteAVCommand,
  executeRemoteAVCommand,
  getAutoDrivePermissions,
  getEmployeeDetails,
  getImplementAttached,
  validateOpsCode,
} from '../../constants/Api';
import {
  AUTODRIVE,
  checkAlphaFeature,
  checkTractorHaveMonitorStatueError,
  checkTractorInConnectedState,
  getConnectivityImg,
  getLocalStorageData,
  getNetworkTypeImg,
  LS_RAV_COMMANDS_KEY,
  LS_ROUTINE,
  MAINTENANCE_LOCK,
  setTitleAndDescription,
  tractorConstantsNew,
  wrapIndictorsData,
} from '../../constants/Common';
import constant from '../../constants/constant';
import { MapFeature, MosFeature } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import {
  AUTO_DRIVE_MONITOR_ERROR_CODES,
  EXECUTE,
  RESET_STATE,
  SELECTED_ITEM_NAME,
  SELECTED_ROUTINE,
  SELECTED_TRACTORS,
  SET_AUTO_DRIVE_MONITOR_POP,
  SET_AUTO_DRIVE_POP,
  SET_CONNECTING,
  SET_IMPLEMENT,
  SET_PINNED_TRACTORS,
  SET_SHOW_AUTO_DRIVE,
  SET_SHOW_CONNECT_LOADER,
  SET_SHOW_MAINTENANCE_LOCK_DRIVE,
  SET_SHOW_PATH_MODAL,
  SET_SHOW_REMOTE_DRIVE_DETAILS,
  SET_SHOW_TERM_AND_COND,
  SET_TRIGGERED_GUARDRAIL,
} from './actions';
import RemoteDriveReducer, { initialState } from './RemoteDriveReducer';

const { TRACTOR_COMMUNICATION } = constant;

interface RemoetDriveContextInterface {
  selectedRemoteDriveTractorId: number;
  setSelectedRemoteDriveTractorId: (prev: any) => void;
  getImplement: (arg: number) => void;
  waypointsForRemoteAv: any;
  setwaypointsForRemoteAv: (waypointsForRemoteAv: any) => void;
  goToPointCoordinates: any;
  setGoToPointCoordinates: (goToPointCoordinates: any) => void;
  greenPolygons: MapFeature[];
  setGreenPolygons: (greenPolygons: MapFeature[]) => void;
  goToRowFollowPolygon: null | MapFeature;
  setGoToRowFollowPolygon: (goToRowFollowPolygon: null | MapFeature) => void;
  RDReducer: any[];
  activeIds: string;
  setActiveIds: (activeIds: string) => void;
  // getCommands: () => void;
  commandsList: any;
  pointCordinate: Coordinate;
  setPointCordinate: (pointCordinate: Coordinate) => void;
  handlePauseResumeStopLiveCom: (
    command: string,
    tractor?: any,
    from?: 'Click' | 'Event',
  ) => void;
  // getStartedTractors: () => void;
  getIndicatorIcons: (tractor: any) => void;
  guardRailsCheck: (guardrail: string, type: string) => void;
  iconsFlag: boolean;
  closeModal: () => void;
  tractorObj: any;
  mapPinnedTractors: (id: number) => void;
  unPinnedTractors: (id: number) => void;
  connectToTractor: (id: number) => void;
  saveCommandTOLocalDB: (tractorId: number, pointsData: any) => void;
  clearAllLocalDBCommands: (tractorId: number) => void;
  addRVRoutineCommand: () => void;
  clearAll1: (tractorId: number) => void;
  startAutoDriveIteration: (tractorId: number, payload?: any) => void;
  selectedTractorsIds: any[];
  setSelectedTractorsIds: (ids: any) => void;
  connectTractorHandler: (tractorId?: number) => void;
}

export const RemoteDriveAppCtx = createContext<RemoetDriveContextInterface>({
  selectedRemoteDriveTractorId: 0,
  setSelectedRemoteDriveTractorId: (state: any) => {},
  getImplement: (arg: number) => {},
  waypointsForRemoteAv: [],
  setwaypointsForRemoteAv: (waypointsForRemoteAv: any) => [],
  goToPointCoordinates: [],
  setGoToPointCoordinates: (goToPointCoordinates) => [],
  greenPolygons: [],
  setGreenPolygons: (greenPolygons: MapFeature[]) => [],
  goToRowFollowPolygon: null,
  setGoToRowFollowPolygon: (goToRowFollowPolygon: null | MapFeature) => null,
  RDReducer: [],
  activeIds: '',
  setActiveIds: (activeIds: string) => '',
  // getCommands: () => {},
  commandsList: {},
  pointCordinate: [],
  setPointCordinate: (pointCordinate: Coordinate) => [],
  handlePauseResumeStopLiveCom: (
    command: string,
    tractor?: any,
    from?: string,
  ) => {},
  // getStartedTractors: () => {},
  getIndicatorIcons: (tractor: any) => {},
  guardRailsCheck: (guardrail: string, type: string) => {},
  iconsFlag: false,
  closeModal: () => {},
  tractorObj: {},
  mapPinnedTractors: (id: number) => {},
  unPinnedTractors: (id: number) => {},
  connectToTractor: (id: number) => {},
  saveCommandTOLocalDB: (tractorId: number, pointsData: any) => {},
  clearAllLocalDBCommands: (tractorId: number) => {},
  addRVRoutineCommand: () => {},
  clearAll1: (tractorId: number) => {},
  startAutoDriveIteration: (tractorId: number, payload?: any) => {},
  selectedTractorsIds: [],
  setSelectedTractorsIds: (ids: any) => {},
  connectTractorHandler: (tractorId?: number) => {},
});

// Provider in your app

const RemoteDriveContext: React.FC = ({ children }) => {
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [appState] = APPReducer;
  const { tractorsList } = appState;
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState] = AutoDriveReducer;
  const { remoteAvTractors } = autoDriveState;
  const mixpanel = useMixpanel();
  const [state, dispatch] = useReducer(RemoteDriveReducer, initialState);
  const [selectedRemoteDriveTractorId, setSelectedRemoteDriveTractorId] =
    useState<number>(0);
  const [goToPointCoordinates, setGoToPointCoordinates] = useState();
  const [goToRowFollowPolygon, setGoToRowFollowPolygon] =
    useState<null | MapFeature>({} as MapFeature);
  const [greenPolygons, setGreenPolygons] = useState<MapFeature[]>([]);
  const [waypointsForRemoteAv, setwaypointsForRemoteAv] = useState<
    MapFeature[]
  >([]);
  const [activeIds, setActiveIds] = useState<string>('');
  const [commandsList, setCommandsList] = useState<any>({});
  const [pointCordinate, setPointCordinate] = useState<Coordinate>([]);
  const [iconsFlag, seticonsFlag] = useState<boolean>(false);
  const [tractorObj, setTractorObj] = useState<any>();
  const [opsCode, setOpsCode] = useState<string>('');
  const [selectedTractorsIds, setSelectedTractorsIds] = useState<any[]>([]);

  const { pinnedTractors, selectedImplementName, selectedTractor } = state;

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_STATE });
    };
  }, []);
  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getCommands();
    }
  }, [userDetails]);

  const getImplement = async (tractor_id: number) => {
    try {
      const { organization } = userDetails;
      const { attached_implement } = await getImplementAttached(
        organization.api_url,
        tractor_id,
      );
      dispatch({ type: SET_IMPLEMENT, payload: attached_implement });
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message || error?.message,
      });
    }
  };

  const mapPinnedTractors = (id: number) => {
    let tTractors: any[] = pinnedTractors;
    if (!pinnedTractors.length) tTractors = [id];
    else if (!pinnedTractors.includes(id)) {
      tTractors = [...pinnedTractors, id];
    }
    dispatch({
      type: SET_PINNED_TRACTORS,
      payload: tTractors,
    });
  };

  const getCommands = () => {
    const remoteAVCommandsObj: any = getLocalStorageData(
      LS_RAV_COMMANDS_KEY,
      true,
    );
    setCommandsList(remoteAVCommandsObj);
  };

  const handlePauseResumeStopLiveCom = async (
    command: string,
    heartbeat = selectedTractor?.heartbeat,
    from?: 'Click' | 'Event',
  ) => {
    try {
      const { organization } = userDetails;

      const payload: any = {
        organization_id: organization.id,
        operator_id: userDetails.id,
        action: command,
        planner: 'remote_av',
        tractor_id: heartbeat.tractor_id,
        drive_action_uuid:
          heartbeat?.drive_action_details?.current_drive_action,
      };
      // if ((command === 'pause' || command === 'resume') && from === 'Click') {
      //   payload.details = 'button_press';
      //   payload.category = 'user_pause';
      //   payload.type = 'hard';
      // }
      // if ((command === 'pause' || command === 'resume') && from === 'Event') {
      //   payload.details = 'timeout';
      //   payload.category = 'user_pause';
      //   payload.type = 'hard';
      // }
      // if (command === 'pause' || command === 'resume') {
      if (command === 'pause' || command === 'resume') {
        payload.details = 'mos_cloud';
        payload.category = 'mos_cloud';
        payload.type = 'hard';
      }

      const { msg } = await executeRemoteAVCommand(
        organization.api_url,
        payload,
      );
      dispatch({
        type: SET_AUTO_DRIVE_POP,
        payload: false,
      });

      notification.success({
        message: msg,
      });
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message || err?.message,
      });
    }
  };

  const mapOpen = (tractor: number) => {
    mixpanel.track('Live Map', {
      event: `Fleet List Indicators Clicked`,
    });
    setTractorObj(tractor);
    seticonsFlag(true);
  };
  const closeModal = () => {
    seticonsFlag(false);
  };

  const getIndicatorIcons = (tractor: any) => {
    const { indicators: data } = tractor;
    const indicatorsData = wrapIndictorsData(data);
    const connectivity = getConnectivityImg(
      indicatorsData[TRACTOR_COMMUNICATION],
    );
    const isError =
      tractor.indicatorsData.filter(
        (indicator: { indicator_level: number }) =>
          indicator.indicator_level === 3,
      ).length > 0;
    if (tractor.isAvailable === tractorConstantsNew.off.key) {
      return (
        <>
          <div className="t_signals">
            <div className="t_signals_img">
              <img className="mr4" src={tOff} style={{ cursor: 'pointer' }} />
            </div>
            <div className="t_signals_img">
              {tractor.active_network === 'cellular' ? (
                <img
                  className="mr4"
                  style={{ marginLeft: '5px', cursor: 'pointer' }}
                  height="11"
                  src={nworkOff}
                />
              ) : (
                <img
                  className="mr4"
                  src={wifiOff}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </div>
          </div>
        </>
      );
    }
    return (
      connectivity.trim() !== '' && (
        <div
          className={`t_signals ${isError ? 't_error' : ''}`}
          onClick={(e: any) => {
            e.stopPropagation();
            mapOpen(tractor);
          }}
        >
          <div className="t_signals_img">
            <img
              className="mr4"
              src={isError ? tractorAlertIcon : tractorspic}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className="t_signals_img">
            {tractor.active_network === 'cellular' ? (
              <img
                className="mr4"
                width="16px"
                src={getNetworkTypeImg(tractor)}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <img
                className="mr4"
                src={getNetworkTypeImg(tractor)}
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>
        </div>
      )
    );
  };

  const guardRailsCheck = async (guardRail: string, triggerState: string) => {
    try {
      dispatch({ type: SET_TRIGGERED_GUARDRAIL, payload: guardRail });
      if (triggerState === 'passive') {
        const titleAndDescription = setTitleAndDescription(guardRail);
        notification.warning({
          message: titleAndDescription.title,
          description: titleAndDescription.description,
        });
      } else if (triggerState === 'active') {
        if (
          guardRail === 'path protection' ||
          guardRail === 'human protection' ||
          guardRail === 'pto protection'
        ) {
          dispatch({ type: SET_SHOW_PATH_MODAL, payload: true });
        } else {
          const titleAndDescription = setTitleAndDescription(guardRail);
          notification.warning({
            message: titleAndDescription.title,
            description: titleAndDescription.description,
          });
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('error');
    }
  };
  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getProfile();
    }
  }, [userDetails]);

  const getProfile = async () => {
    const data = await getEmployeeDetails(
      userDetails.organization.api_url,
      userDetails.organization.id,
      userDetails.id,
    );
    setOpsCode(data.ops_code);
  };
  const getConnectedTractor = (id: number, fail: boolean) => {
    const [tractor] = tractorsList.filter((tractor: any) => tractor.id === id);
    if (fail) {
      dispatch({
        type: SET_SHOW_REMOTE_DRIVE_DETAILS,
        payload: true,
      });
      dispatch({
        type: SET_AUTO_DRIVE_POP,
        payload: true,
      });
      dispatch({
        type: SELECTED_TRACTORS,
        payload: [tractor],
      });
    } else {
      dispatch({
        type: SET_AUTO_DRIVE_POP,
        payload: false,
      });
      dispatch({
        type: SET_CONNECTING,
        payload: false,
      });
      dispatch({
        type: SET_SHOW_REMOTE_DRIVE_DETAILS,
        payload: false,
      });
      dispatch({
        type: SELECTED_TRACTORS,
        payload: [],
      });
    }
  };

  const connectToTractor = async (tractorId: number) => {
    // setLoader(true);
    dispatch({
      type: SET_CONNECTING,
      payload: true,
    });
    try {
      if (
        !checkTractorInConnectedState(
          selectedTractorsIds[0],
          userDetails?.id,
          remoteAvTractors,
        )
      ) {
        const { organization } = userDetails;
        const payload = {
          tractor_id: Number(tractorId),
          operator_id: userDetails.id,
          organization_id: userDetails.organization_id,
          ops_code: Number(opsCode),
        };
        await validateOpsCode(organization.api_url, payload);
      }

      // setLoader(false);
      getConnectedTractor(tractorId, true);
      dispatch({
        type: EXECUTE,
        payload: {
          autoDrive: false,
          connected: true,
          connecting: false,
        },
      });
    } catch (err: any) {
      getConnectedTractor(tractorId, false);
      notification.error({
        message: 'Failed to Connect',
        description: err.response?.data?.error?.message || err?.message,
      });
      dispatch({
        type: SET_SHOW_CONNECT_LOADER,
        payload: false,
      });
    }
  };
  const setLocalStorageData = (
    key: string,
    data: any,
    isJson: boolean,
  ): void => {
    if (data && isJson) {
      data = JSON.stringify(data);
    }
    if (data) {
      localStorage.setItem(key, data);
    }
    getCommands();
  };
  const clearAllLocalDBCommands = (tractorId: number) => {
    const commandsObj = getLocalStorageData(LS_RAV_COMMANDS_KEY, true);
    if (commandsObj && commandsObj[tractorId]) {
      delete commandsObj[tractorId];
      setLocalStorageData(LS_RAV_COMMANDS_KEY, commandsObj, true);
    }
  };
  const saveCommandTOLocalDB = (tractorId: number, pointsData: any): void => {
    let tractorCommands: any = [];
    let remoteAVCommandsObj: any = getLocalStorageData(
      LS_RAV_COMMANDS_KEY,
      true,
    );
    if (remoteAVCommandsObj && remoteAVCommandsObj[tractorId]) {
      tractorCommands = remoteAVCommandsObj[tractorId];
      pointsData.cmdIndex = tractorCommands.length + 1;
      tractorCommands.push(pointsData);
      if (tractorId) {
        remoteAVCommandsObj[tractorId] = tractorCommands;
      }
      setLocalStorageData(LS_RAV_COMMANDS_KEY, remoteAVCommandsObj, true);
    } else {
      tractorCommands = [];
      pointsData.cmdIndex = tractorCommands.length + 1;
      tractorCommands.push(pointsData);
      if (!remoteAVCommandsObj) {
        remoteAVCommandsObj = {};
      }
      if (tractorId) {
        remoteAVCommandsObj[tractorId] = tractorCommands;
      }
      setLocalStorageData(LS_RAV_COMMANDS_KEY, remoteAVCommandsObj, true);
    }
  };

  const addRVRoutineCommand = async () => {
    try {
      const { organization } = userDetails;
      if (organization && organization.api_url) {
        const routine: string | null = localStorage.getItem(LS_ROUTINE);
        const routineContent: any = routine && JSON.parse(routine);
        if (routineContent && routineContent.id) {
          const { name } = routineContent;
          const commandData: any = {
            command: 'routine',
            routine: name,
            implement: selectedImplementName ? selectedImplementName : 'None',
          };
          await addRemoteAVCommand(
            organization.api_url,
            [commandData],
            selectedTractor?.heartbeat?.drive_action_details
              ?.current_drive_action as string,
          );
          commandData.name = name;
          selectedTractor &&
            selectedTractor.id &&
            saveCommandTOLocalDB(selectedTractor.id, commandData);
        }
        // resetForMapClicks('');
      }
    } catch (err: any) {
      dispatch({
        type: SELECTED_ITEM_NAME,
        payload: null,
      });
      dispatch({ type: SELECTED_ROUTINE, payload: null });

      notification.error({
        message: err.response?.data.error.message || err?.message,
      });
    } finally {
      // setLoader(false);
    }
  };

  const clearAll1 = (id: number) => {
    const [tractor] = tractorsList.filter((tractor: any) => tractor.id == id);
    if (tractor && tractor.id) {
      const { organization } = userDetails;
      if (organization && organization.api_url) {
        clearAllLocalDBCommands(id);
        tractor?.heartbeat?.drive_action_details?.current_drive_action &&
          addRemoteAVCommand(
            organization.api_url,
            [
              {
                command: 'clear_command_queue',
              },
            ],
            tractor?.heartbeat?.drive_action_details
              ?.current_drive_action as string,
          );
      }
    }
    // dispatch({
    //   type: SET_AUTO_VALUE,
    //   payload: '',
    // });
    // setGoToPointCoordinates([]);
    // dispatch({
    //   type: SET_SHOW_PRESENT_COMMAND,
    //   payload: 'GOTO',
    // });
    // handleResetCommand('GOTO');
    // clearPreviewPath(selectedTractor.tractor_id);
  };

  const startAutoDriveIteration = async (id: number, actionPayload?: any) => {
    const [tractor] = tractorsList.filter((tractor: any) => tractor.id == id);
    if (tractor && tractor.id) {
      try {
        const { organization } = userDetails;
        const payload = {
          ...{
            organization_id: userDetails.organization_id,
            action: 'start',
            tractor_id: id,
            drive_action_uuid: tractor?.heartbeat?.drive_action_details
              ?.current_drive_action as string,
          },
          ...actionPayload,
        };
        const { msg } = await executeRemoteAVCommand(
          organization.api_url,
          payload,
        );
        notification.success({
          message: msg,
        });
        dispatch({
          type: SET_AUTO_DRIVE_POP,
          payload: false,
        });
      } catch (err: any) {
        notification.error({
          message: err.response?.data.error.message,
        });
      }
    }
  };

  useEffect(() => {
    userDetails &&
      userDetails.organization &&
      checkAlphaFeature('MosFeatures') &&
      getAutoDrivePermissionsHandler();
  }, [userDetails]);

  const getAutoDrivePermissionsHandler = async () => {
    try {
      const resp: MosFeature[] = await getAutoDrivePermissions(
        userDetails.organization.api_url,
        userDetails.organization.id,
      );
      const [permission] =
        resp &&
        resp.filter(
          (permission: MosFeature) => permission.feature_code === AUTODRIVE,
        );
      const status = permission && permission.is_subscribed ? true : false;
      dispatch({
        type: SET_SHOW_AUTO_DRIVE,
        payload: status,
      });
      const [permission1] =
        resp &&
        resp.filter(
          (permission: MosFeature) =>
            permission.feature_code === MAINTENANCE_LOCK,
        );
      const status1 = permission1 && permission1.is_subscribed ? true : false;
      dispatch({
        type: SET_SHOW_MAINTENANCE_LOCK_DRIVE,
        payload: status1,
      });
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };
  const unPinnedTractors = (id: number) => {
    const ttractors = pinnedTractors.filter((item: any) => item !== id);
    dispatch({
      type: SET_PINNED_TRACTORS,
      payload: ttractors,
    });
  };

  const connectTractorHandler = (tractorId?: number) => {
    const message = checkTractorHaveMonitorStatueError(
      tractorId ? tractorId : selectedTractorsIds[0],
      remoteAvTractors,
    );
    // const message = '7001,7002';
    if (message && message.length > 0) {
      const errors = message.split(',').map((element: string) => {
        return Number(element);
      });
      dispatch({
        type: AUTO_DRIVE_MONITOR_ERROR_CODES,
        payload: {
          message: message,
          errors: errors,
          tractorId: tractorId ? tractorId : selectedTractorsIds[0],
        },
      });
      dispatch({
        type: SET_AUTO_DRIVE_MONITOR_POP,
        payload: true,
      });
      // set selected tractor
    } else {
      dispatch({
        type: SET_SHOW_TERM_AND_COND,
        payload: true,
      });
      tractorId && setSelectedTractorsIds([selectedTractor.id]);
    }
  };

  return (
    <RemoteDriveAppCtx.Provider
      value={{
        selectedRemoteDriveTractorId,
        setSelectedRemoteDriveTractorId,
        getImplement,
        waypointsForRemoteAv,
        setwaypointsForRemoteAv,
        goToPointCoordinates,
        setGoToPointCoordinates,
        greenPolygons,
        setGreenPolygons,
        goToRowFollowPolygon,
        setGoToRowFollowPolygon,
        RDReducer: [state, dispatch],
        activeIds,
        setActiveIds,
        // getCommands,
        commandsList,
        pointCordinate,
        setPointCordinate,
        handlePauseResumeStopLiveCom,
        // getStartedTractors,
        getIndicatorIcons,
        guardRailsCheck,
        iconsFlag,
        closeModal,
        tractorObj,
        mapPinnedTractors,
        unPinnedTractors,
        connectToTractor,
        saveCommandTOLocalDB,
        clearAllLocalDBCommands,
        addRVRoutineCommand,
        clearAll1,
        startAutoDriveIteration,
        selectedTractorsIds,
        setSelectedTractorsIds,
        connectTractorHandler,
      }}
    >
      {children}
    </RemoteDriveAppCtx.Provider>
  );
};

export default RemoteDriveContext;
