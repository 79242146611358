/* eslint-disable no-console */
import { Modal, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { saveFeedBack } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { SET_AUTO_DRIVE_FEEDBACK_POP } from '../remote_drive_new/actions';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import './style.css';

interface Props {
  showModal: boolean;
}
const AutoDriveFeedBackPop: React.FC<Props> = ({ showModal }: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { RDReducer } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const [userFeedBack, setUserFeedBack] = useState('');
  const [rating, setRating] = useState(0);
  const { feedBackPayload } = state;
  useEffect(() => {
    console.log('rating', rating);
    console.log('feedBackText', userFeedBack);
  }, [rating, userFeedBack]);

  const feedBackHandler = async () => {
    try {
      const { organization } = userDetails;
      const payload = {
        ...{
          user_rating: rating,
          user_feedback: userFeedBack,
        },
        ...feedBackPayload,
      };
      const { msg } = await saveFeedBack(organization.api_url, payload);
      // console.log('payload', payload);
      msg &&
        notification.success({
          message: msg,
        });
      dispatch({ type: SET_AUTO_DRIVE_FEEDBACK_POP, payload: false });
    } catch (error: any) {
      notification.error({
        message: error.response?.data?.error?.message || error.message,
      });
    } finally {
      console.log();
    }
  };
  return (
    <Modal
      title=""
      centered
      visible={showModal}
      footer={false}
      onOk={() =>
        dispatch({ type: SET_AUTO_DRIVE_FEEDBACK_POP, payload: false })
      }
      onCancel={() =>
        dispatch({ type: SET_AUTO_DRIVE_FEEDBACK_POP, payload: false })
      }
      width={850}
      className="ratingPopup"
      maskClosable={false}
    >
      <div className="adrEndTitle">Autodrive Ended</div>
      <div className="adrEndQuestion">
        Why did you end your Autodrive early?
      </div>
      <textarea
        rows={6}
        className="rateTextarea"
        onChange={(e) => {
          setUserFeedBack(e.target.value);
          //   console.log(e.target.value, 'setFeedBackText');
        }}
      ></textarea>
      <div className="adrEndQuestion mb5">
        How would you rate your Autodrive Experience?
      </div>
      <div className="ratingStars pdl25 mb30">
        <div className="rating">
          <span
            className={`${rating >= 1 ? 'starsel' : ''}`}
            onClick={() => setRating(rating === 1 ? 0 : 1)}
          >
            ☆
          </span>
          <span
            className={`${rating >= 2 ? 'starsel' : ''}`}
            onClick={() => setRating(rating === 2 ? 0 : 2)}
          >
            ☆
          </span>
          <span
            className={`${rating >= 3 ? 'starsel' : ''}`}
            onClick={() => setRating(rating === 3 ? 0 : 3)}
          >
            ☆
          </span>
          <span
            className={`${rating >= 4 ? 'starsel' : ''}`}
            onClick={() => setRating(rating === 4 ? 0 : 4)}
          >
            ☆
          </span>
          <span
            className={`${rating == 5 ? 'starsel' : ''}`}
            onClick={() => setRating(rating === 5 ? 0 : 5)}
          >
            ☆
          </span>
        </div>
      </div>
      <div className="feedBtnSec">
        <button
          key="back"
          className="feedSubmitBtn"
          type="button"
          style={{ cursor: userFeedBack.trim() === '' ? 'default' : 'pointer' }}
          onClick={() => {
            feedBackHandler();
          }}
          disabled={userFeedBack.trim() === '' ? true : false}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};

export default AutoDriveFeedBackPop;
