import React, { memo, useContext } from 'react';
import t_lock from '../../assets/images/tCLock.svg';
import t_speed from '../../assets/images/t_speed.svg';
import warning_icon from '../../assets/images/warning-icon.svg';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';

import { Progress } from 'antd';
import moment from 'moment';
import hitch_implement_B from '../../assets/images/hitch_implement_B.svg';
import hitch_implement_G from '../../assets/images/hitch_implement_G.svg';

import {
  batteryValue,
  checkPausedStatus,
  getBatteryPercentage,
  getChargingTextRemaining,
  getImplementName,
  getOperatorNameByHeartBeat,
  getSpeed,
  getStatusClass,
  getStatusForTractor,
  noneAttached,
  offTimeSetUp,
  tractorConstantsNew,
} from '../../constants/Common';
import Battery from '../common/Battery';
import './style.css';
import { ApplicationContext } from '../../context/AppContext';

interface Props {
  tractor?: any;
  setTractorTabes: (tractor: any) => void;
}
const TractorCard: React.FC<Props> = ({ tractor, setTractorTabes }: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { getIndicatorIcons, RDReducer } = useContext(RemoteDriveAppCtx);
  const [state] = RDReducer;
  const { selectedTractor, showPathModal, pinnedTractors } = state;
  const offDurationInHrs =
    tractor?.last_offline_date_time > 0
      ? Math.round(
          moment
            .duration(
              moment().diff(moment(tractor?.last_offline_date_time || 0)),
            )
            .asHours(),
        )
      : 0;
  const { heartbeat = undefined } = tractor;
  return (
    <>
      <div
        className={`fleetBlk ${
          (tractor && tractor?.selected) ||
          (tractor && tractor.id && pinnedTractors.includes(tractor.id))
            ? 'fleetBlk-selected'
            : ''
        }
        ${
          tractor &&
          tractor.id &&
          selectedTractor &&
          selectedTractor.id === tractor.id &&
          checkPausedStatus(userDetails?.id, selectedTractor, true)
        }
        `}
        onClick={() => setTractorTabes(tractor)}
        key={tractor.id}
      >
        {selectedTractor && selectedTractor.id === tractor.id && showPathModal && (
          <h6 className="pausedMsg">
            <img src={warning_icon} alt="" />
            <span>
              <strong>Paused:</strong> Obstructed path
            </span>
          </h6>
        )}
        {tractor.isAvailable === tractorConstantsNew.charging.key && (
          <>
            <Progress
              className="charging_status"
              percent={batteryValue(tractor)}
              showInfo={false}
            />
            <div className="charging_remain">
              {getChargingTextRemaining(heartbeat)}
            </div>
          </>
        )}
        {tractor.isAvailable === tractorConstantsNew.off.key &&
          Object.keys(tractor).includes('last_offline_date_time') && (
            <>
              {offDurationInHrs >= 1 && (
                <div className="charging_remain ">
                  OFF FOR {offTimeSetUp(offDurationInHrs)}
                </div>
              )}
            </>
          )}
        <div
          className={
            'tractorDtlSec  mbottom ' +
            (tractor.isAvailable === tractorConstantsNew.charging.key ||
            tractor.isAvailable === tractorConstantsNew.off.key
              ? 'mtop'
              : '')
          }
        >
          <div className="tractor_info">
            <div className="tPos">
              <div className="t_driverDtl">
                <div className="t_name">{tractor.name}</div>
                <div className="t_driver_name">
                  {getOperatorNameByHeartBeat(heartbeat).trim() !== '' &&
                    tractor.isAvailable !== tractorConstantsNew.off.key && (
                      <>
                        {getOperatorNameByHeartBeat(heartbeat).trim() !==
                          '' && <img className="mr5" src={t_lock} />}
                        <span>{getOperatorNameByHeartBeat(heartbeat)}</span>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`ttractor_state  ${getStatusClass(
                tractor.isAvailable,
                tractor?.heartbeat?.drive_action_details
                  ?.drive_action_created_at,
              )}`}
            >
              {getStatusForTractor(
                tractor.isAvailable,
                tractor?.heartbeat?.drive_action_details
                  ?.drive_action_created_at,
              )}
            </div>
            {selectedTractor &&
              tractor &&
              selectedTractor.id === tractor.id &&
              checkPausedStatus(userDetails?.id, selectedTractor) ===
                'PAUSED' && <div className="tractorState pusTxt">PAUSED</div>}
          </div>
        </div>

        <div className="tractorDtlSec1">
          <div className="tImplementSec">
            <img
              src={
                getImplementName(tractor) === noneAttached
                  ? hitch_implement_G
                  : hitch_implement_B
              }
              alt=""
            />
            {getImplementName(tractor) === noneAttached ? (
              <span className="hImpNameDbl">{getImplementName(tractor)}</span>
            ) : (
              <span className="hImpNameSel">{getImplementName(tractor)}</span>
            )}
          </div>
          {tractor.isAvailable !== tractorConstantsNew.off.key &&
            tractor.isAvailable !== tractorConstantsNew.available.key &&
            tractor.isAvailable !== tractorConstantsNew.faulty.key &&
            tractor.isAvailable !== tractorConstantsNew.charging.key &&
            tractor.heartbeat && (
              <div className="tSpeedtSec">
                <img src={t_speed} width="15" height="8" />
                <span className="tspeed">{getSpeed(tractor.heartbeat)} </span>
                <span className="tsMPH">MPH</span>
              </div>
            )}
          <div className="tBatterySec">
            <Battery
              battery={getBatteryPercentage(tractor)}
              charging={getStatusForTractor(tractor.isAvailable)}
            ></Battery>{' '}
            <span className="bPer">{batteryValue(tractor)}%</span>
          </div>
          {getIndicatorIcons(tractor)}
        </div>
      </div>
    </>
  );
};

export default memo(TractorCard);
