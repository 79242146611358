/* eslint-disable no-console */
import { notification } from 'antd';
import { Feature, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import { Icon, Style } from 'ol/style';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  displayRecenterViewPortSetUp,
  getLatitudeAndLongitude,
  getPathBorderColor,
  getPathColor,
  getPathFeatureStyle,
  jAGStartNdStop,
  pathConstants,
} from '../../constants/Common';
import { ApplicationContext } from '../../context/AppContext';

import { ScaleLine } from 'ol/control';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import OLMap from 'ol/Map';
import * as proj from 'ol/proj';
import BaseStationIcon from '../../assets/images/basestation_green.png';
import { getRoutePoints } from '../../constants/Api';
import { UserDetails } from '../../constants/types';
import AppLoader from '../common/AppLoader';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';

interface Props {
  selectedRoutine: any;
}

export const SimpleMap: React.FC<Props> = ({ selectedRoutine }) => {
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [loader, setLoader] = useState(false);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [appState] = APPReducer;
  const { groundZero } = appState;
  const { RDReducer } = useContext(RemoteDriveAppCtx);
  const [state] = RDReducer;
  const { selectedTractorLocation } = state;

  const mapElement: any = useRef();
  const mapRef = useRef<OLMap | null>(null);
  const initialLayer = useRef<VectorLayer<any>>();
  const scaleControl = () => {
    const control = new ScaleLine({
      units: 'metric',
      className: 'positionMap',
    });
    return control;
  };
  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      groundZero &&
      !mapInitialized
    ) {
      initializeMap();
    }
  }, [userDetails, groundZero, mapInitialized]);

  useEffect(() => {
    const init = async () => {
      initialLayer?.current
        ?.getSource()
        ?.addFeatures([selectedTractorLocation]);
    };
    if (
      userDetails &&
      userDetails.organization &&
      selectedRoutine &&
      selectedRoutine.id &&
      groundZero &&
      initialLayer &&
      initialLayer.current &&
      mapRef &&
      mapRef.current &&
      mapInitialized &&
      selectedTractorLocation
    ) {
      init();
    }
  }, [
    userDetails,
    selectedRoutine,
    groundZero,
    mapInitialized,
    selectedTractorLocation,
  ]);
  useEffect(() => {
    const { latitude, longitude } = groundZero;
    initialLayer?.current?.getSource().clear();
    const init = async () => {
      await drawRoutine(
        userDetails,
        selectedRoutine,
        [latitude, longitude],
        initialLayer,
        mapRef,
      );
    };
    if (
      userDetails &&
      userDetails.organization &&
      selectedRoutine &&
      selectedRoutine.id &&
      groundZero &&
      initialLayer &&
      initialLayer.current &&
      mapRef &&
      mapRef.current &&
      mapInitialized
    ) {
      init();
    }
  }, [userDetails, selectedRoutine, groundZero, mapInitialized]);
  const drawRoutine = async (
    userDetails: UserDetails,
    selectedRoutine: any,
    base: any,
    layer: any,
    mapRef: any,
  ) => {
    setLoader(true);
    try {
      const routinePoints = await getRoutePoints(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        selectedRoutine.id,
      );

      if (routinePoints && routinePoints.length > 0) {
        const coordinates: any = [];
        routinePoints.forEach((element: any) => {
          // const latAndLong = getLatitudeAndLongitude(
          //   [element.y, element.x],
          //   base,
          // );
          // coordinates.push(proj.fromLonLat([latAndLong[1], latAndLong[0]]));
          coordinates.push(
            proj.fromLonLat([element.longitude, element.latitude]),
          );
        });
        const pointFeature = jAGStartNdStop(coordinates[0], 'Start', 3);
        const layerLines = new Feature({
          geometry: new LineString(coordinates),
          name: `Routine: ${selectedRoutine.name}`,
          pointType: pathConstants.ROUTINE,
          color: getPathColor(pathConstants.ROUTINE),
          borderColor: getPathBorderColor(pathConstants.ROUTINE),
        });
        layerLines.setId(999);
        layerLines.setStyle(getPathFeatureStyle(pathConstants.ROUTINE));
        layer?.current?.getSource().clear();

        layer?.current?.getSource().addFeatures([layerLines, pointFeature]);

        if (mapRef && mapRef.current) {
          displayRecenterViewPortSetUp(
            layerLines as Feature<Geometry>,
            mapRef.current,
          );
        }
      }
    } catch (error: any) {
      notification.error({
        message: 'No Data Found',
      });
    } finally {
      setLoader(false);
    }
  };
  const initializeMap = async () => {
    try {
      const { latitude, longitude } = groundZero;

      const baseLocation = new Feature({
        geometry: new Point(proj.fromLonLat([longitude, latitude])),
      });
      baseLocation.set('name', 'Ground zero');
      //  set id 1 for base station recenter
      baseLocation.setId(1);
      const style = new Style({
        image: new Icon({
          src: BaseStationIcon,
          scale: 0.4,
        }),
        zIndex: 100,
      });
      baseLocation.setStyle(style);
      const initialFeaturesLayer = new VectorLayer({
        source: new VectorSource({
          features: [],
        }),
      });

      const satelliteLayer = new TileLayer({
        source: new XYZ({
          url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga',
          cacheSize: 1000,
        }),
        visible: true,
      });
      // create map
      const initialMap = new OLMap({
        target: mapElement.current,
        layers: [satelliteLayer, initialFeaturesLayer],
        controls: [scaleControl()],
        view: new View({
          projection: 'EPSG:3857',
          center: proj.transform(
            [longitude, latitude],
            'EPSG:4326',
            'EPSG:3857',
          ),
          zoom: 17,
          maxZoom: 25,
        }),
      });
      // set flag for map initialization
      setMapInitialized(true);
      mapRef.current = initialMap;
      initialLayer.current = initialFeaturesLayer;
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  return (
    <div>
      <div
        ref={mapElement}
        style={{
          width: '100vh',
          height: 275,
          borderRadius: 10,
          display: selectedRoutine?.id ? 'block' : 'none',
        }}
        className={`posHd`}
      ></div>
      <AppLoader loader={loader} />
    </div>
  );
};
