import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Amplify from 'aws-amplify';
import React, { useEffect } from 'react';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { MixpanelProvider } from 'react-mixpanel-browser';
import './App.css';
import './components/common/latest_common.css';
import './components/common/responsive.css';
import { checkAlphaFeature } from './constants/Common';
import { WINGSPANAI } from './constants/constant';
import AppContext from './context/AppContext';
import AutoDriveContext from './context/AutoDriveContext';
import ThemeContext from './context/ThemeContext';
import './firebaseInit';
import ErrorBoundary from './routes/ErrorBoundry';
import MobileRoot from './routes/MobileRoot';
import Root from './routes/Root';

export const REACT_APP_SENTRY_KEY = process.env.REACT_APP_SENTRY_KEY;
Sentry.init({
  dsn: REACT_APP_SENTRY_KEY,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1,
  maxBreadcrumbs: 10,
});

if (
  process.env.REACT_APP_ALPHA_FEATURES?.toLocaleLowerCase().includes('cognito')
) {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    },
  });

  Amplify.addPluggable(
    new AWSIoTProvider({
      aws_pubsub_region: process.env.REACT_APP_REGION,
      aws_pubsub_endpoint: `wss://${process.env.REACT_APP_MQTT_ID}.iot.${process.env.REACT_APP_REGION}.amazonaws.com/mqtt`,
    }),
  );
}

const App: React.FC = () => {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    let link: any = document.querySelector("link[rel~='icon']");
    document.title = checkAlphaFeature(WINGSPANAI)
      ? 'WingspanAI'
      : 'Monarch Customer Portal';
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
      link.href = checkAlphaFeature(WINGSPANAI)
        ? '/favicon1.ico'
        : '/favicon.ico';
    }
  }, []);

  return (
    <ErrorBoundary>
      <MixpanelProvider>
        <AppContext>
          <AutoDriveContext>
            <ThemeContext>
              {isMobile && dimensions.width < 740 && (
                <MobileView>
                  <MobileRoot />
                </MobileView>
              )}
              {isMobile &&
                dimensions.width >= 740 &&
                isMobile &&
                dimensions.width < 1024 && (
                  <>
                    <div className="mobile_view"></div>
                  </>
                )}
              {isMobile && dimensions.width >= 1024 && <Root />}
              {!isMobile && (
                <BrowserView>
                  <Root />
                </BrowserView>
              )}
            </ThemeContext>
          </AutoDriveContext>
        </AppContext>
      </MixpanelProvider>
    </ErrorBoundary>
  );
};

export default App;
