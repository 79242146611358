/* eslint-disable no-console */
import { Input, InputNumber, Modal, notification, Select, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import {
  saveVineRowsAutoGenerateData,
  saveVineRowsFromJumpAndGoData,
} from '../../constants/Api';
import { PolygonDropDown } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean) => void;
  polygonsListForPop: PolygonDropDown[];
  selctedVinerowsFromJumpandGo: any[];
}
export const VineRowModelWithJumpAndGo: React.FC<Props> = ({
  showModal,
  handleCancel,
  polygonsListForPop,
  selctedVinerowsFromJumpandGo,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [vineBlockName, setVineBlockName] = useState<string>('');
  const [numberOfRows, setNumberOfRows] = useState<number>(2);
  const [selectedPolygonUuid, setSelectedPolygonUuid] = useState('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [rows, setrows] = useState<any>([]);
  useEffect(() => {
    selctedVinerowsFromJumpandGo &&
      selctedVinerowsFromJumpandGo.length > 0 &&
      setrows([
        selctedVinerowsFromJumpandGo[0].data,
        selctedVinerowsFromJumpandGo[1].data,
      ]);
  }, [selctedVinerowsFromJumpandGo]);
  const saveVineRows = async () => {
    setLoader(true);
    const payload: any = {
      vine_block_name: vineBlockName.trim(),
      polygon_uuid: selectedPolygonUuid,
      num_lanes: numberOfRows,
      vine_row_1: rows[0],
      vine_row_2: rows[1],
    };
    setVineBlockName(vineBlockName.trim());

    try {
      const { organization } = userDetails;
      const response = await saveVineRowsFromJumpAndGoData(
        organization.api_url,
        organization.farm.id,
        payload,
      );
      handleCancel(true);
      setLoader(false);
      notification.success({
        message: response.msg,
      });
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      selectedPolygonUuid &&
      selectedPolygonUuid.trim().length > 0 &&
      vineBlockName &&
      vineBlockName.trim().length > 0 &&
      numberOfRows &&
      numberOfRows > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, selectedPolygonUuid, vineBlockName, numberOfRows]);

  return (
    <Modal
      className="commonPopup addPolygon vinrowAuto vinrowAutoJumpAndGo"
      title="Generate Vine rows"
      centered
      width={'60vw'}
      visible={showModal}
      onCancel={() => handleCancel()}
      okText="Save"
      onOk={saveVineRows}
      okButtonProps={{
        className: 'btnSave ',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow width-class">
        <div className="formCol">
          <label className="formLabel">
            Vine Block Name <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            onChange={(e) => setVineBlockName(e.target.value)}
            value={vineBlockName}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            Polygon <span style={{ color: 'red' }}> *</span>
          </label>
          <Select
            style={{ width: '100%' }}
            onSelect={(value) => {
              setSelectedPolygonUuid(value);
            }}
            value={selectedPolygonUuid}
          >
            {polygonsListForPop.map((data: any) => {
              if (data.name !== '') {
                return (
                  <option value={data.polygon_uuid} key={data.polygon_uuid}>
                    {data.name} ({data.color})
                  </option>
                );
              }
            })}
          </Select>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol ">
          <label className="formLabel">
            No of Vine Lanes <span style={{ color: 'red' }}> *</span>
          </label>
          <InputNumber
            min={1}
            type="number"
            max={99999}
            className="commInput w100"
            value={numberOfRows}
            onChange={(value) => setNumberOfRows(value)}
          />
        </div>
        <div className="formCol ">
          <label className="formLabel"></label>
          <button
            className="btnSave swapBtn  swapBtnAddOn"
            onClick={() => {
              const list = rows.reverse();
              setrows([...list]);
            }}
            style={{ float: 'right' }}
          >
            Swap Start and End
          </button>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel lblHeads">Start Vinerow</label>
        </div>
        <div className="formCol">
          <label className="formLabel lblHeads">End Vinerow</label>
        </div>
      </div>
      <div className="formRow">
        {rows &&
          rows.length == 2 &&
          rows.map((row: any) => (
            <>
              <div className="formCol"> {row[0].row_name}</div>
            </>
          ))}
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default VineRowModelWithJumpAndGo;
