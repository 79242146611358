import { CheckOutlined } from '@ant-design/icons';
import { Input, Timeline } from 'antd';
import * as proj from 'ol/proj';
import React from 'react';

interface Props {
  command: any;
  commantId: number;
  recenterXY?: (coordinate: any) => void;
}
const TimeLineItem: React.FC<Props> = ({ command, commantId, recenterXY }) => {
  return (
    <>
      {command && (
        <Timeline.Item>
          <div className="commandStepsFlex ">
            <div
              className="searchMapBlk savedCommand"
              style={{ display: 'flex' }}
            >
              <span
                style={{ width: 32, marginTop: 6, cursor: 'pointer' }}
                onClick={() =>
                  recenterXY &&
                  recenterXY(
                    proj.transform(
                      command.pointCordinate,
                      'EPSG:4326',
                      'EPSG:3857',
                    ),
                  )
                }
              >
                C{commantId}
              </span>
              <Input
                data-testid="searchMapInputField-LiveMapFleetList"
                placeholder="command"
                value={`${
                  command.command === 'goto_point'
                    ? ` GOTO Point ${
                        command.name ? '( ' + command.name + ' )' : ''
                      }`
                    : ''
                }${
                  command.command === 'vine_block'
                    ? ` ${command.name} ${command.block_name}`
                    : ''
                } ${
                  command.command === 'routine'
                    ? ` Routine ${
                        command.name ? '( ' + command.name + ' )' : ''
                      }`
                    : ''
                }
                `}
                readOnly
              />
            </div>
            <CheckOutlined className="checked" />
          </div>
        </Timeline.Item>
      )}
    </>
  );
};

export default TimeLineItem;
