import React, { useEffect, useState } from 'react';
import { Button, Input, notification } from 'antd';
import logoHorizontalWhite from '../../assets/images/logo_horizontal_white_rgb.png';
import logoWingspanAI from '../../assets/images/WingspanAI_logo_temp.svg';
import applicationIds from '../../locale/applicationIds.json';
import ForgotPwSuccess from './ForgotPwSuccess';
import { useHistory } from 'react-router';
import RoutesConstants from '../../routes/RoutesConstant';
import { getForgetPassword } from '../../constants/Api';
import { checkAlphaFeature } from '../../constants/Common';

const ForgotPassword: React.FC = () => {
  const { push } = useHistory();
  const [forgotPwSuccess, setForgotPwSuccess] = useState(false);
  const [emailId, setEmailid] = useState('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (emailId.trim().length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [emailId]);

  const handleForgotPassword = async () => {
    const payload = {
      email: emailId,
      app_type: 'web',
    };
    try {
      const res = await getForgetPassword(payload);
      if (res.status === 200) {
        const data = await res.json();
        notification.success({ message: data?.msg });
        setForgotPwSuccess(true);
        push(RoutesConstants.ForgotPwSuccess, {
          state: emailId,
        });
      } else if (res.status === 404) {
        const data = await res.json();
        notification.error({ message: data?.error?.message });
      } else {
        const data = await res.json();
        notification.error({ message: data?.message });
      }
    } catch (err: any) {
      notification.error({
        message: err.res ? err.res?.data?.error?.message : err.message,
      });
    }
  };

  return (
    <div className="loginContainer">
      <div className="resetSuccessWrap">
        {checkAlphaFeature('wingspanai') ? (
          <img height="100px" src={logoWingspanAI} />
        ) : (
          <img height="50px" src={logoHorizontalWhite} />
        )}
        {/* <img height="50px" src={logoHorizontalWhite} /> */}
        <div className="paddingTxt">
          <div className="forgotPwContent mt32 mb40">
            Enter the email address associated with you account and we’ll send
            you a link to reset your password.
          </div>
          <div className="loginFRow loginRight">
            <label className="labelTxt userId">Email</label>
            <Input
              id={applicationIds.loginScreen.userId}
              placeholder=""
              data-testid="emailInput-ForgotPassword"
              onChange={(e) => setEmailid(e.target.value)}
            />
          </div>
        </div>

        <div className="">
          <Button
            disabled={isDisabled}
            id={applicationIds.ForgotPasswordScreen.forgotPwLinkBtn}
            type="primary"
            data-testid="sendLinkButton-ForgotPassword"
            onClick={handleForgotPassword}
            className={
              checkAlphaFeature('wingspanai') ? 'btnTxt wspanAiBtn' : 'btnTxt'
            }
          >
            SEND LINK
          </Button>
        </div>
      </div>
      {forgotPwSuccess && <ForgotPwSuccess />}
    </div>
  );
};

export default ForgotPassword;
