import { Timeline } from 'antd';
import React, { useContext } from 'react';
import endBtnIcon from '../../assets/images/endBtnIcon.svg';
import pauseTask from '../../assets/images/pause-task.svg';
import playResume from '../../assets/images/playResume.svg';
import { checkTractorDrivingState } from '../../constants/Common';
import { ApplicationContext } from '../../context/AppContext';
import { RemoteDriveAppCtx } from './RemoteDriveContext';
import TimeLineItem from './TimeLineItem';

interface Props {
  showDeleteConfirm: () => void;
}
const RemoteAvActions: React.FC<Props> = ({ showDeleteConfirm }) => {
  const { userDetails } = useContext(ApplicationContext);
  const { RDReducer, commandsList, handlePauseResumeStopLiveCom } =
    useContext(RemoteDriveAppCtx);
  const [state] = RDReducer;
  const { selectedTractor } = state;
  return (
    <>
      {checkTractorDrivingState(selectedTractor?.heartbeat, userDetails.id) &&
        commandsList &&
        commandsList[selectedTractor.id] &&
        commandsList[selectedTractor.id].length > 0 && (
          <div className="autoDriveInrBlk2 waypointTimeline">
            <div className="waypointBlk">
              <div className="waypointInrBlk">
                <div className="commandSteps">
                  <Timeline>
                    {commandsList[selectedTractor.id].map(
                      (command: any, index: number) => (
                        <>
                          <TimeLineItem
                            command={command}
                            commantId={index + 1}
                          />
                        </>
                      ),
                    )}
                  </Timeline>
                </div>
              </div>
            </div>
          </div>
        )}
      {userDetails?.id ===
        selectedTractor?.heartbeat?.drive_action_details?.current_operator_id &&
        selectedTractor?.heartbeat?.planning_manager?.planner_name?.length >
          0 &&
        selectedTractor?.heartbeat?.planning_manager?.planner_status?.length >
          0 && (
          <>
            {(selectedTractor?.heartbeat?.planning_manager?.planner_name ===
              'copycat' ||
              selectedTractor?.heartbeat?.planning_manager?.planner_name ===
                'remote_av') && (
              <>
                <div
                  className="autoDriveInrBlk2 waypointTimeline"
                  style={{ marginTop: 15 }}
                >
                  <div className="waypointBlk">
                    <div className="waypointInrBlk">
                      <div className="endresumepauseBtns">
                        <div className="resumepauseBtns">
                          {selectedTractor?.heartbeat?.planning_manager
                            ?.planner_status === 'paused' ? (
                            <div
                              className="autoDriveCamPlayControls"
                              onClick={() =>
                                handlePauseResumeStopLiveCom(
                                  'resume',
                                  selectedTractor?.heartbeat,
                                  'Click',
                                )
                              }
                            >
                              <img
                                src={playResume}
                                alt=""
                                className="wtimg30 resumeTask"
                                width="28"
                              />
                              <span>Resume TASK</span>
                            </div>
                          ) : (
                            <div
                              className="autoDriveCamPlayControls"
                              onClick={() =>
                                handlePauseResumeStopLiveCom(
                                  'pause',
                                  selectedTractor?.heartbeat,
                                  'Click',
                                )
                              }
                            >
                              <img src={pauseTask} alt="" width="35" />
                              <span>PAUSE TASK</span>
                            </div>
                          )}
                        </div>

                        <div
                          className="autoDriveCamPlayControls"
                          onClick={showDeleteConfirm}
                        >
                          <img src={endBtnIcon} alt="" width="35" />
                          <span>END TASK</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
    </>
  );
};

export default RemoteAvActions;
