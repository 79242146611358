import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Input,
  Modal,
  notification,
  Select,
  Spin,
  Tag,
  Upload,
} from 'antd';
import 'antd/dist/antd.css';
import Layout from 'antd/lib/layout/layout';
import { RcFile } from 'antd/lib/upload/interface';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMixpanel } from 'react-mixpanel-browser';
import closeGreen from '../../assets/images/closeGreen.svg';
import FlagGrayIcon from '../../assets/images/flag_gray.svg';
import FlagOrangeIcon from '../../assets/images/flag_orange.svg';
import FlagRedIcon from '../../assets/images/flag_red.svg';
import {
  createdAddticket,
  getOrgEquipments,
  getOrgTags,
  getTasksearch,
  ticketaddattachment,
} from '../../constants/Api';
import { speCharRegX } from '../../constants/constant';
import { Taskview, Taskviewdetails } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import applicationIds from '../../locale/applicationIds.json';
import translate from '../../locale/en_translate.json';
import './TicketsStyle.css';

const { CheckableTag } = Tag;
const { Option } = Select;

interface Props {
  tractorId?: any;
  ticketObj?: any;
  Close: () => void;
  afterSave: () => void;
  toggleWidget: boolean;
  tractorVisable?: boolean;
}
const TicketsCreateEdit: React.FC<Props> = ({
  tractorId,
  ticketObj,
  Close,
  afterSave,
  toggleWidget,
  tractorVisable,
}: Props) => {
  const { TextArea } = Input;
  const { t } = useTranslation();
  const [editflag, seteditflag] = useState<boolean>(false);
  const [description, setDescription] = useState(ticketObj?.description);
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [equipment, setEquipment] = useState<any>();
  const [tagsList, setTagsList] = useState<any[]>([]);
  const [equipmentsList, setEquipmentsList] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [fileList, setFileList] = useState<any>([]);
  const [response, setResponse] = useState<any>();
  const [stateVarible, setStateVarible] = useState<any>(1);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [showDescriptionCount, setShowDescriptionCount] =
    useState<boolean>(false);
  const [descriptionCount, setDescriptionCount] = useState<number>(0);
  const [result, setResult] = useState<Taskviewdetails[]>([]);
  const [assignid, setAssignid] = useState<any>();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [currentIndex, setCurrentIndex] = useState<any>();
  const [uuidnum, setuuid] = useState<any>();
  const [tractorName, setTractorName] = useState<any>();
  const { APPReducer } = useContext(ApplicationContext);
  const [appState] = APPReducer;
  const { tractors } = appState;

  const mixpanel = useMixpanel();

  useEffect(() => {
    if (tractors && tractors.length > 0) {
      const [tractorinfo] = tractors.filter(
        (tractor: any) => tractor.id === tractorId,
      );

      setTractorName(tractorinfo?.name);
    }
  }, [tractors]);

  const onChange = (info: any) => {
    const { file, fileList } = info;
    file.status = 'done';
    fileList.map((ele: any, i: any) => {
      ele.index = i;
    });
    setFileList(fileList);
    mixpanel.track('TicketsCreateEdit', {
      event: 'Added Ticket Attachment',
    });
  };

  useEffect(() => {
    if (
      stateVarible > 0 &&
      description &&
      description.trim().length > 2 &&
      equipment
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [stateVarible, description, equipment]);
  useEffect(() => {
    if (userDetails && userDetails.organization) {
      loadTags();
      loadEquipments();
      getassigned();
    }
  }, [userDetails]);

  useEffect(() => {
    if (equipmentsList && equipmentsList.length > 0) {
      equipmentsList.map((equip: any, i: any) => {
        if (tractorName === equip.name) {
          setEquipment(equip.name);
          setuuid(i + 1);
        }
      });
    }
  }, [equipmentsList]);

  useEffect(() => {
    if (result && result.length > 0) {
      result.map((ele: any, i: any) => {
        if (ele?.id === ticketObj?.assignee_id || ele?.id === userDetails?.id) {
          setAssignid(ele?.id);
        }
      });
    }
  }, [result]);

  useEffect(() => {
    if (description !== undefined) setDescriptionCount(description.length);
    if (
      description !== undefined &&
      description.length >= 0 &&
      description.length <= 500
    ) {
      setShowDescriptionCount(true);
    } else {
      setShowDescriptionCount(false);
    }
  }, [description]);
  const loadTags = async () => {
    const resultTags: any = await getOrgTags(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );

    resultTags.map((tag: any) => {
      tag.selected = false;
    });

    setTagsList(resultTags);
  };
  useEffect(() => {
    if (response) {
      ticketattachment();
    }
  }, [response]);

  const ticketattachment = async () => {
    if (fileList.length > 0) {
      setLoader(true);
      const promiseall = fileList.map(async (ele: any, index: any) => {
        const formData = new FormData();
        formData.append('image', ele.originFileObj);

        const data = await ticketaddattachment(
          userDetails.organization.api_url,
          response?.fleet_id,
          response?.id,
          formData,
        );
        return data;
      });
      try {
        await Promise.all(promiseall);
        if (response.msg) {
          afterSave();
          Close();
        }
        mixpanel.track('TicketsCreateEdit', {
          event: 'Ticket Attachment Added Sucessfully',
        });
      } catch (e: any) {
        mixpanel.track('TicketsCreateEdit', {
          event: 'Ticket Attachment Failed',
        });
      }
      setLoader(false);
    } else if (response.msg) {
      afterSave();
      Close();
    }
  };

  const loadEquipments = async () => {
    setEquipmentsList([]);
    let result: any = await getOrgEquipments(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );
    result = result.filter(
      (equipment: any) => equipment.equipment_type !== 'None',
    );
    result = result.map((equip: any, i: any) => {
      const { name } = equip;
      equip.uuid = i + 1;
      equip.name = !speCharRegX?.test(name) ? encodeURIComponent(name) : name;
      return equip;
    });
    setEquipmentsList(result);
  };
  const getassigned = async () => {
    let res: any[] = [];
    const taskviewData: Taskviewdetails = await getTasksearch(
      userDetails.organization.api_url,
      '',
    );
    taskviewData.records.map((ele: Taskview) => {
      ele.fullname = ele.first_name + ' ' + ele.last_name;
    });
    res = taskviewData.records;
    setResult(res);
  };

  const saveTicket = async () => {
    const selTags: any[] = [];
    let equipmentType = '';
    let equipment_id: any = 0;

    try {
      setLoader(true);
      setIsDisabled(true);
      tagsList.map((tag: any) => {
        if (tag.selected) {
          selTags.push(tag.name);
        }
      });
      equipmentsList.map((equip: any) => {
        if (equip.uuid == uuidnum) {
          equipmentType = equip.equipment_type;
          equipment_id = equip.equipment_id;
        }
      });
      const obj: any = {
        ticket_description: description ? description.trim() : '',
        ticket_level: stateVarible,
        assignee_id: assignid,
        tags: selTags,
        equipment_id: equipment_id,
        equipment_type: equipmentType.trim(),
      };
      if (equipment_id !== 'NULL' && equipmentType.trim() !== 'BASESTATION') {
        obj.tractor_id = equipment_id;
      }
      const response: { msg: string } = await createdAddticket(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        obj,
      );
      setResponse(response);
      mixpanel.track('AddedTicket', {
        event: 'Ticket Added Sucessfully',
      });
      notification.success({
        message: t(translate.label.ticketCreated),
        duration: 2,
      });
    } catch (err: any) {
      mixpanel.track('AddedTicketFailed', {
        event: 'Ticket Added Failed',
      });
      notification.error({
        message: err.response?.data.error.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
      setIsDisabled(false);
    }
  };

  const equipmentSelect = (e: any) => {
    const raw = JSON.parse(e);
    equipmentsList.map((ele: any) => {
      if (ele.uuid === raw.uuid) {
        setuuid(ele.uuid);
        setEquipment(ele.name);
      }
    });

    mixpanel.track('TicketsCreateEdit', {
      event: `Select Equipment ${e}`,
    });
  };

  const handleChange = (tag: any, checked: any) => {
    tag.selected = checked;
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t: any) => t !== tag);
    setSelectedTags(nextSelectedTags);
    if (checked) {
      mixpanel.track('TicketsCreateEdit', {
        event: 'Select Tags',
      });
    } else {
      mixpanel.track('TicketsCreateEdit', {
        event: 'Remove Tags',
      });
    }
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const onPreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
    );
    setCurrentIndex(file.index);
  };
  const beforeUpload = (file: any) => {
    const imgSize = file.size / 1024 / 1024 < 8;
    const fileTypeAccepted =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg';
    if (!fileTypeAccepted) {
      notification.error({
        message: `${file.type} is not accepted`,
      });
      return Upload.LIST_IGNORE;
    }
    if (!imgSize) {
      notification.error({
        message: 'file size must be smaller than 8MB!',
      });
    }
    return imgSize || Upload.LIST_IGNORE || fileTypeAccepted;
  };

  const onSelect = (e: any) => {
    setAssignid(e);
  };

  const leftarrowClick = async () => {
    const leftindex = currentIndex + 1;

    setCurrentIndex(leftindex);
    setPreviewTitle(
      fileList[leftindex].name ||
        fileList[leftindex].url!.substring(
          fileList[leftindex].url!.lastIndexOf('/') + 1,
        ),
    );
    const preview = await getBase64(
      fileList[leftindex].originFileObj as RcFile,
    );
    setPreviewImage(fileList[leftindex].url || (preview as string));
  };

  const rightarrowClick = async () => {
    const rightindex = currentIndex - 1;

    setCurrentIndex(rightindex);
    setPreviewTitle(
      fileList[rightindex].name ||
        fileList[rightindex].url!.substring(
          fileList[rightindex].url!.lastIndexOf('/') + 1,
        ),
    );
    const preview = await getBase64(
      fileList[rightindex].originFileObj as RcFile,
    );
    setPreviewImage(fileList[rightindex].url || (preview as string));
  };
  const handleCancel = () => setPreviewOpen(false);

  useEffect(() => {
    if (ticketObj?.id) {
      let tdesc = '';
      if (ticketObj?.error_code) tdesc += `${ticketObj?.error_code} `;
      if (ticketObj?.subsystem) tdesc += `# ${ticketObj?.subsystem} `;
      if (ticketObj?.description) tdesc += `, ${ticketObj?.description}`;
      setDescription(tdesc);
      setStateVarible(ticketObj?.severity);
    }
  }, [ticketObj]);

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn">
          <img
            id={applicationIds.ticketScreen.ticketCloseBtn}
            src={closeGreen}
            alt="close icon"
            onClick={Close}
          />
        </div>

        {!editflag && (
          <div className="profileEdit rightWidgetView ticketWidgetView">
            <h3
              className="createTcktTitle headline1 rowGapBtm3"
              style={{ textAlign: 'center' }}
            >
              Create A Ticket
            </h3>
            <div className="proRowType rowGapBtm3">
              <h5 className="subFlex flexBtn">
                <span className="subTitles fSbd overline1">Severity Level</span>
                <span className="createTcktFlag">
                  <div className="subFlex">
                    {Array.from({ length: 3 }).map((item, key) => {
                      return (
                        <div
                          key={key}
                          onClick={() => {
                            stateVarible === 1 && key === 0
                              ? setStateVarible(1)
                              : setStateVarible(key + 1);
                            mixpanel.track('TicketsCreateEdit', {
                              event: `Select Severity ${key + 1}`,
                            });
                          }}
                        >
                          {stateVarible === 0 && (
                            <img
                              className={key !== 0 ? '' : 'ml_0'}
                              src={FlagGrayIcon}
                              data-testid="selectSeverityLevel-TicketCreateEdit"
                            />
                          )}
                          {stateVarible === 1 && (
                            <img
                              className={key !== 0 ? '' : 'ml_0'}
                              src={key === 0 ? FlagOrangeIcon : FlagGrayIcon}
                            />
                          )}
                          {stateVarible === 2 && (
                            <img
                              className={key !== 0 ? '' : 'ml_0'}
                              src={key !== 2 ? FlagOrangeIcon : FlagGrayIcon}
                            />
                          )}
                          {stateVarible === 3 && (
                            <img
                              className={key !== 0 ? '' : 'ml_0'}
                              src={FlagRedIcon}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </span>
              </h5>
            </div>

            <div className="rowGapBtm3">
              <Select
                style={{ width: '100%' }}
                placeholder="Equipment"
                value={equipment}
                disabled={tractorVisable}
                data-testid="selectTractorDropdown-TicketCreateEdit"
                onSelect={equipmentSelect}
              >
                {equipmentsList?.map((data: any, i: number) => {
                  return (
                    <Option
                      value={JSON.stringify(data)}
                      key={i}
                      data-testid={`${data.name}-Tickets`}
                    >
                      {data.name}
                    </Option>
                  );
                })}
              </Select>
            </div>

            <div className="rowGapBtm3">
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Assigned To"
                value={assignid}
                data-testid="AssignedToDropdown-TicketCreateEdit"
                onChange={onSelect}
                optionFilterProp="children"
                filterOption={(input: any, option: any) => {
                  return (
                    option.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {result.map((ele: any) => (
                  <Option
                    title={ele.fullname}
                    key={ele.id}
                    value={ele.id}
                    data-testid={`${ele.fullname}-TicketViewEdit`}
                  >
                    {ele.fullname}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="rowGapBtm3">
              <h4 className="fSbd overline1">Description</h4>
              <TextArea
                className={`ticketArea ${
                  descriptionCount >= 1 && descriptionCount < 3
                    ? 'newTicket'
                    : ''
                }`}
                placeholder="Enter description here"
                data-testid="ticketDescriptionInputField-TicketCreateEdit"
                autoSize={{ minRows: 5, maxRows: 8 }}
                defaultValue={description}
                maxLength={500}
                onChange={(e: any) => setDescription(e.target.value)}
              />
              <span
                style={{
                  color:
                    descriptionCount >= 1 && descriptionCount < 3
                      ? '#ff0000d1'
                      : '',
                  float: 'right',
                }}
                className="descCount"
              >
                {description?.length === undefined ||
                description?.length === null ||
                description?.length === ''
                  ? 0
                  : description?.length}{' '}
                / 500
              </span>
              {/* </h4> */}
            </div>
            <div className="tagsSec rowGapBtm3">
              <h4 className="fSbd overline1">Tags</h4>
              <div className="tagsBlk" data-testid="selectTag-TicketCreateEdit">
                {tagsList?.map((data: any) => {
                  return (
                    <CheckableTag
                      className="checktag body3"
                      key={data.id}
                      checked={selectedTags.indexOf(data) > -1}
                      onChange={(checked: any) => handleChange(data, checked)}
                      data-testid={`${data.name}-Tag-TicketCreateEdit`}
                    >
                      {data.name}
                    </CheckableTag>
                  );
                })}
              </div>
            </div>

            <div className="attachemtBlk rowGapBtm3 ticketsAttachementBlk attachIcons">
              <h4 className="fSbd overline1">
                Attachments ({fileList.length})
              </h4>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                onChange={onChange}
                onPreview={onPreview}
                beforeUpload={beforeUpload}
                maxCount={5}
                accept=".jpg,.png"
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                openFileDialogOnClick={fileList.length != 5 ? true : false}
              >
                {fileList.length != 5 ? (
                  <Button className="uploadBtn">
                    <PlusOutlined />
                  </Button>
                ) : (
                  <Button className="uploadBtn-disabled" disabled>
                    <PlusOutlined />
                  </Button>
                )}
              </Upload>
            </div>
            <div className="edit-loader">
              {loader && (
                <div className="loader">
                  <Spin size="large" />
                </div>
              )}
            </div>
            <div className="proeditBtn tcktSaveBtn createTicketSaveBtn saveTxtBtn">
              <Button
                id={applicationIds.ticketScreen.saveTicket}
                onClick={saveTicket}
                disabled={isDisabled}
                data-testid="saveButton-TicketCreateEdit"
              >
                Save
              </Button>
            </div>
          </div>
        )}
        <Modal
          visible={previewOpen}
          title={previewTitle}
          footer={null}
          maskClosable={false}
          onCancel={handleCancel}
          className="previewModal"
        >
          <div className="previewmodalBody">
            {fileList.length - 1 !== currentIndex && (
              <ArrowLeftOutlined onClick={leftarrowClick} />
            )}
            {previewImage && (
              <img
                alt="example"
                className="img-responsive"
                src={previewImage}
                width="100%"
              />
            )}
            {currentIndex !== 0 && (
              <ArrowRightOutlined onClick={rightarrowClick} />
            )}
          </div>
        </Modal>
      </div>
    </Layout>
  );
};

export default TicketsCreateEdit;
