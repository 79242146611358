import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import './style.css';
import pathPR from '../../assets/images/pathPR.svg';
import ptoPR from '../../assets/images/ptoPR.svg';
import humanDR from '../../assets/images/humanDR.svg';
import translate from '../../locale/en_translate.json';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

interface Props {
  triggeredGuardRail: string;
  handleIgnore: () => void;
  resize: boolean;
}

export const GuardRailDetection: React.FC<Props> = ({
  triggeredGuardRail,
  handleIgnore,
  resize,
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  // const [description, setdescription] = useState('');
  const [toggle, setToggle] = useState(true);
  const [minimize, setMinimize] = useState(false);
  const [image, setImage] = useState('');
  const body = useRef('');

  useEffect(() => {
    if (triggeredGuardRail === 'path protection') {
      setImage(pathPR);
      setTitle('Obstructed Path');
    } else if (triggeredGuardRail === 'pto protection') {
      setImage(ptoPR);
      setTitle('PTO Danger');
    } else if (triggeredGuardRail === 'human protection') {
      setImage(humanDR);
      setTitle('Human Presence');
    }
  }, [triggeredGuardRail]);

  useEffect(() => {
    body.current = toggle
      ? `Tractor has come to a stop because of an obstruction in its
    path. Operation will resume when resolved`
      : `Confirm that it is safe for the tractor to ignore ${title}
                alert.`;
  }, [toggle]);

  return (
    <div
      className={
        resize ? 'guardrails-modal guardrails-modal-mini' : 'guardrails-modal'
      }
    >
      {!minimize ? (
        <>
          <div
            className="guardrails-close-icon"
            onClick={() => setMinimize(!minimize)}
          >
            <MinusOutlined />
          </div>
          <div>
            <img
              src={image}
              alt="pathPR"
              style={{
                position: 'absolute',
              }}
            />
            <div style={{ marginLeft: '60px' }}>
              <h3 className="">{title}</h3>
              <p className="">
                {/* Tractor has come to a stop because of an obstruction in its
                path. Operation will resume when resolved. */}
                {body.current}
              </p>
            </div>
            <div className="buttonContainer2">
              {toggle ? (
                <button
                  className="btnContinue"
                  type="button"
                  onClick={() => {
                    setToggle(!toggle);
                  }}
                >
                  <span>{t(translate.buttons.continue)}</span>
                </button>
              ) : (
                <>
                  <button
                    className="btnControl"
                    type="button"
                    onClick={() => {
                      setToggle(!toggle);
                    }}
                  >
                    <span>{t(translate.buttons.cancel)}</span>
                  </button>
                  <button
                    className="btnContinue"
                    type="button"
                    onClick={handleIgnore}
                  >
                    <span>{t(translate.buttons.confirm)}</span>
                  </button>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="guardrails-open-icon"
            onClick={() => {
              setToggle(false);
              setMinimize(!minimize);
            }}
          >
            <PlusOutlined />
          </div>
          <img
            src={image}
            alt="pathPR"
            style={{
              position: 'absolute',
              height: '30px',
            }}
          />
          <div style={{ marginLeft: '30px' }}>
            <h3 className="">{title}</h3>
          </div>
        </>
      )}
    </div>
  );
};
