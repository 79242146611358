import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Table } from 'antd';
import translate from '../../locale/en_translate.json';
import loading_logo from '../../assets/images/loading_logo.gif';
import no_ticket_icon from '../../assets/images/no_ticket_icon.svg';
import { handleSelectedClass } from '../../constants/Common';

interface Props {
  columns: Array<any>;
  filterData: Array<any>;
  handleLoadMore: () => void;
  hasMore: boolean;
  filename: string;
  totalcount?: any;
  //   handleChange: (page: pagenation) => void;
  onRowClick?: (record: any) => void;
  onChange?: any;
  loading?: boolean;
  xScroll?: number;
}

const InfiniteScrollTable: React.FC<Props> = ({
  filterData,
  handleLoadMore,
  hasMore,
  columns,
  filename,
  onRowClick,
  totalcount,
  onChange,
  loading,
  xScroll,
}) => {
  return (
    <>
      <InfiniteScroll
        scrollThreshold={0.8}
        height={window.innerHeight - 240}
        dataLength={filterData.length} // This is important field to render the next data
        next={() => handleLoadMore()}
        hasMore={hasMore}
        loader={
          <></>
          // <div style={{ width: '100%', textAlign: 'center' }}>
          //   <p>Loading...</p>
          // </div>
        }
      >
        <Table
          scroll={{ x: xScroll }}
          pagination={false}
          // rowClassName={() => 'rowClassName1'}
          className="table-borderless tablecls"
          id="empTbl"
          dataSource={filterData}
          columns={columns}
          sticky
          loading={{
            indicator: (
              <img
                style={{ width: '60px', height: '36px' }}
                src={loading_logo}
              />
            ),
            spinning: loading,
          }}
          onRow={(record: any) => ({
            onClick: (e) => {
              onRowClick && onRowClick(record);
              handleSelectedClass(e);
            },
          })}
          rowKey={(record: any) => record.id || Math.random()}
          onChange={onChange}
          // scroll={{ x: xScroll ?? 990 }}
          rowClassName={(record: any) => 'rowClassName1 row-' + record.id}
          locale={{
            emptyText: !loading ? (
              <div className="tableEmpty">
                {filename === 'Tickets' && (
                  <img src={no_ticket_icon} height="168" />
                )}
                <h4 className="tableEmptyTxt overline1">
                  NO CURRENT {filename.toLocaleUpperCase()}
                </h4>
              </div>
            ) : (
              <></>
            ),
          }}
        />
      </InfiniteScroll>
      <div id="total">
        {!isNaN(totalcount) ? (
          <span>
            <b>{`${translate.headers.total}: `}</b>
            {`${totalcount}
                 ${
                   Number(totalcount) > 1 || Number(totalcount) === 0
                     ? filename.toLowerCase()
                     : filename.substr(0, filename.length - 1).toLowerCase()
                 }`}
          </span>
        ) : null}
      </div>
    </>
  );
};

export default InfiniteScrollTable;
