/* eslint-disable no-console */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Card, Row, Space, Button, Tooltip, Spin } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import './style.css';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { getDateTime, sortCameraViews } from '../../constants/Common';
import { getVideoURL } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { AnalyticsContext } from '../fleetusage/AnalyticsContext';
import RoutesConstants from '../../routes/RoutesConstant';
import moment from 'moment';
import { ExpandOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useMixpanel } from 'react-mixpanel-browser';
import VedioThumbnailModal from '../fleetusage/VedioThumbnailModal';

interface Props {
  videoData: any;
}
const MediaWidget: React.FC<Props> = ({ videoData }: Props) => {
  const mixpanel = useMixpanel();
  const { userDetails } = useContext(ApplicationContext);
  const { JAG, videoTime } = useContext(AnalyticsContext);
  const [videos, setVideos] = useState<any>([]);
  const [tractor_id, setTractorId] = useState<number>(0);
  const [updatedTime, setUpdatedTime] = useState<number>(0);
  const frontRef: any = useRef();
  const rearRef: any = useRef();
  const driverRef: any = useRef();
  const ptoRef: any = useRef();
  const frontLeft: any = useRef();
  const frontRight: any = useRef();
  const rearLeft: any = useRef();
  const rearRight: any = useRef();
  const curTime: any = useRef();
  const fixTime: any = useRef();
  const [frontflag, setfrontFlag] = useState<boolean>();
  const [rearflag, setrearFlag] = useState<boolean>();
  const [ptoflag, setptoFlag] = useState<boolean>();
  const [frontleftflag, setfrontleftFlag] = useState<boolean>();
  const [frontrightflag, setfrontrightFlag] = useState<boolean>();
  const [rearleftflag, setrearleftflag] = useState<boolean>();
  const [rearrightflag, setrearrightflag] = useState<boolean>();
  const [play, setPlay] = useState(true);
  const [duration, setDuration] = useState<any>();
  const [cameras, setCameras] = useState<string[]>([]);
  const [vediosModal, setVediosModal] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>();

  const faces = [
    frontRef,
    rearRef,
    ptoRef,
    frontLeft,
    frontRight,
    rearRight,
    rearLeft,
  ];
  const NotavalibleMethod = () => {
    setfrontFlag(true);
    setrearFlag(true);
    setptoFlag(true);
    setfrontleftFlag(true);
    setfrontrightFlag(true);
    setrearleftflag(true);
    setrearrightflag(true);
  };

  const mapVideoTag = (face: any, data: any) => {
    if (face.current) face.current.src = data.video_url;
  };

  const mapAction = (action: string) => {
    if (frontRef.current && frontRef.current.src) {
      frontRef.current[action]();
    }
    if (rearRef.current && rearRef.current.src) {
      rearRef.current[action]();
    }
    if (driverRef.current && driverRef.current.src) {
      driverRef.current[action]();
    }
    if (ptoRef.current && ptoRef.current.src) {
      ptoRef.current[action]();
    }
    if (frontLeft.current && frontLeft.current.src) {
      frontLeft.current[action]();
    }
    if (frontRight.current && frontRight.current.src) {
      frontRight.current[action]();
    }
    if (rearLeft.current && rearLeft.current.src) {
      rearLeft.current[action]();
    }
    if (rearRight.current && rearRight.current.src) {
      rearRight.current[action]();
    }
  };

  const resetTags = () => {
    faces.map((face) => face.current && face.current.src);
    curTime.current.innerHTML = 0;
    fixTime.current.innerHTML = 0;
  };

  const mapVideos = (videos: any) => {
    resetTags();
    if (videos && videos.length > 0) {
      videos
        .filter((data: any) => cameras.includes(data.direction))
        .map((data: any) => {
          switch (data.direction) {
            case 'front':
              mapVideoTag(frontRef, data);
              break;
            case 'rear':
              mapVideoTag(rearRef, data);
              break;
            case 'pto':
              mapVideoTag(ptoRef, data);
              break;
            case 'driver':
              mapVideoTag(driverRef, data);
              break;
            case 'front-left':
              mapVideoTag(frontLeft, data);
              break;
            case 'rear-left':
              mapVideoTag(rearLeft, data);
              break;
            case 'front-right':
              mapVideoTag(frontRight, data);
              break;
            case 'rear-right':
              mapVideoTag(rearRight, data);
              break;
            default:
              break;
          }
        });
    }
  };

  useEffect(() => {
    if (videoData && videoData.length > 0) setVideos(videoData);
  }, [videoData]);

  useEffect(() => {
    if (videos && videos.length > 0) {
      mapVideos(videos);
    }
  }, [videos]);

  const processDates = (dates: any[]) =>
    dates.map((date: any) => {
      date = new Date(date);
      date.setSeconds(0);
      date.setMilliseconds(0);
      // let minutes = moment(date).minutes();
      // minutes =
      //   moment(date).toDate().getMinutes() % 5 >= 2.5
      //     ? Math.ceil(moment(date).toDate().getMinutes() / 5) * 5
      //     : Math.floor(moment(date).toDate().getMinutes() / 5) * 5;
      // date.setMinutes(minutes);
      return date.getTime();
    });

  useEffect(() => {
    if (JAG) {
      setTractorId(JAG['tractor']['id']);
      const startDate: any = JAG['drive_action_start_date_time'];
      const endDate: any = JAG['drive_action_finish_date_time'];
      const dates = processDates([startDate, endDate]);
      setDuration(dates);
      setUpdatedTime(dates[0]);
    } else {
      setVideos([]);
    }
  }, [JAG]);

  useEffect(() => {
    if (JAG && videoTime) setUpdatedTime(videoTime);
  }, [videoTime]);

  useEffect(() => {
    if (userDetails && userDetails.organization && updatedTime && tractor_id) {
      NotavalibleMethod();
      setVideos([]);
      getVideoData();
    }
  }, [userDetails, updatedTime]);

  const getVideoData = async () => {
    try {
      setShowLoader(true);
      let response = await getVideoURL(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        tractor_id,
        updatedTime,
      );
      response = sortCameraViews(response, 'direction', 'videos');
      const cams = response.reduce(
        (a: any, data: any) => [...a, data.direction],
        [],
      );
      setCameras(cams);
      setVideos(response);
    } finally {
      setShowLoader(false);
    }
  };

  const playVideo = () => {
    if (play) {
      mapAction('play');
      setPlay(false);
      mixpanel.track('Recorded Video', {
        event: `All Videos Played`,
      });
    } else {
      mapAction('pause');
      setPlay(true);
      mixpanel.track('Recorded Video', {
        event: `All Videos Paused`,
      });
    }
  };

  // const video: any = document.querySelector('video');
  const progress: any = document.querySelector('.progress');
  const progressBar: any = document.querySelector('.progress__filled');

  const currentTime = (video: any) => {
    const currentMinutes = Math.floor(video.currentTime / 60);
    const currentSeconds = Math.floor(video.currentTime - currentMinutes * 60);
    const durationMinutes = Math.floor(video.duration / 60);
    const durationSeconds = Math.floor(video.duration - durationMinutes * 60);
    curTime.current.innerHTML = `0${currentMinutes}: ${
      currentSeconds < 10 ? '0' + currentSeconds : currentSeconds
    }`;
    if (durationMinutes) {
      fixTime.current.innerHTML = `0${durationMinutes}: ${
        durationSeconds < 10 ? '0' + durationSeconds : durationSeconds
      }`;
    }
  };

  const handleProgress = () => {
    try {
      if (frontRef.current && frontRef.current.src) {
        const percent =
          (frontRef.current.currentTime / frontRef.current.duration) * 100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(frontRef.current);
      }

      if (rearRef.current && rearRef.current.src) {
        const percent =
          (rearRef.current.currentTime / rearRef.current.duration) * 100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(rearRef.current);
      }

      if (driverRef.current && driverRef.current.src) {
        const percent =
          (driverRef.current.currentTime / driverRef.current.duration) * 100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(driverRef.current);
      }

      if (ptoRef.current && ptoRef.current.src) {
        const percent =
          (ptoRef.current.currentTime / ptoRef.current.duration) * 100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(ptoRef.current);
      }
      if (frontLeft.current && frontLeft.current.src) {
        const percent =
          (frontLeft.current.currentTime / frontLeft.current.duration) * 100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(frontLeft.current);
      }

      if (frontRight.current && frontRight.current.src) {
        const percent =
          (frontRight.current.currentTime / frontRight.current.duration) * 100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(frontRight.current);
      }

      if (rearLeft.current && rearLeft.current.src) {
        const percent =
          (rearLeft.current.currentTime / rearLeft.current.duration) * 100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(rearLeft.current);
      }

      if (rearRight.current && rearRight.current.src) {
        const percent =
          (rearRight.current.currentTime / rearRight.current.duration) * 100;
        progressBar.style.flexBasis = `${percent}%`;
        currentTime(rearRight.current);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const barHandling = (e: any) => {
    try {
      if (frontRef.current && frontRef.current.src) {
        frontRef.current.currentTime =
          (e.offsetX / progress.offsetWidth) * frontRef.current.duration;
      }

      if (rearRef.current && rearRef.current.src) {
        rearRef.current.currentTime =
          (e.offsetX / progress.offsetWidth) * rearRef.current.duration;
      }

      if (driverRef.current && driverRef.current.src) {
        driverRef.current.currentTime =
          (e.offsetX / progress.offsetWidth) * driverRef.current.duration;
      }

      if (ptoRef.current && ptoRef.current.src) {
        ptoRef.current.currentTime =
          (e.offsetX / progress.offsetWidth) * ptoRef.current.duration;
      }

      if (frontLeft.current && frontLeft.current.src) {
        frontLeft.current.currentTime =
          (e.offsetX / progress.offsetWidth) * frontLeft.current.duration;
      }

      if (frontRight.current && frontRight.current.src) {
        frontRight.current.currentTime =
          (e.offsetX / progress.offsetWidth) * frontRight.current.duration;
      }

      if (rearLeft.current && rearLeft.current.src) {
        rearLeft.current.currentTime =
          (e.offsetX / progress.offsetWidth) * rearLeft.current.duration;
      }

      if (rearRight.current && rearRight.current.src) {
        rearRight.current.currentTime =
          (e.offsetX / progress.offsetWidth) * rearRight.current.duration;
      }
      // handleProgress();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  // video?.addEventListener('timeupdate', handleProgress);
  progress?.addEventListener('click', barHandling);

  const openFullscreen = (videoUrl: any) => {
    mixpanel.track('Recorded Video', {
      event: `${videoUrl} Video Expanded`,
    });
    videoUrl &&
      window.open(
        `${RoutesConstants.VideoPop}?videoUrl=${videoUrl}`,
        `Video URL`,
        'max-width: 100%',
      );
  };
  const rightArrowClick = () => {
    if (duration[1] >= moment(updatedTime).add(5, 'minutes').valueOf()) {
      setUpdatedTime((prev: any) => {
        return moment(prev).add(5, 'minutes').valueOf();
      });
    }
  };

  const leftArrowClick = () => {
    if (duration[0] <= moment(updatedTime).subtract(5, 'minutes').valueOf()) {
      setUpdatedTime((prev: any) => {
        return moment(prev).subtract(5, 'minutes').valueOf();
      });
    }
  };

  const ErrorMethod = (e: any, action: any) => {
    switch (action) {
      case 'front':
        setfrontFlag(false);
        break;
      case 'rear':
        setrearFlag(false);
        break;
      case 'pto':
        setptoFlag(false);
        break;
      case 'front-left':
        setfrontleftFlag(false);
        break;
      case 'rear-left':
        setrearleftflag(false);
        break;
      case 'front-right':
        setfrontrightFlag(false);
        break;
      case 'rear-right':
        setrearrightflag(false);
        break;
    }
  };
  const NOVIDEO = (
    <>{!showLoader && <div className="noVideofile">No Video Available</div>}</>
  );

  return (
    <>
      <Tooltip title="Full Screen">
        <ExpandOutlined
          className="fullScreenVideoThumb vExp"
          onClick={() => setVediosModal(true)}
        />
      </Tooltip>
      <Layout>
        <div className="mainContent">
          <Content>
            <Row>
              <Card
                //   title={tractorName}
                bordered={true}
                className="dbWidget videoDbCon"
                extra={
                  <Space
                    className="mt0"
                    style={{ marginBottom: 0, position: 'relative' }}
                  >
                    <p
                      style={{ fontFamily: 'Montserrat-Medium', fontSize: 13 }}
                    >
                      <a
                        style={{ fontWeight: 'bold' }}
                        onClick={leftArrowClick}
                      >
                        <LeftOutlined />
                      </a>{' '}
                      <span>{updatedTime && getDateTime(updatedTime)}</span>
                      <a
                        style={{ fontWeight: 'bold' }}
                        onClick={rightArrowClick}
                      >
                        {' '}
                        <RightOutlined />
                      </a>
                    </p>
                  </Space>
                }
              >
                <div className="snapContainer fullWidth vediocontiner mediaContainer">
                  <div className="videoSnaps">
                    <p className="snapText">Front Camera</p>
                    {cameras.includes('front') && frontflag ? (
                      <>
                        <video
                          className="video"
                          width="100%"
                          height="100%"
                          ref={frontRef}
                          controls
                          onTimeUpdate={handleProgress}
                          onError={(e: any) => ErrorMethod(e, 'front')}
                        ></video>
                        <ExpandOutlined
                          className="fullScreenVideo"
                          onClick={() => openFullscreen(frontRef.current.src)}
                        />
                      </>
                    ) : (
                      NOVIDEO
                    )}
                  </div>

                  <div className="videoSnaps">
                    <p className="snapText">Rear Camera</p>
                    {cameras.includes('rear') && rearflag ? (
                      <>
                        <video
                          className="video"
                          width="100%"
                          height="100%"
                          ref={rearRef}
                          controls
                          onTimeUpdate={handleProgress}
                          onError={(e: any) => ErrorMethod(e, 'rear')}
                        ></video>
                        <ExpandOutlined
                          className="fullScreenVideo"
                          onClick={() => openFullscreen(rearRef.current.src)}
                        />
                      </>
                    ) : (
                      NOVIDEO
                    )}
                  </div>

                  <div className="videoSnaps" style={{ display: 'none' }}>
                    <p className="snapText">Driver Camera</p>
                    <video
                      className="video"
                      width="100%"
                      height="100%"
                      ref={driverRef}
                      controls
                      onTimeUpdate={handleProgress}
                    ></video>
                    <ExpandOutlined
                      className="fullScreenVideo"
                      onClick={() => openFullscreen(driverRef.current.src)}
                    />
                  </div>

                  <div className="videoSnaps">
                    <p className="snapText">PTO Camera</p>
                    {cameras.includes('pto') && ptoflag ? (
                      <>
                        <video
                          className="video"
                          width="100%"
                          height="100%"
                          ref={ptoRef}
                          controls
                          onTimeUpdate={handleProgress}
                          onError={(e: any) => ErrorMethod(e, 'pto')}
                        ></video>
                        <ExpandOutlined
                          className="fullScreenVideo"
                          onClick={() => openFullscreen(ptoRef.current.src)}
                        />
                      </>
                    ) : (
                      NOVIDEO
                    )}
                  </div>

                  <div className="videoSnaps">
                    <p className="snapText">Front Left Camera</p>
                    {cameras.includes('front-left') && frontleftflag ? (
                      <>
                        <video
                          className="video"
                          width="100%"
                          height="100%"
                          ref={frontLeft}
                          controls
                          onTimeUpdate={handleProgress}
                          onError={(e: any) => ErrorMethod(e, 'front-left')}
                        ></video>
                        <ExpandOutlined
                          className="fullScreenVideo"
                          onClick={() => openFullscreen(frontLeft.current.src)}
                        />
                      </>
                    ) : (
                      NOVIDEO
                    )}
                  </div>

                  <div className="videoSnaps">
                    <p className="snapText">Front Right Camera</p>
                    {cameras.includes('front-right') && frontrightflag ? (
                      <>
                        <video
                          className="video"
                          width="100%"
                          height="100%"
                          ref={frontRight}
                          controls
                          onTimeUpdate={handleProgress}
                          onError={(e: any) => ErrorMethod(e, 'front-right')}
                        ></video>
                        <ExpandOutlined
                          className="fullScreenVideo"
                          onClick={() => openFullscreen(frontRight.current.src)}
                        />
                      </>
                    ) : (
                      NOVIDEO
                    )}
                  </div>

                  <div className="videoSnaps">
                    <p className="snapText">Rear Left Camera</p>
                    {cameras.includes('rear-left') && rearleftflag ? (
                      <>
                        <video
                          className="video"
                          width="100%"
                          height="100%"
                          ref={rearLeft}
                          controls
                          onTimeUpdate={handleProgress}
                          onError={(e: any) => ErrorMethod(e, 'rear-left')}
                        ></video>
                        <ExpandOutlined
                          className="fullScreenVideo"
                          onClick={() => openFullscreen(rearLeft.current.src)}
                        />
                      </>
                    ) : (
                      NOVIDEO
                    )}
                  </div>

                  <div className="videoSnaps">
                    <p className="snapText">Rear Right Camera</p>
                    {cameras.includes('rear-right') && rearrightflag ? (
                      <>
                        <video
                          className="video"
                          width="100%"
                          height="100%"
                          ref={rearRight}
                          controls
                          onTimeUpdate={handleProgress}
                          onError={(e: any) => ErrorMethod(e, 'rear-right')}
                        ></video>
                        <ExpandOutlined
                          className="fullScreenVideo"
                          onClick={() => openFullscreen(rearRight.current.src)}
                        />
                      </>
                    ) : (
                      NOVIDEO
                    )}
                  </div>
                </div>
                {videos && videos.length > 0 && (
                  <div className="barDisplay">
                    <Button
                      style={{ display: 'block' }}
                      className="okBtn"
                      onClick={() => playVideo()}
                    >
                      {play ? <CaretRightOutlined /> : <PauseOutlined />}
                    </Button>

                    <div className="progress">
                      <div className="progress__filled"></div>
                    </div>
                    <div
                      style={{
                        width: '140px',
                        fontFamily: 'Montserrat-SemiBold',
                      }}
                    >
                      <span ref={curTime}>00:00</span> /{' '}
                      <span ref={fixTime}>00:00</span>
                    </div>
                  </div>
                )}
              </Card>
            </Row>
          </Content>
        </div>
        {updatedTime && vediosModal && (
          <VedioThumbnailModal
            showModal={vediosModal}
            setVediosModal={setVediosModal}
            UpdatedTime={updatedTime}
          ></VedioThumbnailModal>
        )}
      </Layout>
    </>
  );
};

export default MediaWidget;
