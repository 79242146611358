/* eslint-disable no-console */
import {
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { AutoComplete, Button, Input, Select, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import fleet_implement_new from '../../assets/images/hitch_implement_gray.svg';
import {
  checkAlphaFeature,
  commands,
  getImplementName,
  isHaveErrors,
  isHaveErrorsForRoutineFlow,
} from '../../constants/Common';
import { MapFeature, TractorHeartBeat } from '../../constants/types';
import { SET_TRACTOR_IMPLEMENT_STATE } from '../../context/actions';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import {
  AUTO_DRIVE_ERROR,
  SELECTED_ITEM_NAME,
  SELECTED_ROUTINE,
  SELECTED_TRACTORS,
  SET_ADD_STEP,
  SET_AUTO_DRIVE_POP,
  SET_AUTO_VALUE,
  SET_SETUP_KEYS,
  SET_SHOW_ROUTINE,
  SHOW_TRACTOR_START,
} from './actions';
import AutoDriveErrorCodePopup from './AutoDriveErrorCodePopup';
import { getIndicatorIcons } from './common';
import ImplementDetail from './ImplementDetail';
import { RemoteDriveAppCtx } from './RemoteDriveContext';

const { Option } = Select;

interface Props {
  addRVCommand: () => void;
  addRowFollowRVCommand: () => void;
  preCommandState: string;
  disConnectConnection: (heartbeat: TractorHeartBeat) => void;
  handleResetCommand: (state: string) => void;
  recenterItem: (item: MapFeature) => void;
  recenterXY: (xy: any) => void;
  clearPreviewPath: (id: number) => void;
}
const AutoDriveRoutine: React.FC<Props> = ({
  disConnectConnection,
  handleResetCommand,
  preCommandState,
  addRVCommand,
  addRowFollowRVCommand,
  recenterItem,
  recenterXY,
  clearPreviewPath,
}) => {
  const [showImplement, setShowImplement] = useState<boolean>(false);
  const [options, setOptions] = useState<any>();
  const {
    RDReducer,
    commandsList,
    clearAllLocalDBCommands,
    clearAll1,
    startAutoDriveIteration,
    waypointsForRemoteAv,
    greenPolygons,
    setGoToRowFollowPolygon,
    setGoToPointCoordinates,
  } = useContext(RemoteDriveAppCtx);
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState, autoDriveDispatch] = AutoDriveReducer;
  const [state, dispatch] = RDReducer;
  const { tractorImplementState } = autoDriveState;
  const {
    selectedTractor,
    showRoutine,
    selectedRoutine,
    autoDriveError,
    autoDriveSettings,

    showSaveCommand,
    setUpKeys,
    commandType,
    selectedItemName,
    selectedVineBlockName,
    addStep,
    autoValue,
    showTractorStart,
  } = state;

  const onSelect = (data: string) => {
    dispatch({
      type: SET_AUTO_VALUE,
      payload: data,
    });
    if (data) {
      if (waypointsForRemoteAv && waypointsForRemoteAv.length > 0) {
        waypointsForRemoteAv.map((tractor: MapFeature) => {
          if (tractor && tractor.name && tractor.name === data) {
            recenterItem(tractor);
            recenterXY(tractor.coordinates);
            setGoToPointCoordinates([]);
            setGoToPointCoordinates(tractor.coordinates);
            dispatch({
              type: SELECTED_ITEM_NAME,
              payload: tractor.name,
            });
          }
        });
      }
      if (greenPolygons && greenPolygons.length > 0) {
        greenPolygons.map((greenPolygon: MapFeature) => {
          if (greenPolygon && greenPolygon.name && greenPolygon.name === data) {
            recenterItem(greenPolygon);
            setGoToRowFollowPolygon(null);
            setGoToRowFollowPolygon(greenPolygon);
          }
        });
      }
    }
  };

  const onSearch = (searchText: string) => {
    if (searchText) {
      const objects: { value: string }[] = [];
      waypointsForRemoteAv.forEach((value: MapFeature) => {
        if (
          value &&
          value.name &&
          value.name.toLowerCase().includes(searchText.toLowerCase())
        ) {
          objects.push({
            value: value.name,
          });
        }
      });
      greenPolygons.forEach((value: MapFeature) => {
        if (
          value &&
          value.name &&
          value.name.toLowerCase().includes(searchText.toLowerCase())
        ) {
          objects.push({
            value: value.name,
          });
        }
      });
      setOptions(objects);
    } else {
      setOptions([]);
    }
  };

  if (showImplement)
    return <ImplementDetail setShowImplement={setShowImplement} />;

  return (
    <div className="rautoDriveRightSec">
      <div className="auto-drive-routine-container w100per">
        <div className="adr-title autoDHead">
          <h2 className="autoDHeadText headline1">Autodrive Routine</h2>
        </div>
        <div
          className={`adr-content-block ${
            isHaveErrors(autoDriveError) && 'adr-content-block-border'
          }`}
        >
          <div className="adr_row p30">
            <div className="adrt_name Button">{selectedTractor?.name}</div>
            <span className="adrArrow"></span>
          </div>
          <div className="adr_row plr30 bdrB mb40">
            <div className="adrConnection driveRoutineFlex">
              {getIndicatorIcons(selectedTractor)}
            </div>
            {/* allow to verify the implement */}
            {selectedTractor?.heartbeat?.drive_action_details && (
              <div
                className="adrImplement"
                onClick={() => setShowImplement(true)}
                style={{ cursor: 'pointer' }}
              >
                <>
                  <div className="implement-txt">
                    <img src={fleet_implement_new} alt="" />

                    <div className="adr_implementTxt overline2">
                      {getImplementName(selectedTractor)}
                    </div>
                    <div className="adrEdit">
                      {tractorImplementState[selectedTractor.id] &&
                        getImplementName(selectedTractor) &&
                        'Edit'}
                    </div>
                  </div>
                  <span className="implement-verify-error">
                    {!tractorImplementState[selectedTractor.id] &&
                      'Click here to Verify'}{' '}
                  </span>
                </>
              </div>
            )}
          </div>
          {!(
            commandsList &&
            commandsList[selectedTractor.id] &&
            commandsList[selectedTractor.id].length > 0
          ) && (
            <>
              {!addStep && preCommandState === '' && (
                <>
                  <div className="adr_row plr30 mb15 ">
                    <div
                      className={`adr_selectRow ${
                        !tractorImplementState[selectedTractor.id]
                          ? 'disableRoutine'
                          : ''
                      }`}
                      onClick={() => {
                        tractorImplementState[selectedTractor.id] &&
                          dispatch({
                            type: SET_SHOW_ROUTINE,
                            payload: !showRoutine,
                          });
                        // after clicking new command clear the auto_drive error
                        tractorImplementState[selectedTractor.id] &&
                          dispatch({
                            type: AUTO_DRIVE_ERROR,
                            payload: {
                              tractor_id: selectedTractor.id,
                              message: '',
                              errors: [],
                            },
                          });
                      }}
                    >
                      {!(selectedRoutine && selectedRoutine?.name) && (
                        <>
                          <span className="adr_plus_nor"></span>
                          <span className="adr_sel_route_txt body2">
                            Select Autodrive Routine
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="adr_row plr30 mb15 dFlexCenter">
                    <div
                      className={`adr_selectRow ${
                        !tractorImplementState[selectedTractor.id]
                          ? 'disableRoutine'
                          : ''
                      }`}
                    >
                      {!(selectedRoutine && selectedRoutine?.name) &&
                        checkAlphaFeature('GotoBlock') && (
                          <span className="adr_sel_route_txt body2">Or</span>
                        )}
                    </div>
                  </div>
                  {checkAlphaFeature('GotoBlock') && (
                    <div className="adr_row plr30 mb30 ">
                      <div
                        className={`adr_selectRow ${
                          !tractorImplementState[selectedTractor.id]
                            ? 'disableRoutine'
                            : ''
                        }`}
                        onClick={() => {
                          if (tractorImplementState[selectedTractor.id]) {
                            dispatch({
                              type: SET_ADD_STEP,
                              payload: true,
                            });
                            handleResetCommand('GOTO');
                            // after clicking new command clear the auto_drive error
                            dispatch({
                              type: AUTO_DRIVE_ERROR,
                              payload: {
                                tractor_id: selectedTractor.id,
                                message: '',
                                errors: [],
                              },
                            });
                          }
                        }}
                      >
                        {!(selectedRoutine && selectedRoutine?.name) && (
                          <>
                            <span className="adr_plus_nor"></span>
                            <span className="adr_sel_route_txt body2">
                              GoTo or GoTo Block
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="commandBackBtn">
                {addStep && (
                  <div
                    className="backBtn"
                    onClick={() => {
                      dispatch({
                        type: SET_AUTO_VALUE,
                        payload: '',
                      });
                      dispatch({
                        type: SET_ADD_STEP,
                        payload: false,
                      });
                      handleResetCommand('');
                    }}
                  >
                    Back
                  </div>
                )}
                {preCommandState === commands.GOTO_POINT && (
                  <div className="commandStepsFlex saveCommandBlk gotoPointBlk">
                    <div className="searchMapBlk savedCommand">
                      <Input
                        data-testid="searchMapInputField-LiveMapFleetList"
                        placeholder="Search Waypoint or Select on Map"
                        value={` GOTO ${
                          selectedItemName
                            ? '( ' + selectedItemName + ' )'
                            : 'Point'
                        }`}
                        readOnly
                      />
                    </div>
                    {showSaveCommand && (
                      <>
                        <Tooltip title="Delete Command">
                          <CloseCircleOutlined
                            onClick={() => {
                              handleResetCommand('GOTO');
                              dispatch({
                                type: SET_AUTO_VALUE,
                                payload: '',
                              });
                            }}
                          />
                        </Tooltip>
                        <CheckOutlined
                          onClick={() => {
                            addRVCommand();
                            dispatch({
                              type: SET_AUTO_VALUE,
                              payload: '',
                            });
                            dispatch({
                              type: SET_ADD_STEP,
                              payload: false,
                            });
                            // to show hide the start button added this code
                            showTractorStart[selectedTractor.id] = false;
                            dispatch({
                              type: SHOW_TRACTOR_START,
                              payload: {
                                ...showTractorStart,
                              },
                            });
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
                {preCommandState === commands.ROW_FOLLOW && (
                  <div className="commandStepsFlex saveCommandBlk">
                    <div
                      className={`searchMapBlk savedCommand inputWidth ${
                        commandType === commands.ROW_FOLLOW && autoDriveSettings
                          ? 'additionalWidth'
                          : ''
                      }`}
                    >
                      <Input
                        data-testid="searchMapInputField-LiveMapFleetList"
                        placeholder="Search Waypoint or Select on Map"
                        value={
                          ' ' + selectedItemName + ' ' + selectedVineBlockName
                        }
                        readOnly
                      />
                    </div>

                    {commandType === commands.ROW_FOLLOW && autoDriveSettings && (
                      <Select
                        style={{
                          width: '284px',
                        }}
                        placeholder="Select sequence"
                        value={setUpKeys.everyFilter}
                        onChange={(value) =>
                          dispatch({
                            type: SET_SETUP_KEYS,
                            payload: {
                              everyFilter: value,
                              isFirstTime: false,
                            },
                          })
                        }
                      >
                        <Option value={0}>Single Row</Option>
                        <Option value={1}>Every Row</Option>
                        <Option value={2}>Every Other </Option>
                        <Option value={3}>Every Third</Option>
                        <Option value={4}>Every Fourth</Option>
                      </Select>
                    )}
                    <Tooltip title="Delete Command">
                      <CloseCircleOutlined
                        onClick={() => {
                          handleResetCommand('GOTO');
                          dispatch({
                            type: SET_AUTO_VALUE,
                            payload: '',
                          });
                        }}
                      />
                    </Tooltip>
                    {commandType === commands.ROW_FOLLOW && autoDriveSettings && (
                      <CheckOutlined
                        onClick={() => {
                          addRowFollowRVCommand();
                          dispatch({
                            type: SET_AUTO_VALUE,
                            payload: '',
                          });
                          dispatch({
                            type: SET_ADD_STEP,
                            payload: false,
                          });
                        }}
                      />
                    )}
                  </div>
                )}
                {preCommandState === '' && addStep && (
                  <div className="selectMapBlk">
                    <div className="commandStepsFlex searchFieldBlk">
                      <div className="searchMapBlk">
                        <SearchOutlined />
                        <AutoComplete
                          options={options}
                          onSelect={onSelect}
                          onSearch={onSearch}
                          value={autoValue}
                        >
                          <Input
                            data-testid="searchMapInputField-LiveMapFleetList"
                            placeholder="Search Waypoint or Select on Map"
                            onChange={(e) =>
                              dispatch({
                                type: SET_AUTO_VALUE,
                                payload: e.target.value,
                              })
                            }
                          />
                        </AutoComplete>
                      </div>
                      <Tooltip title="Delete Command">
                        <CloseCircleOutlined
                          onClick={() => {
                            handleResetCommand('GOTO');
                            dispatch({
                              type: SET_AUTO_VALUE,
                              payload: '',
                            });
                          }}
                        />
                      </Tooltip>
                      {showSaveCommand && (
                        <CheckOutlined
                          onClick={() => {
                            addRVCommand();
                            dispatch({
                              type: SET_AUTO_VALUE,
                              payload: '',
                            });
                            dispatch({
                              type: SET_ADD_STEP,
                              payload: false,
                            });
                            // to show hide the start button added this code
                            showTractorStart[selectedTractor.id] = false;
                            dispatch({
                              type: SHOW_TRACTOR_START,
                              payload: {
                                ...showTractorStart,
                              },
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {commandsList &&
            commandsList[selectedTractor.id] &&
            commandsList[selectedTractor.id].length > 0 && (
              <>
                {commandsList[selectedTractor.id].map(
                  (command: any, index: number) =>
                    index + 1 === commandsList[selectedTractor.id].length && (
                      <>
                        {command && (
                          <div className="commandStepsFlex adr_row p_30 commandStepsFlexRoute">
                            <div
                              className="routeSelect"
                              style={{ display: 'flex' }}
                            >
                              <Input
                                data-testid="searchMapInputField-LiveMapFleetList"
                                placeholder="command"
                                value={`${
                                  command.command === 'goto_point'
                                    ? ` GOTO Point ${
                                        command.name
                                          ? '( ' + command.name + ' )'
                                          : ''
                                      }`
                                    : ''
                                }${
                                  command.command === 'vine_block'
                                    ? ` ${command.name} ${command.block_name}`
                                    : ''
                                } ${
                                  command.command === 'routine'
                                    ? ` Routine ${
                                        command.name
                                          ? '( ' + command.name + ' )'
                                          : ''
                                      }`
                                    : ''
                                } `}
                                readOnly
                              />
                            </div>
                            <CloseOutlined
                              onClick={() => {
                                // to clear preview path
                                clearPreviewPath(selectedTractor.id);
                                clearAll1(selectedTractor.id);
                                clearAllLocalDBCommands(selectedTractor.id);
                                dispatch({
                                  type: SELECTED_ROUTINE,
                                  payload: null,
                                });
                              }}
                            />
                          </div>
                        )}
                      </>
                    ),
                )}
              </>
            )}

          <div className="p_30">
            {commandsList &&
              commandsList[selectedTractor.id] &&
              commandsList[selectedTractor.id].length > 0 && (
                <>
                  {showTractorStart && showTractorStart[selectedTractor.id] ? (
                    <div
                      className="adrStartBtn"
                      onClick={() =>
                        startAutoDriveIteration(selectedTractor.id)
                      }
                    >
                      <Button> Start</Button>
                    </div>
                  ) : (
                    <div className="adrStartBtn">
                      <Button
                        className="driveStartBtn"
                        style={{ color: '#767676', background: 'none' }}
                        disabled
                      >
                        Waiting for Preview
                      </Button>
                    </div>
                  )}
                </>
              )}
          </div>
        </div>
        {autoDriveError &&
          autoDriveError.errors &&
          autoDriveError.errors.map((error: any) =>
            error == 7102 ? (
              <div style={{ textAlign: 'center' }}>
                <span className="errorCodeNameAlpha">
                  Tractor is not in correct starting position <br />
                  <span className="errorCodeName">
                    Drive to start and try again
                  </span>
                </span>
              </div>
            ) : error == 7101 ? (
              <div style={{ textAlign: 'center' }}>
                <span className="errorCodeNameAlpha">
                  Path could not be found <br />
                  <span className="errorCodeName">
                    please reposition the tractor and try again.
                  </span>
                </span>
              </div>
            ) : (
              ''
            ),
          )}
        <div
          className="auto-drive-routine-cancel Button2"
          onClick={() => {
            // to clear preview path
            clearPreviewPath(selectedTractor.id);
            clearAll1(selectedTractor.id);
            clearAllLocalDBCommands(selectedTractor.id);
            dispatch({
              type: SELECTED_ROUTINE,
              payload: null,
            });
            dispatch({ type: SET_AUTO_DRIVE_POP, payload: false });
            // dispatch({
            //   type: SELECTED_TRACTORS,
            //   payload: [],
            // });
            dispatch({
              type: AUTO_DRIVE_ERROR,
              payload: {
                tractor_id: selectedTractor.id,
                message: '',
                errors: [],
              },
            });
            tractorImplementState[selectedTractor.id] = false;
            autoDriveDispatch({
              type: SET_TRACTOR_IMPLEMENT_STATE,
              payload: tractorImplementState,
            });
            // disConnectConnection(selectedTractor.heartbeat);
          }}
        >
          cancel
        </div>
      </div>
      {isHaveErrors(autoDriveError) &&
        isHaveErrorsForRoutineFlow(autoDriveError) && (
          <AutoDriveErrorCodePopup showModal={isHaveErrors(autoDriveError)} />
        )}
    </div>
  );
};

export default AutoDriveRoutine;
