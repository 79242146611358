/* eslint-disable no-console */
import React, { useContext } from 'react';
import low_localization from '../../assets/images/low_localization.svg';
import humanprotection_icon from '../../assets/images/humanprotection_icon.svg';
import pprotection_icon from '../../assets/images/pprotection_icon.svg';
import { checkAutoDriveMonitorErrorCodes } from '../../constants/Common';
import { SET_AUTO_DRIVE_SOFT_INTERRUPT_MESSAGE } from './actions';
import { RemoteDriveAppCtx } from './RemoteDriveContext';
import {
  getInterruptTitle,
  interruptMessageTypes,
} from '../../constants/constant';
import { ApplicationContext } from '../../context/AppContext';

export const AutoDriveSoftInterruptMsg: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);

  const { RDReducer, startAutoDriveIteration } = useContext(RemoteDriveAppCtx);
  const [state, dispatch] = RDReducer;
  const {
    autoDriveSoftInterruptMsg,
    selectedTractor,
    autoDriveMonitorErrorCodes,
  } = state;

  const continueOperation = () => {
    const details = autoDriveSoftInterruptMsg?.metadata?.details;
    if (getPayloadForContinueOperation(details)) {
      const payload = getPayloadForContinueOperation(details);
      startAutoDriveIteration(selectedTractor.id, payload);
      dispatch({
        type: SET_AUTO_DRIVE_SOFT_INTERRUPT_MESSAGE,
        payload: null,
      });
    }
  };
  const endOperation = () => {
    const details = autoDriveSoftInterruptMsg?.metadata?.details;
    if (getPayloadForEndOperation(details)) {
      const payload = getPayloadForEndOperation(details);
      startAutoDriveIteration(selectedTractor.id, payload);
      dispatch({
        type: SET_AUTO_DRIVE_SOFT_INTERRUPT_MESSAGE,
        payload: null,
      });
    }
  };
  const getPayloadForEndOperation = (details: string) => {
    switch (details) {
      case interruptMessageTypes.guardrails_human:
        return {
          action: 'stop',
          details: '',
          category: 'user_initiated',
          type: '',
        };
      case interruptMessageTypes.guardrails_path_presence:
        return {
          action: 'stop',
          details: '',
          category: 'user_initiated',
          type: '',
        };
      default:
        return null;
    }
  };

  const getPayloadForContinueOperation = (details: string) => {
    switch (details) {
      case interruptMessageTypes.guardrails_human:
        return {
          action: 'ignore_and_continue',
          category: 'guardrails',
          planner: 'remote_av',
          operator_id: userDetails.id,
        };
      case interruptMessageTypes.guardrails_path_presence:
        return {
          action: 'ignore_and_continue',
          category: 'guardrails',
          planner: 'remote_av',
          operator_id: userDetails.id,
        };
      default:
        return null;
    }
  };
  const isPathProtectionDisabled = (type: string): boolean => {
    let resp = false;
    if (
      checkAutoDriveMonitorErrorCodes(
        autoDriveMonitorErrorCodes,
        selectedTractor?.id,
      )
    ) {
      autoDriveMonitorErrorCodes.errors.map((error: number) => {
        if (
          (type === interruptMessageTypes.guardrails_path_presence &&
            error == 76200) ||
          (type === interruptMessageTypes.guardrails_human && error == 76100) ||
          (type === interruptMessageTypes.lost_localization && error == 7003)
        ) {
          resp = true;
        }
      });
    }
    return resp;
  };

  return getInterruptTitle(autoDriveSoftInterruptMsg?.metadata?.details) !==
    '' ? (
    <>
      {/* Path Protection code starts  */}
      {autoDriveSoftInterruptMsg?.metadata?.details ===
        interruptMessageTypes.guardrails_path_presence && (
        <div className="pathProtection tractorDetailsAlertBox">
          <div className="pathPRow">
            <span className="terrImgBlock">
              <img src={pprotection_icon} alt="ppIcon" />
            </span>
            <div className="pText">
              <h2 className="headline2 fSbd">Path Protection</h2>
              <div className="minH70">
                <p className="body3">
                  The operation has been paused due to a person detected near
                  the tractor. Check camera views before proceeding.
                </p>
              </div>
            </div>
          </div>
          <div className="pathPRowBtn">
            <button
              type="button"
              className="pOpBtn Button2"
              onClick={endOperation}
            >
              End Operation
            </button>
            <button
              type="button"
              className="pOpBtn ppSelBtn Button2"
              onClick={continueOperation}
              // disabled={isPathProtectionDisabled(
              //   autoDriveSoftInterruptMsg?.metadata?.details,
              // )}
            >
              Ignore and Continue
            </button>
          </div>
        </div>
      )}
      {/* Human Protection code starts  */}
      {autoDriveSoftInterruptMsg?.metadata?.details ===
        interruptMessageTypes.guardrails_human && (
        <div className="humanProtection tractorDetailsAlertBox">
          <div className="pathPRow">
            <span className="terrImgBlock">
              <img src={humanprotection_icon} alt="ppIcon" />
            </span>
            <div className="pText">
              <h2 className="headline2 fSbd">Human Detection</h2>
              <div className="minH70">
                <p className="body3">
                  The operation has been paused due to a person detected near
                  the tractor. Check camera views before proceeding.
                </p>
              </div>
            </div>
          </div>
          <div className="pathPRowBtn">
            <button
              type="button"
              className="pOpBtn Button2"
              onClick={endOperation}
            >
              End Operation
            </button>
            <button
              type="button"
              className="pOpBtn ppSelBtn Button2"
              onClick={continueOperation}
              // disabled={isPathProtectionDisabled(
              //   autoDriveSoftInterruptMsg?.metadata?.details,
              // )}
            >
              Ignore and Continue
            </button>
          </div>
        </div>
      )}
      {autoDriveSoftInterruptMsg?.metadata?.details ===
        interruptMessageTypes.lost_localization && (
        <div className="localizationDown tractorDetailsAlertBox tractorDetailsAlertBox1">
          <div className="pathPRow">
            <span className="terrImgBlock">
              <img src={low_localization} alt="ppIcon" />
            </span>
            <div className="pText">
              <h2 className="headline2 fSbd">Localization Down</h2>
              <div className="minH70">
                <p className="body3">
                  Poor localization, waiting for a better connection.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  ) : (
    <></>
  );
};
