import { CloseCircleOutlined, MinusOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import React from 'react';

import { useMixpanel } from 'react-mixpanel-browser';

import badLoc2 from '../../assets/images/badLoc2.svg';
import bStationIcon from '../../assets/images/bStationGreen.svg';
import datalayerIcon from '../../assets/images/mpLayersIcon.svg';
import dataLayer_te from '../../assets/images/dataLayer_te.svg';
import dataLayer_tg from '../../assets/images/dataLayer_tg.svg';
import farm_blue from '../../assets/images/farm_blue.svg';
import farm_brown from '../../assets/images/farm_brown.svg';
import farm_gray from '../../assets/images/farm_gray.svg';
import farm_green from '../../assets/images/farm_green.svg';
import farm_waypoint from '../../assets/images/farm_waypoint.svg';
import farm_white from '../../assets/images/farm_white.svg';
import mlArrow from '../../assets/images/mlArrow.svg';
import operatorAssist from '../../assets/images/operatorAssist.svg';
import path_ad from '../../assets/images/path_ad.svg';
import path_md from '../../assets/images/path_md.svg';
import path_preview from '../../assets/images/preview_path.svg';
import tractorIcon from '../../assets/images/tractorGreen.svg';
import mpl_white from '../../assets/images/mpl_white.png';
import mpl_green from '../../assets/images/mpl_green.png';
import mpl_blue from '../../assets/images/mpl_blue.png';
import mpl_waypoints from '../../assets/images/waypoint_single.svg';
import mpl_autodrive from '../../assets/images/mpl_autodrive.png';
import mpl_planned_autodrive from '../../assets/images/mpl_planned_autodrive.png';
import mpl_manual from '../../assets/images/mpl_manual.png';
import mpl_triggred_guardrails from '../../assets/images/mpl_triggred_guardrails.png';
import mpl_cretical_health_issues from '../../assets/images/mpl_cretical_health_issues.png';
import mpl_available from '../../assets/images/mpl_available.svg';
import mpl_inuse from '../../assets/images/mpl_inuse.svg';
import mpl_charging from '../../assets/images/mpl_charging.svg';
import mpl_off from '../../assets/images/mpl_off.svg';
import mpl_health_error from '../../assets/images/mpl_health_error.svg';
import mpl_location_unknown from '../../assets/images/mpl_location_unknown.svg';
import mpl_location_unknow_error from '../../assets/images/mpl_location_unknow_error.svg';

interface Props {
  rightSideToggle: boolean;
  mapLayersToggle: boolean;
  setRightSideToggle: (rightSideToggle: boolean) => void;
  setMapLayersToggle: (mapLayersToggle: boolean) => void;
  fleet: boolean;
  setFleet: (fleet: boolean) => void;
  showTractors: boolean;
  setShowTractors: (showTractors: boolean) => void;
  selectItems: (items: boolean) => void;
  showBasestations: boolean;
  setShowBasestations: (showBasestations: boolean) => void;
  farm: boolean;
  setFarm: (farm: boolean) => void;
  showWaypoints: boolean;
  setShowWaypoints: (showWaypoints: boolean) => void;
  showPath: boolean;
  setShowPath: (showPath: boolean) => void;
  showDataLayers: boolean;
  setShowDataLayers: (showDataLayers: boolean) => void;
  showWhitePolygons: boolean;
  setShowWhitePolygons: (showWhitePolygons: boolean) => void;
  showGrayPolygons: boolean;
  setShowGrayPolygons: (showGrayPolygons: boolean) => void;
  selectFarmItems: (items: boolean) => void;
  showGreenPolygons: boolean;
  setShowGreenPolygons: (showGreenPolygons: boolean) => void;
  showBluePolygons: boolean;
  setShowBluePolygons: (showBluePolygons: boolean) => void;
  showBadLocalization: boolean;
  setshowBadLocalization: (showBadLocalization: boolean) => void;
  showBrownPolygons: boolean;
  setShowBrownPolygons: (showBrownPolygons: boolean) => void;
}
const MapLayers: React.FC<Props> = ({
  rightSideToggle,
  setRightSideToggle,
  mapLayersToggle,
  setMapLayersToggle,
  fleet,
  setFleet,
  showTractors,
  setShowTractors,
  selectItems,
  showBasestations,
  setShowBasestations,
  farm,
  setFarm,
  showWaypoints,
  setShowWaypoints,
  showPath,
  setShowPath,
  showDataLayers,
  setShowDataLayers,
  showWhitePolygons,
  setShowWhitePolygons,
  showGrayPolygons,
  setShowGrayPolygons,
  selectFarmItems,
  showGreenPolygons,
  setShowGreenPolygons,
  showBluePolygons,
  setShowBluePolygons,
  showBadLocalization,
  setshowBadLocalization,
  showBrownPolygons,
  setShowBrownPolygons,
}) => {
  const mixpanel = useMixpanel();

  return (
    <div
      className={
        mapLayersToggle ? 'bottomMapLayers mpOpen' : 'bottomMapLayers mpClose'
      }
    >
      {/* <div
      className={
        rightSideToggle ? 'rightSideDataLayers' : 'rightSideDataLayers'
      }
    ></div> */}
      <div className="dataLayerWrapper">
        <div className="dataLayerRowHead mb28">
          <div
            className="mpCloseIcon"
            data-testid="mapLayerclose-MapLayer"
            onClick={() => {
              // mixpanel.track('Live Map', {
              //   event: `Data Layers ${mapLayersToggle ? 'Closed' : 'Opened'}`,
              // });
              setMapLayersToggle(!mapLayersToggle);
            }}
            style={{ cursor: 'pointer' }}
          ></div>
          {/* <span
            onClick={() => {
              mixpanel.track('Live Map', {
                event: `Data Layers ${rightSideToggle ? 'Closed' : 'Opened'}`,
              });
              setRightSideToggle(!rightSideToggle);
            }}
            style={{ cursor: 'pointer' }}
          >
            <img src={mlArrow} alt="dataLayer" height="17" />
          </span> */}
          <div className="mpLayerHead">
            <span className="dataIcon">
              <img src={datalayerIcon} alt="dataLayer" />
            </span>
            <span className="dataHead">MAP LAYERS</span>
          </div>
        </div>
        <div className="dataLayerSec">
          <div className="dataLayerContainer mb7">
            <div className="dataLayerRow mplChkBox">
              <div className="checkBoxItem">
                <span className="layerName" data-testid="layerLegend-MapLayer">
                  LEGEND
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50 dblock pdr0">
                <div className="mplStatus">
                  <div className="mplItem">
                    <img src={mpl_available} alt="" className="mr15" />
                    <span className="mplItemlbl">Available</span>
                  </div>
                  <div className="mplItem">
                    <img src={mpl_inuse} alt="" className="mr15" />
                    <span className="mplItemlbl">In Use</span>
                  </div>
                </div>
                <div className="mplStatus">
                  <div className="mplItem">
                    <img src={mpl_charging} alt="" className="mr15" />
                    <span className="mplItemlbl">Charging</span>
                  </div>
                  <div className="mplItem">
                    <img src={mpl_off} alt="" className="mr15" />
                    <span className="mplItemlbl">Off </span>
                  </div>
                </div>
                <div className="mplStatus2">
                  <div className="mplItem">
                    <img src={mpl_health_error} alt="" className="mr15" />
                    <span className="mplItemlbl">Health Error</span>
                  </div>
                </div>
                <div className="mplStatus2">
                  <div className="mplItem">
                    <img
                      src={mpl_location_unknown}
                      alt=""
                      className="mplMl12"
                    />
                    <span className="mplItemlbl">Location Unknown</span>
                  </div>
                </div>
                <div className="mplStatus2">
                  <div className="mplItem">
                    <img
                      src={mpl_location_unknow_error}
                      alt=""
                      className="mplMl12"
                    />
                    <span className="mplItemlbl">
                      Location Unknown & Health Error
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="dataLayerRow mplChkBox">
              <div className="checkBoxItem">
                <span className="layerName" data-testid="fleetLayer-MapLayer">
                  Fleet
                </span>
                <span className="lChkBox" data-testid="fleetCheckbox-MapLayer">
                  <Checkbox
                    defaultChecked={fleet}
                    checked={fleet}
                    onChange={(e) => {
                      setFleet(e.target.checked);
                      selectItems(e.target.checked);
                    }}
                  ></Checkbox>
                  {(showTractors || showBasestations) && !fleet && (
                    <MinusOutlined className="fleet-minus" />
                  )}
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={tractorIcon} alt="tractor" width="24" height="18" />
                </span>
                <span className="layerName">Tractors</span>
                <span
                  className="lChkBox"
                  data-testid="tractorCheckbox-MapLayer"
                >
                  <Checkbox
                    defaultChecked={showTractors}
                    checked={showTractors}
                    onChange={(e) => {
                      mixpanel.track('Live Map', {
                        event: `Data Layers Tractors Checked ${e.target.checked}`,
                      });
                      setShowTractors(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img
                    src={bStationIcon}
                    alt="basestation"
                    width="11"
                    height="15"
                  />
                </span>
                <span className="layerName">Base Stations</span>
                <span
                  className="lChkBox"
                  data-testid="basestationCheckbox-MapLayer"
                >
                  <Checkbox
                    defaultChecked={showBasestations}
                    checked={showBasestations}
                    onChange={(e) => {
                      mixpanel.track('Live Map', {
                        event: `Data Layers Base Stations Checked ${e.target.checked}`,
                      });
                      setShowBasestations(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
          </div>
          <div className="dataLayerContainer mb7">
            <div className="dataLayerRow mplChkBox">
              <div className="checkBoxItem">
                <span className="layerName">Farm</span>
                <span
                  className="lChkBox"
                  data-testid="farmOverallCheckbox-MapLayer"
                >
                  <Checkbox
                    defaultChecked={farm}
                    checked={farm}
                    onChange={(e) => {
                      setFarm(e.target.checked);
                      selectFarmItems(e.target.checked);
                    }}
                  ></Checkbox>
                  {(showWaypoints ||
                    showGrayPolygons ||
                    showGreenPolygons ||
                    showWhitePolygons) &&
                    !farm && <MinusOutlined className="fleet-minus" />}
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={farm_brown} alt="tractor" width="16" height="16" />
                </span>
                <span className="layerName">Boundary (Brown)</span>
                <span
                  className="lChkBox"
                  data-testid="boundryBrownCheckbox-MapLayer"
                >
                  <Checkbox
                    defaultChecked={showBrownPolygons}
                    checked={showBrownPolygons}
                    onChange={(e) => {
                      setShowBrownPolygons(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={mpl_white} alt="tractor" width="16" height="16" />
                </span>
                <span className="layerName">Geofence (White)</span>
                <span
                  className="lChkBox"
                  data-testid="boundarywhiteCheckbox-MapLayer"
                >
                  <Checkbox
                    defaultChecked={showWhitePolygons}
                    checked={showWhitePolygons}
                    onChange={(e) => {
                      setShowWhitePolygons(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={mpl_green} alt="tractor" width="16" height="16" />
                </span>
                <span className="layerName">Field (Green)</span>
                <span className="lChkBox" data-testid="fieldCheckbox-MapLayer">
                  <Checkbox
                    defaultChecked={showGreenPolygons}
                    checked={showGreenPolygons}
                    onChange={(e) => {
                      setShowGreenPolygons(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={mpl_blue} alt="tractor" width="16" height="16" />
                </span>
                <span className="layerName">Stay-Off Zone (Blue)</span>
                <span
                  className="lChkBox"
                  data-testid="stayOffCheckbox-MapLayer"
                >
                  <Checkbox
                    defaultChecked={showBluePolygons}
                    checked={showBluePolygons}
                    onChange={(e) => {
                      setShowBluePolygons(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
            {/* <div className="dataLayerInnerRow">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={farm_gray} alt="tractor" width="24" height="18" />
                </span>
                <span className="layerName"> Gray Block </span>
                <span>
                  <Checkbox
                    defaultChecked={showGrayPolygons}
                    checked={showGrayPolygons}
                    onChange={(e) => {
                      setShowGrayPolygons(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div> */}
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img
                    src={mpl_waypoints}
                    alt="tractor"
                    width="13"
                    height="18"
                  />
                </span>
                <span className="layerName">Waypoint</span>
                <span
                  className="lChkBox"
                  data-testid="wayPointsCheckbox-MapLayer"
                >
                  <Checkbox
                    defaultChecked={showWaypoints}
                    checked={showWaypoints}
                    onChange={(e) => {
                      setShowWaypoints(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
          </div>
          <div className="dataLayerContainer mb7">
            <div className="dataLayerRow mplChkBox">
              <div className="checkBoxItem">
                <span className="layerName">Path</span>
                <span className="lChkBox" data-testid="pathCheckbox-MapLayer">
                  <Checkbox
                    defaultChecked={showPath}
                    checked={showPath}
                    onChange={(e) => {
                      setShowPath(e.target.checked);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img
                    src={mpl_autodrive}
                    alt="tractor"
                    width="16"
                    height="16"
                  />
                </span>
                <span className="layerName">Autodrive</span>
              </div>
            </div>

            {/* <div className="dataLayerInnerRow">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img
                    src={operatorAssist}
                    alt="tractor"
                    width="24"
                    height="18"
                  />
                </span>
                <span className="layerName">Operator Assist</span>
                <span></span>
              </div>
            </div> */}

            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img
                    src={mpl_planned_autodrive}
                    alt="tractor"
                    width="16"
                    height="16"
                  />
                </span>
                <span className="layerName">Planned Autodrive</span>
                <span></span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={mpl_manual} alt="tractor" width="16" height="16" />
                </span>
                <span className="layerName">Manual</span>
              </div>
            </div>
          </div>
          <div className="dataLayerContainer mb7">
            <div className="dataLayerRow mplChkBox">
              <div className="checkBoxItem">
                <span className="layerName">ALERTS</span>
                <span className="lChkBox" data-testid="alertsCheckbox-MapLayer">
                  <Checkbox
                    defaultChecked={showDataLayers}
                    checked={showDataLayers}
                    onChange={(e) => {
                      setShowDataLayers(e.target.checked);
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img
                    src={mpl_triggred_guardrails}
                    alt="tractor"
                    width="14"
                    height="20"
                  />
                </span>
                <span className="layerName">Triggered Guardrails</span>
              </div>
            </div>
            <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img
                    src={mpl_cretical_health_issues}
                    alt="tractor"
                    width="14"
                    height="20"
                  />
                </span>
                <span className="layerName">Tractor Errors</span>
              </div>
            </div>
            {/* <div className="dataLayerInnerRow mplChkBox">
              <div className="checkBoxItem ml50">
                <span className="iconW">
                  <img src={badLoc2} alt="tractor" width="24" height="18" />
                </span>
                <span className="layerName">Low Localization</span>
                <span className="lChkBox">
                  <Checkbox
                    defaultChecked={showBadLocalization}
                    checked={showBadLocalization}
                    onChange={(e) => {
                      setshowBadLocalization(e.target.checked);
                    }}
                  />
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapLayers;
