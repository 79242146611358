/* eslint-disable no-console */
import { Input, Modal, notification, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import loading_logo from '../../assets/images/loading_logo.gif';
import { editVineBlock, setVineBlock } from '../../constants/Api';
import { PolygonDropDown, VineBlockPayloadType } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import AppLoader from '../common/AppLoader';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean) => void;
  vineBlockPayload: VineBlockPayloadType;
  polygonsListForPop: PolygonDropDown[];
}
export const VineBlock: React.FC<Props> = ({
  showModal,
  handleCancel,
  vineBlockPayload,
  polygonsListForPop,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [name, setName] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [selectedPolygonUuid, setSelectedPolygonUuid] = useState('');
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (vineBlockPayload) {
      if (vineBlockPayload.name) {
        setName(vineBlockPayload.name);
      }
      if (vineBlockPayload.polygonUuid) {
        setSelectedPolygonUuid(vineBlockPayload.polygonUuid);
      }
    }
  }, [vineBlockPayload]);
  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      name &&
      name.trim().length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, name]);

  const AddPolygon = async () => {
    try {
      setLoader(true);
      const vine_rows_uuids: string[] = [];
      vineBlockPayload.vineRows.forEach((element: { rowUuid: string }) => {
        vine_rows_uuids.push(element.rowUuid);
      });
      const payload = {
        name: name.trim(),
        vine_rows_uuids,
        polygon_uuid: selectedPolygonUuid,
      };
      setName(name.trim());
      const { organization } = userDetails;
      if (vineBlockPayload.action === 'ADD') {
        const resp = await setVineBlock(
          organization.api_url,
          organization.farm.id,
          payload,
        );
        if (resp && resp.msg) {
          handleCancel(true);
          notification.success({
            message: resp.msg,
          });
        }
      }
      if (vineBlockPayload.action === 'EDIT') {
        const resp = await editVineBlock(
          organization.api_url,
          organization.farm.id,
          vineBlockPayload.vineBlockId,
          payload,
        );
        if (resp && resp.msg) {
          handleCancel(true);
          notification.success({
            message: resp.msg,
          });
        }
      }
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    } finally {
      setLoader(false);
    }
  };
  return (
    <Modal
      className="commonPopup addPolygon"
      title="VineBlock"
      closable={false}
      centered
      width={'60vw'}
      visible={showModal}
      onCancel={() => handleCancel()}
      okText="Save"
      onOk={AddPolygon}
      okButtonProps={{
        className: 'btnSave ',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Name <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="formCol">
          <label className="formLabel">
            Polygon <span style={{ color: 'red' }}> *</span>
          </label>
          <Select
            className="customSelect"
            onSelect={(value) => {
              setSelectedPolygonUuid(value);
            }}
            value={selectedPolygonUuid}
          >
            {polygonsListForPop.map((data: any) => {
              if (data.name !== '') {
                return (
                  <option value={data.polygon_uuid} key={data.polygon_uuid}>
                    {data.name} ({data.color})
                  </option>
                );
              }
            })}
          </Select>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            VineRows <span style={{ color: 'red' }}> *</span>
          </label>
          <div className="verticsSec">
            {vineBlockPayload?.vineRows.map((data: any) => {
              return (
                <>
                  <div className="vineRowListItem">{data?.name}</div>
                </>
              );
            })}
          </div>
        </div>
        <div className="formCol"></div>
        <AppLoader loader={loader} />
      </div>
    </Modal>
  );
};
export default VineBlock;
