import React from 'react';
import * as Sentry from '@sentry/react';
import SomethingWentWrong from '../components/errors/SomethingWentWrong';

class ErrorBoundary extends React.Component {
  state = {
    hasError: null,
  };

  static getDerivedStateFromError = (error: any) => {
    return { hasError: true };
  };
  componentDidCatch(error: any) {
    Sentry.captureException(error);
  }
  render() {
    if (this.state.hasError) {
      return <SomethingWentWrong />;
    }
    // eslint-disable-next-line react/prop-types
    return this.props?.children;
  }
}

export default ErrorBoundary;
