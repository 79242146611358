/* eslint-disable no-console */
import React, { createContext, useReducer } from 'react';
import TeamsReducer, { initialState } from './TeamsReducer';
export const TeamsContext = createContext({
  TEAMSReducer: [],
} as {
  TEAMSReducer: any;
});

// eslint-disable-next-line react/prop-types
const TeamsAppContext: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(TeamsReducer, initialState);
  return (
    <TeamsContext.Provider
      value={{
        TEAMSReducer: [state, dispatch],
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};

export default TeamsAppContext;
