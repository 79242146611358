/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Space } from 'antd';
import closeGreen from '../../assets/images/closeGreen.svg';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import msgIcon from '../../assets/images/msgIcon.svg';
import applicationIds from '../../locale/applicationIds.json';
import {
  initClearSelection,
  sortCameraViews,
  tractorCameras,
} from '../../constants/Common';
import { useMixpanel } from 'react-mixpanel-browser';
import { UserManualPdfModal } from './UserManualPdfModal';
import { ApplicationContext } from '../../context/AppContext';
import { TOGGLE_VIDEO_WIDGET } from '../../context/actions';
import RoutesConstants from '../../routes/RoutesConstant';
import { useHistory } from 'react-router-dom';
import { privilagesConstants } from '../../constants/Privilages';
import { getVideoURL } from '../../constants/Api';

interface Props {
  details: any;
  isEdit: boolean;
  setIsEdit: (key: boolean) => void;
  setToggleWidget: (key: boolean) => void;
  getSeverity: (key: number) => void;
  getTags: (record: any) => any;
  onCreateTicket?: () => void;
  toggleWidget: boolean;
  tractor?: any;
  hideVideos?: boolean; // optional
}

const DiagnosticWidget: React.FC<Props> = ({
  details,
  setToggleWidget,
  isEdit,
  setIsEdit,
  getSeverity,
  getTags,
  onCreateTicket,
  toggleWidget,
  tractor,
  hideVideos,
}) => {
  const mixpanel = useMixpanel();
  const { push } = useHistory();
  const { t } = useTranslation();
  const [userManualPdfModal, setUserManualPdfModal] = useState(false);
  const { APPReducer, privilegeChecker, userDetails } =
    useContext(ApplicationContext);
  const [showVideos, setShowVideos] = useState(false); // true -> default show videos, false -> to hide(from live map)
  const [state, dispatch] = APPReducer;

  useEffect(() => {
    hideVideos && setShowVideos(false);
  }, [hideVideos]);

  useEffect(() => {
    if (userDetails && userDetails.organization && tractor && details)
      checkVideos();
  }, [details]);

  const checkVideos = async () => {
    const response = await getVideoURL(
      userDetails.organization.api_url,
      userDetails.organization.farm.id,
      tractor?.id,
      details?.created_date_time,
    );
    const result = sortCameraViews(response, 'direction', 'videos').filter(
      (item: any) =>
        Object.values(tractorCameras).join(',').includes(item.direction),
    );
    setShowVideos(result.length > 0);
  };

  const handleVideos = () => {
    const { created_date_time: videoTime, tractor_id: tractorId } = details;
    dispatch({
      type: TOGGLE_VIDEO_WIDGET,
      payload: {
        toggle: !state.videoWidgetData.toggle,
        videoTime: videoTime,
        tractorId: tractorId,
      },
    });
  };

  const openTopic = () => {
    push({
      pathname: RoutesConstants.HowToManual,
      state: {
        chapter: tractor.repair_manual_chapter,
        errorCodes: details.error_code,
        action: 'How to Manual',
      },
    });
    mixpanel.track('HowtoManual', {
      event: 'Moved to HowtoManual',
    });
  };

  return (
    <>
      {details && (
        <>
          <div
            className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}
          >
            <div className="widgetCloseBtn">
              <img
                id={applicationIds.diagnosticsScreen.diaCloseBtn}
                src={closeGreen}
                alt="close icon"
                onClick={() => {
                  setToggleWidget(false);
                  setIsEdit(false);
                  initClearSelection();
                  mixpanel.track('CloseDiagnostics', {
                    event: 'Diagnostics Close',
                  });
                }}
              />
            </div>
            {!isEdit ? (
              <>
                <div className="profileView rightWidgetView">
                  <div className="proRow tcktInfo">
                    <h4 className="headline1">
                      Error Code {details.error_code}
                    </h4>
                    <h3 className="headline2"> {details.modified_date_time}</h3>
                  </div>

                  <div className="proRowType rowGapBtm3">
                    <h5 className="subFlex">
                      <span className="subTitles fSbd overline1">
                        {t(translate.headers.severityLevel)}
                      </span>
                      <span className="subdesc body1">
                        {getSeverity(details.severity)}
                        {details.severity === 1 && <>Low</>}
                        {details.severity === 2 && <>Medium</>}
                        {details.severity === 3 && <>High</>}
                      </span>
                    </h5>
                  </div>
                  <div className="proRowType rowGapBtm3">
                    <Space style={{ display: 'inline' }}>
                      <h5 className="subFlex">
                        <span className="subTitles fSbd overline1">
                          {t(translate.headers.indicatorType)}
                        </span>
                        <span className="subdesc body1">
                          {details.indicator_type}
                        </span>
                      </h5>
                    </Space>
                  </div>
                  <div className="proRowType rowGapBtm3">
                    <Space style={{ display: 'inline' }}>
                      <h5 className="subFlex">
                        <span className="subTitles fSbd overline1">
                          {t(translate.headers.issue)}
                        </span>
                        <span className="subdesc body1">
                          {details.subsystem}
                        </span>
                      </h5>
                    </Space>
                  </div>
                  <div className="proRowType rowGapBtm3">
                    <h5 className="fSbd overline1">
                      {t(translate.headers.description)}
                    </h5>
                    <div className="body1 txtLftGap mt14 w100 desription">
                      {details.description}
                    </div>
                  </div>
                  <div className="proRowType rowGapBtm3 functionalityTag">
                    <h5 className="fSbd overline1">
                      {t(translate.headers.affectedFunctionality)}
                    </h5>
                    <div className="proRoleValue mt14">{getTags(details)}</div>
                  </div>
                  {privilegeChecker(
                    privilagesConstants.Create_a_ticket_from_diagnostic_code,
                  ) && (
                    <div className="proRowType rowGapBtm3">
                      <h5 className="fSbd overline1">Tickets and Tasks</h5>
                      <h6
                        id={applicationIds.diagnosticsScreen.createTicket}
                        className="createTcktBtn mt16 txtLftGap"
                        onClick={onCreateTicket}
                      >
                        <span className="addCBtn createTcktTxt">
                          <img src={msgIcon} height="43px" />
                        </span>
                        <span className="ticketComments">Create A Ticket</span>
                      </h6>
                    </div>
                  )}

                  {showVideos && (
                    <Button
                      type="primary"
                      className="employeeCreate w160"
                      onClick={handleVideos}
                    >
                      View Videos
                    </Button>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          {userManualPdfModal && (
            <UserManualPdfModal
              showModal={userManualPdfModal}
              closeModal={() => setUserManualPdfModal(false)}
            />
          )}
        </>
      )}
    </>
  );
};

export default DiagnosticWidget;
