import { Layout, notification } from 'antd';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { getBatteryData } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import { BatteryList } from '../../constants/types';
import { checkActivity, delay, initScroller } from '../../constants/Common';

interface Props {
  search: string;
}
const Battery: React.FC<Props> = ({ search }) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [filterData, setFilter] = useState<BatteryList[]>([]);
  const [batteryCount, setBatteryCount] = useState<any>();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [pageSize, setPagesize] = useState<any>(25);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialize, setInitialize] = useState<boolean>(false);
  const activity = useRef<string>('');

  const BatteryColumns = [
    {
      render: (name: string) => {
        return (
          <>
            <span className="upTname" data-testid="batteryname-FleetBattery">
              {name}
            </span>
          </>
        );
      },
      title: () => {
        return <>{t(translate.baseStation.Name)}</>;
      },
      dataIndex: 'battery_name',
      key: 'battery_name',
      width: '20%',
      showSorterTooltip: false,
    },
    {
      title: `${t(translate.baseStation.houseMeter)}`,
      dataIndex: 'hour_meter',
      key: 'hour_meter',
      width: '30%',
      showSorterTooltip: false,
    },
    {
      title: `${t(translate.fleet.pin)}`,
      dataIndex: 'bin',
      key: 'bin',
      width: '50%',
    },
  ];

  const getBatterysList = async () => {
    try {
      if (checkActivity(activity.current)) initScroller();
      setLoading(true);
      const result = await getBatteryData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
        pageSize,
        search ? search : '',
      );

      const data =
        result.records && result.records.length > 0 ? result.records : [];
      data.map((element: any) => {
        let BatteryPin = '';
        let hourMeter = '';
        if (element?.bin) {
          BatteryPin =
            element?.bin === '-1' || element?.bin === '0' ? '-' : element?.bin;
        } else {
          BatteryPin = '';
        }
        if (element?.current_hour_meter_hours) {
          hourMeter =
            element?.current_hour_meter_hours === '0'
              ? '-'
              : `${element?.current_hour_meter_hours} hours`;
        } else {
          hourMeter = '-';
        }
        (element.name = element?.name ?? '-'), (element.bin = BatteryPin);
        element.hour_meter = hourMeter;
      });

      setBatteryCount(result._metadata?.total_records_count);
      setHasMore(data.length === pageSize);

      if (checkActivity(activity.current)) setFilter(data);
      else if (activity.current === 'paginate')
        setFilter([...filterData, ...data]);
      else setFilter(data);
    } catch (error: any) {
      notification.error({
        message: error.response?.data?.error?.message || error.message,
      });
    } finally {
      setLoading(false);
      if (!initialize) setInitialize(true);
    }
  };

  const loadBatteries = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity(activity.current)) {
      pageNumber !== 1 ? setPageNumber(1) : getBatterysList();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getBatterysList();
    }
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity(activity.current) && document)
      initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) loadBatteries();
  }, [userDetails, pageNumber]);

  useEffect(() => {
    activity.current = 'search';
    if (userDetails && userDetails.organization && initialize) loadBatteries();
  }, [search]);

  return (
    <Layout>
      <div className="tblDft farmTabsTbl hoverable">
        <InfiniteScrollTable
          xScroll={400}
          columns={BatteryColumns}
          hasMore={hasMore}
          loading={loading}
          filterData={filterData}
          totalcount={batteryCount}
          handleLoadMore={handleLoadMore}
          filename="Batteries"
        />
      </div>
    </Layout>
  );
};

export default Battery;
