import React, { useContext, useState, useEffect } from 'react';
import { Modal, Upload, Button, message, notification, Avatar } from 'antd';
import ProfileIcon from '../../assets/images/defaultProfileIcon.svg';
import { ApplicationContext } from '../../context/AppContext';
import './style.css';
import { addMapmakerImage, getProfileData } from '../../constants/Api';
import ImgCrop from 'antd-img-crop';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import { OrganizationProfileDetails } from '../../constants/types';
import { checkForFileTypes, fileTypesAccepted } from '../../constants/Common';
import AppLoader from '../common/AppLoader';

interface Props {
  showModal: boolean;
  handleCancel: () => void;
}

export const ImageUpload: React.FC<Props> = ({
  showModal,
  handleCancel,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [organizationProfile, setOrganizationProfile] =
    useState<OrganizationProfileDetails>();
  const [fileAcceptable, setFileAcceptable] = useState<boolean>(false);

  const [file, setFile] = useState<any>();
  const [imagefile, setImage] = useState<any>();
  const [imageSize, setImageSize] = useState<boolean>(false);

  const onChange = ({ fileList: newFileList }: any) => {
    if (imageSize && fileAcceptable) {
      setTimeout(function () {
        setImage(newFileList[newFileList.length - 1].thumbUrl);
        setFile(newFileList[newFileList.length - 1]);
      }, 300);
    }
  };

  const getProfile = async () => {
    try {
      setLoader(true);
      const data: OrganizationProfileDetails = await getProfileData(
        userDetails.organization.api_url,
        userDetails.organization.id,
      );
      setImage(data?.farm_layout ? data?.farm_layout : '');
      setOrganizationProfile(data);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getProfile();
  }, [userDetails]);

  useEffect(() => {
    if (file) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [file]);

  const handleSave = async () => {
    await updateImage();
    handleCancel();
  };

  const updateImage = async () => {
    try {
      if (file) {
        const payload = new FormData();
        payload.append('image', file.originFileObj);
        setLoader(true);
        await addMapmakerImage(
          userDetails.organization.api_url,
          userDetails.organization.id,
          payload,
        );
      }
      notification.success({
        message: 'Image Uploaded successfully',
      });
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const beforeUpload = (file: any) => {
    const imgSize = file.size / 1024 / 1024 < 2;
    setImageSize(imgSize);
    if (!imgSize) {
      message.error('Image must smaller than 2MB!');
      return false;
    } else {
      const fileTypeAccepted = checkForFileTypes(file?.type);
      setFileAcceptable(fileTypeAccepted);
      if (!fileTypeAccepted) {
        notification.error({
          message: 'This file type is not accepted',
          duration: 2,
        });
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Modal
      className="commonPopup addPolygon imgUploadStyle popupStyles"
      title="Upload Farm Profile"
      centered
      width={'50vw'}
      confirmLoading={true}
      visible={showModal}
      onCancel={handleCancel}
      closable={false}
      footer={[
        <Button
          key="back"
          onClick={handleCancel}
          className="btnCancel Button"
          data-testid="cancelButtonUploadimg-ImageUpload"
        >
          {t(translate.buttons.cancel)}
        </Button>,
        <Button
          key="submit"
          disabled={isDisabled}
          onClick={() => handleSave()}
          className="btnSave Button"
          data-testid="saveButtonuploadImg-ImageUpload"
        >
          {t(translate.buttons.save)}
        </Button>,
      ]}
    >
      <div className="uploadImg proRow mr0 mb24">
        <div className="userIcon personeeltab imageTooltip">
          <div className="proRow proPic">
            {imagefile !== '' ? (
              <Avatar src={imagefile} size={200}></Avatar>
            ) : (
              <Avatar
                src={ProfileIcon}
                size={200}
                className="defaulticon"
              ></Avatar>
            )}
          </div>
          <div className="modalCol">
            <div className="imgCrop">
              <ImgCrop rotate beforeCrop={beforeUpload}>
                <Upload
                  accept={fileTypesAccepted}
                  listType="picture-card"
                  onChange={onChange}
                  beforeUpload={beforeUpload}
                >
                  <Button
                    className="btnUpload"
                    data-testid="uploadImage-ImageUpload"
                  >
                    {t(translate.buttons.uploadAnImage)}
                  </Button>
                </Upload>
              </ImgCrop>
            </div>
          </div>
        </div>
      </div>
      <AppLoader loader={loader} />
    </Modal>
  );
};

export default ImageUpload;
