import React, { useContext, useState } from 'react';
import { Button, Modal } from 'antd';
import './style.css';
import { ApplicationContext } from '../../context/AppContext';
import { UpdateComplianceCheck } from '../../constants/Api';
import { useHistory } from 'react-router-dom';
import RoutesConstants from '../../routes/RoutesConstant';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {
  showModal?: boolean;
  closeModel?: any;
  termsData?: any;
}
export const TermsConditions: React.FC<Props> = ({
  showModal,
  closeModel,
  termsData,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [numPages, setNumPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  const { push } = useHistory();
  const AcceptDeclineMethod = async (flag: boolean) => {
    const payload: any = {
      compliance_id: termsData.id,
      compliance_code: termsData.compliance_code,
      compliance_version: termsData.version,
      app_name: 'web',
      is_compliance_acknowledged: flag,
    };
    await UpdateComplianceCheck(
      userDetails.organization.api_url,
      userDetails.organization_id,
      userDetails?.id,
      payload,
    );
    if (flag) {
      closeModel();
    } else {
      push(RoutesConstants.Login);
    }
  };
  return (
    <>
      <Modal
        className="modalContainer profileUpdatePopup termspopup"
        title="Terms And Conditions"
        visible={showModal}
        onCancel={closeModel}
        width={1000}
        closable={false}
        maskClosable={false}
        footer={[
          <>
            <Button
              key="back"
              className="psaveBtn"
              onClick={() => AcceptDeclineMethod(true)}
            >
              ACCEPT
            </Button>
            <Button
              key="submit"
              className="pcancelBtn"
              onClick={() => AcceptDeclineMethod(false)}
            >
              DECLINE
            </Button>
          </>,
        ]}
      >
        <div className="modalBox flexnone">
          <div className="formColW">
            <div className="userIcon" key={termsData?.id}>
              <Document
                // file="http://s3.ap-south-1.amazonaws.com/app.dev.monarchtractor.net/atom/sample.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARYG4ZNJLJOLODTN5%2F20220729%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220729T151244Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=129df1f67be8ad2a14ddf282565b1abe0b0d045f581f4235cc043b36d9d7312d"
                // file={termsData.file_path}
                file={`${userDetails?.organization?.api_url}farm/cartography/download-template?action=compliance_check`}
                onLoadSuccess={onDocumentLoadSuccess}
                className="pdf-document"
              >
                {/* <Page pageNumber={pageNumber} /> */}
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    className="pdf-page"
                    pageNumber={index + 1}
                    scale={1.5}
                  />
                ))}
              </Document>
              {/* <Interweave content={termsData?.description} /> */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TermsConditions;
