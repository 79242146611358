import React from 'react';
import { Button } from 'antd';
import logoHorizontalWhite from '../../assets/images/logo_horizontal_white_rgb.png';
import update_complete from '../../assets/images/update_complete.png';
import applicationIds from '../../locale/applicationIds.json';
import { useHistory } from 'react-router-dom';
import RoutesConstants from '../../routes/RoutesConstant';

const AdminChangeSuccess: React.FC = () => {
  const { push } = useHistory();
  return (
    <div className="loginContainer">
      <div className="resetSuccessWrap">
        <img className="mb60" height="50px" src={logoHorizontalWhite} />
        <div
          className="pwSuccess mb40"
          data-testid="resetPwdSuccessMsg-ResetPwdSuccess"
        >
          Organization Administrator Changed Successfully
        </div>
        <img height="95px" src={update_complete} className="mb50" />
        <div className="">
          <Button
            data-testid="goToLogInButton-ResetPwdSuccess"
            id={applicationIds.ResetPasswordScreen.resetSuccessGoTOLogIn}
            type="primary"
            className="btnTxt mtb0"
            onClick={() => push(RoutesConstants.Login)}
          >
            GO TO LOG IN
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdminChangeSuccess;
