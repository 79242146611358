export const activeFilterData = [
  {
    id: true,
    name: 'Active',
  },
  {
    id: false,
    name: 'Inactive',
  },
];
export const statusFilter = [
  {
    id: true,
    name: 'In Use',
  },
  {
    id: false,
    name: 'Inactive',
  },
];
export const resolveFilterData = [
  { name: 'Resolved', value: 'completed' },
  { name: 'Unresolved', value: 'open' },
];

export const resolveFilterData1 = [
  { name: 'Resolved', value: 'resolved' },
  { name: 'Unresolved', value: 'unresolved' },
];

export const softUpdateFilterData = [
  { name: 'Up To Date', id: 'uptodate' },
  { name: 'Update Ready', id: 'update_ready' },
  { name: 'Show All', id: 'show_all' },
];

export const product = [
  { name: 'Tractor', value: 'Tractor' },
  { name: 'Battery', value: 'Battery' },
  { name: 'Base Station', value: 'Base Station' },
];

export const days = [
  { name: '7 Days', value: '7' },
  { name: '15 Days', value: '15' },
  { name: '30 Days', value: '30' },
];

export const ptoOptions = [
  {
    id: true,
    name: 'ON',
  },
  {
    id: false,
    name: 'OFF',
  },
];

export const floatingHitchData = [
  {
    id: true,
    name: 'Yes',
  },
  {
    id: false,
    name: 'No',
  },
];
