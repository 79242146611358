/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */
import { notification } from 'antd';
import jwt_decode from 'jwt-decode';
import React, {
  createContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  getGroundZero,
  getOrgCodes,
  getOrgErrorCodesData,
  getTeamsList,
  getTractorsListData,
  getUserProfile,
  updateVideoLog,
} from '../constants/Api';
import { TractorsList, UserDetails } from '../constants/types';
import RoutesConstants from '../routes/RoutesConstant';
import {
  SET_GROUND_ZERO,
  SET_TEAMS,
  SET_TRACTORS,
  SET_TRACTORS_LOADER,
} from './actions';
import AppReducer, { initialState } from './AppReducer';

export const ApplicationContext = createContext({
  userDetails: {},
  errorCodes: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateUserDetails: () => {},
  privilages: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  privilegeChecker: (code: number) => {},
  fullScreen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateFullScreen: (state: boolean) => {},
  openCameraWindow: (
    tractorId: number,
    cameraView: string,
    tractorName: string,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  initTeams: (pageNumber?: number) => {},
  APPReducer: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getOrgCodesData: () => {},
  // handlePauseResumeStopLiveCom: (command: string) => {},
} as {
  userDetails: UserDetails;
  errorCodes: any;
  updateUserDetails: () => void;
  privilages: any;
  privilegeChecker: (code: number) => boolean;
  fullScreen: boolean;
  updateFullScreen: (code: boolean) => void;
  openCameraWindow: (
    tractorId: number,
    cameraView: string,
    tractorName: string,
  ) => void;
  initTeams: (pageNumber?: number) => void;
  APPReducer: any;
  getOrgCodesData: () => void;
});

const AppContext: React.FC = ({ children }) => {
  const [userDetails, setUserDetails] = useState({} as UserDetails);
  const location = useLocation();
  const { push } = useHistory();
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const [errorCodes, seErrorCodes] = useState<any>({});
  const [privilages, setPrivilages] = useState<number[]>([]);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const cameraWindows = useRef<Map<string, any | undefined>>(
    new Map<string, any | undefined>(),
  );

  const initTractors = async () => {
    dispatch({
      type: SET_TRACTORS_LOADER,
      payload: true,
    });
    try {
      const { records }: { records: TractorsList[] } =
        await getTractorsListData(
          userDetails.organization.api_url,
          userDetails.organization_id,
          1,
          true,
        );
      const data = records && records.length > 0 ? records : [];

      dispatch({
        type: SET_TRACTORS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: SET_TRACTORS_LOADER,
        payload: false,
      });
    }
  };
  const getGroundZeroData = async () => {
    try {
      const { organization } = userDetails;
      const resp = await getGroundZero(
        organization.api_url,
        organization.farm.id,
      );

      dispatch({
        type: SET_GROUND_ZERO,
        payload: resp,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateFullScreen = (state: boolean) => {
    setFullScreen(state);
  };

  const updateUserDetails = async () => {
    try {
      if (localStorage.getItem('auth')) {
        const auth = localStorage.getItem('auth');
        const user: UserDetails = jwt_decode(auth || '');
        const data = await getUserProfile(user.organization.api_url, user.id);
        user.profile_url = data.profile_url;
        user.roles = data.roles;
        user.is_active = data.is_active;
        user.is_org_admin = data.is_org_admin;
        user.first_name = data.first_name;
        user.last_name = data.last_name;
        localStorage.setItem('Avatar', data?.profile_url);
        setUserDetails(user);
      }
    } catch (error: any) {
      // notification.error({
      //   message: error.response?.data.error.message,
      // });
    }
  };

  // set error codes in context

  const getOrgErrorCodes = async () => {
    const result: any = await getOrgErrorCodesData(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );
    const data = result && result.length > 0 ? result : [];
    seErrorCodes(data);
  };
  const getOrgCodesData = async () => {
    const { privileges }: { privileges: number[] } = await getOrgCodes(
      userDetails.organization.api_url,
      userDetails.organization_id,
      userDetails.id,
    );
    const data = privileges && privileges.length > 0 ? privileges : [];
    setPrivilages(data);
  };

  const privilegeChecker = (code: number) => privilages.includes(code);

  const skipResetPassword = () =>
    ![RoutesConstants.ForgotPassword, RoutesConstants.Login].includes(
      location.pathname,
    );

  useEffect(() => {
    notification.config({
      duration: 4,
    });
    if (skipResetPassword()) updateUserDetails();
  }, []);

  const loadInitials = () => {
    getOrgCodesData();
    getOrgErrorCodes();
    initTractors();
    initTeams();
    getGroundZeroData();
  };

  useEffect(() => {
    if (skipResetPassword() && userDetails && userDetails.organization) {
      loadInitials();
    }
  }, [userDetails]);

  useEffect(() => {
    if (state.navigate) {
      const { pathname, data, meta } = state.navigate;
      push({
        pathname,
        state: {
          action: meta.action,
          data: data,
        },
      });
    }
  }, [state.navigate]);

  const openCameraWindow = (
    tractorId: number,
    cameraView: string,
    tractorName: string,
  ) => {
    const url = window.location.origin;
    const view = window.open(
      `${url}${RoutesConstants.CameraView}/${tractorId}/stream/${tractorName}/${cameraView}/true`,
      `${tractorId}${cameraView}`,
      'width=850px, height=480px,min-width: 450px,max-width: 100%,left=600,top=300',
    );
    cameraWindows.current.set(cameraView, view);

    view?.addEventListener('unload', () => {
      if (view.document.URL !== 'about:blank') {
        const successId = localStorage.getItem(view.location.href);
        if (successId) {
          const url = view.location.href;
          const origin = view.location.origin;
          const popUrl = url.replace(RoutesConstants.CameraView + '/', '');
          const srcString = popUrl.replace(origin, '');
          const viewData = srcString;
          const tractorId = viewData.split('/')[0];
          const { organization } = userDetails;
          updateVideoLog(organization.api_url, organization.id, tractorId, {
            remarks: 'Just stopped',
            id: successId,
          }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error('error occured ', error);
          });
        }
      }
    });
  };

  const initTeams = async (pageNumber = 0) => {
    try {
      const response = await getTeamsList(
        userDetails.organization.api_url,
        userDetails.organization.id,
        pageNumber,
      );
      const data =
        response && response.records && response.records.length > 0
          ? response.records
          : [];
      dispatch({ type: SET_TEAMS, payload: data });
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  const refreshPage = () => window.location.reload();

  return (
    <ApplicationContext.Provider
      value={{
        userDetails,
        updateUserDetails,
        errorCodes,
        privilages,
        privilegeChecker,
        fullScreen,
        updateFullScreen,
        openCameraWindow,
        initTeams,
        APPReducer: [state, dispatch],
        getOrgCodesData,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default AppContext;
