/* eslint-disable no-console */
import { Progress } from 'antd';
import moment from 'moment';
import React, { memo, useContext } from 'react';
import loading_gif from '../../assets/images/ticketFwd.gif';
import t_lock from '../../assets/images/t_lock.svg';
import t_speed from '../../assets/images/t_speed.svg';
import {
  batteryValue,
  getBatteryPercentage,
  getOperatorNameByHeartBeat,
  getSpeed,
  getStatusClass,
  getStatusForTractor,
  tractorConstantsNew,
} from '../../constants/Common';
import { TractorsList } from '../../constants/types';
import Battery from '../common/Battery';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';

interface Props {
  tractor?: any;
  connecting: boolean;
  handleSelectTractor: (id: TractorsList) => void;
  selectedTractorsIds: number[];
  showConnectLoader: boolean;
}
const TractorCard: React.FC<Props> = ({
  tractor,
  connecting,
  handleSelectTractor,
  selectedTractorsIds,
  showConnectLoader,
}: Props) => {
  const { getIndicatorIcons } = useContext(RemoteDriveAppCtx);
  const offDurationInHrs = Math.round(
    moment
      .duration(moment().diff(moment(tractor?.last_offline_date_time || 0)))
      .asHours(),
  );
  const { heartbeat = undefined } = tractor;

  return (
    <>
      <div
        className={`fleetBlk ${
          tractor && selectedTractorsIds.includes(tractor.id)
            ? 'fleetBlk-selected'
            : ''
        }`}
        onClick={() => handleSelectTractor(tractor)}
        key={tractor.id}
      >
        {tractor.isAvailable === tractorConstantsNew.charging.key && (
          <>
            <Progress
              className="charging_status"
              percent={batteryValue(tractor)}
              showInfo={false}
            />
            <div className="charging_remain">
              {/* {getChargingTextRemaining(heartbeat)} */}
            </div>
          </>
        )}
        {tractor.isAvailable === tractorConstantsNew.off.key &&
          Object.keys(tractor).includes('last_offline_date_time') && (
            <>
              <div className="charging_remain ">
                OFF FOR {offDurationInHrs <= 12 ? offDurationInHrs : '12+'}{' '}
                {offDurationInHrs <= 1 ? 'HOUR' : 'HOURS'}
              </div>
            </>
          )}
        <div
          className={
            'tractorDtlSec  mb15 ' +
            (tractor.isAvailable === tractorConstantsNew.charging.key ||
            tractor.isAvailable === tractorConstantsNew.off.key
              ? 'mt15'
              : '')
          }
        >
          <div className="tractor_info">
            <div className="tPos">
              {tractor.isAvailable !== tractorConstantsNew.off.key &&
                tractor.isAvailable !== tractorConstantsNew.available.key &&
                tractor.isAvailable !== tractorConstantsNew.charging.key && (
                  <div>
                    <img className="mr12" src={t_lock} />
                  </div>
                )}
              <div className="t_driverDtl">
                <div className="t_name">{tractor.name}</div>
                <div className="t_driver_name">
                  {getOperatorNameByHeartBeat(heartbeat)}
                </div>
              </div>
            </div>
          </div>
          {!(
            tractor.id &&
            selectedTractorsIds.includes(tractor.id) &&
            showConnectLoader
          ) ? (
            <div
              className={
                'ttractor_state ' + getStatusClass(tractor.isAvailable)
              }
            >
              {getStatusForTractor(tractor.isAvailable)}
            </div>
          ) : (
            <div className="t-connect-loader">
              <img src={loading_gif} alt="" />
            </div>
          )}
        </div>

        <div className="tractorDtlSec1">
          <div className="t_battery">
            <Battery
              battery={getBatteryPercentage(tractor)}
              charging={getStatusForTractor(tractor.isAvailable)}
            ></Battery>{' '}
            <span style={{ marginLeft: '8px' }}>{batteryValue(tractor)}%</span>
          </div>
          {tractor.isAvailable !== tractorConstantsNew.off.key &&
            tractor.isAvailable !== tractorConstantsNew.available.key &&
            tractor.isAvailable !== tractorConstantsNew.faulty.key &&
            tractor.isAvailable !== tractorConstantsNew.charging.key &&
            tractor.heartbeat && (
              <div className="flexBtn w65">
                <img src={t_speed} width="13" height="7" />
                <span className="tspeed">{getSpeed(tractor.heartbeat)} </span>
                <span className="tsMPH">MPH</span>
              </div>
            )}
          {getIndicatorIcons(tractor)}
        </div>
      </div>
    </>
  );
};

export default memo(TractorCard);
