/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import { Modal, notification, Spin, InputNumber } from 'antd';
import { ApplicationContext } from '../../context/AppContext';
import { editMarkPointData } from '../../constants/Api';
import { MarkPointPayloadTypes } from '../../constants/types';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean) => void;
  markPointPayload: MarkPointPayloadTypes;
}
export const Mark: React.FC<Props> = ({
  showModal,
  handleCancel,
  markPointPayload,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  const [z, setZ] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  useEffect(() => {
    if (markPointPayload) {
      if (markPointPayload.x) {
        setX(markPointPayload.x);
      }
      if (markPointPayload.y) {
        setY(markPointPayload.y);
      }
      if (markPointPayload.z) {
        setZ(markPointPayload.z);
      }
    }
  }, [markPointPayload]);
  const saveVineRows = async () => {
    setLoader(true);
    const payload = {
      y,
      x,
      z,
    };
    try {
      const { organization } = userDetails;
      if (markPointPayload.action === 'EDIT' && markPointPayload.markId) {
        const resp = await editMarkPointData(
          organization.api_url,
          organization.farm.id,
          markPointPayload.markId,
          payload,
        );
        if (resp && resp.msg) {
          handleCancel(true);
          notification.success({
            message: resp.msg,
          });
        }
      }
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };
  return (
    <Modal
      className="EmployeeModalContainer customModal drawItemsPop"
      title="Mark"
      centered
      visible={showModal}
      onCancel={() => handleCancel()}
      okText="Save"
      onOk={saveVineRows}
      okButtonProps={{
        className: 'btnSave ',
        disabled: false,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow width-class">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label className="Txt1">
                X <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <InputNumber
                    className="input"
                    defaultValue={markPointPayload?.x}
                    value={x}
                    onChange={(value) => setX(value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label className="Txt1">
                Y <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <InputNumber
                    className="input"
                    defaultValue={markPointPayload?.y}
                    value={y}
                    onChange={(value) => setY(value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="formRow width-class">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label className="Txt1">
                Z <span style={{ color: 'red' }}> *</span>
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content input-align">
                  <InputNumber
                    className="input"
                    defaultValue={markPointPayload?.z}
                    value={z}
                    onChange={(value) => setZ(value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default Mark;
