/* eslint-disable no-console */
/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useEffect, useState } from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import { Button, notification, Select } from 'antd';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import { ApplicationContext } from '../../../context/AppContext';
import { DriveAction, TractorsList } from '../../../constants/types';
import { getAllAnalysticsTractors, getOperators } from '../../../constants/Api';
import {
  durationData,
  getAnalyticFormat,
  rangeList,
} from '../../../constants/Common';
// import LiveMap from './LiveMap';
import CustomRangePicker from '../../common/CustomRangePicker';
import fungiMap from '../../../assets/images/analyze_tractor.png';
import './database.css';
import { AnalyticsContext } from '../AnalyticsContext';
import { SET_OPERATION_FILTER, SET_OPERATION_FILTER_LABEL } from '../action';

const { Option } = Select;
interface Props {
  toggleLiveMap: () => void;
}

const AnalyticsDatabase: React.FC<Props> = ({ toggleLiveMap }) => {
  const { userDetails } = useContext(ApplicationContext);
  const { analyticReducer } = useContext(AnalyticsContext);
  const [tractorlist, settractorlist] = useState<TractorsList[]>([]);
  const [tractorId, setTractorId] = useState<any>('');
  const [operators, setOperators] = useState([]);
  const [operatorId, setOperatorId] = useState<any>('');
  const [selectedDateRange, setSelectedDateRange] = useState([
    moment().subtract(1, 'day').toDate(),
    moment().toDate(),
  ]);
  const [duration, setDuration] = useState<number>(1200);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selectRange, setSelectRange] = useState<any[]>([]);
  const [state, dispatch] = analyticReducer;

  const searchAnalytics = () => {
    const obj = {
      date: selectedDateRange,
      tractorId: tractorId,
      operatorId: operatorId,
      distance: selectRange,
      duration: duration,
    };
    dispatch({
      type: SET_OPERATION_FILTER,
      payload: obj,
    });
    toggleLiveMap();
  };

  useEffect(() => {
    if (state.operationFilter && Object.keys(state.operationFilter).length) {
      const { operatorId, duration, tractorId, date } = state.operationFilter;
      const [operator]: any = operators.filter(
        (item: any) => item.id == operatorId,
      );
      const [tractor] = tractorlist.filter((item: any) => item.id == tractorId);
      const [duration1] = durationData.filter(
        (item: any) => item.value == duration,
      );
      dispatch({
        type: SET_OPERATION_FILTER_LABEL,
        payload: {
          durationLabel: duration1 ? duration1?.label : '',
          operatorLabel: operator ? operator.name : '',
          tractorLabel: tractor ? tractor?.name : '',
          dateLabel: getAnalyticFormat(date),
        },
      });
    }
  }, [state.operationFilter]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
    }
  }, [userDetails]);

  const getTractorsList = async () => {
    try {
      const tractor: TractorsList[] = await getAllAnalysticsTractors(
        userDetails.organization.api_url,
        userDetails.organization_id,
      );
      const data = tractor && tractor.length > 0 ? tractor : [];
      settractorlist([...tractorlist, ...data]);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error?.message,
      });
    }
  };

  const handleSelectDateRange = (dates: any[]) => {
    let [date1, date2] = dates;
    date1 = date1 && moment(date1).startOf('day').toDate();
    date2 = date2 && moment(date2).endOf('day').toDate();
    if (date1 && date2) {
      date1.getTime() < date2.getTime() && setSelectedDateRange([date1, date2]);
    } else {
      setSelectedDateRange([date1, date2]);
    }
  };

  useEffect(() => {
    const isValid = selectedDateRange.every((item) => item != null);
    if (!isValid) return;
    if (userDetails && userDetails.organization && selectedDateRange) {
      loadOperators();
    }
  }, [userDetails, selectedDateRange]);

  useEffect(() => {
    if (selectedDateRange[0] && selectedDateRange[1]) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selectedDateRange]);

  const loadOperators = async () => {
    const result = await getOperators(
      userDetails.organization.api_url,
      moment(selectedDateRange[0]).startOf('day').toDate().getTime(),
      moment(selectedDateRange[1]).endOf('day').toDate().getTime(),
    );
    if (result && result[1] === 404) return;
    const members =
      result &&
      result.map((ele: any) =>
        Object.assign({
          id: ele.id,
          name: ele.first_name + ' ' + ele.last_name,
        }),
      );
    if (members.length) setOperators(members);
  };

  const frangeList = rangeList.filter((item) => item > selectRange[0]);

  // const goBackDatabase = () => {
  //   setDriveAction({});
  //   setOperatorsObj('');
  //   setLiveMapFlag(false);
  // };

  return (
    <>
      {/* {!livemapFlag && ( */}
      <Layout>
        <Content>
          <div className="mainContent">
            <div className="dataBaseContainer rowGapTop30">
              <div className="analyzerContainer">
                <div
                  className="analyzerHeader"
                  data-testid="analyzeheader-Operations"
                >
                  <h3 className="headline1">
                    Analyze your farm's <br />
                    performance
                  </h3>
                </div>
                <p className="searchTxt body2">
                  Search through your farm activities to discover new insights
                  and improve profitability.
                </p>
                <div
                  className="rangePickerStyle"
                  data-testid="dateselect-Operations"
                >
                  <label className="inputTop">START</label>
                  <label className="inputTop endRight">END</label>
                  <CustomRangePicker
                    onChange={(dates: any) => {
                      handleSelectDateRange(dates);
                    }}
                    defaultValues={[
                      moment(selectedDateRange[0], 'MMMM DD, yyyy'),
                      moment(selectedDateRange[1], 'MMMM DD, yyyy'),
                    ]}
                    format="MMM DD, Y"
                  />
                </div>

                <div style={{ marginTop: 22 }}>
                  <div className="inputTop">TRACTOR</div>
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Select Tractor"
                    className="dataBaseDropdownStyle"
                    data-testid="tractorselect-Operations"
                    value={tractorId}
                    onSelect={(e: any) => {
                      setTractorId(e);
                    }}
                  >
                    <option value="">All</option>
                    {tractorlist &&
                      tractorlist.length > 0 &&
                      tractorlist.map((data: TractorsList) => (
                        <option
                          key={data.id}
                          value={data.id}
                          data-testid={`${data.id}-Operations`}
                        >
                          {data.name}
                        </option>
                      ))}
                  </Select>
                </div>
                <div style={{ marginTop: 22 }}>
                  <div className="inputTop">OPERATOR</div>
                  <Select
                    style={{ width: '100%' }}
                    className="dataBaseDropdownStyle"
                    placeholder="Select Operator"
                    data-testid="operatorselect-Operations"
                    value={operatorId}
                    onSelect={(e: any) => {
                      setOperatorId(e);
                    }}
                  >
                    <option value="">All</option>
                    {operators &&
                      operators.length > 0 &&
                      operators.map((data: any) => (
                        <option
                          key={data.id}
                          value={data.id}
                          data-testid={`${data.id}-Operations`}
                        >
                          {data.name}
                        </option>
                      ))}
                  </Select>
                </div>
                <div style={{ marginTop: 22 }}>
                  <div className="inputTop">DURATION</div>
                  <Select
                    style={{ width: '100%' }}
                    className="dataBaseDropdownStyle"
                    placeholder="Select Duration"
                    data-testid="durationselect-Operations"
                    value={duration}
                    onSelect={(e: any) => {
                      setDuration(e);
                    }}
                  >
                    <option value="">All</option>
                    {durationData &&
                      durationData.length > 0 &&
                      durationData.map((data: any) => (
                        <option
                          key={data.value}
                          value={data.value}
                          data-testid={`${data.value}-Operations`}
                        >
                          {data.label}
                        </option>
                      ))}
                  </Select>
                </div>
                {/* <div className="duration-range-block">
                  <div className="duration-range-item">
                    <div className="inputTop">FROM DISTANCE</div>
                    <Select
                      style={{ width: '100%' }}
                      className="dataBaseDropdownStyle"
                      onSelect={(e) => setSelectRange([Number(e), undefined])}
                      value={selectRange[0]}
                      placeholder="Select Miles"
                    >
                      {rangeList.map((value, i) => {
                        return (
                          <Option key={i} value={Number(value)}>
                            {value} miles
                          </Option>
                        );
                      })}
                    </Select>
                  </div>

                  <div className="duration-range-item">
                    <div className="inputTop">TO DISTANCE</div>

                    <Select
                      style={{ width: '100%' }}
                      className="dataBaseDropdownStyle"
                      onSelect={(e) => {
                        setSelectRange([selectRange[0], Number(e)]);
                      }}
                      value={selectRange[1]}
                      placeholder="Select Miles"
                    >
                      {frangeList.map((value, i) => {
                        return (
                          <Option key={i} value={Number(value)}>
                            {value} miles
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div> */}

                <div className="searchAnalyticsBtn">
                  <Button
                    key="submit"
                    className="btnteamSave"
                    data-testid="searchanlytsbutton-Operations"
                    onClick={searchAnalytics}
                    disabled={disabled}
                  >
                    {' '}
                    <SearchOutlined /> Search Analytics
                  </Button>
                </div>
              </div>

              <div style={{ width: '75%', float: 'right' }}>
                <img
                  width="100%"
                  style={{ height: '80vh' }}
                  src={fungiMap}
                  alt=""
                />
              </div>
            </div>
          </div>
        </Content>
      </Layout>
      {/* )} */}
      {/* {livemapFlag && (
        <LiveMap
          operatorsObj={operatorsObj}
          goBackDatabase={goBackDatabase}
          driveAction={driveAction}
          setDriveAction={setDriveAction}
        ></LiveMap>
      )} */}
    </>
  );
};

export default AnalyticsDatabase;
