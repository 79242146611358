import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Input, Spin, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { updateLatLong } from '../../constants/Api';
import translate from '../../locale/en_translate.json';
import { ApplicationContext } from '../../context/AppContext';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  groundLat: any;
  groundLang: any;
}

const GroundZeroEditModal: React.FC<Props> = ({
  showModal,
  closeModal,
  groundLat,
  groundLang,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [loader, setLoader] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [latitudeValue, setLatitudeValue] = useState(groundLat);
  const [longitudeValue, setLongitudeValue] = useState(groundLang);

  useEffect(() => {
    if (latitudeValue !== '' && longitudeValue !== '') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [latitudeValue, longitudeValue]);

  const editLatLongValues = async () => {
    try {
      setLoader(true);
      const payload = {
        latitude: latitudeValue,
        longitude: longitudeValue,
      };
      const { organization } = userDetails;
      const response = await updateLatLong(
        organization.api_url,
        organization.farm.id,
        payload,
      );
      notification.success({ message: response.msg });
      closeModal();
      window.location.reload();
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal
      className="commonPopup addPolygon popupStyles"
      title={t(translate.modal.editGroundZero)}
      centered
      width={'50vw'}
      visible={showModal}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal} className="btnCancel Button">
          {t(translate.buttons.cancel)}
        </Button>,
        <Button
          key="submit"
          onClick={editLatLongValues}
          className="btnSave Button"
          disabled={isDisabled}
        >
          {t(translate.buttons.save)}
        </Button>,
      ]}
    >
      <div className="formRow">
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>{t(translate.modal.latitude)}</label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input-content input-align">
                <Input
                  type="number"
                  value={latitudeValue}
                  onChange={(e) => setLatitudeValue(Number(e.target.value))}
                  className="body1 fMed"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="formCol">
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label>{t(translate.modal.longitude)}</label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input-content input-align">
                <Input
                  type="number"
                  className="body1 fMed"
                  value={longitudeValue}
                  onChange={(e) => setLongitudeValue(Number(e.target.value))}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="edit-loader">
        {loader && (
          <div className="loader">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default GroundZeroEditModal;
