/* eslint-disable no-empty */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-console */
/* eslint-disable react/jsx-key */
/* eslint-disable react/react-in-jsx-scope */
import Layout, { Content } from 'antd/lib/layout/layout';
import './style.css';
import EditIcon from '../../assets/images/EditIcon.svg';
import { Avatar, Button, Input, notification, Select, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import defaultProfile from '../../assets/images/defaultProfile.svg';
import { ApplicationContext } from '../../context/AppContext';
import {
  getCrops,
  updateFleetSettingData,
  updateorganization,
  updateProfileImageUpload,
} from '../../constants/Api';
import { FleetSettings } from '../../constants/types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import translate from '../../locale/en_translate.json';
import AppLoader from '../common/AppLoader';
import { checkForFileTypes, fileTypesAccepted } from '../../constants/Common';

interface Props {
  setEditprofile: (data: any) => void;
  organizationProfile: any;
  fleetSetting: FleetSettings[];
}
const EditfarmProfile: React.FC<Props> = ({
  setEditprofile,
  organizationProfile,
  fleetSetting,
}: Props) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<any>(
    organizationProfile?.profile_url,
  );
  const { userDetails } = useContext(ApplicationContext);
  const [firstName, setFirstName] = useState<any>(organizationProfile?.name);
  const [acreage, setAcreage] = useState<any>(
    organizationProfile?.acreage
      ? organizationProfile?.acreage + ' ' + 'Acres'
      : '',
  );
  const [crops, setCrops] = useState<any>();
  const [address, setAddress] = useState<any>(organizationProfile?.address);
  const [building, setBuilding] = useState<any>(
    organizationProfile?.apt_or_suit_no,
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [imageFile, setImagefile] = useState('');
  const [city, setCity] = useState<any>(organizationProfile?.city);
  const [state, setState] = useState<any>(organizationProfile?.state);
  const [zipcode, setZipcode] = useState<any>(organizationProfile?.zip_code);
  const [country, setCountry] = useState<any>(organizationProfile?.country);
  const [cropsData, setCropsData] = useState<any>([]);
  const [electricityrate, setElectricityRate] = useState('');
  const [disselRate, setDieselRate] = useState('');
  const { Option } = Select;

  const [dataarray, setData] = useState<any>([]);
  const [validations, setValidations] = useState<any>({
    firstName: true,
    acreage: true,
    crops: true,
    address: true,
    city: true,
    state: true,
    zipcode: true,
    country: true,
    enabled: false,
  });

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getProfileLookUps();
    }
  }, [userDetails]);

  useEffect(() => {
    if (fleetSetting && fleetSetting.length > 0) {
      fleetSetting.forEach((setting) => {
        if (setting.name === 'electricity_rate') {
          setElectricityRate(setting.value);
        }

        if (setting.name === 'diesel_cost') {
          setDieselRate(setting.value);
        }
      });
    }
  }, [fleetSetting]);

  const getProfileLookUps = async () => {
    const cropsData = await getCrops(
      userDetails.organization.api_url,
      'org_farm_crops',
    );
    setCropsData(cropsData);
    setData(cropsData);
    const splitData = organizationProfile?.crops?.split(',');
    const res = cropsData.filter((data: any) => splitData?.includes(data.name));
    const res1: any = [];
    res.map((d: any) => {
      res1.push(d.name);
    });
    setCrops(res1);
  };

  const onChange = (e: any) => {
    if (e.target.files.length) {
      const { type } = e.target.files[0];
      const fileTypeAllowed = checkForFileTypes(type);
      if (!fileTypeAllowed) {
        notification.error({
          message: 'This file type is not accepted',
        });
      } else {
        setImagefile(e.target.files[0]);
        setFileList(URL.createObjectURL(e.target.files[0]));
      }
    }
  };
  const saveFarmProfile = () => {
    setValidations({
      ...validations,
      firstName: firstName?.trim() !== '',
      address: address?.trim() !== '',
      crops: crops !== '',
      acreage: acreage !== '',
      city: city?.trim() !== '',
      state: state?.trim() !== '',
      zipcode: zipcode?.trim() !== '',
      country: country?.trim() !== '',
      enabled: true,
    });
  };

  useEffect(() => {
    const isValid = Object.values(validations).every((item) => item);
    if (isValid && Object.keys(validations).length) handleFormSubmit();
  }, [validations]);

  const handleFormSubmit = async () => {
    const payload = {
      name: firstName?.trim(),
      address: address?.trim(),
      crops: crops?.toString(),
      acreage: acreage,
      apt_or_suit_no: building?.trim(),
      city: city?.trim(),
      state: state?.trim(),
      zip_code: zipcode?.trim(),
      country: country?.trim(),
    };
    setLoader(true);
    try {
      const response = await updateorganization(
        userDetails.organization.api_url,
        userDetails.organization.id,
        payload,
      );
      await updateFleetSetting();
      if (imageFile) {
        imageUpload();
      } else {
        setEditprofile(false);
        notification.success({
          message: t(translate.label.farmProfileUpdatd),
        });
      }
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const updateFleetSetting = async () => {
    try {
      await updateFleetSettingData(
        userDetails.organization.api_url,
        userDetails.organization.id,
        [
          {
            name: 'electricity_rate',
            value: electricityrate,
          },
          {
            name: 'diesel_cost',
            value: disselRate,
          },
        ],
      );
    } catch (error) {
      console.log(error);
    }
  };

  const imageUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('image', imageFile);
      await updateProfileImageUpload(
        userDetails.organization.api_url,
        userDetails.organization.id,
        formData,
      );
      setEditprofile(false);
      notification.success({
        message: t(translate.label.farmProfileUpdatd),
      });
    } catch (err: any) {
      notification.error({
        message: err?.message,
      });
    }
  };

  const selectFarmInput = (ele: any) => {
    setCrops(ele);
  };

  return (
    <Layout>
      <Content>
        {organizationProfile && (
          <div className="profContainer farm_profile_container disinBlock editProfContHight">
            <AppLoader loader={loader} />
            <div className="profileDetails rowGap55">
              <div className="profPicSec editFarmIcon ">
                <div className="proRow mb54">
                  <h4 className="proRoleHead posrel">
                    {/* <ArrowLeftOutlined onClick={() => setEditprofile(false)} /> */}
                    <div
                      className="teamarrow editProBackBtn "
                      data-testid="arrowEdit-EditFarmProfile"
                      onClick={() => setEditprofile(false)}
                    ></div>
                    <span className="headline1 lh56">Edit Farm Profile</span>
                  </h4>
                </div>
                <>
                  <div className="uploadImg proRow mr0 mb24">
                    <div className="userIcon">
                      <div className="proRow proPic defaultimgicon">
                        {fileList ? (
                          <Avatar
                            className="defaulticon"
                            src={fileList}
                          ></Avatar>
                        ) : (
                          <Avatar
                            src={defaultProfile}
                            className="defaulticon"
                          ></Avatar>
                        )}
                      </div>
                      <label htmlFor="upload-button" className="editImgs">
                        <img src={EditIcon} alt="edit" />
                      </label>
                    </div>
                    <input
                      accept={fileTypesAccepted}
                      type="file"
                      id="upload-button"
                      style={{ display: 'none' }}
                      onChange={onChange}
                    />
                  </div>
                </>
              </div>
              <div className="profDetails editFarmDetails width448 mb30 rowGapTop9">
                <div className="addressSec">
                  <h3 className="mb20 editFarmTitle headline2_B">
                    Farm Information
                  </h3>
                </div>
                <div className="width448 mb24">
                  <Input
                    placeholder="First Name"
                    data-testid="farmnameEdit-EditFarmProfile"
                    defaultValue={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className={classNames({
                      errorClass: !firstName && !validations.firstName,
                    })}
                  />
                </div>
                <div className="width448 mb24">
                  <Input
                    placeholder="Acres"
                    data-testid="acresEdit-EditFarmProfile"
                    defaultValue={acreage}
                    onChange={(e) => setAcreage(e.target.value)}
                    className={classNames({
                      errorClass: !acreage && !validations.acreage,
                    })}
                  />
                </div>
                <div className="width448 mb24 cropsSelect teamsSelect">
                  <Select
                    mode="multiple"
                    placeholder="Crops"
                    data-testid="cropsDropDown-EditFarmProfile"
                    style={{ width: '100%' }}
                    className="addEmployeeSelect teamTxt"
                    value={crops}
                    onChange={selectFarmInput}
                  >
                    {dataarray.map((ele: any) => {
                      return (
                        <Option
                          title={ele.name}
                          data-testid={`${ele.name}-CropsDropdown`}
                          value={ele?.name}
                          key={ele?.id}
                        >
                          <div className="teamName">{ele.name}</div>
                        </Option>
                      );
                    })}
                  </Select>
                </div>
                <div className="addressSec pt2">
                  <h3 className="mb20 editFarmTitle headline2_B">Address</h3>
                </div>
                <div className="width448 mb24">
                  <Input
                    placeholder="Street"
                    data-testid="addressEdit-EditFarmProfile"
                    defaultValue={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className={classNames({
                      errorClass: !address && !validations.address,
                    })}
                  />
                </div>
                <div className="width448 mb24">
                  <Input
                    className="aptSuitBuildNo"
                    placeholder="Apt, Suite, Building (Optional)"
                    defaultValue={building}
                    onChange={(e) => setBuilding(e.target.value)}
                  />
                </div>
                <div className="width448 flexBtn mb24">
                  <Input
                    placeholder="City"
                    data-testid="cityEdit-EditFarmProfile"
                    defaultValue={city}
                    onChange={(e) => setCity(e.target.value)}
                    className={classNames('width216', {
                      errorClass: !city && !validations.city,
                    })}
                  />
                  <Input
                    placeholder="State"
                    data-testid="stateEdit-EditFarmProfile"
                    defaultValue={state}
                    onChange={(e) => setState(e.target.value)}
                    className={classNames('width80', {
                      errorClass: !state && !validations.state,
                    })}
                  />

                  <Input
                    placeholder="Zipcode"
                    data-testid="zipcodeEdit-EditFarmProfile"
                    defaultValue={zipcode}
                    onChange={(e) => setZipcode(e.target.value)}
                    className={classNames('width122', {
                      errorClass: !zipcode && !validations.zipcode,
                    })}
                  />
                </div>
                <div className="width448 mb24">
                  <Input
                    placeholder="Country"
                    data-testid="countryEdit-EditFarmProfile"
                    defaultValue={country}
                    onChange={(e) => setCountry(e.target.value)}
                    className={classNames({
                      errorClass: !country && !validations.country,
                    })}
                  />
                </div>
                <div className="addressSec pt2">
                  <h3 className="mb20 editFarmTitle headline2_B">
                    Fleet Settings
                  </h3>
                </div>
                <div className="width448 mb24">
                  <label>Electricity Rate</label>

                  <Input
                    placeholder="Electricity Rate"
                    data-testid="electricyEdit-EditFarmProfile"
                    defaultValue={electricityrate}
                    onChange={(e) => setElectricityRate(e.target.value)}
                    className={classNames({
                      errorClass:
                        !electricityrate && !validations.electricityrate,
                    })}
                    type="number"
                    value={electricityrate}
                  />
                </div>
                <div className="width448 mb24">
                  <label>Diesel Cost</label>
                  <Input
                    placeholder="Diesel Cost"
                    data-testid="dieselEdit-EditFarmProfile"
                    defaultValue={disselRate}
                    onChange={(e) => setDieselRate(e.target.value)}
                    className={classNames({
                      errorClass: !disselRate && !validations.disselRate,
                    })}
                    type="number"
                    value={disselRate}
                  />
                </div>
                <div className="pt24">
                  <Button
                    key="submit"
                    onClick={saveFarmProfile}
                    className="btnSaveFarm farmSaveBtn"
                    data-testid="saveButton-EditFarmProfile"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default EditfarmProfile;
