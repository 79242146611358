/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
import './autodrive.css';
import './style.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal, notification, Spin } from 'antd';
import { validateOpsCode } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { TractorHeartBeat } from '../../constants/types';
import loading_logo from '../../assets/images/loading_logo.gif';
import AppLoader from '../common/AppLoader';
interface PassCode {
  pin_0: string;
  pin_1: string;
  pin_2: string;
  pin_3: string;
  pin_4: string;
}
interface Props {
  showModal: boolean;
  handleCancel: () => void;
  tractorId: number;
  setShowLiveCommand: () => void;
  heartBeat: TractorHeartBeat;
}
export const PassCode: React.FC<Props> = ({
  showModal,
  handleCancel,
  tractorId,
  setShowLiveCommand,
  heartBeat,
}) => {
  const { userDetails } = useContext(ApplicationContext);
  const [error, setError] = useState<string>('');
  const [onApiSucsess, setonApiSucsess] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [state, setState] = React.useState<PassCode>({
    pin_0: '',
    pin_1: '',
    pin_2: '',
    pin_3: '',
    pin_4: '',
  });
  const pin0Ref = useRef<HTMLInputElement>(null);
  const pin1Ref = useRef<HTMLInputElement>(null);
  const pin2Ref = useRef<HTMLInputElement>(null);
  const pin3Ref = useRef<HTMLInputElement>(null);
  const pin4Ref = useRef<HTMLInputElement>(null);
  const [inputElement, setInputElement] = useState<string>('');

  const handleInputFieldsFocus = (inputFieldName: string) => {
    switch (inputFieldName) {
      case 'pin_0':
        pin1Ref.current?.focus();
        break;
      case 'pin_1':
        pin2Ref.current?.focus();
        break;
      case 'pin_2':
        pin3Ref.current?.focus();
        break;
      case 'pin_3':
        pin4Ref.current?.focus();
        break;
      default:
        break;
    }
  };

  const handleInputFieldsClearFocus = (inputFieldName: string) => {
    switch (inputFieldName) {
      case 'pin_4':
        pin3Ref.current?.focus();
        break;
      case 'pin_3':
        pin2Ref.current?.focus();
        break;
      case 'pin_2':
        pin1Ref.current?.focus();
        break;
      case 'pin_1':
        pin0Ref.current?.focus();
        break;
      default:
        break;
    }
  };
  const handlePasscodeSelection = (value: string): void => {
    setState({
      ...state,
      [inputElement]: value,
    });
    handleInputFieldsFocus(inputElement);
  };
  const clearSelection = () => {
    setState({
      ...state,
      [inputElement]: '',
    });
    handleInputFieldsClearFocus(inputElement);
  };
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    if (value === '') {
      handleInputFieldsClearFocus(evt.target.name);
    }
    setState({
      ...state,
      [evt.target.name]: value,
    });
    if (value.trim().length !== 0) {
      handleInputFieldsFocus(inputElement);
    }
  };
  useEffect(() => {
    if (state.pin_3.length !== 0) {
      login();
    }
  }, [state]);
  const login = async () => {
    setLoader(true);
    const code = `${state.pin_0}${state.pin_1}${state.pin_2}${state.pin_3}${state.pin_4}`;
    try {
      const { organization } = userDetails;
      const payload = {
        tractor_id: Number(tractorId),
        operator_id: userDetails.id,
        organization_id: userDetails.organization_id,
        ops_code: Number(code),
      };
      await validateOpsCode(organization.api_url, payload);
      setonApiSucsess(true);
      return;
    } catch (err) {
      notification.error({
        message: 'Failed to login',
      });
      reset(err);
      setLoader(false);
    }
    //  finally {
    //   setLoader(false);
    // }
  };
  const reset = (err: any) => {
    if (err.message === 'Network Error') {
      setError(err.message);
    } else {
      setError(err.response.data.error.message);
    }
    setState({ pin_0: '', pin_1: '', pin_2: '', pin_3: '', pin_4: '' });
    pin0Ref.current?.focus();
  };
  useEffect(() => {
    pin0Ref.current?.focus();
  }, []);
  useEffect(() => {
    if (
      heartBeat &&
      heartBeat.tractor_id === Number(tractorId) &&
      heartBeat.organization_id === userDetails.organization_id &&
      heartBeat?.drive_action_details &&
      heartBeat?.drive_action_details.current_drive_action &&
      onApiSucsess
    ) {
      setLoader(false);
      setShowLiveCommand();
    }
  }, [heartBeat, tractorId, userDetails, onApiSucsess]);
  return (
    <Modal
      // title={liveCommandType === 'AUTODRIVE' ? 'Live Command' : 'Routines'}
      visible={showModal}
      className="baseModalContainer, passCodePopup"
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
      width="350px"
    >
      <div>
        <div className="keyboardContainer">
          <div className="codeEnterSec">
            <div className="onemoreTryHead">{'  '}</div>
            <div className="onemoreTrytext">{'  '}</div>
            <h2 className="enterLbl">Enter Passcode to Connect</h2>
            <div className="formInputs">
              <form>
                <input
                  type="text"
                  maxLength={1}
                  // readOnly
                  onChange={handleChange}
                  onFocus={() => setInputElement('pin_0')}
                  value={state.pin_0 ? '*' : ''}
                  name="pin_0"
                  ref={pin0Ref}
                  autoFocus
                />
                <input
                  type="text"
                  maxLength={1}
                  // readOnly
                  onChange={handleChange}
                  onFocus={() => setInputElement('pin_1')}
                  value={state.pin_1 ? '*' : ''}
                  name="pin_1"
                  ref={pin1Ref}
                />
                <input
                  // className={error && styles.error}
                  type="text"
                  maxLength={1}
                  // readOnly
                  onChange={handleChange}
                  onFocus={() => setInputElement('pin_2')}
                  value={state.pin_2 ? '*' : ''}
                  name="pin_2"
                  ref={pin2Ref}
                />
                <input
                  // className={error && styles.error}
                  type="text"
                  maxLength={1}
                  // readOnly
                  onChange={handleChange}
                  onFocus={() => setInputElement('pin_3')}
                  value={state.pin_3 ? '*' : ''}
                  name="pin_3"
                  ref={pin3Ref}
                />
              </form>
            </div>
            <div className="incorrectCode">{error}</div>
          </div>
          <div className="buttonsSec">
            <div className="keyboardRow">
              <button
                className="keyboardButton"
                onClick={() => handlePasscodeSelection('1')}
                type="button"
              >
                1
              </button>
              <button
                className="keyboardButton"
                onClick={() => handlePasscodeSelection('2')}
                type="button"
              >
                2
              </button>
              <button
                className="keyboardButton"
                onClick={() => handlePasscodeSelection('3')}
                type="button"
              >
                3
              </button>
            </div>
            <div className="keyboardRow">
              <button
                className="keyboardButton"
                onClick={() => handlePasscodeSelection('4')}
                type="button"
              >
                4
              </button>
              <button
                className="keyboardButton"
                onClick={() => handlePasscodeSelection('5')}
                type="button"
              >
                5
              </button>
              <button
                className="keyboardButton"
                onClick={() => handlePasscodeSelection('6')}
                type="button"
              >
                6
              </button>
            </div>
            <div className="keyboardRow">
              <button
                className="keyboardButton"
                onClick={() => handlePasscodeSelection('7')}
                type="button"
              >
                7
              </button>
              <button
                className="keyboardButton"
                onClick={() => handlePasscodeSelection('8')}
                type="button"
              >
                8
              </button>
              <button
                className="keyboardButton"
                onClick={() => handlePasscodeSelection('9')}
                type="button"
              >
                9
              </button>
            </div>
            <div className="keyboardRow">
              <button
                className="keyboardButton"
                onClick={() => handlePasscodeSelection('0')}
                type="button"
              >
                0
              </button>
              <div
                className="backArrow"
                onClick={() => clearSelection()}
                aria-hidden="true"
              />
            </div>
          </div>
          <button className="passDel">Delete</button>
        </div>
      </div>
      <AppLoader loader={loader} />
    </Modal>
  );
};
