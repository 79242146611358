/* eslint-disable no-console */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Card, Row, Space, Button, Modal, Menu } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import './style.css';
import {
  CaretRightOutlined,
  PauseOutlined,
  MoreOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { getDateTime, sortCameraViews } from '../../constants/Common';
import { getVideoURL } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { useMixpanel } from 'react-mixpanel-browser';
import CameraGrid2 from '../map/CameraGrid2';
import { TOGGLE_VIDEO_WIDGET } from '../../context/actions';
import AppLoader from './AppLoader';

const VideoWidget: React.FC = () => {
  const mixpanel = useMixpanel();
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [state, dispatch] = APPReducer;
  const { videoWidgetData, tractorsMap } = state;
  const { tractorId, videoTime } = videoWidgetData;
  const tractor = tractorsMap.get(tractorId);
  const [videos, setVideos] = useState<any>([]);
  const frontRef: any = useRef();
  const rearRef: any = useRef();
  const driverRef: any = useRef();
  const ptoRef: any = useRef();
  const frontLeft: any = useRef();
  const frontRight: any = useRef();
  const rearLeft: any = useRef();
  const rearRight: any = useRef();
  const curTime: any = useRef();
  const fixTime: any = useRef();
  const [play, setPlay] = useState(true);
  const faces = [frontRef, rearRef, ptoRef, frontLeft, frontRight];
  const [openMenu, setOpenMenu] = useState(false);
  const [speedMenu, setSpeedMenu] = useState(false);
  const [current, setCurrent] = useState('1');
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [vedioInfo, setvedioInfo] = useState<any>({
    front: false,
    rear: false,
    pto: false,
    driver: false,
    frontleft: false,
    rearleft: false,
    frontright: false,
    rearrightRef: false,
  });

  const mapVideoTag = (face: any, data: any) => {
    if (face.current) face.current.src = data.video_url;
  };

  const mapAction = (action: string) => {
    if (frontRef.current && frontRef.current.src) {
      frontRef.current[action]();
      if (videoTime && videoTime === 1653514589176)
        frontRef.current.currentTime = 60;
    }
    if (rearRef.current && rearRef.current.src) {
      rearRef.current[action]();
      if (videoTime && videoTime === 1653514589176)
        rearRef.current.currentTime = 60;
    }
    if (driverRef.current && driverRef.current.src) {
      driverRef.current[action]();
      if (videoTime && videoTime === 1653514589176)
        driverRef.current.currentTime = 60;
    }
    if (ptoRef.current && ptoRef.current.src) {
      ptoRef.current[action]();
      if (videoTime && videoTime === 1653514589176)
        ptoRef.current.currentTime = 60;
    }
    if (frontLeft.current && frontLeft.current.src) {
      frontLeft.current[action]();
      if (videoTime && videoTime === 1653514589176)
        frontLeft.current.currentTime = 60;
    }
    if (frontRight.current && frontRight.current.src) {
      frontRight.current[action]();
      if (videoTime && videoTime === 1653514589176)
        frontRight.current.currentTime = 60;
    }
    if (rearLeft.current && rearLeft.current.src) {
      rearLeft.current[action]();
      if (videoTime && videoTime === 1653514589176)
        rearLeft.current.currentTime = 60;
    }
    if (rearRight.current && rearRight.current.src) {
      rearRight.current[action]();
      if (videoTime && videoTime === 1653514589176)
        rearRight.current.currentTime = 60;
    }
  };

  const resetTags = () => {
    faces.map((face) => face.current && face.current.src);
    // curTime.current.innerHTML = 0;
    // fixTime.current.innerHTML = 0;
  };
  const mapVideos = (videos: any) => {
    if (videos.length) {
      videos.map((data: any) => {
        switch (data.direction) {
          case 'front':
            mapVideoTag(frontRef, data);
            break;
          case 'rear':
            mapVideoTag(rearRef, data);
            break;
          case 'pto':
            mapVideoTag(ptoRef, data);
            break;
          case 'front-left':
            mapVideoTag(frontLeft, data);
            break;
          case 'front-right':
            mapVideoTag(frontRight, data);
            break;
          default:
            break;
        }
      });
    } else {
      resetTags();
    }
  };

  // unmount component
  useEffect(() => {
    return () => {
      dispatch({
        type: TOGGLE_VIDEO_WIDGET,
        payload: {
          toggle: false,
          videoTime: 0,
          tractorId: 0,
          tractorName: '',
        },
      });
    };
  }, []);

  useEffect(() => {
    if (videos) mapVideos(videos);
  }, [videos]);

  useEffect(() => {
    if (userDetails && userDetails.organization && videoTime && tractorId) {
      getVideoData();
    }
  }, [userDetails, videoTime]);

  const getVideoData = async () => {
    try {
      setShowLoader(true);
      let response = await getVideoURL(
        userDetails.organization.api_url,
        userDetails.organization.farm.id,
        tractorId,
        videoTime,
      );
      response = sortCameraViews(response, 'direction', 'videos');
      response.map((data: any) => {
        switch (data.direction) {
          case 'front':
            vedioInfo.front = true;
            break;
          case 'rear':
            vedioInfo.rear = true;
            break;
          case 'pto':
            vedioInfo.pto = true;
            break;
          case 'driver':
            vedioInfo.driver = true;
            break;
          case 'front-left':
            vedioInfo.frontleft = true;
            break;
          case 'rear-left':
            vedioInfo.rearleft = true;
            break;
          case 'front-right':
            vedioInfo.frontright = true;
            break;
          case 'rear-right':
            vedioInfo.rearrightRef = true;
            break;
          default:
            break;
        }
      });
      setVideos(response);
    } finally {
      setShowLoader(false);
    }
  };

  const playVideo = () => {
    if (play) {
      mapAction('play');
      setPlay(false);
      mixpanel.track('Recorded Video', {
        event: `All Videos Played`,
      });
    } else {
      mapAction('pause');
      setPlay(true);
      mixpanel.track('Recorded Video', {
        event: `All Videos Paused`,
      });
    }
  };

  // const video: any = document.querySelector('video');
  const progress: any = document.querySelector('.vedioprogress');
  const progressBar: any = document.querySelector('.vedioprogress__filled');

  const currentTime = (video: any) => {
    const currentMinutes = Math.floor(video.currentTime / 60);
    const currentSeconds = Math.floor(video.currentTime - currentMinutes * 60);
    const durationMinutes = Math.floor(video.duration / 60);
    const durationSeconds = Math.floor(video.duration - durationMinutes * 60);
    curTime.current.innerHTML = `0${currentMinutes}: ${
      currentSeconds < 10 ? '0' + currentSeconds : currentSeconds
    }`;
    if (durationMinutes) {
      fixTime.current.innerHTML = `0${durationMinutes}: ${
        durationSeconds < 10 ? '0' + durationSeconds : durationSeconds
      }`;
    }
  };

  const handleProgress = () => {
    try {
      if (frontRef.current?.src) {
        const percent =
          (frontRef.current.currentTime / frontRef.current.duration) * 100;
        if (isNaN(percent)) {
          progressBar.style.flexBasis = `0%`;
        } else {
          progressBar.style.flexBasis = `${percent}%`;
        }
        currentTime(frontRef.current);
      }

      if (rearRef.current?.src) {
        const percent =
          (rearRef.current.currentTime / rearRef.current.duration) * 100;
        if (isNaN(percent)) {
          progressBar.style.flexBasis = `0%`;
        } else {
          progressBar.style.flexBasis = `${percent}%`;
        }
        currentTime(rearRef.current);
      }

      // if (driverRef.current.src) {
      //   const percent =
      //     (driverRef.current.currentTime / driverRef.current.duration) * 100;
      //   progressBar.style.flexBasis = `${percent}%`;
      //   currentTime(driverRef.current);
      // }

      if (frontLeft.current?.src) {
        const percent =
          (frontLeft.current.currentTime / frontLeft.current.duration) * 100;
        if (isNaN(percent)) {
          progressBar.style.flexBasis = `0%`;
        } else {
          progressBar.style.flexBasis = `${percent}%`;
        }
        currentTime(frontLeft.current);
      }

      if (frontRight.current?.src) {
        const percent =
          (frontRight.current.currentTime / frontRight.current.duration) * 100;
        if (isNaN(percent)) {
          progressBar.style.flexBasis = `0%`;
        } else {
          progressBar.style.flexBasis = `${percent}%`;
        }
        currentTime(frontRight.current);
      }

      if (ptoRef.current?.src) {
        const percent =
          (ptoRef.current.currentTime / ptoRef.current.duration) * 100;
        if (isNaN(percent)) {
          progressBar.style.flexBasis = `0%`;
        } else {
          progressBar.style.flexBasis = `${percent}%`;
        }
        currentTime(ptoRef.current);
      }

      // if (rearLeft.current.src) {
      //   const percent =
      //     (rearLeft.current.currentTime / rearLeft.current.duration) * 100;
      //   progressBar.style.flexBasis = `${percent}%`;
      //   currentTime(rearLeft.current);
      // }

      // if (rearRight.current.src) {
      //   const percent =
      //     (rearRight.current.currentTime / rearRight.current.duration) * 100;
      //   progressBar.style.flexBasis = `${percent}%`;
      //   currentTime(rearRight.current);
      // }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const barHandling = (e: any) => {
    try {
      if (frontRef.current?.src) {
        frontRef.current.currentTime =
          (e.offsetX / progress.offsetWidth) * frontRef.current.duration;
      }

      if (rearRef.current?.src) {
        rearRef.current.currentTime =
          (e.offsetX / progress.offsetWidth) * rearRef.current.duration;
      }

      if (driverRef.current?.src) {
        driverRef.current.currentTime =
          (e.offsetX / progress.offsetWidth) * driverRef.current.duration;
      }

      if (ptoRef.current?.src) {
        ptoRef.current.currentTime =
          (e.offsetX / progress.offsetWidth) * ptoRef.current.duration;
      }

      if (frontLeft.current?.src) {
        frontLeft.current.currentTime =
          (e.offsetX / progress.offsetWidth) * frontLeft.current.duration;
      }

      if (frontRight.current?.src) {
        frontRight.current.currentTime =
          (e.offsetX / progress.offsetWidth) * frontRight.current.duration;
      }

      if (rearLeft.current?.src) {
        rearLeft.current.currentTime =
          (e.offsetX / progress.offsetWidth) * rearLeft.current.duration;
      }

      if (rearRight.current?.src) {
        rearRight.current.currentTime =
          (e.offsetX / progress.offsetWidth) * rearRight.current.duration;
      }
      // handleProgress();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  // video?.addEventListener('timeupdate', handleProgress);
  progress?.addEventListener('click', barHandling);

  // const openFullscreen = (videoUrl: any) => {
  //   mixpanel.track('Recorded Video', {
  //     event: `${videoUrl} Video Expanded`,
  //   });
  //   videoUrl &&
  //     window.open(
  //       `${RoutesConstants.VideoPop}?videoUrl=${videoUrl}`,
  //       `Video URL`,
  //       'max-width: 100%',
  //     );
  // };
  const Cancel = () => {
    // setVediosModal(false);
    dispatch({
      type: TOGGLE_VIDEO_WIDGET,
      payload: {
        toggle: false,
      },
    });
  };

  const moreOptions = () => {
    setOpenMenu(true);
  };
  const onClickSpeedMenu = () => {
    setOpenMenu(false);
    setSpeedMenu(true);
  };

  const selectSpeed = (speed: number, e: any) => {
    setCurrent(e.key);
    const mainVideo: HTMLVideoElement | null = document.getElementById(
      'presentvideo',
    ) as HTMLVideoElement;
    if (mainVideo) {
      mainVideo.playbackRate = speed;
    }
    const elms: NodeListOf<HTMLVideoElement> =
      document.querySelectorAll("[id='subvideo']");
    setSpeedMenu(false);
    for (let i = 0; i < elms.length; i++) {
      elms[i].playbackRate = speed;
    }
  };

  return (
    <>
      <Modal
        title={''}
        visible={videoWidgetData?.toggle}
        className="thubnailVideosFull"
        onCancel={Cancel}
        footer={null}
        width="95vw"
      >
        <Content>
          <Row>
            <Card
              title={tractor?.name}
              bordered={true}
              className="dbWidget videoDbCon"
              extra={
                <Space
                  className="mt0"
                  style={{ marginBottom: 0, position: 'relative' }}
                >
                  <p style={{ fontFamily: 'Montserrat-Medium' }}>
                    <span>{videoTime && getDateTime(videoTime)}</span>
                  </p>
                </Space>
              }
            >
              <CameraGrid2
                handleProgress={handleProgress}
                frontRef={frontRef}
                rearRef={rearRef}
                frontLeft={frontLeft}
                frontRight={frontRight}
                rearLeft={rearLeft}
                rearRight={rearRight}
                driverRef={driverRef}
                ptoRef={ptoRef}
                vedioInfo={vedioInfo}
                showLoader={showLoader}
              />
              {videos.length > 0 && (
                <div className="barDisplay">
                  <Button
                    style={{ display: 'block' }}
                    className="okBtn"
                    onClick={() => playVideo()}
                  >
                    {play ? <CaretRightOutlined /> : <PauseOutlined />}
                  </Button>
                  <div className="vedioprogress">
                    <div className="vedioprogress__filled"></div>
                  </div>
                  <div
                    style={{
                      width: '140px',
                      fontFamily: 'Montserrat-SemiBold',
                    }}
                  >
                    <span ref={curTime}>00:00</span> /{' '}
                    <span ref={fixTime}>00:00</span>
                  </div>
                  <div className="moreDiv">
                    <div onClick={moreOptions}>
                      <MoreOutlined />
                    </div>
                    {openMenu && (
                      <Menu>
                        <Menu.Item
                          key="playback speed"
                          onClick={onClickSpeedMenu}
                        >
                          Playback Speed
                        </Menu.Item>
                      </Menu>
                    )}
                    {speedMenu && (
                      <Menu className="">
                        <Menu.Item
                          key="0.25"
                          onClick={(e) => selectSpeed(0.25, e)}
                        >
                          0.25
                          {current === '0.25' && (
                            <CheckOutlined className="checkmark" />
                          )}
                        </Menu.Item>
                        <Menu.Item
                          key="0.5"
                          onClick={(e) => selectSpeed(0.5, e)}
                        >
                          0.5
                          {current === '0.5' && (
                            <CheckOutlined className="checkmark" />
                          )}
                        </Menu.Item>
                        <Menu.Item
                          key="0.75"
                          onClick={(e) => selectSpeed(0.75, e)}
                        >
                          0.75
                          {current === '0.75' && (
                            <CheckOutlined className="checkmark" />
                          )}
                        </Menu.Item>
                        <Menu.Item key="1" onClick={(e) => selectSpeed(1, e)}>
                          Normal
                          {current === '1' && (
                            <CheckOutlined className="checkmark" />
                          )}
                        </Menu.Item>
                        <Menu.Item
                          key="1.25"
                          onClick={(e) => selectSpeed(1.25, e)}
                        >
                          1.25
                          {current === '1.25' && (
                            <CheckOutlined className="checkmark" />
                          )}
                        </Menu.Item>
                        <Menu.Item
                          key="1.5"
                          onClick={(e) => selectSpeed(1.5, e)}
                        >
                          1.5
                          {current === '1.5' && (
                            <CheckOutlined className="checkmark" />
                          )}
                        </Menu.Item>
                        <Menu.Item
                          key="1.75"
                          onClick={(e) => selectSpeed(1.75, e)}
                        >
                          1.75
                          {current === '1.75' && (
                            <CheckOutlined className="checkmark" />
                          )}
                        </Menu.Item>
                        <Menu.Item key="2" onClick={(e) => selectSpeed(2, e)}>
                          2
                          {current === '2' && (
                            <CheckOutlined className="checkmark" />
                          )}
                        </Menu.Item>
                      </Menu>
                    )}
                  </div>
                </div>
              )}
              <AppLoader loader={showLoader} />
            </Card>
          </Row>
        </Content>
      </Modal>
    </>
  );
};

export default VideoWidget;
