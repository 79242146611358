import React, { useContext, useEffect, useState } from 'react';
import { Modal, notification, Select } from 'antd';
import { ApplicationContext } from '../../context/AppContext';
import './style.css';
import {
  uploadpoly,
  uploadVineRow,
  uploadVines,
  uploadVineLanes,
} from '../../constants/Api';

interface Props {
  showModal: boolean;
  handleCancel: () => void;
}
const categoryData = [
  { id: 1, name: 'Polygon' },
  { id: 2, name: 'VineRow' },
  { id: 3, name: 'Vines' },
  { id: 4, name: 'VineLane' },
];

export const FileUpload: React.FC<Props> = ({
  showModal,
  handleCancel,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [PolygonFile, setPolygonFile] = useState<any>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [category, setCategory] = useState<any>('');

  useEffect(() => {
    if (category && PolygonFile) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, category, PolygonFile]);
  const handleSelect = async (e: any) => {
    setCategory(e);
  };
  const onChangeFile = (event: any) => {
    setPolygonFile(event.target.files[0]);
  };
  const Uploadpolygon = async () => {
    const { organization } = userDetails;
    const formData = new FormData();
    formData.append('files', PolygonFile, 'files.csv');
    if (category === 1) {
      try {
        const resp = await uploadpoly(
          organization.api_url,
          organization.farm.id,
          formData,
        );
        if (resp && resp.msg) {
          handleCancel();
          notification.success({
            message: resp.msg,
          });
        }
      } catch (err: any) {
        notification.error({
          message: err.response?.data.error.message,
        });
      }
    } else if (category === 2) {
      try {
        const resp = await uploadVineRow(
          organization.api_url,
          organization.farm.id,
          formData,
        );
        if (resp && resp.msg) {
          handleCancel();
          notification.success({
            message: resp.msg,
          });
        }
      } catch (err: any) {
        notification.error({
          message: err.response?.data.error.message,
        });
      }
    } else if (category === 3) {
      try {
        const resp = await uploadVines(
          organization.api_url,
          organization.farm.id,
          formData,
        );
        if (resp && resp.msg) {
          handleCancel();
          notification.success({
            message: resp.msg,
          });
        }
      } catch (err: any) {
        notification.error({
          message: err.response?.data.error.message,
        });
      }
    } else if (category === 4) {
      try {
        const resp = await uploadVineLanes(
          organization.api_url,
          organization.id,
          organization.farm.id,
          formData,
        );
        if (resp && resp.msg) {
          handleCancel();
          notification.success({
            message: resp.msg,
          });
        }
      } catch (err: any) {
        notification.error({
          message: err.response?.data.error.message,
        });
      }
    }
  };
  return (
    <Modal
      className="commonPopup addPolygon popupStyles"
      title="Upload Map Elements"
      centered
      width={'50vw'}
      visible={showModal}
      onCancel={handleCancel}
      okText="Save"
      onOk={Uploadpolygon}
      okButtonProps={{
        className: 'btnSave Button ',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel Button',
      }}
    >
      <div className="formRow">
        <div className="formCol">
          <Select
            className="body1"
            style={{ width: '100%' }}
            onSelect={handleSelect}
          >
            {categoryData.map((data: any) => {
              if (data.name !== '') {
                return (
                  <option key={data.id} value={data.id}>
                    {data.name}
                  </option>
                );
              }
            })}
          </Select>
        </div>
        <div className="formCol">
          <div className="commInput pl10">
            <input type="file" onChange={onChangeFile} className="body1" />
          </div>
        </div>
      </div>
      <div className="formRow">
        <div className="formCol ">
          {/* <div className="commInput pl10"> */}
          {/* <input type="file" onChange={onChangeFile} /> */}

          {/* <div className="fileUploadInput commInput">
            <input type="file" onChange={onChangeFile} />
            <span className="button">Choose</span>
            <span className="label" data-js-label>
              No file selected
            </span>
          </div> */}
        </div>
      </div>
      <div className="formCol"></div>
      {/* </div> */}
    </Modal>
  );
};
export default FileUpload;
