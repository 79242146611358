/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-unescaped-entities */
import { Button, Input, Modal, notification } from 'antd';
import React, { useContext, useState } from 'react';
import logo from '../../assets/images/logo192.png';
import { ApplicationContext } from '../../context/AppContext';
import loading_logo from '../../assets/images/loading_logo.gif';
import { lockUnLockReason } from '../../constants/Api';
import { LockedOutTractor } from '../../constants/types';
import maintenance_icon from '../../assets/images/maintenance_btn.svg';
import AppLoader from '../common/AppLoader';

interface Props {
  showModal?: boolean;
  closeModel: (status: boolean) => void;
  status: boolean;
  tractorId: number;
  driveActionUuid: string;
}

export const LockUnLockReason: React.FC<Props> = ({
  showModal,
  closeModel,
  tractorId,
  status,
  driveActionUuid,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [reasonValue, setReasonValue] = useState('');
  const [placeHolder, setPlaceHolder] = useState('reason');
  const updateReason = async () => {
    try {
      setLoader(true);
      const payload: LockedOutTractor = {
        lockout_reason: reasonValue,
        is_tractor_lockout: !status,
        drive_action_uuid: driveActionUuid,
      };
      const resp = await lockUnLockReason(
        userDetails.organization.api_url,
        userDetails.organization.id,
        tractorId,
        payload,
      );
      if (resp && resp.msg) {
        notification.success({
          message: resp.msg,
        });
        closeModel(true);
      }
      setLoader(false);
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error?.message || error?.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Modal
        className="modalContainer teammodal discardmodal mLockout"
        visible={showModal}
        onCancel={() => closeModel(false)}
        width={590}
        closable={false}
        maskClosable={false}
        footer={[
          <>
            <Button
              key="back"
              className="mlockBtn mlockBtnCancel Button"
              onClick={() => closeModel(false)}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              className="mlockBtn mlockBtnConnect Button"
              onClick={updateReason}
              disabled={reasonValue.trim() == '' ? true : false}
            >
              Connect
            </Button>
          </>,
        ]}
      >
        <AppLoader loader={loader} />
        <div className="createteamContainer maintenanceLockModal">
          <div className="createtitle">
            <div className="mlockHead">
              <img src={maintenance_icon} />
              <span className="mlockTxt overline1">MAINTENANCE LOCK</span>
            </div>
            <div className="mlockHelpTxt overline3 fMed">
              This feature will lock the tractor from other users
              <br /> until Maintenance Lock has been disabled
            </div>
            <div className="mlockHelpTxt2 overline2">
              Please give a brief description of why you’re placing this tractor
              on Maintenance Lock
            </div>
            <div className="width100">
              <textarea
                className="mlockTextBox"
                defaultValue={reasonValue}
                value={reasonValue}
                onChange={(e) => setReasonValue(e.target.value)}
              ></textarea>
            </div>
            {/* <div className="pull-left">
              <h1 className="title">Lockout Reason</h1>
            </div>
            <div className="img-div pull-right">
              <img src={logo} />
            </div> */}
          </div>
          {/* <p className="teamdescription">
            <div className="width284">
              <Input
                placeholder={placeHolder}
                onFocus={() => setPlaceHolder('')}
                onBlur={() => setPlaceHolder('reason')}
                defaultValue={reasonValue}
                value={reasonValue}
                onChange={(e) => setReasonValue(e.target.value)}
              />
            </div>
          </p> */}
        </div>
      </Modal>
    </>
  );
};

export default LockUnLockReason;
