import { tractorConstantsNew } from '../../constants/Common';
import { TractorsList } from '../../constants/types';

export const getAvailableTractors = (
  tractors: TractorsList[],
): TractorsList[] =>
  tractors.filter(
    (tractor: TractorsList) =>
      tractor.isAvailable === tractorConstantsNew.available.key,
  );

export const getRemoteDriveTractorsByHearBeat = (
  heartbeats: any[],
  tractors: any[],
) => {
  const hearbeatMap = heartbeats.reduce((a, c) => (a[c.tractor_id] = c), {});
  const tractor_ids = heartbeats.map((item) => item.tractor_id);
  return tractors
    .filter((tractor) => tractor_ids.includes(tractor.id))
    .map((tractor) => {
      tractor.hearbeat = hearbeatMap[tractor.id];
    });
};
