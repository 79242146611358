import React, { useContext, useEffect, useRef } from 'react';
import './style.css';
import { initVideo, stopStream } from './StreamingService';
import { ApplicationContext } from '../../context/AppContext';
import { Modal } from 'antd';
import { createVideoLog, getTurnServerDetails } from '../../constants/Api';
import Timer from '../remotedrive/Timer';

interface Props {
  tractorName: string;
  cameraFace: string;
  setErrorMessage: (errorMessage: string) => void;
  setShowSpinner: (spinner: boolean) => void;
}

const LiveVideo: React.FC<Props> = ({
  tractorName,
  cameraFace,
  setErrorMessage,
  setShowSpinner,
}) => {
  const { userDetails } = useContext(ApplicationContext);
  const id = useRef(0);

  const handleError = (msg: string) => {
    if (msg.includes('[object Object]'))
      msg = 'Probably a network error or  server maybe down';
    setErrorMessage(msg);
  };

  const handleSuccess = (sessionId: number) => {
    const { organization } = userDetails;
    setShowSpinner(false);
    createVideoLog(organization.api_url, organization.id, tractorName, {
      remarks: 'Just started',
      camera: cameraFace,
      session_id: sessionId,
    })
      .then((response) => {
        id.current = response.id;
        localStorage.setItem(window.location.href, response.id);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  useEffect(() => {
    const init = async () => {
      try {
        const baseURL = userDetails.organization.api_url;
        const credentials = await getTurnServerDetails(
          baseURL,
          userDetails.organization.id,
          tractorName,
        );
        const { tractor_ip, proxy_server_url } = credentials;
        const server = `${proxy_server_url}livestream/tractor/${tractor_ip}`;
        // const base = baseURL.split('//')[1].replaceAll('/', '');
        // const server = `wss://${base}:82/livestream/tractor/${tractorName.toLowerCase()}`;
        initVideo(
          server,
          getStreamId(cameraFace),
          handleError,
          credentials,
          handleSuccess,
        );
      } catch (error) {
        handleError('Error getting turn server details');
      }
    };
    if (
      tractorName &&
      tractorName.trim().length > 0 &&
      userDetails &&
      userDetails.organization
    ) {
      init();
    }

    return () => {
      stopStream();
    };
  }, [tractorName, cameraFace, userDetails]);

  const getStreamId = (face: string) => {
    switch (face) {
      case 'front_left':
        return 1;
      case 'front':
        return 2;
      case 'front_right':
        return 3;
      case 'driver':
        return 4;
      case 'rear_left':
        return 5;
      case 'rear':
        return 6;
      case 'rear_right':
        return 7;
      case 'pto':
        return 8;
      default:
        break;
    }
  };

  return <></>;
};

export default LiveVideo;
