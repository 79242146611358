/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-empty */
import React, { useContext, useState } from 'react';
import Layout from 'antd/lib/layout/layout';
import { Avatar, Button } from 'antd';
import closeGreen from '../../assets/images/closeGreen.svg';
import applicationIds from '../../locale/applicationIds.json';
import { ApplicationContext } from '../../context/AppContext';
import { useMixpanel } from 'react-mixpanel-browser';
import './ImpStyle.css';
import EditImplement from './EditImplement';
import { privilagesConstants } from '../../constants/Privilages';
import ProfileIcon from '../../assets/images/defaultProfileIcon.svg';
import AutonomySettings from './AutonomySettings';

interface Props {
  Close: () => void;
  toggleWidget: boolean;
  details: any;
  setRefresh: () => void;
}

const ImplementDetails: React.FC<Props> = ({
  Close,
  toggleWidget,
  details,
  setRefresh,
}: Props) => {
  const { privilegeChecker } = useContext(ApplicationContext);
  const mixpanel = useMixpanel();
  const [editImplement, setEditImplement] = useState<boolean>(false);
  const [autonomyflag, setAutonomyFlag] = useState<boolean>(false);

  const editImpDetails = () => {
    setEditImplement(true);

    mixpanel.track('Edit Implement', {
      event: 'Edit Details Clicked',
    });
  };
  const handleCancel = () => {
    setAutonomyFlag(false);
  };

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn">
          <img
            id={applicationIds.implementsScreen.impDetailsClose}
            src={closeGreen}
            alt="close icon"
            onClick={Close}
          />
        </div>
        {!editImplement && (
          <>
            <div className="profileView empView personeeltab implementsWidget rightWidgetView">
              <div className="proRow rowGapBtm4 proPic">
                {details.image_url && details.image_url !== '' ? (
                  <Avatar src={details.image_url}></Avatar>
                ) : (
                  <Avatar src={ProfileIcon} className="defaulticon"></Avatar>
                )}
              </div>
              <div className="proRow rowGapBtm3 mt20">
                <h4
                  className="headline1"
                  data-testid={`${details.name}-ImplementDetails`}
                >
                  {details?.name}
                </h4>
                <h6 className="headline2">
                  {details?.config_imm?.manufacturer}
                </h6>
              </div>
              <div>
                <table className="fleetTbl">
                  <tr>
                    <td className="fSbd overline1">Model</td>
                    <td className="body1" id="modelName">
                      {details?.config_imm?.model}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: '#F7F7F7' }}>
                    <td className="fSbd overline1">Serial Number</td>
                    <td className="body1">{details?.serial_number}</td>
                  </tr>
                  <tr>
                    <td className="fSbd overline1">Attachment</td>
                    <td className="body1">{details?.attachment_type}</td>
                  </tr>
                  <tr style={{ backgroundColor: '#F7F7F7' }}>
                    <td className="fSbd overline1">Power</td>
                    <td className="body1">{details?.power}</td>
                  </tr>
                  <tr>
                    <td className="fSbd overline1">Floating Hitch</td>
                    <td className="body1">
                      {details?.floating_hitch ? 'Yes' : 'No'}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: '#F7F7F7' }}>
                    <td className="fSbd overline1">Dimensions</td>
                    <td className="body1">
                      Max Width{' '}
                      <span className="dM">{details?.width}&rdquo;</span>
                      <br />
                      Length{' '}
                      <span className="dM">{details?.length}&rdquo;</span>{' '}
                      <br />
                      Height{' '}
                      <span className="dM">{details?.height}&rdquo;</span>{' '}
                      <br />
                      Weight <span className="dS">
                        {details?.weight} lbs
                      </span>{' '}
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td className="flHead">Hour Meter</td>
                    <td className="body1">-</td>
                  </tr>
                  <tr style={{ backgroundColor: '#F7F7F7' }}>
                    <td className="fSbd overline1">Status</td>
                    <td className="body1">
                      {details?.is_active === true ? 'Active' : 'InActive'}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="autonomyBtns">
              <div className="impAutonomyBtn">
                <button onClick={() => setAutonomyFlag(true)}>
                  AUTONOMY SETTINGS
                </button>
              </div>
              {privilegeChecker(privilagesConstants.Edit_Implement_Details) && (
                <div className="impEditBtn saveTxtBtn">
                  <button
                    id={applicationIds.implementsScreen.impEditDetails}
                    onClick={editImpDetails}
                    data-testid="farmEditDetailsLink-ImplementDetails"
                  >
                    EDIT DETAILS
                  </button>
                </div>
              )}
            </div>
          </>
        )}

        {editImplement && (
          <EditImplement
            setEditImplement={() => setEditImplement(false)}
            Close={Close}
            setRefresh={setRefresh}
            implement={details}
          />
        )}
        {autonomyflag && (
          <AutonomySettings
            showModal={autonomyflag}
            handleCancel={handleCancel}
            implement={details}
            Close={Close}
            setRefresh={setRefresh}
          />
        )}
      </div>
    </Layout>
  );
};

export default ImplementDetails;
