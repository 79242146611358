import React, { useState } from 'react';
import './style.css';

interface Props {
  frameHTML: any;
}

const CameraGrid: React.FC<Props> = ({ frameHTML }) => {
  const [index, setIndex] = useState(0);
  return (
    <div className="camerasContainer">
      <div className="containerMain">
        <div
          onClick={() => setIndex(0)}
          className={index === 0 ? 'mainCont' : 'child'}
        >
          {frameHTML('front')}
        </div>
        <div
          onClick={() => setIndex(1)}
          className={index === 1 ? 'mainCont' : 'child'}
        >
          {frameHTML('rear')}
        </div>
        <div
          onClick={() => setIndex(2)}
          className={index === 2 ? 'mainCont' : 'child'}
        >
          {frameHTML('pto')}
        </div>
        <div
          onClick={() => setIndex(3)}
          className={index === 3 ? 'mainCont' : 'child'}
        >
          {frameHTML('front_left')}
        </div>
        <div
          onClick={() => setIndex(4)}
          className={index === 4 ? 'mainCont' : 'child'}
        >
          {frameHTML('front_right')}
        </div>
      </div>
    </div>
  );
};

export default CameraGrid;
