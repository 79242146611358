/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */
import { notification } from 'antd';
import { PubSub } from 'aws-amplify';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { useLocation } from 'react-router-dom';
import {
  LOAD_DRIVING_TRACTORS,
  LOAD_RUNNING_TRACTORS,
} from '../components/remote_drive_new/actions';

import { createLogOut, executeRemoteAVCommand } from '../constants/Api';
import { checkTractorDrivingState } from '../constants/Common';
import { heartBeatTopic } from '../constants/constant';
import { TractorHeartBeat } from '../constants/types';
import { getFCMToken } from '../firebaseInit';
import RoutesConstants from '../routes/RoutesConstant';
import {
  AUTO_DRIVE_LOGOUT_CONFIRM,
  LOAD_REMOTE_DRIVE_TRACTORS,
  SET_POLYGONS_LIST,
} from './actions';
import { ApplicationContext } from './AppContext';
import AutoDriveReducer, { initialState } from './AutoDriveReducer';

export const AutoDriveApplicationContext = createContext({
  AutoDriveReducer: [],
  handleLogout: () => {},
  handlePauseResumeStopLiveCom: (command: string) => {},
} as {
  AutoDriveReducer: any;
  handleLogout: () => void;
  handlePauseResumeStopLiveCom: (command: string) => void;
});

const tractorsMap: Map<number, any> = new Map();
let recentLocation = '/';
const AutoDriveContext: React.FC = ({ children }) => {
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [, appDispatch] = APPReducer;

  const location = useLocation();
  const mixpanel = useMixpanel();
  const [state, dispatch] = useReducer(AutoDriveReducer, initialState);
  const subPubRef = useRef<any>();
  const intervalId = useRef<any>();

  const { remoteAvTractors, drivingTractors } = state;

  // on route change connect mqtt and update tractors routes
  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      remoteAvTractors &&
      remoteAvTractors.length > 0
    ) {
      const temp = remoteAvTractors.filter((remoteAvTractor: any) => {
        return checkTractorDrivingState(remoteAvTractor, userDetails.id);
      });
      if (temp && temp.length > 0) {
        intervalId.current = setInterval(() => {
          pingHandler(temp);
        }, 1000);
        return () => clearInterval(intervalId.current);
      }
    }
  }, [userDetails, remoteAvTractors]);
  useEffect(() => {
    const temp = remoteAvTractors.filter((remoteAvTractor: any) => {
      checkTractorDrivingState(remoteAvTractor, userDetails.id);
    });
    if (temp === 0) {
      clearInterval(intervalId.current);
    }
  }, [remoteAvTractors]);

  const pingHandler = (temp: TractorHeartBeat[]) => {
    if (process.env.REACT_APP_ALPHA_FEATURES?.toLowerCase().includes('iot')) {
      temp.map(async (heartbeat: TractorHeartBeat) => {
        try {
          PubSub.publish(
            `remote_av/heartbeat/${heartbeat.tractor_id}`,

            JSON.stringify({
              drive_action_uuid: heartbeat?.drive_action_details
                ?.current_drive_action as string,
              tractor_id: heartbeat.tractor_id,
            }),
          );
        } catch (error) {
          console.log(error);
        }
      });
    }
  };
  // on route change connect mqtt and update tractors routes
  useEffect(() => {
    userDetails && userDetails.organization && locationChange();
  }, [userDetails, location]);
  const locationChange = () => {
    if (location.pathname === RoutesConstants.LiveMap) {
      subscribeTopic(false);
    } else {
      if (recentLocation === RoutesConstants.LiveMap) subscribeTopic(true);
    }
    recentLocation = location.pathname;
  };
  // heartbeat handler
  const heartBeatHandler = (message: any) => {
    const heartBeat: TractorHeartBeat = JSON.parse(message.toString());
    if (
      heartBeat &&
      heartBeat.organization_id &&
      userDetails.organization_id &&
      heartBeat.organization_id === userDetails.organization_id
    ) {
      tractorsMap.set(heartBeat.tractor_id, heartBeat);
      const list: any = [];
      const entries = Array.from(tractorsMap.entries());
      if (entries && entries.length > 0) {
        entries.map((item: any) => {
          list.push(item[1]);
        });
      }
      // maintain the all tractors heartbeat
      dispatch({
        type: LOAD_REMOTE_DRIVE_TRACTORS,
        payload: list,
      });
    }
  };

  // subscribe and unsubscribe the topics based route
  const subscribeTopic = (isSubscribe: boolean) => {
    if (isSubscribe) {
      if (process.env.REACT_APP_ALPHA_FEATURES?.toLowerCase().includes('iot')) {
        try {
          const sub1 = PubSub.subscribe(heartBeatTopic).subscribe({
            next: (data: any) => {
              heartBeatHandler(JSON.stringify(data.value));
            },
            error: (error: any) => console.error(error),
          });
          // const sub2 = PubSub.subscribe('remote_av/heartbeat/94').subscribe({
          //   next: (data: any) => {
          //     console.log('data', JSON.parse(data.value));
          //   },
          //   error: (error: any) => console.error(error),
          // });
          subPubRef.current = sub1;
          // subPubRefForPing.current = sub2;
          return () => {
            sub1.unsubscribe();
            // sub2.unsubscribe();
          };
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      subPubRef && subPubRef.current && subPubRef.current.unsubscribe();
    }
  };
  // to stop auto drive running tractors with login users
  const handlePauseResumeStopLiveCom = async (command: string) => {
    const temp = remoteAvTractors.filter((remoteAvTractor: any) =>
      checkTractorDrivingState(remoteAvTractor, userDetails.id),
    );
    temp.map(async (heartbeat: TractorHeartBeat, index: number) => {
      try {
        const { organization } = userDetails;

        const payload: any = {
          organization_id: organization.id,
          operator_id: userDetails.id,
          action: command,
          planner: 'remote_av',
          tractor_id: heartbeat.tractor_id,
          drive_action_uuid:
            heartbeat?.drive_action_details?.current_drive_action,
        };
        if (command === 'pause' || command === 'resume') {
          payload.details = 'mos_cloud';
          payload.category = 'mos_cloud';
          payload.type = 'hard';
        }
        if (command === 'stop') {
          payload.category = 'user_initiated';
          payload.details = '';
          payload.type = '';
        }

        const { msg } = await executeRemoteAVCommand(
          organization.api_url,
          payload,
        );

        notification.success({
          message: msg,
        });
      } catch (err: any) {
        notification.error({
          message: err.response?.data.error.message || err?.message,
        });
      }
      command === 'stop' && temp.length - 1 === index && confirmHandleLogout();
    });
  };

  const refreshPage = () => window.location.reload();
  // confirm logout checking
  const handleLogout = () => {
    const temp = remoteAvTractors.filter((remoteAvTractor: any) =>
      checkTractorDrivingState(remoteAvTractor, userDetails.id),
    );
    if (temp.length === 0) {
      confirmHandleLogout();
    } else {
      dispatch({
        type: AUTO_DRIVE_LOGOUT_CONFIRM,
        payload: false,
      });
      dispatch({
        type: AUTO_DRIVE_LOGOUT_CONFIRM,
        payload: true,
      });
    }
  };

  const confirmHandleLogout = async () => {
    try {
      const token = await getFCMToken();
      const payload = {
        notification_token: token,
      };
      appDispatch({
        type: SET_POLYGONS_LIST,
        payload: [],
      });
      await createLogOut(userDetails.organization.api_url, payload);
      refreshPage();
    } catch (err: any) {
      mixpanel.track('LogOut', {
        event: 'Could not clear notification token',
      });
      refreshPage();
    } finally {
      mixpanel.track('Logout');
      mixpanel.reset();
      localStorage.clear();
    }
  };

  const getStartedTractors = () => {
    const temp = remoteAvTractors.filter((remoteAvTractor: any) =>
      checkTractorDrivingState(remoteAvTractor, userDetails.id),
    );
    dispatch({
      type: LOAD_DRIVING_TRACTORS,
      payload: temp,
    });
  };

  const getRunningTractors = () => {
    const temp = drivingTractors.filter(
      (heartbeat: any) =>
        heartbeat?.planning_manager?.planner_status === 'running',
    );
    dispatch({
      type: LOAD_RUNNING_TRACTORS,
      payload: temp,
    });
  };

  useEffect(() => {
    getStartedTractors();
  }, [remoteAvTractors]);

  useEffect(() => {
    getRunningTractors();
  }, [drivingTractors]);

  return (
    <AutoDriveApplicationContext.Provider
      value={{
        AutoDriveReducer: [state, dispatch],
        handleLogout,
        handlePauseResumeStopLiveCom,
      }}
    >
      {children}
    </AutoDriveApplicationContext.Provider>
  );
};

export default AutoDriveContext;
