/* eslint-disable react/react-in-jsx-scope */
import { BasicTooltip } from '@nivo/tooltip';

export const bar_hour_tooltip = ({
  id,
  value,
  indexValue,
}: {
  id: string | number;
  value: string | number;
  indexValue: string | number;
}) => {
  const tractor_name = id; // "PS1"
  let cost: string | number = value; // "USD 11.1"
  const date_str = indexValue; // "Jan 01"
  cost = cost ? cost : 0;
  if (typeof cost == 'string') {
    cost = parseFloat(cost);
  }

  return (
    <BasicTooltip
      id={
        <div className="bg-white-500">
          <div className="text-sm">
            <span>
              <strong>
                {tractor_name === 'total' ? 'Total' : tractor_name}
              </strong>
            </span>
          </div>
          <div className="text-sm">
            <strong>Date:</strong> {date_str}
          </div>
          <div className="text-sm">
            <strong>Cost:</strong> USD {cost.toFixed(2)}
          </div>
        </div>
      }
    />
  );
};
