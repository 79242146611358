import { LeftOutlined } from '@ant-design/icons';
import { Collapse, Tabs } from 'antd';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { ApplicationContext } from '../../context/AppContext';
import opaTractor from '../../assets/images/opaTractor.svg';
import mphImg from '../../assets/images/mphIcon.svg';
import acrImg from '../../assets/images/acrIcon.svg';
import socImg from '../../assets/images/socIcon.svg';

import {
  calPercentage,
  calTotal,
  colors,
  colors1,
  getDate,
  getHour,
  secondsToHms,
} from '../../constants/Common';
import { JagDetails } from '../../constants/types';
import { Pie } from '@ant-design/charts';
import { useMixpanel } from 'react-mixpanel-browser';
import { getJAGFleetReport } from '../../constants/Api';

const { Panel } = Collapse;
const { TabPane } = Tabs;

export interface DataItem {
  type: string;
  value: number;
  colorField?: string;
}

interface Props {
  selectedJumpnGo: any;
  jagDetails: JagDetails;
}

const JumpAndGoMoreDetail: React.FC<Props> = ({
  selectedJumpnGo,
  jagDetails,
}: Props) => {
  const mixpanel = useMixpanel();
  const [data, setData] = useState<DataItem[]>([]);
  // const [fleetReportData, setFleetReportData] = useState<any>();
  const [fleetReportResponse, setFleetReportResponse] = useState<any>();
  const { userDetails } = useContext(ApplicationContext);
  const isNew = useRef<boolean>(false);

  const newDataDisplay = (data: any) => {
    let total = 0;
    const { planning_manager_analytics } = data;
    const {
      operator_assist_hours = 0,
      manual_hours = 0,
      auto_drive_hours = 0,
    } = planning_manager_analytics;

    const result = calTotal({
      operator_assist_hours,
      manual_hours,
      auto_drive_hours,
    });
    if (result.state) {
      total = result.total;
    }
    if (!total) {
      isNew.current = false;
      oldDataDisplay(jagDetails);
      return;
    }
    const dataItem: DataItem[] = [
      {
        type: 'Manual',
        value: calPercentage(manual_hours, total),
      },
      {
        type: 'Operator Assist',
        value: calPercentage(operator_assist_hours, total),
      },
      {
        type: 'Auto Drive',
        value: calPercentage(auto_drive_hours, total),
      },
    ];
    setData(dataItem);
  };

  const oldDataDisplay = (data: any) => {
    let total = 0;
    const { idle_hours = 0, manual_hours = 0, autonomy_hours = 0 } = data;
    const result = calTotal({ idle_hours, manual_hours, autonomy_hours });
    if (result.state) {
      total = result.total;
    }
    const dataItem: DataItem[] = [
      {
        type: 'Idle',
        value: calPercentage(Number(idle_hours), total),
      },
      {
        type: 'Manual',
        value: calPercentage(manual_hours, total),
      },
      {
        type: 'Autonomous',
        value: calPercentage(autonomy_hours, total),
      },
    ];
    setData(dataItem);
  };

  const config: any = {
    className: 'pieChartClass',
    // appendPadding: 10,
    data: data,
    angleField: 'value',
    colorField: 'type',
    color: isNew.current ? colors1 : colors,
    radius: 0.2,
    autofit: true,
    // legend: false,
    tooltips: {
      // display: false,
      type: 'inner',
      // offset: '-30%',
      content: function content(_ref: any) {
        // return ''.concat(_ref.value, '%');
        return '';
      },
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    fill: {
      // display: false,
      // type: 'inner',
      // offset: '-30%',
      content: function content(_ref: any) {
        // return ''.concat(_ref.value, '%');
        return '';
      },
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    legend: false,
    // label: {
    //   type: 'inner',
    //   offset: '-30%',
    //   content: function content(_ref: any) {
    //     console.log(_ref);
    //     // return ''.concat(_ref.value, '%');
    //     // return ''.concat(_ref.value, '');
    //     return '';
    //   },
    //   style: {
    //     fontSize: 14,
    //     textAlign: 'center',
    //   },
    // },
    interactions: [{ type: 'element-active' }],
    // color: colors,
  };

  const getJAGFleetReportData = async () => {
    try {
      const response = await getJAGFleetReport(
        userDetails.organization.api_url,
        userDetails.organization_id,
        selectedJumpnGo.drive_action_uuid,
      );
      // const data = formatFleetAnalyticsData(response);
      setFleetReportResponse(response);
      // setFleetReportData(data);
    } catch (error) {
      // console.error(error);
    }
  };

  useEffect(() => {
    if (selectedJumpnGo && selectedJumpnGo.drive_action_uuid) {
      getJAGFleetReportData();
    }
  }, [selectedJumpnGo]);

  useEffect(() => {
    if (jagDetails && jagDetails?.planning_manager_analytics) {
      isNew.current = true;
      newDataDisplay(jagDetails);
    } else if (jagDetails) {
      oldDataDisplay(jagDetails);
    }
  }, [jagDetails]);

  const tab1Content = (
    <div className="operationsBlk parentAccordion anaOpup">
      <div className="cardHeader">
        <h4>
          <img src={opaTractor} alt="" /> {selectedJumpnGo?.tractor?.name}{' '}
          {getDate(
            selectedJumpnGo?.drive_action_start_date_time ||
              selectedJumpnGo?.start_time,
          )}
          {/* <CloseOutlined
            onClick={() => {
              mixpanel.track('Analytics Map', {
                event: `Jump & Go More Details Closed`,
              });
              setShowJNGModel(false);
              setOpenTab('1');
            }}
          /> */}
        </h4>
      </div>
      <div className="cardBody">
        <table>
          <tr>
            <td className="subTitle2">Operators:</td>
            <td>
              {selectedJumpnGo?.operator.first_name}{' '}
              {selectedJumpnGo?.operator.last_name}
            </td>
          </tr>
          <tr>
            <td className="subTitle2">Start Time:</td>
            <td>
              {getHour(
                selectedJumpnGo?.drive_action_start_date_time ||
                  selectedJumpnGo?.start_time,
              )}
            </td>
            <td className="subTitle2">End Time:</td>
            <td>
              {getHour(
                selectedJumpnGo?.drive_action_finish_date_time ||
                  selectedJumpnGo?.finish_time,
              )}
            </td>
          </tr>
          <tr>
            <td className="subTitle2">Operation Type:</td>
            <td>{jagDetails?.operation_type || '-'}</td>
          </tr>
        </table>
        <Collapse
          defaultActiveKey={['1']}
          accordion
          expandIconPosition="right"
          className="childAccordion cardAccordion"
          expandIcon={({ isActive }) => (
            <LeftOutlined rotate={isActive ? 270 : 270} />
          )}
        >
          <Panel
            header="Operation Analytics"
            key="1"
            className="selectTractorOpt operationAnalyticsBlk"
          >
            <div className="operationAnalyticsInrBlk">
              <div className="anaGraphSec">
                <div className="analeftSec">
                  <h5>Time Distribution</h5>
                  <div className="pieGraphSec">
                    <div className="graphWrapper">
                      <Pie {...config} style={{ height: '400px' }} />
                    </div>
                    <div className="graphTbl" style={{ marginLeft: 0 }}>
                      {isNew.current ? (
                        <table>
                          <tr>
                            <td className="w115">
                              <span className="manualdriveBox"></span>Manual
                            </td>
                            <td className="w55">
                              {jagDetails.planning_manager_analytics
                                ?.manual_hours
                                ? `${secondsToHms(
                                    jagDetails.planning_manager_analytics
                                      .manual_hours,
                                    true,
                                  )}`
                                : '-'}
                            </td>
                          </tr>
                          <tr>
                            <td className="w115">
                              <span className="operatorassitsBox"></span>
                              Operator Assist
                            </td>
                            <td className="w55">
                              {jagDetails.planning_manager_analytics
                                ?.operator_assist_hours
                                ? `${secondsToHms(
                                    jagDetails?.planning_manager_analytics
                                      ?.operator_assist_hours,
                                    true,
                                  )}`
                                : '-'}
                            </td>
                          </tr>
                          <tr>
                            <td className="w115">
                              <span className="autodriveBox"></span>Auto Drive
                            </td>
                            <td className="w55">
                              {jagDetails.planning_manager_analytics
                                ?.auto_drive_hours
                                ? `${secondsToHms(
                                    jagDetails.planning_manager_analytics
                                      ?.auto_drive_hours,
                                    true,
                                  )}`
                                : '-'}
                            </td>
                          </tr>
                        </table>
                      ) : (
                        <table>
                          <tr>
                            <td className="w115">
                              <span className="idleBox"></span>Idle
                            </td>
                            <td className="w55">
                              {jagDetails?.planning_manager_analytics
                                ?.idle_hours
                                ? `${secondsToHms(
                                    jagDetails?.planning_manager_analytics
                                      ?.idle_hours,
                                    true,
                                  )}`
                                : '-'}
                            </td>
                          </tr>
                          <tr>
                            <td className="w115">
                              <span className="manualBoxForPie"></span>Manual
                            </td>
                            <td className="w55">
                              {jagDetails?.planning_manager_analytics
                                ?.manual_hours
                                ? `${secondsToHms(
                                    jagDetails?.planning_manager_analytics
                                      ?.manual_hours,
                                    true,
                                  )}`
                                : '-'}
                            </td>
                          </tr>
                          <tr>
                            <td className="w115">
                              <span className="autonomousBox"></span>Autonomous
                            </td>
                            <td className="w55">
                              {jagDetails?.autonomy_hours
                                ? `${secondsToHms(
                                    jagDetails?.autonomy_hours,
                                    true,
                                  )}`
                                : '-'}
                            </td>
                          </tr>
                        </table>
                      )}
                      {/* <table>
                      <tr>
                        <td>
                          {jagDetails?.idle_hours
                            ? `${secondsToHms(jagDetails?.idle_hours, true)}`
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {jagDetails?.manual_hours
                            ? `${secondsToHms(jagDetails?.manual_hours, true)}`
                            : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {jagDetails?.autonomy_hours
                            ? `${secondsToHms(
                                jagDetails?.autonomy_hours,
                                true,
                              )}`
                            : '-'}
                        </td>
                      </tr>
                    </table> */}
                    </div>
                  </div>
                </div>
                <div className="anarightSec">
                  <div>
                    <h6>Avg Speed</h6>
                    <img src={mphImg} alt="" />
                    <h5>
                      {jagDetails?.avg_speed
                        ? `${Number(
                            Number(jagDetails.avg_speed) * 2.236936,
                          ).toFixed(1)} Mph`
                        : '-'}
                    </h5>
                  </div>
                  <div>
                    <h6>Energy Used</h6>
                    <img src={socImg} alt="" />
                    <h5>
                      {jagDetails?.jng_start_soc
                        ? `${
                            jagDetails?.jng_start_soc -
                            jagDetails?.jng_finish_soc
                          }%`
                        : '-'}{' '}
                      SOC
                      <br />
                      <span>
                        {jagDetails?.energy
                          ? `${Number(jagDetails?.energy)
                              .toFixed(2)
                              .toLocaleString()} kwh`
                          : '-'}
                      </span>
                    </h5>
                  </div>
                  <div>
                    <h6>Coverage</h6>
                    <img src={acrImg} alt="" />
                    <h5>
                      {jagDetails?.distance
                        ? `${Number(jagDetails?.distance)
                            .toFixed(2)
                            .toLocaleString()} Miles`
                        : '-'}
                    </h5>
                  </div>
                  <div>
                    <h6 className="acreshead">Covered Acres</h6>
                    <h6>
                      {jagDetails?.area_covered_in_acre
                        ? `${Number(jagDetails?.area_covered_in_acre)
                            .toFixed(2)
                            .toLocaleString()} Acres`
                        : '-'}
                    </h6>
                  </div>
                  <div>
                    <h6 className="acreshead">Covered Vine Rows</h6>
                    <h6>
                      {jagDetails?.num_of_vine_rows_covered
                        ? `${Number(
                            jagDetails?.num_of_vine_rows_covered,
                          ).toFixed(0)}`
                        : '-'}
                    </h6>
                  </div>
                  <div>
                    <h6 className="acreshead">Acres per hour</h6>
                    <h6>
                      {jagDetails?.acres_per_hours
                        ? `${Number(jagDetails?.acres_per_hours).toFixed(2)}`
                        : '-'}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="anaOefcSec">
                <div className="analeftSec">
                  <h5>Operational Efficiency</h5>
                  <span className="w5per">Electricity Rate</span>
                  <span className="w5per">
                    {jagDetails?.electricity_rate
                      ? `$${Number(jagDetails?.electricity_rate).toFixed(2)}`
                      : '-'}{' '}
                    / Kwh
                  </span>
                  <span className="w5per">Diesel Rate</span>
                  <span className="w5per">
                    {jagDetails?.electricity_rate
                      ? `$${Number(jagDetails?.diesel_cost).toFixed(2)}`
                      : '-'}{' '}
                    / Gallon
                  </span>
                </div>
                <div className="anaOefcRightSec">
                  <h5>Savings</h5>
                  <span className="w5per">Energy</span>
                  <span className="w5per">
                    {jagDetails?.operational_savings
                      ? `$${Number(jagDetails?.operational_savings).toFixed(2)}`
                      : '-'}
                  </span>
                  <div>
                    <span className="w5per">
                      co<sub>2</sub> Reduction
                    </span>
                    <span className="w5per">
                      {jagDetails?.aggregate_emission_reduction
                        ? `${Number(
                            jagDetails?.aggregate_emission_reduction,
                          ).toFixed(2)} kg`
                        : '-'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    </div>
  );

  let tab2Content = <></>;

  if (fleetReportResponse) {
    const {
      idle,
      manual,
      auto_drive: { follow, go_to },
      operator_assist: { row_drive, safe_drive, shadow_drive },
    } = fleetReportResponse;
    tab2Content = (
      <table className="table-center">
        <thead>
          <tr>
            <th rowSpan={2} colSpan={2}>
              Idle
            </th>
            <th rowSpan={2} colSpan={2}>
              Manual
            </th>
            <th colSpan={6}>Operator Assist</th>
            <th colSpan={4}>Auto Drive</th>
          </tr>
          <tr>
            <th colSpan={2}>SAFE DRIVE</th>
            <th colSpan={2}>SHADOW DRIVE</th>
            <th colSpan={2}>ROW DRIVE</th>
            <th colSpan={2}>GOTO</th>
            <th colSpan={2}>FOLLOW</th>
          </tr>
          <tr>
            <th>Hours</th>
            <th>Miles</th>
            <th>Hours</th>
            <th>Miles</th>
            <th>Hours</th>
            <th>Miles</th>
            <th>Hours</th>
            <th>Miles</th>
            <th>Hours</th>
            <th>Miles</th>
            <th>Hours</th>
            <th>Miles</th>
            <th>Hours</th>
            <th>Miles</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{idle?.hours ? secondsToHms(idle?.hours, true) : 0}</td>
            <td>{idle?.distance}</td>
            <td>{manual?.hours ? secondsToHms(manual?.hours, true) : 0}</td>
            <td>{manual?.distance}</td>
            <td>
              {safe_drive?.hours ? secondsToHms(safe_drive?.hours, true) : 0}
            </td>
            <td>{safe_drive?.distance}</td>
            <td>
              {shadow_drive.hours ? secondsToHms(shadow_drive?.hours, true) : 0}
            </td>
            <td>{shadow_drive?.distance}</td>
            <td>
              {row_drive?.hours ? secondsToHms(row_drive?.hours, true) : 0}
            </td>
            <td>{row_drive?.distance}</td>
            <td>{go_to?.hours ? secondsToHms(go_to?.hours, true) : 0}</td>
            <td>{go_to?.distance}</td>
            <td>{follow?.hours ? secondsToHms(follow?.hours, true) : 0}</td>
            <td>{follow?.distance}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  const onChange = (key: string) => {
    // console.log(key);
  };

  return (
    <Tabs
      defaultActiveKey="1"
      onChange={onChange}
      className="fleetAnalyticsTab"
    >
      <TabPane tab="Report" key="1">
        {tab1Content}
      </TabPane>
      <TabPane tab="Data" key="2">
        {tab2Content}
      </TabPane>
    </Tabs>
  );
};

export default JumpAndGoMoreDetail;
