import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, notification, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import './style.css';
import { ApplicationContext } from '../../context/AppContext';
import { selectJumpAndGo } from '../../constants/Api';
import { getDateTime, getDateTimes } from '../../constants/Common';
import { useMixpanel } from 'react-mixpanel-browser';

interface Props {
  showModal: boolean;
  handleCancel: () => void;
  jumpandgodata: [];
  task_uuid: string;
  jumpandgoTractorListdata: [];
}
const AddJumpAndGOModel: React.FC<Props> = ({
  showModal,
  handleCancel,
  jumpandgodata,
  task_uuid,
  jumpandgoTractorListdata,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [driveActionuuids, setDriveActionuuids] = useState<any>([]);
  const [filteredJumpAndGoArr, setFilteredJumpAndGoArr] = useState<any>();
  const mixpanel = useMixpanel();
  const [test, setTest] = useState<any[]>([]);
  useEffect(() => {
    checkJumpAndGoRecordsExist();
  }, []);

  const checkJumpAndGoRecordsExist = () => {
    if (jumpandgoTractorListdata.length !== 0) {
      jumpandgoTractorListdata.map((item: any) => {
        test.push(item.drive_action_uuid);
      });
    } else {
      setTest([]);
    }
    const filteredJumpAndGoData = jumpandgodata.filter((item: any) => {
      return !test.includes(item.drive_action_uuid);
    });
    setFilteredJumpAndGoArr(filteredJumpAndGoData);
  };

  const columns = [
    {
      title: `${t(translate.baseStation.tractorName)}`,
      dataIndex: 'name',
      width: '30%',
    },
    {
      title: `${t(translate.taskmaster.starttime)}`,
      dataIndex: 'starttime',
      width: 190,
    },
    {
      title: `${t(translate.taskmaster.finishtime)}`,
      dataIndex: 'finishtime',
      width: 190,
    },
  ];

  const data: {
    key: number;
    name: string;
    number: string;
    starttime: string;
    finishtime: string;
    drive_action_uuid: number;
  }[] = [];

  filteredJumpAndGoArr &&
    filteredJumpAndGoArr.map((item: any) => {
      data.push({
        key: item.id,
        name: item.tractor.name,
        number: item.tractor.serial_number,
        starttime: getDateTimes(item.drive_action_start_date_time),
        finishtime: getDateTimes(item.drive_action_finish_date_time),
        drive_action_uuid: item.drive_action_uuid,
      });
    });

  const handleSave = async () => {
    if (driveActionuuids.length > 0) {
      const payload = {
        task_uuid: task_uuid,
        drive_action_uuids: driveActionuuids,
      };
      try {
        await selectJumpAndGo(userDetails.organization.api_url, payload);
        mixpanel.track('AddedJumpAndGo', {
          event: 'Jump And Go Added Sucessfully',
        });
      } catch (error: any) {
        mixpanel.track('AddedJumpAndGoFailed', {
          event: 'Jump And Go Added Failed',
        });
        notification.error({
          message: error.response?.data.error.message,
        });
      }
    }
    handleCancel();
  };

  const rowSelection = {
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      selectedRows.map((record: any) => {
        if (!driveActionuuids.includes(record.drive_action_uuid)) {
          setDriveActionuuids((prev: any) => [
            ...prev,
            record.drive_action_uuid,
          ]);
        }
      });
    },
  };

  return (
    <Modal
      title={t(translate.taskmaster.selectJumpAndGoTractors)}
      visible={showModal}
      className="baseModalContainer addJNGClass betaModal"
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} className="btnCancel">
          {t(translate.buttons.cancel)}
        </Button>,
        <Button key="submit" onClick={handleSave} className="btnSave">
          {t(translate.buttons.save)}
        </Button>,
      ]}
    >
      <div style={{ marginBottom: 6 }}></div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={false}
        sticky={true}
        scroll={{ scrollToFirstRowOnChange: true, y: 400 }}
        className="tblDft"
      />
    </Modal>
  );
};

export default AddJumpAndGOModel;
