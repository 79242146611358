import React, { useContext, useEffect, useState } from 'react';
import { Input, notification } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import { getRoute } from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { getDateTimes, getTime } from '../../constants/Common';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import { RoutesList } from '../../constants/types';

const { Search } = Input;

interface Routes {
  created_by: string;
  created_date_time: string;
  id: number;
  name: string;
}

const Routines: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [totalcount, settotalcount] = useState<number>();
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [routines, setRoutines] = useState<Routes[]>([]);

  const getRoutes = async () => {
    try {
      setLoader(true);
      const { organization } = userDetails;
      let records = await getRoute(
        organization.api_url,
        organization.farm.id,
        search,
      );
      records = records && records.length > 0 ? records : [];
      settotalcount(records.length);
      const routesList: Routes[] = [];
      records.map((route: RoutesList) => {
        const obj: Routes = {
          id: route.id,
          created_by: route.created_by
            ? route.created_by.first_name + ' ' + route.created_by.last_name
            : '-',
          created_date_time: route.created_date_time
            ? getDateTimes(route.created_date_time)
            : '-',
          name: route.name ? route.name : '-',
        };
        routesList.push(obj);
      });
      setRoutines(routesList);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) getRoutes();
  }, [userDetails, search]);

  const columns = [
    {
      title: `${t(translate.routines.name)}`,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: `${t(translate.routines.createdby)}`,
      dataIndex: 'created_by',
      key: 'created_by',
    },
    {
      title: `${t(translate.routines.createdTime)}`,
      dataIndex: 'created_date_time',
      key: 'created_date_time',
      // width: '240px',
    },
  ];

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (value.length <= 2) {
      if (search != '') {
        setRoutines([]);
      }
      setSearch('');
    } else {
      setSearch(value);
    }
  };

  return (
    <Layout>
      <div className="mainContent">
        <Content>
          <div className="tblContainer softwareUpdate">
            <div className="tblTopOutSec searchFilters filterGap40">
              <div className="searchSec">
                <Search
                  id="search-field"
                  placeholder="Search Routines"
                  onChange={(e) => handleSearch(e)}
                  // style={{ width: 200 }}
                />
              </div>
            </div>
            <div className="tblDft farmTabsTbl posRel">
              <InfiniteScrollTable
                columns={columns}
                loading={loader}
                hasMore={hasMore}
                filterData={routines}
                totalcount={totalcount}
                handleLoadMore={handleLoadMore}
                filename="Routines"
              />
            </div>
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default Routines;
