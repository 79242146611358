import React, { useState, useContext, useEffect } from 'react';
import { Card, DatePicker, notification, Space, Select } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import { ApplicationContext } from '../../context/AppContext';
import {
  getDateTimes,
  guardRailtypes,
  tractorSort,
  fromToDateWrapper,
  getTractorAvailableState,
} from '../../constants/Common';
import { getGuardrailsList, getTractorsListData } from '../../constants/Api';
import 'react-datepicker/dist/react-datepicker.css';
import {
  GuardrailsList,
  TractorHeartBeat,
  TractorsList,
} from '../../constants/types';
import { useTranslation } from 'react-i18next';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import translate from '../../locale/en_translate.json';
import GuardRailPopup from './GuardRailPopup';
import moment from 'moment';
import CSelect from '../common/CSelect';

const { RangePicker } = DatePicker;

interface TractorResponseType {
  label: string;
  value: number | string | boolean;
  disabled: boolean;
  index: number | string;
  isAvailable?: string;
}

const Guardrails: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [guardrailsData, setGuardrailsData] = useState<GuardrailsList[]>([]);
  const [tractors, setTractorsData] = useState<TractorResponseType[]>([]);
  const [pageSize, setPagesize] = useState<any>(25);
  const [tractorId, setTractorId] = useState<string>();
  const [fromtime, setfromTime] = useState<any>(
    moment().subtract(1, 'day').toDate(),
  );
  const [totime, settoTime] = useState<any>(moment().toDate());
  const [guardrailstype, setguardrails] = useState<string>();
  const [loader, setLoader] = useState<boolean>(false);
  const [showGuardrailModel, setShowGuardrailModel] = useState(false);
  const [selectedGuardRail, setSelectedGuardRail] = useState<GuardrailsList>();
  const [totalcount, settotalcount] = useState<any>();
  const [filterData, setFilter] = useState<GuardrailsList[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(() => {
    if (fromtime && totime) {
      const init = async () => {
        await getGuardRailsListData();
      };
      if (userDetails && userDetails.organization) init();
    }
  }, [userDetails, pageNumber, tractorId, guardrailstype, fromtime, totime]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
    }
  }, [userDetails]);

  const getTractorsList = async () => {
    try {
      const tractor: { records: TractorsList[] } = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractorData: TractorResponseType[] = data.map(
        (d: TractorsList, index: number) => {
          return {
            label: d.name,
            value: d.id,
            disabled: false,
            index: d.id,
            id: d.id,
            connection: d.connection,
            isOnline: d.connection === 'online' ? true : false,
            isAvailable: getTractorAvailableState(d.connection, d?.heartbeat),
            key: index,
          };
        },
      );
      const arr = tractorSort(tractorData, 'label');
      setTractorsData(arr);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
      notification.error({
        message: error.response?.data.error.message || error.message,
      });
    }
  };

  const getGuardRailsListData = async () => {
    try {
      setLoader(true);
      const guardlist = await getGuardrailsList(
        userDetails.organization.api_url,
        tractorId ? tractorId : '',
        fromtime ? fromtime.getTime() : '',
        totime ? totime.getTime() : '',
        guardrailstype ? guardrailstype : '',
        pageNumber,
        pageSize,
      );
      settotalcount(guardlist._metadata.total_records_count);
      const data =
        guardlist && guardlist.records && guardlist.records.length > 0
          ? guardlist.records
          : [];
      const guardRailList: any = [];
      data.map((record: GuardrailsList, index: number) => {
        const obj = {
          tractor_id: record.tractor?.id,
          id: record?.id,
          name: record.tractor?.name,
          camera: record.camera,
          startTime: getDateTimes(record.created_date_time),
          guard_rail_type: record.guard_rail_type,
          jump_and_go_uuid: record.jump_and_go_uuid,
          guard_rail_triggered_uuid: record.guard_rail_triggered_uuid,
          key: index,
        };
        guardRailList.push(obj);
      });
      if (data.length > 0) {
        if (pageNumber === 1) {
          setGuardrailsData(guardRailList);
          setFilter(guardRailList);
        } else {
          setGuardrailsData(guardRailList);
          setFilter([...filterData, ...guardRailList]);
        }
      } else {
        setGuardrailsData(guardRailList);
        setFilter(guardRailList);
      }
      if (data.length < 25) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setLoader(false);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };

  const columns = [
    {
      title: `${t(translate.baseStation.tractorName)}`,
      dataIndex: 'name',
      // sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: `${t(translate.guardrails.type)}`,
      dataIndex: 'guard_rail_type',
    },
    {
      title: `${t(translate.guardrails.camera)}`,
      dataIndex: 'camera',
    },
    {
      title: `${t(translate.taskmaster.starttime)}`,
      dataIndex: 'startTime',
      // width: '240px',
    },
    {
      title: `${t(translate.dashboard.jumpandgo)}`,
      dataIndex: 'jump_and_go_uuid',
    },
  ];

  const handleSelect = (e: any) => {
    // eslint-disable-next-line no-console
    console.log('handleSelect', e);
    guardrailsData.length = 0;
    filterData.length = 0;
    setPageNumber(1);
    setTractorId(e);
  };

  const handleSelectGuardrails = async (e: any) => {
    setguardrails(e);
    setPageNumber(1);
    guardrailsData.length = 0;
    filterData.length = 0;
  };

  const handleGuardRailSelection = (record: GuardrailsList) => {
    setSelectedGuardRail(record);
    setShowGuardrailModel(true);
  };

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const onDateRangeChange = (dates: any) => {
    setGuardrailsData([]);
    setFilter([]);
    setPageNumber(1);
    guardrailsData.length = 0;
    filterData.length = 0;
    if (dates) {
      const [from, to] = fromToDateWrapper(dates);
      setfromTime(from.toDate());
      settoTime(to.toDate());
    } else {
      setfromTime(null);
      settoTime(null);
    }
  };

  function disabledDate(current: any) {
    return current && current > moment();
  }

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(filterData);
  }, [filterData]);

  return (
    <Layout>
      <div className="mainContent">
        <Content>
          <div className="tblContainer softwareUpdate">
            <Card
              bordered={true}
              className="tcktsBlk searchFilters filterGap40"
            >
              <Space
                className="mt0"
                style={{ marginBottom: 0, position: 'relative' }}
              >
                <CSelect
                  list={tractors.map((item) =>
                    Object.assign({
                      id: item.index,
                      name: item.label,
                      isAvailable: item.isAvailable,
                    }),
                  )}
                  selectTrigger={(selected) => {
                    handleSelect(selected ? selected?.id : 0);
                  }}
                  placeholder="All Tractors"
                  label="Tractor"
                  isAll={true}
                  tractorStatus={true}
                  className="dropdownStyle slkttractor tractorField"
                />
                <Select
                  // style={{ width: 180 }}
                  defaultValue="Select Guardrails"
                  onSelect={handleSelectGuardrails}
                  className="dropdownStyle customField"
                >
                  {guardRailtypes.map((data: string, index: any) => {
                    if (data !== '') {
                      return (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      );
                    } else {
                      return (
                        <option key="All" value={data}>
                          All
                        </option>
                      );
                    }
                  })}
                </Select>
                <RangePicker
                  format="YYYY-MM-DD"
                  onChange={onDateRangeChange}
                  className="dropdownStyle dW250_date dateFilter"
                  disabledDate={disabledDate}
                  defaultValue={[moment(fromtime), moment(totime)]}
                />
              </Space>
            </Card>

            <div className="tblDft farmTabsTbl posRel curSor">
              <InfiniteScrollTable
                columns={columns}
                loading={loader}
                hasMore={hasMore}
                filterData={filterData}
                totalcount={totalcount}
                handleLoadMore={handleLoadMore}
                filename="Guardrails"
                onRowClick={(record: any) => handleGuardRailSelection(record)}
              />
            </div>
            {selectedGuardRail && showGuardrailModel && (
              <GuardRailPopup
                showModal={showGuardrailModel}
                handleCancel={() => setShowGuardrailModel(false)}
                guardrailsList={selectedGuardRail as GuardrailsList}
                userDetails={userDetails}
              />
            )}
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default Guardrails;
