/* eslint-disable no-console */
import { Input, Modal, notification, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { setAbBlock, updateAbBlockData } from '../../constants/Api';
import { AbBlockPayload } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';

interface Props {
  showModal: boolean;
  handleCancel: (reload?: boolean) => void;
  polyPayload: AbBlockPayload;
}
export const AbBlock: React.FC<Props> = ({
  showModal,
  handleCancel,
  polyPayload,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [name, setName] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [polygonId, setPolygonId] = useState<number>();

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      name &&
      name.trim().length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userDetails, name]);

  useEffect(() => {
    if (polyPayload && polyPayload.name) {
      setName(polyPayload.name);
    }
    if (polyPayload && polyPayload.polygonId) {
      setPolygonId(polyPayload.polygonId);
    }
  }, [polyPayload]);

  const AddPolygon = async () => {
    try {
      setLoader(true);
      const payload = {
        name: name.trim(),
        lat_long_vertices: polyPayload.lat_long_vertices,
      };
      setName(name.trim());
      if (polyPayload.type === 'Add') {
        const { organization } = userDetails;
        const resp = await setAbBlock(
          organization.api_url,
          organization.farm.id,
          payload,
        );
        if (resp && resp.msg) {
          handleCancel(true);
          notification.success({
            message: resp.msg,
          });
        }
      } else if (polyPayload.type === 'Edit') {
        const { organization } = userDetails;
        const resp = await updateAbBlockData(
          organization.api_url,
          organization.farm.id,
          polygonId as number,
          payload,
        );
        if (resp && resp.msg) {
          handleCancel(true);
          notification.success({
            message: resp.msg,
          });
        }
      }
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };
  return (
    <Modal
      className="commonPopup addPolygon"
      title={`${polyPayload?.type} Ab Block`}
      centered
      width={'60vw'}
      visible={showModal}
      onCancel={() => handleCancel()}
      okText="Save"
      onOk={AddPolygon}
      okButtonProps={{
        className: 'btnSave ',
        disabled: isDisabled,
      }}
      cancelButtonProps={{
        className: 'btnCancel',
      }}
    >
      <div className="formRow">
        <div className="formCol">
          <label className="formLabel">
            Name <span style={{ color: 'red' }}> *</span>
          </label>
          <Input
            className="commInput"
            onChange={(e) => setName(e.target.value)}
            defaultValue={polyPayload?.name}
          />

          <div className="edit-loader">
            {loader && (
              <div className="loader">
                <Spin size="large" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="formRow">
        <div className="formColFull">
          <label className="formLabel">
            Vertices <span style={{ color: 'red' }}> *</span>
          </label>
          <div className="verticsSec">
            {polyPayload?.lat_long_vertices.map((data: any) => {
              return (
                <>
                  <div>
                    ({data?.latitude}, {data?.longitude})
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default AbBlock;
