/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Avatar, Dropdown, Menu, notification, Space } from 'antd';
import {
  ArrowLeftOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  WarningFilled,
  EllipsisOutlined,
} from '@ant-design/icons';

import 'antd/dist/antd.css';
import './style.css';
import RemoveFromTeam from './RemoveFromTeam';
import { ApplicationContext } from '../../context/AppContext';
import { getUserPermission } from '../../constants/Api';
import { privilagesConstants } from '../../constants/Privilages';
import PermissionsDashBoard from './PermissionsDashBoard';

interface Props {
  setDetails: () => void;
  userProfile: any;
  setEnableEmployeeDetails: (key: any) => void;
  setTeamids: (key: any) => void;
  setRefresh: (key: any) => void;
  setToggleWidget: (key: any) => void;
}
const EmployeeDetails: React.FC<Props> = ({
  setDetails,
  userProfile,
  setEnableEmployeeDetails,
  setTeamids,
  setRefresh,
  setToggleWidget,
}) => {
  const { userDetails, privilegeChecker } = useContext(ApplicationContext);
  const [removeTeam, setRemoveTeam] = useState<boolean>(false);
  const [teamDesc, setTeamDesc] = useState<any>();
  const [userPermission, setuserPermission] = useState<any>();
  const [passwordShown, setPasswordShown] = useState(false);
  const [permissionFlag, setPermission] = useState<boolean>(false);
  const [teamId, setTeamId] = useState<number>(0);
  const [appAcess, setAppAcess] = useState<boolean>(false);
  const [seeMenu, setSeeMenu] = useState<boolean>(false);
  const closeModel = (status: any) => {
    setRemoveTeam(status);
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getPermissions();
      const check = userProfile?.teams?.map((team: any) => team.app_access);
      check.length > 0 && setAppAcess(check.includes(true));
    }
  }, [userDetails, userProfile]);

  const getPermissions = async () => {
    try {
      const response = await getUserPermission(
        userDetails.organization.api_url,
        userDetails.organization.id,
        userProfile.id,
      );
      setuserPermission(response);
    } catch (error: any) {
      notification.error({ message: 'Unable to get Permission data' });
    }
  };
  const menu = (ele: any) => {
    return (
      <Menu>
        <Menu.Item key="see" onClick={() => SeeDetails(ele)}>
          <span className="overline2">See Details</span>
        </Menu.Item>
        {privilegeChecker(privilagesConstants.Edit_Teams) && (
          <Menu.Item key="remove" onClick={() => removeFromTeam(ele)}>
            <span className="overline2">Remove From Team</span>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const SeeDetails = (ele: any) => {
    setTeamDesc(ele);
    setTeamids(ele?.id);
    setTeamId(ele?.id);
    setEnableEmployeeDetails(false);
    setPermission(true);
    setRefresh(true);
  };

  const removeFromTeam = (ele: any) => {
    setTeamDesc(ele);
    setRemoveTeam(true);
  };

  const passCheck = (id: number) =>
    (userDetails && userDetails.id === id) || userDetails?.is_org_admin;

  const showPassWord = () => {
    setPasswordShown(!passwordShown);
  };

  const checkGroupPrivilege = (groupId: number) => {
    let value = false;
    userPermission?.filter((groupPrivilege: any) => {
      if (groupPrivilege.id === groupId) {
        const check = groupPrivilege?.privileges?.every(
          (subPrivilege: any) => subPrivilege.status === true,
        );
        value = check;
      }
    });
    return value;
  };

  return (
    <Layout className="posRel">
      <Content>
        <div className="tblContainer">
          <div className="employee-details employee-details-main  my-profile rowGap50 seeDtl">
            <div className="teamarrow mr20" onClick={setDetails}>
              {/* <ArrowLeftOutlined /> */}
            </div>
            <div className="employee-profile">
              <div className="profile-details">
                {userProfile?.profile_url !== '' && userProfile?.profile_url ? (
                  <Avatar src={userProfile?.profile_url}></Avatar>
                ) : (
                  <Avatar>
                    {userProfile?.first_name?.charAt(0)}
                    {userProfile?.last_name?.charAt(0)}
                  </Avatar>
                )}
                <h1 className="headline1 wwp">
                  {userProfile?.first_name} {userProfile?.last_name}
                </h1>
                <span className="headline2">
                  ID: {userProfile?.member_uuid}
                </span>
              </div>
              <div className="profile-contacts">
                <h1 className="headline2 fSbd">Contact</h1>
                <h3 className="body1">{userProfile?.username}</h3>
                <h3 className="body1">{userProfile?.contact_number}</h3>
                {appAcess && !userProfile?.username && (
                  <div style={{ display: 'flex' }}>
                    <WarningFilled
                      style={{
                        color: '#fbb313',
                        marginRight: '10px',
                        fontSize: '22px',
                      }}
                    />
                    <span className="warning-msg">Email Required</span>
                  </div>
                )}
              </div>

              {userProfile?.ops_code && (
                <div className="operator-code">
                  <h1 className="headline2 fSbd">Operator Code</h1>
                  <div className="proRoleValue flex-1 ">
                    <div
                      className="flex"
                      // style={{ justifyContent: 'flex-start' }}
                    >
                      <div className="flex-1 body1">
                        {privilegeChecker(
                          privilagesConstants.View_Operator_Code,
                        ) && passwordShown
                          ? userProfile?.ops_code
                          : '* * * *'}
                      </div>
                      {privilegeChecker(
                        privilagesConstants.View_Operator_Code,
                      ) && (
                        <div className="flex-1 cursor" onClick={showPassWord}>
                          <>
                            {!passwordShown ? (
                              <EyeTwoTone />
                            ) : (
                              <EyeInvisibleOutlined />
                            )}
                          </>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="employee-permissions teams-permissions">
              <div className="permissions-title">
                <h1 className="headline1">Teams & Permissions</h1>
                <span className="body1">
                  Use teams to manage this employee’s access and permissions to
                  the Monarch Platform.
                </span>
              </div>
              <div className="employee-teams">
                <div className="employee-team-sub">
                  {userProfile.teams.map((ele: any) => {
                    return (
                      <div className="empoyee-team" key={ele?.name}>
                        <div className="employee-team-title">
                          <h1 className="headline2 fSbd">{ele?.name}</h1>
                          <span className="body2">{ele?.description}</span>
                        </div>

                        {ele && (
                          <Dropdown overlay={menu(ele)} trigger={['click']}>
                            <a
                              onClick={(e) => {
                                setSeeMenu(!seeMenu);
                                e.preventDefault();
                              }}
                            >
                              <Space>
                                <EllipsisOutlined className="empEllip" />
                              </Space>
                            </a>
                          </Dropdown>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              {userPermission && (
                <div className="employee-main-permissions">
                  <h1 className="headline3">Permissions</h1>
                  <div className="permission-sub">
                    {userPermission &&
                      userPermission.map((ele: any) => {
                        return (
                          <div className="operation-permissions">
                            <div className="operation-title">
                              <h2 className="body1">
                                {ele?.privilege_group_name}
                              </h2>
                              <Checkbox checked={checkGroupPrivilege(ele.id)} />
                            </div>
                            {ele.privileges.map((eledata: any) => {
                              return (
                                <div className="operation-features">
                                  <div className="operation-feature">
                                    <h3 className="body2">
                                      {eledata?.privilege_name}
                                    </h3>
                                    <Checkbox checked={eledata.status} />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Content>
      {removeTeam && (
        <RemoveFromTeam
          showModal={removeTeam}
          closeModel={closeModel}
          userProfile={userProfile}
          teamDesc={teamDesc}
          setRefresh={setRefresh}
          setToggleWidget={setToggleWidget}
        ></RemoveFromTeam>
      )}

      {permissionFlag && (
        <PermissionsDashBoard
          setPermission={setPermission}
          teamId={teamId}
          setTeamId={setTeamId}
        ></PermissionsDashBoard>
      )}
    </Layout>
  );
};

export default EmployeeDetails;
