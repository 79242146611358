/* eslint-disable react/display-name */
import React, { useContext, useState, useEffect } from 'react';
import {
  Card,
  DatePicker,
  Row,
  notification,
  Select,
  Space,
  Tooltip,
} from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import {
  getFleetIndicatorHistory,
  getTractorsListData,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import {
  fleetHistoryData,
  fleetObject,
  TractorsList,
} from '../../constants/types';
import {
  fromToDateWrapper,
  getDateTime,
  getDateTimes,
  getDiffTime,
  getTractorAvailableState,
  tractorSort,
} from '../../constants/Common';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import 'react-datepicker/dist/react-datepicker.css';
import { getIndicatorImages } from '../basestation/Common';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import './style.css';
import moment from 'moment';
import CSelect from '../common/CSelect';

const { RangePicker } = DatePicker;

interface StatusListType {
  name: string;
  value: string;
}

interface LevelType {
  number: string;
  value: string;
}

interface TractorResponseType {
  label: string;
  value: number | string | boolean;
  disabled: boolean;
  index: number | string;
  isAvailable?: string;
}

const FleetIndicatorHistory: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [fleetHistory, setFleetHistory] = useState<fleetObject[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [pageSize, setPagesize] = useState<any>(25);
  const [tractors, setTractorsData] = useState<TractorResponseType[]>([]);
  const [tractorId, setTractorId] = useState<string>();
  const [indicatorKey, setIndicatorKey] = useState<any>();
  const [fromDateTime, setFromDateTime] = useState<any>(
    moment().subtract(1, 'day').toDate(),
  );
  const [toDateTime, setToDateTime] = useState<any>(moment().toDate());
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [filterData, setFilter] = useState<fleetObject[]>([]);
  const statusList: StatusListType[] = [
    { name: 'All', value: '' },
    { name: 'Tractor_Communication', value: 'Tractor_Communication' },
    { name: 'Tractor_Automation', value: 'Tractor_Automation' },
    { name: 'Tractor_Drivability', value: 'Tractor_Drivability' },
    { name: 'Tractor_Data', value: 'Tractor_Data' },
    { name: 'Tractor_Energy', value: 'Tractor_Energy' },
    { name: 'Hitch_and_Implement', value: 'Hitch_and_Implement' },
  ];
  const [indicatorLevel, setIndicatorLevel] = useState<any>();
  const level: LevelType[] = [
    { number: 'all', value: '' },
    { number: '1', value: '1' },
    { number: '2', value: '2' },
    { number: '3', value: '3' },
  ];

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      const init = async () => {
        await fleetIndicatorHistoryList();
      };
      init();
    }
  }, [
    userDetails,
    pageNumber,
    pageSize,
    tractorId,
    indicatorKey,
    indicatorLevel,
    fromDateTime,
    toDateTime,
  ]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
    }
  }, [userDetails]);

  const fleetIndicatorHistoryList = async () => {
    const { organization } = userDetails;
    try {
      setLoader(true);
      const fleetView = await getFleetIndicatorHistory(
        organization.api_url,
        organization.fleet.id,
        tractorId ? tractorId : '',
        indicatorKey ? indicatorKey : '',
        fromDateTime ? fromDateTime.getTime() : '',
        toDateTime ? toDateTime.getTime() : '',
        pageNumber,
        pageSize,
        indicatorLevel ? indicatorLevel : '',
      );
      const { _metadata } = fleetView;
      setTotalCount(_metadata.total_records_count);
      const data =
        fleetView && fleetView.records && fleetView.records.length > 0
          ? fleetView.records
          : [];
      const fleetData: fleetObject[] = [];
      data.map((record: fleetHistoryData) => {
        const startTime = getDateTime(record.created_date_time);
        const EndTime = getDateTime(record.resolved_date_time);

        const obj = {
          id: record.id,
          name: record.tractor.name,
          description: record.description,
          level: record.indicator_level,
          start_time: record.created_date_time
            ? getDateTimes(record.created_date_time)
            : '',
          indicator_name: record.indicator_name,
          explanation: record.explanation ?? '-',
          indicator_level: record.indicator_level,
          error_code: record.error_code,
          resolved_date_time: record.resolved_date_time
            ? getDateTimes(record.resolved_date_time)
            : '',
          Duration: record.resolved_date_time
            ? getDiffTime(startTime, EndTime)
            : '',
        };
        fleetData.push(obj);
      });
      if (data.length > 0) {
        if (pageNumber === 1) {
          setFilter(fleetData);
        } else {
          setFilter([...filterData, ...fleetData]);
        }
      } else {
        setFilter(fleetData);
        setFleetHistory(fleetData);
      }

      if (fleetData.length < 25) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setLoader(false);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };
  const columns = [
    {
      title: `${t(translate.baseStation.tractorName)}`,
      dataIndex: 'name',
      key: 'name',
      width: '12%',
    },
    {
      title: `${t(translate.taskmaster.starttime)}`,
      dataIndex: 'start_time',
      key: 'start_time',
      width: '14%',
    },
    {
      title: `${t(translate.tractorDetails.indicatorName)}`,
      dataIndex: 'indicator_name',
      key: 'indicator_name',
      width: '12%',
    },
    {
      title: `${t(translate.tractorDetails.errorcode)}`,
      dataIndex: 'error_code',
      key: 'error_code',
      width: '10%',
    },
    {
      title: `${t(translate.myTask.description)}`,
      dataIndex: 'description',
      key: 'description',
      render: (description: string, levelObj: fleetObject) => {
        return (
          <div className="description">
            <img
              width="18px"
              height="18px"
              src={getIndicatorImages(
                levelObj.indicator_name,
                levelObj.level,
                true,
              )}
            />
            <Tooltip title={description}>
              <div className="description-text">{description}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: `${t(translate.tractorDetails.explanation)}`,
      dataIndex: 'explanation',
      key: 'explanation',
      width: '12%',
      render: (explanation: string) => {
        return (
          <Tooltip title={explanation}>
            <div className="description-text">{explanation}</div>
          </Tooltip>
        );
      },
    },
    {
      title: `${t(translate.tractorDetails.ResolvedTime)}`,
      dataIndex: 'resolved_date_time',
      key: 'resolved_date_time',
      width: '14%',
    },
    {
      title: `${t(translate.copycat.duration)}`,
      dataIndex: 'Duration',
      key: 'Duration',
      width: '10%',
    },
  ];

  const getTractorsList = async () => {
    try {
      const tractor: { records: TractorsList[] } = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractorData: TractorResponseType[] = data.map((d: TractorsList) => {
        return {
          label: d.name,
          value: d.id,
          disabled: false,
          index: d.id,
          id: d.id,
          connection: d.connection,
          isOnline: d.connection === 'online' ? true : false,
          isAvailable: getTractorAvailableState(d.connection, d?.heartbeat),
        };
      });
      const arr = tractorSort(tractorData, 'label');
      setTractorsData(arr);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };

  const handleSelect = async (e: any) => {
    fleetHistory.length = 0;
    filterData.length = 0;
    setPageNumber(1);
    setTractorId(e);
  };

  const statusSelect = (e: string) => {
    fleetHistory.length = 0;
    filterData.length = 0;
    setPageNumber(1);
    setIndicatorKey(e);
  };

  const levelSelect = (e: string) => {
    fleetHistory.length = 0;
    filterData.length = 0;
    setPageNumber(1);
    setIndicatorLevel(e);
  };

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const onDateRangeChange = (dates: any) => {
    fleetHistory.length = 0;
    filterData.length = 0;
    if (dates) {
      setPageNumber(1);
      setFilter([]);
      setFleetHistory([]);
      const [from, to] = fromToDateWrapper(dates);
      setFromDateTime(from.toDate());
      setToDateTime(to.toDate());
    } else {
      setFromDateTime(null);
      setToDateTime(null);
    }
  };

  function disabledDate(current: any) {
    return current && current > moment();
  }

  return (
    <Layout>
      <div className="mainContent">
        <Content>
          <div className="tblContainer softwareUpdate">
            <Row>
              <Card
                bordered={true}
                className="tcktsBlk searchFilters filterGap40"
              >
                <Space
                  className="mt0"
                  style={{ marginBottom: 0, position: 'relative' }}
                >
                  <CSelect
                    list={tractors.map((item) =>
                      Object.assign({
                        id: item.index,
                        name: item.label,
                        isAvailable: item.isAvailable,
                      }),
                    )}
                    selectTrigger={(selected) => {
                      handleSelect(selected ? selected?.id : 0);
                    }}
                    placeholder="All Tractors"
                    label="Tractor"
                    isAll={true}
                    tractorStatus={true}
                    className="dropdownStyle slkttractor tractorField"
                  />
                  <Select
                    className="dropdownStyle customField "
                    defaultValue="Select Indicator"
                    onSelect={statusSelect}
                  >
                    {statusList.map((data: any) => {
                      return (
                        <option value={data.value} key={data.value}>
                          {data.name.replace(/_/g, ' ')}
                        </option>
                      );
                    })}
                  </Select>
                  <Select
                    className="dropdownStyle customField"
                    defaultValue="Select Level"
                    onSelect={levelSelect}
                  >
                    {level.map((data: any) => {
                      return (
                        <option value={data.value} key={data.value}>
                          {data.number}
                        </option>
                      );
                    })}
                  </Select>
                  <RangePicker
                    format="YYYY-MM-DD"
                    onChange={onDateRangeChange}
                    className="dropdownStyle dW250_date dateFilter"
                    disabledDate={disabledDate}
                    defaultValue={[moment(fromDateTime), moment(toDateTime)]}
                  />
                </Space>
              </Card>
            </Row>
            <div className="tblDft farmTabsTbl posRel">
              <InfiniteScrollTable
                // xScroll={1800}
                columns={columns}
                loading={loader}
                hasMore={hasMore}
                filterData={filterData}
                totalcount={totalCount}
                handleLoadMore={handleLoadMore}
                filename="FleetindicatorHistory"
              />
            </div>
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default FleetIndicatorHistory;
