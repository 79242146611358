/* eslint-disable no-console */
import { Button, Modal } from 'antd';
import React, { useContext } from 'react';
import errorCode_icon from '../../assets/images/errorCode_icon.svg';
import { error_message } from '../../constants/constant';
import { AUTO_DRIVE_LOGOUT_ERROR_CODES } from './actions';
import './autodrivelist.css';
import { RemoteDriveAppCtx } from './RemoteDriveContext';

interface Props {
  showModal?: boolean;
  closeModel?: any;
  autoDriveLogoutErrorCodes: any;
}
export const AutoDriveLogoutErrorPopup: React.FC<Props> = ({
  showModal,
  closeModel,
  autoDriveLogoutErrorCodes,
}: Props) => {
  const { RDReducer } = useContext(RemoteDriveAppCtx);
  const [, dispatch] = RDReducer;
  return (
    <>
      <Modal
        className="commonPopup errorCodePopup logoutPop"
        title=""
        visible={showModal}
        onCancel={closeModel}
        width={500}
        closable={false}
        maskClosable={false}
        footer={[
          <>
            <Button
              key="back"
              className="errorPopupBtn"
              onClick={() => {
                dispatch({
                  type: AUTO_DRIVE_LOGOUT_ERROR_CODES,
                  payload: {
                    message: '',
                    errors: [],
                    userId: 0,
                  },
                });
              }}
            >
              OK
            </Button>
          </>,
        ]}
      >
        <div className="errorCodepopupBlock">
          <div className="error_code_block">
            <div>
              <img src={errorCode_icon} className="mb20" height="48" />
            </div>
            <div className="mb20 notAvail">Logout</div>
          </div>
          <div className="errorCodeList">
            <ul>
              {autoDriveLogoutErrorCodes &&
                autoDriveLogoutErrorCodes.errors &&
                autoDriveLogoutErrorCodes.errors.map((error: any) => (
                  <>
                    <li className="errorListItem">
                      <span className="errorCodeNo"></span>
                      <span className="errorCodeName">
                        {error_message[error] && error_message[error]}
                      </span>
                    </li>
                  </>
                ))}
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AutoDriveLogoutErrorPopup;
