/* eslint-disable no-console */
import { CameraFilled, ExpandOutlined } from '@ant-design/icons';
import { Button, Space, Spin, Tooltip } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import cam_error from '../../assets/images/camera_error.svg';
import loading_logo from '../../assets/images/loading_logo.gif';
import camera_capture from '../../assets/images/camera_capture_icon.svg';

import {
  getCameraViewAllow,
  tractorCameras as cameras,
  tractorCamerasLabelsFromLiveMap,
  tractorConstantsNew,
} from '../../constants/Common';
import { ApplicationContext } from '../../context/AppContext';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import { speCharRegX } from '../../constants/constant';

interface Props1 {
  isUp: boolean;
  title: string;
}

const TooltipC: React.FC<Props1> = ({ children, isUp, title }) => {
  return !isUp ? (
    <div
      title=""
      // title={title}
    >
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

interface Props {
  selectedTractorId: number;
  tractorName: string;
  triggerSnapshot: () => void;
}
export const Cameras: React.FC<Props> = ({
  selectedTractorId,
  tractorName,
  triggerSnapshot,
}) => {
  const [selectedCamera, setSelectedCamera] = useState('');
  const mixpanel = useMixpanel();
  const cameraWindows = useRef<Map<string, any | undefined>>(
    new Map<string, any | undefined>(),
  );
  const { RDReducer } = useContext(RemoteDriveAppCtx);
  const { openCameraWindow } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(true);
  const [state] = RDReducer;
  const { selectedTractor } = state;
  const { heartbeat, isAvailable } = selectedTractor;
  const [allCamerasDown, setAllCamerasDown] = useState(true);

  tractorName = !speCharRegX?.test(tractorName)
    ? encodeURIComponent(tractorName)
    : tractorName;

  useEffect(() => {
    if (
      selectedTractor &&
      selectedTractor.isAvailable &&
      selectedTractor.isAvailable === tractorConstantsNew.off.key
    ) {
      setAllCamerasDown(true);
    } else {
      setAllCamerasDown(false);
    }
  }, [selectedTractor]);

  // useEffect(() => {
  //   getCameraViewAllow(heartbeat, cameras.PTO, isAvailable) &&
  //     setAllCamerasDown(false);
  //   getCameraViewAllow(heartbeat, cameras.FRONT, isAvailable) &&
  //     setAllCamerasDown(false);
  //   getCameraViewAllow(heartbeat, cameras.FRONT_LEFT, isAvailable) &&
  //     setAllCamerasDown(false);
  //   getCameraViewAllow(heartbeat, cameras.FRONT_RIGHT, isAvailable) &&
  //     setAllCamerasDown(false);
  //   getCameraViewAllow(heartbeat, cameras.REAR, isAvailable) &&
  //     setAllCamerasDown(false);

  //   // setAllCamerasDown(true);
  // }, [selectedTractor]);

  const getCameraStatus = (face: string) =>
    getCameraViewAllow(heartbeat, face, isAvailable);

  const setSelectedCameraFun = (camera: string) => {
    if (getCameraViewAllow(heartbeat, camera, isAvailable)) {
      setSelectedCamera(camera);
    }
  };

  useEffect(() => {
    const allStatus = {
      [cameras.PTO]: getCameraStatus(cameras.PTO),
      [cameras.FRONT]: getCameraStatus(cameras.FRONT),
      [cameras.FRONT_LEFT]: getCameraStatus(cameras.FRONT_LEFT),
      [cameras.FRONT_RIGHT]: getCameraStatus(cameras.FRONT_RIGHT),
      [cameras.REAR]: getCameraStatus(cameras.REAR),
    };
    const [cface] = Object.keys(allStatus).filter(
      (status) => allStatus[status],
    );
    if (cface) setSelectedCamera(cface);
  }, [selectedTractorId]);

  const handleClick = (face: string) => {
    getCameraView(selectedTractorId, face, tractorName);
  };

  useEffect(() => {
    setLoading(true);
  }, [selectedCamera]);

  const getCameraView = (
    tractorId: number,
    cameraView: string,
    tractorName: string,
  ) => {
    if (cameraWindows.current.has(cameraView)) {
      mixpanel.track('Live Map', {
        event: `Fleet List Camera ${cameraView} View Clicked`,
      });
      const getWindow = cameraWindows.current.get(cameraView);
      let camViews = localStorage.getItem('cam_views');
      if (camViews && !camViews.includes(`${tractorId}${cameraView}`)) {
        camViews = `${camViews}, ${tractorId}${cameraView}`;
        localStorage.setItem('cam_views', camViews);
      } else {
        localStorage.setItem('cam_views', `${tractorId}${cameraView}`);
      }
      if (`${tractorId}${cameraView}` !== getWindow?.name) {
        openCameraWindow(tractorId, cameraView, tractorName);
      } else {
        getWindow.focus();
      }
    } else {
      openCameraWindow(tractorId, cameraView, tractorName);
    }
  };

  const getTitle = (face: string) => {
    if ([cameras.FRONT_LEFT, cameras.FRONT_RIGHT].includes(face))
      return 'Xavier1 is Down';
    if ([cameras.FRONT].includes(face)) return 'Xavier2 is Down';
    if ([cameras.REAR, cameras.PTO].includes(face)) return 'Xavier6 is Down';
    return 'System is Down';
  };

  const antIcon = <img src={loading_logo} />;

  return (
    <>
      <div className="cameraViewTitle">
        <h5 className="headline3">Camera Views </h5>
        <Tooltip title="Capture Snapshot">
          {/* <CameraFilled onClick={triggerSnapshot} /> */}
          <img
            className="cpointer"
            data-testid="cameraviewicon-LiveMap"
            src={camera_capture}
            alt="Snapshot"
            onClick={triggerSnapshot}
          />
        </Tooltip>
      </div>
      {allCamerasDown && (
        <div className="cameras_down">
          <img src={cam_error} alt="All Cameras Down" />
          <h6 className="body3">All CAMERAS DOWN</h6>
        </div>
      )}
      {/* {!allCamerasDown &&
        selectedCamera !== '' &&
        !getCameraStatus(selectedCamera) && (
          <div className="cameras_down">
            <img src={cam_error} alt="All Cameras Down" />
            <h6>
              {tractorCamerasLabelsFromLiveMap[selectedCamera]} CAMERA DOWN
            </h6>
          </div>
        )} */}

      <div className="pad0 mbcams">
        {!allCamerasDown && selectedCamera !== '' && (
          <div
            className="cameraViewSec cameraViewScreen"
            style={{ position: 'relative' }}
          >
            {getCameraStatus(selectedCamera) && (
              <Space>
                <ExpandOutlined
                  className="fullScreenVideo"
                  style={{ color: '#FFF' }}
                  onClick={() => handleClick(selectedCamera)}
                />
                <Spin indicator={antIcon} spinning={loading}>
                  <iframe
                    src={`${window.location.origin}/CameraView/${selectedTractorId}/stream/${tractorName}/${selectedCamera}/false`}
                    height={'100%'}
                    width={'100%'}
                    style={{ overflow: 'hidden' }}
                    scrolling="no"
                    id="cammms"
                    onLoad={() => setLoading(false)}
                  />
                </Spin>
              </Space>
            )}
          </div>
        )}
        {!allCamerasDown && (
          <div className="camera_box_container mb4 border-none camera_view_buttons newCamBtns">
            <TooltipC
              isUp={getCameraStatus(cameras.PTO)}
              title={getTitle(cameras.PTO)}
            >
              <Button
                disabled={
                  !getCameraViewAllow(heartbeat, cameras.PTO, isAvailable)
                }
                className={`camera_box ${
                  getCameraViewAllow(heartbeat, cameras.PTO, isAvailable) &&
                  selectedCamera === cameras.PTO
                    ? 'active'
                    : ''
                }`}
                onClick={() => setSelectedCameraFun(cameras.PTO)}
              >
                <h3 className="t_camera_lbl body3">Hitch</h3>
              </Button>
            </TooltipC>
            <TooltipC
              isUp={getCameraStatus(cameras.FRONT)}
              title={getTitle(cameras.FRONT)}
            >
              <Button
                disabled={
                  !getCameraViewAllow(heartbeat, cameras.FRONT, isAvailable)
                }
                className={`camera_box ${
                  getCameraViewAllow(heartbeat, cameras.FRONT, isAvailable) &&
                  selectedCamera === cameras.FRONT
                    ? 'active'
                    : ''
                }`}
                onClick={() => setSelectedCameraFun(cameras.FRONT)}
              >
                <h3 className="t_camera_lbl body3">Front</h3>
              </Button>
            </TooltipC>
            <TooltipC
              isUp={getCameraStatus(cameras.FRONT_LEFT)}
              title={getTitle(cameras.FRONT_LEFT)}
            >
              <Button
                disabled={
                  !getCameraViewAllow(
                    heartbeat,
                    cameras.FRONT_LEFT,
                    isAvailable,
                  )
                }
                className={`camera_box ${
                  getCameraViewAllow(
                    heartbeat,
                    cameras.FRONT_LEFT,
                    isAvailable,
                  ) && selectedCamera === cameras.FRONT_LEFT
                    ? 'active'
                    : ''
                }`}
                onClick={() => setSelectedCameraFun(cameras.FRONT_LEFT)}
              >
                <h3 className="t_camera_lbl body3">Left</h3>
              </Button>
            </TooltipC>
            <TooltipC
              isUp={getCameraStatus(cameras.FRONT_RIGHT)}
              title={getTitle(cameras.FRONT_RIGHT)}
            >
              <Button
                disabled={
                  !getCameraViewAllow(
                    heartbeat,
                    cameras.FRONT_RIGHT,
                    isAvailable,
                  )
                }
                className={`camera_box ${
                  getCameraViewAllow(
                    heartbeat,
                    cameras.FRONT_RIGHT,
                    isAvailable,
                  ) && selectedCamera === cameras.FRONT_RIGHT
                    ? 'active'
                    : ''
                }`}
                onClick={() => setSelectedCameraFun(cameras.FRONT_RIGHT)}
              >
                <h3 className="t_camera_lbl body3">Right</h3>
              </Button>
            </TooltipC>
            <TooltipC
              isUp={getCameraStatus(cameras.REAR)}
              title={getTitle(cameras.REAR)}
            >
              <Button
                disabled={
                  !getCameraViewAllow(heartbeat, cameras.REAR, isAvailable)
                }
                className={`camera_box ${
                  getCameraViewAllow(heartbeat, cameras.REAR, isAvailable) &&
                  selectedCamera === cameras.REAR
                    ? 'active'
                    : ''
                }`}
                onClick={() => setSelectedCameraFun(cameras.REAR)}
              >
                <h3 className="t_camera_lbl body3">Rear</h3>
              </Button>
            </TooltipC>
          </div>
        )}
      </div>
    </>
  );
};
