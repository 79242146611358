/* eslint-disable no-console */
/* eslint-disable react/jsx-no-undef */
import { LeftOutlined, SearchOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Collapse,
  Input,
  notification,
  Tabs,
} from 'antd';
import { Coordinate } from 'ol/coordinate';
import { Style } from 'ol/style';
import React, { useContext, useEffect, useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import tractorTGray from '../../assets/images/allTractors_G.svg';
import tractorTGreen from '../../assets/images/allTractors_W.svg';
import user_locked_1 from '../../assets/images/autodrive_G.svg';
import autodrive_new from '../../assets/images/autodrive_new.svg';
import lockLGreen from '../../assets/images/autodrive_W.svg';
import bStationIcon from '../../assets/images/bStationGreen.svg';
import charging_Gray from '../../assets/images/charging_G.svg';
import charging_White from '../../assets/images/charging_W.svg';
import fListIcon_Block from '../../assets/images/fListIcon_B.svg';
import liveCommandW from '../../assets/images/liveCommandW.svg';
import polygon_icon from '../../assets/images/polygon_icon.svg';
import tractorIcon from '../../assets/images/tractorGreen.svg';
import mpl_waypoints from '../../assets/images/waypoint_single.svg';

import { getBasestationList, getTractorsData } from '../../constants/Api';
import {
  checkAlphaFeature,
  getConnectivityImg,
  getOperationNewStatus,
  indicatorsOrder,
  tractorConstantsNew,
  wrapIndictorsData,
} from '../../constants/Common';
import constant from '../../constants/constant';
import {
  MapFeature,
  TractorHeartBeat,
  TractorsList,
} from '../../constants/types';
import { SET_TRACTORS_LIST } from '../../context/actions';
import { ApplicationContext } from '../../context/AppContext';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import TermsCond from '../auth/TermsCond';
import AutoDriveFeedBackPop from '../livecommand/AutoDriveFeedBackPop';
import { PassCode } from '../livecommand/PassCode';
import {
  EXECUTE,
  SELECTED_TRACTOR,
  SELECTED_TRACTORS,
  SET_PINNED_TRACTORS,
  SET_RIGHT_SIDE_TOGGLE,
  SET_SHOW_REMOTE_DRIVE_DETAILS,
  SET_SHOW_TERM_AND_COND,
} from '../remote_drive_new/actions';
import AutoDrive from '../remote_drive_new/AutoDrive';
import { RemoteDriveAppCtx } from '../remote_drive_new/RemoteDriveContext';
import RemoteDriveDetails from '../remote_drive_new/RemoteDriveDetails';
import RemoteDriveDetailsNew from '../remote_drive_new/RemoteDriveDetailsNew';
import './../remote_drive_new/autodrivelist.css';
import { HeartBeat } from './HeartBeat';
import './style.css';
import TractorList from './TractorList';
const { TRACTOR_COMMUNICATION } = constant;

export const baseData: MapFeature[] = [
  {
    id: 1,
    name: 'Ground Zero',
    mapId: '1',
    type: 'BASESTATION',
    visible: true,
    style: new Style({}),
  },
];
const { Panel } = Collapse;
const { TabPane } = Tabs;
interface Props {
  recenterItem: (item: MapFeature) => void;
  recenterXY: (xy: any) => void;
  setRecenterTabId: (tractorId: number) => void;
  heartBeat: TractorHeartBeat;
  selectedTractorFromFleetId: number;
  polygons: MapFeature[];
  initTravelledPath: (tractorId: number, tractors?: any[]) => void;
  clearPreviewPath: (id: number) => void;
  setSelectedTractorsForMaps: (selectedTractors: any) => void;
  pointCordinate: Coordinate;
  startAutoDrive: () => void;
  addRowFollowRVCommand: () => void;
  preCommandState: string;
  addRVCommand: () => void;
  handleResetCommand: (state: string) => void;
  base: [number, number];
  setPreCommandState: (command: string) => void;
  setRedirectToggle: (toggle: boolean) => void;
  redirectToggle: boolean;
  clearAll: () => void;
}
const LiveMapFleetList: React.FC<Props> = ({
  recenterItem,
  setRecenterTabId,
  heartBeat,
  selectedTractorFromFleetId,
  polygons,
  initTravelledPath,
  clearPreviewPath,
  setSelectedTractorsForMaps,
  addRowFollowRVCommand,
  preCommandState,
  recenterXY,
  addRVCommand,
  handleResetCommand,
  base,
  setPreCommandState,
  setRedirectToggle,
  redirectToggle,
  clearAll,
}: Props) => {
  const mixpanel = useMixpanel();
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const {
    RDReducer,
    activeIds,
    setActiveIds,
    waypointsForRemoteAv,
    mapPinnedTractors,
    connectToTractor,
    selectedTractorsIds,
    setSelectedTractorsIds,
    unPinnedTractors,
    clearAllLocalDBCommands,
  } = useContext(RemoteDriveAppCtx);
  const { AutoDriveReducer } = useContext(AutoDriveApplicationContext);
  const [autoDriveState] = AutoDriveReducer;
  const [appState, appDispatch] = APPReducer;
  const { tractors, tractorsList } = appState;
  const { isPinned, drivingTractors } = autoDriveState;
  const [state, dispatch] = RDReducer;
  const {
    autoDrive,
    showRemoteDriveDetails,
    selectedTractor,
    selectedTractors,
    showTermAndCond,
    showAutoDriveFeedBackPop,
    showAutoDriveConnect,
    pinnedTractors,
  } = state;
  const pageSize = 100;
  const pageNumber = 1;
  const [baseId, setBaseId] = useState<any>('a');
  const [baseStationTractors, setBaseStationTractors] = useState<any[]>([]);
  const [baseStations, setBaseStations] = useState<any[]>([]);
  const [fleetId, setFleetId] = useState<any>('Tractor');
  const [autoValue, setAutoValue] = useState('');
  const [options, setOptions] = useState<any>();
  const [tabid, setTabid] = useState<any>('');
  const [showPassCode, setShowPassCode] = useState<boolean>(false);
  const [heartBeatFlag, setheartBeatFlag] = useState<boolean>(false);

  useEffect(() => {
    setSelectedTractorsForMaps(selectedTractors);
  }, [selectedTractors]);

  const genExtra = () => (
    <img
      src={fListIcon_Block}
      onClick={(event) => {
        event.stopPropagation();
      }}
    />
  );
  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      tractors &&
      tractors.length > 0
    ) {
      getTractorsList(true);
    }
  }, [userDetails, tractors]);

  const getTractorsList = async (type = true) => {
    try {
      let records = [];
      if (type) {
        records = tractors && tractors.length > 0 ? tractors : [];
      } else {
        const { records: list }: { records: any } = await getTractorsData(
          userDetails.organization.api_url,
          userDetails.organization_id,
          1,
          100,
          baseId,
        );
        records = list;
      }
      const list = records && records.length > 0 ? records : [];
      list.forEach((element: TractorsList) => {
        (element.housemeter = element.heartbeat
          ? element.heartbeat.hour_meter &&
            `${(Number(element.heartbeat?.hour_meter) / 3600).toFixed()} hrs`
          : ''),
          (element.indicatorsData = []);
        element.isAvailable =
          element.connection === 'offline'
            ? tractorConstantsNew.off.key
            : getOperationNewStatus(element?.heartbeat as TractorHeartBeat);
        for (const arrangeIndicator of indicatorsOrder) {
          for (const indicator of element.indicators) {
            if (arrangeIndicator === indicator.indicator_name) {
              element.indicatorsData.push(indicator);
            }
          }
        }
      });
      let newList: TractorsList[] = [];

      newList = alignItems(list);
      newList = newList.map((item) => {
        item['w_indicators'] = wrapIndictorsData(item.indicators);
        item.connectivity = getConnectivityImg(
          item['w_indicators'][TRACTOR_COMMUNICATION],
        );
        return item;
      });

      if (type) {
        appDispatch({
          type: SET_TRACTORS_LIST,
          payload: newList,
        });
      } else {
        if (
          selectedTractors &&
          selectedTractors.length > 0 &&
          newList &&
          newList.length
        ) {
          selectedTractors.map((sTractor: any) => {
            newList.map((item: any) => {
              if (item.id === sTractor.id) {
                item.selected = sTractor.selected;
              }
            });
          });
        }
        setBaseStationTractors(newList);
      }
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };

  const onSelect = (data: string) => {
    setAutoValue(data);
    if (data) {
      if (tractorsList && tractorsList.length > 0) {
        const tractorId = selectedTractor?.id;
        for (const tractor of tractorsList) {
          if (
            tractor &&
            tractor.name &&
            tractor.name === data &&
            tractorId !== tractor.id
          ) {
            setTractorSelection(tractor.id);
          }
        }
        for (const polygon of polygons) {
          if (polygon && polygon.name && polygon.name === data) {
            recenterItem(polygon);
          }
        }
        for (const baseStation of baseStations) {
          if (baseStation && baseStation.name && baseStation.name === data) {
            // to redirect base station statically
            recenterItem(baseData[0]);
          }
        }
        for (const waypoint of waypointsForRemoteAv) {
          if (waypoint && waypoint.name && waypoint.name === data) {
            recenterItem(waypoint);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (
      redirectToggle &&
      selectedTractorFromFleetId &&
      tractorsList &&
      tractorsList.length > 0
    ) {
      setTractorSelection(selectedTractorFromFleetId);
      setRedirectToggle(false);
    }
  }, [selectedTractorFromFleetId, tractorsList, redirectToggle]);
  const setTractorSelection = (tractorId: number) => {
    if (tractorsList && tractorsList.length > 0) {
      for (const tractor of tractorsList) {
        if (tractor && tractor.id && tractor.id === Number(tractorId)) {
          setFleetId('Tractor');
          setTractorTabes(tractor);
        }
      }
    }
  };
  const isPinnedFun = (id: number): boolean => pinnedTractors.includes(id);
  const setTractorTabes = (tractor: TractorsList) => {
    setAutoValue('');
    const tractorsConst = selectedTractors;
    let exist = false;
    // checking tractor tab already opened or not
    if (tractorsConst && tractorsConst.length > 0) {
      for (const iterator of tractorsConst) {
        if (iterator.id && tractor.id && iterator.id === tractor.id) {
          exist = true;
        }
      }
    }
    // change to details view
    // recenter the tractor position to map center
    // to select particular tab
    setTabid(`${tractor.id}`);
    // to maintain tabs
    if (isPinned) {
      if (pinnedTractors.length == 1) {
        if (exist || isPinnedFun(tractor.id)) {
          dispatch({
            type: SELECTED_TRACTORS,
            payload: [],
          });
          unPinnedTractors(tractor.id);
        } else {
          mapPinnedTractors(tractor.id);
          dispatch({
            type: SELECTED_TRACTORS,
            payload: [tractor],
          });
        }
      } else if (pinnedTractors.length > 1) {
        if (exist) {
          const list = pinnedTractors.filter(
            (item: any) => item !== tractor.id,
          );
          dispatch({
            type: SET_PINNED_TRACTORS,
            payload: list,
          });
          const [ftractor] = tractorsList.filter(
            (tractor: any) => tractor.id === list[0],
          );
          dispatch({
            type: SELECTED_TRACTORS,
            payload: [ftractor],
          });
        } else {
          if (isPinnedFun(tractor.id)) {
            const list = pinnedTractors.filter(
              (item: any) => item !== tractor.id,
            );
            dispatch({
              type: SET_PINNED_TRACTORS,
              payload: list,
            });
            const [ftractor] = tractorsList.filter(
              (tractor: any) => tractor.id === list[0],
            );
            dispatch({
              type: SELECTED_TRACTORS,
              payload: [ftractor],
            });
          } else {
            mapPinnedTractors(tractor.id);
            dispatch({
              type: SELECTED_TRACTORS,
              payload: [tractor],
            });
          }
        }
      } else {
        if (exist) {
          dispatch({
            type: SELECTED_TRACTORS,
            payload: [],
          });
          unPinnedTractors(tractor.id);
        } else {
          dispatch({
            type: SELECTED_TRACTORS,
            payload: [tractor],
          });
          mapPinnedTractors(tractor.id);
        }
      }
    } else {
      if (exist) {
        dispatch({
          type: SELECTED_TRACTORS,
          payload: [],
        });
      } else {
        dispatch({
          type: SELECTED_TRACTORS,
          payload: [tractor],
        });
      }
    }
  };
  const onSearch = (searchText: string) => {
    if (searchText) {
      // const objects: { value: string }[] = [];
      const objects: any = [];
      tractorsList &&
        tractorsList.forEach((value: TractorsList) => {
          if (
            value.name &&
            value.name.toLowerCase().includes(searchText.toLowerCase())
          ) {
            objects.push({
              label: (
                <div className="flSearchSec">
                  <div className="flSearchName">{value.name}</div>
                  <img src={tractorIcon} alt="tractor" width="18" height="18" />
                </div>
              ),
              value: value.name,
            });
          }
        });
      polygons &&
        polygons.forEach((value: MapFeature) => {
          if (
            value.name &&
            value.name.toLowerCase().includes(searchText.toLowerCase())
          ) {
            objects.push({
              label: (
                <div className="flSearchSec">
                  <div className="flSearchName">{value.name}</div>
                  <img
                    src={polygon_icon}
                    alt="tractor"
                    width="13"
                    height="18"
                  />
                </div>
              ),
              value: value.name,
            });
          }
        });
      baseStations &&
        baseStations.forEach((value: MapFeature) => {
          if (
            value.name &&
            value.name.toLowerCase().includes(searchText.toLowerCase())
          ) {
            objects.push({
              label: (
                <div className="flSearchSec">
                  <div className="flSearchName">{value.name}</div>
                  <img
                    src={bStationIcon}
                    alt="tractor"
                    width="13"
                    height="18"
                  />
                </div>
              ),
              value: value.name,
            });
          }
        });
      waypointsForRemoteAv &&
        waypointsForRemoteAv.length > 0 &&
        waypointsForRemoteAv.forEach((value: MapFeature) => {
          if (
            value.name &&
            value.name.toLowerCase().includes(searchText.toLowerCase())
          ) {
            objects.push({
              label: (
                <div className="flSearchSec">
                  <div className="flSearchName">{value.name}</div>
                  <img
                    src={mpl_waypoints}
                    alt="tractor"
                    width="13"
                    height="18"
                  />
                </div>
              ),
              value: value.name,
            });
          }
        });
      objects && objects.length === 1 && onSelect(objects[0].value);
      setOptions(objects);
    } else {
      setOptions([]);
    }
  };

  const alignItems = (ts: TractorsList[]) => {
    const data = ts.reduce(
      (a: any, c: TractorsList) => {
        if (c.isAvailable === tractorConstantsNew.faulty.key) {
          a['faulty'].push(c);
        } else if (
          userDetails &&
          userDetails.id &&
          userDetails?.id ===
            c?.heartbeat?.drive_action_details?.current_operator_id &&
          c.isAvailable !== tractorConstantsNew.off.key
        ) {
          a['inuse'].push(c);
        } else if (
          c &&
          c?.heartbeat &&
          c?.heartbeat?.drive_action_details &&
          c?.heartbeat?.drive_action_details?.current_operator_id > 0 &&
          c.isAvailable !== tractorConstantsNew.off.key
        ) {
          a['otherInuse'].push(c);
        } else if (c.isAvailable === tractorConstantsNew.available.key) {
          a['available'].push(c);
        } else if (c.isAvailable === tractorConstantsNew.charging.key) {
          a['charging'].push(c);
        } else {
          a['others'].push(c);
        }
        return a;
      },
      {
        inuse: [],
        otherInuse: [],
        available: [],
        charging: [],
        faulty: [],
        others: [],
      },
    );

    const result: any = Object.values(data).reduce(
      (a: any, c: any) => (a = [...a, ...c]),
      [],
    );
    return result;
  };

  useEffect(() => {
    if (heartBeat && heartBeat.tractor_id) {
      const sTractors = selectedTractors;
      if (sTractors && sTractors.length > 0) {
        for (const tractor of sTractors) {
          if (
            tractor &&
            tractor.id &&
            heartBeat.tractor_id &&
            tractor.id == heartBeat.tractor_id
          ) {
            tractor.isAvailable = getOperationNewStatus(
              heartBeat as TractorHeartBeat,
            );
            tractor.heartbeat = heartBeat;
            // to update tractor details
            selectedTractor &&
              selectedTractor.id &&
              selectedTractor.id === tractor.id &&
              dispatch({
                type: SELECTED_TRACTOR,
                payload: tractor,
              });
            dispatch({
              type: SELECTED_TRACTORS,
              payload: sTractors,
            });
          }
        }
      }
      let tractorsConst: TractorsList[] = tractorsList;
      if (tractorsConst && tractorsConst.length > 0) {
        for (const tractor of tractorsConst) {
          if (
            tractor &&
            tractor.id &&
            heartBeat.tractor_id &&
            tractor.id == heartBeat.tractor_id
          ) {
            tractor.isAvailable = getOperationNewStatus(
              heartBeat as TractorHeartBeat,
            );
            tractor.heartbeat = heartBeat;
            tractorsConst = alignItems(tractorsConst);
            appDispatch({
              type: SET_TRACTORS_LIST,
              payload: tractorsConst,
            });
          }
        }
      }
      const bsTractors = baseStationTractors;
      if (bsTractors && bsTractors.length > 0) {
        for (const tractor of bsTractors) {
          if (
            tractor &&
            tractor.id &&
            heartBeat.tractor_id &&
            tractor.id == heartBeat.tractor_id
          ) {
            tractor.isAvailable = getOperationNewStatus(
              heartBeat as TractorHeartBeat,
            );
            tractor.heartbeat = heartBeat;
            setBaseStationTractors(bsTractors);
          }
        }
      }
    }
  }, [heartBeat]);

  useEffect(() => {
    initTravelledPath(
      tabid === '' ? 0 : tabid,
      tabid === '' ? [] : tractorsList,
    );
  }, [tabid]);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getBasestations();
    }
    return () => {
      localStorage.removeItem('cam_views');
    };
  }, [userDetails, pageSize]);

  const getBasestations = async () => {
    try {
      const baserecords = await getBasestationList(
        userDetails.organization.api_url,
        userDetails.organization.id,
        pageNumber,
        pageSize,
        '',
        '',
      );
      setBaseStations(baserecords.records);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  const handleCancel = () => {
    setheartBeatFlag(false);
  };

  // added this code to show tractor is selected
  useEffect(() => {
    if (selectedTractors && selectedTractors.length > 0) {
      if (tractorsList && tractorsList.length > 0) {
        tractorsList.map((tractor: TractorsList) => {
          tractor.selected = false;
        });
        selectedTractors.map((item: any) => {
          tractorsList.map((tractor: TractorsList) => {
            if (item.id === tractor.id) {
              tractor.selected = true;
            }
          });
        });
      }
      if (baseStationTractors && baseStationTractors.length > 0) {
        baseStationTractors.map((tractor: TractorsList) => {
          tractor.selected = false;
        });
        selectedTractors.map((item: any) => {
          baseStationTractors.map((tractor: TractorsList) => {
            if (item.id === tractor.id) {
              tractor.selected = true;
            }
          });
        });
      }
    } else {
      if (tractorsList && tractorsList.length > 0) {
        tractorsList.map((tractor: TractorsList) => (tractor.selected = false));
      }
      if (baseStationTractors && baseStationTractors.length > 0) {
        baseStationTractors.map(
          (tractor: TractorsList) => (tractor.selected = false),
        );
      }
    }
    tractorsList &&
      tractorsList.length > 0 &&
      appDispatch({
        type: SET_TRACTORS_LIST,
        payload: tractorsList,
      });
    setBaseStationTractors(baseStationTractors);
  }, [selectedTractors, tabid]);

  useEffect(() => {
    // recenter the tractor funtionality
    setRecenterTabId(Number(tabid));
  }, [userDetails, tabid]);

  useEffect(() => {
    dispatch({
      type: SET_SHOW_REMOTE_DRIVE_DETAILS,
      payload: false,
    });
    let id = '';
    handleResetCommand('');
    if (selectedTractors && selectedTractors.length) {
      const [item] = selectedTractors;
      id = item.id;
      dispatch({
        type: SET_SHOW_REMOTE_DRIVE_DETAILS,
        payload: true,
      });
      dispatch({
        type: SELECTED_TRACTOR,
        payload: item,
      });
      // to hide the right side map layers window
      dispatch({
        type: SET_RIGHT_SIDE_TOGGLE,
        payload: false,
      });
      const remoteAvTractor = item.heartbeat;
      if (
        remoteAvTractor &&
        remoteAvTractor.drive_action_details &&
        remoteAvTractor.drive_action_details.current_drive_action &&
        remoteAvTractor.drive_action_details.current_drive_action.length > 0 &&
        getOperationNewStatus(remoteAvTractor) !==
          tractorConstantsNew.charging.key &&
        remoteAvTractor.drive_action_details.current_operator_id &&
        remoteAvTractor?.drive_action_details?.current_operator_id ===
          userDetails?.id &&
        getOperationNewStatus(remoteAvTractor) !==
          tractorConstantsNew.remote_av.key &&
        getOperationNewStatus(remoteAvTractor) !==
          tractorConstantsNew.copycat.key
      ) {
        setTimeout(() => {
          handleResetCommand('GOTO');
        }, 100);
      }
      setActiveIds(id);
    } else {
      dispatch({
        type: SELECTED_TRACTOR,
        payload: null,
      });
      setActiveIds('');
    }
    // activeIds == id ? setActiveIds('') : setActiveIds(id);
  }, [selectedTractors]);

  useEffect(() => {
    setTabid(`${activeIds}`);
  }, [activeIds]);

  return (
    <>
      <div className="fleetlistBlk">
        <div className="searchMapBlk fListSearchMain">
          <SearchOutlined />
          <AutoComplete
            options={options}
            onSelect={onSelect}
            onSearch={onSearch}
            value={autoValue}
          >
            <Input
              data-testid="searchMapInputField-LiveMapFleetList"
              placeholder="Search Map"
              autoComplete="off"
              onChange={(e) => setAutoValue(e.target.value)}
              className="fListSearch"
            />
          </AutoComplete>
        </div>
        <div
          className={`operationsBlk ${
            drivingTractors && drivingTractors.length > 0 ? 'dmenHandle' : ''
          }`}
          data-testid="fleetListSelector-LiveMapFleetList"
        >
          <Collapse
            expandIconPosition="right"
            className="parentAccordion parentIcon"
            expandIcon={({ isActive }) => (
              <LeftOutlined
                onClick={(event) => {
                  setFleetId(fleetId === 'Tractor' ? '0' : 'Tractor');
                  event.stopPropagation();
                }}
                rotate={isActive ? 360 : 270}
              />
            )}
            activeKey={fleetId}
            // onChange={(resp) => {
            //   console.log('resp', resp);
            //   setFleetId(resp);
            // }}
          >
            <Panel
              header="FLEET LIST"
              className="operationsTitle"
              key="Tractor"
              extra={genExtra()}
            >
              {autoDrive ? (
                <AutoDrive
                  setRecenterTabId={setRecenterTabId}
                  setTractorTabes={setTractorTabes}
                />
              ) : (
                <>
                  {((checkAlphaFeature('MosFeatures') &&
                    showAutoDriveConnect) ||
                    !checkAlphaFeature('MosFeatures')) &&
                    userDetails?.is_active && (
                      <Button
                        className="autodriveButton"
                        onClick={() => {
                          setSelectedTractorsIds([]);
                          dispatch({
                            type: EXECUTE,
                            payload: {
                              autoDrive: true,
                              connected: false,
                            },
                          });
                        }}
                      >
                        <img
                          src={autodrive_new}
                          alt="route"
                          className="routeIcon"
                          data-testid="autodrive-LiveMapFleetList"
                        />
                        <img
                          src={liveCommandW}
                          alt="route"
                          height="14px"
                          className="routeIcon2"
                        />
                        <span>Start Autodrive</span>
                      </Button>
                    )}
                  <Tabs
                    className="fleetTabs"
                    activeKey={baseId}
                    onChange={(key) => {
                      mixpanel.track('Live Map', {
                        event: `${key} Tab`,
                      });
                      setBaseId(key);
                    }}
                  >
                    <TabPane
                      className="tractorTab"
                      tab={
                        <>
                          <div
                            className="flIcons"
                            data-testid="alltractrtab-LiveMap"
                          >
                            <img
                              src={
                                baseId === 'a' ? tractorTGreen : tractorTGray
                              }
                              alt=""
                              className="tabTractorImg"
                            />
                            <span> All Tractors</span>
                          </div>
                        </>
                      }
                      key="a"
                    >
                      <div className="childAccordion2 fleetOverallBlk">
                        <TractorList
                          key={Math.random()}
                          tractors={tractorsList}
                          setTractorTabes={setTractorTabes}
                          keyFilter={'a'}
                        />
                      </div>
                    </TabPane>
                    {((checkAlphaFeature('MosFeatures') &&
                      showAutoDriveConnect) ||
                      !checkAlphaFeature('MosFeatures')) && (
                      <TabPane
                        className="tractorTab"
                        tab={
                          <div
                            className="flIcons"
                            data-testid="autodrivetab-LiveMap"
                          >
                            <img
                              src={baseId === 'b' ? lockLGreen : user_locked_1}
                              alt=""
                              className="tabLockImg"
                            />
                            <span> Autodrive</span>
                          </div>
                        }
                        key="b"
                      >
                        <div className="childAccordion2 fleetOverallBlk">
                          <TractorList
                            key={Math.random()}
                            tractors={tractorsList}
                            setTractorTabes={setTractorTabes}
                            keyFilter={'b'}
                          />
                        </div>
                      </TabPane>
                    )}

                    <TabPane
                      className="tractorTab"
                      tab={
                        <>
                          <div
                            className="flIcons"
                            data-testid="chargingtab-LiveMap"
                          >
                            <img
                              src={
                                baseId === 'c' ? charging_White : charging_Gray
                              }
                              alt=""
                              className="tabTractorImg"
                            />
                            <span> Charging</span>
                          </div>
                        </>
                      }
                      key="c"
                    >
                      <div className="childAccordion2 fleetOverallBlk">
                        <TractorList
                          key={Math.random()}
                          tractors={tractorsList}
                          setTractorTabes={setTractorTabes}
                          keyFilter={'c'}
                        />
                      </div>
                    </TabPane>
                  </Tabs>
                </>
              )}
            </Panel>
          </Collapse>
        </div>
        {showPassCode && (
          <PassCode
            tractorId={tabid}
            showModal={showPassCode}
            handleCancel={() => setShowPassCode(false)}
            setShowLiveCommand={() => {
              setShowPassCode(false);
            }}
            heartBeat={heartBeat}
          />
        )}

        {tabid && heartBeatFlag && (
          <HeartBeat
            showModal={heartBeatFlag}
            handleCancel={handleCancel}
            tractorId={tabid}
            tractorName={''}
          ></HeartBeat>
        )}

        {checkAlphaFeature('AutoDrive') ? (
          <>
            {selectedTractor &&
              selectedTractor.id &&
              showRemoteDriveDetails && (
                <RemoteDriveDetails
                  clearPreviewPath={clearPreviewPath}
                  handleResetCommand={handleResetCommand}
                  recenterXY={recenterXY}
                  recenterItem={recenterItem}
                  addRVCommand={addRVCommand}
                  preCommandState={preCommandState}
                  base={base}
                  addRowFollowRVCommand={addRowFollowRVCommand}
                  setPreCommandState={setPreCommandState}
                  clearAll={clearAll}
                />
              )}
          </>
        ) : (
          <>
            {selectedTractor &&
              selectedTractor.id &&
              showRemoteDriveDetails && (
                <RemoteDriveDetailsNew
                  clearPreviewPath={clearPreviewPath}
                  handleResetCommand={handleResetCommand}
                  preCommandState={preCommandState}
                  addRVCommand={addRVCommand}
                  addRowFollowRVCommand={addRowFollowRVCommand}
                  recenterXY={recenterXY}
                  recenterItem={recenterItem}
                />
              )}
          </>
        )}
        {showTermAndCond && (
          <TermsCond
            handleCallback={(flag: boolean) => {
              if (
                flag &&
                selectedTractorsIds &&
                selectedTractorsIds.length > 0
              ) {
                setBaseId('b');
                selectedTractorsIds &&
                  selectedTractorsIds.length > 0 &&
                  connectToTractor(selectedTractorsIds[0]);
                clearPreviewPath(selectedTractorsIds[0]);
                clearAllLocalDBCommands(selectedTractorsIds[0]);
              }
              dispatch({
                type: SET_SHOW_TERM_AND_COND,
                payload: false,
              });
            }}
          />
        )}
        {showAutoDriveFeedBackPop && (
          <AutoDriveFeedBackPop showModal={showAutoDriveFeedBackPop} />
        )}
      </div>
    </>
  );
};

export default LiveMapFleetList;
