import React, { useContext, useEffect, useRef, useState } from 'react';
import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import { useMixpanel } from 'react-mixpanel-browser';
import { Row, notification, Space, Card, DatePicker, Input } from 'antd';
import moment from 'moment';
import Layout, { Content } from 'antd/lib/layout/layout';
import {
  getTractorsListData,
  getTicketsHistory,
  getOrgEquipments,
  getTasksearch,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { TractorsList, Taskviewdetails, Taskview } from '../../constants/types';
import {
  fromToDateWrapper,
  getDateTimes,
  initClearSelection,
  initScroller,
} from '../../constants/Common';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import 'react-datepicker/dist/react-datepicker.css';
import InfiniteScrollTable from '../common/InfiniteScrollTable';

import FlagGrayIcon from '../../assets/images/flag_gray.svg';
import FlagOrangeIcon from '../../assets/images/flag_orange.svg';
import FlagRedIcon from '../../assets/images/flag_red.svg';
import AddButtonIcon from '../../assets/images/add_button_icon.svg';
import TicketsCreateEdit from './TicketsCreateEdit';
import TicketViewEdit from './TicketViewEdit';
import applicationIds from '../../locale/applicationIds.json';
import { FleetHealthCxt } from '../fleethealth/FleetHealthContext';
import { RESET_NAVIGATE } from '../../context/actions';
import { privilagesConstants } from '../../constants/Privilages';
import CSelect from '../common/CSelect';
import { resolveFilterData } from '../../constants/AppData';
import LevelFilter from '../common/LevelFilter';
import { delay } from '../../constants/Common';
import CSelect1 from '../common/CSelect1';
import { speCharRegX } from '../../constants/constant';
import { SearchOutlined } from '@ant-design/icons';
import './TicketsStyle.css';

const { Search } = Input;

interface TractorResponseType {
  label: string;
  value: number | string | boolean;
  disabled: boolean;
  index: number | string;
}
const { RangePicker } = DatePicker;

type Props = {
  createTicket?: boolean;
  onSubmit?: (content: string) => void;
};

const Tickets: React.FC<Props> = ({ createTicket, onSubmit }) => {
  const { t } = useTranslation();
  const { userDetails, APPReducer, privilegeChecker } =
    useContext(ApplicationContext);
  const [state, dispatch] = APPReducer;
  const { navigate } = state;
  let routeData: any;
  if (navigate) {
    routeData = navigate.data;
  }
  const { ticketRefreshStatus } = useContext(FleetHealthCxt);
  const [tractorList, setTractorList] = useState<TractorsList[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<any>(25);
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'created_date_time',
    order: 'descend',
  });
  const mixpanel = useMixpanel();
  const [result, setResult] = useState<Taskviewdetails[]>([]);

  const [tractorsData, setTractorsData] = useState<TractorResponseType[]>([
    { label: 'All', value: '', disabled: false, index: '' },
  ]);
  const [status, setStatus] = useState<any>('open');
  const [createdAt, setCreatedAt] = useState<number>(0);
  const [toDateTime, setToDateTime] = useState<any>(null);
  const [filterData, setFilter] = useState<any[]>([]);
  const [search, setSearch] = useState<string>('');
  const [severityLevel, setSeverityLevel] = useState<string>('');
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalcount, settotalcount] = useState<any>();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showViewEdit, setShowViewEdit] = useState<boolean>(false);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const [equipmentsList, setEquipmentsList] = useState<any[]>([]);
  const [equipment, setEquipment] = useState<any>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);
  const [dateFilter, setDateFilter] = useState<any>([]);
  const [sortedData, setSortedData] = useState<any>({
    created_date_time: 'descend',
  });
  const [keyCode, setKeyCode] = useState<boolean>(false);
  const activity = useRef<string>('');

  const checkActivity = () =>
    ['search', 'sort', 'filter'].includes(activity.current);

  const columns = [
    {
      title: () => {
        return (
          <>
            {t(translate.fleetticketsHistory.dateTime)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'created_date_time' && (
                <img
                  className="sort-pad"
                  src={
                    sortedData['created_date_time'] === 'ascend'
                      ? sortUp
                      : sortDown
                  }
                />
              )}
            </span>
          </>
        );
      },
      sortDirections: ['descend', 'ascend', 'descend'],
      dataIndex: 'created_date_time',
      key: 'created_date_time',
      width: '15%',
      sorter: (a: { date_time: number }, b: { date_time: number }) => {
        return a.date_time - b.date_time;
      },
      sortOrder:
        sortedInfo.columnKey === 'created_date_time' && sortedInfo.order,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: () => {
        return (
          <>
            {t(translate.fleetticketsHistory.severity)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'ticket_level' && (
                <img
                  className="sort-pad"
                  src={
                    sortedData['ticket_level'] === 'ascend' ? sortUp : sortDown
                  }
                />
              )}
            </span>
          </>
        );
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      dataIndex: 'ticket_level',
      key: 'ticket_level',
      width: '9%',
      sorter: (a: { ticket_level: number }, b: { ticket_level: number }) => {
        return a.ticket_level - b.ticket_level;
      },
      sortOrder: sortedInfo.columnKey === 'ticket_level' && sortedInfo.order,
      ellipsis: true,
      showSorterTooltip: false,
      render: (level: any) => {
        return (
          <div className="description">
            <img className="mr11" src={getSeverityIcon(level, 1)} />
            <img className="mr11" src={getSeverityIcon(level, 2)} />
            <img className="mr11" src={getSeverityIcon(level, 3)} />
          </div>
        );
      },
    },

    {
      title: `${t(translate.fleetticketsHistory.assignto)}`,
      dataIndex: 'assignee',
      key: 'assignee',
      width: '12%',
      render: (assignee: string, record: any) => (
        <div>
          {record.is_service_ticket_raised ? (
            <div>Monarch</div>
          ) : record.assignee ? (
            <>{assignee}</>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      title: `${t(translate.fleetticketsHistory.equipment)}`,
      dataIndex: 'equipmentName',
      key: 'equipmentName',
      width: '15%',
    },
    {
      title: `${t(translate.fleetticketsHistory.ticketDescription)}`,
      dataIndex: 'ticket_description',
      key: 'ticket_description',
      width: '20%',
      ellipsis: true,
      render: (name: string) => {
        return (
          <span className="description" data-testid={`${name}-Tickets`}>
            {name}
          </span>
        );
      },
    },
    {
      title: `${t(translate.fleetticketsHistory.tags)}`,
      dataIndex: 'tags',
      render: (tags: any) => {
        return (
          <div className="tags-container">
            <div className="tags-no-hover">
              {tags?.slice(0, 2).map((data: any) => {
                return (
                  <div
                    className="tag-chip-selected overline2"
                    key={data}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <span data-testid={`${data}-Tickets`}>
                      {data.toLowerCase() === 'driveability'
                        ? 'Drivability'.toUpperCase()
                        : data}
                    </span>
                  </div>
                );
              })}
              {tags?.length > 2 && (
                <div
                  className="tag-chip-selected overline2 moreTags"
                  onClick={(e) => e.stopPropagation()}
                >
                  <span> + {tags.length - 2}</span>
                </div>
              )}
            </div>
            <div className="tags-hover">
              {tags?.map((data: any) => {
                return (
                  <div
                    className="tag-chip-selected overline2"
                    key={data}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {data.toLowerCase() === 'driveability'
                      ? 'Drivability'.toUpperCase()
                      : data}
                  </div>
                );
              })}
            </div>
          </div>
        );
      },
    },
  ];

  const getassigned = async () => {
    let res: any[] = [];
    const taskviewData: Taskviewdetails = await getTasksearch(
      userDetails.organization.api_url,
      '',
    );
    taskviewData.records.map((ele: Taskview) => {
      ele.fullname = ele.first_name + ' ' + ele.last_name;
    });
    res = taskviewData.records;
    setResult(res);
  };

  const getTractorsList = async () => {
    try {
      const tractor: { records: TractorsList[] } = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractors: TractorResponseType[] = data.map((d: TractorsList) => {
        return {
          label: d.name,
          value: d.id,
          disabled: false,
          index: d.id,
        };
      });
      setTractorList([...tractorList, ...data]);
      setTractorsData([...tractorsData, ...tractors]);
    } catch (error: any) {
      notification.error({
        message: error.response?.data?.error.message || error.message,
      });
    }
  };

  const loadEquipments = async () => {
    setEquipmentsList([]);
    let result: any = await getOrgEquipments(
      userDetails.organization.api_url,
      userDetails.organization_id,
    );
    result = result.filter(
      (equipment: any) => equipment.equipment_type !== 'None',
    );
    result = result.map((equip: any) => {
      const { name } = equip;
      equip.name = !speCharRegX?.test(name) ? encodeURIComponent(name) : name;
      return equip;
    });
    setEquipmentsList(result);
  };

  const formatTicket = (data: any[]) =>
    data.map((ele: any) => {
      ele.completed_date_time = ele.completed_date_time
        ? getDateTimes(ele.completed_date_time)
        : '';
      ele.date_time = ele.created_date_time;
      ele.created_date_time = ele.created_date_time
        ? getDateTimes(ele.created_date_time)
        : '';
      ele.completed_by_user = ele.completed_by_user
        ? ele.completed_by_user.first_name +
          ' ' +
          ele.completed_by_user.last_name
        : '';
      ele.created_by_user = ele.created_by_user
        ? ele.created_by_user.first_name + ' ' + ele.created_by_user.last_name
        : '';
      ele.assignee = ele.assignee
        ? ele.assignee.first_name + ' ' + ele.assignee.last_name
        : '';
      ele.equipmentName = ele.equipment ? ele.equipment.name : 'Other';
      ele.ticket_level = ele.ticket_level.toString();
      ele.tractorId = ele.tractor_id;
      return ele;
    });

  const getTickets = async () => {
    if (checkActivity()) initScroller();
    if (keyCode) {
      await delay(1000);
    }

    const filter: any = { pageNumber, pageSize };
    try {
      setLoading(true);
      if (Number(severityLevel) > 0) {
        filter.severityLevel = severityLevel;
      }
      if (equipment && equipment?.equipment_id) {
        filter.equipment = {
          equipment_id:
            equipment?.equipment_id === 'NULL'
              ? 'NULL'
              : equipment?.equipment_id,
          equipment_type: equipment?.equipment_type,
        };
      }
      if (createdAt > 0) {
        filter.createdAt = createdAt;
      }
      if (status !== '' && !routeData) {
        filter.status = status;
      }
      const fromDateTime =
        dateFilter && !routeData
          ? dateFilter[0] && moment(dateFilter[0]).startOf('day').toDate()
          : '';
      const toDateTime =
        dateFilter && !routeData
          ? dateFilter[1] && moment(dateFilter[1]).endOf('day').toDate()
          : '';
      if (!routeData && search.toLowerCase() === 'monarch') {
        filter.is_service_ticket_raised = true;
      }

      const tickethistory = await getTicketsHistory(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        pageNumber,
        pageSize,
        filter,
        fromDateTime ? fromDateTime.getTime() : '',
        toDateTime ? toDateTime.getTime() : '',
        !routeData
          ? search.toLowerCase() === 'monarch'
            ? ''
            : search
          : routeData.ref_uuid,
        sortedInfo,
      );
      settotalcount(tickethistory._metadata?.total_records_count);
      let data =
        tickethistory &&
        tickethistory.records &&
        tickethistory.records.length > 0
          ? tickethistory.records
          : [];
      data = formatTicket(data);
      setHasMore(data.length === pageSize);
      if (checkActivity()) setFilter(data);
      else if (activity.current === 'paginate')
        setFilter([...filterData, ...data]);
      else setFilter(data);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message || error.message,
        duration: 2,
      });
    } finally {
      setLoading(false);
    }
  };

  const getSeverityIcon = (level: number, orderNumber: number) => {
    if (level >= 3) {
      return FlagRedIcon;
    }
    if (orderNumber <= level) {
      return FlagOrangeIcon;
    }
    return FlagGrayIcon;
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    setFilter([]);
    setLoading(true);
    activity.current = 'sort';
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trimLeft('').regX;
    onSubmit && onSubmit(value);
    setSearch(value);
  };

  const resetData = () => {
    setFilter([]);
    settotalcount(0);
  };

  useEffect(() => {
    return () => {
      setFilter([]);
      setTractorList([]);
      setTractorsData([]);
      settotalcount(0);
    };
  }, []);

  useEffect(() => {
    if (dateFilter === null) {
      setToDateTime('');
    }
  }, [dateFilter]);

  const levelSelect = (e: string) => {
    filterData.length = 0;
    setSeverityLevel(e);
    mixpanel.track('Tickets', {
      event: `Select Level ${e}`,
    });
  };

  const equipmentSelect = (e: any) => {
    filterData.length = 0;
    const raw = JSON.parse(e);
    setEquipment(raw);
    mixpanel.track('Tickets', {
      event: `Select Equipment ${raw.name}`,
    });
  };

  const statusSelect = (e: string) => {
    filterData.length = 0;
    setStatus(e);
    mixpanel.track('Tickets', {
      event: `Select Status ${e}`,
    });
  };

  const createdSelect = (e: string | number) => {
    filterData.length = 0;
    setCreatedAt(Number(e));
    mixpanel.track('Tickets', {
      event: `Select Creator ${e}`,
    });
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const Close = () => {
    setShowForm(false);
    setShowViewEdit(false);
    getTickets();
    initClearSelection();
  };

  const showCreateForm = () => {
    setShowForm(true);
    mixpanel.track('Addticket', {
      event: 'Add Ticket',
    });
  };

  const afterTicketSave = () => {
    resetData();
    filterData.length = 0;
    setRefresh(!refresh);
  };

  const viewTicket = (ticket: any) => {
    setShowViewEdit(true);
    setSelectedTicket(ticket);
    mixpanel.track('DetailsTicket', {
      event: 'Ticket Details',
    });
  };

  useEffect(() => {
    if (dateFilter && dateFilter.length > 0) {
      onDateRangeChange(dateFilter);
    }
  }, [dateFilter]);

  const onDateRangeChange = (dates: any) => {
    filterData.length = 0;
    if (dates) {
      const [from, to] = fromToDateWrapper(dates);
      setToDateTime(to.toDate());
      mixpanel.track('Tickets', {
        event: `Select From Date ${from.toDate()} & To Date ${to.toDate()}`,
      });
    } else {
      setToDateTime(null);
      mixpanel.track('Tickets', {
        event: 'Select From Date & To Date Empty',
      });
    }
  };

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current > moment();
  }
  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
      loadEquipments();
      getassigned();
      if (!routeData) setInit(true);
    }
  }, [userDetails]);

  useEffect(() => {
    if (init) {
      activity.current = 'filter';
      loadTickets();
    }
  }, [createdAt, severityLevel, equipment, toDateTime, status]);

  useEffect(() => {
    if (init) loadTickets();
  }, [pageNumber, sortedInfo]);

  useEffect(() => {
    if ((init && search?.length > 2) || !search?.length) loadTickets();
  }, [search]);

  useEffect(() => {
    if (init) {
      activity.current = '';
      loadTickets();
    }
  }, [refresh]);

  const loadTickets = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getTickets();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getTickets();
    }
  };

  const getTickets1 = async () => {
    const tickethistory = await getTicketsHistory(
      userDetails.organization.api_url,
      userDetails.organization.fleet.id,
      pageNumber,
      pageSize,
      {},
      '',
      '',
      routeData.ref_uuid,
      sortedInfo,
    );
    let data =
      tickethistory?.records && tickethistory.records.length > 0
        ? tickethistory.records
        : [];
    data = formatTicket(data);
    if (data.length) {
      const [temp] = data.filter(
        (ticket: any) => ticket.ticket_id == routeData.ref_uuid,
      );
      setInit(true);
      if (temp) viewTicket(temp);
      dispatch({
        type: RESET_NAVIGATE,
      });
    }
  };

  useEffect(() => {
    if (routeData) {
      const { ref_uuid, status } = routeData;
      if (ref_uuid) setSearch(ref_uuid);
      setStatus(status);
      getTickets1();
    }
  }, [routeData]);

  useEffect(() => {
    if (createTicket) {
      setShowForm(true);
    }
  }, [createTicket]);

  useEffect(() => {
    if (init) afterTicketSave();
  }, [ticketRefreshStatus]);

  const handleKey = (e: any) => {
    setKeyCode(false);
    if (e.keyCode === 8) {
      setKeyCode(true);
    }
  };

  return (
    <Layout style={{ height: '80vh' }}>
      <div className="mainContent">
        <Content>
          <div className="tblContainer viewportContainer">
            <Row>
              <Card className="tblTopOutSec tcktsBlk searchFilters filterGap40">
                <Space
                  className="mt0"
                  style={{ marginBottom: 0, position: 'relative' }}
                >
                  <div
                    className="searchSec searchUnit ticketsSearchBar"
                    style={{ flexGrow: 1 }}
                  >
                    <Input
                      className="ant-input-search ticket-search"
                      id={applicationIds.personnelScreen.ticketSearcchText}
                      placeholder="Search Tickets"
                      data-testid="searchInputField-Tickets"
                      autoComplete="off"
                      onChange={(e) => handleSearch(e)}
                      style={{
                        width: '200px !important',
                      }}
                      onKeyDown={(e) => handleKey(e)}
                      value={search}
                      prefix={<SearchOutlined />}
                    />
                  </div>
                  <div
                    className="smallFilter resolved-unresolved-menu"
                    data-testid="resolvedUnresolvedDropdown-Tickets"
                  >
                    <CSelect
                      dataTestkey="name"
                      dataTestLabel="Employees"
                      list={resolveFilterData}
                      isAll={false}
                      selectTrigger={(selected) => {
                        if (selected) {
                          const { value } = selected;
                          statusSelect(value);
                        }
                      }}
                      label="Status"
                      keyValue="value"
                      defaultId={status}
                    />
                  </div>
                  <div
                    className="medFilter"
                    data-testid="severityLevel-Tickets"
                  >
                    <LevelFilter
                      selectTrigger={(selected) => {
                        levelSelect(selected ? selected.id : '');
                      }}
                      defaultId={severityLevel}
                    />
                  </div>
                  <RangePicker
                    format="YYYY-MM-DD"
                    onChange={(dates) => setDateFilter(dates)}
                    value={dateFilter}
                    className="dropdownStyle dW250_date dateFilter"
                    data-testid="dateselection-Tickets"
                    disabledDate={disabledDate}
                  />

                  <div
                    className="medFilter"
                    data-testid="equipmentselection-Tickets"
                  >
                    <CSelect1
                      list={equipmentsList}
                      selectTrigger={(selected) => {
                        if (selected) {
                          equipmentSelect(JSON.stringify(selected));
                        } else {
                          equipmentSelect(0);
                        }
                      }}
                      label="Equipment"
                      placeholder="All Equipments"
                      keyValue="equipment_id"
                      defaultId="0"
                    />
                  </div>
                  <div
                    className="medFilter"
                    data-testid="creatorselection-Tickets"
                  >
                    <CSelect
                      list={result}
                      selectTrigger={(selected) => {
                        if (selected) {
                          const { id } = selected;
                          createdSelect(id);
                        } else {
                          createdSelect(0);
                        }
                      }}
                      label="Creator"
                      placeholder="All Creators"
                      keyLabel="fullname"
                    />
                  </div>
                </Space>
              </Card>
            </Row>
            <Row>
              <div className="tblDft farmTabsTbl hoverable cPointer">
                <InfiniteScrollTable
                  columns={columns}
                  hasMore={hasMore}
                  loading={loading}
                  filterData={filterData}
                  totalcount={totalcount}
                  handleLoadMore={handleLoadMore}
                  filename="Tickets"
                  onRowClick={(record) => viewTicket(record)}
                  onChange={handleChange}
                />
                {privilegeChecker(privilagesConstants.Create_a_fleet_ticket) &&
                  !showForm &&
                  !showViewEdit && (
                    <div>
                      <img
                        id={applicationIds.ticketScreen.editTicket}
                        className={
                          showForm || showViewEdit
                            ? 'addedbtn addButton'
                            : 'addButton'
                        }
                        data-testid="addButton-Employees"
                        src={AddButtonIcon}
                        onClick={showCreateForm}
                      />
                    </div>
                  )}
              </div>
            </Row>
          </div>
        </Content>
      </div>

      {showForm && (
        <TicketsCreateEdit
          Close={Close}
          afterSave={afterTicketSave}
          toggleWidget={showForm}
        ></TicketsCreateEdit>
      )}
      {selectedTicket && showViewEdit && (
        <TicketViewEdit
          Close={Close}
          afterSave={afterTicketSave}
          ticketObj={selectedTicket}
          toggleWidget={showViewEdit}
        ></TicketViewEdit>
      )}
    </Layout>
  );
};

export default Tickets;
