import React from 'react';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { APP_TYPE, REMOTE_ACCESS_GRANT } from '../constants/constant';
import RoutesConstants from './RoutesConstant';

interface Props {
  component: React.FC;
  path: string;
  exact: boolean;
}

const PrivateRoute: React.FC<Props> = ({ path, exact, component }: Props) => {
  const history = useHistory();
  const { push } = history;
  const isAuthenticated = localStorage.getItem('auth');
  const checkRemoteAccess =
    window.location.pathname.includes(REMOTE_ACCESS_GRANT);
  const location = useLocation();
  const { search } = location;

  if (checkRemoteAccess && !isAuthenticated) {
    const token = new URLSearchParams(search).get('remote_access_token');
    const appType = new URLSearchParams(search).get('app_type');
    localStorage.setItem(REMOTE_ACCESS_GRANT, String(token));
    localStorage.setItem(APP_TYPE, String(appType));
  }
  if (checkRemoteAccess && isAuthenticated) {
    const token = new URLSearchParams(search).get('remote_access_token');
    const appType = new URLSearchParams(search).get('app_type');
    const keyCheck = localStorage.getItem(REMOTE_ACCESS_GRANT);
    const appType1 = localStorage.getItem(APP_TYPE);
    localStorage.setItem(REMOTE_ACCESS_GRANT, String(token));
    localStorage.setItem(APP_TYPE, String(appType ?? appType1));

    if (!keyCheck) {
      // push(RoutesConstants.RemoteAccess);
      // window.open(window.location.toString(), '_blank');
      window.location.href = window.location.toString();
    }
  }

  return isAuthenticated ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Redirect to="/login" />
  );
};
export default PrivateRoute;
