/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/display-name */
import { Layout, notification } from 'antd';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { getBasestationList } from '../../constants/Api';
import { BaseStationResponse } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import Editbasestation from './Editbasestation';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import {
  checkActivity,
  getTractorStatusClass,
  initClearSelection,
  initScroller,
} from '../../constants/Common';
import { useMixpanel } from 'react-mixpanel-browser';

interface Props {
  search: string;
}
const BaseStation: React.FC<Props> = ({ search }: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [filterData, setFilter] = useState<BaseStationResponse[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [pageSize, setPagesize] = useState<any>(25);
  const [baseflag, setbaseflag] = useState<boolean>(false);
  const [baseobj, setbaseData] = useState<any>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalcount, settotalcount] = useState<any>();
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'name',
    order: 'ascend',
  });
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortedData, setSortedData] = useState<any>({
    name: 'ascend',
  });
  const mixpanel = useMixpanel();
  const [initialize, setInitialize] = useState<boolean>(false);
  const activity = useRef<string>('');
  const searchString = useRef<string>('');
  const [baseStations, setBaseStations] = useState<BaseStationResponse[]>([]);
  const [baseStationNames, setBaseStationNames] = useState<string[]>([]);

  const columns = [
    {
      render: (name: string) => {
        return <span data-testid={`${name}-BaseStation`}>{name}</span>;
      },
      title: () => {
        return (
          <>
            {t(translate.fleet.name)}
            <span className="sort-pad">
              <img
                className="sort-pad"
                src={sortedData['name'] === 'ascend' ? sortUp : sortDown}
              />
            </span>
          </>
        );
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      ellipsis: true,
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      showSorterTooltip: false,
      sorter: true,
      // sorter: (a: { name: string }, b: { name: string }) =>
      //   a.name.localeCompare(b.name),
      onCell: (record: BaseStationResponse) => ({
        onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.fleet.status)}`,
      width: '30%',
      render: (record: any) => {
        return (
          <span className={getTractorStatusClass(record.status)}>
            {record.status}
          </span>
        );
      },
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
    {
      title: `${t(translate.fleet.pin)}`,
      dataIndex: 'bsin',
      key: 'bsin',
      width: '50%',
      onCell: (record: any) => ({
        onClick: () => getRow(record),
      }),
    },
  ];

  const wrapResult = async (
    apiUrl: string,
    ogId: number,
    pageNumber: number,
    pageSize: number,
    search: string,
    sortedInfo: string,
  ) => {
    const result = await getBasestationList(
      apiUrl,
      ogId,
      pageNumber,
      pageSize,
      search,
      sortedInfo,
    );
    return {
      result,
      searchKey: search,
    };
  };

  const getBasestations = async () => {
    if (checkActivity(activity.current)) initScroller();
    setLoading(true);
    try {
      const { result: baserecords, searchKey } = await wrapResult(
        userDetails.organization.api_url,
        userDetails.organization.id,
        pageNumber,
        pageSize,
        search,
        sortedInfo,
      );

      if (searchString.current !== searchKey) return;

      settotalcount(baserecords?._metadata?.total_records_count);
      const data =
        baserecords && baserecords.records && baserecords.records.length > 0
          ? baserecords.records
          : [];
      data.forEach((element: any) => {
        let baseStnPin = '';
        if (element?.bsin) {
          baseStnPin =
            element?.bsin === '0' || element?.bsin === '-1'
              ? '-'
              : element?.bsin;
        } else {
          baseStnPin = '-';
        }
        (element.name = element?.name ?? '-'), (element.bsin = baseStnPin);
        element.status = element?.basestation_connected_status
          ? 'available'
          : 'Off';
      });
      if (!initialize) setBaseStations(data);

      setHasMore(data.length === pageSize);

      if (checkActivity(activity.current)) setFilter(data);
      else if (activity.current === 'paginate')
        setFilter([...filterData, ...data]);
      else setFilter(data);
    } catch (err: any) {
      notification.error({
        message: err.response?.data?.error?.message || err.message,
      });
    } finally {
      setLoading(false);
      if (!initialize) setInitialize(true);
    }
  };

  const getRow = (record: BaseStationResponse) => {
    setbaseData(record);
    setbaseflag(true);
    mixpanel.track('BaseStation', {
      event: `${record.name} Clicked`,
    });
  };
  const Close = () => {
    setbaseflag(false);
    initClearSelection();
    mixpanel.track('Edit BaseStation', {
      event: 'Edit baseStation Closed',
    });
  };
  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity(activity.current) && document)
      initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    activity.current = 'sort';
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) loadBaseStations();
  }, [userDetails, pageNumber]);

  useEffect(() => {
    searchString.current = search;
    activity.current = 'search';

    if (userDetails && userDetails.organization && initialize)
      loadBaseStations();
  }, [search]);

  useEffect(() => {
    const bNames = baseStations.map((item: any) => item.name);
    setBaseStationNames(bNames);
  }, [baseStations]);

  useEffect(() => {
    if (sortedInfo.order && initialize) loadBaseStations();
  }, [sortedInfo.order]);

  const loadBaseStations = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity(activity.current)) {
      pageNumber !== 1 ? setPageNumber(1) : getBasestations();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getBasestations();
    }
  };

  return (
    <Layout>
      {baseflag && (
        <Editbasestation
          baseobj={baseobj}
          Close={Close}
          setRefresh={() => setRefresh(!refresh)}
          baseflag={baseflag}
          baseStationNames={baseStationNames}
        ></Editbasestation>
      )}

      <div className="tblDft farmTabsTbl hoverable cPointer">
        <InfiniteScrollTable
          columns={columns}
          hasMore={hasMore}
          loading={loading}
          filterData={filterData}
          totalcount={totalcount}
          handleLoadMore={handleLoadMore}
          filename="Base Stations"
          onChange={handleChange}
        />
      </div>
    </Layout>
  );
};

export default BaseStation;
