/* eslint-disable no-console */
import React, { memo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  checkIsTractorConnected,
  tractorConstantsNew,
} from '../../constants/Common';
import { ApplicationContext } from '../../context/AppContext';
import { AutoDriveApplicationContext } from '../../context/AutoDriveContext';
import translate from '../../locale/en_translate.json';
import AppLoader from '../common/AppLoader';
import TractorCard from './TractorCard';

type Props = {
  tractors: any;
  setTractorTabes: (params: any) => void;
  keyFilter: string;
};
const TractorList: React.FC<Props> = ({
  tractors,
  setTractorTabes,
  keyFilter,
}) => {
  const { userDetails, APPReducer } = useContext(ApplicationContext);
  const [state] = APPReducer;
  const { t } = useTranslation();
  const { tractorsLoader } = state;
  const [errorMessage, setErrorMessage] = useState(true);
  return (
    <>
      {tractors &&
        tractors.length > 0 &&
        tractors.map((tractor: any) => {
          if (
            keyFilter === 'a' ||
            (keyFilter === 'b' &&
              checkIsTractorConnected(tractor, userDetails.id)) ||
            (keyFilter === 'c' &&
              tractor.isAvailable === tractorConstantsNew.charging.key)
          ) {
            errorMessage && setErrorMessage(false);
            return (
              <>
                <TractorCard
                  key={Math.random()}
                  tractor={tractor}
                  setTractorTabes={(tractor) => setTractorTabes(tractor)}
                />
              </>
            );
          }
        })}
      {errorMessage && (
        <span className="no-inuse-tractors">
          {!tractorsLoader &&
            keyFilter === 'a' &&
            t(translate.label.noTractorsAvailable)}
          {keyFilter === 'b' && t(translate.label.noTractorsConnected)}
          {keyFilter === 'c' && t(translate.label.noTractorsCharged)}
        </span>
      )}
      <AppLoader loader={tractorsLoader} />
    </>
  );
};

export default memo(TractorList);
