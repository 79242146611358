import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Modal, notification, Select, Tabs } from 'antd';
import moment from 'moment';
import { ApplicationContext } from '../../context/AppContext';
import { getClient } from '../../util/MqttConnection';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-streaming';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Chart: any = require('react-chartjs-2').Chart;
const { TabPane } = Tabs;
import copyIcon from '../../assets/images/copy_icon.png';
import constants from '../../constants/constant';
import HeartBeat1 from './HeartBeat1';
const {
  TRACTOR_MODEL,
  SYSTEM_MODEL,
  RAMDATA,
  TEMPDATA,
  USAGEDATA,
  POWERSTATUSDATA,
  DISKUSAGEDATA,
} = constants;

interface Props {
  showModal: boolean;
  handleCancel: any;
  tractorId: any;
  tractorName: any;
}
const ModelData: any = [];
const data: any = {
  datasets: [
    {
      label: 'Dataset 1',

      fill: false,
      lineTension: 0.4,
      backgroundColor: 'cyan',
      borderColor: 'cyan',
      borderJoinStyle: 'miter',
      pointRadius: 0,
      showLine: true,
      data: [],
    },
    {
      label: 'Dataset 2',

      fill: false,
      lineTension: 0.4,
      backgroundColor: 'green',
      borderColor: 'green',
      borderJoinStyle: 'miter',
      pointRadius: 0,
      showLine: true,
      data: [],
    },
    {
      label: 'Dataset 3',

      fill: false,
      lineTension: 0.4,
      backgroundColor: 'yellow',
      borderColor: 'yellow',
      borderJoinStyle: 'miter',
      pointRadius: 0,
      showLine: true,
      data: [],
    },
    {
      label: 'Dataset 4',

      fill: false,
      lineTension: 0.4,
      backgroundColor: 'blue',
      borderColor: 'blue',
      borderJoinStyle: 'miter',
      pointRadius: 0,
      showLine: true,
      data: [],
    },
    {
      label: 'Dataset 5',

      fill: false,
      lineTension: 0.4,
      backgroundColor: 'orange',
      borderColor: 'orange',
      borderJoinStyle: 'miter',
      pointRadius: 0,
      showLine: true,
      data: [],
    },
    {
      label: 'Dataset 6',

      fill: false,
      lineTension: 0.4,
      backgroundColor: 'red',
      borderColor: 'red',
      borderJoinStyle: 'miter',
      pointRadius: 0,
      showLine: true,
      data: [],
    },
  ],
};
const data2: any = {
  datasets: [
    {
      label: 'llc_state',

      fill: false,
      lineTension: 0.4,
      backgroundColor: '#f44336',
      borderColor: '#f44336',
      borderJoinStyle: 'miter',
      pointRadius: 0,
      showLine: true,
      data: [],
    },
  ],
};

const systemModelData: any = [];
let chartsystemModelData: any;
let charttractorModelData: any;
const heartBeatData: any = [];

export const HeartBeat: React.FC<Props> = ({
  showModal,
  handleCancel,
  tractorId,
  tractorName,
}: Props) => {
  const dateformat = ' DD-MM-YYYY HH:mm:ss';
  const { userDetails } = useContext(ApplicationContext);
  const [modalName, setModalName] = useState<any>('SystemModal');
  const [indexvalue, setIndexvalue] = useState<any>('1');
  const [indexData, setIndexData] = useState<any>();
  const [indexOptions, setIndexOptions] = useState<any>();
  const [label, setLabel] = useState<string>('');
  const heartBeatRef = useRef<any>();
  useEffect(() => {
    try {
      if (userDetails && userDetails.organization) {
        const { organization } = userDetails;
        const mqttClient = getClient(
          organization.mqtt_ip,
          organization.mqtt_port,
        );

        if (mqttClient) {
          mqttClient.on('connect', () => {
            mqttClient.subscribe(['HeartBeat', `system_model_${tractorId}`]);
          });
          mqttClient.on('message', (topic: string, message: any) => {
            if (topic === `system_model_${tractorId}`) {
              const obj: any = {
                data: message.toString(),
                DateTime: moment(new Date()).format(dateformat),
              };
              chartsystemModelData = obj.data && obj.data.split(',');
              systemModelData.unshift(obj);
              if (systemModelData.length === 50) {
                systemModelData.splice(-25);
              }
            } else if (topic === 'HeartBeat') {
              const heartbeat = JSON.parse(message.toString());
              if (heartbeat?.tractor_id === Number(tractorId)) {
                heartBeatRef.current = heartbeat;
                const obj: any = {
                  data: JSON.parse(message.toString()),
                  DateTime: moment(new Date()).format(dateformat),
                };
                charttractorModelData =
                  obj?.data?.tractor_model &&
                  obj?.data?.tractor_model.split(',');
                heartBeatData.unshift(obj);
                if (heartBeatData.length === 50) {
                  heartBeatData.splice(-25);
                }
              }
            }
          });
        }

        return () => {
          mqttClient.unsubscribe(['HeartBeat', `system_model_${tractorId}`]);
          mqttClient.end();
        };
      }
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  }, []);

  const onSelectModal = (e: any) => {
    data.datasets.map((ele: any) => {
      ele.data = [];
    });
    data2.datasets[0].data = [];
    ModelData.length = 0;
    setModalName(e);
    if (e === 'TractorModal') {
      setIndexvalue('0');
      modalFun();
    } else {
      setIndexvalue('1');
      modalFun();
    }
  };

  const modalFun = () => {
    Object.entries(indexData).length &&
      Object.entries(indexData).map((item: any, index: any) => {
        const obj = {
          id: item[0],
          name: item[1],
        };
        ModelData.push(obj);
        if (modalName === 'TractorModal') {
          data2.datasets[0].label = item[1];
        } else {
          data.datasets.map((ele: any, i: any) => {
            if (index === i) {
              ele.label = item[1];
            }
          });
        }
      });
  };
  const handleCancelBtn = () => {
    data.datasets[0].data = [];
    systemModelData.length = 0;
    chartsystemModelData = 0;
    charttractorModelData = 0;
    heartBeatData.length = 0;
    handleCancel();
  };

  useEffect(() => {
    if (modalName) {
      modalName === 'TractorModal'
        ? setIndexOptions(TRACTOR_MODEL)
        : setIndexOptions(SYSTEM_MODEL);
    }
  }, [modalName]);

  useEffect(() => {
    systemModelData.length = 0;
    chartsystemModelData = 0;
    charttractorModelData = 0;
    heartBeatData.length = 0;
    if (indexvalue) {
      if (indexvalue === '1') {
        setIndexData(USAGEDATA);
      } else if (indexvalue === '2') {
        setIndexData(TEMPDATA);
      } else if (indexvalue === '3') {
        setIndexData(RAMDATA);
      } else if (indexvalue === '4') {
        setIndexData(POWERSTATUSDATA);
      } else if (indexvalue === '5') {
        setIndexData(DISKUSAGEDATA);
      } else if (indexvalue === '0') {
        setIndexData(TRACTOR_MODEL);
      }
    }
  }, [indexvalue]);

  useEffect(() => {
    if (indexData) {
      ModelData.length = 0;
      modalFun();
    }
  }, [indexData]);

  useEffect(() => {
    const data = getLabelString();
    setLabel(data);
  }, [indexvalue]);

  const SelectIndexData = (e: any) => {
    if (indexvalue !== e) {
      data.datasets.map((ele: any) => {
        ele.data = [];
      });
      data2.datasets[0].data = [];
      setIndexvalue(e);
    }
  };

  const getLabelString = () => {
    let str = 'CPU Usage (units)';
    if (indexOptions)
      str =
        indexvalue === '2'
          ? indexOptions[indexvalue] + '   ' + '(celsius)'
          : indexOptions[indexvalue] + '   ' + '(units)';

    return str;
  };

  const options: any = {
    scales: {
      legend: {
        display: true,
        labels: {
          font: {
            size: 20,
          },
        },
      },
      xAxes: [
        {
          type: 'realtime',
          realtime: {
            onRefresh: (Chart: any) => {
              if (modalName === 'SystemModal') {
                if (
                  typeof indexData !== 'undefined' &&
                  chartsystemModelData &&
                  chartsystemModelData.length > 0 &&
                  ModelData.length > 0
                ) {
                  Chart.data.datasets.forEach((dataset: any, index: any) => {
                    dataset.data.push({
                      x: Date.now(),
                      y: chartsystemModelData[ModelData[index]?.id],
                    });
                  });
                }
              } else if (modalName === 'TractorModal') {
                typeof indexData !== 'undefined' &&
                  charttractorModelData &&
                  charttractorModelData.length > 0 &&
                  ModelData.length > 0 &&
                  data2.datasets[0].data.push({
                    x: Date.now(),
                    y: charttractorModelData[ModelData[0]?.id],
                  });
              }
            },
            delay: 5000,
            refresh: 5000,
            duration: 100000,
          },
          scaleLabel: {
            display: true,
            fontFamily: 'Arial',
            fontSize: 20,
            fontColor: '#6c757d',
            labelString: 'Time',
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            fontFamily: 'Arial',
            fontSize: 20,
            fontColor: '#6c757d',
            labelString: label,
          },
          ticks: {
            max:
              modalName === 'TractorModal' ? 10 : indexvalue === '4' ? 2 : 100,
            min: 0,
          },
        },
      ],
    },
  };

  return (
    <Modal
      className="hearBeatPopup"
      title={tractorName}
      centered
      visible={showModal}
      onCancel={handleCancelBtn}
      footer={null}
    >
      <Tabs defaultActiveKey="1" style={{ position: 'relative' }}>
        <TabPane tab="Visualization" key="1" className="visulizationtab">
          <>
            {/* <div
              className="clearBtn"
              style={{ position: 'absolute', top: 0, right: 10, width: '50%' }}
            >
              <Select
                style={{ width: '250px', marginRight: '10px' }}
                placeholder="Visualization"
                defaultValue={modalName}
                onSelect={onSelectModal}
              >
                <option value="TractorModal" key="TractorModal">
                  Tractor Modal
                </option>
                <option value="SystemModal" key="SystemModal">
                  System Modal
                </option>
              </Select>

              <Select
                style={{ width: '250px', marginRight: '10px' }}
                placeholder="Select Index"
                value={indexvalue}
                onSelect={SelectIndexData}
              >
                {indexOptions &&
                  Object.entries(indexOptions).length &&
                  Object.entries(indexOptions).map((item: any) => (
                    <option value={item[0]} key={item[0]}>
                      {item[1]}
                    </option>
                  ))}
              </Select>
            </div> */}
          </>

          <div className="appline">
            {/* <Line
              data={modalName === 'SystemModal' ? data : data2}
              options={options}
            /> */}
            <HeartBeat1 data={heartBeatRef.current} />
          </div>
        </TabPane>
        <TabPane tab="Heart Beat" key="2">
          {heartBeatData.length > 0 && (
            <div className="clearBtn">
              <Button
                onClick={() => {
                  heartBeatData.length = 0;
                }}
              >
                Clear All
              </Button>
            </div>
          )}

          <div className="jsonCont">
            {heartBeatData &&
              heartBeatData.length > 0 &&
              heartBeatData.map((ele: any) => {
                return (
                  <div className="objRow" key={ele?.tractor_id}>
                    <img
                      src={copyIcon}
                      alt="Edit"
                      height="32"
                      width="32"
                      className="heartbeatcopy hoverShow1 on-hover-hand-change"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          JSON.stringify(ele?.data, null, 2),
                        );
                        notification.info({ message: 'Copied' });
                      }}
                    />
                    <span className="objRowDate">{ele?.DateTime}</span>
                    <pre>{JSON.stringify(ele?.data, null, 2)}</pre>
                  </div>
                );
              })}
          </div>
        </TabPane>
        <TabPane tab="System Model" key="3">
          {systemModelData.length > 0 && (
            <div className="clearBtn">
              <Button
                onClick={() => {
                  systemModelData.length = 0;
                }}
              >
                Clear All
              </Button>
            </div>
          )}

          <div className="jsonCont">
            {systemModelData &&
              systemModelData.length > 0 &&
              systemModelData.map((ele: any, i: any) => {
                return (
                  <div className="objRow" key={i}>
                    <img
                      src={copyIcon}
                      alt="Edit"
                      height="32"
                      width="32"
                      className="heartbeatcopy hoverShow1 on-hover-hand-change"
                      onClick={() => {
                        navigator.clipboard.writeText(ele?.data);
                        notification.info({ message: 'Copied' });
                      }}
                    />
                    <span className="objRowDate">{ele?.DateTime}</span>
                    {ele?.data}
                  </div>
                );
              })}
          </div>
        </TabPane>
      </Tabs>
    </Modal>
  );
};
