/* eslint-disable no-console */
import React from 'react';
import Layout from 'antd/lib/layout/layout';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import LiveMap from './LiveMap';
import './style.css';
import RemoteDriveContext from '../remote_drive_new/RemoteDriveContext';

const MapWrapper: React.FC = () => {
  const handle = useFullScreenHandle();
  return (
    <FullScreen handle={handle}>
      <Layout>
        <Layout className="site-layout posRel">
          <RemoteDriveContext>
            <LiveMap />
          </RemoteDriveContext>
        </Layout>
      </Layout>
    </FullScreen>
  );
};

export default MapWrapper;
