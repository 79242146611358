import { Button, Modal, notification } from 'antd';
import InfiniteScrollTable from '../../common/InfiniteScrollTable';
import React, { useEffect, useState } from 'react';
import { UserDetails } from '../../../constants/types';
import { useLocation } from 'react-router-dom';
import { getTrimbleSyncWayPoints } from '../../../constants/Api';
import { getDateTimesByString } from '../../../constants/Common';

interface Props {
  userDetails: UserDetails;
  showModal: boolean;
  closeModal: () => void;
  selectedOrg: any;
}

export const SyncedWaypointsModal: React.FC<Props> = ({
  showModal,
  closeModal,
  selectedOrg,
  userDetails,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [waypoints, setWaypoints] = useState([]);

  useEffect(() => {
    const init = async () => {
      const stateData: any = state;
      const { app } = stateData;

      try {
        setLoading(true);
        const response = await getTrimbleSyncWayPoints(
          userDetails.organization.api_url,
          userDetails.organization.id,
          userDetails.organization.farm.id,
          app.id,
          selectedOrg.id,
        );
        setWaypoints(response);
      } catch (error) {
        notification.error({
          message: 'Could not get data',
        });
      } finally {
        setLoading(false);
      }
    };

    if (userDetails && selectedOrg && showModal) {
      init();
    }
  }, [userDetails, selectedOrg, showModal]);

  const columns = [
    {
      title: 'Waypoint Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Sync Time',
      dataIndex: 'modified_date_time',
      key: 'time',
      render: (data: string) => getDateTimesByString(data),
    },
  ];

  return (
    <Modal
      className="profileUpdatePopup syncModalCon"
      title=""
      visible={showModal}
      closable={false}
      footer={null}
    >
      <div className="appStoreTxt trimbleTxtCon mt0">Synced Waypoints</div>

      <div className="farmTabsTbl hoverable">
        <InfiniteScrollTable
          columns={columns}
          hasMore={false}
          loading={loading}
          filterData={waypoints}
          handleLoadMore={() => []}
          filename="Sync Waypoints"
        />
      </div>

      <Button className="trimbleOkBtn" onClick={() => closeModal()}>
        Ok
      </Button>
    </Modal>
  );
};
