/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useContext, useState } from 'react';
import { Button, Input, Modal, notification } from 'antd';
import { ApplicationContext } from '../../context/AppContext';
import logo from '../../assets/images/logo192.png';
import './style.css';
import { updateTeam } from '../../constants/Api';
import loading_logo from '../../assets/images/loading_logo.gif';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';
import AppLoader from '../common/AppLoader';

interface Props {
  showModal?: boolean;
  closeModel: (key: boolean) => void;
  teamName: string;
  description: string;
  teamId: number;
}

const { TextArea } = Input;
export const EditTeam: React.FC<Props> = ({
  showModal,
  closeModel,
  teamName,
  description,
  teamId,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails, initTeams } = useContext(ApplicationContext);
  const [team, setTeam] = useState<string>(teamName);
  const [teamDescription, setTeamDescription] = useState<string>(description);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      setSaveDisabled(
        team?.trim().length < 1 || teamDescription?.trim().length < 1,
      );
    }
  }, [team, teamDescription]);

  const editTeam = async () => {
    try {
      setLoader(true);
      const data = {
        name: team,
        description: teamDescription,
      };
      const response = await updateTeam(
        userDetails.organization.api_url,
        userDetails.organization.id,
        teamId,
        data,
      );
      notification.success({
        message: `${teamName} Updated Successfully`,
        duration: 2,
      });
      initTeams();
      setLoader(false);
      closeModel(true);
    } catch (error: any) {
      if (error.response.data.error.message) {
        notification.error({
          message: error.response.data.error.message,
          duration: 2,
        });
      } else {
        notification.error({ message: 'Unable to update Team', duration: 2 });
      }
      setLoader(false);
      closeModel(false);
    }
  };
  const handleChangeTeam = (event: any) => {
    const regex = /^[a-zA-Z0-9 @#$&*)(+_-]*$/;
    if (regex.test(event.target.value)) {
      setTeam(event.target.value);
    }
  };
  return (
    <>
      <Modal
        className="modalContainer teammodal"
        visible={showModal}
        onCancel={() => closeModel(false)}
        width={590}
        closable={false}
        maskClosable={false}
        footer={[
          <>
            <Button
              data-testid="editTeamCancelButton-EditTeam"
              key="back"
              className="btnteamCancel captalize Button"
              onClick={() => closeModel(false)}
            >
              Cancel
            </Button>
            <Button
              data-testid="editTeamSaveButton-EditTeam"
              key="submit"
              className="btnteamSave captalize Button"
              disabled={saveDisabled}
              onClick={editTeam}
            >
              Save
            </Button>
          </>,
        ]}
      >
        <AppLoader loader={loader} />
        <div className="createteamContainer">
          <div className="createtitle">
            <div className="pull-left">
              <h1 className="title headline1">Edit</h1>
            </div>
            <div className="img-div pull-right">
              <img src={logo} />
            </div>
          </div>
          <p className="teamdescription body1">
            Use teams to manage this employee’s access and permissions to the
            Monarch Platform.{' '}
          </p>
          <Input
            data-testid="editTeamNameInputField-EditTeam"
            placeholder="Enter Team Name"
            className="team-input input-text"
            value={team}
            onChange={handleChangeTeam}
          />
          <TextArea
            data-testid="editTeamDescriptionInputField-EditTeam"
            style={{ width: '100%' }}
            placeholder="Create a description to further identify the specifics of the team"
            showCount
            autoSize={{ minRows: 3, maxRows: 8 }}
            value={teamDescription}
            maxLength={110}
            className="team-text-area  input-text textareaheight mBtm48"
            onChange={(e: any) => setTeamDescription(e.target.value)}
          />
        </div>
      </Modal>
    </>
  );
};

export default EditTeam;
