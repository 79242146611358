import { DownloadOutlined } from '@ant-design/icons';
import { DatePicker, notification, Row, Select } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMixpanel } from 'react-mixpanel-browser';
import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import {
  getAllMapTractors,
  getAllTractorSnapShot,
  getTractorsListData,
} from '../../constants/Api';
import {
  checkSnapFeature,
  fromToDateWrapper,
  getDateTimes,
  getTractorAvailableState,
  initClearSelection,
  tractorSort,
} from '../../constants/Common';
import { snapshotObject, TractorsList } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import translate from '../../locale/en_translate.json';
import CSelect from '../common/CSelect';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import SnapDownload from './SnapDownload';
import './SnapshotStyle.css';
import SnapshotView from './SnapshotView';

// const { Search } = Input;

interface TractorResponseType {
  label: string;
  value: number | string | boolean;
  disabled: boolean;
  index: number | string;
  isAvailable: string;
}
interface Props {
  // onSearchText?: (text: string) => void;
  transactionId?: string;
}

const { RangePicker } = DatePicker;
const { Option } = Select;

const Snapshot: React.FC<Props> = ({ transactionId }) => {
  const { t } = useTranslation();
  const { userDetails } = useContext(ApplicationContext);
  const [dataSource, setDataSource] = useState<snapshotObject[]>([]);
  const [tractorlist, settractorlist] = useState<TractorsList[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPagesize] = useState<any>(25);
  // const [tractors, setTractors] = useState<TractorsList[]>([]);
  const [snapDownloadFlag, setSnapDownloadFlag] = useState<boolean>(false);
  const [tractorsData, setTractorsData] = useState<TractorResponseType[]>([]);
  const [filterTractor, setFilterTractor] = useState<any>();
  const [status, setStatus] = useState<string>('');
  const [createdAt, setCreatedAt] = useState<string>('');
  const [fromDateTime, setFromDateTime] = useState<any>(
    moment().subtract(1, 'day').toDate(),
  );
  const [toDateTime, setToDateTime] = useState<any>(moment().toDate());
  const [snapShotView, setSnapsShotView] = useState<any>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<any>();
  const [snapshotFlag, setSnapshotFlag] = useState<boolean>(false);
  const mixpanel = useMixpanel();
  const [sortedData, setSortedData] = useState<any>({
    created_date_time: 'descend',
  });
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'created_date_time',
    order: 'descend',
  });
  const [intialflag, setIntialflag] = useState<boolean>(false);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTractorsList();
      // getTractors();
    }
  }, [userDetails]);
  // const getTractors = async () => {
  //   try {
  //     const { records }: { records: TractorsList[] } = await getAllMapTractors(
  //       userDetails.organization.api_url,
  //       userDetails.organization_id,
  //     );
  //     setTractors(records);
  //   } catch (error: any) {
  //     notification.error({
  //       message: error.response?.data.error.message,
  //     });
  //   }
  // };
  const getTractorsList = async () => {
    try {
      const tractor: { records: TractorsList[] } = await getTractorsListData(
        userDetails.organization.api_url,
        userDetails.organization_id,
        pageNumber,
      );
      const data =
        tractor && tractor.records && tractor.records.length > 0
          ? tractor.records
          : [];
      const tractors: TractorResponseType[] = data.map((d: TractorsList) => {
        return {
          label: d.name,
          value: d.id,
          disabled: false,
          index: d.id,
          connection: d.connection,
          isOnline: d.connection === 'online' ? true : false,
          isAvailable: getTractorAvailableState(d.connection, d?.heartbeat),
        };
      });
      const arr = tractorSort(tractors, 'label');
      settractorlist([...tractorlist, ...data]);
      setTractorsData([...tractorsData, ...arr]);
    } catch (error: any) {
      notification.error({
        message: error.message,
      });
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.organization) getSnapShotData();
  }, [pageNumber, pageSize, filterTractor, fromDateTime]);

  useEffect(() => {
    if (
      userDetails &&
      userDetails.organization &&
      sortedInfo.order &&
      intialflag
    ) {
      setPageNumber(1);
      setDataSource([]);
      getSnapShotData(true);
    }
  }, [sortedInfo.order]);

  useEffect(() => {
    if (transactionId && dataSource.length) {
      const [record] = dataSource.filter(
        (item: any) => item.transaction_id == transactionId,
      );
      if (record) getRow(record);
    }
  }, [dataSource]);

  const getSnapShotData = async (sort = false) => {
    try {
      const page = sort ? 1 : pageNumber;
      const { organization } = userDetails;
      setLoader(true);
      const snapshot = await getAllTractorSnapShot(
        organization.api_url,
        organization.farm.id,
        filterTractor ? filterTractor : '',
        page,
        pageSize,
        status ? status : '',
        createdAt ? createdAt : '',
        fromDateTime
          ? String(moment(fromDateTime).startOf('day').toDate().getTime())
          : '',
        toDateTime
          ? String(moment(toDateTime).endOf('day').toDate().getTime())
          : '',
        // search,
        sortedInfo,
      );
      setTotalCount(snapshot?._metadata?.total_records_count);
      const data =
        snapshot && snapshot.records && snapshot.records.length > 0
          ? snapshot.records.map((record: any) => {
              return {
                id: record.id,
                tractorName: record.tractor_id.name,
                date_time: record.created_date_time,
                created_date_time: record.created_date_time
                  ? getDateTimes(record.created_date_time)
                  : '',
                name: record.created_by
                  ? record.created_by?.first_name +
                    ' ' +
                    record.created_by?.last_name
                  : '-',
                status: record.status,
                remarks: record.remarks,
                tractor_id: record.tractor_id,
                tractorId: record.tractor_id?.id,
                transaction_id: record.transaction_id,
                created_at: record.created_at,
                guard_rail_type: record.guard_rail_type,
                drive_action_uuid: record.drive_action_uuid,
                createdAt: record.created_at.replaceAll('_', ' '),
              };
            })
          : [];

      if (data.length === 0) {
        setHasMore(false);
      } else {
        if (data.length > 0 && !sort) {
          if (pageNumber === 1) {
            setDataSource([...data]);
          } else {
            setDataSource([...dataSource, ...data]);
          }
        } else {
          setDataSource(data);
        }
        if (data.length < 25) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      }
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      title: () => {
        return (
          <>
            {t(translate.headers.dateTime)}
            <span className="sort-pad">
              <img
                className="sort-pad"
                src={
                  sortedData['created_date_time'] === 'ascend'
                    ? sortUp
                    : sortDown
                }
              />
            </span>
          </>
        );
      },
      sortOrder:
        sortedInfo.columnKey === 'created_date_time' && sortedInfo.order,
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      ellipsis: true,
      dataIndex: 'created_date_time',
      key: 'created_date_time',
      width: '15%',
      sorter: () => false,
      showSorterTooltip: false,
    },
    {
      title: `${t(translate.snapshot.tractor)}`,
      dataIndex: 'tractorName',
      key: 'tractorName',
      // width: '25%',
    },
    {
      title: `${t(translate.fleetticketsHistory.operator)}`,
      dataIndex: 'name',
      key: 'name',
      // width: '15%',
    },
    // {
    //   title: `${t(translate.fleetticketsHistory.status)}`,
    //   dataIndex: 'status',
    //   key: 'status',
    // },
    {
      title: `${t(translate.snapshot.creationType)}`,
      dataIndex: 'createdAt',
      key: 'createdAt',
      // width: '20%',
    },
  ];

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    setIntialflag(true);
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };

  function disabledDate(current: any) {
    return current && current > moment();
  }

  const onDateRangeChange = (dates: any) => {
    setSnapshotFlag(false);
    setDataSource([]);
    dataSource.length = 0;
    setPageNumber(1);
    setTotalCount(0);

    if (dates) {
      const [from, to] = fromToDateWrapper(dates);
      setFromDateTime(from.toDate());
      setToDateTime(to.toDate());
    } else {
      setFromDateTime(null);
      setToDateTime(null);
    }
  };

  const handleSelect = async (e: any) => {
    setPageNumber(1);
    setDataSource([]);
    dataSource.length = 0;
    setFilterTractor(e);
  };

  const handleLoadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const Close = () => {
    setSnapshotFlag(false);
    initClearSelection();
    mixpanel.track('Snapshot', {
      event: 'Snapshot Closed',
    });
  };

  const getRow = (record: any) => {
    setSnapshotFlag(true);
    setSnapsShotView(record);
    mixpanel.track('Snapshot', {
      event: `${record.tractorName} Clicked`,
    });
  };

  // const handleSearch = (e: any) => {
  // onSearchText && onSearchText(e.target.value.trim());
  // const value = e.target.value.trim();
  // if (value.length <= 2) {
  // if (search != '') {
  // setDataSource([]);
  // }
  // setSearch('');
  // } else {
  // setSearch(value);
  // }
  // };

  return (
    <Layout>
      <div className="mainContent snapshotContent">
        <Content>
          <Row>
            {userDetails?.organization?.id &&
              checkSnapFeature(userDetails?.organization?.id) && (
                <div className="snapshot-download-btn">
                  <DownloadOutlined
                    onClick={() => setSnapDownloadFlag(!snapDownloadFlag)}
                    style={{ fontSize: '32px' }}
                  />
                </div>
              )}
            <div className="inline ml5 searchFilters">
              {/* <div className="searchSec searchUnit">
                  <Search
                  id="sanpshotview"
                  placeholder="Search Snapshots"
                  onChange={(e) => handleSearch(e)}
                  />
                </div> */}

              <div
                className="tractorField"
                data-testid="tractorselect-Librarysnapshot"
              >
                <CSelect
                  list={tractorsData.map((item) =>
                    Object.assign({
                      id: item.index,
                      name: item.label,
                      isAvailable: item.isAvailable,
                    }),
                  )}
                  selectTrigger={(selected) => {
                    handleSelect(selected ? selected?.id : 0);
                  }}
                  placeholder="All Tractors"
                  label="Tractor"
                  isAll={true}
                  // tractorStatus={true}
                />
              </div>
              <RangePicker
                format="YYYY-MM-DD"
                onChange={onDateRangeChange}
                className="dropdownStyle dW250_date dateFilter"
                data-testid="dateselect-Librarysnapshot"
                disabledDate={disabledDate}
                defaultValue={[moment(fromDateTime), moment(toDateTime)]}
              />
            </div>
            <div className="tblDft lbTbls farmTabsTbl reportsTbl hoverable h42vh mx-ht cPointer">
              <InfiniteScrollTable
                columns={columns}
                hasMore={hasMore}
                loading={loader}
                filterData={dataSource}
                totalcount={totalCount}
                handleLoadMore={handleLoadMore}
                filename="Snapshots"
                onRowClick={(record) => getRow(record)}
                onChange={handleChange}
              />
            </div>
          </Row>
        </Content>
      </div>
      {snapshotFlag && snapShotView && (
        <SnapshotView
          Close={Close}
          snapshotFlag={snapshotFlag}
          snapshotList={snapShotView}
        />
      )}
      {snapDownloadFlag && (
        <SnapDownload
          callback={(state) => {
            // console.log(state)
            setSnapDownloadFlag(state);
          }}
          tractors={tractorsData}
        />
      )}
    </Layout>
  );
};

export default Snapshot;
