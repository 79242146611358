/* eslint-disable react/jsx-key */
import { Layout, Row, Input, notification } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import AddButtonIcon from '../../assets/images/add_button_icon.svg';
import applicationIds from '../../locale/applicationIds.json';
import { useMixpanel } from 'react-mixpanel-browser';
import AddImplement from './AddImplement';
import {
  getCategoryList,
  getimplementbyId,
  getImplementsList,
} from '../../constants/Api';
import { ApplicationContext } from '../../context/AppContext';
import { Implementview } from '../../constants/types';
import InfiniteScrollTable from '../common/InfiniteScrollTable';
import sortDown from '../../assets/images/sort_down.svg';
import sortUp from '../../assets/images/sort_up.svg';
import ImplementDetails from './ImplementDetails';
import { initClearSelection, initScroller } from '../../constants/Common';
import { privilagesConstants } from '../../constants/Privilages';
import CSelect from '../common/CSelect';
import { statusFilter } from '../../constants/AppData';
import { delay } from '../../constants/Common';

const { Search } = Input;
interface Props {
  onSearchText?: (text: string) => void;
}
const FarmImplements: React.FC<Props> = ({ onSearchText }: Props) => {
  const { t } = useTranslation();
  const { userDetails, privilegeChecker } = useContext(ApplicationContext);
  const [addImplementFlag, setAddImplementFlag] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize] = useState<any>(25);
  const [implementSearch, setImplementSearch] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(true);
  const [implementView, setImplementView] = useState<Implementview[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<any>();
  const [implementDetails, setImplementDetails] = useState<boolean>(false);
  const [detailsList, setDetailsList] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [active, setActive] = useState<boolean>(true);
  const [implementType, setImpleteType] = useState<any>([]);
  const [implemeant_Type, setImplemeant] = useState<any>('All');
  const [init, setInit] = useState<boolean>(false);

  const [sortedData, setSortedData] = useState<any>({
    name: 'ascend',
  });
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: 'name',
    order: 'ascend',
  });
  const [keyCode, setKeyCode] = useState<boolean>(false);
  const activity = useRef<string>('');

  const checkActivity = () =>
    ['search', 'sort', 'filter'].includes(activity.current);

  const mixpanel = useMixpanel();
  const searchString = useRef<string>('');

  const wrapResult = async (
    apiUrl: string,
    orgId: number,
    pageNumber: number,
    pageSize: number,
    search: string,
    active: boolean,
    implement_type: any,
    sortedInfo: string,
  ) => {
    const report = await getImplementsList(
      apiUrl,
      orgId,
      pageNumber,
      pageSize,
      search,
      active,
      implement_type,
      sortedInfo,
    );
    return {
      report,
      searchKey: search,
    };
  };

  useEffect(() => {
    return () => {
      setImplementView([]);
    };
  }, []);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getProfileLookps();
      setInit(true);
      loadImplements();
    }
  }, [userDetails]);

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    setImplementView([]);
    setLoader(true);
    activity.current = 'sort';
    const { columnKey, order } = sorter;
    setSortedData({ ...sortedData, [columnKey]: order });
    setSortedInfo(sorter);
  };
  useEffect(() => {
    if (init) {
      activity.current = 'filter';
      loadImplements();
    }
  }, [active, implemeant_Type]);

  useEffect(() => {
    if (init) loadImplements();
  }, [pageNumber, sortedInfo, implementSearch]);

  useEffect(() => {
    if (init) {
      activity.current = '';
      loadImplements();
    }
    return () => {
      setRefresh(false);
    };
  }, [refresh]);

  const loadImplements = () => {
    if (userDetails && !userDetails.organization) return;
    if (checkActivity()) {
      pageNumber !== 1 ? setPageNumber(1) : getImplementsListData();
    } else if (activity.current === 'paginate' || activity.current === '') {
      getImplementsListData();
    }
  };

  const getProfileLookps = async () => {
    try {
      const cropsdata = await getCategoryList(
        userDetails.organization.api_url,
        'implement_type',
      );
      setImpleteType(cropsdata);
    } catch (error: any) {
      notification.error({
        message: error?.response?.data.error.message || error?.message,
        duration: 2,
      });
    }
  };
  const getImplementsListData = async () => {
    if (checkActivity()) initScroller();
    if (keyCode) {
      await delay(1000);
    }
    try {
      setLoader(true);
      const { report, searchKey } = await wrapResult(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        pageNumber,
        pageSize,
        implementSearch,
        active,
        implemeant_Type === 'All' ? '' : implemeant_Type,
        sortedInfo,
      );
      if (searchString.current !== searchKey) return;
      const data =
        report && report.records && report.records.length > 0
          ? report.records.map((record: any) => {
              return {
                name: record.name,
                manufacturer: record.config_imm
                  ? record.config_imm.manufacturer
                  : '',
                model: record.config_imm ? record.config_imm.model : '',
                serial_number: record.serial_number,
                id: record.id,
                config_imm_uuid: record.config_imm_uuid,
                config_implements_uuid: record.config_implements_uuid,
                implement_type: record?.implement_type,
              };
            })
          : [];
      setTotalCount(report?._metadata?.total_records_count);
      setHasMore(data.length === pageSize);
      if (checkActivity()) setImplementView(data);
      else if (activity.current === 'paginate')
        setImplementView([...implementView, ...data]);
      else setImplementView(data);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message || error.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      render: (name: string) => {
        return <span data-testid={`${name}-FarmImplements`}>{name}</span>;
      },
      title: (filters: any) => {
        return (
          <>
            {t(translate.implements.name)}
            <span className="sort-pad">
              {sortedInfo.columnKey === 'name' && (
                <img
                  className="sort-pad"
                  src={sortedData['name'] === 'ascend' ? sortUp : sortDown}
                />
              )}
            </span>
          </>
        );
      },
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => {
        return a.name?.localeCompare(b.name);
      },
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: `${t(translate.implements.manufacturer)}`,
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      render: (manufacturer: string) => {
        return (
          <span data-testid={`${manufacturer}-FarmImplements`}>
            {manufacturer}
          </span>
        );
      },
    },
    {
      title: `${t(translate.implements.model)}`,
      dataIndex: 'model',
      key: 'model',
      render: (model: string) => {
        return (
          <span className="description" data-testid={`${model}-FarmImplements`}>
            {model}
          </span>
        );
      },
    },

    {
      title: `${t(translate.implements.implemeantType)}`,
      dataIndex: 'implement_type',
      key: 'implement_type',
    },

    {
      title: `${t(translate.baseStation.serialNumber)}`,
      dataIndex: 'serial_number',
      key: 'serial_number',
      render: (serial_number: string) => {
        return (
          <span data-testid={`${serial_number}-FarmImplements`}>
            {serial_number}
          </span>
        );
      },
    },
  ];

  const handleSearch = (e: any) => {
    activity.current = 'search';
    const value = e.target.value.trim().regX;
    if (value.length <= 1) {
      if (implementSearch != '') {
        setImplementView([]);
      }
      searchString.current = '';
      setImplementSearch('');
    } else {
      searchString.current = value;
      setImplementSearch(value);
    }
  };

  const addImplement = () => {
    setAddImplementFlag(true);
    mixpanel.track('Add Implements', {
      event: 'Add Implements Clicked',
    });
  };

  const Close = () => {
    setAddImplementFlag(false);
    setImplementDetails(false);
    initClearSelection();
    mixpanel.track('Implements', {
      event: 'Implements Closed',
    });
  };

  const handleLoadMore = () => {
    if (pageNumber === 1 && checkActivity() && document) initScroller();

    activity.current = 'paginate';
    setPageNumber(pageNumber + 1);
  };

  const getRow = (record: any) => {
    editimplementmethod(record?.config_implements_uuid);
    mixpanel.track('Implements', {
      event: `${record.name} Clicked`,
    });
  };

  const editimplementmethod = async (id: string) => {
    try {
      const implemeantsData = await getimplementbyId(
        userDetails.organization.api_url,
        userDetails.organization.fleet.id,
        id,
      );
      setDetailsList(implemeantsData);
      setImplementDetails(true);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  const handleKey = (e: any) => {
    setKeyCode(false);
    if (e.keyCode === 8) {
      setKeyCode(true);
    }
  };

  return (
    <Layout>
      <div className="mainContent">
        <Content>
          <div className="tblContainer viewportContainer">
            <Row>
              <div className="tblTopOutSec searchFilters filterGap40">
                <div className="flex">
                  <div className="searchSec">
                    <Search
                      placeholder="Search Directory"
                      onChange={(e) => handleSearch(e)}
                      style={{ width: 200 }}
                      data-testid="implementsSearchInputField-FarmImplements"
                      id="search-field"
                      onKeyDown={(e) => handleKey(e)}
                      autoComplete="off"
                    />
                  </div>
                  <div
                    className="smallFilter"
                    data-testid="status-FarmImplements"
                  >
                    <CSelect
                      list={statusFilter}
                      isAll={false}
                      selectTrigger={(selected) => {
                        const { id } = selected;
                        setActive(id);
                      }}
                      label="Status"
                      defaultId="true"
                    />
                  </div>
                  <div
                    className="smallFilter"
                    data-testid="ImplemeType-FarmImplements"
                  >
                    {implementType?.length > 0 && (
                      <CSelect
                        list={implementType}
                        selectTrigger={(selected) => {
                          if (selected) {
                            const { name } = selected;
                            setImplemeant(name);
                          } else {
                            setImplemeant('All');
                          }
                        }}
                        keyValue="name"
                        label="Type"
                        placeholder="All Types"
                        defaultId={implemeant_Type}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="tblDft farmTabsTbl hoverable cPointer">
                <InfiniteScrollTable
                  columns={columns}
                  hasMore={hasMore}
                  loading={loader}
                  filterData={implementView}
                  totalcount={totalCount}
                  handleLoadMore={handleLoadMore}
                  filename="Implements"
                  onRowClick={(record) => getRow(record)}
                  onChange={handleChange}
                />
                {privilegeChecker(privilagesConstants.Add_Implements) && (
                  <img
                    id={applicationIds.implementsScreen.addImplements}
                    className={'addImpButton'}
                    data-testid="addImplementButton-FarmImplements"
                    src={AddButtonIcon}
                    onClick={addImplement}
                  />
                )}
              </div>
            </Row>
          </div>
        </Content>

        {addImplementFlag && (
          <AddImplement
            Close={Close}
            toggleWidget={addImplementFlag}
            setRefresh={() => setRefresh(true)}
          />
        )}

        {implementDetails && (
          <ImplementDetails
            Close={Close}
            toggleWidget={implementDetails}
            details={detailsList}
            setRefresh={() => setRefresh(true)}
          />
        )}
      </div>
    </Layout>
  );
};

export default FarmImplements;
