import { Modal } from 'antd';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

interface Props {
  url: string;
  showModel: boolean;
  closeModel: () => void;
}

const ReportRenderer: React.FC<Props> = ({ url, showModel, closeModel }) => {
  const [numPages, setNumPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Modal
      title="Daily Fleet Usage Report"
      visible={showModel}
      onCancel={closeModel}
      width={1000}
      centered
      footer={null}
      closable={true}
    >
      <div className="modalBox flexnone">
        <div className="formColW">
          <div className="userIcon">
            <Document
              file={url}
              onLoadSuccess={onDocumentLoadSuccess}
              className="pdf-document"
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  className="pdf-page"
                  pageNumber={index + 1}
                  scale={1.5}
                />
              ))}
            </Document>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReportRenderer;
