import React, { useContext } from 'react';
import { AnalyticsContext } from '../AnalyticsContext';
import AnalyticsDashboard from './AnalyticsDashboard';
import AnalyticsDetails from './AnalyticsDetails';

interface Props {
  setShowLiveMap: (state: boolean) => void;
  showLiveMap: boolean;
}

const AnalyticsWrapper: React.FC<Props> = ({ setShowLiveMap, showLiveMap }) => {
  const { analyticReducer } = useContext(AnalyticsContext);
  const [state] = analyticReducer;
  const { JAG } = state;

  return (
    <div>
      {showLiveMap && (
        <div style={{ display: !JAG ? 'block' : 'none' }}>
          <AnalyticsDashboard
            toggleLiveMap={() => setShowLiveMap(!showLiveMap)}
          />
        </div>
      )}
      {JAG && <AnalyticsDetails />}
    </div>
  );
};

export default AnalyticsWrapper;
