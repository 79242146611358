import React from 'react';
import { Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
interface Props {
  menu: any;
  name: string;
  addItem: (name: string) => void;
}

const DropdownRender: React.FC<Props> = ({ menu, addItem, name }: Props) => {
  return (
    <div>
      {menu}
      {menu?.props?.flattenOptions?.length === 0 && (
        <>
          <Divider style={{ margin: '4px 0' }} />
          <div className="mfAdd" onClick={() => addItem(name)}>
            <strong>
              <PlusOutlined />
            </strong>
            <span style={{ marginTop: '-4px', padding: '0px 4px' }}>
              &lsquo;
              {name}
              &rsquo;
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default DropdownRender;
