import React, { useContext, useState } from 'react';
import { Layout } from 'antd';
import Routes from '../../routes/Routes';
import { ApplicationContext } from '../../context/AppContext';
import { UserDetails } from '../../constants/types';
import AppSideMenu from './AppSideMenu';
import { MenuContext } from '../../context/MenuContext';
import 'antd/dist/antd.css';
import './style.css';
import { useHistory } from 'react-router-dom';
import SessionTimer from './SessionTimer';
const { Content, Sider } = Layout;

const AppLayout: React.FC = () => {
  const { userDetails } = useContext(ApplicationContext);
  const [collapsed, setCollapsed] = useState(false);
  const [resetSidebar, setResetSidebar] = useState(false);
  const { push } = useHistory();

  return (
    <MenuContext.Provider value={{ collapsed }}>
      <Layout style={{ minHeight: '100vh' }}>
        {!window.location.pathname.includes('remote_access_grant') && (
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            onCollapse={setCollapsed}
            style={{
              background: '#2F333B',
              color: '#fff',
            }}
            className="sideMenuSec"
            width="100"
          >
            <AppSideMenu
              userProfile={userDetails as UserDetails}
              collapsed={collapsed}
              onLinkClick={(pathname) => {
                push({
                  pathname,
                  state: {
                    reset: !resetSidebar,
                  },
                });
                setResetSidebar(!resetSidebar);
              }}
            />
          </Sider>
        )}
        <Layout className="site-layout">
          <Content>
            <Routes />
          </Content>
        </Layout>
      </Layout>
      <SessionTimer />
    </MenuContext.Provider>
  );
};

export default AppLayout;
