import React, { createContext, useState } from 'react';

interface Theme {
  styles: ThemeStyles;
  setStyles: (data: ThemeStyles) => void;
}

interface ThemeStyles {
  menuColor: string;
  backgrounfColor: string;
}

export const ApplicationThemeContext = createContext({} as Theme);

// eslint-disable-next-line react/prop-types
const ThemeContext: React.FC = ({ children }) => {
  const [styles, setStyles] = useState<ThemeStyles>({
    menuColor: '#1E3A46',
    backgrounfColor: 'black',
  });

  return (
    <ApplicationThemeContext.Provider value={{ styles, setStyles }}>
      {children}
    </ApplicationThemeContext.Provider>
  );
};

export default ThemeContext;
