/* eslint-disable react/no-unescaped-entities */
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Input, Layout, Space, Col, Spin, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import 'antd/dist/antd.css';
import { useHistory } from 'react-router';
import RoutesConstants from '../../routes/RoutesConstant';
import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import applicationIds from '../../locale/applicationIds.json';
import 'antd/dist/antd.css';
import './style.css';
import { ApplicationContext } from '../../context/AppContext';
import jwt_decode from 'jwt-decode';
import { UserDetails } from '../../constants/types';
import { getFCMToken } from '../../firebaseInit';
import loading_logo from '../../assets/images/loading_logo.gif';
import { useMixpanel } from 'react-mixpanel-browser';
import { login, loginWithToken, subscribe } from '../../constants/Api';
import logoHorizontalWhite from '../../assets/images/logo_horizontal_white_rgb.png';
// import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { REMOTE_ACCESS_GRANT } from '../../constants/constant';
import { version } from '../../../package.json';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';

interface Props {
  handleSubmit?: (record: any) => void;
  authData?: (record: any) => void;
  username?: string;
}
const Login: React.FC<Props> = ({
  handleSubmit,
  authData,
  username,
}: Props) => {
  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);
  const [loginDisabled, setLoginDisabled] = useState(true);
  const { updateUserDetails } = useContext(ApplicationContext);
  const [spinning, setSpinning] = useState(false);
  const mixpanel = useMixpanel();
  const history = useHistory();
  const translation = useTranslation();
  const [resetPassword, setResetPassword] = useState<boolean>(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const action = String(queryParams.get('supportid'));

  useEffect(() => {
    if (action && action !== 'null') {
      setUserName(action);
    }
  }, [action]);
  const pushLink = (link: string) => {
    if (history) {
      history.push(link);
    }
  };
  const translateData = (content: string) => {
    return translation.t(content);
  };

  const resetData = () => {
    setLoginDisabled(true);
    setIsValid(true);
    setErrors('');
  };
  useEffect(() => {
    setLoginDisabled(userName.trim().length < 1 || password.trim().length < 1);
    setIsValid(true);
    setErrors('');
    return () => {
      resetData();
    };
  }, [userName, password]);

  const handleRemoteAccess = () => {
    const keyCheck = localStorage.getItem(REMOTE_ACCESS_GRANT);
    if (keyCheck)
      window.open(
        `${window.location.origin}/${REMOTE_ACCESS_GRANT}?remote_access_token=${keyCheck}`,
        '_blank',
      );
  };

  const handleLogin = async (e: any) => {
    if (handleSubmit) {
      handleSubmit({ userName, password });
      e.preventDefault();
    }
    try {
      setSpinning(true);
      if (
        process.env.REACT_APP_ALPHA_FEATURES?.toLocaleLowerCase().includes(
          'cognito',
        )
      ) {
        const payload: any = {
          username: userName,
          password:
            userName === 'wae@monarchtractor.com'
              ? password.toLocaleLowerCase()
              : password,
        };
        const response = await Auth.signIn(payload);
        const access_token = response.signInUserSession.idToken.jwtToken;
        localStorage.setItem('access_token', access_token);
        try {
          const { Authorization } = await loginWithToken({
            org_type: 'customer',
            username: userName,
          });
          handleAuthToken(Authorization);
        } catch (error: any) {
          notification.error({
            message: error?.message,
            duration: 2,
          });
        }
      } else {
        const payload: any = {
          username: userName,
          password:
            userName === 'wae@monarchtractor.com'
              ? password.toLocaleLowerCase()
              : password,
          org_type: 'customer',
        };
        const { Authorization } = await login(payload);

        handleAuthToken(Authorization);
      }
    } catch (err: any) {
      mixpanel.track('Login', {
        event: 'LoginFailed',
      });
      setErrors(
        err.toString().includes('Password attempts exceeded')
          ? 'Password attempts exceeded'
          : 'Incorrect Username or Password',
      );
      setIsValid(false);
    } finally {
      setSpinning(false);
    }
  };

  const handleAuthToken = (Authorization: string) => {
    localStorage.setItem('auth', Authorization);
    updateUserDetails();
    const userDetails: UserDetails = jwt_decode(Authorization || '');
    registerToken(userDetails);
    mixpanel.identify(userDetails.username);
    mixpanel.track('Login', {
      event: 'Login Success',
    });
    if (authData) {
      authData(Authorization);
    }
    if (userDetails) {
      handleRemoteAccess();
      pushLink(RoutesConstants.Attention);
    } else {
      setErrors('user details not found');
    }
  };

  const registerToken = async (userDetails: UserDetails) => {
    try {
      const token = await getFCMToken();
      subscribe(userDetails.organization.api_url, {
        platform: 'web',
        api_service: 'fcm',
        token,
      });
    } catch (err: any) {
      setErrors(err.response?.data.error.message);
    }
  };

  const handleResetScreen = () => {
    setResetPassword(true);
    pushLink(RoutesConstants.EmailConfirm);
  };

  return (
    <Spin
      className="loader"
      spinning={spinning}
      indicator={
        <img style={{ width: '40px', height: '40px' }} src={loading_logo} />
      }
    >
      {/* <div>
        <img
          style={{
            width: '60px',
            height: '36px',
            // display: spinning ? 'block' : 'none',
          }}
          src={loading_logo}
        />
      </div> */}
      <div className="loginContainer">
        <div className="loginWrapper">
          <div className="monarchImg">
            <img alt="monarch" src={logoHorizontalWhite} />
            <p className="loginSubtitle">
              Making Sustainable Farming <br /> Economically Superior
            </p>
          </div>
          <div className="loginContWrap">
            {/* <Row wrap={false}> */}
            {/* <div className="loginLeft">
                <Col flex="436px">
                  <p>Leading the way in farm autonomy</p>
                </Col>
              </div> */}

            <div className="loginRight">
              <Col>
                <Layout className="layout">
                  <Content className="container">
                    <Space direction="vertical">
                      <p className="errMsgDiv" data-testid={`${errors}-Login`}>
                        {errors}
                      </p>
                      <div className="loginFRow">
                        {/* <label className="labelTxt userId">Email</label> */}
                        <Input
                          id={applicationIds.loginScreen.userId}
                          placeholder="Email"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                          defaultValue={username}
                          className={classNames({
                            validationClass: !isValid,
                          })}
                          data-testid="emailInputField-Login"
                        />
                      </div>
                      <div className="loginFRow mb50">
                        {/* <label className="labelTxt">Password</label> */}
                        <div
                          onClick={handleResetScreen}
                          className="forgotTxt"
                          data-testid="forgotpasswrdlink-Login"
                        >
                          Forgot Password
                        </div>
                        <Input.Password
                          id={applicationIds.loginScreen.password}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onPressEnter={(e) => handleLogin(e)}
                          placeholder="Password"
                          className={classNames({
                            validationClass: !isValid,
                          })}
                          data-testid="passwordInputField-Login"
                          iconRender={(visible) =>
                            visible ? (
                              <EyeTwoTone style={{ color: '#fff' }} />
                            ) : (
                              <EyeInvisibleOutlined style={{ color: '#fff' }} />
                            )
                          }
                        />
                      </div>

                      <div className="rememberTxt">
                        <Checkbox /> Remember Me
                      </div>
                      <Button
                        id={applicationIds.loginScreen.login}
                        type="primary"
                        disabled={loginDisabled}
                        onClick={(e) => handleLogin(e)}
                        className="btnTxt"
                        data-testid="loginButton-Login"
                      >
                        {translateData(translate.buttons.login)}
                      </Button>
                    </Space>
                  </Content>
                </Layout>
              </Col>
            </div>
            {/* </Row> */}
          </div>
        </div>
        <div className="versionCon">
          <h1>Version</h1>
          <h3>{version}</h3>
        </div>
      </div>
      {resetPassword && <ForgotPassword />}
    </Spin>
  );
};

export default Login;
