/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useContext, useState } from 'react';
import {
  Avatar,
  Button,
  notification,
  Checkbox,
  Input,
  Modal,
  Select,
} from 'antd';
import { ApplicationContext } from '../../context/AppContext';
import logo from '../../assets/images/logo192.png';
import './style.css';
import { Taskview, Taskviewdetails, TeamUser } from '../../constants/types';
import { getTasksearch } from '../../constants/Api';
import {
  getTeamById,
  getTeamUsers,
  duplicateAllTeam,
} from '../../constants/Api';
import translate from '../../locale/en_translate.json';
import { useTranslation } from 'react-i18next';

interface Props {
  showModal?: boolean;
  closeModel?: any;
  teamId?: any;
  setTeamId?: any;
}

const { TextArea } = Input;
export const DuplicateTeam: React.FC<Props> = ({
  showModal,
  closeModel,
  teamId,
  setTeamId,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails, initTeams } = useContext(ApplicationContext);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [results, setResult] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [employees, setEmployess] = useState<any>([]);
  const [team, setTeam] = useState<any>({});
  const [refresh, setRefresh] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    if (userDetails && userDetails.organization) {
      getTeam();
      getTeamEmployees();
    }
  }, [userDetails]);
  useEffect(() => {
    if (userDetails && userDetails.organization) {
      setSaveDisabled(title.trim().length < 1 || description.trim().length < 1);
    }
  }, [title, description]);

  const payload: any = {
    name: title,
    description: description,
    users: checked ? [] : selectedItems,
    duplicate: true,
    source_team_id: team?.id,
    copy_all_users_to_new_team: checked,
  };

  const getTeam = async () => {
    try {
      const response = await getTeamById(
        userDetails.organization.api_url,
        userDetails.organization.id,
        teamId,
      );
      setTeam(response);
      setLoader(false);
    } catch (error: any) {
      notification.error({ message: 'Unable to get team data' });
    }
  };
  const getTeamEmployees = async () => {
    const teamUsers: TeamUser[] = await getTeamUsers(
      userDetails.organization.api_url,
      userDetails.organization.id,
      teamId,
      true,
      searchValue ? searchValue : '',
    );
    const _employees: any[] = teamUsers.map((ele: TeamUser) => {
      return ele?.first_name + ' ' + ele?.last_name;
    });
    setResult(teamUsers);
  };

  const duplicateTeam = async () => {
    try {
      const data = await duplicateAllTeam(
        userDetails.organization.api_url,
        userDetails.organization.id,
        payload,
      );
      if (data?.id) {
        notification.success({
          message: t(translate.label.duplicateteamCreated),
          duration: 2,
        });
        setTeamId(data.id);
        initTeams();
        closeModel();
      } else {
        notification.error({ message: 'Duplication failed', duration: 2 });
      }
    } catch (error: any) {
      if (error.response.data.error.message) {
        notification.error({
          message: error.response.data.error.message,
          duration: 2,
        });
      } else {
        notification.error({ message: 'Could not create a Duplicate request' });
      }
    }
  };

  const handleChange = async (e: any) => {
    setSelectedItems(e);
  };

  const check = () => {
    setChecked(!checked);
  };

  const onTitleChange = (event: any) => {
    const regex = /^[a-zA-Z0-9 @#$&*)(+_-]*$/;
    if (regex.test(event.target.value)) {
      setTitle(event.target.value);
    }
  };

  const onDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  };
  return (
    <>
      <Modal
        className="modalContainer teammodal"
        visible={showModal}
        onCancel={closeModel}
        width={590}
        closable={false}
        maskClosable={false}
        footer={[
          <>
            <Button
              data-testid="duplicateEmpCancelButton-DuplicateTeam"
              key="back"
              className="btnteamCancel captalize Button"
              onClick={closeModel}
            >
              Cancel
            </Button>
            <Button
              data-testid="duplicateEmpSaveButton-DuplicateTeam"
              disabled={saveDisabled}
              key="submit"
              onClick={duplicateTeam}
              className="duplicateSaveButton btnteamSave Button"
            >
              Save
            </Button>
          </>,
        ]}
      >
        <div className="createteamContainer">
          <div className="createtitle">
            <div className="pull-left">
              <h1 className="title headline1">Duplicate {team.name}?</h1>
            </div>
            <div className="img-div pull-right">
              <img src={logo} />
            </div>
          </div>
          <p className="teamdescription body1">
            Use teams to manage employee's access and permissions to the Monarch
            Platform.
          </p>
          <Input
            data-testid="duplicateTeamNameInputField-DuplicateTeam"
            placeholder="Enter Team Name"
            value={title}
            className="team-input input-text"
            onChange={onTitleChange}
          />
          <TextArea
            data-testid="duplicateTeamDescInputField-DuplicateTeam"
            style={{ width: '100%' }}
            placeholder="Create a description to further identify the specifics of the team"
            showCount
            maxLength={110}
            className="team-text-area-2 input-text body1"
            value={description}
            onChange={onDescriptionChange}
          />

          <p>{employees}</p>
          <Select
            data-testid="addEmployeeDropdown-DuplicateTeam"
            disabled={checked}
            mode="multiple"
            placeholder="Add Employees"
            style={{ width: '100%' }}
            onChange={handleChange}
            className="dropdownStyle team-select"
            showSearch={true}
            filterOption={(input, option) => {
              return (
                option?.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            maxTagCount="responsive"
          >
            {!checked &&
              results.map((ele: any) => {
                return (
                  <Select.Option
                    title={`${ele.first_name} ${ele.last_name}`}
                    value={ele?.id}
                    key={ele?.id}
                  >
                    <span
                      data-testid={`${ele.first_name} ${ele.last_name}-DuplicateTeam`}
                      className="emailAlign empName"
                    >
                      <Avatar
                        style={{
                          width: '34px',
                          height: '34px',
                          marginRight: '25px',
                          backgroundColor: '#67AFB7',
                          color: 'white',
                          fontFamily: 'Montserrat-Bold',
                        }}
                        src={ele?.profile_url}
                      >
                        {ele?.first_name?.charAt(0)}
                        {ele?.last_name?.charAt(0)}
                      </Avatar>
                      <span className="optionname">
                        {ele.first_name} {ele.last_name}
                      </span>
                    </span>
                  </Select.Option>
                );
              })}
          </Select>
          <Checkbox
            data-testid="migrateEmpCheckBox-DuplicateTeam"
            value={checked}
            onChange={check}
            className="team-checkbox"
          >
            Migrate all employees to new team
          </Checkbox>
        </div>
      </Modal>
    </>
  );
};

export default DuplicateTeam;
