import React, { useContext, useEffect, useState } from 'react';
import { Modal, Space, Button, notification, Select, Tag } from 'antd';
import './style.css';
import Interweave from 'interweave';
import verHisIcon from '../../assets/images/Group 1296.svg';
import verM_logo from '../../assets/images/verM_logo.svg';
import {
  Software,
  TractorHealth,
  updateVersionData,
} from '../../constants/types';
import { FIRMAWARE, SOFTWARE } from './SoftwareUpdate';
import { ApplicationContext } from '../../context/AppContext';
import {
  getTractorHealth,
  getVersionList,
  updateSoftwareAPI,
} from '../../constants/Api';
import { CheckOutlined } from '@ant-design/icons';
import { getReleaseDate } from '../../constants/Common';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: (state: boolean) => void;
  data: Software;
}
const validationsText = {
  tractorBattery: 'Tractor battery must be above 20% or currently charging.',
  tractorErrorCode:
    'Tractor has no relevant error codes with a severity level of 2 or 3.',
  tractorMotorOff: 'Tractor motor must be off.',
  internetConnection:
    'Tractor needs strong internet connection with a bandwidth greater than 10mbps.',
};
export const SoftwareModal: React.FC<Props> = ({
  isModalVisible,
  setIsModalVisible,
  data,
}: Props) => {
  const { userDetails } = useContext(ApplicationContext);
  const [tractorHealth, setTractorHealth] = useState<TractorHealth>();
  const [isInstall, setIsInstall] = useState<boolean>(true);
  const [updatedVersion, setUpdatedVersion] = useState<updateVersionData[]>([]);
  const [selectVersion, setSelectVersion] = useState<any>();
  const [softwareVesrion, setSoftwareVesrion] = useState<any>();

  const [shwoReleaseNotesFlag, setshwoReleaseNotesFlag] =
    useState<boolean>(false);

  useEffect(() => {
    // allow to install if all health checks are good
    if (
      tractorHealth &&
      tractorHealth.soc &&
      tractorHealth.soc.status &&
      tractorHealth.network &&
      tractorHealth.network.status &&
      tractorHealth.autonomy &&
      tractorHealth.autonomy.status &&
      tractorHealth.motor &&
      tractorHealth.motor.status
    ) {
      setIsInstall(true);
    } else {
      setIsInstall(false);
    }
  }, [tractorHealth]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    if (data && data.id) {
      getSoftwares(data.id);
    }
  }, [data]);
  useEffect(() => {
    if (userDetails && userDetails.organization && data) {
      getVersionLists(data.tractor_model, data.type || 'software');
    }
  }, [userDetails, data]);
  const getSoftwares = async (tractorId: number) => {
    try {
      const health: TractorHealth = await getTractorHealth(
        userDetails.organization.api_url,
        tractorId,
      );
      setTractorHealth(health);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };
  const updateSoftware = async () => {
    try {
      await updateSoftwareAPI(
        userDetails.organization.api_url,
        data.id,
        userDetails.organization.fleet.id,
        {
          current_software_version_id:
            data && data?.type === SOFTWARE
              ? data.software_version_id
              : data.firmware_version_id,
          organization_id: userDetails.organization.id,
          desired_software_version_id:
            data && data?.type === SOFTWARE
              ? data.latest_software_version_id
              : data.latest_firmware_version_id,
        },
      );
      getVersionLists(data.tractor_model, data.type || 'software');

      notification.success({
        message: 'Success',
      });
      handleCancel();
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };
  const getVersionLists = async (tractorModel: string, type: string) => {
    try {
      const records = await getVersionList(
        userDetails.organization.api_url,
        userDetails.organization.id,
        type,
        tractorModel,
      );
      const data = records.map((record: updateVersionData) => {
        return {
          versionid: record.software_version_id,
          newVersion: record.software_version,
          releaseNotes: record.release_notes,
          isGoldBuild:
            record.build_info && record.build_info?.build_tag === 'gold'
              ? true
              : false,
        };
      });

      setSoftwareVesrion(data[0].newVersion);
      setSelectVersion(data[0]);
      setUpdatedVersion(data as []);
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
      });
    }
  };

  const handleSelectVersion = (version: any) => {
    const versionData = JSON.parse(version);
    if (data.type === SOFTWARE) {
      data.latest_software_version_id = versionData.versionid;
    } else {
      data.latest_firmware_version_id = versionData.versionid;
    }
    setSoftwareVesrion(versionData.newVersion);
    setSelectVersion(versionData);
  };

  return (
    <div>
      <Modal
        closable={false}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={false}
        className="softwareInstall"
      >
        <div className="verInstallSec">
          <h4 className="verHistTxt mb12 posRel">
            {data && data?.type && data?.type === SOFTWARE
              ? 'Software'
              : data.type === FIRMAWARE
              ? 'Firmware'
              : ''}{' '}
            Update
            <img src={verM_logo} alt="mlogo" className="mLogo" height="33" />
          </h4>
          <div className="verDtl mb32">
            <span className="verNo">
              {data && data?.type && data?.type === SOFTWARE
                ? data?.software_version
                : data?.firmware_version}
            </span>
            <span className="verDate">
              {data && data?.type && data?.type === SOFTWARE
                ? data.data && data.data.software
                  ? getReleaseDate(data.data.software.release_date)
                  : ''
                : data.type === FIRMAWARE
                ? data.data && data.data.firmware
                  ? getReleaseDate(data.data.firmware.release_date)
                  : ''
                : ''}
            </span>
          </div>

          <div className="software-update-items-list mb24">
            <div className="installIns mb20">
              {tractorHealth &&
              tractorHealth?.soc &&
              tractorHealth.soc.status &&
              tractorHealth?.autonomy &&
              tractorHealth.autonomy.status &&
              tractorHealth?.motor &&
              tractorHealth?.motor?.status &&
              tractorHealth?.network &&
              tractorHealth?.network?.status ? (
                <>All conditions are met to update this tractor.</>
              ) : (
                <>
                  <img src={verHisIcon} height="18" className="mr16" /> Please
                  resolve the following issues to install the update.
                </>
              )}
            </div>
            <ul className="installInsUL">
              {tractorHealth &&
              tractorHealth?.soc &&
              tractorHealth.soc.status ? (
                <span className="listClass listClassColor">
                  <CheckOutlined style={{ marginRight: 20 }} />{' '}
                  {validationsText.tractorBattery}
                </span>
              ) : (
                <li>
                  <span>{validationsText.tractorBattery}</span>
                </li>
              )}
              {tractorHealth &&
              tractorHealth?.autonomy &&
              tractorHealth.autonomy.status ? (
                <span className="listClass listClassColor">
                  <CheckOutlined style={{ marginRight: 20 }} />{' '}
                  {validationsText.tractorErrorCode}
                </span>
              ) : (
                <li>
                  <span>{validationsText.tractorErrorCode}</span>
                </li>
              )}
              {tractorHealth &&
              tractorHealth?.motor &&
              tractorHealth?.motor?.status ? (
                <span className="listClass listClassColor">
                  <CheckOutlined style={{ marginRight: 20 }} />{' '}
                  {validationsText.tractorMotorOff}
                </span>
              ) : (
                <li>
                  <span>{validationsText.tractorMotorOff}</span>
                </li>
              )}
              {tractorHealth &&
              tractorHealth?.network &&
              tractorHealth?.network?.status ? (
                <span className="listClass listClassColor">
                  <CheckOutlined style={{ marginRight: 20 }} />{' '}
                  {validationsText.internetConnection}
                </span>
              ) : (
                <li>
                  <span>{validationsText.internetConnection}</span>
                </li>
              )}
            </ul>
          </div>
          <div className="installDec">
            The update process will take 20-25 minutes and <b>{data?.name}</b>{' '}
            will be unavailable for use during that time. Please ensure you do
            not have any pending operations before starting installation.
          </div>

          <div className="instBtnSec">
            <Select
              style={{ width: 214, marginBottom: 20 }}
              className="dropdownStyle dW214"
              value={softwareVesrion}
              onSelect={(e: string) => handleSelectVersion(e)}
            >
              {updatedVersion.map((data: any, index: number) => {
                return (
                  <option key={index} value={JSON.stringify(data)}>
                    {data.newVersion}{' '}
                    {data.isGoldBuild && (
                      <Tag color="gold" className="gold-tag">
                        gold
                      </Tag>
                    )}
                  </option>
                );
              })}
            </Select>
          </div>

          <div className="instBtnSec instBtn">
            <Space>
              <Button
                type="primary"
                className="installBtn"
                onClick={() => setshwoReleaseNotesFlag(!shwoReleaseNotesFlag)}
              >
                Release Notes
              </Button>
              <Button
                className="verCancelBtn mr0"
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              {!isInstall ? (
                <Button type="primary" className="installBtn dblBtn">
                  install
                </Button>
              ) : (
                <Button
                  type="primary"
                  className="installBtn"
                  onClick={() => updateSoftware()}
                >
                  install
                </Button>
              )}
            </Space>
          </div>
          {shwoReleaseNotesFlag && (
            <div className="releaseNotes mb24">
              <div className="verNoteHead">Release Notes:</div>
              <div className="verDec2">
                <Interweave
                  content={
                    selectVersion
                      ? selectVersion?.releaseNotes
                      : data?.data?.software?.latest_software?.release_notes ||
                        '-'
                  }
                />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
