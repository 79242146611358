import { Feature } from 'ol';
import { getVineRowsData } from '../../constants/Api';
import {
  getLatitudeAndLongitude,
  getXYCoordinates,
  priority,
  getVineRowStyle,
} from '../../constants/Common';
import { MapFeature, UserDetails } from '../../constants/types';
import * as proj from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { notification } from 'antd';
import LineString from 'ol/geom/LineString';

export const getVineRows = async (
  userDetails: UserDetails,
  base: [number, number],
): Promise<{ layer: any; list: MapFeature[] }> => {
  try {
    const { organization } = userDetails;
    const response = await getVineRowsData(
      organization.api_url,
      organization.farm.id,
    );
    const { records } = response;
    const vineRowsFeatures: Feature<any>[] = [];
    const vineRowsList: MapFeature[] = [];
    // vineRows = records;
    records.map((vine: any) => {
      const coordinates: any = [];
      const id = `vine_row_${vine.id}`;
      let latAndLongA: number[] = [];
      let latAndLongB: number[] = [];
      if (
        vine.head_a_latitude !== null &&
        vine.head_a_longitude !== null &&
        vine.head_b_latitude !== null &&
        vine.head_b_longitude !== null
      ) {
        const latLong1 = getXYCoordinates(
          [vine.head_a_longitude, vine.head_a_latitude],
          base,
        );
        const latLong2 = getXYCoordinates(
          [vine.head_b_longitude, vine.head_b_latitude],
          base,
        );
        // added convertion for remote AV start here
        vine.head_a_y = latLong1[1];
        vine.head_a_x = latLong1[0];
        vine.head_b_y = latLong2[1];
        vine.head_b_x = latLong2[0];
        // added convertion for remote AV end here
        latAndLongA = [vine.head_a_latitude, vine.head_a_longitude];
        latAndLongB = [vine.head_b_latitude, vine.head_b_longitude];
      } else {
        const vineA = {
          id: vine.id,
          x: vine.head_a_x / 100,
          y: vine.head_a_y / 100,
        };
        const vineB = {
          id: vine.id,
          x: vine.head_b_x / 100,
          y: vine.head_b_y / 100,
        };
        latAndLongA = getLatitudeAndLongitude([vineA.y, vineA.x], base);
        latAndLongB = getLatitudeAndLongitude([vineB.y, vineB.x], base);
      }

      coordinates.push(proj.fromLonLat([latAndLongA[1], latAndLongA[0]]));
      coordinates.push(proj.fromLonLat([latAndLongB[1], latAndLongB[0]]));
      const vineLocation = new Feature({
        geometry: new LineString(coordinates),
        pointType: 'vinerow',
        pointId: vine.id,
        name: vine.name,
      });
      vineLocation.setId(id);
      vineRowsFeatures.push(vineLocation);
      vineRowsList.push({
        id: vine.id,
        name: vine.name,
        type: 'VINEROW',
        mapId: id,
        visible: true,
        style: getVineRowStyle(true, false),
      });
    });

    const vineRowsFeaturesLayer = new VectorLayer({
      source: new VectorSource({
        features: vineRowsFeatures,
      }),
      zIndex: priority.VINEROW,
      visible: false,
      style: getVineRowStyle(true, false),
    });

    return { layer: vineRowsFeaturesLayer, list: vineRowsList };
  } catch (error: any) {
    notification.error({
      message: error.response?.data.error.message,
    });
    return { layer: null, list: [] };
  }
};
