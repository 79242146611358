/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-empty */
import React, { useContext, useEffect, useState } from 'react';
import Layout from 'antd/lib/layout/layout';
import closeGreen from '../../assets/images/closeGreen.svg';
import applicationIds from '../../locale/applicationIds.json';
import { ApplicationContext } from '../../context/AppContext';
import {
  Avatar,
  Button,
  Input,
  notification,
  Select,
  Spin,
  Tooltip,
} from 'antd';
import { CheckOutlined, CloseOutlined, WarningFilled } from '@ant-design/icons';
import {
  UpdateNewEmployee,
  updateUserProfileImageUpload,
} from '../../constants/Api';
import ImageUpload from './ImageUpload';
import { isValidEmail, onlyNumbersAllow } from '../../constants/Common';
import { TeamsContext } from '../../context/TeamContext';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import classNames from 'classnames';
import AppLoader from './AppLoader';
interface Props {
  Close: () => void;
  toggleWidget: boolean;
  userProfile: any;
}

const validationData = {
  firstName: true,
  lastName: true,
  email: true,
  enabled: false,
};
const EditEmployee: React.FC<Props> = ({
  Close,
  toggleWidget,
  userProfile,
}: Props) => {
  const { t } = useTranslation();
  const { userDetails, APPReducer, getOrgCodesData, updateUserDetails } =
    useContext(ApplicationContext);
  const { TEAMSReducer } = useContext(TeamsContext);
  const [state] = APPReducer;
  const { teams } = state;
  const [selectTeamsIds, setselectTeamsIds] = useState<any[]>([]);
  const [access, setAccess] = useState({
    app_access: false,
    tractor_access: false,
  });
  const [fileList, setFileList] = useState<any>('');
  const [teamslist, setTeams] = useState<any>([]);
  const [firstName, setFirstName] = useState<any>();
  const [lastName, setLastName] = useState<any>();
  const [email, setEmail] = useState<string>('');
  const [contactNum, setContactNum] = useState<any>();
  const [validations, setValidations] = useState<any>(validationData);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [firstNameError, setFirstNameError] = useState<boolean>();
  const [firstNameLongError, setFirstNameLongError] = useState<boolean>();
  const [firstNameSpecialCharaterError, setFirstNameSpecialCharaterError] =
    useState<boolean>();
  const [lastNameError, setLastNameError] = useState<boolean>();
  const [lastNameLongError, setLastNameLongError] = useState<boolean>();
  const [lastNameSpecialCharacterError, setLastNameSpecialCharacterError] =
    useState<boolean>();
  const [loader, setLoader] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { Option } = Select;

  useEffect(() => {
    if (
      firstName &&
      firstName?.trim().length > 2 &&
      firstName?.trim().length <= 25 &&
      !getregexErrors(firstName?.trim()) &&
      lastName &&
      lastName?.trim().length > 2 &&
      lastName?.trim().length <= 25 &&
      !getregexErrors(lastName?.trim()) &&
      selectTeamsIds &&
      selectTeamsIds.length > 0
    ) {
      if (access?.app_access) {
        const emildata = email.trim().length > 0 && isValidEmail(email);
        setIsDisabled(!emildata);
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  }, [firstName, lastName, email, access, selectTeamsIds]);

  const getregexErrors = (value: any) => {
    const exp = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    const regexp = new RegExp(exp);
    return regexp.test(value);
  };

  useEffect(() => {
    if (userProfile) {
      setFirstName(userProfile?.first_name);
      setLastName(userProfile?.last_name);
      setEmail(userProfile?.email);
      setContactNum(userProfile?.contact_number);
      setFileList(userProfile?.profile_url);
      const st = userProfile.is_active ? 'true' : 'false';
      setStatus(st);
    }
  }, [userProfile]);

  useEffect(() => {
    const taccess = teams
      .filter((team: any) => selectTeamsIds.includes(team.id))
      .reduce(
        (a: any, team: any) => {
          if (team.app_access) a.app_access = true;
          if (team.tractor_access) a.tractor_access = true;
          return a;
        },
        { app_access: false, tractor_access: false },
      );
    setAccess(taccess);
  }, [selectTeamsIds]);

  useEffect(() => {
    if (userProfile && userProfile.teams) {
      setTeams(userProfile?.teams);
    }
  }, [userProfile]);

  useEffect(() => {
    if (teamslist) {
      const tempData: any = [];
      teamslist.map((ele: any) => {
        tempData.push(ele.id);
      });
      setselectTeamsIds(tempData);
    }
  }, [teamslist]);

  useEffect(() => {
    const isValid = Object.values(validations).every((item) => item);
    if (isValid && Object.keys(validations).length) editProfileSave();
  }, [validations]);

  useEffect(() => {
    const exp = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    const regexp = new RegExp(exp);
    if (regexp.test(firstName !== undefined && firstName)) {
      setFirstNameSpecialCharaterError(true);
    } else {
      setFirstNameSpecialCharaterError(false);
    }
    if (firstName?.trim() !== '' && firstName?.trim().length < 3) {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (firstName?.trim() !== '' && firstName?.trim().length > 25) {
      setFirstNameLongError(true);
    } else {
      setFirstNameLongError(false);
    }
  }, [firstName]);

  useEffect(() => {
    const exp = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    const regexp = new RegExp(exp);
    if (regexp.test(lastName !== undefined && lastName)) {
      setLastNameSpecialCharacterError(true);
    } else {
      setLastNameSpecialCharacterError(false);
    }
    if (lastName?.trim() !== '' && lastName?.trim().length < 3) {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }

    if (lastName?.trim() !== '' && lastName?.trim().length > 25) {
      setLastNameLongError(true);
    } else {
      setLastNameLongError(false);
    }
  }, [lastName]);

  const handleFormSubmit = () => {
    const exp = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    const regexp = new RegExp(exp);
    setValidations({
      ...validations,
      firstName:
        firstName?.trim() !== '' &&
        firstName?.trim().length > 2 &&
        firstName?.trim().length < 25 &&
        !regexp.test(firstName),
      lastName:
        lastName?.trim() !== '' &&
        lastName?.trim().length > 2 &&
        lastName?.trim().length < 25 &&
        !regexp.test(lastName),
      email:
        email.trim() !== ''
          ? isValidEmail(email)
            ? true
            : false
          : email.trim() === '',

      enabled: true,
    });
  };

  useEffect(() => {
    if (email.trim() !== '') {
      const validationError = isValidEmail(email);
      setEmailError(!validationError ? true : false);
    } else {
      setEmailError(false);
    }
  }, [email]);

  const editProfileSave = async () => {
    const payload: any = {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      organization_id: userDetails.organization_id,
      email: email ? email : '',
      contact_number: contactNum ? contactNum.trim() : '',
      teams: selectTeamsIds,
      is_active: status === 'true' ? true : false,
    };
    try {
      setLoader(true);
      await UpdateNewEmployee(
        userDetails.organization.api_url,
        userProfile.id,
        payload,
      );
      getOrgCodesData();
      handleSave();
      notification.success({
        message: t(translate.label.employeeUpdatd),
        duration: 2,
      });
    } catch (err: any) {
      notification.error({
        message: err?.message,
        duration: 2,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleSave = async () => {
    try {
      setLoader(true);
      if (fileList?.originFileObj) {
        await imageUpload();
      }
      updateUserDetails();
      Close();
    } catch (err: any) {
      notification.error(err.response?.data.error.message);
    } finally {
      setLoader(false);
    }
  };

  const selectTeams = async (e: any) => {
    setselectTeamsIds(e);
  };

  const imageUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('image', fileList?.originFileObj);
      const response = await updateUserProfileImageUpload(
        userDetails.organization.api_url,
        userDetails.id,
        formData,
      );
      notification.success({
        message: response?.msg,
      });
    } catch (err: any) {
      notification.error({
        message: err.response?.data.error.message,
        duration: 2,
      });
    }
  };

  return (
    <Layout>
      <div className={'rightSideWidegt ' + (toggleWidget ? 'expand' : 'hide')}>
        <div className="widgetCloseBtn">
          <img
            data-testid="editModalCloseIcon-EditEmployee"
            id={applicationIds.personnelScreen.AddEmpClose}
            src={closeGreen}
            alt="close icon"
            onClick={Close}
          />
        </div>
        <div className="profileView empView addEmpBlk rightWidgetView">
          <div className="proRow mb24">
            <h4 className="headline1 rowGapBtm13">Edit Profile</h4>
          </div>
          <div className="uploadImg proRow mr0 rowGapBtm2">
            <div className="userIcon">
              <div className="proRow proPic">
                {fileList && fileList !== '' ? (
                  <Avatar
                    src={fileList?.thumbUrl ? fileList?.thumbUrl : fileList}
                  ></Avatar>
                ) : (
                  <Avatar
                    style={{
                      width: '145px',
                      height: '145px',
                      backgroundColor: '#67AFB7',
                      color: 'white',
                      fontFamily: 'Montserrat-Bold',
                    }}
                  >
                    {userProfile?.first_name?.charAt(0)}
                    {userProfile?.last_name?.charAt(0)}
                  </Avatar>
                )}
              </div>
              <ImageUpload setFileList={setFileList}></ImageUpload>
            </div>
          </div>
          <div className="proRow formStyles">
            <AppLoader loader={loader} />
            <div className="width100 rowGapBtm3 ">
              <div>
                <Input
                  data-testid="firstNameEditInputField-EditEmployee"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className={classNames({
                    errorClass:
                      firstNameError ||
                      firstNameSpecialCharaterError ||
                      firstNameLongError,
                  })}
                />
              </div>
              {firstNameError && (
                <label style={{ color: 'red' }}>
                  Min 3 Characters Required
                </label>
              )}
              {firstNameError && firstNameSpecialCharaterError && <br />}
              {firstNameSpecialCharaterError && (
                <label style={{ color: 'red' }}>
                  Should not contain special character
                </label>
              )}
              {firstNameSpecialCharaterError && firstNameLongError && <br />}
              {firstNameLongError && (
                <label style={{ color: 'red' }}>
                  Should not exceed 25 characters
                </label>
              )}
            </div>
            <div className="width100 rowGapBtm3">
              <div>
                <Input
                  data-testid="lastNameEditInputField-EditEmployee"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className={classNames({
                    errorClass:
                      lastNameError ||
                      lastNameSpecialCharacterError ||
                      lastNameLongError,
                  })}
                />
              </div>
              {lastNameError && (
                <label style={{ color: 'red' }}>
                  Min 3 Characters Required
                </label>
              )}
              {lastNameError && lastNameSpecialCharacterError && <br />}
              {lastNameSpecialCharacterError && (
                <label style={{ color: 'red' }}>
                  Should not contain special character
                </label>
              )}
              {lastNameSpecialCharacterError && lastNameLongError && <br />}
              {lastNameLongError && (
                <label style={{ color: 'red' }}>
                  Should not exceed 25 characters
                </label>
              )}
            </div>
            <h6 className="headline2 fSbd width100 rowGapBtm4">Teams</h6>
            <div className=" width100 rowGapBtm3 teamsSelect">
              <Select
                mode="multiple"
                style={{
                  width: '100%',
                }}
                data-testid="TeamsEditInputField-EditEmployee"
                placeholder="Add Teams"
                value={teams && teams.length > 0 ? selectTeamsIds : ''}
                onChange={selectTeams}
                className="addEmployeeSelect multiSelectField"
                showSearch={true}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return (
                    option!.title
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {teams.map((team: any) => {
                  return (
                    <Option value={team?.id} key={team?.id} title={team.name}>
                      <div className="teamName body3">{team.name}</div>
                      <div className="teamDescription">{team.description}</div>
                    </Option>
                  );
                })}
              </Select>
              {selectTeamsIds.length > 0 && (
                <div className="mt10 flex-column">
                  {access.tractor_access ? (
                    <div style={{ color: '#366376' }}>
                      <CheckOutlined /> Access tractors
                    </div>
                  ) : (
                    <div>
                      <CloseOutlined /> Access tractors
                    </div>
                  )}
                  {access.app_access ? (
                    <div style={{ color: '#366376' }}>
                      <CheckOutlined /> Access apps
                    </div>
                  ) : (
                    <div>
                      <CloseOutlined /> Access apps
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex width100">
              {access.app_access && !email && (
                <Tooltip
                  title="Need Email to grant some permissions."
                  placement="bottom"
                >
                  <WarningFilled
                    style={{
                      color: '#fbb313',
                      marginRight: '10px',
                      fontSize: '22px',
                      marginLeft: '30px',
                    }}
                  />
                </Tooltip>
              )}
              <span className="headline2 fSbd width100 rowGapBtm4">
                Contact
              </span>
            </div>

            {access.app_access ? (
              <>
                <div className="width100 rowGapBtm3">
                  <div>
                    <Input
                      id={applicationIds.personnelScreen.editEmpMail}
                      data-testid="emailEditInputField-EditEmployee"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className={classNames({
                        errorClass: !validations.email,
                      })}
                    />
                  </div>
                  {emailError && (
                    <label style={{ color: 'red' }}>Not a valid email</label>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="width100 rowGapBtm3">
                  <div>
                    <Input
                      id={applicationIds.personnelScreen.editEmpMail}
                      data-testid="emailEditInputField-EditEmployee"
                      placeholder="Email (optional)"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  {emailError && (
                    <label style={{ color: 'red' }}>Not a valid email</label>
                  )}
                </div>
              </>
            )}
            <div className="width100 rowGapBtm3">
              <Input
                data-testid="contactNumEditInputField-EditEmployee"
                id={applicationIds.personnelScreen.editEmpContact}
                placeholder="Phone Number"
                value={contactNum}
                onChange={(e) => setContactNum(e.target.value)}
                onKeyPress={(e: any) => onlyNumbersAllow(e)}
              />
            </div>

            <h6 className="headline2 fSbd width100 rowGapBtm4">Status</h6>
            <div className="width100 rowGapBtm3" style={{ marginBottom: 102 }}>
              <Select
                data-testid="statusDropdown-EditEmployee"
                getPopupContainer={(trigger) => trigger.parentNode}
                // style={{ width: '284px' }}
                value={status}
                onSelect={(change) => setStatus(change)}
              >
                <option
                  value="true"
                  key="true"
                  data-testid="activeStatus-EditEmployee"
                >
                  Active
                </option>
                <option
                  value="false"
                  key="false"
                  data-testid="inActiveStatus-EditEmployee"
                >
                  Inactive
                </option>
              </Select>
            </div>
          </div>
          <div className="proeditBtn tcktSaveBtn saveTxtBtn">
            <Button
              onClick={handleFormSubmit}
              data-testid="saveEditButton-EditEmployee"
              disabled={isDisabled}
            >
              SAVE
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default EditEmployee;
