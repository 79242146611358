import React, { useContext, useState } from 'react';
import { Tabs } from 'antd';
import { ApplicationContext } from '../../context/AppContext';
import Notification from '../notifications/Notification';
// import Training from '../training/Training';
import UserProfile from './UserProfile';
import { privilagesConstants } from '../../constants/Privilages';
const { TabPane } = Tabs;
import './style.css';

const Profile: React.FC = () => {
  const { privilegeChecker } = useContext(ApplicationContext);
  const [tabid, setTabid] = useState<any>('Profile');

  const onChange = (key: string) => {
    setTabid(key);
  };

  return (
    <>
      <div className="profileWrapper">
        <Tabs defaultActiveKey="1" className="elTabs" onChange={onChange}>
          <TabPane tab="Profile" key="1">
            <UserProfile />
          </TabPane>
          {privilegeChecker(privilagesConstants.Notifications) && (
            <TabPane
              data-testid="notifications-Profile"
              tab="Notifications"
              key="2"
            >
              <div className="my-profile-container userPf posRel">
                <Notification />
              </div>
            </TabPane>
          )}

          {/* <TabPane tab="Training" key="3" className="attTableHeight mBottom0">
            <div className="my-profile-container userPf posRel">
              <Training />
            </div>
          </TabPane> */}
        </Tabs>
      </div>
    </>
  );
};

export default Profile;
