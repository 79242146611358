import { notification } from 'antd';
import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import RoutesConstants from '../../routes/RoutesConstant';

const SessionTimer: React.FC = () => {
  const { push } = useHistory();

  const onIdle = () => {
    localStorage.clear();
    notification.info({
      description: 'You have been logged out due to Inactivity',
      message: 'Logged Out',
    });
    push(RoutesConstants.Login);
  };

  const { reset } = useIdleTimer({
    onIdle,
    onAction() {
      reset();
    },
    timeout: 1000 * 60 * 40,
    promptTimeout: 1000 * 60 * 10,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    syncTimers: 0,
  });

  return <></>;
};

export default SessionTimer;
