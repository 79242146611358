import { DatePicker } from 'antd';
import type { Moment } from 'moment';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

const { RangePicker } = DatePicker;

type RangeValue = [Moment | null, Moment | null] | null;

interface Props {
  onChange: (dates: any) => void;
  format?: string;
  defaultValues?: any;
  range?: number;
  open?: boolean;
  style?: Record<string, unknown>;
}
const CustomRangePicker: React.FC<Props> = ({
  onChange,
  format,
  defaultValues,
  range = 6,
  open,
  style,
}) => {
  const [dates, setDates] = useState<RangeValue>(null);
  const [hackValue, setHackValue] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);
  const op = useRef<boolean>(false);

  const disabledDate = (current: Moment) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > range;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > range;
    return (
      current.endOf('day').diff(new Date(), 'days') > 0 ||
      !!tooEarly ||
      !!tooLate
    );
    // return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    op.current = open;
    if (open) {
      if (value) setHackValue([value[0], null]);
      else setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(null);
    }
  };

  useEffect(() => {
    if (defaultValues) setValue(defaultValues);
  }, [defaultValues]);

  const handleOnChange = (dates: any) => {
    setValue(dates);
    onChange(dates);
  };

  useEffect(() => {
    const date = op.current && value && value[0] ? moment(value[0]) : moment();
    if (op.current) {
      setHackValue([date, null]);
    }
  }, [op.current]);

  useEffect(() => {
    if (op.current) {
      const elements: NodeList = document.querySelectorAll(
        '.ant-picker-input-active > input',
      );
      if (elements[0]) {
        const handleEvent = (e: any) => {
          e.preventDefault();
          return false;
        };
        elements[0].removeEventListener('keydown', handleEvent);
        elements[0].addEventListener('keydown', handleEvent);
      }
    }
  }, [op.current]);

  return (
    <RangePicker
      style={style}
      value={hackValue || value}
      disabledDate={disabledDate}
      onCalendarChange={(val) => setDates(val)}
      onChange={handleOnChange}
      onOpenChange={onOpenChange}
      format={format || 'MMM DD, YY'}
      // defaultValue={value}
      // open={open}
    />
  );
};

export default CustomRangePicker;
