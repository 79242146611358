import Layout, { Content, Header } from 'antd/lib/layout/layout';
import React, { createRef, useContext, useEffect, useState } from 'react';
import FullCalendar, {
  EventClickArg,
  EventSourceInput,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { getTaskList } from '../../constants/Api';
import { TaskResponse } from '../../constants/types';
import listPlugin from '@fullcalendar/list';
import { useHistory, useLocation } from 'react-router';
import RoutesConstants from '../../routes/RoutesConstant';
import './style.css';
import { Button, Input, notification } from 'antd';
import { ApplicationContext } from '../../context/AppContext';
import { SearchOutlined } from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import translate from '../../locale/en_translate.json';
import CreateTaskModel from './CreateTaskModel';
import { useMixpanel } from 'react-mixpanel-browser';

type Event = {
  id: number;
  task_uuid: string;
  start: number;
  end: number;
  title: string;
  backgroundColor: string;
};

const TaskMaster: React.FC = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { userDetails } = useContext(ApplicationContext);
  const [event, setEvent] = useState<Event[]>([]);
  const calenderRef: React.RefObject<FullCalendar> = createRef();
  const [viewTitle, setViewTitle] = useState('');
  const [showCreate, setShowCreate] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [titleSearch, setTitleSearch] = useState<string>('');
  const [showClosed, setShowClosed] = useState(false);
  const mixpanel = useMixpanel();
  const { t } = useTranslation();
  useEffect(() => {
    if (userDetails && userDetails.organization) {
      setTitleSearch('');
      getTasks('');
    }
  }, [viewTitle, userDetails, showCreate, showClosed]);

  const getTasks = async (title: string) => {
    try {
      const startTime = calenderRef.current
        ?.getApi()
        .view.activeStart.getTime();
      const endTime = calenderRef.current?.getApi().view.activeEnd.getTime();
      const tasks: TaskResponse[] = await getTaskList(
        userDetails.organization.api_url,
        userDetails.organization.id,
        startTime || 0,
        endTime || 0,
        title,
        userDetails.id,
        !pathname.includes(RoutesConstants.Taskmaster),
        showClosed ? '' : 'created,started,finished',
      );

      const getColor = (status: string) => {
        if (status === 'Closed') {
          return 'grey';
        } else if (status === 'Finished') {
          return 'orange';
        } else if (status === 'Started') {
          return '#0A7189';
        } else if (status === 'Created') {
          return 'green';
        } else {
          return '';
        }
      };
      const events: Event[] = [];
      tasks.forEach((task: TaskResponse) => {
        events.push({
          id: task.id,
          task_uuid: task.task_uuid,
          start: task.planned_start_date_time,
          end: task.planned_finish_date_time,
          title: task.title,
          backgroundColor: getColor(task.task_status_name),
        });
      });

      setEvent(events);
    } catch (error: any) {
      notification.error({
        message: error.response?.data.error.message,
      });
    }
  };

  const handleClickEvent = (e: EventClickArg) => {
    push({
      pathname: RoutesConstants.Taskdetails,
      state: {
        task_uuid: e.event._def.extendedProps.task_uuid,
      },
    });
  };

  const handleCloseModel = () => {
    setFromDate(new Date());
    setShowCreate(false);
  };

  return (
    <Layout className="taskmasterComp">
      <div className="mainContent">
        <div className="taskmasterblk">
          <div className="dbWidget tmTbl calTbl taskmasterWidget tblContainer">
            <Header className="tm-header ant-card-head">
              <div className="ant-card-head-title">Task Master</div>
              <div className="ant-card-extra">
                <Input
                  className="search-box ant-input-affix-wrapper"
                  placeholder="Search tasks"
                  data-testid="searchtask-WorkSchedule"
                  value={titleSearch}
                  prefix={<SearchOutlined />}
                  onChange={(e) => {
                    setTitleSearch(e.target.value);
                    getTasks(e.target.value);
                  }}
                />
                <Button
                  className="tm-button ant-btn-primary btn-create float-right"
                  data-testid="addbutton-WorkSchedule"
                  onClick={() => {
                    mixpanel.track('AddTask', {
                      event: 'Add Task',
                    });
                    setShowCreate(true);
                  }}
                >
                  {t(translate.buttons.add)}
                </Button>
                <Checkbox
                  className="showClose"
                  data-testid="checkbox-WorkSchedule"
                  value={showClosed}
                  onChange={(e) => setShowClosed(e.target.checked)}
                >
                  Show Closed
                </Checkbox>
              </div>
            </Header>
            <Content className="tm-content ant-card-body calendar-body rmTbl2">
              {' '}
              <FullCalendar
                ref={calenderRef}
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin,
                ]}
                initialView="listWeek"
                headerToolbar={{
                  center: 'title',
                  left: 'prev,next',
                  right: 'listWeek,dayGridMonth,timeGridWeek,timeGridDay',
                }}
                buttonText={{
                  list: 'schedule',
                }}
                editable={false}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={5}
                events={event as EventSourceInput}
                eventsSet={() =>
                  setViewTitle(calenderRef.current?.getApi().view.title || '')
                }
                eventClick={(e) => handleClickEvent(e)}
                dateClick={(e) => {
                  setShowCreate(true);
                  setFromDate(e.date);
                }}
                moreLinkClick={(e) =>
                  calenderRef.current
                    ?.getApi()
                    .changeView('timeGridDay', e.date)
                }
              />
            </Content>
            {showCreate && (
              <CreateTaskModel
                visible={showCreate}
                close={() => handleCloseModel()}
                success={() => {
                  handleCloseModel();
                }}
                selectedDate={fromDate}
                selectedfromDate={fromDate}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TaskMaster;
