import Layout, { Content } from 'antd/lib/layout/layout';
import React, { useContext, useEffect, useState } from 'react';
import Employees from './Employees';
import OrganizationDetails from './OrganizationDetails';
// import OrgAdministrator from './OrgAdministrator';
// import { getProfileData } from '../../constants/Api';
// import {
//   OrganizationAdministrators,
//   OrganizationProfileDetails,
// } from '../../constants/types';
import { ApplicationContext } from '../../context/AppContext';
import TeamsAppContext from '../../context/TeamContext';
// import Roles from './Roles';
import { Card, Tabs } from 'antd';
import { useMixpanel } from 'react-mixpanel-browser';
import { useLocation } from 'react-router-dom';
import BaseStation from '../basestation/BaseStation';
import FarmImplements from '../farmImplements/FarmImplements';
import Fleet from '../fleet/Fleet';
import { SET_LAST_TAB_INDEX } from '../../context/actions';

const { TabPane } = Tabs;
const Organization: React.FC = () => {
  const location = useLocation();
  const state: any = location && location.state ? location.state : null;
  const { APPReducer } = useContext(ApplicationContext);
  const [, dispatch] = APPReducer;
  // const [orgAdmin, setOrgAdmin] = useState<OrganizationAdministrators[]>([]);
  // const [orgProfile, setOrgProfile] = useState<OrganizationProfileDetails>();
  const [tabid, setTabid] = useState<string>('');
  const mixpanel = useMixpanel();

  // useEffect(() => {
  //   if (userDetails && userDetails.organization) {
  //     getProfileDetails();
  //   }
  // }, [userDetails]);

  useEffect(() => {
    if (state?.reset) {
      setTabid('Farm');
    }
  }, [state?.reset]);

  useEffect(() => {
    if (state && state.state === 'Fleet') {
      setTabid(state.state);
    }
  }, [state]);

  useEffect(() => {
    const lastTabIndex: string | null = localStorage.getItem('lastTabIndex');
    const tlastTabIndex: any = lastTabIndex && JSON.parse(lastTabIndex);
    if (tlastTabIndex?.catlog === 'Organization') {
      setTabid(tlastTabIndex?.tab);
    } else {
      setTabid('Farm');
    }
  }, [localStorage.getItem('lastTabIndex')]);

  // const getProfileDetails = async () => {
  //   try {
  //     const profileData: OrganizationProfileDetails = await getProfileData(
  //       userDetails.organization.api_url,
  //       userDetails.organization.id,
  //     );
  //     // setOrgProfile(profileData);
  //     // setOrgAdmin(profileData.org_administrators);
  //   } catch (err: any) {
  //     notification.error({
  //       message: err.response?.data.error.message,
  //     });
  //   }
  // };

  return (
    <Layout>
      <Content>
        <div className="mainContent">
          <div className="dbWidget attContainer padt0 bNone">
            <Card>
              <Tabs
                activeKey={tabid}
                className="elTabs"
                onChange={(key) => {
                  setTabid(key);
                  dispatch({
                    type: SET_LAST_TAB_INDEX,
                    payload: {
                      catlog: 'Organization',
                      tab: key,
                    },
                  });
                  mixpanel.track(key, {
                    event: `${key} Tab`,
                  });
                }}
              >
                <TabPane
                  tab="Farm"
                  key="Farm"
                  className="attTableHeight mBottom0"
                >
                  {tabid === 'Farm' && <OrganizationDetails />}
                </TabPane>
                <TabPane
                  tab="Personnel"
                  key="Personnel"
                  className="attTableHeight mBottom0"
                  data-testid="Personnel-FarmProfile"
                >
                  {tabid === 'Personnel' && (
                    <TeamsAppContext>
                      <Employees />
                    </TeamsAppContext>
                  )}
                </TabPane>
                <TabPane
                  tab="Fleet"
                  key="Fleet"
                  className="attTableHeight mBottom0"
                  data-testid="FleetMenu-FarmProfile"
                >
                  {tabid === 'Fleet' && <Fleet />}
                </TabPane>
                <TabPane
                  tab="Implements"
                  key="Implements"
                  className="attTableHeight mBottom0"
                  data-testid="ImplementsMenu-FarmProfile"
                >
                  {tabid === 'Implements' && <FarmImplements />}
                </TabPane>
              </Tabs>
            </Card>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default Organization;
