import React, { useContext, useEffect, useState } from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import { Card, Tabs } from 'antd';
import Diagnostics from './Diagnostics';
import Tickets from '../tickets/Tickets';
import { useLocation } from 'react-router-dom';
import SoftwareUpdate from '../softwareupdates/SoftwareUpdate';
import { useMixpanel } from 'react-mixpanel-browser';
import FleetHealthContext from './FleetHealthContext';
import RestartManager from './RestartManager';
import { SET_LAST_TAB_INDEX } from '../../context/actions';
import { ApplicationContext } from '../../context/AppContext';
import { privilagesConstants } from '../../constants/Privilages';

const { TabPane } = Tabs;

const FleetHealthContainer: React.FC = () => {
  const [tabid, setTabid] = useState<string | undefined>('');
  const { APPReducer, privilegeChecker } = useContext(ApplicationContext);
  const [, dispatch] = APPReducer;
  const [tractorId, setTractorId] = useState<number>(0);
  const [createTicket, setCreateTicket] = useState<boolean>(false);
  const location: any = useLocation();
  const mixpanel = useMixpanel();
  const state: any = location && location.state ? location.state : {};

  const navigateBasedonAction = () => {
    if (location.state && location.state.action == 'INDICATOR') {
      setTabid('3');
      setTractorId(location.state.tractorId);
    }
    if (location.state && location.state.action == 'REPAIR MANAGER') {
      setTabid('5');
      setTractorId(location.state.tractorId);
    }
    if (location.state && location.state.action == 'TICKET') {
      setTabid('2');
      if (location && location.state && location.state.createTicket) {
        setCreateTicket(true);
      }
    }
    if (location.state && location.state.action == 'SOFTWARE') {
      setTabid('4');
    }
  };

  useEffect(() => {
    navigateBasedonAction();
  }, [location]);

  useEffect(() => {
    if (state && Object.keys(state).includes('reset')) {
      setTabid('2');
    }
  }, [state.reset]);

  useEffect(() => {
    const lastTabIndex: string | null = localStorage.getItem('lastTabIndex');
    const tlastTabIndex: any = lastTabIndex && JSON.parse(lastTabIndex);
    if (tlastTabIndex?.catlog === 'FleetHealth') {
      setTabid(tlastTabIndex?.tab);
    } else if (location && location.state) {
      navigateBasedonAction();
    } else {
      setTabid('2');
    }
  }, [localStorage.getItem('lastTabIndex')]);

  return (
    <FleetHealthContext>
      <Layout>
        <Content>
          <div className="mainContent">
            <div className="dbWidget attContainer padt0 bNone">
              <Card>
                <Tabs
                  activeKey={tabid}
                  className="elTabs"
                  onChange={(key) => {
                    setTabid(key);
                    dispatch({
                      type: SET_LAST_TAB_INDEX,
                      payload: {
                        catlog: 'FleetHealth',
                        tab: key,
                      },
                    });
                    mixpanel.track(key, {
                      event: `${key} Tab`,
                    });
                  }}
                >
                  {process.env.REACT_APP_ALPHA_FEATURES &&
                    process.env.REACT_APP_ALPHA_FEATURES.includes(
                      'DigitalApps',
                    ) && (
                      <TabPane
                        tab="OVERVIEW"
                        key="1"
                        disabled
                        className="attTableHeight mBottom0"
                      >
                        <h1>Overview</h1>
                      </TabPane>
                    )}
                  <TabPane
                    tab="TICKETS"
                    key="2"
                    className="attTableHeight mBottom0"
                    data-testid="TicketsMenu-Maintenance"
                  >
                    {tabid == '2' && <Tickets createTicket={createTicket} />}
                  </TabPane>

                  <TabPane
                    tab="DIAGNOSTICS"
                    key="3"
                    className="attTableHeight mBottom0"
                  >
                    {tabid == '3' && <Diagnostics tractorID={tractorId} />}
                  </TabPane>
                  <TabPane
                    tab="UPDATES"
                    key="4"
                    className="attTableHeight mBottom0"
                    data-testid="UpdatesMenu-Maintenance"
                  >
                    {tabid == '4' && <SoftwareUpdate />}
                  </TabPane>
                  {process.env.REACT_APP_ALPHA_FEATURES &&
                    process.env.REACT_APP_ALPHA_FEATURES.includes(
                      'RepairManager',
                    ) &&
                    privilegeChecker(
                      privilagesConstants.Repair_Manager_Access,
                    ) && (
                      <TabPane
                        tab="REPAIR MANAGER"
                        key="5"
                        className="attTableHeight mBottom0"
                        data-testid="RepairMenu-Maintenance"
                      >
                        {tabid == '5' && <RestartManager />}
                      </TabPane>
                    )}
                </Tabs>
              </Card>
            </div>
          </div>
        </Content>
      </Layout>
    </FleetHealthContext>
  );
};

export default FleetHealthContainer;
